import { useCallback, useEffect, useState, useMemo } from 'react';
import { Input, Row, Col } from 'antd';

import Layout from '../../../components/Layout/Layout';
import Breadcrumb from '../../../components/Breadcrumb/Breadcrumb';
import PageSubtitle from '../../../components/PageSubtitle/PageSubtitle';
import Button from '../../../components/Button/Button';
import TemplatesEditor from './TemplatesEditor';
import StatusValue from '../components/StatusValue';
import StyledTable from '../components/StyledTable/StyledTable';
import EditIcon from '../components/EditIcon';

import useLocale from '../../../hooks/useLocale';
import useModal from '../../../hooks/useModal';
import useDebounce from '../../../hooks/useDebounce';

import { argumentifyTemplatesSearchCriteria } from './utils';
import { ITemplatesSearchCriteria } from './types';
import { getSortableFilterableColumns } from '../utils';
import { MAX_PAGE_SIZE } from '../../../constants/common';

import { ClientService } from '../../../shared/api/ClientService';
import API from '../../../utils/api';

import './Templates.scss';

function Templates(): JSX.Element {
  const { t } = useLocale();
  const { Search } = Input;
  const { showModal, closeModal } = useModal();

  const [searchTerm, setSearchTerm] = useState<string>('');
  const value = useDebounce(searchTerm);

  const [dataSource, setDataSource] = useState<ClientService.MessageTemplateDto[]>();
  const [totalCount, setTotalCount] = useState<number>();
  const [loading, setLoading] = useState<boolean>(false);
  const [criteria, setCriteria] = useState<ITemplatesSearchCriteria>({
    maxResultCount: MAX_PAGE_SIZE,
  });

  const requestTemplates = useCallback(async () => {
    setLoading(true);
    const response = await API.getMessageTemplateList(...argumentifyTemplatesSearchCriteria(criteria)).catch(() =>
      setLoading(false)
    );
    setLoading(false);

    if (response) {
      setDataSource(response?.items);
      setTotalCount(response?.totalCount || 0);
    }
  }, [criteria]);

  const handleShowEditor = useCallback(
    (templateId?: string) => {
      showModal(
        <TemplatesEditor
          onOk={() => {
            closeModal();
            requestTemplates();
          }}
          onCancel={closeModal}
          id={templateId}
        />
      );
    },
    [closeModal, requestTemplates, showModal]
  );

  useEffect(() => {
    requestTemplates();
  }, [criteria, requestTemplates]);

  useEffect(() => {
    setCriteria((prev) => ({ ...prev, filterText: value }));
  }, [value]);

  const sortableColumns = useMemo(() => {
    const columns = [
      {
        title: t.TYPE,
        dataIndex: 'typeName',
        key: 'typeName',
        sorter: true,
        filterByName: true,
      },
      {
        title: t.TEMPLATE_NAME,
        dataIndex: 'name',
        key: 'name',
        sorter: true,
        filterByName: true,
      },
      {
        title: t.STATUS,
        dataIndex: 'isActive',
        key: 'isActive',
        sorter: true,
        render: (isActive?: boolean) => <StatusValue isActive={isActive} />,
        filters: [
          {
            text: t.ACTIVE,
            value: true,
          },
          {
            text: t.INACTIVE,
            value: false,
          },
        ],
        onFilter: (value: any, record: ClientService.HolidayDto) => value === record.isActive,
      },
      {
        dataIndex: 'action',
        key: 'action',
        render: (_: any, record: ClientService.HolidayDto) => <EditIcon onClick={() => handleShowEditor(record?.id)} />,
      },
    ];

    return getSortableFilterableColumns(columns, dataSource);
  }, [dataSource, handleShowEditor]);

  return (
    <Layout className="Templates">
      <Breadcrumb data={[{ title: t.ADMINISTRATION }, { title: t.EMAIL_AND_SMS_TEMPLATES }]} />
      <PageSubtitle title={t.EMAIL_AND_SMS_TEMPLATES} />
      <Row align="bottom" justify="space-between">
        <Col span={8}>
          <Search
            placeholder={t.SEARCH_BY_TEMPLATE_NAME}
            allowClear
            size="large"
            onSearch={setSearchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </Col>
        <Col>
          <Button onClick={() => handleShowEditor()}>{t.ADD_TEMPLATE}</Button>
        </Col>
      </Row>
      <StyledTable rowKey="id" dataSource={dataSource} columns={sortableColumns} total={totalCount} loading={loading} />
    </Layout>
  );
}

export default Templates;
