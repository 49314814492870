import { useCallback, useEffect, useState, useMemo } from 'react';
import { Input, Row, Col } from 'antd';
import { Icon } from '@fluentui/react/lib/Icon';

import Layout from '../../../components/Layout/Layout';
import Breadcrumb from '../../../components/Breadcrumb/Breadcrumb';
import PageSubtitle from '../../../components/PageSubtitle/PageSubtitle';
import Button from '../../../components/Button/Button';
import CourtsEditor from './CourtsEditor';
import StatusValue from '../components/StatusValue';
import StyledTable from '../components/StyledTable/StyledTable';

import useLocale from '../../../hooks/useLocale';
import useModal from '../../../hooks/useModal';
import useDebounce from '../../../hooks/useDebounce';

import { getSortableFilterableColumns, convertArrayToReadable } from '../utils';
import { convertCourtsToDataSource, filterDataSourceBySearchValue } from './utils';
import { ICourt } from './types';
import API from '../../../utils/api';

import './Courts.scss';
import { ClientService } from '../../../shared/api/ClientService';

function Courts(): JSX.Element {
  const { t } = useLocale();
  const { Search } = Input;
  const { showModal, closeModal } = useModal();

  const [courts, setCourts] = useState<ICourt[]>();
  const [loading, setLoading] = useState<boolean>(false);
  const [agents, setAgents] = useState<ClientService.LookupDto[]>();

  const [searchTerm, setSearchTerm] = useState<string>('');
  const value = useDebounce(searchTerm);

  const dataSource = useMemo(() => filterDataSourceBySearchValue(value, courts, agents), [value, courts, agents]);
  const requestCourts = useCallback(async () => {
    setLoading(true);
    const response = await API.getCourtsSearchResults(undefined, undefined, undefined).catch(() => setLoading(false));
    const agents = await API.listAgents().catch(() => setLoading(false));
    setLoading(false);

    if (response?.court && agents) {
      const courts = response?.court?.map((item) => ({
        ...item,
        trusteeNames: item?.trusteeIds?.map((id) => agents?.find((agent) => agent?.id === id)?.name),
      })) as ICourt[];
      setCourts(convertCourtsToDataSource(courts));
      setAgents(agents);
    }
  }, []);

  const handleShowEditor = useCallback(
    (externalCourtId?: string) =>
      showModal(
        <CourtsEditor
          onOk={() => {
            requestCourts();
            closeModal();
          }}
          onCancel={closeModal}
          externalCourtId={externalCourtId}
        />
      ),
    [closeModal, requestCourts, showModal]
  );

  useEffect(() => {
    requestCourts();
  }, [requestCourts]);

  const columns = useMemo(() => {
    const sourceColumns = [
      {
        title: t.COURT_NUMBER,
        dataIndex: 'courtNumber',
        key: 'courtNumber',
        sorter: true,
        filterByName: true,
      },
      {
        title: t.COURT_NAME,
        dataIndex: 'courtName',
        key: 'courtName',
        sorter: true,
        filterByName: true,
      },
      {
        title: t.COURT_CITY,
        dataIndex: 'courtCity',
        key: 'courtCity',
        sorter: true,
        filterByName: true,
      },
      {
        title: t.TRUSTEE,
        dataIndex: 'trusteeIds',
        key: 'trusteeIds',
        render: (trusteeIds?: string[], record?: ICourt) => <>{convertArrayToReadable(record?.trusteeNames)}</>,
        filters: [...new Set(agents?.map((item) => item.name))]
          ?.filter((i) => i && Boolean(i?.trim()))
          ?.map((uniqueItem) => ({
            text: uniqueItem as string,
            value: uniqueItem as string,
          }))
          ?.sort((a, b) => a?.value?.localeCompare(b?.value)),
        onFilter: (value: any, record?: ICourt) =>
          record?.trusteeNames ? record?.trusteeNames?.indexOf(value) >= 0 : false,
      },
      {
        title: t.STATUS,
        dataIndex: 'status',
        key: 'status',
        sorter: true,
        render: (isActive?: boolean) => <StatusValue isActive={isActive} />,
        filters: [
          {
            text: t.ACTIVE,
            value: true,
          },
          {
            text: t.INACTIVE,
            value: false,
          },
        ],
        onFilter: (value: any, record: ClientService.CourtDetailsDto) => value === record.status,
      },
      {
        dataIndex: 'action',
        key: 'action',
        render: (_: any, record: ClientService.CourtDetailsDto) => (
          <Icon
            iconName="Edit"
            onClick={() => handleShowEditor(record?.externalCourtId)}
            className="UserManagement__edit-icon"
          />
        ),
      },
    ];

    return getSortableFilterableColumns(sourceColumns, dataSource);
  }, [agents, dataSource, handleShowEditor]);

  return (
    <Layout className="Courts">
      <Breadcrumb data={[{ title: t.ADMINISTRATION }, { title: t.COURTS }]} />
      <PageSubtitle title={t.COURTS} />
      <Row align="bottom" justify="space-between">
        <Col span={8}>
          <Search
            placeholder={t.SEARCH_BY_COURTNUMBER_COURTNAME_COURTCITY_OR_TRUSTEENAME}
            allowClear
            size="large"
            onSearch={setSearchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </Col>
        <Col>
          <Button onClick={() => handleShowEditor()}>{t.ASSIGN_TRUSTEE}</Button>
        </Col>
      </Row>
      <StyledTable
        rowKey="externalCourtId"
        dataSource={dataSource}
        columns={columns}
        total={dataSource?.length || 0}
        loading={loading}
      />
    </Layout>
  );
}

export default Courts;
