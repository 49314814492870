import { useCallback, useEffect, useState, useMemo } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useQuery } from 'react-query';
import { Col, Row, Form, Select, Spin } from 'antd';

import Button from '../../../../../components/Button/Button';
import RequestForReview from '../../../../../components/RequestForReview/RequestForReview';
import FormRow from './FormRow';
import ActionConfirmationModal from '../../../../../modals/ActionConfirmationModal/ActionConfirmationModal';
import DataItem from '../../../../../components/DataItem/DataItem';
import FilingHistoryModal from '../../../../../modals/FilingHistoryModal/FilingHistoryModal';
import FormFilingModal from './FormFilingModal/FormFilingModal';
import CourtRevivalModal from './CourtRevivalModal/CourtRevivalModal';
import AttachmentsTab, { AttachmentsFormProps, FileActionEnum } from '../../../../../components/AttachmentsTab';
import { UPLOAD_FILES_EVENT } from '../../../../../constants/eventBus';

import FilingDateModal from '../../../../../modals/FilingDateModal/FilingDateModal';
import addIcon from '../../../../../images/add_icon.svg';
import SelectSuffixIcon from '../../../../../components/SelectSuffixIcon/SelectSuffixIcon';

import useModal from '../../../../../hooks/useModal';
import useLocale from '../../../../../hooks/useLocale';

import {
  PROPOSAL_PROCESS_STATUSES_QUERY,
  PROPOSAL_STATES_QUERY,
  FORM56_HISTORY_QUERY,
  FORM93_HISTORY_QUERY,
  FORM95_HISTORY_QUERY,
  FORM96_HISTORY_QUERY,
  USE_QUERY_OPTIONS,
} from '../../../../../constants/reactQuery';
import {
  PROPOSAL_PROCESS_STATUS_DROPDOWN_OPTIONS,
  FOLDER_CHECKLIST,
  PREFIX_CHECKLIST,
  FOLDER_FORMS,
  PREFIX_FORM56,
  PREFIX_FORM93,
  PREFIX_FORM95,
  PREFIX_FORM96,
} from './constants';
import { DATE_FORMAT2 } from '../../../../../constants/common';
import { ProposalFormEnum } from './types';
import { ROUTES } from '../../../../../constants/routes';
import { ADMIN_INFO_CHANGE_EVENT } from '../../../../../constants/eventBus';
import { eventBus } from '../../../../../utils/eventBus';
import genericMessage from '../../../../../utils/genericMessage';
import { convertServerDateOnlyToInputDate } from '../../../../../utils/helpers';

import { ClientService } from '../../../../../shared/api/ClientService';
import API from '../../../../../utils/api';

import './Proposal.scss';

interface IForm extends ClientService.ProposalStatusUpdateDto, AttachmentsFormProps {}

interface IProps {
  disableAllFields?: boolean;
}

const Proposal = ({ disableAllFields }: IProps) => {
  const { t } = useLocale();
  const { Option } = Select;
  const { showModal, closeModal } = useModal();
  const [form] = Form.useForm<IForm>();
  const { applicationFileId } = useParams<{ applicationFileId: string }>();
  const navigate = useNavigate();

  const [proposalInfo, setProposalInfo] = useState<ClientService.ProposalInfoDto>();
  const [proposalInfoLoading, setProposalInfoLoading] = useState<boolean>(false);
  const [proposalStatusUpdating, setProposalStatusUpdating] = useState<boolean>(false);

  const [isAttachmentFormModal, setIsAttachmentFormModal] = useState<boolean>(false);

  const {
    data: form56History,
    refetch: refetchForm56History,
    isFetching: form56HistoryFetching,
  } = useQuery(
    [FORM56_HISTORY_QUERY, applicationFileId],
    () => API.getForm56History(applicationFileId),
    USE_QUERY_OPTIONS
  );

  const {
    data: form93History,
    refetch: refetchForm93History,
    isFetching: form93HistoryFetching,
  } = useQuery(
    [FORM93_HISTORY_QUERY, applicationFileId],
    () => API.getForm93History(applicationFileId),
    USE_QUERY_OPTIONS
  );

  const {
    data: form95History,
    refetch: refetchForm95History,
    isFetching: form95HistoryFetching,
  } = useQuery(
    [FORM95_HISTORY_QUERY, applicationFileId],
    () => API.getForm95History(applicationFileId),
    USE_QUERY_OPTIONS
  );

  const {
    data: form96History,
    refetch: refetchForm96History,
    isFetching: form96HistoryFetching,
  } = useQuery(
    [FORM96_HISTORY_QUERY, applicationFileId],
    () => API.getForm96History(applicationFileId),
    USE_QUERY_OPTIONS
  );

  const { data: proposalProcessStatuses } = useQuery(
    [PROPOSAL_PROCESS_STATUSES_QUERY],
    () => API.listProposalProcessStatuses(),
    USE_QUERY_OPTIONS
  );

  const { data: proposalStates } = useQuery([PROPOSAL_STATES_QUERY], () => API.listProposalStates(), USE_QUERY_OPTIONS);

  const requestProposalInfo = useCallback(async () => {
    setProposalInfoLoading(true);
    const response = await API.getProposalInfo(applicationFileId).catch(() => setProposalInfoLoading(false));
    if (response) {
      setProposalInfo(response);
      form.setFieldsValue(response);
    }
    setProposalInfoLoading(false);
  }, [applicationFileId, form]);

  const requestProposalStatusesUpdate = useCallback(async () => {
    setProposalStatusUpdating(true);
    const values = form.getFieldsValue();

    eventBus.dispatch(UPLOAD_FILES_EVENT);

    const response = await API.updateProposalStatuses({
      fileId: applicationFileId,
      proposalProcessStatusId: values?.proposalProcessStatusId,
      proposalStateId: values?.proposalStateId,
      paymentStatusId: values?.paymentStatusId,
    } as ClientService.ProposalStatusUpdateDto).catch(() => setProposalStatusUpdating(false));
    setProposalStatusUpdating(false);

    if (response?.result === ClientService.Result.Successful) {
      eventBus.dispatch(ADMIN_INFO_CHANGE_EVENT);
      await requestProposalInfo();
    }
  }, [applicationFileId, form, requestProposalInfo]);

  const closeAttachmentModal = useCallback(() => {
    closeModal();
    setIsAttachmentFormModal(false);
  }, [closeModal]);

  const closeModalAndRefresh = useCallback(() => {
    closeAttachmentModal();
    requestProposalInfo();
  }, [closeAttachmentModal, requestProposalInfo]);

  const getFormDetails = useCallback(
    (proposalFormEnum: ProposalFormEnum) => {
      switch (proposalFormEnum) {
        case ProposalFormEnum.Form56:
          return {
            uploadModalTitle: t.FORM_56_UPLOAD,
            historyModalTitle: t.FORM_56_FILING_HISTORY,
            filingDate: proposalInfo?.form56FilingDate,
            supportingFolderCode: FOLDER_FORMS,
            prefixCodes: [PREFIX_FORM56],
            formHistory: form56History,
            refetch: refetchForm56History,
          };
        case ProposalFormEnum.Form93:
          return {
            uploadModalTitle: t.FORM_93_FILING,
            historyModalTitle: t.FORM_93_FILING_HISTORY,
            filingDate: proposalInfo?.form93FilingDate,
            supportingFolderCode: FOLDER_FORMS,
            prefixCodes: [PREFIX_FORM93],
            formHistory: form93History,
            refetch: refetchForm93History,
          };
        case ProposalFormEnum.Form95:
          return {
            uploadModalTitle: t.FORM_95_FILING,
            historyModalTitle: t.FORM_95_FILING_HISTORY,
            filingDate: proposalInfo?.form95FilingDate,
            supportingFolderCode: FOLDER_FORMS,
            prefixCodes: [PREFIX_FORM95],
            formHistory: form95History,
            refetch: refetchForm95History,
          };
        case ProposalFormEnum.Form96:
          return {
            uploadModalTitle: t.FORM_96_FILING,
            historyModalTitle: t.FORM_96_FILING_HISTORY,
            filingDate: proposalInfo?.form96FilingDate,
            supportingFolderCode: FOLDER_FORMS,
            prefixCodes: [PREFIX_FORM96],
            formHistory: form96History,
            refetch: refetchForm96History,
          };
        case ProposalFormEnum.Checklist:
          return {
            uploadModalTitle: t.PROPOSAL_CHECKLIST,
            supportingFolderCode: FOLDER_CHECKLIST,
            prefixCodes: [PREFIX_CHECKLIST],
          };
        default:
          return;
      }
    },
    [
      form56History,
      form93History,
      form95History,
      form96History,
      proposalInfo?.form56FilingDate,
      proposalInfo?.form93FilingDate,
      proposalInfo?.form95FilingDate,
      proposalInfo?.form96FilingDate,
      refetchForm56History,
      refetchForm93History,
      refetchForm95History,
      refetchForm96History,
      t.FORM_56_FILING_HISTORY,
      t.FORM_56_UPLOAD,
      t.FORM_93_FILING,
      t.FORM_93_FILING_HISTORY,
      t.FORM_95_FILING,
      t.FORM_95_FILING_HISTORY,
      t.FORM_96_FILING,
      t.FORM_96_FILING_HISTORY,
      t.PROPOSAL_CHECKLIST,
    ]
  );

  const handleUploadForReview = useCallback(
    async (proposalFormEnum: ProposalFormEnum, onOk?: () => void, title?: string) => {
      const formDetails = getFormDetails(proposalFormEnum);

      setIsAttachmentFormModal(true);
      showModal(
        <RequestForReview
          title={title}
          applicationFileId={applicationFileId}
          supportingFolderCode={formDetails?.supportingFolderCode}
          prefixCodes={formDetails?.prefixCodes}
          newSupportingFileRowLayoutProps={{
            disabledPrefixInput: true,
            preselectedPrefixInput: true,
            disabledFoldersInput: true,
          }}
          isFlatLayout
          flatLayoutProps={{ hasExistingFiles: true, label: formDetails?.uploadModalTitle }}
          onOk={() => {
            if (formDetails?.refetch) formDetails?.refetch();
            closeModalAndRefresh();
            if (onOk) onOk();
          }}
          onCancel={closeAttachmentModal}
        />
      );
    },
    [getFormDetails, showModal, applicationFileId, closeAttachmentModal, closeModalAndRefresh]
  );

  const handleUpdateForm56FilingDate = useCallback(
    (documentId?: string) => {
      if (!documentId) return;

      setIsAttachmentFormModal(true);
      showModal(
        <FilingDateModal
          title={t.FORM_56_FILING}
          onOk={async (filingDate) => {
            const response = await API.updateForm56Filing({
              filingDate,
              fileId: applicationFileId,
              documentId,
            } as ClientService.ProposalFormFilingDto);
            closeAttachmentModal();

            if (response) {
              await requestProposalInfo();
              await refetchForm56History();
            }
          }}
          onCancel={closeAttachmentModal}
        />
      );
    },
    [applicationFileId, closeAttachmentModal, refetchForm56History, requestProposalInfo, showModal, t.FORM_56_FILING]
  );

  const handleUpdateCourtRevival = useCallback(
    (documentId?: string) => {
      if (!documentId) return;

      setIsAttachmentFormModal(true);
      showModal(
        <CourtRevivalModal
          onOk={closeModalAndRefresh}
          onCancel={closeModalAndRefresh}
          fileId={applicationFileId as string}
        />
      );
    },
    [applicationFileId, closeModalAndRefresh, showModal]
  );

  const handleForm56Upload = useCallback(() => {
    const isPendingStatus = Boolean(
      proposalProcessStatuses?.find((item) => item?.id === proposalInfo?.proposalProcessStatusId)?.enumValue ===
        ClientService.ProposalProcessStatusEnum.Pending
    );
    if (disableAllFields || isPendingStatus) return;

    if (!proposalInfo?.form56FilingDate && !proposalInfo?.form56DocumentId) {
      handleUploadForReview(ProposalFormEnum.Form56, refetchForm56History, t.FORM_56_UPLOAD);
      return;
    }

    if (proposalInfo?.form56DocumentId) {
      handleUpdateForm56FilingDate(proposalInfo?.form56DocumentId);
      return;
    }
  }, [
    disableAllFields,
    handleUpdateForm56FilingDate,
    handleUploadForReview,
    proposalInfo?.form56DocumentId,
    proposalInfo?.form56FilingDate,
    proposalInfo?.proposalProcessStatusId,
    proposalProcessStatuses,
    refetchForm56History,
    t.FORM_56_UPLOAD,
  ]);

  const handleViewHistory = useCallback(
    (proposalFormEnum: ProposalFormEnum) => {
      const formDetails = getFormDetails(proposalFormEnum);

      if (formDetails && formDetails?.formHistory?.length) {
        showModal(
          <FilingHistoryModal
            onClose={closeModal}
            title={formDetails?.historyModalTitle}
            data={formDetails?.formHistory}
          />
        );
      }
    },
    [closeModal, getFormDetails, showModal]
  );

  const handleUploadWithFilingDate = useCallback(
    (proposalFormEnum: ProposalFormEnum) => {
      if (disableAllFields || proposalInfo?.proposalProcessStatus === 'Pending') return;

      const data = getFormDetails(proposalFormEnum);
      if (!data) return;

      setIsAttachmentFormModal(true);
      showModal(
        <FormFilingModal
          onOk={() => {
            if (data?.refetch) data?.refetch();
            closeModalAndRefresh();
          }}
          onCancel={closeAttachmentModal}
          title={data?.uploadModalTitle}
          fileId={applicationFileId as string}
          prefixCodes={data?.prefixCodes}
          proposalFormEnum={proposalFormEnum}
          filingDate={proposalInfo?.form93FilingDate}
        />
      );
    },
    [
      applicationFileId,
      closeAttachmentModal,
      closeModalAndRefresh,
      disableAllFields,
      getFormDetails,
      proposalInfo?.form93FilingDate,
      proposalInfo?.proposalProcessStatus,
      showModal,
    ]
  );

  const handleProposalRejectedNotification = useCallback(() => {
    API.triggerProposalRejectedNotification(applicationFileId);
    requestProposalStatusesUpdate();
  }, [applicationFileId, requestProposalStatusesUpdate]);

  const handleProposalWithdrawnNotification = useCallback(() => {
    API.triggerProposalWithdrawnNotification(applicationFileId);
    requestProposalStatusesUpdate();
  }, [applicationFileId, requestProposalStatusesUpdate]);

  const handleProposalAcceptConfirmation = useCallback(() => {
    showModal(
      <ActionConfirmationModal
        message={t.YOU_ARE_ABOUT_TO_UPDATE_THE_PROPOSAL_AS_ACCEPTED}
        title={t.CONFIRM_PROPOSAL_STATE_CHANGE}
        onOk={() => {
          requestProposalStatusesUpdate();
          closeModal();
        }}
        onCancel={closeModal}
        okText={t.YES}
        cancelText={t.NO}
      />
    );
  }, [
    showModal,
    t.YOU_ARE_ABOUT_TO_UPDATE_THE_PROPOSAL_AS_ACCEPTED,
    t.CONFIRM_PROPOSAL_STATE_CHANGE,
    t.YES,
    t.NO,
    closeModal,
    requestProposalStatusesUpdate,
  ]);

  const handleNavigateToScheduling = useCallback(async () => {
    const response = await API.listAppointmentTypes(applicationFileId as string);

    if (response) {
      const fixedAppointmentTypeId = response?.find((item) => item?.code === 'meeting-with-creditors')?.id;
      const fixedAgentTeamNames = Boolean(fixedAppointmentTypeId) ? ['Proposal CPC', 'FLA'] : undefined;

      navigate(`${ROUTES.APPLICATION_OVERVIEW}/${applicationFileId}/${ROUTES.APPOINTMENT_SCHEDULING}`, {
        state: {
          shedulingComponentProps: { fixedAppointmentTypeId, fixedAgentTeamNames },
        },
      });
    }
  }, [applicationFileId, navigate]);

  const onFinish = useCallback(
    async (values) => {
      const proposalStateEnum = proposalStates?.find((item) => item.id === values?.proposalStateId)?.enumValue;
      const lastProposalStateEnum = proposalStates?.find(
        (item) => item.id === proposalInfo?.proposalStateId
      )?.enumValue;

      const proposalProcessStatusEnum = proposalProcessStatuses?.find(
        (item) => item.id === values?.proposalProcessStatusId
      )?.enumValue;

      const paymentStatusId = values?.paymentStatusId;
      const verifyDocument = values?.newFiles;
      const isCompleted = await API.validateMeetingWithCreditorsCompleted(applicationFileId);
      const hasAttachedChecklistDocument = await API.validateHasAttachedChecklistDocument(applicationFileId);
      const isAllLiabilityClaimsComplete = await API.validateIsAllLiabilityClaimsComplete(applicationFileId);
      let errors = [];

      if (proposalStateEnum === ClientService.ProposalStateEnum.Accepted) {
        genericMessage.warning(t.ENSURE_TO_CHECK_IF_THE_APPROVAL_STATUS_IN_ASCEND_IS_MEETING_REQUESTED);
      }

      if (proposalProcessStatusEnum === ClientService.ProposalProcessStatusEnum.Completed) {
        if (!isAllLiabilityClaimsComplete)
          errors.push(t.UNABLE_TO_PROCESS_YOUR_REQUEST_AS_THERE_IS_ONE_OR_MORE_CREDITORS_NOT_MARKED_AS_YES);

        if (!isCompleted)
          errors.push(
            t.UNABLE_TO_PROCESS_YOUR_REQUEST_AS_THERE_IS_ONE_OR_MORE_MEETING_WITH_CREDITOR_APPOINTMENTS_THAT_ARE_NOT_COMPLETE
          );

        if (proposalStateEnum == null)
          errors.push(t.UNABLE_TO_PROCESS_YOUR_REQUEST_AS_NO_VALUE_IS_SELECTED_FOR_PROPOSAL_STATE);

        if ((!hasAttachedChecklistDocument && verifyDocument == null) || verifyDocument?.length == 0) {
          errors.push(t.UNABLE_TO_PROCESS_YOUR_REQUEST_AS_THERE_IS_NO_CHECKLIST_DOCUMENT_ATTACHED);
        }
      }

      if (errors.length > 0) genericMessage.error({}, errors);

      if (proposalProcessStatusEnum === ClientService.ProposalProcessStatusEnum.InProgress) {
        if (proposalStateEnum != lastProposalStateEnum) {
          switch (proposalStateEnum) {
            case ClientService.ProposalStateEnum.Accepted:
              handleProposalAcceptConfirmation();
              break;
            case ClientService.ProposalStateEnum.Rejected:
              handleUploadForReview(
                ProposalFormEnum.Checklist,
                () => handleProposalRejectedNotification(),
                t.PROPOSAL_REJECTED_PROCESS
              );
              break;
            case ClientService.ProposalStateEnum.Withdrawn:
              handleUploadForReview(
                ProposalFormEnum.Checklist,
                () => handleProposalWithdrawnNotification(),
                t.PROPOSAL_WITHDRAWN_PROCESS
              );
              break;
            default:
              requestProposalStatusesUpdate();
          }
        } else {
          requestProposalStatusesUpdate();
        }
      } else {
        switch (proposalStateEnum) {
          case ClientService.ProposalStateEnum.Accepted:
            handleProposalAcceptConfirmation();
            break;
          case ClientService.ProposalStateEnum.Rejected:
            await handleUploadForReview(
              ProposalFormEnum.Checklist,
              () => API.triggerProposalRejectedNotification(applicationFileId),
              t.PROPOSAL_REJECTED_PROCESS
            );
            break;
          case ClientService.ProposalStateEnum.Withdrawn:
            await handleUploadForReview(
              ProposalFormEnum.Checklist,
              () => API.triggerProposalWithdrawnNotification(applicationFileId),
              t.PROPOSAL_WITHDRAWN_PROCESS
            );
            break;
          default:
            genericMessage.error({}, t.FORM_GENERIC_ERROR_SUBMITTING);
        }
      }
    },
    [
      proposalStates,
      applicationFileId,
      t.ENSURE_TO_CHECK_IF_THE_APPROVAL_STATUS_IN_ASCEND_IS_MEETING_REQUESTED,
      t.UNABLE_TO_PROCESS_YOUR_REQUEST_AS_THERE_IS_ONE_OR_MORE_MEETING_WITH_CREDITOR_APPOINTMENTS_THAT_ARE_NOT_COMPLETE,
      t.PROPOSAL_REJECTED_PROCESS,
      t.PROPOSAL_WITHDRAWN_PROCESS,
      t.UNABLE_TO_PROCESS_YOUR_REQUEST_AS_NO_VALUE_IS_SELECTED_FOR_PROPOSAL_STATE,
      handleProposalAcceptConfirmation,
      handleUploadForReview,
      proposalInfo,
    ]
  );

  const proposalProcessStatusesOptions = useMemo(() => {
    const proposalProcessStatusEnum = proposalProcessStatuses?.find(
      (item) => item.id === proposalInfo?.proposalProcessStatusId
    )?.enumValue;

    const options = PROPOSAL_PROCESS_STATUS_DROPDOWN_OPTIONS?.map((item) => ({
      ...item,
      ...proposalProcessStatuses?.find((status) => status.enumValue === item.enumValue),
    }));

    switch (proposalProcessStatusEnum) {
      case ClientService.ProposalProcessStatusEnum.Pending:
        return options?.splice(0, 1);
      case ClientService.ProposalProcessStatusEnum.InProgress:
        return options?.splice(1, 2);
      case ClientService.ProposalProcessStatusEnum.Completed:
        return options?.splice(2, 1);
      default:
        return options?.splice(0, 1);
    }
  }, [proposalInfo?.proposalProcessStatusId, proposalProcessStatuses]);

  const renderChecklist = useCallback(
    (proposalFormEnum: ProposalFormEnum) => {
      const formDetails = getFormDetails(proposalFormEnum);
      return (
        <div style={{ maxHeight: 600, overflowY: 'auto' }}>
          <AttachmentsTab
            form={form}
            applicationFileId={applicationFileId}
            supportingFolderCode={formDetails?.supportingFolderCode}
            prefixCodes={formDetails?.prefixCodes}
            newSupportingFileRowLayoutProps={{
              disabledPrefixInput: true,
              preselectedPrefixInput: true,
              disabledFoldersInput: true,
            }}
            isFlatLayout
            flatLayoutProps={{ hasExistingFiles: true, label: formDetails?.uploadModalTitle }}
            draggerProps={{
              mode: FileActionEnum.Upload,
              disabled: disableAllFields || proposalInfo?.proposalProcessStatus === 'Pending',
            }}
          />
        </div>
      );
    },
    [applicationFileId, disableAllFields, form, getFormDetails, proposalInfo]
  );

  useEffect(() => {
    requestProposalInfo();
  }, [requestProposalInfo]);

  return (
    <div className="Proposal">
      <div className="Proposal__section-header">{t.PROPOSAL}</div>
      <Spin spinning={proposalInfoLoading || proposalStatusUpdating}>
        <Row className="Proposal__info-row">
          <DataItem
            colSpan={8}
            label={t.INSOLVENCY}
            value={convertServerDateOnlyToInputDate(proposalInfo?.insolvencyDate)?.format(DATE_FORMAT2) || '-'}
          />
          <DataItem colSpan={8} label={t.NO_OF_WARNINGS} value={proposalInfo?.numWarnings || 0} />
          <DataItem
            colSpan={8}
            label={t.PROPOSAL_STATUS}
            value={{ value: proposalInfo?.proposalStatus || '-', color: 'dark-gray', bold: true }}
          />
        </Row>

        {proposalInfo?.hasBeenCourtRevival && (
          <FormRow
            label={t.COURT_REVIVAL}
            onClickUpload={handleUpdateCourtRevival}
            isRevived={proposalInfo?.courtRevived ?? false}
          />
        )}

        <Form layout="vertical" form={form} onFinish={onFinish} name="Proposal" className="Proposal__form-container">
          <Row align="bottom" gutter={20}>
            <Col span={8}>
              <Form.Item
                name="proposalProcessStatusId"
                label={t.PROPOSAL_PROCESS_STATUS}
                rules={[{ required: true, message: t.REQUIRED_FIELD }]}
                required
              >
                <Select
                  placeholder={t.SELECT}
                  size="large"
                  suffixIcon={<SelectSuffixIcon />}
                  disabled={disableAllFields || proposalInfo?.proposalProcessStatus === 'Pending'}
                >
                  {proposalProcessStatusesOptions?.map((item) => (
                    <Option key={item.id} id={item.id} disabled={item.disabled}>
                      {item.name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item name="proposalStateId" label={t.PROPOSAL_STATE}>
                <Select
                  placeholder={t.SELECT}
                  size="large"
                  suffixIcon={<SelectSuffixIcon />}
                  disabled={disableAllFields || proposalInfo?.proposalProcessStatus === 'Pending'}
                >
                  {proposalStates?.map((item) => (
                    <Option key={item.id} id={item.id}>
                      {item.name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item name="paymentStatusId" label={t.PAYMENT_STATUS}>
                <Select
                  placeholder={t.SELECT}
                  size="large"
                  suffixIcon={<SelectSuffixIcon />}
                  disabled={disableAllFields || proposalInfo?.proposalProcessStatus === 'Pending'}
                >
                  {proposalInfo?.paymentStatusOptions?.map((item) => (
                    <Option key={item.id} id={item.id}>
                      {item.name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item shouldUpdate noStyle>
                {!isAttachmentFormModal && renderChecklist(ProposalFormEnum.Checklist)}
              </Form.Item>
            </Col>
          </Row>

          <Row justify="end">
            <Col>
              <Form.Item>
                <Button kind="primary" htmlType="submit" disabled={disableAllFields}>
                  {t.SAVE}
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Spin>

      <Spin spinning={form56HistoryFetching || form93HistoryFetching || form95HistoryFetching || form96HistoryFetching}>
        {(proposalInfo?.hasBeenDeemedAnnulled ||
          proposalInfo?.hasBeenRevivalWarranted ||
          proposalInfo?.hasBeenOpposed ||
          proposalInfo?.hasBeenAutoRevival) && <div className="Proposal__section-header">{t.FORMS}</div>}
        {proposalInfo?.hasBeenDeemedAnnulled && (
          <FormRow
            label={t.NOTICE_DEEMED_ANNULEMENT}
            date={proposalInfo?.form56FilingDate}
            onClickUpload={handleForm56Upload}
            onClickHistory={() => handleViewHistory(ProposalFormEnum.Form56)}
            isHistoryIcon={Boolean(form56History?.length)}
            isUploaded={Boolean(proposalInfo?.form56DocumentId)}
          />
        )}
        {proposalInfo?.hasBeenRevivalWarranted && (
          <FormRow
            label={t.NOTICE_AUTOMATED_REVIVAL}
            date={proposalInfo?.form93FilingDate}
            onClickUpload={() => handleUploadWithFilingDate(ProposalFormEnum.Form93)}
            onClickHistory={() => handleViewHistory(ProposalFormEnum.Form93)}
            isHistoryIcon={Boolean(form93History?.length)}
            isUploaded={Boolean(proposalInfo?.form93FilingDate)}
          />
        )}
        {proposalInfo?.hasBeenOpposed && (
          <FormRow
            label={t.NOTICE_NO_AUTOMATIC_REVIVAL}
            date={proposalInfo?.form95FilingDate}
            onClickUpload={() => handleUploadWithFilingDate(ProposalFormEnum.Form95)}
            onClickHistory={() => handleViewHistory(ProposalFormEnum.Form95)}
            isHistoryIcon={Boolean(form95History?.length)}
            isUploaded={Boolean(proposalInfo?.form95FilingDate)}
          />
        )}
        {proposalInfo?.hasBeenAutoRevival && (
          <FormRow
            label={t.NOTICE_REVIVAL}
            date={proposalInfo?.form96FilingDate}
            onClickUpload={() => handleUploadWithFilingDate(ProposalFormEnum.Form96)}
            onClickHistory={() => handleViewHistory(ProposalFormEnum.Form96)}
            isHistoryIcon={Boolean(form96History?.length)}
            isUploaded={Boolean(proposalInfo?.form96FilingDate)}
          />
        )}
      </Spin>

      <Row align="middle" justify="space-between">
        <Col>
          <div className="Proposal__section-header">{t.MEETING_WITH_CREDITORS}</div>
        </Col>
        <Col>
          <Row gutter={6} className="Proposal__add-appointment" onClick={handleNavigateToScheduling}>
            <Col>{t.ADD_APPOINTMENT}</Col>
            <Col>
              <img src={addIcon} alt="add-icon" width={15} height={15} />
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
};

export default Proposal;
