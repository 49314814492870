import { forwardRef, ForwardedRef } from 'react';
import SaveIcon from './SaveIcon';

interface IProps {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  handleSave: () => void;
  handleSaveOptimized: () => void;
}
const SaveButton = forwardRef(
  ({ isOpen, setIsOpen, handleSave, handleSaveOptimized }: IProps, ref: ForwardedRef<HTMLDivElement>) => {
    return (
      <button
        className="Button ActionButton"
        onClick={() => setIsOpen(!isOpen)}
        style={{ position: 'relative', marginLeft: 10 }}
      >
        <div className="Icon">
          <SaveIcon />
        </div>
        {isOpen && (
          <div className="Overlay FlyoutMenu" ref={ref} style={{ position: 'absolute', top: 32, right: 0 }}>
            <button
              className="Button ActionButton row"
              style={{ flexWrap: 'nowrap', whiteSpace: 'nowrap', justifyContent: 'left' }}
              onClick={handleSave}
            >
              Save PDF
            </button>
            <button
              className="Button ActionButton row"
              style={{ flexWrap: 'nowrap', whiteSpace: 'nowrap', justifyContent: 'left' }}
              onClick={handleSaveOptimized}
            >
              Save Optimized PDF
            </button>
          </div>
        )}
      </button>
    );
  }
);

export default SaveButton;
