import { useCallback, useEffect, useState } from 'react';
import { Row, Col } from 'antd';
import AppForm, { CustomFormPropsAPISegments } from '../../../../../../components/Forms/AppForm/AppForm';
import FormSaveButton from '../../../../../../components/Button/AppButtons/FormSaveButton';
import FormInput from '../../../../../../components/Forms/FormInput/FormInput';
import { ClientService } from '../../../../../../shared/api/ClientService';

import styles from './ProcessStatusSelection.module.scss';

interface IOption {
  label: string;
  value: ClientService.FileProcessStatusEnum;
}

const processStatusOptions: IOption[] = [
  { label: 'Pending', value: ClientService.FileProcessStatusEnum.Pending },
  { label: 'In Progress', value: ClientService.FileProcessStatusEnum.InProgress },
  { label: 'Complete', value: ClientService.FileProcessStatusEnum.Complete },
];

interface ProcessStatusSelectionProps extends CustomFormPropsAPISegments {
  label: string;
  status?: ClientService.FileProcessStatusEnum;
  allItemsCompleted?: boolean;
  isMigrated?: boolean;
  skipAllCompletedIfMigrated?: boolean;
}

const ProcessStatusSelection = ({
  label,
  status = ClientService.FileProcessStatusEnum.Pending,
  allItemsCompleted = false,
  isMigrated = false,
  skipAllCompletedIfMigrated = false,
  disableAllFields,
  ...props
}: ProcessStatusSelectionProps): JSX.Element => {
  const [form] = AppForm.AntD.useForm<any>();

  const [options, setOptions] = useState<IOption[] | undefined>();

  useEffect(() => {
    if (status == null || status === ClientService.FileProcessStatusEnum.Pending) {
      setOptions(
        processStatusOptions.filter((option) => {
          return option.value === ClientService.FileProcessStatusEnum.Pending;
        })
      );
    } else if (status === ClientService.FileProcessStatusEnum.InProgress && isMigrated && skipAllCompletedIfMigrated) {
      setOptions(
        processStatusOptions.filter((option) => {
          return option.value !== ClientService.FileProcessStatusEnum.Pending;
        })
      );
    } else if (status === ClientService.FileProcessStatusEnum.InProgress && allItemsCompleted) {
      setOptions(
        processStatusOptions.filter((option) => {
          return option.value !== ClientService.FileProcessStatusEnum.Pending;
        })
      );
    } else if (status === ClientService.FileProcessStatusEnum.Complete) {
      setOptions(
        processStatusOptions.filter((option) => {
          return option.value === ClientService.FileProcessStatusEnum.Complete;
        })
      );
    } else {
      setOptions(
        processStatusOptions.filter((option) => {
          return option.value === ClientService.FileProcessStatusEnum.InProgress;
        })
      );
    }
  }, [status, allItemsCompleted, isMigrated, skipAllCompletedIfMigrated]);

  const setInitialValue = useCallback(() => {
    if (status) {
      form.setFieldsValue({
        processStatus: status,
      });
    }
  }, [form, status]);

  useEffect(() => {
    setInitialValue();
  }, [setInitialValue]);

  return (
    <AppForm form={form} disableAllFields={disableAllFields} {...props}>
      <Row gutter={20}>
        <Col span={8}>
          <div className={styles.input_and_save_container}>
            <div className={styles.status_input}>
              <FormInput type="select" noSelectOption name="processStatus" label={label} optionsList={options} />
            </div>
            <div className={styles.save_button_container}>
              <FormSaveButton narrow padding="medium" disabled={disableAllFields} />
            </div>
          </div>
        </Col>
      </Row>
    </AppForm>
  );
};

export default ProcessStatusSelection;
