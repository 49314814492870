import * as React from 'react';

import { BrowserRouter, Routes, Route } from 'react-router-dom';
import ContextProviders from '../../context/ContextProviders';
import LandingPage from '../../pages/LandingPage/LandingPage';
import NotFoundPage from '../../pages/NotFoundPage';
import NewProspectPage from '../../pages/NewProspectPage/NewProspectPage';
import DebtorProfilePage from '../../pages/DebtorProfilePage/DebtorProfilePage';
import DebtorSearchPage from '../../pages/DebtorSearchPage/DebtorSearchPage';
import { ROUTES } from '../../constants/routes';
import Modal from '../../modals/Modal/Modal';
import { LogoutAction } from '../Auth/LogoutAction';
import UserInfoPage from '../../pages/UserInfoPage';
import AuthorizeRoute from '../Auth/AuthorizeRoute';
import { LoginAction } from '../Auth/LoginAction';
import { LoginActions, LogoutActions } from '../../constants/auth';
import DebtorProfileContent from '../../pages/DebtorProfilePage/DebtorProfileContent/DebtorProfileContent';
import ReceiptTable from '../../pages/ApplicationOverviewPage/ReceiptContent/ReceiptTable';
import ReceiptEditor from '../../pages/ApplicationOverviewPage/ReceiptContent/ReceiptEditor';
import EmailTable from '../../pages/ApplicationOverviewPage/EmailContent/EmailTable/EmailTable';
import EmailView from '../../pages/ApplicationOverviewPage/EmailContent/EmailView/EmailView';
import SMSTable from '../../pages/ApplicationOverviewPage/SMSContent/SMSTable';
import SMSView from '../../pages/ApplicationOverviewPage/SMSContent/SMSView';

import ApplicationOverviewPage from '../../pages/ApplicationOverviewPage/ApplicationOverviewPage';
import ApplicationOverviewContent from '../../pages/ApplicationOverviewPage/ApplicationOverviewContent/ApplicationOverviewContent';
import AppointmentScheduling from '../../pages/ApplicationOverviewPage/AppointmentScheduling/AppointmentScheduling';
import AppointmentsHistory from '../../pages/ApplicationOverviewPage/AppointmentsHistory/AppointmentsHistory';
import NotesHistory from '../../pages/ApplicationOverviewPage/NotesContent/NotesHistory/NotesHistory';
import TaskActionTable from '../../pages/ApplicationOverviewPage/TasksContent/TaskActionTable/TaskActionTable';
import DocumentsContent from '../../pages/ApplicationOverviewPage/DocumentsContent/DocumentsContent';
import CreditReport from '../../pages/ApplicationOverviewPage/CreditReportContent/CreditReport';
import CreditReportHistory from '../../pages/ApplicationOverviewPage/CreditReportContent/CreditReportHistory';
import RequestCreditReport from '../../pages/ApplicationOverviewPage/CreditReportContent/RequestCreditReport';
import FileAnalysis from '../../pages/ApplicationOverviewPage/FileAnalysis/FileAnalysis';
import UserProfile from '../../pages/UserProfile/UserProfile';
import DebtorConversionReport from '../../pages/DebtorConversionReport/DebtorConversionReport';
import MarketingReport from '../../pages/MarketingReport/MarketingReport';

import FileAnalysisConfiguration from '../../pages/AdministrationPage/FileAnalysisConfiguration/FileAnalysisConfiguration';
import Templates from '../../pages/AdministrationPage/Templates/Templates';
import UserManagement from '../../pages/AdministrationPage/UserManagement/UserManagement';
import Holidays from '../../pages/AdministrationPage/Holidays/Holidays';
import DocumentPrefix from '../../pages/AdministrationPage/DocumentPrefix/DocumentPrefix';
import Courts from '../../pages/AdministrationPage/Courts/Courts';
import Allocations from '../../pages/AdministrationPage/Allocations/Allocations';
import NewClaimPage from '../../pages/NewClaimPage/NewClaimPage';
import ViewClaimPage from '../../pages/ViewClaimPage/ViewClaimPage';
import NewIncomeAndExpensePage from '../../pages/NewIncomeAndExpensePage/NewIncomeAndExpensePage';
import ViewIncomeAndExpensePage from '../../pages/ViewIncomeAndExpensePage/ViewIncomeAndExpensePage';
import NewTaxReturnPage from '../../pages/NewTaxReturnPage/NewTaxReturnPage';
import ViewTaxReturnPage from '../../pages/ViewTaxReturnPage/ViewTaxReturnPage';
import PreviewFileArray from '../../pages/ApplicationOverviewPage/NewAndViewPageComponents/PreviewFileArray/PreviewFileArray';
import OfficeLocations from '../../pages/AdministrationPage/OfficeLocations/OfficeLocations';

const App = (): JSX.Element => {
  return (
    <ContextProviders>
      <BrowserRouter>
        <Routes>
          <Route path="/signin-oidc" element={<LoginAction action={LoginActions.LoginCallback} />} />
          <Route path="/logout" element={<LogoutAction action={LogoutActions.Logout} />} />
          <Route path="/logout/callback" element={<LogoutAction action={LogoutActions.LogoutCallback} />} />
          {/* 
          <Route path="/:lng(en|es|de|fr|pt|it)/register/:form?" element={<Register />} />
          <Route path="/silentrenew" element={<SilentRenew />} /> 
          */}
          <Route
            index
            element={
              <AuthorizeRoute>
                {' '}
                <LandingPage />{' '}
              </AuthorizeRoute>
            }
          />
          <Route
            path={ROUTES.NEW_PROSPECT}
            element={
              <AuthorizeRoute>
                <NewProspectPage />
              </AuthorizeRoute>
            }
          />
          <Route
            path={`${ROUTES.APPLICATION_OVERVIEW}/:applicationFileId`}
            element={
              <AuthorizeRoute>
                <ApplicationOverviewPage />
              </AuthorizeRoute>
            }
          >
            <Route
              path=""
              element={
                <AuthorizeRoute>
                  <ApplicationOverviewContent />
                </AuthorizeRoute>
              }
            />
            <Route
              path={ROUTES.APPOINTMENT_SCHEDULING}
              element={
                <AuthorizeRoute>
                  <AppointmentScheduling />
                </AuthorizeRoute>
              }
            />
            <Route
              path={`${ROUTES.APPOINTMENT_SCHEDULING}/:appointmentId`}
              element={
                <AuthorizeRoute>
                  <AppointmentScheduling />
                </AuthorizeRoute>
              }
            />
            <Route
              path={ROUTES.APPOINTMENTS}
              element={
                <AuthorizeRoute>
                  <AppointmentsHistory />
                </AuthorizeRoute>
              }
            />
            <Route
              path={`${ROUTES.EMAIL_HISTORY}`}
              element={
                <AuthorizeRoute>
                  <EmailTable />
                </AuthorizeRoute>
              }
            />
            <Route
              path={`${ROUTES.EMAIL}/:emailId`}
              element={
                <AuthorizeRoute>
                  <EmailView />
                </AuthorizeRoute>
              }
            />

            <Route
              path={`${ROUTES.SMS_HISTORY}`}
              element={
                <AuthorizeRoute>
                  <SMSTable />
                </AuthorizeRoute>
              }
            />
            <Route
              path={`${ROUTES.SMS}/:smsId`}
              element={
                <AuthorizeRoute>
                  <SMSView />
                </AuthorizeRoute>
              }
            />
            <Route
              path={`${ROUTES.NOTES_HISTORY}`}
              element={
                <AuthorizeRoute>
                  <NotesHistory />
                </AuthorizeRoute>
              }
            />
            <Route
              path={ROUTES.RECEIPTS}
              element={
                <AuthorizeRoute>
                  <ReceiptTable />
                </AuthorizeRoute>
              }
            />

            <Route
              path={`${ROUTES.ADD_RECEIPT}`}
              element={
                <AuthorizeRoute>
                  <ReceiptEditor />
                </AuthorizeRoute>
              }
            />
            <Route
              path={`${ROUTES.RECEIPTS}/:receiptId`}
              element={
                <AuthorizeRoute>
                  <ReceiptEditor />
                </AuthorizeRoute>
              }
            />
            <Route
              path={`${ROUTES.TASKS_HISTORY}`}
              element={
                <AuthorizeRoute>
                  <TaskActionTable />
                </AuthorizeRoute>
              }
            />

            <Route
              path={ROUTES.REQUEST_CREDIT_REPORT}
              element={
                <AuthorizeRoute>
                  <RequestCreditReport />
                </AuthorizeRoute>
              }
            />

            <Route
              path={`${ROUTES.CREDIT_REPORT}/:reportId`}
              element={
                <AuthorizeRoute>
                  <CreditReport />
                </AuthorizeRoute>
              }
            />

            <Route
              path={ROUTES.CREDIT_REPORT_HISTORY}
              element={
                <AuthorizeRoute>
                  <CreditReportHistory />
                </AuthorizeRoute>
              }
            />
            {/* <Route
              path={`${ROUTES.VIEW_INCOME_AND_EXPENSE}`}
              // path={`${ROUTES.VIEW_INCOME_AND_EXPENSE}/:submissionId`}
              // path={`${ROUTES.VIEW_INCOME_AND_EXPENSE}`}
              element={
                <AuthorizeRoute>
                  <ViewIncomeAndExpensePage />
                </AuthorizeRoute>
              }
            /> */}
          </Route>
          <Route
            path={`${ROUTES.DEBTOR_PROFILE}/:uuid`}
            element={
              <AuthorizeRoute>
                <DebtorProfilePage />
              </AuthorizeRoute>
            }
          >
            <Route
              path=""
              element={
                <AuthorizeRoute>
                  <DebtorProfileContent />
                </AuthorizeRoute>
              }
            />
          </Route>
          <Route
            path={ROUTES.DEBTOR_SEARCH}
            element={
              <AuthorizeRoute>
                <DebtorSearchPage />
              </AuthorizeRoute>
            }
          />
          <Route
            path="/user-info"
            element={
              <AuthorizeRoute>
                <UserInfoPage />
              </AuthorizeRoute>
            }
          />
          <Route path={ROUTES.ADMINISTRATION}>
            <Route
              path={ROUTES.FILE_ANALYSIS_CONFIGURATION}
              element={
                <AuthorizeRoute>
                  <FileAnalysisConfiguration />
                </AuthorizeRoute>
              }
            />
            <Route
              path={ROUTES.TEMPLATES}
              element={
                <AuthorizeRoute>
                  <Templates />
                </AuthorizeRoute>
              }
            />
            <Route
              path={ROUTES.USER_MANAGEMENT}
              element={
                <AuthorizeRoute>
                  <UserManagement />
                </AuthorizeRoute>
              }
            />
            <Route
              path={ROUTES.HOLIDAYS}
              element={
                <AuthorizeRoute>
                  <Holidays />
                </AuthorizeRoute>
              }
            />
            <Route
              path={ROUTES.OFFICE_LOCATIONS}
              element={
                <AuthorizeRoute>
                  <OfficeLocations />
                </AuthorizeRoute>
              }
            />
            <Route
              path={ROUTES.DOCUMENT_PREFIX}
              element={
                <AuthorizeRoute>
                  <DocumentPrefix />
                </AuthorizeRoute>
              }
            />
            <Route
              path={ROUTES.COURTS}
              element={
                <AuthorizeRoute>
                  <Courts />
                </AuthorizeRoute>
              }
            />
            <Route
              path={ROUTES.ALLOCATIONS}
              element={
                <AuthorizeRoute>
                  <Allocations />
                </AuthorizeRoute>
              }
            />
          </Route>
          <Route
            path={`${ROUTES.USER_PROFILE}`}
            element={
              <AuthorizeRoute>
                <UserProfile />
              </AuthorizeRoute>
            }
          />
          <Route path={ROUTES.REPORTS}>
            <Route
              path={ROUTES.DEBTOR_CONVERTION_REPORT}
              element={
                <AuthorizeRoute>
                  <DebtorConversionReport />
                </AuthorizeRoute>
              }
            />
            <Route
              path={ROUTES.MARKETING_REPORT}
              element={
                <AuthorizeRoute>
                  <MarketingReport />
                </AuthorizeRoute>
              }
            />
          </Route>
          /* ADMIN INFO - NEW TASK ACTION PAGES */
          <Route path={`${ROUTES.TASK_ACTIONS}`}>
            <Route
              path={`${ROUTES.NEW_CLAIM}/:taskActionId`}
              element={
                <AuthorizeRoute>
                  <NewClaimPage />
                </AuthorizeRoute>
              }
            />
            <Route
              path={`${ROUTES.NEW_INCOME_EXPENSE}/:taskActionId`}
              element={
                <AuthorizeRoute>
                  <NewIncomeAndExpensePage />
                </AuthorizeRoute>
              }
            />
            <Route
              path={`${ROUTES.NEW_TAX_RETURN}/:taskActionId`}
              element={
                <AuthorizeRoute>
                  <NewTaxReturnPage />
                </AuthorizeRoute>
              }
            />
          </Route>
          /* ADMIN INFO - VIEW RECORD PAGES */
          <Route path={`${ROUTES.APPLICATION_OVERVIEW}/:applicationFileId/`}>
            <Route
              path={`${ROUTES.CLAIMS}/:claimFileId`}
              element={
                <AuthorizeRoute>
                  <ViewClaimPage />
                </AuthorizeRoute>
              }
            />
            <Route
              path={`${ROUTES.INCOME_AND_EXPENSE}/:submissionId`}
              element={
                <AuthorizeRoute>
                  <ViewIncomeAndExpensePage />
                </AuthorizeRoute>
              }
            />
            <Route
              path={`${ROUTES.TAX_RETURN}/:submissionId`}
              element={
                <AuthorizeRoute>
                  <ViewTaxReturnPage />
                </AuthorizeRoute>
              }
            />
          </Route>
          <Route
            path={ROUTES.FILE_PREVIEW}
            element={
              <AuthorizeRoute>
                <PreviewFileArray useAsPage />
              </AuthorizeRoute>
            }
          />
          <Route path={ROUTES.NOT_FOUND} element={<NotFoundPage />} />
        </Routes>
        <Modal />
      </BrowserRouter>
    </ContextProviders>
  );
};

export default App;
