import { Table, TableProps, Spin, ConfigProvider } from 'antd';

import { paginationShowTotal } from '../../../../utils/helpers';
import { DEFAULT_PAGE_SIZE } from '../../../../constants/common';

import './StyledTable.scss';

interface IProps extends TableProps<any> {
  total?: number;
  loading?: boolean;
}

const StyledTable = ({ total, loading = false, ...props }: IProps) => (
  <Spin spinning={loading}>
    <ConfigProvider
      getPopupContainer={(node?: HTMLElement) => {
        if (node) {
          return node.parentElement as HTMLElement;
        }
        return document.body;
      }}
    >
      <Table
        pagination={{
          position: ['bottomRight'],
          defaultPageSize: DEFAULT_PAGE_SIZE,
          showSizeChanger: true,
          total,
          showTotal: paginationShowTotal,
        }}
        className="StyledTable"
        {...props}
      />
    </ConfigProvider>
  </Spin>
);

export default StyledTable;
