import { useCallback } from 'react';
import { Col, Row } from 'antd';

import Button from '../../components/Button/Button';

import './PageSubtitle.scss';

interface IProps {
  title?: string | JSX.Element | Array<JSX.Element>;
  subtitle?: string;
  buttonTitle?: string | false;
  onButtonClick?: () => void;
  leftSideComponent?: JSX.Element;
}

function PageSubtitle({ title, subtitle, buttonTitle, onButtonClick, leftSideComponent }: IProps): JSX.Element {
  const handleClick = useCallback(() => {
    if (onButtonClick) onButtonClick();
  }, [onButtonClick]);

  return (
    <Row className="PageSubtitle" align="middle" justify="space-between">
      <Col>
        <div className="PageSubtitle__title">{title}</div>
        <div>{subtitle}</div>
      </Col>
      <Col className="PageSubtitle__inner-rightside-container">
        {buttonTitle && !leftSideComponent && (
          <Button narrow padding="medium" onClick={handleClick}>
            {buttonTitle}
          </Button>
        )}
        {leftSideComponent}
      </Col>
    </Row>
  );
}

export default PageSubtitle;
