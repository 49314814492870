import Icon from '@ant-design/icons';
import type { CustomIconComponentProps } from '@ant-design/icons/lib/components/Icon';

const LocationSVGComponent = () => (
  <svg width="18" height="18" viewBox="0 0 24 24">
    <path
      fill="currentColor"
      d="m11.92381,1.08051l-0.03522,0c-4.26106,0 -7.72758,3.05847 -7.72758,6.81779c0,2.46422 1.27155,5.7164 3.7793,9.66635c1.85924,2.92837 3.74455,5.17117 3.76344,5.19346c0.05248,0.06221 0.13519,0.09887 0.22284,0.09887c0.00249,0 0.00504,0 0.00753,-0.0001c0.09042,-0.0022 0.17389,-0.04304 0.22405,-0.10955c0.01859,-0.02485 1.89046,-2.51969 3.73708,-5.57115c2.49247,-4.11861 3.75626,-7.24022 3.75626,-9.27787c-0.00018,-3.75937 -3.46676,-6.81779 -7.7277,-6.81779l0,0.00001zm3.56696,6.98439c0,1.74451 -1.60803,3.16368 -3.58463,3.16368c-1.97654,0 -3.58457,-1.41922 -3.58457,-3.16368s1.60803,-3.16368 3.58457,-3.16368c1.97659,0 3.58463,1.41922 3.58463,3.16368z"
    />
  </svg>
);

const LocationIcon = (props: Partial<CustomIconComponentProps>) => <Icon component={LocationSVGComponent} {...props} />;

export default LocationIcon;
