import { Icon } from '@fluentui/react/lib/Icon';

import '../DebtorProfileContent.scss';

interface IProps {
  title?: string;
  hasErrors?: boolean;
}
const PanelHeader = ({ title, hasErrors }: IProps) => (
  <div className="DebtorProfileContent__panel-header">
    {title}
    {hasErrors && <Icon iconName="AlertSolid" className="DebtorProfileContent__panel-header--icon" />}
  </div>
);

export default PanelHeader;
