import { useCallback, useEffect, useState, useMemo } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Menu, Drawer } from 'antd';
import {
  HomeOutlined,
  SettingOutlined,
  FileProtectOutlined,
  SearchOutlined,
  CloseOutlined,
  MenuOutlined,
  UserOutlined,
} from '@ant-design/icons';

import ActionConfirmationModal from '../../modals/ActionConfirmationModal/ActionConfirmationModal';

import { ROUTES } from '../../constants/routes';
import useUnsaved from '../../hooks/useUnsaved';
import useModal from '../../hooks/useModal';
import useLocale from '../../hooks/useLocale';
import useMenu from '../../hooks/useMenu';

import './LeftMenu.scss';
import styles from '../../styles/style.module.scss';

const COLLAPSE_KEY = 'collapse-key';

const LeftMenu = () => {
  const { t } = useLocale();
  const navigate = useNavigate();
  const location = useLocation();
  const { isUnsavedForm, setIsUnsavedForm } = useUnsaved();
  const { showModal, closeModal } = useModal();
  const { collapsed, openMenu, closeMenu, isSuperAdmin, isSupervisorAllocation } = useMenu();

  const [isOpenSecondLevel, setIsOpenSecondLevel] = useState<boolean>(false);
  const [firstLevelKey, setFirstLevelKey] = useState<string>();
  const [secondLevelKey, setSecondLevelKey] = useState<string>();

  const handleNavigate = useCallback(
    (route: string) => {
      if (!isUnsavedForm) {
        navigate(route);
      } else {
        showModal(
          <ActionConfirmationModal
            title={t.UNSAVED_CHANGED_TITLE}
            message={t.UNSAVED_CHANGED_MESSAGE}
            onOk={() => {
              setIsUnsavedForm(false);
              closeModal();
              navigate(route);
            }}
            onCancel={closeModal}
          />
        );
      }
    },
    [
      closeModal,
      isUnsavedForm,
      navigate,
      setIsUnsavedForm,
      showModal,
      t.UNSAVED_CHANGED_MESSAGE,
      t.UNSAVED_CHANGED_TITLE,
    ]
  );

  const handleFirstLevelMenuSelect = useCallback(
    ({ key }) => {
      if (key === COLLAPSE_KEY) {
        return collapsed ? openMenu() : closeMenu();
      }

      if (isOpenSecondLevel && key !== ROUTES.ADMINISTRATION && key !== ROUTES.REPORTS) {
        setIsOpenSecondLevel(false);
        setFirstLevelKey(key);
        setSecondLevelKey(undefined);
      }

      if (key === ROUTES.ADMINISTRATION || key === ROUTES.REPORTS || key === ROUTES.PROFILE) {
        setIsOpenSecondLevel(true);
        setFirstLevelKey(key);
        return;
      }

      handleNavigate(key);
    },
    [isOpenSecondLevel, handleNavigate, collapsed, openMenu, closeMenu]
  );

  const handleSecondLevelMenuSelect = useCallback(
    ({ key }) => handleNavigate(`${firstLevelKey}/${key}`),
    [firstLevelKey, handleNavigate]
  );

  useEffect(() => {
    return () => {
      setIsUnsavedForm(false);
    };
  }, [setIsUnsavedForm]);

  useEffect(() => {
    const splitted = location?.pathname?.split('/');
    setFirstLevelKey('/' + splitted?.[1]);
    setSecondLevelKey(splitted?.[2]);
  }, [location?.pathname]);

  const seconLevelMenu = useMemo(() => {
    switch (firstLevelKey) {
      case ROUTES.ADMINISTRATION:
        return isSuperAdmin ? (
          <Menu.ItemGroup>
            <Menu.Item key={ROUTES.FILE_ANALYSIS_CONFIGURATION}>File Analysis Configuration</Menu.Item>
            <Menu.Item key={ROUTES.USER_MANAGEMENT}>User Management</Menu.Item>
            <Menu.Item key={ROUTES.TEMPLATES}>Templates</Menu.Item>
            <Menu.Item key={ROUTES.HOLIDAYS}>Holidays</Menu.Item>
            <Menu.Item key={ROUTES.OFFICE_LOCATIONS}>Office Locations</Menu.Item>
            <Menu.Item key={ROUTES.DOCUMENT_PREFIX}>Document Prefix</Menu.Item>
            <Menu.Item key={ROUTES.COURTS}>Courts</Menu.Item>
            <Menu.Item key={ROUTES.ALLOCATIONS}>Allocations</Menu.Item>
          </Menu.ItemGroup>
        ) : (
          <Menu.ItemGroup>
            <Menu.Item key={ROUTES.ALLOCATIONS}>Allocations</Menu.Item>
          </Menu.ItemGroup>
        );
      case ROUTES.REPORTS:
        return (
          <Menu.ItemGroup>
            <Menu.Item key={ROUTES.DEBTOR_CONVERTION_REPORT}>Debtor Convertion Report</Menu.Item>
            <Menu.Item key={ROUTES.MARKETING_REPORT}>Marketing Report</Menu.Item>
          </Menu.ItemGroup>
        );
      default:
        return;
    }
  }, [firstLevelKey, isSuperAdmin]);

  return (
    <>
      <Menu
        selectedKeys={firstLevelKey ? [firstLevelKey] : []}
        mode="vertical"
        theme="dark"
        inlineCollapsed={collapsed}
        style={{ width: collapsed ? 60 : 160, backgroundColor: styles.colorInactiveMenu }}
        onClick={handleFirstLevelMenuSelect}
        className="LeftMenu__menu--first-level"
      >
        <Menu.Item key={COLLAPSE_KEY} icon={!collapsed ? <CloseOutlined /> : <MenuOutlined />} />

        <Menu.Item key={ROUTES.DASHBOARD} icon={<HomeOutlined />}>
          Home
        </Menu.Item>
        <Menu.Item key={ROUTES.DEBTOR_SEARCH} icon={<SearchOutlined />}>
          Debtor Search
        </Menu.Item>
        <Menu.Item key={ROUTES.REPORTS} icon={<FileProtectOutlined />}>
          Reports
        </Menu.Item>

        <Menu.Divider style={{ margin: '15px 20px' }} />

        <Menu.Item key={ROUTES.USER_PROFILE} icon={<UserOutlined />}>
          User Profile
        </Menu.Item>

        {(isSuperAdmin || isSupervisorAllocation) && (
          <>
            <Menu.Divider style={{ margin: '15px 20px' }} />

            <Menu.Item key={ROUTES.ADMINISTRATION} icon={<SettingOutlined />}>
              Administration
            </Menu.Item>
          </>
        )}
      </Menu>

      <Drawer
        visible={isOpenSecondLevel}
        placement="left"
        onClose={() => setIsOpenSecondLevel(false)}
        closable={false}
        drawerStyle={{ backgroundColor: styles.colorActiveMenu }}
        contentWrapperStyle={{ width: 260, left: collapsed ? 60 : 160 }}
        className="LeftMenu__menu--second-level"
        zIndex={1}
      >
        <Menu
          theme="dark"
          style={{ backgroundColor: styles.colorActiveMenu }}
          selectedKeys={secondLevelKey ? [secondLevelKey] : undefined}
          onClick={handleSecondLevelMenuSelect}
        >
          {seconLevelMenu}
        </Menu>
      </Drawer>
    </>
  );
};

export default LeftMenu;
