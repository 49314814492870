import { useState, useCallback, useEffect } from 'react';
import { Modal, Form, Input, Select, Col, Row, Radio, Spin, Tabs, DatePicker } from 'antd';
import { useNavigate } from 'react-router-dom';
import { useQuery } from 'react-query';
import moment from 'moment';

import Button from '../../../../components/Button/Button';
import AttachmentsTab, {
  AttachmentsFormProps,
  argumentifyDocumentsSearchCriteria,
} from '../../../../components/AttachmentsTab';
import DataItem from '../../../../components/DataItem/DataItem';
import SelectLookupDto from '../../../../components/SelectLookupDto/SelectLookupDto';
import ExtraFooterButton from './ExtraFooterButton';
import SelectSuffixIcon from '../../../../components/SelectSuffixIcon/SelectSuffixIcon';

import useLocale from '../../../../hooks/useLocale';
import genericMessage from '../../../../utils/genericMessage';
import eventBus from '../../../../utils/eventBus';

import {
  DATE_FORMAT2,
  DATE_FORMAT_PLACEHOLDER,
  DATE_AND_TIME_FORMAT2,
  MAX_PAGE_SIZE,
} from '../../../../constants/common';
import { AGENTS_QUERY, TEAMS_QUERY, USE_QUERY_OPTIONS } from '../../../../constants/reactQuery';
import { AssignTypeEnum } from '../types';
import { NO_DATA } from '../constants';
import { UPLOAD_FILES_EVENT, TASK_ACTION_UPDATE_EVENT } from '../../../../constants/eventBus';
import { ROUTES } from '../../../../constants/routes';
import { VERIFICATIONS_TAB } from '../../DocumentsContent/DocumentsContent';
import { DOCUMENTS_TAB } from '../../ApplicationOverviewContent/ApplicationOverviewContent';

import { AuthorizeService } from '../../../../components/Auth/AuthorizeService';

import { ClientService } from '../../../../shared/api/ClientService';
import API from '../../../../utils/api';

import './TaskActionEditor.scss';

interface IFormUpdate extends ClientService.IAppTaskActionUpdateDto, AttachmentsFormProps {
  assignType: AssignTypeEnum;
}

interface IFormCreate extends ClientService.IAppTaskActionCreateDto, AttachmentsFormProps {
  assignType: AssignTypeEnum;
}

interface IProps {
  fileId: string;
  taskId?: string;
  actionId?: string;
  onNext?: () => void;
  onCancel?: () => void;
  isDisabled?: boolean;
}

const DETAILS_TAB = 'details';
const ATTACHMENTS_TAB = 'attachments';

const formTabs = [DETAILS_TAB, ATTACHMENTS_TAB];

function TaskActionEditor({ taskId, actionId, onNext, onCancel, fileId, isDisabled = false }: IProps): JSX.Element {
  const { t } = useLocale();
  const { Option } = Select;
  const { TextArea } = Input;
  const { TabPane } = Tabs;
  const navigate = useNavigate();
  const [form] = Form.useForm<IFormUpdate | IFormCreate>();
  const [isDeleteAttachmentDisabled, setIsDeleteAttachmentDisabled] = useState<boolean>(true);

  const user = AuthorizeService.getCurrentUserInfo();

  const [fileStagesLoading, setFileStagesLoading] = useState<boolean>(false);
  const [fileStages, setFileStages] = useState<ClientService.LookupDto[]>();

  const [appTaskActionNamesLoading, setAppTaskActionNamesLoading] = useState<boolean>(false);
  const [appTaskActionNames, setAppTaskActionNames] = useState<ClientService.LookupDto[]>();

  const [task, setTask] = useState<ClientService.IAppTaskDto>();
  const [taskLoading, setTaskLoading] = useState<boolean>(false);

  const [action, setAction] = useState<ClientService.IAppTaskActionDto>();
  const [actionLoading, setActionLoading] = useState<boolean>(false);

  const [createActionLoading, setCreateActionLoading] = useState<boolean>(false);
  const [updateActionLoading, setUpdateActionLoading] = useState<boolean>(false);

  const [defaultValues, setDefaultValues] = useState<IFormUpdate | IFormCreate>();
  const [activeTab, setActiveTab] = useState<string>(formTabs[0]);

  const [verificationDocumentId, setVerificationDocumentId] = useState<string>();

  const { data: agents } = useQuery([AGENTS_QUERY], () => API.listAgents(), USE_QUERY_OPTIONS);
  const { data: teams } = useQuery([TEAMS_QUERY], () => API.listTeams(undefined), USE_QUERY_OPTIONS);

  const requestFileStages = useCallback(async () => {
    setFileStagesLoading(true);
    const response = await API.listFileStages();
    if (response) setFileStages(response);
    setFileStagesLoading(false);
  }, []);

  const requestDocuments = useCallback(
    async (documentIds?: string[]) => {
      if (!documentIds || !documentIds?.length) return;

      const response = await API.documentsGET(
        ...argumentifyDocumentsSearchCriteria({ fileId, documentIds, maxResultCount: MAX_PAGE_SIZE })
      );

      if (response.items) {
        form.setFieldsValue({
          existingFiles: response.items,
        });
      }
    },
    [fileId, form]
  );

  const requestAppTaskActionNames = useCallback(async (fileProcessId, taskNameId) => {
    setAppTaskActionNamesLoading(true);
    const response = await API.listAppTaskActionNames(fileProcessId, taskNameId).catch(() =>
      setAppTaskActionNamesLoading(false)
    );

    if (response) setAppTaskActionNames(response);
    setAppTaskActionNamesLoading(false);
  }, []);

  const requestGetTask = useCallback(async (taskId) => {
    setTaskLoading(true);
    const task = await API.tasksGET2(taskId).catch(() => setTaskLoading(false));
    setTaskLoading(false);

    if (task) {
      setTask(task);
    }
  }, []);

  const requestGetAction = useCallback(
    async (actionId) => {
      setActionLoading(true);
      const response = await API.taskActionsGET2(actionId).catch(() => setActionLoading(false));
      setActionLoading(false);

      if (response) {
        setAction(response);
        requestDocuments(response?.attachedDocumentIds);

        //enable attachment delete option for actions that are not new claim, asset realization, i&e and tax return submissions
        if (
          response?.appTaskActionNameEnumValue !== ClientService.AppTaskActionNameEnum.ClaimReceived &&
          response?.appTaskActionNameEnumValue !== ClientService.AppTaskActionNameEnum.SecuredClaim &&
          response?.appTaskActionNameEnumValue !== ClientService.AppTaskActionNameEnum.NewIandESubmission &&
          response?.appTaskActionNameEnumValue !== ClientService.AppTaskActionNameEnum.NewTaxReturnSubmission
        ) {
          setIsDeleteAttachmentDisabled(false);
        }
      }
    },
    [requestDocuments]
  );

  const requestDocumentVerification = useCallback(
    async (appTaskActionId: string) => {
      const response = await API.documentVerificationFromApptaskaction(appTaskActionId);

      if (response) {
        const isAssignedToCurrentAgent = Boolean(
          [...(response?.signees || []), ...(response?.approvers || []), ...(response?.reviewers || [])]?.find(
            (item) => item?.agentId === user?.profile?.sub
          )
        );
        if (isAssignedToCurrentAgent && response?.id) setVerificationDocumentId(response?.id);
      }
    },
    [user?.profile?.sub]
  );

  const requestCreateTaskAction = useCallback(
    async ({
      appTaskActionNameId,
      description,
      followUpDate,
      assignedAgentId,
      assignedTeamId,
      attachedDocumentIds,
    }) => {
      const body = {
        appTaskActionNameId,
        description,
        followUpDate,
        openDate: moment(),
        appTaskId: task?.id,
        assignedAgentId,
        assignedTeamId,
        attachedDocumentIds,
      } as ClientService.AppTaskActionCreateDto;

      setCreateActionLoading(true);
      const response = await API.taskActionsPOST(body).catch(() => setCreateActionLoading(false));
      setCreateActionLoading(false);

      if (response?.result === ClientService.Result.Successful) {
        genericMessage.success(t.ACTION_CREATED_SUCCESS_MESSAGE);
        if (onNext) onNext();
      } else {
        genericMessage.error({}, response?.messages?.[0]?.body);
      }
    },
    [onNext, t.ACTION_CREATED_SUCCESS_MESSAGE, task?.id]
  );

  const requestUpdateTaskAction = useCallback(
    async ({
      assignedAgentId,
      assignedTeamId,
      followUpDate,
      description,
      appTaskActionStatus,
      attachedDocumentIds,
    }) => {
      const statusResponse = await API.getAppTaskActionStatus(appTaskActionStatus).catch(() =>
        setUpdateActionLoading(false)
      );

      if (statusResponse) {
        const body = {
          description,
          followUpDate,
          assignedAgentId,
          assignedTeamId,
          appTaskActionStatus: statusResponse?.enumValue,
          attachedDocumentIds,
        } as ClientService.AppTaskActionUpdateDto;

        setUpdateActionLoading(true);
        const response = await API.taskActionsPUT(actionId as string, body).catch(() => setUpdateActionLoading(false));
        setUpdateActionLoading(false);

        if (response?.result === ClientService.Result.Successful) {
          eventBus.dispatch(TASK_ACTION_UPDATE_EVENT);
          genericMessage.success(t.ACTION_UPDATED_SUCCESS_MESSAGE);
          if (onNext) onNext();
        } else {
          genericMessage.error({}, response?.messages?.[0]?.body);
        }
      }
    },
    [actionId, onNext, t.ACTION_UPDATED_SUCCESS_MESSAGE]
  );

  const handleVerify = useCallback(() => {
    navigate(`${ROUTES.APPLICATION_OVERVIEW}/${fileId}`, {
      state: {
        preselectedTab: DOCUMENTS_TAB,
        preselectedDocumentsSubTab: VERIFICATIONS_TAB,
        preselectedDocumentId: verificationDocumentId,
      },
    });
    if (onCancel) onCancel();
  }, [fileId, navigate, onCancel, verificationDocumentId]);

  const handleValuesChange = useCallback(
    (values) => {
      Object.keys(values).forEach((field) => {
        const error = form.getFieldError(field);
        if (!error.length) {
          return;
        }
        form.setFields([
          {
            name: field,
            errors: [],
          },
        ]);
      });
    },
    [form]
  );

  const handleOnSubmit = useCallback(
    (values) => {
      eventBus.dispatch(UPLOAD_FILES_EVENT, {
        onUploadSuccess: (attachedDocumentIds?: string[]) => {
          const params = { ...values, attachedDocumentIds };
          if (actionId) {
            requestUpdateTaskAction(params);
          } else {
            requestCreateTaskAction(params);
          }
        },
      });
    },
    [actionId, requestCreateTaskAction, requestUpdateTaskAction]
  );

  const handleTabChange = useCallback((key) => form.validateFields().then(() => setActiveTab(key)), [form]);

  useEffect(() => {
    if (!fileStages) {
      requestFileStages();
    }
  }, [fileStages, requestFileStages]);

  useEffect(() => {
    if (actionId && !action) {
      requestGetAction(actionId);
    }
  }, [actionId, action, requestGetAction]);

  useEffect(() => {
    if (taskId && !task) {
      requestGetTask(taskId);
    }
  }, [taskId, task, requestGetTask]);

  useEffect(() => {
    if (defaultValues) return;

    if (actionId && action && agents) {
      const assignType = action?.assignedTeamId ? AssignTypeEnum.Team : AssignTypeEnum.Users;
      setDefaultValues({ ...action, assignType } as IFormUpdate);
    }

    if (!actionId) {
      setDefaultValues({ assignType: AssignTypeEnum.Users, followUpDate: moment() } as IFormCreate);
    }
  }, [task, defaultValues, taskId, form, agents, actionId, action]);

  useEffect(() => {
    if (defaultValues) {
      form.setFieldsValue({
        ...defaultValues,
        description: defaultValues?.description,
      });
    }
  }, [defaultValues, form]);

  useEffect(() => {
    if (!appTaskActionNames && task) {
      requestAppTaskActionNames(task?.fileProcessId, task?.appTaskNameId);
    }
  }, [appTaskActionNames, requestAppTaskActionNames, task]);

  useEffect(() => {
    if (actionId) requestDocumentVerification(actionId);
  }, [actionId, requestDocumentVerification]);

  //Since Task Actions do not have the concept of "file stage names" until they have a file. We are using this to get the "file stage name" for the task action.
  const getStageNameForActions = useCallback(() => {
    if (actionId && action && appTaskActionNames) {
      const actionCode = appTaskActionNames?.find((item) => item?.id === action?.appActionNameId)?.enumValue;

      if (actionCode === ClientService.AppTaskActionNameEnum.NewIandESubmission) {
        return t.INCOMEEXPENSE_STAGE_INCOME_EXPENSE;
      }

      if (actionCode === ClientService.AppTaskActionNameEnum.NewTaxReturnSubmission) {
        return t.TAXRETURN_STAGE_TAX_RETURN;
      }

      if (
        actionCode === ClientService.AppTaskActionNameEnum.ClaimReceived ||
        actionCode === ClientService.AppTaskActionNameEnum.SecuredClaim
      ) {
        return t.CLAIMS_STAGE_CLAIM;
      }
    }
    return undefined;
  }, [
    actionId,
    action,
    appTaskActionNames,
    t.INCOMEEXPENSE_STAGE_INCOME_EXPENSE,
    t.TAXRETURN_STAGE_TAX_RETURN,
    t.CLAIMS_STAGE_CLAIM,
  ]);

  return (
    <Modal
      destroyOnClose
      centered
      visible
      title={actionId ? appTaskActionNames?.find((item) => item?.id === action?.appActionNameId)?.name : t.ACTION_NEW}
      width={900}
      footer={null}
      onCancel={onCancel}
      className="TaskActionEditor"
    >
      <Spin
        spinning={
          appTaskActionNamesLoading ||
          taskLoading ||
          fileStagesLoading ||
          actionLoading ||
          createActionLoading ||
          updateActionLoading
        }
      >
        <Form
          form={form}
          layout="vertical"
          initialValues={defaultValues}
          onValuesChange={handleValuesChange}
          onFinish={handleOnSubmit}
          validateTrigger={['onSubmit', 'onChange']}
        >
          <Tabs onChange={handleTabChange} activeKey={activeTab}>
            <TabPane tab={t.DETAILS} key={formTabs[0]} className="TaskActionEditor__tabs-container">
              <Row gutter={10} className="TaskActionEditor__info-container">
                <DataItem
                  colSpan={12}
                  label={t.TASK_STAGE}
                  value={getStageNameForActions() || task?.fileStageName || NO_DATA}
                  horizontalSplit={[30, 70]}
                />
                {actionId && (
                  <DataItem
                    colSpan={12}
                    label={t.ACTION}
                    value={appTaskActionNames?.find((item) => item?.id === action?.appActionNameId)?.name || ''}
                    horizontalSplit={[30, 70]}
                  />
                )}
              </Row>
              <Row gutter={10} className="TaskActionEditor__info-container">
                <DataItem
                  colSpan={12}
                  label={t.TASK_PROCESS}
                  value={task?.fileProcessName || NO_DATA}
                  horizontalSplit={[30, 70]}
                />
                {actionId && (
                  <DataItem
                    colSpan={12}
                    label={t.TASK_OPENED}
                    value={action?.openDate ? moment(action?.openDate).format(DATE_AND_TIME_FORMAT2) : NO_DATA}
                    horizontalSplit={[30, 70]}
                  />
                )}
              </Row>
              <Row gutter={10} className="TaskActionEditor__info-container">
                <DataItem colSpan={12} label={t.TASK} value={task?.appTaskName || NO_DATA} horizontalSplit={[30, 70]} />
                <DataItem
                  colSpan={12}
                  label={t.TASK_CLOSED}
                  value={action?.closeDate ? moment(action?.closeDate).format(DATE_AND_TIME_FORMAT2) : NO_DATA}
                  horizontalSplit={[30, 70]}
                />
              </Row>

              <Row gutter={20}>
                <Col span={12}>
                  {actionId ? (
                    <Form.Item
                      name="appTaskActionStatus"
                      label={t.STATUS}
                      rules={[{ required: true, message: t.REQUIRED_FIELD }]}
                    >
                      <Select
                        suffixIcon={<SelectSuffixIcon />}
                        size="large"
                        disabled={appTaskActionNamesLoading || isDisabled}
                        placeholder={t.SELECT}
                      >
                        <Option key="0" value={ClientService.AppTaskActionStatusEnum.Pending}>
                          {t.PENDING}
                        </Option>
                        <Option key="1" value={ClientService.AppTaskActionStatusEnum.Completed}>
                          {t.COMPLETED}
                        </Option>
                        <Option key="2" value={ClientService.AppTaskActionStatusEnum.Cancelled}>
                          {t.CANCELLED}
                        </Option>
                      </Select>
                    </Form.Item>
                  ) : (
                    <Form.Item
                      name="appTaskActionNameId"
                      label={t.ACTION_NAME}
                      rules={[{ required: true, message: t.REQUIRED_FIELD }]}
                    >
                      <SelectLookupDto data={appTaskActionNames} disabled={isDisabled} />
                    </Form.Item>
                  )}
                </Col>
                <Col span={12}>
                  <Form.Item
                    name="followUpDate"
                    label={t.FOLLOW_UP}
                    rules={[{ required: true, message: t.REQUIRED_FIELD }]}
                  >
                    <DatePicker
                      size="large"
                      disabledDate={(date) => date.isBefore(moment(), 'day')}
                      format={DATE_FORMAT2}
                      placeholder={DATE_FORMAT_PLACEHOLDER}
                      disabled={isDisabled}
                    />
                  </Form.Item>
                </Col>
              </Row>

              <Row>
                <Form.Item
                  name="description"
                  label={t.TASK_DESCRIPTION}
                  rules={[
                    { required: true, message: t.REQUIRED_FIELD },
                    { max: 5000, message: t.MAX_500_CHAR_ERROR },
                  ]}
                >
                  <TextArea size="large" rows={5} disabled={isDisabled} />
                </Form.Item>
              </Row>
              <Row gutter={20} align="bottom">
                <Col span={12}>
                  <Form.Item label={t.TASK_ASSIGN} name="assignType" required>
                    <Radio.Group size="large" buttonStyle="solid" optionType="button" disabled={isDisabled}>
                      <Radio.Button value={AssignTypeEnum.Users}>{t.TASK_USERS}</Radio.Button>
                      <Radio.Button value={AssignTypeEnum.Team}>{t.TASK_TEAM}</Radio.Button>
                    </Radio.Group>
                  </Form.Item>
                </Col>
                <Col span={12} className="TaskActionEditor__search-container">
                  <Form.Item
                    noStyle
                    shouldUpdate={(prevValues, currentValues) => prevValues.assignType !== currentValues.assignType}
                  >
                    {({ getFieldValue }) => (
                      <>
                        {getFieldValue('assignType') === AssignTypeEnum.Users && (
                          <Form.Item name="assignedAgentId" rules={[{ required: true, message: t.REQUIRED_FIELD }]}>
                            <SelectLookupDto data={agents} showSearch disabled={isDisabled} />
                          </Form.Item>
                        )}

                        {getFieldValue('assignType') === AssignTypeEnum.Team && (
                          <Form.Item name="assignedTeamId" rules={[{ required: true, message: t.REQUIRED_FIELD }]}>
                            <SelectLookupDto data={teams} showSearch disabled={isDisabled} />
                          </Form.Item>
                        )}
                      </>
                    )}
                  </Form.Item>
                </Col>
              </Row>
            </TabPane>
            <TabPane tab={t.ATTACHMENTS} key={formTabs[1]} forceRender>
              <AttachmentsTab
                form={form}
                applicationFileId={fileId}
                disabled={isDisabled}
                columnsProps={{ isDeleteDisabled: isDisabled || isDeleteAttachmentDisabled }}
              />
            </TabPane>
          </Tabs>
          <Row gutter={12} justify="end" className="TaskEditor__footer">
            <Col>
              <ExtraFooterButton data={action} appTaskActionNames={appTaskActionNames} applicationFileId={fileId} />
            </Col>
            <Col>{verificationDocumentId && <Button onClick={handleVerify}>Verify</Button>}</Col>
            <Col>
              <Button kind="cancel" padding="large" onClick={onCancel}>
                {t.CANCEL}
              </Button>
            </Col>
            <Col hidden={isDisabled}>
              <Button kind="primary" padding="large" htmlType="submit">
                {t.SAVE}
              </Button>
            </Col>
          </Row>
        </Form>
      </Spin>
    </Modal>
  );
}

export default TaskActionEditor;
