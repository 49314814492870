import { useState, useCallback, useEffect } from 'react';
import { Modal, DatePicker, Form, Col, Row, Spin } from 'antd';
import moment from 'moment';

import Button from '../../components/Button/Button';

import { DATE_FORMAT2, DATE_FORMAT_PLACEHOLDER } from '../../constants/common';
import { TWO_BUSINESS_DAYS } from './constants';
import useLocale from '../../hooks/useLocale';
import API from '../../utils/api';
import { convertServerDateOnlyToInputDate } from '../../utils/helpers';

import './FollowUp.scss';

interface IProps {
  onSave: (date?: moment.Moment, onSaveFailure?: () => void) => void;
  onCancel: () => void;
  shouldDisableOnSave?: boolean;
}

interface IForm {
  followUpDate?: moment.Moment;
}

const FollowUpModal = ({ onSave, onCancel, shouldDisableOnSave = false }: IProps) => {
  const { t } = useLocale();
  const [form] = Form.useForm<IForm>();
  const [loading, setLoading] = useState(false);
  const [disabled, setDisabled] = useState<boolean>(false);

  const requestNextBusinessDay = useCallback(async () => {
    setLoading(true);
    const response = await API.getNextBusinessDay(undefined, TWO_BUSINESS_DAYS).catch(() => setLoading(false));
    setLoading(false);

    if (response) {
      form.setFieldsValue({ followUpDate: convertServerDateOnlyToInputDate(response) });
    }
  }, [form]);

  useEffect(() => {
    requestNextBusinessDay();
  }, [requestNextBusinessDay]);

  return (
    <Modal
      destroyOnClose
      centered
      width={440}
      visible
      closable={!loading}
      title={t.NEW_PROSPECT_FOLLOW_UP}
      onCancel={onCancel}
      footer={null}
      className="FollowUp"
    >
      <Spin spinning={loading}>
        <Form
          layout="vertical"
          form={form}
          onFinish={(values) => {
            if (shouldDisableOnSave) setDisabled(true);

            onSave(values?.followUpDate, () => {
              if (shouldDisableOnSave) setDisabled(false);
            });
          }}
        >
          <Form.Item
            name="followUpDate"
            label={t.NEW_PROSPECT_FOLLOW_UP_DATE}
            rules={[{ required: true, message: `${t.NEW_PROSPECT_FOLLOW_UP_DATE} ${t.IS_REQUIRED}!` }]}
          >
            <DatePicker
              format={DATE_FORMAT2}
              style={{ width: '100%' }}
              disabledDate={(date) => date.isBefore(moment(), 'day')}
              placeholder={DATE_FORMAT_PLACEHOLDER}
              size="large"
            />
          </Form.Item>
          <Row align="middle" justify="end" gutter={10} style={{ paddingTop: 16 }}>
            <Col>
              <Form.Item noStyle>
                <Button kind="cancel" onClick={onCancel}>
                  {t.CANCEL}
                </Button>
              </Form.Item>
            </Col>
            <Col>
              <Form.Item noStyle>
                <Button kind="primary" htmlType="submit" disabled={disabled}>
                  {t.SAVE}
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Spin>
    </Modal>
  );
};

export default FollowUpModal;
