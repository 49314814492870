import { Divider, DividerProps } from 'antd';

interface IProps extends DividerProps {
  style?: React.CSSProperties;
  className?: string;
}

const DividerStyled = ({ style, className, ...props }: IProps) => {
  return <Divider {...props} className={className} style={{ borderTop: '1px solid #E8E8E9', ...style }} />;
};

export default DividerStyled;
