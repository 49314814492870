import { Col, Divider, Form, Input, Row, Typography } from 'antd';
import { LocationDto } from '../../../../modals/GoogleMaps';
import useLocale from '../../../../hooks/useLocale';
import ChangeTrackerFormItem from '../../../../components/ChangeTrackerFormItem';
import SearchLocationInput from '../../../../components/SearchLocationInput/SearchLocationInput';
import { DeleteOutlined } from '@ant-design/icons';

interface IFinancialAdviceProviderRowProps {
  isRequired: boolean;
  rowName: number;
  onAddressSelect: (row: number) => (autofilledAddress: LocationDto) => void;
  remove: (index: number | number[]) => void;
  isLocked?: boolean;
  listName?: string;
}

const FinancialAdviceProviderRow = ({
  isRequired,
  isLocked = false,
  rowName,
  onAddressSelect,
  remove,
  listName,
}: IFinancialAdviceProviderRowProps): JSX.Element => {
  const { t } = useLocale();
  const { Text } = Typography;
  return (
    <>
      <Row gutter={20} align="middle" style={{ position: 'relative' }}>
        <Col span={24}>
          <Text type={'secondary'}>{t.ADVICE_RECEIVED_BY_OTHER_CONSULTANTS_APPLICANT_SECTION_SUBTITLE}</Text>
        </Col>
        <Col span={12}>
          <ChangeTrackerFormItem
            name={[rowName, 'surname']}
            label={t.ADVICE_RECEIVED_BY_OTHER_CONSULTANTS_APPLICANT_SURNAME}
            rules={[
              { required: isRequired, message: t.ADVICE_RECEIVED_BY_OTHER_CONSULTANTS_APPLICANT_SURNAME_VALIDATION },
            ]}
          >
            <Input size="large" disabled={isLocked} />
          </ChangeTrackerFormItem>
        </Col>
        <Col span={12}>
          <ChangeTrackerFormItem
            name={[rowName, 'givenName']}
            label={t.ADVICE_RECEIVED_BY_OTHER_CONSULTANTS_APPLICANT_GIVEN_NAME}
            rules={[
              { required: isRequired, message: t.ADVICE_RECEIVED_BY_OTHER_CONSULTANTS_APPLICANT_GIVEN_NAME_VALIDATION },
            ]}
          >
            <Input size="large" disabled={isLocked} />
          </ChangeTrackerFormItem>
        </Col>
        <Col span={24}>
          <ChangeTrackerFormItem
            name={[rowName, 'firmName']}
            label={t.ADVICE_RECEIVED_BY_OTHER_CONSULTANTS_APPLICANT_FIRM_NAME}
            rules={[
              { required: isRequired, message: t.ADVICE_RECEIVED_BY_OTHER_CONSULTANTS_APPLICANT_FIRM_NAME_VALIDATION },
            ]}
          >
            <Input size="large" disabled={isLocked} />
          </ChangeTrackerFormItem>
        </Col>
        <Col span={24}>
          <Form.Item
            noStyle
            shouldUpdate={(prevValues, currentValues) => prevValues[rowName] !== currentValues[rowName]}
          >
            {({ getFieldValue }) => (
              <ChangeTrackerFormItem
                name={[rowName, 'firmAddress']}
                label={t.ADVICE_RECEIVED_BY_OTHER_CONSULTANTS_APPLICANT_FIRM_ADDRESS}
                rules={[
                  {
                    required: isRequired,
                    message: t.ADVICE_RECEIVED_BY_OTHER_CONSULTANTS_APPLICANT_FIRM_ADDRESS_VALIDATION,
                  },
                ]}
                listName={listName}
              >
                <SearchLocationInput
                  address={{ address: getFieldValue([rowName, 'firmAddress']) }}
                  onSelect={onAddressSelect(rowName)}
                  size="large"
                  disabled={isLocked}
                />
              </ChangeTrackerFormItem>
            )}
          </Form.Item>
        </Col>
        <Col span={12}>
          <ChangeTrackerFormItem
            name={[rowName, 'addressUnit']}
            label={t.ADVICE_RECEIVED_BY_OTHER_CONSULTANTS_APPLICANT_FIRM_ADDRESS_UNIT}
            listName={listName}
          >
            <Input size="large" disabled={isLocked} />
          </ChangeTrackerFormItem>
        </Col>
        <Col span={12}></Col>
        <Col span={12}>
          <ChangeTrackerFormItem
            name={[rowName, 'totalAmountPaidToDate']}
            label={t.ADVICE_RECEIVED_BY_OTHER_CONSULTANTS_APPLICANT_TOTAL_AMOUNT_PAID_TO_DATE}
            rules={[
              {
                required: isRequired,
                message: t.ADVICE_RECEIVED_BY_OTHER_CONSULTANTS_APPLICANT_TOTAL_AMOUNT_PAID_TO_DATE_VALIDATION,
              },
            ]}
          >
            <Input size="large" type={'number'} disabled={isLocked} />
          </ChangeTrackerFormItem>
        </Col>
        <Col span={12}>
          <ChangeTrackerFormItem
            name={[rowName, 'totalRemainingAmountToBePaid']}
            label={t.ADVICE_RECEIVED_BY_OTHER_CONSULTANTS_APPLICANT_TOTAL_REMAINING_AMOUNT_TO_BE_PAID}
            rules={[
              {
                required: isRequired,
                message: t.ADVICE_RECEIVED_BY_OTHER_CONSULTANTS_APPLICANT_TOTAL_REMAINING_AMOUNT_TO_BE_PAID_VALIDATION,
              },
            ]}
          >
            <Input size="large" type={'number'} disabled={isLocked} />
          </ChangeTrackerFormItem>
        </Col>
        {!isLocked && (
          <Col span={2} style={{ position: 'absolute', bottom: '0', right: '0' }}>
            <DeleteOutlined
              className="ApplicationFormTab__input-action-icon"
              onClick={() => {
                if (isLocked) return;
                remove(rowName);
              }}
            />
          </Col>
        )}
      </Row>
      <Divider dashed className="ApplicationFormTab__questionnaire-question-divider" />
    </>
  );
};

export default FinancialAdviceProviderRow;
