import { useCallback } from 'react';
import { Row, Col, Form } from 'antd';
import { InternalNamePath } from 'rc-field-form/es/interface';
import { Icon } from '@fluentui/react/lib/Icon';

import styles from '../../../../styles/style.module.scss';

interface IError {
  name: InternalNamePath;
  errors: string[];
  warnings?: string[];
}

interface IProps {
  title?: string;
  panelFieldsNames?: string[];
}

const PanelHeader = ({ title, panelFieldsNames }: IProps) => {
  const hasPanelErrors = useCallback(
    (errors?: IError[]) => Boolean(errors?.filter((item) => item?.errors?.length)?.length),
    []
  );
  return (
    <Form.Item noStyle shouldUpdate>
      {({ getFieldsError }) => (
        <Row align="middle" gutter={8}>
          <Col>{title}</Col>
          <Col>
            {hasPanelErrors([...(getFieldsError(panelFieldsNames) || [])]) && (
              <Icon iconName="AlertSolid" style={{ color: styles.colorDanger, marginTop: 3 }} />
            )}
          </Col>
        </Row>
      )}
    </Form.Item>
  );
};

export default PanelHeader;
