import { OptionType, InputTypeEnum } from './types';
import { EMPTY_CELL_VALUE, UNLIMITED, CURRENCY_PRECISION, PERCENTAGE_PRECISION } from './constants';

export const UnlimitedNumericRegexPattern = new RegExp('^([0-9.-]*|Unlimited)$', 'i');

export const isUnlimited = (value?: string | number) => value?.toString().toLowerCase() === UNLIMITED.toLowerCase();

export const convertCellValueToReadable = (value?: string | number, type?: InputTypeEnum) => {
  if (!value) return EMPTY_CELL_VALUE;
  if (type === InputTypeEnum.Percentage) return `${Number(value || 0).toFixed(PERCENTAGE_PRECISION)}%`;
  if (type === InputTypeEnum.Currency) return `$${Number(value || 0).toFixed(CURRENCY_PRECISION)}`;
  if (type === InputTypeEnum.UnlimitedNumeric && isUnlimited(value)) return UNLIMITED;
  if (type === InputTypeEnum.UnlimitedNumeric && Number(value))
    return `${Number(value || 0).toFixed(CURRENCY_PRECISION)}`;
  return value;
};

export const convertSelectedNameToId = (value?: string, options?: OptionType[]) => {
  if (!value || value === EMPTY_CELL_VALUE) return undefined;
  return options?.find((i) => i.name === value)?.id;
};

export const convertUnlimitedNumericToNumber = (value?: string) => Number(value) || undefined;
