import { useCallback, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useQuery } from 'react-query';
import { Table, Form, Radio, Col, Row, Tooltip, Spin } from 'antd';
import moment from 'moment';
import { CSVLink } from 'react-csv';

import Layout from '../../components/Layout/Layout';
import PageTitleContainer from '../../components/PageTitleContainer/PageTitleContainer';
import Breadcrumb from '../../components/Breadcrumb/Breadcrumb';
import PageSubtitle from '../../components/PageSubtitle/PageSubtitle';
import DatePicker from '../../components/DatePicker/DatePicker';
import FormSectionTitle from '../../components/FormSectionTitle/FormSectionTitle';
import Button from '../../components/Button/Button';

import { AGENTS_QUERY, FILE_STAGES_QUERY, USE_QUERY_OPTIONS } from '../../constants/reactQuery';
import { DATE_FORMAT2, DATE_FORMAT_PLACEHOLDER } from '../../constants/common';
import { TRUSTEE_REPORT_TYPE, OFFICE_REPORT_TYPE, ESTATE_REPORT_TYPE } from './constants';
import { argumentifyReportSearchCriteria, convertNumberToPercentage } from './utils';
import { AuthorizeService } from '../../components/Auth/AuthorizeService';
import { ClientService } from '../../shared/api/ClientService';
import API from '../../utils/api';

import useLocale from '../../hooks/useLocale';
import { ROUTES } from '../../constants/routes';

import './DebtorConversionReport.scss';

type IDataItem = ClientService.GroupByOfficeDto | ClientService.GroupByEstateAdminDto | ClientService.GroupByTrusteeDto;

function DebtorConversionReport() {
  const { t } = useLocale();
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const user = AuthorizeService.getCurrentUserInfo();

  const [showTable, setShowTable] = useState<boolean>(false);

  const [dataSource, setDataSource] = useState<IDataItem[]>();
  const [loading, setLoading] = useState<boolean>(false);
  const [type, setType] = useState<string>();

  const { data: agents } = useQuery([AGENTS_QUERY], () => API.listAgents(), USE_QUERY_OPTIONS);
  const { data: fileStages } = useQuery([FILE_STAGES_QUERY], () => API.listFileStages(), USE_QUERY_OPTIONS);

  const userName = useMemo(() => agents?.find((item) => item.id === user?.profile?.sub), [agents, user?.profile?.sub]);

  const selectResponseByType = useCallback(
    (type: string, response: ClientService.DebtorConversionDto, agents?: ClientService.LookupDto[]) => {
      switch (type) {
        case OFFICE_REPORT_TYPE:
          return response?.office?.sort((a, b) => (a?.office || '')?.localeCompare(b?.office || ''));
        case ESTATE_REPORT_TYPE:
          return response?.estateAdmin
            ?.map(
              (item) =>
                ({
                  ...item,
                  estateAdmin: item?.estateAdmin || agents?.find((agent) => agent.id === item?.id)?.name,
                } as ClientService.GroupByEstateAdminDto)
            )
            ?.sort((a, b) => (a?.estateAdmin || '')?.localeCompare(b?.estateAdmin || ''))
            ?.sort((a, b) => (a?.office || '')?.localeCompare(b?.office || ''));

        case TRUSTEE_REPORT_TYPE:
          return response?.trustee?.sort((a, b) => (a?.trustee || '')?.localeCompare(b?.trustee || ''));
        default:
          return [];
      }
    },
    []
  );

  const requestReport = useCallback(
    async ({ createdFrom, createdTo, groupBy }) => {
      if (agents) {
        setLoading(true);
        const response = await API.report(
          ...argumentifyReportSearchCriteria({
            createdFrom,
            createdTo,
            isOfficeSelected: groupBy === OFFICE_REPORT_TYPE,
            isEstateAdminSelected: groupBy === ESTATE_REPORT_TYPE,
            isTrusteeSelected: groupBy === TRUSTEE_REPORT_TYPE,
          })
        ).catch(() => setLoading(false));
        setLoading(false);

        if (response) {
          setDataSource(selectResponseByType(groupBy, response, agents));
          setShowTable(true);
          setType(groupBy);
        }
      }
    },
    [agents, selectResponseByType]
  );

  const navigateToSearchResults = useCallback(
    (itemId?: string, fileStageEnum?: ClientService.FileStageEnum) => {
      navigate(ROUTES.DEBTOR_SEARCH, {
        state: {
          officeId: type === OFFICE_REPORT_TYPE ? itemId : undefined,
          trusteeId: type === TRUSTEE_REPORT_TYPE ? itemId : undefined,
          estateAdminId: type === ESTATE_REPORT_TYPE ? itemId : undefined,
          fileStageId: fileStages?.find((item) => item.enumValue === fileStageEnum)?.id,
        },
      });
    },
    [fileStages, navigate, type]
  );

  const columns = [
    {
      title: t.ESTATE_ADMIN,
      dataIndex: 'estateAdmin',
      key: 'estateAdmin',
      width: 200,
      hidden: type !== ESTATE_REPORT_TYPE,
    },
    {
      title: t.OFFICE,
      dataIndex: 'office',
      key: 'office',
      width: 200,
      hidden: type === TRUSTEE_REPORT_TYPE,
    },
    {
      title: t.TRUSTEE,
      dataIndex: 'trustee',
      key: 'trustee',
      width: 200,
      hidden: type !== TRUSTEE_REPORT_TYPE,
    },

    {
      title: t.NUMBER_OF_FILES,
      dataIndex: 'numberofFiles',
      key: 'numberofFiles',
      render: (text: any, record: IDataItem) => (
        <Tooltip overlay="Click to view files">
          <span className="DebtorConversionReport__clickable-cell" onClick={() => navigateToSearchResults(record?.id)}>
            {text}
          </span>
        </Tooltip>
      ),
    },
    {
      title: t.PENDING_CONFIRMATION,
      dataIndex: 'pendingConfirmation',
      key: 'pendingConfirmation',
      render: (text: any, record: IDataItem) => (
        <Tooltip overlay="Click to view files">
          <span
            className="DebtorConversionReport__clickable-cell"
            onClick={() => navigateToSearchResults(record?.id, ClientService.FileStageEnum.FileInProcess)}
          >
            {text}
          </span>
        </Tooltip>
      ),
    },
    {
      title: t.CONVERSION_RATE,
      dataIndex: 'pendingConfirmationConversionRate',
      key: 'pendingConfirmationConversionRate',
      render: (rate?: number) => <>{convertNumberToPercentage(rate)}</>,
    },
    {
      title: t.SIGNUP_IN_PROGRESS,
      dataIndex: 'signupInProgress',
      key: 'signupInProgress',
      render: (text: any, record: IDataItem) => (
        <Tooltip overlay="Click to view files">
          <span
            className="DebtorConversionReport__clickable-cell"
            onClick={() => navigateToSearchResults(record?.id, ClientService.FileStageEnum.PreSignup)}
          >
            {text}
          </span>
        </Tooltip>
      ),
    },
    {
      title: t.CONVERSION_RATES,
      dataIndex: 'signupInProgressConversionRate',
      key: 'signupInProgressConversionRate',
      render: (rate?: number) => <>{convertNumberToPercentage(rate)}</>,
    },
    {
      title: t.FILES_SIGNED,
      dataIndex: 'filesSigned',
      key: 'filesSigned',
      render: (text: any, record: IDataItem) => (
        <Tooltip overlay="Click to view files">
          <span
            className="DebtorConversionReport__clickable-cell"
            onClick={() => navigateToSearchResults(record?.id, ClientService.FileStageEnum.Signed)}
          >
            {text}
          </span>
        </Tooltip>
      ),
    },
    {
      title: t.CONVERSION_RATE,
      dataIndex: 'filesSignedConversionRate',
      key: 'filesSignedConversionRate',
      render: (rate?: number) => <>{convertNumberToPercentage(rate)}</>,
    },
  ].filter((item) => !item.hidden);

  return (
    <Layout page={ROUTES.USER_PROFILE} className="DebtorConversionReport">
      <PageTitleContainer title={userName?.name || ''} hasToolbar={false} />
      <Breadcrumb
        data={[
          { link: ROUTES.DASHBOARD, title: t.DASHBOARD },
          { title: t.REPORTS },
          { title: t.DEBTOR_CONVERSION_REPORT },
        ]}
      />
      <PageSubtitle title={t.DEBTOR_CONVERSION_REPORT} />
      <Spin spinning={loading}>
        <Form form={form} layout="vertical" onFinish={requestReport} onValuesChange={() => setShowTable(false)}>
          <Row gutter={40}>
            <Col span={8}>
              <Row>
                <Col span={24}>
                  <FormSectionTitle titleText={t.PARAMETERS} />
                </Col>
                <Col span={24}>
                  <Form.Item
                    name="createdFrom"
                    label={t.CREATED_DATE_FROM}
                    rules={[{ required: true, message: t.REQUIRED_FIELD }]}
                  >
                    <DatePicker
                      format={DATE_FORMAT2}
                      dateSetter={null}
                      size="large"
                      disabledDate={(date) => date.isAfter(moment(), 'day')}
                      placeholder={DATE_FORMAT_PLACEHOLDER}
                    />
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item noStyle shouldUpdate>
                    {({ getFieldValue }) => (
                      <Form.Item
                        name="createdTo"
                        label={t.CREATED_DATE_TO}
                        rules={[
                          { required: true, message: t.REQUIRED_FIELD },
                          {
                            validator: (_, value) => {
                              if (
                                getFieldValue('dateFrom') &&
                                moment(getFieldValue('dateFrom')).isSameOrAfter(value, 'd')
                              ) {
                                return Promise.reject(t.TO_DATE_SHOULD_BE_AFTER_FROM_DATE);
                              } else {
                                return Promise.resolve();
                              }
                            },
                          },
                        ]}
                      >
                        <DatePicker
                          format={DATE_FORMAT2}
                          dateSetter={null}
                          size="large"
                          disabledDate={(date) => date.isAfter(moment(), 'day')}
                          placeholder={DATE_FORMAT_PLACEHOLDER}
                        />
                      </Form.Item>
                    )}
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item name="groupBy" label={t.GROUP_BY} rules={[{ required: true, message: t.REQUIRED_FIELD }]}>
                    <Radio.Group size="large" buttonStyle="solid" optionType="button">
                      <Radio.Button value={OFFICE_REPORT_TYPE}>{t.OFFICE}</Radio.Button>
                      <Radio.Button value={ESTATE_REPORT_TYPE}>{t.ESTATE_ADMIN}</Radio.Button>
                      <Radio.Button value={TRUSTEE_REPORT_TYPE}>{t.TRUSTEE}</Radio.Button>
                    </Radio.Group>
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Row align="middle" justify="end" gutter={10} className="DebtorConversionReport__footer">
                    <Col>
                      <Form.Item>
                        <Button
                          onClick={() => {
                            form.resetFields();
                            setShowTable(false);
                          }}
                          kind="cancel"
                        >
                          {t.CLEAR}
                        </Button>
                      </Form.Item>
                    </Col>

                    <Col>
                      <Form.Item>
                        <Button htmlType="submit">{t.VIEW_REPORT}</Button>
                      </Form.Item>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
            <Col span={showTable ? 16 : 0}>
              <Row justify="end">
                <Col>
                  <CSVLink
                    filename={'DebtorConversionReport.csv'}
                    data={
                      (type === ESTATE_REPORT_TYPE
                        ? dataSource?.map((item) => ({
                            ...item,
                            pendingConfirmationConversionRate: convertNumberToPercentage(
                              item?.pendingConfirmationConversionRate
                            ),
                            signupInProgressConversionRate: convertNumberToPercentage(
                              item?.signupInProgressConversionRate
                            ),
                            filesSignedConversionRate: convertNumberToPercentage(item?.filesSignedConversionRate),
                          }))
                        : dataSource) || []
                    }
                    headers={columns?.map((item) => ({ label: item.title, key: item.key }))}
                  >
                    <Button kind="link" iconName="Download">
                      {t.DOWNLOAD_CSV}
                    </Button>
                  </CSVLink>
                </Col>
                <Col span={24}>
                  <Table columns={columns} dataSource={dataSource} rowKey="id" />
                </Col>
              </Row>
            </Col>
          </Row>
        </Form>
      </Spin>
    </Layout>
  );
}

export default DebtorConversionReport;
