import { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Form, Row, Col, Spin, Radio, Input } from 'antd';

import CurrencyInput from './CurrencyInput';

import { currencyParser, handleCurrencyValidationNegativeAllowed } from '../utils';
import { ClientService } from '../../../../shared/api/ClientService';
import API from '../../../../utils/api';
import useLocale from '../../../../hooks/useLocale';
import eventBus from '../../../../utils/eventBus';

import '../FileAnalysis.scss';

function TrusteeReportDetails({ disabled = false }: { disabled?: boolean }): JSX.Element {
  const { t } = useLocale();
  const { applicationFileId } = useParams<{ applicationFileId: string }>();
  const [form] = Form.useForm<ClientService.FileAnalysisTrusteeReportDetailDto>();

  const [trusteeReportDetailsLoading, setTrusteeReportDetailsLoading] = useState<boolean>(false);
  const [trusteeReportDetailsUpdating, setTrusteeReportDetailsUpdating] = useState<boolean>(false);

  const [initialValues, setInitialValues] = useState<ClientService.FileAnalysisTrusteeReportDetailDto>();

  const requestFileAnalysisTrusteeReportDetails = useCallback(async () => {
    setTrusteeReportDetailsLoading(true);

    const response = await API.getFileAnalysisTrusteeReportDetails(applicationFileId).catch(() =>
      setTrusteeReportDetailsLoading(false)
    );
    if (response) {
      form.setFieldsValue({ ...response });
      setInitialValues(response);

      eventBus.dispatch('dataEntryFormValuesChanged');
    }

    setTrusteeReportDetailsLoading(false);
  }, [applicationFileId, form]);

  const requestUpdateFileAnalysisTrusteeReportDetails = useCallback(
    async (updatedTrusteeReportDetails: ClientService.FileAnalysisBankruptcyDetailDto) => {
      setTrusteeReportDetailsUpdating(true);

      const response = await API.updateFileAnalysisTrusteeReportDetails(updatedTrusteeReportDetails).catch(() =>
        setTrusteeReportDetailsUpdating(false)
      );

      if (response?.result === ClientService.Result.Successful) {
        requestFileAnalysisTrusteeReportDetails();
      }

      setTrusteeReportDetailsUpdating(false);
    },
    [requestFileAnalysisTrusteeReportDetails]
  );

  useEffect(() => {
    requestFileAnalysisTrusteeReportDetails();
  }, [requestFileAnalysisTrusteeReportDetails]);

  const onFinish = useCallback(
    async (values) => {
      const updatedTrusteeReportDetails = {
        ...values,
        fileId: applicationFileId,
        giftsConveyancesRecoveryImpact: currencyParser(values?.giftsConveyancesRecoveryImpact),
        giftsConveyancesAdjUnsecuredClaims: currencyParser(values?.giftsConveyancesAdjUnsecuredClaims),
        anyDefectsRecoveryImpact: currencyParser(values?.anyDefectsRecoveryImpact),
        anyDefectsAdjUnsecuredClaims: currencyParser(values?.anyDefectsAdjUnsecuredClaims),
        affectRecoveryImpactToRecovery: currencyParser(values?.affectRecoveryImpactToRecovery),
        affectRecoveryAdjUnsecuredClaims: currencyParser(values?.affectRecoveryAdjUnsecuredClaims),
      };

      requestUpdateFileAnalysisTrusteeReportDetails(updatedTrusteeReportDetails);
    },
    [applicationFileId, requestUpdateFileAnalysisTrusteeReportDetails]
  );

  const handleSubmit = useCallback(() => {
    form.submit();
  }, [form]);

  const handleEnter = useCallback(
    (event: any) => {
      if (event.key === 'Enter') {
        form.submit();
      }
    },
    [form]
  );

  return (
    <Spin spinning={trusteeReportDetailsLoading || trusteeReportDetailsUpdating}>
      <Form form={form} layout="vertical" className="FileAnalysis__form" onFinish={onFinish} validateTrigger="onSubmit">
        {(_, formInstance) => (
          <>
            <Row gutter={12}>
              {/* Gift Conveyance */}
              <>
                <Col span={24}>
                  <Form.Item
                    name="isGiftsConveyances"
                    label={t.ANY_GIFTS_CONVEYANCES_OR_OTHER_TRANSACTIONS_WHICH_REQUIRE_NOTE_DISCLOSURE}
                  >
                    <Radio.Group
                      buttonStyle="solid"
                      optionType="button"
                      key="postType"
                      onChange={handleSubmit}
                      disabled={disabled}
                    >
                      <Radio.Button value={true}>{t.YES}</Radio.Button>
                      <Radio.Button value={false}>{t.NO}</Radio.Button>
                    </Radio.Group>
                  </Form.Item>
                </Col>
                {formInstance.getFieldValue('isGiftsConveyances') && (
                  <>
                    <Col span={24}>
                      <Form.Item
                        label={t.ADD_DESIRED_NOTE_DISCLOSURE_HERE}
                        name="giftsConveyancesNotes"
                        valuePropName="defaultValue"
                      >
                        <Input
                          size="large"
                          onChange={(e) => form.setFieldsValue({ giftsConveyancesNotes: e.target.value })}
                          onBlur={(e) => {
                            if (e.target.value !== initialValues?.giftsConveyancesNotes) handleSubmit();
                          }}
                          onKeyUp={handleEnter}
                          disabled={disabled}
                        />
                      </Form.Item>
                    </Col>

                    <Col span={24}>
                      <Form.Item
                        label={t.IMPACT_TO_RECOVERY}
                        name="giftsConveyancesRecoveryImpact"
                        rules={[{ validator: handleCurrencyValidationNegativeAllowed }]}
                      >
                        <CurrencyInput
                          negativeAllowed
                          onBlur={() => {
                            if (
                              form.getFieldValue('giftsConveyancesRecoveryImpact') !==
                              initialValues?.giftsConveyancesRecoveryImpact
                            ) {
                              handleSubmit();
                            }
                          }}
                          onKeyUp={handleEnter}
                          disabled={disabled}
                        />
                      </Form.Item>
                    </Col>

                    <Col span={24}>
                      <Form.Item
                        label={t.ADJUSTMENT_TO_UNSECURED_CLAIMS}
                        name="giftsConveyancesAdjUnsecuredClaims"
                        rules={[{ validator: handleCurrencyValidationNegativeAllowed }]}
                      >
                        <CurrencyInput
                          negativeAllowed
                          onBlur={() => {
                            if (
                              form.getFieldValue('giftsConveyancesAdjUnsecuredClaims') !==
                              initialValues?.giftsConveyancesAdjUnsecuredClaims
                            ) {
                              handleSubmit();
                            }
                          }}
                          onKeyUp={handleEnter}
                          disabled={disabled}
                        />
                      </Form.Item>
                    </Col>
                  </>
                )}
              </>

              {/* Defects */}
              <>
                <Col span={24}>
                  <Form.Item name="isAnyDefects" label={t.ANY_DEFECTS_IN_SECURITY}>
                    <Radio.Group
                      buttonStyle="solid"
                      optionType="button"
                      key="postType"
                      onChange={handleSubmit}
                      disabled={disabled}
                    >
                      <Radio.Button value={true}>{t.YES}</Radio.Button>
                      <Radio.Button value={false}>{t.NO}</Radio.Button>
                    </Radio.Group>
                  </Form.Item>
                </Col>
                {formInstance.getFieldValue('isAnyDefects') && (
                  <>
                    <Col span={24}>
                      <Form.Item
                        label={t.PROVIDE_ANY_ADDITIONAL_COMMENTS_HERE}
                        name="anyDefectsNotes"
                        valuePropName="defaultValue"
                      >
                        <Input
                          size="large"
                          onChange={(e) => form.setFieldsValue({ anyDefectsNotes: e.target.value })}
                          onBlur={(e) => {
                            if (e.target.value !== initialValues?.anyDefectsNotes) handleSubmit();
                          }}
                          onKeyUp={handleEnter}
                          disabled={disabled}
                        />
                      </Form.Item>
                    </Col>

                    <Col span={24}>
                      <Form.Item
                        label={t.IMPACT_TO_RECOVERY}
                        name="anyDefectsRecoveryImpact"
                        rules={[{ validator: handleCurrencyValidationNegativeAllowed }]}
                      >
                        <CurrencyInput
                          negativeAllowed
                          onBlur={() => {
                            if (
                              form.getFieldValue('anyDefectsRecoveryImpact') !== initialValues?.anyDefectsRecoveryImpact
                            ) {
                              handleSubmit();
                            }
                          }}
                          onKeyUp={handleEnter}
                          disabled={disabled}
                        />
                      </Form.Item>
                    </Col>

                    <Col span={24}>
                      <Form.Item
                        label={t.ADJUSTMENT_TO_UNSECURED_CLAIMS}
                        name="anyDefectsAdjUnsecuredClaims"
                        rules={[{ validator: handleCurrencyValidationNegativeAllowed }]}
                      >
                        <CurrencyInput
                          negativeAllowed
                          onBlur={() => {
                            if (
                              form.getFieldValue('anyDefectsAdjUnsecuredClaims') !==
                              initialValues?.anyDefectsAdjUnsecuredClaims
                            ) {
                              handleSubmit();
                            }
                          }}
                          onKeyUp={handleEnter}
                          disabled={disabled}
                        />
                      </Form.Item>
                    </Col>
                  </>
                )}
              </>

              {/* Secured shortfall */}
              <>
                <Col span={24}>
                  <Form.Item name="isSecuredShortfall" label={t.IS_THERE_A_SECURED_CREDITOR}>
                    <Radio.Group
                      buttonStyle="solid"
                      optionType="button"
                      key="postType"
                      onChange={handleSubmit}
                      disabled={disabled}
                    >
                      <Radio.Button value={true}>{t.YES}</Radio.Button>
                      <Radio.Button value={false}>{t.NO}</Radio.Button>
                    </Radio.Group>
                  </Form.Item>
                </Col>
                {formInstance.getFieldValue('isSecuredShortfall') && (
                  <Col span={24}>
                    <Form.Item
                      name="isSecuredShortfallParticipate"
                      label={t.WILL_THE_SECURED_CREDITOR_PARTICIPATE_IN_DIVIDEND_DISTRIBUTIONS}
                    >
                      <Radio.Group
                        buttonStyle="solid"
                        optionType="button"
                        key="postType"
                        onChange={handleSubmit}
                        disabled={disabled}
                      >
                        <Radio.Button value={true}>{t.YES}</Radio.Button>
                        <Radio.Button value={false}>{t.NO}</Radio.Button>
                      </Radio.Group>
                    </Form.Item>
                  </Col>
                )}
              </>

              {/* CoSignGuaranteed */}
              <>
                <Col span={24}>
                  <Form.Item name="isCoSignGuaranteed" label={t.ANY_COSIGNED_GUARANTEED_CLAIMS}>
                    <Radio.Group
                      buttonStyle="solid"
                      optionType="button"
                      key="postType"
                      onChange={handleSubmit}
                      disabled={disabled}
                    >
                      <Radio.Button value={true}>{t.YES}</Radio.Button>
                      <Radio.Button value={false}>{t.NO}</Radio.Button>
                    </Radio.Group>
                  </Form.Item>
                </Col>
                {formInstance.getFieldValue('isCoSignGuaranteed') && (
                  <Col span={24}>
                    <Form.Item
                      name="isCoSingGuaranteedContinue"
                      label={t.DOES_THE_AMOUNT_RELATED_TO_A_COSIGNED_GUARANTEED}
                    >
                      <Radio.Group
                        buttonStyle="solid"
                        optionType="button"
                        key="postType"
                        onChange={handleSubmit}
                        disabled={disabled}
                      >
                        <Radio.Button value={true}>{t.YES}</Radio.Button>
                        <Radio.Button value={false}>{t.NO}</Radio.Button>
                      </Radio.Group>
                    </Form.Item>
                  </Col>
                )}
              </>

              {/* Affect Recovery */}
              <>
                <Col span={24}>
                  <Form.Item
                    name="isAffectRecovery"
                    label={t.ANY_FACTS_OR_SITUATIONS_WHICH_COULD_AFFECT_THE_RECOVERY_TO_CREDITORS}
                  >
                    <Radio.Group
                      buttonStyle="solid"
                      optionType="button"
                      key="postType"
                      onChange={handleSubmit}
                      disabled={disabled}
                    >
                      <Radio.Button value={true}>{t.YES}</Radio.Button>
                      <Radio.Button value={false}>{t.NO}</Radio.Button>
                    </Radio.Group>
                  </Form.Item>
                </Col>
                {formInstance.getFieldValue('isAffectRecovery') && (
                  <>
                    <Col span={24}>
                      <Form.Item
                        label={t.TRUSTEE_TO_PREPARE_AND_INSERT_NOTE}
                        name="affectRecoveryNotes"
                        valuePropName="defaultValue"
                      >
                        <Input
                          size="large"
                          onChange={(e) => form.setFieldsValue({ affectRecoveryNotes: e.target.value })}
                          onBlur={(e) => {
                            if (e.target.value !== initialValues?.affectRecoveryNotes) handleSubmit();
                          }}
                          onKeyUp={handleEnter}
                          disabled={disabled}
                        />
                      </Form.Item>
                    </Col>

                    <Col span={24}>
                      <Form.Item
                        label={t.IMPACT_TO_RECOVERY}
                        name="affectRecoveryImpactToRecovery"
                        rules={[{ validator: handleCurrencyValidationNegativeAllowed }]}
                      >
                        <CurrencyInput
                          negativeAllowed
                          onBlur={() => {
                            if (
                              form.getFieldValue('affectRecoveryImpactToRecovery') !==
                              initialValues?.affectRecoveryImpactToRecovery
                            ) {
                              handleSubmit();
                            }
                          }}
                          onKeyUp={handleEnter}
                          disabled={disabled}
                        />
                      </Form.Item>
                    </Col>

                    <Col span={24}>
                      <Form.Item
                        label={t.ADJUSTMENT_TO_UNSECURED_CLAIMS}
                        name="affectRecoveryAdjUnsecuredClaims"
                        rules={[{ validator: handleCurrencyValidationNegativeAllowed }]}
                      >
                        <CurrencyInput
                          negativeAllowed
                          onBlur={() => {
                            if (
                              form.getFieldValue('affectRecoveryAdjUnsecuredClaims') !==
                              initialValues?.affectRecoveryAdjUnsecuredClaims
                            ) {
                              handleSubmit();
                            }
                          }}
                          onKeyUp={handleEnter}
                          disabled={disabled}
                        />
                      </Form.Item>
                    </Col>
                  </>
                )}
              </>
            </Row>
          </>
        )}
      </Form>
    </Spin>
  );
}

export default TrusteeReportDetails;
