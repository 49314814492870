import { LocaleObj } from '../locale/LocalizedStrings';

const TEN_DIGITS = 10;
const EMAIL_REGEX =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

class validator {
  static validateRequiredString = (value: string) => {
    return !!value;
  };

  static validatePhoneNumber = (value: string) => {
    return value.replace(/\s|\(|\)|-|_/g, '').length === TEN_DIGITS;
  };

  static validateEmailAddress = (value: string) => {
    return value.toLowerCase().match(EMAIL_REGEX);
  };

  static validateNumber = (value: string | number): boolean => {
    return value != null && value !== '' && !isNaN(Number(value.toString()));
  };

  static validateNumbersAndLetters = (value: string | number): boolean => {
    return value != null && value !== '' && !!String(value).match(/^[0-9a-zA-Z]+$/);
  };
}

class errorMessage {
  static required = (locale: LocaleObj, description: string) => {
    return description + ' ' + locale.IS_REQUIRED;
  };

  static invalid = (locale: LocaleObj, description: string) => {
    return description + ' ' + locale.IS_INVALID;
  };
}

export { validator, errorMessage };
