import { useState, useEffect, useMemo } from 'react';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { Collapse } from 'antd';
import { Icon } from '@fluentui/react/lib/Icon';
import { useQuery } from 'react-query';

import TaskActionTable from '../TasksContent/TaskActionTable/TaskActionTable';
import AppointmentTable from '../../../components/AppointmentTable/AppointmentTable';
import NotesList from '../NotesContent/NotesList/NotesList';
import PanelExtra from './PanelExtra';

import useLocale from '../../../hooks/useLocale';
import { ClientService } from '../../../shared/api/ClientService';
import { ROUTES } from '../../../constants/routes';
import {
  NEW_TASK_EVENT,
  NEW_NOTE_EVENT,
  NOTE_ADDED_EVENT,
  TASK_ADDED_EVENT,
  NOTE_CHANGE_EVENT,
  TASK_UPDATE_EVENT,
} from '../../../constants/eventBus';
import { NEW_TASK_SEARCH_PARAM } from '../../../constants/searchParams';
import { TASKS_QUERY, NOTES_QUERY, APPOINTEMENTS_QUERY } from '../../../constants/reactQuery';

import { eventBus } from '../../../utils/eventBus';
import { argumentifyNoteSearchCriteria } from '../utils';
import { argumentifyTaskSearchCriteria } from '../TasksContent/utils';
import API from '../../../utils/api';

import './ApplicationOverviewContent.scss';

interface IProps {
  data: ClientService.IApplicationFileDto | null;
}

const TASKS_KEY = 'tasks';
const NOTES_KEY = 'notes';
const APPOINTMENTS_KEY = 'appointments';

const PanelHeader = ({ title, count }: { title: string; count: number }) => (
  <div className="ApplicationOverviewContent__panel-header">
    {title}
    <span>{count}</span>
  </div>
);

const OverviewTab = ({ data }: IProps): JSX.Element => {
  const { t } = useLocale();
  const { Panel } = Collapse;
  const navigate = useNavigate();
  const { search } = useLocation();
  const { applicationFileId } = useParams<{ applicationFileId: string }>();

  const isDuplicate = useMemo(() => data?.isDuplicate, [data]);

  const { data: notes, refetch: requestNotes } = useQuery([NOTES_QUERY], () =>
    API.notesGET(
      ...argumentifyNoteSearchCriteria({
        fileId: applicationFileId,
      })
    )
  );

  const { data: tasks, refetch: requestTasks } = useQuery([TASKS_QUERY], () =>
    API.tasksGET(
      ...argumentifyTaskSearchCriteria({
        fileId: applicationFileId,
        appTaskStatuses: [ClientService.AppTaskStatusEnum.Pending],
      })
    )
  );

  const { data: appointments } = useQuery([APPOINTEMENTS_QUERY], () =>
    API.listAppointmentDetails(applicationFileId, false)
  );

  const [activeKeys, setActiveKeys] = useState<Array<string>>([TASKS_KEY, NOTES_KEY, APPOINTMENTS_KEY]);

  useEffect(() => {
    const searchParams = new URLSearchParams(search);
    if (searchParams.has(NEW_TASK_SEARCH_PARAM)) {
      eventBus.dispatch(NEW_TASK_EVENT);
    }
  }, [search]);

  useEffect(() => {
    eventBus.on(NOTE_ADDED_EVENT, requestNotes);
    return () => {
      eventBus.remove(NOTE_ADDED_EVENT, requestNotes);
    };
  }, [requestNotes]);

  useEffect(() => {
    eventBus.on(NOTE_CHANGE_EVENT, requestNotes);
    return () => {
      eventBus.remove(NOTE_CHANGE_EVENT, requestNotes);
    };
  }, [requestNotes]);

  useEffect(() => {
    eventBus.on(TASK_ADDED_EVENT, requestTasks);
    return () => {
      eventBus.remove(TASK_ADDED_EVENT, requestTasks);
    };
  }, [requestTasks]);

  useEffect(() => {
    eventBus.on(TASK_UPDATE_EVENT, requestTasks);
    return () => {
      eventBus.remove(TASK_UPDATE_EVENT, requestTasks);
    };
  }, [requestTasks]);

  return (
    <Collapse
      ghost
      activeKey={activeKeys}
      expandIcon={({ isActive }) => <Icon iconName={isActive ? 'ChevronUp' : 'ChevronRight'} />}
      onChange={(keys) => setActiveKeys([...keys])}
      className="ApplicationOverviewContent__collapse"
    >
      <Panel
        header={<PanelHeader title={t.APPLICATION_OVERVIEW_TASKS} count={tasks?.totalCount || 0} />}
        key={TASKS_KEY}
        extra={
          <PanelExtra
            addItemTitle={t.ADD_TASK}
            onAddItem={() => eventBus.dispatch(NEW_TASK_EVENT)}
            isActive={true}
            onViewHistory={() => navigate(`${ROUTES.APPLICATION_OVERVIEW}/${data?.id}/${ROUTES.TASKS_HISTORY}`)}
            addAllowed={!isDuplicate}
          />
        }
      >
        <TaskActionTable isHistoryView={false} />
      </Panel>
      <Panel
        header={<PanelHeader title={t.APPLICATION_OVERVIEW_APPOINTMENTS} count={appointments?.length || 0} />}
        key={APPOINTMENTS_KEY}
        extra={
          <PanelExtra
            addItemTitle={t.APPOINTMENT_ADD}
            onAddItem={() =>
              navigate(`${ROUTES.APPLICATION_OVERVIEW}/${data?.id}/${ROUTES.APPOINTMENT_SCHEDULING}`, {
                state: { fromApplicationOverview: true },
              })
            }
            onViewHistory={() => navigate(`${ROUTES.APPLICATION_OVERVIEW}/${data?.id}/${ROUTES.APPOINTMENTS}`)}
            addAllowed={!isDuplicate}
          />
        }
      >
        <AppointmentTable isAllAppointments={false} />
      </Panel>
      <Panel
        header={<PanelHeader title={t.APPLICATION_OVERVIEW_NOTES} count={notes?.totalCount || 0} />}
        key={NOTES_KEY}
        forceRender
        extra={
          <PanelExtra
            addItemTitle={t.ADD_NOTE}
            onAddItem={() => eventBus.dispatch(NEW_NOTE_EVENT)}
            isActive={activeKeys.indexOf(NOTES_KEY) >= 0}
            onViewHistory={() => navigate(`${ROUTES.APPLICATION_OVERVIEW}/${data?.id}/${ROUTES.NOTES_HISTORY}`)}
            addAllowed={!isDuplicate}
          />
        }
      >
        <NotesList pagination={false} maxResultCount={5} />
      </Panel>
    </Collapse>
  );
};

export default OverviewTab;
