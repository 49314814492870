import { ReactNode } from 'react';
import { Radio as AntdRadio, RadioProps, RadioGroupProps } from 'antd';

import styles from './Radio.module.scss';

interface ICommonProps {
  className?: string;
  style?: React.CSSProperties;
  children?: ReactNode;
}

export type CustomRadioProps = RadioProps & ICommonProps;
export type CustomRadioGroupProps = RadioGroupProps & ICommonProps;

function Radio({ className, style, children, ...props }: CustomRadioProps) {
  return (
    <div className={`${styles.AppRadio} ${className ? className : ''}`} style={style}>
      <AntdRadio {...props}>{children}</AntdRadio>
    </div>
  );
}

Radio.Group = ({ className, style, children, ...props }: CustomRadioGroupProps) => {
  return (
    <div
      className={styles.AppRadioGroup}
      // style={style}
    >
      <AntdRadio.Group className={className} style={style} {...props}>
        {children}
      </AntdRadio.Group>
    </div>
  );
};

Radio.Button = ({ className, style, children, ...props }: CustomRadioProps) => {
  return (
    // <div className={`${styles.AppRadioButton} ${className ? className : ''}`}>
    <AntdRadio.Button className={className} style={style} {...props}>
      {children}
    </AntdRadio.Button>
    // </div>
  );
};

export default Radio;
