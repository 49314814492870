import {
  IEmailSearchCriteria,
  INoteSearchCriteria,
  IAvailableTimeslotsSearchCriteria,
  IOfficeLocationsSearchCriteria,
  IDocumentSearchCriteria,
} from './types';
import moment from 'moment';
import { User } from 'oidc-client';

import { ClientService } from '../../shared/api/ClientService';
import styles from '../../styles/style.module.scss';

const unknownAuthor = 'Unknown Author';

export const argumentifyEmailSearchCriteria = ({
  fileId,
  createdDateMin,
  createdDateMax,
  sorting,
  skipCount,
  maxResultCount,
}: IEmailSearchCriteria) => [fileId, createdDateMin, createdDateMax, sorting, skipCount, maxResultCount] as const;

export const argumentifyNoteSearchCriteria = ({
  filterText,
  noteId,
  fileId,
  isPinned,
  creationTimeMin,
  creationTimeMax,
  creatorId,
  documentId,
  documentVerificationId,
  sorting,
  skipCount,
  maxResultCount,
}: INoteSearchCriteria) =>
  [
    filterText,
    noteId,
    fileId,
    isPinned,
    creationTimeMin,
    creationTimeMax,
    creatorId,
    documentId,
    documentVerificationId,
    sorting,
    skipCount,
    maxResultCount,
  ] as const;

export const argumentifyTimeslotsSearchCriteria = ({
  date,
  officeLocationId,
  timeRange,
}: IAvailableTimeslotsSearchCriteria) => [date, officeLocationId, timeRange] as const;

export const argumentifyOfficeLocationsSearchCriteria = ({
  filterText,
  nearbyLatitude,
  nearbyLongitude,
  sorting,
  skipCount,
  maxResultCount,
}: IOfficeLocationsSearchCriteria) =>
  [filterText, nearbyLatitude, nearbyLongitude, sorting, skipCount, maxResultCount] as const;

export const getUserAvatarName = (name?: string) =>
  (name || unknownAuthor)
    ?.split(' ')
    .map((part: string) => part?.[0]?.toUpperCase())
    .join('');

export const getNoteTime = (time?: Date, name?: string) =>
  `${moment(time).format('MM/DD/YYYY @ HH:mm:ss')} | ${name || unknownAuthor}`;

export const getUserFullName = (user?: User | null) => {
  const userProfile = user?.profile;

  let name = '';

  if (userProfile) {
    if (userProfile.given_name && userProfile.family_name) {
      name = `${userProfile.given_name} ${userProfile.family_name}`;
    } else if (userProfile.name) {
      name = userProfile.name;
    }
  }

  return name;
};

export const mapAppFormStatusToSubTabInfo = (
  title: string,
  key: string,
  status: ClientService.AppFormStatusEnum = ClientService.AppFormStatusEnum.NotStarted,
  subtitle?: string
) => {
  switch (status) {
    case ClientService.AppFormStatusEnum.NotStarted:
      return {
        title,
        key,
        subtitle: subtitle || 'Not started',
        iconName: 'CircleFill',
        iconColor: styles.colorSecondary,
      };
    case ClientService.AppFormStatusEnum.Incomplete:
      return {
        title,
        key,
        subtitle: subtitle || 'Incomplete',
        iconName: 'AlertSolid',
        iconColor: styles.colorDanger,
      };
    case ClientService.AppFormStatusEnum.Incomplete_Review:
      return {
        title,
        key,
        subtitle: subtitle || 'Incomplete / Review',
        iconName: 'AlertSolid',
        iconColor: styles.colorDanger,
      };
    case ClientService.AppFormStatusEnum.ReadyForReview:
      return {
        title,
        key,
        subtitle: subtitle || 'Ready For Review',
        iconName: 'CircleFill',
        iconColor: styles.colorWarning,
      };
    case ClientService.AppFormStatusEnum.Reviewed:
      return {
        title,
        key,
        subtitle: subtitle || 'Reviewed',
        iconName: 'CircleFill',
        iconColor: styles.colorGreen,
      };
    case ClientService.AppFormStatusEnum.Complete:
      return {
        title,
        key,
        subtitle: subtitle || 'Completed',
        iconName: 'CompletedSolid',
        iconColor: styles.colorGreen,
      };
  }
};

export const argumentifyDocumentsSearchCriteria = ({
  filterText,
  filterTextlang,
  fileId,
  documentId,
  documentIds,
  documentFolderId,
  documentVerificationStatusId,
  documentVerificationStatuses,
  documentTypeId,
  documentTypes,
  documentPrefixes,
  documentPrefixCodes,
  documentFolders,
  isVisible,
  isDeleted,
  modifiedDateMin,
  modifiedDateMax,
  sorting,
  skipCount,
  maxResultCount,
}: IDocumentSearchCriteria) =>
  [
    filterText,
    filterTextlang,
    fileId,
    documentId,
    documentIds,
    documentFolderId,
    documentVerificationStatusId,
    documentVerificationStatuses,
    documentTypeId,
    documentTypes,
    documentPrefixes,
    documentPrefixCodes,
    documentFolders,
    isVisible,
    isDeleted,
    modifiedDateMin,
    modifiedDateMax,
    sorting,
    skipCount,
    maxResultCount,
  ] as const;
