import { useState } from 'react';
import { Tabs } from 'antd';

import SessionsOverview from './CounsellingViews/SessionsOverview';
import AddSession from './CounsellingViews/AddSession';
import EditSession from './CounsellingViews/EditSession';
import { useApplicationFile } from '../../../../ApplicationOverviewPage';

import './Counselling.scss';

const SESSIONS_TAB = 'sessions';
const CREATE_SESSION_TAB = 'create';
const EDIT_SESSION_TAB = 'edit';

interface ICounsellingProps {
  disableAllFields?: boolean;
}

const Counselling = ({ disableAllFields }: ICounsellingProps) => {
  const { TabPane } = Tabs;

  const [activeKey, setActiveKey] = useState<string>(SESSIONS_TAB);
  const [selectedSessionId, setSelectedSessionId] = useState<string>();

  return (
    <Tabs activeKey={activeKey} renderTabBar={() => <></>} className="Counselling" destroyInactiveTabPane>
      <TabPane key={SESSIONS_TAB}>
        <SessionsOverview
          onAddSession={() => {
            if (!disableAllFields) setActiveKey(CREATE_SESSION_TAB);
          }}
          onSessionSelect={(sessionId: string) => {
            setActiveKey(EDIT_SESSION_TAB);
            setSelectedSessionId(sessionId);
          }}
          disabled={disableAllFields}
        />
      </TabPane>
      <TabPane key={CREATE_SESSION_TAB}>
        <AddSession onSave={() => setActiveKey(SESSIONS_TAB)} onCancel={() => setActiveKey(SESSIONS_TAB)} />
      </TabPane>
      <TabPane key={EDIT_SESSION_TAB}>
        <EditSession
          sessionId={selectedSessionId}
          onSave={() => setActiveKey(SESSIONS_TAB)}
          onCancel={() => setActiveKey(SESSIONS_TAB)}
        />
      </TabPane>
    </Tabs>
  );
};

export default Counselling;
