import { ClientService } from '../../shared/api/ClientService';
import { Icon } from '@fluentui/react/lib/Icon';

import useLocale from '../../hooks/useLocale';

import './InfoBlockPopover.scss';

interface IStageItem extends ClientService.IFileStageDto {
  title: string;
}

function InfoBlockPopover({ value }: { value: number }): JSX.Element {
  const { t } = useLocale();
  const fileStages: IStageItem[] = [
    { title: t.NEW_PROSPECT, enumValue: ClientService.FileStageEnum.NewProspect },
    { title: t.PRE_SIGNUP, enumValue: ClientService.FileStageEnum.PreSignup },
    { title: t.SIGNED, enumValue: ClientService.FileStageEnum.Signed },
    { title: t.FILE_IN_PROCESS, enumValue: ClientService.FileStageEnum.FileInProcess },
    { title: t.CLOSED, enumValue: ClientService.FileStageEnum.Closed },
    { title: t.ANNULLED, enumValue: ClientService.FileStageEnum.Annulled },
    { title: t.TRANSFERRED, enumValue: ClientService.FileStageEnum.Transferred },
  ];

  return (
    <div>
      {fileStages.map((stage) => {
        const enumValue = stage?.enumValue || 0;

        console.log(enumValue, value);
        const isCompleted = enumValue < value;
        const inProgress = enumValue === value;
        return (
          <div
            key={stage.title}
            className={`InfoBlockPopover__item-container ${
              isCompleted
                ? 'InfoBlockPopover__completed'
                : inProgress
                ? 'InfoBlockPopover__in-progress'
                : 'InfoBlockPopover__not-started'
            }`}
          >
            <div className="InfoBlockPopover__item-inner-container">
              <Icon iconName="SkypeCircleCheck" />
              <div className="InfoBlockPopover__item-title">{stage.title}</div>
            </div>
            <div className="InfoBlockPopover__item-status">
              {isCompleted
                ? t.APPLICATION_FILE_STAGE_COMPLETE
                : inProgress
                ? t.APPLICATION_FILE_STAGE_IN_PROGRESS
                : t.APPLICATION_FILE_STAGE_NOT_STARTED}
            </div>
          </div>
        );
      })}
    </div>
  );
}

export default InfoBlockPopover;
