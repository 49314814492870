import { useCallback } from 'react';
import { Row, Col, Menu, Dropdown, Checkbox, Form, Tooltip } from 'antd';
import { Icon } from '@fluentui/react/lib/Icon';

import Button from '../../../../../components/Button/Button';

import useLocale from '../../../../../hooks/useLocale';
import { WEEKDAYS_OPTIONS } from '../constants';

import './CopyDropdown.scss';

interface IProps {
  onSelect?: (weekdays: string[]) => void;
}

interface IForm {
  weekdays: string[];
}

function CopyDropdown({ onSelect }: IProps): JSX.Element {
  const { t } = useLocale();
  const [form] = Form.useForm<IForm>();

  const handleFinish = useCallback(
    (values) => {
      if (onSelect) onSelect(values?.weekdays);
    },
    [onSelect]
  );

  const overlay = (
    <Menu className="CopyDropdown">
      <Form form={form} onFinish={handleFinish} layout="vertical">
        <Form.Item name="weekdays" noStyle>
          <Checkbox.Group options={WEEKDAYS_OPTIONS}></Checkbox.Group>
        </Form.Item>

        <Menu.Item>
          <Form.Item noStyle>
            <Button htmlType="submit">{t.APPLY}</Button>
          </Form.Item>
        </Menu.Item>
      </Form>
    </Menu>
  );

  return (
    <Dropdown
      overlay={overlay}
      placement="bottomLeft"
      trigger={['click']}
      getPopupContainer={(triggerNode: HTMLElement) => triggerNode.parentElement as HTMLElement}
      destroyPopupOnHide
    >
      <Col>
        <Row align="middle" gutter={10} className="DocumentsContent__link--primary">
          <Col>
            <Tooltip title={t.COPY}>
              <Icon iconName="Copy" className="CopyDropdown__copy-icon" />
            </Tooltip>
          </Col>
        </Row>
      </Col>
    </Dropdown>
  );
}

export default CopyDropdown;
