import { Col, Row, Divider } from 'antd';

import UploadDataItem, { IUploadDataItem } from '../../../../../../components/UploadDataItem/UploadDataItem';
import FormSectionTitle from '../../../../../../components/FormSectionTitle/FormSectionTitle';

interface IUploadSection extends IUploadDataItem {
  sectionTitle: string;
  hasDivider?: boolean;
}

const UploadSection = ({ sectionTitle, hasDivider = true, ...props }: IUploadSection) => (
  <>
    <Row>
      <Col span={24}>
        <FormSectionTitle titleText={sectionTitle} style={{ paddingBottom: 4, paddingTop: 24 }} />
      </Col>
      <UploadDataItem {...props} dataRowProps={{ justify: 'end' }} colSpan={props.colSpan || 12} />
    </Row>

    {hasDivider && <Divider style={{ marginBottom: 0 }} />}
  </>
);

export default UploadSection;
