import { IOfficeLocationsSearchCriteria, IAvailableTimeslotsSearchCriteria } from './types';
import { ClientService } from '../../shared/api/ClientService';

export const argumentifyOfficeLocationsSearchCriteria = ({
  filterText,
  nearbyLatitude,
  nearbyLongitude,
  includeInactive,
  isActive,
  sorting,
  skipCount,
  maxResultCount,
}: IOfficeLocationsSearchCriteria) =>
  [filterText, nearbyLatitude, nearbyLongitude, includeInactive, isActive, sorting, skipCount, maxResultCount] as const;

export const argumentifyTimeslotsSearchCriteria = ({
  fileId,
  date,
  officeLocationId,
  timeRange,
  meetingTypeId,
  appointmentTypeId,
  teamNames,
}: IAvailableTimeslotsSearchCriteria) =>
  [fileId, date, officeLocationId, timeRange, meetingTypeId, appointmentTypeId, teamNames] as const;

export const convertMeetingTypeToReadable = (
  meetingType?: ClientService.MeetingTypeDto,
  agent?: ClientService.AvailableAgentDto
) => {
  if (!meetingType) return '-';
  if (!agent) return meetingType?.name;
  return `${meetingType?.name} with ${agent?.firstName || ''} ${agent.lastName || ''}`;
};

export const convertOfficeToReadable = (office?: ClientService.OfficeLocationDto) => {
  if (!office) return 'Office: -';
  return `${office?.name || '-'}: ${office?.streetName || ''} ${office?.streetNumber || ''}  ${office?.city || ''} ${
    office?.postalCode || '-'
  }`;
};

export const getAvailableTimeslotsByMeetingType = (
  appointmentTimes?: ClientService.AppointmentTimeSlotDto[],
  meetingTypeId?: string
) =>
  appointmentTimes?.filter((item) => {
    if (!meetingTypeId) return item?.availableAgents;

    const isFound = item?.availableAgents?.find((agent) =>
      agent?.meetingTypes?.find((type) => type.id === meetingTypeId)
    );
    if (isFound) {
      return item?.availableAgents?.filter((agent) => agent?.meetingTypes?.find((type) => type.id === meetingTypeId));
    }

    return false;
  });

export const getMeetingTypeIfOnlyOneAvailable = (
  appointmentTimes?: ClientService.AppointmentTimeSlotDto[],
  meetingTypes?: ClientService.LookupDto[]
) => {
  const inPersonMeetingTypeId = meetingTypes?.find(
    (item) => item.enumValue === ClientService.MeetingTypeEnum.InPerson
  )?.id;
  const phoneVideoMeetingTypeId = meetingTypes?.find(
    (item) => item.enumValue === ClientService.MeetingTypeEnum.Phone
  )?.id;
  const countInPerson = getAvailableTimeslotsByMeetingType(appointmentTimes, inPersonMeetingTypeId)?.length;
  const countPhoneVideo = getAvailableTimeslotsByMeetingType(appointmentTimes, phoneVideoMeetingTypeId)?.length;
  if (!countInPerson && countPhoneVideo) return phoneVideoMeetingTypeId;
  if (!countPhoneVideo && countInPerson) return inPersonMeetingTypeId;
  return undefined;
};

export const setTimeslotSpanSize = (
  meetingTypeId?: string,
  timeslotMeetingTypeEnum?: ClientService.MeetingTypeEnum,
  meetingTypes?: ClientService.LookupDto[]
) => {
  const meetingType = meetingTypes?.find((item) => item?.id === meetingTypeId);

  if (!Boolean(meetingType)) return 9;
  if (meetingType?.enumValue === timeslotMeetingTypeEnum) return 18;
  return 0;
};

/**
 * Disabled. Replaced by new method getAgentByPreselectRuleForAppointment. #20024
 * @param agents
 * @param meetingTypeId
 * @returns
 */
export const getAgentByPreselectRule = (agents?: ClientService.AvailableAgentDto[], meetingTypeId?: string) => {
  const availablaAgentsByMeetingType = agents?.filter((agent: ClientService.AvailableAgentDto) =>
    agent?.meetingTypes?.find((type) => type.id === meetingTypeId)
  );

  const sorted = availablaAgentsByMeetingType?.sort((a, b) => {
    if (Number(a?.noOfPDSAppointmentsForDay) - Number(b?.noOfPDSAppointmentsForDay) === 0) {
      return Number(a?.weeklyAverageOfPDSAppointments) - Number(b?.weeklyAverageOfPDSAppointments);
    }
    return Number(a?.noOfPDSAppointmentsForDay) - Number(b?.noOfPDSAppointmentsForDay);
  });

  return sorted?.[0]?.agentId;
};

// TODO. Enable back in R4
/**
 * Note: Sorting by noOfPDSAppointmentsForDay has completed in server side, no need to do it again here.
 * Also need to consider select available agent based on current debtor's preferred language code.
 * Above processes has complated in server side, here just simply pickup the first item from the drop down list of available agents.
 * #20024
 *
 * @returns one available agent's Id. Note: It is always the first item in the drop down.
 */
export const getAgentByPreselectRuleForAppointment = (
  agents?: ClientService.AvailableAgentDto[],
  meetingTypeId?: string
) => {
  const availableAgentsByMeetingType = agents?.filter((agent: ClientService.AvailableAgentDto) =>
    agent?.meetingTypes?.find((type) => type.id === meetingTypeId)
  );

  return availableAgentsByMeetingType?.[0]?.agentId;
};
