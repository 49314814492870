export type SystemColorsType = 'dark-gray' | 'gray-text' | 'green' | 'red' | 'orange' | 'blue-special';

export const getAsSystemColorOrUnchanged = (color: string | SystemColorsType) => {
  if (color === 'green') return '#54a575';
  if (color === 'red') return '#ed1a3b';
  if (color === 'orange') return '#fe7a4a';
  if (color === 'blue-special') return '#14a4d3';
  if (color === 'dark-gray') return '#47576b';
  if (color === 'gray-text') return '#9e9e9e';
  return color;
};
