import { ClientService } from '../../shared/api/ClientService';
import { IAssignedTasksSearchCriteria, ITaskActionSearchCriteria } from './types';

export const argumentifyAssignedTasksSearchCriteria = ({
  appTaskId,
  appTaskIds,
  filterText,
  description,
  openDateMin,
  openDateMax,
  closeDateMin,
  closeDateMax,
  assignedAgentIds,
  assignedTeamIds,
  appTaskNames,
  appTaskStatuses,
  fileId,
  fileStages,
  fileProcesses,
  sorting,
  skipCount,
  maxResultCount,
}: IAssignedTasksSearchCriteria) =>
  [
    appTaskId,
    appTaskIds,
    filterText,
    description,
    openDateMin,
    openDateMax,
    closeDateMin,
    closeDateMax,
    assignedAgentIds,
    assignedTeamIds,
    appTaskNames,
    appTaskStatuses,
    fileId,
    fileStages,
    fileProcesses,
    sorting,
    skipCount,
    maxResultCount,
  ] as const;

export const argumentifyTaskActionSearchCriteria = ({
  filterText,
  filterSubjectText,
  fileId,
  description,
  followUpDateMin,
  followUpDateMax,
  openDateMin,
  openDateMax,
  closeDateMin,
  closeDateMax,
  assignedAgentId,
  assignedTeamId,
  appTaskId,
  appTaskActionIds,
  appTaskActionId,
  appTaskActionNameId,
  appTaskActionStatusId,
  appTaskActionStatus,
  teamTypes,
  appTaskActionNames,
  calledFromDashboard,
  sorting,
  skipCount,
  maxResultCount,
}: ITaskActionSearchCriteria) =>
  [
    filterText,
    filterSubjectText,
    fileId,
    description,
    followUpDateMin,
    followUpDateMax,
    openDateMin,
    openDateMax,
    closeDateMin,
    closeDateMax,
    assignedAgentId,
    assignedTeamId,
    appTaskId,
    appTaskActionIds,
    appTaskActionId,
    appTaskActionNameId,
    appTaskActionStatusId,
    appTaskActionStatus,
    teamTypes,
    appTaskActionNames,
    calledFromDashboard,
    sorting,
    skipCount,
    maxResultCount,
  ] as const;

export const ModifyGetAppTasksInput = (input: ClientService.GetAppTasksInput) => {
  if (input.appTaskId === null) input.appTaskId = undefined;

  if (input.fileId === null) input.fileId = undefined;

  return input;
};
