import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import 'normalize.css';
import App from './components/App/App';
import reportWebVitals from './reportWebVitals';
import { initializeIcons } from '@uifabric/icons';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { ReactPlugin } from '@microsoft/applicationinsights-react-js';
import { QueryClient, QueryClientProvider } from 'react-query';

initializeIcons(undefined, { disableWarnings: true });

const queryClient = new QueryClient();
const reactPlugin = new ReactPlugin();
const appInsights = new ApplicationInsights({
  config: {
    connectionString: `InstrumentationKey=${process.env.REACT_APP_INSIGHTS_INSTRUMENTATION_KEY};IngestionEndpoint=${process.env.REACT_APP_INSIGHTS_INGESTION_ENDPOINT}`,
    enableAutoRouteTracking: true,
    extensions: [reactPlugin],
    autoTrackPageVisitTime: true,
    isBrowserLinkTrackingEnabled: true,
    enableRequestHeaderTracking: true,
    enableResponseHeaderTracking: true,
    enableAjaxErrorStatusText: true,
    enableAjaxPerfTracking: true,
  },
});

ReactDOM.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <App />
    </QueryClientProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
appInsights.loadAppInsights();
