import { useCallback } from 'react';
import { Avatar, Comment } from 'antd';
import moment from 'moment';
import { Icon } from '@fluentui/react/lib/Icon';

import NoteEditor from '../NoteEditor/NoteEditor';
import NoteDeleteConfirmation from './NoteDeleteConfirmation';

import useLocale from '../../../../hooks/useLocale';
import useModal from '../../../../hooks/useModal';

import { DATE_FORMAT_NOTE } from '../../../../constants/common';
import { NOTE_CHANGE_EVENT, NOTE_CHANGE_INIT_EVENT } from '../../../../constants/eventBus';
import { eventBus } from '../../../../utils/eventBus';
import genericMessage from '../../../../utils/genericMessage';
import { ClientService } from '../../../../shared/api/ClientService';
import API from '../../../../utils/api';

import './Note.scss';

interface IProps {
  item: ClientService.INoteDto;
  isEditMode?: boolean;
  documentId?: string;
  documentVerificationId?: string;
  isAvatarVisible?: boolean;
  applicationFileId: string;
  editable?: boolean;
}

const Note = ({
  item,
  isEditMode = false,
  documentVerificationId,
  documentId,
  isAvatarVisible = true,
  applicationFileId,
  editable = true,
}: IProps) => {
  const { t } = useLocale();
  const { showModal, closeModal } = useModal();

  const avatarName = item?.creatorName
    ?.split(' ')
    .map((part: string) => part?.[0]?.toUpperCase())
    .join('');

  const handlePin = useCallback(async () => {
    if (!item.id) return;
    await API.pin(item?.id, !item?.isPinned);

    eventBus.dispatch(NOTE_CHANGE_EVENT);
  }, [item]);

  const handleDelete = useCallback(async () => {
    if (!item.id) return;
    await API.notesDELETE(item?.id);

    genericMessage.success(t.NOTE_DELETE_SUCCESS);
    eventBus.dispatch(NOTE_CHANGE_EVENT);
  }, [item.id, t.NOTE_DELETE_SUCCESS]);

  const handleConfirmDelete = useCallback(() => {
    showModal(
      <NoteDeleteConfirmation
        onCancel={closeModal}
        onOk={() => {
          handleDelete();
          closeModal();
        }}
      />
    );
  }, [closeModal, handleDelete, showModal]);

  if (isEditMode && editable) {
    return (
      <NoteEditor
        item={item}
        documentId={documentId}
        documentVerificationId={documentVerificationId}
        applicationFileId={applicationFileId as string}
      />
    );
  }

  return (
    <Comment
      className="Note"
      author={item?.creatorName}
      avatar={
        isAvatarVisible ? (
          <Avatar alt={item?.creatorName} size={32} className="Note__avatar">
            {avatarName}
          </Avatar>
        ) : null
      }
      content={<div>{item?.content}</div>}
      datetime={
        <div className="Note__header">
          <div>{`${(moment(item?.creationTime).local(true) ?? moment()).format(DATE_FORMAT_NOTE)} ${
            avatarName ? '| ' + avatarName : ''
          }`}</div>

          {editable && (
            <div className="Note__header--icons-container">
              <Icon iconName="Delete" onClick={handleConfirmDelete} />
              <Icon iconName="Edit" onClick={() => eventBus.dispatch(NOTE_CHANGE_INIT_EVENT, { noteId: item?.id })} />
              <Icon iconName={item?.isPinned ? 'PinnedSolid' : 'Pinned'} onClick={handlePin} />
            </div>
          )}
        </div>
      }
    />
  );
};

export default Note;
