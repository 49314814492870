import { useCallback, useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { Modal, Form, Col, Row, Checkbox, Select, Spin, message, Input } from 'antd';

import SelectWithResultingList from '../components/SelectWithResultingList/SelectWithResultingList';
import Button from '../../../components/Button/Button';
import SelectLookupDto from '../../../components/SelectLookupDto/SelectLookupDto';

import useLocale from '../../../hooks/useLocale';
import useDebounce from '../../../hooks/useDebounce';
import useMenu from '../../../hooks/useMenu';

import { LANGUAGES_QUERY, ROLES_QUERY, USE_QUERY_OPTIONS } from '../../../constants/reactQuery';
import { IdentityService } from '../../../shared/api/IdentityService';
import { ClientService } from '../../../shared/api/ClientService';
import { PdsRoleClientAPI, PdsUserClientAPI } from '../../../utils/identityServiceApi';
import { AuthorizeService } from '../../../components/Auth/AuthorizeService';
import API from '../../../utils/api';

import './UserManagement.scss';
import genericMessage from '../../../utils/genericMessage';
import { argumentifyListUsersSearchCriteria } from '../../ApplicationOverviewPage/ApplicationOverviewContent/AdminInfo/General/utils';
import FormInput from '../../../components/Forms/FormInput/FormInput';

interface IProps {
  id?: string;
  onOk: () => void;
  onCancel: () => void;
}

const UserEditor = ({ id, onOk, onCancel }: IProps) => {
  const { t } = useLocale();
  const [form] = Form.useForm<IdentityService.CreatePdsUserInput>();
  const { Option } = Select;
  const [searchTerm, setSearchTerm] = useState<string>('');

  const { setIsSuperAdmin, setIsSupervisorAllocation } = useMenu();

  const [users, setUsers] = useState<IdentityService.AdUserDto[]>();
  const [selectedUser, setSelectedUser] = useState<IdentityService.AdUserDto>();
  const [teams, setTeams] = useState<ClientService.LookupDto[]>();
  const [teamTypes, setTeamTypes] = useState<ClientService.LookupDto[]>();
  const [estateAdministrators, setEstateAdministrators] = useState<IdentityService.PdsUserListItem[]>([]);
  const [trustees, setTrustees] = useState<IdentityService.PdsUserListItem[]>([]);
  const [hasAgentRole, setHasAgentRole] = useState(false);
  const [noRoleSelected, hasRole] = useState(false);

  const [disabledForm, setDisabledForm] = useState<boolean>(true);
  const [disabledFieldsNames, setDisabledFieldsNames] = useState<string[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  const [activeOffices, setActiveOffices] = useState<ClientService.OfficeLocationDto[]>();
  const [disabledOffice, setDisabledOffice] = useState<ClientService.LookupDto>();

  const value = useDebounce(searchTerm);
  const user = AuthorizeService.getCurrentUserInfo();
  const [isEstateAdminForFiles, setIsEstateAdminForFiles] = useState(false);
  const [isTrusteeForFiles, setIsTrusteeForFiles] = useState(false);
  const [isCourtTrusteeForFiles, setIsCourtTrusteeForFiles] = useState(false);
  const [isActive, setIsActive] = useState(false);

  const { data: languages } = useQuery([LANGUAGES_QUERY], () => API.listLanguages(), USE_QUERY_OPTIONS);
  const { data: roles } = useQuery([ROLES_QUERY], () => PdsRoleClientAPI.listRoles(), USE_QUERY_OPTIONS);

  const requestOffices = useCallback(async () => {
    const response = await API.listOfficeLocations(false);
    if (response) setActiveOffices(response);
  }, []);

  const requestUsers = useCallback(
    async (value?: string) => {
      const response = await PdsUserClientAPI.searchAdUsers(value);

      if (response) {
        setUsers(response);

        if (response?.length > 1) {
          setDisabledForm(true);
          form.resetFields();
        }
      }
    },
    [form]
  );

  const requestTeamsByTeamTypes = useCallback(
    async (teamTypeIds?: string[], teamIds?: string[]) => {
      if (!teamTypeIds) return;

      setLoading(true);
      let teams: ClientService.LookupDto[] = [];
      for (const id of teamTypeIds) {
        const response = await API.listTeams(id).catch(() => setLoading(false));
        if (response) {
          teams = [...teams, ...response];
        }
      }
      setLoading(false);

      setTeams(teams);
      form.setFieldsValue({ teamIds: teamIds?.filter((teamId) => teams?.find((team) => team.id === teamId)) });
    },
    [form]
  );

  const checkHasAgentRole = useCallback(
    (roleIds?: string[]) => {
      if (roleIds) {
        if (roleIds.length == 0) hasRole(false);
        else hasRole(true);
        const selectedRoles = roleIds.map((id) => roles?.find((role) => role.id === id)?.name);
        const isAgent = selectedRoles.some((s) => s === 'Agent');
        setHasAgentRole(isAgent);
      } else {
        hasRole(false);
        setHasAgentRole(false);
      }
    },
    [roles]
  );

  const requestTeamTypesByRoles = useCallback(
    async (roleIds?: string[], teamTypeIds?: string[], teamIds?: string[]) => {
      checkHasAgentRole(roleIds);

      if (!roleIds || !roleIds?.length) {
        setTeamTypes(undefined);
        setTeams(undefined);
        form.setFieldsValue({
          teamTypeIds: undefined,
          teamIds: undefined,
        });
        return;
      }

      setLoading(true);
      const teamTypes = await API.listTeamTypes(roleIds).catch(() => setLoading(false));
      setLoading(false);

      if (teamTypes) setTeamTypes(teamTypes);
      const filteredTeamTypeIds = teamTypeIds?.filter((teamTypeId) =>
        teamTypes?.find((item) => item.id === teamTypeId)
      );
      form.setFieldsValue({ teamTypeIds: filteredTeamTypeIds });

      await requestTeamsByTeamTypes(filteredTeamTypeIds, teamIds);
    },
    [checkHasAgentRole, form, requestTeamsByTeamTypes]
  );

  const validateForm = useCallback(
    (userData?: IdentityService.PdsUserDto) => {
      if (userData?.hasActions) message.warning(t.USER_ACCOUNT_PENDING_ACTIONS_ERROR, 5);
      if (userData?.hasTasks) message.warning(t.USER_ACCOUNT_PENDING_TASKS_ERROR, 5);
      if (userData?.hasAppointments) message.warning(t.USER_ACCOUNT_PENDING_APPOINTMENTS_ERROR, 5);

      return (
        userData?.hasActions ||
        userData?.hasTasks ||
        userData?.hasAppointments ||
        userData?.hasAgentFiles ||
        userData?.hasTrusteeCourts ||
        userData?.hasEstateAdministratorFiles
      );
    },
    [
      t.USER_ACCOUNT_AGENT_FILES_ERROR,
      t.USER_ACCOUNT_ESTATE_ADMIN_FILES_ERROR,
      t.USER_ACCOUNT_PENDING_ACTIONS_ERROR,
      t.USER_ACCOUNT_PENDING_TASKS_ERROR,
      t.USER_ACCOUNT_PENDING_APPOINTMENTS_ERROR,
      t.USER_ACCOUNT_TRUSTEE_COURTS_ERROR,
    ]
  );

  const disableFieldsOnValidationFailed = useCallback((userData?: IdentityService.PdsUserDto) => {
    let disabledFields = [];

    if (
      userData?.hasActions ||
      userData?.hasAppointments ||
      userData?.hasAgentFiles ||
      userData?.hasTrusteeCourts ||
      userData?.hasEstateAdministratorFiles
    ) {
      disabledFields.push(...['roleIds', 'teamTypeIds', 'teamIds']);
    }

    if (userData?.hasActions || userData?.hasTasks || userData?.hasAppointments) {
      disabledFields.push('isActive');
    }

    if (userData?.hasAppointments) {
      disabledFields.push('preferredLanguageId');
    }

    setDisabledFieldsNames(disabledFields);
  }, []);

  const handleDisabledOfficeId = useCallback(
    async (officeLocationId?: string) => {
      const activeOfficeDto = activeOffices?.find((item) => item?.id === officeLocationId);
      if (!activeOfficeDto) {
        const allOffices = await API.listOfficeLocations(true);

        if (allOffices) {
          const disabledOfficeDto = allOffices?.find((item) => item?.id === officeLocationId);
          setDisabledOffice(disabledOfficeDto);
        }
      }
    },
    [activeOffices]
  );

  const requestUser = useCallback(
    async (userId?: string) => {
      setLoading(true);
      const response = await PdsUserClientAPI.getUser(userId).catch(() => setLoading(false));
      setLoading(false);

      if (response) {
        setDisabledForm(false);

        form.setFieldsValue(response);
        handleDisabledOfficeId(response?.officeLocationId);
        await requestTeamTypesByRoles(response?.roleIds, response?.teamTypeIds, response?.teamIds);
        setSelectedUser(response);

        setIsActive(response?.isActive ?? false);
        setIsEstateAdminForFiles(response?.isEstateAdminOfFiles ?? false);
        setIsTrusteeForFiles(response?.isTrusteeOfFiles ?? false);
        setIsCourtTrusteeForFiles(response?.hasTrusteeCourts ?? false);

        if (validateForm(response)) {
          disableFieldsOnValidationFailed(response);
        }
      }
    },
    [form, handleDisabledOfficeId, requestTeamTypesByRoles, validateForm, disableFieldsOnValidationFailed]
  );

  const requestCreateUser = useCallback(
    async (values) => {
      setLoading(true);
      const response = await PdsUserClientAPI.createUser({ adUserId: selectedUser?.adUserId, ...values }).catch(
        (error) => {
          setLoading(false);
          genericMessage.error({}, error?.message);
        }
      );
      setLoading(false);

      if (response?.result === ClientService.Result.Successful) {
        if (onOk) onOk();
      } else {
        genericMessage.error({}, response?.messages?.[0].body);
      }
    },
    [onOk, selectedUser?.adUserId]
  );

  const requestRoles = useCallback(
    async (email?: string) => {
      const isSuperAdmin = await PdsUserClientAPI.isSuperAdmin(email);
      setIsSuperAdmin(isSuperAdmin || false);

      const isSupervisorAllocation = await PdsUserClientAPI.isSupervisorAllocation(email);
      setIsSupervisorAllocation(isSupervisorAllocation || false);
    },
    [setIsSuperAdmin, setIsSupervisorAllocation]
  );

  const requestUpdateUser = useCallback(
    async (values) => {
      setLoading(true);
      const response = await PdsUserClientAPI.updateUser({ id, ...values }).catch((error) => {
        setLoading(false);
        genericMessage.error({}, error?.error?.message);
      });
      setLoading(false);

      if (response?.result === ClientService.Result.Successful) {
        if (id === user?.profile?.sub) {
          requestRoles(user?.profile?.email);
        }

        if (onOk) onOk();
      } else {
        genericMessage.error({}, response?.messages?.[0].body);
      }
    },
    [id, user?.profile?.sub, user?.profile?.email, onOk, requestRoles]
  );

  const onFinish = useCallback(
    async (values) => {
      if (id) {
        await requestUpdateUser(values);
      } else {
        await requestCreateUser(values);
      }
    },
    [requestCreateUser, requestUpdateUser, id]
  );

  const handleValuesChange = useCallback(
    async (value, values) => {
      if (Object.keys(value)?.indexOf('teamTypeIds') >= 0) {
        await requestTeamsByTeamTypes(value?.teamTypeIds, values?.teamIds);
        return;
      }

      if (Object.keys(value)?.indexOf('roleIds') >= 0) {
        await requestTeamTypesByRoles(value?.roleIds, values?.teamTypeIds, values?.teamIds);
        return;
      }

      if (Object.keys(value)?.indexOf('isActive') >= 0) {
        setIsActive(value?.isActive ?? false);
      }
    },
    [requestTeamTypesByRoles, requestTeamsByTeamTypes]
  );

  useEffect(() => {
    if (value?.trim()?.length >= 3) requestUsers(value);
  }, [requestUsers, value]);

  useEffect(() => {
    if (id) requestUser(id);
  }, [requestUser, id]);

  useEffect(() => {
    requestOffices();
  }, [requestOffices]);

  useEffect(() => {
    if (isEstateAdminForFiles && estateAdministrators?.length == 0) updateEstateAdministrators();
    if ((isTrusteeForFiles || isCourtTrusteeForFiles) && trustees?.length == 0) updateTrustees();
  }, [isCourtTrusteeForFiles, isEstateAdminForFiles, isTrusteeForFiles, isActive]);

  const updateEstateAdministrators = useCallback(async () => {
    let estateAdministrators = await PdsUserClientAPI.listUsers(
      ...argumentifyListUsersSearchCriteria({
        roleId: roles?.find((r) => r?.name === 'Estate Administrator')?.id,
        isActive: true,
      })
    );

    if (estateAdministrators) {
      setEstateAdministrators(estateAdministrators?.items || []);
    }
  }, [roles, setEstateAdministrators]);

  const updateTrustees = useCallback(async () => {
    let trustees = await PdsUserClientAPI.listUsers(
      ...argumentifyListUsersSearchCriteria({ roleId: roles?.find((r) => r?.name === 'Trustee')?.id, isActive: true })
    );

    if (trustees) {
      setTrustees(trustees?.items || []);
    }
  }, [roles, setTrustees]);

  return (
    <Modal
      destroyOnClose
      centered
      visible
      title={id ? t.EDIT_USER : t.ADD_USER}
      width={1080}
      footer={null}
      onCancel={onCancel}
      className="UserManagement__modal"
    >
      <Spin spinning={loading}>
        <Form form={form} onFinish={onFinish} onValuesChange={handleValuesChange} initialValues={{ isActive: true }}>
          <Row gutter={40}>
            <Col span={12}>
              {!id && (
                <Row gutter={10}>
                  <Col span={23}>
                    <Form.Item label={t.SEARCH_BY_EMAIL_ID} labelCol={{ span: 8 }} labelAlign="left">
                      <Select
                        allowClear
                        showSearch
                        size="large"
                        value={searchTerm}
                        onChange={setSearchTerm}
                        onSearch={setSearchTerm}
                        onSelect={(emailAddress: string) => {
                          const selectedUser = users?.find((item) => item.emailAddress === emailAddress);
                          setSelectedUser(selectedUser);
                          setDisabledForm(false);
                        }}
                        showArrow={false}
                        filterOption={false}
                      >
                        {users?.map((user) => (
                          <Option key={user?.adUserId} value={user?.emailAddress}>
                            {user.emailAddress}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col span={1}></Col>
                </Row>
              )}
            </Col>
          </Row>

          <Row gutter={40}>
            {selectedUser && (
              <>
                <Col span={12} offset={0}>
                  <Form.Item
                    label={t.FIRST_NAME}
                    labelCol={{ span: 8 }}
                    labelAlign="left"
                    name={'firstName'}
                    rules={[{ required: true, message: t.REQUIRED_FIELD }]}
                  >
                    <Input size={'large'} />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    label={t.LAST_NAME}
                    labelCol={{ span: 8 }}
                    labelAlign="left"
                    name={'lastName'}
                    rules={[{ required: true, message: t.REQUIRED_FIELD }]}
                  >
                    <Input size={'large'} />
                  </Form.Item>
                </Col>

                <Col span={12}>
                  <Form.Item label={t.EMAIL_ID} labelCol={{ span: 8 }} labelAlign="left">
                    <div>{!disabledForm ? selectedUser?.emailAddress : '-'}</div>
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item label={t.PHONE_NUMBER} labelCol={{ span: 8 }} labelAlign="left">
                    <div>{!disabledForm ? selectedUser?.phoneNumber : '-'}</div>
                  </Form.Item>
                </Col>

                <Col span={12}>
                  <SelectWithResultingList
                    name="roleIds"
                    data={roles}
                    label={t.ROLE}
                    labelCol={{ span: 8 }}
                    required
                    disabled={disabledForm}
                    canDelete={true}
                  />
                </Col>

                <Col span={12}>
                  <Form.Item
                    name="preferredLanguageId"
                    label={t.PREFERRED_LANGUAGE}
                    labelCol={{ span: 8 }}
                    labelAlign="left"
                    rules={[{ required: true, message: t.REQUIRED_FIELD }]}
                  >
                    <SelectLookupDto data={languages} />
                  </Form.Item>
                </Col>

                <Col span={12}>
                  <Form.Item noStyle shouldUpdate>
                    <SelectWithResultingList
                      name="teamTypeIds"
                      data={teamTypes}
                      label={t.TEAM_TYPE}
                      labelCol={{ span: 8 }}
                      required
                      disabled={disabledForm}
                      canDelete={true}
                    />
                  </Form.Item>
                </Col>

                <Col span={12}>
                  <Form.Item
                    label={t.DEFAULT_OFFICE}
                    labelCol={{ span: 8 }}
                    labelAlign="left"
                    name="officeLocationId"
                    rules={[{ required: true, message: t.REQUIRED_FIELD }]}
                  >
                    <SelectLookupDto
                      data={disabledOffice ? [disabledOffice, ...(activeOffices || [])] : activeOffices}
                      disabled={disabledForm || disabledFieldsNames?.indexOf('officeLocationId') >= 0}
                      showSearch
                    />
                  </Form.Item>
                </Col>

                <Col span={12}>
                  <SelectWithResultingList
                    name="teamIds"
                    data={teams}
                    label={t.TEAM}
                    labelCol={{ span: 8 }}
                    required
                    disabled={disabledForm}
                    canDelete={true}
                  />
                </Col>
                <Col span={12}>
                  <Form.Item
                    name="isActive"
                    valuePropName="checked"
                    label={t.STATUS}
                    labelCol={{ span: 8 }}
                    labelAlign="left"
                  >
                    <Checkbox disabled={disabledForm || disabledFieldsNames?.indexOf('isActive') >= 0} />
                  </Form.Item>
                </Col>
                {!isActive && isEstateAdminForFiles && (
                  <Col span={12}>
                    <Form.Item noStyle shouldUpdate>
                      <FormInput
                        type="select"
                        name="estateAdministratorId"
                        label={t.ESTATE_ADMINISTRATOR}
                        labelCol={{ span: 8 }}
                        labelAlign="left"
                        required
                        disabled={disabledForm}
                        optionsList={estateAdministrators?.map((estateAdmin) => ({
                          value: estateAdmin.id,
                          label: estateAdmin?.fullName,
                        }))}
                      />
                    </Form.Item>
                  </Col>
                )}
                {!isActive && isTrusteeForFiles && (
                  <Col span={12}>
                    <Form.Item noStyle shouldUpdate>
                      <FormInput
                        type="select"
                        name="trusteeId"
                        label={t.TRUSTEE}
                        labelCol={{ span: 8 }}
                        labelAlign="left"
                        required
                        disabled={disabledForm}
                        optionsList={trustees?.map((trustee) => ({
                          value: trustee.id,
                          label: trustee?.fullName,
                        }))}
                      />
                    </Form.Item>
                  </Col>
                )}
                {!isActive && isCourtTrusteeForFiles && (
                  <Col span={12}>
                    <Form.Item noStyle shouldUpdate>
                      <FormInput
                        type="select"
                        name="courtTrusteeId"
                        label={t.COURT_TRUSTEE}
                        labelCol={{ span: 8 }}
                        labelAlign="left"
                        required
                        disabled={disabledForm}
                        optionsList={trustees?.map((trustee) => ({
                          value: trustee.id,
                          label: trustee?.fullName,
                        }))}
                      />
                    </Form.Item>
                  </Col>
                )}
              </>
            )}
          </Row>

          <Row gutter={12} justify="end" className="TaskEditor__footer">
            <Col>
              <Button kind="cancel" onClick={onCancel}>
                {t.CANCEL}
              </Button>
            </Col>
            <Col>
              <Button kind="primary" htmlType="submit">
                {t.SAVE}
              </Button>
            </Col>
          </Row>
        </Form>
      </Spin>
    </Modal>
  );
};

export default UserEditor;
