import { Modal, Form, Row, Col, Table } from 'antd';
import moment from 'moment';

import Button from '../../components/Button/Button';

import useLocale from '../../hooks/useLocale';
import { DATE_FORMAT2 } from '../../constants/common';

import './FilingHistoryModal.scss';

export interface IHistoryDataItem {
  filingDate?: moment.Moment;
  updatedDate?: moment.Moment;
  updatedBy?: string;
}

interface IProps {
  title: string;
  data: IHistoryDataItem[];
  onClose?: () => void;
}

function FilingHistoryModal({ data, onClose, title }: IProps): JSX.Element {
  const { t } = useLocale();

  const columns = [
    {
      title: t.FILLING_DATE,
      dataIndex: 'filingDate',
      key: 'filingDate',
      render: (date: string) => <>{moment(date).format(DATE_FORMAT2)}</>,
    },
    {
      title: t.UPDATED_ON,
      dataIndex: 'updatedDate',
      key: 'updatedDate',
      render: (date: string) => <>{moment(date).format(DATE_FORMAT2)}</>,
    },
    {
      title: t.UPDATE_BY,
      dataIndex: 'updatedBy',
      key: 'updatedBy',
    },
  ];
  return (
    <Modal
      title={title}
      destroyOnClose
      centered
      visible
      footer={null}
      closable
      onCancel={onClose}
      width={900}
      className="FilingHistoryModal"
    >
      <Table
        rowKey="id"
        dataSource={data?.sort((a, b) => moment(b.filingDate).unix() - moment(a.filingDate).unix())}
        columns={columns}
        pagination={false}
        scroll={{ y: 500 }}
      />
      <Row gutter={12} justify="end" align="bottom" wrap={false} className="FilingHistoryModal__footer-container">
        <Col>
          <Form.Item noStyle>
            <Button onClick={onClose}>{t.CLOSE}</Button>
          </Form.Item>
        </Col>
      </Row>
    </Modal>
  );
}

export default FilingHistoryModal;
