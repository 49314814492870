import { useMemo } from 'react';
import { useParams, useNavigate } from 'react-router-dom';

import PageSubtitle from '../../../components/PageSubtitle/PageSubtitle';
import AppointmentTable from '../../../components/AppointmentTable/AppointmentTable';
import Breadcrumb from '../../../components/Breadcrumb/Breadcrumb';
import { useApplicationFile } from '../../ApplicationOverviewPage';

import { ROUTES } from '../../../constants/routes';
import useLocale from '../../../hooks/useLocale';

import './AppointmentsHistory.scss';

function AppointmentsHistory(): JSX.Element {
  const { applicationFileId } = useParams<{ applicationFileId: string }>();
  const { t } = useLocale();
  const navigate = useNavigate();

  const { data: appFileData } = useApplicationFile();
  const isDuplicate = useMemo(() => appFileData?.isDuplicate, [appFileData]);

  return (
    <div>
      <Breadcrumb
        data={[
          { link: ROUTES.DASHBOARD, title: t.DASHBOARD },
          { link: `${ROUTES.DEBTOR_SEARCH}`, title: t.DEBTOR_SEARCH },
          { link: `${ROUTES.APPLICATION_OVERVIEW}/${applicationFileId}/`, title: t.APPLICATION_OVERVIEW },
          {
            title: t.APPOINTMENTS_HISTORY,
          },
        ]}
      />

      <PageSubtitle
        title={t.APPOINTMENTS_HISTORY}
        buttonTitle={!isDuplicate && t.APPOINTMENT_ADD}
        onButtonClick={() =>
          navigate(`${ROUTES.APPLICATION_OVERVIEW}/${applicationFileId}/${ROUTES.APPOINTMENT_SCHEDULING}`)
        }
      />

      <div className="AppointmentsHistory">
        <AppointmentTable editable={!isDuplicate} />
      </div>
    </div>
  );
}

export default AppointmentsHistory;
