import { useState, useCallback, useEffect, Key } from 'react';

import EditableTable from './EditableTable/EditableTable';

import {
  InputTypeEnum,
  ResidenceAvailableDto,
  TaxTypesDto,
  ResidenceAppliedToDto,
  RESPsLookupDto,
  RRSPsLookupDto,
  IDataProps,
  IConfigurationType,
} from '../types';
import { BC_ALL_OTHER_CODE, CURRENCY_PRECISION, PERCENTAGE_PRECISION } from '../constants';
import API from '../../../../utils/api';
import { ClientService } from '../../../../shared/api/ClientService';
import { convertSelectedNameToId, convertUnlimitedNumericToNumber, isUnlimited } from '../utils';
import genericMessage from '../../../../utils/genericMessage';
import useLocale from '../../../../hooks/useLocale';

interface IProps {
  isEditable?: boolean;
  setIsEditable?: (isEditable: boolean) => void;
}

function ProvinceExemptionsTable({ isEditable, setIsEditable }: IProps): JSX.Element {
  const { t } = useLocale();

  const [data, setData] = useState<IDataProps<IConfigurationType>[]>();
  const [loading, setLoading] = useState<boolean>(false);

  const [listTaxTypes, setListTaxTypes] = useState<TaxTypesDto[]>();
  const [listResidenceAvailable, setListResidenceAvailable] = useState<ResidenceAvailableDto[]>();
  const [listResidenceAppliesTo, setListResidenceAppliesTo] = useState<ResidenceAppliedToDto[]>();
  const [listRESPs, setListRESPs] = useState<RESPsLookupDto[]>();
  const [listRRSPs, setListRRSPs] = useState<RRSPsLookupDto[]>();

  const requestConfigurations = useCallback(async () => {
    setLoading(true);
    const response = await API.getFileAnalysisProvincialExemptionsTaxRateConfigurationsList().catch(() =>
      setLoading(false)
    );
    if (response) setData(response?.map((item) => ({ ...item, key: item.id as Key, hasError: false })));
    setLoading(false);
  }, []);

  const requestListTaxTypes = useCallback(async () => {
    const response = await API.listTaxTypes();
    if (response) setListTaxTypes(response);
  }, []);

  const requestListResidenceAvailable = useCallback(async () => {
    const response = await API.listResidenceAvailable();
    if (response) setListResidenceAvailable(response);
  }, []);

  const requestListResidenceAppliesTo = useCallback(async () => {
    const response = await API.listResidenceAppliesTo();
    if (response) setListResidenceAppliesTo(response);
  }, []);

  const requestListRESPs = useCallback(async () => {
    const response = await API.listRESPs();
    if (response) setListRESPs(response);
  }, []);

  const requestListRRSPs = useCallback(async () => {
    const response = await API.listRRSPs();
    if (response) setListRRSPs(response);
  }, []);

  useEffect(() => {
    if (!listTaxTypes) requestListTaxTypes();
  }, [listTaxTypes, requestListTaxTypes]);

  useEffect(() => {
    if (!listResidenceAvailable) requestListResidenceAvailable();
  }, [listResidenceAvailable, requestListResidenceAvailable]);

  useEffect(() => {
    if (!listResidenceAppliesTo) requestListResidenceAppliesTo();
  }, [listResidenceAppliesTo, requestListResidenceAppliesTo]);

  useEffect(() => {
    if (!listRESPs) requestListRESPs();
  }, [listRESPs, requestListRESPs]);

  useEffect(() => {
    if (!listRRSPs) requestListRRSPs();
  }, [listRRSPs, requestListRRSPs]);

  const requestUpdateConfigurations = useCallback(
    async (data?: IDataProps<IConfigurationType>[]) => {
      const body = data?.map((item: any) => ({
        id: item?.id,
        isAllOtherBC: item?.provinceCode === BC_ALL_OTHER_CODE,
        vehicleAmount: convertUnlimitedNumericToNumber(item?.vehicleAmount),
        isUnlimitedVehicleAmount: isUnlimited(item?.vehicleAmount),
        residenceAmount: convertUnlimitedNumericToNumber(item?.residenceAmount),
        isUnlimitedResidenceAmount: isUnlimited(item?.residenceAmount),
        fileAnalysisResidenceAppliesId: convertSelectedNameToId(
          item?.fileAnalysisResidenceAppliesName,
          listResidenceAppliesTo
        ),
        fileAnalysisResidenceAvailabilityId: convertSelectedNameToId(
          item?.fileAnalysisResidenceAvailabilityName,
          listResidenceAvailable
        ),
        householdAmount: convertUnlimitedNumericToNumber(item?.householdAmount),
        isUnlimitedHouseholdAmount: isUnlimited(item?.householdAmount),
        huntingFishingFarmingAmount: convertUnlimitedNumericToNumber(item.huntingFishingFarmingAmount),
        isUnlimitedHuntingFishingFarmingAmount: isUnlimited(item.huntingFishingFarmingAmount),
        toolsAmount: convertUnlimitedNumericToNumber(item.toolsAmount),
        isUnlimitedToolsAmount: isUnlimited(item.toolsAmount),
        fileAnalysisRESPId: convertSelectedNameToId(item?.fileAnalysisRESPName, listRESPs),
        fileAnalysisRRSPId: convertSelectedNameToId(item?.fileAnalysisRRSPName, listRRSPs),
        taxRate: Number(item.taxRate),
        fileAnalysisTaxTypeId: convertSelectedNameToId(item?.fileAnalysisTaxTypeName, listTaxTypes),
      })) as ClientService.FileAnalysisProvincialExemptionsTaxRateConfigurationInputDto[];

      setLoading(true);
      const response = await API.updateFileAnalysisProvincialExemptionsTaxRateConfigurations(body).catch(() =>
        setLoading(false)
      );

      if (response?.result === ClientService.Result.Successful) {
        genericMessage.success(t.CONFIGURATION_CHANGE_SUCCESS);
        if (setIsEditable) setIsEditable(false);
        requestConfigurations();
      } else {
        genericMessage.error({}, response?.messages?.[0].body);
      }
      setLoading(false);
    },
    [
      listRESPs,
      listRRSPs,
      listResidenceAppliesTo,
      listResidenceAvailable,
      listTaxTypes,
      requestConfigurations,
      setIsEditable,
      t.CONFIGURATION_CHANGE_SUCCESS,
    ]
  );

  const handleCancel = useCallback(() => {
    if (setIsEditable) setIsEditable(false);
    requestConfigurations();
  }, [requestConfigurations, setIsEditable]);

  useEffect(() => {
    if (!data) requestConfigurations();
  }, [data, requestConfigurations]);

  const defaultColumns = [
    { title: t.PROVINCE, dataIndex: 'provinceCode', key: 'provinceCode', type: InputTypeEnum.ReadOnly },
    { title: t.NAME, dataIndex: 'provinceName', key: 'provinceName', type: InputTypeEnum.ReadOnly },
    {
      title: t.VEHICLE,
      dataIndex: 'vehicleAmount',
      key: 'vehicleAmount',
      type: InputTypeEnum.UnlimitedNumeric,
      precision: CURRENCY_PRECISION,
    },
    {
      title: t.RESIDENCE_AMOUNT,
      dataIndex: 'residenceAmount',
      key: 'residenceAmount',
      type: InputTypeEnum.UnlimitedNumeric,
      precision: CURRENCY_PRECISION,
    },
    {
      title: t.RESIDENCY_APP,
      dataIndex: 'fileAnalysisResidenceAppliesName',
      key: 'fileAnalysisResidenceAppliesName',
      type: InputTypeEnum.Select,
      options: listResidenceAppliesTo,
    },
    {
      title: t.RESIDENCY_AVAIL,
      dataIndex: 'fileAnalysisResidenceAvailabilityName',
      key: 'fileAnalysisResidenceAvailabilityName',
      type: InputTypeEnum.Select,
      options: listResidenceAvailable,
    },
    {
      title: t.HOUSEHOLD,
      dataIndex: 'householdAmount',
      key: 'householdAmount',
      type: InputTypeEnum.UnlimitedNumeric,
      precision: CURRENCY_PRECISION,
    },

    {
      title: t.HUNT_FISH_FARM,
      dataIndex: 'huntingFishingFarmingAmount',
      key: 'huntingFishingFarmingAmount',
      type: InputTypeEnum.UnlimitedNumeric,
      precision: CURRENCY_PRECISION,
    },
    {
      title: t.TOOLS,
      dataIndex: 'toolsAmount',
      key: 'toolsAmount',
      type: InputTypeEnum.UnlimitedNumeric,
      precision: CURRENCY_PRECISION,
    },
    {
      title: t.RESPS,
      dataIndex: 'fileAnalysisRESPName',
      key: 'fileAnalysisRESPName',
      type: InputTypeEnum.Select,
      options: listRESPs,
    },
    {
      title: t.RRSP,
      dataIndex: 'fileAnalysisRRSPName',
      key: 'fileAnalysisRRSPName',
      type: InputTypeEnum.Select,
      options: listRRSPs,
    },
    {
      title: t.RATE,
      dataIndex: 'taxRate',
      key: 'taxRate',
      type: InputTypeEnum.Percentage,
      precision: PERCENTAGE_PRECISION,
    },
    {
      title: t.TYPE,
      dataIndex: 'fileAnalysisTaxTypeName',
      key: 'fileAnalysisTaxTypeName',
      type: InputTypeEnum.Select,
      options: listTaxTypes,
    },
  ];

  return (
    <EditableTable
      data={data}
      loading={loading}
      defaultColumns={defaultColumns}
      isEditable={isEditable}
      onSave={requestUpdateConfigurations}
      onCancel={handleCancel}
    />
  );
}

export default ProvinceExemptionsTable;
