import { useState, useCallback, useEffect } from 'react';
import { Row, Col, Modal, Tabs, Spin } from 'antd';
import AppForm from '../../components/Forms/AppForm/AppForm';
import FormInput from '../../components/Forms/FormInput/FormInput';
import ButtonContainer from '../../components/ButtonContainer/ButtonContainer';
import Button from '../../components/Button/Button';
import ErrorsContainer, { IErrorsMsgAndType } from '../../components/ErrorsContainer/ErrorsContainer';
import useModal from '../../hooks/useModal';
import useLocale from '../../hooks/useLocale';
import useErrorHandling from '../../hooks/useErrorHandling';
import ReactQuill from 'react-quill';
import { ClientService } from '../../shared/api/ClientService';
import API from '../../utils/api';
import genericMessage from '../../utils/genericMessage';
import { AuthorizeService } from '../../components/Auth/AuthorizeService';
import AttachmentsTab, { AttachmentsFormProps } from '../../components/AttachmentsTab';
import { UPLOAD_FILES_EVENT } from '../../constants/eventBus';
import { eventBus } from '../../utils/eventBus';
import { argumentifyEmailTemplatesCriteria } from '../../pages/ApplicationOverviewPage/NewAndViewPageComponents/utils';
import { MAX_PAGE_SIZE } from '../../constants/common';

import './SendEmailConfirmation.scss';
import SelectLookupDto from '../../components/SelectLookupDto/SelectLookupDto';

interface SendEmailConfirmationProps {
  fileId: string;
  claimId: string;
  recipientEmailAddress: string;
  emailTemplateId?: string;
  ignoreDoNotEmail?: boolean;
  onCancel?: () => void;
  onSend?: () => void;
  className?: string;
  style?: React.CSSProperties;
}

interface IForm extends AttachmentsFormProps {
  subject?: string;
  from?: string;
  to?: string;
  body?: string;
  messageTemplateId?: string;
  ignoreDoNotEmail?: boolean;
}

const EMAIL_TAB = 'email';
const ATTACHMENTS_TAB = 'attachments';

/**
 *  Send email to creditor for Admin Info - Claims & Asset Realization.
 *  Note: Only works for ViewClaimPage.tsx when click "Complete" or "Complete with Alert" buttons.
 *  Work as"Email Confirmation" popup and "Creditor Email Popup".
 *  #20431
 */
const SendEmailConfirmation = ({
  fileId,
  claimId,
  recipientEmailAddress,
  emailTemplateId,
  ignoreDoNotEmail,
  onCancel,
  onSend,
  className,
  style,
}: SendEmailConfirmationProps) => {
  const { t } = useLocale();
  const { closeModal } = useModal();
  const { TabPane } = Tabs;

  const { processResponseForErrors } = useErrorHandling();
  const [form] = AppForm.AntD.useForm<IForm>();

  const [errors, setErrors] = useState<IErrorsMsgAndType[] | undefined>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isSending, setIsSending] = useState<boolean>(false);
  const [tab, setTab] = useState<string>(EMAIL_TAB);
  const [templates, setTemplates] = useState<ClientService.AppEmailTemplateDto[]>();

  const user = AuthorizeService.getCurrentUserInfo();

  const requestTemplates = useCallback(async () => {
    const response = await API.emailTemplates(...argumentifyEmailTemplatesCriteria({ maxResultCount: MAX_PAGE_SIZE }));

    if (response?.items) setTemplates(response?.items);
  }, []);

  const handleTabChange = useCallback((key) => form.validateFields().then(() => setTab(key)), [form]);

  const isFormValid = useCallback(async () => {
    let requestIsValid = true;
    try {
      await form.validateFields();
    } catch (error) {
      requestIsValid = false;
    }
    return requestIsValid;
  }, [form]);

  const sendEmail = useCallback(
    async (attachedDocumentIds?: string[]) => {
      setErrors(undefined);

      const formValues = form.getFieldsValue();
      setIsSending(true);

      try {
        const createEmailResponse = await API.create(
          new ClientService.AppEmailCreateDto({
            fileId: fileId,
            claimId: claimId,
            messageTemplateId: formValues.messageTemplateId,
            toEmailAddress: formValues.to,
            subject: formValues.subject,
            body: formValues.body,
            attachedDocumentIds,
            ignoreDoNotEmail: ignoreDoNotEmail,
          })
        );
        setIsSending(false);
        const responseErrors = processResponseForErrors(createEmailResponse);
        setErrors(responseErrors.messages);
        if (responseErrors.hasErrors) return;

        genericMessage.success(t.EMAIL_SENT_SUCCESS);
        return true;
      } catch (error: any) {
        setIsSending(false);
        const requestErrors = processResponseForErrors(error);
        console.error(requestErrors.messages);
        setErrors([{ message: t.ERROR__TRYING_TO_SEND_EMAIL }]);
        return false;
      }
    },
    [fileId, form, processResponseForErrors, t.EMAIL_SENT_SUCCESS, t.ERROR__TRYING_TO_SEND_EMAIL]
  );

  const handleCancel = useCallback(() => {
    closeModal();
    if (onCancel) onCancel();
  }, [closeModal, onCancel]);

  const handleSubmit = useCallback(async () => {
    const isRequestValid = await isFormValid();
    if (!isRequestValid) {
      setErrors([{ message: t.ERROR__REQUIRED_FIELDS_MISSING }]);
      return;
    }

    eventBus.dispatch(UPLOAD_FILES_EVENT, {
      onUploadSuccess: async (attachedDocumentIds: string[]) => {
        const emailSent = await sendEmail(attachedDocumentIds);

        if (emailSent) {
          closeModal();
          if (onSend) onSend();
        }
      },
    });
  }, [closeModal, isFormValid, onSend, sendEmail, t.ERROR__REQUIRED_FIELDS_MISSING]);

  const requestTemplate = useCallback(
    (messageTemplateId: string) => {
      setIsLoading(true);
      API.emailTemplates2(messageTemplateId)
        .then((response) => {
          setIsLoading(false);
          const responseErrors = processResponseForErrors(response);
          setErrors(responseErrors.messages);
          if (responseErrors.hasErrors) return;

          form.setFieldsValue({
            body: response?.body,
            subject: response?.subject,
          });
        })
        .catch((error) => {
          setIsLoading(false);
          setErrors([{ message: t.EMAIL_TEMPLATE_COULD_NOT_BE_RETRIEVED }]);
        });
    },
    [form, processResponseForErrors, t.EMAIL_TEMPLATE_COULD_NOT_BE_RETRIEVED]
  );

  useEffect(() => {
    requestTemplates();
  }, [requestTemplates]);

  useEffect(() => {
    if (emailTemplateId) requestTemplate(emailTemplateId);
  }, [emailTemplateId, requestTemplate]);

  return (
    <Modal
      className={`SendEmailConfirmation ${className ? className : ''}`}
      style={style}
      destroyOnClose
      onCancel={() => {
        closeModal();
        if (onCancel) onCancel();
      }}
      centered
      visible
      title={t.CREDITOR_EMAIL}
      width={900}
      footer={[
        <ButtonContainer
          key="modal-button-container"
          style={{
            paddingTop: '0',
          }}
        >
          <Button key="cancel-button" kind="cancel" onClick={handleCancel}>
            {t.CANCEL}
          </Button>
          <Button key="send-button" onClick={handleSubmit}>
            {t.SEND}
          </Button>
        </ButtonContainer>,
      ]}
    >
      <Spin spinning={isLoading || isSending}>
        <AppForm
          form={form}
          initialValues={{
            from: user?.profile?.email,
            messageTemplateId: emailTemplateId,
            to: recipientEmailAddress,
          }}
        >
          <Tabs className="Tabs" onTabClick={handleTabChange} activeKey={tab}>
            <TabPane tab={t.EMAIL} key={EMAIL_TAB}>
              {errors && <ErrorsContainer errors={errors} noTitle />}
              <Row gutter={20}>
                <Col span={24}>
                  <FormInput name="messageTemplateId" label={t.EMAIL_TEMPLATE}>
                    <SelectLookupDto data={templates} onChange={requestTemplate} showSearch />
                  </FormInput>
                </Col>
                <Col span={12}>
                  <FormInput name="from" label={t.EMAIL_FIELD_FROM} required disabled />
                </Col>
                <Col span={12}>
                  <FormInput name="to" label={t.EMAIL_FIELD_TO} required />
                </Col>
                <Col span={24}>
                  <FormInput name="subject" label={t.EMAIL_FIELD_SUBJECT} required />
                </Col>
              </Row>
              <Row gutter={20}>
                <Col span={24}>
                  <FormInput name="body" label={t.EMAIL_FIELD_BODY} required initialValue={''}>
                    <ReactQuill
                      onChange={(content: any, delta: any, source: any, editor: any) => {
                        if (!editor.getText() || editor.getText() === '\n') {
                          form.setFieldsValue({ body: '' });
                        }
                      }}
                    />
                  </FormInput>
                </Col>
              </Row>
            </TabPane>
            <TabPane tab={t.EMAIL_ATTACHMENTS} key={ATTACHMENTS_TAB} forceRender>
              <AttachmentsTab
                form={form}
                applicationFileId={fileId}
                draggerProps={{
                  totalFilesSizeLimitMB: 15,
                  singleFileSizeLimitMB: 5,
                }}
              />
            </TabPane>
          </Tabs>
        </AppForm>
      </Spin>
    </Modal>
  );
};

export default SendEmailConfirmation;
