import { Col, Row } from 'antd';
import { Typography } from 'antd';
import { LinkOutlined } from '@ant-design/icons';

import './SignUpHeaderItem.scss';
import styles from '../../../../../../styles/style.module.scss';

interface IProps {
  value?: string | number;
  label?: string;
  fontWeight?: 'bold' | 'normal';
  titleColor?: 'colorDanger' | 'colorGreen';
  hasIcon?: boolean;
  onClick?: () => void;
  disabled?: boolean;
}

const SignUpHeaderItem = ({
  value,
  label,
  fontWeight = 'normal',
  titleColor = 'colorGreen',
  hasIcon,
  onClick,
  disabled,
}: IProps) => {
  const { Text } = Typography;

  return (
    <Row className="SignUpHeaderItem" style={{ fontWeight }} gutter={8}>
      <Col className="SignUpHeaderItem__title" span={24}>
        <Text ellipsis style={{ color: disabled ? styles.colorSecondary : styles[titleColor] }}>
          {value}
        </Text>
      </Col>
      <Col>
        <Text ellipsis style={{ color: disabled ? styles.colorSecondary : styles.colorPrimary }}>
          {label}
        </Text>
      </Col>
      <Col span={1}>
        {hasIcon && (
          <LinkOutlined
            rotate={45}
            className="SignUpHeaderItem__link-icon"
            disabled={disabled}
            style={{
              color: disabled ? styles.colorSecondary : styles.colorDanger,
              cursor: disabled ? 'not-allowed' : 'pointer',
            }}
            onClick={() => {
              if (!disabled && onClick) onClick();
            }}
          />
        )}
      </Col>
    </Row>
  );
};

export default SignUpHeaderItem;
