import { useCallback, useEffect, useState } from 'react';
import { Typography, Checkbox, message, Spin } from 'antd';
import { Icon } from '@fluentui/react/lib/Icon';

import ActionConfirmationModal from '../../../../modals/ActionConfirmationModal/ActionConfirmationModal';
import UploadSupportingDocumentsModal from '../../../../modals/UploadSupportingDocumentsModal/UploadSupportingDocumentsModal';
import OverrideModal from '../../../../modals/OverrideModal/OverrideModal';
import Table from '../../../../components/Table/Table';

import useLocale from '../../../../hooks/useLocale';
import useModal from '../../../../hooks/useModal';

import { DELETE_FILE_EVENT } from '../../../../constants/eventBus';
import { eventBus } from '../../../../utils/eventBus';
import { downloadUtil } from '../../DocumentsContent/utils';
import authService from '../../../../components/Auth/AuthorizeService';
import { ClientService } from '../../../../shared/api/ClientService';
import API from '../../../../utils/api';

import './ApplicationFormTab.scss';
import styles from '../../../../styles/style.module.scss';

interface ISupportingDocumentsProps {
  data: ClientService.IApplicationFileDto | null;
  updateStatus: () => void;
}

const TAXES_FOLDER_CODE = 'taxes';
const PERSONAL_FOLDER_CODE = 'lead';

const SupportingDocuments = ({ data, updateStatus }: ISupportingDocumentsProps) => {
  const { t, getLocalizedDocumentName } = useLocale();
  const { Text } = Typography;
  const { showModal, closeModal } = useModal();

  const [supportingDocuments, setSupportingDocuments] = useState<ClientService.SupportingDocumentStaffPortalDto[]>();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [isDeleting, setIsDeleting] = useState<boolean>(false);
  const [isUpdatingStatus, setIsUpdatingStatus] = useState<boolean>(false);
  const [isDownloading, setIsDownloading] = useState<boolean>(false);

  const modifyTableDetailColumn = useCallback(
    (data?: ClientService.SupportingDocumentStaffPortalDto[]) =>
      data
        ?.filter((item) => item?.id)
        ?.map((filtered) =>
          filtered?.documentFolderCode !== TAXES_FOLDER_CODE && filtered?.documentFolderCode !== PERSONAL_FOLDER_CODE
            ? filtered
            : ({
                ...filtered,
                detail: `${filtered?.detail} - ${filtered?.isForSpouse ? t.SPOUSE : t.APPLICANT}`,
              } as ClientService.SupportingDocumentStaffPortalDto)
        )
        ?.sort((a, b) => (a?.detail || '').localeCompare(b?.detail || ''))
        ?.reverse()
        ?.reduce(
          (
            acc: ClientService.SupportingDocumentStaffPortalDto[],
            current: ClientService.SupportingDocumentStaffPortalDto,
            index,
            array
          ) => {
            if (index < array?.length - 1 && current.detail === array[index + 1].detail) {
              current.detail = '';
            }
            acc.push(current);
            return acc;
          },
          []
        )
        ?.reverse(),
    [t.APPLICANT, t.SPOUSE]
  );

  const requestSupportingDocuments = useCallback(async () => {
    setIsLoading(true);
    const response = await API.getAllSupportingDocumentsByFileId(data?.id).catch(() => setIsLoading(false));

    if (response) {
      setSupportingDocuments(modifyTableDetailColumn(response));
    }

    setIsLoading(false);
  }, [data?.id, modifyTableDetailColumn]);

  const handleDownload = useCallback(async (documentId: string, name: string) => {
    setIsDownloading(true);

    let token = await authService.getAccessToken();

    fetch(`${process.env.REACT_APP_BASE_URL}/api/client-service/documents/${documentId}/download`, {
      method: 'GET',
      headers: new Headers({
        Authorization: `Bearer ${token}`,
      }),
    })
      .then((response) => {
        return response.blob();
      })
      .then((blob) => {
        downloadUtil(blob, name as string);
        setIsDownloading(false);
      })
      .catch(() => setIsDownloading(false));
  }, []);

  const handleDeletion = useCallback(
    (documentId: string, documentName: string) => {
      showModal(
        <ActionConfirmationModal
          title={t.CONFIRM_DELETION}
          message={t.ARE_YOU_SURE_YOU_WANT_TO_DELETE_FILE?.replace('{0}', documentName)}
          onCancel={closeModal}
          okText={t.DELETE}
          onOk={async () => {
            setIsDeleting(true);
            const result = await API.documentsDELETE([documentId]).catch(() => setIsDeleting(false));
            if (result?.result === ClientService.Result.Successful) {
              message.success(t.DOCUMENT_WAS_DELETED);
              requestSupportingDocuments();
              updateStatus();
              closeModal();
            } else {
              message.error(result?.messages?.[0]?.body || t.SOMETHING_WENT_WRONG);
            }

            setIsDeleting(false);
          }}
        />
      );
    },
    [
      closeModal,
      requestSupportingDocuments,
      showModal,
      t.ARE_YOU_SURE_YOU_WANT_TO_DELETE_FILE,
      t.CONFIRM_DELETION,
      t.DELETE,
      t.DOCUMENT_WAS_DELETED,
      t.SOMETHING_WENT_WRONG,
      updateStatus,
    ]
  );

  const handleUpload = useCallback(
    (record?: ClientService.SupportingDocumentStaffPortalDto) => {
      showModal(
        <UploadSupportingDocumentsModal
          applicationFileId={data?.id as string}
          supportingDocument={record}
          onCancel={closeModal}
          requestSupportingDocuments={requestSupportingDocuments}
          onOk={() => {
            requestSupportingDocuments();
            updateStatus();
            closeModal();
          }}
        />
      );
    },
    [showModal, data?.id, closeModal, updateStatus, requestSupportingDocuments]
  );

  const handleOverride = useCallback(
    (supportingDocument?: ClientService.SupportingDocumentStaffPortalDto) => {
      showModal(
        <OverrideModal
          supportingDocument={supportingDocument}
          onCancel={closeModal}
          onOk={() => {
            requestSupportingDocuments();
            updateStatus();
            closeModal();
          }}
        />
      );
    },
    [closeModal, requestSupportingDocuments, showModal, updateStatus]
  );

  const changeCheckboxStateOnClick = useCallback(
    (document?: ClientService.DocumentDto, supportingDocumentId?: string) =>
      setSupportingDocuments(
        (prev) =>
          (prev || []).map((item) =>
            item?.id === supportingDocumentId
              ? {
                  ...item,
                  documents: (item?.documents || []).map((i) =>
                    i?.id === document?.id
                      ? { ...i, isSupportingDocumentReviewed: !document?.isSupportingDocumentReviewed }
                      : i
                  ),
                }
              : item
          ) as ClientService.SupportingDocumentStaffPortalDto[]
      ),
    []
  );

  const handleUpdateReviewStatus = useCallback(
    async (document?: ClientService.DocumentDto) => {
      setIsUpdatingStatus(true);

      const result = await API.updateReviewStatus({
        documentId: document?.id,
        isReviewed: !document?.isSupportingDocumentReviewed,
      } as ClientService.SupportingDocumentDocumentReviewStatusUpdateDto).catch(() => setIsUpdatingStatus(false));

      if (result?.result === ClientService.Result.Successful) {
        message.success(t.REVIEW_STATUS_WAS_CHANGED);
        requestSupportingDocuments();
        updateStatus();
      } else {
        message.error(result?.messages?.[0]?.body || t.SOMETHING_WENT_WRONG);
      }

      setIsUpdatingStatus(false);
    },
    [requestSupportingDocuments, t.REVIEW_STATUS_WAS_CHANGED, t.SOMETHING_WENT_WRONG, updateStatus]
  );

  const columns = [
    {
      title: t.DETAILS,
      dataIndex: 'detail',
      key: 'detail',
    },
    {
      title: t.DOCUMENT_NAME,
      dataIndex: 'documentName',
      key: 'documentName',
      render: (_?: any, record?: ClientService.SupportingDocumentStaffPortalDto) => (
        <>{getLocalizedDocumentName({ ...record, name: record?.documentName })}</>
      ),
    },
    {
      title: t.FILE_UPLOAD,
      dataIndex: 'documents',
      key: 'documents',
      render: (documents?: ClientService.DocumentDto[], record?: ClientService.SupportingDocumentStaffPortalDto) =>
        record?.isOverridden ? (
          '-'
        ) : documents?.length ? (
          <>
            {documents?.map((document, index) => (
              <div className="ApplicationFormTab__supporting-documents--file-upload-cell" key={`upload-cell-${index}`}>
                <div
                  className="ApplicationFormTab__supporting-documents--file-upload-cell-inner-wrapper"
                  onClick={() => handleDownload(document?.id as string, getLocalizedDocumentName(document) as string)}
                >
                  <Icon iconName="CompletedSolid" style={{ color: styles.colorGreen }} />
                  <Text
                    className="ApplicationFormTab__supporting-documents--uploaded-document"
                    ellipsis
                    style={{ width: 250 }}
                  >
                    {getLocalizedDocumentName(document)}
                  </Text>
                </div>
                <div className="ApplicationFormTab__supporting-documents--file-upload-cell-inner-wrapper">
                  <Icon
                    iconName="Edit"
                    style={{ color: styles.colorGray }}
                    className="ApplicationFormTab__supporting-documents--file-upload-cell-action-button"
                    onClick={() => handleUpload(record)}
                  />
                  <Icon
                    iconName="Delete"
                    style={{ color: styles.colorDanger }}
                    className="ApplicationFormTab__supporting-documents--file-upload-cell-action-button"
                    onClick={() => handleDeletion(document?.id as string, document?.name as string)}
                  />
                </div>
              </div>
            ))}
          </>
        ) : (
          <div
            className="ApplicationFormTab__supporting-documents--file-upload-cell-inner-wrapper"
            onClick={() => handleUpload(record)}
          >
            <Icon iconName="CircleFill" style={{ color: styles.colorSecondary }} />
            <span className="ApplicationFormTab__supporting-documents--upload-link">{t.UPLOAD}</span>
          </div>
        ),
      width: 300,
    },
    {
      title: t.OVERRIDE_TITLE,
      dataIndex: 'documents',
      key: 'documents',
      render: (documents?: ClientService.DocumentDto[], record?: ClientService.SupportingDocumentStaffPortalDto) => (
        <>
          {(documents?.length ? documents : [undefined])?.map((_: any, index: number) => (
            <div className="ApplicationFormTab__supporting-documents--override-cell-inner-wrapper" key={index}>
              <Checkbox
                checked={Boolean(record?.isOverridden)}
                disabled={!Boolean(record?.isOverriddenEnabled)}
                onClick={() => handleOverride(record)}
              />
              <Text ellipsis>{record?.overrideReason}</Text>
            </div>
          ))}
        </>
      ),
    },
    {
      title: t.REVIEWED_BY_ADMIN,
      dataIndex: 'documents',
      key: 'documents',
      render: (documents?: ClientService.DocumentDto[], record?: ClientService.SupportingDocumentStaffPortalDto) => (
        <>
          {documents?.length
            ? documents?.map((item: ClientService.DocumentDto, index: number) => (
                <div className="ApplicationFormTab__supporting-documents--reviewed-cell" key={index}>
                  <Checkbox
                    checked={item?.isSupportingDocumentReviewed}
                    onClick={() => {
                      changeCheckboxStateOnClick(item, record?.id);
                      handleUpdateReviewStatus(item);
                    }}
                  />
                </div>
              ))
            : '-'}
        </>
      ),
    },
  ];

  useEffect(() => {
    if (data?.id && !supportingDocuments) requestSupportingDocuments();
  }, [data?.id, requestSupportingDocuments, supportingDocuments]);

  useEffect(() => {
    eventBus.on(DELETE_FILE_EVENT, requestSupportingDocuments);

    return () => {
      eventBus.remove(DELETE_FILE_EVENT, requestSupportingDocuments);
    };
  }, [requestSupportingDocuments]);

  return (
    <Spin spinning={isLoading || isDeleting || isUpdatingStatus || isDownloading}>
      <div className="ApplicationFormTab__supporting-documents">
        <Table
          columns={columns}
          dataSource={supportingDocuments}
          rowClassName={(record: ClientService.SupportingDocumentStaffPortalDto) =>
            !record?.detail ? 'ApplicationFormTab__supporting-documents--no-details-row' : ''
          }
          rowKey={(record: ClientService.SupportingDocumentStaffPortalDto) => record.id as string}
          allWhite
          pagination={false}
        />
      </div>
    </Spin>
  );
};

export default SupportingDocuments;
