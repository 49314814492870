import { Select, SelectProps } from 'antd';

import SelectSuffixIcon from '../SelectSuffixIcon/SelectSuffixIcon';
import useLocale from '../../hooks/useLocale';

import { ClientService } from '../../shared/api/ClientService';

import './SelectLookupDto.scss';

interface IProps extends SelectProps {
  data?: ClientService.LookupDto[];
}

const SelectLookupDto = ({ data = [], ...props }: IProps) => {
  const { getLocalizedDtoName } = useLocale();
  const { Option } = Select;

  return (
    <Select
      size="large"
      suffixIcon={<SelectSuffixIcon />}
      placeholder="Select"
      className="SelectLookupDto"
      filterOption={(input, option) => {
        if (props?.showSearch && !props?.filterOption)
          return option?.title?.toLowerCase().includes(input.toLowerCase() || '');
      }}
      {...props}
    >
      {data &&
        Array.isArray(data) &&
        (data || [])?.map((item) => (
          <Option value={item?.id} key={item?.id} title={getLocalizedDtoName(item) || item?.displayName}>
            {getLocalizedDtoName(item) || item?.displayName}
          </Option>
        ))}
    </Select>
  );
};

export default SelectLookupDto;
