import { useCallback } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Menu, Dropdown } from 'antd';
import { Icon } from '@fluentui/react/lib/Icon';

import ChequePADRequestReviewModal from '../../modals/ChequePADRequestReviewModal/ChequePADRequestReviewModal';

import useModal from '../../../../../../../hooks/useModal';
import useLocale from '../../../../../../../hooks/useLocale';

import { ROUTES } from '../../../../../../../constants/routes';
import { ClientService } from '../../../../../../../shared/api/ClientService';

import './RowActionMenu.scss';

interface IProps {
  rowItem: ClientService.PADScheduleDto;
  onRowChangeCallback: () => void;
}

function RowActionMenu({ rowItem, onRowChangeCallback }: IProps) {
  const { showModal, closeModal } = useModal();
  const navigate = useNavigate();
  const { applicationFileId } = useParams<{ applicationFileId?: string }>();
  const { t } = useLocale();

  const handleEdit = useCallback(() => {
    showModal(
      <ChequePADRequestReviewModal
        id={rowItem?.id}
        applicationFileId={applicationFileId as string}
        onOk={() => {
          closeModal();
          onRowChangeCallback();
        }}
        onCancel={closeModal}
      />
    );
  }, [showModal, rowItem?.id, applicationFileId, closeModal, onRowChangeCallback]);

  const actionMenu = (
    <Menu className="RowActionMenu__action-menu">
      <Menu.Item disabled={rowItem.isDeposited} onClick={handleEdit}>
        <Icon iconName="Edit" /> {t.EDIT}
      </Menu.Item>
      {rowItem?.hasReceipts && (
        <Menu.Item onClick={() => navigate(`${ROUTES.APPLICATION_OVERVIEW}/${applicationFileId}/${ROUTES.RECEIPTS}`)}>
          <Icon iconName="History" /> {t.RECEIPT_HISTORY}
        </Menu.Item>
      )}

      {(!rowItem.isDeposited || rowItem?.amount !== rowItem?.deposited) && (
        <Menu.Item
          onClick={() =>
            navigate(`${ROUTES.APPLICATION_OVERVIEW}/${applicationFileId}/${ROUTES.ADD_RECEIPT}`, {
              state: {
                padScheduleId: rowItem?.id,
              },
            })
          }
        >
          <Icon iconName="Script" style={{ transform: 'scale(-1, 1)' }} /> {t.NEW_RECEIPT}
        </Menu.Item>
      )}
    </Menu>
  );

  return (
    <div onClick={(e) => e.stopPropagation()}>
      <Dropdown
        overlay={actionMenu}
        placement="bottomLeft"
        trigger={['click']}
        getPopupContainer={(triggerNode: HTMLElement) => triggerNode.parentElement as HTMLElement}
      >
        <div style={{ cursor: 'pointer' }}>
          <Icon iconName="More" />
        </div>
      </Dropdown>
    </div>
  );
}

export default RowActionMenu;
