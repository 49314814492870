import { useEffect, useState, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { Input, Tabs } from 'antd';

import DashboardTable from './DashboardTable/DashboardTable';
import ActionTable from './ActionTable/ActionTable';
import Layout from '../../components/Layout/Layout';
import PageTitle from '../../components/PageTitle/PageTitle';

import useLocale from '../../hooks/useLocale';

import { ROUTES } from '../../constants/routes';
import { ClientService } from '../../shared/api/ClientService';
import {
  ALL_TASKS,
  CLAIM_ACTIONS,
  IE_ACTIONS,
  TAX_RETURN_ACTIONS,
  PROPOSAL_ACTIONS,
  BANKING_ACTIONS,
  REPORT_170_ACTIONS,
  COURT_ACTIONS,
  SRD_ACTIONS,
  TRUSTEE_ACTIONS,
} from './constants';

import './LandingPage.scss';

const { Search } = Input;

const LandingPage = (): JSX.Element => {
  const { t } = useLocale();
  const { TabPane } = Tabs;
  const navigate = useNavigate();

  const [searchTerm, setSearchTerm] = useState<string>('');
  const [filterText, setFilterText] = useState<string>('');
  const [activeKey, setActiveKey] = useState<string>(ALL_TASKS);

  const handleKeyChange = useCallback((key) => setActiveKey(key), []);

  useEffect(() => {
    const redirect = localStorage.getItem('redirect');
    const fileIds = localStorage.fileIdArray != null ? JSON.parse(localStorage.fileIdArray) : '';
    console.log(`redirect: ${redirect} / files:`, fileIds);
    if (redirect) {
      localStorage.removeItem('redirect');
      localStorage.removeItem('fileIdArray');
      navigate(ROUTES.FILE_PREVIEW, { state: { fileIdArray: fileIds } });
    }
  });

  return (
    <Layout page={ROUTES.DASHBOARD}>
      <div className="LandingPage">
        <PageTitle title={t.DASHBOARD} />
        <div style={{ margin: '60px 0 50px', display: 'flex', justifyContent: 'center' }}>
          <Search
            placeholder={t.DASHBOARD_SEARCH_PLACEHOLDER}
            value={searchTerm}
            onChange={(e) => {
              if (Boolean(searchTerm) && !Boolean(e.target.value)) {
                setFilterText('');
              }
              setSearchTerm(e.target.value);
            }}
            onSearch={() => {
              setFilterText(searchTerm);
              setActiveKey(ALL_TASKS);
            }}
            style={{ maxWidth: 450 }}
            allowClear
          />
        </div>
        <Tabs onChange={handleKeyChange} activeKey={activeKey} destroyInactiveTabPane>
          <TabPane tab={t.ALL_TASKS} key={ALL_TASKS}>
            <DashboardTable filterText={filterText} />
          </TabPane>
          <TabPane tab={t.CLAIM_ACTIONS} key={CLAIM_ACTIONS}>
            <ActionTable teamTypes={[ClientService.TeamTypeEnum.POC_CPC, ClientService.TeamTypeEnum.Asset_CPC]} />
          </TabPane>
          <TabPane tab={t.I_E_ACTIONS} key={IE_ACTIONS}>
            <ActionTable teamTypes={[ClientService.TeamTypeEnum.IandE_CPC]} />
          </TabPane>
          <TabPane tab={t.TAX_RETURN_ACTIONS} key={TAX_RETURN_ACTIONS}>
            <ActionTable teamTypes={[ClientService.TeamTypeEnum.Tax_CPC]} />
          </TabPane>
          <TabPane tab={t.PROPOSAL_ACTIONS} key={PROPOSAL_ACTIONS}>
            <ActionTable teamTypes={[ClientService.TeamTypeEnum.Proposal_CPC]} />
          </TabPane>
          <TabPane tab={t.BANKING_ACTIONS} key={BANKING_ACTIONS}>
            <ActionTable teamTypes={[ClientService.TeamTypeEnum.Banking_CPC]} />
          </TabPane>
          <TabPane tab={t.ACTIONS_170} key={REPORT_170_ACTIONS}>
            <ActionTable teamTypes={[ClientService.TeamTypeEnum.Report_170_CPC]} />
          </TabPane>
          <TabPane tab={t.COURT_ACTIONS} key={COURT_ACTIONS}>
            <ActionTable teamTypes={[ClientService.TeamTypeEnum.Court_CPC]} />
          </TabPane>
          <TabPane tab={t.SRD_ACTIONS} key={SRD_ACTIONS}>
            <ActionTable teamTypes={[ClientService.TeamTypeEnum.SRD_CPC]} />
          </TabPane>
          <TabPane tab={t.TRUSTEE_ACTIONS} key={TRUSTEE_ACTIONS}>
            <ActionTable teamTypes={[ClientService.TeamTypeEnum.Trustee]} />
          </TabPane>
        </Tabs>
      </div>
    </Layout>
  );
};

export default LandingPage;
