import type { IMarketingReportSearchCriteria } from './types';

const argumentifyMarketingReportSearchCriteria = ({
  appointmentDate_FromDate,
  appointmentDate_ToDate,
  prospectCreationDate_FromDate,
  prospectCreationDate_ToDate,
}: IMarketingReportSearchCriteria) =>
  [
    appointmentDate_FromDate,
    appointmentDate_ToDate,
    prospectCreationDate_FromDate,
    prospectCreationDate_ToDate,
  ] as const;

export { argumentifyMarketingReportSearchCriteria };
