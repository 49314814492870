import { useState, useEffect, useCallback } from 'react';
import { Radio, Form, Row, Col, FormInstance, Spin, Tabs } from 'antd';
import moment from 'moment';
import { Icon } from '@fluentui/react/lib/Icon';

import NewFiles from './NewFiles/NewFiles';
import ExistingFiles from './ExistingFiles/ExistingFiles';
import FoldersWithLoad from './FoldersWithLoad/FoldersWithLoad';
import SupportingDocsLabel from './SupportingDocsLabel';

import useLocale from '../../hooks/useLocale';
import { eventBus } from '../../utils/eventBus';
import { UPLOAD_FILES_EVENT } from '../../constants/eventBus';
import {
  argumentifyCreateDocument,
  argumentifyCreateSupportingDocument,
  argumentifyDocumentsSearchCriteria,
  convertUndefinedToTrue,
} from './utils';
import {
  AttachmentsFormProps,
  FileProps,
  NewSupportingFileRowLayoutProps,
  AttachmentsTabFlatLayoutProps,
  DraggerProps,
  FileActionEnum,
  FilesColumnsProps,
  AttachmentTypeEnum,
  OverrideInfo,
} from './types';
import genericMessage from '../../utils/genericMessage';
import { ClientService } from '../../shared/api/ClientService';
import API from '../../utils/api';

import './AttachmentsTab.scss';
import styles from '../../styles/style.module.scss';
import { sanitizeDocumentName } from '../../pages/ApplicationOverviewPage/DocumentsContent/utils';

import SupportingDocsLabelOLD from './SupportingDocsLabelOLD';

const EXISTING_FILE_TAB = 'existing-file';
const NEW_FILE_TAB = 'new-file';

export interface IUploadEventDetails {
  onUploadSuccess?: (documentsIds?: string[]) => void;
  onUploadError?: (error?: string) => void;
  uploadAndShare?: boolean;
}

export interface IAttachmentTabProps {
  applicationFileId?: string;
  attachingDocuments?: ClientService.DocumentDto[];
  form?: FormInstance<AttachmentsFormProps>;
  supportingFolderCode?: string;
  prefixCodes?: (string | undefined)[];
  isPrefixCodesRequired?: boolean;
  limitSupportingDocsByPrefixCode?: (string | undefined)[];
  isFlatLayout?: boolean;
  flatLayoutProps?: AttachmentsTabFlatLayoutProps;
  newSupportingFileRowLayoutProps?: NewSupportingFileRowLayoutProps;
  draggerProps?: DraggerProps;
  required?: boolean;
  columnsProps?: FilesColumnsProps;
  disabled?: boolean;
  attachmentType?: AttachmentTypeEnum;
  appFormAssetsDebtsAssetId?: string;
  businessNames?: (string | undefined)[];
  updateUploadListenerStatus?: (canUpload: boolean) => void;
  existingDocumentIds?: string[];
  supportingDocumentId?: string;
}

function AttachmentsTab({
  applicationFileId,
  attachingDocuments,
  form,
  supportingFolderCode,
  limitSupportingDocsByPrefixCode,
  prefixCodes,
  isPrefixCodesRequired = false,
  isFlatLayout = false,
  flatLayoutProps,
  newSupportingFileRowLayoutProps,
  draggerProps,
  required = false,
  columnsProps,
  disabled = false,
  attachmentType = undefined,
  appFormAssetsDebtsAssetId,
  businessNames,
  updateUploadListenerStatus,
  existingDocumentIds,
  supportingDocumentId,
}: IAttachmentTabProps): JSX.Element {
  const { t } = useLocale();
  const { TabPane } = Tabs;

  const [uploading, setUploading] = useState<boolean>(false);

  const [listFolders, setListFolders] = useState<ClientService.LookupDto[]>();
  const [listFoldersLoading, setListFoldersLoading] = useState<boolean>(false);

  const [isInstruction, setIsInstruction] = useState<boolean>(
    Boolean(isFlatLayout) && Boolean(flatLayoutProps?.hasInstruction)
  );

  const [activeTab, setActiveTab] = useState<string>(EXISTING_FILE_TAB);

  const requestListFolders = useCallback(async () => {
    setListFoldersLoading(true);
    const response = await API.listFolders().catch(() => setListFoldersLoading(false));
    if (response) {
      setListFolders(response);
    }
    setListFoldersLoading(false);
  }, []);

  useEffect(() => {
    if (!listFolders) {
      requestListFolders();
    }
  }, [listFolders, requestListFolders]);

  useEffect(() => {
    if (attachingDocuments && form) {
      form.setFieldsValue({ existingFiles: attachingDocuments });
    }
  }, [attachingDocuments, form]);

  const supportingFolderId = listFolders?.find((item) => item.code === supportingFolderCode)?.id;

  const handleUploadError = useCallback((eventDetail?: IUploadEventDetails, errorMessage?: string) => {
    setUploading(false);
    eventDetail?.onUploadError?.(errorMessage);
  }, []);

  const saveAttachedFiles = useCallback(
    async (e) => {
      const eventDetail = e?.detail as IUploadEventDetails;
      const existingFiles = form?.getFieldValue('existingFiles') as ClientService.DocumentDto[];
      const documentIds = existingFiles?.map((item) => item?.id);

      const newFiles = form?.getFieldValue('newFiles') as FileProps[];

      if (!existingFiles?.length && !newFiles?.length && required) {
        genericMessage.error({}, t.NO_FILES_SELECTED);
        return;
      }

      if (attachmentType === AttachmentTypeEnum.AppFormIncomeTaxReturn) {
        if (form?.getFieldValue('yearLastReturnFiled') && form.getFieldValue('spouseYearLastReturnFiled')) {
          if ((existingFiles?.length || 0) + (newFiles?.length || 0) > 2) {
            handleUploadError(eventDetail, t.UPLOAD_FAILED_APPFORM_OVER_TWO);
            return;
          }

          const newFilesForSpouse = newFiles?.filter((file) => file?.isForSpouse === true);
          const newFilesForSelf = newFiles?.filter((file) => file?.isForSpouse !== true);
          const existingFilesForSpouse = existingFiles?.filter((file) => file?.isForSpouse === true);
          const existingFilesForSelf = existingFiles?.filter((file) => file?.isForSpouse !== true);

          if ((newFilesForSelf?.length || 0) + (existingFilesForSelf?.length || 0) === 2) {
            handleUploadError(eventDetail, t.UPLOAD_FAILED_APPFORM_ONE_DOCUMENT_FOR_SPOUSE_REQUIRED);
            return;
          }

          if ((newFilesForSpouse?.length || 0) + (existingFilesForSpouse?.length || 0) === 2) {
            handleUploadError(eventDetail, t.UPLOAD_FAILED_APPFORM_ONE_DOCUMENT_FOR_CLIENT_REQUIRED);
            return;
          }
        } else if (form?.getFieldValue('yearLastReturnFiled')) {
          if ((existingFiles?.length || 0) + (newFiles?.length || 0) > 1) {
            handleUploadError(eventDetail, t.UPLOAD_FAILED_APPFORM_OVER_ONE);
            return;
          }

          if (newFiles) {
            newFiles.forEach((file) => {
              file.isForSpouse = false;
            });
          }
        } else if (form?.getFieldValue('spouseYearLastReturnFiled')) {
          if ((existingFiles?.length || 0) + (newFiles?.length || 0) > 1) {
            handleUploadError(eventDetail, t.UPLOAD_FAILED_APPFORM_OVER_ONE);
            return;
          }

          if (newFiles) {
            newFiles.forEach((file) => {
              file.isForSpouse = true;
            });
          }
        }
      }

      if (newFiles?.length) {
        setUploading(true);
        for (const file of newFiles) {
          if (file?.documentFolderId && file?.documentPrefixId) {
            const createDocumentDetails = {
              fileModifiedDate: moment(),
              content: { fileName: sanitizeDocumentName(file?.name as string), data: file?.originFileObj as Blob },
              // By default isVisible = false, 22521
              isVisible: eventDetail?.uploadAndShare ?? false,
              documentFolderId: file?.documentFolderId,
              documentPrefixId: file?.documentPrefixId,
              additionalInformation: file?.additionalInformation,
              isForSpouse: file?.isForSpouse || false,
              businessName: file?.businessName,
            };

            let response = undefined;

            if (supportingDocumentId) {
              response = await API.uploadsupportingDocument(
                ...argumentifyCreateSupportingDocument({
                  ...createDocumentDetails,
                  supportingDocumentId,
                })
              ).catch(() => handleUploadError(eventDetail, t.UPLOAD_FAILED));
            } else {
              response = await API.documentsPOST(
                ...argumentifyCreateDocument({
                  ...createDocumentDetails,
                  fileId: applicationFileId,
                })
              ).catch(() => handleUploadError(eventDetail, t.UPLOAD_FAILED));
            }

            if (response?.returnId && !response?.hasErrors) documentIds.unshift(response?.returnId);

            if (response?.hasErrors && eventDetail?.onUploadError) {
              eventDetail?.onUploadError(response?.messages?.[0]?.body || t.UPLOAD_FAILED);
            }
          }
        }
        setUploading(false);
      }

      form?.setFieldsValue({ newFiles: [] });

      eventDetail?.onUploadSuccess?.(documentIds as string[]);
    },
    [
      form,
      required,
      attachmentType,
      t.NO_FILES_SELECTED,
      t.UPLOAD_FAILED_APPFORM_PERSONAL_INFO_OVER_ONE,
      t.UPLOAD_FAILED_APPFORM_ONE_DOCUMENT_FOR_SPOUSE_REQUIRED,
      t.UPLOAD_FAILED_APPFORM_ONE_DOCUMENT_FOR_CLIENT_REQUIRED,
      t.UPLOAD_FAILED_APPFORM_OVER_ONE,
      t.UPLOAD_FAILED_APPFORM_OVER_TWO,
      t.UPLOAD_FAILED,
      handleUploadError,
      supportingDocumentId,
      applicationFileId,
    ]
  );

  useEffect(() => {
    if (updateUploadListenerStatus) updateUploadListenerStatus(true);

    eventBus.on(UPLOAD_FILES_EVENT, saveAttachedFiles);

    return () => {
      if (updateUploadListenerStatus) updateUploadListenerStatus(false);

      eventBus.remove(UPLOAD_FILES_EVENT, saveAttachedFiles);
    };
  }, [saveAttachedFiles]);

  const getSupportingDocOverrides = useCallback(() => {
    if (supportingFolderId && supportingFolderCode == 'assets') {
      if (existingDocumentIds != undefined && existingDocumentIds.length > 0) {
        setUploading(true);
        API.documentsGET(
          ...argumentifyDocumentsSearchCriteria({
            fileId: applicationFileId,
            documentIds: existingDocumentIds,
          })
        ).then((existingFiles) => {
          API.getApplicabilityOverride(applicationFileId, appFormAssetsDebtsAssetId, supportingFolderId).then(
            (value) => {
              const SupportingDocOverrides = value.map(
                ({ documentPrefixId, overrideReason }) =>
                  ({
                    documentPrefixId,
                    overrideReason: overrideReason,
                    isOverridden: true,
                    isDisabled: false,
                  } as OverrideInfo)
              );
              existingFiles.items?.forEach((item) => {
                const itemIndex = SupportingDocOverrides.findIndex((i) => i.documentPrefixId == item.documentPrefixId);
                if (itemIndex > -1) {
                  SupportingDocOverrides[itemIndex].isDisabled = true;
                } else if (item.documentPrefixId) {
                  SupportingDocOverrides.push({
                    documentPrefixId: item.documentPrefixId,
                    overrideReason: '',
                    isOverridden: false,
                    isDisabled: true,
                  });
                }
              });
              form?.setFieldsValue({
                SupportingDocOverrides: SupportingDocOverrides,
              });
              console.log('SupportingDocOverrides', SupportingDocOverrides);
            }
          );
        });
        setUploading(false);
      } else {
        API.getApplicabilityOverride(applicationFileId, appFormAssetsDebtsAssetId, supportingFolderId).then((value) => {
          const SupportingDocOverrides = value.map(
            ({ documentPrefixId, overrideReason }) =>
              ({
                documentPrefixId,
                overrideReason: overrideReason,
                isOverridden: true,
                isDisabled: false,
              } as OverrideInfo)
          );
          form?.setFieldsValue({
            SupportingDocOverrides: SupportingDocOverrides,
          });
        });
      }
    }
  }, [supportingFolderId, form, supportingFolderCode, appFormAssetsDebtsAssetId]);

  useEffect(() => {
    getSupportingDocOverrides();
  }, [supportingFolderId]);

  if (!form) {
    return <div>{t.NO_FORM}</div>;
  }

  if (!applicationFileId) {
    return <div>{t.NO_APPLICATION_FILE_ID}.</div>;
  }

  if (isFlatLayout && supportingFolderId) {
    return (
      <div className="AttachmentsTab">
        {isInstruction && (
          <Row
            className="AttachmentsTab__skip-message"
            align="middle"
            justify="space-between"
            style={{ backgroundColor: draggerProps?.disabled ? styles.colorActiveRow : styles.colorLightBlue }}
          >
            <Col>{flatLayoutProps?.instructionText || t.IF_YOU_HAVE_DOCS_UPLOAD_ELSE_SKIP_AND_UPLOAD_LATER}</Col>
            <Col>
              <Icon
                iconName="StatusErrorFull"
                className="AttachmentsTab__skip-cross"
                onClick={() => setIsInstruction(false)}
              />
            </Col>
          </Row>
        )}

        {convertUndefinedToTrue(flatLayoutProps?.hasUploader) && (
          <Form.Item
            label={
              <>
                {flatLayoutProps?.label}
                {flatLayoutProps?.hasSupportingList &&
                  ((supportingFolderCode == 'assets' && (
                    <SupportingDocsLabel
                      supportingFolderId={supportingFolderId}
                      prefixCodes={prefixCodes}
                      isPrefixCodesRequired={isPrefixCodesRequired}
                      hint={flatLayoutProps?.supportingListLabelHint}
                      limitSupportingDocsByPrefixCode={limitSupportingDocsByPrefixCode}
                    />
                  )) ||
                    (supportingFolderCode != 'assets' && (
                      <SupportingDocsLabelOLD
                        supportingFolderId={supportingFolderId}
                        prefixCodes={prefixCodes}
                        isPrefixCodesRequired={isPrefixCodesRequired}
                        hint={flatLayoutProps?.supportingListLabelHint}
                        limitSupportingDocsByPrefixCode={limitSupportingDocsByPrefixCode}
                      />
                    )))}
              </>
            }
            className="AttachmentsTab__newFiles"
            shouldUpdate
            required={required}
          >
            <NewFiles
              form={form}
              supportingFolderId={supportingFolderId}
              draggerProps={{
                ...draggerProps,
                mode: draggerProps?.mode || FileActionEnum.Upload,
                multiple: draggerProps?.multiple || true,
                totalFilesSizeLimitMB: draggerProps?.totalFilesSizeLimitMB || 100,
              }}
              prefixCodes={prefixCodes}
              newSupportingFileRowLayoutProps={newSupportingFileRowLayoutProps}
              columnsProps={columnsProps}
              businessNames={businessNames}
              supportingFolderCode={supportingFolderCode}
            />
          </Form.Item>
        )}

        {flatLayoutProps?.hasExistingFiles && (
          <Form.Item shouldUpdate noStyle>
            <ExistingFiles
              applicationFileId={applicationFileId as string}
              form={form}
              supportingFolderId={supportingFolderId}
              prefixCodes={prefixCodes}
              columnsProps={{
                ...columnsProps,
                isAdditionalInfoColumnDisabled: !flatLayoutProps?.isAdditionalInfoEditableForExistingFiles,
              }}
              appFormAssetsDebtsAssetId={appFormAssetsDebtsAssetId}
              documentIds={existingDocumentIds}
              onDocumentDeleted={getSupportingDocOverrides}
            />
          </Form.Item>
        )}
      </div>
    );
  }

  return (
    <Spin spinning={uploading || listFoldersLoading}>
      <Form.Item noStyle shouldUpdate>
        <Tabs
          activeKey={activeTab}
          renderTabBar={() => (
            <Row align="middle" justify="space-between" gutter={20}>
              <Col span={12}>
                <Form.Item label={t.ATTACHMENT}>
                  <Radio.Group
                    value={activeTab}
                    onChange={(e) => setActiveTab(e?.target?.value)}
                    buttonStyle="solid"
                    optionType="button"
                    key="postType"
                    disabled={disabled}
                  >
                    <Radio.Button value={EXISTING_FILE_TAB}>{t.ATTACHMENT_EXISTING}</Radio.Button>
                    <Radio.Button value={NEW_FILE_TAB}>{t.ATTACHMENT_NEW}</Radio.Button>
                  </Radio.Group>
                </Form.Item>
              </Col>
              <Col span={activeTab === NEW_FILE_TAB ? 0 : 12}>
                <Form.Item label={t.FOLDER} name="searchFolderId">
                  <FoldersWithLoad
                    onChange={() => form.setFieldsValue({ searchFileId: undefined })}
                    disabled={disabled}
                  />
                </Form.Item>
              </Col>
            </Row>
          )}
          className="AttachmentsTab"
        >
          <TabPane key={NEW_FILE_TAB}>
            <NewFiles
              draggerProps={{
                ...draggerProps,
                mode: draggerProps?.mode !== undefined ? draggerProps?.mode : FileActionEnum.Upload,
                multiple: convertUndefinedToTrue(draggerProps?.multiple),
                totalFilesSizeLimitMB: draggerProps?.totalFilesSizeLimitMB || 100,
                disabled: disabled,
              }}
              form={form}
              prefixCodes={prefixCodes}
              columnsProps={columnsProps}
            />
          </TabPane>
          <TabPane key={EXISTING_FILE_TAB}>
            <ExistingFiles
              applicationFileId={applicationFileId as string}
              form={form}
              columnsProps={columnsProps}
              disabled={disabled}
              appFormAssetsDebtsAssetId={appFormAssetsDebtsAssetId}
              documentIds={existingDocumentIds}
            />
          </TabPane>
        </Tabs>
      </Form.Item>
    </Spin>
  );
}

export default AttachmentsTab;
