import { useCallback, useEffect, useMemo, useState } from 'react';
import { Col, Row, Form, Select, Spin } from 'antd';
import { useParams } from 'react-router-dom';
import { Icon } from '@fluentui/react/lib/Icon';

import DataItem from '../../../../../../components/DataItem/DataItem';
import Button from '../../../../../../components/Button/Button';
import SectionContainer from '../../../../../../components/SectionContainer/SectionContainer';
import SelectSuffixIcon from '../../../../../../components/SelectSuffixIcon/SelectSuffixIcon';

import useLocale from '../../../../../../hooks/useLocale';

import { ClientService } from '../../../../../../shared/api/ClientService';
import API from '../../../../../../utils/api';
import { eventBus } from '../../../../../../utils/eventBus';
import { ADMIN_INFO_CHANGE_EVENT, DISCHARGE_UPDATE_EVENT } from '../../../../../../constants/eventBus';

import '../Discharge.scss';

interface IProps {
  disableAllFields?: boolean;
}

const DischargeStatusForm = ({ disableAllFields }: IProps) => {
  const { t } = useLocale();

  const { Option } = Select;
  const { applicationFileId } = useParams<{ applicationFileId: string }>();

  const [form] = Form.useForm<ClientService.AdminInfoDischargeDto>();
  const [initial, setInitial] = useState<ClientService.AdminInfoDischargeDto>();
  const [loading, setLoading] = useState<boolean>(false);

  const DISCHARGE_DROPDOWN_OPTIONS = useMemo(
    () => [
      { value: ClientService.FileProcessStatusEnum.Pending, label: t.PENDING, disabled: true },
      { value: ClientService.FileProcessStatusEnum.InProgress, label: t.IN_PROGRESS, disabled: true },
      { value: ClientService.FileProcessStatusEnum.Complete, label: t.COMPLETE },
    ],
    [t.COMPLETE, t.IN_PROGRESS, t.PENDING]
  );

  const requestDischarge = useCallback(
    async (applicationFileId: string) => {
      setLoading(true);
      const response = await API.dischargeGET(applicationFileId).catch(() => setLoading(false));
      setLoading(false);

      if (response) {
        form.setFieldsValue(response);
        setInitial(response);
      }
    },
    [form]
  );

  const requestUpdateDischargeStatus = useCallback(
    async ({ dischargeProcessStatus }) => {
      setLoading(true);
      const response = await API.dischargePUT(
        applicationFileId as string,
        { dischargeProcessStatus } as ClientService.AdminInfoDischargeUpdateDto
      ).catch(() => setLoading(false));
      setLoading(false);

      if (response?.result === ClientService.Result.Successful) {
        eventBus.dispatch(ADMIN_INFO_CHANGE_EVENT);
      }
    },
    [applicationFileId]
  );

  const statusOptions = useMemo(() => {
    const options = [...DISCHARGE_DROPDOWN_OPTIONS];
    switch (initial?.dischargeProcessStatus) {
      case ClientService.FileProcessStatusEnum.Pending:
        return options?.splice(0, 1);
      case ClientService.FileProcessStatusEnum.InProgress:
        return options?.splice(1, initial?.isProcessAllowedToBeCompleted ? 2 : 1);
      case ClientService.FileProcessStatusEnum.Incomplete:
        return options?.splice(1, initial?.isProcessAllowedToBeCompleted ? 2 : 1);
      case ClientService.FileProcessStatusEnum.Complete:
        return options?.splice(2, 1);
      default:
        return options?.splice(0, 1);
    }
  }, [DISCHARGE_DROPDOWN_OPTIONS, initial?.dischargeProcessStatus, initial?.isProcessAllowedToBeCompleted]);

  const requestDischargeByFileId = useCallback(() => {
    if (applicationFileId) requestDischarge(applicationFileId);
  }, [applicationFileId, requestDischarge]);

  useEffect(() => {
    if (applicationFileId) requestDischarge(applicationFileId);
  }, [applicationFileId, requestDischarge]);

  useEffect(() => {
    eventBus.on(DISCHARGE_UPDATE_EVENT, requestDischargeByFileId);

    return () => {
      eventBus.remove(DISCHARGE_UPDATE_EVENT, requestDischargeByFileId);
    };
  }, [requestDischargeByFileId]);

  return (
    <Spin spinning={loading}>
      <SectionContainer title={t.DISCHARGE} style={{ marginBottom: 0, paddingLeft: 0 }} backgroundColor="light">
        <Form layout="vertical" form={form} onFinish={requestUpdateDischargeStatus}>
          <Form.Item>
            <Row gutter={20}>
              <DataItem
                colSpan={8}
                label={t.DISCHARGE_TOTAL_DUE}
                layout="vertical"
                value={{ value: initial?.totalDue || 0, format: 'currency' }}
              />
              <DataItem
                colSpan={8}
                label={t.DISCHARGE_TOTAL_PAYMENTS}
                layout="vertical"
                value={{ value: initial?.totalPayments || 0, format: 'currency', color: 'red' }}
                valueIcon={(initial?.totalPayments || 0) > (initial?.totalDue || 0) && <Icon iconName="AlertSolid" />}
              />
            </Row>
          </Form.Item>
          <Row gutter={20} align="bottom">
            <Col span={8}>
              <Form.Item label={t.DISCHARGE_STATUS} required name="dischargeProcessStatus">
                <Select
                  placeholder={t.SELECT}
                  size="large"
                  suffixIcon={<SelectSuffixIcon />}
                  disabled={disableAllFields}
                >
                  {statusOptions?.map((item) => (
                    <Option key={item?.value} value={item.value} disabled={item?.disabled}>
                      {item?.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col>
              <Form.Item>
                <Button
                  kind="cancel"
                  onClick={() => form.setFieldsValue(initial as ClientService.AdminInfoDischargeDto)}
                  disabled={disableAllFields}
                >
                  {t.CANCEL}
                </Button>
              </Form.Item>
            </Col>
            <Col>
              <Form.Item>
                <Button kind="primary" htmlType="submit" disabled={disableAllFields}>
                  {t.SAVE}
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </SectionContainer>
    </Spin>
  );
};

export default DischargeStatusForm;
