import React, { ReactNode } from 'react';
import { Dropdown as AntdDropdown } from 'antd';
import { DropdownButtonProps } from 'antd/lib/dropdown';
import Icon from '../../Icon/Icon';

import './DropdownButton-KS.scss';
import './DropdownButton.scss';

type Kinds_Custom = 'primary' | 'secondary' | 'cancel' | 'special' | 'success' | 'darkgray';
type Kinds_PassThrough = 'link' | 'ghost' | 'dashed' | 'text';

interface CustomDropdownButtonProps extends DropdownButtonProps {
  kind?: Kinds_Custom | Kinds_PassThrough;
  narrow?: boolean; // removes the min-width setting so width is determined only by content and padding
  padding?: 'small' | 'medium' | 'large' | 'no-padding' | boolean;
  height?: 'short' | 'standard' | 'tall';
  children?: ReactNode;
  style?: React.CSSProperties;
}

const DropdownButton = ({
  kind,
  narrow = false,
  padding = 'small',
  height = 'standard',
  className,
  style,
  children,
  ...props
}: CustomDropdownButtonProps): JSX.Element => {
  let cssClass = `Button Button__${kind} `;
  cssClass += height ? `Button__height-${height} ` : '';
  cssClass += padding ? `Button__${padding} ` : '';
  cssClass += narrow ? 'Button__narrow ' : '';

  return (
    <div className={`CustomDropdownButton ${className ? className : ''}`} style={style}>
      <AntdDropdown.Button {...props} className={cssClass} icon={<Icon iconName="CaretDownSolid8" fontSize="9px" />}>
        {children}
      </AntdDropdown.Button>
    </div>
  );
};

export default DropdownButton;
