import { SearchOutlined } from '@ant-design/icons';
import type { TableColumnType } from 'antd';
import { Button, Input, Space } from 'antd';
import useLocale from '../../hooks/useLocale';

export const useColumnSearchProps = <T,>() => {
  const { t } = useLocale();

  const handleSearch = (selectedKeys: string[], updateSearchValue: (searchValue: string) => void) => {
    updateSearchValue(selectedKeys[0]);
  };

  const handleReset = (clearFilters: () => void, updateSearchValue: (searchValue?: string) => void) => {
    updateSearchValue();
    clearFilters();
  };

  const getColumnSearchProps = (
    placeholder: string,
    updateSearchValue: (searchValue?: string) => void
  ): TableColumnType<T> => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 16 }} onKeyDown={(e) => e.stopPropagation()}>
        <Input
          placeholder={placeholder}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys as string[], updateSearchValue)}
          style={{ marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys as string[], updateSearchValue)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
              backgroundColor: '#ed1a3b',
              borderColor: '#ed1a3b',
              fontWeight: 'bold',
            }}
          >
            {t.SEARCH}
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters, updateSearchValue)}
            size="small"
            style={{
              width: 90,
              backgroundColor: '#ed1a3b',
              borderColor: '#ed1a3b',
              color: '#fff',
              fontWeight: 'bold',
            }}
          >
            {t.RESET}
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm();
            }}
            style={{ color: 'black', fontWeight: 'bold' }}
          >
            {t.CLOSE}
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered: boolean) => <SearchOutlined style={{ color: filtered ? '#1677ff' : undefined }} />,
  });

  return { getColumnSearchProps };
};
