import { useMemo } from 'react';
import { Tooltip, TooltipProps } from 'antd';
import { Icon as FluentIcon, IIconProps } from '@fluentui/react';
import { getAsSystemColorOrUnchanged, SystemColorsType } from '../../utils/systemColors';

import styles from './Icon.module.scss';
export interface IconProps extends IIconProps {
  iconName: string;
  color?: SystemColorsType;
  fontSize?: string;
  bold?: boolean;
  bordered?: boolean;
  degreesToRotate?: string; // e.g. -90
  tooltip?: string | TooltipProps;
  className?: string;
  style?: React.CSSProperties;
  onClick?: (e: any) => void;
}

function Icon({
  iconName,
  color,
  fontSize,
  bold,
  bordered,
  degreesToRotate,
  tooltip,
  className,
  style,
  onClick,
  ...props
}: IconProps): JSX.Element {
  type TIconPropStyles = {
    [key: string]: string;
  };

  const iconPropStyles: TIconPropStyles = {};

  if (iconName === 'GoTo') {
    iconName = 'DrillDown';
    degreesToRotate = '-90';
  }

  if (color) iconPropStyles.color = getAsSystemColorOrUnchanged(color);
  if (fontSize) iconPropStyles.fontSize = fontSize;
  if (bold) iconPropStyles.fontWeight = 'bold';
  if (degreesToRotate) iconPropStyles.transform = `rotate(${degreesToRotate}deg)`;
  if (onClick || tooltip) {
    iconPropStyles.cursor = 'pointer';
  } else {
    iconPropStyles.cursor = 'default';
  }

  const iconElement = (
    <FluentIcon
      iconName={iconName}
      style={{
        ...iconPropStyles,
        ...style,
      }}
      onClick={(e) => {
        if (onClick) {
          onClick(e);
        }
      }}
      {...props}
    />
  );

  const tooltipOptions: TooltipProps | undefined = useMemo(() => {
    if (tooltip) {
      if (typeof tooltip === 'string') {
        return { placement: 'top', title: tooltip, arrowPointAtCenter: true };
      } else {
        return tooltip;
      }
    }
    return undefined;
  }, [tooltip]);

  return (
    <div className={`${styles.PDS_Icon} ${className ? className : ''} ${bordered ? styles.bordered : ''}`}>
      {tooltip ? <Tooltip {...(tooltipOptions as TooltipProps)}>{iconElement}</Tooltip> : iconElement}
    </div>
  );
}

export default Icon;
