import { useCallback, useState, useEffect } from 'react';
import { Row, Col, Input, Form, Modal, Spin } from 'antd';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import update from 'immutability-helper';

import Button from '../../../../components/Button/Button';
import { IconRed } from '../Icons';
import DndRow from './DndRow';
import FoldersWithLoad from '../../../../components/AttachmentsTab/FoldersWithLoad/FoldersWithLoad';
import PrefixesWithLoad from '../../../../components/AttachmentsTab/PrefixesWithLoad/PrefixesWithLoad';

import genericMessage from '../../../../utils/genericMessage';
import { ClientService } from '../../../../shared/api/ClientService';
import API from '../../../../utils/api';
import useLocale from '../../../../hooks/useLocale';
import { IDocument } from '../types';

import './BundleFilesModal.scss';

interface IProps {
  fileId?: string;
  selectedIds?: string[];
  documents?: ClientService.IDocumentDto[];
  onOk?: () => void;
  onCancel?: () => void;
}

function BundleFilesModal({ fileId, selectedIds, documents, onOk, onCancel }: IProps): JSX.Element {
  const { t } = useLocale();

  const [form] = Form.useForm<ClientService.DocumentBundleDto>();

  const [documentFolderId, setDocumentFolderId] = useState<string>();

  const [types, setTypes] = useState<ClientService.LookupDto[]>();
  const [typesLoading, setTypesLoading] = useState<boolean>(false);

  const [data, setData] = useState<IDocument[]>();
  const [bundleLoading, setBundleLoading] = useState<boolean>(false);

  const moveItem = useCallback(
    (dragIndex, hoverIndex) => {
      setData((prevState) => {
        if (!prevState) return;

        const data = [...prevState];
        const dragItem = data[dragIndex];
        const prevItem = data.splice(hoverIndex, 1, dragItem);
        data.splice(dragIndex, 1, prevItem[0]);

        return data;
      });
    },
    [setData]
  );

  const deleteItem = useCallback(
    (index) =>
      setData((prevState) => {
        if (!prevState) return;
        return update(prevState, { $splice: [[index, 1]] });
      }),

    [setData]
  );

  useEffect(() => {
    if (!data && types) {
      const filtered = documents
        ?.filter((item) => (selectedIds || [])?.indexOf(item?.id as string) >= 0)
        .map((item) => ({
          ...item,
          isPdf: types.find((type) => type.id === item.documentTypeId)?.code === 'application/pdf',
        })) as IDocument[];
      const sorted = filtered.sort((a, b) => (a?.name || '')?.localeCompare(b?.name || ''));
      setData(sorted);
    }
  }, [data, documents, selectedIds, types]);

  const requestListTypes = useCallback(async () => {
    setTypesLoading(true);
    const response = await API.listTypes();
    if (response) setTypes(response);

    setTypesLoading(false);
  }, []);

  const handleBundle = useCallback(
    async ({ documentFolderId, documentPrefixId, additionalInformation }) => {
      setBundleLoading(true);
      const response = await API.bundle({
        fileId,
        documentFolderId,
        documentPrefixId,
        additionalInformation,
        documentIds: data?.map((item) => item?.id as string),
      } as ClientService.DocumentBundleDto);

      setBundleLoading(false);
      if (response?.result === ClientService.Result.Successful) {
        genericMessage.success(t.DOCUMENTS_BUNDLE_SUCCESS);
      } else {
        genericMessage.error({}, response?.messages?.[0]?.body || '');
      }

      if (onOk) onOk();
    },
    [data, onOk, t.DOCUMENTS_BUNDLE_SUCCESS, fileId]
  );

  useEffect(() => {
    if (!types) requestListTypes();
  }, [types, requestListTypes]);

  const isAnyNotPdf = Boolean(data?.find((item) => !item.isPdf));

  return (
    <Modal
      destroyOnClose
      centered
      visible
      title={t.BUNDLE_FILES}
      className="BundleFilesModal"
      width={900}
      footer={null}
      closable
      onCancel={onCancel}
    >
      <Form form={form} layout="vertical" onFinish={handleBundle}>
        <Spin spinning={typesLoading || bundleLoading}>
          <Row gutter={20} align="middle">
            <Col span={12}>
              <Form.Item
                label={t.DOCUMENTS_FOLDER}
                rules={[{ required: true, message: t.REQUIRED_FIELD }]}
                name="documentFolderId"
              >
                <FoldersWithLoad onSelect={setDocumentFolderId} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label={t.DOCUMENTS_PREFIX}
                rules={[{ required: true, message: t.REQUIRED_FIELD }]}
                name="documentPrefixId"
              >
                <PrefixesWithLoad documentFolderId={documentFolderId} />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item label={t.ADDITIONAL_INFORMATION} name="additionalInformation">
                <Input size="large" />
              </Form.Item>
            </Col>
          </Row>

          {isAnyNotPdf && (
            <Row align="middle" justify="start" className="BundleFilesModal__warning" gutter={8}>
              <Col>
                <IconRed iconName="Info" style={{ fontWeight: 'bold' }} />
              </Col>
              <Col>One or more of the selected files is not PDF format. Please check and try again</Col>
            </Row>
          )}

          <Row>
            <Col span={8}>
              <div className="BundleFilesModal__label">{t.DOCUMENTS_FILENAME}</div>
            </Col>
            <Col span={7}>
              <div className="BundleFilesModal__label">{t.DOCUMENTS_FOLDER}</div>
            </Col>
            <Col>
              <div className="BundleFilesModal__label">{t.DOCUMENTS_PREFIX}</div>
            </Col>
          </Row>

          <DndProvider backend={HTML5Backend}>
            {data?.map((item, index) => (
              <DndRow
                key={item.id}
                index={index}
                id={item.id as string}
                document={item}
                moveItem={moveItem}
                deleteItem={deleteItem}
                hasDelete={data?.length > 2}
              />
            ))}
          </DndProvider>
          <Row gutter={12} justify="end" className="BundleFilesModal__buttons-container">
            <Col>
              <Form.Item>
                <Button kind="cancel" padding="large" onClick={onCancel}>
                  {t.CANCEL}
                </Button>
              </Form.Item>
            </Col>
            <Col>
              <Form.Item>
                <Button
                  kind="primary"
                  padding="large"
                  htmlType="submit"
                  disabled={isAnyNotPdf || !Boolean(data?.length)}
                >
                  {t.BUNDLE}
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Spin>
      </Form>
    </Modal>
  );
}

export default BundleFilesModal;
