import { Icon } from '@fluentui/react/lib/Icon';

import useLocale from '../../../hooks/useLocale';
import { DraggerProps } from '../types';

import '../AttachmentsTab.scss';
import styles from '../../../styles/style.module.scss';

type IDraggerInnerProps = Pick<DraggerProps, 'singleFileSizeLimitMB' | 'totalFilesSizeLimitMB' | 'disabled'>;

const DraggerInner = ({ ...props }: IDraggerInnerProps) => {
  const { t } = useLocale();
  return (
    <>
      <Icon
        iconName="CloudUpload"
        className="AttachmentsTab__icon"
        style={{ color: props?.disabled ? styles.colorSecondary : styles.colorDanger }}
      />
      <div className="AttachmentsTab__dragger--title">Click or drag file to this area to upload</div>
      <div className="AttachmentsTab__dragger--info">
        {props?.singleFileSizeLimitMB && (
          <span>{`${t.FILES_UNDER} ${props?.singleFileSizeLimitMB} ${t.MB_EACH}. `}</span>
        )}
        {props?.totalFilesSizeLimitMB && (
          <span>{`${t.TOTAL_FILE_SIZE_SHLD_NT_EXCEED} ${props?.totalFilesSizeLimitMB} ${t.MB}`}</span>
        )}
      </div>
    </>
  );
};

export default DraggerInner;
