import moment from 'moment';
import { DatePicker as AntdDatePicker, DatePickerProps, TimeRangePickerProps } from 'antd';
import { RangeValue } from 'rc-picker/lib/interface';
import 'moment/locale/fr-ca';
import frLocale from 'antd/es/date-picker/locale/fr_CA';
import enLocale from 'antd/es/date-picker/locale/en_US';

import { FormInstance } from 'antd/es/form/Form';

import { ILocale } from '../../types/ILocale';
import useLocale from '../../hooks/useLocale';

import './DatePicker-KS.scss';
import './DatePicker.scss';

const { RangePicker: AntdRangePicker } = AntdDatePicker;

export type DateOnChangeCustom = (value: moment.Moment | null) => void;
export type DateOnChangeRange = (values: RangeValue<moment.Moment> | [], formatString?: [string, string] | []) => void;
export type ValueSingle = moment.Moment | null;
export type ValueRange = RangeValue<moment.Moment | null> | [];

export type PickerTypes = 'single' | 'range';
export type DateSetter = React.Dispatch<React.SetStateAction<moment.Moment | null>>;
export type DateRangeSetter = React.Dispatch<React.SetStateAction<RangeValue<moment.Moment | null> | []>>;

interface ICommonProps {
  fullWidth?: boolean;
  className?: string;
  style?: React.CSSProperties;
}

type IDatePickerProps_Form = {
  type?: PickerTypes;
  form: FormInstance<any>; // the form object for the Form this DatePicker is being used in, if any
  formFieldName: string; // the name of the Form data property associated with an instance of this DatePicker, if any
  dateSetter?: DateSetter | DateRangeSetter | null;
  errorMsgSetter?: React.Dispatch<React.SetStateAction<string>>;
};

type IDatePickerProps_Standard = {
  type?: PickerTypes;
  dateSetter: DateSetter | DateRangeSetter | null; // outside of a <Form> the state value setter is required
  errorMsgSetter?: React.Dispatch<React.SetStateAction<string>>;
};

type IDatePickerProps = (DatePickerProps | TimeRangePickerProps) &
  (IDatePickerProps_Form | IDatePickerProps_Standard) &
  ICommonProps;

interface IDefaultProps {
  onChange: DateOnChangeCustom | DateOnChangeRange | null;
  onKeyDown: null | ((e: any) => void);
  dropdownClassName: string;
}

interface IformDataObject {
  [key: string]: any;
}

interface IGetRangeValuesArgs {
  currentRange: ValueRange;
  fieldPlaceholder: string;
  form?: FormInstance<any> | null;
  formFieldName?: string | null;
}

// Create the Date Range value with correct fields cleared and return it in the proper format to update either state or form data
const getRangeFieldValuesWithCorrectClearing = ({
  currentRange,
  fieldPlaceholder,
  form = null,
  formFieldName = null,
}: IGetRangeValuesArgs): ValueRange | IformDataObject => {
  let returnRange: ValueRange = [];

  const startDate = currentRange ? currentRange[0] : null;
  const endDate = currentRange ? currentRange[1] : null;

  if (fieldPlaceholder === 'Start date' && endDate != null) {
    returnRange = [null, endDate];
  } else if (fieldPlaceholder === 'End date' && startDate != null) {
    returnRange = [startDate, null];
  } else {
    returnRange = [];
  }

  if (form && formFieldName) {
    const formDataObj: IformDataObject = {};
    formDataObj[formFieldName] = returnRange;
    return formDataObj;
  } else {
    return returnRange;
  }
};

function DatePicker({
  type = 'single',
  onChange,
  dateSetter,
  errorMsgSetter,
  fullWidth,
  className,
  style,
  ...props
}: IDatePickerProps): JSX.Element {
  const { locale } = useLocale();

  const form: FormInstance<any> | null = (props as any).form ? (props as any).form : null;
  const formFieldName: string | null = (props as any).formFieldName ? (props as any).formFieldName : null;

  // const dateSetter: DateSetter | DateRangeSetter | null = (props as any).dateSetter ? (props as any).dateSetter : null;
  // const errorMsgSetter: React.Dispatch<React.SetStateAction<string>> | null = (props as any).errorMsgSetter
  //   ? (props as any).errorMsgSetter
  //   : null;

  const defaultProps: IDefaultProps = {
    onChange: onChange ? (onChange as DateOnChangeCustom | DateOnChangeRange) : null,
    onKeyDown: null,
    dropdownClassName: 'DatePicker_Custom_Dropdown',
  };

  switch (type) {
    // DatePicker
    case 'single': {
      // default onChange handler when outside of a <Form> and passed a state value setter function
      if (!defaultProps.onChange && !form && dateSetter) {
        defaultProps.onChange = (value: ValueSingle) => {
          (dateSetter as DateSetter)(value);
          if (errorMsgSetter) {
            errorMsgSetter('');
          }
        };
      }
      // clear date field onKeyDown inside or outside <Form> after deleting contents and hitting Enter
      defaultProps.onKeyDown = (e: any) => {
        const target = e.target as HTMLInputElement;
        if (e.code === 'Enter' && target.value === '') {
          if (dateSetter) {
            (dateSetter as DateSetter)(null);
          }
          if (form && formFieldName) {
            const formDataObj: IformDataObject = {};
            formDataObj[formFieldName] = null;
            form.setFieldsValue(formDataObj);
          }
        }
      };
      return (
        <div
          className={`DatePicker_Custom ${fullWidth ? 'full-width' : ''} ${className ? className : ''}`}
          style={style}
        >
          <AntdDatePicker
            {...(props as DatePickerProps)}
            {...(defaultProps as Partial<DatePickerProps>)}
            locale={locale === ILocale.fr ? frLocale : enLocale}
          />
        </div>
      );
      break;
    }
    // RangePicker
    case 'range': {
      // default onChange handler when outside of a <Form> and passed a state value setter function
      if (!defaultProps.onChange && !form && dateSetter) {
        defaultProps.onChange = (values: ValueRange) => {
          (dateSetter as DateRangeSetter)(values);
          if (errorMsgSetter) {
            errorMsgSetter('');
          }
        };
      }

      // clear individual date range field onKeyDown inside or outside <Form> after deleting contents and hitting Enter
      defaultProps.onKeyDown = (e: any) => {
        const target = e.target as HTMLInputElement;
        if (e.code === 'Enter' && target.value === '') {
          const placeholder: string = e.target.placeholder;
          if (dateSetter) {
            (dateSetter as DateRangeSetter)((prev) => {
              const rangeValuesWithCorrectClearing = getRangeFieldValuesWithCorrectClearing({
                currentRange: prev,
                fieldPlaceholder: placeholder,
              }) as ValueRange;
              return rangeValuesWithCorrectClearing;
            });
          }
          if (form && formFieldName) {
            const formValue = form.getFieldValue(formFieldName);
            const formRangeValuesObj = getRangeFieldValuesWithCorrectClearing({
              currentRange: formValue,
              fieldPlaceholder: placeholder,
              form,
              formFieldName,
            }) as IformDataObject;
            form.setFieldsValue(formRangeValuesObj);
          }
        }
      };
      return (
        <div
          className={`DatePicker_Custom ${fullWidth ? 'full-width' : ''} ${className ? className : ''}`}
          style={style}
        >
          <AntdRangePicker
            {...(props as TimeRangePickerProps)}
            {...(defaultProps as Partial<TimeRangePickerProps>)}
            locale={locale === ILocale.fr ? frLocale : enLocale}
          />
        </div>
      );
      break;
    }
  }
}

export default DatePicker;
