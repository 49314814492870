import { useCallback } from 'react';
import { Input, Select, Col, Row, Form, Checkbox } from 'antd';
import { useQuery } from 'react-query';
import moment from 'moment';

import DatePicker from '../../../../components/DatePicker/DatePicker';
import PasswordResetConfirmation from '../../../../modals/PasswordResetConfirmation/PasswordResetConfirmation';
import LabelWithExtra from '../components/LabelWithExtra';
import SelectLookupDto from '../components/SelectLookupDto';
import SelectSuffixIcon from '../../../../components/SelectSuffixIcon/SelectSuffixIcon';

import useLocale from '../../../../hooks/useLocale';
import useModal from '../../../../hooks/useModal';

import { USE_QUERY_OPTIONS, LANGUAGES_QUERY } from '../../../../constants/reactQuery';
import { DATE_FORMAT2, DATE_FORMAT_PLACEHOLDER } from '../../../../constants/common';
import genericMessage from '../../../../utils/genericMessage';
import { ClientService } from '../../../../shared/api/ClientService';
import API from '../../../../utils/api';

import '../DebtorProfileContent.scss';

interface IProps {
  fileId?: string;
  disabled?: boolean;
}

const BasicInformation = ({ fileId, disabled = false }: IProps) => {
  const { t } = useLocale();
  const { Option } = Select;
  const { showModal, closeModal } = useModal();

  const { data: languages } = useQuery([LANGUAGES_QUERY], () => API.listLanguages(), USE_QUERY_OPTIONS);

  const requestResetPassword = useCallback(async () => {
    const response = await API.sendClientPortalResetPasswordEmail(fileId);

    if (response?.result === ClientService.Result.Successful) {
      genericMessage.success(t.DEBTOR_PROFILE_EMAIL_SUCCESS);
    } else {
      genericMessage.error({}, t.DEBTOR_PROFILE_EMAIL_FAILURE);
    }

    closeModal();
  }, [closeModal, fileId, t.DEBTOR_PROFILE_EMAIL_FAILURE, t.DEBTOR_PROFILE_EMAIL_SUCCESS]);

  const handleResetPassword = useCallback(
    async (isRegisteredToClientPortal: boolean) => {
      showModal(
        <PasswordResetConfirmation
          onYes={requestResetPassword}
          onNo={closeModal}
          isUserRegistered={isRegisteredToClientPortal}
        />
      );
    },
    [closeModal, requestResetPassword, showModal]
  );

  return (
    <>
      <Row gutter={20}>
        <Col span={8}>
          <Form.Item
            name="firstName"
            label={t.DEBTOR_PROFILE_FIRST_NAME}
            required
            rules={[{ required: true, message: t.REQUIRED_FIELD }]}
          >
            <Input size="large" disabled={disabled} />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item name="middleName" label={t.DEBTOR_PROFILE_MIDDLE_NAME}>
            <Input size="large" disabled={disabled} />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
            name="lastName"
            label={t.DEBTOR_PROFILE_LAST_NAME}
            required
            rules={[{ required: true, message: t.REQUIRED_FIELD }]}
          >
            <Input size="large" disabled={disabled} />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={20}>
        <Col span={8}>
          <Form.Item name="dateOfBirth" label={t.DEBTOR_PROFILE_DATE_OF_BIRTH}>
            <DatePicker
              dateSetter={null}
              format={DATE_FORMAT2}
              placeholder={DATE_FORMAT_PLACEHOLDER}
              disabledDate={(date) => date.isAfter(moment(), 'day')}
              size="large"
              disabled={disabled}
            />
          </Form.Item>
        </Col>

        <Col span={8}>
          <Form.Item
            name="enableClientLogin"
            label={
              <LabelWithExtra
                label={t.DEBTOR_PROFILE_ENABLE_CLIENT_LOGIN}
                required
                extraComponent={
                  <Form.Item noStyle shouldUpdate>
                    {() => {
                      return (
                        <Form.Item name="isRegisteredToClientPortal" valuePropName="checked" noStyle>
                          <Checkbox className="DebtorProfileContent__checkbox" disabled={true}>
                            {t.CLIENT_REGISTERED}
                          </Checkbox>
                        </Form.Item>
                      );
                    }}
                  </Form.Item>
                }
              />
            }
            requiredMark="optional"
            rules={[{ required: true, message: t.REQUIRED_FIELD }]}
          >
            <Select size="large" suffixIcon={<SelectSuffixIcon />} disabled={disabled}>
              <Option value={true}>{t.YES}</Option>
              <Option value={false}>{t.NO}</Option>
            </Select>
          </Form.Item>
          {
            <Form.Item noStyle shouldUpdate>
              {({ getFieldValue }) =>
                getFieldValue('enableClientLogin') &&
                !disabled && (
                  <div
                    onClick={() => {
                      handleResetPassword(getFieldValue('isRegisteredToClientPortal'));
                    }}
                    className="DebtorProfileContent__link-button"
                  >
                    {getFieldValue('isRegisteredToClientPortal') ? t.SEND_RESET_PASSWORD : t.SEND_REGISTRATION_PASSWORD}
                  </div>
                )
              }
            </Form.Item>
          }
        </Col>
        <Col span={8}>
          <Form.Item name="preferredLanguageId" label={t.DEBTOR_PROFILE_PREFERRED_LANGUAGE}>
            <SelectLookupDto data={languages} disabled={disabled} />
          </Form.Item>
        </Col>
      </Row>
    </>
  );
};

export default BasicInformation;
