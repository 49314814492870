import React, { useCallback, useEffect, useState } from 'react';
import { Col, Form, Input, message, Modal, Row, Spin } from 'antd';
import useLocale from '../../../hooks/useLocale';
import Button from '../../../components/Button/Button';
import { ClientService } from '../../../shared/api/ClientService';
import API from '../../../utils/api';
import SelectLookupDto from '../../../components/SelectLookupDto/SelectLookupDto';
import { EMPTY_PHONE_MASK, PHONE_MASK } from '../../NewProspectPage/constants';
import InputMask from 'react-input-mask';
import genericMessage from '../../../utils/genericMessage';
import MaritalStatusEnum = ClientService.MaritalStatusEnum;

interface IDuplicateFileDialog {
  fileId?: string;
  onCancel?: () => void;
  onSave?: (fileId?: string) => void;
}

const DuplicateFileDialog: React.FC<IDuplicateFileDialog> = (props) => {
  const { t } = useLocale();
  const [phoneTypes, setPhoneTypes] = useState<ClientService.PhoneTypeLookupDto[]>();
  const [form] = Form.useForm<ClientService.PersonalDetailsDto>();
  const [loading, setLoading] = useState(false);
  const [processing, setProcessing] = useState(false);

  const requestPhoneTypes = useCallback(async () => {
    const response = await API.listPhoneTypes();

    if (response) {
      setPhoneTypes(response);
      form.setFieldsValue({
        mainPhoneTypeId: response?.find((item) => item.enumValue === ClientService.PhoneTypeEnum.Mobile)?.id,
      });
    }
  }, [form]);

  const appFormPersonalInfo = useCallback(async () => {
    try {
      setLoading(true);

      const appFormPersonalInfoDto = await API.getAppFormPersonalInfo(props.fileId);
      if (appFormPersonalInfoDto && appFormPersonalInfoDto.maritalStatusId) {
        const maritalStatuses = await API.listMaritalStatuses();
        const status = maritalStatuses.find((f) => f.id === appFormPersonalInfoDto.maritalStatusId);
        if (status?.enumValue === MaritalStatusEnum.Married || status?.enumValue === MaritalStatusEnum.CommonLaw) {
          form.setFieldsValue({
            firstName: appFormPersonalInfoDto.spouseFirstName || undefined,
            middleName: appFormPersonalInfoDto.spouseMiddleName || undefined,
            lastName: appFormPersonalInfoDto.spouseLastName || undefined,
            mainPhoneTypeId: appFormPersonalInfoDto.spouseMainPhoneTypeId || undefined,
            mainPhoneNumber: appFormPersonalInfoDto.spouseMainPhoneNumber || undefined,
            emailAddress: appFormPersonalInfoDto.spouseEmailAddress || '',
          });
        }
      }
    } catch (ex) {
      console.log(ex);
    } finally {
      setLoading(false);
    }
  }, [form, props.fileId]);

  const handleSaveProspect = useCallback(async () => {
    try {
      const valid = await form.validateFields();

      setProcessing(true);

      if (valid) {
        setLoading(true);

        const values = form.getFieldsValue();
        const response = API.createDuplicateProspect({
          fileId: props.fileId,
          emailAddress: values.emailAddress,
          firstName: values.firstName,
          lastName: values.lastName,
          mainPhoneNumber: values.mainPhoneNumber,
          mainPhoneTypeId: values.mainPhoneTypeId,
          middleName: values.middleName,
        } as ClientService.CreateDuplicateProspectDto)
          .then((r) => {
            message.success(t.SUCCESSFULLY_SAVED);

            setProcessing(false);

            if (typeof props.onSave === 'function') {
              props.onSave(r?.returnId);
            }
          })
          .catch((e) => {
            genericMessage.error({}, e?.error?.validationErrors?.map((item: any) => item?.message)?.join(' '));
          })
          .finally(() => {
            setProcessing(false);
          });
      }
    } catch (ex: any) {
      if (ex?.errorFields) genericMessage.error({}, ex?.errorFields?.map((item: any) => item?.errors[0])?.join(' '));
      else genericMessage.error(ex);
    } finally {
      setLoading(false);
    }
  }, [form, props, t.NEW_PROSPECT_SIMILAR_RECORDS_MESSAGE, t.SUCCESSFULLY_SAVED]);

  useEffect(() => {
    if (!phoneTypes) requestPhoneTypes().then((r) => console.log(r));
    appFormPersonalInfo().then((r) => console.log(r));
  }, [appFormPersonalInfo, phoneTypes, requestPhoneTypes]);

  return (
    <Modal
      title={t.DUPLICATE_FILE_FORM_DIALOG_TITLE}
      destroyOnClose={true}
      centered={true}
      width={1000}
      visible={true}
      closable={true}
      footer={[
        <Button key="cancel" kind="cancel" padding="large" onClick={props.onCancel} style={{ marginRight: 6 }}>
          {t.CANCEL}
        </Button>,
        <Button key="ok" padding="large" kind="primary" loading={processing} onClick={handleSaveProspect}>
          {t.SAVE}
        </Button>,
      ]}
    >
      <Spin spinning={loading}>
        <Form form={form} layout={'vertical'}>
          <Row gutter={20}>
            <Col span={8}>
              <Form.Item
                name="firstName"
                label={t.NEW_PROSPECT_FIRST_NAME}
                rules={[{ required: true, message: `${t.NEW_PROSPECT_FIRST_NAME} ${t.IS_REQUIRED}!` }]}
              >
                <Input size="large" maxLength={100} />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item name="middleName" label={t.NEW_PROSPECT_MIDDLE_NAME}>
                <Input size="large" maxLength={100} />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                name="lastName"
                label={t.NEW_PROSPECT_LAST_NAME}
                rules={[{ required: true, message: `${t.NEW_PROSPECT_LAST_NAME} ${t.IS_REQUIRED}!` }]}
              >
                <Input size="large" maxLength={100} />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={20} align="middle">
            <Col span={8}>
              <Row gutter={10} wrap={false}>
                <Col span={8}>
                  <Form.Item
                    name="mainPhoneTypeId"
                    label={t.NEW_PROSPECT_MAIN_PHONE}
                    rules={[{ required: true, message: `${t.NEW_PROSPECT_MAIN_PHONE} ${t.IS_REQUIRED}!` }]}
                  >
                    <SelectLookupDto
                      data={
                        phoneTypes?.filter(
                          (item) => item?.enumValue !== ClientService.PhoneTypeEnum.Fax
                        ) as ClientService.LookupDto[]
                      }
                    />
                  </Form.Item>
                </Col>
                <Col span={16}>
                  <Form.Item
                    name="mainPhoneNumber"
                    label=" "
                    rules={[
                      {
                        validator: (_, value) => {
                          if (!value || value === EMPTY_PHONE_MASK) {
                            return Promise.reject(t.PHONE_NUMBER_REQUIRED_ERROR);
                          }
                          if (!/^\(\d{3}\) \d{3}-\d{4}$/.test(value)) {
                            return Promise.reject(t.PHONE_NUMBER_INCORRECT_ERROR);
                          }
                          return Promise.resolve();
                        },
                      },
                    ]}
                  >
                    <InputMask mask={PHONE_MASK}>
                      <Input size="large" />
                    </InputMask>
                  </Form.Item>
                </Col>
              </Row>
            </Col>
            <Col span={8}>
              <Form.Item
                name="emailAddress"
                label={t.NEW_PROSPECT_EMAIL_ADDRESS}
                rules={[{ type: 'email', message: `${t.NEW_PROSPECT_EMAIL_ADDRESS} ${t.IS_REQUIRED}!` }]}
              >
                <Input size="large" maxLength={100} />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Spin>
    </Modal>
  );
};

export default DuplicateFileDialog;
