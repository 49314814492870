import EmailIcon from './EmailIcon';

interface IProps {
  handleShowEmailEditor: () => void;
}

const EmailButton = ({ handleShowEmailEditor }: IProps) => (
  <button className="Button ActionButton" onClick={handleShowEmailEditor} style={{ marginLeft: 10, marginRight: 10 }}>
    <div className="Icon" style={{ color: 'transparent' }}>
      <EmailIcon />
    </div>
  </button>
);

export default EmailButton;
