import moment, { Moment } from 'moment';
import { Row, Col } from 'antd';
import { Icon } from '@fluentui/react/lib/Icon';

import DataItem from '../../../../../components/DataItem/DataItem';

import { DATE_FORMAT2 } from '../../../../../constants/common';
import useLocale from '../../../../../hooks/useLocale';

interface IFormRow {
  label: string;
  date?: Moment;
  isHistoryIcon?: boolean;
  isUploaded?: boolean;
  /*
   * Work for Court Revival section only. When uploaded "Court Revival" file, the "Upload" button's label will be displayed as "Revived"
   * #22182
   */
  isRevived?: boolean;
  onClickUpload?: () => void;
  onClickHistory?: () => void;
}

/**
 * Work for "Admin Info" -> "Proposal" -> rendering rows for "Court Revival", "Notice to Creditors and Report to Official Receiver on Deemed Annulment of Consumer Proposal - Filing", etc
 * @param param0
 * @returns
 */
const FormRow = ({ label, date, isHistoryIcon, isUploaded, isRevived, onClickUpload, onClickHistory }: IFormRow) => {
  const { t } = useLocale();

  return (
    <DataItem
      colSpan={24}
      label={label}
      value={{
        value: (
          <Row gutter={10}>
            <Col>
              <span style={{ cursor: 'pointer', color: date ? 'black' : 'red' }} onClick={onClickUpload}>
                {isRevived
                  ? t.REVIVED
                  : isUploaded
                  ? date
                    ? moment(date).format(DATE_FORMAT2)
                    : t.UPLOADED
                  : t.UPLOAD}
              </span>
            </Col>
            <Col>
              <Icon
                iconName="History"
                style={{
                  cursor: isHistoryIcon ? 'pointer' : 'auto',
                  color: 'red',
                  visibility: isHistoryIcon ? 'visible' : 'hidden',
                }}
                onClick={onClickHistory}
              />
            </Col>
          </Row>
        ),
      }}
      horizontalSplit={[80, 20]}
      valueAlignRight
      className="Proposal__form-item"
    />
  );
};

export default FormRow;
