import React, { isValidElement, ReactNode, useCallback, useMemo, useState } from 'react';
import { Form, Input, InputProps, FormItemProps } from 'antd';
import { TextAreaProps } from 'antd/lib/input';
import { LabeledValue } from 'antd/lib/select';
import SelectInput, { ISelectOption } from './SelectInput';
import Radio, { CustomRadioGroupProps } from '../Radio/Radio';
import Label from '../Label/Label';
import { Rule, RuleObject, FormInstance } from 'rc-field-form/es/interface';
import { isArrayWithValues } from '../../../utils/helpers';
import useLocale from '../../../hooks/useLocale';
import moment from 'moment';
import DatePicker, { PickerTypes, DateSetter, DateRangeSetter } from '../../DatePicker/DatePicker';
// import { DefaultOptionType } from 'antd/lib/select';

import styles from './FormInput.module.scss';

const { TextArea } = Input;

interface IRequiredObject {
  required: boolean;
  message?: string;
}

/*
    This component will be updated to handle additional
    input types as I come across them and they may have
    type-specific props (like Select does), so typing 
    has been set up to handle that scenario
*/
type TTextProps = {
  type?: 'text';
  prefix?: InputProps['prefix'];
  suffix?: InputProps['suffix'];
  value?: string;
  placeholder?: string;
};
type TSelectProps = {
  type: 'select';
  selectMode?: 'multiple' | 'tags';
  optionsList?: ISelectOption[]; //| DefaultOptionType[];
  noSelectOption?: boolean;
  defaultValue?: any;
  value?: string | string[] | number | number[] | LabeledValue | LabeledValue[];
  placeholder?: ReactNode;
  loading?: boolean;
};
type TTextareaProps = {
  type: 'textarea';
  value?: string;
  allowClear?: boolean;
  allowResize?: boolean;
  maxLength?: number;
  height?: number;
  showCount?: TextAreaProps['showCount']; //boolean;
  placeholder?: string;
};
type TCheckboxProps = {
  type: 'checkbox';
  value?: (string | number)[];
};
type TRadioProps = {
  type: 'radio';
  value?: any;
  radioGroupName?: CustomRadioGroupProps['name'];
  buttonStyle?: CustomRadioGroupProps['buttonStyle'];
  radioOptions?: CustomRadioGroupProps['options'];
  radioOptionType?: CustomRadioGroupProps['optionType'];
};
type TDatepickerProps = {
  type: 'datepicker';
  pickerType?: PickerTypes;
  form?: FormInstance<any>;
  dateSetter?: DateSetter | DateRangeSetter | null;
  // formFieldName?: string;
  value?: moment.Moment | null;
  placeholder?: ReactNode;
};

type TInputOnChange = (e: any) => void;
type TSelectOnChange = (value: any, option: any) => void;
type TSelectOnSearch = (value: any) => void;
type TSelectFilterOption = (inputValue: string, option: any) => boolean;

type TInputTypeSpecificProps =
  | TTextProps
  | TSelectProps
  | TTextareaProps
  | TCheckboxProps
  | TRadioProps
  | TDatepickerProps;

interface TProps {
  labelExtraContent?: ReactNode;
  required?: boolean | string;
  validate?: (value: any, formInstance: FormInstance) => string | void; // return a string if the validation should fail; nothing if it should pass
  // onChange?: TInputOnChange | TSelectOnChange;
  onChange?: TInputOnChange | TSelectOnChange | ((...args: unknown[]) => void);
  onSearch?: TSelectOnSearch | ((...args: unknown[]) => void);
  autoClearSearchValue?: boolean;
  filterOption?: TSelectFilterOption | ((...args: unknown[]) => void);
  itemClassName?: string;
  inputClassName?: string;
  inputStyle?: React.CSSProperties;
  size?: 'large' | 'middle' | 'small';
  disabled?: boolean;
  readOnly?: boolean;
  loading?: boolean;
}

type FormItemPropsLimited = Omit<FormItemProps, 'required'>;

type TFormInputProps = TInputTypeSpecificProps & TProps & FormItemPropsLimited;

const isObject = (varToCheck: any): boolean => {
  if (typeof varToCheck === 'object' && !Array.isArray(varToCheck) && varToCheck !== null) {
    return true;
  }
  return false;
};

const DROPDOWN_OPTION_SELECT = 'select';

const FormInput: React.FC<TFormInputProps> = ({
  type = 'text',
  label,
  name,
  labelExtraContent,
  required = false,
  value,
  validate: onValidation,
  onChange,
  onSearch,
  filterOption,
  autoClearSearchValue = true,
  className = '',
  itemClassName,
  inputClassName,
  inputStyle,
  rules,
  size = 'large',
  disabled = false,
  readOnly = false,
  children,
  loading,
  ...props
}): JSX.Element => {
  const inputPrefix: InputProps['prefix'] | undefined = (props as any).prefix;
  const inputSuffix: InputProps['suffix'] | undefined = (props as any).suffix;

  const allowClear: boolean | undefined = (props as any).allowClear || false;
  const allowResize: boolean | undefined = (props as any).allowResize || false;
  const maxLength: number | undefined = (props as any).maxLength;
  const showCount: TextAreaProps['showCount'] | undefined = (props as any).showCount;
  const height: number | undefined = (props as any).height;

  const optionsList: ISelectOption[] | undefined = (props as any).optionsList;
  const noSelectOption: boolean | undefined = (props as any).noSelectOption;
  const defaultValue: any = (props as any).defaultValue;
  const selectMode: 'multiple' | 'tags' | undefined = (props as any).selectMode;

  const radioGroupName: CustomRadioGroupProps['name'] | undefined = (props as any).radioGroupName;
  const buttonStyle: CustomRadioGroupProps['buttonStyle'] | undefined = (props as any).buttonStyle;
  const radioOptions: CustomRadioGroupProps['options'] | undefined = (props as any).radioOptions;
  const radioOptionType: CustomRadioGroupProps['optionType'] | undefined = (props as any).radioOptionType;

  const pickerType: PickerTypes | undefined = (props as any).pickerType;
  const form: FormInstance<any> | undefined =
    (props as any).form != null ? ((props as any).form as FormInstance<any>) : undefined;
  // const formFieldName: string | undefined = (props as any).formFieldName;
  const dateSetter: DateSetter | DateRangeSetter | null | undefined = (props as any).dateSetter;

  const placeholder: ReactNode | string | undefined = (props as any).placeholder;

  const { t, locale } = useLocale();

  const [isRequired, setIsRequired] = useState<boolean>(!!required);
  const [useCustomLabel, setUseCustomLabel] = useState<boolean>(() => {
    return labelExtraContent == null && typeof label === 'string' ? false : true;
  });

  const customLabel = useMemo((): ReactNode => {
    const disabledOrReadOnly = disabled || readOnly;
    const requiredSetting = isRequired && !disabledOrReadOnly;
    if (!isValidElement(label) && (labelExtraContent || typeof label === 'string' || useCustomLabel)) {
      return <Label className="test" value={label} extraContent={labelExtraContent} required={requiredSetting} />;
    } else if (isValidElement(label)) {
      // console.log('LABEL COMPONENT props:', label.props);
      const typeName = (label as any).type?.name;
      if (typeName && (typeName === 'Label' || typeName === 'LabelWithExtraValue')) {
        const requiredStatus =
          (label as any).props?.required != null && !disabledOrReadOnly
            ? (label as any).props?.required
            : requiredSetting;
        return React.cloneElement(label as any, { required: requiredStatus });
      }
      return React.cloneElement(label as any, { required: requiredSetting, 'data-element-type': 'unique' });
    }
    return null;
  }, [label, labelExtraContent, useCustomLabel, isRequired, disabled, readOnly]);

  const renderLabel = useCallback((): ReactNode => {
    if (useCustomLabel) return customLabel;
    return label;
  }, [
    useCustomLabel,
    customLabel,
    // labelExtraContent,
    label,
  ]);

  /* 
        When automatically creating an error message, this is
        used to determine which indefinite article to use 
    */
  const articleAorAn = useMemo((): string => {
    if (label && typeof label === 'string') {
      if (locale === 'en') {
        const firstLetterOfLabel = label.toLowerCase().substring(0, 1);
        const useAn = ['a', 'e', 'i', 'o', 'u'].indexOf(firstLetterOfLabel) >= 0;
        return useAn ? 'an' : 'a';
      } else if (locale === 'fr') {
        return 'un/une';
      }
    }
    return '';
  }, [label, locale]);

  /* 
        Set or construct the message to show if 'required' validation fails.
        If a message is explicitly passed in, it will take precedence.
        We'll construct the message even if 'required' prop isn't set since
        a 'required' validation could still be passed through 'rules'.
    */
  const requiredMessage = useMemo((): string => {
    if (typeof required === 'string') {
      return required;
    }

    if (!label || typeof label !== 'string') {
      const noLabelString = {
        text: t.ENTER_VALUE, //"enter a value",
        textarea: t.ENTER_REQUIRED_TEXT, //"enter the required text",
        select: t.MAKE_A_SELECTION, //"make a selection",
        checkbox: t.MAKE_A_SELECTION, //"make your selection(s)",
        radio: t.MAKE_YOUR_SELECTION, //"make a selection",
        datepicker: t.SELECT_THE_DATE, //"select the date(s)"
      };

      return `${t.PLEASE} ${noLabelString[type]}.`;
    }

    const validationVerb = {
      text: t.ENTER_PARTIAL, //"enter"
      textarea: t.ENTER_PARTIAL, //"enter"
      select: t.SELECT_PARTIAL, //"select"
      checkbox: t.CHOOSE_PARTIAL, //"choose"
      radio: t.CHOOSE_PARTIAL, //"choose"
      datepicker: t.PICK_PARTIAL, //"pick"
    };
    return `${t.PLEASE} ${validationVerb[type]} ${
      type !== 'checkbox' ? articleAorAn : t.ONE_OR_MORE_PARTIAL
    } ${label}.`;
  }, [
    required,
    type,
    articleAorAn,
    label,
    t.PLEASE,
    t.ENTER_PARTIAL,
    t.SELECT_PARTIAL,
    t.CHOOSE_PARTIAL,
    t.PICK_PARTIAL,
    t.ONE_OR_MORE_PARTIAL,
    t.ENTER_VALUE,
    t.ENTER_REQUIRED_TEXT,
    t.MAKE_A_SELECTION,
    t.MAKE_YOUR_SELECTION,
    t.SELECT_THE_DATE,
  ]);

  // Construct the validation rules that will be applied to the input
  const validationRules = useMemo((): Rule[] => {
    /* 
            The requiredRule object will be used to track the 'required' status
            and any message that should be displayed if 'required' validation fails.
            The values will initially be set based on the value of the 'required'
            prop, with either its string message or an auto-calculated one
        */
    let requiredRule: IRequiredObject | RuleObject = {
      required: !!required,
      message: requiredMessage,
    };

    // text fields should fail validation if they only have spaces
    let whitespaceRule: RuleObject | undefined =
      type === 'text'
        ? {
            whitespace: true,
            message: `'${label}' ${t.ONLY_HAS_SPACES_PARTIAL}.`,
          }
        : undefined;

    //  collect the validation rules
    let fieldRules: Rule[] = [];

    //  track if a validation function has been passed through 'rules' prop
    let hasFunctionPassedInRulesProp = false;

    if (isArrayWithValues(rules)) {
      rules?.forEach((rule) => {
        if (isObject(rule)) {
          if ('required' in rule) {
            /* 
                            If a 'required' validation object has been passed through the 'rules'
                            prop it will take precedence and replace the options passed through
                            the 'required' prop on assumption it will be less confusing and closer
                            to the Form.Item behavior
                        */
            requiredRule = rule;
            if (rule.required != null) {
              setIsRequired(rule.required);
            }
          } else if ('whitespace' in rule) {
            whitespaceRule = rule;
          } else {
            fieldRules = [...fieldRules, rule];
          }
        } else if (typeof rule === 'function') {
          hasFunctionPassedInRulesProp = true;
          fieldRules = [...fieldRules, rule];
        }
      });
    }

    /* 
            Any explicitly defined 'required' message should now be finalized.
            Order of precedence for the message will be:
            1) Passed through 'rules'
            2) Passed through 'required'
            3) Auto-generated
        */
    const requiredValidationMessage = requiredRule.message != null ? requiredRule.message : requiredMessage;

    /* 
            Define the basic 'required' validation function to be used for
            each input by its 'type'. Will be added to as needed
        */
    const requiredValidationByType = {
      select: (value: any) => {
        if (value == null || value === DROPDOWN_OPTION_SELECT) {
          return requiredValidationMessage;
        }
        if (selectMode && Array.isArray(value) && value.length === 0) {
          return requiredValidationMessage;
        }
      },
      text: (value: any) => {
        if (value == null || value.trim() === '') {
          return requiredValidationMessage;
        }
      },
      textarea: (value: string) => {
        if (value == null || value.trim() === '') {
          return requiredValidationMessage;
        }
      },
      checkbox: () => null,
      radio: (value: any) => {
        if (value == null) {
          return requiredValidationMessage;
        }
      },
      datepicker: (value: any) => {
        if (
          value == null ||
          (!Array.isArray(value) && !moment.isMoment(value)) ||
          (Array.isArray(value) && value[0] !== null && value[1] === null && !moment.isMoment(value[0])) ||
          (Array.isArray(value) && value[0] !== null && value[1] !== null && !moment.isMoment(value[1]))
        ) {
          return requiredValidationMessage;
        }
      },
    };

    /* 
            If a validator function was passed through 'rules' it will take precedence.
            If none was passed, a validator function will be added to the rules if conditions are met 
        */
    if ((requiredRule.required || onValidation) && !hasFunctionPassedInRulesProp) {
      fieldRules = [
        ...fieldRules,
        (formInstance) => ({
          validator(_, value) {
            if (requiredRule.required) {
              // if the field is 'required' the basic validator for the type will run
              const requiredValidationResult = requiredValidationByType[type](value);
              if (typeof requiredValidationResult === 'string') {
                return Promise.reject(requiredValidationResult);
              }
            }
            if (onValidation != null) {
              const customValidationResult = onValidation(value, formInstance);
              if (typeof customValidationResult === 'string') {
                return Promise.reject(customValidationResult);
              }
            }
            return Promise.resolve();
          },
        }),
      ];
    }

    /*
            Create an array to hold all default rules that should
            be applied to the input.
        */
    let defaultRulesToApply: RuleObject[] = [];

    /*
            The requiredRule object will only be added to the 'rules' if the
            input type does not have a default validator function defined in
            'requiredValidationByType' as including both would generate duplicate
            validation error messages. However, if a 'required' message was
            passed, it will be used in the default validator
        */
    if (requiredValidationByType[type] == null) {
      defaultRulesToApply.push(requiredRule);
    } else {
      /*
                Without a requiredRule, a visual indicator that a field is required
                (if it is) won't be displayed, so we'll use our customLabel to handle
                that secenario
            */
      setUseCustomLabel(true);
    }

    /*
            Use an object to track the sets of rules that should
            be applied by default to specific input types.

            This will be added to as necessary but might prove to be
            overkill.
        */
    const defaultRulesByType = {
      text: [whitespaceRule],
    };

    /*
            If the current 'type' has a corresponding list of default
            rules, loop through and add them to array of default rules
            that will be applied
        */
    if (type in defaultRulesByType) {
      (defaultRulesByType as any)[type].forEach((rule: any) => {
        defaultRulesToApply.push(rule as RuleObject);
      });
    }

    /* 
            Add the default rules to the full
            list of rules that will be applied
        */
    fieldRules = [...fieldRules, ...defaultRulesToApply];

    return fieldRules;
  }, [required, type, label, rules, selectMode, onValidation, requiredMessage, t.ONLY_HAS_SPACES_PARTIAL]);

  /* 
        Define functions to return the actual form inputs based on 'type'
    */
  const inputBuilder = useMemo(() => {
    return {
      text: (): JSX.Element => {
        return (
          <Input
            value={value}
            placeholder={placeholder as string}
            prefix={inputPrefix}
            suffix={inputSuffix}
            disabled={disabled}
            readOnly={readOnly}
            className={inputClassName}
            size={size}
            onChange={onChange ? (onChange as TInputOnChange) : onChange}
            style={inputStyle}
          />
        );
      },
      select: (): JSX.Element => {
        return (
          <SelectInput
            mode={selectMode}
            value={value}
            placeholder={placeholder}
            defaultValue={defaultValue}
            disabled={disabled}
            className={inputClassName}
            size={size}
            noSelectOption={noSelectOption}
            loading={loading}
            options={optionsList?.map((option) => ({
              label: option.label,
              value: option.value,
              title: option?.label,
            }))}
            readOnly={readOnly}
            onChange={onChange ? (onChange as TSelectOnChange) : undefined}
            onSearch={onSearch ? (onSearch as TSelectOnSearch) : undefined}
            autoClearSearchValue={autoClearSearchValue !== undefined ? autoClearSearchValue : true}
            style={inputStyle}
            filterOption={(input, option) => option?.title?.toLowerCase().includes(input.toLowerCase() || '')}
          />
        );
      },
      checkbox: () => null,
      radio: (): JSX.Element => {
        return (
          <Radio.Group
            disabled={disabled || readOnly}
            name={radioGroupName}
            value={value}
            buttonStyle={buttonStyle}
            optionType={radioOptionType}
            options={radioOptions}
            onChange={onChange ? (onChange as TInputOnChange) : undefined}
            className={inputClassName}
            style={inputStyle}
          />
        );
      },
      textarea: (): JSX.Element => {
        let textAreaStyles: React.CSSProperties = {};
        if (height) textAreaStyles.height = `${height}px`;
        if (!allowResize) textAreaStyles.resize = 'none';
        textAreaStyles = {
          ...textAreaStyles,
          ...inputStyle,
        };
        return (
          <TextArea
            value={value}
            placeholder={placeholder as string}
            disabled={disabled}
            readOnly={readOnly}
            allowClear={allowClear}
            maxLength={maxLength}
            showCount={showCount}
            className={inputClassName}
            style={textAreaStyles}
            onChange={onChange ? (onChange as TInputOnChange) : undefined}
          />
        );
      },
      datepicker: (): JSX.Element => {
        if (form == null || name == null) {
          console.error("FormInput with a type of 'datepicker' requires 'form' and 'name' props");
          return <></>;
        }
        console.log('pickerType inside FormInput:', pickerType);
        return (
          <DatePicker
            type={pickerType != null ? (pickerType as PickerTypes) : 'single'}
            form={form as any}
            formFieldName={name as string}
            dateSetter={dateSetter}
            size="large"
            fullWidth
            disabled={disabled || readOnly}
            onChange={onChange ? (onChange as any) : undefined}
          />
        );
      },
    };
  }, [
    value,
    placeholder,
    inputPrefix,
    inputSuffix,
    disabled,
    readOnly,
    inputClassName,
    size,
    onChange,
    inputStyle,
    selectMode,
    defaultValue,
    noSelectOption,
    optionsList,
    onSearch,
    autoClearSearchValue,
    filterOption,
    radioGroupName,
    buttonStyle,
    radioOptionType,
    radioOptions,
    height,
    allowResize,
    allowClear,
    maxLength,
    showCount,
    form,
    name,
    pickerType,
    dateSetter,
  ]);

  return (
    <div className={`${styles.Custom_FormInput} ${labelExtraContent ? 'extra-content-in-label' : ''}  ${className}`}>
      <Form.Item
        className={`${itemClassName} ${readOnly ? 'readonly-field' : ''}`}
        name={name}
        label={renderLabel()}
        rules={!disabled && !readOnly ? validationRules : undefined}
        {...(() => {
          delete (props as any).optionsList;
          delete (props as any).noSelectOption;
          delete (props as any).defaultValue;
          return props;
        })()}
      >
        {/* 
                    If children are passed they will be rendered in place
                    of running an inputBuilder()
                */}
        {children ? children : inputBuilder[type]()}
      </Form.Item>
    </div>
  );
};

export default FormInput;
