import { useCallback, useMemo, useState } from 'react';
import { Modal, Form, Row, Col, Spin } from 'antd';
import moment from 'moment';

import Button from '../../components/Button/Button';
import AttachmentsTab, {
  AttachmentsFormProps,
  IAttachmentTabProps,
  AttachmentTypeEnum,
  argumentifyUpdateDocument,
} from '../../components/AttachmentsTab';

import useLocale from '../../hooks/useLocale';

import { UPLOAD_FILES_EVENT } from '../../constants/eventBus';
import { eventBus } from '../../utils/eventBus';
import genericMessage from '../../utils/genericMessage';
import { ClientService } from '../../shared/api/ClientService';
import API from '../../utils/api';

import './UploadSupportingDocumentsModal.scss';

export interface IUploadSupportingDocumentsModalProps extends IAttachmentTabProps {
  supportingDocument?: ClientService.SupportingDocumentStaffPortalDto;
  onCancel?: () => void;
  onOk?: () => void;
  requestSupportingDocuments?: () => void;
}

const TAXES_FOLDER_CODE = 'taxes';
const PERSONAL_FOLDER_CODE = 'lead';

function IUploadSupportingDocumentsModal({
  supportingDocument,
  onOk,
  requestSupportingDocuments,
  onCancel,
  ...attachmentsTabProps
}: IUploadSupportingDocumentsModalProps): JSX.Element {
  const { t } = useLocale();
  const [form] = Form.useForm<AttachmentsFormProps>();

  const [isUploading, setIsUploading] = useState<boolean>(false);
  const [isUpdating, setIsUpdating] = useState<boolean>(false);

  const handleUpdate = useCallback(async () => {
    const updatedExistingFiles = form
      .getFieldValue('existingFiles')
      ?.filter((item: any, index: number) => form.isFieldTouched(['existingFiles', index, 'additionalInformation']));

    if (updatedExistingFiles?.length) {
      setIsUpdating(true);

      for (const file of updatedExistingFiles) {
        await API.documentsPUT(
          ...argumentifyUpdateDocument({ ...file, fileModifiedDate: moment(), id: file?.id as string })
        ).catch(() => setIsUpdating(false));
      }

      requestSupportingDocuments?.();
      setIsUpdating(false);
    }
  }, [form, requestSupportingDocuments]);

  const handleUpload = useCallback(() => {
    setIsUploading(true);
    eventBus.dispatch(UPLOAD_FILES_EVENT, {
      onUploadSuccess: () => {
        setIsUploading(false);
        onOk?.();
      },
      onUploadError: (errorMessage?: string) => {
        setIsUploading(false);
        genericMessage.error({}, errorMessage);
      },
    });
  }, [onOk]);

  const handleSave = useCallback(async () => {
    await handleUpdate();
    handleUpload();
  }, [handleUpdate, handleUpload]);

  const handleCancel = useCallback(() => {
    if (onCancel) onCancel();
  }, [onCancel]);

  const existingDocumentIds = useMemo(
    () => supportingDocument?.documents?.map((item) => item?.id as string),
    [supportingDocument?.documents]
  );

  const businessNames = useMemo(
    () => (supportingDocument?.businessName ? [supportingDocument?.businessName] : undefined),
    [supportingDocument?.businessName]
  );

  const prefixCodes = useMemo(
    () => supportingDocument?.documentPrefixes?.map((item) => item?.documentPrefixCode),
    [supportingDocument?.documentPrefixes]
  );

  const isSinglePrefix = useMemo(
    () => supportingDocument?.documentPrefixes && supportingDocument?.documentPrefixes?.length === 1,
    [supportingDocument?.documentPrefixes]
  );

  return (
    <Spin spinning={isUploading || isUpdating}>
      <Modal
        title={t.UPLOAD_DOCUMENT}
        destroyOnClose
        centered
        visible
        footer={null}
        closable
        onCancel={handleCancel}
        width={1080}
        className="UploadSupportingDocumentsModal"
      >
        <Form form={form} layout="vertical">
          <Row>
            <Col span={24}>
              <span className="UploadSupportingDocumentsModal__summary">{supportingDocument?.detail}</span>
            </Col>
            <Col span={24}>
              <span className="UploadSupportingDocumentsModal__summary">{supportingDocument?.documentName}</span>
            </Col>
            <Col span={24}>
              <Form.Item>
                <AttachmentsTab
                  form={form}
                  {...attachmentsTabProps}
                  newSupportingFileRowLayoutProps={{
                    ...attachmentsTabProps?.newSupportingFileRowLayoutProps,
                    disabledFoldersInput: true,
                    disabledPrefixInput: isSinglePrefix,
                    preselectedPrefixInput: isSinglePrefix,
                    disabledForSpouseInput: supportingDocument?.isForSpouse,
                    preselectedForSpouseInput: supportingDocument?.isForSpouse,
                    disabledBusinessInput: Boolean(supportingDocument?.businessName),
                    preselectedBusinessInput: Boolean(supportingDocument?.businessName),
                  }}
                  supportingFolderCode={supportingDocument?.documentFolderCode}
                  prefixCodes={prefixCodes}
                  isFlatLayout
                  flatLayoutProps={{
                    ...attachmentsTabProps?.flatLayoutProps,
                    hasExistingFiles: true,
                    isAdditionalInfoEditableForExistingFiles: true,
                  }}
                  columnsProps={{
                    hasBusinessNameColumn: Boolean(supportingDocument?.businessName),
                    hasForSpouseColumn: Boolean(supportingDocument?.isForSpouse),
                  }}
                  existingDocumentIds={existingDocumentIds}
                  businessNames={businessNames}
                  attachmentType={
                    supportingDocument?.documentFolderCode === TAXES_FOLDER_CODE
                      ? AttachmentTypeEnum.AppFormIncomeTaxReturn
                      : supportingDocument?.documentFolderCode === PERSONAL_FOLDER_CODE
                      ? AttachmentTypeEnum.AppFormPersonalInfo
                      : undefined
                  }
                  supportingDocumentId={supportingDocument?.id}
                />
              </Form.Item>
            </Col>
          </Row>

          <Row align="middle" justify="space-between" className="FormFilingModal__footer" wrap={false}>
            <Col>{t.CMBND_FILE_GREATER_THAN_100_ERROR}</Col>
            <Col>
              <Row gutter={12} justify="end" align="bottom" wrap={false}>
                <Col>
                  <Form.Item noStyle>
                    <Button kind="cancel" onClick={onCancel}>
                      {t.CANCEL}
                    </Button>
                  </Form.Item>
                </Col>
                <Col>
                  <Form.Item noStyle shouldUpdate>
                    {({ getFieldValue }) => {
                      const isAnyFileUploaded = getFieldValue('newFiles') && getFieldValue('newFiles')?.length > 0;
                      return (
                        <Button onClick={handleSave} /*disabled={!isAnyFileUploaded || isUploading}*/>{t.SAVE}</Button>
                      );
                    }}
                  </Form.Item>
                </Col>
              </Row>
            </Col>
          </Row>
        </Form>
      </Modal>
    </Spin>
  );
}

export default IUploadSupportingDocumentsModal;
