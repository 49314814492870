import { Table, Row, Col } from 'antd';

import { ClientService } from '../../../../shared/api/ClientService';
import useLocale from '../../../../hooks/useLocale';

import './Panels.scss';

interface IProps {
  dataSource?: ClientService.CreditReportHistoryAlertDto[];
}

const TransAlerts = ({ dataSource }: IProps) => {
  const { t } = useLocale();
  const columns = [
    {
      title: t.CODE,
      dataIndex: 'code',
      key: 'code',
    },
    {
      title: t.TEXT,
      dataIndex: 'text',
      key: 'text',
    },
  ];

  return (
    <Row>
      <Col span={12}>
        <Table columns={columns} dataSource={dataSource} rowKey="id" pagination={false} />
      </Col>
    </Row>
  );
};

export default TransAlerts;
