import { useCallback, useState, useEffect, useContext } from 'react';
import { useParams } from 'react-router-dom';
import { Table, Spin } from 'antd';
import moment from 'moment';

import FormSectionTitle from '../../../../../../../components/FormSectionTitle/FormSectionTitle';
import RowActionMenu from '../RowActionMenu/RowActionMenu';
import ScheduleStateLabel from '../ScheduleStateLabel';

import { BankingContext } from '../../BankingContext';
import { BankingPageViewEnum } from '../../types';
import useLocale from '../../../../../../../hooks/useLocale';

import { DATE_FORMAT2 } from '../../../../../../../constants/common';
import { ClientService } from '../../../../../../../shared/api/ClientService';
import API from '../../../../../../../utils/api';

import './PADScheduleTable.scss';

interface IProps {
  disableAllFields?: boolean;
}

const PADScheduleTable = ({ disableAllFields }: IProps) => {
  const { applicationFileId } = useParams<{ applicationFileId?: string }>();
  const { setPageView } = useContext(BankingContext);
  const { t } = useLocale();

  const [dataSource, setDataSource] = useState<ClientService.PADScheduleDto[]>();
  const [loading, setLoading] = useState<boolean>(false);

  const requestPadScheduleAll = useCallback(async (applicationFileId: string) => {
    setLoading(true);
    const response = await API.padScheduleAll(applicationFileId).catch(() => setLoading(false));
    setLoading(false);

    if (response) setDataSource(response?.sort((a, b) => moment(a?.dueDate).diff(moment(b?.dueDate))));
  }, []);

  useEffect(() => {
    if (applicationFileId) requestPadScheduleAll(applicationFileId);
  }, [applicationFileId, requestPadScheduleAll]);

  const columns = [
    {
      title: t.DUE_DATE,
      dataIndex: 'dueDate',
      key: 'dueDate',
      sorter: (a: ClientService.PADScheduleDto, b: ClientService.PADScheduleDto) =>
        moment(a?.dueDate).diff(moment(b?.dueDate)),
      render: (date: moment.Moment) => <>{moment.utc(date).format(DATE_FORMAT2)}</>,
    },
    {
      title: t.TYPE,
      dataIndex: 'type',
      key: 'type',
    },
    {
      title: t.NUMBER,
      dataIndex: 'no',
      key: 'no',
    },
    {
      title: t.AMOUNT,
      dataIndex: 'amount',
      key: 'amount',
    },
    {
      title: t.DEPOSITED,
      dataIndex: 'deposited',
      key: 'deposited',
    },
    {
      title: t.STATUS,
      dataIndex: 'paymentStatus',
      key: 'paymentStatus',
      render: (paymentStatus: ClientService.PADSchedulePaymentStatusEnum) => (
        <>
          {' '}
          {paymentStatus == ClientService.PADSchedulePaymentStatusEnum.Deposited
            ? t.DEPOSITED
            : paymentStatus == ClientService.PADSchedulePaymentStatusEnum.Pending
            ? t.PENDING
            : t.NOT_DEPOSITED}
        </>
      ),
    },
    {
      title: t.AGR_NUMBER,
      dataIndex: 'agrNo',
      key: 'agrNo',
    },
    {
      title: t.STATE,
      dataIndex: 'state',
      key: 'state',
      render: (state?: ClientService.PADScheduleStateEnum) => <ScheduleStateLabel state={state} />,
    },
    {
      title: t.HOLD_UNTIL,
      dataIndex: 'holdUntil',
      key: 'holdUntil',
      render: (date?: moment.Moment) => <>{!date ? '' : moment(date).format(DATE_FORMAT2)}</>,
    },
    {
      dataIndex: 'action',
      key: 'action',
      render: (_: any, record: ClientService.PADScheduleDto) => (
        <RowActionMenu
          rowItem={record}
          onRowChangeCallback={() => requestPadScheduleAll(applicationFileId as string)}
        />
      ),
    },
  ];

  if (disableAllFields) {
    columns.pop();
  }

  return (
    <div className="PADScheduleTable">
      <FormSectionTitle
        titleText={t.CHEQUEPAD_SCHEDULE}
        icon={{
          iconName: 'History',
          onClick: () => setPageView(BankingPageViewEnum.PadScheduleHistory),
          style: { color: 'red', cursor: 'pointer' },
        }}
      />

      <Spin spinning={loading}>
        <Table dataSource={dataSource} columns={columns} />
      </Spin>
    </div>
  );
};

export default PADScheduleTable;
