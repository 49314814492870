import {
  IDocumentSearchCriteria,
  IDocumentVerificationsSearchCriteria,
  IVersionSearchCriteria,
  IDebtorSearchCriteria,
  ICopyFile,
  ICopyOptionSource,
  IVerifyFile,
} from './types';
import { CreateDocument, UpdateDocument } from '../../../components/AttachmentsTab/types';
import { ClientService } from '../../../shared/api/ClientService';
import { getEmptyGuid } from '../../../utils/helpers';

import styles from '../../../styles/style.module.scss';

export const getEmptyGuidIfIsNull = (guidBasedField: string | undefined) => {
  if (guidBasedField === null || guidBasedField === undefined) return getEmptyGuid();
  else return guidBasedField;
};

export const argumentifyDocumentsSearchCriteria = ({
  filterText,
  filterTextlang,
  fileId,
  documentId,
  documentIds,
  documentFolderId,
  documentVerificationStatusId,
  documentVerificationStatuses,
  documentTypeId,
  documentTypes,
  documentPrefixes,
  documentPrefixCodes,
  documentFolders,
  isVisible,
  isDeleted,
  modifiedDateMin,
  modifiedDateMax,
  sorting,
  skipCount,
  maxResultCount,
}: IDocumentSearchCriteria) =>
  [
    filterText,
    filterTextlang,
    fileId,
    documentId,
    documentIds,
    documentFolderId,
    documentVerificationStatusId,
    documentVerificationStatuses,
    documentTypeId,
    documentTypes,
    documentPrefixes,
    documentPrefixCodes,
    documentFolders,
    isVisible,
    isDeleted,
    modifiedDateMin,
    modifiedDateMax,
    sorting,
    skipCount,
    maxResultCount,
  ] as const;

export const readableBytes = (bytes?: number) => {
  if (!bytes) return '0 KB';
  const i = Math.floor(Math.log(bytes) / Math.log(1024));
  const sizes = ['B', 'KB', 'MB', 'GB'];

  return (bytes / Math.pow(1024, i)).toFixed(2) + ' ' + sizes[i];
};

export const argumentifyDocumentVerificationsSearchCriteria = ({
  filterText,
  fileId,
  documentId,
  documentFolders,
  assignedToCurrentUser,
  assignedToAgentId,
  includeOnlyPending,
  documentTypes,
  verificationStatuses,
  isUrgent,
  sorting,
  skipCount,
  maxResultCount,
}: IDocumentVerificationsSearchCriteria) =>
  [
    filterText,
    fileId,
    documentId,
    documentFolders,
    assignedToCurrentUser,
    assignedToAgentId,
    includeOnlyPending,
    documentTypes,
    verificationStatuses,
    isUrgent,
    sorting,
    skipCount,
    maxResultCount,
  ] as const;

export const argumentifyCreateDocument = ({
  fileId,
  documentFolderId,
  documentPrefixId,
  additionalInformation,
  isVisible,
  fileModifiedDate,
  content,
  isForSpouse,
  businessName,
}: CreateDocument) =>
  [
    fileId,
    documentFolderId,
    documentPrefixId,
    additionalInformation,
    isVisible,
    fileModifiedDate,
    content,
    isForSpouse,
    businessName,
  ] as const;

export const argumentifyDocumentVersionsParams = ({
  documentId,
  modifiedDateMin,
  modifiedDateMax,
  sorting,
  skipCount,
  maxResultCount,
}: IVersionSearchCriteria) =>
  [documentId, modifiedDateMin, modifiedDateMax, sorting, skipCount, maxResultCount] as const;

export const argumentifyUpdateDocument = ({
  id,
  documentFolderId,
  documentPrefixId,
  additionalInformation,
  fileModifiedDate,
  content,
  isVisible,
}: UpdateDocument) =>
  [
    id,
    documentFolderId,
    getEmptyGuidIfIsNull(documentPrefixId),
    additionalInformation,
    fileModifiedDate,
    content,
    isVisible,
  ] as const;

export const argumentifyDebtorSearchParams = ({
  searchString,
  fullName,
  hasActiveFiles,
  pageSize,
  pageIndex,
  clientStatus,
  fileStage,
  filingType,
  trustee,
  serviceLocation,
  estateAdmin,
  isEmailChecked,
  isEstateNumberChecked,
  sortingFieldName,
  isAscending,
  includeFileIds,
  fileStageId,
  filingTypeId,
  debtorId,
  trusteeId,
  officeId,
  estateAdminId,
}: IDebtorSearchCriteria) =>
  [
    searchString,
    fullName,
    hasActiveFiles,
    pageSize,
    pageIndex,
    clientStatus,
    fileStage,
    filingType,
    trustee,
    serviceLocation,
    estateAdmin,
    isEmailChecked,
    isEstateNumberChecked,
    sortingFieldName,
    isAscending,
    includeFileIds,
    fileStageId,
    filingTypeId,
    debtorId,
    trusteeId,
    officeId,
    estateAdminId,
  ] as const;

export const downloadUtil = (blob: Blob, documentName: string) => {
  const url = URL.createObjectURL(blob);
  const link = window.document.createElement('a');
  link.href = url;
  link.setAttribute('download', documentName);
  link.click();
  URL.revokeObjectURL(url);
};

export const readableOptionName = (source?: ICopyOptionSource) => {
  const debtorName = source?.firstName || source?.lastName ? `${source?.firstName} ${source?.lastName}` : '-';
  const fileName = source?.fileName || '-';
  const estateNumber = source?.estateNumber || '-';
  return `Debtor: ${debtorName}, FileName: ${fileName}, Estate #: ${estateNumber}`;
};

export const argumentifyCopyDocument = ({ id, body }: ICopyFile) => [id, body] as const;

export const argumentifyVerifyDocument = ({ id, body }: IVerifyFile) => [id, body] as const;

export const statusNameToReadable = (status?: ClientService.DocumentVerificationStatusEnum) => {
  switch (status) {
    case ClientService.DocumentVerificationStatusEnum.Pending:
      return 'Pending';
    case ClientService.DocumentVerificationStatusEnum.PartiallyCompleted:
      return 'PartiallyCompleted';
    case ClientService.DocumentVerificationStatusEnum.Completed:
      return 'Completed';
    case ClientService.DocumentVerificationStatusEnum.Rejected:
      return 'Rejected';
    default:
      return '';
  }
};

export const getVerificationRole = (
  documentVerification?: ClientService.DocumentVerificationDetailDto,
  userId?: string
) =>
  documentVerification?.reviewers?.find((item) => item?.agentId === userId) ||
  documentVerification?.approvers?.find((item) => item?.agentId === userId) ||
  documentVerification?.signees?.find((item) => item?.agentId === userId);

export const verifierButtonDetails = (verificationRole?: ClientService.DocumentVerificationRoleTypeEnum) => {
  switch (verificationRole) {
    case ClientService.DocumentVerificationRoleTypeEnum.Reviewer:
      return {
        title: 'Review',
        kind: 'special',
      };
    case ClientService.DocumentVerificationRoleTypeEnum.Approver:
      return {
        title: 'Approve',
        kind: 'success',
      };
    case ClientService.DocumentVerificationRoleTypeEnum.Signee:
      return {
        title: 'Sign',
        kind: 'cancel',
      };
    default:
      return null;
  }
};

export const getVerificationsStatusColor = (status?: ClientService.DocumentVerificationStatusEnum) => {
  switch (status) {
    case ClientService.DocumentVerificationStatusEnum.Pending:
      return styles.colorGray;
    case ClientService.DocumentVerificationStatusEnum.Completed:
      return styles.colorGreen;
    case ClientService.DocumentVerificationStatusEnum.Rejected:
      return styles.colorDanger;
    default:
      return styles.colorPrimary;
  }
};

export const getVerificationStatusIconDetails = (status?: ClientService.DocumentVerificationRoleResultEnum) => {
  switch (status) {
    case ClientService.DocumentVerificationRoleResultEnum.Pending:
      return { iconName: 'Blocked2Solid', color: styles.colorSecondary };
    case ClientService.DocumentVerificationRoleResultEnum.Accept:
      return { iconName: 'CompletedSolid', color: styles.colorGreen };
    case ClientService.DocumentVerificationRoleResultEnum.Reject:
      return { iconName: 'StatusErrorFull', color: styles.colorDanger };
    default:
      return { iconName: 'Blocked2Solid', color: styles.colorSecondary };
  }
};

export const sanitizeDocumentName = (name: string): string => {
  let parts = name.split('.');
  let newFilename = parts.length > 1 ? parts.slice(0, -1).join('_') + '.' + parts.slice(-1) : name;

  return newFilename;
};
