import { useCallback, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Form, Col, Row, Spin } from 'antd';

import AssetsDebts from '../../../ApplicationOverviewPage/ApplicationOverviewContent/ApplicationForm/AssetsDebts';
import Divider from '../../../../components/Divider/Divider';

import { currencyFormatter } from '../utils';
import API from '../../../../utils/api';
import eventBus from '../../../../utils/eventBus';
import { ClientService } from '../../../../shared/api/ClientService';
import useLocale from '../../../../hooks/useLocale';

import '../FileAnalysis.scss';
import styles from '../../../../styles/style.module.scss';

function DebtsCreditorsDetails({
  disabled = false,
  applicationFile,
}: {
  disabled?: boolean;
  applicationFile?: ClientService.IApplicationFileDto;
}): JSX.Element {
  const { applicationFileId } = useParams<{ applicationFileId: string }>();
  const { t } = useLocale();

  const [debtsCreditors, setDebtsCreditors] = useState<ClientService.FileAnalysisDebtsCreditorsDto>();
  const [debtsCreditorsLoading, setDebtsCreditorsLoading] = useState<boolean>();

  const requestFileAnalysisDebtsCreditors = useCallback(async () => {
    setDebtsCreditorsLoading(true);
    const response = await API.getFileAnalysisDebtsCreditors(applicationFileId).catch(() =>
      setDebtsCreditorsLoading(false)
    );
    if (response) {
      setDebtsCreditors(response);
      eventBus.dispatch('dataEntryFormValuesChanged');
    }

    setDebtsCreditorsLoading(false);
  }, [applicationFileId]);

  return (
    <Spin spinning={debtsCreditorsLoading}>
      <Form layout="vertical" className="FileAnalysis__form">
        <Row className="FileAnalysis__form--debts-creditors-list" gutter={20}>
          <Col span={12}>
            <Form.Item label={t.SECURED_CREDITORS}>
              <div
                className="FileAnalysis__form--total-debts"
                style={{
                  color: disabled ? styles.colorSecondary : styles.colorDanger,
                }}
              >
                ${currencyFormatter(debtsCreditors?.totalSecuredClaimsValue, 0)}
              </div>
            </Form.Item>
            <Divider className="FileAnalysis__form--total-divider" />
            <Form.Item label={t.ASCRIBED_VALUE_OF_SECURED_CLAIMS}>
              ${currencyFormatter(debtsCreditors?.ascribedSecuredClaimsValue, 0)}
            </Form.Item>
            <Form.Item label={t.UNSECURED_PORTION}>
              ${currencyFormatter(debtsCreditors?.unsecuredPortionSecuredClaimsValue, 0)}
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label={t.UNSECURED_CREDITORS}>
              <div
                className="FileAnalysis__form--total-debts"
                style={{
                  color: disabled ? styles.colorSecondary : styles.colorDanger,
                }}
              >
                ${currencyFormatter(debtsCreditors?.totalUnsecuredClaimsValue, 0)}
              </div>
            </Form.Item>
            <Divider className="FileAnalysis__form--total-divider" />
            <Form.Item label={t.CREDITOR_NOT_PARTICIPATING_IN_PROPOSAL}>
              ${currencyFormatter(debtsCreditors?.creditorsNotParticipatingProposalValue, 0)}
            </Form.Item>
            <Form.Item label={t.UNSECURED_PORTION_OF_SECURED_CLAIMS}>
              ${currencyFormatter(debtsCreditors?.unsecuredPortionSecuredClaimsValue, 0)}
            </Form.Item>
            <Form.Item label={t.CONTINGENT_DEFERRED_LIABILITIES}>
              ${currencyFormatter(debtsCreditors?.contigentDeferredLiabilitiesValue, 0)}
            </Form.Item>
            <Form.Item label={t.PREFERRED_CREDITORS}>
              ${currencyFormatter(debtsCreditors?.preferredCreditorsValue, 0)}
            </Form.Item>
            <Form.Item label={t.ALL_OTHER_UNSECURED_CREDITORS}>
              ${currencyFormatter(debtsCreditors?.allUnsecuredCreditorsValue, 0)}
            </Form.Item>
            <Form.Item label={t.ADJUSTMENT_TO_UNSECURED_CLAIMS}>
              ${currencyFormatter(debtsCreditors?.adjustmentToUnsecuredClaimsValue, 0)}
            </Form.Item>
          </Col>
        </Row>
        <AssetsDebts debtsOnly onUpdateDebts={requestFileAnalysisDebtsCreditors} applicationFile={applicationFile} />
      </Form>
    </Spin>
  );
}

export default DebtsCreditorsDetails;
