import { ReactNode, useEffect, useState } from 'react';
import { Modal } from 'antd';
import Button from '../../../../../components/Button/Button';
import ButtonContainer from '../../../../../components/ButtonContainer/ButtonContainer';
import AppForm from '../../../../../components/Forms/AppForm/AppForm';
import useModal from '../../../../../hooks/useModal';
import useLocale from '../../../../../hooks/useLocale';

interface IGenericObject {
  [key: string]: any;
}

interface AscendConfirmActionModalProps {
  title?: string;
  message?: ReactNode;
  onSave?: (modalFormValues: IGenericObject) => void;
  onCancel?: () => void;
  children?: ReactNode;
}

const AscendConfirmActionModal = ({ title, message, onSave, onCancel, children }: AscendConfirmActionModalProps) => {
  const { closeModal } = useModal();
  const { t } = useLocale();

  const [confirmActiveForm] = AppForm.AntD.useForm();

  const [initialValuesSet, setInitialValuesSet] = useState<boolean>(false);

  useEffect(() => {
    if (initialValuesSet) return;

    const formValues = confirmActiveForm.getFieldsValue();
    const formKeys = Object.keys(formValues);

    if (formKeys.length === 0) return;

    // Initially Set all toggles to active
    const initialFieldValues: IGenericObject = {};
    formKeys.forEach((key) => {
      initialFieldValues[key] = true;
    });
    confirmActiveForm.setFieldsValue(initialFieldValues);
    setInitialValuesSet(true);
  }, [initialValuesSet, confirmActiveForm]);

  return (
    <Modal
      destroyOnClose
      centered
      visible
      title={title}
      width={600}
      footer={[
        <ButtonContainer
          key="modal-button-container"
          style={{
            paddingTop: '0',
          }}
        >
          <Button
            key="cancel-button"
            kind="cancel"
            onClick={() => {
              closeModal();
              if (onCancel) onCancel();
            }}
          >
            {t.CANCEL}
          </Button>
          <Button
            key="save-button"
            onClick={() => {
              closeModal();
              if (onSave) onSave(confirmActiveForm.getFieldsValue());
            }}
          >
            {t.SAVE}
          </Button>
        </ButtonContainer>,
      ]}
      closable
      // onCancel={onCancel}
      maskClosable={false}
    >
      <AppForm form={confirmActiveForm}>
        <p>{message}</p>
        {children}
      </AppForm>
    </Modal>
  );
};

export default AscendConfirmActionModal;
