import { useState, useEffect, useMemo } from 'react';
import moment from 'moment';
import ExpandableRecordsDisplay, {
  IRecordField,
  ITableColumn,
} from '../components/ExpandableRecordsDisplay/ExpandableRecordsDisplay';
import ProcessStatusSelection from '../components/ProcessStatusSelection/ProcessStatusSelection';
import { ClientService } from '../../../../../shared/api/ClientService';
import API from '../../../../../utils/api';
// import { getSubmissionStateValueAsElement } from '../../../helpers';
import useLocale from '../../../../../hooks/useLocale';
import CurrencyDisplay from '../../../../../components/CurrencyDisplay/CurrencyDisplay';
import { ROUTES } from '../../../../../constants/routes';
import { DATE_AND_TIME_FORMAT, DATE_FORMAT4 } from '../../../../../constants/common';
import { convertServerDateOnlyToInputDate, getUndefinedOrSelf, splitOnCapital } from '../../../../../utils/helpers';
import { eventBus } from '../../../../../utils/eventBus';
import { ADMIN_INFO_CHANGE_EVENT } from '../../../../../constants/eventBus';

interface IProps {
  data: ClientService.IApplicationFileDto | null;
  filingTypesList: ClientService.ILookupDto[] | null;
  handleUpdateData: () => void;
  disableAllFields?: boolean;
}

const IncomeAndExpense = ({
  data,
  filingTypesList,
  handleUpdateData,
  disableAllFields = false,
}: IProps): JSX.Element => {
  const { t } = useLocale();

  const [processStatus, setProcessStatus] = useState<ClientService.AdminInfoIncomeExpenseDto>();
  const [allItemsCompleted, setAllItemsCompleted] = useState<boolean>(false);

  const incomeRecordFields: IRecordField[] = useMemo(
    () => [
      { label: t.ADMIN_INFO_IANDE_SCHEDULEFIELDS_PERIOD, dataIndex: 'period' },
      { label: t.ADMIN_INFO_IANDE_SCHEDULEFIELDS_DUE, dataIndex: 'due' },
      {
        label: t.ADMIN_INFO_IANDE_SCHEDULEFIELDS_ESTIMATED_SURPLUS,
        dataIndex: 'estSurplus',
        format: 'currency-no-dollar-sign',
      },
      { label: t.ADMIN_INFO_IANDE_SCHEDULEFIELDS_STATUS, dataIndex: 'status' },
    ],
    [
      t.ADMIN_INFO_IANDE_SCHEDULEFIELDS_DUE,
      t.ADMIN_INFO_IANDE_SCHEDULEFIELDS_ESTIMATED_SURPLUS,
      t.ADMIN_INFO_IANDE_SCHEDULEFIELDS_PERIOD,
      t.ADMIN_INFO_IANDE_SCHEDULEFIELDS_STATUS,
    ]
  );

  const incomeAndExpenseSubmissionsTableCols: ITableColumn[] = useMemo(
    () => [
      { title: t.ADMIN_INFO_DETAILS_TABLE_COL_RECEIVED, dataIndex: 'received' },
      { title: t.ADMIN_INFO_DETAILS_TABLE_COL_ASSIGNED_TO, dataIndex: 'assignedTo' },
      { title: t.ADMIN_INFO_DETAILS_TABLE_COL_RESULT, dataIndex: 'optionStatus' },
      { title: t.ADMIN_INFO_DETAILS_TABLE_COL_DISCREPANCY, dataIndex: 'discrepancy' },
      { title: t.ADMIN_INFO_DETAILS_TABLE_COL_STATE, dataIndex: 'state' },
      { title: t.ADMIN_INFO_DETAILS_TABLE_COL_ACTUAL_SURPLUS, dataIndex: 'actualSurplus' },
    ],
    [
      t.ADMIN_INFO_DETAILS_TABLE_COL_ACTUAL_SURPLUS,
      t.ADMIN_INFO_DETAILS_TABLE_COL_ASSIGNED_TO,
      t.ADMIN_INFO_DETAILS_TABLE_COL_DISCREPANCY,
      t.ADMIN_INFO_DETAILS_TABLE_COL_RECEIVED,
      t.ADMIN_INFO_DETAILS_TABLE_COL_RESULT,
      t.ADMIN_INFO_DETAILS_TABLE_COL_STATE,
    ]
  );

  const fileId = useMemo((): string => {
    if (data?.id) {
      return data.id;
    }
    return '';
  }, [data?.id]);

  useEffect(() => {
    if (fileId) {
      API.incomeExpenseGET(fileId).then((response) => {
        setProcessStatus(response);
      });
    }
  }, [fileId]);

  const expandableRecordsDisplay = useMemo(() => {
    console.log(fileId, incomeAndExpenseSubmissionsTableCols, incomeRecordFields, t.NO, t.YES);
    return (
      <ExpandableRecordsDisplay
        recordFields={incomeRecordFields}
        apiGetMasterRecords={() => {
          return API.schedulesAll(fileId);
        }}
        apiSetMasterRecords={(response, setMasterRecords) => {
          const masterRecords = response.map((income: ClientService.IncomeExpenseSubmissionPeriodDto) => {
            return {
              id: income.externalIeSubmissionPeriodId,
              leadValue: undefined,
              period:
                income.period != null
                  ? moment()
                      .month(income.period - 1)
                      .format('MMM')
                  : undefined,
              due:
                income.dueDate != null
                  ? convertServerDateOnlyToInputDate(income.dueDate)?.format(DATE_FORMAT4)
                  : undefined,
              estSurplus: income.estimatedSurplus,
              status:
                income.status != null
                  ? splitOnCapital(ClientService.IncomeExpensePeriodStatusEnum[income.status])
                  : undefined,
            };
          });

          //check if all the income and expense records are completed
          const isAllCompleted = response.every((income: ClientService.IncomeExpenseSubmissionPeriodDto) => {
            return income.status === ClientService.IncomeExpensePeriodStatusEnum.Completed;
          });

          setAllItemsCompleted(isAllCompleted);
          setMasterRecords(masterRecords);
        }}
        tableColumns={incomeAndExpenseSubmissionsTableCols}
        apiGetTableData={(scheduleId) => {
          // console.log('I&E GetTableData scheduleId:', scheduleId);
          return API.submissionsAll(fileId, scheduleId as string);
        }}
        apiSetTableData={(response, setTableData) => {
          // console.log('I&E GetTableData response:', response);
          const iAndESubmissions = response.map((submission: ClientService.IncomeExpenseSubmissionDto) => {
            return {
              id: submission.id,
              received:
                submission.receivedDate != null
                  ? convertServerDateOnlyToInputDate(submission?.receivedDate)?.format(DATE_AND_TIME_FORMAT)
                  : undefined,
              assignedTo: submission.assignedAgentName,
              optionStatus:
                submission.status != null
                  ? splitOnCapital(ClientService.IncomeExpenseSubmissionStatusEnum[submission.status])
                  : undefined,
              discrepancy: submission.hasDiscrepancy ? t.YES : t.NO,
              state:
                submission.state != null
                  ? splitOnCapital(ClientService.IncomeExpenseSubmissionStateEnum[submission.state])
                  : undefined,
              actualSurplus:
                submission.calculatedSurplusPayment != null ? (
                  <CurrencyDisplay amount={submission.calculatedSurplusPayment} noDollarSign />
                ) : undefined,
            };
          });
          setTableData(iAndESubmissions);
        }}
        viewTableRecordRoute={`${ROUTES.APPLICATION_OVERVIEW}/${fileId}/${ROUTES.INCOME_AND_EXPENSE}`}
      />
    );
  }, [fileId, incomeAndExpenseSubmissionsTableCols, incomeRecordFields, t.NO, t.YES]);

  return (
    <div>
      <ProcessStatusSelection
        label={t.ADMIN_INFO_INCOME_EXPENSE_STATUS}
        changedFormProtection
        disableAllFields={disableAllFields}
        status={processStatus?.incomeExpenseProcessStatus}
        isMigrated={getUndefinedOrSelf(data?.isMigrated)}
        skipAllCompletedIfMigrated={
          data?.isMigrated && data?.migratedFileStage == ClientService.FileStageEnum.FileInProcess
        }
        allItemsCompleted={allItemsCompleted}
        apiSubmit={(values) => {
          return API.incomeExpensePUT(fileId, {
            status: values.processStatus,
          } as ClientService.AdminInfoIncomeExpenseUpdateDto);
        }}
        onSubmitResponseSuccess={() => eventBus.dispatch(ADMIN_INFO_CHANGE_EVENT)}
      />
      {expandableRecordsDisplay}
    </div>
  );
};

export default IncomeAndExpense;
