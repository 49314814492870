import { useNavigate } from 'react-router-dom';
import Button, { CustomButtonProps } from '../Button';
import useLocale from '../../../hooks/useLocale';

const DeleteButton = ({ padding = 'large', onClick, className, ...props }: CustomButtonProps): JSX.Element => {
  const { t } = useLocale();
  const navigate = useNavigate();

  return (
    <Button
      // className={className}
      kind="primary"
      padding={padding}
      onClick={(e) => {
        if (onClick) {
          onClick(e);
        } else {
          navigate(-1);
        }
      }}
      {...props}
    >
      {t.BUTTON_REDUNDANT}
    </Button>
  );
};

export default DeleteButton;
