import { useState, useEffect, useCallback } from 'react';
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from 'react-places-autocomplete';
import { Loader, LoaderOptions } from '@googlemaps/js-api-loader';
import { Col, Row } from 'antd';
import { Icon } from '@fluentui/react/lib/Icon';

import GoogleMaps from '../../modals/GoogleMaps/GoogleMaps';

import useLocale from '../../hooks/useLocale';
import useModal from '../../hooks/useModal';

import { LocationDto } from '../../modals/GoogleMaps/types';
import { ClientService } from '../../shared/api/ClientService';
import API from '../../utils/api';
import { STREET_NUMBER, ROUTE, LOCALITY, POSTAL_CODE, ADMINISRATIVE_LEVEL_1, COUNTRY } from './constants';
import { WEEKLY_VERSION, PLACES_LIBRARY, GEOMETRY_LIBRARY } from '../../constants/common';

import './SearchLocationInput.scss';

interface IFormControlledProps {
  value?: string;
  onChange?: (value: string) => void;
}

interface IProps extends IFormControlledProps {
  address?: LocationDto;
  onSelect: (location: LocationDto) => void;
  provinces?: ClientService.LookupDto[];
  countries?: ClientService.LookupDto[];
  locationIcon?: boolean;
  markers?: LocationDto[];
  size?: 'large' | 'default' | 'small';
  disabled?: boolean;
}

interface GeocoderAddressComponent {
  long_name?: string;
  short_name?: string;
  types?: string[];
}

function SearchLocationInput({
  address,
  onSelect,
  provinces,
  countries,
  locationIcon = false,
  markers,
  value,
  onChange,
  size = 'default',
  disabled = false,
}: IProps) {
  const { showModal, closeModal } = useModal();
  const { t } = useLocale();

  const [controlledValue, setControlledValue] = useState<string>('');
  const [focused, setFocused] = useState<boolean>(false);
  const [location, setLocation] = useState<LocationDto>();

  const [isApiLoaded, setIsApiLoaded] = useState<boolean>(false);
  const [loaderOptions, setLoaderOptions] = useState<LoaderOptions>({
    version: WEEKLY_VERSION,
    libraries: [PLACES_LIBRARY, GEOMETRY_LIBRARY],
    apiKey: '',
  });

  const handleChange = useCallback(
    (controlledValue) => {
      setControlledValue(controlledValue);
      if (onChange) onChange(controlledValue);
    },
    [onChange]
  );

  useEffect(() => {
    setControlledValue(value || '');
  }, [value]);

  const getLongName = useCallback(
    (addressComponents: GeocoderAddressComponent[], prop: string) =>
      addressComponents.find((item) => item.types?.[0] === prop)?.long_name,
    []
  );

  const getShortName = useCallback(
    (addressComponents: GeocoderAddressComponent[], prop: string) =>
      addressComponents.find((item) => item.types?.[0] === prop)?.short_name,
    []
  );

  const getLocation = useCallback(
    (addressComponents: GeocoderAddressComponent[], formatted_address: string): LocationDto => {
      const streetNumber = getLongName(addressComponents, STREET_NUMBER);
      const streetName = getLongName(addressComponents, ROUTE);
      const city = getLongName(addressComponents, LOCALITY);
      const postalCode = getLongName(addressComponents, POSTAL_CODE);

      const provinceCode = getShortName(addressComponents, ADMINISRATIVE_LEVEL_1);
      const provinceName = getLongName(addressComponents, ADMINISRATIVE_LEVEL_1);
      const provinceId = provinces?.find((item) => item.code === provinceCode)?.id;

      const countryCode = getShortName(addressComponents, COUNTRY);
      const countryName = getLongName(addressComponents, COUNTRY);
      const countryId = countries?.find((item) => item.code === countryCode)?.id;

      return {
        streetNumber,
        streetName,
        city,
        postalCode,
        province: {
          id: provinceId,
          code: provinceCode,
          name: provinceName,
        },
        country: {
          id: countryId,
          code: countryCode,
          name: countryName,
        },
        address: formatted_address,
      };
    },
    [countries, getLongName, getShortName, provinces]
  );

  const handleSelect = useCallback(
    async (controlledValue) => {
      const result = await geocodeByAddress(controlledValue);

      if (result && result[0]) {
        const geocode = result[0];
        const address = getLocation(geocode.address_components, geocode.formatted_address);
        const coordinates = await getLatLng(geocode);

        const selectedLocation = {
          ...location,
          ...address,
          longitude: coordinates?.lng,
          latitude: coordinates?.lat,
        };

        setLocation(selectedLocation);
        if (onSelect) onSelect(selectedLocation);
      }
    },
    [getLocation, onSelect, location]
  );

  const requestApiKey = useCallback(async () => {
    const result = await API.getGoogleApiKey();
    const apiKey = result.apiKey;

    if (apiKey) {
      const updatedLoaderOptions = { ...loaderOptions, apiKey };
      const loader = new Loader(updatedLoaderOptions);
      const isLoaderLoaded = await loader?.load();

      if (isLoaderLoaded) {
        setLoaderOptions(updatedLoaderOptions);
        setIsApiLoaded(true);
      }
    }
  }, [loaderOptions]);

  const handleShowLocations = useCallback(
    () =>
      showModal(
        <GoogleMaps
          target={location}
          data={markers}
          center={
            location?.latitude && location?.longitude
              ? { lat: location?.latitude, lng: location?.longitude }
              : undefined
          }
          isOk={t.CLOSE}
          onOk={closeModal}
          onCancel={closeModal}
        />
      ),
    [showModal, location, markers, t.CLOSE, closeModal]
  );

  useEffect(() => {
    if (!loaderOptions?.apiKey) {
      requestApiKey();
    }
  }, [loaderOptions?.apiKey, requestApiKey]);

  useEffect(() => {
    setControlledValue(value || '');
  }, [value]);

  return (
    <Row className="SearchLocationInput">
      {isApiLoaded && (
        <PlacesAutocomplete
          value={controlledValue}
          onChange={handleChange}
          onSelect={handleSelect}
          searchOptions={{
            componentRestrictions: {
              country: countries?.find((item) => item?.id === address?.country?.id)?.code?.toLowerCase() || null,
            },
            types: ['address'],
          }}
        >
          {({ getInputProps, suggestions, getSuggestionItemProps }) => (
            <>
              <Col span={24} className={`SearchLocationInput__input SearchLocationInput__input--${size}`}>
                <input
                  {...getInputProps()}
                  onFocus={() => setFocused(true)}
                  onBlur={() => setFocused(false)}
                  disabled={disabled}
                />
                {locationIcon && (
                  <div className="SearchLocationInput__input--icon">
                    <Icon iconName="POI" onClick={handleShowLocations} />
                  </div>
                )}
              </Col>

              {controlledValue?.length > 2 && focused && (
                <Row className="SearchLocationInput__dropdown">
                  {suggestions?.map((suggestion, index) => (
                    <Col
                      className="SearchLocationInput__dropdown--item"
                      {...getSuggestionItemProps(suggestion)}
                      key={index}
                    >
                      {suggestion.description}
                    </Col>
                  ))}
                </Row>
              )}
            </>
          )}
        </PlacesAutocomplete>
      )}
    </Row>
  );
}

export default SearchLocationInput;
