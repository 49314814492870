import { useCallback, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Form, Select, Col, Row, Spin } from 'antd';
import moment from 'moment';

import SignUpHeader from './SignUpHeader/SignUpHeader';
import RadioGroup from './RadioGroup';
import Button from '../../../../../components/Button/Button';
import DatePicker from '../../../../../components/DatePicker/DatePicker';
import UpdateModal from './UpdateInfoModal/UpdateInfoModal';
import ActionConfirmationModal from '../../../../../modals/ActionConfirmationModal/ActionConfirmationModal';
import SelectSuffixIcon from '../../../../../components/SelectSuffixIcon/SelectSuffixIcon';

import useModal from '../../../../../hooks/useModal';
import useLocale from '../../../../../hooks/useLocale';

import { DATE_FORMAT2 } from '../../../../../constants/common';
import genericMessage from '../../../../../utils/genericMessage';
import { ADMIN_INFO_CHANGE_EVENT } from '../../../../../constants/eventBus';
import { eventBus } from '../../../../../utils/eventBus';
import { ClientService } from '../../../../../shared/api/ClientService';
import API from '../../../../../utils/api';

import './SignUp.scss';

interface IForm extends ClientService.AdminInfoSignupUpdateDto {
  isTrusteeEngagementAcceptance?: boolean;
  isTrusteeConflictCheck?: boolean;
}

interface ISignUpProps {
  disableAllFields?: boolean;
}

const SignUp = ({ disableAllFields }: ISignUpProps) => {
  const [form] = Form.useForm<IForm>();
  const { applicationFileId } = useParams<{ applicationFileId: string }>();
  const { Option } = Select;
  const { showModal, closeModal } = useModal();
  const { t } = useLocale();

  const [initialValues, setInitialValues] = useState<ClientService.AdminInfoSignupDto>();
  const [loading, setLoading] = useState<boolean>(false);

  const [isAppFormComplete, setIsAppformComplete] = useState<boolean>(false);
  const [isGeneralComplete, setIsGeneralComplete] = useState<boolean>(false);
  const [isAssetDebtStatusComplete, setIsAssetDebtStatusComplete] = useState<boolean>(false);

  const SIGNUP_DROPDOWN_OPTIONS = useMemo(
    () => [
      { value: ClientService.FileProcessStatusEnum.Pending, label: t.PENDING_CONFIRMATION },
      { value: ClientService.FileProcessStatusEnum.InProgress, label: t.SIGNUP_INPROGRESS },
      { value: ClientService.FileProcessStatusEnum.Complete, label: t.SIGNED },
    ],
    [t.PENDING_CONFIRMATION, t.SIGNED, t.SIGNUP_INPROGRESS]
  );

  const fillOptionsDropdown = useCallback((signupStatus?: number) => {
    switch (signupStatus) {
      case ClientService.FileProcessStatusEnum.Pending:
        return [...SIGNUP_DROPDOWN_OPTIONS]?.splice(0, 2);
      case ClientService.FileProcessStatusEnum.InProgress:
        return [...SIGNUP_DROPDOWN_OPTIONS]?.splice(1, 2);
      case ClientService.FileProcessStatusEnum.Incomplete:
        return [...SIGNUP_DROPDOWN_OPTIONS]?.splice(1, 2);
      case ClientService.FileProcessStatusEnum.Complete:
        return [...SIGNUP_DROPDOWN_OPTIONS]?.splice(2, 1);
      default:
        return [...SIGNUP_DROPDOWN_OPTIONS]?.splice(0, 2);
    }
  }, []);

  const setInitialFormValues = useCallback(
    (values: ClientService.AdminInfoSignupDto) => {
      form.setFieldsValue({
        isPPSASearchComplete: values?.isPPSASearchCompleted,
        isInsolvencyNameSearchComplete: values?.isInsolvencyNameSearchCompleted,
        signupStatus: values?.signupStatus?.status,
        followupDate: values?.followupDate,
        isTrusteeEngagementAcceptance: values?.isTrusteeEngagementAcceptance,
        isTrusteeConflictCheck: values?.isTrusteeConflictCheck,
      });
    },
    [form]
  );

  const requestAppFormStatus = useCallback(async (applicationFileId: string) => {
    const response = await API.getAppFormStatus(applicationFileId);
    if (response) {
      setIsAppformComplete(
        response.personalInfoStatus === ClientService.AppFormStatusEnum.Complete &&
          response.assetsDebtsStatus === ClientService.AppFormStatusEnum.Complete &&
          response.incomeExpenseStatus === ClientService.AppFormStatusEnum.Complete &&
          response.incomeTaxStatus === ClientService.AppFormStatusEnum.Complete &&
          response.bankingInformationStatus === ClientService.AppFormStatusEnum.Complete &&
          response.questionnaireStatus === ClientService.AppFormStatusEnum.Complete
      );

      setIsAssetDebtStatusComplete(response.assetsDebtsStatus === ClientService.AppFormStatusEnum.Complete);
    }
  }, []);

  const requestGeneralGET = useCallback(async (applicationFileId: string) => {
    const response = await API.generalGET(applicationFileId);
    if (response) {
      setIsGeneralComplete(
        Boolean(response.agent) &&
          Boolean(response.estateAdministrator) &&
          Boolean(response.trustee) &&
          Boolean(response.interviewer) &&
          Boolean(response.filingType) &&
          Boolean(response.serviceLocation) &&
          Boolean(response.trusteeOffice)
      );
    }
  }, []);

  const requestSignUpGET = useCallback(
    async (applicationFileId: string) => {
      setLoading(true);
      const response = await API.signUpGET(applicationFileId).catch(() => setLoading(false));

      if (response) {
        setInitialValues(response);
        setInitialFormValues(response);
      }

      setLoading(false);
    },
    [setInitialFormValues]
  );

  const requestSignUpPUT = useCallback(
    async (body: ClientService.AdminInfoSignupUpdateDto) => {
      setLoading(true);
      const response = await API.signUpPUT(applicationFileId as string, body).catch(() => setLoading(false));
      setLoading(false);

      if (response?.result === ClientService.Result.Successful) {
        eventBus.dispatch(ADMIN_INFO_CHANGE_EVENT);
        requestSignUpGET(applicationFileId as string);
      } else {
        genericMessage.error({}, response?.messages?.[0]?.body);
      }
    },
    [applicationFileId, requestSignUpGET]
  );

  const checkIsInProgress = useCallback(() => {
    const values = form.getFieldsValue();
    return (
      values?.signupStatus === ClientService.FileProcessStatusEnum.Incomplete ||
      values?.signupStatus === ClientService.FileProcessStatusEnum.InProgress
    );
  }, [form]);

  const validateAppFormComplete = useCallback(() => {
    const isInProgress = checkIsInProgress();

    if (isInProgress && !isAppFormComplete) {
      genericMessage.error(
        {},
        t.UNABLE_TO_PROCEED_AS_THERE_ARE_ONE_OR_MORE_SECTIONS_IN_THE_APPLICATION_FORM_THAT_ARE_STILL_PENDING
      );
      return false;
    }

    return true;
  }, [
    checkIsInProgress,
    isAppFormComplete,
    t.UNABLE_TO_PROCEED_AS_THERE_ARE_ONE_OR_MORE_SECTIONS_IN_THE_APPLICATION_FORM_THAT_ARE_STILL_PENDING,
  ]);

  const confirmStatusChangeToComplete = useCallback(
    (body: ClientService.AdminInfoSignupUpdateDto) => {
      showModal(
        <ActionConfirmationModal
          title={t.STATUS_CHANGE_CONFIRMATION}
          message={t.PLEASE_ENSURE_THE_ESTATE_NO_IS_UPDATED_FOR_THIS_FILE_IN_ASCEND}
          onOk={() => {
            closeModal();
            requestSignUpPUT(body);
          }}
          onCancel={closeModal}
        />
      );
    },
    [
      closeModal,
      requestSignUpPUT,
      showModal,
      t.PLEASE_ENSURE_THE_ESTATE_NO_IS_UPDATED_FOR_THIS_FILE_IN_ASCEND,
      t.STATUS_CHANGE_CONFIRMATION,
    ]
  );

  const confirmStatusChangeToInProcess = useCallback(
    async (body: ClientService.AdminInfoSignupUpdateDto) => {
      const response = await API.signUpPUT(applicationFileId as string, body);

      if (
        response?.messages?.[0]?.fieldIdentifier === ClientService.FieldIdentifier?.ConfirmTransitionSignupInProgress
      ) {
        showModal(
          <ActionConfirmationModal
            title={t.STATUS_CHANGE_CONFIRMATION}
            message={t.DO_YOU_WANT_TO_UPDATE_THE_STATUS_AS_SIGNUP_INPROGRESS}
            onOk={() => {
              requestSignUpPUT({
                ...body,
                confirmUpdateStatusInProgress: true,
              } as ClientService.AdminInfoSignupUpdateDto);
              closeModal();
            }}
            onCancel={closeModal}
          />
        );
      }
    },
    [
      applicationFileId,
      closeModal,
      requestSignUpPUT,
      showModal,
      t.DO_YOU_WANT_TO_UPDATE_THE_STATUS_AS_SIGNUP_INPROGRESS,
      t.STATUS_CHANGE_CONFIRMATION,
    ]
  );

  const handleSave = useCallback(() => {
    const values = form.getFieldsValue();

    const body = {
      isPPSASearchComplete: values?.isPPSASearchComplete,
      isInsolvencyNameSearchComplete: values?.isInsolvencyNameSearchComplete,
      signupStatus: values?.signupStatus,
      followupDate: values?.followupDate,
    } as ClientService.AdminInfoSignupUpdateDto;

    if (values?.signupStatus === ClientService.FileProcessStatusEnum.Pending) {
      requestSignUpPUT(body);
      return;
    }

    if (values?.signupStatus === ClientService.FileProcessStatusEnum.Complete) {
      confirmStatusChangeToComplete(body);
      return;
    }

    confirmStatusChangeToInProcess(body);
  }, [confirmStatusChangeToComplete, confirmStatusChangeToInProcess, form, requestSignUpPUT]);

  const updateGeneralAndSave = useCallback(() => {
    showModal(
      <UpdateModal
        onOk={() => {
          closeModal();
          requestGeneralGET(applicationFileId as string);
          handleSave();
        }}
        onCancel={closeModal}
        applicationFileId={applicationFileId}
      />
    );
  }, [applicationFileId, closeModal, handleSave, requestGeneralGET, showModal]);

  const handleFinish = useCallback(() => {
    if (!validateAppFormComplete()) return;

    if (!isGeneralComplete && checkIsInProgress()) {
      updateGeneralAndSave();
    } else {
      handleSave();
    }
  }, [validateAppFormComplete, isGeneralComplete, checkIsInProgress, updateGeneralAndSave, handleSave]);

  useEffect(() => {
    if (applicationFileId && !initialValues) requestSignUpGET(applicationFileId);
  }, [applicationFileId, initialValues, requestSignUpGET]);

  useEffect(() => {
    if (applicationFileId) requestAppFormStatus(applicationFileId);
  }, [applicationFileId, requestAppFormStatus]);

  useEffect(() => {
    if (applicationFileId) requestGeneralGET(applicationFileId);
  }, [applicationFileId, requestGeneralGET]);

  const isAnyStatusIncomplete = initialValues?.status !== ClientService.AppFormStatusEnum.Complete;
  const isPageReadOnly = disableAllFields;

  return (
    <Spin spinning={loading}>
      <Row className="SignUp">
        <Col span={24}>
          <SignUpHeader
            data={initialValues}
            onUpdateSuccess={() => {
              requestSignUpGET(applicationFileId as string);
              requestAppFormStatus(applicationFileId as string);
            }}
            isAssetDebtStatusComplete={isAssetDebtStatusComplete}
            disabled={isPageReadOnly}
          />
          <Form form={form} layout="vertical" onFinish={handleFinish}>
            <Row gutter={24} align="bottom">
              <Col xl={8} xs={24}>
                <Form.Item name="isPPSASearchComplete" label={t.PPSA_SEARCH}>
                  <RadioGroup disabled={isAnyStatusIncomplete || isPageReadOnly} />
                </Form.Item>
              </Col>
              <Col xl={8} xs={24}>
                <Form.Item name="isInsolvencyNameSearchComplete" label={t.INSOLVENCY_NAME_SEARCH}>
                  <RadioGroup disabled={isAnyStatusIncomplete || isPageReadOnly} />
                </Form.Item>
              </Col>
              <Col xl={8} xs={24}>
                {initialValues?.totalDebts && initialValues?.totalDebts > 500000 ? (
                  <Form.Item
                    name="isTrusteeEngagementAcceptance"
                    label={t.TRUSTEE_TO_FOLLOW_ONLINE_STEPS_FOR_ENGAGEMENT_ACCEPTANCE__RISK_ASSESSMENT}
                  >
                    <RadioGroup disabled />
                  </Form.Item>
                ) : (
                  <Form.Item
                    name="isTrusteeConflictCheck"
                    label={t.TRUSTEE_TO_FOLLOW_RISK_MANAGEMENT_SECTION_IN_MY_BDO_AND_CONFLICT_CHECK}
                  >
                    <RadioGroup disabled />
                  </Form.Item>
                )}
              </Col>
              <Col xl={8} xs={24}>
                <Form.Item
                  name="signupStatus"
                  label={t.SIGNUP_STATUS}
                  rules={[{ required: true, message: t.REQUIRED_FIELD }]}
                >
                  <Select
                    size="large"
                    suffixIcon={<SelectSuffixIcon />}
                    placeholder={t.SELECT_STATUS}
                    disabled={isAnyStatusIncomplete || isPageReadOnly}
                  >
                    {fillOptionsDropdown(initialValues?.signupStatus?.status)?.map((item) => (
                      <Option value={item.value} key={item.value}>
                        {item.label}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col xl={8} xs={24}>
                <Form.Item noStyle shouldUpdate>
                  {({ getFieldValue }) =>
                    getFieldValue('signupStatus') === ClientService.FileProcessStatusEnum.Pending && (
                      <Form.Item name="followupDate" label={t.FOLLOWUP_DATE}>
                        <DatePicker
                          type="single"
                          dateSetter={null}
                          size="large"
                          placeholder={DATE_FORMAT2.toLowerCase()}
                          format={DATE_FORMAT2}
                          disabledDate={(date) => date?.isBefore(moment(), 'd')}
                          disabled={isAnyStatusIncomplete || isPageReadOnly}
                        />
                      </Form.Item>
                    )
                  }
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={20} justify="end">
              <Col>
                <Form.Item>
                  <Button kind="cancel" disabled={isAnyStatusIncomplete || isPageReadOnly}>
                    {t.CANCEL}
                  </Button>
                </Form.Item>
              </Col>
              <Col>
                <Form.Item>
                  <Button kind="primary" htmlType="submit" disabled={isAnyStatusIncomplete || isPageReadOnly}>
                    {t.SAVE}
                  </Button>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Col>
      </Row>
    </Spin>
  );
};

export default SignUp;
