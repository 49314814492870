import React, { useCallback, useEffect, useState, ReactNode, useRef, useMemo } from 'react';
import { Input, Divider, Dropdown, Spin, Table, Row, Col, Tooltip } from 'antd';
import { useLocation, useNavigate } from 'react-router-dom';
import ReactToPrint from 'react-to-print';
import { CSVLink } from 'react-csv';
import { Icon } from '@fluentui/react/lib/Icon';
import { AlignLeftOutlined } from '@ant-design/icons';
import moment from 'moment';

import Filters from './Filters';
import HeaderCell from './HeaderCell/HeaderCell';
import PageTitle from '../../components/PageTitle/PageTitle';
import Layout from '../../components/Layout/Layout';
import Button from '../../components/Button/Button';
import ActionCell from './ActionCell/ActionCell';

import useLocale from '../../hooks/useLocale';
import { ROUTES } from '../../constants/routes';
import { INITIAL_PAGE_INDEX, DATE_FORMAT2, FIRST_ITEM, DEFAULT_PAGE_SIZE } from '../../constants/common';
import { DEFAULT_COLUMN_WIDTH, ACTIONS_KEY, DOUBLE_COLUMN_WIDTH } from './constants';
import { paginationShowTotal } from '../../utils/helpers';
import { argumentifyDebtorSearchCriteria, convertDebtorsListToCSVData } from './utils';
import { IDebtorSearchCriteria, ICSVDebtors } from './types';
import { ClientService } from '../../shared/api/ClientService';
import API from '../../utils/api';

import './DebtorSearchPage.scss';

interface ClientDataType extends ClientService.ClientResultsDto {
  key: React.Key;
}

interface ILocation {
  state?: IDebtorSearchCriteria;
  key?: string;
}

const DebtorSearchPage = (): JSX.Element => {
  const { t } = useLocale();
  const location = useLocation() as ILocation;
  const navigate = useNavigate();
  const printRef = useRef<HTMLDivElement>(null);
  const csvRef = useRef<any>(null);

  const { Search } = Input;

  const [debtors, setDebtors] = useState<ClientDataType[]>();
  const [debtorsLoading, setDebtorsLoading] = useState<boolean>(false);
  const [totalCount, setTotalCount] = useState<number>();

  const [advancedFilters, showAdvancedFilters] = useState(false);
  const [popupFilters, showPopupFilters] = useState(false);
  const [expandedRowKeys, setExpandedRowKeys] = useState<React.Key[]>([]);

  const [searchString, setSearchString] = useState<string>();
  const [criteria, setCriteria] = useState<IDebtorSearchCriteria>();
  const [csvList, setCSVList] = useState<ICSVDebtors[]>([]);

  const isAnyResult = Boolean(debtors?.length);

  const requestDebtorSearchResults = useCallback(async (criteria: IDebtorSearchCriteria) => {
    setDebtorsLoading(true);
    const response = await API.getDebtorSearchResults(...argumentifyDebtorSearchCriteria(criteria)).catch(() =>
      setDebtorsLoading(false)
    );

    if (response?.clients) {
      setDebtors(response?.clients?.map((item) => ({ ...item, key: item?.clientId } as ClientDataType)));
      setTotalCount(response.totalRecords || 0);
      setExpandedRowKeys(response?.clients?.map((item) => item.clientId as React.Key));
    }

    setDebtorsLoading(false);
  }, []);

  const requestCSVList = useCallback(async () => {
    const response = await API.getDebtorSearchResults(
      ...argumentifyDebtorSearchCriteria({ ...criteria, pageIndex: 0, pageSize: totalCount })
    );

    if (response?.clients) {
      setCSVList(convertDebtorsListToCSVData(response?.clients));
      if (csvRef?.current) csvRef?.current?.link?.click();
    }
  }, [criteria, totalCount]);

  const handleTableChange = useCallback(
    (pagination: any) =>
      setCriteria((prev) => ({
        ...prev,
        pageIndex: pagination?.current - 1,
        pageSize: pagination.pageSize,
      })),
    []
  );

  const handleSearch = useCallback(
    (searchString) => {
      setCriteria((prev) => ({ ...prev, searchString, pageIndex: INITIAL_PAGE_INDEX }));
      if (location?.state) navigate(ROUTES.DEBTOR_SEARCH, { replace: true });
    },
    [location?.state, navigate]
  );

  const handleFiltersChange = useCallback(
    (filters) => setCriteria((prev) => ({ ...prev, ...filters, searchString, isInitialLoad: false })),
    [searchString]
  );

  const resetSearchFields = useCallback(() => {
    setCriteria((prev) => ({
      ...prev,
      clientStatus: undefined,
      fileStage: undefined,
      filingType: undefined,
      trustee: undefined,
      serviceLocation: undefined,
      estateAdmin: undefined,
      isEmailChecked: undefined,
      isEstateNumberChecked: undefined,
    }));
  }, []);

  const handleRowExpand = useCallback(
    (expanded: boolean, key: React.Key) => {
      let expandedArray = [];
      if (expanded) {
        expandedArray = expandedRowKeys.filter((item) => item !== key);
      } else {
        expandedArray = [...expandedRowKeys, key];
      }
      setExpandedRowKeys(expandedArray);
    },
    [expandedRowKeys]
  );

  const columns = useMemo(
    () => [
      {
        dataIndex: 'firstName',
        label: t.FIRST_NAME,
        key: 'firstName',
        render: (text: string, item: ClientDataType) => <HeaderCell title={t.FIRST_NAME} text={text} />,
      },
      {
        dataIndex: 'middleName',
        label: t.MIDDLE_NAME,
        key: 'middleName',
        render: (text: string) => <HeaderCell title={t.MIDDLE_NAME} text={text} />,
      },
      {
        dataIndex: 'lastName',
        label: t.LAST_NAME,
        key: 'lastName',
        render: (text: string) => <HeaderCell title={t.LAST_NAME} text={text} />,
      },
      {
        dataIndex: 'mainPhoneNumber',
        label: t.PHONE,
        key: 'mainPhoneNumber',
        render: (text: string) => <HeaderCell title={t.PHONE} text={text} />,
      },
      {
        dataIndex: 'emailAddress',
        label: t.EMAIL,
        key: 'emailAddress',
        render: (text: string) => <HeaderCell title={t.EMAIL} text={text} />,
      },
      {
        dataIndex: 'clientStatus',
        label: t.STATUS,
        key: 'clientStatus',
        render: (text: string) => <HeaderCell title={t.STATUS} text={text} />,
      },
      {
        dataIndex: 'createdOn',
        key: 'createdOn',
        label: t.CREATED,
        render: (text: string) => <HeaderCell title={t.CREATED} text={text ? moment(text).format(DATE_FORMAT2) : ''} />,
      },
    ],
    [t.CREATED, t.EMAIL, t.FIRST_NAME, t.LAST_NAME, t.MIDDLE_NAME, t.PHONE, t.STATUS]
  );

  const nestedColumns = useMemo(
    () => [
      {
        title: t.FILENAME,
        dataIndex: 'fileName',
        key: 'fileName',
      },
      {
        title: t.ESTATE,
        dataIndex: 'estateNumber',
        key: 'estateNumber',
      },
      {
        title: t.FILING_TYPE,
        dataIndex: 'filingType',
        key: 'filingType',
      },
      {
        title: t.SERVICE_LOCATION,
        dataIndex: 'serviceLocation',
        key: 'serviceLocation',
      },
      {
        title: t.ESTATE_ADMINISTRATOR,
        dataIndex: 'estateAdministrator',
        key: 'estateAdministrator',
      },
      {
        title: t.TRUSTEE,
        dataIndex: 'trustee',
        key: 'trustee',
      },
      {
        title: t.STAGE,
        dataIndex: 'stage',
        key: 'stage',
        render: (stage: string) => (
          <div className={stage === 'Cancelled' ? 'DebtorSearchPage__cancelled-stage' : ''}>{stage}</div>
        ),
      },
      {
        title: t.ACTIONS,
        dataIndex: ACTIONS_KEY,
        key: ACTIONS_KEY,
        render: (_: any, item: ClientService.FileResultsDto) => <ActionCell fileId={item?.fileId} />,
      },
    ],
    [t.ACTIONS, t.ESTATE, t.ESTATE_ADMINISTRATOR, t.FILENAME, t.FILING_TYPE, t.SERVICE_LOCATION, t.STAGE, t.TRUSTEE]
  );

  const headers = useMemo(
    () => [
      ...columns?.map(({ label, key }) => ({ label, key })),
      ...nestedColumns?.filter((item) => item.key !== ACTIONS_KEY)?.map(({ title, key }) => ({ label: title, key })),
    ],
    [columns, nestedColumns]
  );

  const searchField = useMemo(
    () => (
      <Search
        value={searchString}
        onChange={(e) => setSearchString(e.target.value)}
        onSearch={handleSearch}
        placeholder={t.HEADER_SEARCH_PLACEHOLDER}
        allowClear
        className="DebtorSearchPage__search-input"
        size="large"
      />
    ),
    [Search, handleSearch, searchString, t.HEADER_SEARCH_PLACEHOLDER]
  );

  const overlay = useMemo(
    () => (
      <div className="DebtorSearchPage__dropdown-overlay">
        <Filters
          isPopupView={isAnyResult}
          onChange={(changedCriteria) => {
            handleFiltersChange(changedCriteria);
            showPopupFilters(false);
          }}
          criteria={criteria}
        />
      </div>
    ),
    [criteria, handleFiltersChange, isAnyResult]
  );

  const expandedRowRender = useCallback(
    (row: ClientDataType, isPrintView?: boolean): ReactNode => {
      const columns = isPrintView ? nestedColumns?.filter((item) => item.key !== 'actions') : [...nestedColumns];
      return (
        <Table
          columns={columns.map((item) => ({ ...item, width: DEFAULT_COLUMN_WIDTH }))}
          dataSource={row?.files}
          rowKey={(record: ClientService.FileResultsDto) => record?.fileId as string}
          onRow={(record) => ({
            onClick: () => navigate(`${ROUTES.APPLICATION_OVERVIEW}/${record?.fileId}`),
          })}
          rowClassName="DebtorSearchPage__clickable-row"
          pagination={false}
          className="DebtorSearchPage__expandable-table"
        />
      );
    },
    [navigate, nestedColumns]
  );

  const tableRenderer = useCallback(
    (isPrintView?: boolean) => (
      <Table
        showHeader={false}
        rowKey="clientId"
        dataSource={debtors}
        columns={[
          ...columns.map((item, index) => ({
            ...item,
            width: index === columns?.length - 1 && !isPrintView ? DOUBLE_COLUMN_WIDTH : DEFAULT_COLUMN_WIDTH,
          })),
        ]}
        onChange={handleTableChange}
        expandable={{
          expandedRowRender: (row) => expandedRowRender(row, isPrintView),
          expandIcon: ({ expanded, onExpand, record }) => (
            <Icon
              iconName={expanded ? 'ChevronUp' : 'ChevronRight'}
              onClick={(e) => {
                handleRowExpand(expanded, record?.key as string);
                onExpand(record, e);
              }}
              className="DebtorSearchPage__expand-icon"
            />
          ),

          expandedRowKeys,
        }}
        onRow={(record) => ({
          onClick: () => navigate(`${ROUTES.DEBTOR_PROFILE}/${record?.files?.[FIRST_ITEM]?.fileId}`),
        })}
        rowClassName="DebtorSearchPage__clickable-row"
        pagination={
          !isPrintView
            ? {
                position: ['bottomLeft'],
                defaultPageSize: DEFAULT_PAGE_SIZE,
                showSizeChanger: true,
                total: totalCount,
                showTotal: paginationShowTotal,
              }
            : false
        }
        className="DebtorSearchPage__table"
      />
    ),
    [columns, debtors, expandedRowKeys, expandedRowRender, handleRowExpand, handleTableChange, navigate, totalCount]
  );

  useEffect(() => {
    if (location?.state) {
      setSearchString(location?.state?.searchString);
      setCriteria(location?.state);
    }
  }, [location?.state, navigate]);

  useEffect(() => {
    if (criteria) requestDebtorSearchResults(criteria);
  }, [criteria, requestDebtorSearchResults]);

  return (
    <Layout page={ROUTES.DEBTOR_SEARCH}>
      <PageTitle title={t.DEBTOR_SEARCH} />
      <div className="DebtorSearchPage">
        {isAnyResult ? (
          <Row align="bottom" justify="space-between">
            <Col span={20}>
              <Row align="middle">
                <Col>
                  <Dropdown
                    overlay={overlay}
                    trigger={['click']}
                    placement="topLeft"
                    visible={popupFilters}
                    onVisibleChange={(newOpen) => showPopupFilters(newOpen)}
                    destroyPopupOnHide
                  >
                    <Button kind="link" style={{ fontWeight: 'bold' }}>
                      <AlignLeftOutlined />
                      {t.FILTERS}
                    </Button>
                  </Dropdown>
                </Col>
                <Col onClick={resetSearchFields}>
                  <Button kind="link">{t.CLEAR}</Button>
                </Col>
                <Col offset={1}>{searchField}</Col>
              </Row>
            </Col>
            <Col span={4}>
              <Row justify="end" gutter={10} align="middle">
                <Col>
                  <ReactToPrint
                    content={() => printRef.current}
                    trigger={() => (
                      <Tooltip title={t.PRINT_ALL_RECORDS} placement="top">
                        <Icon iconName="Print" className="DebtorSearchPage__print-icon" />
                      </Tooltip>
                    )}
                    onBeforeGetContent={() =>
                      requestDebtorSearchResults({ ...criteria, pageIndex: 0, pageSize: totalCount })
                    }
                  />
                </Col>
                <Col>
                  <Tooltip title={t.DOWNLOAD_AS_CSV_FILE} placement="topRight">
                    <Icon iconName="Download" className="DebtorSearchPage__download-icon" onClick={requestCSVList} />
                  </Tooltip>

                  <CSVLink
                    filename={'Debtor_List.csv'}
                    data={csvList}
                    headers={headers}
                    ref={csvRef}
                    style={{ display: 'none' }}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
        ) : (
          <Row>
            <Col span={8} offset={8}>
              {searchField}
              <div className="DebtorSearchPage__advanced-search" onClick={() => showAdvancedFilters(!advancedFilters)}>
                {advancedFilters ? t.HIDE_ADVANCED_SEARCH : t.ADVANCED_SEARCH}
              </div>
            </Col>
            <Col span={16} offset={4}>
              {advancedFilters && <Filters isPopupView={false} onChange={handleFiltersChange} criteria={criteria} />}
            </Col>
          </Row>
        )}

        <div className="DebtorSearchPage__divider-container">{isAnyResult && <Divider />}</div>
        <Spin spinning={debtorsLoading}>
          {isAnyResult ? (
            tableRenderer(false)
          ) : (
            <div className="DebtorSearchPage__results-empty-view">
              {t.No_SEARCH_RESULTS_AVLABLE_PLS_CHK_N_TRY_AGAIN}
            </div>
          )}
        </Spin>
      </div>

      <div ref={printRef} className="DebtorSearchPage__print-table">
        <PageTitle title={t.DEBTOR_SEARCH} />
        <div className="DebtorSearchPage__print-word-break">{tableRenderer(true)}</div>
      </div>
    </Layout>
  );
};

export default DebtorSearchPage;
