import { IDebtorSearchResult, IDebtorSearchCriteria } from './types';

export const readableDebtorOption = (source?: IDebtorSearchResult) => {
  const debtorName = source?.firstName || source?.lastName ? `${source?.firstName} ${source?.lastName}` : '-';
  const fileName = source?.fileName || '-';
  const estateNumber = source?.estateNumber || '-';
  return `Debtor: ${debtorName}, FileName: ${fileName}, Estate #: ${estateNumber}`;
};

export const argumentifyDebtorSearchCriteria = ({
  searchString,
  fullName,
  hasActiveFiles,
  pageSize,
  pageIndex,
  clientStatus,
  fileStage,
  filingType,
  trustee,
  serviceLocation,
  estateAdmin,
  isEmailChecked,
  isEstateNumberChecked,
  sortingFieldName,
  isAscending,
  includeFileIds,
  fileStageId,
  filingTypeId,
  debtorId,
  trusteeId,
  officeId,
  estateAdminId,
}: IDebtorSearchCriteria) =>
  [
    searchString,
    fullName,
    hasActiveFiles,
    pageSize,
    pageIndex,
    clientStatus,
    fileStage,
    filingType,
    trustee,
    serviceLocation,
    estateAdmin,
    isEmailChecked,
    isEstateNumberChecked,
    sortingFieldName,
    isAscending,
    includeFileIds,
    fileStageId,
    filingTypeId,
    debtorId,
    trusteeId,
    officeId,
    estateAdminId,
  ] as const;
