import { useCallback } from 'react';
import { Modal, Row, Col } from 'antd';

import Button from '../../../../components/Button/Button';
import useLocale from '../../../../hooks/useLocale';

import './NoteDeleteConfirmation.scss';

interface IProps {
  visible?: boolean;
  onOk?: () => void;
  onCancel?: () => void;
}

function NoteDeleteConfirmation({ visible = false, onOk, onCancel }: IProps): JSX.Element {
  const { t } = useLocale();
  const handleOk = useCallback(() => {
    if (onOk) onOk();
  }, [onOk]);

  const handleCancel = useCallback(() => {
    if (onCancel) onCancel();
  }, [onCancel]);
  return (
    <Modal
      destroyOnClose
      title={t.CONFIRMATION}
      visible
      footer={
        <Row gutter={10} justify="end">
          <Col>
            <Button key="cancel" kind="cancel" padding="large" onClick={handleCancel}>
              {t.NO}
            </Button>
          </Col>
          <Col>
            <Button key="ok" padding="large" onClick={handleOk}>
              {t.YES}
            </Button>
          </Col>
        </Row>
      }
      className="NoteDeleteConfirmation"
      onCancel={onCancel}
    >
      <p>{t.NOTE_DELETE_CONFIRMATION}</p>
    </Modal>
  );
}

export default NoteDeleteConfirmation;
