import React, { useState, useEffect, useCallback } from 'react';
import { Row, Col } from 'antd';
import { useParams, useNavigate } from 'react-router-dom';
import moment from 'moment';
import Layout from '../../components/Layout/Layout';
import PageTitle from '../../components/PageTitle/PageTitle';
import NewAndViewHeader from '../ApplicationOverviewPage/NewAndViewPageComponents/NewAndViewHeader/NewAndViewHeader';
import { ROUTES } from '../../constants/routes';
import useLocale from '../../hooks/useLocale';
import AppForm from '../../components/Forms/AppForm/AppForm';
import EmailContentDisplay, {
  TEmailData,
} from '../ApplicationOverviewPage/NewAndViewPageComponents/EmailContentDisplay/EmailContentDisplay';
import FormInput from '../../components/Forms/FormInput/FormInput';
import DynamicInfoBlock, { TDataItem } from '../../components/DynamicInfoBlock/DynamicInfoBlock';
import ThreeColumnLowerLayout from '../ApplicationOverviewPage/NewAndViewPageComponents/ThreeColumnLowerLayout/ThreeColumnLowerLayout';
import Loading from '../../components/Loading/Loading';
import API from '../../utils/api';
import { ClientService } from '../../shared/api/ClientService';
import { DATE_FORMAT2 } from '../../constants/common';
import useErrorHandling from '../../hooks/useErrorHandling';
import useAlertAsAsync from '../ApplicationOverviewPage/NewAndViewPageComponents/AlertModal/useAlertAsAsync';
import ErrorsContainer, { IErrorsMsgAndType } from '../../components/ErrorsContainer/ErrorsContainer';
import { ISelectOption } from '../../components/Forms/FormInput/SelectInput';
import {
  getEnumTextValuesAsArray,
  getUndefinedOrBoolean,
  getUndefinedOrSelf,
  getUndefinedOrYesNo,
  convertInputDateToServerDateOnly,
  convertServerDateOnlyToInputDate,
} from '../../utils/helpers';
import ButtonsGroupCancelDraftComplete from '../ApplicationOverviewPage/NewAndViewPageComponents/NewAndViewHeader/HeaderButtonGroups/ButtonsGroupCancelDraftComplete';
import genericMessage from '../../utils/genericMessage';
import { argumentifyTaskActionSearchCriteria } from '../../pages/LandingPage/utils';
import { AuthorizeService } from '../../components/Auth/AuthorizeService';

interface IViewSubmissionForm {
  result?: number;
  discrepancy?: string;
  additionalProcess?: string;
  additionalProcessInstructions?: string;
  commentsForClientPortal?: string;
  dateFiled?: moment.Moment;
  reminderDate?: moment.Moment;
  estimated?: string;
  source?: number;
  assessmentDate?: moment.Moment;
  status?: string;
  dispositionDate?: moment.Moment;
  disposition?: string;
}

const getSubmitValuesFromForm = (
  id: string,
  taxSubmissionGetResponse: ClientService.TaxReturnSubmissionDetailDto,
  formValues: IViewSubmissionForm
): ClientService.ITaxReturnSubmissionUpdateDto => {
  return {
    id,
    filedDate: getUndefinedOrSelf(formValues.dateFiled),
    reminderDate: getUndefinedOrSelf(formValues.reminderDate),
    source: getUndefinedOrSelf(formValues.source),
    assessmentDate: getUndefinedOrSelf(formValues.assessmentDate),
    ascendTaxReturnStatus: getUndefinedOrSelf(formValues.status),
    disposition: getUndefinedOrSelf(formValues.disposition),
    dispositionDate: getUndefinedOrSelf(formValues.dispositionDate),
    result: getUndefinedOrSelf(formValues.result),
    hasDiscrepancy: getUndefinedOrBoolean(formValues.discrepancy),
    hasAdditionalProcess: getUndefinedOrBoolean(formValues.additionalProcess),
    additionalProcessInstructions: getUndefinedOrSelf(formValues.additionalProcessInstructions),
    commentsForClientPortal: getUndefinedOrSelf(formValues.commentsForClientPortal),
    isComplete: false,
    estimatedAmount: getUndefinedOrSelf(formValues.estimated),
  };
};

const ViewTaxReturnPage = () => {
  const { applicationFileId, submissionId } = useParams();
  const { t } = useLocale();
  const { handleAlert } = useAlertAsAsync();
  const { processResponseForErrors } = useErrorHandling();
  const navigate = useNavigate();

  const [form] = AppForm.AntD.useForm();

  const [pageErrors, setPageErrors] = useState<IErrorsMsgAndType[] | undefined>();
  const [headerErrors, setHeaderErrors] = useState<IErrorsMsgAndType[] | undefined>();

  const [taxReturnResponseFailed, setTaxReturnResponseFailed] = useState<boolean>(false);
  const [taxReturnResponse, setTaxReturnResponse] = useState<ClientService.TaxReturnSubmissionDetailDto | undefined>();
  const [taxSubmissionState, setTaxSubmissionState] = useState<string | undefined>();

  const [submissionType, setSubmissionType] = useState<ClientService.TaxReturnSubmissionTypeEnum | undefined>();

  const [infoBlockItems, setInfoBlockItems] = useState<TDataItem[] | undefined>();
  const [documentIds, setDocumentIds] = useState<string[] | undefined>();
  const [emailInfo, setEmailInfo] = useState<TEmailData | undefined>();

  const [additionalProcessValue, setAdditionalProcessValue] = useState<boolean | undefined>();

  const [statusTypeOptions, setStatusTypeOptions] = useState<ISelectOption[] | undefined>();
  const [dispositionTypeOptions, setDispositionTypeOptions] = useState<ISelectOption[] | undefined>();
  const [sourceTypeOptions, setSourceTypeOptions] = useState<ISelectOption[] | undefined>();

  const [initialFormValuesHaveBeenSet, setInitialFormValuesHaveBeenSet] = useState<boolean>(false);
  const [formIsLoading, setFormIsLoading] = useState<boolean>(true);

  const [infoBlockIsLoading, setInfoBlockIsLoading] = useState<boolean>(true);
  const [threeColLayoutIsLoading, setThreeColLayoutIsLoading] = useState<boolean>(true);

  const [isFormInactive, setIsFormInactive] = useState<boolean>(false);

  // useEffect(() => {
  //   console.log('submissionId:', submissionId);
  // }, [submissionId]);

  const getInfoBlockItems = useCallback(
    (info: { [key: string]: any }): TDataItem[] => {
      return [
        { label: t.ASCEND_ESTATE_NUMBER, value: info.estateNumber },
        { label: t.ASCEND_SIN, value: info.sin },
        { label: t.ASCEND_DEBTOR_NAME, value: info.debtorName },
        {
          label: t.ASCEND_DATE_OF_INSOLVENCY,
          value: info?.dateOfInsolvency
            ? convertServerDateOnlyToInputDate(moment(info?.dateOfInsolvency))?.format(DATE_FORMAT2)
            : '',
        },
        { label: t.ASCEND_SUBMISSION, value: ClientService.TaxReturnSubmissionTypeEnum[info.submission] },
        { label: t.ASCEND_TAX_PERIOD, value: info.taxPeriod },
        { label: t.INFO_FILENAME, value: info.fileName },
        { label: t.INFO_DESCRIPTION, value: info.description },
      ];
    },
    [
      t.ASCEND_ESTATE_NUMBER,
      t.ASCEND_SIN,
      t.ASCEND_DEBTOR_NAME,
      t.ASCEND_DATE_OF_INSOLVENCY,
      t.ASCEND_SUBMISSION,
      t.ASCEND_TAX_PERIOD,
      t.INFO_FILENAME,
      t.INFO_DESCRIPTION,
    ]
  );

  const requestTaxReturnSubmission = useCallback(
    (submissionId: string) => {
      API.taxReturnSubmissionGET(submissionId)
        .then((response) => {
          const responseErrors = processResponseForErrors(response);
          setPageErrors(responseErrors.messages);
          if (responseErrors.hasErrors) {
            setTaxReturnResponseFailed(true);
            setFormIsLoading(false);
            return;
          }
          setTaxReturnResponse({
            ...response,
            filedDate: convertServerDateOnlyToInputDate(response?.filedDate),
            reminderDate: convertServerDateOnlyToInputDate(response?.reminderDate),
            assessmentDate: convertServerDateOnlyToInputDate(response?.assessmentDate),
            dispositionDate: convertServerDateOnlyToInputDate(response?.dispositionDate),
            receivedDate: convertServerDateOnlyToInputDate(response?.receivedDate),
          } as ClientService.TaxReturnSubmissionDetailDto);
        })
        .catch((error) => {
          setPageErrors([{ message: t.DATA_COULD_NOT_BE_RETRIEVED }]);
          setTaxReturnResponseFailed(true);
          setFormIsLoading(false);
        });
    },
    [processResponseForErrors, t.DATA_COULD_NOT_BE_RETRIEVED]
  );

  const handleOnCompleteNavigate = useCallback(async () => {
    const user = AuthorizeService.getCurrentUserInfo();
    const response = await API.taskActionsGET(
      ...argumentifyTaskActionSearchCriteria({
        maxResultCount: 1000,
        skipCount: 0,
        assignedAgentId: user?.profile?.sub,
        calledFromDashboard: true,
        teamTypes: [ClientService.TeamTypeEnum.Tax_CPC],
      })
    );
    if (response) {
      const nextPendingActionId = response?.items
        ?.filter((item) => !item?.linkedTaxReturnSubmissionIds || !Boolean(item?.linkedTaxReturnSubmissionIds?.length))
        ?.sort(
          (a, b) =>
            (moment(b?.closeDate).diff(moment(b?.openDate), 'days') || 0) -
            (moment(a?.closeDate).diff(moment(a?.openDate), 'days') || 0)
        )?.[0]?.id;

      if (nextPendingActionId) {
        navigate(`${ROUTES.TASK_ACTIONS}/${ROUTES.NEW_TAX_RETURN}/${nextPendingActionId}`);
      } else {
        navigate(ROUTES.DASHBOARD);
      }
    }
  }, [navigate]);

  useEffect(() => {
    if (statusTypeOptions) return;

    API.taxStatusTypes()
      .then((response) => {
        const responseErrors = processResponseForErrors(response as any);
        if (responseErrors.hasErrors) {
          console.error("Couldn't retrieve Tax Status Types");
          return;
        }
        response.sort((a, b) => {
          if (a.orderNumber != null && b.orderNumber != null) return a.orderNumber - b.orderNumber;
          return 0;
        });
        // console.log('Tax Status Types sorted:', response);
        setStatusTypeOptions(
          response.map((statusType) => {
            return { label: statusType.name, value: statusType.name };
          })
        );
      })
      .catch((error) => {
        console.error("Couldn't retrieve Tax Status Types");
      });
  }, [statusTypeOptions, processResponseForErrors]);

  useEffect(() => {
    if (dispositionTypeOptions) return;

    API.dispositionTypes()
      .then((response) => {
        const responseErrors = processResponseForErrors(response as any);
        if (responseErrors.hasErrors) {
          console.error("Couldn't retrieve Disposition Types");
          return;
        }
        setDispositionTypeOptions(
          response.map((dispositionType) => {
            return { label: dispositionType.name, value: dispositionType.name };
          })
        );
      })
      .catch((error) => {
        console.error("Couldn't retrieve Disposition Types");
      });
  }, [dispositionTypeOptions, processResponseForErrors]);

  useEffect(() => {
    if (sourceTypeOptions) return;

    API.sourceTypes()
      .then((response) => {
        const responseErrors = processResponseForErrors(response as any);
        if (responseErrors.hasErrors) {
          console.error("Couldn't retrieve Source Types");
          return;
        }
        response.sort((a, b) => {
          if (a.orderNumber != null && b.orderNumber != null) return a.orderNumber - b.orderNumber;
          return 0;
        });
        setSourceTypeOptions(
          response.map((sourceType) => {
            return { label: sourceType.name, value: sourceType.name };
          })
        );
      })
      .catch((error) => {
        console.error("Couldn't retrieve Source Types");
      });
  }, [sourceTypeOptions, processResponseForErrors]);

  useEffect(() => {
    if (taxReturnResponse || submissionId == null) return;
    requestTaxReturnSubmission(submissionId);
  }, [
    taxReturnResponse,
    submissionId,
    processResponseForErrors,
    t.DATA_COULD_NOT_BE_RETRIEVED,
    requestTaxReturnSubmission,
  ]);

  useEffect(() => {
    if (!taxReturnResponse) return;

    if (taxReturnResponse.info) {
      const infoBlockData = taxReturnResponse.info;
      setInfoBlockItems(getInfoBlockItems(infoBlockData));
      setSubmissionType(infoBlockData.submission);
      // setSubmissionType(ClientService.TaxReturnSubmissionTypeEnum.TaxReturnFiling);
      setInfoBlockIsLoading(false);
    } else {
      setInfoBlockIsLoading(false);
    }

    if (taxReturnResponse.state != null) {
      setTaxSubmissionState(ClientService.TaxReturnSubmissionStateEnum[taxReturnResponse.state]);
      if (
        taxReturnResponse.state === ClientService.TaxReturnSubmissionStateEnum.Complete ||
        taxReturnResponse.state === ClientService.TaxReturnSubmissionStateEnum.Inactive
      ) {
        setIsFormInactive(true);
      }
    }

    setEmailInfo({
      fromName: taxReturnResponse.importedEmailSenderName,
      fromAddress: taxReturnResponse.importedEmailSenderEmailAddress,
      toName: taxReturnResponse.importedEmailRecipientName,
      toAddress: taxReturnResponse.importedEmailRecipientEmailAddress,
      subjectField: taxReturnResponse.importedEmailSubject,
      bodyField: taxReturnResponse.importedEmailBody,
    });

    setDocumentIds(taxReturnResponse.documentIds);
  }, [taxReturnResponse, getInfoBlockItems]);

  useEffect(() => {
    if (!taxReturnResponse || initialFormValuesHaveBeenSet) return;

    let submissionFields: IViewSubmissionForm = {
      result: getUndefinedOrSelf(taxReturnResponse.result),
      discrepancy: getUndefinedOrYesNo(taxReturnResponse.hasDiscrepancy),
      additionalProcess: getUndefinedOrYesNo(taxReturnResponse.hasAdditionalProcess),
      commentsForClientPortal: getUndefinedOrSelf(taxReturnResponse.commentsForClientPortal),
      dateFiled: getUndefinedOrSelf(taxReturnResponse.filedDate),
      reminderDate: getUndefinedOrSelf(taxReturnResponse.reminderDate),
      estimated: getUndefinedOrSelf(taxReturnResponse.estimatedAmount),
      source: getUndefinedOrSelf(taxReturnResponse.source),
      assessmentDate: getUndefinedOrSelf(taxReturnResponse.assessmentDate),
      status: getUndefinedOrSelf(taxReturnResponse.ascendTaxReturnStatus),
      dispositionDate: getUndefinedOrSelf(taxReturnResponse.dispositionDate),
      disposition: getUndefinedOrSelf(taxReturnResponse.disposition),
      additionalProcessInstructions: getUndefinedOrSelf(taxReturnResponse.additionalProcessInstructions),
    };

    form.setFieldsValue(submissionFields);
    setAdditionalProcessValue(taxReturnResponse.hasAdditionalProcess);
    setInitialFormValuesHaveBeenSet(true);
    setFormIsLoading(false);
  }, [taxReturnResponse, initialFormValuesHaveBeenSet, form]);

  useEffect(() => {
    if (!taxReturnResponse || !emailInfo || !documentIds) {
      return;
    }

    setThreeColLayoutIsLoading(false);
  }, [taxReturnResponse, emailInfo, documentIds]);

  const handleSave = useCallback(
    async (isComplete = false, withAlert = false) => {
      if (!taxReturnResponse || submissionId == null) return;
      setHeaderErrors(undefined);

      const formValues = form.getFieldsValue();
      // console.log('formValues:', formValues);

      let requestIsValid = true;
      if (isComplete) {
        try {
          await form.validateFields();
        } catch (error) {
          // console.log('form validation error', error);
          requestIsValid = false;
        }
      }
      if (!requestIsValid) {
        setHeaderErrors([{ message: t.ERROR__REQUIRED_FIELDS_MISSING }]);
        return;
      }

      // Combine page form values with fetched values needed for save
      let requestBody: ClientService.ITaxReturnSubmissionUpdateDto = getSubmitValuesFromForm(
        submissionId,
        taxReturnResponse,
        formValues
      );
      // console.log('requestBody:', requestBody);

      if (isComplete) {
        requestBody = {
          ...requestBody,
          isComplete: true,
        };
      }

      const request = new ClientService.TaxReturnSubmissionUpdateDto(requestBody);

      if (withAlert) {
        const alertBody = await handleAlert();
        // console.log('alertFormValues:', alertBody);
        if (!alertBody) return;
        const typedAlertBody = alertBody as ClientService.IAlertCreateDto;
        const alertRequest = new ClientService.AlertCreateDto(typedAlertBody);
        request.alerts = alertRequest;
      }

      // console.log('save request body:', request);

      try {
        const saveResponse = await API.taxReturnSubmissionPUT({
          ...request,
          filedDate: convertInputDateToServerDateOnly(request?.filedDate),
          reminderDate: convertInputDateToServerDateOnly(request?.reminderDate),
          assessmentDate: convertInputDateToServerDateOnly(request?.assessmentDate),
          dispositionDate: convertInputDateToServerDateOnly(request?.dispositionDate),
        } as ClientService.TaxReturnSubmissionUpdateDto);
        const responseErrors = processResponseForErrors(saveResponse);
        setHeaderErrors(responseErrors.messages);
        if (responseErrors.hasErrors) return;

        setTimeout(() => genericMessage.success(t.SUCCESSFULLY_SAVED), 500);

        if (isComplete) {
          handleOnCompleteNavigate();
        }
      } catch (error: any) {
        const requestErrors = processResponseForErrors(error);
        console.error(requestErrors.messages);
        setHeaderErrors([{ message: t.ERROR__TRYING_TO_SAVE }]);
        return;
      }
    },
    [
      taxReturnResponse,
      submissionId,
      form,
      t.ERROR__REQUIRED_FIELDS_MISSING,
      t.SUCCESSFULLY_SAVED,
      t.ERROR__TRYING_TO_SAVE,
      handleAlert,
      processResponseForErrors,
      handleOnCompleteNavigate,
    ]
  );

  // useEffect(() => {
  //   console.log('taxReturnResponse:', taxReturnResponse);
  // }, [taxReturnResponse]);

  return (
    <Layout page={ROUTES.TAX_RETURN}>
      <div className={`styles.ViewTaxReturnPage`}>
        {pageErrors && <ErrorsContainer errors={pageErrors} noTitle />}
        <PageTitle title={t.TAXRETURN_TAX_RETURN_PROCESS} />
        <NewAndViewHeader
          errors={headerErrors}
          fileId={applicationFileId}
          subtitle="view-tax-return"
          status={taxSubmissionState}
          breadcrumbData={[
            { link: ROUTES.DASHBOARD, title: t.DASHBOARD },
            { link: `${ROUTES.APPLICATION_OVERVIEW}`, title: t.APPLICATION_OVERVIEW },
            { title: t.ADMIN_INFO_PROCESS_TAX_RETURN },
            { title: t.NEWVIEW_SUBTITLE_VIEW_TAX_RETURN },
          ]}
          buttons={
            !isFormInactive && (
              <ButtonsGroupCancelDraftComplete
                buttonsEnabled={initialFormValuesHaveBeenSet && !isFormInactive}
                onSaveAsDraft={() => handleSave()}
                onSaveAsDraftWithAlert={() => handleSave(false, true)}
                onComplete={() => handleSave(true)}
                onCompleteWithAlert={() => handleSave(true, true)}
              />
            )
          }
        />
        <AppForm form={form} isLoading={formIsLoading} disableAllFields={isFormInactive}>
          <Row gutter={20}>
            <Col span={24}>
              <DynamicInfoBlock externalDataIsLoading={infoBlockIsLoading} itemsPerRow={3} data={infoBlockItems} />
            </Col>
          </Row>
          <Row gutter={20}>
            <Col span={6}>
              <FormInput
                type="select"
                label={t.VIEW_TAXRETURN_RESULT}
                name="result"
                required
                optionsList={(() => {
                  const resultOptions = getEnumTextValuesAsArray(ClientService.TaxReturnSubmissionStatusEnum);
                  return resultOptions.map((option, index) => {
                    return { label: option, value: index };
                  });
                })()}
                disabled={isFormInactive}
              />
            </Col>
            <Col span={6}>
              <FormInput
                type="select"
                label={t.VIEW_TAXRETURN_DISCREPANCY}
                name="discrepancy"
                required
                optionsList={[
                  { label: t.NO, value: 'no' },
                  { label: t.YES, value: 'yes' },
                ]}
                disabled={isFormInactive}
              />
            </Col>
            <Col span={6}>
              <FormInput
                type="select"
                label={t.VIEW_TAXRETURN_ADDITIONAL_PROCESS}
                name="additionalProcess"
                required
                optionsList={[
                  { label: t.NO, value: 'no' },
                  { label: t.YES, value: 'yes' },
                ]}
                onChange={(value: string) => {
                  if (value === 'yes') {
                    setAdditionalProcessValue(true);
                  } else {
                    setAdditionalProcessValue(false);
                    form.setFieldsValue({
                      additionalProcessInstructions: undefined,
                    });
                  }
                }}
                disabled={isFormInactive}
              />
            </Col>
          </Row>
          <Row gutter={20}>
            {additionalProcessValue && (
              <Col span={12}>
                <FormInput
                  required
                  label={t.VIEW_TAXRETURN_ADDITIONAL_PROCESS_INSTRUCTIONS}
                  name="additionalProcessInstructions"
                  disabled={isFormInactive}
                />
              </Col>
            )}
            <Col span={12}>
              <FormInput
                label={t.VIEW_TAXRETURN_COMMENTS_FOR_CLIENT_PORTAL}
                name="commentsForClientPortal"
                disabled={isFormInactive}
              />
            </Col>
          </Row>
          {threeColLayoutIsLoading ? (
            <Loading noText />
          ) : !threeColLayoutIsLoading && taxReturnResponseFailed ? (
            <div style={{ textAlign: 'center' }}>{t.DATA_COULD_NOT_BE_RETRIEVED}</div>
          ) : (
            <ThreeColumnLowerLayout
              leftColumnContent={
                <>
                  {submissionType === ClientService.TaxReturnSubmissionTypeEnum.TaxReturnFiling && (
                    <>
                      <FormInput
                        type="datepicker"
                        required
                        form={form}
                        name="dateFiled"
                        label={t.VIEW_TAXRETURN_DATE_FILED}
                        disabled={isFormInactive}
                      />
                      <FormInput
                        type="datepicker"
                        required
                        form={form}
                        name="reminderDate"
                        label={t.VIEW_TAXRETURN_REMINDER_DATE}
                        disabled={isFormInactive}
                      />
                      <FormInput
                        required
                        name="estimated"
                        label={`${t.VIEW_TAXRETURN_ESTIMATED} - ${taxReturnResponse?.info?.taxPeriod} ($)`}
                        disabled={isFormInactive}
                      />
                      <FormInput
                        required
                        type="select"
                        name="source"
                        label={t.VIEW_TAXRETURN_SOURCE}
                        //optionsList={sourceTypeOptions}
                        optionsList={(() => {
                          const resultOptions = getEnumTextValuesAsArray(ClientService.TaxReturnSubmissionSourceEnum);
                          return resultOptions.map((option, index) => {
                            return { label: option, value: index };
                          });
                        })()}
                        disabled={isFormInactive}
                      />
                    </>
                  )}
                  {submissionType === ClientService.TaxReturnSubmissionTypeEnum.NOA && (
                    <>
                      <FormInput
                        type="datepicker"
                        required
                        form={form}
                        name="assessmentDate"
                        label={t.VIEW_TAXRETURN_ASSESSMENT_DATE}
                        disabled={isFormInactive}
                      />
                      <FormInput
                        required
                        type="select"
                        name="status"
                        label={t.VIEW_TAXRETURN_STATUS}
                        optionsList={statusTypeOptions}
                        disabled={isFormInactive}
                      />
                      <FormInput
                        type="datepicker"
                        required
                        form={form}
                        name="dispositionDate"
                        label={t.VIEW_TAXRETURN_DISPOSITION_DATE}
                        disabled={isFormInactive}
                      />
                      <FormInput
                        required
                        type="select"
                        name="disposition"
                        label={t.VIEW_TAXRETURN_DISPOSITION}
                        optionsList={dispositionTypeOptions}
                        disabled={isFormInactive}
                      />
                    </>
                  )}
                </>
              }
              filesList={documentIds}
              rightColumnContent={<EmailContentDisplay title={t.TAXRETURN_TAX_RETURN_EMAIL} {...emailInfo} />}
            />
          )}
        </AppForm>
      </div>
    </Layout>
  );
};

export default ViewTaxReturnPage;
