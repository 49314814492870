import { useCallback, useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Row, Col, Tooltip } from 'antd';

import Divider from '../../../../components/Divider/Divider';
import { IconRed } from '../Icons';
import RequestForReview from '../../../../components/RequestForReview/RequestForReview';
import BundleFilesModal from '../BundleFilesModal/BundleFilesModal';
import DebtorVisibility from '../DebtorVisibility/DebtorVisibility';
import UploadModal from '../UploadModal/UploadModal';
import CopyFilesModal from '../CopyFilesModal/CopyFilesModal';
import EmailEditor from '../../EmailContent/EmailEditor/EmailEditor';
import ActionConfirmationModal from '../../../../modals/ActionConfirmationModal/ActionConfirmationModal';

import authService from '../../../../components/Auth/AuthorizeService';
import { ClientService } from '../../../../shared/api/ClientService';
import API from '../../../../utils/api';
import useModal from '../../../../hooks/useModal';
import useLocale from '../../../../hooks/useLocale';

import { downloadUtil } from '../utils';
import { FileActionEnum } from '../../../../components/AttachmentsTab';

import './DocumentsTable.scss';

interface IProps {
  isRecoveryMode?: boolean;
  documents?: ClientService.IDocumentDto[];
  selectedIds?: string[];
  onComplete?: () => void;
  canReceiveEmail?: boolean;
}

function HeaderToolbar({ isRecoveryMode, documents, selectedIds, onComplete, canReceiveEmail = true }: IProps) {
  const { showModal, closeModal } = useModal();
  const { applicationFileId } = useParams<{ applicationFileId?: string }>();
  const { t, getLocalizedDocumentName } = useLocale();

  const [selectedDocuments, setSelectedDocuments] = useState<ClientService.IDocumentDto[]>();

  const requestActionConfirmation = useCallback(
    async (title: string, message: string, cb: () => void) => {
      showModal(
        <ActionConfirmationModal
          title={title}
          message={message}
          okText={t.YES}
          cancelText={t.NO}
          onOk={() => {
            cb();
            closeModal();
          }}
          onCancel={closeModal}
        />
      );
    },
    [closeModal, showModal, t.NO, t.YES]
  );

  useEffect(() => {
    setSelectedDocuments(
      selectedIds
        ?.map((key) => documents?.find((document) => document?.id === key))
        .filter(Boolean) as ClientService.IDocumentDto[]
    );
  }, [documents, selectedIds]);

  const handleSendForReview = useCallback(() => {
    showModal(
      <RequestForReview
        data={selectedDocuments as ClientService.IDocumentDto[]}
        onOk={() => {
          closeModal();
          if (onComplete) onComplete();
        }}
        onCancel={closeModal}
      />
    );
  }, [selectedDocuments, showModal, closeModal, onComplete]);

  const handleDownload = useCallback(async () => {
    let token = await authService.getAccessToken();

    selectedIds?.forEach((documentId) => {
      if (documentId) {
        fetch(`${process.env.REACT_APP_BASE_URL}/api/client-service/documents/${documentId}/download`, {
          method: 'GET',
          headers: new Headers({
            Authorization: `Bearer ${token}`,
          }),
        })
          .then((response) => {
            return response.blob();
          })
          .then((blob) => {
            const document = documents?.find((item) => item.id === documentId);
            return downloadUtil(blob, getLocalizedDocumentName(document) as string);
          });
      }
    });
  }, [selectedIds, documents, getLocalizedDocumentName]);

  const handleDelete = useCallback(async () => {
    if (selectedIds && selectedIds?.length > 0) {
      await API.documentsDELETE([...selectedIds]);

      if (onComplete) onComplete();
    }
  }, [selectedIds, onComplete]);

  const handleBundle = useCallback(() => {
    showModal(
      <BundleFilesModal
        selectedIds={selectedIds}
        documents={documents}
        onOk={() => {
          closeModal();
          if (onComplete) onComplete();
        }}
        onCancel={closeModal}
        fileId={applicationFileId}
      />
    );
  }, [applicationFileId, closeModal, documents, onComplete, selectedIds, showModal]);

  const handleCopy = useCallback(() => {
    const sortedDocuments = selectedDocuments?.sort((a, b) => (a?.name || '')?.localeCompare(b?.name || ''));

    showModal(
      <CopyFilesModal documents={sortedDocuments} onOk={closeModal} onCancel={closeModal} fileId={applicationFileId} />
    );
  }, [applicationFileId, closeModal, selectedDocuments, showModal]);

  const handleUpdateVisibility = useCallback(
    (makeVisible: boolean) => {
      showModal(
        <DebtorVisibility
          data={selectedDocuments}
          checked={makeVisible}
          onOk={() => {
            closeModal();
            if (onComplete) onComplete();
          }}
          onCancel={closeModal}
        />
      );
    },
    [closeModal, selectedDocuments, onComplete, showModal]
  );

  const handleEdit = useCallback(() => {
    showModal(
      <UploadModal
        fileId={applicationFileId as string}
        documents={selectedDocuments}
        action={FileActionEnum.Edit}
        onOk={() => {
          closeModal();
          if (onComplete) onComplete();
        }}
        onCancel={closeModal}
      />
    );
  }, [applicationFileId, closeModal, selectedDocuments, onComplete, showModal]);

  const handleRestore = useCallback(async () => {
    if (selectedIds) {
      for (let id of selectedIds) {
        await API.restore(id);
      }

      if (onComplete) onComplete();
    }
  }, [onComplete, selectedIds]);

  const handleShowEditor = useCallback(() => {
    showModal(
      <EmailEditor
        onNext={() => {
          closeModal();
        }}
        onCancel={closeModal}
        fileId={applicationFileId as string}
        attachingDocuments={selectedDocuments as ClientService.DocumentDto[]}
      />
    );
  }, [showModal, closeModal, applicationFileId, selectedDocuments]);

  return isRecoveryMode ? (
    <Row>
      <Tooltip title={t.RECOVER_FILE}>
        <IconRed
          iconName="RevToggleKey"
          onClick={() =>
            requestActionConfirmation(t.CONFIRM_RECOVER, t.CONFIRM_RECOVER_SELECTED_FILES_MESSAGE, handleRestore)
          }
        />
      </Tooltip>
    </Row>
  ) : (
    <Row align="middle" gutter={10}>
      <Col>
        <Row align="middle" gutter={20}>
          <Col>
            <Tooltip title={t.EDIT}>
              <IconRed iconName="Edit" onClick={handleEdit} />
            </Tooltip>
          </Col>
          <Col>
            <Tooltip title={t.COPY}>
              <IconRed iconName="Copy" onClick={handleCopy} />
            </Tooltip>
          </Col>
          <Col>
            <Tooltip title={t.DELETE}>
              <IconRed
                iconName="Delete"
                onClick={() =>
                  requestActionConfirmation(t.CONFIRM_DELETE, t.CONFIRM_DELETE_SELECTED_FILE_MESSAGE, handleDelete)
                }
              />
            </Tooltip>
          </Col>
        </Row>
      </Col>

      <Col>
        <Divider type="vertical" />
      </Col>

      <Col>
        <Row align="middle" gutter={20}>
          <Col>
            <Tooltip title={t.BUNDLE}>
              <IconRed iconName="MapLayers" onClick={handleBundle} />
            </Tooltip>
          </Col>
          <Col>
            <Tooltip title={t.DOCUMENTS_DEBTOR_VISIBILITY}>
              <IconRed iconName="RedEye" onClick={() => handleUpdateVisibility(true)} />
            </Tooltip>
          </Col>
          <Col>
            <Tooltip title={t.DOCUMENTS_DEBTOR_VISIBILITY_DISABLE}>
              <IconRed iconName="Hide" onClick={() => handleUpdateVisibility(false)} />
            </Tooltip>
          </Col>
        </Row>
      </Col>

      <Col>
        <Divider type="vertical" />
      </Col>

      <Col>
        <Row align="middle" gutter={20}>
          <Col>
            <Tooltip title={t.DOWNLOAD}>
              <IconRed iconName="Download" onClick={handleDownload} />
            </Tooltip>
          </Col>
          {canReceiveEmail && (
            <Col>
              <Tooltip title={t.EMAIL}>
                <IconRed iconName="Mail" onClick={handleShowEditor} />
              </Tooltip>
            </Col>
          )}
          <Col>
            <Tooltip title={t.DOCUMENTS_SEND_FOR_REVIEW}>
              <IconRed iconName="Send" onClick={handleSendForReview} />
            </Tooltip>
          </Col>
        </Row>
      </Col>
    </Row>
  );
}

export default HeaderToolbar;
