import { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Tabs } from 'antd';

import DocumentsTable from './DocumentsTable/DocumentsTable';
import VerificationTab from './VerificationsTab/VerificationsTab';

import useLocale from '../../../hooks/useLocale';
import { eventBus } from '../../../utils/eventBus';
import { VERIFICATION_CHANGE_EVENT } from '../../../constants/eventBus';
import API from '../../../utils/api';

import './DocumentsContent.scss';

export const DOCUMENTS_TAB = 'documents-tab';
export const VERIFICATIONS_TAB = 'verifications-tab';
export const RECOVER_DELETED_TAB = 'recover-deleted-tab';

interface IProps {
  defaultActiveKey?: string;
  initialDocumentId?: string;
  updateDocumentsTitle?: () => void;
}

function DocumentsContent({ defaultActiveKey, initialDocumentId, updateDocumentsTitle }: IProps): JSX.Element {
  const { t } = useLocale();
  const { applicationFileId } = useParams<{ applicationFileId: string }>();
  const { TabPane } = Tabs;

  const [activeTab, setActiveTab] = useState<string>(defaultActiveKey || DOCUMENTS_TAB);
  const [pendingCount, setPendingCount] = useState<number>();

  const requestPendingCount = useCallback(async () => {
    if (applicationFileId) {
      const response = await API.pendingVerificationCount(applicationFileId);
      setPendingCount(response);
    }
  }, [applicationFileId]);

  useEffect(() => {
    requestPendingCount();
  }, [requestPendingCount]);

  useEffect(() => {
    eventBus.on(VERIFICATION_CHANGE_EVENT, requestPendingCount);

    return () => {
      eventBus.remove(VERIFICATION_CHANGE_EVENT, requestPendingCount);
    };
  }, [requestPendingCount]);

  return (
    <div className="DocumentsContent">
      <Tabs
        className="DocumentsContent__tabs"
        activeKey={activeTab}
        onChange={setActiveTab}
        type="card"
        destroyInactiveTabPane
      >
        <TabPane tab={t.DOCUMENTS_ALL} key={DOCUMENTS_TAB}>
          <DocumentsTable initialDocumentId={initialDocumentId} updateDocumentsTitle={updateDocumentsTitle} />
        </TabPane>
        <TabPane tab={`${t.DOCUMENTS_REVIEW_APPROVAL} (${pendingCount || 0})`} key={VERIFICATIONS_TAB}>
          <VerificationTab initialDocumentId={initialDocumentId} />
        </TabPane>
        <TabPane tab={t.DOCUMENTS_RECOVER_DELETED} key={RECOVER_DELETED_TAB}>
          <DocumentsTable isDeleted />
        </TabPane>
      </Tabs>
    </div>
  );
}

export default DocumentsContent;
