import { useCallback, useEffect, useMemo, useState } from 'react';
import { AutoComplete, ConfigProvider, Input } from 'antd';
import { debounce } from '../../utils/debounce';
import SelectSuffixIcon from '../../components/SelectSuffixIcon/SelectSuffixIcon';

import styles from './LookAheadField.module.scss';

export interface IOption {
  value?: string;
  label?: string;
  [key: string]: any;
}

interface LookAheadFieldProps {
  size?: 'small' | 'middle' | 'large';
  displayValue?: IOption | string;
  initialOptionValue?: string;
  charsToWaitBeforeSearch?: number;
  debounceWait?: number;
  disabled?: boolean;
  apiSearch?: (searchText: string) => Promise<any>;
  apiSetOptionsData?: (response: any, setOptionsData: React.Dispatch<React.SetStateAction<IOption[]>>) => void;
  apiGetSourceData?: () => Promise<any>;
  apiSetSourceData?: (response: any, setSourceData: React.Dispatch<React.SetStateAction<IOption[]>>) => void;
  sourceData?: IOption[] | [];
  onSearch?: (searchText: string) => void;
  onSelect?: (value: string, option: IOption) => void;
  parentUpdater?: (value: string | undefined, option: IOption) => void; // | TParentUpdaterOptions;
  placeholder?: string;
  updateParentOnSearch?: boolean;
  className?: string;
  style?: React.CSSProperties;
  showCaretDownIcon?: boolean;
}

const LookAheadField = ({
  size = 'large',
  displayValue,
  initialOptionValue,
  charsToWaitBeforeSearch = 3,
  debounceWait = 200,
  disabled,
  apiSearch,
  apiSetOptionsData,
  apiGetSourceData,
  apiSetSourceData,
  sourceData,
  onSelect,
  onSearch,
  parentUpdater,
  updateParentOnSearch = true,
  placeholder = '',
  className,
  style,
  showCaretDownIcon = false,
}: LookAheadFieldProps): JSX.Element => {
  const [options, setOptions] = useState<IOption[]>([]);
  const [dataSource, setDataSource] = useState<IOption[]>([]);
  const [fieldDisplayValue, setFieldDisplayValue] = useState<IOption | string | undefined>();
  const [initialOptionId, setInitialOptionId] = useState<string | undefined>(initialOptionValue);

  useEffect(() => {
    if (initialOptionId && apiSearch) {
      apiSearch('')
        .then((results) => {
          const option = results.find((item: any) => item.id === initialOptionId);
          if (option) {
            setFieldDisplayValue(option?.name);
          }
        })
        .catch((error) => {
          console.error(error.message);
        });
    }
  }, []);

  useEffect(() => {
    if (dataSource.length > 0) return;
    if (sourceData) {
      setDataSource(sourceData);
    } else if (apiGetSourceData) {
      apiGetSourceData()
        .then((response) => {
          if (apiSetSourceData) {
            apiSetSourceData(response, setDataSource);
          } else {
            setDataSource(response);
          }
        })
        .catch((error) => {
          console.error(error.message);
        });
    }
  }, [dataSource, sourceData, apiGetSourceData, apiSetSourceData]);

  useEffect(() => {
    if (displayValue) {
      setFieldDisplayValue(displayValue);
    }
  }, [displayValue]);

  const currentDisplayValue: string | undefined = useMemo(() => {
    if (typeof fieldDisplayValue === 'string') {
      return fieldDisplayValue;
    }
    if (fieldDisplayValue?.label) {
      return fieldDisplayValue.label;
    }
    if (fieldDisplayValue?.value) {
      return fieldDisplayValue.value;
    }
    return undefined;
  }, [fieldDisplayValue]);

  const updateParentStateValue = useCallback(
    (value: string | undefined, option?: IOption) => {
      if (parentUpdater) {
        const currentOption = option ? option : { label: value, value: value };
        parentUpdater(value, currentOption);
      }
    },
    [parentUpdater]
  );

  const debouncedSearch = useCallback(
    debounce((searchText: string) => {
      if (dataSource.length > 0) {
        setOptions(
          dataSource.filter((item) => {
            if (item.label || item.value) {
              const valueToCompare = item.label ? item.label : item.value;
              return valueToCompare?.toLowerCase().includes(searchText.toLowerCase());
            }
            return false;
          })
        );
      } else if (apiSearch) {
        apiSearch(searchText)
          .then((response) => {
            if (apiSetOptionsData) {
              apiSetOptionsData(response, setOptions);
            } else {
              setOptions(response);
            }
          })
          .catch((error) => {
            console.error(error.message);
          });
      }
    }, debounceWait),
    [debounce]
  );

  const handleSearch = useCallback(
    (searchText: string) => {
      debouncedSearch(searchText);
    },
    [debouncedSearch]
  );

  useEffect(() => {
    if (updateParentOnSearch && currentDisplayValue) {
      updateParentStateValue(currentDisplayValue);
    }
  }, [updateParentOnSearch, updateParentStateValue, currentDisplayValue]);

  const handleOnSelect = useCallback(
    (value: string, option: any) => {
      setFieldDisplayValue(option);
      updateParentStateValue(value, option);
      setOptions([]);
    },
    [updateParentStateValue]
  );

  return (
    <ConfigProvider
      getPopupContainer={(node?: HTMLElement) => {
        if (node) {
          return node.parentElement as HTMLElement;
        }
        return document.body;
      }}
    >
      <div className={`${styles.LookAhead} ${className ? className : ''}`} style={style}>
        <AutoComplete
          value={currentDisplayValue}
          disabled={disabled}
          placeholder={!showCaretDownIcon && placeholder}
          size={size}
          options={options}
          onSearch={(searchText) => {
            setFieldDisplayValue({ value: searchText });
            if (searchText.length < charsToWaitBeforeSearch) {
              setOptions([]);
            } else {
              handleSearch(searchText);
            }
            if (onSearch) {
              onSearch(searchText);
            }
          }}
          onFocus={() => {
            if (charsToWaitBeforeSearch === 0) {
              handleSearch(currentDisplayValue ?? '');
            }
          }}
          onSelect={(value: string, option: IOption) => {
            handleOnSelect(value, option);
            if (onSelect) {
              onSelect(value, option);
            }
          }}
        >
          {showCaretDownIcon && (
            <Input
              size="large"
              placeholder={placeholder}
              suffix={<SelectSuffixIcon className={styles.Caret_down_styles} />}
            />
          )}
        </AutoComplete>
      </div>
    </ConfigProvider>
  );
};

export default LookAheadField;
