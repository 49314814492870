import { useCallback, useEffect, useState } from 'react';
import { Col, Row, Form, Select, Radio, Divider, Spin } from 'antd';
import { useParams } from 'react-router-dom';
import { useQuery } from 'react-query';
import moment from 'moment';

import DataItem from '../../../../../components/DataItem/DataItem';
import SectionContainer from '../../../../../components/SectionContainer/SectionContainer';
import UploadDataItem from '../../../../../components/UploadDataItem/UploadDataItem';
import RequestForReview from '../../../../../components/RequestForReview/RequestForReview';
import OppositionLetterModal from './OppositionLetterModal/OppositionLetterModal';
import ReviewOppositionResponseModal from './ReviewOppositionResponseModal/ReviewOppositionResponseModal';
import RequestCourtAppearanceModal from './RequestCourtAppearanceModal/RequestCourtAppearanceModal';
import ResponseCourtAppearanceModal from './ResponseCourtAppearanceModal/ResponseCourtAppearanceModal';
import FilingDateModal from '../../../../../modals/FilingDateModal/FilingDateModal';
import UploadAndShareModal from '../../../../../modals/UploadAndShareModal/UploadAndShareModal';
import DischargeStatusForm from './components/DischargeStatusForm';
import ChequeRequisitionHistoryModal from './ChequeRequisitionHistoryModal/ChequeRequisitionHistoryModal';
import Button from '../../../../../components/Button/Button';
import SelectSuffixIcon from '../../../../../components/SelectSuffixIcon/SelectSuffixIcon';

import useLocale from '../../../../../hooks/useLocale';
import useModal from '../../../../../hooks/useModal';

import {
  DISCHARGE_FOLDER_CODE,
  DISCHARGE_CHECKLIST170_PREFIX,
  DISCHARGE_REPORT170_PREFIX,
  DISCHARGE_CERTIFICATE_PREFIX,
  DISCHARGE_FORM80_PREFIX,
  DISCHARGE_FORM63_PREFIX,
  DISCHARGE_SIGNED_AGREEMENT_PREFIX,
  DISCHARGE_CHEQUE_REQUISITION_PREFIX,
  DISCHARGE_RECOMMENDATION_LETTER_PREFIX,
  DISCHARGE_CONDITIONAL_ORDER_PREFIX,
  PENDING_CODE,
} from './constants';
import {
  convertCourtDischargeStatusEnumToReadable,
  convertDischargeCourtAppearanceNotificationStatusEnumToReadable,
  convertDischargeComplianceNotificationStatusEnumToReadable,
} from './utils';
import genericMessage from '../../../../../utils/genericMessage';
import {
  MEDIATION_MODES_QUERY,
  MEDIATION_STATUSES_QUERY,
  USE_QUERY_OPTIONS,
} from '../../../../../constants/reactQuery';
import { DISCHARGE_UPDATE_EVENT } from '../../../../../constants/eventBus';
import { eventBus } from '../../../../../utils/eventBus';
import { ClientService } from '../../../../../shared/api/ClientService';
import API from '../../../../../utils/api';

import './Discharge.scss';
import { DATE_FORMAT2 } from '../../../../../constants/common';

interface IProps {
  disableAllFields?: boolean;
}

const Discharge = ({ disableAllFields }: IProps) => {
  const { Option } = Select;
  const { t } = useLocale();

  const { showModal, closeModal } = useModal();
  const { applicationFileId } = useParams<{ applicationFileId: string }>();

  const [form] = Form.useForm<ClientService.FileDischargeFormUpdateDto>();
  const [initial, setInitial] = useState<ClientService.FileDischargeFormDto>();
  const [loading, setLoading] = useState<boolean>(false);

  const { data: mediationModes } = useQuery([MEDIATION_MODES_QUERY], () => API.mediationOfMode(), USE_QUERY_OPTIONS);
  const { data: mediationStatuses } = useQuery(
    [MEDIATION_STATUSES_QUERY],
    () => API.mediationStatus(),
    USE_QUERY_OPTIONS
  );

  const PENDING_READONLY_LOOKUP_OPTION = {
    name: t.PENDING,
    code: PENDING_CODE,
    enumValue: 0,
  };

  const requestFileDischargeForm = useCallback(
    async (applicationFileId: string) => {
      setLoading(true);
      const response = await API.fileDischargeFormGET(applicationFileId).catch(() => setLoading(false));
      setLoading(false);

      if (response) {
        form.setFieldsValue({
          hasPossibleMediation: response?.hasPossibleMediation,
          hasInitiateCourtDischarge: response?.hasInitiateCourtDischarge,
          mediationStatus: response?.mediationStatus || 0,
          modeOfMediation: response?.modeOfMediation,
        });
        setInitial(response);
      }
    },
    [form]
  );

  const requestUpdateFileDischargeForm = useCallback(
    async (values) => {
      setLoading(true);
      const response = await API.fileDischargeFormPUT(applicationFileId as string, {
        id: initial?.id as string,
        ...values,
      }).catch(() => setLoading(false));
      setLoading(false);

      if (response?.result === ClientService.Result.Successful) {
        eventBus.dispatch(DISCHARGE_UPDATE_EVENT);
      }
    },
    [applicationFileId, initial?.id]
  );

  const handleFilingDateChange = useCallback(
    async (documentPrefixCode: string, title?: string, filingDate?: moment.Moment) => {
      showModal(
        <FilingDateModal
          title={title}
          filingDate={filingDate}
          onOk={async (date) => {
            const response = await API.filingDate(
              applicationFileId as string,
              {
                id: initial?.id,
                date,
                documentPrefixCode,
              } as ClientService.DischargeFilingDateUpdateDto
            ).catch(() => genericMessage.error({}, t.FILING_DATE_UPDATE_ERROR));

            if (response?.result === ClientService.Result.Successful) {
              genericMessage.success(t.FILING_DATE_UPDATED);
              eventBus.dispatch(DISCHARGE_UPDATE_EVENT);
              closeModal();
            }
          }}
          onCancel={closeModal}
        />
      );
    },
    [applicationFileId, closeModal, initial?.id, showModal, t.FILING_DATE_UPDATED, t.FILING_DATE_UPDATE_ERROR]
  );

  const handleUploadForReview = useCallback(
    async (prefixCode?: string, title?: string) => {
      showModal(
        <RequestForReview
          title={title}
          applicationFileId={applicationFileId}
          supportingFolderCode={DISCHARGE_FOLDER_CODE}
          prefixCodes={prefixCode ? [prefixCode] : undefined}
          newSupportingFileRowLayoutProps={{
            disabledPrefixInput: true,
            preselectedPrefixInput: true,
            disabledFoldersInput: true,
          }}
          isFlatLayout
          flatLayoutProps={{ hasExistingFiles: true, label: t.UPLOAD }}
          onOk={() => {
            closeModal();
            eventBus.dispatch(DISCHARGE_UPDATE_EVENT);
          }}
          onCancel={closeModal}
        />
      );
    },
    [showModal, applicationFileId, closeModal, t]
  );

  const handleUploadAndShare = useCallback(
    async (prefixCode?: string, title?: string, disabledPrefixInput?: boolean, preselectedPrefixInput?: boolean) => {
      showModal(
        <UploadAndShareModal
          title={title || t.UPLOAD}
          hasShareOption
          hasFilingDateField={false}
          applicationFileId={applicationFileId}
          supportingFolderCode={DISCHARGE_FOLDER_CODE}
          prefixCodes={prefixCode ? [prefixCode] : undefined}
          flatLayoutProps={{
            hasExistingFiles: false,
            label: t.UPLOAD,
          }}
          newSupportingFileRowLayoutProps={{ disabledPrefixInput, preselectedPrefixInput }}
          onOk={() => {
            closeModal();
            eventBus.dispatch(DISCHARGE_UPDATE_EVENT);
          }}
          onCancel={closeModal}
        />
      );
    },
    [applicationFileId, closeModal, showModal, t]
  );

  const handleViewHistory = useCallback(() => {
    showModal(<ChequeRequisitionHistoryModal onClose={closeModal} applicationFileId={applicationFileId} />);
  }, [closeModal, showModal, applicationFileId]);

  const handleOppositionLetter = useCallback(async () => {
    showModal(
      <OppositionLetterModal
        applicationFileId={applicationFileId}
        title={t.DISCHARGE_OPPOSITION_LETTER}
        onOk={() => {
          closeModal();
          eventBus.dispatch(DISCHARGE_UPDATE_EVENT);
        }}
        onCancel={closeModal}
      />
    );
  }, [showModal, applicationFileId, t.DISCHARGE_OPPOSITION_LETTER, closeModal]);

  const handleReviewOppositionResponse = useCallback(async () => {
    showModal(
      <ReviewOppositionResponseModal
        applicationFileId={applicationFileId as string}
        onOk={closeModal}
        onCancel={closeModal}
      />
    );
  }, [applicationFileId, closeModal, showModal]);

  const handleRequestCourtAppearance = useCallback(async () => {
    showModal(
      <RequestCourtAppearanceModal
        onCancel={closeModal}
        applicationFileId={applicationFileId as string}
        dischargeId={initial?.id as string}
        onOk={() => {
          closeModal();
          eventBus.dispatch(DISCHARGE_UPDATE_EVENT);
        }}
      />
    );
  }, [showModal, closeModal, applicationFileId, initial?.id]);

  const handleResponseCourtAppearance = useCallback(async () => {
    showModal(
      <ResponseCourtAppearanceModal
        applicationFileId={applicationFileId as string}
        dischargeId={initial?.id as string}
        onOk={() => {
          closeModal();
          eventBus.dispatch(DISCHARGE_UPDATE_EVENT);
        }}
        onCancel={closeModal}
      />
    );
  }, [showModal, closeModal, applicationFileId, initial?.id]);

  const handleCourtAppearance = useCallback(() => {
    switch (initial?.courtAppearanceNotificationStatus) {
      case ClientService.DischargeCourtAppearanceNotificationStatusEnum.Pending:
        return handleRequestCourtAppearance();
      case ClientService.DischargeCourtAppearanceNotificationStatusEnum.Requested:
        return handleResponseCourtAppearance();
      default:
        return;
    }
  }, [handleRequestCourtAppearance, handleResponseCourtAppearance, initial?.courtAppearanceNotificationStatus]);

  const requestFileDischargeFormByFileId = useCallback(() => {
    if (applicationFileId) requestFileDischargeForm(applicationFileId);
  }, [applicationFileId, requestFileDischargeForm]);

  useEffect(() => {
    if (applicationFileId) requestFileDischargeForm(applicationFileId);
  }, [applicationFileId, requestFileDischargeForm]);

  useEffect(() => {
    eventBus.on(DISCHARGE_UPDATE_EVENT, requestFileDischargeFormByFileId);

    return () => {
      eventBus.remove(DISCHARGE_UPDATE_EVENT, requestFileDischargeFormByFileId);
    };
  }, [requestFileDischargeFormByFileId]);

  return (
    <div className="Discharge">
      <DischargeStatusForm disableAllFields={disableAllFields} />

      <Spin spinning={loading}>
        <Form layout="vertical" form={form} onFinish={requestUpdateFileDischargeForm}>
          <SectionContainer title={t.DISCHARGE_170_PROCESS}>
            <Row gutter={40}>
              <DataItem
                colSpan={8}
                label={t.DISCHARGE_30_DAYS_NOTICE}
                layout="vertical"
                value={{ value: initial?.thirtyDaysNotice ? t.YES : t.NO }}
              />
              <DataItem
                colSpan={16}
                label={t.DISCHARGE_TYPE}
                layout="vertical"
                value={{ value: initial?.dischargeType || '-' }}
              />
            </Row>
            <Divider />

            <Row gutter={40}>
              <UploadDataItem
                label={t.DISCHARGE_170_CHECKLIST}
                buttonProps={{ onClick: () => handleUploadForReview(DISCHARGE_CHECKLIST170_PREFIX) }}
                link={initial?.is170ChecklistUploaded && t.UPLOADED}
                disabled={disableAllFields}
              />

              <UploadDataItem
                type={['extra-switch']}
                label={t.DISCHARGE_170_REPORT}
                buttonProps={{
                  onClick: () =>
                    !initial?.is170ReportUploaded || initial?.filingDate170Report
                      ? handleUploadForReview(DISCHARGE_REPORT170_PREFIX)
                      : handleFilingDateChange(
                          DISCHARGE_REPORT170_PREFIX,
                          t.DISCHARGE_170_REPORT_FILING,
                          initial?.filingDate170Report
                        ),
                }}
                link={
                  initial?.filingDate170Report
                    ? moment(initial?.filingDate170Report).format(DATE_FORMAT2)
                    : !initial?.is170ReportUploaded
                    ? t.UPLOAD
                    : t.UPLOADED
                }
                switchProps={{
                  checked: initial?.is170ReportShared,
                  disabled: true,
                }}
                disabled={disableAllFields}
              />
              <UploadDataItem
                type={['extra-switch']}
                label={t.DISCHARGE_DISCHARGE_CERT}
                buttonProps={{
                  onClick: () => handleUploadAndShare(DISCHARGE_CERTIFICATE_PREFIX, t.DISCHARGE_DISCHARGE_CERTIFICATE),
                }}
                link={initial?.isDischargeCertificateUploaded && t.UPLOADED}
                switchProps={{
                  checked: initial?.isDischargeCertificateShared,
                  disabled: true,
                }}
                disabled={!initial?.dischargeType?.includes('Automatic') || disableAllFields}
              />
            </Row>
          </SectionContainer>

          <SectionContainer title={t.DISCHARGE_OPPOSITION}>
            <Row gutter={40}>
              <UploadDataItem
                type={['extra-switch', 'extra-review']}
                label={t.DISCHARGE_OPPOSITION_LETTER}
                buttonProps={{ onClick: handleOppositionLetter }}
                link={
                  (initial?.isOppositionLetterCompleted && t.COMPLETED) ||
                  (initial?.isOppositionLetterUploaded && t.UPLOADED)
                }
                switchProps={{
                  checked: initial?.isOppositionLetterShared,
                  disabled: true,
                }}
                reviewIconProps={{
                  onClick: handleReviewOppositionResponse,
                }}
                reviewIconVisible={initial?.isOppositionLetterUploaded}
                disabled={disableAllFields}
              />
              <UploadDataItem
                type={['extra-switch']}
                label={t.DISCHARGE_FORM_80}
                buttonProps={{
                  onClick: () => handleUploadAndShare(DISCHARGE_FORM80_PREFIX, t.DISCHARGE_FORM_80),
                }}
                link={initial?.isForm80Uploaded && t.UPLOADED}
                switchProps={{
                  checked: initial?.isForm80Shared,
                  disabled: true,
                }}
                disabled={disableAllFields}
              />
            </Row>
            <Divider />

            <Row gutter={40} align="middle">
              <Col span={16}>
                <Form.Item noStyle>
                  <DataItem.Label label={t.DISCHARGE_POSSIBLE_MEDIATION} />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item name="hasPossibleMediation" noStyle>
                  <Radio.Group size="large" buttonStyle="solid" optionType="button" disabled={disableAllFields}>
                    <Radio.Button value={true}>{t.YES}</Radio.Button>
                    <Radio.Button value={false}>{t.NO}</Radio.Button>
                  </Radio.Group>
                </Form.Item>
              </Col>

              <Divider style={{ marginTop: 12, marginBottom: 12 }} />

              <Col span={16}>
                <Form.Item noStyle>
                  <DataItem.Label label={t.DISCHARGE_INITIAL_COURT} />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item name="hasInitiateCourtDischarge" noStyle>
                  <Radio.Group size="large" buttonStyle="solid" optionType="button" disabled={disableAllFields}>
                    <Radio.Button value={true}>YES</Radio.Button>
                    <Radio.Button value={false}>NO</Radio.Button>
                  </Radio.Group>
                </Form.Item>
              </Col>
            </Row>
          </SectionContainer>

          <SectionContainer title={t.DISCHARGE_MEDIATION}>
            <Row gutter={40}>
              <Col span={8}>
                <Form.Item label={t.DISCHARGE_MEDIATION_STATUS} name="mediationStatus" initialValue={0}>
                  <Select
                    placeholder={t.SELECT}
                    size="large"
                    suffixIcon={<SelectSuffixIcon />}
                    disabled={disableAllFields}
                  >
                    {[...(mediationStatuses || []), PENDING_READONLY_LOOKUP_OPTION]?.map((item) => (
                      <Option key={item?.code} value={item?.enumValue} disabled={item.code === PENDING_CODE}>
                        {item?.name}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item label={t.DISCHARGE_MEDIATION_MODE} name="modeOfMediation">
                  <Select
                    placeholder={t.SELECT}
                    size="large"
                    suffixIcon={<SelectSuffixIcon />}
                    disabled={disableAllFields}
                  >
                    {mediationModes?.map((item) => (
                      <Option key={item?.code} value={item?.enumValue}>
                        {item?.name}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>

            <Divider />

            <Row gutter={40}>
              <UploadDataItem
                type={['extra-switch']}
                label={t.DISCHARGE_FORM_63}
                disabled={
                  initial?.mediationStatus !== ClientService.DischargeMediationStatusEnum.Successful || disableAllFields
                }
                buttonProps={{
                  onClick: () => handleUploadAndShare(DISCHARGE_FORM63_PREFIX, t.DISCHARGE_FORM_63),
                }}
                link={initial?.isForm63Uploaded && t.UPLOADED}
                switchProps={{
                  checked: initial?.isForm63Shared,
                  disabled: true,
                }}
              />
              <UploadDataItem
                type={['extra-switch']}
                label={t.DISCHARGE_SIGNED_AGREEMENT}
                disabled={
                  initial?.mediationStatus !== ClientService.DischargeMediationStatusEnum.Successful || disableAllFields
                }
                buttonProps={{
                  onClick: () => handleUploadAndShare(DISCHARGE_SIGNED_AGREEMENT_PREFIX, t.DISCHARGE_SIGNED_AGREEMENT),
                }}
                link={initial?.isSignedAgreementUploaded && t.UPLOADED}
                switchProps={{
                  checked: initial?.isSignedAgreementShared,
                  disabled: true,
                }}
              />
            </Row>
          </SectionContainer>

          <SectionContainer title={t.DISCHARGE_COURT}>
            <Row gutter={40}>
              <DataItem
                colSpan={8}
                label={t.DISCHARGE_COURT_DISCHARGE}
                layout="vertical"
                value={convertCourtDischargeStatusEnumToReadable(initial?.courtDischargeStatus)}
              />
            </Row>
            <Divider />

            <Row gutter={40}>
              <UploadDataItem
                type={['extra-icon']}
                label={t.DISCHARGE_CHEQUE_REQUISITION}
                buttonProps={{
                  onClick: () =>
                    handleUploadForReview(DISCHARGE_CHEQUE_REQUISITION_PREFIX, t.DISCHARGE_CHEQUE_REQUISITION),
                }}
                link={initial?.isChequeRequisitionUploaded && t.UPLOADED}
                iconProps={{
                  onClick: handleViewHistory,
                }}
                disabled={disableAllFields}
              />
              <UploadDataItem
                type={['extra-switch']}
                label={t.DISCHARGE_RECOMMEND_LETTER}
                buttonProps={{
                  onClick: () =>
                    handleUploadAndShare(DISCHARGE_RECOMMENDATION_LETTER_PREFIX, t.DISCHARGE_RECOMMENDATION_LETTER),
                }}
                link={initial?.isRecommendationLetterUploaded && t.UPLOADED}
                switchProps={{
                  checked: initial?.isRecommendationLetterShared,
                  disabled: true,
                }}
                disabled={disableAllFields}
              />
              <UploadDataItem
                type={['extra-switch']}
                label={t.DISCHARGE_COURT_ORDER}
                buttonProps={{
                  onClick: () => handleUploadAndShare(undefined, t.DISCHARGE_COURT_ORDER, false, false),
                }}
                link={initial?.isConditionalOrderUploaded && t.UPLOADED}
                switchProps={{
                  checked: initial?.isConditionalOrderShared,
                  disabled: true,
                }}
                disabled={disableAllFields}
              />
              <UploadDataItem
                type={['extra-icon']}
                label={t.DISCHARGE_NOTIFY_COURT_APP}
                link={convertDischargeCourtAppearanceNotificationStatusEnumToReadable(
                  initial?.courtAppearanceNotificationStatus
                )}
                buttonProps={{
                  onClick: handleCourtAppearance,
                  style: {
                    cursor:
                      initial?.courtAppearanceNotificationStatus ===
                        ClientService.DischargeCourtAppearanceNotificationStatusEnum.Rejected ||
                      initial?.courtAppearanceNotificationStatus ===
                        ClientService.DischargeCourtAppearanceNotificationStatusEnum.Accepted
                        ? 'auto'
                        : 'pointer',
                  },
                }}
                iconProps={
                  initial?.courtAppearanceNotificationStatus !==
                  ClientService.DischargeCourtAppearanceNotificationStatusEnum.Pending
                    ? {
                        onClick: handleRequestCourtAppearance,
                        iconName: 'Add',
                        className: 'Discharge__add-icon',
                      }
                    : undefined
                }
                disabled={disableAllFields}
              />
              <UploadDataItem
                label={t.DISCHARGE_COMPLIANCE_NOTIF}
                link={convertDischargeComplianceNotificationStatusEnumToReadable(initial?.complianceNotificationStatus)}
                buttonProps={{
                  style: { cursor: 'auto' },
                }}
                disabled={disableAllFields}
              />
            </Row>
          </SectionContainer>

          <Row justify="end">
            <Col>
              <Form.Item>
                <Button kind="primary" htmlType="submit" disabled={disableAllFields}>
                  {t.SAVE}
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Spin>
    </div>
  );
};

export default Discharge;
