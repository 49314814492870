import { ClientService } from '../../../../../shared/api/ClientService';
import { IHistorySearchCriteria } from './types';

export const argumentifyHistorySearchParams = ({
  fileId,
  documentFolderEnum,
  documentPrefix,
  verificationRoleType,
  isApprovedOrPending,
  sorting,
  skipCount,
  maxResultCount,
}: IHistorySearchCriteria) =>
  [
    fileId,
    documentFolderEnum,
    documentPrefix,
    verificationRoleType,
    isApprovedOrPending,
    sorting,
    skipCount,
    maxResultCount,
  ] as const;

export const convertCourtDischargeStatusEnumToReadable = (courtEnum?: ClientService.CourtDischargeStatusEnum) => {
  switch (courtEnum) {
    case ClientService.CourtDischargeStatusEnum.Monitor:
      return 'Monitor';
    case ClientService.CourtDischargeStatusEnum.MediationFailed:
      return 'Mediation Failed';
    case ClientService.CourtDischargeStatusEnum.Opposition:
      return 'Opposition';
    default:
      return '-';
  }
};

export const convertDischargeCourtAppearanceNotificationStatusEnumToReadable = (
  courtAppearanceEnum?: ClientService.DischargeCourtAppearanceNotificationStatusEnum
) => {
  switch (courtAppearanceEnum) {
    case ClientService.DischargeCourtAppearanceNotificationStatusEnum.Pending:
      return 'Pending';
    case ClientService.DischargeCourtAppearanceNotificationStatusEnum.Requested:
      return 'Requested';
    case ClientService.DischargeCourtAppearanceNotificationStatusEnum.Accepted:
      return 'Accepted';
    case ClientService.DischargeCourtAppearanceNotificationStatusEnum.Rejected:
      return 'Rejected';
    default:
      return '-';
  }
};

export const convertDischargeComplianceNotificationStatusEnumToReadable = (
  complianceEnum?: ClientService.DischargeComplianceNotificationStatusEnum
) => {
  switch (complianceEnum) {
    case ClientService.DischargeComplianceNotificationStatusEnum.Pending:
      return 'Pending';
    case ClientService.DischargeComplianceNotificationStatusEnum.Complete:
      return 'Complete';

    default:
      return '-';
  }
};
