import React, { useCallback, useEffect, useState, ReactElement, useMemo } from 'react';
import { usePrevious } from '@fluentui/react-hooks';
import { Row, Col, Divider } from 'antd';
import moment from 'moment';
import FormInput from '../../../../components/Forms/FormInput/FormInput';
import DatePicker, { ValueSingle } from '../../../../components/DatePicker/DatePicker';
import { FormInstance } from 'antd/es/form/Form';
import Button from '../../../../components/Button/Button';
import ButtonContainer from '../../../../components/ButtonContainer/ButtonContainer';
import ErrorsContainer, { IErrorsMsgAndType } from '../../../../components/ErrorsContainer/ErrorsContainer';
import Loading from '../../../../components/Loading/Loading';
import { ClientService } from '../../../../shared/api/ClientService';
import API from '../../../../utils/api';
// import Label from '../../../../components/Forms/Label/Label';
import LookAheadField from '../../../../components/LookAheadField/LookAheadField';
import useLocale from '../../../../hooks/useLocale';
import useModal from '../../../../hooks/useModal';
import ActionConfirmationModal from '../../../../modals/ActionConfirmationModal/ActionConfirmationModal';
import useErrorHandling from '../../../../hooks/useErrorHandling';
import {
  getOnlyNumbers,
  getUndefinedOrSelf,
  hasOnlyNumbers,
  isMomentWithSameDate,
  isString12CharactersOrLess,
} from '../../../../utils/helpers';
import { IGenericObject } from '../../../../types/IGenericObject';

import styles from './SearchAscend.module.scss';
import { DATE_FORMAT2 } from '../../../../constants/common';
export const stylesSearchAscend = styles;
export interface IAscendFormValues {
  ascendEstateNumber?: string;
  ascendSin?: string;
  ascendDebtorName?: string;
  ascendDateOfInsolvency?: moment.Moment | undefined;
  [key: string]: any;
}

const mainAscendFields = ['ascendEstateNumber', 'ascendSin', 'ascendDebtorName', 'ascendDateOfInsolvency'];
// Child variable field components should have their fields listed in string array like above
// That array of fields will then be passed to the setVariableFieldsListInParent() function passed to the child

interface IVariableFieldValues {
  [key: string]: any;
}

export interface IAscendVariableFieldsBlockProps {
  form?: FormInstance<any>;
  externalStateSetter?: React.Dispatch<React.SetStateAction<any>>;
  variableFieldsFromParent?: IVariableFieldValues;
  setVariableFieldsListInParent?: React.Dispatch<React.SetStateAction<string[] | undefined>>;
  updateAscendSearchForm?: React.Dispatch<React.SetStateAction<IAscendFormValues>>;
  isMatched?: boolean;
  searchResponse?: any;
}

interface IConfirmAPIObject {
  apiCall: () => Promise<any>;
  requestBody: IGenericObject;
}

interface SearchAscendProps {
  form: FormInstance<any>;
  externalStateSetter?: React.Dispatch<React.SetStateAction<any>>;
  initialValues?: IAscendFormValues;
  // matchedId?: string;
  apiSearch?: (ascendSearchFormValues: IAscendFormValues) => Promise<any>;
  apiSetData?: (searchResponse: any, setState: React.Dispatch<React.SetStateAction<IAscendFormValues>>) => void; // allows you to manipulate response data before it is placed in state
  variableFieldsComponent?: ReactElement<IAscendVariableFieldsBlockProps>;
  numberOfVariableFieldsForSearch?: number;
  showVariableFieldsWhen?: 'on-load' | 'on-match';
  apiConfirm?: (ascendSearchFormValues: IAscendFormValues, searchResponse?: any) => IConfirmAPIObject;
  confirmLinkMessage?: string;
  onConfirm?: (confirmResponse?: any) => void;
  handleConfirmValidation?: (
    ascendSearchFormValues: IAscendFormValues | undefined,
    searchResponse?: any
  ) => boolean | string;
  mainFieldsRequiredForConfirm?: boolean;
  confirmActionComponent?: (
    confirmResponse: ClientService.SaveResult,
    ascendFormValues: IAscendFormValues,
    searchResponse: any,
    lastConfirmRequestBody: any,
    onConfirm?: ((confirmResponse?: any) => void) | undefined
  ) => JSX.Element;
  handleAsyncConfirmAction?: (
    confirmResponse: any,
    ascendFormValues: IAscendFormValues,
    searchResponse: any,
    lastConfirmRequestBody: any,
    onConfirm?: ((confirmResponse?: any) => void) | undefined
  ) => void;
  children?: ReactElement<IAscendVariableFieldsBlockProps>;
}

const COLS_IN_GRID = 24;
const COLS_IN_ROW = 4;
const COLS_IN_VARIABLE_BLOCK = 3;

const SearchAscend = ({
  form,
  externalStateSetter,
  initialValues,
  // matchedId,
  apiSearch,
  apiSetData,
  variableFieldsComponent,
  numberOfVariableFieldsForSearch = 1,
  showVariableFieldsWhen = 'on-load',
  apiConfirm,
  confirmLinkMessage,
  onConfirm,
  handleConfirmValidation,
  mainFieldsRequiredForConfirm,
  handleAsyncConfirmAction,
  confirmActionComponent,
  children,
}: SearchAscendProps) => {
  const { t } = useLocale();

  const { showModal, closeModal } = useModal();
  const { processResponseForErrors } = useErrorHandling();

  const [initialValuesSet, setInitialValuesSet] = useState<boolean>(false);
  const [dateOfInsolvency, setDateOfInsolvency] = useState<moment.Moment | null>(null);

  const [ascendSearchForm, setAscendSearchForm] = useState<IAscendFormValues | undefined>();
  const [variableFieldsFromChild, setVariableFieldsFromChild] = useState<IVariableFieldValues | undefined>(undefined);
  const [variableFieldsToChild, setVariableFieldsToChild] = useState<IVariableFieldValues | undefined>(undefined);

  const [variableFieldsList, setVariableFieldsList] = useState<string[] | undefined>();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [errors, setErrors] = useState<IErrorsMsgAndType[] | undefined>();

  const [searchResponse, setSearchResponse] = useState<any>();
  const [isMatched, setIsMatched] = useState<boolean>(false);
  // const [matchedId, setMatchedId] = useState<string | undefined>();

  const [confirmDisabled, setConfirmDisabled] = useState<boolean>(true);

  const dateOfInsolvencyPrevious = usePrevious(dateOfInsolvency);
  const ascendSearchFormPrevious = usePrevious(ascendSearchForm);

  useEffect(() => {
    // Initialize Ascend search form with initial values from prop
    if (initialValues && !initialValuesSet) {
      //making sure SIN is only numbers
      if (initialValues.ascendSin) {
        initialValues.ascendSin = getOnlyNumbers(initialValues.ascendSin);
      }

      setAscendSearchForm(initialValues);
      setDateOfInsolvency(initialValues.ascendDateOfInsolvency == null ? null : initialValues.ascendDateOfInsolvency);
      setInitialValuesSet(true);
    }
  }, [initialValues, initialValuesSet]);

  const updateAscendSearchFormWithDateOfInsolvency = useCallback(() => {
    // Push dateOfInsolvency state value into the Ascend search form values
    if (!isMomentWithSameDate(dateOfInsolvency, dateOfInsolvencyPrevious)) {
      setAscendSearchForm((prev) => ({
        ...prev,
        ascendDateOfInsolvency: getUndefinedOrSelf(dateOfInsolvency),
      }));
    }
  }, [dateOfInsolvency, dateOfInsolvencyPrevious]);

  useEffect(() => {
    updateAscendSearchFormWithDateOfInsolvency();
  }, [updateAscendSearchFormWithDateOfInsolvency]);

  const updateAscendSearchFormWithVariableFieldValues = useCallback(() => {
    // If a variableFields component has pushed or updated its values in the SearchAscend state
    // using the setter passed down through its props, update the Ascend search form with those values
    if (variableFieldsFromChild) {
      setAscendSearchForm((prev) => ({
        ...prev,
        ...variableFieldsFromChild,
      }));
    }
  }, [variableFieldsFromChild]);

  useEffect(() => {
    updateAscendSearchFormWithVariableFieldValues();
  }, [updateAscendSearchFormWithVariableFieldValues]);

  const updateParentFormWithAscendSearchFormValues = useCallback(() => {
    // If the Ascend search form has updated, push its values up into the page-level form
    if (form && ascendSearchForm) {
      form.setFieldsValue({
        ...ascendSearchForm,
      });

      if (
        !isMomentWithSameDate(ascendSearchFormPrevious?.ascendDateOfInsolvency, ascendSearchForm.ascendDateOfInsolvency)
      ) {
        setDateOfInsolvency(getUndefinedOrSelf(ascendSearchForm?.ascendDateOfInsolvency));
      }
    }
  }, [form, ascendSearchForm, ascendSearchFormPrevious?.ascendDateOfInsolvency]);

  useEffect(() => {
    updateParentFormWithAscendSearchFormValues();
  }, [updateParentFormWithAscendSearchFormValues]);

  const updateVariableFieldsForChild = useCallback(() => {
    // When the Ascend search form changes, update the state holding values for the variable fields
    // The variable fields component will be re-rendered and updated values will be cloned to its props
    if (ascendSearchForm) {
      const tempAscendSearchForm = { ...ascendSearchForm };
      mainAscendFields.forEach((field) => {
        delete tempAscendSearchForm[field];
      });
      setVariableFieldsToChild(tempAscendSearchForm);
    }
  }, [ascendSearchForm]);

  useEffect(() => {
    updateVariableFieldsForChild();
  }, [updateVariableFieldsForChild]);

  const searchInAscend = useCallback(() => {
    setErrors(undefined);

    const notEnoughFieldsMsg = {
      message: t.ASCEND_ERROR__NOT_ENOUGH_FIELDS,
      messageType: ClientService.ResultMessageType.Error,
    };

    if (!apiSearch) {
      console.error('No API call has been defined for the Search');
      return;
    }

    if (!ascendSearchForm) {
      setErrors([notEnoughFieldsMsg]);
      return;
    }

    let numberOfAscendBaseFieldsWithValues = 0;

    mainAscendFields.forEach((fieldName) => {
      const formFieldValue = ascendSearchForm[fieldName];
      if (formFieldValue != null && formFieldValue !== '') {
        numberOfAscendBaseFieldsWithValues += 1;
      }
    });

    if (numberOfAscendBaseFieldsWithValues < 2) {
      console.error('Number of Ascend base fields with values is only ' + numberOfAscendBaseFieldsWithValues);
      setErrors([notEnoughFieldsMsg]);
      return;
    }

    if (numberOfVariableFieldsForSearch > 0) {
      if (!variableFieldsList || variableFieldsList.length === 0) {
        console.error(
          'The current variable fields child component being used by SearchAscend has not defined and passed up a list of field names'
        );
        return;
      }

      let numberOfVariableFieldsWithValues = 0;
      variableFieldsList.forEach((variableFieldName) => {
        const formVariableFieldValue = ascendSearchForm[variableFieldName];
        if (formVariableFieldValue != null && formVariableFieldValue !== '') {
          numberOfVariableFieldsWithValues += 1;
        }
      });

      if (numberOfVariableFieldsWithValues < numberOfVariableFieldsForSearch) {
        setErrors([notEnoughFieldsMsg]);
        return;
      }
    }

    setIsLoading(true);

    apiSearch(ascendSearchForm)
      .then((response) => {
        setIsLoading(false);

        const responseErrors = processResponseForErrors(response as any);
        setErrors(responseErrors.messages);
        if (responseErrors.hasErrors) return;

        const responseData: any = response.data;

        if (apiSetData) {
          apiSetData(responseData, setAscendSearchForm);
        } else {
          setAscendSearchForm(response.data);
        }
        setIsMatched(true);
        setSearchResponse(responseData);
        setConfirmDisabled(false);
      })
      .catch((error) => {
        console.error(error);
        setIsLoading(false);
        setErrors([
          {
            message: t.ASCEND_SEARCH_SUBMIT_ERROR,
            messageType: ClientService.ResultMessageType.Error,
          },
        ]);
      });
  }, [
    apiSearch,
    ascendSearchForm,
    numberOfVariableFieldsForSearch,
    variableFieldsList,
    processResponseForErrors,
    apiSetData,
    t.ASCEND_SEARCH_SUBMIT_ERROR,
    t.ASCEND_ERROR__NOT_ENOUGH_FIELDS,
  ]);

  const renderVariableBlock = useCallback(() => {
    // Render the variable fields component specified in props. It will be cloned and the
    // relevant props will be added to it
    if (showVariableFieldsWhen === 'on-match' && !isMatched) return;

    const variableComponent = children ? children : variableFieldsComponent ? variableFieldsComponent : null;
    if (variableComponent) {
      return React.cloneElement(variableComponent, {
        form: form,
        externalStateSetter: externalStateSetter,
        variableFieldsFromParent: variableFieldsToChild,
        setVariableFieldsListInParent: setVariableFieldsList,
        updateAscendSearchForm: setVariableFieldsFromChild,
        isMatched: isMatched,
        searchResponse: searchResponse,
      });
    }
    return null;
  }, [
    showVariableFieldsWhen,
    children,
    variableFieldsComponent,
    form,
    externalStateSetter,
    variableFieldsToChild,
    isMatched,
    searchResponse,
  ]);

  const confirmAllAscendFieldsFilled = useCallback((): boolean | string => {
    if (variableFieldsList == null) {
      console.error(
        'The current variable fields child component being used by SearchAscend has not defined and passed up a list of field names.'
      );
      return false;
    }

    if (!ascendSearchForm) {
      return t.ASCEND_ERROR__NO_FIELDS_HAVE_VALUE;
    }

    const allAscendFields = mainFieldsRequiredForConfirm
      ? [...mainAscendFields, ...variableFieldsList]
      : [...variableFieldsList];

    let allFieldsFilled = true;
    let ascendNoOfHouseholdFilled = true;
    let ascendSelectIncomeAndExpense = true;

    allAscendFields.forEach((key) => {
      if (
        ascendSearchForm[key] == null ||
        (typeof ascendSearchForm[key] === 'string' && ascendSearchForm[key].trim() === '')
      ) {
        //check if the field is ascendNoOfHousehold or ascendSelectIncomeAndExpense and set the flag to false to show the error message
        if (key === 'ascendNoOfHousehold') {
          ascendNoOfHouseholdFilled = false;
        } else if (key === 'ascendSelectIncomeAndExpense') {
          ascendSelectIncomeAndExpense = false;
        } else if (key !== 'ascendAccountNumber') {
          //for all other fields, set the flag to false to show the error message
          allFieldsFilled = false;
        }
      }
    });

    //display the error message based on the flag
    if (!ascendSelectIncomeAndExpense && !ascendNoOfHouseholdFilled)
      return t.ASCEND_ERROR__ONE_OR_MORE_FIELDS_MISSING_HOUSEHOLD_INCOME_AND_EXPENSE;
    if (!ascendNoOfHouseholdFilled) return t.ASCEND_ERROR__ONE_OR_MORE_FIELDS_MISSING_NO_HOUSEHOLD;
    if (!ascendSelectIncomeAndExpense) return t.ASCEND_ERROR__ONE_OR_MORE_FIELDS_MISSING_INCOME_AND_EXPENSE;
    if (!allFieldsFilled) return t.ASCEND_ERROR__ONE_OR_MORE_FIELDS_MISSING;

    return true;
  }, [
    ascendSearchForm,
    mainFieldsRequiredForConfirm,
    variableFieldsList,
    t.ASCEND_ERROR__NO_FIELDS_HAVE_VALUE,
    t.ASCEND_ERROR__ONE_OR_MORE_FIELDS_MISSING,
    t.ASCEND_ERROR__ONE_OR_MORE_FIELDS_MISSING_HOUSEHOLD_INCOME_AND_EXPENSE,
    t.ASCEND_ERROR__ONE_OR_MORE_FIELDS_MISSING_NO_HOUSEHOLD,
    t.ASCEND_ERROR__ONE_OR_MORE_FIELDS_MISSING_INCOME_AND_EXPENSE,
  ]);

  const linkTask = useCallback(() => {
    if (!apiConfirm) {
      console.error('A function to link the task has not been passed to the "apiConfirm" prop.');
      return;
    }
    setIsLoading(true);
    const confirmObject = apiConfirm(ascendSearchForm as IAscendFormValues, searchResponse);
    confirmObject
      .apiCall()
      .then((response: ClientService.SaveResult) => {
        setIsLoading(false);

        if (response.hasConfirm === false) {
          // If the response has a "hasConfirm" property and it is false, then we will assume that the response might contain an error message
          const responseErrors = processResponseForErrors(response);
          setErrors(responseErrors.messages);
          if (responseErrors.hasErrors) return;
          if (onConfirm) {
            onConfirm(response);
          } else {
            console.warn(
              'The link was successfully confirmed but an onConfirm() function was not found. Define a function if you would like something to happen on successful confirmation'
            );
          }
        } else {
          if (!confirmActionComponent && !handleAsyncConfirmAction) {
            console.error(
              'A function for handling "Mark as active" requests must be passed to either the "confirmActionComponent" or "handleAsyncConfirmAction" prop.'
            );
            return;
          }

          if (handleAsyncConfirmAction) {
            handleAsyncConfirmAction(
              response as any,
              ascendSearchForm as IAscendFormValues,
              searchResponse,
              confirmObject.requestBody,
              onConfirm
            );
          } else if (confirmActionComponent) {
            showModal(
              confirmActionComponent(
                response,
                ascendSearchForm as IAscendFormValues,
                searchResponse,
                confirmObject.requestBody,
                onConfirm
              )
            );
          }
        }
      })
      .catch((error) => {
        setIsLoading(false);
        const requestErrors = processResponseForErrors(error);
        setErrors(requestErrors.messages);
      });
  }, [
    apiConfirm,
    processResponseForErrors,
    ascendSearchForm,
    searchResponse,
    onConfirm,
    showModal,
    confirmActionComponent,
    handleAsyncConfirmAction,
  ]);

  const ascendConfirm = useCallback(() => {
    let isValidOrErrorMsg;

    if (handleConfirmValidation) {
      isValidOrErrorMsg = handleConfirmValidation(ascendSearchForm, searchResponse);
    } else {
      isValidOrErrorMsg = confirmAllAscendFieldsFilled();
    }

    if (typeof isValidOrErrorMsg === 'string') {
      setErrors([
        {
          message: isValidOrErrorMsg,
          messageType: ClientService.ResultMessageType.Error,
        },
      ]);
      return;
    } else if (!isValidOrErrorMsg) {
      setErrors([
        {
          message: t.ASCEND_ERROR__ERROR_TRYING_TO_VALIDATE,
          messageType: ClientService.ResultMessageType.Error,
        },
      ]);
      return;
    }

    showModal(
      <ActionConfirmationModal
        title={t.ASCEND_LINK_TO_ESTATE}
        message={confirmLinkMessage}
        onOk={() => {
          closeModal();
          linkTask();
        }}
        onCancel={closeModal}
      />
    );
  }, [
    confirmAllAscendFieldsFilled,
    showModal,
    closeModal,
    confirmLinkMessage,
    ascendSearchForm,
    handleConfirmValidation,
    searchResponse,
    linkTask,
    t.ASCEND_ERROR__ERROR_TRYING_TO_VALIDATE,
    t.ASCEND_LINK_TO_ESTATE,
  ]);

  const debtorNameDisplayValue = useMemo(() => {
    if (ascendSearchForm?.ascendDebtorName) return ascendSearchForm?.ascendDebtorName;
    if (form.getFieldValue('ascendDebtorName')) return form.getFieldValue('ascendDebtorName');
    return undefined;
  }, [ascendSearchForm?.ascendDebtorName, form]);

  const updateDebtorName = useCallback((value: string | undefined) => {
    setAscendSearchForm((prev) => ({
      ...prev,
      ascendDebtorName: value,
    }));
  }, []);

  return (
    <div className={styles.SearchAscend}>
      {errors && <ErrorsContainer noTitle errors={errors} />}
      {!isMatched && <div className={styles.number_of_fields_message}>{t.ASCEND_AT_LEAST_TWO_FIELDS}</div>}
      <Row gutter={20}>
        <Col span={COLS_IN_GRID / COLS_IN_ROW}>
          <FormInput
            required={isMatched && mainFieldsRequiredForConfirm}
            label={t.ASCEND_ESTATE_NUMBER}
            key={`estateNum-matched-${isMatched}`}
            name="ascendEstateNumber"
            onChange={(e: any) => {
              setAscendSearchForm((prev) => ({
                ...prev,
                ascendEstateNumber: e.target.value,
              }));
            }}
          />
        </Col>
        <Col span={COLS_IN_GRID / COLS_IN_ROW}>
          <FormInput
            required={isMatched && mainFieldsRequiredForConfirm}
            label={t.ASCEND_SIN}
            key={`sin-matched-${isMatched}`}
            name="ascendSin"
            value={ascendSearchForm?.ascendSin}
            onChange={(e: any) => {
              //only accept the change if all the characters are numbers and the string is 12 characters or less
              if (
                (hasOnlyNumbers(e.target.value) && isString12CharactersOrLess(e.target.value)) ||
                e.target.value === ''
              ) {
                setAscendSearchForm((prev) => ({
                  ...prev,
                  ascendSin: e.target.value,
                }));
              } else {
                setAscendSearchForm((prev) => ({
                  ...prev,
                }));
              }
            }}
          />
        </Col>
        <Col span={COLS_IN_GRID / COLS_IN_ROW}>
          <FormInput
            required={isMatched && mainFieldsRequiredForConfirm}
            label={t.ASCEND_DEBTOR_NAME}
            key={`ascendDebtorName-matched-${isMatched}`}
            name="ascendDebtorName"
          >
            <LookAheadField
              displayValue={debtorNameDisplayValue}
              apiSearch={(searchText) => {
                return API.listDebtors(searchText, 50);
              }}
              apiSetOptionsData={(response, setOptionsData) => {
                setOptionsData(
                  response.map((debtor: any, index: number) => {
                    const safeName = debtor.displayName.replaceAll(' ', '_');
                    return {
                      key: `option_${index}_${safeName}`,
                      label: debtor.displayName,
                      value: debtor.displayName,
                    };
                  })
                );
              }}
              parentUpdater={updateDebtorName}
              // updateParentOnSearch={false}
              onSearch={(searchText) => {
                if (searchText.trim().length === 0) {
                  updateDebtorName(undefined);
                }
              }}
            />
          </FormInput>
        </Col>
        <Col span={COLS_IN_GRID / COLS_IN_ROW}>
          <FormInput
            type="datepicker"
            required={isMatched && mainFieldsRequiredForConfirm}
            label={t.ASCEND_DATE_OF_INSOLVENCY}
            key={`ascendDateOfInsolvency-matched-${isMatched}`}
            name="ascendDateOfInsolvency"
          >
            <DatePicker
              className={styles.date_of_insolvency_datepicker}
              form={form}
              formFieldName="ascendDateOfInsolvency"
              dateSetter={setDateOfInsolvency}
              size="large"
              onChange={(value: ValueSingle) => {
                setDateOfInsolvency(getUndefinedOrSelf(value));
              }}
              inputReadOnly={true}
              format={DATE_FORMAT2}
            />
          </FormInput>
        </Col>
      </Row>
      <Divider className={styles.divider} />
      <Row gutter={20}>
        <div
          className={styles.dynamic_fields_section}
          style={{
            width: `${(((COLS_IN_GRID / COLS_IN_ROW) * COLS_IN_VARIABLE_BLOCK) / COLS_IN_GRID) * 100}%`,
          }}
        >
          {renderVariableBlock()}
        </div>
        <Col span={COLS_IN_GRID / COLS_IN_ROW} style={{ display: 'flex', alignItems: 'stretch' }}>
          <ButtonContainer
            inline
            style={{
              width: '100%',
              paddingTop: !isMatched ? '58px' : '28px',
              marginBottom: '24px',
            }}
          >
            <Button narrow kind="darkgray" padding="medium" onClick={searchInAscend}>
              {t.ASCEND_BUTTON_SEARCH_ASCEND}
            </Button>
            <Button narrow padding="large" disabled={confirmDisabled} onClick={ascendConfirm}>
              {t.ASCEND_BUTTON_CONFIRM}
            </Button>
          </ButtonContainer>
        </Col>
      </Row>
      {isLoading && (
        <div className={styles.loading_container}>
          <Loading noText />
        </div>
      )}
    </div>
  );
};

export default SearchAscend;
