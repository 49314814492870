import Icon from '../../../Icon/Icon';

import styles from './EditButton.module.scss';

interface IProps {
  fontSize?: string; // e.g. 14px
  onClick?: (e?: any) => void;
  className?: string;
  style?: React.CSSProperties;
}

const EditButton = ({ fontSize = '14px', onClick, className, style }: IProps): JSX.Element => {
  return (
    <div className={`${styles.EditButton} ${className ? className : ''}`} style={style}>
      <Icon iconName="Edit" fontSize={fontSize} color="red" onClick={onClick} />
    </div>
  );
};

export default EditButton;
