import { currencyFormatter } from '../../pages/ApplicationOverviewPage/FileAnalysis/utils';

import styles from './CurrencyDisplay.module.scss';

interface CurrencyDisplayProps {
  amount?: string | number;
  decimalDigits?: number;
  noDollarSign?: boolean;
  className?: string;
}

const CurrencyDisplay = ({
  amount = 0,
  decimalDigits = 2,
  noDollarSign = false,
  className = '',
}: CurrencyDisplayProps): JSX.Element => {
  return (
    <div className={`${styles.currency_display} ${className}`}>
      {!noDollarSign && <span className={styles.dollar_sign}>$</span>}
      <span className={styles.dollar_amount}>{currencyFormatter(amount, decimalDigits)}</span>
    </div>
  );
};

export default CurrencyDisplay;
