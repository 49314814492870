import { useCallback, useEffect, useState, useMemo } from 'react';
import { Input, Row, Col, Spin } from 'antd';
import { Icon } from '@fluentui/react/lib/Icon';
import { useQuery } from 'react-query';

import Layout from '../../../components/Layout/Layout';
import Breadcrumb from '../../../components/Breadcrumb/Breadcrumb';
import PageSubtitle from '../../../components/PageSubtitle/PageSubtitle';
import Button from '../../../components/Button/Button';
import UserEditor from './UserEditor';
import StatusValue from '../components/StatusValue';
import StyledTable from '../components/StyledTable/StyledTable';

import useLocale from '../../../hooks/useLocale';
import useModal from '../../../hooks/useModal';
import useDebounce from '../../../hooks/useDebounce';

import { argumentifyUserSearchCriteria } from './utils';
import { getSortableFilterableColumns } from '../utils';

import { ISearchUserCriteria } from './types';
import { DEFAULT_PAGE_SIZE } from '../../../constants/common';

import { TEAMS_TYPES_QUERY, ROLES_QUERY, USE_QUERY_OPTIONS } from '../../../constants/reactQuery';
import { IdentityService } from '../../../shared/api/IdentityService';
import { ClientService } from '../../../shared/api/ClientService';
import { PdsRoleClientAPI, PdsUserClientAPI } from '../../../utils/identityServiceApi';
import API from '../../../utils/api';

import './UserManagement.scss';

function UserManagement(): JSX.Element {
  const { t } = useLocale();
  const { Search } = Input;
  const { showModal, closeModal } = useModal();

  const [searchTerm, setSearchTerm] = useState<string>('');
  const value = useDebounce(searchTerm);

  const [dataSource, setDataSource] = useState<IdentityService.PdsUserListItem[]>();
  const [totalCount, setTotalCount] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(false);
  const [criteria, setCriteria] = useState<ISearchUserCriteria>({
    pageSize: DEFAULT_PAGE_SIZE,
  });

  const { data: roles } = useQuery([ROLES_QUERY], () => PdsRoleClientAPI.listRoles(), USE_QUERY_OPTIONS);
  const { data: teamTypes } = useQuery([TEAMS_TYPES_QUERY], () => API.listTeamTypes(undefined), USE_QUERY_OPTIONS);

  const requestUsers = useCallback(async () => {
    setLoading(true);
    const response = await PdsUserClientAPI.listUsers(...argumentifyUserSearchCriteria(criteria)).catch(() =>
      setLoading(false)
    );
    setLoading(false);

    if (response) {
      setDataSource(response?.items);
      setTotalCount(response?.totalCount || 0);
    }
  }, [criteria]);

  const handleShowEditor = useCallback(
    (userId?: string) => {
      showModal(
        <UserEditor
          id={userId}
          onOk={() => {
            closeModal();
            requestUsers();
          }}
          onCancel={closeModal}
        />
      );
    },
    [closeModal, requestUsers, showModal]
  );

  const handleTableChange = useCallback((pagination: any, filters: any, sorter: any) => {
    setCriteria((prev) => ({
      ...prev,
      pageSize: pagination.pageSize,
      pageIndex: pagination.current - 1,
      sortBy: sorter?.column?.sortByEnum,
      isAscending: sorter?.order === 'ascend',
    }));
  }, []);

  useEffect(() => {
    requestUsers();
  }, [criteria, requestUsers]);

  useEffect(() => {
    setCriteria((prev) => ({ ...prev, searchText: value }));
  }, [value]);

  const sortableColumns = useMemo(() => {
    const columns = [
      {
        title: t.EMAIL_ID,
        dataIndex: 'emailAddress',
        key: 'emailAddress',
        sorter: true,
        sortByEnum: IdentityService.PdsUserSortEnum.EmailAddress,
      },
      {
        title: t.FIRST_NAME,
        dataIndex: 'firstName',
        key: 'firstName',
        sorter: true,
        sortByEnum: IdentityService.PdsUserSortEnum.FirstName,
      },
      {
        title: t.LAST_NAME,
        dataIndex: 'lastName',
        key: 'lastName',
        sorter: true,
        sortByEnum: IdentityService.PdsUserSortEnum.LastName,
      },
      {
        title: t.ROLE,
        dataIndex: 'role',
        key: 'role',
        sorter: true,
        sortByEnum: IdentityService.PdsUserSortEnum.Role,
        filters: (roles || [])
          ?.map((item) => ({
            text: item.name as string,
            value: item.name as string,
          }))
          ?.sort((a, b) => a?.value?.localeCompare(b?.value)),
        onFilter: (value: any, record: IdentityService.PdsUserListItem) =>
          (record?.role || '')?.split(', ')?.indexOf(value) >= 0,
      },
      {
        title: t.OFFICE_LOCATION,
        dataIndex: 'officeLocation',
        key: 'officeLocation',
        sorter: true,
        sortByEnum: IdentityService.PdsUserSortEnum.OfficeLocation,
        filterByName: true,
      },
      {
        title: t.TEAM_TYPE,
        dataIndex: 'teamType',
        key: 'teamType',
        sorter: true,
        sortByEnum: IdentityService.PdsUserSortEnum.TeamType,
        filters: (teamTypes || [])
          ?.map((item) => ({
            text: item.name as string,
            value: item.name as string,
          }))
          ?.sort((a, b) => a?.value?.localeCompare(b?.value)),
        onFilter: (value: any, record: IdentityService.PdsUserListItem) =>
          (record?.teamType || '')?.split(', ')?.indexOf(value) >= 0,
      },
      {
        title: t.STATUS,
        dataIndex: 'isActive',
        key: 'isActive',
        render: (isActive: boolean) => <StatusValue isActive={isActive} />,
        sorter: true,
        sortByEnum: IdentityService.PdsUserSortEnum.Status,
        filters: [
          {
            text: t.ACTIVE,
            value: true,
          },
          {
            text: t.INACTIVE,
            value: false,
          },
        ],
        onFilter: (value: any, record: ClientService.HolidayDto) => value === record.isActive,
      },
      {
        dataIndex: 'action',
        key: 'action',
        render: (_: any, item: IdentityService.PdsUserListItem) => (
          <Icon iconName="Edit" onClick={() => handleShowEditor(item?.id)} className="UserManagement__edit-icon" />
        ),
      },
    ];

    return getSortableFilterableColumns(columns, dataSource);
  }, [dataSource, handleShowEditor, roles, teamTypes]);

  return (
    <Layout className="UserManagement">
      <Breadcrumb data={[{ title: t.ADMINISTRATION }, { title: t.USER_MANAGEMENT }]} />
      <PageSubtitle title={t.USER_MANAGEMENT} />
      <Row align="bottom" justify="space-between">
        <Col span={8}>
          <Search
            placeholder={t.SEARCH_BY_EMAILID_FN_LN}
            allowClear
            size="large"
            onSearch={setSearchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </Col>
        <Col>
          <Button onClick={() => handleShowEditor()}>Add User</Button>
        </Col>
      </Row>
      <Spin spinning={loading}>
        <StyledTable
          rowKey="id"
          dataSource={dataSource}
          columns={sortableColumns}
          onChange={handleTableChange}
          total={totalCount}
          loading={loading}
        />
      </Spin>
    </Layout>
  );
}

export default UserManagement;
