import { useState, useEffect, useCallback } from 'react';
import useLocale from '../../../../hooks/useLocale';
import { ClientService } from '../../../../shared/api/ClientService';
import General from './General/AdminInfo_General';
import API from '../../../../utils/api';
import AppTabSystem, { TTabsAndContentVertical } from '../../../../components/AppTabs/AppTabSystem';
import SignUp from './SignUp/SignUp';
import Counselling from './Counselling/Counselling';
import Proposal from './Proposal/Proposal';
import Claims from './Claims/AdminInfo_Claims';
import AssetRealization from './AssetRealization/AdminInfo_AssetRealization';
import IncomeAndExpense from './IncomeAndExpense/AdminInfo_IncomeAndExpense';
import TaxReturn from './TaxReturn/AdminInfo_TaxReturn';
import Banking from './Banking/Banking';
import ReceiptsAndDisbursements from './ReceiptsAndDisbursements/ReceiptsAndDisbursements';
import Discharge from './Discharge/Discharge';

import { eventBus } from '../../../../utils/eventBus';
import { ADMIN_INFO_CHANGE_EVENT } from '../../../../constants/eventBus';

import styles from '../../../../styles/style.module.scss';

interface IProps {
  data: ClientService.IApplicationFileDto | null;
  handleUpdateData: (forcedResponse?: any) => void;
  defaultActiveKey?: string;
}

export const GENERAL_TAB = 'general';
export const SIGNUP_TAB = 'signup';
export const COUNSELLING_TAB = 'counselling';
export const CLAIMS_TAB = 'claims';
export const ASSET_REALIZATION_TAB = 'asset-realization';
export const BANKING_TAB = 'banking';
export const INCOME_AND_EXPENSE_TAB = 'income-and-expense';
export const TAX_PROCESS_TAB = 'tax-process';
export const DISCHARGE_TAB = 'discharge';
export const RECEIPTS_AND_DISBURSEMENTS_TAB = 'receipts-and-disbursements';
export const PROPOSAL_TAB = 'proposal';

const AdminInfoTab = ({ data, handleUpdateData, defaultActiveKey }: IProps): JSX.Element => {
  const { t } = useLocale();

  const [filingTypesList, setFilingTypesList] = useState<ClientService.ILookupDto[] | null>(null);
  const [subTabs, setSubTabs] = useState<TTabsAndContentVertical[]>([]);
  const [isDuplicateFilename, setIsDuplicateFilename] = useState<boolean>(false);
  const [adminInfoStatuses, setAdminInfoStatuses] = useState<ClientService.AdminInfoFileProcessStatusDto>();

  const requestDuplicates = useCallback(async (fileId: string) => {
    const response = await API.isDuplicateFilename(fileId);

    if (response) {
      setIsDuplicateFilename(response);
    }
  }, []);

  const convertFileProcessStatusEnumToDetails = useCallback(
    (fileProcessStatusEnum?: ClientService.FileProcessStatusEnum) => {
      if (fileProcessStatusEnum === undefined) return {};

      if (
        fileProcessStatusEnum === ClientService.FileProcessStatusEnum.Complete ||
        fileProcessStatusEnum === ClientService.FileProcessStatusEnum.Exception
      ) {
        return {
          iconName: 'SkypeCircleCheck',
          iconColor: 'green',
          subTitle: t.COMPLETE,
        };
      }

      if (
        fileProcessStatusEnum === ClientService.FileProcessStatusEnum.Incomplete ||
        fileProcessStatusEnum === ClientService.FileProcessStatusEnum.InProgress
      ) {
        return {
          iconName: 'AlertSolid',
          iconColor: 'red',
          subTitle: t.INCOMPLETE,
        };
      }

      return {
        iconName: 'ProgressRingDots',
        subTitle: t.PENDING,
      };
    },
    [t.COMPLETE, t.INCOMPLETE, t.PENDING]
  );

  useEffect(() => {
    if (data) {
      console.log('DATA:', data);
    }
  }, [data]);

  useEffect(() => {
    API.listFilingTypes().then((response) => {
      setFilingTypesList(response);
    });
  }, [setFilingTypesList]);

  // Build up list of Tabs to display and corresponding Tab content based on
  // the current file's File Stage and Filing Type
  const updateSubTabs = useCallback(() => {
    const fileStageEnum = data?.fileStage?.enumValue;
    const filingTypeName = data?.filingType;
    const isMigratedFile = data?.isMigrated;
    const migratedFileStage = data?.migratedFileStage;

    let filingTypeEnum: ClientService.FilingTypeEnum | number | undefined;

    filingTypesList?.every((filingTypeObj) => {
      if (filingTypeObj.name === filingTypeName) {
        filingTypeEnum = filingTypeObj.enumValue;
        return false;
      }
      return true;
    });

    const Ordinary = ClientService.FilingTypeEnum.Ordinary;
    const Summary = ClientService.FilingTypeEnum.Summary;
    const DivisionI = ClientService.FilingTypeEnum.DivisionI;
    const DivisionII = ClientService.FilingTypeEnum.DivisionII;

    // Handle scenario for disabling fields in different Admin Info pages
    let disableAllAdminInfoModules = false; // except receipts and disbursement
    let disableReceiptsDisbursementsModule = false;
    let disableSignupModule = false;
    let disableCounsellingSessionModule = false;

    if (fileStageEnum === ClientService.FileStageEnum.StatusChanged) {
      disableAllAdminInfoModules = true;

      if (
        filingTypeEnum === ClientService.FilingTypeEnum.DivisionI ||
        filingTypeEnum === ClientService.FilingTypeEnum.Ordinary
      ) {
        disableReceiptsDisbursementsModule = true;
      }
    }

    if (isMigratedFile) {
      if (migratedFileStage === ClientService.FileStageEnum.Closed) {
        disableSignupModule = true;
        disableCounsellingSessionModule = true;
      }

      if (migratedFileStage === ClientService.FileStageEnum.FileInProcess) {
        disableSignupModule = true;
      }
    }

    let subTabsTitlesAndContent: TTabsAndContentVertical[] = [
      {
        title: t.ADMIN_INFO_PROCESS_GENERAL,
        key: GENERAL_TAB,
        content: (
          <General
            data={data}
            filingTypesList={filingTypesList}
            handleUpdateData={handleUpdateData}
            disableAllFields={disableAllAdminInfoModules}
          />
        ),
        ...convertFileProcessStatusEnumToDetails(
          !adminInfoStatuses
            ? undefined
            : adminInfoStatuses?.generalStatus
            ? ClientService.FileProcessStatusEnum.Complete
            : ClientService.FileProcessStatusEnum.Incomplete
        ),
      },
      {
        title: t.ADMIN_INFO_PROCESS_SIGNUP,
        key: SIGNUP_TAB,
        content: <SignUp disableAllFields={disableAllAdminInfoModules || disableSignupModule} />,
        ...convertFileProcessStatusEnumToDetails(adminInfoStatuses?.signupStatus),
      },
    ];

    if (
      fileStageEnum !== (ClientService.FileStageEnum.NewProspect as unknown) &&
      fileStageEnum !== (ClientService.FileStageEnum.PreSignup as unknown)
    ) {
      subTabsTitlesAndContent = [
        ...subTabsTitlesAndContent,
        {
          title: t.ADMIN_INFO_PROCESS_COUNSELLING,
          key: COUNSELLING_TAB,
          content: <Counselling disableAllFields={disableAllAdminInfoModules || disableCounsellingSessionModule} />,
          ...convertFileProcessStatusEnumToDetails(adminInfoStatuses?.counsellingStatus),
        },
        {
          title: t.ADMIN_INFO_PROCESS_CLAIMS,
          key: CLAIMS_TAB,
          content: (
            <Claims
              data={data}
              filingTypesList={filingTypesList}
              handleUpdateData={handleUpdateData}
              disableAllFields={disableAllAdminInfoModules}
            />
          ),
          ...convertFileProcessStatusEnumToDetails(adminInfoStatuses?.claimsStatus),
        },
        {
          title: t.ADMIN_INFO_PROCESS_ASSET_REALIZATION,
          key: ASSET_REALIZATION_TAB,
          content: (
            <AssetRealization
              data={data}
              filingTypesList={filingTypesList}
              handleUpdateData={handleUpdateData}
              disableAllFields={disableAllAdminInfoModules}
            />
          ),
          ...convertFileProcessStatusEnumToDetails(adminInfoStatuses?.assetRealisationStatus),
        },
        {
          title: t.ADMIN_INFO_PROCESS_BANKING,
          key: BANKING_TAB,
          content: <Banking disableAllFields={disableAllAdminInfoModules} />, // disableAllFields={anyDisableCondition}
          ...convertFileProcessStatusEnumToDetails(adminInfoStatuses?.bankingStatus),
        },
      ];

      if (filingTypeEnum === (Ordinary as unknown) || filingTypeEnum === (Summary as unknown)) {
        subTabsTitlesAndContent = [
          ...subTabsTitlesAndContent,
          {
            title: t.ADMIN_INFO_PROCESS_INCOME_AND_EXPENSE,
            key: INCOME_AND_EXPENSE_TAB,
            content: (
              <IncomeAndExpense
                data={data}
                filingTypesList={filingTypesList}
                handleUpdateData={handleUpdateData}
                disableAllFields={disableAllAdminInfoModules}
              />
            ),
            ...convertFileProcessStatusEnumToDetails(adminInfoStatuses?.incomeExpenseStatus),
          },
          {
            title: t.ADMIN_INFO_PROCESS_TAX_RETURN,
            key: TAX_PROCESS_TAB,
            content: (
              <TaxReturn
                data={data}
                filingTypesList={filingTypesList}
                handleUpdateData={handleUpdateData}
                disableAllFields={disableAllAdminInfoModules}
              />
            ),
            ...convertFileProcessStatusEnumToDetails(adminInfoStatuses?.taxReturnStatus),
          },
          {
            title: t.ADMIN_INFO_PROCESS_DISCHARGE,
            key: DISCHARGE_TAB,
            content: <Discharge disableAllFields={disableAllAdminInfoModules} />,
            ...convertFileProcessStatusEnumToDetails(adminInfoStatuses?.dischargeStatus),
          },
        ];
      }

      subTabsTitlesAndContent = [
        ...subTabsTitlesAndContent,

        {
          title: t.ADMIN_INFO_PROCESS_RECEIPTS_AND_DISBURSEMENTS,
          key: RECEIPTS_AND_DISBURSEMENTS_TAB,
          content: (
            <ReceiptsAndDisbursements
              filingTypeEnum={filingTypeEnum}
              disableAllFields={disableReceiptsDisbursementsModule}
            />
          ),
          ...convertFileProcessStatusEnumToDetails(adminInfoStatuses?.receiptsandDisbursementStatus),
        },
      ];

      if (filingTypeEnum === (DivisionI as unknown) || filingTypeEnum === (DivisionII as unknown)) {
        subTabsTitlesAndContent = [
          ...subTabsTitlesAndContent,
          {
            title: t.ADMIN_INFO_PROCESS_PROPOSAL,
            key: PROPOSAL_TAB,
            content: <Proposal disableAllFields={disableAllAdminInfoModules} />,
            ...convertFileProcessStatusEnumToDetails(adminInfoStatuses?.proposalStatus),
          },
        ];
      }
    }

    setSubTabs(subTabsTitlesAndContent);
  }, [
    data,
    filingTypesList,
    t.ADMIN_INFO_PROCESS_GENERAL,
    t.ADMIN_INFO_PROCESS_SIGNUP,
    t.ADMIN_INFO_PROCESS_COUNSELLING,
    t.ADMIN_INFO_PROCESS_CLAIMS,
    t.ADMIN_INFO_PROCESS_ASSET_REALIZATION,
    t.ADMIN_INFO_PROCESS_BANKING,
    t.ADMIN_INFO_PROCESS_RECEIPTS_AND_DISBURSEMENTS,
    t.ADMIN_INFO_PROCESS_INCOME_AND_EXPENSE,
    t.ADMIN_INFO_PROCESS_TAX_RETURN,
    t.ADMIN_INFO_PROCESS_DISCHARGE,
    t.ADMIN_INFO_PROCESS_PROPOSAL,
    handleUpdateData,
    convertFileProcessStatusEnumToDetails,
    adminInfoStatuses,
  ]);

  const requestAdminInfoStatuses = useCallback(async () => {
    if (data?.id) {
      const response = await API.processStatus(data?.id);

      if (response) setAdminInfoStatuses(response);
    }
  }, [data?.id]);

  const requestUpdatedData = useCallback(() => {
    requestAdminInfoStatuses();
    handleUpdateData();
  }, [handleUpdateData, requestAdminInfoStatuses]);

  useEffect(() => {
    updateSubTabs();
  }, [updateSubTabs]);

  useEffect(() => {
    if (data?.id) requestDuplicates(data?.id as string);
  }, [data?.id, requestDuplicates]);

  useEffect(() => {
    requestAdminInfoStatuses();
  }, [requestAdminInfoStatuses]);

  useEffect(() => {
    eventBus.on(ADMIN_INFO_CHANGE_EVENT, requestUpdatedData);

    return () => {
      eventBus.remove(ADMIN_INFO_CHANGE_EVENT, requestUpdatedData);
    };
  }, [requestUpdatedData]);

  if (isDuplicateFilename) {
    return (
      <div style={{ color: styles.colorDanger, paddingTop: 40, fontWeight: 600, textAlign: 'center' }}>
        {t.MULTIPLE_FILENAMES_MESSAGE}
      </div>
    );
  }

  return (
    <AppTabSystem
      mode="vertical"
      changedFormProtection
      tabs={subTabs}
      defaultActiveKey={defaultActiveKey || GENERAL_TAB}
      destroyInactiveTabPane
    />
  );
};

export default AdminInfoTab;
