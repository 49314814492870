import React, { useCallback, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Menu, Dropdown } from 'antd';
import { Icon } from '@fluentui/react/lib/Icon';

import UploadModal from '../UploadModal/UploadModal';
import EmailEditor from '../../EmailContent/EmailEditor/EmailEditor';
import RequestForReview from '../../../../components/RequestForReview/RequestForReview';
import ActionConfirmationModal from '../../../../modals/ActionConfirmationModal/ActionConfirmationModal';

import useModal from '../../../../hooks/useModal';
import useLocale from '../../../../hooks/useLocale';

import authService from '../../../../components/Auth/AuthorizeService';
import { FileActionEnum } from '../../../../components/AttachmentsTab';
import { ClientService } from '../../../../shared/api/ClientService';
import API from '../../../../utils/api';

import './DocumentsTable.scss';

interface IProps {
  document?: ClientService.IDocumentDto;
  onComplete?: () => void;
  onComment?: () => void;
  canReceiveEmail?: boolean;
}

function RowActionMenu({ document, onComplete, onComment, canReceiveEmail = true }: IProps) {
  const { showModal, closeModal } = useModal();
  const { applicationFileId } = useParams<{ applicationFileId?: string }>();
  const { t, getLocalizedDocumentName } = useLocale();

  const handleShowReviewEditor = useCallback(() => {
    if (document) {
      showModal(
        <RequestForReview
          data={[document]}
          onOk={() => {
            closeModal();
            if (onComplete) onComplete();
          }}
          onCancel={closeModal}
        />
      );
    }
  }, [document, showModal, closeModal, onComplete]);

  const requestActionConfirmation = useCallback(
    async (title: string, message: string, cb: () => void) => {
      showModal(
        <ActionConfirmationModal
          title={title}
          message={message}
          okText={t.YES}
          cancelText={t.NO}
          onOk={() => {
            cb();
            closeModal();
          }}
          onCancel={closeModal}
        />
      );
    },
    [closeModal, showModal, t.NO, t.YES]
  );

  const handleDelete = useCallback(async () => {
    await API.documentsDELETE([document?.id as string]);
    if (onComplete) onComplete();
  }, [document, onComplete]);

  const handleDownload = useCallback(async () => {
    const documentId = document?.id as string;
    let token = await authService.getAccessToken();

    fetch(`${process.env.REACT_APP_BASE_URL}/api/client-service/documents/${documentId}/download`, {
      method: 'GET',
      headers: new Headers({
        Authorization: `Bearer ${token}`,
      }),
    })
      .then((response) => {
        return response.blob();
      })
      .then((blob) => {
        const url = URL.createObjectURL(blob);
        const link = window.document.createElement('a');
        link.href = url;
        link.setAttribute('download', getLocalizedDocumentName(document) as string);
        link.click();
        URL.revokeObjectURL(url);
      });
  }, [document]);

  const handleReplace = useCallback(() => {
    showModal(
      <UploadModal
        fileId={applicationFileId as string}
        documents={[document as ClientService.DocumentDto]}
        action={FileActionEnum.Replace}
        onOk={() => {
          closeModal();
          if (onComplete) onComplete();
        }}
        onCancel={closeModal}
      />
    );
  }, [applicationFileId, closeModal, document, onComplete, showModal]);

  const handleEdit = useCallback(() => {
    showModal(
      <UploadModal
        fileId={applicationFileId as string}
        documents={[document as ClientService.DocumentDto]}
        action={FileActionEnum.Edit}
        onOk={() => {
          closeModal();
          if (onComplete) onComplete();
        }}
        onCancel={closeModal}
      />
    );
  }, [applicationFileId, closeModal, document, onComplete, showModal]);

  const handleShowEmailEditor = useCallback(() => {
    showModal(
      <EmailEditor
        onNext={() => {
          closeModal();
        }}
        onCancel={closeModal}
        fileId={applicationFileId as string}
        attachingDocuments={[document as ClientService.DocumentDto]}
      />
    );
  }, [showModal, closeModal, applicationFileId, document]);

  const actionMenu = (
    <Menu className="DocumentsTable__action-menu">
      <Menu.Item onClick={handleEdit}>
        <Icon iconName="Edit" /> {t.EDIT}
      </Menu.Item>
      <Menu.Item onClick={onComment}>
        <Icon iconName="Comment" /> {t.COMMENT}
      </Menu.Item>
      {canReceiveEmail && (
        <Menu.Item onClick={handleShowEmailEditor}>
          <Icon iconName="Mail" /> {t.EMAIL}
        </Menu.Item>
      )}
      <Menu.Item onClick={handleShowReviewEditor}>
        <Icon iconName="Send" /> {t.DOCUMENTS_REQUEST_REVIEW}
      </Menu.Item>
      <Menu.Item onClick={handleDownload}>
        <Icon iconName="Download" /> {t.DOWNLOAD}
      </Menu.Item>
      <Menu.Item onClick={handleReplace}>
        <Icon iconName="Upload" /> {t.REPLACE}
      </Menu.Item>
      <Menu.Item
        onClick={() =>
          requestActionConfirmation(t.CONFIRM_DELETE, t.CONFIRM_DELETE_SELECTED_FILE_MESSAGE, handleDelete)
        }
      >
        <Icon iconName="Delete" /> {t.DELETE}
      </Menu.Item>
    </Menu>
  );

  return (
    <div onClick={(e) => e.stopPropagation()}>
      <Dropdown
        overlay={actionMenu}
        placement="bottomLeft"
        trigger={['click']}
        getPopupContainer={(triggerNode: HTMLElement) => triggerNode.parentElement as HTMLElement}
      >
        <div className="DocumentsTable__more-icon">
          <Icon iconName="More" />
        </div>
      </Dropdown>
    </div>
  );
}

export default RowActionMenu;
