import { convertDatesToReadable } from '../../../utils';
import { TIME_FORMAT } from '../../../../../constants/common';

import { ClientService } from '../../../../../shared/api/ClientService';

import './Overrides.scss';

interface IProps {
  entry?: ClientService.AvailabilityOverrideEntryDto;
}

const Entry = ({ entry }: IProps) => (
  <div className="Overrides__entry-item">
    <div>{convertDatesToReadable(entry?.startTime?.utc(), entry?.endTime?.utc(), TIME_FORMAT, 'h')}</div>
    <div>{`${entry?.locationName}, ${entry?.meetingTypeName}`}</div>
  </div>
);

export default Entry;
