import { IClaimsSearchCriteria, IAssetsSearchCriteria, ITaxReturnPeriodSearchCriteria } from './types';

export const argumentifyClaimsSearchCriteria = ({
  id,
  fileName,
  estateNumber,
  isCompleted,
  isSecured,
  creditorName,
  accountNumber,
  externalLiabilityClaimIds,
  externalDebtorFileId,
  externalAssetId,
  isSecuredWithoutAssets,
  isLetterSent,
  sorting,
  skipCount,
  maxResultCount,
}: IClaimsSearchCriteria) =>
  [
    id,
    fileName,
    estateNumber,
    isCompleted,
    isSecured,
    creditorName,
    accountNumber,
    externalLiabilityClaimIds,
    externalDebtorFileId,
    externalAssetId,
    isSecuredWithoutAssets,
    isLetterSent,
    sorting,
    skipCount,
    maxResultCount,
  ] as const;

export const argumentifyAssetsSearchCriteria = ({
  id,
  isSecured,
  fileName,
  sorting,
  skipCount,
  maxResultCount,
}: IAssetsSearchCriteria) => [id, isSecured, fileName, sorting, skipCount, maxResultCount] as const;

export const argumentifyTaxReturnPeriodSearchCriteria = ({
  fileId,
  sorting,
  skipCount,
  maxResultCount,
}: ITaxReturnPeriodSearchCriteria) => [fileId, sorting, skipCount, maxResultCount] as const;
