import { Form, Col, Row, SelectProps, ColProps } from 'antd';
import { Icon } from '@fluentui/react/lib/Icon';
import { NamePath } from 'rc-field-form/lib/interface';

import SelectLookupDto from '../../../../components/SelectLookupDto/SelectLookupDto';

import useLocale from '../../../../hooks/useLocale';

import { ClientService } from '../../../../shared/api/ClientService';
import { IdentityService } from '../../../../shared/api/IdentityService';
import { ALL_ID } from './constants';

import './SelectWithResultingList.scss';
import styles from '../../../../styles/style.module.scss';

interface IProps extends SelectProps {
  name: NamePath;
  data?: ClientService.LookupDto[] | IdentityService.PdsRoleDto[];
  span?: number;
  label?: string;
  labelCol?: ColProps;
  required?: boolean;
  selectAllOption?: boolean;
  canDelete?: boolean;
}

const ALL_OPTION = {
  id: ALL_ID,
  name: 'All',
} as ClientService.LookupDto;

const SelectWithResultingList = ({
  data,
  name,
  span = 24,
  label,
  labelCol,
  required,
  selectAllOption,
  canDelete,
  ...props
}: IProps) => {
  const { t } = useLocale();
  const selectFieldName = `${name}-select`;

  if (!data)
    return (
      <Row gutter={10}>
        <Col span={23}>
          <Form.Item label={label} labelCol={labelCol} labelAlign="left" required={required}>
            <SelectLookupDto />
          </Form.Item>
        </Col>
      </Row>
    );

  return (
    <Form.Item noStyle shouldUpdate>
      {({ getFieldValue, setFields }) => {
        const listFieldValues = getFieldValue(name) || [];

        const options = (
          selectAllOption && listFieldValues?.length !== data?.length ? [ALL_OPTION, ...data] : [...data]
        )?.filter((item) => listFieldValues?.indexOf(item?.id) < 0);

        return (
          <Row gutter={10}>
            <Col span={23}>
              <Form.Item
                label={label}
                labelCol={labelCol}
                labelAlign="left"
                className="SelectWithResultingList__select-field"
                name={`${name}-select`}
                required={required}
                rules={[
                  {
                    validator: () => {
                      if (required && !getFieldValue(name)?.length) {
                        return Promise.reject(t.REQUIRED_FIELD);
                      } else {
                        return Promise.resolve();
                      }
                    },
                  },
                ]}
                validateTrigger="onSubmit"
              >
                <SelectLookupDto data={options} {...props} />
              </Form.Item>
            </Col>
            <Form.List initialValue={[]} name={name}>
              {(fields, { add, remove }) => (
                <>
                  <Col span={1}>
                    <Form.Item shouldUpdate>
                      <div
                        onClick={() => {
                          if (props?.disabled) return;

                          const selectedId = getFieldValue(selectFieldName);

                          if (!selectedId) return;

                          if (selectedId === ALL_ID) {
                            options?.filter((option) => option.id !== ALL_ID)?.forEach((filtered) => add(filtered.id));
                          } else {
                            add(selectedId);
                          }

                          setFields([
                            {
                              name: selectFieldName,
                              errors: [],
                              value: undefined,
                            },
                          ]);
                        }}
                      >
                        <Icon
                          iconName="Add"
                          className="SelectWithResultingList__add-icon"
                          style={{
                            cursor: props?.disabled ? 'not-allowed' : 'pointer',
                            color: props?.disabled ? styles.colorSecondary : styles.colorDanger,
                            borderColor: props?.disabled ? styles.colorSecondary : styles.colorDanger,
                          }}
                        />
                      </div>
                    </Form.Item>
                  </Col>
                  <Col span={24}>
                    {fields?.map((row, index) => (
                      <Row key={`roles-${index}`} gutter={10} className="SelectWithResultingList__list-item">
                        <Col span={23}>
                          <Form.Item label=" " labelCol={labelCol}>
                            {data?.find((item) => item.id === getFieldValue([name as string, row.name]))?.name}
                          </Form.Item>
                        </Col>
                        {(canDelete !== undefined ? canDelete : props?.disabled !== true) && (
                          <Col span={1}>
                            <Form.Item label=" ">
                              <div onClick={() => remove(row.name)}>
                                <Icon iconName="Delete" className="SelectWithResultingList__delete-icon" />
                              </div>
                            </Form.Item>
                          </Col>
                        )}
                      </Row>
                    ))}
                  </Col>
                </>
              )}
            </Form.List>
          </Row>
        );
      }}
    </Form.Item>
  );
};

export default SelectWithResultingList;
