import { useState, useCallback, useEffect, useMemo } from 'react';
import { Modal, Tabs, Form, Row, Col, Checkbox, Spin } from 'antd';
import { Label } from '@fluentui/react';
import { Icon } from '@fluentui/react/lib/Icon';

import AttachmentsTab, { AttachmentsFormProps, convertIdToLookupCodeName } from '../../../../components/AttachmentsTab';
import IncomeExpenseIncomeRow from './IncomeExpenseIncomeRow';
import Button from '../../../../components/Button/Button';
import ChangeTrackerFormItem from '../../../../components/ChangeTrackerFormItem';

import useLocale from '../../../../hooks/useLocale';
import { ClientService } from '../../../../shared/api/ClientService';

import './ApplicationFormTab.scss';
import { convertLookupCodeName } from '../../../../components/AttachmentsTab/utils';
import { debounceWithTimeOut } from '../../../../utils/helpers';

interface IProps {
  fileId?: string;
  data?: ClientService.AppFormIncomeExpenseIncomeDto;
  incomeTypes: ClientService.LookupDto[];
  hasDocumentsTab?: boolean;
  loading?: boolean;
  onSave: (body: ClientService.AppFormIncomeExpenseIncomeDto, uploadListenerStatus: boolean) => void;
  onCancel?: () => void;
  isLocked?: boolean;
  isMissingSupportingDocuments?: boolean;
  updateUploadListenerStatus?: (status: boolean) => void;
}

const SOURCE_TAB = 'source';
const DOCUMENTS_TAB = 'documents';

interface IForm extends ClientService.AppFormIncomeExpenseIncomeDto, AttachmentsFormProps {}

function IncomeExpenseIncome({
  fileId,
  incomeTypes,
  onSave,
  onCancel,
  hasDocumentsTab = true,
  loading = false,
  data,
  isLocked = false,
  isMissingSupportingDocuments = false,
}: IProps): JSX.Element {
  const { t } = useLocale();
  const { TabPane } = Tabs;
  const [form] = Form.useForm<IForm>();

  const formTabs = useMemo(() => (hasDocumentsTab ? [SOURCE_TAB, DOCUMENTS_TAB] : [SOURCE_TAB]), [hasDocumentsTab]);

  const [isMarried, setIsMarried] = useState(false);
  const [activeTab, setActiveTab] = useState<string>(SOURCE_TAB);

  const [prefixCodes, setPrefixCodes] = useState<(string | undefined)[]>();
  const [prefixHint, setPrefixHint] = useState<string[]>();
  const [existingDocumentIds, setExistingDocumentIds] = useState<string[]>(data?.documentIds || []);
  const [uploadListenerStatus, setUploadListenerStatus] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    if (data) {
      form.setFieldsValue({
        ...data,
        incomeDetails:
          data?.incomeDetails?.filter(
            (item) =>
              !(
                (incomeTypes?.find((type) => type?.id === item?.incomeTypeId)?.enumValue ===
                  ClientService.IncomeTypeEnum.Other ||
                  incomeTypes?.find((type) => type?.id === item?.incomeTypeId)?.enumValue ===
                    ClientService.IncomeTypeEnum.OtherBenefits ||
                  incomeTypes?.find((type) => type?.id === item?.incomeTypeId)?.enumValue ===
                    ClientService.IncomeTypeEnum.GovernmentBenefits) &&
                !item?.applicantAmount &&
                !item?.spouseAmount &&
                !item?.otherHouseholdMemberAmount
              )
          ) || [],
      });

      setExistingDocumentIds(data?.documentIds || []);

      setIsMarried(data?.isMarried || false);
    }
  }, [data, form, incomeTypes]);

  const handleSave = useCallback(
    (dto: ClientService.AppFormIncomeExpenseIncomeDto) => {
      if (isLocked) return;

      dto.fileId = fileId;

      setIsLoading(true);
      onSave(dto, uploadListenerStatus);
    },
    [isLocked, fileId, onSave, uploadListenerStatus]
  );

  const handleNextStepOrSubmit = useCallback(
    (isDocumentsTabVisible?: boolean) => {
      const activeKeyIndex = formTabs?.indexOf(activeTab);

      if (isDocumentsTabVisible && formTabs?.length - 1 > activeKeyIndex) {
        setActiveTab(formTabs[activeKeyIndex + 1]);
      } else {
        const values = form.getFieldsValue();
        handleSave(values);
      }
    },
    [activeTab, form, formTabs, handleSave]
  );

  const handleTabChange = useCallback((key) => form.validateFields().then(() => setActiveTab(key)), [form]);

  const onFormSubmit = useCallback(
    async (isDocumentsTabVisible?: boolean) => {
      form
        .validateFields()
        .then(() => handleNextStepOrSubmit(isDocumentsTabVisible))
        .catch((error) => console.log(error));
    },
    [form, handleNextStepOrSubmit]
  );

  useEffect(() => {
    const nonEmptyFields = form
      .getFieldValue('incomeDetails')
      ?.filter(
        (item: ClientService.AppFormIncomeExpenseIncomeDetailDto) =>
          item.applicantAmount || item.spouseAmount || item.otherHouseholdMemberAmount
      );

    const prefixCodes = nonEmptyFields
      ?.map((item: ClientService.AppFormIncomeExpenseIncomeDetailDto) =>
        convertIdToLookupCodeName(item?.incomeTypeId as string, incomeTypes, ClientService.IncomeTypeEnum)
      )
      ?.filter((item?: string) => Boolean(item))
      ?.map((item: string) => `Income.${convertLookupCodeName(item)}`);

    if (prefixCodes?.length) setPrefixCodes(prefixCodes);

    const isSelfEmploymentIncomeFilled = Boolean(
      nonEmptyFields?.find(
        (item: ClientService.AppFormIncomeExpenseIncomeDetailDto) =>
          item.incomeTypeId ===
          incomeTypes?.find((type) => type.enumValue === ClientService.IncomeTypeEnum.SelfEmploymentIncome)?.id
      )
    );

    const isPensionOrAnnuitiesFilled = Boolean(
      nonEmptyFields?.find(
        (item: ClientService.AppFormIncomeExpenseIncomeDetailDto) =>
          item.incomeTypeId ===
          incomeTypes?.find((type) => type.enumValue === ClientService.IncomeTypeEnum.PensionOrAnnuities)?.id
      )
    );

    const hint = [];
    if (isSelfEmploymentIncomeFilled)
      hint?.push(t.ONLY_1_OF_THE_3_SUPPORTING_DOCUMENTS_LISTED_FOR_SELFEMPLOYMENT_INCOME_IS_REQUIRED);
    if (isPensionOrAnnuitiesFilled)
      hint?.push(t.ONLY_1_OF_THE_2_SUPPORTING_DOCUMENTS_LISTED_FOR_PENSION_INCOME_IS_REQUIRED);
    setPrefixHint(hint);
  }, [
    activeTab,
    form,
    incomeTypes,
    t.ONLY_1_OF_THE_2_SUPPORTING_DOCUMENTS_LISTED_FOR_PENSION_INCOME_IS_REQUIRED,
    t.ONLY_1_OF_THE_3_SUPPORTING_DOCUMENTS_LISTED_FOR_SELFEMPLOYMENT_INCOME_IS_REQUIRED,
  ]);

  const isLastTab = useMemo(() => formTabs?.length - 1 === formTabs.indexOf(activeTab), [formTabs, activeTab]);

  return (
    <Modal
      destroyOnClose
      centered
      visible
      title={t.MONTHLY_INCOME}
      width={1200}
      footer={null}
      closable
      onCancel={onCancel}
      maskClosable={false}
      className="ApplicationFormTab__modal"
    >
      <Spin spinning={loading || isLoading}>
        <Form form={form} layout="vertical">
          {(_, formInstance) => {
            const incomeDetails = formInstance?.getFieldValue(
              'incomeDetails'
            ) as ClientService.AppFormIncomeExpenseIncomeDetailDto[];

            const isAnyFieldFilled = Boolean(
              incomeDetails?.find((f) => f.spouseAmount || f.applicantAmount || f.otherHouseholdMemberAmount)
            );

            const isDocumentsTabVisible =
              Boolean(
                incomeDetails
                  ?.filter(
                    (item) =>
                      item.incomeTypeId !==
                        incomeTypes.find((type) => type.enumValue === ClientService.IncomeTypeEnum.ChildSupport)?.id &&
                      item.incomeTypeId !==
                        incomeTypes.find((type) => type.enumValue === ClientService.IncomeTypeEnum.SpousalSupport)
                          ?.id &&
                      item.incomeTypeId !==
                        incomeTypes.find((type) => type.enumValue === ClientService.IncomeTypeEnum.Other)?.id
                  )
                  .find((f) => f.spouseAmount || f.applicantAmount || f.otherHouseholdMemberAmount)
              ) && hasDocumentsTab;

            const debouncedSubmit = debounceWithTimeOut(() => onFormSubmit(isDocumentsTabVisible), 500);

            return (
              <>
                <Tabs
                  className="Tabs"
                  tabBarExtraContent={
                    <>
                      {isMarried && (
                        <ChangeTrackerFormItem name="isSpouseRefusedToProvideIncomeData" valuePropName="checked">
                          <Checkbox disabled={isLocked}>{t.SPOUSE_REFUSED_TO_PROVIDE_INCOME_DATA}</Checkbox>
                        </ChangeTrackerFormItem>
                      )}
                    </>
                  }
                  activeKey={activeTab}
                  onChange={handleTabChange}
                >
                  <TabPane tab={t.SOURCE} key={SOURCE_TAB} forceRender>
                    <Row gutter={20} align="middle">
                      <Col span={11}>
                        <Label className="ApplicationFormTab__label">{t.TYPE}</Label>
                      </Col>

                      <Col span={Boolean(isMarried) ? 4 : 6}>
                        <Label className="ApplicationFormTab__label">{t.APPLICANT_AMOUNT}</Label>
                      </Col>
                      {Boolean(isMarried) && (
                        <Col span={4}>
                          <Label className="ApplicationFormTab__label">{t.SPOUSE_AMOUNT}</Label>
                        </Col>
                      )}
                      <Col span={Boolean(isMarried) ? 4 : 6}>
                        <Label className="ApplicationFormTab__label">{t.OTHER_HOUSEHOLD_MEMBERS}</Label>
                      </Col>
                      <Col span={1}></Col>
                    </Row>
                    <Form.List name="incomeDetails">
                      {(incomeDetails, { add, remove }) => (
                        <>
                          {incomeDetails?.map((row, index) => (
                            <IncomeExpenseIncomeRow
                              key={`incomeDetails-${index}`}
                              form={form}
                              isMarried={isMarried}
                              incomeTypes={incomeTypes}
                              rowName={row.name}
                              remove={remove}
                              isLocked={isLocked}
                            />
                          ))}
                          <Row gutter={20}>
                            <Col span={8}>
                              <Form.Item
                                noStyle
                                shouldUpdate={(previousValue, currentValue) =>
                                  previousValue?.incomeDetails !== currentValue?.incomeDetails
                                }
                              >
                                {({ getFieldValue }) => {
                                  const incomeDetails = getFieldValue(
                                    'incomeDetails'
                                  ) as ClientService.AppFormIncomeExpenseIncomeDetailDto[];
                                  const isAnyRemaining =
                                    incomeTypes?.filter(
                                      (item) => !Boolean(incomeDetails?.find((i) => item?.id === i?.incomeTypeId))
                                    )?.length || 0;
                                  const isCurrentNotFilled = incomeDetails?.find((i) => !i.incomeTypeId);
                                  const isButtonVisible =
                                    ((!isCurrentNotFilled && isAnyRemaining > 0) ||
                                      (isCurrentNotFilled && isAnyRemaining - 1 > 0)) &&
                                    !isLocked;

                                  return (
                                    isButtonVisible && (
                                      <Form.Item>
                                        <div
                                          onClick={() =>
                                            isCurrentNotFilled
                                              ? {}
                                              : add({
                                                  incomeTypeId: undefined,
                                                  description: undefined,
                                                  applicantAmount: 0,
                                                  spouseAmount: 0,
                                                  otherHouseholdMemberAmount: 0,
                                                })
                                          }
                                          className="ApplicationFormTab__link-left"
                                        >
                                          {t.ADD_INCOME_EXPENSE}
                                        </div>
                                      </Form.Item>
                                    )
                                  );
                                }}
                              </Form.Item>
                            </Col>
                          </Row>
                        </>
                      )}
                    </Form.List>
                  </TabPane>

                  {isDocumentsTabVisible && (
                    <TabPane
                      tab={
                        <Row align="middle" gutter={5}>
                          <Col>{t.DOCUMENTS}</Col>
                          <Col>
                            {isMissingSupportingDocuments && (
                              <Icon iconName="AlertSolid" className="ApplicationFormTab__tab-error-icon" />
                            )}
                          </Col>
                        </Row>
                      }
                      key={DOCUMENTS_TAB}
                      forceRender
                    >
                      <AttachmentsTab
                        form={form}
                        applicationFileId={fileId}
                        supportingFolderCode="surplus-ie"
                        prefixCodes={prefixCodes}
                        isPrefixCodesRequired={true}
                        isFlatLayout
                        flatLayoutProps={{
                          hasInstruction: true,
                          hasSupportingList: true,
                          hasExistingFiles: true,
                          supportingListLabelHint: prefixHint && prefixHint?.length ? prefixHint : undefined,
                        }}
                        columnsProps={{
                          hasSizeColumn: true,
                          hasFolderColumn: false,
                          isDeleteDisabled: isLocked,
                        }}
                        draggerProps={{ disabled: isLocked }}
                        existingDocumentIds={existingDocumentIds}
                        updateUploadListenerStatus={setUploadListenerStatus}
                      />
                    </TabPane>
                  )}
                </Tabs>

                <Row gutter={12} justify="end">
                  <Col>
                    <Button kind="cancel" padding="large" onClick={onCancel}>
                      {t.CANCEL}
                    </Button>
                  </Col>
                  <Col>
                    <Button
                      kind="primary"
                      padding="large"
                      onClick={() => debouncedSubmit()}
                      disabled={!isAnyFieldFilled || (isLastTab && isLocked)}
                    >
                      {isDocumentsTabVisible && !isLastTab ? t.NEXT : t.SAVE}
                    </Button>
                  </Col>
                </Row>
              </>
            );
          }}
        </Form>
      </Spin>
    </Modal>
  );
}

export default IncomeExpenseIncome;
