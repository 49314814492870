import moment from 'moment';
import { DATE_FORMAT2 } from '../../../../../constants/common';
import { ClientService } from '../../../../../shared/api/ClientService';

export const RECEIPTS_DISBURSEMENTS_DROPDOWN_OPTIONS = [
  { value: ClientService.FileProcessStatusEnum.Pending, label: 'Pending', disabled: true },
  { value: ClientService.FileProcessStatusEnum.InProgress, label: 'In Progress', disabled: true },
  { value: ClientService.FileProcessStatusEnum.Complete, label: 'Complete' },
];

export const convertDateToReadable = (date?: moment.Moment) => (date ? moment(date)?.format(DATE_FORMAT2) : undefined);
