import { useCallback, useEffect, useState, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { Tabs, Row, Col, Collapse } from 'antd';
import { Icon } from '@fluentui/react/lib/Icon';

import ProposalDetails from './DataEntryForms/ProposalDetails';
import BankruptcyDetails from './DataEntryForms/BankruptcyDetails';
import TrusteeReportDetails from './DataEntryForms/TrusteeReportDetails';
import AssetsDetails from './DataEntryForms/AssetsDetails';
import DebtsCreditorsDetails from './DataEntryForms/DebtsCreditorsDetails';
import SurplusIncomeInformation from './DataEntryForms/SurplusIncomeInformation';
import SurplusIncomeTable from './Results/SurplusIncomeTable';
import FeesTable from './Results/FeesTable';
import CalculationsTable from './Results/CalculationsTable';
import EstimatedRealizationsTable from './Results/EstimatedRealizationsTable';
import Form48 from './Results/Form48';

import {
  FILE_ANALYSIS_FORM_48_TAB,
  FILE_ANALYSIS_ESTIMATED_REALIZATIONS_TAB,
  FILE_ANALYSIS_CALCULATIONS_TAB,
  FILE_ANALYSIS_SURPLUS_INCOME_TAB,
  FILE_ANALYSIS_FEES_TAB,
  FILE_ANALYSIS_PROPOSAL_DETAILS_TAB,
  FILE_ANALYSIS_BANKRUPTCY_DETAILS_TAB,
  FILE_ANALYSIS_TRUSTEE_REPORT_DETAILS_TAB,
  FILE_ANALYSIS_ASSETS_TAB,
  FILE_ANALYSIS_DEBTS_CREDITORS_TAB,
  FILE_ANALYSIS_SURPLUS_INCOME_INFO_TAB,
} from './constants';
import eventBus from '../../../utils/eventBus';
import useLocale from '../../../hooks/useLocale';

import { ClientService } from '../../../shared/api/ClientService';
import API from '../../../utils/api';

import './FileAnalysis.scss';

function FileAnalysis(): JSX.Element {
  const { TabPane } = Tabs;
  const { Panel } = Collapse;
  const { t } = useLocale();
  const { applicationFileId } = useParams<{ applicationFileId: string }>();

  const [calculations, setCalculations] = useState<ClientService.FileAnalysisCalculationsDto>();
  const [applicationFile, setApplicationFile] = useState<ClientService.IApplicationFileDto>();

  const requestApplicationFile = useCallback(async (applicationFileId: string) => {
    const response = await API.applicationFile2(applicationFileId);

    if (response) {
      setApplicationFile(response);
    }
  }, []);

  const requestFileAnalysisCalculations = useCallback(async () => {
    const response = await API.getFileAnalysisCalculations(applicationFileId);

    if (response) setCalculations(response);
  }, [applicationFileId]);

  useEffect(() => {
    if (!calculations) requestFileAnalysisCalculations();
  }, [calculations, requestFileAnalysisCalculations]);

  useEffect(() => {
    if (applicationFileId) requestApplicationFile(applicationFileId);
  }, [applicationFileId, requestApplicationFile]);

  useEffect(() => {
    eventBus.on('dataEntryFormValuesChanged', requestFileAnalysisCalculations);

    return () => {
      eventBus.remove('dataEntryFormValuesChanged', requestFileAnalysisCalculations);
    };
  }, [requestFileAnalysisCalculations]);

  const taxTypeLabel = calculations?.fileAnalysisEstimatedRealizationCalculations?.proposal?.taxTypeLabel;

  return (
    <div className="FileAnalysis">
      <Row gutter={30} className="FileAnalysis__data-entry-container">
        <Col span={9}>
          <Collapse
            accordion
            expandIcon={({ isActive }) => <Icon iconName={isActive ? 'ChevronUp' : 'ChevronDown'} />}
            destroyInactivePanel
            defaultActiveKey={FILE_ANALYSIS_PROPOSAL_DETAILS_TAB}
          >
            <Panel header={t.FILE_ANALYSIS_PROPOSAL_DETAILS} key={FILE_ANALYSIS_PROPOSAL_DETAILS_TAB}>
              <ProposalDetails disabled={applicationFile?.isLocked} />
            </Panel>
            <Panel header={t.FILE_ANALYSIS_BANKRUPTCY_DETAILS} key={FILE_ANALYSIS_BANKRUPTCY_DETAILS_TAB}>
              <BankruptcyDetails taxTypeLabel={taxTypeLabel} disabled={applicationFile?.isLocked} />
            </Panel>
            <Panel header={t.FILE_ANALYSIS_TRUSTEE_REPORT_DETAILS} key={FILE_ANALYSIS_TRUSTEE_REPORT_DETAILS_TAB}>
              <TrusteeReportDetails disabled={applicationFile?.isLocked} />
            </Panel>
            <Panel header={t.FILE_ANALYSIS_ASSETS} key={FILE_ANALYSIS_ASSETS_TAB}>
              <AssetsDetails
                disabled={applicationFile?.isLocked || applicationFile?.isAppFormLocked}
                applicationFile={applicationFile}
              />
            </Panel>
            <Panel header={t.FILE_ANALYSIS_DEBTS_CREDITORS} key={FILE_ANALYSIS_DEBTS_CREDITORS_TAB}>
              <DebtsCreditorsDetails
                disabled={applicationFile?.isLocked || applicationFile?.isAppFormLocked}
                applicationFile={applicationFile}
              />
            </Panel>
            <Panel header={t.FILE_ANALYSIS_SURPLUS_INCOME_INFO} key={FILE_ANALYSIS_SURPLUS_INCOME_INFO_TAB}>
              <SurplusIncomeInformation disabled={applicationFile?.isLocked} applicationFile={applicationFile} />
            </Panel>
          </Collapse>
        </Col>

        <Col span={15} className="FileAnalysis__results">
          <Tabs defaultActiveKey={FILE_ANALYSIS_ESTIMATED_REALIZATIONS_TAB} destroyInactiveTabPane>
            <TabPane tab={t.FILE_ANALYSIS_ESTIMATED_REALIZATIONS} key={FILE_ANALYSIS_ESTIMATED_REALIZATIONS_TAB}>
              <EstimatedRealizationsTable calculations={calculations?.fileAnalysisEstimatedRealizationCalculations} />
            </TabPane>
            <TabPane tab={t.FILE_ANALYSIS_FORM_48} key={FILE_ANALYSIS_FORM_48_TAB}>
              <Form48 text={calculations?.form48Text} />
            </TabPane>
            <TabPane tab={t.FILE_ANALYSIS_CALCULATIONS} key={FILE_ANALYSIS_CALCULATIONS_TAB}>
              <CalculationsTable calculations={calculations?.fileAnalysisResultsCalculations} />
            </TabPane>
            <TabPane tab={t.FILE_ANALYSIS_SURPLUS_INCOME} key={FILE_ANALYSIS_SURPLUS_INCOME_TAB}>
              <SurplusIncomeTable calculations={calculations?.fileAnalysisSurplusIncomeCalculations} />
            </TabPane>
            <TabPane tab={t.FILE_ANALYSIS_FEES} key={FILE_ANALYSIS_FEES_TAB}>
              <FeesTable
                calculations={calculations?.fileAnalysisFeeProposalBankruptcyCalculations}
                taxTypeLabel={taxTypeLabel}
              />
            </TabPane>
          </Tabs>
        </Col>
      </Row>
    </div>
  );
}

export default FileAnalysis;
