import { useState, useEffect, useCallback, useRef } from 'react';
import { useOutletContext, Outlet, useNavigate, useLocation } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { Icon } from '@fluentui/react/lib/Icon';

import PageTitleContainer from '../../components/PageTitleContainer/PageTitleContainer';
import Layout from '../../components/Layout/Layout';

import { ClientService } from '../../shared/api/ClientService';
import API from '../../utils/api';
import { ROUTES } from '../../constants/routes';
import { SCROLL_TO_TOP_EVENT } from '../../constants/eventBus';
import { eventBus } from '../../utils/eventBus';

import './ApplicationOverviewPage.scss';

type ApplicationFileContextType = {
  data: ClientService.IApplicationFileDto | null;
};

interface LocationState {
  fromDebtorProfile?: boolean;
}

const ApplicationOverviewPage = (): JSX.Element => {
  const { applicationFileId } = useParams<{ applicationFileId: string }>();
  const navigate = useNavigate();
  const location = useLocation();
  const state = location?.state as LocationState;

  const [data, setData] = useState<ClientService.IApplicationFileDto | null>(null);

  const [dataFetching, setDataFetching] = useState(true);
  const [dataError, setDataError] = useState(false);

  const fetchData = useCallback(() => {
    setDataError(false);
    setDataFetching(true);

    if (applicationFileId) {
      API.applicationFile2(applicationFileId)
        .then((response) => {
          setData(response);
          setDataFetching(false);
        })
        .catch(() => {
          setDataFetching(false);

          setDataError(true);
        });
    }
  }, [applicationFileId]);

  useEffect(() => {
    if (applicationFileId) {
      fetchData();
    }
  }, [applicationFileId, fetchData]);

  const bodyRef = useRef<null | HTMLDivElement>(null);

  const scrollToTop = useCallback(() => {
    bodyRef?.current?.scrollIntoView();
  }, []);

  useEffect(() => {
    eventBus.on(SCROLL_TO_TOP_EVENT, scrollToTop);

    return () => {
      eventBus.remove(SCROLL_TO_TOP_EVENT, scrollToTop);
    };
  }, [scrollToTop]);

  return (
    <Layout
      loading={dataFetching}
      error={dataError}
      retry={fetchData}
      className="ApplicationOverviewPage"
      page={ROUTES.APPLICATION_OVERVIEW}
    >
      <div ref={bodyRef}>
        <PageTitleContainer
          title={data?.debtorName}
          titleIcon={
            !Boolean(state?.fromDebtorProfile) && (
              <Icon iconName="Edit" onClick={() => navigate(`${ROUTES.DEBTOR_PROFILE}/${applicationFileId}`)} />
            )
          }
          hasToolbar={!Boolean(state?.fromDebtorProfile)}
          style={{ paddingTop: 24, paddingLeft: 40 }}
        />
        <div style={{ paddingLeft: 40 }}>
          <Outlet context={{ data }} />
        </div>
      </div>
    </Layout>
  );
};

export default ApplicationOverviewPage;

export function useApplicationFile() {
  return useOutletContext<ApplicationFileContextType>();
}
