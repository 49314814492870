import { useState, useEffect, useMemo } from 'react';
import moment from 'moment';
import { DATE_AND_TIME_FORMAT } from '../../../../../constants/common';
import ExpandableRecordsDisplay, {
  IRecordField,
  ITableColumn,
} from '../components/ExpandableRecordsDisplay/ExpandableRecordsDisplay';
import { ClientService } from '../../../../../shared/api/ClientService';
import { getClaimStateValueAsElement } from '../../../helpers';
import useLocale from '../../../../../hooks/useLocale';
import ProcessStatusSelection from '../components/ProcessStatusSelection/ProcessStatusSelection';
import API from '../../../../../utils/api';
import { ROUTES } from '../../../../../constants/routes';
import { argumentifyAssetsSearchCriteria, argumentifyClaimsSearchCriteria } from '../utils';
import { splitOnCapital } from '../../../../../utils/helpers';
import { eventBus } from '../../../../../utils/eventBus';
import { ADMIN_INFO_CHANGE_EVENT } from '../../../../../constants/eventBus';
import { MAX_PAGE_SIZE } from '../../../../../constants/common';

interface IProps {
  data: ClientService.IApplicationFileDto | null;
  filingTypesList: ClientService.ILookupDto[] | null;
  handleUpdateData: () => void;
  disableAllFields?: boolean;
}

const AssetRealization = ({
  data,
  filingTypesList,
  handleUpdateData,
  disableAllFields = false,
}: IProps): JSX.Element => {
  const { t } = useLocale();

  const [processStatus, setProcessStatus] = useState<ClientService.AdminInfoAssetRealizationDto | undefined>();

  const assetRecordFields: IRecordField[] = [
    { label: t.ADMIN_INFO_AR_ASSETFIELDS_DESC, dataIndex: 'description', width: '300px' },
    { label: t.ADMIN_INFO_AR_ASSETFIELDS_EST, dataIndex: 'estimatedAmount', format: 'currency' },
    { label: t.ADMIN_INFO_AR_ASSETFIELDS_REAL, dataIndex: 'realizable', format: 'currency' },
    { label: t.ADMIN_INFO_AR_ASSETFIELDS_COMPLETED, dataIndex: 'completed' },
    { label: t.ADMIN_INFO_AR_ASSETFIELDS_EXEMPT, dataIndex: 'exempt' },
  ];

  const claimByAssetFields: IRecordField[] = [
    { label: t.ADMIN_INFO_AR_CLAIMBYASSETFIELDS_CREDITOR_INFO, dataIndex: 'creditor' },
    { label: t.ADMIN_INFO_AR_ASSETFIELDS_COMPLETED, dataIndex: 'completed' },
    { label: t.ADMIN_INFO_AR_CLAIMBYASSETFIELDS_SECURED, dataIndex: 'secured', width: '150px', format: 'currency' },
  ];

  const ClaimFilesTableCols: ITableColumn[] = [
    { title: t.ADMIN_INFO_DETAILS_TABLE_COL_RECEIVED, dataIndex: 'received' },
    { title: t.ADMIN_INFO_DETAILS_TABLE_COL_ASSIGNED_TO, dataIndex: 'assignedTo' },
    { title: t.RESULT, dataIndex: 'status' },
    { title: t.ADMIN_INFO_DETAILS_TABLE_COL_DISCREPANCY, dataIndex: 'discrepancy' },
    { title: t.ADMIN_INFO_DETAILS_TABLE_COL_STATE, dataIndex: 'state' },
  ];

  const fileId = useMemo((): string => {
    if (data?.id) {
      return data.id;
    }
    return '';
  }, [data?.id]);

  // const fileName = useMemo((): string => {
  //   if (data?.applicationFileName) {
  //     return data.applicationFileName;
  //   }
  //   return '';
  // }, [data?.applicationFileName]);

  useEffect(() => {
    if (fileId) {
      API.securedGET(fileId).then((response) => {
        setProcessStatus(response);
      });
    }
  }, [fileId]);

  return (
    <div>
      <ProcessStatusSelection
        label={t.ADMIN_INFO_ASSET_REALIZATION_STATUS}
        changedFormProtection
        disableAllFields={disableAllFields}
        status={processStatus?.assetRealizationProcessStatus}
        allItemsCompleted={processStatus?.completedAllClaims}
        apiSubmit={(values) => {
          return API.securedPUT(fileId, {
            assetRealizationProcessStatus: values.processStatus,
          } as ClientService.AdminInfoAssetRealizationUpdateDto);
        }}
        onSubmitResponseSuccess={() => eventBus.dispatch(ADMIN_INFO_CHANGE_EVENT)}
      />
      <ExpandableRecordsDisplay
        recordFields={assetRecordFields}
        apiGetMasterRecords={() => {
          return API.assets(...argumentifyAssetsSearchCriteria({ id: fileId, maxResultCount: MAX_PAGE_SIZE })); // pass applicationFileId instead of filename
        }}
        apiSetMasterRecords={(response, setMasterRecords) => {
          // console.log('ASSET REALIZATION GetMasterRecords response:', response);
          const masterRecords = response.map((asset: ClientService.AssetDto) => {
            return {
              id: asset.externalEntityId,
              leadValue: splitOnCapital(ClientService.AssetType[asset.assetType as number]),
              description: asset.description,
              estimatedAmount: asset.estimatedAmount,
              realizable: asset.realizableAmount,
              completed: asset.isFullyRealized ? t.YES : t.NO,
              exempt: asset.isExempt ? t.YES : t.NO,
            };
          });
          // console.log('ASSET REALIZATION masterRecords:', masterRecords);
          setMasterRecords(masterRecords);
        }}
        extraMasterRecords={[
          {
            id: 'x',
            leadValue: '-',
            description: t.ADMIN_INFO_ASSET_REALIZATION_NO_ASSET_SECURED_CLAIM,
            estimatedAmount: undefined,
            realizable: undefined,
            completed: undefined,
            exempt: undefined,
          },
        ]}
        secondaryFields={claimByAssetFields}
        argsForSecondaryApiGet={['leadValue']}
        apiGetSecondaryRecords={({ id: assetId, leadValue }) => {
          if (assetId && assetId !== 'x') {
            // connected to asset
            return API.liabilities(
              ...argumentifyClaimsSearchCriteria({
                id: fileId,
                externalAssetId: assetId,
                maxResultCount: MAX_PAGE_SIZE,
              })
            );
          }
          return API.liabilities(
            ...argumentifyClaimsSearchCriteria({
              id: fileId,
              isSecuredWithoutAssets: true,
              maxResultCount: MAX_PAGE_SIZE,
            })
          );
        }}
        apiSetSecondaryRecords={(response, setSecondaryRecords) => {
          const secondaryRecords = response.map((claim: ClientService.LiabilityClaimDto) => {
            return {
              id: claim.externalEntityId,
              creditor: `${claim.creditor?.companyName} ${claim.accountNumber}`,
              completed: claim.isCompleted ? t.YES : t.NO,
              secured: claim.securedSoaAmount,
            };
          });
          setSecondaryRecords(secondaryRecords);
        }}
        tableColumns={ClaimFilesTableCols}
        apiGetTableData={(liabilityClaimId) => {
          // console.log('ASSET REALIZATION getTableData claimId:', liabilityClaimId);
          return API.claimFiles(fileId, liabilityClaimId as string);
        }}
        apiSetTableData={(response, setTableData) => {
          // console.log('ASSET REALIZATION GetTableData response', response);
          const claimFileStatusValues = Object.keys(ClientService.ClaimFileStatusEnum).filter((key: any) => {
            return !isNaN(Number(ClientService.ClaimFileStatusEnum[key]));
          });
          const claimFiles = response.map((claimFile: ClientService.ClaimFileDto) => {
            return {
              id: claimFile.id,
              received:
                claimFile.receivedOn != null ? moment(claimFile.receivedOn).format(DATE_AND_TIME_FORMAT) : undefined,
              assignedTo: claimFile.assignedAgent?.fullName,
              status: claimFile.status != null ? claimFileStatusValues[claimFile.status] : undefined,
              discrepancy: claimFile.isDiscrepancy ? t.YES : t.NO,
              state: getClaimStateValueAsElement(claimFile.state),
            };
          });
          setTableData(claimFiles);
        }}
        viewTableRecordRoute={`${ROUTES.APPLICATION_OVERVIEW}/${fileId}/${ROUTES.CLAIMS}`}
      />
    </div>
  );
};

export default AssetRealization;
