import { useCallback, useEffect, useState } from 'react';
import { Modal, Form, Select, Col, Row, Spin, Input, Tabs } from 'antd';

import Button from '../../../../../../../components/Button/Button';
import SearchLocationInput from '../../../../../../../components/SearchLocationInput/SearchLocationInput';
import { LocationDto } from '../../../../../../../modals/GoogleMaps/types';
import SelectSuffixIcon from '../../../../../../../components/SelectSuffixIcon/SelectSuffixIcon';

import useLocale from '../../../../../../../hooks/useLocale';
import genericMessage from '../../../../../../../utils/genericMessage';
import { AuthorizeService } from '../../../../../../../components/Auth/AuthorizeService';
import { ClientService } from '../../../../../../../shared/api/ClientService';
import API from '../../../../../../../utils/api';

import './PADRequestReviewModal.scss';

interface IProps {
  id?: string;
  applicationFileId: string;
  onOk: () => void;
  onCancel: () => void;
}

const DETAILS_TAB = 'details';
const REVIEW_TAB = 'review';

const PADRequestReviewModal = ({ id, applicationFileId, onOk, onCancel }: IProps) => {
  const { t } = useLocale();
  const [form] = Form.useForm<ClientService.PADInfoChangeRequestDto>();
  const { Option } = Select;
  const { TabPane } = Tabs;
  const { TextArea } = Input;
  const user = AuthorizeService.getCurrentUserInfo();

  const [activeKey, setActiveKey] = useState<string>(id ? DETAILS_TAB : REVIEW_TAB);
  const [loading, setLoading] = useState<boolean>(false);

  const requestPADInfoChangeRequest = useCallback(
    async (id, applicationFileId) => {
      setLoading(true);
      const response = await API.padInfoChangeRequestGET(id, applicationFileId).catch(() => setLoading(false));
      setLoading(false);

      if (response) {
        form.setFieldsValue({ ...response, reviewStatus: undefined });
      }
    },
    [form]
  );

  const requestCreatePADInfoChangeRequest = useCallback(
    async (values: ClientService.PADInfoChangeRequestDto) => {
      setLoading(true);
      const response = await API.padInfoChangeRequestPOST(
        applicationFileId as string,
        {
          ...values,
          agentId: user?.profile?.sub,
        } as ClientService.PADInfoChangeRequestCreateDto
      ).catch(() => setLoading(false));
      setLoading(false);

      if (response?.result === ClientService.Result.Successful) {
        if (response?.hasWarnings) {
          let warningMessage = response.messages?.find((i) => i.messageType == ClientService.ResultMessageType.Warning);
          if (warningMessage != undefined && warningMessage.body) {
            genericMessage.warning(warningMessage.body);
          }
        }
        genericMessage.success(t.PAD_INFO_CHANGE_REQUEST_IS_CREATED);
        onOk();
      }
    },
    [applicationFileId, onOk, t.PAD_INFO_CHANGE_REQUEST_IS_CREATED, user?.profile?.sub]
  );

  const requestUpdatePADInfoChangeRequest = useCallback(
    async (values: ClientService.PADInfoChangeRequestDto) => {
      setLoading(true);

      let info = new ClientService.PADInfoChangeRequestUpdateDto(values);

      const response = await API.padInfoChangeRequestPUT(
        id as string,
        values?.reviewStatus,
        values?.rejectionReason,
        applicationFileId as string,
        info
      ).catch(() => setLoading(false));
      setLoading(false);

      if (response?.result === ClientService.Result.Successful) {
        genericMessage.success(t.PAD_INFO_CHANGE_REQUEST_IS_UPDATED);
        onOk();
      }
    },
    [applicationFileId, id, onOk, t.PAD_INFO_CHANGE_REQUEST_IS_UPDATED]
  );

  const handleTabChange = useCallback(
    (key: string) => {
      if (activeKey === DETAILS_TAB) {
        form
          .validateFields(['accountName', 'accountNumber', 'transitNumber', 'branchNumber', 'bankName', 'bankAddress'])
          .then(() => setActiveKey(key));
      } else {
        setActiveKey(key);
      }
    },
    [activeKey, form]
  );

  const handleSubmit = useCallback(() => {
    form.validateFields().then(async (values) => {
      if (id) {
        requestUpdatePADInfoChangeRequest(values);
      } else {
        requestCreatePADInfoChangeRequest(values);
      }
    });
  }, [form, id, requestCreatePADInfoChangeRequest, requestUpdatePADInfoChangeRequest]);

  const handleNextOrSubmit = useCallback(() => {
    if (activeKey === DETAILS_TAB) {
      handleTabChange(REVIEW_TAB);
    } else {
      handleSubmit();
    }
  }, [activeKey, handleSubmit, handleTabChange]);

  useEffect(() => {
    if (id && applicationFileId) requestPADInfoChangeRequest(id, applicationFileId);
  }, [id, applicationFileId, requestPADInfoChangeRequest]);

  return (
    <Modal
      destroyOnClose
      centered
      visible
      title={t.REVIEW_PAD_REQUEST}
      className="PADRequestReviewModal"
      width={750}
      footer={null}
      closable
      onCancel={onCancel}
    >
      <Spin spinning={loading}>
        <Form form={form} layout="vertical">
          <Tabs activeKey={activeKey} onTabClick={handleTabChange}>
            {id && (
              <TabPane tab={t.DETAILS} key={DETAILS_TAB} style={{ minHeight: 400 }}>
                <Row gutter={24}>
                  <Col span={12}>
                    <Form.Item
                      label={t.ACCOUNT_NAME}
                      name="accountName"
                      rules={[{ required: true, message: t.REQUIRED_FIELD }]}
                    >
                      <Input size="large" />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      label={t.BANK_NAME}
                      name="bankName"
                      rules={[{ required: true, message: t.REQUIRED_FIELD }]}
                    >
                      <Input size="large" />
                    </Form.Item>
                  </Col>
                  <Col span={24}>
                    <Form.Item noStyle shouldUpdate>
                      {({ getFieldValue, setFieldsValue }) => (
                        <Form.Item
                          label={t.BANK_ADDRESS}
                          name="bankAddress"
                          rules={[{ required: true, message: t.REQUIRED_FIELD }]}
                        >
                          <SearchLocationInput
                            address={{ streetName: getFieldValue('bankAddress') }}
                            onSelect={(address: LocationDto) => setFieldsValue({ bankAddress: address.address })}
                            size="large"
                          />
                        </Form.Item>
                      )}
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item
                      label={t.ACCOUNT_NUMBER}
                      name="accountNumber"
                      rules={[{ required: true, message: t.REQUIRED_FIELD }]}
                    >
                      <Input size="large" />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item
                      label={t.TRANSIT_NUMBER}
                      name="transitNumber"
                      rules={[{ required: true, message: t.REQUIRED_FIELD }]}
                    >
                      <Input size="large" />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item
                      label={t.BRANCH_NUMBER}
                      name="branchNumber"
                      rules={[{ required: true, message: t.REQUIRED_FIELD }]}
                    >
                      <Input size="large" />
                    </Form.Item>
                  </Col>
                </Row>
              </TabPane>
            )}
            <TabPane tab={t.REVIEW} key={REVIEW_TAB} style={{ minHeight: 400 }}>
              <Row>
                <Col span={12}>
                  <Form.Item
                    label={t.REVIEW_STATUS}
                    name="reviewStatus"
                    rules={[{ required: true, message: t.REQUIRED_FIELD }]}
                  >
                    <Select size="large" suffixIcon={<SelectSuffixIcon />}>
                      <Option value={ClientService.PADRequestReviewStatusEnum.Approved}>Approved</Option>
                      <Option value={ClientService.PADRequestReviewStatusEnum.Rejected}>Rejected</Option>
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item noStyle shouldUpdate>
                    {({ getFieldValue }) =>
                      getFieldValue('reviewStatus') === ClientService.PADRequestReviewStatusEnum.Rejected && (
                        <Form.Item
                          label={t.REJECT_REASON}
                          name="rejectionReason"
                          rules={[{ required: true, message: t.REQUIRED_FIELD }]}
                        >
                          <TextArea rows={4} />
                        </Form.Item>
                      )
                    }
                  </Form.Item>
                </Col>
              </Row>
            </TabPane>
          </Tabs>
          <Row gutter={20} justify="end" className="PADRequestReviewModal__footer">
            <Col>
              <Form.Item noStyle shouldUpdate>
                <Button kind="cancel" onClick={onCancel}>
                  {t.CANCEL}
                </Button>
              </Form.Item>
            </Col>
            <Col>
              <Form.Item noStyle shouldUpdate>
                <Button kind="primary" onClick={handleNextOrSubmit}>
                  {activeKey === DETAILS_TAB ? t.NEXT : t.SAVE}
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Spin>
    </Modal>
  );
};

export default PADRequestReviewModal;
