import { useCallback, useEffect, useState, useRef, useMemo } from 'react';
import { Col, Collapse, Divider, Form, Input, Radio, RadioChangeEvent, Row, Select, Spin } from 'antd';
import { Icon } from '@fluentui/react/lib/Icon';
import { ValidateErrorEntity } from 'rc-field-form/es/interface';
import { Label } from '@fluentui/react';
import { DeleteOutlined } from '@ant-design/icons';
import update from 'immutability-helper';

import Button from '../../../../components/Button/Button';
import SearchLocationInput from '../../../../components/SearchLocationInput/SearchLocationInput';
import { LocationDto } from '../../../../modals/GoogleMaps';
import SelectSuffixIcon from '../../../../components/SelectSuffixIcon/SelectSuffixIcon';
import QuestionnaireQuestion from './QuestionnaireQuestion';
import QuestionnaireBusinessRow from './QuestionnaireBusinessRow';
import ErrorComponent, { IError } from './ErrorComponent';
import ActionConfirmationModal from '../../../../modals/ActionConfirmationModal/ActionConfirmationModal';
import AttachmentsTab, {
  argumentifyDocumentsSearchCriteria,
  AttachmentsFormProps,
} from '../../../../components/AttachmentsTab';
import ChangeTrackerFormItem from '../../../../components/ChangeTrackerFormItem';

import useUnsaved from '../../../../hooks/useUnsaved';
import useModal from '../../../../hooks/useModal';
import useLocale from '../../../../hooks/useLocale';

import { APPFORM_SAVE_AND_LEAVE_EVENT, UPLOAD_FILES_EVENT } from '../../../../constants/eventBus';
import {
  convertInputDateToServerDateOnly,
  convertServerDateOnlyToInputDate,
  debounceWithTimeOut,
} from '../../../../utils/helpers';
import genericMessage from '../../../../utils/genericMessage';
import { antdUtil } from '../../../../utils/antdUtil';
import eventBus from '../../../../utils/eventBus';
import { ClientService } from '../../../../shared/api/ClientService';
import API from '../../../../utils/api';
import './ApplicationFormTab.scss';
import FinancialAdviceProviderRow from './FinancialAdviceProviderRow';
import { MAX_PAGE_SIZE } from '../../../../constants/common';

interface IProps {
  data: ClientService.IApplicationFileDto | null;
  updateStatus: () => void;
}

const debtAresApplicantFields = [
  'isDebtFromFineOrPenaltyImposedByCourt',
  'isDebtFromRecognizanceOfBailBond',
  'isDebtFromAlimony',
  'isDebtFromMaintenanceOfAffiliationOrder',
  'isDebtFromMaintenanceOfSupportOfSeparatedFamily',
  'isDebtFromFraud',
  'isDebtFromEmbezzlement',
  'isDebtFromMisappropriation',
  'isDebtFromDefalcationWhileActingInAFiduciaryCapacity',
  'isDebtFromPropertyOrServicesObtainedByFalseMeansOrFraud',
  'isDebtFromStudentLoansOutstanding',
];

const coSignedOrGuaranteedLoansFileds = ['lendersName', 'lendersAddress', 'borrowersName', 'borrowersAddress'];

const transactionsApplicantFields = [
  'hasMadeExessPaymentsPast12Months',
  'hasAssetsSiezedOrGarnishedPast12Months',
  'hasSoldDisposedOrTransferredRealProperlyOrOtherAssetsPast5Years',
  'isExpectedToReceiveNonIncomeFundsNext12Months',
  'hasBeenInvolvedInCivilLitigationReceivingMoneyOrProperty',
  'hasMadeArragementsToContinuePayingCreditors',
];

const transactionsSpouseFields = [
  'hasSpouseMadeExessPaymentsPast12Months',
  'hasSpouseAssetsSiezedOrGarnishedPast12Months',
  'hasSpouseSoldDisposedOrTransferredRealProperlyOrOtherAssetsPast5Years',
  'hasSpouseGivenGiftsOver500WhileInsolventPast5Years',
  'isSpouseExpectedToReceiveNonIncomeFundsNext12Months',
  'hasSpouseBeenInvolvedInCivilLitigationReceivingMoneyOrProperty',
  'hasSpouseMadeArragementsToContinuePayingCreditors',
];

const businessesFields = ['businesses', 'spouseBusinesses'];
const financialAdviceFields = [
  'financialAdvices',
  'spouseFinancialAdvices',
  'debtorHasAFinancialAdviceProvider',
  'debtorSpouseHasAFinancialAdviceProvider',
];

const supportingDocumentsFields = ['existingFiles'];
type IClientUpdatedPanelsType = Pick<
  ClientService.AppFormQuestionnaireDto,
  | 'isBusinessUpdatedByClient'
  | 'isCoSignsGuaranteedLoansUpdatedByClient'
  | 'isCreditBureauConsentUpdatedByClient'
  | 'isDebtAreasApplicantUpdatedByClient'
  | 'isDebtAreasSpouseUpdatedByClient'
  | 'isTransactionsApplicantUpdatedByClient'
  | 'isTransactionsSpouseUpdatedByClient'
  | 'isAdviceReceivedApplicantUpdatedByClient'
>;

const DEBT_AREAS_APPLICANT_TAB = 'debtAreasApplicant';
const DEBT_AREAS_SPOUSE_TAB = 'debtAreasSpouse';
const CO_SIGNED_OR_GUARANTEED_LOANS_TAB = 'coSignedOrGuaranteedLoans';
const TRANSACTIONS_APPLICANT_TAB = 'transactionsApplicant';
const TRANSACTIONS_SPOUSE_TAB = 'transactionsSpouse';
const BUSINESSES_TAB = 'businesses';
const CREDIT_BUREAU_CONSENT_TAB = 'creditBureauConsent';
const FINANCIAL_ADVICE_TAB = 'financialAdvices';

interface IForm extends ClientService.AppFormQuestionnaireDto, AttachmentsFormProps {}

const Questionnaire = ({ data, updateStatus }: IProps): JSX.Element => {
  const { t } = useLocale();
  const { Panel } = Collapse;
  const { Option } = Select;
  const { showModal, closeModal } = useModal();

  const [form] = Form.useForm<IForm>();
  const [formErrors, setFormErrors] = useState<IError[]>();
  const { setIsUnsavedForm } = useUnsaved();
  const isAppFormLocked = useMemo(
    () => Boolean(data?.isAppFormLocked) || Boolean(data?.isLocked),
    [data?.isAppFormLocked, data?.isLocked]
  );

  const [clientUpdatedPanels, setClientUpdatedPanels] = useState<IClientUpdatedPanelsType>();
  const [currentTab, setCurrentTab] = useState<string | undefined>('1');

  const [isReviewed, setIsReviewed] = useState(false);
  const [needToBeReviewed, setNeedToBeReviewed] = useState(false);
  const [isCompleted, setIsCompleted] = useState(false);

  const [isMarried, setIsMarried] = useState<boolean>(false);
  const [isJointFile, setIsJointFile] = useState<boolean>(false);

  const [countries, setCountries] = useState<ClientService.LookupDto[]>();
  const [businessOwnershipTypes, setBusinessOwnershipTypes] = useState<ClientService.LookupDto[]>([]);
  const [businessTypes, setBusinessTypes] = useState<ClientService.LookupDto[]>([]);
  const [businessNames, setBusinessNames] = useState<(string | undefined)[]>([]);

  const [hasCoSignOrGuaranteedLoan, setHasCoSignOrGuaranteedLoan] = useState<boolean>(false);
  const [hasOwnedBusinessPast5Years, setHasOwnedBusinessPast5Years] = useState<boolean>(false);
  const [hasSpouseOwnedBusinessPast5Years, setHasSpouseOwnedBusinessPast5Years] = useState<boolean>(false);
  const [debtorHasAFinancialAdviceProvider, setDebtorHasAFinancialAdviceProvider] = useState<boolean>(false);
  const [debtorSpouseHasAFinancialAdviceProvider, setDebtorSpouseHasAFinancialAdviceProvider] =
    useState<boolean>(false);
  const [existingDocumentIds, setExistingDocumentIds] = useState<string[]>([]);

  const [isPrevalidated, setIsPrevalidated] = useState<boolean>(false);
  const [questionnaireLoading, setQuestionnaireLoading] = useState<boolean>(true);

  const refDebtAresApplicant = useRef<HTMLDivElement>(null);
  const refCoSignedOrGuaranteedLoans = useRef<HTMLDivElement>(null);
  const refTransactionsApplicant = useRef<HTMLDivElement>(null);
  const refTransactionsSpouse = useRef<HTMLDivElement>(null);
  const refBusinesses = useRef<HTMLDivElement>(null);
  const refFinancialAdvices = useRef<HTMLDivElement>(null);
  const refSupportingDocuments = useRef<HTMLDivElement>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const adjustResponse = useCallback((response: ClientService.AppFormQuestionnaireDto) => {
    if (!response.hasCoSignedOrGuaranteedLoan) {
      if (!response.coSignedOrGuaranteedLoans) {
        response.coSignedOrGuaranteedLoans = [];
      }
      response.coSignedOrGuaranteedLoans?.push(new ClientService.AppFormQuestionnaireCoSignOrGuaranteedLoanDto());
    }
    if (!response.hasOwnedBusinessPast5Years) {
      if (!response.businesses) {
        response.businesses = [];
      }
      response.businesses?.push(new ClientService.AppFormQuestionnaireBusinessDto());
    }
    if (!response.hasSpouseOwnedBusinessPast5Years) {
      if (!response.spouseBusinesses) {
        response.spouseBusinesses = [];
      }
      response.spouseBusinesses?.push(new ClientService.AppFormQuestionnaireBusinessDto());
    }
    if (!response.debtorHasAFinancialAdviceProvider) {
      if (!response.financialAdvices) {
        response.financialAdvices = [];
      }
      response.financialAdvices?.push(new ClientService.AppFormQuestionnaireFinancialAdviceProviderDto());
    }
    if (!response.debtorSpouseHasAFinancialAdviceProvider) {
      if (!response.spouseFinancialAdvices) {
        response.spouseFinancialAdvices = [];
      }
      response.spouseFinancialAdvices?.push(new ClientService.AppFormQuestionnaireFinancialAdviceProviderDto());
    }
    return response;
  }, []);

  const saveAndFinalize = useCallback(() => {
    if (isLoading) return;
    try {
      setIsLoading(true);
      form.submit();
    } catch (e) {
      console.log(e);
    } finally {
      setIsLoading(false);
    }
  }, [form, isLoading, setIsLoading]);

  const checkIfDocumentUploadHasErrors = useCallback(
    () =>
      form
        .validateFields()
        .then(() => false)
        .catch((e: ValidateErrorEntity) => {
          console.log(e?.errorFields);
          return Boolean(
            e?.errorFields
              ?.map((field) => field?.name)
              ?.flat()
              ?.find((i) => i === 'newFiles')
          );
        }),
    [form]
  );

  const preFillCollections = useCallback(() => {
    API.listCountries().then((response) => {
      setCountries(response);
    });

    API.listBusinessOwnershipTypes().then((response) => {
      setBusinessOwnershipTypes(response);
    });

    API.listBusinessTypes().then((response) => {
      setBusinessTypes(response);
    });
  }, []);

  const handleCoSignOrGuaranteedLoanRadioChanged = useCallback(
    (e: RadioChangeEvent) => {
      setHasCoSignOrGuaranteedLoan(e?.target?.value);

      if (e?.target?.value)
        form.setFieldsValue({
          coSignedOrGuaranteedLoans: [
            { isBySpouse: false } as ClientService.AppFormQuestionnaireCoSignOrGuaranteedLoanDto,
          ],
        });
    },
    [form]
  );

  const handleAutofilledAddressSplitForLenderAddress = useCallback(
    async ({ country, address, city, postalCode, streetName, streetNumber, province }: LocationDto) => {
      const countryId = countries?.find((item) => item.code === country?.code)?.id;

      const provinces = await API.listProvinces(countryId);
      const provinceId = provinces.find((item) => item.code === province?.code)?.id;

      return {
        lendersAddress: address,
        lendersCity: city,
        lendersPostalCode: postalCode,
        lendersStreetName: streetName,
        lendersStreetNumber: streetNumber,
        lendersCountryId: countryId,
        lendersProvinceId: provinceId,
      };
    },
    [countries]
  );

  const handleLenderAddressAutocomplete = useCallback(
    (row: number) => async (autofilledAddress: LocationDto) => {
      const updatedAddress = await handleAutofilledAddressSplitForLenderAddress(autofilledAddress);
      form.setFieldsValue(
        update(form.getFieldsValue(), {
          coSignedOrGuaranteedLoans: {
            [row]: { $merge: updatedAddress },
          },
        })
      );
    },
    [form, handleAutofilledAddressSplitForLenderAddress]
  );

  const handleAutofilledAddressSplitForBorrowerAddress = useCallback(
    async ({ country, address, city, postalCode, streetName, streetNumber, province }: LocationDto) => {
      const countryId = countries?.find((item) => item.code === country?.code)?.id;

      const provinces = await API.listProvinces(countryId);
      const provinceId = provinces.find((item) => item.code === province?.code)?.id;

      return {
        borrowersAddress: address,
        borrowersCity: city,
        borrowersPostalCode: postalCode,
        borrowersStreetName: streetName,
        borrowersStreetNumber: streetNumber,
        borrowersCountryId: countryId,
        borrowersProvinceId: provinceId,
      };
    },
    [countries]
  );

  const handleBorrowerAddressAutocomplete = useCallback(
    (row: number) => async (autofilledAddress: LocationDto) => {
      const updatedAddress = await handleAutofilledAddressSplitForBorrowerAddress(autofilledAddress);
      form.setFieldsValue(
        update(form.getFieldsValue(), {
          coSignedOrGuaranteedLoans: {
            [row]: { $merge: updatedAddress },
          },
        })
      );
    },
    [form, handleAutofilledAddressSplitForBorrowerAddress]
  );

  const handleOwnedBusinessPast5YearsRadioChanged = (e: RadioChangeEvent) => {
    setHasOwnedBusinessPast5Years(e?.target?.value);
  };

  const handleSpouseOwnedBusinessPast5YearsRadioChanged = (e: RadioChangeEvent) => {
    setHasSpouseOwnedBusinessPast5Years(e?.target?.value);
  };

  const handleAutofilledAddressSplitForBusinessAddress = useCallback(
    async ({ country, address, city, postalCode, streetName, streetNumber, province }: LocationDto) => {
      const countryId = countries?.find((item) => item.code === country?.code)?.id;

      const provinces = await API.listProvinces(countryId);
      const provinceId = provinces.find((item) => item.code === province?.code)?.id;

      return {
        businessAddress: address,
        businessCity: city,
        businessPostalCode: postalCode,
        businessStreetName: streetName,
        businessStreetNumber: streetNumber,
        businessCountryId: countryId,
        businessProvinceId: provinceId,
      };
    },
    [countries]
  );

  const handleBusinessAddressAutocomplete = useCallback(
    (row: number) => async (autofilledAddress: LocationDto) => {
      const updatedAddress = await handleAutofilledAddressSplitForBusinessAddress(autofilledAddress);
      form.setFieldsValue(
        update(form.getFieldsValue(), {
          businesses: {
            [row]: { $merge: updatedAddress },
          },
        })
      );
    },
    [form, handleAutofilledAddressSplitForBusinessAddress]
  );

  const handleSpouseBusinessAddressAutocomplete = useCallback(
    (row: number) => async (autofilledAddress: LocationDto) => {
      const updatedAddress = await handleAutofilledAddressSplitForBusinessAddress(autofilledAddress);
      form.setFieldsValue(
        update(form.getFieldsValue(), {
          spouseBusinesses: {
            [row]: { $merge: updatedAddress },
          },
        })
      );
    },
    [form, handleAutofilledAddressSplitForBusinessAddress]
  );

  const handleBusinessNamesChange = useCallback((businesses: ClientService.AppFormQuestionnaireBusinessDto[]) => {
    const businessNames = businesses?.map((item) => (item?.businessName || '')?.trim())?.filter(Boolean);
    setBusinessNames(businessNames || []);
  }, []);

  const handleDebtorHasAFinancialAdviceProviderRadioChanged = (e: RadioChangeEvent) => {
    setDebtorHasAFinancialAdviceProvider(e?.target?.value);
    if (e?.target?.value)
      form.setFieldsValue({
        financialAdvices: [{} as ClientService.AppFormQuestionnaireFinancialAdviceProviderDto],
      });
  };

  const handleDebtorSpouseHasAFinancialAdviceProviderRadioChanged = (e: RadioChangeEvent) => {
    setDebtorSpouseHasAFinancialAdviceProvider(e?.target?.value);
    if (e?.target?.value)
      form.setFieldsValue({
        spouseFinancialAdvices: [{} as ClientService.AppFormQuestionnaireFinancialAdviceProviderDto],
      });
  };

  const handleAutofilledAddressSplitForFinancialAdviceProvider = useCallback(
    async ({ country, address, city, postalCode, streetName, streetNumber, province }: LocationDto) => {
      const countryId = countries?.find((item) => item.code === country?.code)?.id;

      const provinces = await API.listProvinces(countryId);
      const provinceId = provinces.find((item) => item.code === province?.code)?.id;

      return {
        firmAddress: address,
        firmCity: city,
        firmPostalCode: postalCode,
        firmStreetName: streetName,
        firmStreetNumber: streetNumber,
        firmCountryId: countryId,
        firmProvinceId: provinceId,
      };
    },
    [countries]
  );

  const handleFinancialProviderAddressAutocomplete = useCallback(
    (row: number) => async (autofilledAddress: LocationDto) => {
      const updatedAddress = await handleAutofilledAddressSplitForFinancialAdviceProvider(autofilledAddress);
      form.setFieldsValue(
        update(form.getFieldsValue(), {
          financialAdvices: {
            [row]: { $merge: updatedAddress },
          },
        })
      );
    },
    [form, handleAutofilledAddressSplitForFinancialAdviceProvider]
  );

  const handleSpouseFinancialProviderAddressAutocomplete = useCallback(
    (row: number) => async (autofilledAddress: LocationDto) => {
      const updatedAddress = await handleAutofilledAddressSplitForFinancialAdviceProvider(autofilledAddress);
      form.setFieldsValue(
        update(form.getFieldsValue(), {
          spouseFinancialAdvices: {
            [row]: { $merge: updatedAddress },
          },
        })
      );
    },
    [form, handleAutofilledAddressSplitForFinancialAdviceProvider]
  );

  const refreshStatus = useCallback(() => {
    API.getQuestionnaireStatus(data?.id).then((response) => {
      setIsReviewed(response.status === ClientService.AppFormStatusEnum.Reviewed);
      setIsCompleted(response.status === ClientService.AppFormStatusEnum.Complete);
      setNeedToBeReviewed(
        response.status === ClientService.AppFormStatusEnum.ReadyForReview ||
          response.status === ClientService.AppFormStatusEnum.Incomplete_Review
      );
    });
  }, [data?.id]);

  const markComplete = useCallback(() => {
    API.questionnaireMarkAsComplete(data?.id).then((_) => {
      updateStatus();
      refreshStatus();
    });
  }, [data?.id, refreshStatus, updateStatus]);

  const markReviewed = useCallback(() => {
    API.questionnaireMarkAsReviewed(data?.id).then((_) => {
      updateStatus();
      refreshStatus();
    });
  }, [data?.id, refreshStatus, updateStatus]);

  const prefillForm = useCallback(
    (response: ClientService.AppFormQuestionnaireDto) => {
      form.setFieldsValue({
        ...response,
        debtFromStudentLoansOutstandingDateCeasedBeingStudent: convertServerDateOnlyToInputDate(
          response?.debtFromStudentLoansOutstandingDateCeasedBeingStudent
        ),
        spouseDebtFromStudentLoansOutstandingDateCeasedBeingStudent: convertServerDateOnlyToInputDate(
          response?.spouseDebtFromStudentLoansOutstandingDateCeasedBeingStudent
        ),
        businesses: response?.businesses?.map(
          (item: ClientService.AppFormQuestionnaireBusinessDto) =>
            ({
              ...item,
              startDate: convertServerDateOnlyToInputDate(item?.startDate),
              dateOperationsCeased: convertServerDateOnlyToInputDate(item?.dateOperationsCeased),
            } as ClientService.AppFormQuestionnaireBusinessDto)
        ),
        spouseBusinesses: response?.spouseBusinesses?.map(
          (item: ClientService.AppFormQuestionnaireBusinessDto) =>
            ({
              ...item,
              startDate: convertServerDateOnlyToInputDate(item?.startDate),
              dateOperationsCeased: convertServerDateOnlyToInputDate(item?.dateOperationsCeased),
            } as ClientService.AppFormQuestionnaireBusinessDto)
        ),
        financialAdvices: response?.financialAdvices?.map(
          (item: ClientService.AppFormQuestionnaireFinancialAdviceProviderDto) =>
            ({
              ...item,
            } as ClientService.AppFormQuestionnaireFinancialAdviceProviderDto)
        ),
        spouseFinancialAdvices: response?.spouseFinancialAdvices?.map(
          (item: ClientService.AppFormQuestionnaireFinancialAdviceProviderDto) =>
            ({
              ...item,
            } as ClientService.AppFormQuestionnaireFinancialAdviceProviderDto)
        ),
      } as ClientService.AppFormQuestionnaireDto);
    },
    [form]
  );

  const updateErrorsWithRequiredDocuments = useCallback(
    (totalCount?: number) =>
      setFormErrors((e) => {
        const filtered = e?.filter((item) => item?.name?.join() !== 'existingFiles') || [];

        return Number(totalCount || 0) < 1
          ? [...filtered, { name: ['existingFiles'], errors: [t.REQUIRED_DOCUMENT_FOR_BUSINESS_IS_MISSING] }]
          : filtered;
      }),
    [t.REQUIRED_DOCUMENT_FOR_BUSINESS_IS_MISSING]
  );

  const requestQuestionnaireInfoDocuments = useCallback(
    async (totalCount?: number) => {
      updateErrorsWithRequiredDocuments(totalCount);
    },
    [updateErrorsWithRequiredDocuments]
  );

  const checkIfAllBusinessHasAtLeastOneDocument = useCallback(async () => {
    API.getAppFormQuestionnaire(data?.id).then(async (response) => {
      const folders = await API.listFolders();
      const documentFolderId = folders?.find((item) => item.code === 'surplus-ie')?.id;

      const documents = await API.documentsGET(
        ...argumentifyDocumentsSearchCriteria({
          fileId: data?.id,
          documentFolderId,
          documentPrefixCodes: ['Business'],
          maxResultCount: MAX_PAGE_SIZE,
        })
      );

      if (response?.hasOwnedBusinessPast5Years || response?.hasSpouseOwnedBusinessPast5Years) {
        requestQuestionnaireInfoDocuments(
          // Total number of uploaded files are equal or more than total number of applican's and spouse's businesses
          (response.businesses?.length ?? 0) + (response.spouseBusinesses?.length ?? 0) <=
            (documents?.items?.length ?? 0) &&
            // For each applicant's business we have at least one document
            response.businesses?.every((applicantBusiness) =>
              documents?.items?.some((doc) => applicantBusiness.businessName === doc.businessName)
            ) &&
            // For each spouse's business we have at least one document
            response.spouseBusinesses?.every((spouseBusiness) =>
              documents?.items?.some((doc) => spouseBusiness.businessName === doc.businessName)
            )
            ? 1
            : 0
        );
      }
    });
  }, [data?.id, requestQuestionnaireInfoDocuments]);

  const handleQuestionnaireUpdateRequest = useCallback(
    (dto?: ClientService.AppFormQuestionnaireDto, onSaveSuccess?: () => void) => {
      eventBus.dispatch(UPLOAD_FILES_EVENT, {
        onUploadSuccess: async (documentIds: string[]) => {
          setQuestionnaireLoading(true);

          const response = await API.updateAppFormQuestionnaire({
            ...dto,
            documentIds: documentIds ?? [],
            fileId: data?.id,
            debtFromStudentLoansOutstandingDateCeasedBeingStudent: convertInputDateToServerDateOnly(
              dto?.debtFromStudentLoansOutstandingDateCeasedBeingStudent
            ),
            spouseDebtFromStudentLoansOutstandingDateCeasedBeingStudent: convertInputDateToServerDateOnly(
              dto?.spouseDebtFromStudentLoansOutstandingDateCeasedBeingStudent
            ),
            businesses: dto?.businesses?.map(
              (item: ClientService.AppFormQuestionnaireBusinessDto) =>
                ({
                  ...item,
                  startDate: convertInputDateToServerDateOnly(item?.startDate),
                  dateOperationsCeased: convertInputDateToServerDateOnly(item?.dateOperationsCeased),
                } as ClientService.AppFormQuestionnaireBusinessDto)
            ),
            spouseBusinesses: dto?.spouseBusinesses?.map(
              (item: ClientService.AppFormQuestionnaireBusinessDto) =>
                ({
                  ...item,
                  startDate: convertInputDateToServerDateOnly(item?.startDate),
                  dateOperationsCeased: convertInputDateToServerDateOnly(item?.dateOperationsCeased),
                } as ClientService.AppFormQuestionnaireBusinessDto)
            ),
            financialAdvices: dto?.financialAdvices?.map(
              (item: ClientService.AppFormQuestionnaireFinancialAdviceProviderDto) =>
                ({
                  ...item,
                } as ClientService.AppFormQuestionnaireFinancialAdviceProviderDto)
            ),
            spouseFinancialAdvices: dto?.spouseFinancialAdvices?.map(
              (item: ClientService.AppFormQuestionnaireFinancialAdviceProviderDto) =>
                ({
                  ...item,
                } as ClientService.AppFormQuestionnaireFinancialAdviceProviderDto)
            ),
          } as ClientService.AppFormQuestionnaireDto).catch(() => {
            setQuestionnaireLoading(false);
          });

          if (response?.result === ClientService.Result.Successful) {
            setQuestionnaireLoading(false);
            genericMessage.success(t.SUCCESSFULLY_SAVED);
            setIsUnsavedForm(false);
            updateStatus();
            refreshStatus();

            if (onSaveSuccess) {
              onSaveSuccess();
            }

            API.getAppFormQuestionnaire(data?.id).then(async (response) => {
              prefillForm(adjustResponse(response));
              setExistingDocumentIds(response?.documentIds || []);
              checkIfAllBusinessHasAtLeastOneDocument();
            });
          } else {
            setQuestionnaireLoading(false);
            genericMessage.error({}, response?.messages?.[0]?.body);
          }
        },
      });
    },
    [
      adjustResponse,
      data?.id,
      prefillForm,
      refreshStatus,
      requestQuestionnaireInfoDocuments,
      setIsUnsavedForm,
      t.SUCCESSFULLY_SAVED,
      updateStatus,
      checkIfAllBusinessHasAtLeastOneDocument,
    ]
  );

  const onFinish = useCallback(
    async (dto?: ClientService.AppFormQuestionnaireDto, onSaveSuccess?: () => void) => {
      if (!dto || isAppFormLocked) return;

      setFormErrors(form.getFieldsError());
      setCurrentTab(undefined);

      if (isPrevalidated) {
        const documentUploadErrors = await checkIfDocumentUploadHasErrors();
        if (documentUploadErrors) {
          showModal(
            <ActionConfirmationModal
              title={t.CONFIRMATION}
              message={t.QUESTIONNAIRE_DOCUMENTS_UPLOAD_ERROR_CONFIRM}
              okText={t.LEAVE}
              cancelText={t.STAY}
              onOk={() => {
                closeModal();
                handleQuestionnaireUpdateRequest(dto, onSaveSuccess);
              }}
              onCancel={closeModal}
            />
          );
        } else {
          handleQuestionnaireUpdateRequest(dto, onSaveSuccess);
        }
      } else {
        checkIfAllBusinessHasAtLeastOneDocument();
        setIsPrevalidated(true);
      }
    },
    [
      isAppFormLocked,
      form,
      isPrevalidated,
      checkIfAllBusinessHasAtLeastOneDocument,
      checkIfDocumentUploadHasErrors,
      showModal,
      t.CONFIRMATION,
      t.QUESTIONNAIRE_DOCUMENTS_UPLOAD_ERROR_CONFIRM,
      t.LEAVE,
      t.STAY,
      closeModal,
      handleQuestionnaireUpdateRequest,
    ]
  );

  const getPanelFieldsErrors = useCallback(
    (panelFields?: string[]) => {
      if (!panelFields) return undefined;

      const filtered = formErrors?.filter(
        (item) =>
          (item?.errors?.length || item?.warnings?.length) &&
          Boolean(item?.name?.find((name) => panelFields.indexOf(String(name)) > -1))
      );

      return filtered?.length ? filtered : undefined;
    },
    [formErrors]
  );

  const onClickError = useCallback((tab: string, tabRef: React.RefObject<HTMLDivElement>) => {
    setCurrentTab(tab);
    setTimeout(() => tabRef?.current?.scrollIntoView({ block: 'start', behavior: 'smooth', inline: 'start' }), 500);
  }, []);

  const hasPanelErrors = useCallback(
    (errors?: IError[]) => Boolean(errors?.filter((item) => item?.errors?.length)?.length),
    []
  );

  const saveAndLeave = useCallback(
    (e) => {
      onFinish(form.getFieldsValue(), e?.detail?.onSaveSuccess);
    },
    [form, onFinish]
  );

  const handleFormValuesChange = useCallback(
    (values) => {
      Object.keys(values).forEach((field) => {
        const error = form.getFieldError(field);
        if (!error.length) {
          return;
        }
        form.setFields([
          {
            name: field,
            errors: [],
          },
        ]);
      });
    },
    [form]
  );

  const updateReviewedPanels = useCallback((panelKey?: string) => {
    switch (panelKey) {
      case DEBT_AREAS_APPLICANT_TAB:
        return setClientUpdatedPanels((prev) => ({ ...prev, isDebtAreasApplicantUpdatedByClient: false }));
      case DEBT_AREAS_SPOUSE_TAB:
        return setClientUpdatedPanels((prev) => ({ ...prev, isDebtAreasSpouseUpdatedByClient: false }));
      case CO_SIGNED_OR_GUARANTEED_LOANS_TAB:
        return setClientUpdatedPanels((prev) => ({ ...prev, isCoSignsGuaranteedLoansUpdatedByClient: false }));
      case TRANSACTIONS_APPLICANT_TAB:
        return setClientUpdatedPanels((prev) => ({ ...prev, isTransactionsApplicantUpdatedByClient: false }));
      case TRANSACTIONS_SPOUSE_TAB:
        return setClientUpdatedPanels((prev) => ({ ...prev, isTransactionsSpouseUpdatedByClient: false }));
      case BUSINESSES_TAB:
        return setClientUpdatedPanels((prev) => ({ ...prev, isBusinessUpdatedByClient: false }));
      case CREDIT_BUREAU_CONSENT_TAB:
        return setClientUpdatedPanels((prev) => ({ ...prev, isCreditBureauConsentUpdatedByClient: false }));
      case FINANCIAL_ADVICE_TAB:
        return setClientUpdatedPanels((prev) => ({ ...prev, isAdviceReceivedApplicantUpdatedByClient: false }));
      default:
        return;
    }
  }, []);

  const handleTabChange = useCallback(
    (key: string | string[]) => {
      const newTab = Array.isArray(key) ? key?.[0] : key;
      setCurrentTab(newTab);
      updateReviewedPanels(newTab);
    },
    [updateReviewedPanels]
  );

  useEffect(() => {
    return () => setIsUnsavedForm(false);
  }, [setIsUnsavedForm]);

  useEffect(() => {
    eventBus.on(APPFORM_SAVE_AND_LEAVE_EVENT, saveAndLeave);

    return () => {
      eventBus.remove(APPFORM_SAVE_AND_LEAVE_EVENT, saveAndLeave);
    };
  }, [saveAndLeave]);

  useEffect(() => {
    preFillCollections();

    API.getAppFormPersonalInfo(data?.id).then(async (response) => {
      setIsJointFile(response.isJointFile ?? false);
    });

    API.getAppFormQuestionnaire(data?.id).then(async (response) => {
      setClientUpdatedPanels({
        isBusinessUpdatedByClient: response?.isBusinessUpdatedByClient,
        isCoSignsGuaranteedLoansUpdatedByClient: response?.isCoSignsGuaranteedLoansUpdatedByClient,
        isCreditBureauConsentUpdatedByClient: response?.isCreditBureauConsentUpdatedByClient,
        isDebtAreasApplicantUpdatedByClient: response?.isDebtAreasApplicantUpdatedByClient,
        isDebtAreasSpouseUpdatedByClient: response?.isDebtAreasSpouseUpdatedByClient,
        isTransactionsApplicantUpdatedByClient: response?.isTransactionsApplicantUpdatedByClient,
        isTransactionsSpouseUpdatedByClient: response?.isTransactionsSpouseUpdatedByClient,
        isAdviceReceivedApplicantUpdatedByClient: response?.isAdviceReceivedApplicantUpdatedByClient,
      });

      prefillForm(adjustResponse(response));

      setIsMarried(response.isMarried || false);
      setExistingDocumentIds(response?.documentIds || []);
      setHasCoSignOrGuaranteedLoan(response?.hasCoSignedOrGuaranteedLoan || false);
      setHasOwnedBusinessPast5Years(response?.hasOwnedBusinessPast5Years || false);
      setHasSpouseOwnedBusinessPast5Years(response?.hasSpouseOwnedBusinessPast5Years || false);
      setDebtorHasAFinancialAdviceProvider(response?.debtorHasAFinancialAdviceProvider || false);
      setDebtorSpouseHasAFinancialAdviceProvider(response?.debtorSpouseHasAFinancialAdviceProvider || false);
      setQuestionnaireLoading(false);

      const questionnaireInfoFormStatus = await API.getQuestionnaireStatus(data?.id);
      const isNotStarted = questionnaireInfoFormStatus?.status === ClientService.AppFormStatusEnum.NotStarted;

      if (!isAppFormLocked && !isNotStarted) {
        form.submit();
      }

      if (isNotStarted) {
        setIsPrevalidated(true);
      }

      if (
        (response?.hasOwnedBusinessPast5Years || response?.hasSpouseOwnedBusinessPast5Years) &&
        response?.businesses
      ) {
        handleBusinessNamesChange([...(response?.businesses || []), ...(response?.spouseBusinesses || [])]);
      }
    });

    refreshStatus();
  }, [
    adjustResponse,
    data?.id,
    form,
    handleBusinessNamesChange,
    isAppFormLocked,
    preFillCollections,
    prefillForm,
    refreshStatus,
    requestQuestionnaireInfoDocuments,
    needToBeReviewed,
  ]);

  return (
    <Spin spinning={questionnaireLoading}>
      <Form
        form={form}
        onFinish={(values) => onFinish(values)}
        onFinishFailed={() => onFinish(form.getFieldsValue())}
        onValuesChange={(changedValues, values) => {
          setIsUnsavedForm(true);
          handleFormValuesChange(changedValues);
          handleBusinessNamesChange([...(values?.businesses || []), ...(values?.spouseBusinesses || [])]);
        }}
        layout="vertical"
        className="ApplicationFormTab"
        validateTrigger={['onSubmit', 'onBlur']}
        validateMessages={antdUtil.validateMessages}
        preserve={false}
      >
        {Boolean(formErrors?.filter((item) => item?.errors?.length || item?.warnings?.length)?.length) &&
          !isAppFormLocked && (
            <div className="ApplicationFormTab__errors-container">
              <div className="ApplicationFormTab__errors-container--title">{t.VALIDATION_ERRORS_AND_WARNINGS}</div>
              <ErrorComponent
                errors={getPanelFieldsErrors(debtAresApplicantFields)}
                onClick={() => onClickError('1', refDebtAresApplicant)}
              />
              <ErrorComponent
                errors={getPanelFieldsErrors(financialAdviceFields)}
                onClick={() => onClickError('2', refFinancialAdvices)}
              />
              <ErrorComponent
                errors={getPanelFieldsErrors(coSignedOrGuaranteedLoansFileds)}
                onClick={() => onClickError('3', refCoSignedOrGuaranteedLoans)}
              />
              <ErrorComponent
                errors={getPanelFieldsErrors(transactionsApplicantFields)}
                onClick={() => onClickError('4', refTransactionsApplicant)}
              />
              <ErrorComponent
                errors={getPanelFieldsErrors(transactionsSpouseFields)}
                onClick={() => onClickError('5', refTransactionsSpouse)}
              />
              <ErrorComponent
                errors={getPanelFieldsErrors(businessesFields)}
                onClick={() => onClickError('6', refBusinesses)}
              />
              <ErrorComponent
                errors={getPanelFieldsErrors(supportingDocumentsFields)}
                onClick={() => onClickError('7', refSupportingDocuments)}
              />
            </div>
          )}
        <ChangeTrackerFormItem name="clientUpdatedFields" hidden />
        <Collapse
          ghost
          activeKey={currentTab}
          onChange={handleTabChange}
          accordion
          expandIcon={({ isActive }) => (
            <div
              className={`ms-Icon ms-Icon--${
                isActive ? 'ChevronUp' : 'ChevronRight'
              } ApplicationOverviewPage__chevron-icon`}
            />
          )}
        >
          {/* Debt Areas - Applicant */}
          <Panel
            className={clientUpdatedPanels?.isDebtAreasApplicantUpdatedByClient ? 'user-changed' : ''}
            header={
              <Row gutter={6} ref={refDebtAresApplicant}>
                <Col>{t.DEBT_AREAS_APPLICANT}</Col>
                <Col>
                  {hasPanelErrors(getPanelFieldsErrors(debtAresApplicantFields)) && (
                    <Icon iconName="AlertSolid" className="ApplicationFormTab__panel-header-error" />
                  )}
                </Col>
              </Row>
            }
            key={DEBT_AREAS_APPLICANT_TAB}
            forceRender
          >
            <div className="ApplicationFormTab__header">{t.DO_YOU_HAVE_ANY_DEBTS_ARISING_FROM}</div>
            <Row gutter={20}>
              <QuestionnaireQuestion
                label={t.FINE_OR_PENALTY_IMPOSED_BY_COURT_INCLUDING_ASSAULT}
                flagName="isDebtFromFineOrPenaltyImposedByCourt"
                flagRequired
                detailsName="debtFromFineOrPenaltyImposedByCourtDetails"
                isLocked={isAppFormLocked}
              />
              <Divider dashed className="ApplicationFormTab__questionnaire-question-divider" />
              <QuestionnaireQuestion
                label={t.RECOGNIZANCE_OF_BAIL_BOND}
                flagName="isDebtFromRecognizanceOfBailBond"
                flagRequired
                detailsName="debtFromRecognizanceOfBailBondDetails"
                isLocked={isAppFormLocked}
              />
              <Divider dashed className="ApplicationFormTab__questionnaire-question-divider" />
              <QuestionnaireQuestion
                label={t.ALIMONY}
                flagName="isDebtFromAlimony"
                flagRequired
                detailsName="debtFromAlimonyDetails"
                isLocked={isAppFormLocked}
              />
              <Divider dashed className="ApplicationFormTab__questionnaire-question-divider" />
              <QuestionnaireQuestion
                label={t.MAINTENANCE_OF_AFFILIATION_ORDER}
                flagName="isDebtFromMaintenanceOfAffiliationOrder"
                flagRequired
                detailsName="debtFromMaintenanceOfAffiliationOrderDetails"
                isLocked={isAppFormLocked}
              />
              <Divider dashed className="ApplicationFormTab__questionnaire-question-divider" />
              <QuestionnaireQuestion
                label={t.MAINTENANCE_OF_SUPPORT_OF_SEPARATED_FAMILY}
                flagName="isDebtFromMaintenanceOfSupportOfSeparatedFamily"
                flagRequired
                detailsName="debtFromMaintenanceOfSupportOfSeparatedFamilyDetails"
                isLocked={isAppFormLocked}
              />
              <Divider dashed className="ApplicationFormTab__questionnaire-question-divider" />
              <QuestionnaireQuestion
                label={t.FRAUD}
                flagName="isDebtFromFraud"
                flagRequired
                detailsName="debtFromFraudDetails"
                isLocked={isAppFormLocked}
              />
              <Divider dashed className="ApplicationFormTab__questionnaire-question-divider" />
              <QuestionnaireQuestion
                label={t.EMBEZZLEMENT}
                flagName="isDebtFromEmbezzlement"
                flagRequired
                detailsName="debtFromEmbezzlementDetails"
                isLocked={isAppFormLocked}
              />
              <Divider dashed className="ApplicationFormTab__questionnaire-question-divider" />
              <QuestionnaireQuestion
                label={t.MISAPPROPRIATION}
                flagName="isDebtFromMisappropriation"
                flagRequired
                detailsName="debtFromMisappropriationDetails"
                isLocked={isAppFormLocked}
              />
              <Divider dashed className="ApplicationFormTab__questionnaire-question-divider" />
              <QuestionnaireQuestion
                label={t.DEFALCATION_WHILE_ACTING_IN_A_FIDUCIARY_CAPACITY}
                flagName="isDebtFromDefalcationWhileActingInAFiduciaryCapacity"
                flagRequired
                detailsName="debtFromDefalcationWhileActingInAFiduciaryCapacityDetails"
                isLocked={isAppFormLocked}
              />
              <Divider dashed className="ApplicationFormTab__questionnaire-question-divider" />
              <QuestionnaireQuestion
                label={t.PROPERTY_OR_SERVICES_OBTAINED_BY_FALSE_MEANSFRAUD}
                flagName="isDebtFromPropertyOrServicesObtainedByFalseMeansOrFraud"
                flagRequired
                detailsName="debtFromPropertyOrServicesObtainedByFalseMeansOrFraudDetails"
                isLocked={isAppFormLocked}
              />
              <Divider dashed className="ApplicationFormTab__questionnaire-question-divider" />
              <QuestionnaireQuestion
                label={t.STUDENT_LOANS_OUTSTANDING}
                flagName="isDebtFromStudentLoansOutstanding"
                flagRequired
                detailsName="debtFromStudentLoansOutstandingDetails"
                dateName="debtFromStudentLoansOutstandingDateCeasedBeingStudent"
                dateLabel={t.DATE_YOU_CEASED_BEING_FULL_TIMEPART_TIME_STUDENT}
                isLocked={isAppFormLocked}
              />
            </Row>
          </Panel>

          {/* Debt Areas - Spouse */}
          {isMarried && (
            <Panel
              className={clientUpdatedPanels?.isDebtAreasSpouseUpdatedByClient ? 'user-changed' : ''}
              header={t.DEBT_AREAS_SPOUSE_PARTNER}
              key={DEBT_AREAS_SPOUSE_TAB}
              forceRender
            >
              <div className="ApplicationFormTab__header">{t.DO_YOU_HAVE_ANY_DEBTS_ARISING_FROM}</div>
              <Row gutter={20}>
                <QuestionnaireQuestion
                  label={t.FINE_OR_PENALTY_IMPOSED_BY_COURT_INCLUDING_ASSAULT}
                  flagName="isSpouseDebtFromFineOrPenaltyImposedByCourt"
                  detailsName="spouseDebtFromFineOrPenaltyImposedByCourtDetails"
                  isLocked={isAppFormLocked}
                />
                <Divider dashed className="ApplicationFormTab__questionnaire-question-divider" />
                <QuestionnaireQuestion
                  label={t.RECOGNIZANCE_OF_BAIL_BOND}
                  flagName="isSpouseDebtFromRecognizanceOfBailBond"
                  detailsName="spouseDebtFromRecognizanceOfBailBondDetails"
                  isLocked={isAppFormLocked}
                />
                <Divider dashed className="ApplicationFormTab__questionnaire-question-divider" />
                <QuestionnaireQuestion
                  label={t.ALIMONY}
                  flagName="isSpouseDebtFromAlimony"
                  detailsName="spouseDebtFromAlimonyDetails"
                  isLocked={isAppFormLocked}
                />
                <Divider dashed className="ApplicationFormTab__questionnaire-question-divider" />
                <QuestionnaireQuestion
                  label={t.MAINTENANCE_OF_AFFILIATION_ORDER}
                  flagName="isSpouseDebtFromMaintenanceOfAffiliationOrder"
                  detailsName="spouseDebtFromMaintenanceOfAffiliationOrderDetails"
                  isLocked={isAppFormLocked}
                />
                <Divider dashed className="ApplicationFormTab__questionnaire-question-divider" />
                <QuestionnaireQuestion
                  label={t.MAINTENANCE_OF_SUPPORT_OF_SEPARATED_FAMILY}
                  flagName="isSpouseDebtFromMaintenanceOfSupportOfSeparatedFamily"
                  detailsName="spouseDebtFromMaintenanceOfSupportOfSeparatedFamilyDetails"
                  isLocked={isAppFormLocked}
                />
                <Divider dashed className="ApplicationFormTab__questionnaire-question-divider" />
                <QuestionnaireQuestion
                  label={t.FRAUD}
                  flagName="isSpouseDebtFromFraud"
                  detailsName="spouseDebtFromFraudDetails"
                  isLocked={isAppFormLocked}
                />
                <Divider dashed className="ApplicationFormTab__questionnaire-question-divider" />
                <QuestionnaireQuestion
                  label={t.EMBEZZLEMENT}
                  flagName="isSpouseDebtFromEmbezzlement"
                  detailsName="spouseDebtFromEmbezzlementDetails"
                  isLocked={isAppFormLocked}
                />
                <Divider dashed className="ApplicationFormTab__questionnaire-question-divider" />
                <QuestionnaireQuestion
                  label={t.MISAPPROPRIATION}
                  flagName="isSpouseDebtFromMisappropriation"
                  detailsName="spouseDebtFromMisappropriationDetails"
                  isLocked={isAppFormLocked}
                />
                <Divider dashed className="ApplicationFormTab__questionnaire-question-divider" />
                <QuestionnaireQuestion
                  label={t.DEFALCATION_WHILE_ACTING_IN_A_FIDUCIARY_CAPACITY}
                  flagName="isSpouseDebtFromDefalcationWhileActingInAFiduciaryCapacity"
                  detailsName="spouseDebtFromDefalcationWhileActingInAFiduciaryCapacityDetails"
                  isLocked={isAppFormLocked}
                />
                <Divider dashed className="ApplicationFormTab__questionnaire-question-divider" />
                <QuestionnaireQuestion
                  label={t.PROPERTY_OR_SERVICES_OBTAINED_BY_FALSE_MEANSFRAUD}
                  flagName="isSpouseDebtFromPropertyOrServicesObtainedByFalseMeansOrFraud"
                  detailsName="spouseDebtFromPropertyOrServicesObtainedByFalseMeansOrFraudDetails"
                  isLocked={isAppFormLocked}
                />
                <Divider dashed className="ApplicationFormTab__questionnaire-question-divider" />
                <QuestionnaireQuestion
                  label={t.STUDENT_LOANS_OUTSTANDING}
                  flagName="isSpouseDebtFromStudentLoansOutstanding"
                  detailsName="spouseDebtFromStudentLoansOutstandingDetails"
                  dateName="spouseDebtFromStudentLoansOutstandingDateCeasedBeingStudent"
                  dateLabel={t.DATE_YOU_CEASED_BEING_FULL_TIMEPART_TIME_STUDENT}
                  isLocked={isAppFormLocked}
                />
              </Row>
            </Panel>
          )}

          {/* Financial Provider Advice */}
          <Panel
            className={clientUpdatedPanels?.isAdviceReceivedApplicantUpdatedByClient ? 'user-changed' : ''}
            header={
              <Row gutter={6} ref={refFinancialAdvices}>
                <Col>{t.ADVICE_RECEIVED_BY_OTHER_CONSULTANTS_APPLICANT_TITLE}</Col>
                <Col>
                  {hasPanelErrors([...(getPanelFieldsErrors(financialAdviceFields) || [])]) && (
                    <Icon iconName="AlertSolid" className="ApplicationFormTab__panel-header-error" />
                  )}
                </Col>
              </Row>
            }
            key={FINANCIAL_ADVICE_TAB}
            forceRender
          >
            <div className="ApplicationFormTab__header">
              {t.ADVICE_RECEIVED_BY_OTHER_CONSULTANTS_APPLICANT_TITLE_APPLICANT}
            </div>
            <Row gutter={20}>
              <Col span={8}>
                <ChangeTrackerFormItem
                  name="debtorHasAFinancialAdviceProvider"
                  label={t.ADVICE_RECEIVED_BY_OTHER_CONSULTANTS_APPLICANT_RADIO_SUBTITLE}
                  rules={[{ required: true, message: t.REQUIRED_FIELD }]}
                >
                  <Radio.Group
                    size="large"
                    buttonStyle="solid"
                    optionType="button"
                    onChange={handleDebtorHasAFinancialAdviceProviderRadioChanged}
                    disabled={isAppFormLocked}
                  >
                    <Radio.Button value={true}>{t.YES}</Radio.Button>
                    <Radio.Button value={false}>{t.NO}</Radio.Button>
                  </Radio.Group>
                </ChangeTrackerFormItem>
              </Col>
              <Col span={16}></Col>
            </Row>

            {debtorHasAFinancialAdviceProvider && (
              <Form.List name="financialAdvices">
                {(financialAdvices, { add, remove }) => (
                  <>
                    {financialAdvices?.map((row, index) => (
                      <FinancialAdviceProviderRow
                        key={`financialAdvices-${index}`}
                        isRequired={true}
                        rowName={row.name}
                        onAddressSelect={handleFinancialProviderAddressAutocomplete}
                        remove={remove}
                        isLocked={isAppFormLocked}
                        listName="financialAdvices"
                      />
                    ))}
                    {!isAppFormLocked && (
                      <Row gutter={20}>
                        <Col span={8}>
                          <ChangeTrackerFormItem>
                            <div onClick={() => add()} className="ApplicationFormTab__link-left">
                              {t.ADVICE_RECEIVED_BY_OTHER_CONSULTANTS_APPLICANT_ADD_PROVIDER_BUTTON_TEXT}
                            </div>
                          </ChangeTrackerFormItem>
                        </Col>
                      </Row>
                    )}
                  </>
                )}
              </Form.List>
            )}

            {isJointFile && (
              <>
                <div className="ApplicationFormTab__header">
                  {t.ADVICE_RECEIVED_BY_OTHER_CONSULTANTS_APPLICANT_TITLE_SPOUSE}
                </div>
                <Row gutter={20}>
                  <Col span={8}>
                    <ChangeTrackerFormItem
                      name="debtorSpouseHasAFinancialAdviceProvider"
                      label={t.ADVICE_RECEIVED_BY_OTHER_CONSULTANTS_APPLICANT_RADIO_SUBTITLE}
                      rules={[{ required: isJointFile, message: t.REQUIRED_FIELD }]}
                    >
                      <Radio.Group
                        size="large"
                        buttonStyle="solid"
                        optionType="button"
                        onChange={handleDebtorSpouseHasAFinancialAdviceProviderRadioChanged}
                        disabled={isAppFormLocked}
                      >
                        <Radio.Button value={true}>{t.YES}</Radio.Button>
                        <Radio.Button value={false}>{t.NO}</Radio.Button>
                      </Radio.Group>
                    </ChangeTrackerFormItem>
                  </Col>
                  <Col span={16}></Col>
                </Row>

                {debtorSpouseHasAFinancialAdviceProvider && (
                  <Form.List name="spouseFinancialAdvices">
                    {(spouseFinancialAdvices, { add, remove }) => (
                      <>
                        {spouseFinancialAdvices?.map((row, index) => (
                          <FinancialAdviceProviderRow
                            key={`spouseFinancialAdvices-${index}`}
                            isRequired={isJointFile}
                            rowName={row.name}
                            onAddressSelect={handleSpouseFinancialProviderAddressAutocomplete}
                            remove={remove}
                            isLocked={isAppFormLocked}
                            listName="spouseFinancialAdvices"
                          />
                        ))}
                        {!isAppFormLocked && (
                          <Row gutter={20}>
                            <Col span={8}>
                              <ChangeTrackerFormItem>
                                <div onClick={() => add()} className="ApplicationFormTab__link-left">
                                  {t.ADVICE_RECEIVED_BY_OTHER_CONSULTANTS_APPLICANT_ADD_PROVIDER_BUTTON_TEXT}
                                </div>
                              </ChangeTrackerFormItem>
                            </Col>
                          </Row>
                        )}
                      </>
                    )}
                  </Form.List>
                )}
              </>
            )}
          </Panel>

          {/* Co-Signs or Guaranteed Loans */}
          <Panel
            className={clientUpdatedPanels?.isCoSignsGuaranteedLoansUpdatedByClient ? 'user-changed' : ''}
            header={
              <Row gutter={6} ref={refCoSignedOrGuaranteedLoans}>
                <Col>{t.CO_SIGNS_OR_GUARANTEED_LOANS}</Col>
                <Col>
                  {hasPanelErrors(getPanelFieldsErrors(coSignedOrGuaranteedLoansFileds)) && (
                    <Icon iconName="AlertSolid" className="ApplicationFormTab__panel-header-error" />
                  )}
                </Col>
              </Row>
            }
            key={CO_SIGNED_OR_GUARANTEED_LOANS_TAB}
            forceRender
          >
            <Row gutter={20} style={{ paddingTop: 24 }}>
              <Col span={8}>
                <ChangeTrackerFormItem name="hasCoSignedOrGuaranteedLoan" label={t.ANY_COSIGNED_OR_GUARANTEED_LOANS}>
                  <Radio.Group
                    size="large"
                    buttonStyle="solid"
                    optionType="button"
                    onChange={handleCoSignOrGuaranteedLoanRadioChanged}
                    disabled={isAppFormLocked}
                  >
                    <Radio.Button value={true}>{t.YES}</Radio.Button>
                    <Radio.Button value={false}>{t.NO}</Radio.Button>
                  </Radio.Group>
                </ChangeTrackerFormItem>
              </Col>
              <Col span={16}></Col>
            </Row>

            {hasCoSignOrGuaranteedLoan && (
              <Form.List name="coSignedOrGuaranteedLoans">
                {(coSignedOrGuaranteedLoans, { add, remove }) => (
                  <>
                    {coSignedOrGuaranteedLoans?.map((row, index) => (
                      <Row gutter={20} align="middle" key={`coSignedOrGuaranteedLoans-${index}`}>
                        <Col span={8}>
                          <ChangeTrackerFormItem
                            name={[row.name, 'isBySpouse']}
                            label={t.BY}
                            listName="coSignedOrGuaranteedLoans"
                          >
                            <Select suffixIcon={<SelectSuffixIcon />} size="large" disabled={isAppFormLocked}>
                              <Option key="applicant" value={false}>
                                {t.APPLICANT}
                              </Option>
                              <Option key="spouse" value={true}>
                                {t.SPOUSE}
                              </Option>
                            </Select>
                          </ChangeTrackerFormItem>
                        </Col>
                        <Col span={16}></Col>

                        <Col span={8}>
                          <ChangeTrackerFormItem
                            name={[row.name, 'lendersName']}
                            label={t.LENDERS_NAME}
                            rules={[{ required: true }]}
                            listName="coSignedOrGuaranteedLoans"
                          >
                            <Input size="large" disabled={isAppFormLocked} />
                          </ChangeTrackerFormItem>
                        </Col>
                        <Col span={16}></Col>

                        <Col span={16}>
                          <Form.Item
                            noStyle
                            shouldUpdate={(prevValues, currentValues) =>
                              prevValues[row.name] !== currentValues[row.name]
                            }
                          >
                            {({ getFieldValue }) => (
                              <ChangeTrackerFormItem
                                name={[row.name, 'lendersAddress']}
                                label={t.LENDERS_ADDRESS}
                                rules={[{ required: true }]}
                                listName="coSignedOrGuaranteedLoans"
                              >
                                <SearchLocationInput
                                  address={{ address: getFieldValue([row.name, 'lendersAddress']) }}
                                  onSelect={handleLenderAddressAutocomplete(row.name)}
                                  size="large"
                                  disabled={isAppFormLocked}
                                />
                              </ChangeTrackerFormItem>
                            )}
                          </Form.Item>
                        </Col>
                        <Col span={8}>
                          <ChangeTrackerFormItem
                            name={[row.name, 'lendersUnit']}
                            label={t.LENDERS_ADRS_UNIT}
                            listName="coSignedOrGuaranteedLoans"
                          >
                            <Input size="large" disabled={isAppFormLocked} />
                          </ChangeTrackerFormItem>
                        </Col>

                        <Col span={8}>
                          <ChangeTrackerFormItem
                            name={[row.name, 'borrowersName']}
                            label={t.BORROWERS_NAME}
                            rules={[{ required: true }]}
                            listName="coSignedOrGuaranteedLoans"
                          >
                            <Input size="large" disabled={isAppFormLocked} />
                          </ChangeTrackerFormItem>
                        </Col>
                        <Col span={16}></Col>

                        <Col span={16}>
                          <Form.Item
                            noStyle
                            shouldUpdate={(prevValues, currentValues) =>
                              prevValues[row.name] !== currentValues[row.name]
                            }
                          >
                            {({ getFieldValue }) => (
                              <ChangeTrackerFormItem
                                name={[row.name, 'borrowersAddress']}
                                label={t.BORROWERS_ADDRESS}
                                rules={[{ required: true }]}
                                listName="coSignedOrGuaranteedLoans"
                              >
                                <SearchLocationInput
                                  address={{ address: getFieldValue([row.name, 'borrowersAddress']) }}
                                  onSelect={handleBorrowerAddressAutocomplete(row.name)}
                                  size="large"
                                  disabled={isAppFormLocked}
                                />
                              </ChangeTrackerFormItem>
                            )}
                          </Form.Item>
                        </Col>
                        <Col span={7}>
                          <ChangeTrackerFormItem
                            name={[row.name, 'borrowersUnit']}
                            label={t.BORROWERS_ADDRESS_UNIT}
                            listName="coSignedOrGuaranteedLoans"
                          >
                            <Input size="large" disabled={isAppFormLocked} />
                          </ChangeTrackerFormItem>
                        </Col>
                        <Col span={+isAppFormLocked} style={{ textAlign: 'right' }}>
                          <DeleteOutlined
                            className="ApplicationFormTab__input-action-icon"
                            onClick={() => remove(row.name)}
                          />
                        </Col>
                      </Row>
                    ))}
                    {!isAppFormLocked && (
                      <Row gutter={20}>
                        <Col span={8}>
                          <Form.Item noStyle shouldUpdate>
                            {({ getFieldError, getFieldValue }) => (
                              <div
                                onClick={() => add({ isBySpouse: false })}
                                className="ApplicationFormTab__link-left"
                                style={{
                                  marginTop: getFieldError([
                                    'coSignedOrGuaranteedLoans',
                                    getFieldValue('coSignedOrGuaranteedLoans')?.length - 1,
                                    'borrowersAddress',
                                  ])?.length
                                    ? 12
                                    : -12,
                                }}
                              >
                                {t.ADD_LOAN}
                              </div>
                            )}
                          </Form.Item>
                        </Col>
                      </Row>
                    )}
                  </>
                )}
              </Form.List>
            )}
          </Panel>

          {/* Transactions - Applicant */}
          <Panel
            className={clientUpdatedPanels?.isTransactionsApplicantUpdatedByClient ? 'user-changed' : ''}
            header={
              <Row gutter={6} ref={refTransactionsApplicant}>
                <Col>{t.TRANSACTIONS_APPLICANT}</Col>
                <Col>
                  {hasPanelErrors(getPanelFieldsErrors(transactionsApplicantFields)) && (
                    <Icon iconName="AlertSolid" className="ApplicationFormTab__panel-header-error" />
                  )}
                </Col>
              </Row>
            }
            key={TRANSACTIONS_APPLICANT_TAB}
            forceRender
          >
            <div className="ApplicationFormTab__questionnaire-question-additional-info"></div>
            <Row gutter={20}>
              <QuestionnaireQuestion
                label={t.HAVE_YOU_MADE_PAYMENTS_IN_EXCESS_OF_THE_REGULAR_AMOUNT_TO_CREDITORS_IN_THE_PAST__MONTHS}
                flagName="hasMadeExessPaymentsPast12Months"
                flagRequired
                detailsName="hasMadeExessPaymentsPast12MonthsDetails"
                isLocked={isAppFormLocked}
              />
              <Divider dashed className="ApplicationFormTab__questionnaire-question-divider" />
              <QuestionnaireQuestion
                label={t.HAVE_YOU_HAD_ANY_ASSETS_SEIZED_OR_GARNISHED_BY_A_CREDITORS_IN_THE_PAST__MONTHS}
                flagName="hasAssetsSiezedOrGarnishedPast12Months"
                flagRequired
                detailsName="hasAssetsSiezedOrGarnishedPast12MonthsDetail"
                isLocked={isAppFormLocked}
              />
              <Divider dashed className="ApplicationFormTab__questionnaire-question-divider" />
              <QuestionnaireQuestion
                label={t.HAVE_YOU_SOLD_DISPOSED_OR_TRANSFERRED_ANY_REAL_PROPERTY_OR_OTHER_ASSETS_IN_THE_PAST_YEARS}
                flagName="hasSoldDisposedOrTransferredRealProperlyOrOtherAssetsPast5Years"
                flagRequired
                detailsName="hasSoldDisposedOrTransferredRealProperlyOrOtherAssetsPast5YearsDetails"
                boolName="hasSoldDisposedOrTransferredRealProperlyOrOtherAssetsPast5YearsInsolventAtTime"
                boolLabel={t.INSOLVENT_AT_THE_TIME}
                isLocked={isAppFormLocked}
              />
              <Divider dashed className="ApplicationFormTab__questionnaire-question-divider" />
              <QuestionnaireQuestion
                label={
                  t.HAVE_YOU_MADE_ANY_GIFTS_TO_RELATIVES_OR_OTHERS_IN_THE_EXCESS_OF_WHILE_YOU_KNEW_YOURSELF_TO_BE_INSOLVENT_IN_THE_PAST_YEARS
                }
                flagName="hasGivenGiftsOver500WhileInsolventPast5Years"
                flagRequired
                detailsName="hasGivenGiftsOver500WhileInsolventPast5YearsDetails"
                isLocked={isAppFormLocked}
              />
              <Divider dashed className="ApplicationFormTab__questionnaire-question-divider" />
              <QuestionnaireQuestion
                label={
                  t.DO_YOU_EXPECT_TO_RECEIVE_ANY_SUMS_OF_MONEY_WHICH_ARE_NOT_RELATED_TO_YOUR_NORMAL_INCOME_OR_ANY_OTHER_PROPERTY_WITHIN_THE_NEXT__MONTHS_INCLUDING_INHERITANCE
                }
                flagName="isExpectedToReceiveNonIncomeFundsNext12Months"
                flagRequired
                detailsName="isExpectedToReceiveNonIncomeFundsNext12MonthsDetails"
                isLocked={isAppFormLocked}
              />
              <Divider dashed className="ApplicationFormTab__questionnaire-question-divider" />
              <QuestionnaireQuestion
                label={
                  t.HAVE_YOU_BEEN_OR_ARE_YOU_INVOLVED_IN_CIVIL_LITIGATION_FROM_WHICH_YOU_MAY_RECEIVE_MONIES_OR_PROPERTY
                }
                flagName="hasBeenInvolvedInCivilLitigationReceivingMoneyOrProperty"
                flagRequired
                detailsName="hasBeenInvolvedInCivilLitigationReceivingMoneyOrPropertyDetails"
                isLocked={isAppFormLocked}
              />
              <Divider dashed className="ApplicationFormTab__questionnaire-question-divider" />
              <QuestionnaireQuestion
                label={t.HAVE_YOU_MADE_ARRANGEMENTS_TO_CONTINUE_TO_PAY_ANY_CREDITORS_AFTER_FILING}
                flagName="hasMadeArragementsToContinuePayingCreditors"
                flagRequired
                detailsName="hasMadeArragementsToContinuePayingCreditorsDetails"
                isLocked={isAppFormLocked}
              />
            </Row>
          </Panel>

          {/* Transactions - Spouse/Partner */}
          {isJointFile && (
            <Panel
              className={clientUpdatedPanels?.isTransactionsSpouseUpdatedByClient ? 'user-changed' : ''}
              header={
                <Row gutter={6} ref={refTransactionsApplicant}>
                  <Col>{t.TRANSACTIONS_SPOUSE_PARTNER}</Col>
                  <Col>
                    {hasPanelErrors(getPanelFieldsErrors(transactionsSpouseFields)) && (
                      <Icon iconName="AlertSolid" className="ApplicationFormTab__panel-header-error" />
                    )}
                  </Col>
                </Row>
              }
              key={TRANSACTIONS_SPOUSE_TAB}
              forceRender
            >
              <div className="ApplicationFormTab__questionnaire-question-additional-info"></div>
              <Row gutter={20}>
                <QuestionnaireQuestion
                  label={t.HAVE_YOU_MADE_PAYMENTS_IN_EXCESS_OF_THE_REGULAR_AMOUNT_TO_CREDITORS_IN_THE_PAST__MONTHS}
                  flagName="hasSpouseMadeExessPaymentsPast12Months"
                  flagRequired
                  detailsName="hasSpouseMadeExessPaymentsPast12MonthsDetails"
                  isLocked={isAppFormLocked}
                />
                <Divider dashed className="ApplicationFormTab__questionnaire-question-divider" />
                <QuestionnaireQuestion
                  label={t.HAVE_YOU_HAD_ANY_ASSETS_SEIZED_OR_GARNISHED_BY_A_CREDITORS_IN_THE_PAST__MONTHS}
                  flagName="hasSpouseAssetsSiezedOrGarnishedPast12Months"
                  flagRequired
                  detailsName="hasSpouseAssetsSiezedOrGarnishedPast12MonthsDetail"
                  isLocked={isAppFormLocked}
                />
                <Divider dashed className="ApplicationFormTab__questionnaire-question-divider" />
                <QuestionnaireQuestion
                  label={t.HAVE_YOU_SOLD_DISPOSED_OR_TRANSFERRED_ANY_REAL_PROPERTY_OR_OTHER_ASSETS_IN_THE_PAST_YEARS}
                  flagName="hasSpouseSoldDisposedOrTransferredRealProperlyOrOtherAssetsPast5Years"
                  detailsName="hasSpouseSoldDisposedOrTransferredRealProperlyOrOtherAssetsPast5YearsDetails"
                  boolName="hasSpouseSoldDisposedOrTransferredRealProperlyOrOtherAssetsPast5YearsInsolventAtTime"
                  boolLabel={t.INSOLVENT_AT_THE_TIME}
                  flagRequired
                  isLocked={isAppFormLocked}
                />
                <Divider dashed className="ApplicationFormTab__questionnaire-question-divider" />
                <QuestionnaireQuestion
                  label={
                    t.HAVE_YOU_MADE_ANY_GIFTS_TO_RELATIVES_OR_OTHERS_IN_THE_EXCESS_OF_WHILE_YOU_KNEW_YOURSELF_TO_BE_INSOLVENT_IN_THE_PAST_YEARS
                  }
                  flagName="hasSpouseGivenGiftsOver500WhileInsolventPast5Years"
                  detailsName="hasSpouseGivenGiftsOver500WhileInsolventPast5YearsDetails"
                  flagRequired
                  isLocked={isAppFormLocked}
                />
                <Divider dashed className="ApplicationFormTab__questionnaire-question-divider" />
                <QuestionnaireQuestion
                  label={
                    t.DO_YOU_EXPECT_TO_RECEIVE_ANY_SUMS_OF_MONEY_WHICH_ARE_NOT_RELATED_TO_YOUR_NORMAL_INCOME_OR_ANY_OTHER_PROPERTY_WITHIN_THE_NEXT__MONTHS_INCLUDING_INHERITANCE
                  }
                  flagName="isSpouseExpectedToReceiveNonIncomeFundsNext12Months"
                  detailsName="isSpouseExpectedToReceiveNonIncomeFundsNext12MonthsDetails"
                  flagRequired
                  isLocked={isAppFormLocked}
                />
                <Divider dashed className="ApplicationFormTab__questionnaire-question-divider" />
                <QuestionnaireQuestion
                  label={
                    t.HAVE_YOU_BEEN_OR_ARE_YOU_INVOLVED_IN_CIVIL_LITIGATION_FROM_WHICH_YOU_MAY_RECEIVE_MONIES_OR_PROPERTY
                  }
                  flagName="hasSpouseBeenInvolvedInCivilLitigationReceivingMoneyOrProperty"
                  detailsName="hasSpouseBeenInvolvedInCivilLitigationReceivingMoneyOrPropertyDetails"
                  flagRequired
                  isLocked={isAppFormLocked}
                />
                <Divider dashed className="ApplicationFormTab__questionnaire-question-divider" />
                <QuestionnaireQuestion
                  label={t.HAVE_YOU_MADE_ARRANGEMENTS_TO_CONTINUE_TO_PAY_ANY_CREDITORS_AFTER_FILING}
                  flagName="hasSpouseMadeArragementsToContinuePayingCreditors"
                  detailsName="hasSpouseMadeArragementsToContinuePayingCreditorsDetails"
                  flagRequired
                  isLocked={isAppFormLocked}
                />
              </Row>
            </Panel>
          )}

          {/* Business */}
          <Panel
            className={clientUpdatedPanels?.isBusinessUpdatedByClient ? 'user-changed' : ''}
            header={
              <Row gutter={6} ref={refBusinesses}>
                <Col>{t.BUSINESS}</Col>
                <Col>
                  {hasPanelErrors([
                    ...(getPanelFieldsErrors(businessesFields) || []),
                    ...(getPanelFieldsErrors(supportingDocumentsFields) || []),
                  ]) && <Icon iconName="AlertSolid" className="ApplicationFormTab__panel-header-error" />}
                </Col>
              </Row>
            }
            key={BUSINESSES_TAB}
            forceRender
          >
            <div className="ApplicationFormTab__header">{t.APPLICANT}</div>
            <Row gutter={20}>
              <Col span={8}>
                <ChangeTrackerFormItem
                  name="hasOwnedBusinessPast5Years"
                  label={t.HAVE_YOU_OWNED_A_BUSINESS_WITHIN_THE_LAST_5_YEARS}
                >
                  <Radio.Group
                    size="large"
                    buttonStyle="solid"
                    optionType="button"
                    onChange={handleOwnedBusinessPast5YearsRadioChanged}
                    disabled={isAppFormLocked}
                  >
                    <Radio.Button value={true}>{t.YES}</Radio.Button>
                    <Radio.Button value={false}>{t.NO}</Radio.Button>
                  </Radio.Group>
                </ChangeTrackerFormItem>
              </Col>
              <Col span={16}></Col>
            </Row>

            {hasOwnedBusinessPast5Years && (
              <Form.List name="businesses">
                {(businesses, { add, remove }) => (
                  <>
                    {businesses?.map((row, index) => (
                      <QuestionnaireBusinessRow
                        key={`businesses-${index}`}
                        form={form}
                        businessOwnershipTypes={businessOwnershipTypes}
                        businessTypes={businessTypes}
                        isRequired={true}
                        rowName={row.name}
                        onAddressSelect={handleBusinessAddressAutocomplete}
                        remove={remove}
                        isLocked={isAppFormLocked}
                        listName="businesses"
                      />
                    ))}
                    {!isAppFormLocked && (
                      <Row gutter={20}>
                        <Col span={8}>
                          <ChangeTrackerFormItem>
                            <div onClick={() => add()} className="ApplicationFormTab__link-left">
                              {t.ADD_BUSINESS}
                            </div>
                          </ChangeTrackerFormItem>
                        </Col>
                      </Row>
                    )}
                  </>
                )}
              </Form.List>
            )}

            {isJointFile && (
              <>
                <div className="ApplicationFormTab__header">{t.SPOUSE_PARTNER}</div>
                <Row gutter={20}>
                  <Col span={8}>
                    <ChangeTrackerFormItem
                      name="hasSpouseOwnedBusinessPast5Years"
                      label={t.HAVE_YOU_OWNED_A_BUSINESS_WITHIN_THE_LAST_5_YEARS}
                    >
                      <Radio.Group
                        size="large"
                        buttonStyle="solid"
                        optionType="button"
                        onChange={handleSpouseOwnedBusinessPast5YearsRadioChanged}
                        disabled={isAppFormLocked}
                      >
                        <Radio.Button value={true}>{t.YES}</Radio.Button>
                        <Radio.Button value={false}>{t.NO}</Radio.Button>
                      </Radio.Group>
                    </ChangeTrackerFormItem>
                  </Col>
                  <Col span={16}></Col>
                </Row>

                {hasSpouseOwnedBusinessPast5Years && (
                  <Form.List name="spouseBusinesses">
                    {(spouseBusinesses, { add, remove }) => (
                      <>
                        {spouseBusinesses?.map((row, index) => (
                          <QuestionnaireBusinessRow
                            key={`spouseBusinesses-${index}`}
                            form={form}
                            businessOwnershipTypes={businessOwnershipTypes}
                            businessTypes={businessTypes}
                            isRequired={isJointFile}
                            rowName={row.name}
                            onAddressSelect={handleSpouseBusinessAddressAutocomplete}
                            remove={remove}
                            isLocked={isAppFormLocked}
                            listName="spouseBusinesses"
                          />
                        ))}
                        {!isAppFormLocked && (
                          <Row gutter={20}>
                            <Col span={8}>
                              <ChangeTrackerFormItem>
                                <div onClick={() => add()} className="ApplicationFormTab__link-left">
                                  {t.ADD_BUSINESS}
                                </div>
                              </ChangeTrackerFormItem>
                            </Col>
                          </Row>
                        )}
                      </>
                    )}
                  </Form.List>
                )}
              </>
            )}

            <Divider />

            <div className="ApplicationFormTab__header" ref={refSupportingDocuments}>
              {t.SUPPORTING_DOCUMENTS}
            </div>
            <ChangeTrackerFormItem shouldUpdate>
              <AttachmentsTab
                applicationFileId={data?.id as string}
                form={form}
                supportingFolderCode="surplus-ie"
                prefixCodes={['Business']}
                isFlatLayout
                flatLayoutProps={{ hasInstruction: true, hasSupportingList: true, hasExistingFiles: true }}
                columnsProps={{
                  hasSizeColumn: true,
                  isDeleteDisabled: isAppFormLocked,
                  hasBusinessNameColumn: Boolean(businessNames?.length),
                }}
                draggerProps={{ disabled: isAppFormLocked }}
                newSupportingFileRowLayoutProps={{ disabledFoldersInput: true }}
                businessNames={businessNames}
                existingDocumentIds={existingDocumentIds}
              />
            </ChangeTrackerFormItem>
          </Panel>

          {/* Credit Bureau Consent */}
          <Panel
            className={clientUpdatedPanels?.isCreditBureauConsentUpdatedByClient ? 'user-changed' : ''}
            header={t.CREDIT_BUREAU_CONSENT}
            key={CREDIT_BUREAU_CONSENT_TAB}
            forceRender
          >
            <Row gutter={20} style={{ paddingTop: 24 }}>
              <Col span={24}>
                <ChangeTrackerFormItem>
                  <Label>{t.CONSENT_AGREEMENT_TEXT}</Label>
                </ChangeTrackerFormItem>
              </Col>
              <Col span={16}>
                <Label className="ApplicationFormTab__label">{t.I_UNDERSTAND}</Label>
              </Col>
              <Col span={8}>
                <ChangeTrackerFormItem name="isConsentChecked">
                  {/* should always be read-only in StaffPortal */}
                  <Radio.Group size="large" buttonStyle="solid" optionType="button" disabled>
                    <Radio.Button value={true}>{t.YES}</Radio.Button>
                    <Radio.Button value={false}>{t.NO}</Radio.Button>
                  </Radio.Group>
                </ChangeTrackerFormItem>
              </Col>
            </Row>
          </Panel>
        </Collapse>

        <Row justify="end" gutter={20} className="ApplicationFormTab__buttons-container">
          <Col>
            <Button
              padding="large"
              disabled={
                !needToBeReviewed ||
                !clientUpdatedPanels ||
                Object.values(clientUpdatedPanels)?.filter(Boolean)?.length !== 0 ||
                isAppFormLocked
              }
              onClick={markReviewed}
            >
              {t.MARK_AS_REVIEWED}
            </Button>
          </Col>
          <Col>
            <Button padding="large" disabled={!isReviewed || isAppFormLocked || isCompleted} onClick={markComplete}>
              {t.MARK_AS_COMPLETED}
            </Button>
          </Col>
          <Col>
            <Button
              padding="large"
              disabled={isAppFormLocked || isLoading}
              onClick={debounceWithTimeOut(saveAndFinalize, 2000)}
            >
              {t.SAVE}
            </Button>
          </Col>
        </Row>
      </Form>
    </Spin>
  );
};

export default Questionnaire;
