import { useCallback, useEffect, useMemo, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Button, Col, Row } from 'antd';
import { Icon } from '@fluentui/react/lib/Icon';

import ActionDropdown from './ActionDropdown';
import TaskEditor from '../../pages/ApplicationOverviewPage/TasksContent/TaskEditor/TaskEditor';
import EmailEditor from '../../pages/ApplicationOverviewPage/EmailContent/EmailEditor/EmailEditor';
import SMSEditor from '../../pages/ApplicationOverviewPage/SMSContent/SMSEditor/SMSEditor';
import AddNoteIcon from './AddNoteIcon';
import AddNoteModal from './AddNoteModal';

import useModal from '../../hooks/useModal';
import useLocale from '../../hooks/useLocale';

import { NOTE_ADDED_EVENT, TASK_ADDED_EVENT } from '../../constants/eventBus';
import { eventBus } from '../../utils/eventBus';
import { ROUTES } from '../../constants/routes';
import { ClientService } from '../../shared/api/ClientService';
import API from '../../utils/api';

import './QuickActionsToolbar.scss';
import ActionConfirmationModal from '../../modals/ActionConfirmationModal/ActionConfirmationModal';
import DuplicateFileDialog from '../../pages/ApplicationOverviewPage/FileContent/DuplicateFileDialog';

function QuickActionsToolbar(): JSX.Element {
  const { t } = useLocale();
  const navigate = useNavigate();
  const { applicationFileId } = useParams<{ applicationFileId?: string }>();
  const { showModal, closeModal } = useModal();

  const [appFile, setAppFile] = useState<ClientService.ApplicationFileDto>();
  const [config, setConfig] = useState<ClientService.QuickActionsConfigDto>();

  const isDuplicate = useMemo(() => appFile?.isDuplicate, [appFile]);
  const isDuplicatingFileAllowed = useMemo(
    () =>
      appFile?.fileStage?.enumValue === ClientService.FileStageEnum.NewProspect ||
      appFile?.fileStage?.enumValue === ClientService.FileStageEnum.PreSignup,
    [appFile]
  );

  const requestAppFileData = useCallback(async (applicationFileId: string) => {
    const response = await API.applicationFile2(applicationFileId);
    if (response) {
      setAppFile(response);
    }
  }, []);

  const handleDuplicatingAFile = useCallback(() => {
    showModal(
      <DuplicateFileDialog
        fileId={applicationFileId}
        onCancel={closeModal}
        onSave={(fileId?: string) => {
          closeModal();
          navigate(`${ROUTES.APPLICATION_OVERVIEW}/${fileId}`);
        }}
      />
    );
  }, [showModal, applicationFileId, closeModal, navigate]);

  const handleConfirmDuplicateFileDialog = useCallback(() => {
    showModal(
      <ActionConfirmationModal
        title={t.DUPLICATE_FILE_CONFIRMATION_TITLE}
        onOk={handleDuplicatingAFile}
        onCancel={closeModal}
        okText={t.YES}
        cancelText={t.NO}
        message={t.DUPLICATE_FILE_CONFIRMATION_MESSAGE}
      />
    );
  }, [
    showModal,
    t.DUPLICATE_FILE_CONFIRMATION_TITLE,
    t.YES,
    t.NO,
    t.DUPLICATE_FILE_CONFIRMATION_MESSAGE,
    handleDuplicatingAFile,
    closeModal,
  ]);

  const handleSendNewEmailAction = useCallback(() => {
    showModal(<EmailEditor onNext={closeModal} onCancel={closeModal} fileId={applicationFileId as string} />);
  }, [showModal, closeModal, applicationFileId]);

  const handleSendNewSMSAction = useCallback(() => {
    showModal(<SMSEditor fileId={applicationFileId as string} onNext={closeModal} onCancel={closeModal} />);
  }, [showModal, closeModal, applicationFileId]);

  const handleAddTask = useCallback(() => {
    showModal(
      <TaskEditor
        onNext={() => {
          closeModal();
          eventBus.dispatch(TASK_ADDED_EVENT);
        }}
        onCancel={closeModal}
        fileId={applicationFileId as string}
        fileStageId={appFile?.fileStage?.id}
      />
    );
  }, [showModal, closeModal, applicationFileId, appFile?.fileStage?.id]);

  const handleAddNoteAction = useCallback(() => {
    showModal(
      <AddNoteModal
        onCancel={closeModal}
        applicationFileId={applicationFileId as string}
        onOk={() => {
          eventBus.dispatch(NOTE_ADDED_EVENT);
          closeModal();
        }}
      />
    );
  }, [showModal, closeModal, applicationFileId]);

  const handlePrint = useCallback(() => window.print(), []);

  useEffect(() => {
    API.getQuickActionsConfig(applicationFileId).then((response) => {
      setConfig(response);
    });
  }, [applicationFileId]);

  useEffect(() => {
    if (applicationFileId) requestAppFileData(applicationFileId);
  }, [applicationFileId, requestAppFileData]);

  return (
    <Row align="middle" justify="end" gutter={10}>
      <Col className="QuickActionsToolbar__flag">{appFile?.isMigrated && `(${t.MIGRATED})`}</Col>
      <Col className="QuickActionsToolbar__flag">{isDuplicate && `(${t.DUPLICATE})`}</Col>

      <Col>
        {appFile?.copiedFromFileId && (
          <Link
            className="QuickActionsToolbar__flag"
            to={`${ROUTES.APPLICATION_OVERVIEW}/${appFile?.copiedFromFileId}`}
          >
            ({t.COPIED_FROM_FILE})
          </Link>
        )}
      </Col>
      {isDuplicatingFileAllowed ? (
        <Col>
          <Button type="primary" onClick={handleConfirmDuplicateFileDialog} danger={true}>
            {t.DUPLICATE_FILE}
          </Button>
        </Col>
      ) : null}

      <Col className="QuickActionsToolbar">
        <Row>
          {config?.canReceiveEmail || appFile?.isMigrated ? (
            <ActionDropdown
              addItemTitle={t.NEW_EMAIL}
              historyItemTitle={t.EMAIL_HISTORY}
              onAddClick={handleSendNewEmailAction}
              onHistoryClick={() =>
                navigate(`${ROUTES.APPLICATION_OVERVIEW}/${applicationFileId}/${ROUTES.EMAIL_HISTORY}`)
              }
              iconName="Mail"
              addAllowed={!isDuplicate}
            />
          ) : (
            <></>
          )}

          {config?.canReceiveSMS && (
            <ActionDropdown
              addItemTitle={t.NEW_SMS}
              historyItemTitle={t.SMS_HISTORY}
              onAddClick={handleSendNewSMSAction}
              onHistoryClick={() =>
                navigate(`${ROUTES.APPLICATION_OVERVIEW}/${applicationFileId}/${ROUTES.SMS_HISTORY}`)
              }
              iconName="CannedChat"
              addAllowed={!isDuplicate}
            />
          )}
          <ActionDropdown
            addItemTitle={t.NEW_NOTE}
            historyItemTitle={t.NOTE_HISTORY}
            onAddClick={handleAddNoteAction}
            onHistoryClick={() =>
              navigate(`${ROUTES.APPLICATION_OVERVIEW}/${applicationFileId}/${ROUTES.NOTES_HISTORY}`)
            }
            icon={<AddNoteIcon />}
            addAllowed={!isDuplicate}
          />

          <div className="QuickActionsToolbar__divider" />

          <ActionDropdown
            addItemTitle={t.NEW_RECEIPT}
            historyItemTitle={t.RECEIPT_HISTORY}
            onAddClick={() => navigate(`${ROUTES.APPLICATION_OVERVIEW}/${applicationFileId}/${ROUTES.ADD_RECEIPT}`)}
            onHistoryClick={() => navigate(`${ROUTES.APPLICATION_OVERVIEW}/${applicationFileId}/${ROUTES.RECEIPTS}`)}
            iconName="Script"
            style={{ transform: 'scale(-1, 1)' }}
            addAllowed={!isDuplicate}
          />

          <ActionDropdown
            addItemTitle={t.NEW_APPOINTMENT}
            historyItemTitle={t.APPOINTMENT_HISTORY}
            onAddClick={() =>
              navigate(`${ROUTES.APPLICATION_OVERVIEW}/${applicationFileId}/${ROUTES.APPOINTMENT_SCHEDULING}`)
            }
            onHistoryClick={() =>
              navigate(`${ROUTES.APPLICATION_OVERVIEW}/${applicationFileId}/${ROUTES.APPOINTMENTS}`)
            }
            iconName="Calendar"
            addAllowed={!isDuplicate}
          />

          <ActionDropdown
            addItemTitle={t.NEW_TASK}
            historyItemTitle={t.TASK_HISTORY}
            onAddClick={handleAddTask}
            onHistoryClick={() => navigate(ROUTES.TASKS_HISTORY)}
            iconName="CheckList"
            addAllowed={!isDuplicate}
          />

          <div className="QuickActionsToolbar__divider" />

          <ActionDropdown
            addItemTitle={t.REQUEST_CREDIT_REPORT}
            historyItemTitle={t.CREDIT_REPORT_HISTORY}
            onAddClick={() =>
              navigate(`${ROUTES.APPLICATION_OVERVIEW}/${applicationFileId}/${ROUTES.REQUEST_CREDIT_REPORT}`)
            }
            onHistoryClick={() =>
              navigate(`${ROUTES.APPLICATION_OVERVIEW}/${applicationFileId}/${ROUTES.CREDIT_REPORT_HISTORY}`)
            }
            iconName="ReportDocument"
            addAllowed={!isDuplicate}
          />

          <Icon iconName="Print" className="QuickActionsToolbar__icon" onClick={handlePrint} />
        </Row>
      </Col>
    </Row>
  );
}

export default QuickActionsToolbar;
