import { useNavigate } from 'react-router-dom';
import Icon from '../../../Icon/Icon';
import useLocale from '../../../../hooks/useLocale';
import { ROUTES } from '../../../../constants/routes';

import styles from './BackToOverviewButton.module.scss';

interface IProps {
  fileId: string;
}

const BackToOverviewButton = ({ fileId }: IProps): JSX.Element => {
  const { t } = useLocale();
  const navigate = useNavigate();

  return (
    <div
      className={styles.BackToOverviewButton}
      onClick={() => {
        if (fileId) {
          const redirectRoute = `${ROUTES.APPLICATION_OVERVIEW}/${fileId}`;
          navigate(redirectRoute);
        }
      }}
    >
      <div className={styles.button_text}>{t.OVERVIEW}</div>
      <Icon className={styles.icon} iconName="RevToggleKey" fontSize="16px" color="red" />
    </div>
  );
};

export default BackToOverviewButton;
