import { useEffect, useCallback, useState } from 'react';
import { Modal, Row, Col } from 'antd';
import { Wrapper, Status } from '@googlemaps/react-wrapper';
import { LoaderOptions } from '@googlemaps/js-api-loader';

import Button from '../../components/Button/Button';
import MapComponent from './MapComponent';

import API from '../../utils/api';
import useLocale from '../../hooks/useLocale';
import { WEEKLY_VERSION, PLACES_LIBRARY, GEOMETRY_LIBRARY, DEFAULT_LNG, DEFAULT_LAT } from '../../constants/common';
import { DEFAULT_ZOOM } from './constants';
import { LocationDto } from './types';

import './GoogleMaps.scss';

interface IProps {
  data?: LocationDto[];
  target?: LocationDto;
  center?: google.maps.LatLngLiteral;
  isOk?: string | boolean;
  onOk?: (id?: string) => void;
  isCancel?: string | boolean;
  onCancel?: () => void;
}

const GoogleMaps = ({
  onOk,
  onCancel,
  data,
  target,
  center = { lat: DEFAULT_LAT, lng: DEFAULT_LNG },
  isOk,
  isCancel,
}: IProps) => {
  const { t } = useLocale();
  const [selectedMarkerId, setSelectedMarkerId] = useState<string>();
  const [loaderOptions, setLoaderOptions] = useState<LoaderOptions>({
    version: WEEKLY_VERSION,
    libraries: [PLACES_LIBRARY, GEOMETRY_LIBRARY],
    apiKey: '',
  });

  const requestApiKey = useCallback(async () => {
    const result = await API.getGoogleApiKey();
    const apiKey = result.apiKey;

    if (apiKey) {
      const updatedLoaderOptions = { ...loaderOptions, apiKey };
      setLoaderOptions(updatedLoaderOptions);
    }
  }, [loaderOptions]);

  useEffect(() => {
    if (!loaderOptions?.apiKey) {
      requestApiKey();
    }
  }, [loaderOptions?.apiKey, requestApiKey]);

  const render = (status: Status) => {
    switch (status) {
      case Status.LOADING:
        return <div>{t.LOADING_MAP}</div>;
      case Status.FAILURE:
        return <div>{t.ERROR_LOADING_MAP}</div>;
      case Status.SUCCESS:
        return (
          <MapComponent
            center={center}
            zoom={DEFAULT_ZOOM}
            data={data}
            target={target}
            setSelectedMarkerId={setSelectedMarkerId}
          />
        );
    }
  };

  const handleCancel = useCallback(() => {
    if (onCancel) onCancel();
  }, [onCancel]);

  const handleOk = useCallback(() => {
    if (onOk) onOk(selectedMarkerId);
  }, [onOk, selectedMarkerId]);

  return (
    <Modal
      destroyOnClose
      centered
      visible
      width={700}
      title={t.LOCATIONS}
      className="GoogleMaps"
      closable
      onCancel={handleCancel}
      footer={null}
    >
      {loaderOptions.apiKey && <Wrapper render={render} {...loaderOptions} />}
      <Row align="middle" justify="end" gutter={10} style={{ paddingTop: 24 }}>
        {isCancel && (
          <Col>
            <Button key="cancel" kind="cancel" padding="large" onClick={handleCancel}>
              {isCancel}
            </Button>
          </Col>
        )}
        {isOk && (
          <Col>
            <Button key="continue" padding="large" onClick={handleOk}>
              {isOk}
            </Button>
          </Col>
        )}
      </Row>
    </Modal>
  );
};

export default GoogleMaps;
