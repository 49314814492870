import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import Loading from '../../../../../../components/Loading/Loading';
import Table, { ITableProps } from '../../../../../../components/Table/Table';
import Icon from '../../../../../../components/Icon/Icon';

import styles from './DetailsTable.module.scss';

export interface DetailsTableProps {
  idForApiGet?: string | number;
  columns: ITableProps['columns'];
  tableData?: object[];
  apiGetTableData?: (idForApiGet: string | number) => Promise<any>;
  apiSetTableData?: (response: any, setTableData: React.Dispatch<React.SetStateAction<any>>) => void;
  viewRecordRoute?: string;
  className?: string;
  style?: React.CSSProperties;
}

const extractIdFromColumn = (column: any) => {
  if (column.key) {
    const keyParts = column.key.split('_');
    const tableIdParts = keyParts[0].split('-');
    return tableIdParts[1];
  }
  return null;
};

const DetailsTable: React.FC<DetailsTableProps> = ({
  idForApiGet,
  columns,
  tableData,
  apiGetTableData,
  apiSetTableData,
  viewRecordRoute,
  className,
  style,
}) => {
  const navigate = useNavigate();

  const [tableDataSet, setTableDataSet] = useState<any[] | undefined>();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const addTableDataToState = useCallback(() => {
    setIsLoading(true);

    if (tableData) {
      setIsLoading(false);
      setTableDataSet(tableData);
      return false;
    }

    if (apiGetTableData && idForApiGet) {
      console.log('running apiGetTableData()');
      setIsLoading(true);

      apiGetTableData(idForApiGet).then((response) => {
        setIsLoading(false);
        console.log('apiGetTableData() response:', response);
        if (apiSetTableData) {
          apiSetTableData(response, setTableDataSet);
        } else {
          setTableDataSet(response);
        }
      });
      return;
    }
  }, [tableData, idForApiGet, apiGetTableData, apiSetTableData]);

  useEffect(() => {
    if (!tableDataSet) {
      addTableDataToState();
    }
  }, [tableDataSet, addTableDataToState]);

  const renderTable = useCallback(() => {
    if (tableDataSet && columns && columns.length > 0) {
      const currentTableId = extractIdFromColumn(columns[0]);
      const actionCol = {
        key: `table-${currentTableId}_action`,
        title: <div></div>,
        dataIndex: 'action',
        className: styles.actions_column,
      };
      const columnsWithAction = [...columns, actionCol];

      const tableDataWithActions = tableDataSet.map((dataRow, index) => {
        console.log('dataRow:', dataRow);
        return {
          ...dataRow,
          key: `table-${currentTableId}_row-${index}`,
          action: (
            <div className={styles.record_icon_wrapper}>
              <Icon
                iconName="GoTo"
                color="red"
                fontSize="18px"
                style={{ fontWeight: 'bold' }}
                onClick={() => {
                  console.log(`navigate to: ${viewRecordRoute}/${dataRow.id}`);
                  navigate(`${viewRecordRoute}/${dataRow.id}`);
                }}
              />
            </div>
          ),
        };
      });

      return <Table columns={columnsWithAction} dataSource={tableDataWithActions} pagination={false} rowKey="key" />;
    }
  }, [tableDataSet, columns, viewRecordRoute, navigate]);

  if (isLoading) return <Loading noText />;

  return (
    <div className={`${styles.DetailsTable} ${className ? className : ''}`} style={style}>
      {renderTable()}
    </div>
  );
};

export default DetailsTable;
