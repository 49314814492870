import { useCallback, useEffect, useState } from 'react';
import { Modal, Col, Row, Spin } from 'antd';

import Button from '../../../../../../../components/Button/Button';
import DataItem from '../../../../../../../components/DataItem/DataItem';

import { convertPadRequestStatusToReadable } from '../../utils';
import { ClientService } from '../../../../../../../shared/api/ClientService';
import API from '../../../../../../../utils/api';
import useLocale from '../../../../../../../hooks/useLocale';

import './PADViewModal.scss';

interface IProps {
  id?: string;
  applicationFileId: string;
  onClose: () => void;
}

const PADViewModal = ({ id, applicationFileId, onClose }: IProps) => {
  const [data, setData] = useState<ClientService.PADInfoChangeRequestDto>();
  const [loading, setLoading] = useState<boolean>(false);
  const { t } = useLocale();

  const requestPADInfo = useCallback(async (id: string, applicationFileId: string) => {
    setLoading(true);
    const response = await API.padInfoChangeRequestGET(id, applicationFileId).catch(() => setLoading(false));
    setLoading(false);

    if (response) setData(response);
  }, []);

  useEffect(() => {
    if (id && applicationFileId) requestPADInfo(id, applicationFileId);
  }, [applicationFileId, id, requestPADInfo]);

  return (
    <Modal
      destroyOnClose
      centered
      visible
      title={t.VIEW_PAD_REQUEST}
      className="PADViewModal"
      width={750}
      footer={null}
      closable
      onCancel={onClose}
    >
      <Spin spinning={loading}>
        <div style={{ minHeight: 400 }}>
          <DataItem
            label={t.REVIEW_STATUS}
            value={convertPadRequestStatusToReadable(data?.reviewStatus)}
            className="PADViewModal__item"
          />
          {data?.reviewStatus === ClientService.PADRequestReviewStatusEnum.Rejected ? (
            <DataItem label={t.REJECT_REASON} value={data?.rejectionReason} className="PADViewModal__item" />
          ) : (
            <></>
          )}
        </div>
        <Row justify="end">
          <Col>
            <Button kind="primary" onClick={onClose}>
              {t.CLOSE}
            </Button>
          </Col>
        </Row>
      </Spin>
    </Modal>
  );
};

export default PADViewModal;
