import { InputNumber, InputNumberProps } from 'antd';
import { forwardRef, Ref } from 'react';
import { antdUtil } from '../../../../utils/antdUtil';

interface IProps extends InputNumberProps {
  negativeAllowed?: boolean;
}

const CurrencyInput = forwardRef((props: IProps, ref: Ref<HTMLInputElement>) => (
  <InputNumber
    {...props}
    placeholder="$ 0"
    ref={ref}
    size="large"
    precision={2}
    controls={false}
    min={props.min || (props.negativeAllowed ? -999999999 : 0)}
    max={999999999}
    formatter={(value) => antdUtil.currencyFormatter(Number(value))}
    parser={(value) => antdUtil.currencyParser(value) as number}
  />
));

export default CurrencyInput;
