export const currencyParser = (value?: string | number, fractionDigits?: number) => {
  if (value === undefined || value === null) return 0;

  const parsedToCentsPrecision = parseFloat(
    String(value)?.replace(/(,*)/g, '')?.split('.')?.splice(0, 2)?.join('.')
  ).toFixed(fractionDigits === undefined ? 2 : fractionDigits);

  return Number(parsedToCentsPrecision) || 0;
};

export const currencyFormatter = (value?: string | number, fractionDigits?: number) => {
  if (value === undefined || value === null || currencyParser(value) === undefined || currencyParser(value) === null)
    return '0';

  const formattedValue = currencyParser(value)
    ?.toFixed(fractionDigits === undefined ? 2 : fractionDigits)
    ?.replace(/(?<=(\.\d{2}))(\d)+/g, '0')
    ?.replace(/\B(?=(\d{3})+(?!\d))/g, ',');

  return formattedValue || '0';
};

export const handleCurrencyValidation = async (_: any, amount?: number | string) => {
  if (Number(amount) < 0) {
    return Promise.reject(Error('Amount cannot be less than $0'));
  } else if (Number(amount) > 999999999) {
    return Promise.reject(Error('Amount cannot exceed $999,999,999'));
  } else {
    return Promise.resolve();
  }
};

export const handleCurrencyValidationNegativeAllowed = async (_: any, amount?: number | string) => {
  if (Number(amount) < -999999999) {
    return Promise.reject(Error('Amount cannot be less than $-999,999,999'));
  } else if (Number(amount) > 999999999) {
    return Promise.reject(Error('Amount cannot exceed $999,999,999'));
  } else {
    return Promise.resolve();
  }
};

export const handlePositiveOnlyNumberValidation = async (_: any, monthlyPaymentMonths?: string) => {
  if (Number(monthlyPaymentMonths) < 0) {
    return Promise.reject(Error('Value cannot be less than 0'));
  } else {
    return Promise.resolve();
  }
};

export const convertNegativeToReadable = (value?: number, precision?: number) => {
  if (value === undefined) return '';

  return value < 0
    ? `(${currencyFormatter(Math.abs(value), precision || 0)})`
    : currencyFormatter(value, precision || 0);
};
