import { useCallback, useEffect, useState } from 'react';
import { Modal, Col, Row, Form, Spin } from 'antd';
import moment from 'moment';

import Button from '../../../../components/Button/Button';
import NewFiles from '../../../../components/AttachmentsTab/NewFiles/NewFiles';

import { FileProps, AttachmentsFormProps, FileActionEnum } from '../../../../components/AttachmentsTab/types';
import { argumentifyCreateDocument, argumentifyUpdateDocument, sanitizeDocumentName } from '../utils';

import useLocale from '../../../../hooks/useLocale';
import { ClientService } from '../../../../shared/api/ClientService';
import API from '../../../../utils/api';

import './UploadModal.scss';
import genericMessage from '../../../../utils/genericMessage';
import { isEmptyGuid } from '../../../../utils/helpers';

interface IProps {
  fileId: string;
  documents?: ClientService.IDocumentDto[];
  action: FileActionEnum;
  onOk?: () => void;
  onCancel?: () => void;
}

function UploadModal({ fileId, onOk, onCancel, documents, action }: IProps) {
  const { t } = useLocale();
  const [form] = Form.useForm<AttachmentsFormProps>();

  const [uploadingDocuments, setUploadingDocuments] = useState<boolean>(false);
  const [updatingDocuments, setUpdatingDocuments] = useState<boolean>(false);

  const [types, setTypes] = useState<ClientService.LookupDto[]>();

  const requestListTypes = useCallback(async () => {
    const response = await API.listTypes();
    if (response) setTypes(response);
  }, []);

  useEffect(() => {
    if (documents && action) {
      const newFiles = (documents || []).map((item) => ({
        documentFolderId: !isEmptyGuid(item?.documentFolderId) ? item?.documentFolderId : undefined,
        documentPrefixId: !isEmptyGuid(item?.documentPrefixId) ? item?.documentPrefixId : undefined,
        additionalInformation: item?.additionalInformation,
        uid: item?.id as string,
        name: sanitizeDocumentName(item?.name as string),
        frenchName: item?.frenchName,
        id: item?.id,
      }));
      form.setFieldsValue({ newFiles });
    }
  }, [action, documents, form]);

  useEffect(() => {
    if (!types) requestListTypes();
  }, [types, requestListTypes]);

  const requestFilesUpload = useCallback(async () => {
    setUploadingDocuments(true);
    const newFiles = form.getFieldValue('newFiles');

    for (let file of newFiles) {
      await API.documentsPOST(
        ...argumentifyCreateDocument({
          fileId,
          fileModifiedDate: moment(),
          content: { fileName: sanitizeDocumentName(file?.name), data: file?.originFileObj as Blob },
          isVisible: false, // By default, not visible. 22521.
          documentFolderId: file?.documentFolderId,
          documentPrefixId: file?.documentPrefixId,
          additionalInformation: file?.additionalInformation,
          isForSpouse: false, // UPDATE, when backend fixed
        })
      ).catch(() => setUploadingDocuments(false));
    }

    setUploadingDocuments(false);
    if (onOk) onOk();
  }, [fileId, form, onOk]);

  const requestFilesEdit = useCallback(async () => {
    setUpdatingDocuments(true);
    const newFiles = form.getFieldValue('newFiles');

    for (let file of newFiles) {
      const document = documents?.find((item) => item.id === file.id);
      await API.documentsPUT(
        ...argumentifyUpdateDocument({
          id: document?.id as string,
          documentFolderId: file?.documentFolderId,
          documentPrefixId: file?.documentPrefixId,
          additionalInformation: file?.additionalInformation,
          fileModifiedDate: moment(),
          isVisible: document?.isVisible ?? false,
        })
      ).catch(() => setUpdatingDocuments(false));
    }

    setUpdatingDocuments(false);
    if (onOk) onOk();
  }, [form, onOk, documents]);

  const requestFilesReplace = useCallback(async () => {
    setUpdatingDocuments(true);
    const newFiles = form.getFieldValue('newFiles');

    for (let file of newFiles) {
      const document = documents?.find((item) => item.id === file.id);
      if (file.originFileObj) {
        await API.documentsPUT(
          ...argumentifyUpdateDocument({
            id: document?.id as string,
            fileModifiedDate: moment(),
            content: { fileName: sanitizeDocumentName(file?.name), data: file?.originFileObj as Blob },
            documentFolderId: file?.documentFolderId,
            documentPrefixId: file?.documentPrefixId,
            additionalInformation: file?.additionalInformation,
            isVisible: document?.isVisible ?? false,
          })
        ).catch(() => setUpdatingDocuments(false));
      } else {
        setUpdatingDocuments(false);
        genericMessage.error({}, t.UPLOAD_FILE_TO_REPLACE_MESSAGE);
      }
    }

    setUpdatingDocuments(false);
    if (onOk) onOk();
  }, [form, onOk, documents, t.UPLOAD_FILE_TO_REPLACE_MESSAGE]);

  const getModalInfo = useCallback(() => {
    switch (action) {
      case FileActionEnum.Edit:
        return {
          title: t.EDIT_FILE,
          buttonText: t.SAVE,
          onSubmit: requestFilesEdit,
        };
      case FileActionEnum.Replace:
        return {
          title: t.REPLACE_FILE,
          buttonText: t.REPLACE,
          onSubmit: requestFilesReplace,
        };
      case FileActionEnum.Upload:
        return {
          title: t.UPLOAD_FILE,
          buttonText: t.UPLOAD,
          onSubmit: requestFilesUpload,
        };
      default:
        return {
          title: t.UPLOAD_FILE,
          buttonText: t.UPLOAD,
          onSubmit: requestFilesUpload,
        };
    }
  }, [
    action,
    requestFilesEdit,
    requestFilesReplace,
    requestFilesUpload,
    t.EDIT_FILE,
    t.REPLACE,
    t.REPLACE_FILE,
    t.SAVE,
    t.UPLOAD,
    t.UPLOAD_FILE,
  ]);

  return (
    <Modal
      destroyOnClose
      centered
      visible
      title={getModalInfo()?.title}
      className="UploadModal"
      width={900}
      footer={null}
      closable
      onCancel={onCancel}
    >
      <Spin spinning={uploadingDocuments || updatingDocuments}>
        <Form layout="vertical" form={form}>
          <Form.Item name="newFiles">
            <NewFiles
              form={form}
              draggerProps={{
                multiple: action !== FileActionEnum.Replace,
                mode: action,
                totalFilesSizeLimitMB: 100,
              }}
            />
          </Form.Item>

          <Row gutter={12} justify="space-between" align="middle" className="UploadModal__buttons-container">
            <Col>{action === FileActionEnum.Upload && t.MAX_100MB_UPLOAD_MESSAGE}</Col>
            <Col>
              <Row gutter={20} justify="end" align="middle">
                <Col>
                  <Form.Item noStyle>
                    <Button kind="cancel" padding="large" onClick={onCancel}>
                      {t.CANCEL}
                    </Button>
                  </Form.Item>
                </Col>

                <Col>
                  <Form.Item noStyle shouldUpdate>
                    {({ getFieldValue }) => {
                      const newFiles = getFieldValue('newFiles');
                      return (
                        <Button
                          kind="primary"
                          padding="large"
                          onClick={() => getModalInfo()?.onSubmit()}
                          disabled={
                            !newFiles ||
                            !newFiles?.length ||
                            Boolean(
                              newFiles?.find((item: FileProps) => !item.documentFolderId || !item.documentPrefixId)
                            )
                          }
                        >
                          {getModalInfo()?.buttonText}
                        </Button>
                      );
                    }}
                  </Form.Item>
                </Col>
              </Row>
            </Col>
          </Row>
        </Form>
      </Spin>
    </Modal>
  );
}

export default UploadModal;
