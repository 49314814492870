import { useCallback, useState, useEffect, useContext, useMemo } from 'react';
import { Select, Form, Row, Col, Spin, Divider } from 'antd';
import { useParams } from 'react-router-dom';

import FormSectionTitle from '../../../../../../../components/FormSectionTitle/FormSectionTitle';
import Button from '../../../../../../../components/Button/Button';
import DataItem from '../../../../../../../components/DataItem/DataItem';
import PADRequestReviewModal from '../../modals/PADRequestReviewModal/PADRequestReviewModal';
import SelectSuffixIcon from '../../../../../../../components/SelectSuffixIcon/SelectSuffixIcon';
import PaymentSummary from './PaymentSummary';

import useLocale from '../../../../../../../hooks/useLocale';
import useModal from '../../../../../../../hooks/useModal';

import { BankingContext } from '../../BankingContext';
import { BankingPageViewEnum } from '../../types';
import { ClientService } from '../../../../../../../shared/api/ClientService';
import API from '../../../../../../../utils/api';
import { eventBus } from '../../../../../../../utils/eventBus';
import { ADMIN_INFO_CHANGE_EVENT } from '../../../../../../../constants/eventBus';

import './BankingForm.scss';

interface IProps {
  disableAllFields?: boolean;
}

const BankingForm = ({ disableAllFields }: IProps) => {
  const { t } = useLocale();
  const { Option } = Select;
  const { showModal, closeModal } = useModal();
  const { setPageView } = useContext(BankingContext);

  const { applicationFileId } = useParams<{ applicationFileId?: string }>();
  const [form] = Form.useForm<ClientService.AdminInfoBankingUpdateDto>();

  const [initial, setInitial] = useState<ClientService.AdminInfoBankingDto>();
  const [loading, setLoading] = useState<boolean>(false);

  const [isAllDeposited, setIsAllDeposited] = useState<boolean>(false);

  const BANKING_STATUS_DROPDOWN_OPTIONS = useMemo(
    () => [
      { value: ClientService.FileProcessStatusEnum.Pending, label: t.PENDING },
      { value: ClientService.FileProcessStatusEnum.InProgress, label: t.IN_PROGRESS },
      { value: ClientService.FileProcessStatusEnum.Complete, label: t.COMPLETE },
    ],
    [t.COMPLETE, t.IN_PROGRESS, t.PENDING]
  );

  const requestBankingGET = useCallback(
    async (applicationFileId: string) => {
      setLoading(true);
      const response = await API.bankingGET(applicationFileId).catch(() => setLoading(false));
      setLoading(false);

      if (response) {
        form.setFieldsValue({ status: response?.bankingProcessStatus });
        setInitial(response);
      }
    },
    [form]
  );

  const requestPadScheduleAll = useCallback(async (applicationFileId: string) => {
    setLoading(true);
    const response = await API.padScheduleAll(applicationFileId).catch(() => setLoading(false));
    setLoading(false);

    if (response) {
      const isAnyNotDeposited = Boolean(response?.find((item) => !item.isDeposited));
      setIsAllDeposited(!isAnyNotDeposited);
    }
  }, []);

  const handleRequestPadReviewModal = useCallback(() => {
    if (!disableAllFields) {
      if (applicationFileId)
        showModal(
          <PADRequestReviewModal
            onOk={() => {
              closeModal();
              requestBankingGET(applicationFileId);
              requestPadScheduleAll(applicationFileId);
            }}
            onCancel={closeModal}
            applicationFileId={applicationFileId as string}
            id={initial?.pendingChangeRequestId}
          />
        );
    }
  }, [
    applicationFileId,
    closeModal,
    disableAllFields,
    initial?.pendingChangeRequestId,
    requestBankingGET,
    requestPadScheduleAll,
    showModal,
  ]);

  const onFinish = useCallback(
    async (values) => {
      setLoading(true);
      const response = await API.bankingPUT(applicationFileId as string, values).catch(() => setLoading(false));
      setLoading(false);

      if (response?.result === ClientService.Result.Successful) {
        eventBus.dispatch(ADMIN_INFO_CHANGE_EVENT);
      }
    },
    [applicationFileId]
  );

  useEffect(() => {
    if (applicationFileId) {
      requestPadScheduleAll(applicationFileId);
      requestBankingGET(applicationFileId);
    }
  }, [applicationFileId, requestBankingGET, requestPadScheduleAll]);

  const options = useMemo(() => {
    const basis = [...BANKING_STATUS_DROPDOWN_OPTIONS];
    switch (initial?.bankingProcessStatus) {
      case ClientService.FileProcessStatusEnum.Pending:
        return basis?.splice(0, 1);
      case ClientService.FileProcessStatusEnum.InProgress:
        return basis?.splice(1, isAllDeposited ? 2 : 1);
      case ClientService.FileProcessStatusEnum.Incomplete:
        return basis?.splice(1, isAllDeposited ? 2 : 1);
      case ClientService.FileProcessStatusEnum.Complete:
        return basis?.splice(2, 1);
      default:
        return basis?.splice(0, 1);
    }
  }, [BANKING_STATUS_DROPDOWN_OPTIONS, initial?.bankingProcessStatus, isAllDeposited]);

  return (
    <Spin spinning={loading}>
      <Form layout="vertical" form={form} onFinish={onFinish} className="BankingForm">
        <Row align="bottom" gutter={20}>
          <Col span={8}>
            <Form.Item
              name="status"
              label={t.BANKING_STATUS}
              required
              rules={[{ required: true, message: t.REQUIRED_FIELD }]}
            >
              <Select size="large" suffixIcon={<SelectSuffixIcon />} placeholder={t.SELECT} disabled={disableAllFields}>
                {options?.map((item) => (
                  <Option value={item.value} key={item.value}>
                    {item.label}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col>
            <Form.Item>
              <Button htmlType="submit" disabled={disableAllFields}>
                {t.SAVE}
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>

      <PaymentSummary />

      <Divider dashed style={{ marginBottom: 0 }} />

      <FormSectionTitle
        titleText={t.PAD_INFORMATION}
        icon={{
          iconName: 'History',
          onClick: () => setPageView(BankingPageViewEnum.PadHistory),
          style: { color: 'red', cursor: 'pointer' },
        }}
      />

      <Row style={{ paddingBottom: 40 }}>
        <Col span={8}>
          <DataItem
            label={t.ACTIVE}
            layout="vertical"
            value={{ value: initial?.activePadInformation || '-', color: 'red', size: 'regular' }}
          />
        </Col>
        <Col span={8}>
          <DataItem
            label={t.CHANGE_REQUEST}
            layout="vertical"
            value={{
              value: (
                <span onClick={handleRequestPadReviewModal} style={{ cursor: 'pointer' }}>
                  {initial?.pendingChangeRequestId ? t.PENDING : t.NEW}
                </span>
              ),
              color: 'red',
              size: 'regular',
            }}
          />
        </Col>
      </Row>
    </Spin>
  );
};

export default BankingForm;
