import { useCallback } from 'react';
import { Typography, Progress, Tooltip, Popover, Row, Col } from 'antd';

import InfoBlockPopover from './InfoBlockPopover';

import { HUNDRED_PERCENT, MISSING_NUMBER } from '../../constants/common';
import { NA, MAX_LENGTH_OF_ITEMS } from './constants';

import './InfoBlock.scss';
import Icon from '../Icon/Icon';

interface IValueItem {
  label: string;
  value?: string;
  tooltip?: string;
  isItemEditable?: boolean;
  onItemClickEdit?: () => void;
}

interface IProgressItem {
  title?: string;
  label?: string;
  value?: number;
}

interface IProps {
  valueItems?: IValueItem[];
  progressItem?: IProgressItem;
  enumValue?: number;
  isEditable?: boolean;
  onClickEdit?: () => void;
}

const InfoBlock = ({ valueItems, progressItem, isEditable = false, onClickEdit, enumValue }: IProps): JSX.Element => {
  const { Text } = Typography;

  const handleEditClick = useCallback(() => {
    if (onClickEdit) onClickEdit();
  }, [onClickEdit]);

  return (
    <Row className="InfoBlock" align="middle">
      <Col span={16}>
        <Row gutter={10}>
          {valueItems?.map((item, index) => (
            <Col xl={8} md={12} xs={24} key={index}>
              <Row wrap={false}>
                <Col span={2}>
                  <Tooltip title={item?.tooltip}>
                    <Text style={{ cursor: 'pointer' }} type="secondary" strong>
                      {item?.label}
                    </Text>
                  </Tooltip>
                </Col>
                <Col flex="auto" style={{ overflowWrap: 'break-word', marginLeft: 8 }}>
                  {item?.value || NA} {item?.isItemEditable && <Icon iconName="edit" onClick={item?.onItemClickEdit} />}
                </Col>
              </Row>
            </Col>
          ))}
        </Row>
      </Col>

      <Col span={8}>
        <Row gutter={10} align="middle">
          <Col flex="none">
            <Text strong type="secondary">
              {progressItem?.title}
            </Text>
          </Col>
          <Col flex="none">{progressItem?.label}</Col>
          <Col flex="auto" style={{ cursor: 'pointer' }}>
            <Popover placement="bottomRight" content={<InfoBlockPopover value={enumValue || 0} />} trigger="click">
              <Progress
                success={{
                  percent: ((progressItem?.value ?? MISSING_NUMBER) / MAX_LENGTH_OF_ITEMS) * HUNDRED_PERCENT,
                }}
                showInfo={false}
              />
            </Popover>
          </Col>

          {isEditable && (
            <Col flex="none" className="InfoBlock__edit-btn" onClick={handleEditClick}>
              Edit
            </Col>
          )}
        </Row>
      </Col>
    </Row>
  );
};

export default InfoBlock;
