import { useCallback, useEffect, useRef, useContext } from 'react';
import WebViewer, { Core } from '@pdftron/webviewer';
import type { RcFile, UploadFile } from 'antd/es/upload/interface';
import { useQuery } from 'react-query';

import { PDFTronContext } from '../../../context/PDFTronContext';
import { eventBus } from '../../../utils/eventBus';
import { USE_QUERY_OPTIONS, DOCUMENT_TYPES_QUERY, PDFTRON_LICENSE_KEY_QUERY } from '../../../constants/reactQuery';
import { CONVERT_TO_PDF_EVENT } from '../../../constants/eventBus';

import { ClientService } from '../../../shared/api/ClientService';
import API from '../../../utils/api';

interface IEventDetail {
  uploadFiles: UploadFile[];
  onConvertionSuccess: (convertedFiles?: UploadFile[]) => void;
}

interface IProps {
  children?: JSX.Element;
}

enum PdfTronFileTypes {
  BMP = 'BMP',
  PNG = 'PNG',
  JPG = 'JPG',
  JPEG = 'JPEG',
  TIFF = 'TIFF',
}

function ConvertToPdfWrapper({ children }: IProps): JSX.Element {
  const viewerRef = useRef<HTMLDivElement>(null);
  const { converter, setConverter } = useContext(PDFTronContext);

  const { data: documentTypes } = useQuery([DOCUMENT_TYPES_QUERY], () => API.listTypes(), USE_QUERY_OPTIONS);
  const { data: licenseKey } = useQuery([PDFTRON_LICENSE_KEY_QUERY], () => API.getPDFTronKey(), USE_QUERY_OPTIONS);

  const getPDFTronFileType = useCallback(
    (fileType: string) => {
      const fileTypeDtoEnum = documentTypes?.find((item) => item?.code === fileType)?.enumValue;

      switch (fileTypeDtoEnum) {
        case ClientService.DocumentTypeEnum.BMP:
          return PdfTronFileTypes.BMP;
        case ClientService.DocumentTypeEnum.PNG:
          return PdfTronFileTypes.PNG;
        case ClientService.DocumentTypeEnum.JPG:
          return PdfTronFileTypes.JPG;
        case ClientService.DocumentTypeEnum.JPEG:
          return PdfTronFileTypes.JPEG;
        case ClientService.DocumentTypeEnum.TIFF:
          return PdfTronFileTypes.TIFF;
        default:
          return;
      }
    },
    [documentTypes]
  );

  const changeFileNameToPdf = useCallback((name?: string) => {
    const nameArray = name?.split('.');
    nameArray?.pop();
    return `${nameArray?.join('.')}.pdf`;
  }, []);

  const convertImageToPdf = useCallback(
    async (file: UploadFile, pdfTronFileType: string) => {
      const core = converter?.Core as typeof Core;
      const url = URL.createObjectURL(file?.originFileObj as Blob);

      const doc = await core?.createDocument(url, { extension: pdfTronFileType });
      const data = await doc?.getFileData();
      const arr = new Uint8Array(data);

      const pdfDocumentType = documentTypes?.find(
        (item) => item.enumValue === ClientService.DocumentTypeEnum.PDF
      )?.code;
      const blob = new Blob([arr], { type: pdfDocumentType });

      return {
        ...file,
        originFileObj: blob as RcFile,
        type: pdfDocumentType,
        name: changeFileNameToPdf(file?.name),
      };
    },
    [converter?.Core, documentTypes, changeFileNameToPdf]
  );

  const handleConvertToPdf = useCallback(
    async (e) => {
      const eventDetail = e?.detail as IEventDetail;
      const convertedFiles = [];

      if (eventDetail?.uploadFiles) {
        for (const uploadFile of eventDetail?.uploadFiles) {
          const pdfTronFileType = getPDFTronFileType(uploadFile?.type as string);
          if (pdfTronFileType) {
            const converted = await convertImageToPdf(uploadFile, pdfTronFileType);
            convertedFiles.push(converted);
          } else {
            convertedFiles.push(uploadFile);
          }
        }
      }

      if (eventDetail?.onConvertionSuccess) {
        eventDetail?.onConvertionSuccess(convertedFiles);
      }
    },
    [convertImageToPdf, getPDFTronFileType]
  );

  useEffect(() => {
    if (!converter && licenseKey?.key) {
      WebViewer(
        {
          path: '/static',
          fullAPI: true,
          licenseKey: licenseKey?.key,
        },
        viewerRef.current as HTMLDivElement
      ).then(async (converter) => {
        const core = converter?.Core as typeof Core;
        const { PDFNet } = core;
        await PDFNet.initialize();
        setConverter(converter);
      });
    }
  }, [converter, licenseKey?.key, setConverter]);

  useEffect(() => {
    return () => {
      setConverter(undefined);
    };
  }, [setConverter]);

  useEffect(() => {
    eventBus.on(CONVERT_TO_PDF_EVENT, handleConvertToPdf);

    return () => {
      eventBus.remove(CONVERT_TO_PDF_EVENT, handleConvertToPdf);
    };
  }, [handleConvertToPdf]);

  return (
    <>
      <div ref={viewerRef} style={{ visibility: 'hidden', height: 1 }} />
      {children}
    </>
  );
}

export default ConvertToPdfWrapper;
