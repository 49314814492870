import { useEffect, useState, useMemo, useCallback } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Divider, Row, Col, ConfigProvider } from 'antd';

import moment from 'moment';
import Layout from '../../components/Layout/Layout';
import PageTitle from '../../components/PageTitle/PageTitle';
import NewAndViewHeader from '../ApplicationOverviewPage/NewAndViewPageComponents/NewAndViewHeader/NewAndViewHeader';
import { ROUTES } from '../../constants/routes';
import useLocale from '../../hooks/useLocale';
import AppForm from '../../components/Forms/AppForm/AppForm';
import EmailContentDisplay, {
  TEmailData,
} from '../ApplicationOverviewPage/NewAndViewPageComponents/EmailContentDisplay/EmailContentDisplay';
import FormInput from '../../components/Forms/FormInput/FormInput';
import DataItem from '../../components/DataItem/DataItem';
import DynamicInfoBlock, { TDataItem } from '../../components/DynamicInfoBlock/DynamicInfoBlock';
import LabelWithExtraValue from '../../components/Forms/Label/LabelWithExtraValue';
import ThreeColumnLowerLayout from '../ApplicationOverviewPage/NewAndViewPageComponents/ThreeColumnLowerLayout/ThreeColumnLowerLayout';
import ButtonsGroupCancelDraftComplete from '../ApplicationOverviewPage/NewAndViewPageComponents/NewAndViewHeader/HeaderButtonGroups/ButtonsGroupCancelDraftComplete';
import Loading from '../../components/Loading/Loading';
import { ClientService } from '../../shared/api/ClientService';
import API from '../../utils/api';
import ErrorsContainer, { IErrorsMsgAndType } from '../../components/ErrorsContainer/ErrorsContainer';
import useErrorHandling from '../../hooks/useErrorHandling';
import { DATE_FORMAT2, MAX_PAGE_SIZE } from '../../constants/common';
import {
  getFirstCharacters,
  getFormValueOrFetchedValueOrUndefined,
  getStringDecimal,
  getUndefinedOrBoolean,
  getUndefinedOrSelf,
  isNullOrWhitespace,
  formatToCurrency,
  GetStringCurrency,
  convertServerDateOnlyToInputDate,
} from '../../utils/helpers';
import LookAheadField, { IOption } from '../../components/LookAheadField/LookAheadField';
import { argumentifyEmailTemplatesCriteria } from '../ApplicationOverviewPage/NewAndViewPageComponents/utils';
import InfoBubble from '../../components/InfoBubble/InfoBubble';
import useAlertAsAsync from '../ApplicationOverviewPage/NewAndViewPageComponents/AlertModal/useAlertAsAsync';
import genericMessage from '../../utils/genericMessage';
import useModal from '../../hooks/useModal';
import SendEmailConfirmation from '../../modals/SendEmailConfirmation/SendEmailConfirmation';
import OnDropUploader from '../../components/OnDropUploader/OnDropUploader';
import ButtonsGroupEdit from '../ApplicationOverviewPage/NewAndViewPageComponents/NewAndViewHeader/HeaderButtonGroups/ButtonsGroupEdit';
import { argumentifyDocumentsSearchCriteria } from '../ApplicationOverviewPage/DocumentsContent/utils';

import styles from './ViewClaimPage.module.scss';
import RequestForReview from '../../components/RequestForReview/RequestForReview';
import ActionConfirmationModal from '../../modals/ActionConfirmationModal/ActionConfirmationModal';
import { argumentifyTaskActionSearchCriteria } from '../../pages/LandingPage/utils';
import { AuthorizeService } from '../../components/Auth/AuthorizeService';

interface ILeftColumnDataValues {
  claimStatus?: string;
  soaAmount?: string;
  claimExternalAmountFiled?: string;
  claimAmountFiled?: string;
  claimExternalVoting?: string;
  claimVoting?: string;
  externalMeetingRequested?: string;
  meetingRequested?: string;
  externalVotedBy?: string;
  votedBy?: string;
}

interface IViewClaimForm {
  result?: number;
  discrepancy?: string;
  creditorEmailTemplate?: string;
  soaAmount?: number;
  claimAmountFiled?: string;
  claimVoting?: string;
  meetingRequested?: string;
  votedBy?: string;
  claimAmountMatches?: string;
  securityAgreement?: string;
  securityRegistration?: string;
  validateRegistrationDescription?: string;
  securityRegistrationDate?: moment.Moment;
  isThereAnEquity?: string;
  is170ReportRequested?: string;
}

const FOR = 'for',
  AGAINST = 'against',
  DID_NOT_VOTE = 'did not vote';

const NA = 'NA';

const IN_PERSON = 'in person',
  LETTER = 'letter',
  PROXY = 'proxy';

const AVAILABLE = 'available',
  NOT_AVAILABLE = 'not available';

const MATCHES = 'matches',
  DOES_NOT_MATCH = 'does not match';

const COMPLETE = 'Complete',
  INACTIVE = 'Inactive',
  DUPLICATE = 'Duplicate';

const getSubmitValuesFromForm = (
  claimGetResponse: ClientService.IClaimFileDetailDto,
  formValues: IViewClaimForm
): ClientService.IClaimFileUpdateDto => {
  const soaAmount = claimGetResponse.isSecured
    ? claimGetResponse.securedSOAAmount
    : claimGetResponse.unsecuredSOAAmount;

  return {
    state: claimGetResponse.state,
    result: getUndefinedOrSelf(formValues.result),
    discrepancy: getUndefinedOrBoolean(formValues.discrepancy),
    creditorEmailTemplateId: getFormValueOrFetchedValueOrUndefined(
      formValues.creditorEmailTemplate,
      claimGetResponse.emailTemplateId
    ),
    filedAmount:
      formValues.claimAmountFiled == null || formValues.claimAmountFiled === ''
        ? undefined
        : Number(Number(getStringDecimal(formValues.claimAmountFiled)).toFixed(2)),
    soa: getUndefinedOrSelf(soaAmount),
    vote: getUndefinedOrSelf(formValues.claimVoting),
    isMeetingRequested:
      formValues.meetingRequested !== NA ? getUndefinedOrBoolean(formValues.meetingRequested) : undefined,
    isMeetingRequestedOther: formValues.meetingRequested === NA ? NA : undefined,
    votedBy: getUndefinedOrSelf(formValues.votedBy),
    isClaimAmountMatching: getFormValueOrFetchedValueOrUndefined(
      formValues.claimAmountMatches,
      claimGetResponse.isClaimAmountMatching
    ),
    isSecurityAgreementAvailable: getFormValueOrFetchedValueOrUndefined(
      formValues.securityAgreement,
      claimGetResponse.isSecurityAgreementAvailable,
      AVAILABLE,
      NOT_AVAILABLE
    ),
    isSecurityRegistrationAvailable: getFormValueOrFetchedValueOrUndefined(
      formValues.securityRegistration,
      claimGetResponse.isSecurityRegistrationAvailable,
      AVAILABLE,
      NOT_AVAILABLE
    ),
    isRegistrationDescriptionMatching: getFormValueOrFetchedValueOrUndefined(
      formValues.validateRegistrationDescription,
      claimGetResponse.isRegistrationDescriptionMatching,
      MATCHES,
      DOES_NOT_MATCH
    ),
    securityRegistrationDate: getFormValueOrFetchedValueOrUndefined(
      formValues.securityRegistrationDate,
      claimGetResponse.securityRegistrationDate
    ),
    isThereAnEquity: getFormValueOrFetchedValueOrUndefined(
      formValues.isThereAnEquity,
      claimGetResponse.isThereAnEquity
    ),
    is170ReportRequested: getUndefinedOrBoolean(formValues.is170ReportRequested),
  };
};

const ViewClaimPage = () => {
  const navigate = useNavigate();
  const { applicationFileId, claimFileId } = useParams();
  const { t } = useLocale();
  const { handleAlert } = useAlertAsAsync();
  const [form] = AppForm.AntD.useForm<IViewClaimForm>();
  const { processResponseForErrors } = useErrorHandling();
  const { showModal, closeModal } = useModal();

  const [debtorProfile, setDebtorProfile] = useState<ClientService.DebtorProfileDto | undefined>();

  const [claimResponseFailed, setClaimResponseFailed] = useState<boolean>(false);
  const [claimFileInfo, setClaimFileInfo] = useState<ClientService.ClaimFileDetailDto | undefined>();
  const [claimFileState, setClaimFileState] = useState<string | undefined>();
  const [claimIsSecured, setClaimIsSecured] = useState<boolean | undefined>();
  /** It is about Creditor Email info. */
  const [infoBlockItems, setInfoBlockItems] = useState<TDataItem[] | undefined>();
  const [leftColumnStaticDataValues, setLeftColumnStaticDataValues] = useState<ILeftColumnDataValues | undefined>();
  const [documentIds, setDocumentIds] = useState<string[] | undefined>();
  const [emailInfo, setEmailInfo] = useState<TEmailData | undefined>();
  const [resultOptions, setResultOptions] = useState<any>();

  const [uploadFolderID, setUploadFolderID] = useState<string | undefined>();
  const [uploadPrefixID, setUploadPrefixID] = useState<string | undefined>();
  const [uploadedFileIDs, setUploadedFileIDs] = useState<string[] | undefined>();

  const [claimAmountFiledValue, setClaimAmountFiledValue] = useState<number | undefined>();
  const [claimVotingValue, setClaimVotingValue] = useState<string | undefined>();

  const [initialFormValuesHaveBeenSet, setInitialFormValuesHaveBeenSet] = useState<boolean>(false);
  const [formIsLoading, setFormIsLoading] = useState<boolean>(true);

  const [emailTemplateSelection, setEmailTemplateSelection] = useState<IOption | undefined>();

  const [infoBlockIsLoading, setInfoBlockIsLoading] = useState<boolean>(true);
  const [threeColLayoutIsLoading, setThreeColLayoutIsLoading] = useState<boolean>(true);

  const [pageErrors, setPageErrors] = useState<IErrorsMsgAndType[] | undefined>();
  const [headerErrors, setHeaderErrors] = useState<IErrorsMsgAndType[] | undefined>();
  const [uploadErrors, setUploadErrors] = useState<IErrorsMsgAndType[] | undefined>();

  const [fieldsAreReadOnly, setFieldsAreReadOnly] = useState<boolean>(false);

  const resetPageData = useCallback(() => {
    setClaimFileInfo(undefined);
    setInitialFormValuesHaveBeenSet(false);
    setClaimAmountFiledValue(undefined);
    setClaimVotingValue(undefined);
    setEmailTemplateSelection(undefined);
    setThreeColLayoutIsLoading(true);
    setPageErrors(undefined);
    setHeaderErrors(undefined);
  }, []);

  const getInfoBlockItems = useCallback(
    (info: { [key: string]: any }): TDataItem[] => {
      return [
        { label: t.ASCEND_ESTATE_NUMBER, value: info.estateNumber },
        { label: t.ASCEND_SIN, value: info.sin },
        { label: t.ASCEND_DEBTOR_NAME, value: info.debtorName },
        {
          label: t.ASCEND_DATE_OF_INSOLVENCY,
          value: info?.dateOfInsolvency
            ? convertServerDateOnlyToInputDate(moment(info?.dateOfInsolvency))?.format(DATE_FORMAT2)
            : '',
        },
        { label: t.ASCEND_CREDITOR_NAME, value: info.creditorName },
        { label: t.ASCEND_ACCOUNT_NUMBER, value: info.accountNumber },
        { label: t.ASCEND_MERGE_CREDITOR_EMAIL, value: info.mergeCreditorEmail ? t.YES : t.NO },
        { label: t.INFO_FILENAME, value: info.fileName },
        // Removed based on Bug:21414
        // { label: t.INFO_DESCRIPTION, value: info.description },
      ];
    },
    [
      t.ASCEND_ESTATE_NUMBER,
      t.ASCEND_SIN,
      t.ASCEND_DEBTOR_NAME,
      t.ASCEND_DATE_OF_INSOLVENCY,
      t.ASCEND_CREDITOR_NAME,
      t.ASCEND_ACCOUNT_NUMBER,
      t.ASCEND_MERGE_CREDITOR_EMAIL,
      t.INFO_FILENAME,
      t.YES,
      t.NO,
    ]
  );

  const votedByOptions = useMemo(() => {
    return [
      { label: t.VIEW_CLAIM_VOTED_BY__IN_PERSON, value: IN_PERSON },
      { label: t.VIEW_CLAIM_VOTED_BY__LETTER, value: LETTER },
      { label: t.VIEW_CLAIM_VOTED_BY__PROXY, value: PROXY },
      { label: t.NA, value: NA },
    ];
  }, [t.VIEW_CLAIM_VOTED_BY__IN_PERSON, t.VIEW_CLAIM_VOTED_BY__LETTER, t.VIEW_CLAIM_VOTED_BY__PROXY, t.NA]);

  const claimVotingOptions = useMemo(() => {
    return [
      { label: t.VIEW_CLAIM_CLAIM_VOTING__FOR, value: FOR },
      { label: t.VIEW_CLAIM_CLAIM_VOTING__AGAINST, value: AGAINST },
      { label: t.VIEW_CLAIM_CLAIM_VOTING__DID_NOT_VOTE, value: DID_NOT_VOTE },
      { label: t.NA, value: NA },
    ];
  }, [
    t.VIEW_CLAIM_CLAIM_VOTING__FOR,
    t.VIEW_CLAIM_CLAIM_VOTING__AGAINST,
    t.VIEW_CLAIM_CLAIM_VOTING__DID_NOT_VOTE,
    t.NA,
  ]);

  /** Redirect page when "Complete" process is done success.   */
  const handleOnCompleteNavigate = useCallback(async () => {
    const user = AuthorizeService.getCurrentUserInfo();
    const response = await API.taskActionsGET(
      ...argumentifyTaskActionSearchCriteria({
        maxResultCount: 1000,
        skipCount: 0,
        assignedAgentId: user?.profile?.sub,
        calledFromDashboard: true,
        teamTypes: [ClientService.TeamTypeEnum.POC_CPC, ClientService.TeamTypeEnum.Asset_CPC],
      })
    );
    if (response) {
      const nextPendingActionId = response?.items
        ?.filter((item) => !Boolean(item?.linkedClaimFileId))
        ?.sort(
          (a, b) =>
            (moment(b?.closeDate).diff(moment(b?.openDate), 'days') || 0) -
            (moment(a?.closeDate).diff(moment(a?.openDate), 'days') || 0)
        )?.[0]?.id;

      if (nextPendingActionId) {
        navigate(`${ROUTES.TASK_ACTIONS}/${ROUTES.NEW_CLAIM}/${nextPendingActionId}`);
      } else {
        navigate(ROUTES.DASHBOARD);
      }
    }
  }, [navigate]);

  useEffect(() => {
    if (debtorProfile || !applicationFileId) return;

    API.getDebtorProfile(applicationFileId)
      .then((response) => {
        const responseErrors = processResponseForErrors(response);
        setHeaderErrors(responseErrors.messages);
        if (responseErrors.hasErrors) {
          console.error('Could not retrieve Debtor information');
          return;
        }
        // console.log('Debtor Data:', response);
        setDebtorProfile(response.profile);
      })
      .catch((error) => {
        console.error('Could not retrieve Debtor information');
      });
  }, [debtorProfile, applicationFileId, processResponseForErrors]);

  useEffect(() => {
    // get list of upload folders
    if (!claimIsSecured) return;
    API.listFolders()
      .then((response) => {
        // console.log('UPLOAD FOLDERS:', response);
        const responseErrors = processResponseForErrors(response as any);
        if (responseErrors.hasErrors) {
          console.error('could not retrieve list of upload folders');
          return;
        }
        response.forEach((folder) => {
          if (folder.code === 'claims') {
            setUploadFolderID(folder.id);
          }
        });
      })
      .catch((error) => {
        console.error('problem fetching list of upload folders');
      });
  }, [claimIsSecured, processResponseForErrors]);

  useEffect(() => {
    // get list of prefixes for upload folder
    if (!claimIsSecured || !uploadFolderID) return;

    API.listPrefixes(uploadFolderID, undefined)
      .then((response) => {
        // console.log('CLAIMS FOLDER PREFIXES:', response);
        const responseErrors = processResponseForErrors(response as any);
        if (responseErrors.hasErrors) {
          console.error('could not retrieve list of upload folders');
          return;
        }
        response.forEach((prefix) => {
          if (prefix.code === 'Claims.SecuredChecklist') {
            setUploadPrefixID(prefix.id);
          }
        });
      })
      .catch((error) => {
        console.error('problem fetching list of prefixes');
      });
  }, [claimIsSecured, uploadFolderID, processResponseForErrors]);

  const requestClaimsGet = useCallback(
    (claimFileId: string) => {
      API.claimFileGET(claimFileId)
        .then((response) => {
          const responseErrors = processResponseForErrors(response);
          setPageErrors(responseErrors.messages);
          if (responseErrors.hasErrors) {
            setClaimResponseFailed(true);
            setFormIsLoading(false);
            setInfoBlockIsLoading(false);
            setThreeColLayoutIsLoading(false);
            return;
          }

          setClaimFileInfo(response);
        })
        .catch((error) => {
          setPageErrors([{ message: t.DATA_COULD_NOT_BE_RETRIEVED }]);
          setClaimResponseFailed(true);
          setFormIsLoading(false);
          setInfoBlockIsLoading(false);
          setThreeColLayoutIsLoading(false);
        });
    },
    [processResponseForErrors, t.DATA_COULD_NOT_BE_RETRIEVED]
  );

  useEffect(() => {
    if (claimFileInfo || claimFileId == null) return;
    requestClaimsGet(claimFileId);
  }, [claimFileInfo, claimFileId, requestClaimsGet]);

  useEffect(() => {
    if (!claimFileInfo) return;

    setClaimIsSecured(claimFileInfo.isSecured);

    let claimFileStatusGroup = claimFileInfo.isSecured
      ? ClientService.ClaimFileStatusGroupEnum.SecuredClaims
      : ClientService.ClaimFileStatusGroupEnum.UnsecuredClaims;

    API.statusTypes(claimFileStatusGroup)
      .then((response) => {
        let newResultOptions = response.map((option) => {
          return {
            label: t[option.code ? option.code?.toLocaleUpperCase() : 'UNKNOWN'],
            value: option.code ? option.enumValue : -1,
          };
        });

        setResultOptions(newResultOptions);
      })
      .catch((error) => {
        console.error('problem fetching list of claim file statusyses');
      });

    if (claimFileInfo.info) {
      const infoBlockData = claimFileInfo.info;
      setInfoBlockItems(getInfoBlockItems(infoBlockData));
      setInfoBlockIsLoading(false);
    } else {
      setInfoBlockIsLoading(false);
    }

    if (claimFileInfo.state != null) {
      let claimState = claimFileInfo.state;

      setClaimFileState(ClientService.ClaimFileStateEnum[claimState]);
      if (
        claimState === ClientService.ClaimFileStateEnum.Complete ||
        claimState === ClientService.ClaimFileStateEnum.Inactive ||
        claimState === ClientService.ClaimFileStateEnum.Duplicate
      ) {
        setFieldsAreReadOnly(true);
      } else {
        setFieldsAreReadOnly(false);
      }
    }

    setEmailInfo({
      fromName: claimFileInfo.importedEmailSenderName,
      fromAddress: claimFileInfo.importedEmailSenderEmailAddress,
      toName: claimFileInfo.importedEmailRecipientName,
      toAddress: claimFileInfo.importedEmailRecipientEmailAddress,
      subjectField: claimFileInfo.importedEmailSubject,
      bodyField: claimFileInfo.importedEmailBody,
    });

    setLeftColumnStaticDataValues({
      claimStatus: claimFileInfo.claimStatus,
      soaAmount: claimFileInfo.isSecured
        ? String(claimFileInfo.securedSOAAmount)
        : String(claimFileInfo.unsecuredSOAAmount),
      claimExternalAmountFiled:
        claimFileInfo.externalFiledAmount == null ? '-' : String(claimFileInfo.externalFiledAmount),
      claimAmountFiled: claimFileInfo.filedAmount == null ? '-' : String(claimFileInfo.filedAmount),
      claimExternalVoting:
        claimFileInfo.externalVote == null
          ? '-'
          : claimFileInfo.externalVote === ClientService.ClaimVote.Other &&
            !isNullOrWhitespace(claimFileInfo.externalVoteOther)
          ? claimFileInfo.externalVoteOther
          : t[ClientService.ClaimVote[claimFileInfo.externalVote].toUpperCase()],
      claimVoting: claimFileInfo.vote == null ? '-' : claimFileInfo.vote,
      externalMeetingRequested:
        claimFileInfo.externalIsMeetingRequested == null
          ? '-'
          : claimFileInfo.externalIsMeetingRequested
          ? t.YES
          : t.NO,
      meetingRequested:
        claimFileInfo.isMeetingRequested == null
          ? claimFileInfo.isMeetingRequestedOther
            ? claimFileInfo.isMeetingRequestedOther
            : undefined
          : claimFileInfo.isMeetingRequested
          ? 'yes'
          : 'no',
      externalVotedBy:
        claimFileInfo.externalVotedBy == null
          ? '-'
          : claimFileInfo.externalVotedBy === ClientService.ClaimVotedBy.Other &&
            !isNullOrWhitespace(claimFileInfo.externalVotedByOther)
          ? claimFileInfo.externalVotedByOther
          : t[ClientService.ClaimVotedBy[claimFileInfo.externalVotedBy].toUpperCase()],
      votedBy: claimFileInfo.is170ReportRequested == null ? '-' : claimFileInfo.is170ReportRequested ? t.YES : t.NO,
    });
    if (claimFileInfo.vote != null) setClaimVotingValue(claimFileInfo.vote);
    if (claimFileInfo.filedAmount != null) setClaimAmountFiledValue(claimFileInfo.filedAmount);

    setDocumentIds(claimFileInfo.documentIds);
    setUploadedFileIDs(claimFileInfo.checklistDocumentIds);
  }, [claimFileInfo, getInfoBlockItems, t]);

  useEffect(() => {
    if (!claimFileInfo || initialFormValuesHaveBeenSet) return;

    let claimFields = {
      result: claimFileInfo.result,
      discrepancy: claimFileInfo.isDiscrepancy == null ? undefined : claimFileInfo.isDiscrepancy ? 'yes' : 'no',
      soaAmount: claimFileInfo.isSecured ? claimFileInfo.securedSOAAmount : claimFileInfo.unsecuredSOAAmount,
      claimAmountFiled:
        claimFileInfo.filedAmount != null ? GetStringCurrency(String(claimFileInfo.filedAmount.toFixed(2))) : '',
      claimVoting: claimFileInfo.vote == null ? undefined : claimFileInfo.vote,
      meetingRequested:
        claimFileInfo.isMeetingRequested == null
          ? claimFileInfo.isMeetingRequestedOther
            ? claimFileInfo.isMeetingRequestedOther
            : undefined
          : claimFileInfo.isMeetingRequested
          ? 'yes'
          : 'no',
      votedBy: claimFileInfo.votedBy == null ? undefined : claimFileInfo.votedBy,
      is170ReportRequested:
        claimFileInfo.is170ReportRequested == null ? undefined : claimFileInfo.is170ReportRequested ? 'yes' : 'no',
    };

    let securedFields = {};

    if (claimIsSecured || claimFileInfo.isSecured) {
      securedFields = {
        claimAmountMatches:
          claimFileInfo.isClaimAmountMatching == null ? undefined : claimFileInfo.isClaimAmountMatching ? 'yes' : 'no',
        securityAgreement:
          claimFileInfo.isSecurityAgreementAvailable == null
            ? undefined
            : claimFileInfo.isSecurityAgreementAvailable
            ? AVAILABLE
            : NOT_AVAILABLE,
        securityRegistration:
          claimFileInfo.isSecurityRegistrationAvailable == null
            ? undefined
            : claimFileInfo.isSecurityRegistrationAvailable
            ? AVAILABLE
            : NOT_AVAILABLE,
        validateRegistrationDescription:
          claimFileInfo.isRegistrationDescriptionMatching == null
            ? undefined
            : claimFileInfo.isRegistrationDescriptionMatching
            ? MATCHES
            : DOES_NOT_MATCH,
        securityRegistrationDate:
          claimFileInfo.securityRegistrationDate == null ? undefined : claimFileInfo.securityRegistrationDate,
        isThereAnEquity:
          claimFileInfo.isThereAnEquity == null ? undefined : claimFileInfo.isThereAnEquity ? 'yes' : 'no',
      };

      claimFields = {
        ...claimFields,
        ...securedFields,
      };
    }

    form.setFieldsValue(claimFields);
    setInitialFormValuesHaveBeenSet(true);
    setFormIsLoading(false);
  }, [claimFileInfo, claimIsSecured, initialFormValuesHaveBeenSet, form]);

  useEffect(() => {
    if (
      !claimFileInfo ||
      !emailInfo ||
      !documentIds ||
      !leftColumnStaticDataValues ||
      !initialFormValuesHaveBeenSet ||
      claimIsSecured == null
    ) {
      return;
    }

    setThreeColLayoutIsLoading(false);
  }, [claimFileInfo, emailInfo, documentIds, leftColumnStaticDataValues, initialFormValuesHaveBeenSet, claimIsSecured]);

  useEffect(() => {
    if (emailTemplateSelection == null) {
      form.setFieldsValue({ creditorEmailTemplate: undefined });
      return;
    }
    form.setFieldsValue({ creditorEmailTemplate: emailTemplateSelection?.value });
  }, [emailTemplateSelection, form]);

  const claimAmountDifference = useMemo(() => {
    if (!claimFileInfo || claimAmountFiledValue == null) return undefined;
    if (claimFileInfo.isSecured && claimFileInfo.securedSOAAmount == null) return undefined;
    if (!claimFileInfo.isSecured && claimFileInfo.unsecuredSOAAmount == null) return undefined;

    const soaAmount = (
      claimFileInfo.isSecured ? claimFileInfo.securedSOAAmount : claimFileInfo.unsecuredSOAAmount
    ) as number;
    const difference = ((claimAmountFiledValue - soaAmount) / soaAmount) * 100;
    return Number(difference.toFixed(1));
  }, [claimFileInfo, claimAmountFiledValue]);

  const requestDocumentReview = useCallback(async () => {
    const documentsForReview = await API.documentsGET(
      ...argumentifyDocumentsSearchCriteria({ documentIds: uploadedFileIDs, maxResultCount: MAX_PAGE_SIZE })
    );
    const responseErrors = processResponseForErrors(documentsForReview);
    setHeaderErrors(responseErrors.messages);
    if (responseErrors.hasErrors) return;

    return new Promise((resolve) => {
      showModal(
        <RequestForReview
          data={documentsForReview.items as ClientService.IDocumentDto[]}
          onOk={() => {
            closeModal();
            resolve(true);
          }}
          onCancel={() => {
            closeModal();
            resolve(true);
          }}
        />
      );
    });
  }, [uploadedFileIDs, processResponseForErrors, showModal, closeModal]);

  const openDocumentsInNewTab = useCallback(() => {
    if (documentIds && documentIds?.length) {
      localStorage.setItem('redirect', ROUTES.FILE_PREVIEW);
      localStorage.setItem('fileIdArray', JSON.stringify(documentIds));

      let a = document.createElement('a');
      a.href = ROUTES.FILE_PREVIEW;
      a.target = '_blank';
      a.click();
    }
  }, [documentIds]);

  /**
   * Handle send Creditor Email. #20431
   */
  const sendCreditorEmail = useCallback(() => {
    const formValues = form.getFieldsValue();
    if (formValues.creditorEmailTemplate == null || !applicationFileId) return;
    // if (!claimFileInfo?.importedEmailSenderEmailAddress) {
    //   setHeaderErrors([{ message: t.CREDITOR_EMAIL_REQUIRED }]);
    //   return;
    // }

    //
    // Logic: The current claim file will be open in a new browser tab.
    // Note: The claim file is open in a new browser tab to enable the user to view the file and modify the email content before sending it to the creditor.
    //
    openDocumentsInNewTab();

    // Popup module for sending email to creditor.
    return new Promise((resolve) => {
      showModal(
        <SendEmailConfirmation
          fileId={applicationFileId as string}
          claimId={claimFileId as string}
          emailTemplateId={formValues.creditorEmailTemplate}
          recipientEmailAddress={claimFileInfo?.importedEmailSenderEmailAddress ?? ''} // It is current claim's creditor email address.
          ignoreDoNotEmail={true}
          onCancel={() => resolve(true)}
          onSend={() => resolve(true)}
        />
      );
    });
  }, [
    form,
    applicationFileId,
    openDocumentsInNewTab,
    showModal,
    claimFileId,
    claimFileInfo?.importedEmailSenderEmailAddress,
  ]);

  const handleDescrapencyConfirmation = useCallback(
    (message: string): Promise<boolean> => {
      return new Promise((resolve) => {
        showModal(
          <ActionConfirmationModal
            title={`${t.VIEW_CLAIM_DISCREPANCY_CONFIRMATION}!`}
            message={message}
            okText={t.YES}
            cancelText={t.NO}
            onOk={() => {
              closeModal();
              resolve(true);
            }}
            onCancel={() => {
              closeModal();
              resolve(false);
            }}
          />
        );
      });
    },
    [closeModal, showModal, t.NO, t.VIEW_CLAIM_DISCREPANCY_CONFIRMATION, t.YES]
  );

  const handleSave = useCallback(
    async (isComplete = false, withAlert = false) => {
      if (!claimFileInfo || claimFileId == null) return;
      setHeaderErrors(undefined);

      let requestIsValid = true;
      const formValues = form.getFieldsValue();
      // console.log('formValues:', formValues);
      if (isComplete) {
        try {
          await form.validateFields();
        } catch (error) {
          // console.log('form validation error', error);
          requestIsValid = false;
        }
      }

      if (!requestIsValid) {
        setHeaderErrors([{ message: t.ERROR__REQUIRED_FIELDS_MISSING }]);
        return;
      }

      let hasUploadedFiles = false;
      if (isComplete && claimIsSecured) {
        if (!uploadedFileIDs || !Array.isArray(uploadedFileIDs) || !uploadedFileIDs.length) {
          setHeaderErrors([{ message: t.VIEW_CLAIM_ERROR__AT_LEAST_ONE_FILE }]);
          return;
        }
        hasUploadedFiles = true;
      }

      // Combine page form values with fetched values needed for save
      let requestBody: ClientService.IClaimFileUpdateDto = getSubmitValuesFromForm(claimFileInfo, formValues);
      requestBody = {
        ...requestBody,
        checkListDocumentIds: uploadedFileIDs,
      };

      if (isComplete) {
        requestBody = {
          ...requestBody,
          isComplete: true,
        };
      }

      const request = new ClientService.ClaimFileUpdateDto(requestBody);

      if (withAlert) {
        const alertBody = await handleAlert();

        if (!alertBody) return;
        const typedAlertBody = alertBody as ClientService.IAlertCreateDto;
        const alertRequest = new ClientService.AlertCreateDto(typedAlertBody);
        request.alerts = alertRequest;
      }

      try {
        let saveResponse = await API.claimFilePUT(claimFileId, request);

        if (saveResponse.hasConfirm) {
          const confirmationMessage =
            (saveResponse.messages && saveResponse.messages[0]?.body) || t.VIEW_CLAIM_DISCREPANCY_CONFIRMATION_MESSAGE;
          const confirmationResult = await handleDescrapencyConfirmation(confirmationMessage);

          if (!confirmationResult) {
            return;
          }

          request.isConfirmedToComplete = confirmationResult;

          saveResponse = await API.claimFilePUT(claimFileId, request);
        }

        const responseErrors = processResponseForErrors(saveResponse);

        setHeaderErrors(responseErrors.messages);
        if (responseErrors.hasErrors) return;
        setTimeout(() => genericMessage.success(t.SUCCESSFULLY_SAVED), 500);

        if (isComplete) {
          if (formValues.creditorEmailTemplate != null) {
            //
            // Popup Send Creditor email module.
            //
            await sendCreditorEmail();
          }

          if (hasUploadedFiles) {
            await requestDocumentReview();
          }

          resetPageData();
          handleOnCompleteNavigate();
        }
      } catch (error: any) {
        setHeaderErrors([{ message: t.ERROR__TRYING_TO_SAVE }]);
        return;
      }
    },
    [
      claimFileInfo,
      claimFileId,
      form,
      claimIsSecured,
      t.ERROR__REQUIRED_FIELDS_MISSING,
      t.VIEW_CLAIM_ERROR__AT_LEAST_ONE_FILE,
      t.VIEW_CLAIM_DISCREPANCY_CONFIRMATION_MESSAGE,
      t.SUCCESSFULLY_SAVED,
      t.ERROR__TRYING_TO_SAVE,
      uploadedFileIDs,
      handleAlert,
      processResponseForErrors,
      handleDescrapencyConfirmation,
      sendCreditorEmail,
      requestDocumentReview,
      resetPageData,
      handleOnCompleteNavigate,
    ]
  );

  const renderHeaderButtons = useCallback(() => {
    if (!claimFileState) return;
    if (claimFileState === COMPLETE) {
      return (
        <ButtonsGroupEdit
          buttonsEnabled={initialFormValuesHaveBeenSet}
          onEdit={() => {
            setClaimFileInfo(
              (prev) =>
                ({ ...prev, state: ClientService.ClaimFileStateEnum.Active } as ClientService.ClaimFileDetailDto)
            );
            form.setFieldsValue({ result: undefined, discrepancy: undefined });
          }}
        />
      );
    } else if (claimFileState === INACTIVE || claimFileState === DUPLICATE) {
      return null;
    }
    return (
      <ButtonsGroupCancelDraftComplete
        buttonsEnabled={initialFormValuesHaveBeenSet}
        onCancel={() => {
          resetPageData();
          setFormIsLoading(true);
          requestClaimsGet(claimFileId as string);
        }}
        onSaveAsDraft={() => handleSave()}
        onSaveAsDraftWithAlert={() => handleSave(false, true)}
        onComplete={() => handleSave(true, false)}
        onCompleteWithAlert={() => handleSave(true, true)}
      />
    );
  }, [claimFileState, initialFormValuesHaveBeenSet, form, resetPageData, requestClaimsGet, claimFileId, handleSave]);

  const updateChecklistFiles = useCallback(
    (documentId: string, action: 'add' | 'delete') => {
      if (documentId == null) return;

      let updatedDocIds: string[] = [];

      if (action === 'add') {
        updatedDocIds = Array.isArray(uploadedFileIDs) ? [...uploadedFileIDs, documentId] : [documentId];
      } else if (action === 'delete') {
        updatedDocIds =
          !Array.isArray(uploadedFileIDs) || !uploadedFileIDs.length
            ? []
            : uploadedFileIDs.filter((id) => {
                return id !== documentId;
              });
      }

      setUploadedFileIDs(updatedDocIds.length === 0 ? undefined : updatedDocIds);

      API.updateChecklistDocuments(claimFileId, updatedDocIds)
        .then((response) => {
          const responseErrors = processResponseForErrors(response);
          setUploadErrors(responseErrors.messages);
        })
        .catch((error) => {
          setUploadErrors([{ message: t.ERROR__ERROR_WITH_UPLOAD }]);
        });
    },
    [claimFileId, uploadedFileIDs, processResponseForErrors, t.ERROR__ERROR_WITH_UPLOAD]
  );

  return (
    <ConfigProvider
      getPopupContainer={(node?: HTMLElement) => {
        if (node) {
          return node.parentElement as HTMLElement;
        }
        return document.body;
      }}
    >
      <Layout page={ROUTES.CLAIMS}>
        <div className={styles.ViewClaimPage}>
          {pageErrors && <ErrorsContainer errors={pageErrors} noTitle />}
          <PageTitle title={t.CLAIMS_CLAIM_PROCESS} />
          <NewAndViewHeader
            errors={headerErrors}
            fileId={applicationFileId}
            claimFileId={claimFileId}
            subtitle={
              claimIsSecured == null ? 'Loading...' : claimIsSecured ? 'view-secured-claim' : 'view-unsecured-claim'
            }
            status={claimFileState}
            breadcrumbData={[{ link: ROUTES.DASHBOARD, title: t.DASHBOARD }, { title: t.CLAIMS_CLAIM_PROCESS }]}
            buttons={renderHeaderButtons()}
          />
          <AppForm form={form} readonlyAllFields={fieldsAreReadOnly} isLoading={formIsLoading}>
            <Row gutter={20}>
              <Col span={24}>
                <DynamicInfoBlock
                  externalDataIsLoading={infoBlockIsLoading}
                  loadError={claimResponseFailed}
                  itemsPerRow={3}
                  data={infoBlockItems}
                />
              </Col>
            </Row>
            <Row gutter={20}>
              <Col span={6}>
                <FormInput
                  type="select"
                  label={t.VIEW_CLAIM_RESULT}
                  name="result"
                  placeholder={t.SELECT}
                  required
                  disabled={fieldsAreReadOnly}
                  optionsList={resultOptions}
                />
              </Col>
              <Col span={6}>
                <FormInput
                  type="select"
                  label={t.VIEW_CLAIM_DISCREPANCY}
                  name="discrepancy"
                  placeholder={t.SELECT}
                  required
                  disabled={fieldsAreReadOnly}
                  optionsList={[
                    { label: t.NO, value: 'no' },
                    { label: t.YES, value: 'yes' },
                  ]}
                  noSelectOption
                />
              </Col>
              {/* hidden based on Bug:21414 */}
              <Col span={6}>
                <FormInput label={t.VIEW_CLAIM_CREDITOR_EMAIL_TEMPLATE} name="creditorEmailTemplate">
                  <LookAheadField
                    placeholder={t.SELECT}
                    disabled={fieldsAreReadOnly}
                    charsToWaitBeforeSearch={0}
                    showCaretDownIcon
                    apiSearch={(searchText) => {
                      return API.emailTemplates(
                        ...argumentifyEmailTemplatesCriteria({ filterText: searchText, maxResultCount: 100 })
                      );
                    }}
                    apiSetOptionsData={(response, setOptionsData) => {
                      setOptionsData(
                        response.items.map((template: any, index: number) => {
                          const adjustedName = template.name.toLowerCase().replaceAll(' ', '-');
                          return {
                            key: `option_${index}_${adjustedName}`,
                            value: template.id,
                            label: template.name,
                          };
                        })
                      );
                    }}
                    onSelect={(_, option) => {
                      setEmailTemplateSelection(option);
                    }}
                    onSearch={(searchText) => {
                      if (searchText.trim().length === 0) {
                        setEmailTemplateSelection(undefined);
                      }
                    }}
                  />
                </FormInput>
              </Col>
            </Row>
            {!claimIsSecured ? null : uploadFolderID && uploadPrefixID ? (
              <>
                {uploadErrors && <ErrorsContainer errors={uploadErrors} noTitle />}
                <OnDropUploader
                  fileId={applicationFileId as string}
                  onDrop={(documentId) => updateChecklistFiles(documentId, 'add')}
                  onDelete={(documentId) => updateChecklistFiles(documentId, 'delete')}
                  documentsIds={!uploadedFileIDs?.length ? undefined : uploadedFileIDs}
                  disabled={fieldsAreReadOnly}
                  documentFolderId={uploadFolderID}
                  documentPrefixId={uploadPrefixID}
                />
              </>
            ) : (
              <Loading text={t.LOADING_FILE_UPLOADS} style={{ paddingBottom: '20px' }} />
            )}
            {threeColLayoutIsLoading ? (
              <Loading noText />
            ) : !threeColLayoutIsLoading && claimResponseFailed ? (
              <div style={{ textAlign: 'center' }}>{t.DATA_COULD_NOT_BE_RETRIEVED}</div>
            ) : (
              <ThreeColumnLowerLayout
                leftColumnContent={
                  <>
                    <DataItem
                      layout="vertical"
                      label={t.NEW_CLAIM_CLAIM_STATUS}
                      value={leftColumnStaticDataValues?.claimStatus}
                    />
                    <DataItem
                      layout="vertical"
                      label={t.VIEW_CLAIM_SOA}
                      value={{ value: leftColumnStaticDataValues?.soaAmount, format: 'currency' }}
                    />
                    <Divider className={styles.divider} />
                    <FormInput
                      name="claimAmountFiled"
                      required
                      disabled={fieldsAreReadOnly}
                      label={
                        <LabelWithExtraValue
                          value={t.VIEW_CLAIM_CLAIM_AMOUNT_FILED}
                          extraDollarValue={leftColumnStaticDataValues?.claimExternalAmountFiled}
                        />
                      }
                      onChange={(e: any) => {
                        let onlyDecimalValue = getFirstCharacters(getStringDecimal(e.target.value), 25);

                        const decimalFormattedValue = Number(onlyDecimalValue).toFixed(2);
                        const valueAsNumber = Number(decimalFormattedValue);

                        setClaimAmountFiledValue(valueAsNumber);

                        form.setFieldsValue({ claimAmountFiled: formatToCurrency(onlyDecimalValue) });
                      }}
                      suffix={
                        claimAmountDifference == null ? undefined : (
                          <InfoBubble.Directional
                            direction={claimAmountDifference >= 0 ? 'up' : 'down'}
                            value={`${claimAmountDifference}%`}
                          />
                        )
                      }
                    />
                    <FormInput
                      type="select"
                      name="claimVoting"
                      required
                      disabled={fieldsAreReadOnly}
                      label={
                        <LabelWithExtraValue
                          value={t.VIEW_CLAIM_CLAIM_VOTING}
                          extraValue={leftColumnStaticDataValues?.claimExternalVoting}
                        />
                      }
                      optionsList={claimVotingOptions.map((option) => ({ label: option.label, value: option.value }))}
                      onChange={(value: string) => {
                        setClaimVotingValue(value);
                      }}
                      noSelectOption
                      placeholder={t.SELECT}
                    />
                    <FormInput
                      key={`mtgReq_${claimVotingValue}`}
                      type="select"
                      name="meetingRequested"
                      required={claimVotingValue !== FOR}
                      label={
                        <LabelWithExtraValue
                          required={claimVotingValue !== FOR}
                          value={t.VIEW_CLAIM_MEETING_REQUESTED}
                          extraValue={leftColumnStaticDataValues?.externalMeetingRequested}
                        />
                      }
                      optionsList={[
                        { label: t.YES, value: 'yes' },
                        { label: t.NO, value: 'no' },
                        { label: t.NA, value: NA },
                      ]}
                      disabled={(claimVotingValue != null && claimVotingValue === FOR) || fieldsAreReadOnly}
                      noSelectOption
                      placeholder={t.SELECT}
                    />
                    <FormInput
                      type="select"
                      name="votedBy"
                      required
                      disabled={fieldsAreReadOnly}
                      label={
                        <LabelWithExtraValue
                          value={t.VIEW_CLAIM_VOTED_BY}
                          extraValue={leftColumnStaticDataValues?.externalVotedBy}
                        />
                      }
                      optionsList={votedByOptions.map((option) => ({ label: option.label, value: option.value }))}
                      noSelectOption
                      placeholder={t.SELECT}
                    />
                    {claimIsSecured && (
                      <>
                        <FormInput
                          type="select"
                          required
                          disabled={fieldsAreReadOnly}
                          name="claimAmountMatches"
                          label={t.VIEW_CLAIM_CLAIM_AMOUNT_MATCHES}
                          optionsList={[
                            { label: t.YES, value: 'yes' },
                            { label: t.NO, value: 'no' },
                          ]}
                        />
                        <FormInput
                          type="select"
                          required
                          disabled={fieldsAreReadOnly}
                          name="securityAgreement"
                          label={t.VIEW_CLAIM_SECURITY_AGREEMENT}
                          optionsList={[
                            { label: t.VIEW_CLAIM_SECURITY_AGREEMENT__AVAILABLE, value: AVAILABLE },
                            { label: t.VIEW_CLAIM_SECURITY_AGREEMENT__NOT_AVAILABLE, value: NOT_AVAILABLE },
                          ]}
                          noSelectOption
                          placeholder={t.SELECT}
                        />
                        <FormInput
                          type="select"
                          required
                          disabled={fieldsAreReadOnly}
                          name="securityRegistration"
                          label={t.VIEW_CLAIM_SECURITY_REGISTRATION}
                          optionsList={[
                            { label: t.VIEW_CLAIM_SECURITY_AGREEMENT__AVAILABLE, value: AVAILABLE },
                            { label: t.VIEW_CLAIM_SECURITY_AGREEMENT__NOT_AVAILABLE, value: NOT_AVAILABLE },
                          ]}
                        />
                        <FormInput
                          type="select"
                          required
                          disabled={fieldsAreReadOnly}
                          name="validateRegistrationDescription"
                          label={t.VIEW_CLAIM_VALIDATE_REG_DESCRIPTION}
                          optionsList={[
                            { label: t.VIEW_CLAIM_VALIDATE_REG_DESCRIPTION__MATCHES, value: MATCHES },
                            { label: t.VIEW_CLAIM_VALIDATE_REG_DESCRIPTION__DOES_NOT_MATCH, value: DOES_NOT_MATCH },
                          ]}
                        />
                        <FormInput
                          type="datepicker"
                          required
                          disabled={fieldsAreReadOnly}
                          label={t.VIEW_CLAIM_SECURITY_REGISTRATION_DATE}
                          key={`securityRegDate-${claimIsSecured}`}
                          form={form}
                          name="securityRegistrationDate"
                          style={{ display: 'flex' }}
                        />
                        <FormInput
                          type="select"
                          required
                          disabled={fieldsAreReadOnly}
                          name="isThereAnEquity"
                          label={t.VIEW_CLAIM_IS_THERE_AN_EQUITY}
                          optionsList={[
                            { label: t.YES, value: 'yes' },
                            { label: t.NO, value: 'no' },
                          ]}
                        />
                      </>
                    )}
                    <FormInput
                      type="select"
                      name="is170ReportRequested"
                      required
                      disabled={fieldsAreReadOnly}
                      label={t.VIEW_CLAIM_170_REPORT_REQUESTED}
                      optionsList={[
                        { label: t.YES, value: 'yes' },
                        { label: t.NO, value: 'no' },
                      ]}
                    />
                  </>
                }
                filesList={documentIds}
                rightColumnContent={<EmailContentDisplay title={t.CLAIMS_CREDITOR_EMAIL} {...emailInfo} />}
              />
            )}
          </AppForm>
        </div>
      </Layout>
    </ConfigProvider>
  );
};

export default ViewClaimPage;
