import { ReactNode, useState, useEffect, useMemo, useCallback, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { ClientService } from '../../../../shared/api/ClientService';
import API from '../../../../utils/api';
import moment from 'moment';
import { DATE_FORMAT2 } from '../../../../constants/common';
import Icon from '../../../../components/Icon/Icon';
import DataItem from '../../../../components/DataItem/DataItem';
import { DocumentPreviewTypeEnum } from '../../../../components/PreviewFile';
import PreviewFile from '../../../../components/PreviewFile';
import ErrorsContainer, { IErrorsMsgAndType } from '../../../../components/ErrorsContainer/ErrorsContainer';
import useErrorHandling from '../../../../hooks/useErrorHandling';
import useLocale from '../../../../hooks/useLocale';
import useIsMounted from '../../../../hooks/useIsMounted';

import styles from './PreviewFileArray.module.scss';

interface ICurrentFile {
  fileIndex: number;
  file: ClientService.IDocumentDto;
}

interface PreviewFileArrayProps {
  filesList?: string[];
  extraActionButtons?: ReactNode | (() => ReactNode);
  webviewerHeight?: string;
  useAsPage?: boolean;
  className?: string;
  style?: React.CSSProperties;
}

const HEADER_PADDING_FOR_USEASPAGE = 30;

/** Preview imported files. */
const PreviewFileArray = ({
  filesList,
  extraActionButtons,
  webviewerHeight,
  useAsPage,
  className,
  style,
}: PreviewFileArrayProps) => {
  const isMounted = useIsMounted();
  const [componentAsPage, setComponentAsPage] = useState<boolean | undefined>();

  const location = useLocation();
  const { t } = useLocale();
  const fileIdArray = location.state ? (location.state as any).fileIdArray : undefined;

  const { processResponseForErrors } = useErrorHandling();
  const [fileIds, setFileIds] = useState<string[] | undefined>();
  const [fileCache, setFileCache] = useState<any | undefined>();
  const [currentFile, setCurrentFile] = useState<ICurrentFile | undefined>();

  const [errors, setErrors] = useState<IErrorsMsgAndType[] | undefined>();

  const numberOfFiles = useMemo(() => (fileIds != null ? fileIds.length : 0), [fileIds]);

  const [viewerDisplayHeight, setViewerDisplayHeight] = useState<string | undefined>(webviewerHeight);

  const filePreviewHeaderRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (componentAsPage != null) return;

    if (useAsPage) {
      setComponentAsPage(true);
    } else {
      setComponentAsPage(false);
    }
  }, [componentAsPage, useAsPage]);

  useEffect(() => {
    if (filePreviewHeaderRef.current != null && useAsPage) {
      const headerHeight = filePreviewHeaderRef.current.getBoundingClientRect().height + HEADER_PADDING_FOR_USEASPAGE;
      const calculatedHeight = `calc(100vh - ${headerHeight}px)`;
      setViewerDisplayHeight(calculatedHeight);
    }
  }, [filePreviewHeaderRef, useAsPage]);

  const loadFileAsCurrentFile = useCallback(
    (targetIndex: number) => {
      const targetFileId = fileIds ? fileIds[targetIndex] : undefined;
      if (targetFileId == null) {
        console.error('The target File ID is not available for this record');
        return;
      }
      if (fileCache != null && fileCache[targetFileId] != null) {
        console.log('The file is already in the cache');
        setCurrentFile(fileCache[targetIndex]);
        return;
      }
      console.log('fetching the file');

      API.documentsGET2(targetFileId)
        .then((response) => {
          const responseErrors = processResponseForErrors(response);
          setErrors(responseErrors.messages);
          if (responseErrors.hasErrors) return;

          const fileObject = {
            fileIndex: targetIndex,
            file: response,
          };
          if (isMounted.current) {
            setCurrentFile(fileObject);
            setFileCache({
              [targetFileId]: fileObject,
            });
          }
          console.log('document response:', response);
        })
        .catch((error) => {
          const requestErrors = processResponseForErrors(error);
          setErrors(requestErrors.messages);
        });
    },
    [fileCache, fileIds, isMounted, processResponseForErrors]
  );

  const handleFileNavigation = (direction: 'previous' | 'next') => {
    const getTargetFileIndex = {
      next: (currentFileIndex: number, numberOfFiles: number) => {
        if (currentFileIndex === numberOfFiles - 1) {
          return 0;
        }
        return currentFileIndex + 1;
      },
      previous: (currentFileIndex: number, numberOfFiles: number) => {
        if (currentFileIndex === 0) {
          return numberOfFiles - 1;
        }
        return currentFileIndex - 1;
      },
    };
    const currentFileIndex = currentFile?.fileIndex != null ? currentFile.fileIndex : 0;
    const targetFileIndex = getTargetFileIndex[direction](currentFileIndex, numberOfFiles);
    loadFileAsCurrentFile(targetFileIndex);
  };

  const loadInitialFile = useCallback(() => {
    if (!currentFile && fileIds) {
      console.log('load initial file');
      loadFileAsCurrentFile(0);
    }
  }, [currentFile, fileIds, loadFileAsCurrentFile]);

  useEffect(() => {
    loadInitialFile();
  }, [loadInitialFile]);

  useEffect(() => {
    if (filesList && filesList.length > 0) {
      setFileIds(filesList);
    } else if (fileIdArray && fileIdArray.length > 0) {
      setFileIds(fileIdArray);
    }
  }, [filesList, fileIdArray]);

  // useEffect(() => {
  //   // console.log('currentFile --> ', currentFile);
  //   // console.log('viewerDisplayHeight:', viewerDisplayHeight)
  // });

  const currentFileName = useMemo(() => (currentFile?.file.name ? currentFile?.file.name : '-'), [currentFile]);

  const currentFileReceivedDate = useMemo(() => {
    return currentFile?.file.uploadDate ? moment(currentFile?.file.uploadDate).format(DATE_FORMAT2) : '-';
  }, [currentFile]);

  const renderExtraButtons = () => {
    if (typeof extraActionButtons === 'function') {
      return extraActionButtons();
    }
    return extraActionButtons;
  };

  return (
    <div className={`${styles.PreviewFileArray} ${className ? className : ''}`} style={style}>
      {errors && <ErrorsContainer errors={errors} />}
      <div
        ref={filePreviewHeaderRef}
        className={styles.file_preview_header}
        style={
          componentAsPage
            ? {
                padding: `${HEADER_PADDING_FOR_USEASPAGE / 2}px`,
              }
            : undefined
        }
      >
        <DataItem
          noCol
          horizontalSplit="auto"
          label={`${t.FILEARRAY_PREVIEW_FILE} ${
            fileIds && numberOfFiles > 0 && currentFile ? `(${currentFile?.fileIndex + 1}/${numberOfFiles})` : ''
          }:`}
          value={currentFileName}
        />
        {/* {fileIds && numberOfFiles > 0 && currentFile ? (
            <span className={styles.file_number}>{`(${currentFile?.fileIndex+1}/${numberOfFiles})`}</span>
          ) : null
        } */}
        <DataItem
          noCol
          horizontalSplit="auto"
          label={`${t.FILEARRAY_PREVIEW_RECEIVED}:`}
          value={currentFileReceivedDate}
        />
        <div className={styles.file_preview_actions}>
          <div className={styles.extra_actions}>{extraActionButtons && renderExtraButtons()}</div>
          <div className={styles.action}>
            {fileIds && numberOfFiles > 1 ? (
              <Icon
                iconName="ChevronLeft"
                color="red"
                bold
                fontSize="17px"
                onClick={() => handleFileNavigation('previous')}
                tooltip={{ placement: 'top', title: 'Previous file' }}
              />
            ) : null}
          </div>
          <div className={styles.action}>
            {fileIds && numberOfFiles > 1 ? (
              <Icon
                iconName="ChevronRight"
                color="red"
                bold
                fontSize="17px"
                onClick={() => handleFileNavigation('next')}
                tooltip={{ placement: 'top', title: 'Next file' }}
              />
            ) : null}
          </div>
        </div>
      </div>
      <div className={styles.preview_file_container}>
        {isMounted.current && (
          <PreviewFile
            file={currentFile?.file ? currentFile.file : undefined}
            documentPreviewType={DocumentPreviewTypeEnum.document}
            onUpdate={() => {
              console.log('updated document');
            }}
            webviewerHeight={viewerDisplayHeight}
          />
        )}
      </div>
    </div>
  );
};

export default PreviewFileArray;
