import { useCallback, useState, useRef } from 'react';
import { Avatar, Comment, Input } from 'antd';
import { Icon } from '@fluentui/react/lib/Icon';

import useLocale from '../../../../hooks/useLocale';
import genericMessage from '../../../../utils/genericMessage';
import { AuthorizeService } from '../../../../components/Auth/AuthorizeService';
import { getUserAvatarName, getUserFullName } from '../../utils';
import { INoteDto, NoteCreateDto } from '../../types';
import { NOTE_ADDED_EVENT, NOTE_CHANGE_EVENT } from '../../../../constants/eventBus';
import { eventBus } from '../../../../utils/eventBus';
import API from '../../../../utils/api';

import './NoteEditor.scss';

const { TextArea } = Input;

interface IProps {
  item?: INoteDto;
  documentId?: string;
  documentVerificationId?: string;
  isAvatarVisible?: boolean;
  applicationFileId?: string;
  onFinishEditing?: () => void;
}

const NoteEditor = ({
  item,
  documentId,
  documentVerificationId,
  isAvatarVisible = true,
  applicationFileId,
  onFinishEditing,
}: IProps): JSX.Element => {
  const { t } = useLocale();
  const editorRef = useRef<HTMLDivElement>(null);
  const user = AuthorizeService.getCurrentUserInfo();

  const [content, setContent] = useState<string | undefined>(item?.content);
  const [isPinned, setPinned] = useState<boolean | undefined>(item?.isPinned);

  const handleContentChange = useCallback((e: any) => {
    setContent(e.target.value);
  }, []);

  const handlePinChange = useCallback(
    (e: any) => {
      setPinned(!isPinned);
    },
    [isPinned]
  );

  const requestNoteCreate = useCallback(async () => {
    await API.notesPOST({
      fileId: applicationFileId,
      content,
      isPinned: Boolean(isPinned),
      documentId,
      documentVerificationId,
    } as NoteCreateDto);

    genericMessage.success(t.NOTE_CREATE_SUCCESS);
    setContent(undefined);
    setPinned(undefined);
    eventBus.dispatch(NOTE_ADDED_EVENT);
    if (onFinishEditing) onFinishEditing();
  }, [
    applicationFileId,
    content,
    isPinned,
    documentId,
    documentVerificationId,
    t.NOTE_CREATE_SUCCESS,
    onFinishEditing,
  ]);

  const requestNoteUpdate = useCallback(
    async (noteId: string) => {
      await API.notesPUT(noteId, {
        fileId: applicationFileId,
        content,
        isPinned: Boolean(isPinned),
        documentId,
        documentVerificationId,
      } as NoteCreateDto);

      genericMessage.success(t.NOTE_UPDATE_SUCCESS);
      setContent(undefined);
      eventBus.dispatch(NOTE_CHANGE_EVENT);
      if (onFinishEditing) onFinishEditing();
    },
    [applicationFileId, content, documentId, documentVerificationId, isPinned, onFinishEditing, t.NOTE_UPDATE_SUCCESS]
  );

  const handleSubmit = useCallback(() => {
    if (!applicationFileId) {
      return;
    }

    if (!content || !content.trim()) {
      genericMessage.error({}, t.NOTE_TEXT_REQUIRED_MESSAGE);
      return;
    }

    if (item?.id) {
      requestNoteUpdate(item?.id);
    } else {
      requestNoteCreate();
    }
  }, [applicationFileId, content, item?.id, t.NOTE_TEXT_REQUIRED_MESSAGE, requestNoteUpdate, requestNoteCreate]);

  return (
    <div ref={editorRef} key={String(item?.id)}>
      <Comment
        avatar={
          isAvatarVisible ? (
            <Avatar alt={user?.profile.name} size={32} className="NoteEditor__avatar">
              {getUserAvatarName(getUserFullName(user))}
            </Avatar>
          ) : null
        }
        content={
          <>
            <TextArea
              rows={4}
              onChange={handleContentChange}
              value={content}
              className="NoteEditor__text-area"
              placeholder={t.ADD_A_NOTE}
              maxLength={5000}
            />
            <div className="NoteEditor__text-area-icons-container">
              <Icon iconName={isPinned ? 'PinnedSolid' : 'Pinned'} onClick={handlePinChange} />
              <Icon iconName="Save" onClick={handleSubmit} />
            </div>
          </>
        }
      />
    </div>
  );
};

export default NoteEditor;
