import { Col, Row, Form } from 'antd';

import CashInput from './CashInput';

interface IProps {
  disabled?: boolean;
}
const CashInputBlock = ({ disabled }: IProps) => {
  return (
    <Row style={{ padding: 10 }} gutter={10}>
      <Col xl={6} xs={12}>
        <Form.Item label="Cents" name="cashPaymentDenominationCents">
          <CashInput disabled={disabled} />
        </Form.Item>
      </Col>
      <Col xl={6} xs={12}>
        <Form.Item label="$1" name="cashPaymentDenominationOnes">
          <CashInput disabled={disabled} />
        </Form.Item>
      </Col>
      <Col xl={6} xs={12}>
        <Form.Item label="$2" name="cashPaymentDenominationTwos">
          <CashInput disabled={disabled} />
        </Form.Item>
      </Col>
      <Col xl={6} xs={12}>
        <Form.Item label="$5" name="cashPaymentDenominationFives">
          <CashInput disabled={disabled} />
        </Form.Item>
      </Col>
      <Col xl={6} xs={12}>
        <Form.Item label="$10" name="cashPaymentDenominationTens">
          <CashInput disabled={disabled} />
        </Form.Item>
      </Col>
      <Col xl={6} xs={12}>
        <Form.Item label="$20" name="cashPaymentDenominationTwenties">
          <CashInput disabled={disabled} />
        </Form.Item>
      </Col>
      <Col xl={6} xs={12}>
        <Form.Item label="$50" name="cashPaymentDenominationFifties">
          <CashInput disabled={disabled} />
        </Form.Item>
      </Col>
      <Col xl={6} xs={12}>
        <Form.Item label="$100" name="cashPaymentDenominationHundreds">
          <CashInput disabled={disabled} />
        </Form.Item>
      </Col>
    </Row>
  );
};

export default CashInputBlock;
