import { useState, useEffect } from 'react';
import { Form, Row, Col, FormInstance } from 'antd';
import { ClientService } from '../../../../shared/api/ClientService';
import useLocale from '../../../../hooks/useLocale';
import API from '../../../../utils/api';
import { Label } from '@fluentui/react';
import IncomeExpenseExpenseRow from './IncomeExpenseExpenseRow';

import './ApplicationFormTab.scss';

interface IProps {
  form: FormInstance<IForm>;
  tabIndex: number;
  isMarried: boolean;
  expenseType: ClientService.LookupDto;
  isLocked?: boolean;
  onChange?: (value: any) => void;
}

interface IForm {
  tabs: ITab[];
}

interface ITab {
  expenseType: ClientService.LookupDto;
  expenseDetails: ClientService.AppFormIncomeExpenseExpenseDetailDto[];
}

function IncomeExpenseExpenseTab({ form, tabIndex, isMarried, expenseType, isLocked, onChange }: IProps): JSX.Element {
  const { t, getLocalizedDtoName } = useLocale();

  const [hasOtherAmounts, setHasOtherAmounts] = useState(false);

  const [descriptions, setDescriptions] = useState<ClientService.LookupDto[]>([]);
  const [descriptionsLoading, setDescriptionsLoading] = useState<boolean>(false);

  useEffect(() => {
    setDescriptionsLoading(true);
    API.listExpenseDescriptions(expenseType?.id).then((response) => {
      setDescriptions(response);
      setDescriptionsLoading(false);

      const expenseDetails = (form.getFieldValue(['tabs', tabIndex]) as ITab)?.expenseDetails?.filter((item) => {
        const description = response?.find((d) => d.id === item.expenseDescriptionId);
        const isOther =
          description?.enumValue === ClientService.ExpenseDescriptionEnum.Other_NonDiscretionary ||
          description?.enumValue === ClientService.ExpenseDescriptionEnum.Other_Housing ||
          description?.enumValue === ClientService.ExpenseDescriptionEnum.Other_Living ||
          description?.enumValue === ClientService.ExpenseDescriptionEnum.Other_Transportation ||
          description?.enumValue === ClientService.ExpenseDescriptionEnum.Other_Personal ||
          description?.enumValue === ClientService.ExpenseDescriptionEnum.Other_Insurance ||
          description?.enumValue === ClientService.ExpenseDescriptionEnum.Other_Payments ||
          description?.enumValue === ClientService.ExpenseDescriptionEnum.Other_Medical;

        return !(isOther && !item?.applicantAmount && !item?.spouseAmount && !item?.otherHouseholdMemberAmount);
      });

      const tabs = form.getFieldValue(['tabs']);
      tabs[tabIndex].expenseDetails = expenseDetails;

      form.setFieldsValue({ tabs });
    });

    setHasOtherAmounts(expenseType?.enumValue === ClientService.ExpenseTypeEnum.NonDiscretionary);
  }, [expenseType, form, tabIndex]);

  return (
    <>
      <Row gutter={20} align="middle">
        <Col flex="25 1 100px">
          <Label className="ApplicationFormTab__label">{t.DESCRIPTION}</Label>
        </Col>
        <Col flex="10 1 100px">
          <Label className="ApplicationFormTab__label">{t.APPLICANT_AMOUNT}</Label>
        </Col>
        {Boolean(hasOtherAmounts && isMarried) && (
          <Col flex="10 1 100px">
            <Label className="ApplicationFormTab__label">{t.SPOUSE_AMOUNT}</Label>
          </Col>
        )}
        {Boolean(hasOtherAmounts) && (
          <Col flex="10 1 100px">
            <Label className="ApplicationFormTab__label">{t.OTHER_HOUSEHOLD_MEMBERS}</Label>
          </Col>
        )}
        <Col flex="0 1 40px"></Col>
      </Row>

      <Form.List name={['tabs', tabIndex, 'expenseDetails']}>
        {(expenseDetails, { add, remove }) => (
          <>
            {expenseDetails?.map(({ name }, index) => (
              <IncomeExpenseExpenseRow
                key={`expenseDetails-${index}`}
                form={form}
                tabIndex={tabIndex}
                isMarried={isMarried}
                hasOtherAmounts={hasOtherAmounts}
                descriptions={descriptions}
                rowName={name}
                remove={remove}
                onChange={onChange || undefined}
                isLocked={isLocked}
              />
            ))}
            <Row gutter={20}>
              <Col span={8}>
                <Form.Item
                  noStyle
                  shouldUpdate={(previousValue, currentValue) =>
                    previousValue?.tabs?.[tabIndex]?.expenseDetails !== currentValue?.tabs?.[tabIndex]?.expenseDetails
                  }
                >
                  {({ getFieldValue }) => {
                    const expenseDetails = getFieldValue([
                      'tabs',
                      tabIndex,
                      'expenseDetails',
                    ]) as ClientService.AppFormIncomeExpenseExpenseDetailDto[];
                    const isAnyRemaining =
                      descriptions?.filter(
                        (item) => !Boolean(expenseDetails?.find((i) => item?.id === i?.expenseDescriptionId))
                      )?.length || 0;
                    const isCurrentNotFilled = expenseDetails?.find((i) => !i.expenseDescriptionId);
                    const isButtonVisible =
                      ((!isCurrentNotFilled && isAnyRemaining > 0) || (isCurrentNotFilled && isAnyRemaining - 1 > 0)) &&
                      !isLocked;

                    return (
                      isButtonVisible && (
                        <Form.Item>
                          <div
                            onClick={() => {
                              onChange && onChange({});

                              if (!isCurrentNotFilled) {
                                add({
                                  expenseDescriptionId: undefined,
                                  specifiedDescription: undefined,
                                  applicantAmount: 0,
                                  spouseAmount: 0,
                                  otherHouseholdMemberAmount: 0,
                                });
                              }
                            }}
                            className="ApplicationFormTab__link-left"
                          >
                            {t.ADD} {getLocalizedDtoName(expenseType)} {t.EXPENSE}
                          </div>
                        </Form.Item>
                      )
                    );
                  }}
                </Form.Item>
              </Col>
            </Row>
          </>
        )}
      </Form.List>
    </>
  );
}

export default IncomeExpenseExpenseTab;
