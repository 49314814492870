import React from 'react';
import authService from '../Auth/AuthorizeService';
import { ILocale } from '../../types/ILocale';

type Props = {
  downloadUrl: string;
};

class DownloadFile extends React.Component<Props> {
  protected transformOptions = async (options: RequestInit): Promise<RequestInit> => {
    let token = await authService.getAccessToken();
    let locale = localStorage.getItem('locale');
    if (locale) locale = JSON.parse(locale);

    options.headers = {
      ...options.headers,
      Authorization: `Bearer ${token}`,
      'accept-language': locale === ILocale.fr ? ILocale.fr : ILocale.en,
    };

    return Promise.resolve(options);
  };

  downloadFile = (url: string): any => {
    let options_: RequestInit = {
      method: 'GET',
      headers: {},
    };

    return this.transformOptions(options_)
      .then((transformedOptions_) => {
        return fetch(url, transformedOptions_).then(async (response) => {
          const filename = response.headers.get('content-disposition')?.split(';')[1].split('=')[1];

          console.log(filename);

          const blob = await response.blob();

          return { filename, blob };
        });
      })

      .then((data) => {
        let url = window.URL.createObjectURL(data.blob);
        let a = document.createElement('a');
        a.href = url;
        a.download = data.filename ?? 'file.pdf';
        a.click();
      });
  };

  render() {
    return (
      <a
        href="#"
        onClick={() => {
          this.downloadFile(this.props.downloadUrl);
        }}
      >
        {this.props.children}
      </a>
    );
  }
}

export default DownloadFile;
