import moment from 'moment';
import { IGenericObject } from '../types/IGenericObject';
import { DATE_FORMAT, DATE_AND_TIME_FORMAT3, TIME_FORMAT } from '../constants/common';
import { ClientService } from '../shared/api/ClientService';

export const paginationShowTotal = (total: number) => `Total ${total} ${total > 1 || total === 0 ? 'items' : 'item'}`;

export const isArrayWithValues = (varToCheck: unknown): boolean => {
  if (varToCheck && Array.isArray(varToCheck) && varToCheck.length > 0) {
    return true;
  }
  return false;
};

export const getEnumTextValuesAsArray = (sourceEnum: any): string[] => {
  return Object.keys(sourceEnum).filter((key: any) => {
    return !isNaN(Number(sourceEnum[key]));
  });
};

export const checkFlatObjectForValues = (object: IGenericObject, keys?: string[], excludeKeys = false): boolean => {
  const objectKeys = Object.keys(object);
  let keysToCheck: string[] = [];

  let thereArePassedKeys = false;
  if (!keys || (keys && keys.length === 0)) {
    keysToCheck = objectKeys;
    console.log('will check object keys');
  } else if (keys && keys.length > 0 && excludeKeys) {
    keysToCheck = objectKeys;
    thereArePassedKeys = true;
    console.log('will check object keys');
  } else if (keys && keys.length > 0) {
    thereArePassedKeys = true;
    keysToCheck = keys;
  }
  console.log('keysToCheck:', keysToCheck);
  console.log('thereArePassedKeys:', thereArePassedKeys);

  let allValuesSet = true;
  keysToCheck.forEach((key) => {
    console.log('key:', key);
    if ((excludeKeys && thereArePassedKeys && keys?.indexOf(key) === -1) || !excludeKeys) {
      console.log('key evaluated:', key);
      if (object[key] == null || object[key] === '' || object[key] === 'select') allValuesSet = false;
    }
  });
  console.log('allValuesSet:', allValuesSet);
  return allValuesSet;
};

export const getUndefinedOrSelf = (value: any) => {
  return value == null || value === 'select' ? undefined : value;
};

export const getUndefinedOrBoolean = (value: any) => {
  return value == null ? undefined : typeof value === 'string' && value.toLowerCase() === 'yes' ? true : false;
};

export const getUndefinedOrYesNo = (value: boolean | null | undefined) => {
  return value == null ? undefined : value === true ? 'yes' : 'no';
};

export const getFormValueOrFetchedValueOrUndefined = (
  formValue: any,
  fetchedValue: any,
  resolveToTrue = 'yes',
  resolveToFalse = 'no'
) => {
  let value = getUndefinedOrSelf(formValue);
  if (value === resolveToTrue || value === resolveToFalse) {
    value = value === resolveToTrue ? true : false;
  }
  if (value != null) return value;

  return getUndefinedOrSelf(fetchedValue);
};

export const splitOnCapital = (input: string) => {
  let result = input[0];
  for (let i = 1; i < input.length; i++) {
    if (input?.[i]?.toUpperCase() === input[i]) {
      result += ` ${input[i]}`;
    } else {
      result += input[i];
    }
  }
  return result;
};

export const getFullName = (firstName: string, middleName: string | null | undefined, lastName: string): string => {
  let name = `${firstName} `;
  if (middleName) {
    name += `${middleName} `;
  }
  name += lastName;
  return name;
};

export const hasOnlyNumbers = (value: string) => {
  return /^\d+$/.test(value);
};

export const getOnlyNumbers = (value: string) => {
  return value.replace(/\D/g, '');
};

export const getStringDecimal = (value: string, decimalPlacesAllowed?: number) => {
  value = value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');

  const decimalPlaces = (value.toString().split('.')[1] || []).length;
  const decimalPlacesAllowedToUse = decimalPlacesAllowed || 2;

  return decimalPlaces > decimalPlacesAllowedToUse
    ? value.substring(0, value.length - (decimalPlaces - decimalPlacesAllowedToUse))
    : value;
};

export const formatToCurrency = (value: string) => {
  return value.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

export const GetStringCurrency = (value: string, decimalPlacesAllowed?: number) => {
  return formatToCurrency(getStringDecimal(value, decimalPlacesAllowed));
};

export const isString12CharactersOrLess = (value: string) => {
  return value.length <= 12;
};

export const isNullOrWhitespace = (input: string | undefined) => {
  if (typeof input === 'undefined' || input == null) return true;
  return input.replace(/\s/g, '').length < 1;
};

export const getFirstCharacters = (input: string, numberOfCharacters: number) => {
  if (input.length > numberOfCharacters) {
    return input.substring(0, numberOfCharacters);
  }
  return input;
};

export const isMomentWithSameDate = (moment1: any | undefined, moment2: any | undefined) => {
  if (moment1 && moment2) {
    return moment1.isSame(moment2, 'day') && moment1.isSame(moment2, 'month') && moment1.isSame(moment2, 'year');
  }

  return moment1 === moment2;
};

export const convertInputDateToServerDateOnly = (date?: moment.Moment) =>
  date ? moment(moment(date).format(DATE_FORMAT)).utc(true) : undefined;

export const convertInputDateToServerTimeString = (date?: moment.Moment) => {
  return date ? moment(moment(date).format(DATE_AND_TIME_FORMAT3)).utc(true).format(TIME_FORMAT) : undefined;
};

export const convertServerDateOnlyToInputDate = (date?: moment.Moment) => (date ? moment.utc(date) : undefined);

export const isEmptyGuid = (guid: string | undefined) => {
  if (!guid) return true;

  return guid === '00000000-0000-0000-0000-000000000000';
};

export const getEmptyGuid = () => {
  return '00000000-0000-0000-0000-000000000000';
};

export const checkIfProvinceOpenTextField = (countryId?: string, countries?: ClientService.LookupDto[]) => {
  const countryCode = countries?.find((item) => item?.id === countryId)?.code;
  return countryCode !== 'CA' && countryCode !== 'US';
};

export const replaceLineBreaksWithBrTag = (data?: string) => (data ? data.replace(/(\r\n|\r|\n)/g, '<br/>') : '');

export const removeHTMLTagsFromString = (data?: string) => (data ? data?.replace(/<[^>]+>/g, '') : '');

type Procedure<R = void> = (...args: any[]) => R;

export const debounceWithTimeOut = <F extends Procedure>(
  func: F,
  waitMilliseconds: number
): ((...args: Parameters<F>) => void) => {
  let timeoutID: NodeJS.Timeout | undefined;
  return function (this: ThisParameterType<F>, ...args: Parameters<F>): void {
    const doLater = () => {
      timeoutID = undefined;
      func.apply(this, args);
    };
    if (timeoutID !== undefined) {
      clearTimeout(timeoutID);
    }
    timeoutID = setTimeout(doLater, waitMilliseconds);
  };
};
