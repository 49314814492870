import authService, { AuthorizeService } from '../components/Auth/AuthorizeService';
import PageTitle from '../components/PageTitle/PageTitle';
import useLocale from '../hooks/useLocale';

function Logout() {
  authService.signOut();
}

const UserInfoPage = () => {
  const userInfo = AuthorizeService.getCurrentUserInfo();
  const { t } = useLocale();

  return (
    <div>
      <div className="row align-items-center profile-header">
        <div className="col-md text-center text-md-left">
          <PageTitle title={t.USER_INFO}></PageTitle>
          <p className="lead text-muted">{`${t.USER}: ${userInfo?.profile.unique_name}`}</p>
        </div>
      </div>
      <div className="row">
        <pre className="col-12 text-light bg-dark p-4">{JSON.stringify(userInfo, null, 2)}</pre>
      </div>
      <div className="row">
        <h2>{t.ACCESS_TOKEN}</h2>
        <pre className="col-12 text-light bg-dark p-4">
          {JSON.stringify(AuthorizeService.parseJwt(userInfo?.access_token), null, 2)}
        </pre>
      </div>
      <div className="row align-items-center">
        <div className="col-md text-center text-md-left">
          <button onClick={Logout}>{t.LOG_OUT}</button>
        </div>
      </div>
    </div>
  );
};

export default UserInfoPage;
