import { useState, useCallback, useEffect } from 'react';
import { Modal, Form, Input, Select, Col, Row, Checkbox, Spin } from 'antd';
import ReactQuill from 'react-quill';
import { useQuery } from 'react-query';

import Button from '../../../components/Button/Button';
import SelectSuffixIcon from '../../../components/SelectSuffixIcon/SelectSuffixIcon';

import useLocale from '../../../hooks/useLocale';

import genericMessage from '../../../utils/genericMessage';
import { TEMPLATE_SYSTEM_FIELDS_QUERY, MESSAGE_TEMPLATE_TYPES, USE_QUERY_OPTIONS } from '../../../constants/reactQuery';
import { ClientService } from '../../../shared/api/ClientService';
import API from '../../../utils/api';

import './Templates.scss';

interface IProps {
  id?: string;
  onOk: () => void;
  onCancel: () => void;
}

const TemplatesEditor = ({ id, onOk, onCancel }: IProps) => {
  const { t } = useLocale();
  const [form] = Form.useForm<ClientService.MessageTemplateDto>();
  const { Option } = Select;
  const { TextArea } = Input;

  const [template, setTemplate] = useState<ClientService.MessageTemplateDto>();
  const [loading, setLoading] = useState<boolean>(false);

  const { data: templateSystemFields } = useQuery(
    [TEMPLATE_SYSTEM_FIELDS_QUERY],
    () => API.getMessageTemplateSystemFields(),
    USE_QUERY_OPTIONS
  );

  const { data: messageTemplateTypes } = useQuery(
    [MESSAGE_TEMPLATE_TYPES],
    () => API.listMessageTemplateTypes(),
    USE_QUERY_OPTIONS
  );

  const requestTemplate = useCallback(
    async (templateId: string) => {
      setLoading(true);
      const response = await API.getMessageTemplate(templateId).catch(() => setLoading(false));
      setLoading(false);

      if (response) {
        form.setFieldsValue(response);
        setTemplate(response);
      }
    },
    [form]
  );

  const requestCreateTemplate = useCallback(
    async (values) => {
      setLoading(true);
      const response = await API.addMessageTemplate(values).catch((error) => {
        setLoading(false);
        genericMessage.error({}, error?.message);
      });
      setLoading(false);

      if (response?.result === ClientService.Result.Successful) {
        if (onOk) onOk();
      } else {
        genericMessage.error({}, response?.messages?.[0].body);
      }
    },
    [onOk]
  );

  const requestUpdateTemplate = useCallback(
    async (values) => {
      setLoading(true);
      const response = await API.editMessageTemplate({ ...template, ...values }).catch((error) => {
        setLoading(false);
        genericMessage.error({}, error?.error?.message);
      });
      setLoading(false);

      if (response?.result === ClientService.Result.Successful) {
        if (onOk) onOk();
      } else {
        genericMessage.error({}, response?.messages?.[0].body);
      }
    },
    [onOk, template]
  );

  const onFinish = useCallback(
    (values) => {
      if (id) {
        requestUpdateTemplate(values);
      } else {
        requestCreateTemplate(values);
      }
    },
    [id, requestCreateTemplate, requestUpdateTemplate]
  );

  useEffect(() => {
    if (id) requestTemplate(id);
  }, [requestTemplate, id]);

  return (
    <Modal
      destroyOnClose
      centered
      visible
      title={id ? t.EDIT_TEMPLATE : t.ADD_TEMPLATE}
      width={900}
      footer={null}
      onCancel={onCancel}
      className="Templates__modal"
    >
      <Spin spinning={loading}>
        <Form form={form} onFinish={onFinish} initialValues={{ isActive: true, body: '' }}>
          {(_, formInstance) => {
            const templateTypeId = formInstance.getFieldValue('typeId');
            const isEmailTypeSelected =
              messageTemplateTypes?.find((item) => item.id === templateTypeId)?.name === 'Email';
            return (
              <>
                <Row>
                  <Col span={12}>
                    <Form.Item
                      name="typeId"
                      label={t.TYPE}
                      labelCol={{ span: 8 }}
                      labelAlign="left"
                      rules={[{ required: true, message: t.REQUIRED_FIELD }]}
                    >
                      <Select suffixIcon={<SelectSuffixIcon />} size="large" disabled={Boolean(id)}>
                        {messageTemplateTypes?.map((item) => (
                          <Option key={item?.id} value={item?.id}>
                            {item.name}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                </Row>

                {templateTypeId && (
                  <Row gutter={60}>
                    <Col span={24}>
                      <Form.Item
                        name="name"
                        label={t.NAME}
                        labelCol={{ span: 4 }}
                        labelAlign="left"
                        rules={[{ required: true, message: t.REQUIRED_FIELD }]}
                        required
                      >
                        <Input size="large" maxLength={100} />
                      </Form.Item>
                    </Col>
                    {isEmailTypeSelected && (
                      <Col span={24}>
                        <Form.Item
                          name="subject"
                          label={t.SUBJECT}
                          labelCol={{ span: 4 }}
                          labelAlign="left"
                          rules={[{ required: true, message: t.REQUIRED_FIELD }]}
                          required
                        >
                          <Input size="large" maxLength={500} />
                        </Form.Item>
                      </Col>
                    )}

                    <Col span={24}>
                      <Form.Item
                        name="body"
                        label={isEmailTypeSelected ? t.BODY : t.MESSAGE}
                        labelCol={{ span: 4 }}
                        labelAlign="left"
                        rules={[
                          { required: true, message: t.REQUIRED_FIELD },
                          {
                            max: 5000,
                            message: isEmailTypeSelected ? t.BODY_MAX_5000_CHAR_ERROR : t.MESSAGE_MAX_5000_CHAR_ERROR,
                          },
                        ]}
                        required
                      >
                        {isEmailTypeSelected ? <ReactQuill /> : <TextArea rows={4} />}
                      </Form.Item>
                    </Col>

                    <Col span={24}>
                      <Form.Item label={t.SYSTEM_FIELDS} labelCol={{ span: 4 }} labelAlign="left">
                        <div>{templateSystemFields?.fields}</div>
                      </Form.Item>
                    </Col>

                    <Col span={24}>
                      <Form.Item
                        name="isActive"
                        label={t.STATUS}
                        valuePropName="checked"
                        labelCol={{ span: 4 }}
                        labelAlign="left"
                      >
                        <Checkbox />
                      </Form.Item>
                    </Col>
                  </Row>
                )}

                <Row gutter={24} justify="end" className="TaskEditor__footer">
                  <Col>
                    <Button kind="cancel" onClick={onCancel}>
                      {t.CANCEL}
                    </Button>
                  </Col>
                  {templateTypeId && (
                    <Col>
                      <Button kind="primary" htmlType="submit">
                        {t.SAVE}
                      </Button>
                    </Col>
                  )}
                </Row>
              </>
            );
          }}
        </Form>
      </Spin>
    </Modal>
  );
};

export default TemplatesEditor;
