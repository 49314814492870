import { useParams } from 'react-router-dom';
import { Col, Row } from 'antd';
import { useQuery } from 'react-query';
import moment from 'moment';

import {
  APPLICATION_FILE_QUERY,
  COUNSELLING_TYPES_QUERY,
  DELIVERY_METHODS_QUERY,
  INCLUDE_INACTIVE,
  OFFICE_LOCATIONS_QUERY,
  USE_QUERY_OPTIONS,
} from '../../../../../../constants/reactQuery';
import { DATE_FORMAT2, TIME_FORMAT, MAX_PAGE_SIZE } from '../../../../../../constants/common';
import { argumentifyOfficeLocationsSearchCriteria } from '../../../../../../components/SchedulingComponent/utils';
import { getSessionStatusNameByAppointmentEnum } from '../utils';
import useLocale from '../../../../../../hooks/useLocale';

import { ClientService } from '../../../../../../shared/api/ClientService';
import API from '../../../../../../utils/api';

interface IProps {
  session: ClientService.AdminInfoCounsellingSessionDto;
  onClick: () => void;
}

const OverviewRow = ({ session, onClick }: IProps) => {
  const { applicationFileId } = useParams<{ applicationFileId: string }>();
  const { t } = useLocale();

  const { data: applicationFile } = useQuery(
    [APPLICATION_FILE_QUERY],
    () => API.applicationFile2(applicationFileId as string),
    USE_QUERY_OPTIONS
  );
  const { data: deliveryMethods } = useQuery(
    [DELIVERY_METHODS_QUERY],
    () => API.listMethodOfDeliveries(),
    USE_QUERY_OPTIONS
  );
  const { data: counsellingTypes } = useQuery(
    [COUNSELLING_TYPES_QUERY],
    () => API.listCounsellingSessionTypes(),
    USE_QUERY_OPTIONS
  );

  const { data: officeLocations } = useQuery(
    [OFFICE_LOCATIONS_QUERY, INCLUDE_INACTIVE, MAX_PAGE_SIZE],
    () =>
      API.officeLocationsGET(
        ...argumentifyOfficeLocationsSearchCriteria({ maxResultCount: MAX_PAGE_SIZE, includeInactive: true })
      ),
    USE_QUERY_OPTIONS
  );

  return (
    <Row className="CounsellingViews__session">
      <Col xl={2} xs={12}>
        <span className="CounsellingViews__session--title">
          {t.SESSION} {session?.sessionNumber}
        </span>
      </Col>
      <Col xl={3} xs={12}>
        <span className="CounsellingViews__session--title">{t.TYPE}</span>
        {counsellingTypes?.find((item) => item.enumValue === session?.ascendCounsellingTypeId)?.name || ''}
      </Col>
      <Col xl={3} xs={12}>
        <span className="CounsellingViews__session--title">{t.METHOD} </span>
        {deliveryMethods?.find((item) => item.enumValue === session?.ascendMethodOfDeliveryId)?.name || ''}
      </Col>
      <Col xl={4} xs={12}>
        <span className="CounsellingViews__session--title">{t.LOCATION}</span>
        {officeLocations?.items?.find((session) => session.id === applicationFile?.serviceLocationId)?.name}
      </Col>
      <Col xl={9} xs={24}>
        <span className="CounsellingViews__session--appointment">
          {`${moment(session?.appointment?.appointmentDate).format(`${DATE_FORMAT2} @ ${TIME_FORMAT}`)} ${t.WITH} ${
            session?.appointment?.agent?.fullName
          }`}
        </span>
      </Col>
      <Col xl={3} xs={12}>
        <span className="CounsellingViews__session--title">{t.STATUS}</span>
        <span className="CounsellingViews__session--status-value" onClick={onClick}>
          {getSessionStatusNameByAppointmentEnum(session?.appointment?.appointmentStatus?.enumValue)}
        </span>
      </Col>
    </Row>
  );
};

export default OverviewRow;
