import { Icon, IIconProps } from '@fluentui/react/lib/Icon';

import styles from '../../../styles/style.module.scss';

export const CustomIcon = ({ ...props }: IIconProps) => (
  <Icon {...props} style={{ fontSize: styles.iconSizeDefault, cursor: 'pointer' }} />
);

export const IconGray = ({ ...props }: IIconProps) => (
  <Icon {...props} style={{ color: styles.colorSecondary, fontSize: styles.iconSizeDefault, cursor: 'pointer' }} />
);

export const IconRed = ({ ...props }: IIconProps) => (
  <Icon {...props} style={{ color: styles.colorDanger, fontSize: styles.iconSizeDefault, cursor: 'pointer' }} />
);
