import { useCallback, useEffect, useState } from 'react';
import { Modal, Form, Row, Col, Input, Checkbox, Spin } from 'antd';

import Button from '../../../../../../components/Button/Button';
import DataItem from '../../../../../../components/DataItem/DataItem';

import useLocale from '../../../../../../hooks/useLocale';
import { readableBytes } from '../../../../../ApplicationOverviewPage/DocumentsContent/utils';
import { ClientService } from '../../../../../../shared/api/ClientService';
import API from '../../../../../../utils/api';

import './ReviewOppositionResponseModal.scss';

interface IProps {
  applicationFileId: string;
  onOk?: () => void;
  onCancel?: () => void;
}

function ReviewOppositionResponseModal({ applicationFileId, onOk, onCancel }: IProps): JSX.Element {
  const { t } = useLocale();
  const { TextArea } = Input;

  const [form] = Form.useForm<ClientService.OppositionLetterResponseUpdateDto>();
  const [initial, setInitial] = useState<ClientService.OppositionLetterResponseDto>();
  const [readableProcesses, setReadableProcesses] = useState<string>();
  const [readableDocumentsArray, setReadableDocumentsArray] = useState<(string | undefined)[]>();

  const [loading, setLoading] = useState<boolean>(false);

  const requestOppositionLetterResponse = useCallback(
    async (applicationFileId: string) => {
      setLoading(true);
      const response = await API.getOppositionLetterResponse(applicationFileId).catch(() => setLoading(false));
      const fileProcesses = await API.listFileProcesses().catch(() => setLoading(false));

      setLoading(false);

      if (response) {
        setInitial(response);
        setReadableProcesses(
          response?.pendingProcesses
            ?.map((item) => fileProcesses?.find((process) => process.enumValue === item)?.name)
            ?.join(', ') || ''
        );
        setReadableDocumentsArray(
          response?.documents?.map((item) => `${item?.name} (${readableBytes(item?.fileSizeInBytes)})`)
        );
        form.setFieldsValue({
          oppositionDeptorResponse: response?.oppositionDeptorResponse,
          isOppositionLetterWithdrawn: response?.isOppositionLetterWithdrawn,
          isOppositionLetterCompleted: response?.isOppositionLetterCompleted,
        } as ClientService.OppositionLetterResponseUpdateDto);
      }
    },
    [form]
  );

  const handleSubmit = useCallback(
    (isOppositionLetterCompleted?: boolean) => {
      form.validateFields().then(async (values) => {
        await API.updateOppositionLetterResponse({
          ...values,
          fileId: applicationFileId,
          isOppositionLetterCompleted,
        } as ClientService.OppositionLetterResponseUpdateDto);

        if (onOk) onOk();
      });
    },
    [form, applicationFileId, onOk]
  );

  const handleCancel = useCallback(() => {
    if (onCancel) onCancel();
  }, [onCancel]);

  useEffect(() => {
    if (applicationFileId) requestOppositionLetterResponse(applicationFileId);
  }, [applicationFileId, requestOppositionLetterResponse]);

  return (
    <Modal
      title={t.DISCHARGE_REVIEW_OPPOSITION_RESPONSE}
      destroyOnClose
      centered
      visible
      footer={null}
      closable
      onCancel={handleCancel}
      width={700}
      className="ReviewOppositionResponseModal"
    >
      <Spin spinning={loading}>
        <Form form={form} layout="vertical" name="ReviewOppositionResponseModal" onFinishFailed={(e) => console.log(e)}>
          <Row>
            <DataItem
              colSpan={24}
              label={t.DISCHARGE_PENDING_PROCESS}
              layout="vertical"
              value={{
                value: readableProcesses || '',
                size: 'small',
              }}
              style={{ paddingBottom: 24 }}
            />

            <DataItem
              colSpan={24}
              label={t.DISCHARGE_OPPOSITION_COMMENTS}
              layout="vertical"
              value={{ value: initial?.oppositionComments || '', size: 'small' }}
              style={{ paddingBottom: 24 }}
            />

            <DataItem
              colSpan={24}
              label={t.FILES}
              layout="vertical"
              value={{
                value: (
                  <>
                    {readableDocumentsArray?.map((item, index) => (
                      <div key={`document-${index}`}>{item}</div>
                    ))}
                  </>
                ),
                size: 'small',
              }}
              style={{ paddingBottom: 24 }}
            />

            <Col span={24}>
              <Form.Item
                name="oppositionDeptorResponse"
                label={t.DISCHARGE_OPPOSITION_DEBTOR_RESPONSE}
                required
                rules={[{ required: true, message: t.REQUIRED_FIELD }]}
              >
                <TextArea rows={4} disabled={initial?.isOppositionLetterCompleted} />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item name="isOppositionLetterWithdrawn" valuePropName="checked">
                <Checkbox disabled={initial?.isOppositionLetterCompleted}>{t.DISCHARGE_WITHDRAW_OPPOSITION}</Checkbox>
              </Form.Item>
            </Col>
          </Row>

          <Row align="middle" justify="end" className="ReviewOppositionResponseModal__footer" wrap={false} gutter={10}>
            <Col>
              <Form.Item noStyle>
                <Button kind="cancel" onClick={onCancel}>
                  {t.CANCEL}
                </Button>
              </Form.Item>
            </Col>
            <Col>
              <Form.Item noStyle>
                <Button onClick={() => handleSubmit(false)} disabled={initial?.isOppositionLetterCompleted}>
                  {t.SAVE}
                </Button>
              </Form.Item>
            </Col>
            <Col>
              <Form.Item noStyle>
                <Button onClick={() => handleSubmit(true)} disabled={initial?.isOppositionLetterCompleted}>
                  {t.COMPLETE}
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Spin>
    </Modal>
  );
}

export default ReviewOppositionResponseModal;
