import QuickActionsToolbar from '../../components/QuickActionsToolbar/QuickActionsToolbar';
import PageTitle from '../../components/PageTitle/PageTitle';
import './PageTitleContainer.scss';
import React from 'react';

function PageTitleContainer({
  title,
  titleIcon,
  titleRefLink,
  children,
  hasToolbar = true,
  style,
}: {
  title?: string | JSX.Element | Array<JSX.Element>;
  titleIcon?: JSX.Element | false;
  titleRefLink?: JSX.Element | false;
  children?: JSX.Element;
  hasToolbar?: boolean;
  style?: React.CSSProperties;
}): JSX.Element {
  return (
    <div className="PageTitleContainer" style={style}>
      <div>
        <PageTitle title={title || ''} icon={titleIcon} refLink={titleRefLink} />
        {children}
      </div>
      {hasToolbar && <QuickActionsToolbar />}
    </div>
  );
}

export default PageTitleContainer;
