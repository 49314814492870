import { Icon } from '@fluentui/react/lib/Icon';

import './SubTab.scss';
import styles from '../../../../../styles/style.module.scss';

export interface ISubTab {
  title: string;
  subtitle?: string;
  iconName?: string;
  iconColor?: string;
  active?: boolean | undefined;
}

function SubTab({ title, subtitle, iconName, iconColor, active }: ISubTab): JSX.Element {
  return (
    <div className={`SubTab ${active ? 'SubTab__active' : ''}`}>
      {iconName && (
        <Icon iconName={iconName} className="SubTab__icon" style={{ color: iconColor || styles.colorGray }} />
      )}
      <div className="SubTab__inner-container">
        <div className="SubTab__title">{title}</div>
        {subtitle && <div className="SubTab__subtitle">{subtitle}</div>}
      </div>
    </div>
  );
}

export default SubTab;
