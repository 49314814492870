import { Row, Col } from 'antd';

import SurplusIncomeRow from './SurplusIncomeRow';
import { ClientService } from '../../../../shared/api/ClientService';
import useLocale from '../../../../hooks/useLocale';

import styles from '../../../../styles/style.module.scss';
import '../FileAnalysis.scss';

const SurplusIncomeTable = ({
  calculations,
}: {
  calculations?: ClientService.FileAnalysisSurplusIncomeCalculationDto;
}) => {
  const { t } = useLocale();
  return (
    <div className="FileAnalysis__results--surplus-container">
      <Row className="FileAnalysis__results--row" style={{ borderBottom: 0, fontWeight: 700 }}>
        <Col offset={12} span={3}>
          {t.DEBTOR}
        </Col>
        <Col span={3}>{t.SPOUSE}</Col>
        <Col span={3}>{t.OTHER}</Col>
        <Col span={3}>{t.TOTAL}</Col>
      </Row>
      <SurplusIncomeRow
        title={t.NET_MONTHLY_INCOME}
        debtorCellValue={calculations?.monthlyNetIncomeApplicant}
        spouseCellValue={calculations?.monthlyNetIncomeSpouse}
        otherCellValue={calculations?.monthlyNetIncomeOther}
        totalCellValue={calculations?.monthlyNetIncomeTotal}
      />
      <SurplusIncomeRow
        title={t.NON_DISCRETIONARY_EXPENSES}
        debtorCellValue={calculations?.monthlyNonDiscretionaryExpensesApplicant}
        spouseCellValue={calculations?.monthlyNonDiscretionaryExpensesSpouse}
        otherCellValue={calculations?.monthlyNonDiscretionaryExpensesOther}
        totalCellValue={calculations?.monthlyNonDiscretionaryExpensesTotal}
        style={{ borderBottomColor: styles.colorPrimary }}
      />
      <SurplusIncomeRow
        title={t.TOTAL_NET_INCOME_IN_SURPLUS}
        debtorCellValue={calculations?.monthlyNetIncomeUsedInCalcApplicant}
        spouseCellValue={calculations?.monthlyNetIncomeUsedInCalcSpouse}
        otherCellValue={calculations?.monthlyNetIncomeUsedInCalcOther}
        totalCellValue={calculations?.monthlyNetIncomeUsedInCalcTotal}
        style={{ borderBottom: 0 }}
      />
      <Row className="FileAnalysis__results--row-percentage">
        <Col offset={12} span={3}>
          {calculations?.monthlyNetIncomeUsedInCalcApplicantPercentage}%
        </Col>
        <Col span={3}>{calculations?.monthlyNetIncomeUsedInCalcSpousePercentage}%</Col>
        <Col span={3}>{calculations?.monthlyNetIncomeUsedInCalcOtherPercentage}%</Col>
        <Col span={3} className="FileAnalysis__results--cell-total">
          {calculations?.monthlyNetIncomeUsedInCalcTotalPercentage}%
        </Col>
      </Row>
      <SurplusIncomeRow
        title={t.NUMBER_PERSONS_HOUSEHOLD}
        totalCellValue={calculations?.numberOfPersonsInHousehold}
        style={{ borderBottomColor: styles.colorPrimary, paddingTop: 24 }}
      />
      <SurplusIncomeRow
        title={t.TOTAL_NET_INCOME_IN_SURPLUS}
        totalCellValue={calculations?.monthlyNetIncomeUsedInSurplusIncomeCalcTotal}
        style={{ paddingTop: 48 }}
      />
      <SurplusIncomeRow
        title={t.ALLOWABLE_NET_INCOME_PER_STANDARD}
        otherCellValue={calculations?.spouseRefusedCheckText || ''}
        totalCellValue={calculations?.allowableMonthlyNetIncome}
        merged={Boolean(calculations?.spouseRefusedCheckText)}
        style={{ borderBottomColor: styles.colorPrimary }}
      />
      <SurplusIncomeRow
        title={t.TOTAL_MONTHLY_SURPLUS}
        totalCellValue={calculations?.totalMonthlySurplus}
        style={{ borderBottom: 0, fontWeight: 700 }}
      />
      <SurplusIncomeRow
        title={t.INDIVIDUAL_PORTION_INSURPLUS}
        debtorCellValue={calculations?.totalMonthlySurplusApplicant}
        spouseCellValue={calculations?.totalMonthlySurplusSpouse}
        otherCellValue={calculations?.totalMonthlySurplusOther}
        totalCellValue={calculations?.totalMonthlySurplusTotal}
        style={{ paddingTop: 48 }}
      />
      <SurplusIncomeRow
        title={t.MONTHLY_PAYMENT_REQUIRED}
        debtorCellValue={calculations?.monthlyPaymentRequiredApplicant}
        spouseCellValue={
          calculations?.monthlyPaymentRequiredSpouse && calculations?.monthlyPaymentRequiredSpouse >= 0
            ? calculations?.monthlyPaymentRequiredSpouse
            : calculations?.monthlyPaymentRequiredSpouseText
        }
        otherCellValue={calculations?.monthlyPaymentRequiredOther}
        totalCellValue={calculations?.monthlyPaymentRequiredTotal}
      />
      <SurplusIncomeRow
        title={t.NUMBER_PAYMENTS_BUNKRUPTCY}
        debtorCellValue={calculations?.noOfMonthlyPaymentsApplicant}
        spouseCellValue={
          calculations?.noOfMonthlyPaymentsSpouse && calculations?.noOfMonthlyPaymentsSpouse >= 0
            ? calculations?.noOfMonthlyPaymentsSpouse
            : calculations?.noOfMonthlyPaymentsSpouseText
        }
        style={{ borderBottomColor: styles.colorPrimary }}
      />
      <SurplusIncomeRow
        title={t.TOTAL_SURPLUS_INCOME_CONTRIBUTION}
        debtorCellValue={calculations?.surplusIncomeContributionApplicant}
        spouseCellValue={
          calculations?.surplusIncomeContributionSpouse && calculations?.surplusIncomeContributionSpouse >= 0
            ? calculations?.surplusIncomeContributionSpouse
            : calculations?.surplusIncomeContributionSpouseText
        }
        otherCellValue={calculations?.surplusIncomeContributionOther}
        totalCellValue={calculations?.surplusIncomeContributionTotal}
        style={{ borderBottom: 0 }}
      />
    </div>
  );
};

export default SurplusIncomeTable;
