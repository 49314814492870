import { useState, useEffect, useCallback } from 'react';
import { Modal, Form, Row, Col, Table } from 'antd';
import moment from 'moment';

import Button from '../../../../../../components/Button/Button';

import useLocale from '../../../../../../hooks/useLocale';

import { DATE_FORMAT2, MAX_PAGE_SIZE } from '../../../../../../constants/common';
import { DISCHARGE_CHEQUE_REQUISITION_PREFIX } from '../constants';
import { argumentifyHistorySearchParams } from '../utils';

import { ClientService } from '../../../../../../shared/api/ClientService';
import API from '../../../../../../utils/api';

import './ChequeRequisitionHistoryModal.scss';

interface IProps {
  applicationFileId?: string;
  onClose?: () => void;
}

function ChequeRequisitionHistoryModal({ applicationFileId, onClose }: IProps): JSX.Element {
  const { t } = useLocale();

  const [dataSource, setDataSource] = useState<ClientService.DocumentVerificationHistoryDto[]>();

  const requestHistory = useCallback(async (applicationFileId: string) => {
    const response = await API.history(
      ...argumentifyHistorySearchParams({
        fileId: applicationFileId,
        documentFolderEnum: ClientService.DocumentFolderEnum.Discharge,
        documentPrefix: DISCHARGE_CHEQUE_REQUISITION_PREFIX,
        verificationRoleType: ClientService.DocumentVerificationRoleTypeEnum.Approver,
        maxResultCount: MAX_PAGE_SIZE,
      })
    );

    if (response) {
      setDataSource(response);
    }
  }, []);

  useEffect(() => {
    if (applicationFileId) requestHistory(applicationFileId);
  }, [applicationFileId, requestHistory]);

  const columns = [
    {
      title: t.DOCUMENT_NAME,
      dataIndex: 'documentName',
      key: 'documentName',
    },
    {
      title: t.REQUESTED_ON,
      dataIndex: 'requestDate',
      key: 'requestDate',
      render: (date: string) => <>{moment(date).format(DATE_FORMAT2)}</>,
    },
    {
      title: t.REQUESTED_BY,
      dataIndex: 'requestorName',
      key: 'requestorName',
    },
    {
      title: t.APPROVED_ON,
      dataIndex: 'verificationDate',
      key: 'verificationDate',
      render: (date: string) => <>{moment(date).format(DATE_FORMAT2)}</>,
    },
    {
      title: t.APPROVED_BY,
      dataIndex: 'verifierName',
      key: 'verifierName',
    },
  ];
  return (
    <Modal
      title={t.DISCHARGE_CHEQUE_REQUISITION_HISTORY}
      destroyOnClose
      centered
      visible
      footer={null}
      closable
      onCancel={onClose}
      width={900}
      className="ChequeRequisitionHistoryModal"
    >
      <Table rowKey="id" columns={columns} pagination={false} scroll={{ y: 500 }} dataSource={dataSource} />
      <Row
        gutter={12}
        justify="end"
        align="bottom"
        wrap={false}
        className="ChequeRequisitionHistoryModal__footer-container"
      >
        <Col>
          <Form.Item noStyle>
            <Button onClick={onClose}>{t.CLOSE}</Button>
          </Form.Item>
        </Col>
      </Row>
    </Modal>
  );
}

export default ChequeRequisitionHistoryModal;
