import { Key } from 'react';
import { ClientService } from '../../../shared/api/ClientService';

export type ResidenceAvailableDto =
  ClientService.LookupDto_1OfOfResidenceAvailableEnumAndCoreAnd_0AndCulture_neutralAndPublicKeyToken_null;
export type TaxTypesDto = ClientService.LookupDto_1OfOfTaxTypesEnumAndCoreAnd_0AndCulture_neutralAndPublicKeyToken_null;
export type ResidenceAppliedToDto =
  ClientService.LookupDto_1OfOfResidenceAppliesToEnumAndCoreAnd_0AndCulture_neutralAndPublicKeyToken_null;
export type RESPsLookupDto =
  ClientService.LookupDto_1OfOfRESPRRSPEnumAndCoreAnd_0AndCulture_neutralAndPublicKeyToken_null;
export type RRSPsLookupDto =
  ClientService.LookupDto_1OfOfRESPRRSPEnumAndCoreAnd_0AndCulture_neutralAndPublicKeyToken_null;

export type IConfigurationType =
  | ClientService.IFileAnalysisProvincialExemptionsTaxRateConfigurationDto
  | ClientService.IFileAnalysisSurplusIncomeConfigurationDto
  | ClientService.IFileAnalysisNWTerritoryMultiplesConfigurationDto
  | ClientService.IFileAnalysisOSBFilingAndOtherFeeConfigurationDto
  | ClientService.IFileAnalysisRealEstateFeeConfigurationDto;

export type IDataProps<T extends IConfigurationType> = T & {
  key: Key;
  hasError: boolean;
};

export type OptionType = ResidenceAvailableDto | TaxTypesDto | ResidenceAppliedToDto | RESPsLookupDto | RRSPsLookupDto;

export interface ColumnType {
  key?: Key;
  dataIndex?: string;
  title?: string;
  type?: InputTypeEnum;
  options?: OptionType[];
  precision?: number;
}

export enum InputTypeEnum {
  Text = 0,
  Numeric = 1,
  Select = 2,
  ReadOnly = 3,
  UnlimitedNumeric = 4,
  Percentage = 5,
  Currency = 6,
}
