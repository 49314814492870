import { DatePicker, Input, Select, Col, Row, Checkbox, Form, FormInstance } from 'antd';
import moment from 'moment';

import { IconRed } from '../../../pages/ApplicationOverviewPage/DocumentsContent/Icons';

import { DATE_FORMAT2, DATE_FORMAT_PLACEHOLDER } from '../../../constants/common';
import { ClientService } from '../../../shared/api/ClientService';

import styles from '../../../styles/style.module.scss';
import '../RequestForReview.scss';
import useLocale from '../../../hooks/useLocale';

interface IProps {
  rowName: number;
  agents?: ClientService.LookupDto[];
  remove: (rowName: number) => void;
  isRemovable?: boolean;
  form: FormInstance;
  handleValidation: () => void;
}

function ReviewRow({ rowName, agents, remove, isRemovable = true, form, handleValidation }: IProps) {
  const { Option } = Select;
  const { t, getLocalizedDocumentName } = useLocale();

  return (
    <Form.Item noStyle shouldUpdate>
      {({ getFieldsValue, getFieldValue }) => {
        const documentValues = getFieldsValue()?.verifications?.[rowName];
        const isFirstRow = rowName === 0;
        return (
          <>
            <Row gutter={12} align="top">
              <Col span={3} style={{ paddingTop: isFirstRow ? 30 : 0 }}>
                <Form.Item>
                  <div
                    style={{
                      color:
                        documentValues?.verificationStatus === ClientService.DocumentVerificationStatusEnum.Pending
                          ? styles.colorDanger
                          : styles.colorPrimary,
                    }}
                  >
                    {getLocalizedDocumentName(documentValues)}
                  </div>
                </Form.Item>
              </Col>
              <Col span={4}>
                <Form.Item
                  name={[rowName, 'reviewers']}
                  label={isFirstRow && t.REVIEWERS_S}
                  validateStatus={documentValues?.error && 'error'}
                >
                  <Select size="large" mode="multiple" onChange={handleValidation}>
                    {agents?.map((option) => (
                      <Option key={`reviewers-${option?.id}`} value={option?.name}>
                        {option?.name}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={4}>
                <Form.Item
                  name={[rowName, 'approvers']}
                  label={isFirstRow && t.APPROVERS_S}
                  validateStatus={documentValues?.error && 'error'}
                >
                  <Select size="large" mode="multiple" onChange={handleValidation}>
                    {agents?.map((option) => (
                      <Option key={`approvers-${option?.id}`} value={option?.name}>
                        {option?.name}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={4}>
                <Form.Item
                  name={[rowName, 'signees']}
                  label={isFirstRow && t.SIGNEE_S}
                  validateStatus={documentValues?.error && 'error'}
                >
                  <Select size="large" mode="multiple" onChange={handleValidation}>
                    {agents?.map((option) => (
                      <Option key={`signees-${option?.id}`} value={option?.name}>
                        {option?.name}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={3}>
                <Form.Item
                  name={[rowName, 'dueDate']}
                  label={isFirstRow && t.DUE_DATE}
                  rules={[
                    {
                      required: getFieldValue(['verifications', rowName, 'isUrgent']),
                      message: t.DUE_DATE_IS_REQUIRED,
                    },
                  ]}
                  validateTrigger="onBlur"
                >
                  <DatePicker
                    size="large"
                    format={DATE_FORMAT2}
                    disabledDate={(date) => date.isBefore(moment(), 'day')}
                    placeholder={DATE_FORMAT_PLACEHOLDER}
                  />
                </Form.Item>
              </Col>
              <Col span={4}>
                <Form.Item name={[rowName, 'comments']} label={isFirstRow && t.COMMENTS}>
                  <Input size="large" />
                </Form.Item>
              </Col>
              <Col span={2}>
                <Form.Item label={isFirstRow && 'Urgent'}>
                  <Row justify="center" align="middle">
                    <Col span={12}>
                      <Form.Item name={[rowName, 'isUrgent']} noStyle valuePropName="checked" trigger="onChange">
                        <Checkbox />
                      </Form.Item>
                    </Col>

                    <Col span={12}>
                      <Form.Item noStyle>
                        {isRemovable && <IconRed iconName="Delete" onClick={() => remove(rowName)} />}
                      </Form.Item>
                    </Col>
                  </Row>
                </Form.Item>
              </Col>
            </Row>

            <Row>
              <Col offset={3} span={12} className="RequestForReview__error">
                {documentValues?.error && <div>{documentValues?.error}</div>}
              </Col>
            </Row>
          </>
        );
      }}
    </Form.Item>
  );
}

export default ReviewRow;
