import React from 'react';
import { Col, Tooltip } from 'antd';
import { Icon } from '@fluentui/react/lib/Icon';

import './DocumentsTable.scss';
import styles from '../../../../styles/style.module.scss';

interface IProps {
  iconName?: string;
  title?: string;
  isActive?: boolean;
  onClick: () => void;
  disabled?: boolean;
  iconStyle?: React.CSSProperties;
}

const PreviewFileTab = ({ isActive, onClick, title, iconName, disabled, iconStyle }: IProps) => (
  <Col className="DocumentsTable__preview-tab">
    <Tooltip title={title}>
      <Icon
        iconName={iconName}
        onClick={() => {
          if (disabled) return;
          onClick();
        }}
        style={{
          color: isActive ? styles.colorDanger : disabled ? styles.colorSecondary : styles.colorPrimary,
          fontWeight: isActive ? 600 : 400,
          cursor: disabled ? 'not-allowed' : 'pointer',
          ...iconStyle,
        }}
      />
    </Tooltip>
  </Col>
);

export default PreviewFileTab;
