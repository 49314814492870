import { useCallback } from 'react';
import { Modal, Col, Row, Form, Input } from 'antd';

import Button from '../../../../components/Button/Button';

import { ClientService } from '../../../../shared/api/ClientService';
import useLocale from '../../../../hooks/useLocale';
import API from '../../../../utils/api';
import { argumentifyVerifyDocument } from '../utils';

import './RejectModal.scss';

interface IProps {
  documentVerificationId?: string;
  onOk?: () => void;
  onCancel?: () => void;
  verificationRole?: ClientService.DocumentVerificationRoleTypeEnum;
}

interface IForm {
  comment: string;
}

function RejectModal({ documentVerificationId, onOk, onCancel, verificationRole }: IProps): JSX.Element {
  const { t } = useLocale();

  const [form] = Form.useForm<IForm>();
  const { TextArea } = Input;

  const handleSave = useCallback(
    async (values) => {
      const result = await API.submitPUT(
        ...argumentifyVerifyDocument({
          id: documentVerificationId as string,
          body: {
            verificationRole,
            verificationResult: ClientService.DocumentVerificationRoleResultEnum.Reject,
            comment: values?.comment,
          } as ClientService.DocumentVerificationSubmitDto,
        })
      );

      if (result && onOk) onOk();
    },
    [documentVerificationId, verificationRole, onOk]
  );

  return (
    <Modal
      destroyOnClose
      centered
      visible
      title={t.REJECT_DOCUMENT}
      className="RejectModal"
      width={600}
      footer={null}
      closable
      onCancel={onOk}
    >
      <Form form={form} layout="vertical" onFinish={handleSave}>
        <Form.Item
          name="comment"
          label={t.REASON_FOR_REJECTION}
          rules={[{ required: true, message: t.REQUIRED_FIELD }]}
        >
          <TextArea rows={4} />
        </Form.Item>
        <Row gutter={12} justify="end" className="ReviewStatusModal__buttons-container">
          <Col>
            <Button kind="cancel" padding="large" onClick={onCancel}>
              {t.CANCEL}
            </Button>
          </Col>
          <Col>
            <Button kind="primary" padding="large" htmlType="submit">
              {t.SAVE}
            </Button>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
}

export default RejectModal;
