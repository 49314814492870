import React, { useCallback, useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import type { ColumnsType } from 'antd/lib/table';
import { Input, Table, Row, Col, Switch, Tabs, Space, Spin, Tooltip, Typography, Popover } from 'antd';
import { Icon } from '@fluentui/react/lib/Icon';
import moment from 'moment';

import Divider from '../../../../components/Divider/Divider';
import RowActionMenu from './RowActionMenu';
import HeaderToolbar from './HeaderToolbar';
import PreviewFile, { DocumentPreviewTypeEnum } from '../../../../components/PreviewFile';
import { IconRed } from '../Icons';
import NotesList from '../../NotesContent/NotesList/NotesList';
import AddIcon from '../../../../images/add_icon.svg';
import Filters from '../Filters/Filters';
import ReviewStatusModal from '../ReviewStatusModal/ReviewStatusModal';
import UploadModal from '../UploadModal/UploadModal';
import ActionConfirmationModal from '../../../../modals/ActionConfirmationModal/ActionConfirmationModal';
import { ROUTES } from '../../../../constants/routes';
import { DOCUMENTS_TAB } from '../../ApplicationOverviewContent/ApplicationOverviewContent';
import PreviewFileTab from './PreviewFileTab';

import {
  argumentifyDocumentsSearchCriteria,
  argumentifyDocumentVersionsParams,
  readableBytes,
  argumentifyUpdateDocument,
  statusNameToReadable,
  getVerificationsStatusColor,
} from '../utils';
import { FileActionEnum } from '../../../../components/AttachmentsTab';
import { IDocumentSearchCriteria, IVersionSearchCriteria } from '../types';
import { TableViewEnum, TablePreviewEnum, DocumentsDataType, VersionsDataType } from './types';
import { DATE_FORMAT2, DEFAULT_PAGE_SIZE, FIRST_ITEM, SORTING_ASC, SORTING_DESC } from '../../../../constants/common';
import { paginationShowTotal } from '../../../../utils/helpers';

import { ClientService } from '../../../../shared/api/ClientService';
import API from '../../../../utils/api';
import useLocale from '../../../../hooks/useLocale';
import useModal from '../../../../hooks/useModal';
import useDebounce from '../../../../hooks/useDebounce';
import genericMessage from '../../../../utils/genericMessage';

import './DocumentsTable.scss';

interface IProps {
  initialDocumentId?: string;
  isDeleted?: boolean;
  updateDocumentsTitle?: () => void;
}

const LIST_TAB = 'list';
const NOTES_TAB = 'notes';
const VERSIONS_TAB = 'versions';

function DocumentsTable({ initialDocumentId, isDeleted = false, updateDocumentsTitle }: IProps): JSX.Element {
  const { Search } = Input;
  const { TabPane } = Tabs;
  const { Text } = Typography;

  const { t, getLocalizedDocumentName, getLocalizedVersionDocumentName } = useLocale();
  const { showModal, closeModal } = useModal();
  const { applicationFileId } = useParams<{ applicationFileId?: string }>();
  const navigate = useNavigate();

  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>();
  const [selectedDocumentId, setDocumentId] = useState<string>();
  const [selectedDocument, setSelectedDocument] = useState<ClientService.IDocumentDto>();
  const [selectedVersion, setSelectedVersion] = useState<ClientService.IDocumentVersionDto>();

  const [documents, setDocuments] = useState<DocumentsDataType[]>();
  const [totalCount, setTotalCount] = useState<number>(0);
  const [documentsLoading, setDocumentsLoading] = useState<boolean>(false);
  const [criteria, setCriteria] = useState<IDocumentSearchCriteria>({
    skipCount: 0,
    maxResultCount: DEFAULT_PAGE_SIZE,
    fileId: applicationFileId,
    filterText: '',
    documentFolders: [],
    sorting: 'modifiedDate desc',
    documentId: initialDocumentId,
    isDeleted,
  });

  const [versions, setVersions] = useState<DocumentsDataType[]>();
  const [versionsTotalCount, setVersionsTotalCount] = useState<number>(0);
  const [versionsLoading, setVersionsLoading] = useState<boolean>(false);
  const [versionRestoring, setVersionRestoring] = useState<boolean>(false);
  const [versionsCriteria, setVersionsCriteria] = useState<IVersionSearchCriteria>({
    skipCount: 0,
    maxResultCount: DEFAULT_PAGE_SIZE,
    sorting: 'modifiedDate desc',
  });

  const [listTypes, setListTypes] = useState<ClientService.LookupDto[]>();
  const [listFolders, setListFolders] = useState<ClientService.LookupDto[]>();
  const [clientUploadFolderId, setClientUploadFolderId] = useState<string>();

  const [tableView, setTableView] = useState<TableViewEnum>(
    initialDocumentId ? TableViewEnum.TablePreview : TableViewEnum.TableList
  );
  const [tablePreviewView, setTablePreviewView] = useState<TablePreviewEnum>(TablePreviewEnum.Regular);
  const [activeTab, setActiveTab] = useState<string>(LIST_TAB);

  const [searchText, setSearchText] = useState<string>();
  const filterText = useDebounce(searchText);

  const [config, setConfig] = useState<ClientService.QuickActionsConfigDto>();

  const requestFileConfig = useCallback(async (applicationFileId: string) => {
    const response = await API.getQuickActionsConfig(applicationFileId);
    if (response) setConfig(response);
  }, []);

  const requestDocuments = useCallback(async () => {
    setDocumentsLoading(true);
    const response = await API.documentsGET(...argumentifyDocumentsSearchCriteria({ ...criteria })).catch((e) => {
      setDocumentsLoading(false);
      genericMessage.error(e);
    });
    setDocumentsLoading(false);

    if (response) {
      setDocuments(response?.items?.map((item) => ({ ...item, key: item?.id as React.Key })));
      setDocumentId(response?.items?.[FIRST_ITEM]?.id);
    }
    setTotalCount(response?.totalCount || 0);
  }, [criteria]);

  const requestVersions = useCallback(async () => {
    setVersionsLoading(true);
    const response = await API.versionsGET(
      ...argumentifyDocumentVersionsParams({ ...versionsCriteria, documentId: selectedDocumentId })
    );

    if (response.items) {
      setVersions(response?.items?.map((item) => ({ ...item, key: item?.id as React.Key })));
      setSelectedVersion(response?.items?.[FIRST_ITEM]);
    }
    if (response.totalCount) setVersionsTotalCount(response.totalCount);
    setVersionsLoading(false);
  }, [versionsCriteria, selectedDocumentId]);

  const requestListTypes = useCallback(async () => {
    const response = await API.listTypes();
    if (response) setListTypes(response);
  }, []);

  const requestListFolders = useCallback(async () => {
    const response = await API.listFolders();
    if (response) {
      setListFolders(response);
      setClientUploadFolderId(
        response?.find((item) => item?.enumValue == ClientService.DocumentFolderEnum.ClientUpload)?.id
      );
    }
  }, []);

  const requestRestore = useCallback(
    async (selectedDocumentId) => {
      await API.restore(selectedDocumentId);
      requestDocuments();
    },
    [requestDocuments]
  );

  const excludeDocumentIdFromSearchResults = useCallback(() => {
    if (criteria?.documentId) {
      setCriteria((prev) => ({ ...prev, documentId: undefined }));
    }
  }, [criteria?.documentId]);

  const handleTableChange = useCallback(
    (pagination: any, filters: any, sorter: any) => {
      setCriteria((prev) => ({
        ...prev,
        documentTypes: filters.documentTypeName || undefined,
        documentVerificationStatuses: filters.verificationStatus || undefined,
        isVisible: filters?.isVisible?.length === 1 ? filters?.isVisible?.[0] : undefined,
        maxResultCount: pagination.pageSize,
        skipCount: pagination.pageSize * (pagination.current - 1),
        sorting:
          sorter.field !== undefined
            ? `${sorter.field === 'uploadDate' ? 'CreationTime' : sorter.field} ${
                sorter.order === 'ascend' ? SORTING_ASC : SORTING_DESC
              }`
            : prev?.sorting,
      }));
    },
    [setCriteria]
  );

  const handleVersionsTableChange = useCallback(
    (pagination: any, filters: any, sorter: any) => {
      setVersionsCriteria({
        ...versionsCriteria,
        maxResultCount: pagination.pageSize,
        skipCount: pagination.pageSize * (pagination.current - 1),
        sorting:
          sorter.field !== undefined
            ? `${sorter.field} ${sorter.order === 'ascend' ? SORTING_ASC : SORTING_DESC}`
            : versionsCriteria.sorting,
      });
    },
    [setVersionsCriteria, versionsCriteria]
  );

  const handleAddDocument = useCallback(() => {
    showModal(
      <UploadModal
        fileId={applicationFileId as string}
        action={FileActionEnum.Upload}
        onOk={() => {
          closeModal();
          requestDocuments();
        }}
        onCancel={closeModal}
      />
    );
  }, [showModal, applicationFileId, closeModal, requestDocuments]);

  const handleCommentDocument = useCallback(
    (documentId) => () => {
      setTableView(TableViewEnum.TablePreview);
      setDocumentId(documentId);
      setSelectedRowKeys([]);
      setActiveTab(NOTES_TAB);
    },
    []
  );

  const requestActionConfirmation = useCallback(
    async (title: string, message: string, cb: () => void) => {
      showModal(
        <ActionConfirmationModal
          title={title}
          message={message}
          okText={t.YES}
          cancelText={t.NO}
          onOk={() => {
            cb();
            closeModal();
          }}
          onCancel={closeModal}
        />
      );
    },
    [closeModal, showModal, t.NO, t.YES]
  );

  const handleRestoreDocument = useCallback(
    (documentId) => {
      requestRestore(documentId);
    },
    [requestRestore]
  );

  const handleRestoreDocumentVersion = useCallback(async (version: VersionsDataType) => {
    setVersionRestoring(true);
    await API.restore2(version?.id as string).catch(() => setVersionRestoring(false));
    setSelectedVersion(version);

    setVersionRestoring(false);
  }, []);

  const handleVisibilityToggle = useCallback(
    (document: ClientService.IDocumentDto) => async (isVisible: boolean) => {
      setDocuments(
        documents?.map((item) => {
          if (item.id === document?.id) {
            item.isVisible = isVisible;
          }
          return item;
        })
      );

      const response = await API.documentsPUT(
        ...argumentifyUpdateDocument({
          ...document,
          id: document.id as string,
          isVisible,
          fileModifiedDate: moment(),
        })
      ).catch((error) => console.log(error));

      if (response) requestDocuments();
    },
    [documents, requestDocuments]
  );

  const handleFiltersReset = useCallback(() => {
    setCriteria((prev) => ({ ...prev, documentFolders: [], skipCount: 0 }));
  }, []);

  const handleFiltersChange = useCallback((documentFolders) => {
    setCriteria((prev) => ({ ...prev, documentFolders, skipCount: 0 }));
  }, []);

  const handleSearchChange = useCallback((e) => {
    const value = e.target.value;
    setSearchText(value);
  }, []);

  const handleHeaderToolbarActionComplete = useCallback(() => {
    setSelectedRowKeys([]);
    requestDocuments();
  }, [requestDocuments]);

  const toggleTableView = useCallback(() => {
    excludeDocumentIdFromSearchResults();
    setTableView(tableView === TableViewEnum.TablePreview ? TableViewEnum.TableList : TableViewEnum.TablePreview);
  }, [excludeDocumentIdFromSearchResults, tableView]);

  useEffect(() => {
    requestDocuments();
    if (criteria?.fileId) {
      requestFileConfig(criteria.fileId);
    }
  }, [criteria, requestDocuments]);

  useEffect(() => {
    if (activeTab === VERSIONS_TAB) {
      requestVersions();
    }
  }, [versionsCriteria, requestVersions, activeTab]);

  useEffect(() => {
    if (!listTypes) requestListTypes();
  }, [listTypes, requestListTypes]);

  useEffect(() => {
    if (!listFolders) requestListFolders();
  }, [listFolders, requestListFolders]);

  useEffect(() => {
    if (selectedDocumentId) setSelectedDocument(documents?.find((item) => item.id === selectedDocumentId));
  }, [documents, selectedDocumentId]);

  useEffect(() => {
    setCriteria((prevCriteria) => ({ ...prevCriteria, filterText }));
  }, [filterText]);

  useEffect(() => {
    if (initialDocumentId && !isDeleted) {
      setTableView(TableViewEnum.TablePreview);
      setDocumentId(initialDocumentId);
      navigate(`${ROUTES.APPLICATION_OVERVIEW}/${applicationFileId}`, {
        state: { preselectedTab: DOCUMENTS_TAB, preselectedDocumentsSubTab: DOCUMENTS_TAB },
      });
    }
  }, [applicationFileId, initialDocumentId, isDeleted, navigate]);

  const columns: ColumnsType<DocumentsDataType> = [
    {
      title: t.DOCUMENTS_NAME,
      dataIndex: 'name',
      key: 'name',
      sorter: true,
      width: '25%',
      render: (name?: string, record?: DocumentsDataType) => (
        <Text style={{ whiteSpace: 'normal', wordBreak: 'break-word' }}>{getLocalizedDocumentName(record)}</Text>
      ),
    },
    {
      title: t.DOCUMENTS_FOLDER,
      dataIndex: 'documentFolderName',
      key: 'documentFolderName',
      sorter: true,
      render: (documentFolderName?: string, record?: DocumentsDataType) =>
        record?.documentFolderId == clientUploadFolderId ? (
          <Text
            onClick={(e) => {
              e?.stopPropagation();
              showModal(
                <UploadModal
                  fileId={applicationFileId as string}
                  documents={[record as ClientService.IDocumentDto]}
                  action={FileActionEnum.Edit}
                  onOk={() => {
                    closeModal();
                    requestDocuments();
                    if (updateDocumentsTitle) updateDocumentsTitle();
                  }}
                  onCancel={closeModal}
                />
              );
            }}
            style={{ color: '#ed1a3b', textDecoration: 'underline' }}
          >
            {documentFolderName}
          </Text>
        ) : (
          <Text>{documentFolderName}</Text>
        ),
    },
    {
      title: t.DOCUMENTS_UPLOADED,
      dataIndex: 'uploadDate',
      key: 'uploadDate',
      render: (item?: moment.Moment) => <>{moment(item).format(DATE_FORMAT2)}</>,
      sorter: true,
    },
    {
      title: t.DOCUMENTS_MODIFIED,
      dataIndex: 'modifiedDate',
      key: 'modifiedDate',
      render: (item?: moment.Moment) => <>{moment(item).format(DATE_FORMAT2)}</>,
      sorter: true,
    },
    {
      title: t.DOCUMENTS_SIZE,
      dataIndex: 'fileSizeInBytes',
      key: 'fileSizeInBytes',
      render: (item?: number) => <>{readableBytes(item)}</>,
      sorter: true,
    },
    {
      title: t.DOCUMENTS_TYPE,
      dataIndex: 'documentTypeName',
      key: 'documentTypeName',
      filters: listTypes?.map((item) => ({ text: item?.name as string, value: item?.enumValue as number })),
      sorter: true,
    },
    {
      title: t.DOCUMENTS_STATUS,
      dataIndex: 'verificationStatus',
      key: 'verificationStatus',
      sorter: true,
      render: (_: any, record: DocumentsDataType) => (
        <div
          className="DocumentsTable__status-link"
          onClick={(e) => {
            e.stopPropagation();
            showModal(
              <ReviewStatusModal
                documentId={record.documentVerificationId}
                onClose={closeModal}
                onDelete={() => {
                  closeModal();
                  requestDocuments();
                }}
              />
            );
          }}
          style={{ color: getVerificationsStatusColor(record?.verificationStatus) }}
        >
          {statusNameToReadable(record?.verificationStatus)}
        </div>
      ),
      filters: [
        {
          text: statusNameToReadable(ClientService.DocumentVerificationStatusEnum.Pending),
          value: ClientService.DocumentVerificationStatusEnum.Pending,
        },
        {
          text: statusNameToReadable(ClientService.DocumentVerificationStatusEnum.PartiallyCompleted),
          value: ClientService.DocumentVerificationStatusEnum.PartiallyCompleted,
        },
        {
          text: statusNameToReadable(ClientService.DocumentVerificationStatusEnum.Completed),
          value: ClientService.DocumentVerificationStatusEnum.Completed,
        },
        {
          text: statusNameToReadable(ClientService.DocumentVerificationStatusEnum.Rejected),
          value: ClientService.DocumentVerificationStatusEnum.Rejected,
        },
      ],
      hidden: isDeleted,
    },
    {
      title: t.DOCUMENTS_VISIBLE,
      dataIndex: 'isVisible',
      key: 'isVisible',
      render: (value: any, record: DocumentsDataType) => (
        <div onClick={(e) => e.stopPropagation()}>
          <Switch checked={value || false} onChange={handleVisibilityToggle(record)} />
        </div>
      ),
      filters: [
        { text: t.VISIBLE, value: true },
        { text: t.HIDDEN, value: false },
      ],
      hidden: isDeleted,
    },
    {
      key: 'action',
      render: (text: string, record: DocumentsDataType) =>
        isDeleted ? (
          <Row
            className="DocumentsTable__link--primary"
            align="middle"
            gutter={6}
            justify="end"
            onClick={(e) => {
              e.stopPropagation();
              requestActionConfirmation(t.CONFIRM_RECOVER, t.CONFIRM_RECOVER_SELECTED_FILES_MESSAGE, () =>
                handleRestoreDocument(record?.id)
              );
            }}
          >
            <Col>{t.RECOVER_FILE}</Col>
            <Col>
              <Icon iconName="RevToggleKey" />
            </Col>
          </Row>
        ) : (
          <RowActionMenu
            document={record}
            onComplete={requestDocuments}
            onComment={handleCommentDocument(record?.id)}
            canReceiveEmail={config?.canReceiveEmail}
          />
        ),
    },
  ].filter((item) => !item.hidden);

  const columnsVersions: ColumnsType<VersionsDataType> = [
    {
      title: t.DOCUMENTS_VERSION,
      dataIndex: 'versionName',
      key: 'versionName',
      width: '25%',
      render: (_?: string, record?: VersionsDataType) => (
        <Text style={{ whiteSpace: 'normal', wordBreak: 'break-word' }}>{getLocalizedVersionDocumentName(record)}</Text>
      ),
    },
    {
      title: t.DOCUMENTS_MODIFIED_BY,
      dataIndex: 'modifiedBy',
      render: (text: string, item: VersionsDataType) => <>{item.modifiedBy || '-'}</>,
      key: 'modifiedBy',
      sorter: true,
    },
    {
      title: t.DOCUMENTS_MODIFIED_ON,
      dataIndex: 'modifiedDate',
      render: (text: string, item: VersionsDataType) => <>{moment(item.modifiedDate).format(DATE_FORMAT2)}</>,
      key: 'modifiedDate',
      sorter: true,
    },
    {
      key: 'action',
      render: (text: string, item: VersionsDataType) => (
        <Tooltip title={t.RESTORE_VERSION}>
          <IconRed iconName="RevToggleKey" onClick={() => handleRestoreDocumentVersion(item)} />
        </Tooltip>
      ),
    },
  ];

  const table = (
    <Spin spinning={documentsLoading}>
      <Table
        rowKey="id"
        dataSource={[...(documents || [])]}
        columns={columns}
        onChange={handleTableChange}
        pagination={{
          position: ['bottomLeft'],
          defaultPageSize: DEFAULT_PAGE_SIZE,
          showSizeChanger: true,
          total: totalCount,
          showTotal: paginationShowTotal,
        }}
        rowSelection={{
          selectedRowKeys,
          onChange: setSelectedRowKeys,
        }}
        onRow={(record) => {
          return {
            onClick: (e) => {
              if ((e?.target as HTMLDivElement)?.classList?.contains('ant-table-selection-column')) {
                return;
              }
              setDocumentId(record?.id);
              setTableView(TableViewEnum.TablePreview);
            },
          };
        }}
        className={documents?.length ? 'DocumentsTable__table' : 'DocumentsTable__table-empty'}
        rowClassName={(record: DocumentsDataType) =>
          record?.id === selectedDocumentId ? 'DocumentsTable__row--selected' : 'DocumentsTable__row'
        }
      />
    </Spin>
  );

  const previewTable = (
    <Spin spinning={documentsLoading}>
      <Table
        rowKey="id"
        dataSource={documents}
        columns={Object.assign([], columns).splice(0, 3)}
        onChange={handleTableChange}
        pagination={{
          position: ['bottomLeft'],
          defaultPageSize: DEFAULT_PAGE_SIZE,
          showSizeChanger: true,
          total: totalCount,
          showTotal: paginationShowTotal,
        }}
        rowSelection={{
          selectedRowKeys,
          onChange: setSelectedRowKeys,
        }}
        onRow={(record) => ({
          onClick: (e) => {
            setDocumentId(record?.id);
          },
        })}
        rowClassName={(record: DocumentsDataType) =>
          record?.id === selectedDocumentId ? 'DocumentsTable__row--selected' : 'DocumentsTable__row'
        }
        className={documents?.length ? 'DocumentsTable__table' : 'DocumentsTable__table-empty'}
      />
    </Spin>
  );

  const versionsTable = (
    <Spin spinning={versionsLoading || versionRestoring}>
      <Table
        rowKey="id"
        dataSource={versions}
        columns={columnsVersions}
        onChange={handleVersionsTableChange}
        pagination={{
          position: ['bottomLeft'],
          defaultPageSize: DEFAULT_PAGE_SIZE,
          showSizeChanger: true,
          total: versionsTotalCount,
          showTotal: paginationShowTotal,
        }}
        onRow={(record) => ({
          onClick: () => setSelectedVersion(record),
        })}
        rowClassName={(record: DocumentsDataType) =>
          record?.id === selectedVersion?.id ? 'DocumentsTable__row--selected' : 'DocumentsTable__row'
        }
        className={versions?.length ? 'DocumentsTable__table' : 'DocumentsTable__table-empty'}
      />
    </Spin>
  );

  const isFullScreen = tablePreviewView === TablePreviewEnum.FullScreen;
  const isRowSelected = Boolean(selectedRowKeys?.length);

  return (
    <div className="DocumentsTable">
      <Row justify="space-between" className="DocumentsTable__header">
        <Col xl={18} xs={16}>
          <Row align="middle" gutter={20} justify="start">
            <Filters
              listFolders={listFolders}
              documentFolders={criteria.documentFolders}
              onFoldersSelect={handleFiltersChange}
            />
            <Col onClick={handleFiltersReset} className="DocumentsTable__clear">
              {t.CLEAR_ALL}
            </Col>
            <Col>
              <Search value={searchText} onChange={handleSearchChange} placeholder={t.SEARCH} allowClear />
            </Col>
          </Row>
        </Col>

        <Col xl={6} xs={8}>
          <Row align="middle" gutter={20} justify="end">
            <Col className="DocumentsTable__add" onClick={handleAddDocument}>
              {t.DOCUMENTS_UPLOAD_FILE}
              <img src={AddIcon} alt="add-icon" />
            </Col>
          </Row>
        </Col>
      </Row>

      <Divider className="DocumentsTable__divider" />

      {tableView === TableViewEnum.TableList && (
        <>
          <Row align="middle" justify="space-between">
            <Col>
              {isRowSelected && (
                <HeaderToolbar
                  isRecoveryMode={isDeleted}
                  selectedIds={selectedRowKeys as string[]}
                  documents={documents}
                  onComplete={handleHeaderToolbarActionComplete}
                  canReceiveEmail={config?.canReceiveEmail}
                />
              )}
            </Col>
            <Col>
              {!isDeleted && (
                <Space>
                  <Tooltip title={t.FILE_VIEW}>
                    <IconRed iconName="Page" onClick={toggleTableView} />
                  </Tooltip>
                </Space>
              )}
            </Col>
          </Row>
          {table}
        </>
      )}

      {tableView === TableViewEnum.TablePreview && (
        <Row gutter={20}>
          <Col span={isFullScreen ? 0 : 8}>
            <Tabs
              tabBarExtraContent={
                <Space>
                  <Tooltip title={t.BACK_TO_LIST_VIEW}>
                    <IconRed iconName="BulletedList" onClick={toggleTableView} />
                  </Tooltip>
                </Space>
              }
              activeKey={activeTab}
              onTabClick={(activeKey) => {
                setActiveTab(activeKey);
                setTablePreviewView(TablePreviewEnum.Regular);
              }}
              renderTabBar={() => (
                <Row align="middle" justify="space-between">
                  <Col>
                    {!isRowSelected ? (
                      <Row align="middle" justify="start">
                        <PreviewFileTab
                          title={t.FILE_INFORMATION}
                          iconName="PageList"
                          onClick={() => setActiveTab(LIST_TAB)}
                          isActive={activeTab === LIST_TAB}
                        />
                        <PreviewFileTab
                          title={t.COMMENTS}
                          iconName="Chat"
                          onClick={() => setActiveTab(NOTES_TAB)}
                          isActive={activeTab === NOTES_TAB}
                          disabled={!selectedDocument}
                        />
                        <PreviewFileTab
                          title={t.VERSION_HISTORY}
                          iconName="History"
                          onClick={() => setActiveTab(VERSIONS_TAB)}
                          isActive={activeTab === VERSIONS_TAB}
                          disabled={!selectedDocument}
                        />
                      </Row>
                    ) : (
                      <HeaderToolbar
                        isRecoveryMode={isDeleted}
                        selectedIds={selectedRowKeys as string[]}
                        documents={documents}
                        onComplete={handleHeaderToolbarActionComplete}
                        canReceiveEmail={config?.canReceiveEmail}
                      />
                    )}
                  </Col>
                  <Col>
                    <Space>
                      <Tooltip title={t.BACK_TO_LIST_VIEW}>
                        <IconRed iconName="BulletedList" onClick={toggleTableView} />
                      </Tooltip>
                    </Space>
                  </Col>
                </Row>
              )}
            >
              <>
                {isRowSelected ? (
                  <TabPane key={LIST_TAB}>{previewTable}</TabPane>
                ) : (
                  <>
                    <TabPane key={LIST_TAB}>{previewTable}</TabPane>
                    <TabPane key={NOTES_TAB}>
                      {activeTab === NOTES_TAB ? (
                        <NotesList
                          isAddMode
                          documentId={selectedDocumentId}
                          pagination={false}
                          isAvatarVisible={false}
                        />
                      ) : null}
                    </TabPane>
                    <TabPane key={VERSIONS_TAB}>{versionsTable}</TabPane>
                  </>
                )}
              </>
            </Tabs>
          </Col>
          <Col span={isFullScreen ? 24 : 16}>
            {!documentsLoading && (
              <PreviewFile
                file={activeTab === VERSIONS_TAB ? selectedVersion : selectedDocument}
                documentPreviewType={
                  activeTab === VERSIONS_TAB
                    ? DocumentPreviewTypeEnum.documentVersion
                    : DocumentPreviewTypeEnum.document
                }
                onUpdate={activeTab === VERSIONS_TAB ? requestVersions : requestDocuments}
              />
            )}
          </Col>
        </Row>
      )}
    </div>
  );
}

export default DocumentsTable;
