import { useCallback } from 'react';
import { Modal, Form, Row, Col } from 'antd';
import moment from 'moment';

import Button from '../../components/Button/Button';
import DatePicker from '../../components/DatePicker/DatePicker';

import useLocale from '../../hooks/useLocale';
import { DATE_FORMAT2, DATE_FORMAT_PLACEHOLDER } from '../../constants/common';

import './FilingDateModal.scss';

interface IProps {
  title?: string;
  filingDate?: moment.Moment;
  onOk?: (filingDate: moment.Moment) => void;
  onCancel?: () => void;
}

function FilingDateModal({ title, filingDate, onOk, onCancel }: IProps): JSX.Element {
  const { t } = useLocale();
  const [form] = Form.useForm<{ filingDate?: string }>();

  const handleSubmit = useCallback(
    async ({ filingDate }) => {
      if (onOk) onOk(filingDate);
    },
    [onOk]
  );

  const handleCancel = useCallback(() => {
    if (onCancel) onCancel();
  }, [onCancel]);

  return (
    <Modal
      title={title}
      destroyOnClose
      centered
      visible
      footer={null}
      closable
      onCancel={handleCancel}
      className="FilingDateModal"
    >
      <Form form={form} onFinish={handleSubmit} layout="vertical" initialValues={{ filingDate }}>
        <Form.Item
          label={t.FILLING_DATE}
          name="filingDate"
          required
          rules={[{ required: true, message: `${t.FILLING_DATE} ${t.IS_REQUIRED}!` }]}
        >
          <DatePicker
            format={DATE_FORMAT2}
            dateSetter={null}
            size="large"
            disabledDate={(date) => date.isAfter(moment(), 'day')}
            placeholder={DATE_FORMAT_PLACEHOLDER}
          />
        </Form.Item>
        <Row gutter={12} justify="end" align="bottom" wrap={false} className="FilingDateModal__footer">
          <Col>
            <Form.Item noStyle>
              <Button kind="cancel" onClick={onCancel}>
                {t.CANCEL}
              </Button>
            </Form.Item>
          </Col>
          <Col>
            <Form.Item noStyle>
              <Button htmlType="submit">{t.SAVE}</Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
}

export default FilingDateModal;
