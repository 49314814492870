import { useCallback, useEffect, useState } from 'react';
import { Icon } from '@fluentui/react/lib/Icon';
import moment from 'moment';

import { MeetingTypeDto } from '../../types';
import { convertTimeToReadable, sortByStartDate } from '../../utils';
import { ClientService } from '../../../../shared/api/ClientService';

import './CalendarAvailability.scss';

interface IProps {
  data?: ClientService.AgentAvailabilityDto;
  meetingTypes?: MeetingTypeDto[];
}

const Content = ({ meetingTypes, data }: IProps) => {
  const [sorted, setSorted] = useState<ClientService.AgentAvailabilityEntryDto[]>();

  const getIcon = useCallback(
    (meetingTypeId?: string) =>
      meetingTypes?.find((item) => item?.id === meetingTypeId)?.enumValue === ClientService.MeetingTypeEnum.InPerson
        ? 'Contact'
        : 'Phone',
    [meetingTypes]
  );

  useEffect(() => {
    if (data && data?.availabilityEntries) {
      setSorted(sortByStartDate(data?.availabilityEntries));
    }
  }, [data]);

  if (!sorted || !sorted?.length) return <></>;

  return (
    <div className="CalendarAvailability__cell-container">
      {sorted?.map((entry, index) => (
        <div className="CalendarAvailability__cell-item" key={`entry-${data?.date}-${index}`}>
          <Icon iconName={getIcon(entry.meetingTypeId)} />
          <div>
            {
              //convertTimeToReadable(moment.utc(entry?.startTime).local(true), moment.utc(entry?.endTime).local(true))
              convertTimeToReadable(moment(entry?.startTime).utc(), moment(entry?.endTime).utc())
            }
          </div>
        </div>
      ))}
    </div>
  );
};

export default Content;
