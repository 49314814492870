import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Input, Row, Col, Form, Spin } from 'antd';
import InputMask from 'react-input-mask';
import moment from 'moment';

import ActionConfirmationModal from '../../modals/ActionConfirmationModal/ActionConfirmationModal';

import PageTitleContainer from '../../components/PageTitleContainer/PageTitleContainer';
import Breadcrumb from '../../components/Breadcrumb/Breadcrumb';
import SelectLookupDto from '../../components/SelectLookupDto/SelectLookupDto';
import Layout from '../../components/Layout/Layout';
import Button from '../../components/Button/Button';

import FollowUpModal from '../../modals/FollowUp/FollowUp';
import GenericErrorModal from '../../modals/GenericErrorModal/GenericErrorModal';
import SimilarRecordsModal from '../../modals/SimilarRecords/SimilarRecords';

import useLocale from '../../hooks/useLocale';
import useModal from '../../hooks/useModal';
import { convertInputDateToServerDateOnly } from '../../utils/helpers';

import { ROUTES } from '../../constants/routes';
import { EMPTY_PHONE_MASK, PHONE_MASK } from './constants';
import genericMessage from '../../utils/genericMessage';
import { ClientService } from '../../shared/api/ClientService';
import API from '../../utils/api';

import './NewProspectPage.scss';

const NewProspectPage = (): JSX.Element => {
  const navigate = useNavigate();
  const { t } = useLocale();
  const { showModal, closeModal } = useModal();
  const [form] = Form.useForm<ClientService.PersonalDetailsDto>();

  const [phoneTypes, setPhoneTypes] = useState<ClientService.PhoneTypeLookupDto[]>();
  const [loading, setLoading] = useState<boolean>(false);

  const requestPhoneTypes = useCallback(async () => {
    const response = await API.listPhoneTypes();

    if (response) {
      setPhoneTypes(response);
      form.setFieldsValue({
        mainPhoneTypeId: response?.find((item) => item.enumValue === ClientService.PhoneTypeEnum.Mobile)?.id,
      });
    }
  }, [form]);

  const requestGetDuplicates = useCallback(async () => {
    const values = form.getFieldsValue();
    return await API.checkForDuplicates(
      values?.firstName,
      values?.lastName,
      values?.mainPhoneNumber,
      values?.emailAddress,
      []
    ).catch(genericMessage.error);
  }, [form]);

  const navigateToNextStep = useCallback(
    (returnId?: string) => navigate(`${ROUTES.DEBTOR_PROFILE}/${returnId}`),
    [navigate]
  );

  const navigateToDebtorSearch = useCallback(() => navigate(`${ROUTES.DEBTOR_SEARCH}`), [navigate]);

  const requestCreatePersonalDetails = useCallback(
    async ({
      followUpDate,
      onSuccess,
      onFailure,
    }: {
      followUpDate?: moment.Moment;
      onSuccess: (returnId?: string) => void;
      onFailure?: () => void;
    }) => {
      const values = form.getFieldsValue();
      setLoading(true);
      const response = !followUpDate
        ? await API.createPersonalDetails(values).catch(() => setLoading(false))
        : await API.createPersonalDetailsWithFollowUpTask({
            ...values,
            followUpDate: convertInputDateToServerDateOnly(values?.followUpDate),
          } as ClientService.PersonalDetailsDto).catch(() => setLoading(false));
      setLoading(false);

      if (response?.result === ClientService.Result.Successful) {
        onSuccess(response.returnId);
        closeModal();
      } else {
        genericMessage.error({}, response?.messages?.map((item) => item?.body)?.join(' '));
        if (onFailure) onFailure();
      }
    },
    [closeModal, form]
  );

  const handleSaveNewProspect = useCallback(
    (isContinue?: boolean) => {
      return isContinue
        ? requestCreatePersonalDetails({ onSuccess: navigateToNextStep })
        : showModal(
            <FollowUpModal
              onSave={(followUpDate?: moment.Moment, onSaveFailure?: () => void) =>
                requestCreatePersonalDetails({
                  followUpDate,
                  onSuccess: navigateToDebtorSearch,
                  onFailure: onSaveFailure,
                })
              }
              onCancel={closeModal}
              shouldDisableOnSave
            />
          );
    },
    [closeModal, navigateToDebtorSearch, navigateToNextStep, requestCreatePersonalDetails, showModal]
  );

  const handleDuplicatesCheck = useCallback(
    async (isContinue: boolean) => {
      const duplicates = await requestGetDuplicates();

      if (!duplicates) return;

      if (!duplicates?.length) {
        handleSaveNewProspect(isContinue);
      }

      if (duplicates.length) {
        showModal(
          <SimilarRecordsModal
            onError={() => {
              showModal(<GenericErrorModal onCancel={closeModal} message={t.NEW_PROSPECT_SIMILAR_RECORDS_MESSAGE} />);
            }}
            onSaveAsNew={() => handleSaveNewProspect(isContinue)}
            duplicates={duplicates}
            onCancel={closeModal}
          />
        );
      }
    },
    [requestGetDuplicates, closeModal, handleSaveNewProspect, showModal, t.NEW_PROSPECT_SIMILAR_RECORDS_MESSAGE]
  );

  const handleNoAddressConfirmation = useCallback(
    (isContinue: boolean) => {
      showModal(
        <ActionConfirmationModal
          title={t.CONFIRMATION}
          message={t.NEW_PROSPECT_EMPTY_EMAIL_MESSAGE}
          onOk={() => {
            closeModal();
            handleDuplicatesCheck(isContinue);
          }}
          onCancel={closeModal}
        />
      );
    },
    [showModal, t.CONFIRMATION, t.NEW_PROSPECT_EMPTY_EMAIL_MESSAGE, closeModal, handleDuplicatesCheck]
  );

  const handleSaveAndContinue = useCallback(async () => {
    form.validateFields().then((values) => {
      if (!values?.emailAddress) {
        handleNoAddressConfirmation(true);
      } else {
        handleDuplicatesCheck(true);
      }
    });
  }, [handleDuplicatesCheck, form, handleNoAddressConfirmation]);

  const handleSaveAndClose = useCallback(() => {
    form.validateFields().then((values) => {
      if (!values?.emailAddress) {
        handleNoAddressConfirmation(false);
      } else {
        handleDuplicatesCheck(false);
      }
    });
  }, [handleDuplicatesCheck, form, handleNoAddressConfirmation]);

  const handleCancelNewProspect = useCallback(() => {
    const values = form.getFieldsValue();
    if (
      values?.firstName ||
      values?.middleName ||
      values?.lastName ||
      values?.mainPhoneNumber ||
      values?.emailAddress
    ) {
      showModal(
        <ActionConfirmationModal
          title={t.CANCEL_NEW_PROSPECT}
          message={t.NEW_PROSPECT_CANCEL_MESSAGE}
          okText={t.STAY}
          onOk={closeModal}
          cancelText={t.LEAVE}
          onCancel={() => {
            closeModal();
            navigate(ROUTES.DASHBOARD);
          }}
        />
      );
    } else {
      navigate(ROUTES.DASHBOARD);
    }
  }, [form, showModal, t.CANCEL_NEW_PROSPECT, t.NEW_PROSPECT_CANCEL_MESSAGE, t.STAY, t.LEAVE, closeModal, navigate]);

  useEffect(() => {
    if (!phoneTypes) requestPhoneTypes();
  }, [phoneTypes, requestPhoneTypes]);

  return (
    <Layout page={ROUTES.NEW_PROSPECT}>
      <PageTitleContainer title={t.NEW_PROSPECT} hasToolbar={false} />
      <Breadcrumb data={[{ link: ROUTES.DASHBOARD, title: t.DASHBOARD }, { title: t.NEW_PROSPECT }]} />

      <Spin spinning={loading}>
        <Form form={form} layout="vertical" className="NewProspectPage">
          <Row gutter={20}>
            <Col span={8}>
              <Form.Item
                name="firstName"
                label={t.NEW_PROSPECT_FIRST_NAME}
                rules={[{ required: true, message: `${t.NEW_PROSPECT_FIRST_NAME} ${t.IS_REQUIRED}!` }]}
              >
                <Input size="large" maxLength={100} />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item name="middleName" label={t.NEW_PROSPECT_MIDDLE_NAME}>
                <Input size="large" maxLength={100} />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                name="lastName"
                label={t.NEW_PROSPECT_LAST_NAME}
                rules={[{ required: true, message: `${t.NEW_PROSPECT_LAST_NAME} ${t.IS_REQUIRED}!` }]}
              >
                <Input size="large" maxLength={100} />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={20} align="middle">
            <Col span={8}>
              <Row gutter={10} wrap={false}>
                <Col span={8}>
                  <Form.Item
                    name="mainPhoneTypeId"
                    label={t.NEW_PROSPECT_MAIN_PHONE}
                    rules={[{ required: true, message: `${t.NEW_PROSPECT_MAIN_PHONE} ${t.IS_REQUIRED}!` }]}
                  >
                    <SelectLookupDto
                      data={
                        phoneTypes?.filter(
                          (item) => item?.enumValue !== ClientService.PhoneTypeEnum.Fax
                        ) as ClientService.LookupDto[]
                      }
                    />
                  </Form.Item>
                </Col>
                <Col span={16}>
                  <Form.Item
                    name="mainPhoneNumber"
                    label=" "
                    rules={[
                      {
                        validator: (_, value) => {
                          if (!value || value === EMPTY_PHONE_MASK) {
                            return Promise.reject(t.PHONE_NUMBER_REQUIRED_ERROR);
                          }
                          if (!/^\(\d{3}\) \d{3}-\d{4}$/.test(value)) {
                            return Promise.reject(t.PHONE_NUMBER_INCORRECT_ERROR);
                          }
                          return Promise.resolve();
                        },
                      },
                    ]}
                  >
                    <InputMask mask={PHONE_MASK}>
                      <Input size="large" />
                    </InputMask>
                  </Form.Item>
                </Col>
              </Row>
            </Col>

            <Col span={8}>
              <Form.Item
                name="emailAddress"
                label={t.NEW_PROSPECT_EMAIL_ADDRESS}
                rules={[{ type: 'email', message: `${t.NEW_PROSPECT_EMAIL_ADDRESS} ${t.IS_REQUIRED}!` }]}
              >
                <Input size="large" maxLength={100} />
              </Form.Item>
            </Col>
          </Row>

          <Row align="middle" justify="end" gutter={10} style={{ paddingTop: 24 }}>
            <Col>
              <Button kind="cancel" onClick={handleCancelNewProspect}>
                {t.NEW_PROSPECT_CANCEL}
              </Button>
            </Col>
            <Col>
              <Button onClick={handleSaveAndClose} kind="primary" iconName="SaveAndClose">
                {t.NEW_PROSPECT_SAVE_AND_CLOSE}
              </Button>
            </Col>
            <Col>
              <Button onClick={handleSaveAndContinue} kind="primary" iconName="Save">
                {t.NEW_PROSPECT_SAVE_AND_CONTINUE}
              </Button>
            </Col>
          </Row>
        </Form>
      </Spin>
    </Layout>
  );
};

export default NewProspectPage;
