export const IDENTITY_CONFIG = {
  authority: process.env.REACT_APP_AUTH_URL,
  client_id: process.env.REACT_APP_IDENTITY_CLIENT_ID,
  redirect_uri: process.env.REACT_APP_REDIRECT_URL,
  silent_redirect_uri: process.env.REACT_APP_SILENT_REDIRECT_URL,
  post_logout_redirect_uri: process.env.REACT_APP_LOGOFF_REDIRECT_URL,
  audience: process.env.REACT_APP_AUDIENCE,
  response_type: 'code',
  automaticSilentRenew: true,
  revokeAccessTokenOnSignout: true,
  loadUserInfo: true,
  scope: process.env.REACT_APP_SCOPE,
};

export const METADATA_OIDC = {
  issuer: process.env.REACT_APP_ISSUER,
  jwks_uri: process.env.REACT_APP_AUTH_URL + '/.well-known/openid-configuration/jwks',
  authorization_endpoint: process.env.REACT_APP_AUTH_URL + '/connect/authorize',
  token_endpoint: process.env.REACT_APP_AUTH_URL + '/connect/token',
  userinfo_endpoint: process.env.REACT_APP_AUTH_URL + '/connect/userinfo',
  end_session_endpoint: process.env.REACT_APP_AUTH_URL + '/connect/logout',
  check_session_iframe: process.env.REACT_APP_AUTH_URL + '/connect/checksession',
  revocation_endpoint: process.env.REACT_APP_AUTH_URL + '/connect/revocat',
  introspection_endpoint: process.env.REACT_APP_AUTH_URL + '/connect/introspect',
};

export const QueryParameterNames = {
  ReturnUrl: 'returnUrl',
  Message: 'message',
};

export const LoginActions = {
  Login: 'login',
  LoginCallback: 'login-callback',
  LoginFailed: 'login-failed',
  Profile: 'profile',
  Register: 'register',
};

export const LogoutActions = {
  LogoutCallback: 'logout-callback',
  Logout: 'logout',
  LoggedOut: 'logged-out',
};

export const ApplicationPaths = {
  DefaultLoginRedirectPath: '/',
  //ApiAuthorizationClientConfigurationUrl: `/_configuration/${ApplicationName}`,
  //ApiAuthorizationPrefix: prefix,
  Login: `${process.env.REACT_APP_REDIRECT_URL}`, //`${prefix}/${LoginActions.Login}`,
  LoginFailed: `${LoginActions.LoginFailed}`,
  LoginCallback: `${LoginActions.LoginCallback}`,
  Register: `${LoginActions.Register}`,
  Profile: `${LoginActions.Profile}`,
  LogOut: `${LogoutActions.Logout}`,
  LoggedOut: `${LogoutActions.LoggedOut}`,
  LogOutCallback: `${LogoutActions.LogoutCallback}`,
  //IdentityRegisterPath: '/Identity/Account/Register',
  //IdentityManagePath: '/Identity/Account/Manage'
};
