import { useMemo, useState } from 'react';
import { Input, Form, Row, Col, Typography, Checkbox } from 'antd';
import { Icon } from '@fluentui/react/lib/Icon';
import update from 'immutability-helper';

import FoldersWithLoad from '../FoldersWithLoad/FoldersWithLoad';
import PrefixFormItem from '../PrefixesWithLoad/PrefixFormItem';
import SelectLookupDto from '../../SelectLookupDto/SelectLookupDto';

import useLocale from '../../../hooks/useLocale';
import { readableBytes, convertUndefinedToTrue, getColumnWidth, getNameColumnWidth } from '../utils';
import { NewSupportingFileRowLayoutProps, FilesColumnsProps, OverrideInfo } from '../types';
import { ClientService } from '../../../shared/api/ClientService';

import '../AttachmentsTab.scss';

interface IProps {
  remove: (rowName: number) => void;
  rowName: number;
  prefixCodes?: (string | undefined)[];
  newSupportingFileRowLayoutProps?: NewSupportingFileRowLayoutProps;
  columnsProps?: FilesColumnsProps;
  businessNames?: (string | undefined)[];
  supportingFolderCode?: string | undefined;
}

const NewSupportingFileRow = ({
  remove,
  rowName,
  prefixCodes,
  newSupportingFileRowLayoutProps,
  columnsProps,
  businessNames,
  supportingFolderCode,
}: IProps) => {
  const { t, getLocalizedDocumentName } = useLocale();
  const { Text } = Typography;
  const [lastPrefixId, setLastPrefixId] = useState('');

  const columnWidth = useMemo(() => getColumnWidth(columnsProps), [columnsProps]);
  const businessSelectData = useMemo(
    () => businessNames?.map((item) => ({ name: item, id: item } as ClientService.LookupDto)),
    [businessNames]
  );

  const disableOverrideCheckbox = (selectedPrefixId: string, overrideData: OverrideInfo[]) => {
    const newOverride: OverrideInfo = {
      isDisabled: true,
      isOverridden: false,
      overrideReason: '',
      documentPrefixId: selectedPrefixId,
    };
    if (lastPrefixId && lastPrefixId != '') {
      console.log('enable the override checkbox', lastPrefixId);
      const lastIndex = overrideData.findIndex((item) => item.documentPrefixId === lastPrefixId);
      const enabledOverride: OverrideInfo = {
        isDisabled: false,
        isOverridden: false,
        overrideReason: '',
        documentPrefixId: lastPrefixId,
      };
      overrideData[lastIndex] = enabledOverride;
    }
    if (!overrideData) {
      setLastPrefixId(selectedPrefixId);
      return [newOverride];
    } else {
      const index = overrideData.findIndex((item) => item.documentPrefixId === newOverride.documentPrefixId);

      if (index !== -1) {
        // Update existing item
        const updatedData = [...overrideData];
        if (!updatedData[index].isDisabled) {
          setLastPrefixId(selectedPrefixId);
        }
        updatedData[index] = newOverride;
        return updatedData;
      } else {
        // Add new item
        setLastPrefixId(selectedPrefixId);
        const existingData = [...overrideData];
        existingData.push(newOverride);
        return existingData;
      }
    }
  };

  return (
    <Form.Item noStyle shouldUpdate>
      {({ getFieldValue, setFieldsValue, getFieldsValue }) => {
        return (
          <Row gutter={20} align="middle" key={`new-file-${rowName}`}>
            <Col span={getNameColumnWidth(columnsProps)} className="AttachmentsTab__file-name">
              <Form.Item label={newSupportingFileRowLayoutProps?.hasLabels && ' '}>
                <Text>{getLocalizedDocumentName(getFieldValue(['newFiles', rowName]))}</Text>
              </Form.Item>
            </Col>

            <Col span={columnsProps?.hasForSpouseColumn ? 2 : 0}>
              <Form.Item name={[rowName, 'isForSpouse']} label={t.DOCUMENTS_FOR_SPOUSE} valuePropName="checked">
                <Checkbox
                  disabled={newSupportingFileRowLayoutProps?.disabledForSpouseInput}
                  onChange={(e) => {
                    setFieldsValue(
                      update(getFieldsValue(), {
                        newFiles: {
                          [rowName]: { $merge: { isForSpouse: e?.target?.checked } },
                        },
                      })
                    );
                  }}
                />
              </Form.Item>
            </Col>

            <Col span={columnsProps?.hasSizeColumn ? 2 : 0}>
              <Form.Item label={newSupportingFileRowLayoutProps?.hasLabels && t.DOCUMENTS_FILE_SIZE}>
                <Text>{readableBytes(getFieldValue(['newFiles', rowName, 'size']))}</Text>
              </Form.Item>
            </Col>

            {convertUndefinedToTrue(columnsProps?.hasFolderColumn) && (
              <Col span={columnWidth}>
                <Form.Item
                  label={newSupportingFileRowLayoutProps?.hasLabels && t.DOCUMENTS_FOLDER}
                  rules={[{ required: true, message: t.FOLDER_IS_REQUIRED }]}
                  name={[rowName, 'documentFolderId']}
                >
                  <FoldersWithLoad
                    onSelect={() =>
                      setFieldsValue(
                        update(getFieldsValue(), {
                          newFiles: {
                            [rowName]: { $merge: { documentPrefixId: undefined } },
                          },
                        })
                      )
                    }
                    disabled={newSupportingFileRowLayoutProps?.disabledFoldersInput}
                  />
                </Form.Item>
              </Col>
            )}

            {convertUndefinedToTrue(columnsProps?.hasPrefixColumn) && (
              <Col span={columnWidth}>
                <PrefixFormItem
                  rowName={rowName}
                  documentFolderId={getFieldValue(['newFiles', rowName, 'documentFolderId'])}
                  prefixCodes={prefixCodes}
                  overrideData={getFieldValue('SupportingDocOverrides') as OverrideInfo[]}
                  onSelectedPrefixChanged={() => {
                    if (supportingFolderCode == 'assets') {
                      const updatedOverrideInfo = disableOverrideCheckbox(
                        getFieldValue(['newFiles', rowName, 'documentPrefixId']),
                        getFieldValue('SupportingDocOverrides') as OverrideInfo[]
                      );
                      setFieldsValue({ SupportingDocOverrides: updatedOverrideInfo });
                    }
                  }}
                  onDataFetch={(documentPrefixId) => {
                    if (documentPrefixId && newSupportingFileRowLayoutProps?.preselectedPrefixInput) {
                      setFieldsValue(
                        update(getFieldsValue(), {
                          newFiles: {
                            [rowName]: { documentPrefixId: { $set: documentPrefixId } },
                          },
                        })
                      );
                    }
                  }}
                  {...newSupportingFileRowLayoutProps}
                />
              </Col>
            )}
            {columnsProps?.hasBusinessNameColumn && (
              <Col span={columnWidth}>
                <Form.Item
                  label={t.BUSINESS}
                  rules={[{ required: true, message: t.BUSINESS_REQUIRED }]}
                  name={[rowName, 'businessName']}
                >
                  <SelectLookupDto
                    data={businessSelectData}
                    disabled={newSupportingFileRowLayoutProps?.disabledBusinessInput}
                  />
                </Form.Item>
              </Col>
            )}
            <Col span={convertUndefinedToTrue(columnsProps?.hasAdditionalInfoColumn) ? columnWidth : 0}>
              <Form.Item
                name={[rowName, 'additionalInformation']}
                label={newSupportingFileRowLayoutProps?.hasLabels && t.DOCUMENTS_ADDITIONAL_INFORMATION}
              >
                <Input size="large" />
              </Form.Item>
            </Col>

            <Col span={1}>
              {convertUndefinedToTrue(columnsProps?.hasDeleteButton) && (
                <Form.Item label={newSupportingFileRowLayoutProps?.hasLabels && ' '}>
                  <Icon iconName="Delete" onClick={() => remove(rowName)} className="AttachmentsTab__icon" />
                </Form.Item>
              )}
            </Col>
          </Row>
        );
      }}
    </Form.Item>
  );
};

export default NewSupportingFileRow;
