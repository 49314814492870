import { useState, useCallback, useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import API from '../../../../utils/api';

import Button from '../../../../components/Button/Button';

import useLocale from '../../../../hooks/useLocale';
import useModal from '../../../../hooks/useModal';

import { ROUTES } from '../../../../constants/routes';
import { ADMIN_INFO_TAB } from '../../../ApplicationOverviewPage/ApplicationOverviewContent/ApplicationOverviewContent';
import {
  DISCHARGE_TAB,
  RECEIPTS_AND_DISBURSEMENTS_TAB,
  BANKING_TAB,
  PROPOSAL_TAB,
  TAX_PROCESS_TAB,
} from '../../../ApplicationOverviewPage/ApplicationOverviewContent/AdminInfo/AdminInfoTab';
import { ClientService } from '../../../../shared/api/ClientService';
import { getEmptyGuid, isEmptyGuid } from '../../../../utils/helpers';

interface IProps {
  data?: ClientService.IAppTaskActionDto;
  applicationFileId?: string;
  appTaskActionNames?: ClientService.LookupDto[];
}

enum IExtraButtonTypeEnum {
  Claims = 0,
  IandE = 1,
  IandEUpload = 2,
  TaxReturn = 3,
  TaxReturnUpload = 4,
  Discharge = 5,
  RandD = 6,
  Banking = 7,
  Proposal = 8,
}

const ExtraFooterButton = ({ data, applicationFileId, appTaskActionNames }: IProps) => {
  const navigate = useNavigate();
  const { t } = useLocale();
  const { closeModal, modal } = useModal();

  const [buttonTypeEnum, setButtonTypeEnum] = useState<IExtraButtonTypeEnum>();

  const getExtraButtonEnum = useCallback((appActionNameId: string, appTaskActionNames: ClientService.LookupDto[]) => {
    const appTaskNameEnum = appTaskActionNames?.find((item) => item?.id === appActionNameId)?.enumValue;
    if (
      appTaskNameEnum === ClientService.AppTaskActionNameEnum.ClaimReceived ||
      appTaskNameEnum === ClientService.AppTaskActionNameEnum.SecuredClaim ||
      appTaskNameEnum === ClientService.AppTaskActionNameEnum.UnsecuredClaim
    ) {
      return IExtraButtonTypeEnum.Claims;
    }

    if (appTaskNameEnum === ClientService.AppTaskActionNameEnum.NewIandESubmission) {
      return IExtraButtonTypeEnum.IandE;
    }

    if (appTaskNameEnum === ClientService.AppTaskActionNameEnum.NewIandEUpload) {
      return IExtraButtonTypeEnum.IandEUpload;
    }

    if (appTaskNameEnum === ClientService.AppTaskActionNameEnum.NewTaxReturnSubmission) {
      return IExtraButtonTypeEnum.TaxReturn;
    }

    if (appTaskNameEnum === ClientService.AppTaskActionNameEnum.NewTaxReturnUpload) {
      return IExtraButtonTypeEnum.TaxReturnUpload;
    }

    if (
      appTaskNameEnum === ClientService.AppTaskActionNameEnum.MeetingWithCreditorsAccepted ||
      appTaskNameEnum === ClientService.AppTaskActionNameEnum.Process170Pending ||
      appTaskNameEnum === ClientService.AppTaskActionNameEnum.OppositionResponseReceived ||
      appTaskNameEnum === ClientService.AppTaskActionNameEnum.WithdrawOpposition ||
      appTaskNameEnum === ClientService.AppTaskActionNameEnum.CourtAppearanceAccepted ||
      appTaskNameEnum === ClientService.AppTaskActionNameEnum.CourtAppearanceRejected ||
      appTaskNameEnum === ClientService.AppTaskActionNameEnum.CourtDischargePending ||
      appTaskNameEnum === ClientService.AppTaskActionNameEnum.PostMediationMonitor ||
      appTaskNameEnum === ClientService.AppTaskActionNameEnum.MediationFailed ||
      appTaskNameEnum === ClientService.AppTaskActionNameEnum.ComplianceReview ||
      appTaskNameEnum === ClientService.AppTaskActionNameEnum.CourtAppearanceRequested
    ) {
      return IExtraButtonTypeEnum.Discharge;
    }

    if (
      appTaskNameEnum === ClientService.AppTaskActionNameEnum.InterimDividendScheduled ||
      appTaskNameEnum === ClientService.AppTaskActionNameEnum.ProcessDividendPayment ||
      appTaskNameEnum === ClientService.AppTaskActionNameEnum.CertificateofFullPerformance ||
      appTaskNameEnum === ClientService.AppTaskActionNameEnum.SrdCompleted ||
      appTaskNameEnum === ClientService.AppTaskActionNameEnum.TrusteeDischargePending ||
      appTaskNameEnum === ClientService.AppTaskActionNameEnum.DividendChecklistApproved ||
      appTaskNameEnum === ClientService.AppTaskActionNameEnum.DividendChecklistRejected ||
      appTaskNameEnum === ClientService.AppTaskActionNameEnum.DividendChecklistSigned ||
      appTaskNameEnum === ClientService.AppTaskActionNameEnum.DividendChecklistPendngReview ||
      appTaskNameEnum === ClientService.AppTaskActionNameEnum.SrdChecklistApproved_Proposal ||
      appTaskNameEnum === ClientService.AppTaskActionNameEnum.SrdChecklistRejected_Proposal ||
      appTaskNameEnum === ClientService.AppTaskActionNameEnum.SrdChecklistSigned_Proposal ||
      appTaskNameEnum === ClientService.AppTaskActionNameEnum.SrdChecklistPendingReview_Proposal ||
      appTaskNameEnum === ClientService.AppTaskActionNameEnum.SrdChecklistApproved_Bankruptcy ||
      appTaskNameEnum === ClientService.AppTaskActionNameEnum.SrdChecklistRejected_Bankruptcy ||
      appTaskNameEnum === ClientService.AppTaskActionNameEnum.SrdChecklistSigned_Bankruptcy ||
      appTaskNameEnum === ClientService.AppTaskActionNameEnum.SrdChecklistPendingReview_Bankruptcy
    ) {
      return IExtraButtonTypeEnum.RandD;
    }

    if (
      appTaskNameEnum === ClientService.AppTaskActionNameEnum.PADInfoUpdateRequest ||
      appTaskNameEnum === ClientService.AppTaskActionNameEnum.ChequePadHoldRequest ||
      appTaskNameEnum === ClientService.AppTaskActionNameEnum.ChequePADHoldNotification ||
      appTaskNameEnum === ClientService.AppTaskActionNameEnum.PadInfoUpdateNotification ||
      appTaskNameEnum === ClientService.AppTaskActionNameEnum.HoldRequestedReminder ||
      appTaskNameEnum === ClientService.AppTaskActionNameEnum.OnHoldReminder
    ) {
      return IExtraButtonTypeEnum.Banking;
    }

    if (appTaskNameEnum === ClientService.AppTaskActionNameEnum.ProposalAccepted) {
      return IExtraButtonTypeEnum.Proposal;
    }
  }, []);

  const extraButtonDetails = useMemo(() => {
    switch (buttonTypeEnum) {
      case IExtraButtonTypeEnum.Claims:
        return {
          title: t.TASK_ACTION_BTN_CLAIM,
          action: data?.linkedClaimFileId
            ? async () => {
                let fileId =
                  data?.searchedFileId && !isEmptyGuid(data?.fileId)
                    ? data?.searchedFileId
                    : (await API.claimFileGET(data?.linkedClaimFileId || getEmptyGuid()))?.fileId;

                navigate(`${ROUTES.APPLICATION_OVERVIEW}/${fileId}/${ROUTES.CLAIMS}/${data?.linkedClaimFileId}`);
              }
            : () => navigate(`${ROUTES.TASK_ACTIONS}/${ROUTES.NEW_CLAIM}/${data?.id}`),
        };

      case IExtraButtonTypeEnum.IandE:
        return {
          title: data?.linkedIncomeExpenseSubmissionId ? t.TASK_ACTION_BTN_VIEW_IE : t.TASK_ACTION_BTN_NEW_IE,
          action: data?.linkedIncomeExpenseSubmissionId
            ? async () => {
                let fileId =
                  data?.searchedFileId && !isEmptyGuid(data?.fileId)
                    ? data?.searchedFileId
                    : (
                        await API.submissionsGET(
                          getEmptyGuid(),
                          data?.linkedIncomeExpenseSubmissionId || getEmptyGuid()
                        )
                      )?.fileId;

                navigate(
                  `${ROUTES.APPLICATION_OVERVIEW}/${fileId}/${ROUTES.INCOME_AND_EXPENSE}/${data?.linkedIncomeExpenseSubmissionId}`
                );
              }
            : () => navigate(`${ROUTES.TASK_ACTIONS}/${ROUTES.NEW_INCOME_EXPENSE}/${data?.id}`),
        };

      case IExtraButtonTypeEnum.IandEUpload:
        return {
          title: t.TASK_ACTION_BTN_VIEW_IE,
          action: async () => {
            let fileId =
              data?.searchedFileId && !isEmptyGuid(data?.fileId)
                ? data?.searchedFileId
                : (await API.submissionsGET(getEmptyGuid(), data?.linkedIncomeExpenseSubmissionId || getEmptyGuid()))
                    ?.fileId;

            navigate(
              `${ROUTES.APPLICATION_OVERVIEW}/${fileId}/${ROUTES.INCOME_AND_EXPENSE}/${data?.linkedIncomeExpenseSubmissionId}`
            );
          },
        };
      case IExtraButtonTypeEnum.TaxReturn:
        const handleViewTaxReturn = async () => {
          const firstLinkedTaxReturnSubmissionId = data?.linkedTaxReturnSubmissionIds?.[0];
          const taxReturnSubmission = await API.taxReturnSubmissionGET(
            firstLinkedTaxReturnSubmissionId || getEmptyGuid()
          );
          const fileId = taxReturnSubmission?.fileId ?? data?.searchedFileId;

          navigate(`${ROUTES.APPLICATION_OVERVIEW}/${fileId}`, {
            state: { preselectedTab: ADMIN_INFO_TAB, preselectedAdminInfoSubTab: TAX_PROCESS_TAB },
          });
        };

        return {
          title: data?.linkedTaxReturnSubmissionIds
            ? t.TASK_ACTION_BTN_VIEW_TAX_RETURN
            : t.TASK_ACTION_BTN_NEW_TAX_RETURN,
          action: data?.linkedTaxReturnSubmissionIds
            ? handleViewTaxReturn
            : () => navigate(`${ROUTES.TASK_ACTIONS}/${ROUTES.NEW_TAX_RETURN}/${data?.id}`),
        };
      case IExtraButtonTypeEnum.TaxReturnUpload:
        return {
          title: t.TASK_ACTION_BTN_VIEW_TAX_RETURN,
          action: async () => {
            let firstLinkedTaxReturnSubmissionId =
              data?.linkedTaxReturnSubmissionIds && data?.linkedTaxReturnSubmissionIds[0];
            let fileId =
              data?.searchedFileId && !isEmptyGuid(data?.fileId)
                ? data?.searchedFileId
                : (await API.taxReturnSubmissionGET(firstLinkedTaxReturnSubmissionId || getEmptyGuid()))?.fileId;

            navigate(`${ROUTES.APPLICATION_OVERVIEW}/${fileId}`, {
              state: { preselectedTab: ADMIN_INFO_TAB, preselectedAdminInfoSubTab: TAX_PROCESS_TAB },
            });
          },
        };
      case IExtraButtonTypeEnum.Discharge:
        return {
          title: t.TASK_ACTION_BTN_DISCHARGE,
          action: () =>
            navigate(`${ROUTES.APPLICATION_OVERVIEW}/${applicationFileId}`, {
              state: { preselectedTab: ADMIN_INFO_TAB, preselectedAdminInfoSubTab: DISCHARGE_TAB },
            }),
        };

      case IExtraButtonTypeEnum.RandD:
        return {
          title: t.TASK_ACTION_BTN_VIEW_RND,
          action: () =>
            navigate(`${ROUTES.APPLICATION_OVERVIEW}/${applicationFileId}`, {
              state: { preselectedTab: ADMIN_INFO_TAB, preselectedAdminInfoSubTab: RECEIPTS_AND_DISBURSEMENTS_TAB },
            }),
        };

      case IExtraButtonTypeEnum.Banking:
        return {
          title: t.TASK_ACTION_BTN_BANKING,
          action: () =>
            navigate(`${ROUTES.APPLICATION_OVERVIEW}/${applicationFileId}`, {
              state: { preselectedTab: ADMIN_INFO_TAB, preselectedAdminInfoSubTab: BANKING_TAB },
            }),
        };

      case IExtraButtonTypeEnum.Proposal:
        return {
          title: t.TASK_ACTION_BTN_PROPOSAL,
          action: () =>
            navigate(`${ROUTES.APPLICATION_OVERVIEW}/${applicationFileId}`, {
              state: { preselectedTab: ADMIN_INFO_TAB, preselectedAdminInfoSubTab: PROPOSAL_TAB },
            }),
        };

      default:
        return undefined;
    }
  }, [
    applicationFileId,
    buttonTypeEnum,
    data?.fileId,
    data?.id,
    data?.linkedClaimFileId,
    data?.linkedIncomeExpenseSubmissionId,
    data?.linkedTaxReturnSubmissionIds,
    data?.searchedFileId,
    navigate,
    t.TASK_ACTION_BTN_BANKING,
    t.TASK_ACTION_BTN_CLAIM,
    t.TASK_ACTION_BTN_DISCHARGE,
    t.TASK_ACTION_BTN_NEW_IE,
    t.TASK_ACTION_BTN_NEW_TAX_RETURN,
    t.TASK_ACTION_BTN_PROPOSAL,
    t.TASK_ACTION_BTN_VIEW_IE,
    t.TASK_ACTION_BTN_VIEW_RND,
    t.TASK_ACTION_BTN_VIEW_TAX_RETURN,
  ]);

  useEffect(() => {
    if (data?.appActionNameId && appTaskActionNames)
      setButtonTypeEnum(getExtraButtonEnum(data?.appActionNameId, appTaskActionNames));
  }, [data?.appActionNameId, appTaskActionNames, getExtraButtonEnum]);

  return (
    <Button
      kind="primary"
      padding="large"
      onClick={() => {
        if (extraButtonDetails?.action) extraButtonDetails?.action();
        if (modal) closeModal();
      }}
    >
      {extraButtonDetails?.title}
    </Button>
  );
};

export default ExtraFooterButton;
