import { useState, useCallback, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import moment from 'moment';
import { Empty } from 'antd';
import Layout from '../../components/Layout/Layout';
import PageTitle from '../../components/PageTitle/PageTitle';
import NewAndViewHeader from '../ApplicationOverviewPage/NewAndViewPageComponents/NewAndViewHeader/NewAndViewHeader';
import { ROUTES } from '../../constants/routes';
import useLocale from '../../hooks/useLocale';
import AppForm from '../../components/Forms/AppForm/AppForm';
import SearchAscend from '../ApplicationOverviewPage/NewAndViewPageComponents/SearchAscend/SearchAscend';
import NewIncomeAndExpenseFields from '../ApplicationOverviewPage/NewAndViewPageComponents/SearchAscend/VariableFieldGroups/NewIncomeAndExpenseFields';
import EmailContentDisplay, {
  TEmailData,
} from '../ApplicationOverviewPage/NewAndViewPageComponents/EmailContentDisplay/EmailContentDisplay';
import DataItem from '../../components/DataItem/DataItem';
import ThreeColumnLowerLayout from '../ApplicationOverviewPage/NewAndViewPageComponents/ThreeColumnLowerLayout/ThreeColumnLowerLayout';
import { ClientService } from '../../shared/api/ClientService';
import API from '../../utils/api';
import { ISearchAscendFileCriteria } from '../ApplicationOverviewPage/NewAndViewPageComponents/types';
import { argumentifyAscendFileSearchCriteria } from '../ApplicationOverviewPage/NewAndViewPageComponents/utils';
import AscendConfirmActionModal from '../ApplicationOverviewPage/NewAndViewPageComponents/SearchAscend/AscendConfirmActionModal/AscendConfirmActionModal';
import MarkAsActiveRadioSelection from '../ApplicationOverviewPage/NewAndViewPageComponents/SearchAscend/AscendConfirmActionModal/MarkAsActiveRadioSelection/MarkAsActiveRadioSelection';
import genericMessage from '../../utils/genericMessage';
import ErrorsContainer, { IErrorsMsgAndType } from '../../components/ErrorsContainer/ErrorsContainer';
import ButtonsGroupCancelDraftRedundant from '../ApplicationOverviewPage/NewAndViewPageComponents/NewAndViewHeader/HeaderButtonGroups/ButtonsGroupCancelDraftRedundant';
import useErrorHandling from '../../hooks/useErrorHandling';
import useAlertAsAsync from '../ApplicationOverviewPage/NewAndViewPageComponents/AlertModal/useAlertAsAsync';
import Loading from '../../components/Loading/Loading';
import {
  getUndefinedOrSelf,
  convertServerDateOnlyToInputDate,
  convertInputDateToServerDateOnly,
} from '../../utils/helpers';
// import { confirmPromiseTest } from '../../utils/APITestFunctions';

import styles from './NewIncomeAndExpensePage.module.scss';

interface AscendFormValues {
  ascendEstateNumber?: string;
  ascendSin?: string;
  ascendDebtorName?: string;
  ascendDateOfInsolvency?: moment.Moment;
  ascendNoOfHousehold?: string;
  ascendSelectIncomeAndExpense?: string;
}

const NewIncomeAndExpensePage = () => {
  const { t } = useLocale();
  const navigate = useNavigate();

  const { taskActionId } = useParams();

  // const taskActionId = taskActionId2 || '26346262-4BCD-F57A-F788-3A070667E54F';

  const [form] = AppForm.AntD.useForm<any>();

  const { processResponseForErrors } = useErrorHandling();
  const { handleAlert } = useAlertAsAsync();

  const [pageErrors, setPageErrors] = useState<IErrorsMsgAndType[] | undefined>();
  const [incomeAndExpenseResponse, setIncomeAndExpenseResponse] = useState<
    ClientService.AppTaskActionDto | undefined
  >();
  const [submissionResponseFailed, setSubmissionResponseFailed] = useState<boolean>(false);
  const [newIncomeExpenseFormValues, setNewIncomeExpenseFormValues] = useState<AscendFormValues | undefined>();
  const [initialFormValuesHaveBeenSet, setInitialFormValuesHaveBeenSet] = useState<boolean>(false);
  const [formIsLoading, setFormIsLoading] = useState<boolean>(true);
  const [emailInfo, setEmailInfo] = useState<TEmailData | undefined>();
  const [ascendSearchedFileId, setAscendSearchedFileId] = useState<string | undefined>();

  const [documentIds, setDocumentIds] = useState<string[] | undefined>();

  const [mainFormErrors, setMainFormErrors] = useState<IErrorsMsgAndType[] | undefined>();
  const [externalAscendErrors, setExternalAscendErrors] = useState<IErrorsMsgAndType[] | undefined>();

  const [threeColLayoutIsLoading, setThreeColLayoutIsLoading] = useState<boolean>(true);
  const [threeColErrors, setThreeColErrors] = useState<IErrorsMsgAndType[] | undefined>();

  useEffect(() => {
    if (!taskActionId) return;

    API.taskActionsGET2(taskActionId)
      .then((response) => {
        // console.log('fetched I&E data:', response);
        const responseErrors = processResponseForErrors(response);
        setPageErrors(responseErrors.messages);
        if (responseErrors.hasErrors) {
          setSubmissionResponseFailed(true);
          setFormIsLoading(false);
          return;
        }

        /*
          NOTE: IF THE TASK HAS ALREADY BEEN LINKED, THE PAGE SHOULD REDIRECT
          - CHECK IF response.linkedIncomeExpenseSubmissionId HAS A VALUE
          - IF SO, REDIRECT TO, I THINK, View Income and Expense Page
          - OTHERWISE...
        */

        setIncomeAndExpenseResponse(response);
      })
      .catch((error) => {
        console.error('did not fetch I&E data');
        setPageErrors([{ message: t.DATA_COULD_NOT_BE_RETRIEVED }]);
        setFormIsLoading(false);
        // console.error(error);
      });
  }, [taskActionId, processResponseForErrors, t.DATA_COULD_NOT_BE_RETRIEVED]);

  useEffect(() => {
    if (incomeAndExpenseResponse) {
      if (!emailInfo) {
        setEmailInfo({
          fromName: incomeAndExpenseResponse.importedEmailSenderName,
          fromAddress: incomeAndExpenseResponse.importedEmailSenderEmailAddress,
          toName: incomeAndExpenseResponse.importedEmailRecipientName,
          toAddress: incomeAndExpenseResponse.importedEmailRecipientEmailAddress,
          subjectField: incomeAndExpenseResponse.importedEmailSubject,
          bodyField: incomeAndExpenseResponse.importedEmailBody,
        });
      }

      if (!newIncomeExpenseFormValues) {
        setNewIncomeExpenseFormValues({
          ascendEstateNumber: getUndefinedOrSelf(incomeAndExpenseResponse.extractedEstateNumber),
          ascendSin: getUndefinedOrSelf(incomeAndExpenseResponse.extractedSINNumber),
          ascendDebtorName: getUndefinedOrSelf(incomeAndExpenseResponse.extractedDebtorName),
          ascendDateOfInsolvency: incomeAndExpenseResponse?.extractedInsolvencyDate
            ? convertServerDateOnlyToInputDate(moment(incomeAndExpenseResponse?.extractedInsolvencyDate))
            : undefined,
          ascendNoOfHousehold:
            incomeAndExpenseResponse.numberOfHouseHold == null
              ? undefined
              : String(incomeAndExpenseResponse.numberOfHouseHold),
          ascendSelectIncomeAndExpense: getUndefinedOrSelf(incomeAndExpenseResponse.selectedIncomeExpensePeriodId),
        });
      }

      if (!documentIds) {
        setDocumentIds(incomeAndExpenseResponse.attachedDocumentIds);
      }
    }
  }, [incomeAndExpenseResponse, emailInfo, newIncomeExpenseFormValues, documentIds]);

  useEffect(() => {
    if (!initialFormValuesHaveBeenSet && newIncomeExpenseFormValues) {
      // Set initial form values
      form.setFieldsValue(newIncomeExpenseFormValues);
      setInitialFormValuesHaveBeenSet(true);
      setFormIsLoading(false);
    }
  }, [initialFormValuesHaveBeenSet, newIncomeExpenseFormValues, form]);

  useEffect(() => {
    if (!incomeAndExpenseResponse || !emailInfo || !documentIds) return;

    setThreeColLayoutIsLoading(false);
  }, [incomeAndExpenseResponse, emailInfo, documentIds]);

  const handleSave = useCallback(
    async (withAlert = false, isRedundant = false) => {
      if (!taskActionId) return;

      setMainFormErrors(undefined);

      const formValues = form.getFieldsValue();

      let requestBody: ClientService.IAppTaskActionIncomeExpenseUpdateDto = {
        id: taskActionId,
        searchedFileId: getUndefinedOrSelf(ascendSearchedFileId),
        estateNumber: getUndefinedOrSelf(formValues.ascendEstateNumber),
        sin: getUndefinedOrSelf(formValues.ascendSin),
        debtorName: getUndefinedOrSelf(formValues.ascendDebtorName),
        dateOfInsolvency: convertInputDateToServerDateOnly(formValues.ascendDateOfInsolvency),
        numberOfHouseHold: formValues.ascendNoOfHousehold == null ? undefined : Number(formValues.ascendNoOfHousehold),
        selectedIncomeExpensePeriodId:
          formValues.ascendSelectIncomeAndExpense == null ? undefined : Number(formValues.ascendSelectIncomeAndExpense),
        isRedundant: isRedundant,
      };

      // console.log('requestBody:', requestBody);
      const request = new ClientService.AppTaskActionIncomeExpenseUpdateDto(requestBody);

      if (withAlert) {
        const alertBody = await handleAlert();
        // console.log('alertRequest:', alertBody);
        if (alertBody === false) return;
        const typedAlertBody = alertBody as ClientService.IAlertCreateDto;
        const alertRequest = new ClientService.AlertCreateDto(typedAlertBody);
        request.alerts = alertRequest;
      }
      // console.log('final save request:', request);

      API.updateIncomeExpense(request)
        .then((response: ClientService.SaveResult | ClientService.RemoteServiceErrorResponse) => {
          const responseErrors = processResponseForErrors(response);
          setMainFormErrors(responseErrors.messages);
          if (responseErrors.hasErrors) return;
          setTimeout(() => genericMessage.success(t.SUCCESSFULLY_SAVED), 500);
        })
        .catch((error) => {
          // const requestErrors = processResponseForErrors(error);
          // setMainFormErrors(requestErrors.messages);
          setMainFormErrors([{ message: t.FORM_GENERIC_ERROR_SUBMITTING }]);
        });
      if (isRedundant) {
        navigate(ROUTES.DASHBOARD);
      }
    },
    [
      taskActionId,
      form,
      ascendSearchedFileId,
      handleAlert,
      processResponseForErrors,
      t.SUCCESSFULLY_SAVED,
      t.FORM_GENERIC_ERROR_SUBMITTING,
      navigate,
    ]
  );

  return (
    <Layout page={ROUTES.INCOME_AND_EXPENSE}>
      <div className={styles.NewIncomeAndExpensePage}>
        {pageErrors && <ErrorsContainer errors={pageErrors} noTitle />}
        <PageTitle title={t.INCOMEEXPENSE_INCOME_EXPENSE_PROCESS} />
        <NewAndViewHeader
          errors={mainFormErrors}
          subtitle="new-income-and-expense"
          breadcrumbData={[{ link: ROUTES.DASHBOARD, title: t.DASHBOARD }, { title: t.NEW_INCOME_EXPENSE }]}
          buttons={
            <ButtonsGroupCancelDraftRedundant
              buttonsEnabled={initialFormValuesHaveBeenSet}
              onSaveAsDraft={() => handleSave()}
              onSaveAsDraftWithAlert={() => handleSave(true)}
              onRedundant={() => handleSave(false, true)}
            />
          }
        />
        <AppForm form={form} isLoading={formIsLoading}>
          {externalAscendErrors && <ErrorsContainer errors={externalAscendErrors} noTitle />}
          <SearchAscend
            form={form}
            initialValues={newIncomeExpenseFormValues}
            variableFieldsComponent={<NewIncomeAndExpenseFields />}
            numberOfVariableFieldsForSearch={0}
            showVariableFieldsWhen="on-match"
            apiSearch={(ascendSearchValues) => {
              const args: ISearchAscendFileCriteria = {
                estateNumber: ascendSearchValues.ascendEstateNumber,
                sIN: ascendSearchValues.ascendSin,
                debtorName: ascendSearchValues.ascendDebtorName,
                dateOfInsolvency: ascendSearchValues?.ascendDateOfInsolvency
                  ? convertInputDateToServerDateOnly(moment(ascendSearchValues?.ascendDateOfInsolvency))
                  : undefined,
              };
              return API.search(...argumentifyAscendFileSearchCriteria(args));
            }}
            apiSetData={(responseData, setStateData) => {
              // console.log('searchAscend Response:', responseData);
              const updatedFormValues: AscendFormValues = {
                ascendEstateNumber: responseData.estateNumber,
                ascendSin: responseData.sin,
                ascendDebtorName: responseData.debtorName,
                ascendDateOfInsolvency: convertServerDateOnlyToInputDate(moment(responseData.insolvencyDate)),
              };
              setAscendSearchedFileId(responseData.fileId);
              setStateData((prev) => {
                const formValuesWithUpdates = {
                  ...prev,
                  ...updatedFormValues,
                };
                return formValuesWithUpdates;
              });
            }}
            confirmLinkMessage={t.ASCEND_ABOUT_TO_LINK_I_AND_E}
            apiConfirm={(ascendFormValues, ascendSearchResponse) => {
              // console.log('values passed to apiConfirm():', ascendFormValues);
              const confirmRequestBody: ClientService.IIncomeExpenseSubmissionLinkDto = {
                searchedFileId: ascendSearchResponse?.fileId as string,
                numberOfHousehold: Number(ascendFormValues.ascendNoOfHousehold),
                selectedIeScheduleId: ascendFormValues.ascendSelectIncomeAndExpense,
                result: 0,
                markAsActive: undefined,
                appTaskActionId: taskActionId,
              };
              const confirmRequest = new ClientService.IncomeExpenseSubmissionLinkDto(confirmRequestBody);
              return {
                apiCall: () => {
                  return API.linkTaskAction2(confirmRequest);
                },
                requestBody: confirmRequestBody,
              };
              /* TEST */
              // return {
              //   apiCall: () => {
              //     return confirmPromiseTest(confirmRequest);
              //   },
              //   requestBody: confirmRequest,
              // };
            }}
            onConfirm={(confirmResponse) => {
              //On confirm, navigate to the new income and expense page. The id of the new income and expense submission is returned in the confirmResponse
              //The application overview page uses the fileid of the current file.
              const incomeAndExpenseSumissionId = confirmResponse.returnId;
              navigate(
                `${ROUTES.APPLICATION_OVERVIEW}/${ascendSearchedFileId}/${ROUTES.INCOME_AND_EXPENSE}/${incomeAndExpenseSumissionId}`
              );
            }}
            confirmActionComponent={(
              confirmAttemptResponse,
              ascendFormValues,
              ascendSearchResponse,
              lastConfirmRequestBody,
              onConfirm
            ) => {
              return (
                <AscendConfirmActionModal
                  title={t.ASCEND_MARK_AS_ACTIVE_Q}
                  message={
                    confirmAttemptResponse.messages
                      ? confirmAttemptResponse.messages[0].body
                      : t.ASCEND_MARK_AS_ACTIVE_Q
                  }
                  onSave={(modalFormValues) => {
                    // console.log('modalFormValues passed to onSave():', modalFormValues);
                    // console.log('main form values:', form.getFieldsValue(true));
                    const updatedConfirmRequest = {
                      ...lastConfirmRequestBody,
                      markAsActive: modalFormValues.markAsActive,
                    };
                    // console.log('updatedConfirmRequest:', updatedConfirmRequest);

                    const finalConfirmRequest = new ClientService.IncomeExpenseSubmissionLinkDto(updatedConfirmRequest);

                    API.linkTaskAction2(finalConfirmRequest).then((response: any) => {
                      const responseErrors = processResponseForErrors(response);
                      setExternalAscendErrors(responseErrors.messages);
                      if (responseErrors.hasErrors) return;
                      if (onConfirm) onConfirm(response);
                    });

                    /* TEST */
                    // confirmPromiseTest(finalConfirmRequest, true).then((response: any) => {
                    //   if (onConfirm) onConfirm(response);
                    // });
                  }}
                >
                  <MarkAsActiveRadioSelection
                    name="markAsActive"
                    label={t.ASCEND_MARK_AS_ACTIVE_Q}
                    falseOption={t.ASCEND_OPTION_INACTIVE}
                  />
                </AscendConfirmActionModal>
              );
            }}
          />
          {threeColLayoutIsLoading ? (
            <Loading noText />
          ) : !threeColLayoutIsLoading && submissionResponseFailed ? (
            <div style={{ textAlign: 'center' }}>{t.DATA_COULD_NOT_BE_RETRIEVED}</div>
          ) : (
            <ThreeColumnLowerLayout
              errors={threeColErrors}
              leftColumnContent={
                <>
                  <DataItem label={t.INCOMEEXPENSE_INCOME_EXPENSE_INFO} value={' '} />
                  <div className={styles.label_n_img_wrapper}>
                    <Empty description="" />
                    <div className={styles.label}>{t.CONFIRM_FILE_IN_EXISTING_SYSTEM}</div>
                  </div>
                </>
              }
              filesList={documentIds}
              rightColumnContent={
                <EmailContentDisplay
                  title={t.INCOMEEXPENSE_INCOME_EXPENSE_EMAIL}
                  errorsSetter={setThreeColErrors}
                  {...emailInfo}
                />
              }
            />
          )}
        </AppForm>
      </div>
    </Layout>
  );
};

export default NewIncomeAndExpensePage;
