export const USE_QUERY_OPTIONS = {
  refetchOnWindowFocus: false,
  refetchOnMount: false,
};

export const FOLDERS_QUERY = 'folders';
export const PREFIXES_QUERY = 'prefixes';
export const AGENTS_QUERY = 'agents';
export const PROPOSAL_PROCESS_STATUSES_QUERY = 'proposal_process_statuses';
export const PROPOSAL_STATES_QUERY = 'proposal_states';
export const PROPOSAL_INFO_QUERY = 'proposal_info';
export const MEETING_TYPES_QUERY = 'meeting_types';
export const APPOINTMENT_STATUSES_QUERY = 'appointment_statuses';
export const OFFICE_LOCATIONS_QUERY = 'office_locations';
export const OFFICE_LOCATIONS_WITH_INACTIVE_QUERY = 'office_locations_with_inactive';
export const INCLUDE_INACTIVE = 'include_inactive';
export const EXCLUDE_INACTIVE = 'exclude_inactive';

export const APPOINTMENT_DETAILS_QUERY = 'appointment_details';
export const APPOINTMENT_TYPES_QUERY = 'appointment_types';
export const COUNSELLING_TYPES_QUERY = 'counselling_types';
export const DELIVERY_METHODS_QUERY = 'delivery_methods';
export const REGIONS_LOCATIONS_QUERY = 'regions_locations';
export const FORM56_HISTORY_QUERY = 'form56_history';
export const FORM93_HISTORY_QUERY = 'form93_history';
export const FORM95_HISTORY_QUERY = 'form95_history';
export const FORM96_HISTORY_QUERY = 'form96_history';
export const DOCUMENTS_QUERY = 'documents';
export const TASK_STATUSES_QUERY = 'task_statuses';
export const TASKS_QUERY = 'tasks';
export const NOTES_QUERY = 'notes';
export const APPOINTEMENTS_QUERY = 'appointments';
export const APPLICATION_FILE_QUERY = 'application_file';
export const REFERRAL_SOURCE_TYPES_QUERY = 'referral_source_types';
export const REFERRAL_SOURCE_DETAILS_QUERY = 'referral_source_details';
export const INTAKE_SOURCES_QUERY = 'intake_sources';
export const APPOINTMENTS_QUERY = 'appointments';
export const TEAMS_QUERY = 'teams';
export const LANGUAGES_QUERY = 'languages';
export const ROLES_QUERY = 'roles';
export const AD_USERS_QUERY = 'ad_users';
export const USER_QUERY = 'user';
export const USERS_QUERY = 'users';
export const DOCUMENT_TYPES_QUERY = 'document_types';
export const DOCUMENT_QUERY = 'document';
export const COUNTRIES_QUERY = 'countries';
export const PROVINCES_QUERY = 'provinces';
export const TIMEZONES_QUERY = 'timezones';
export const TIMEZONE_QUERY = 'timezone';
export const CONVERT_COUNSELLING_TYPES_QUERY = 'convert_counselling_types';
export const CONVERT_DELIVERY_METHODS_QUERY = 'convert_delivery_methods';
export const COUNSELLING_QUERY = 'counselling';
export const DEBTOR_PROFILE_QUERY = 'debtor_profile';
export const PHONE_TYPES_QUERY = 'phone_types';
export const TEMPLATE_SYSTEM_FIELDS_QUERY = 'template_system_fields';
export const MESSAGE_TEMPLATE_TYPES = 'message_template_types';
export const TEAMS_TYPES_QUERY = 'teams_types';
export const FILE_STAGES_QUERY = 'file_stages';
export const FILING_TYPES_QUERY = 'filing_types';
export const CLIENT_STATUSES_QUERY = 'client_statuses';
export const PAD_SCHEDULE_LIST = 'pad_schedule_list';
export const MEDIATION_MODES_QUERY = 'mediation_modes';
export const MEDIATION_STATUSES_QUERY = 'mediation_statuses';
export const FILE_PROCESSES = 'file_processes';
export const TASK_NAMES_QUERY = 'task_names';
export const FILE_COURT_AGENTS_QUERY = 'file_court_agents';
export const PDFTRON_LICENSE_KEY_QUERY = 'pdftron_license_key';
export const EXISTING_FILES_QUERY = 'existing_files';
