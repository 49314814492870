import moment from 'moment';

export const getFilledArray = (filledTo: number) => {
  const array: number[] = [];
  new Array(filledTo).fill(undefined).forEach((acc, index) => {
    array.push(index);
  });
  return array;
};

export const getDisabledHours = (minTime: moment.Moment, hours: number[] = []) => {
  return hours?.filter((hour) => hour <= Number(moment(minTime).format('HH'))) || [];
};

export const setRequiredRule = (label: string) => ({ required: true, message: `${label} is required` });
