import { SelectProps, Spin } from 'antd';
import { useQuery } from 'react-query';

import SelectLookupDto from '../../SelectLookupDto/SelectLookupDto';

import { FOLDERS_QUERY, USE_QUERY_OPTIONS } from '../../../constants/reactQuery';
import API from '../../../utils/api';

import './FoldersWithLoad.scss';
import useLocale from '../../../hooks/useLocale';

const FoldersWithLoad = ({ ...props }: SelectProps) => {
  const { data, isFetching } = useQuery([FOLDERS_QUERY], () => API.listFolders(), USE_QUERY_OPTIONS);
  const { t } = useLocale();

  return (
    <SelectLookupDto
      placeholder={t.SELECT_FOLDER}
      className="FoldersWithLoad"
      data={data}
      loading={isFetching}
      notFoundContent={isFetching ? <Spin /> : null}
      {...props}
    />
  );
};

export default FoldersWithLoad;
