import { useState, useCallback, useEffect } from 'react';
import { Modal, Form, Input, Col, Row, Checkbox, Spin } from 'antd';
import { useQuery } from 'react-query';

import Button from '../../../components/Button/Button';
import SelectLookupDto from '../../../components/SelectLookupDto/SelectLookupDto';

import useLocale from '../../../hooks/useLocale';

import { ClientService } from '../../../shared/api/ClientService';
import genericMessage from '../../../utils/genericMessage';
import { FOLDERS_QUERY, USE_QUERY_OPTIONS } from '../../../constants/reactQuery';
import API from '../../../utils/api';

import './DocumentPrefix.scss';

interface IProps {
  documentPrefixId?: string;
  onOk: () => void;
  onCancel: () => void;
}

const PrefixEditor = ({ documentPrefixId, onOk, onCancel }: IProps) => {
  const { t } = useLocale();
  const [form] = Form.useForm<ClientService.DocumentPrefixDto>();

  const [prefix, setPrefix] = useState<ClientService.DocumentPrefixDto>();
  const [isCustomPrefix, setIsCustomPrefix] = useState<boolean>(true);
  const [loading, setLoading] = useState<boolean>(false);

  const { data: folders } = useQuery([FOLDERS_QUERY], () => API.listFolders(), USE_QUERY_OPTIONS);

  const requestPrefix = useCallback(
    async (documentPrefixId: string) => {
      setLoading(true);
      const response = await API.getDocumentPrefix(documentPrefixId).catch(() => setLoading(false));
      setLoading(false);

      if (response) {
        form.setFieldsValue(response);
        setIsCustomPrefix(response.isCustom ?? false);
        setPrefix(response);
      }
    },
    [form]
  );

  const requestCreatePrefix = useCallback(
    async (values) => {
      setLoading(true);
      const response = await API.addDocumentPrefix(values).catch((error) => {
        setLoading(false);
        genericMessage.error({}, error?.message);
      });
      setLoading(false);

      if (response?.result === ClientService.Result.Successful) {
        if (onOk) onOk();
      } else {
        genericMessage.error({}, response?.messages?.[0].body);
      }
    },
    [onOk]
  );

  const requestUpdatePrefix = useCallback(
    async (values) => {
      setLoading(true);
      const response = await API.editDocumentPrefix({ ...prefix, ...values }).catch((error) => {
        setLoading(false);
        genericMessage.error({}, error?.error?.message);
      });
      setLoading(false);

      if (response?.result === ClientService.Result.Successful) {
        if (onOk) onOk();
      } else {
        genericMessage.error({}, response?.messages?.[0].body);
      }
    },
    [onOk, prefix]
  );

  const onFinish = useCallback(
    (values) => {
      if (documentPrefixId) {
        requestUpdatePrefix(values);
      } else {
        requestCreatePrefix(values);
      }
    },
    [documentPrefixId, requestCreatePrefix, requestUpdatePrefix]
  );

  useEffect(() => {
    if (documentPrefixId) requestPrefix(documentPrefixId);
  }, [requestPrefix, documentPrefixId]);

  return (
    <Modal
      destroyOnClose
      centered
      visible
      title={documentPrefixId ? t.EDIT_PRFX : t.ADD_PRFX}
      width={900}
      footer={null}
      onCancel={onCancel}
      className="DocumentPrefix__modal"
    >
      <Spin spinning={loading}>
        <Form form={form} onFinish={onFinish} initialValues={{ isActive: true }}>
          <Row gutter={60}>
            <Col span={12}>
              <Form.Item
                name="documentFolderId"
                label={t.FOLDER}
                labelCol={{ span: 8 }}
                labelAlign="left"
                rules={[{ required: true, message: t.FOLDER_IS_REQUIRED }]}
                required
              >
                <SelectLookupDto data={folders} disabled={!isCustomPrefix} />
              </Form.Item>
            </Col>

            <Col span={12}>
              <Form.Item
                name="documentPrefixName"
                label={t.PRE_FIX}
                labelCol={{ span: 8 }}
                labelAlign="left"
                rules={[{ required: true, message: t.PRFX_REQUIRED }]}
                required
              >
                <Input size="large" />
              </Form.Item>
            </Col>

            <Col span={12}>
              <Form.Item
                name="isActive"
                valuePropName="checked"
                label={t.STATUS}
                labelCol={{ span: 8 }}
                labelAlign="left"
              >
                <Checkbox disabled={!isCustomPrefix} />
              </Form.Item>
            </Col>
            {
              <Col span={12}>
                <Form.Item
                  name="frenchName"
                  label={t.FRENCH_PREFIX}
                  labelCol={{ span: 8 }}
                  labelAlign="left"
                  rules={[{ required: true, message: t.FRENCH_PREFIX_REQUIRED }]}
                  required
                >
                  <Input size="large" />
                </Form.Item>
              </Col>
            }
          </Row>
          <Row gutter={12} justify="end" className="DocumentPrefix__footer">
            <Col>
              <Button kind="cancel" onClick={onCancel}>
                {t.CANCEL}
              </Button>
            </Col>
            <Col>
              <Button kind="primary" htmlType="submit">
                {t.SAVE}
              </Button>
            </Col>
          </Row>
        </Form>
      </Spin>
    </Modal>
  );
};

export default PrefixEditor;
