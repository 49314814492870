import { Radio, RadioGroupProps } from 'antd';

import useLocale from '../../../../../hooks/useLocale';

import './SignUp.scss';

interface IProps extends RadioGroupProps {
  yesText?: string;
  noText?: string;
}

const RadioGroup = ({ yesText, noText, ...props }: IProps) => {
  const { t } = useLocale();

  return (
    <div className="SignUp__radio">
      <Radio.Group size="large" buttonStyle="solid" optionType="button" {...props}>
        <Radio.Button value={true}>{yesText || t.YES}</Radio.Button>
        <Radio.Button value={false}>{noText || t.NO}</Radio.Button>
      </Radio.Group>
    </div>
  );
};

export default RadioGroup;
