import {
  ICreditorSearchCriteria,
  IDebtorSearchCriteria,
  IBankruptSearchCriteria,
  ISearchAscendCriteria,
  ISearchAscendFileCriteria,
  ITeamsSearchCriteria,
  ITaskActionsSearchCriteria,
  IEmailTemplatesCriteria,
  IAgentListSearchCriteria,
} from './types';

export const argumentifyCreditorSearchCriteria = ({
  companyName,
  contactName,
  isActive,
  sorting,
  skipCount,
  maxResultCount,
}: ICreditorSearchCriteria) => [companyName, contactName, isActive, sorting, skipCount, maxResultCount] as const;

export const argumentifyDebtorSearchCriteria = ({
  searchString,
  fullName,
  hasActiveFiles,
  pageSize,
  pageIndex,
  clientStatus,
  fileStage,
  isEmailChecked,
  isEstateNumberChecked,
  sortingFieldName,
  isAscending,
  includeFileIds,
  debtorId,
}: IDebtorSearchCriteria) =>
  [
    searchString,
    fullName,
    hasActiveFiles,
    pageSize,
    pageIndex,
    clientStatus,
    fileStage,
    isEmailChecked,
    isEstateNumberChecked,
    sortingFieldName,
    isAscending,
    includeFileIds,
    debtorId,
  ] as const;

export const argumentifyBankruptSearchCriteria = ({
  fileName,
  estateNumber,
  sIN,
  debtorName,
  dateOfInsolvency,
  isActive,
}: IBankruptSearchCriteria) => [fileName, estateNumber, sIN, debtorName, dateOfInsolvency, isActive] as const;

export const argumentifyAscendSearchCriteria = ({
  estateNumber,
  sIN,
  debtorName,
  dateOfInsolvency,
  creditorName,
  accountNumber,
}: ISearchAscendCriteria) => [estateNumber, sIN, debtorName, dateOfInsolvency, creditorName, accountNumber] as const;

export const argumentifyAscendFileSearchCriteria = ({
  estateNumber,
  sIN,
  debtorName,
  dateOfInsolvency,
}: ISearchAscendFileCriteria) => [estateNumber, sIN, debtorName, dateOfInsolvency] as const;

export const argumentifyTeamsSearchCriteria = ({
  name,
  agentIdToBeIgnored,
  teamTypeEnum,
  sorting,
  skipCount,
  maxResultCount,
}: ITeamsSearchCriteria) => [name, agentIdToBeIgnored, teamTypeEnum, sorting, skipCount, maxResultCount] as const;

export const argumentifyTaskActionsSearchCriteria = ({
  filterText,
  description,
  followUpDateMin,
  followUpDateMax,
  openDateMin,
  openDateMax,
  closeDateMin,
  closeDateMax,
  assignedAgentId,
  assignedTeamId,
  appTaskId,
  appTaskActionIds,
  appTaskActionId,
  appTaskActionNameId,
  appTaskActionStatusId,
  appTaskActionStatus,
  teamTypes,
  appTaskActionNames,
  calledFromDashboard,
  sorting,
  skipCount,
  maxResultCount,
}: ITaskActionsSearchCriteria) =>
  [
    filterText,
    description,
    followUpDateMin,
    followUpDateMax,
    openDateMin,
    openDateMax,
    closeDateMin,
    closeDateMax,
    assignedAgentId,
    assignedTeamId,
    appTaskId,
    appTaskActionIds,
    appTaskActionId,
    appTaskActionNameId,
    appTaskActionStatusId,
    appTaskActionStatus,
    teamTypes,
    appTaskActionNames,
    calledFromDashboard,
    sorting,
    skipCount,
    maxResultCount,
  ] as const;

export const argumentifyEmailTemplatesCriteria = ({
  filterText,
  createdDateMin,
  createdDateMax,
  sorting,
  skipCount,
  maxResultCount,
}: IEmailTemplatesCriteria) =>
  [filterText, createdDateMin, createdDateMax, sorting, skipCount, maxResultCount] as const;

export const argumentifyAgentListSearchCriteria = ({
  isActive,
  fullName,
  sorting,
  skipCount,
  maxResultCount,
}: IAgentListSearchCriteria) => [isActive, fullName, sorting, skipCount, maxResultCount] as const;
