import { useMemo } from 'react';
import { useQuery } from 'react-query';

import { Form, Select, SelectProps } from 'antd';
import SelectSuffixIcon from '../../../components/SelectSuffixIcon/SelectSuffixIcon';

import { ClientService } from '../../../shared/api/ClientService';
import API from '../../../utils/api';
import useLocale from '../../../hooks/useLocale';

import { getAvailableTimeslotsByMeetingType, getAgentByPreselectRule } from '../utils';
import { MEETING_TYPES_QUERY } from '../../../constants/reactQuery';
import { ISelectedTimeslot } from '../types';
import { ALL_OPTION_ID } from '../constants';

import '../SchedulingComponent.scss';

interface IProps extends SelectProps {
  initialTimeslots?: ClientService.AppointmentTimeSlotDto[];
  filterOutUnknown?: boolean;
}

const MeetinTypeSelect = ({ initialTimeslots, filterOutUnknown, ...props }: IProps) => {
  const { t } = useLocale();
  const { Option } = Select;

  const { data: meetingTypes } = useQuery([MEETING_TYPES_QUERY], () => API.listMeetingTypes());

  const typesWithAllOption = useMemo(
    () => [
      { name: t.ALL, id: ALL_OPTION_ID },
      ...((filterOutUnknown
        ? meetingTypes?.filter((i) => i.enumValue !== ClientService.MeetingTypeEnum.Unknown)
        : meetingTypes) || []),
    ],
    [meetingTypes, t.ALL, filterOutUnknown]
  );

  return (
    <Form.Item noStyle shouldUpdate>
      {({ setFieldsValue }) => {
        return (
          <Form.Item label={t.APPOINTMENT_SCHEDULING_MEETING_TYPE} name="meetingTypeId">
            <Select
              {...props}
              suffixIcon={<SelectSuffixIcon />}
              size="large"
              onChange={(meetingTypeId) => {
                const availableTimeslots = getAvailableTimeslotsByMeetingType(initialTimeslots, meetingTypeId);
                const firstAvailableTimeslot = availableTimeslots?.[0];
                const selectedTimeslot = availableTimeslots?.[0] as ISelectedTimeslot;
                const firstAvailableAgentMeetingTypeId =
                  firstAvailableTimeslot?.availableAgents?.[0]?.meetingTypes?.[0]?.id;

                if (selectedTimeslot) {
                  selectedTimeslot.timeslotMeetingTypeId = firstAvailableAgentMeetingTypeId;
                }

                setFieldsValue({
                  availableTimeslots,
                  selectedTimeslot,
                  agentId: selectedTimeslot
                    ? getAgentByPreselectRule(selectedTimeslot?.availableAgents, firstAvailableAgentMeetingTypeId)
                    : undefined,
                });
              }}
            >
              {typesWithAllOption?.map((item) => (
                <Option key={`meetingType-${item.id}`} value={item.id}>
                  {item.name}
                </Option>
              ))}
            </Select>
          </Form.Item>
        );
      }}
    </Form.Item>
  );
};

export default MeetinTypeSelect;
