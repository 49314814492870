import { ReactNode, useCallback, useEffect, useMemo, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import moment from 'moment';
import { Divider, Row, Col } from 'antd';

import AppForm from '../../../../../components/Forms/AppForm/AppForm';
import CancelButton from '../../../../../components/Button/AppButtons/CancelButton';
import FormSaveButton from '../../../../../components/Button/AppButtons/FormSaveButton';
import Loading from '../../../../../components/Loading/Loading';
import NoticeToUser from '../../../../../components/NoticeToUser/NoticeToUser';
import useLocale from '../../../../../hooks/useLocale';
import { ClientService } from '../../../../../shared/api/ClientService';
import { IdentityService } from '../../../../../shared/api/IdentityService';
import API from '../../../../../utils/api';
import { PdsRoleClientAPI, PdsUserClientAPI } from '../../../../../utils/identityServiceApi';
import { ROUTES } from '../../../../../constants/routes';
import FormSectionTitle from '../../../../../components/FormSectionTitle/FormSectionTitle';
import FormInput from '../../../../../components/Forms/FormInput/FormInput';
import ButtonContainer from '../../../../../components/ButtonContainer/ButtonContainer';
import { eventBus } from '../../../../../utils/eventBus';
import { ADMIN_INFO_CHANGE_EVENT } from '../../../../../constants/eventBus';
import { argumentifyListUsersSearchCriteria, argumentifyOfficeLocationsSearchCriteria } from './utils';
import { MAX_PAGE_SIZE, DATE_FORMAT2 } from '../../../../../constants/common';
import { convertServerDateOnlyToInputDate } from '../../../../../utils/helpers';
import useModal from '../../../../../hooks/useModal';
import FilingTypeConfirmationModal from '../../../../../modals/FilingTypeConfirmationModal/FilingTypeConfirmationModal';
import './AdminInfo-GeneralForm.scss';
import useMenu from '../../../../../hooks/useMenu';
import Icon from '../../../../../components/Icon/Icon';
import UpdateFilingTypeModal from '../../../../../modals/UpdateFilingTypeModal/UpdateFilingType';
import genericMessage from '../../../../../utils/genericMessage';

const { noticeToUserStyles } = NoticeToUser;

interface IProps {
  data: ClientService.IApplicationFileDto | null;
  filingTypesList: ClientService.ILookupDto[] | null;
  handleUpdateData: (forcedResponse?: any) => void;
  disableAllFields?: boolean;
}

interface IForm_AdminInfoGeneral {
  agent?: string;
  estateAdministrator?: string;
  trustee?: string;
  interviewer?: string;
  filingType?: ClientService.FilingTypeEnum | string;
  serviceLocation?: string | undefined;
  trusteeOffice?: string | undefined;
  fileName?: string | undefined;
  insolvencyDate?: moment.Moment | string | undefined;
  estateNumber?: string | undefined;
  bankruptcyDuration?: string | undefined;
  updateFilingType?: ClientService.FilingTypeEnum | undefined;
}

interface ICourtData {
  courtName?: string | undefined;
  courtNumber?: string | undefined;
}

const isArrayWithValues = (varToCheck: unknown): boolean => {
  return !!(varToCheck && Array.isArray(varToCheck) && varToCheck.length > 0);
};

const AdminInfo_General = ({
  data,
  filingTypesList,
  handleUpdateData,
  disableAllFields = false,
}: IProps): JSX.Element => {
  const { isSuperAdmin } = useMenu();

  const fileStageName = data?.fileStage?.name;
  const { t } = useLocale();
  const { applicationFileId } = useParams<{ applicationFileId?: string }>();

  const [form] = AppForm.AntD.useForm<IForm_AdminInfoGeneral>();
  const [agents, setAgents] = useState<IdentityService.PdsUserListItem[]>();
  const [estateAdministrators, setEstateAdministrator] = useState<IdentityService.PdsUserListItem[]>();
  const [trustees, setTrustees] = useState<IdentityService.PdsUserListItem[]>();
  const [interviewers, setInterviewers] = useState<IdentityService.PdsUserListItem[]>();
  const [fileStage, setFileStage] = useState<ClientService.IFileStageDto | null>(null);
  const [initialFilingType, setInitialFilingType] = useState<ClientService.ILookupDto | null>(null);
  const [updateFilingTypes, setUpdateFilingTypes] = useState<ClientService.ILookupDto[] | null>(null);
  const [officeLocationsList, setOfficeLocationsList] = useState<ClientService.IOfficeLocationDto[] | undefined | null>(
    null
  );
  const [generalInfo, setGeneralInfo] = useState<ClientService.IAdminInfoGeneralDto | null>(null);
  const [loading, setLoading] = useState<boolean>(true);

  const { showModal, closeModal } = useModal();

  const [showDurationOfBankruptcyField, setShowDurationOfBankruptcyField] = useState<boolean>(false);
  const [filingTypesIsEditable, setFilingTypesIsEditable] = useState<boolean>(false);
  const [showUpdateFilingTypeField, setShowUpdateFilingTypeField] = useState<boolean>(false);
  const [filingTypeChangedMsg, setFilingTypeChangedMsg] = useState<ReactNode | null>(null);
  const [filingTypeChangedInstructions, setFilingTypeChangedInstructions] = useState<
    (string | undefined)[] | undefined
  >();
  const [courtData, setCourtData] = useState<ICourtData | undefined>({ courtName: '-', courtNumber: '-' });
  const [debtorId, setDebtorId] = useState<string>();

  useEffect(() => {
    if (data?.fileStage) {
      setFileStage(data.fileStage);
    }
  }, [data?.fileStage, setFileStage]);

  useEffect(() => {
    if (data?.courtName || data?.courtNumber) {
      setCourtData({
        courtName: data.courtName != null ? data.courtName : '-',
        courtNumber: data.courtNumber != null ? data.courtNumber : '-',
      });
    }
  }, [data?.courtName, data?.courtNumber]);

  const fillData = useCallback(async (id?: string) => {
    const offices = await API.officeLocationsGET(
      ...argumentifyOfficeLocationsSearchCriteria({ maxResultCount: MAX_PAGE_SIZE })
    );
    if (offices?.items) setOfficeLocationsList(offices?.items);

    const roles = await PdsRoleClientAPI.listRoles();
    if (roles) {
      const agents = await PdsUserClientAPI.listUsers(
        ...argumentifyListUsersSearchCriteria({ roleId: roles?.find((r) => r?.name === 'Agent')?.id })
      );
      if (agents) setAgents(agents?.items);

      const estateAdministrators = await PdsUserClientAPI.listUsers(
        ...argumentifyListUsersSearchCriteria({ roleId: roles?.find((r) => r?.name === 'Estate Administrator')?.id })
      );
      if (estateAdministrators) {
        setEstateAdministrator(estateAdministrators?.items);
        setInterviewers(estateAdministrators?.items);
      }

      const trustees = await PdsUserClientAPI.listUsers(
        ...argumentifyListUsersSearchCriteria({ roleId: roles?.find((r) => r?.name === 'Trustee')?.id })
      );
      if (trustees) setTrustees(trustees?.items);

      if (id) {
        setLoading(true);
        const general = await API.generalGET(id).catch(() => setLoading(false));
        setLoading(false);

        if (general) {
          setGeneralInfo(general);

          if (!Boolean(agents?.items?.find((item) => item.id === general?.agent?.id)) && general?.agent) {
            setAgents((prev) => [
              { id: general?.agent?.id, fullName: general?.agent?.fullName } as IdentityService.PdsUserListItem,
              ...(prev || []),
            ]);
          }

          if (
            !Boolean(estateAdministrators?.items?.find((item) => item.id === general?.estateAdministrator?.id)) &&
            general?.estateAdministrator
          ) {
            setEstateAdministrator((prev) => [
              {
                id: general?.estateAdministrator?.id,
                fullName: general?.estateAdministrator?.fullName,
              } as IdentityService.PdsUserListItem,
              ...(prev || []),
            ]);
          }

          if (
            !Boolean(estateAdministrators?.items?.find((item) => item.id === general?.interviewer?.id)) &&
            general?.interviewer
          ) {
            setInterviewers((prev) => [
              {
                id: general?.interviewer?.id,
                fullName: general?.interviewer?.fullName,
              } as IdentityService.PdsUserListItem,
              ...(prev || []),
            ]);
          }

          if (!Boolean(trustees?.items?.find((item) => item.id === general?.trustee?.id)) && general?.trustee) {
            setTrustees((prev) => [
              {
                id: general?.trustee?.id,
                fullName: general?.trustee?.fullName,
              } as IdentityService.PdsUserListItem,
              ...(prev || []),
            ]);
          }

          if (
            !Boolean(offices?.items?.find((item) => item.id === general?.serviceLocation?.id)) &&
            general?.serviceLocation
          ) {
            setOfficeLocationsList((prev) => [
              general?.serviceLocation as ClientService.IOfficeLocationDto,
              ...(prev || []),
            ]);
          }

          if (
            !Boolean(offices?.items?.find((item) => item.id === general?.trusteeOffice?.id)) &&
            general?.trusteeOffice
          ) {
            setOfficeLocationsList((prev) => [
              general?.trusteeOffice as ClientService.IOfficeLocationDto,
              ...(prev || []),
            ]);
          }
        }
      }
    }
  }, []);

  // Find the full lookup object for current file's 'filingType'
  // and store it in state as 'initialFilingType'
  useEffect(() => {
    if (data?.filingType) {
      if (filingTypesList && isArrayWithValues(filingTypesList)) {
        filingTypesList.every((filingTypeObj) => {
          if (filingTypeObj.name === data.filingType) {
            setInitialFilingType(filingTypeObj);
            return false;
          }
          return true;
        });
      }
    }
  }, [data, filingTypesList, setInitialFilingType]);

  useEffect(() => {
    fillData(data?.id);
  }, [fillData, data?.id]);

  useEffect(() => {
    interface ICurrentFilingTypesMappedToUpdateFilingTypes {
      [key: string]: ClientService.FilingTypeEnum[];
    }

    const currentFilingTypesMappedToUpdateFilingTypes: ICurrentFilingTypesMappedToUpdateFilingTypes = {
      Ordinary: [
        // ClientService.FilingTypeEnum.Summary,
        ClientService.FilingTypeEnum.DivisionI,
        ClientService.FilingTypeEnum.DivisionII,
      ],
      Summary: [
        ClientService.FilingTypeEnum.Ordinary,
        ClientService.FilingTypeEnum.DivisionI,
        ClientService.FilingTypeEnum.DivisionII,
      ],
      'Division I': [ClientService.FilingTypeEnum.Ordinary, ClientService.FilingTypeEnum.Summary],
      'Division II': [ClientService.FilingTypeEnum.Ordinary, ClientService.FilingTypeEnum.Summary],
    };

    if (initialFilingType && initialFilingType.name) {
      if (filingTypesList && isArrayWithValues(filingTypesList)) {
        const listOfUpateFilingTypes = currentFilingTypesMappedToUpdateFilingTypes[initialFilingType.name].map(
          (filingTypeEnum, index) => {
            let matchedFilingTypeObj: ClientService.ILookupDto | null = null;
            filingTypesList.every((filingTypeObj) => {
              if (filingTypeObj.enumValue === filingTypeEnum) {
                matchedFilingTypeObj = filingTypeObj;
                return false;
              }
              return true;
            });
            return matchedFilingTypeObj as unknown as ClientService.ILookupDto;
          }
        );
        setUpdateFilingTypes(listOfUpateFilingTypes);
      }
    }
  }, [initialFilingType, filingTypesList, setUpdateFilingTypes]);

  const fileStageIsNewProspect = useCallback((): boolean => {
    if (fileStage?.enumValue != null && (fileStage.enumValue as unknown) === ClientService.FileStageEnum.NewProspect) {
      return true;
    }
    return false;
  }, [fileStage?.enumValue]);

  const fileStageIsPreSignupAndStatusIsPendingOrInProgress = useCallback((): boolean => {
    if (
      fileStage?.enumValue != null &&
      fileStage.enumValue === ClientService.FileStageEnum.PreSignup &&
      generalInfo?.signupStatus?.status != null &&
      ((generalInfo.signupStatus.status as unknown) === ClientService.FileProcessStatusEnum.Pending ||
        (generalInfo.signupStatus.status as unknown) === ClientService.FileProcessStatusEnum.InProgress)
    ) {
      return true;
    }
    return false;
  }, [fileStage, generalInfo]);

  useEffect(() => {
    if (
      fileStageIsNewProspect() ||
      fileStageIsPreSignupAndStatusIsPendingOrInProgress() ||
      data?.filingType === undefined ||
      data?.filingType === null
    ) {
      setFilingTypesIsEditable(true);
    } else {
      setFilingTypesIsEditable(false);
    }
  }, [fileStageIsNewProspect, fileStageIsPreSignupAndStatusIsPendingOrInProgress, setFilingTypesIsEditable]);

  useEffect(() => {
    if (
      initialFilingType?.name &&
      initialFilingType.name !== 'Division I' &&
      initialFilingType.name !== 'Division II'
    ) {
      setShowDurationOfBankruptcyField(true);
    } else {
      setShowDurationOfBankruptcyField(false);
    }
  }, [initialFilingType]);

  useEffect(() => {
    if (
      fileStage?.enumValue &&
      [ClientService.FileStageEnum.Signed, ClientService.FileStageEnum.FileInProcess].indexOf(fileStage.enumValue) >= 0
    ) {
      if (isSuperAdmin) setShowUpdateFilingTypeField(true);
      else setShowUpdateFilingTypeField(false);
    } else {
      setShowUpdateFilingTypeField(false);
    }
  }, [fileStage, setShowUpdateFilingTypeField, isSuperAdmin]);

  const setInitialFormValues = useCallback(() => {
    if (generalInfo) {
      form.setFieldsValue({
        agent: generalInfo?.agent?.id,
        estateAdministrator: generalInfo?.estateAdministrator?.id,
        trustee: generalInfo?.trustee?.id,
        interviewer: generalInfo?.interviewer?.id,
        filingType: initialFilingType?.enumValue,
        serviceLocation: generalInfo?.serviceLocation?.id,
        trusteeOffice: generalInfo?.trusteeOffice?.id,
        fileName: generalInfo?.fileName,
        insolvencyDate: convertServerDateOnlyToInputDate(generalInfo?.insolvencyDate)?.format(DATE_FORMAT2),
        estateNumber: generalInfo?.estateNumber,
        bankruptcyDuration: generalInfo?.bankruptcyDuration,
        updateFilingType: undefined,
      });
    }
  }, [generalInfo, form, initialFilingType]);

  useEffect(() => {
    setInitialFormValues();
  }, [setInitialFormValues]);

  const fileId = useMemo((): string => {
    if (data?.id) {
      return data.id;
    }
    return '';
  }, [data?.id]);

  const handleChangeFilingTypeConfirmation = useCallback((): Promise<boolean> => {
    return new Promise((resolve) => {
      showModal(
        <FilingTypeConfirmationModal
          title={t.FILINGTYPE_CHANGE_CONFIRMATION_TITLE}
          message={t.FILINGTYPE_CHANGE_CONFIRMATION_MESSAGE}
          okText={t.YES}
          cancelText={t.NO}
          onOk={() => {
            closeModal();
            resolve(true);
          }}
          onCancel={() => {
            closeModal();
            resolve(false);
          }}
        />
      );
    });
  }, [
    closeModal,
    showModal,
    t.NO,
    t.FILINGTYPE_CHANGE_CONFIRMATION_TITLE,
    t.FILINGTYPE_CHANGE_CONFIRMATION_MESSAGE,
    t.YES,
  ]);

  const createRequestBody = useCallback((dto: IForm_AdminInfoGeneral) => {
    const filingTypeToUse: ClientService.FilingTypeEnum | string | undefined =
      dto.updateFilingType != null ? dto.updateFilingType : dto.filingType;
    return new ClientService.AdminInfoGeneralUpdateDto({
      filingType: filingTypeToUse as ClientService.FilingTypeEnum,
      agentId: dto.agent,
      estateAdministratorId: dto.estateAdministrator,
      trusteeId: dto.trustee,
      interviewerId: dto.interviewer,
      serviceLocationId: dto.serviceLocation,
      trusteeOfficeId: dto.trusteeOffice,
    });
  }, []);

  useEffect(() => {
    if (data?.descendantFileId) {
      const redirectRoute = `${ROUTES.APPLICATION_OVERVIEW}/${data.descendantFileId}`;

      let fileCreatedMessage = t.NEW_FILE_HAS_BEEN_CREATED;
      let accessFileMessage = t.TO_ACCESS_THE_FILE_THAT_WAS_CREATED;

      if (data?.descendantFileType) {
        const fileType = data.descendantFileType;
        const fileIsNew = fileType === ClientService.AppFileDescendantType.New ? true : false;
        accessFileMessage = fileIsNew
          ? t.TO_ACCESS_NEW_FILE_THAT_WAS_CREATED
          : t.TO_ACCESS_DUPLICATE_FILE_THAT_WAS_CREATED;
        if (!fileIsNew) fileCreatedMessage = t.DUPLICATE_FILE_HAS_BEEN_CREATED;
      }

      setFilingTypeChangedMsg(
        <div>
          <p>
            {`${t.STAGE_UPDATED_TO_STATUS_CHANGED} and ${fileCreatedMessage}. ${accessFileMessage},`}{' '}
            <Link to={redirectRoute}>{t.CLICK_HERE_PARTIAL}</Link>.
          </p>
          {filingTypeChangedInstructions &&
            filingTypeChangedInstructions.map((instruction, index) => {
              if (instruction != null) {
                // console.log('INSTRUCTION:', instruction);
                return (
                  <p key={`response-infoMsg-${index}`} className={noticeToUserStyles.info_message}>
                    {instruction}
                  </p>
                );
              }
              return null;
            })}
        </div>
      );
    }
  }, [
    data,
    t.STAGE_UPDATED_TO_STATUS_CHANGED,
    t.DUPLICATE_FILE_HAS_BEEN_CREATED,
    t.NEW_FILE_HAS_BEEN_CREATED,
    t.TO_ACCESS_THE_FILE_THAT_WAS_CREATED,
    t.CLICK_HERE_PARTIAL,
    t.CREATE_ESTATE_IN_ASCEND_FOR_NEW_FILE,
    t.TO_ACCESS_NEW_FILE_THAT_WAS_CREATED,
    t.TO_ACCESS_DUPLICATE_FILE_THAT_WAS_CREATED,
    t.CREATE_ESTATE_IN_ASCEND_FOR_DUPLICATE_FILE,
    filingTypeChangedInstructions,
  ]);

  const requestDebtor = useCallback(async () => {
    const debtorProfile = await API.getDebtorProfile(applicationFileId as string);
    if (debtorProfile?.profile?.clientId) {
      setDebtorId(debtorProfile?.profile?.clientId);
    }
  }, [applicationFileId]);

  const onSubmitSuccess = useCallback(
    (response, dto) => {
      eventBus.dispatch(ADMIN_INFO_CHANGE_EVENT);

      const isFilingTypeUpdated = dto.updateFilingType != null || dto.filingType !== initialFilingType?.enumValue;
      const ifFilingTypeUpdatedMessageRequired = dto.updateFilingType != null;

      console.log(isFilingTypeUpdated);
      if (response.returnId === data?.id) {
        // no New or Duplicate file created
        if (isFilingTypeUpdated) {
          handleUpdateData();

          if (ifFilingTypeUpdatedMessageRequired) setFilingTypeChangedMsg(t.FILING_TYPE_UPDATED_IN_FILE);
        }
      } else {
        // New or Duplicate file created
        if (response.returnId) {
          // console.log('new or duplicate file response:', response);

          const getInfoMessages = (messages: ClientService.Message[] | undefined) => {
            if (!messages || messages.length === 0) return undefined;

            const infoMessages: ClientService.Message[] = [];
            messages.forEach((msg) => {
              // console.log('MSG:', msg);
              if (msg.messageType === ClientService.ResultMessageType.Info) {
                infoMessages.push(msg);
              }
            });

            if (infoMessages.length === 0) return undefined;

            return infoMessages.map((infoMessage) => {
              if (!infoMessage.body) {
                return undefined;
              }
              if (infoMessage.body.indexOf('_') >= 0 && infoMessage.body === infoMessage?.body?.toUpperCase()) {
                return t[infoMessage.body];
              }
              return infoMessage.body;
            });
          };

          setFilingTypeChangedInstructions(getInfoMessages(response.messages));
          handleUpdateData();
        }
      }
    },
    [data, handleUpdateData, t]
  );

  useEffect(() => {
    if (!debtorId) requestDebtor();
  }, [debtorId, requestDebtor]);

  if (loading) return <Loading />;

  const handleUpdateFilingType = async (filingType?: ClientService.FilingTypeEnum) => {
    API.partialUpdateGeneralInfo(
      fileId,
      new ClientService.AdminInfoGeneralUpdateDto({
        filingType: filingType,
      })
    )
      .then((response) => {
        genericMessage.success(t.SUCCESSFULLY_SAVED);
        eventBus.dispatch(ADMIN_INFO_CHANGE_EVENT);
        handleUpdateData();
      })
      .catch((err) => {
        console.log('handleUpdateFilingType: ', err);
      });
  };

  const showChangeFilingTypeModal = () => {
    showModal(
      <UpdateFilingTypeModal
        filingTypes={filingTypesList}
        onSave={async (filingType) => {
          await handleUpdateFilingType(filingType);
          closeModal();
        }}
        onCancel={() => {
          closeModal();
        }}
        shouldDisableOnSave
      />
    );
  };

  const getSuperAdminEditIcon = () => {
    if (isSuperAdmin) return <Icon iconName="edit" onClick={showChangeFilingTypeModal} />;
    else return null;
  };

  return (
    <>
      {filingTypeChangedMsg && <NoticeToUser>{filingTypeChangedMsg}</NoticeToUser>}
      <AppForm
        form={form}
        changedFormProtection
        onValidationSuccess={(values) => {
          // console.log('form values:', values);
        }}
        apiSubmit={async (values) => {
          let confirmed = true;
          if ((values as IForm_AdminInfoGeneral).updateFilingType != null) {
            if (isSuperAdmin) {
              confirmed = await handleChangeFilingTypeConfirmation();
            } else (values as IForm_AdminInfoGeneral).updateFilingType = undefined;
          }
          if (confirmed) {
            return API.generalPUT(
              // 'e99ad6cb-3399-402e-9c1c-723c83260212', // fake id to force fail
              fileId,
              createRequestBody(values)
            );
          } else {
            return new ClientService.SaveResult({
              messages: [],
              hasConfirm: false,
              hasWarnings: false,
              hasErrors: false,
              result: undefined,
            });
          }
        }}
        onSubmitResponseError={(response) => {
          console.log('submitErrorResponse: ', response);
        }}
        onSubmitResponseSuccess={(response, values) => onSubmitSuccess(response, values)}
        className="AdminInfo-GeneralForm"
        scrollToFirstError
      >
        <FormSectionTitle titleText={t.ADMIN_INFO_GENERAL_DETAILS} noTopPadding />
        <Row gutter={20}>
          <Col span={8}>
            <FormInput
              type="select"
              name="agent"
              label={t.ADMIN_INFO_AGENT}
              disabled={disableAllFields}
              required={fileStageName !== 'New Prospect'}
              optionsList={agents?.map((agent) => ({
                value: agent.id,
                label: agent?.fullName,
              }))}
            />
          </Col>
          <Col span={8}>
            <FormInput
              type="select"
              name="estateAdministrator"
              label={t.ADMIN_INFO_ESTATE_ADMINISTRATOR}
              disabled={disableAllFields}
              required={fileStageName !== 'New Prospect'}
              optionsList={estateAdministrators?.map((estateAdministrator) => ({
                value: estateAdministrator.id,
                label: estateAdministrator?.fullName,
              }))}
            />
          </Col>
          <Col span={8}>
            <FormInput
              type="select"
              name="trustee"
              label={t.ADMIN_INFO_TRUSTEE}
              disabled={disableAllFields}
              required={fileStageName !== 'New Prospect'}
              optionsList={trustees?.map((trustee) => ({
                value: trustee.id,
                label: trustee?.fullName,
              }))}
            />
          </Col>
        </Row>
        <Row gutter={20}>
          <Col span={8}>
            <FormInput
              type="select"
              name="interviewer"
              label={t.ADMIN_INFO_INTERVIEWER}
              disabled={disableAllFields}
              required={fileStageName !== 'New Prospect'}
              optionsList={interviewers?.map((interviewer) => ({
                value: interviewer.id,
                label: interviewer?.fullName,
              }))}
            />
          </Col>
          <Col span={8}>
            <FormInput
              labelExtraContent={getSuperAdminEditIcon()}
              type="select"
              name="filingType"
              label={t.ADMIN_INFO_FILING_TYPE}
              disabled={disableAllFields && !filingTypesIsEditable}
              readOnly={!filingTypesIsEditable ? true : undefined}
              optionsList={filingTypesList?.map((filingType) => ({
                value: filingType.enumValue,
                label: filingType.name,
              }))}
            />
          </Col>
          <Col span={8}>
            <FormInput
              type="select"
              name="serviceLocation"
              label={t.ADMIN_INFO_SERVICE_LOCATION}
              disabled={disableAllFields}
              required={fileStageName !== 'New Prospect'}
              optionsList={officeLocationsList?.map((location) => ({
                value: location.id,
                label: location.name,
              }))}
            />
          </Col>
        </Row>
        <Row gutter={20}>
          <Col span={8}>
            <FormInput
              type="select"
              name="trusteeOffice"
              label={t.OFFICE_IS_TRUSTEE_OFFICE}
              disabled={disableAllFields}
              required={fileStageName !== 'New Prospect'}
              optionsList={officeLocationsList
                ?.filter((location) => location.isTrusteeOffice)
                .map((location) => ({
                  value: location.id,
                  label: location.name,
                }))}
            />
          </Col>
        </Row>
        <Divider />
        <Row gutter={20}>
          <Col span={8}>
            <FormInput name="fileName" label={t.ADMIN_INFO_FILE_NAME} readOnly disabled={disableAllFields} />
          </Col>
          <Col span={8}>
            <FormInput
              name="insolvencyDate"
              label={t.ADMIN_INFO_INSOLVENCY_DATE}
              readOnly
              disabled={disableAllFields}
            />
          </Col>
          <Col span={8}>
            <FormInput name="estateNumber" label={t.ADMIN_INFO_ESTATE_NUMBER} readOnly disabled={disableAllFields} />
          </Col>
        </Row>
        <Row gutter={20}>
          {showDurationOfBankruptcyField && (
            <Col span={8}>
              <FormInput
                name="bankruptcyDuration"
                label={t.ADMIN_INFO_DURATION_OF_BANKRUPTCY}
                readOnly
                disabled={disableAllFields}
              />
            </Col>
          )}
          {showUpdateFilingTypeField && (
            <Col span={8}>
              <FormInput
                type="select"
                name="updateFilingType"
                label={t.ADMIN_INFO_UPDATE_FILING_TYPE}
                disabled={disableAllFields || filingTypesIsEditable}
                noSelectOption
                optionsList={updateFilingTypes?.map((updateFilingType) => ({
                  value: updateFilingType.enumValue,
                  label: updateFilingType.name,
                }))}
              />
            </Col>
          )}
        </Row>
        <Divider />
        <Row gutter={20}>
          <Col span={8}>
            <FormInput label={t.COURT_NAME} value={courtData?.courtName} readOnly disabled={disableAllFields} />
          </Col>
          <Col span={8}>
            <FormInput label={t.COURT_NUMBER} value={courtData?.courtNumber} readOnly disabled={disableAllFields} />
          </Col>
        </Row>
        <ButtonContainer>
          <CancelButton />
          <FormSaveButton />
        </ButtonContainer>
      </AppForm>
    </>
  );
};

export default AdminInfo_General;
