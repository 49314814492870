import { Row, Col } from 'antd';
import moment from 'moment';
import { useQuery } from 'react-query';

import { DATE_FORMAT2 } from '../../constants/common';
import { readableBytes } from '../../pages/ApplicationOverviewPage/DocumentsContent/utils';
import DataItem from '../DataItem/DataItem';

import { DOCUMENT_TYPES_QUERY, USE_QUERY_OPTIONS } from '../../constants/reactQuery';
import { ClientService } from '../../shared/api/ClientService';
import API from '../../utils/api';

import './PreviewFileHeader.scss';
import { useMemo } from 'react';
import useLocale from '../../hooks/useLocale';

export interface PreviewHeaderProps {
  file?: ClientService.IDocumentDto | ClientService.IDocumentVerificationDto | ClientService.IDocumentVersionDto;
}

const PreviewFileHeader = ({ file }: PreviewHeaderProps) => {
  const { data: documentTypes } = useQuery([DOCUMENT_TYPES_QUERY], () => API.listTypes(), USE_QUERY_OPTIONS);
  const { t } = useLocale();

  const fileType = useMemo(
    () => documentTypes?.find((item) => item.id === file?.documentTypeId)?.name || '-',
    [documentTypes, file]
  );

  const fileSize = useMemo(() => (file?.fileSizeInBytes ? readableBytes(file?.fileSizeInBytes) : '-'), [file]);

  const fileModifiedDate = useMemo(
    () => (file?.modifiedDate ? moment(file?.modifiedDate).format(DATE_FORMAT2) : '-'),
    [file]
  );

  return (
    <Row align="middle" justify="space-between">
      <Col>
        <Row align="middle" justify="start" gutter={20} className="PreviewFile__info-container">
          <Col>
            <DataItem label={t.FILE_TYPE} value={fileType} horizontalSplit="auto" noCol />
          </Col>
          <Col>
            <DataItem label={t.SIZE} value={fileSize} horizontalSplit="auto" noCol />
          </Col>
          <Col>
            <DataItem label={t.MODIFIED} value={fileModifiedDate} horizontalSplit="auto" noCol />
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default PreviewFileHeader;
