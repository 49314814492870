import Button, { CustomButtonProps } from '../Button';
import useLocale from '../../../hooks/useLocale';

interface FormSaveButtonProps extends CustomButtonProps {
  style?: React.CSSProperties;
}

const FormSaveButton = ({
  padding = 'large',
  className,
  style,
  disabled = false,
  ...props
}: FormSaveButtonProps): JSX.Element => {
  const { t } = useLocale();

  return (
    <Button className={className} padding={padding} htmlType="submit" disabled={disabled} style={style} {...props}>
      {t.SAVE}
    </Button>
  );
};

export default FormSaveButton;
