import { useCallback, useState, useEffect, useMemo } from 'react';
import { useQuery } from 'react-query';
import { Upload, message, Row, Col } from 'antd';
import { Icon } from '@fluentui/react/lib/Icon';
import type { RcFile, UploadFile, UploadChangeParam } from 'antd/es/upload/interface';
import moment from 'moment';
import useLocale from '../../hooks/useLocale';
import {
  argumentifyDocumentsSearchCriteria,
  argumentifyCreateDocument,
  verifyUploadedFileType,
} from '../AttachmentsTab/utils';
import { PREFIXES_QUERY, FOLDERS_QUERY, USE_QUERY_OPTIONS, DOCUMENT_TYPES_QUERY } from '../../constants/reactQuery';
import { ClientService } from '../../shared/api/ClientService';
import API from '../../utils/api';
import ConvertToPdfWrapper from '../AttachmentsTab/NewFiles/ConvertToPdfWrapper';
import { eventBus } from '../../utils/eventBus';
import { CONVERT_TO_PDF_EVENT } from '../../constants/eventBus';
import { MAX_PAGE_SIZE } from '../../constants/common';

import './OnDropUploader.scss';
import Loading from '../Loading/Loading';
import { sanitizeDocumentName } from '../../pages/ApplicationOverviewPage/DocumentsContent/utils';

interface IProps {
  documentsIds?: string[];
  disabled?: boolean;
  onDrop: (documentId: string) => void;
  onDelete: (documentId: string) => void;
  fileId: string;
  documentFolderId: string;
  documentPrefixId: string;
}

function OnDropUploader({
  documentsIds,
  disabled,
  onDrop,
  onDelete,
  fileId,
  documentFolderId,
  documentPrefixId,
}: IProps): JSX.Element {
  const { t } = useLocale();

  const [uploadList, setUploadList] = useState<UploadFile[]>();
  const [totalSize, setTotalSize] = useState<number>(0);

  const [documents, setDocuments] = useState<ClientService.DocumentDto[]>();
  const [existingFilesIds, setExistingFilesIds] = useState<string[] | undefined>(documentsIds);

  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    if (!existingFilesIds || existingFilesIds.length === 0 || documents) {
      setIsLoading(false);
    }
  }, [existingFilesIds, documents]);

  const { data: prefixes } = useQuery(
    [PREFIXES_QUERY],
    () => API.listPrefixes(documentFolderId, undefined),
    USE_QUERY_OPTIONS
  );

  const { data: folders } = useQuery([FOLDERS_QUERY], () => API.listFolders(), USE_QUERY_OPTIONS);

  const { data: types } = useQuery([DOCUMENT_TYPES_QUERY], () => API.listTypes(), USE_QUERY_OPTIONS);

  const requestDocumentsByIds = useCallback(async (existingFilesIds: string[]) => {
    const response = await API.documentsGET(
      ...argumentifyDocumentsSearchCriteria({ documentIds: existingFilesIds, maxResultCount: MAX_PAGE_SIZE })
    );
    setIsLoading(false);
    if (response) setDocuments(response?.items);
  }, []);

  const requestDeleteDocument = useCallback(
    async (documentId: string) => {
      const response = await API.documentsDELETE([documentId]);
      if (response) {
        setExistingFilesIds((prev) => {
          const updatedIDs = prev?.filter((id) => id !== documentId);
          console.log('updatedIDs inside requestDeleteDocument():', updatedIDs);
          return updatedIDs?.length ? updatedIDs : undefined;
        });
        onDelete(documentId);
      }
    },
    [onDelete]
  );

  const handleBeforeUpload = useCallback(
    (file: RcFile, fileList: RcFile[]) => {
      if (!verifyUploadedFileType(file, types)) {
        message.error(t.DOCUMENTS_INVALID_TYPE_MESSAGE);
        return Upload.LIST_IGNORE;
      }

      const allFilesSize = fileList.reduce((prev, curr) => prev || 0 + curr.size || 0, 0) + totalSize;
      const allowedAllFilesSize = allFilesSize / 1024 / 1024 < 100;

      if (!allowedAllFilesSize) {
        if (file.uid === fileList?.[0]?.uid) {
          message.error(t.THE_SIZE_OF_ALL_ATTACHMENTS_SHOULDNOT_BE_GREATER_THAN_100MB);
        }
        return Upload.LIST_IGNORE;
      }

      setTotalSize(allFilesSize);

      return false;
    },
    [t.DOCUMENTS_INVALID_TYPE_MESSAGE, t.THE_SIZE_OF_ALL_ATTACHMENTS_SHOULDNOT_BE_GREATER_THAN_100MB, totalSize, types]
  );

  const handleUploadFileChange = useCallback(
    async (info: UploadChangeParam) => {
      eventBus.dispatch(CONVERT_TO_PDF_EVENT, {
        uploadFiles: info?.fileList,
        onConvertionSuccess: async (fileList: UploadFile[]) => {
          const files = fileList?.filter((item) => !uploadList?.find((i) => i.uid === item.uid));

          let newIds: string[] = [];

          for (const file of files) {
            const response = await API.documentsPOST(
              ...argumentifyCreateDocument({
                fileId,
                documentFolderId,
                documentPrefixId,
                isVisible: false, // By default, not visible. 22521.
                fileModifiedDate: moment(),
                content: { fileName: sanitizeDocumentName(file?.name as string), data: file?.originFileObj as Blob },
                isForSpouse: false, // UPDATE, when backend fixed
              })
            );

            if (response?.returnId) {
              onDrop(response?.returnId);
              newIds = [...newIds, response?.returnId];
            }
          }

          setExistingFilesIds((prev) => [...(prev || []), ...(newIds || [])]);
          setUploadList(fileList);
        },
      });
    },
    [documentFolderId, documentPrefixId, fileId, uploadList, onDrop]
  );

  useEffect(() => {
    if (existingFilesIds) {
      requestDocumentsByIds(existingFilesIds);
    } else {
      setDocuments(undefined);
    }
  }, [existingFilesIds, requestDocumentsByIds]);

  useEffect(() => {
    console.log('existingFilesIds state value inside component:', existingFilesIds);
    console.log('documents state value inside component:', documents);
  }, [existingFilesIds, documents]);

  const folderName = useMemo(
    () => folders?.find((item) => item.id === documentFolderId)?.name || '',
    [folders, documentFolderId]
  );
  const prefixName = useMemo(
    () => prefixes?.find((item) => item.id === documentPrefixId)?.name || '',
    [prefixes, documentPrefixId]
  );

  if (isLoading) return <Loading text={t.LOADING_FILE_UPLOADS} style={{ paddingBottom: '20px' }} />;

  return (
    <>
      <ConvertToPdfWrapper>
        <Upload.Dragger
          name="files"
          className="OnDropUploader__dragger"
          beforeUpload={handleBeforeUpload}
          onChange={handleUploadFileChange}
          disabled={disabled}
          showUploadList={false}
        >
          <Icon iconName="CloudUpload" className="OnDropUploader__icon" />
          <div className="OnDropUploader__dragger--title">{t.CLICK_OR_DRAG_FILE_TO_THIS_AREA_TO_UPLOAD}</div>
          <div className="OnDropUploader__dragger--info">{t.TOTAL_FILES_SIZE_SHOULD_NOT_EXCEED_100_MB}</div>
        </Upload.Dragger>
      </ConvertToPdfWrapper>
      <div className="OnDropUploader__files-list">
        {documents?.length && (
          <Row className="OnDropUploader__files-list__headers">
            <Col span={8}>{t.DOCUMENTS_FILENAME}</Col>
            <Col span={8}>{t.DOCUMENTS_FOLDER}</Col>
            <Col span={7}>{t.DOCUMENTS_PREFIX}</Col>
            <Col></Col>
          </Row>
        )}
        {documents?.map((item, index) => (
          <Row key={`file-${index}`} className="OnDropUploader__file-row" align="bottom">
            <Col span={8}>
              <span className="OnDropUploader__file-name">{item.name}</span>
            </Col>
            <Col span={8}>
              <span className="OnDropUploader__file-row__static-value">{folderName}</span>
            </Col>
            <Col span={7}>
              <span className="OnDropUploader__file-row__static-value">{prefixName}</span>
            </Col>
            <Col>
              <Icon
                iconName="Delete"
                onClick={disabled ? undefined : () => requestDeleteDocument(item.id as string)}
                className="OnDropUploader__icon"
                style={disabled ? { color: '#9e9e9e', cursor: 'not-allowed' } : undefined}
              />
            </Col>
          </Row>
        ))}
      </div>
    </>
  );
}

export default OnDropUploader;
