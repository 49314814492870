import moment from 'moment';
import { IOfficeLocationsSearchCriteria } from './types';
import { DATE_FORMAT2 } from '../../../constants/common';

import { ClientService } from '../../../shared/api/ClientService';

export const calculateCashAmount = (values: ClientService.ReceiptDto) =>
  1 * (values?.cashPaymentDenominationOnes || 0) +
  2 * (values?.cashPaymentDenominationTwos || 0) +
  5 * (values?.cashPaymentDenominationFives || 0) +
  10 * (values?.cashPaymentDenominationTens || 0) +
  20 * (values?.cashPaymentDenominationTwenties || 0) +
  50 * (values?.cashPaymentDenominationFifties || 0) +
  100 * (values?.cashPaymentDenominationHundreds || 0) +
  (values?.cashPaymentDenominationCents || 0) / 100;

export const argumentifyOfficeLocationsSearchCriteria = ({
  filterText,
  nearbyLatitude,
  nearbyLongitude,
  includeInactive,
  isActive,
  sorting,
  skipCount,
  maxResultCount,
}: IOfficeLocationsSearchCriteria) =>
  [filterText, nearbyLatitude, nearbyLongitude, includeInactive, isActive, sorting, skipCount, maxResultCount] as const;

export const convertReceiptPostTypeEnumToReadable = (postTypeEnum?: ClientService.ReceiptPostTypeEnum) => {
  switch (postTypeEnum) {
    case ClientService.ReceiptPostTypeEnum.Estate:
      return 'Estate';
    case ClientService.ReceiptPostTypeEnum.Holding:
      return 'Holding';
    case ClientService.ReceiptPostTypeEnum.PrefilingAccount:
      return 'Pre-filing account';
    default:
      return '';
  }
};

export const convertReceiptTypeEnumToReadable = (receiptTypeEnum?: ClientService.ReceiptTypeEnum) => {
  switch (receiptTypeEnum) {
    case ClientService.ReceiptTypeEnum.General:
      return 'General';
    case ClientService.ReceiptTypeEnum.Scheduled:
      return 'Scheduled';
    default:
      return 'General';
  }
};

export const convertScheduleItemToReadable = (item?: ClientService.PadScheduleSelectListItemDto) => {
  if (!item || !item?.dueDate) return '-';
  return `${moment(item?.dueDate).format(DATE_FORMAT2)} - ${item?.voucherNumber} - ${item?.amountDue}`;
};
