import { Col, Row, Checkbox, Form } from 'antd';
import moment from 'moment';
import update from 'immutability-helper';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

import { IconRed } from './Icons';
import DndContainer from './DndContainer';

import { DATE_FORMAT2 } from '../../../constants/common';
import useLocale from '../../../hooks/useLocale';
import '../RequestForReview.scss';

interface IProps {
  rowName: number;
  remove: (rowName: number) => void;
  isRemovable?: boolean;
  isEditable?: boolean;
}

function SummaryRow({ rowName, remove, isRemovable = true, isEditable = false }: IProps) {
  const { t } = useLocale();

  return (
    <Form.Item noStyle shouldUpdate>
      {({ getFieldsValue, setFieldsValue }) => {
        const documentValues = getFieldsValue()?.verifications?.[rowName];
        const isFirstRow = rowName === 0;
        return (
          <Row className="RequestForReview__summary--row">
            <Col
              span={4}
              className={isEditable ? 'RequestForReview__summary--editable' : 'RequestForReview__summary--disabled'}
            >
              <Form.Item label={isFirstRow && t.DOCUMENTS_FILENAME}>{documentValues?.name}</Form.Item>
            </Col>
            <Col span={3}>
              <Form.Item label={isFirstRow && t.DOCUMENTS_REVIEWERS}>
                <DndProvider backend={HTML5Backend}>
                  <DndContainer
                    containerId={`reviewers-${documentValues?.documentId}`}
                    cards={documentValues?.reviewers}
                    setCards={(reviewers: string[]) => {
                      setFieldsValue({
                        verifications: update(getFieldsValue()?.verifications, {
                          [rowName]: { reviewers: { $set: reviewers } },
                        }),
                      });
                    }}
                    isEditable={isEditable}
                  />
                </DndProvider>
              </Form.Item>
            </Col>
            <Col span={3}>
              <Form.Item label={isFirstRow && t.DOCUMENTS_APPROVERS}>
                <DndProvider backend={HTML5Backend}>
                  <DndContainer
                    containerId={`approvers-${documentValues?.documentId}`}
                    cards={documentValues?.approvers}
                    setCards={(approvers: string[]) => {
                      setFieldsValue({
                        verifications: update(getFieldsValue()?.verifications, {
                          [rowName]: { approvers: { $set: approvers } },
                        }),
                      });
                    }}
                    isEditable={isEditable}
                  />
                </DndProvider>
              </Form.Item>
            </Col>
            <Col span={3}>
              <Form.Item label={isFirstRow && t.DOCUMENTS_SIGNEES}>
                <DndProvider backend={HTML5Backend}>
                  <DndContainer
                    containerId={`signees-${documentValues?.documentId}`}
                    cards={documentValues?.signees}
                    setCards={(signees: string[]) => {
                      setFieldsValue({
                        verifications: update(getFieldsValue()?.verifications, {
                          [rowName]: { signees: { $set: signees } },
                        }),
                      });
                    }}
                    isEditable={isEditable}
                  />
                </DndProvider>
              </Form.Item>
            </Col>
            <Col span={3} className={`${isEditable && 'RequestForReview__summary--disabled'}`}>
              <Form.Item label={isFirstRow && t.DOCUMENTS_DUE_DATE}>
                {documentValues?.dueDate && (moment(documentValues?.dueDate).format(DATE_FORMAT2) || DATE_FORMAT2)}
              </Form.Item>
            </Col>
            <Col span={4} className={`${isEditable && 'RequestForReview__summary--disabled'}`}>
              <Form.Item label={isFirstRow && t.DOCUMENTS_COMMENTS}>{documentValues?.comments}</Form.Item>
            </Col>
            <Col span={2}>
              <Form.Item label={isFirstRow && t.DOCUMENTS_URGENT} name={[rowName, 'isUrgent']} valuePropName="checked">
                <Checkbox defaultChecked={documentValues.isUrgent} disabled />
              </Form.Item>
            </Col>
            <Col span={1}>
              <Form.Item label={isFirstRow && ' '}>
                {isRemovable && <IconRed iconName="Delete" onClick={() => remove(rowName)} />}
              </Form.Item>
            </Col>
          </Row>
        );
      }}
    </Form.Item>
  );
}

export default SummaryRow;
