import { useState, useCallback, useEffect, useMemo } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import { Button, Popover, Space, Tabs, ConfigProvider, Row, Col } from 'antd';
import { Icon } from '@fluentui/react/lib/Icon';

import InfoBlock from '../../../components/InfoBlock/InfoBlock';
import OverviewTab from './OverviewTab';
import AdminInfoTab from './AdminInfo/AdminInfoTab';
import Breadcrumb from '../../../components/Breadcrumb/Breadcrumb';
import { useApplicationFile } from '../ApplicationOverviewPage';
import ApplicationFormTab from './ApplicationForm/ApplicationFormTab';
import SubTab, { ISubTab } from './ApplicationForm/SubTab/SubTab';
import ActionConfirmationModal from '../../../modals/ActionConfirmationModal/ActionConfirmationModal';
import DocumentsContent from '../DocumentsContent/DocumentsContent';
import FileAnalysis from '../FileAnalysis/FileAnalysis';

import { ROUTES } from '../../../constants/routes';
import useLocale from '../../../hooks/useLocale';
import useUnsaved from '../../../hooks/useUnsaved';
import useModal from '../../../hooks/useModal';

import { ClientService } from '../../../shared/api/ClientService';
import API from '../../../utils/api';
import { argumentifyDocumentsSearchCriteria, mapAppFormStatusToSubTabInfo } from '../utils';
import eventBus from '../../../utils/eventBus';
import { APP_FORM_REDIRECT_EVENT } from '../../../constants/eventBus';

import './ApplicationOverviewContent.scss';
import genericMessage from '../../../utils/genericMessage';
import ChangeFileNameModal from '../../../modals/ChangeFileNameModal/ChangeFileName';
import useMenu from '../../../hooks/useMenu';

export const OVERVIEW_TAB = 'overview';
export const CLIENT_INFO_TAB = 'client-info';
export const ADMIN_INFO_TAB = 'admin-info';
export const DOCUMENTS_TAB = 'documents';
export const FILE_ANALYSIS_TAB = 'file-analysis';
export const APPLICATION_FORM_TAB = 'application-form';

interface IAppOverviewContentState {
  preselectedTab?: string;
  preselectedAppFormSubTab?: string;
  preselectedAdminInfoSubTab?: string;
  preselectedDocumentsSubTab?: string;
  preselectedDocumentId?: string;
}

interface ILocation {
  state: IAppOverviewContentState;
}

interface ITab extends ISubTab {
  key: string;
}

const LOCK_APP_FORM_KEY = 'lock-app-form';

function ApplicationOverviewContent(): JSX.Element {
  const { t } = useLocale();
  const { applicationFileId } = useParams<{ applicationFileId: string }>();
  const location = useLocation() as ILocation;

  const { TabPane } = Tabs;

  const { showModal, closeModal } = useModal();
  const { isUnsavedForm, setIsUnsavedForm } = useUnsaved();

  const [subTabs, setSubTabs] = useState<ITab[]>([]);

  const [activeTab, setActiveTab] = useState<string>(location?.state?.preselectedTab || OVERVIEW_TAB);
  const [activeAppFormSubTab, setActiveAppFormSubTab] = useState<string | undefined>(
    location?.state?.preselectedAppFormSubTab
  );

  const [isAppFormPopupOpen, setAppFormPopupOpen] = useState<boolean>(false);
  const [appFormStatusPopoverVisibility, setAppFormStatusPopoverVisibility] = useState<boolean>(false);

  const [data, setData] = useState<ClientService.IApplicationFileDto | null>(null);
  const [hasDocumentsUploadedFromClientPortal, setHasDocumentsUploadedFromClientPortal] = useState<boolean>(false);

  const isDuplicate = useMemo(() => data?.isDuplicate, [data]);

  const { data: contextData } = useApplicationFile();
  const { isSuperAdmin } = useMenu();

  useEffect(() => {
    setData(contextData);
    setActiveAppFormSubTab(location?.state?.preselectedAppFormSubTab);
  }, [setData, contextData, location?.state?.preselectedAppFormSubTab]);

  const updateApplicationFileData = useCallback(() => {
    if (applicationFileId) {
      API.applicationFile2(applicationFileId).then((response) => {
        setData(response);
      });
    }
  }, [applicationFileId]);

  const updateDocumentsTabTitle = useCallback(async () => {
    const documentFolders = await API.listFolders();
    const documentFolderId = documentFolders?.find(
      (folder) => folder.enumValue === ClientService.DocumentFolderEnum.ClientUpload
    )?.id;

    const numDoc = await API.count3(
      ...argumentifyDocumentsSearchCriteria({ fileId: data?.id, documentFolderId, isDeleted: false })
    );

    setHasDocumentsUploadedFromClientPortal(numDoc > 0);
  }, [data?.id]);

  const updateStatus = useCallback(async () => {
    if (data?.id) {
      const appFormStatuses = await API.getAppFormStatus(data?.id);

      const tabs = [
        mapAppFormStatusToSubTabInfo('Personal Information', '1', appFormStatuses.personalInfoStatus),
        mapAppFormStatusToSubTabInfo('Assets & Debts', '2', appFormStatuses.assetsDebtsStatus),
        mapAppFormStatusToSubTabInfo('Income & Expenses', '3', appFormStatuses.incomeExpenseStatus),
        mapAppFormStatusToSubTabInfo('Income Tax', '4', appFormStatuses.incomeTaxStatus),
        mapAppFormStatusToSubTabInfo('Banking Information', '5', appFormStatuses.bankingInformationStatus),
        mapAppFormStatusToSubTabInfo('Questionnaire', '6', appFormStatuses.questionnaireStatus),
        {
          title:
            Boolean(data?.isAppFormLocked) || Boolean(data?.isLocked) ? t.UNLOCK_APP_TAB_NAME : t.LOCK_APP_TAB_NAME,
          iconName: Boolean(data?.isAppFormLocked) || Boolean(data?.isLocked) ? 'UnlockSolid' : 'LockSolid',
          key: LOCK_APP_FORM_KEY,
        },
      ];

      tabs?.splice(
        6,
        0,
        mapAppFormStatusToSubTabInfo(
          t.SUPPORTING_DOCUMENTS,
          '7',
          appFormStatuses.supportingDocumentStatus,
          `${appFormStatuses?.numberOfUploadedSupportingDocuments} out of ${appFormStatuses?.numberOfRequiredSupportingDocuments}`
        )
      );

      setSubTabs(tabs);

      await updateDocumentsTabTitle();
    }
  }, [
    data?.id,
    data?.isAppFormLocked,
    data?.isLocked,
    t.LOCK_APP_TAB_NAME,
    t.SUPPORTING_DOCUMENTS,
    t.UNLOCK_APP_TAB_NAME,
    updateDocumentsTabTitle,
  ]);

  useEffect(() => {
    updateStatus();
  }, [updateStatus]);

  const handleGoToAppForm = useCallback((e) => {
    e.stopPropagation();
    setActiveTab(APPLICATION_FORM_TAB);
  }, []);

  const handleToggleAppFormLockStatus = useCallback(async () => {
    if (!applicationFileId) return;

    const isAppLocked = Boolean(data?.isAppFormLocked) || Boolean(data?.isLocked);

    showModal(
      <ActionConfirmationModal
        title={isAppLocked ? t.UNLOCK_APP_FORM_TITLE : t.LOCK_APP_FORM_TITLE}
        message={isAppLocked ? t.UNLOCK_APP_FORM_MESSAGE : t.LOCK_APP_FORM_MESSAGE}
        okText={isAppLocked ? t.UNLOCK : t.LOCK}
        onCancel={closeModal}
        onOk={async () => {
          closeModal();
          const response = await API.lockAppform(applicationFileId, !Boolean(data?.isAppFormLocked), true);

          if (response?.result === ClientService.Result.Successful) {
            updateApplicationFileData();
          } else {
            genericMessage.error({}, response?.messages?.[0]?.body);
          }
        }}
      />
    );
  }, [
    applicationFileId,
    closeModal,
    data?.isAppFormLocked,
    data?.isLocked,
    showModal,
    t.LOCK,
    t.LOCK_APP_FORM_MESSAGE,
    t.LOCK_APP_FORM_TITLE,
    t.UNLOCK,
    t.UNLOCK_APP_FORM_MESSAGE,
    t.UNLOCK_APP_FORM_TITLE,
    updateApplicationFileData,
  ]);

  const handleAppFormTabChange = useCallback(
    (item: ITab) => {
      if (item?.key === LOCK_APP_FORM_KEY) {
        handleToggleAppFormLockStatus();
        return;
      }

      if (isUnsavedForm) {
        showModal(
          <ActionConfirmationModal
            title={t.UNSAVED_CHANGED_TITLE}
            message={t.UNSAVED_CHANGED_MESSAGE}
            onOk={() => {
              closeModal();
              setIsUnsavedForm(false);
              setActiveTab(APPLICATION_FORM_TAB);
              setActiveAppFormSubTab(item.key);
              setAppFormStatusPopoverVisibility(false);
            }}
            onCancel={closeModal}
          />
        );
      } else {
        setActiveTab(APPLICATION_FORM_TAB);
        setActiveAppFormSubTab(item.key);
        setAppFormStatusPopoverVisibility(false);
      }
    },
    [
      closeModal,
      handleToggleAppFormLockStatus,
      isUnsavedForm,
      setIsUnsavedForm,
      showModal,
      t.UNSAVED_CHANGED_MESSAGE,
      t.UNSAVED_CHANGED_TITLE,
    ]
  );

  const setAppFormTab = useCallback((e) => {
    setActiveTab(APPLICATION_FORM_TAB);

    const subTab = e?.detail?.subTab;
    if (subTab) setActiveAppFormSubTab(subTab);
  }, []);

  useEffect(() => {
    return () => {
      setIsUnsavedForm(false);
    };
  }, [setIsUnsavedForm]);

  useEffect(() => {
    eventBus.on(APP_FORM_REDIRECT_EVENT, setAppFormTab);

    return () => {
      eventBus.remove(APP_FORM_REDIRECT_EVENT, setAppFormTab);
    };
  }, [setAppFormTab]);

  const showChangeFileNameModal = () => {
    showModal(
      <ChangeFileNameModal
        file={data}
        onSave={async (applicationFileName) => {
          if (data?.id) {
            await handleUpdateFileName(data.id, applicationFileName);
          }
        }}
        onCancel={closeModal}
      />
    );
  };

  const handleUpdateFileName = async (id: string, applicationFileName?: string) => {
    await API.rename(id, applicationFileName)
      .then((response) => {
        if (response?.result === ClientService.Result.Successful) {
          genericMessage.success(t.SUCCESSFULLY_SAVED);
          updateApplicationFileData();
          closeModal();
        } else {
          genericMessage.error({}, response?.messages?.[0]?.body);
        }
      })
      .catch((err) => {
        console.log('handleUpdateFilingType: ', err);
      });
  };

  return (
    <ConfigProvider
      getPopupContainer={(node?: HTMLElement) => {
        if (node) {
          return node.parentElement as HTMLElement;
        }
        return document.body;
      }}
    >
      <div className="ApplicationOverviewContent">
        <Breadcrumb
          data={[
            { link: ROUTES.DASHBOARD, title: t.DASHBOARD },
            { link: `${ROUTES.DEBTOR_SEARCH}`, title: t.DEBTOR_SEARCH },
            { title: t.APPLICATION_OVERVIEW },
          ]}
        />
        <div style={{ marginTop: 25 }}>
          <InfoBlock
            enumValue={data?.fileStage?.enumValue}
            progressItem={{
              title: t.APPLICATION_OVERVIEW_FILESTAGE,
              label: data?.fileStage?.name,
              value: data?.fileStage?.orderNumber,
            }}
            valueItems={[
              {
                label: t.APPLICATION_OVERVIEW_FILENAME,
                value: data?.applicationFileName,
                tooltip: t.APPLICATION_OVERVIEW_FILENAME_TOOLTIP,
                isItemEditable: isSuperAdmin,
                onItemClickEdit: showChangeFileNameModal,
              },
              {
                label: t.APPLICATION_OVERVIEW_ESTATE,
                value: data?.estateNumber,
                tooltip: t.APPLICATION_OVERVIEW_ESTATE_TOOLTIP,
              },
              {
                label: t.APPLICATION_OVERVIEW_FILING_TYPE,
                value: data?.filingType,
                tooltip: t.APPLICATION_OVERVIEW_FILING_TYPE_TOOLTIP,
              },
              {
                label: t.APPLICATION_OVERVIEW_SERVICELOCATION,
                value: data?.serviceLocation,
                tooltip: t.APPLICATION_OVERVIEW_SERVICELOCATION_TOOLTIP,
              },
              {
                label: t.APPLICATION_OVERVIEW_AGENT,
                value: data?.agentName,
                tooltip: t.APPLICATION_OVERVIEW_AGENT_TOOLTIP,
              },
              {
                label: t.APPLICATION_OVERVIEW_ESTATEADMIN,
                value: data?.estateAdministrator,
                tooltip: t.APPLICATION_OVERVIEW_ESTATEADMIN_TOOLTIP,
              },
              {
                label: t.APPLICATION_OVERVIEW_TRUSTEE,
                value: data?.trustee,
                tooltip: t.APPLICATION_OVERVIEW_TRUSTEE_TOOLTIP,
              },
            ]}
          />
        </div>
        <div style={{ marginTop: 40 }}>
          <Tabs
            className="ApplicationOverviewContent__tabs"
            activeKey={activeTab}
            onTabClick={setActiveTab}
            tabBarExtraContent={
              !isDuplicate && (
                <>
                  <Space className="ApplicationOverviewContent__space">
                    <Popover
                      placement="bottomLeft"
                      content={
                        <>
                          {subTabs.map((item, index) => (
                            <div
                              key={String(index)}
                              onClick={() => handleAppFormTabChange(item)}
                              className="ApplicationFormTab__subtab--item-container"
                            >
                              <SubTab {...item} />
                            </div>
                          ))}
                        </>
                      }
                      visible={appFormStatusPopoverVisibility}
                      onVisibleChange={(visible: boolean) => {
                        setAppFormStatusPopoverVisibility(visible);
                        if (visible) updateStatus();
                      }}
                      trigger="click"
                    >
                      <Button type="default" onClick={() => setAppFormPopupOpen(!isAppFormPopupOpen)}>
                        <Icon
                          iconName={isAppFormPopupOpen ? 'ChevronUp' : 'ChevronDown'}
                          className="ApplicationOverviewContent__icon"
                        />
                        Application Form
                        <Icon
                          iconName="DrillDown"
                          className="ApplicationOverviewContent__icon--appform"
                          onClick={handleGoToAppForm}
                        />
                      </Button>
                    </Popover>
                  </Space>
                </>
              )
            }
            destroyInactiveTabPane
          >
            <TabPane tab={t.APPLICATION_OVERVIEW_OVERVIEW_TAB} key={OVERVIEW_TAB}>
              <OverviewTab data={data} />
            </TabPane>
            {!isDuplicate && (
              <>
                <TabPane tab={t.APPLICATION_OVERVIEW_ADMIN_INFO_TAB} key={ADMIN_INFO_TAB}>
                  <AdminInfoTab
                    data={data}
                    handleUpdateData={updateApplicationFileData}
                    defaultActiveKey={(location?.state as IAppOverviewContentState)?.preselectedAdminInfoSubTab}
                  />
                </TabPane>

                <TabPane
                  tab={
                    <Row align="middle" gutter={5}>
                      {hasDocumentsUploadedFromClientPortal && (
                        <Col>
                          <Icon iconName="AlertSolid" className="ApplicationFormTab__tab-error-icon" />
                        </Col>
                      )}
                      <Col>{t.DOCUMENTS}</Col>
                    </Row>
                  }
                  key={DOCUMENTS_TAB}
                >
                  <DocumentsContent
                    initialDocumentId={(location?.state as IAppOverviewContentState)?.preselectedDocumentId}
                    defaultActiveKey={(location?.state as IAppOverviewContentState)?.preselectedDocumentsSubTab}
                    updateDocumentsTitle={updateDocumentsTabTitle}
                  />
                </TabPane>
                <TabPane tab={t.APPLICATION_OVERVIEW_ANALYSIS_TAB} key={FILE_ANALYSIS_TAB}>
                  <FileAnalysis />
                </TabPane>
                <TabPane key={APPLICATION_FORM_TAB}>
                  <ApplicationFormTab data={data} defaultActiveKey={activeAppFormSubTab} />
                </TabPane>
              </>
            )}
          </Tabs>
        </div>
      </div>
    </ConfigProvider>
  );
}

export default ApplicationOverviewContent;
