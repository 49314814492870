import { useState, useCallback, useEffect } from 'react';
import { Modal, Form, Col, Row, Checkbox, Spin } from 'antd';
import { useQuery } from 'react-query';
import moment from 'moment';

import Button from '../../../components/Button/Button';
import SelectWithResultingList from '../components/SelectWithResultingList/SelectWithResultingList';
import DatePicker from '../../../components/DatePicker/DatePicker';

import useLocale from '../../../hooks/useLocale';

import genericMessage from '../../../utils/genericMessage';
import { ClientService } from '../../../shared/api/ClientService';
import { DATE_FORMAT2, DATE_FORMAT_PLACEHOLDER, MAX_PAGE_SIZE } from '../../../constants/common';
import { OFFICE_LOCATIONS_QUERY, USE_QUERY_OPTIONS, INCLUDE_INACTIVE } from '../../../constants/reactQuery';
import { argumentifyOfficeLocationsSearchCriteria } from '../../../components/SchedulingComponent/utils';
import { convertInputDateToServerDateOnly, convertServerDateOnlyToInputDate } from '../../../utils/helpers';

import API from '../../../utils/api';

import './Holidays.scss';

interface IProps {
  id?: string;
  onOk: () => void;
  onCancel: () => void;
}

const HolidaysEditor = ({ id, onOk, onCancel }: IProps) => {
  const { t } = useLocale();
  const [form] = Form.useForm<ClientService.HolidayDto>();

  const [holiday, setHoliday] = useState<ClientService.HolidayDto>();
  const [loading, setLoading] = useState<boolean>(false);

  const { data: offices } = useQuery(
    [OFFICE_LOCATIONS_QUERY, INCLUDE_INACTIVE, MAX_PAGE_SIZE],
    () =>
      API.officeLocationsGET(
        ...argumentifyOfficeLocationsSearchCriteria({ maxResultCount: MAX_PAGE_SIZE, includeInactive: true })
      ),
    USE_QUERY_OPTIONS
  );

  const requestHoliday = useCallback(
    async (holidayId: string) => {
      setLoading(true);
      const response = await API.getHoliday(holidayId).catch(() => setLoading(false));
      setLoading(false);

      if (response) {
        form.setFieldsValue({
          ...response,
          holidayDate: convertServerDateOnlyToInputDate(response?.holidayDate),
          officeLocationIds: response?.isForAllOffices
            ? (offices?.items || [])?.filter((item) => item.isActive)?.map((item) => item?.id as string)
            : response.officeLocationIds,
        });
        setHoliday(response);
      }
    },
    [form, offices]
  );

  const requestCreateHoliday = useCallback(
    async (values) => {
      setLoading(true);
      const response = await API.addHoliday({
        ...values,
        holidayDate: convertInputDateToServerDateOnly(values?.holidayDate),
        isForAllOffices: values?.officeLocationIds?.length === offices?.items?.length,
      }).catch((error) => {
        setLoading(false);
        genericMessage.error({}, error?.message);
      });
      setLoading(false);

      if (response?.result === ClientService.Result.Successful) {
        if (onOk) onOk();
      } else {
        genericMessage.error({}, response?.messages?.[0].body);
      }
    },
    [offices?.items?.length, onOk]
  );

  const requestUpdateHoliday = useCallback(
    async (values) => {
      setLoading(true);
      const response = await API.editHoliday({
        ...holiday,
        ...values,
        holidayDate: convertInputDateToServerDateOnly(values?.holidayDate),
        isForAllOffices: values?.officeLocationIds?.length === offices?.items?.length,
      }).catch((error) => {
        setLoading(false);
        genericMessage.error({}, error?.error?.message);
      });
      setLoading(false);

      if (response?.result === ClientService.Result.Successful) {
        if (onOk) onOk();
      } else {
        genericMessage.error({}, response?.messages?.[0].body);
      }
    },
    [holiday, offices?.items?.length, onOk]
  );

  const onFinish = useCallback(
    (values) => {
      if (id) {
        requestUpdateHoliday(values);
      } else {
        requestCreateHoliday(values);
      }
    },
    [id, requestCreateHoliday, requestUpdateHoliday]
  );

  useEffect(() => {
    if (id && offices) requestHoliday(id);
  }, [requestHoliday, id, offices]);

  return (
    <Modal
      destroyOnClose
      centered
      visible
      title={id ? t.EDIT_HOLIDAY : t.ADD_HOLIDAY}
      width={700}
      footer={null}
      onCancel={onCancel}
      className="Holidays__modal"
    >
      <Spin spinning={loading}>
        <Form form={form} onFinish={onFinish} initialValues={{ isActive: true }}>
          <Row>
            <Col span={24}>
              <Form.Item noStyle shouldUpdate>
                {({ getFieldValue }) => {
                  const officeLocationIds = getFieldValue('officeLocationIds');
                  const data = offices?.items?.filter(
                    (item) => item.isActive || officeLocationIds?.indexOf(item?.id) >= 0
                  );
                  return (
                    <SelectWithResultingList
                      name="officeLocationIds"
                      data={data}
                      label={t.OFFICE_LOCATION}
                      labelCol={{ span: 6 }}
                      required
                      selectAllOption
                      showSearch
                    />
                  );
                }}
              </Form.Item>
            </Col>
            <Col span={23}>
              <Form.Item
                label={t.HOLIDAY_DATE}
                name="holidayDate"
                required
                rules={[{ required: true, message: t.REQUIRED_FIELD }]}
                labelCol={{ span: 6 }}
                labelAlign="left"
              >
                <DatePicker
                  format={DATE_FORMAT2}
                  dateSetter={null}
                  size="large"
                  disabledDate={(date) => date.isBefore(moment(), 'day')}
                  placeholder={DATE_FORMAT_PLACEHOLDER}
                />
              </Form.Item>
            </Col>

            <Col span={24}>
              <Form.Item
                name="isActive"
                valuePropName="checked"
                label={t.STATUS}
                labelCol={{ span: 6 }}
                labelAlign="left"
              >
                <Checkbox />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={24} justify="end" className="Holidays__modal--footer">
            <Col>
              <Button kind="cancel" onClick={onCancel}>
                {t.CANCEL}
              </Button>
            </Col>
            <Col>
              <Button kind="primary" htmlType="submit">
                {t.SAVE}
              </Button>
            </Col>
          </Row>
        </Form>
      </Spin>
    </Modal>
  );
};

export default HolidaysEditor;
