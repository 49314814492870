import { useEffect, useState } from 'react';
import { DatePicker, Form, Input, Select, Row, Col, FormInstance, Radio, RadioChangeEvent } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
import { ClientService } from '../../../../shared/api/ClientService';
import API from '../../../../utils/api';
import useLocale from '../../../../hooks/useLocale';
import SelectSuffixIcon from '../../../../components/SelectSuffixIcon/SelectSuffixIcon';

import './ApplicationFormTab.scss';
import { DATE_FORMAT2, DATE_FORMAT_PLACEHOLDER } from '../../../../constants/common';
import { antdUtil } from '../../../../utils/antdUtil';
import ChangeTrackerFormItem from '../../../../components/ChangeTrackerFormItem';

interface IProps {
  form: FormInstance<ClientService.AppFormPersonalInfoDto>;
  name: string;
  rowName: number;
  remove: (index: number | number[]) => void;
  isLocked?: boolean;
  listName?: string;
}

const PersonalInformationPreviousInsolvencyItem = ({
  form,
  name,
  rowName,
  remove,
  isLocked = false,
  listName,
}: IProps): JSX.Element => {
  const { Option } = Select;
  const { t } = useLocale();
  const [insolvencyTypes, setInsolvencyTypes] = useState<ClientService.LookupDto[]>();
  const [insolvencyTypesLoading, setInsolvencyTypesLoading] = useState<boolean>(false);

  const [proposalResults, setProposalResults] = useState<ClientService.LookupDto[]>();
  const [proposalResultsLoading, setProposalResultsLoading] = useState<boolean>(false);

  const [isPreviousInsolvencyBankruptcy, setIsPreviousInsolvencyBankruptcy] = useState<boolean>(false);
  const [isPreviousInsolvencyProposal, setIsPreviousInsolvencyProposal] = useState<boolean>(false);

  useEffect(() => {
    setInsolvencyTypesLoading(true);
    API.listInsolvencyTypes().then((response) => {
      setInsolvencyTypes(response);
      setInsolvencyTypesLoading(false);
    });

    setProposalResultsLoading(true);
    API.listProposalResults().then((response) => {
      setProposalResults(response);
      setProposalResultsLoading(false);
    });
  }, []);

  useEffect(() => {
    let typeId = form.getFieldValue([name, rowName, 'insolvencyTypeId']);
    let selType = insolvencyTypes?.find((item) => item?.id === typeId);

    setIsPreviousInsolvencyBankruptcy(selType?.enumValue === ClientService.InsolvencyTypeEnum.Bankruptcy);
    setIsPreviousInsolvencyProposal(selType?.enumValue === ClientService.InsolvencyTypeEnum.Proposal);
  }, [form, insolvencyTypes, name, rowName]);

  const handlePreviousInsolvencyTypeChanged = (e: RadioChangeEvent) => {
    const selType = insolvencyTypes?.find((item) => item?.id === e?.target?.value);

    setIsPreviousInsolvencyBankruptcy(selType?.enumValue === ClientService.InsolvencyTypeEnum.Bankruptcy);
    setIsPreviousInsolvencyProposal(selType?.enumValue === ClientService.InsolvencyTypeEnum.Proposal);
  };

  return (
    <>
      <Form.Item name={[rowName, 'id']} hidden>
        <Input disabled={isLocked} />
      </Form.Item>
      <Row gutter={20}>
        <Col span={8}>
          <ChangeTrackerFormItem
            name={[rowName, 'insolvencyTypeId']}
            label={t.TYPE}
            rules={[{ required: true }]}
            listName={listName}
          >
            <Radio.Group
              onChange={handlePreviousInsolvencyTypeChanged}
              buttonStyle="solid"
              optionType="button"
              disabled={isLocked}
            >
              {insolvencyTypes?.map((option) => (
                <Radio.Button key={option.id} value={option?.id}>
                  {option?.name}
                </Radio.Button>
              ))}
            </Radio.Group>
          </ChangeTrackerFormItem>
        </Col>
        <Col span={16} />
        <Col span={8}>
          <ChangeTrackerFormItem
            name={[rowName, 'applicantName']}
            label={t.UNDER_WHAT_NAME_DID_YOU_FILE}
            listName={listName}
          >
            <Input size="large" disabled={isLocked} />
          </ChangeTrackerFormItem>
        </Col>
        <Col span={8}>
          <ChangeTrackerFormItem name={[rowName, 'trusteeName']} label={t.TRUSTEES_NAME} listName={listName}>
            <Input size="large" disabled={isLocked} />
          </ChangeTrackerFormItem>
        </Col>
        <Col span={8}>
          <ChangeTrackerFormItem name={[rowName, 'estateNumber']} label={t.ESTATE_NUMBER} listName={listName}>
            <Input size="large" disabled={isLocked} />
          </ChangeTrackerFormItem>
        </Col>
      </Row>

      <Row gutter={20} align="middle">
        <Col span={8}>
          <ChangeTrackerFormItem name={[rowName, 'filingLocation']} label={t.PLACE_FILED} listName={listName}>
            <Input size="large" disabled={isLocked} />
          </ChangeTrackerFormItem>
        </Col>

        {isPreviousInsolvencyProposal && (
          <>
            <Col span={8}>
              <ChangeTrackerFormItem name={[rowName, 'proposalResultId']} label={t.PROPOSAL_RESULT} listName={listName}>
                <Select
                  loading={proposalResultsLoading}
                  suffixIcon={<SelectSuffixIcon />}
                  size="large"
                  disabled={isLocked}
                >
                  {proposalResults?.map((option) => (
                    <Option key={option.id} value={option?.id}>
                      {option?.name}
                    </Option>
                  ))}
                </Select>
              </ChangeTrackerFormItem>
            </Col>
            <Col span={7}>
              <ChangeTrackerFormItem
                name={[rowName, 'proposalDate']}
                className="ApplicationFormTab__input-label-warning"
                label={t.PROPOSAL_DATE}
                listName={listName}
                rules={[
                  {
                    validator: async (_: any, value?: any) => (value ? Promise.resolve() : Promise.reject()),
                    warningOnly: true,
                    message: t.RECOMMENDED_FIELD,
                    validateTrigger: 'onChange',
                  },
                ]}
              >
                <DatePicker
                  size="large"
                  disabledDate={antdUtil.futureDates}
                  format={DATE_FORMAT2}
                  placeholder={DATE_FORMAT_PLACEHOLDER}
                  disabled={isLocked}
                />
              </ChangeTrackerFormItem>
            </Col>
          </>
        )}
        {isPreviousInsolvencyBankruptcy && (
          <>
            <Col span={8}>
              <ChangeTrackerFormItem
                name={[rowName, 'dischargeDate']}
                label={t.BANKRUPTCY_DISCHARGE_DATE}
                listName={listName}
              >
                <DatePicker
                  size="large"
                  disabledDate={antdUtil.futureDates}
                  format={DATE_FORMAT2}
                  placeholder={DATE_FORMAT_PLACEHOLDER}
                  disabled={isLocked}
                />
              </ChangeTrackerFormItem>
            </Col>
            <Col span={7}>
              <ChangeTrackerFormItem
                listName={listName}
                name={[rowName, 'bankruptcyDate']}
                className="ApplicationFormTab__input-label-warning"
                label={t.BANKRUPTCY_DATE}
                rules={[
                  {
                    validator: async (_: any, value?: any) => (value ? Promise.resolve() : Promise.reject()),
                    warningOnly: true,
                    message: t.RECOMMENDED_FIELD,
                    validateTrigger: 'onChange',
                  },
                ]}
              >
                <DatePicker
                  size="large"
                  disabledDate={antdUtil.futureDates}
                  format={DATE_FORMAT2}
                  placeholder={DATE_FORMAT_PLACEHOLDER}
                  disabled={isLocked}
                />
              </ChangeTrackerFormItem>
            </Col>
          </>
        )}
        {!isLocked && (
          <Col span={1} style={{ textAlign: 'left' }}>
            <DeleteOutlined className="ApplicationFormTab__input-action-icon" onClick={() => remove(rowName)} />
          </Col>
        )}
      </Row>
    </>
  );
};

export default PersonalInformationPreviousInsolvencyItem;
