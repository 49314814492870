import { useState, useCallback, useEffect, Key } from 'react';

import EditableTable from './EditableTable/EditableTable';

import { CURRENCY_PRECISION, PERCENTAGE_PRECISION } from '../constants';
import genericMessage from '../../../../utils/genericMessage';
import { InputTypeEnum, IDataProps, IConfigurationType } from '../types';
import API from '../../../../utils/api';
import { ClientService } from '../../../../shared/api/ClientService';
import useLocale from '../../../../hooks/useLocale';

interface IProps {
  isEditable?: boolean;
  setIsEditable?: (isEditable: boolean) => void;
}

function RealEstateTable({ isEditable, setIsEditable }: IProps): JSX.Element {
  const { t } = useLocale();

  const [data, setData] = useState<IDataProps<IConfigurationType>[]>();
  const [loading, setLoading] = useState<boolean>(false);

  const requestConfigurations = useCallback(async () => {
    setLoading(true);
    const response = await API.getFileAnalysisRealEstateFeeConfigurationsList().catch(() => setLoading(false));
    if (response) setData(response?.map((item) => ({ ...item, key: item.id as Key, hasError: false })));
    setLoading(false);
  }, []);

  const requestUpdateConfigurations = useCallback(
    async (data?: IDataProps<IConfigurationType>[]) => {
      const body = data?.map((item: any) => ({
        id: item?.id,
        commissionPercentage: Number(item.commissionPercentage),
        over100kCommissionPercentage: Number(item.over100kCommissionPercentage),
        legalFee: Number(item.legalFee),
      })) as ClientService.FileAnalysisRealEstateFeeConfigurationInputDto[];

      setLoading(true);
      const response = await API.updateFileAnalysisRealEstateFeeConfigurations(body).catch(() => setLoading(false));

      if (response?.result === ClientService.Result.Successful) {
        genericMessage.success(t.CONFIGURATION_CHANGE_SUCCESS);
        if (setIsEditable) setIsEditable(false);
        requestConfigurations();
      } else {
        genericMessage.error({}, response?.messages?.[0].body);
      }
      setLoading(false);
    },
    [requestConfigurations, setIsEditable, t.CONFIGURATION_CHANGE_SUCCESS]
  );

  const handleCancel = useCallback(() => {
    if (setIsEditable) setIsEditable(false);
    requestConfigurations();
  }, [requestConfigurations, setIsEditable]);

  useEffect(() => {
    if (!data) requestConfigurations();
  }, [data, requestConfigurations]);

  const defaultColumns = [
    {
      title: t.PROVINCE,
      dataIndex: 'provinceCode',
      key: 'provinceCode',
      type: InputTypeEnum.ReadOnly,
    },
    {
      title: t.NAME,
      dataIndex: 'provinceName',
      key: 'provinceName',
      type: InputTypeEnum.ReadOnly,
    },
    {
      title: t.COMMISION,
      dataIndex: 'commissionPercentage',
      key: 'commissionPercentage',
      type: InputTypeEnum.Percentage,
      precision: PERCENTAGE_PRECISION,
    },
    {
      title: t.OVER_LAKH_DOLLARS,
      dataIndex: 'over100kCommissionPercentage',
      key: 'over100kCommissionPercentage',
      type: InputTypeEnum.Percentage,
      precision: PERCENTAGE_PRECISION,
    },
    {
      title: t.LEGAL_FEES,
      dataIndex: 'legalFee',
      key: 'legalFee',
      type: InputTypeEnum.Currency,
      precision: CURRENCY_PRECISION,
    },
  ];

  return (
    <EditableTable
      data={data}
      loading={loading}
      defaultColumns={defaultColumns}
      isEditable={isEditable}
      onSave={requestUpdateConfigurations}
      onCancel={handleCancel}
    />
  );
}

export default RealEstateTable;
