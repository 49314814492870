import { useCallback, useEffect } from 'react';
import { Modal, Form, Row, Col, Select, Input } from 'antd';
import { useQuery } from 'react-query';

import Button from '../../../../../../components/Button/Button';
import AttachmentsTab, { AttachmentsFormProps } from '../../../../../../components/AttachmentsTab';
import SelectSuffixIcon from '../../../../../../components/SelectSuffixIcon/SelectSuffixIcon';

import useLocale from '../../../../../../hooks/useLocale';

import { FILE_PROCESSES, USE_QUERY_OPTIONS } from '../../../../../../constants/reactQuery';
import { UPLOAD_FILES_EVENT } from '../../../../../../constants/eventBus';
import { DISCHARGE_LETTER_TO_DEBTOR_RE_OPPOSITION } from '../constants';
import { eventBus } from '../../../../../../utils/eventBus';
import { ClientService } from '../../../../../../shared/api/ClientService';
import API from '../../../../../../utils/api';

import './OppositionLetterModal.scss';

interface IProps {
  title: string;
  applicationFileId?: string;
  onOk?: () => void;
  onCancel?: () => void;
}

interface IForm extends ClientService.OppositionLetterUpdateDto, AttachmentsFormProps {}

function OppositionLetterModal({ title, applicationFileId, onOk, onCancel }: IProps): JSX.Element {
  const { t } = useLocale();
  const { TextArea } = Input;
  const { Option } = Select;

  const [form] = Form.useForm<IForm>();

  const { data: fileProcesses } = useQuery([FILE_PROCESSES], () => API.listFileProcesses(), USE_QUERY_OPTIONS);

  const requestOppositionLetter = useCallback(
    async (applicationFileId: string) => {
      const response = await API.getOppositionLetter(applicationFileId);
      if (response) form.setFieldsValue(response);
    },
    [form]
  );

  const requestUpdateLetter = useCallback(
    async ({ documentIds, isOppositionLetterShared, pendingProcesses, oppositionComments }) => {
      await API.updateOppositionLetter({
        fileId: applicationFileId,
        documentIds,
        isOppositionLetterShared,
        pendingProcesses,
        oppositionComments,
      } as ClientService.OppositionLetterUpdateDto);

      if (onOk) onOk();
    },
    [applicationFileId, onOk]
  );

  const handleUpload = useCallback(
    (uploadAndShare?: boolean) => {
      form.validateFields().then((values) => {
        eventBus.dispatch(UPLOAD_FILES_EVENT, {
          uploadAndShare,
          onUploadSuccess: (documentIds: string[]) => {
            requestUpdateLetter({ ...values, documentIds, isOppositionLetterShared: uploadAndShare });
          },
        });
      });
    },
    [form, requestUpdateLetter]
  );

  const handleCancel = useCallback(() => {
    if (onCancel) onCancel();
  }, [onCancel]);

  useEffect(() => {
    if (applicationFileId) requestOppositionLetter(applicationFileId);
  }, [applicationFileId, requestOppositionLetter]);

  return (
    <Modal
      title={title}
      destroyOnClose
      centered
      visible
      footer={null}
      closable
      onCancel={handleCancel}
      width={900}
      className="OppositionLetterModal"
    >
      <Form form={form} layout="vertical" name="OppositionLetterModal">
        <Row>
          <Col span={12}>
            <Form.Item
              label={t.PENDING_PROCESS}
              name="pendingProcesses"
              required
              rules={[{ required: true, message: t.REQUIRED_FIELD }]}
            >
              <Select
                size="large"
                suffixIcon={<SelectSuffixIcon />}
                mode="multiple"
                placeholder={t.SELECT}
                showArrow
                showSearch
                filterOption={(input, option) => option?.title?.toLowerCase().includes(input.toLowerCase() || '')}
              >
                {fileProcesses?.map((item) => (
                  <Option value={item?.enumValue} key={item.enumValue} title={item?.name}>
                    {item?.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              name="oppositionComments"
              label={t.OPPOSITION_COMMENTS}
              required
              rules={[{ required: true, message: t.REQUIRED_FIELD }]}
            >
              <TextArea rows={4} maxLength={1000} />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item>
              <AttachmentsTab
                form={form}
                applicationFileId={applicationFileId}
                supportingFolderCode="discharge"
                prefixCodes={[DISCHARGE_LETTER_TO_DEBTOR_RE_OPPOSITION]}
                newSupportingFileRowLayoutProps={{
                  disabledPrefixInput: true,
                  preselectedPrefixInput: true,
                  disabledFoldersInput: true,
                }}
                isFlatLayout
                flatLayoutProps={{ hasExistingFiles: true, label: t.FILES }}
                required
              />
            </Form.Item>
          </Col>
        </Row>

        <Row align="middle" justify="space-between" className="OppositionLetterModal__footer" wrap={false} gutter={20}>
          <Col>{t.MAX_100MB_UPLOAD_MESSAGE}</Col>
          <Col>
            <Row gutter={12} justify="end" align="bottom" wrap={false}>
              <Col>
                <Form.Item noStyle>
                  <Button kind="cancel" onClick={onCancel}>
                    {t.CANCEL}
                  </Button>
                </Form.Item>
              </Col>
              <Col>
                <Form.Item noStyle>
                  <Button onClick={() => handleUpload(false)}>{t.UPLOAD}</Button>
                </Form.Item>
              </Col>
              <Col>
                <Form.Item noStyle>
                  <Button onClick={() => handleUpload(true)}>{t.UPLOAD_SHARE}</Button>
                </Form.Item>
              </Col>
            </Row>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
}

export default OppositionLetterModal;
