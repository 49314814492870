import { isValidElement, ReactElement, ReactNode, useCallback } from 'react';
import Icon, { IconProps } from '../Icon/Icon';
import CurrencyDisplay from '../CurrencyDisplay/CurrencyDisplay';
import { getAsSystemColorOrUnchanged, SystemColorsType } from '../../utils/systemColors';

import styles from './InfoBubble.module.scss';

export type TInfoBubble =
  | ReactElement<InfoBubbleProps>
  | ReactElement<InfoBubbleDirectionalProps>
  | InfoBubbleProps
  | InfoBubbleDirectionalProps;

export type InfoBubbleProps = {
  value: string | number;
  format?: 'normal' | 'currency' | 'currency-no-dollar-sign';
  color?: string;
  icon?: ReactNode | IconProps;
  bgColor?: SystemColorsType | string;
  className?: string;
  style?: React.CSSProperties;
};

export type InfoBubbleDirectionalProps = Omit<InfoBubbleProps, 'color' | 'icon' | 'bgColor'> & {
  direction?: 'up' | 'down';
};

function InfoBubble({
  value,
  format = 'normal',
  color,
  bgColor = 'dark-gray',
  icon,
  className,
  style,
}: InfoBubbleProps): JSX.Element {
  const isCurrency = format === 'currency' || format === 'currency-no-dollar-sign';

  let propStyles: React.CSSProperties = {};
  if (color) propStyles.color = color;
  if (bgColor) propStyles.backgroundColor = getAsSystemColorOrUnchanged(bgColor);

  const renderIcon = useCallback(() => {
    if (icon && isValidElement(icon)) return icon;
    return <Icon {...(icon as IconProps)} />;
  }, [icon]);

  return (
    <div
      className={`${styles.InfoBubbleWrapper} ${className ? className : ''}`}
      style={{
        ...propStyles,
        ...style,
      }}
    >
      <div className={styles.InfoBubble}>
        {icon && <div className={styles.icon}>{renderIcon()}</div>}
        <div className={styles.value}>
          {isCurrency ? <CurrencyDisplay amount={value} noDollarSign={format === 'currency-no-dollar-sign'} /> : value}
        </div>
      </div>
    </div>
  );
}

InfoBubble.Directional = ({ direction, value, format, className, style }: InfoBubbleDirectionalProps): JSX.Element => {
  let iconName, bgColor;

  if (direction === 'down') {
    iconName = 'Down';
    bgColor = 'red';
  } else {
    iconName = 'Up';
    bgColor = 'green';
  }

  bgColor = getAsSystemColorOrUnchanged(bgColor);

  return (
    <InfoBubble
      value={value}
      format={format}
      bgColor={bgColor as SystemColorsType}
      icon={{ iconName: iconName, style: { top: '2px' } }}
      className={`${styles.directional} ${className ? className : ''}`}
      style={style}
    />
  );
};

export default InfoBubble;
