import { useState, useCallback, useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { Row, Col } from 'antd';

import DataItem from '../../../../../../../components/DataItem/DataItem';
import FormSectionTitle from '../../../../../../../components/FormSectionTitle/FormSectionTitle';

import useLocale from '../../../../../../../hooks/useLocale';

import genericMessage from '../../../../../../../utils/genericMessage';
import { ClientService } from '../../../../../../../shared/api/ClientService';
import API from '../../../../../../../utils/api';

const PaymentSummary = () => {
  const { t } = useLocale();
  const { applicationFileId } = useParams<{ applicationFileId?: string }>();
  const [summary, setSummary] = useState<ClientService.BalanceInfoDto>();

  const requestBalanceInfo = useCallback(
    async (applicationFileId: string) => {
      API.getBalanceInfo(applicationFileId)
        .then((response) => setSummary(response))
        .catch(() => genericMessage.error({}, t.SOMETHING_WENT_WRONG));
    },
    [t.SOMETHING_WENT_WRONG]
  );

  useEffect(() => {
    if (applicationFileId && !summary) requestBalanceInfo(applicationFileId);
  }, [requestBalanceInfo, applicationFileId, summary]);

  const totalDue = useMemo(() => summary?.startingBalance || 0, [summary?.startingBalance]);
  const totalDeposited = useMemo(() => summary?.paidBalance || 0, [summary?.paidBalance]);
  const totalOutstanding = useMemo(() => totalDue - totalDeposited, [totalDue, totalDeposited]);

  return (
    <>
      <FormSectionTitle titleText={t.PAYMENT_SUMMARY} />

      <Row>
        <Col span={4}>
          <DataItem
            layout="vertical"
            label={t.TOTAL_DUE}
            value={totalDue ? { value: totalDue, format: 'currency-no-dollar-sign' } : '-'}
          />
        </Col>
        <Col span={4}>
          <DataItem
            layout="vertical"
            label={t.TOTAL_DEPOSITED}
            value={totalDeposited ? { value: totalDeposited, format: 'currency-no-dollar-sign' } : '-'}
          />
        </Col>
        <Col span={4}>
          <DataItem
            layout="vertical"
            label={t.TOTAL_OUTSTANDING}
            value={
              totalOutstanding
                ? {
                    value: totalOutstanding,
                    format: 'currency-no-dollar-sign',
                  }
                : '-'
            }
          />
        </Col>
      </Row>
    </>
  );
};

export default PaymentSummary;
