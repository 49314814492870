import moment from 'moment';
import { DATE_FORMAT2 } from '../../../../../constants/common';
import { ClientService } from '../../../../../shared/api/ClientService';
import styles from '../../../../../styles/style.module.scss';

export const convertPadRequestStatusToReadable = (status?: ClientService.PADRequestReviewStatusEnum) => {
  switch (status) {
    case ClientService.PADRequestReviewStatusEnum.Pending:
      return 'Pending';
    case ClientService.PADRequestReviewStatusEnum.Approved:
      return 'Approved';
    case ClientService.PADRequestReviewStatusEnum.Rejected:
      return 'Rejected';
    default:
      return '';
  }
};

export const getPadScheduleStateByEnum = (status?: ClientService.PADScheduleStateEnum) => {
  switch (status) {
    case ClientService.PADScheduleStateEnum.Active:
      return {
        label: 'Active',
        color: styles.colorGreen,
      };
    case ClientService.PADScheduleStateEnum.HoldRequested:
      return {
        label: 'Hold Requested',
        color: styles.colorWarning,
      };
    case ClientService.PADScheduleStateEnum.OnHold:
      return {
        label: 'On Hold',
        color: styles.colorDanger,
      };
    case ClientService.PADScheduleStateEnum.RequestRejected:
      return {
        label: 'Request Rejected',
        color: styles.colorDanger,
      };
    default:
      return {};
  }
};

export const convertDateToReadable = (date?: moment.Moment, format?: string) =>
  date ? moment(date)?.format(format || DATE_FORMAT2) : '-';
