import { CSSProperties } from 'react';
import { Form, Col, Row, Tooltip } from 'antd';
import { useQuery } from 'react-query';

import AddIcon from '../../AddIcon';
import CopyDropdown from '../CopyDropdown/CopyDropdown';
import AvailabilityItem from './AvailabilityItem';

import useLocale from '../../../../../hooks/useLocale';

import { MEETING_TYPES_QUERY } from '../../../../../constants/reactQuery';
import { ClientService } from '../../../../../shared/api/ClientService';
import API from '../../../../../utils/api';

import './WeeklyRow.scss';

interface IProps {
  name: string;
  weekday?: string;
  copyable?: boolean;
  style?: CSSProperties;
}

const WeeklyRow = ({ name, weekday, copyable = true, style }: IProps) => {
  const { t } = useLocale();
  const { data: meetingTypes } = useQuery([MEETING_TYPES_QUERY], () => API.listMeetingTypes());

  return (
    <div className="WeeklyRow" style={style}>
      <Form.Item shouldUpdate noStyle>
        {({ getFieldValue, setFieldsValue }) => {
          const isAvailable = Boolean(getFieldValue(name)?.length);
          return (
            <Form.List name={name}>
              {(fields, { add, remove }) => (
                <Row gutter={20}>
                  <Col span={weekday ? 2 : 0}>
                    <span>{weekday}</span>
                  </Col>
                  <Col span={weekday ? 20 : 22}>
                    <>
                      {isAvailable ? (
                        fields?.map((row, index) => (
                          <AvailabilityItem
                            remove={() => remove(row.name)}
                            key={String(index)}
                            rowName={row.name}
                            weekdayPropName={name}
                          />
                        ))
                      ) : (
                        <div className="WeeklyRow__unavailable-text">{t.UNAVAILABLE}</div>
                      )}
                    </>
                  </Col>
                  <Col span={2}>
                    <Row wrap={false} gutter={8} align="middle" style={{ paddingTop: 3 }}>
                      <Col>
                        <Form.Item noStyle>
                          <Tooltip title={t.ADD}>
                            <AddIcon
                              onClick={() =>
                                add({
                                  meetingTypeId: meetingTypes?.find(
                                    (item) => item.enumValue === ClientService.MeetingTypeEnum?.InPerson
                                  )?.id,
                                })
                              }
                            />
                          </Tooltip>
                        </Form.Item>
                      </Col>
                      {copyable && (
                        <Col>
                          <CopyDropdown
                            onSelect={(weekdays) => {
                              const currentFieldValues = (
                                getFieldValue(name) as
                                  | ClientService.WeeklyScheduleEntryDto[]
                                  | ClientService.AvailabilityOverrideEntryDto[]
                              ).map((item) => ({
                                ...item,
                                rawStartTime: item?.startTime?.hour() + ':' + item?.startTime?.minute(),
                                rawEndTime: item?.endTime?.hour() + ':' + item?.endTime?.minute(),
                              }));
                              for (let weekday of weekdays) {
                                setFieldsValue({ [weekday]: currentFieldValues });
                              }
                            }}
                          />
                        </Col>
                      )}
                    </Row>
                  </Col>
                </Row>
              )}
            </Form.List>
          );
        }}
      </Form.Item>
    </div>
  );
};

export default WeeklyRow;
