import authService from "../../components/Auth/AuthorizeService";
import { ILocale } from "../../types/ILocale";

export class BaseService {

  protected transformOptions = async (options: RequestInit): Promise<RequestInit> => {
   let locale = localStorage.getItem('locale')
   if(locale) locale = JSON.parse(locale);
      let token = await authService.getAccessToken();

      options.headers = {
          ...options.headers,
          Authorization: `Bearer ${token}`,
          'accept-language':locale === ILocale.fr ? ILocale.fr:ILocale.en
      };
      return Promise.resolve(options);
  };
}
