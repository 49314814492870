import { ReactNode, useCallback, useState, useEffect } from 'react';
import DataItem from '../../../../components/DataItem/DataItem';
import Loading from '../../../../components/Loading/Loading';
import useErrorHandling, { IErrorsResponse } from '../../../../hooks/useErrorHandling';
import { IErrorsMsgAndType } from '../../../../components/ErrorsContainer/ErrorsContainer';
import useLocale from '../../../../hooks/useLocale';

import styles from './EmailContentDisplay.module.scss';

export type TEmailData = {
  fromName?: ReactNode | null;
  fromAddress?: ReactNode | null;
  toName?: ReactNode | null;
  toAddress?: ReactNode | null;
  subjectField?: ReactNode | null;
  bodyField?: ReactNode | null;
};

type TSetStateWithEmailData = React.Dispatch<React.SetStateAction<TEmailData | undefined>>;

type EmailContentDisplayCommonProps = {
  title?: ReactNode;
  errorsSetter?: React.Dispatch<React.SetStateAction<IErrorsMsgAndType[] | undefined>>;
  className?: string;
  style?: React.CSSProperties;
};

type EmailContentDisplayDataProps = TEmailData & EmailContentDisplayCommonProps;

type EmailContentDisplayApiProps = {
  apiGet?: () => Promise<any>;
  apiSetData?: (response: any, setState: TSetStateWithEmailData) => void; // allows you to manipulate response data before it is placed in state
} & EmailContentDisplayCommonProps;

type EmailContentDisplayProps = EmailContentDisplayDataProps | EmailContentDisplayApiProps;

const getFormattedNameAndAddressField = (name: ReactNode | null, address: ReactNode | null): ReactNode => {
  if (name && address) {
    return `${name} <${address}>`;
  } else if (!address) {
    return name;
  } else {
    return address;
  }
};

const EmailContentDisplay = ({
  title,
  errorsSetter: setErrors,
  className,
  style,
  ...props
}: EmailContentDisplayProps): JSX.Element => {
  const apiGet: EmailContentDisplayApiProps['apiGet'] | undefined = (props as any).apiGet;
  const apiSetData: EmailContentDisplayApiProps['apiSetData'] | undefined = (props as any).apiSetData;

  const fromName: ReactNode | undefined = (props as any).fromName;
  const fromAddress: ReactNode | undefined = (props as any).fromAddress;
  const toName: ReactNode | undefined = (props as any).toName;
  const toAddress: ReactNode | undefined = (props as any).toAddress;
  const subjectField: ReactNode | undefined = (props as any).subjectField;
  const bodyField: ReactNode | undefined = (props as any).bodyField;

  const { t } = useLocale();

  const { processResponseForErrors } = useErrorHandling();

  const [emailData, setEmailData] = useState<TEmailData | undefined>();
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const updateErrors = useCallback(
    (processedErrorsObj: IErrorsResponse) => {
      if (processedErrorsObj.messages == null) return;
      if (!setErrors) return;
      setErrors((prev) => {
        const prevMsgs = prev || [];
        const newMsgs = processedErrorsObj.messages || [];
        const allMsgs = [...prevMsgs, ...newMsgs];
        return allMsgs.length > 0 ? allMsgs : undefined;
      });
    },
    [setErrors]
  );

  const setData = useCallback(() => {
    // setIsLoading(true);
    // if (fromName || fromAddress || toName || toAddress || subjectField || bodyField) {
    if (!apiGet) {
      setEmailData({
        fromName: fromName || '',
        fromAddress: fromAddress || '',
        toName: toName || '',
        toAddress: toAddress || '',
        subjectField: subjectField || '',
        bodyField: bodyField || '',
      });
      setIsLoading(false);
      return false;
    }

    if (apiGet) {
      // setIsLoading(true);
      apiGet()
        .then((response) => {
          setIsLoading(false);
          const responseErrors = processResponseForErrors(response);
          updateErrors(responseErrors);
          if (responseErrors.hasErrors) return;
          // set dataItems in state
          if (apiSetData) {
            apiSetData(response, setEmailData);
          } else {
            setEmailData(response);
          }
        })
        .catch((error) => {
          const requestErrors = processResponseForErrors(error);
          updateErrors(requestErrors);
          // console.error('There was an error retrieving Email data');
        });
    }
  }, [
    fromName,
    fromAddress,
    toName,
    toAddress,
    subjectField,
    bodyField,
    apiGet,
    apiSetData,
    processResponseForErrors,
    updateErrors,
  ]);

  useEffect(() => {
    setData();
  }, [setData]);

  return (
    <div className={styles.EmailContentDisplay}>
      {title && <div className={styles.title}>{title}</div>}
      {isLoading && <Loading noText />}
      {(emailData || !isLoading) && (
        <>
          <DataItem
            className={styles.email_element}
            layout="vertical"
            noColPadding
            label={t.EMAIL_FIELD_FROM}
            value={{
              value: getFormattedNameAndAddressField(emailData?.fromName, emailData?.fromAddress),
              size: 'small',
            }}
          />
          <DataItem
            className={styles.email_element}
            layout="vertical"
            noColPadding
            label={t.EMAIL_FIELD_TO}
            value={{
              value: getFormattedNameAndAddressField(emailData?.toName, emailData?.toAddress),
              size: 'small',
            }}
          />
          <DataItem
            className={styles.email_element}
            layout="vertical"
            noColPadding
            label={t.EMAIL_FIELD_SUBJECT}
            value={{ value: `${emailData?.subjectField}`, size: 'small' }}
          />
          <div className={styles.body}>
            {typeof emailData?.bodyField === 'string' ? (
              // emailData?.bodyField?.replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, '<br />')
              <pre
                style={{
                  overflowX: 'auto',
                  whiteSpace: 'pre-wrap',
                  wordWrap: 'break-word',
                }}
              >
                {emailData?.bodyField}
              </pre>
            ) : (
              emailData?.bodyField
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default EmailContentDisplay;
