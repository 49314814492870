import { Row, Col, Tooltip } from 'antd';
import { useNavigate } from 'react-router-dom';
import { Icon } from '@fluentui/react/lib/Icon';

import AddIcon from '../../../images/add_icon.svg';

import { ROUTES } from '../../../constants/routes';
import { eventBus } from '../../../utils/eventBus';
import { NEW_TASK_EVENT } from '../../../constants/eventBus';
import { DOCUMENTS_TAB } from '../../ApplicationOverviewPage/ApplicationOverviewContent/ApplicationOverviewContent';
import useLocale from '../../../hooks/useLocale';

import './ActionCell.scss';

const ActionCell = ({ fileId }: { fileId?: string }) => {
  const navigate = useNavigate();
  const { t } = useLocale();

  if (!fileId) return <></>;

  return (
    <Row gutter={10} className="ActionCell" wrap={false} onClick={(e) => e.stopPropagation()}>
      <Col>
        <Tooltip title={t.ADD_APPOINTMENT_UPPER}>
          <Icon
            iconName="Calendar"
            onClick={(e) => {
              e.stopPropagation();
              navigate(`${ROUTES.APPLICATION_OVERVIEW}/${fileId}/${ROUTES.APPOINTMENT_SCHEDULING}`);
            }}
          />
        </Tooltip>
      </Col>
      <Col>
        <Tooltip title={t.DOCUMENTS}>
          <Icon
            iconName="Page"
            onClick={(e) => {
              e.stopPropagation();
              navigate(`${ROUTES.APPLICATION_OVERVIEW}/${fileId}`, {
                state: { preselectedTab: DOCUMENTS_TAB },
              });
            }}
          />
        </Tooltip>
      </Col>
      <Col>
        <Tooltip title={t.ADD_TASK}>
          <img
            src={AddIcon}
            alt="add-icon"
            onClick={(e) => {
              e.stopPropagation();
              eventBus.dispatch(NEW_TASK_EVENT);
              navigate(`${ROUTES.APPLICATION_OVERVIEW}/${fileId}/${ROUTES.TASKS_HISTORY}`);
            }}
            width={15}
            height={15}
          />
        </Tooltip>
      </Col>
    </Row>
  );
};

export default ActionCell;
