import { Form, Row, Col, Divider, FormInstance } from 'antd';
import PersonalInformationPreviousInsolvencyItem from './PersonalInformationPreviousInsolvencyItem';
import { ClientService } from '../../../../shared/api/ClientService';
import useLocale from '../../../../hooks/useLocale';

import './ApplicationFormTab.scss';

interface IProps {
  form: FormInstance<ClientService.AppFormPersonalInfoDto>;
  name: string;
  isLocked?: boolean;
}

const PersonalInformationPreviousInsolvency = ({ form, name, isLocked = false }: IProps): JSX.Element => {
  const { t } = useLocale();

  return (
    <Form.List name={name}>
      {(previousInsolvencies, { add, remove }) => (
        <>
          {previousInsolvencies?.map((row, index) => (
            <>
              <PersonalInformationPreviousInsolvencyItem
                form={form}
                name={name}
                rowName={row.name}
                remove={remove}
                isLocked={isLocked}
                listName={name}
              />
              {previousInsolvencies?.length - 1 !== index && <Divider />}
            </>
          ))}
          {!isLocked && (
            <Row gutter={20}>
              <Col span={8}>
                <Form.Item noStyle>
                  <div onClick={() => add()} className="ApplicationFormTab__link-left">
                    {t.ADD_INSOLVENCY}
                  </div>
                </Form.Item>
              </Col>
            </Row>
          )}
          <i>{t.DISCLOSE_ALL_PREVIOUS_FILINGS}</i>
        </>
      )}
    </Form.List>
  );
};

export default PersonalInformationPreviousInsolvency;
