import { useState, useCallback, useEffect, useMemo } from 'react';
import {
  Modal,
  Tabs,
  Form,
  Input,
  Select,
  Row,
  Col,
  Checkbox,
  InputNumber,
  DatePicker,
  Tooltip,
  Spin,
  message,
} from 'antd';
import { DeleteOutlined } from '@ant-design/icons';

import Button from '../../../../components/Button/Button';
import { ClientService } from '../../../../shared/api/ClientService';
import useLocale from '../../../../hooks/useLocale';
import API from '../../../../utils/api';
import TextArea from 'antd/lib/input/TextArea';
import { Label } from '@fluentui/react';
import { Icon } from '@fluentui/react/lib/Icon';
import SearchLocationInput from '../../../../components/SearchLocationInput/SearchLocationInput';
import { LocationDto } from '../../../../modals/GoogleMaps/types';
import AttachmentsTab, { AttachmentsFormProps, convertIdToLookupCodeName } from '../../../../components/AttachmentsTab';
import eventBus from '../../../../utils/eventBus';
import { UPLOAD_FILES_EVENT } from '../../../../constants/eventBus';
import SelectSuffixIcon from '../../../../components/SelectSuffixIcon/SelectSuffixIcon';

import './ApplicationFormTab.scss';
import { antdUtil } from '../../../../utils/antdUtil';
import styles from '../../../../styles/style.module.scss';
import { OverrideInfo } from '../../../../components/AttachmentsTab/types';
import ChangeTrackerFormItem from '../../../../components/ChangeTrackerFormItem';

export interface IOtherAssetProp {
  assetId?: string;
  assetDescriptionEnum?: ClientService.AssetDescriptionEnum;
  isExempt?: boolean;
}

interface IProps {
  assetId?: string;
  fileId: string;
  isMissingDetails?: boolean;
  isMissingSupportingDocuments?: boolean;
  onNext?: (otherAsset?: IOtherAssetProp) => void;
  onCancel?: () => void;
  isLocked?: boolean;
}

interface IForm extends ClientService.AppFormAssetsDebtsAssetDto, AttachmentsFormProps {}

function AssetsDebtsAsset({
  assetId,
  fileId,
  isMissingDetails,
  isMissingSupportingDocuments,
  onNext,
  onCancel,
  isLocked = false,
}: IProps): JSX.Element {
  const { t } = useLocale();
  const { TabPane } = Tabs;
  const { Option } = Select;
  const [form] = Form.useForm<IForm>();

  const [title, setTitle] = useState('');
  const [subTitle, setSubTitle] = useState('');

  const [isJointFile, setIsJointFile] = useState(false);
  const [isManitoba, setIsManitoba] = useState(false);
  const [hasPrincipalResidence, setHasPrincipalResidence] = useState(false);

  const [initialTypeId, setInitialTypeId] = useState('');
  const [initialApplicantOwnership, setInitialApplicantOwnership] = useState<number | undefined>();
  const [initialSpouseOwnership, setInitialSpouseOwnership] = useState<number | undefined>();

  const [selectedTypeId, setSelectedTypeId] = useState('');
  const [types, setTypes] = useState<ClientService.LookupDto[]>([]);
  const [typesLoading, setTypesLoading] = useState<boolean>(false);

  const [selectedDescriptionId, setSelectedDescriptionId] = useState('');
  const [descriptions, setDescriptions] = useState<ClientService.LookupDto[]>([]);
  const [descriptionsLoading, setDescriptionsLoading] = useState<boolean>(false);
  const [existingDocumentIds, setExistingDocumentIds] = useState<string[]>([]);

  const [ownershipTypes, setOwnershipTypes] = useState<ClientService.LookupDto[]>([]);
  const [ownershipTypesLoading, setOwnershipTypesLoading] = useState<boolean>(false);

  const [isOtherDescription, setIsOtherDescription] = useState(false);
  const [isTFSACashSavings, setIsTFSACashSavings] = useState(false);
  const [isRealEstate, setIsRealEstate] = useState(false);
  const [isInvestmentOrPolicy, setIsInvestmentOrPolicy] = useState(false);
  const [isLifeInsurance, setIsLifeInsurance] = useState(false);
  const [isRetirementFund, setIsRetirementFund] = useState(false);
  const [isRESP, setIsRESP] = useState(false);
  const [isOwnershipInterest, setIsOwnershipInterest] = useState(false);
  const [isTaxableInvestment, setIsTaxableInvestment] = useState(false);
  const [isSharedInvestment, setIsSharedInvestment] = useState(false);
  const [isMotorVehicle, setIsMotorVehicle] = useState(false);
  const [isFurniture, setIsFurniture] = useState(false);
  const [isRecreationalEquipment, setIsRecreationalEquipment] = useState(false);
  const [isTaxRefund, setIsTaxRefund] = useState(false);
  const [isOtherType, setIsOtherType] = useState(false);

  const [formTabs, setFormTabs] = useState<string[]>(['basic']);
  const [activeTab, setActiveTab] = useState<string>('basic');

  const [prefixCodes, setPrefixCodes] = useState<(string | undefined)[]>();
  const [isInitialLoading, setIsInitialLoading] = useState<boolean>();
  const [uploadListenerStatus, setUploadListenerStatus] = useState<boolean>(false);

  const requestInitialData = useCallback(async () => {
    setIsInitialLoading(true);
    const appFormAssetConfig = await API.getAppFormAssetConfig(fileId).catch(() => setIsInitialLoading(false));

    if (appFormAssetConfig) {
      setIsJointFile(appFormAssetConfig?.isJointFile || false);
      setIsManitoba(appFormAssetConfig?.isManitoba || false);
      setHasPrincipalResidence(appFormAssetConfig?.hasPrincipalResidence || false);
    }

    const assetTypes = await API.listAssetTypes().catch(() => setIsInitialLoading(false));
    if (assetTypes) setTypes(assetTypes);

    const ownershipTypes = await API.listAssetDebtOwnershipTypes().catch(() => setIsInitialLoading(false));
    if (ownershipTypes) setOwnershipTypes(ownershipTypes);

    if (!assetId) {
      setTitle(t.NEW_ASSET);

      form.setFieldsValue({ totalNumberOfOwners: 1, debtorsOwnershipInterest: 100 });
      setIsInitialLoading(false);
      return;
    } else {
      setTitle(t.EDIT_ASSET);

      const appFormAsset = await API.getAppFormAsset(assetId).catch(() => setIsInitialLoading(false));
      if (appFormAsset) {
        form.setFieldsValue(appFormAsset);

        setInitialTypeId(appFormAsset?.assetTypeId || '');
        setInitialApplicantOwnership(appFormAsset?.applicantOwnershipPercentage);
        setInitialSpouseOwnership(appFormAsset?.spouseOwnershipPercentage);

        setSelectedTypeId(appFormAsset?.assetTypeId || '');
        setSelectedDescriptionId(appFormAsset?.assetDescriptionId || '');
        setExistingDocumentIds(appFormAsset?.documentIds || []);
      }

      setIsInitialLoading(false);
    }
  }, [assetId, fileId, form, t.EDIT_ASSET, t.NEW_ASSET]);

  useEffect(() => {
    requestInitialData();
  }, [requestInitialData]);

  useEffect(() => {
    if (!assetId) {
      if (isJointFile) {
        form.setFieldsValue({ applicantOwnershipPercentage: 50 });
        form.setFieldsValue({ spouseOwnershipPercentage: 50 });
        form.setFieldsValue({ otherOwnershipPercentage: 0 });
      } else {
        form.setFieldsValue({ applicantOwnershipPercentage: 100 });
        form.setFieldsValue({ spouseOwnershipPercentage: 0 });
        form.setFieldsValue({ otherOwnershipPercentage: 0 });
      }
    } else {
      if (initialApplicantOwnership == undefined || initialSpouseOwnership == undefined) {
        const type = types.find((item) => item?.id === initialTypeId);
        const typeVal = type?.enumValue;

        if (typeVal === ClientService.AssetTypeEnum.RealEstate) {
          form.setFieldsValue({ applicantOwnershipPercentage: initialApplicantOwnership });
          form.setFieldsValue({ spouseOwnershipPercentage: initialSpouseOwnership });
          form.setFieldsValue({ otherOwnershipPercentage: 100 });
        } else {
          if (isJointFile) {
            form.setFieldsValue({ applicantOwnershipPercentage: 50 });
            form.setFieldsValue({ spouseOwnershipPercentage: 50 });
            form.setFieldsValue({ otherOwnershipPercentage: 0 });
          } else {
            form.setFieldsValue({ applicantOwnershipPercentage: 100 });
            form.setFieldsValue({ spouseOwnershipPercentage: 0 });
            form.setFieldsValue({ otherOwnershipPercentage: 0 });
          }
        }
      } else {
        form.setFieldsValue({ applicantOwnershipPercentage: initialApplicantOwnership });
        form.setFieldsValue({ spouseOwnershipPercentage: initialSpouseOwnership });
        form.setFieldsValue({ otherOwnershipPercentage: 100 - initialApplicantOwnership - initialSpouseOwnership });
      }
    }
  }, [assetId, initialTypeId, initialApplicantOwnership, initialSpouseOwnership, isJointFile]);

  useEffect(() => {
    if (!selectedTypeId) return;
    setDescriptionsLoading(true);

    API.listAssetDescriptions(selectedTypeId).then((response) => {
      setDescriptions(response);
      setDescriptionsLoading(false);

      if (response.length === 1) {
        form.setFieldsValue({ assetDescriptionId: response[0].id });
      }
    });

    const selType = types.find((item) => item?.id === selectedTypeId);
    const selTypeVal = selType?.enumValue;

    setIsRealEstate(selTypeVal === ClientService.AssetTypeEnum.RealEstate);
    setIsInvestmentOrPolicy(selTypeVal === ClientService.AssetTypeEnum.InvestmentsOrPolicies);
    setIsMotorVehicle(selTypeVal === ClientService.AssetTypeEnum.MotorVehicles);
    setIsFurniture(selTypeVal === ClientService.AssetTypeEnum.Furniture);
    setIsRecreationalEquipment(selTypeVal === ClientService.AssetTypeEnum.RecreationalEquipment);
    setIsTaxRefund(selTypeVal === ClientService.AssetTypeEnum.TaxRefund);
    setIsOtherType(selTypeVal === ClientService.AssetTypeEnum.Other);
  }, [form, selectedTypeId, types]);

  useEffect(() => {
    const selType = types.find((item) => item?.id === selectedTypeId);
    const selTypeVal = selType?.enumValue;

    if (selTypeVal === ClientService.AssetTypeEnum.RealEstate && !hasPrincipalResidence) {
      form.setFieldsValue({ isPrincipalResidence: true });
    }
  }, [selectedTypeId, types, hasPrincipalResidence, form]);

  useEffect(() => {
    const selDesc = descriptions?.find((item) => item?.id === selectedDescriptionId);
    const selDescVal = selDesc?.enumValue;

    setSubTitle(selDesc?.name || '');

    setIsTFSACashSavings(selDescVal === ClientService.AssetDescriptionEnum.TFSA);

    setIsOtherDescription(
      selDescVal === ClientService.AssetDescriptionEnum.Other_CashOrSavings ||
        selDescVal === ClientService.AssetDescriptionEnum.Other_Furniture ||
        selDescVal === ClientService.AssetDescriptionEnum.Other_PersonalEffects ||
        selDescVal === ClientService.AssetDescriptionEnum.Other_InvestmentsOrPolicies ||
        selDescVal === ClientService.AssetDescriptionEnum.Other_RealEstate ||
        selDescVal === ClientService.AssetDescriptionEnum.Other_RecreationalEquipment
    );
    setIsLifeInsurance(selDescVal === ClientService.AssetDescriptionEnum.LifeInsurance);
    setIsRetirementFund(
      selDescVal === ClientService.AssetDescriptionEnum.RRSP || selDescVal === ClientService.AssetDescriptionEnum.RDSP
    );
    setIsRESP(selDescVal === ClientService.AssetDescriptionEnum.RESP);
    setIsOwnershipInterest(
      selDescVal === ClientService.AssetDescriptionEnum.RESP ||
        selDescVal === ClientService.AssetDescriptionEnum.Shares ||
        selDescVal === ClientService.AssetDescriptionEnum.Bonds ||
        selDescVal === ClientService.AssetDescriptionEnum.Investments ||
        selDescVal === ClientService.AssetDescriptionEnum.RRIF ||
        selDescVal === ClientService.AssetDescriptionEnum.LIRA ||
        selDescVal === ClientService.AssetDescriptionEnum.Pension ||
        selDescVal === ClientService.AssetDescriptionEnum.CoopShares ||
        selDescVal === ClientService.AssetDescriptionEnum.CreditUnionShares ||
        selDescVal === ClientService.AssetDescriptionEnum.Other_InvestmentsOrPolicies
    );
    setIsTaxableInvestment(
      selDescVal === ClientService.AssetDescriptionEnum.Shares ||
        selDescVal === ClientService.AssetDescriptionEnum.Bonds ||
        selDescVal === ClientService.AssetDescriptionEnum.Investments ||
        selDescVal === ClientService.AssetDescriptionEnum.RRIF ||
        selDescVal === ClientService.AssetDescriptionEnum.LIRA ||
        selDescVal === ClientService.AssetDescriptionEnum.Pension
    );
    setIsSharedInvestment(
      selDescVal === ClientService.AssetDescriptionEnum.CoopShares ||
        selDescVal === ClientService.AssetDescriptionEnum.CreditUnionShares ||
        selDescVal === ClientService.AssetDescriptionEnum.Other_InvestmentsOrPolicies
    );
  }, [selectedDescriptionId, descriptions]);

  useEffect(() => {
    if (!assetId) {
      let defaultOwnershipType = ownershipTypes.find(
        (item) => item.enumValue == ClientService.AssetDebtOwnershipTypeEnum.Applicant
      );

      form.setFieldsValue({ ownershipTypeId: defaultOwnershipType?.id });
    }
  }, [assetId, ownershipTypes]);

  const handleTypeSelect = (value: string, option: any) => {
    setSelectedTypeId(value);

    form.setFieldsValue({ assetDescriptionId: undefined });
  };

  const handleDescriptionSelect = (value: string, option: any) => {
    setSelectedDescriptionId(value);
  };

  const handleApplicantOwnershipChanged = (value: 0 | 100 | null) => {
    let spouseOwnership = form.getFieldValue('spouseOwnershipPercentage') as number;

    if (value) {
      if (value + spouseOwnership > 100) {
        form.setFieldsValue({ spouseOwnershipPercentage: 100 - value });
        form.setFieldsValue({ otherOwnershipPercentage: 0 });
      } else {
        form.setFieldsValue({ otherOwnershipPercentage: 100 - value - spouseOwnership });
      }
    }
  };

  const handleSpouseOwnershipChanged = (value: 0 | 100 | null) => {
    let applicantOwnership = form.getFieldValue('applicantOwnershipPercentage') as number;

    if (value) {
      if (value + applicantOwnership > 100) {
        form.setFieldsValue({ applicantOwnershipPercentage: 100 - value });
        form.setFieldsValue({ otherOwnershipPercentage: 0 });
      } else {
        form.setFieldsValue({ otherOwnershipPercentage: 100 - value - applicantOwnership });
      }
    }
  };

  const handleRealEstateAssetAddressAutofill = useCallback(
    async ({ address, city, postalCode, streetName, streetNumber, country, province }: LocationDto) => {
      const countries = await API.listCountries();
      const countryId = countries?.find((item) => item.code === country?.code)?.id;

      const provinces = await API.listProvinces(countryId);
      const provinceId = provinces.find((item) => item.code === province?.code)?.id;

      form.setFieldsValue({ address, city, postalCode, streetName, streetNumber, provinceId, countryId });
    },
    [form]
  );

  useEffect(() => {
    if (isRealEstate || isInvestmentOrPolicy || isMotorVehicle || isRecreationalEquipment) {
      setFormTabs(['basic', 'details', 'documents']);
    } else if (isTFSACashSavings) {
      setFormTabs(['basic', 'documents']);
    } else {
      setFormTabs(['basic']);
    }
  }, [isTFSACashSavings, isInvestmentOrPolicy, isMotorVehicle, isRealEstate, isRecreationalEquipment]);

  const handleNextStepOrSubmit = useCallback(() => {
    const activeKeyIndex = formTabs.indexOf(activeTab);

    if (formTabs?.length - 1 > activeKeyIndex) {
      setActiveTab(formTabs[activeKeyIndex + 1]);
    } else {
      if (isLocked) return;

      const overriderInfo = form.getFieldValue('SupportingDocOverrides');
      form.setFieldsValue({ SupportingDocOverrides: [] });

      const values = form.getFieldsValue();
      values.fileId = fileId;
      values.documentIds = [];

      setIsInitialLoading(true);

      if (uploadListenerStatus) {
        eventBus.dispatch(UPLOAD_FILES_EVENT, {
          onUploadSuccess: async (_documentIds?: string[]) => {
            if (_documentIds != undefined && _documentIds.length > 0) {
              values.documentIds = [..._documentIds];
            }

            if (overriderInfo) {
              const docFolderId = (await API.listFolders()).find((item) => item.code === 'assets')?.id;

              const updateApplicabilityOverrideInput = new ClientService.SupportingDocumentApplicabilityOverrideDto({
                documentFolderId: docFolderId,
                fileId: fileId,
                supportingDocumentApplicabilityOverrideItems: (overriderInfo as OverrideInfo[])
                  .filter((i) => i.isOverridden)
                  .map(
                    ({ documentPrefixId, overrideReason }) =>
                      new ClientService.SupportingDocumentApplicabilityOverrideItemDto({
                        documentPrefixId,
                        overrideReason,
                      })
                  ),
              });
              values.supportingDocumentOverrideInfo = updateApplicabilityOverrideInput;
            }

            if (assetId) {
              values.id = assetId;
              API.updateAppFormAsset(values)
                .then((_response) => {
                  const assetDescriptionEnum = descriptions?.find(
                    (item) => item.id === values.assetDescriptionId
                  )?.enumValue;
                  const isExempt = undefined ? false : values.isExempt;
                  const otherAsset = isOtherType ? { assetId, assetDescriptionEnum, isExempt } : undefined;

                  setIsInitialLoading(false);
                  if (onNext) onNext(otherAsset);
                })
                .catch(() => {
                  setIsInitialLoading(false);
                });
            } else {
              API.createAppFormAsset(values)
                .then((_response) => {
                  const assetDescriptionEnum = descriptions?.find(
                    (item) => item.id === values.assetDescriptionId
                  )?.enumValue;
                  const isExempt = undefined ? false : values.isExempt;
                  const otherAsset = isOtherType ? { assetId, assetDescriptionEnum, isExempt } : undefined;

                  setIsInitialLoading(false);
                  if (onNext) onNext(otherAsset);
                })
                .catch(() => {
                  setIsInitialLoading(false);
                });
            }
          },
          onUploadError: (errorMessage?: string) => {
            errorMessage && message.error(errorMessage);
            setIsInitialLoading(false);
            return;
          },
        });
      } else {
        if (assetId) {
          values.id = assetId;
          API.updateAppFormAsset(values)
            .then((response) => {
              const assetDescriptionEnum = descriptions?.find(
                (item) => item.id === values.assetDescriptionId
              )?.enumValue;
              const isExempt = undefined ? false : values.isExempt;
              const otherAsset = isOtherType ? { assetId, assetDescriptionEnum, isExempt } : undefined;

              setIsInitialLoading(false);
              if (onNext) onNext(otherAsset);
            })
            .catch(() => {
              setIsInitialLoading(false);
            });
        } else {
          API.createAppFormAsset(values)
            .then((response) => {
              const assetDescriptionEnum = descriptions?.find(
                (item) => item.id === values.assetDescriptionId
              )?.enumValue;
              const isExempt = undefined ? false : values.isExempt;
              const otherAsset = isOtherType ? { assetId, assetDescriptionEnum, isExempt } : undefined;

              setIsInitialLoading(false);
              if (onNext) onNext(otherAsset);
            })
            .catch(() => {
              setIsInitialLoading(false);
            });
        }
      }
    }
  }, [formTabs, activeTab, isLocked, form, fileId, assetId, descriptions, isOtherType, onNext, uploadListenerStatus]);

  const handleTabChange = useCallback((key) => form.validateFields().then(() => setActiveTab(key)), [form]);

  const onFormSubmit = useCallback(async () => {
    form
      .validateFields()
      .then(handleNextStepOrSubmit)
      .catch((error) => console.log(error));
  }, [form, handleNextStepOrSubmit]);

  useEffect(() => {
    setPrefixCodes([
      [
        convertIdToLookupCodeName(form.getFieldValue('assetTypeId'), types, ClientService.AssetTypeEnum),
        convertIdToLookupCodeName(
          form.getFieldValue('assetDescriptionId'),
          descriptions,
          ClientService.AssetDescriptionEnum
        ),
      ]?.join('.'),
    ]);
  }, [activeTab, types, form, descriptions]);

  const isLastTab = useMemo(() => formTabs?.length - 1 === formTabs.indexOf(activeTab), [activeTab, formTabs]);

  return (
    <Modal
      destroyOnClose
      centered
      visible
      title={
        <>
          <div>{title}</div>
          <div className="ApplicationFormTab__modal-sub-title">{subTitle}</div>
        </>
      }
      width={900}
      footer={null}
      onCancel={onCancel}
      className="ApplicationFormTab__modal"
      closable
      maskClosable={false}
    >
      <Spin spinning={isInitialLoading}>
        <Form form={form} layout="vertical" validateMessages={antdUtil.validateMessages}>
          <Tabs className="Tabs" activeKey={activeTab} onChange={handleTabChange}>
            <TabPane tab={t.BASIC} key="basic" forceRender>
              <Row gutter={20}>
                <Col span={12}>
                  <ChangeTrackerFormItem name="assetTypeId" label={t.TYPE} rules={[{ required: true }]}>
                    <Select
                      loading={typesLoading}
                      onChange={handleTypeSelect}
                      suffixIcon={<SelectSuffixIcon />}
                      size="large"
                      disabled={isLocked}
                    >
                      {types?.map((option) => (
                        <Option key={option.id} value={option?.id}>
                          {option?.name}
                        </Option>
                      ))}
                    </Select>
                  </ChangeTrackerFormItem>
                </Col>
                <Col span={12}>
                  <ChangeTrackerFormItem name="assetDescriptionId" label={t.DESCRIPTION} rules={[{ required: true }]}>
                    <Select
                      loading={descriptionsLoading}
                      onChange={handleDescriptionSelect}
                      suffixIcon={<SelectSuffixIcon />}
                      size="large"
                      disabled={isLocked}
                    >
                      {descriptions?.map((option) => (
                        <Option key={option.id} value={option?.id}>
                          {t[option?.localizationString || '']}
                        </Option>
                      ))}
                    </Select>
                  </ChangeTrackerFormItem>
                </Col>
                {isOtherDescription ? (
                  <Col span={24}>
                    <ChangeTrackerFormItem
                      name="specifiedDescription"
                      label={t.OTHER_SPECIFY}
                      rules={[{ required: true }]}
                    >
                      <TextArea size="large" disabled={isLocked} />
                    </ChangeTrackerFormItem>
                  </Col>
                ) : (
                  <></>
                )}
                <Col span={12}>
                  <ChangeTrackerFormItem
                    name="value"
                    label={
                      <Row align="middle">
                        <Col>Value</Col>
                        <Col>
                          {(isRealEstate || isMotorVehicle || isRecreationalEquipment || isInvestmentOrPolicy) && (
                            <Tooltip
                              title={
                                isInvestmentOrPolicy ? t.ASSETS_CURRENT_MARKET_VALUE : t.ASSETS_ESTIMATED_RESALE_VALUE
                              }
                            >
                              <Icon
                                iconName="Info"
                                className="ApplicationFormTab__tooltip"
                                style={{ fontWeight: 'bold' }}
                              />
                            </Tooltip>
                          )}
                        </Col>
                      </Row>
                    }
                    rules={[{ required: true, message: t.REQUIRED_FIELD }]}
                  >
                    <InputNumber
                      size="large"
                      style={{ width: '100%' }}
                      min={1}
                      max={999999999}
                      formatter={(value) => antdUtil.currencyFormatter(value)}
                      parser={(value) => antdUtil.currencyParser(value) as 1 | 999999999}
                      disabled={isLocked}
                    />
                  </ChangeTrackerFormItem>
                </Col>
                <Col span={12}>
                  <ChangeTrackerFormItem name="ownershipTypeId" label={t.OWNERSHIP}>
                    <Select
                      loading={ownershipTypesLoading}
                      suffixIcon={<SelectSuffixIcon />}
                      size="large"
                      disabled={isLocked}
                    >
                      {ownershipTypes?.map((option) => (
                        <Option key={option.id} value={option?.id}>
                          {option?.name}
                        </Option>
                      ))}
                    </Select>
                  </ChangeTrackerFormItem>
                </Col>
                <Col span={24}>
                  <ChangeTrackerFormItem name="isExempt" valuePropName="checked">
                    <Checkbox disabled={isLocked}>{t.EXEMPT}</Checkbox>
                  </ChangeTrackerFormItem>
                </Col>
                <Col span={24}>
                  <ChangeTrackerFormItem name="comments" label={t.COMMENTS}>
                    <TextArea size="large" maxLength={200} disabled={isLocked} />
                  </ChangeTrackerFormItem>
                </Col>
              </Row>

              <Form.List name="securedToDebts">
                {(securedToDebts, { add, remove }) => (
                  <>
                    {Boolean(securedToDebts?.length) && (
                      <Row>
                        <Label className="ApplicationFormTab__label">{t.SECURED_TO}</Label>
                      </Row>
                    )}
                    {securedToDebts?.map((row, index) => (
                      <Row gutter={20} align="middle" key={`securedToDebts-${index}`}>
                        <Col span={22}>
                          <ChangeTrackerFormItem name={[row.name, 'displayName']} listName="securedToDebts">
                            <Input size="large" disabled />
                          </ChangeTrackerFormItem>
                        </Col>
                        <Col span={2}>
                          {!isLocked && (
                            <DeleteOutlined
                              className="ApplicationFormTab__input-action-icon"
                              onClick={() => {
                                if (isLocked) return;
                                remove(row.name);
                              }}
                              style={{
                                cursor: !isLocked ? 'pointer' : 'not-allowed',
                                color: !isLocked ? styles.colorDanger : styles.colorSecondary,
                              }}
                            />
                          )}
                        </Col>
                      </Row>
                    ))}
                  </>
                )}
              </Form.List>
            </TabPane>

            {formTabs.indexOf('details') >= 0 && (
              <TabPane
                tab={
                  <Row align="middle" gutter={5}>
                    <Col>{t.DETAILS}</Col>
                    <Col>
                      {isMissingDetails && (
                        <Icon iconName="AlertSolid" className="ApplicationFormTab__tab-error-icon" />
                      )}
                    </Col>
                  </Row>
                }
                key="details"
                forceRender
              >
                {/* Real Estate */}
                {Boolean(isRealEstate) && (
                  <>
                    <ChangeTrackerFormItem noStyle name="city" />
                    <ChangeTrackerFormItem noStyle name="postalCode" />
                    <ChangeTrackerFormItem noStyle name="provinceId" />
                    <ChangeTrackerFormItem noStyle name="countryId" />
                    <ChangeTrackerFormItem noStyle name="streetName" />
                    <ChangeTrackerFormItem noStyle name="streetNumber" />
                    <Row gutter={20}>
                      <Col span={24}>
                        <ChangeTrackerFormItem
                          name="address"
                          className="ApplicationFormTab__input-label-error"
                          label={t.ADDRESS}
                        >
                          <SearchLocationInput
                            address={{ address: form.getFieldValue('address') }}
                            onSelect={handleRealEstateAssetAddressAutofill}
                            size="large"
                            disabled={isLocked}
                          />
                        </ChangeTrackerFormItem>
                      </Col>

                      <Col span={12}>
                        <ChangeTrackerFormItem name="unit" label={t.UNIT}>
                          <Input size="large" disabled={isLocked} />
                        </ChangeTrackerFormItem>
                      </Col>
                    </Row>
                    <Row gutter={20}>
                      <Col span={12}>
                        <ChangeTrackerFormItem
                          name="applicantOwnershipPercentage"
                          className="ApplicationFormTab__input-label-warning"
                          label={t.APPLICANT_OWNERSHIP_PERCENTAGE}
                        >
                          <InputNumber
                            size="large"
                            style={{ width: '100%' }}
                            min={0}
                            max={100}
                            formatter={(value) => antdUtil.percentFormatter(value)}
                            parser={(value) => antdUtil.percentParser(value) as 0 | 100}
                            onChange={handleApplicantOwnershipChanged}
                            disabled={isLocked}
                          />
                        </ChangeTrackerFormItem>
                      </Col>
                      <Col span={12}>
                        <ChangeTrackerFormItem
                          name="spouseOwnershipPercentage"
                          className="ApplicationFormTab__input-label-warning"
                          label={t.SPOUSE_OWNERSHIP_PERCENTAGE}
                        >
                          <InputNumber
                            size="large"
                            style={{ width: '100%' }}
                            min={0}
                            max={100}
                            formatter={(value) => antdUtil.percentFormatter(value)}
                            parser={(value) => antdUtil.percentParser(value) as 0 | 100}
                            onChange={handleSpouseOwnershipChanged}
                            disabled={isLocked}
                          />
                        </ChangeTrackerFormItem>
                      </Col>
                      <Col span={12}>
                        <ChangeTrackerFormItem
                          name="totalNumberOfOwners"
                          className="ApplicationFormTab__input-label-error"
                          label={t.TOTAL_NUMBER_OF_OWNERS}
                        >
                          <InputNumber size="large" style={{ width: '100%' }} min={1} max={99} disabled={isLocked} />
                        </ChangeTrackerFormItem>
                      </Col>
                      <Col span={12}>
                        <ChangeTrackerFormItem name="otherOwnershipPercentage" label={t.OTHER_OWNERSHIP_PERCENTAGE}>
                          <InputNumber
                            size="large"
                            style={{ width: '100%' }}
                            min={0}
                            max={100}
                            formatter={(value) => antdUtil.percentFormatter(value)}
                            parser={(value) => antdUtil.percentParser(value) as 0 | 100}
                            disabled
                          />
                        </ChangeTrackerFormItem>
                      </Col>
                      {isManitoba && (
                        <Col span={12}>
                          <ChangeTrackerFormItem name="isOwnershipHeldInJointTenancy" valuePropName="checked">
                            <Checkbox disabled={isLocked}>{t.OWNERSHIP_HELD_IN_JOINT_TENANCY}</Checkbox>
                          </ChangeTrackerFormItem>
                        </Col>
                      )}
                      <Col span={12}>
                        <ChangeTrackerFormItem name="isPrincipalResidence" valuePropName="checked">
                          <Checkbox disabled={form.getFieldValue('isPrincipalResidence') || isLocked}>
                            {t.PRINCIPAL_RESIDENCE}
                          </Checkbox>
                        </ChangeTrackerFormItem>
                      </Col>
                    </Row>
                  </>
                )}

                {/* Investments / Policies */}
                {Boolean(isInvestmentOrPolicy) && (
                  <Row gutter={20}>
                    <Col span={12}>
                      <ChangeTrackerFormItem
                        name="nameOfInstitution"
                        className="ApplicationFormTab__input-label-error"
                        label={t.NAME_OF_INSTITUTION}
                      >
                        <Input size="large" disabled={isLocked} />
                      </ChangeTrackerFormItem>
                    </Col>
                    <Col span={12}>
                      <ChangeTrackerFormItem name="policyNumber" label={t.POLICY_NUMBER}>
                        <Input size="large" disabled={isLocked} />
                      </ChangeTrackerFormItem>
                    </Col>
                    {Boolean(isRetirementFund) && (
                      <Col span={12}>
                        <ChangeTrackerFormItem
                          name="contributionsInLast12Months"
                          className="ApplicationFormTab__input-label-error"
                          label={t.CONTRIBUTIONS_IN_LAST_12_MONTHS}
                        >
                          <InputNumber
                            size="large"
                            style={{ width: '100%' }}
                            min={0}
                            max={999999999}
                            formatter={(value) => antdUtil.currencyFormatter(value)}
                            parser={(value) => antdUtil.currencyParser(value) as 0 | 999999999}
                            disabled={isLocked}
                          />
                        </ChangeTrackerFormItem>
                      </Col>
                    )}
                    {Boolean(isRESP) && (
                      <Col span={12}>
                        <ChangeTrackerFormItem
                          name="grantToBeRepaid"
                          className="ApplicationFormTab__input-label-warning"
                          label={t.GRANT_TO_BE_REPAID}
                        >
                          <InputNumber
                            size="large"
                            style={{ width: '100%' }}
                            min={0}
                            max={999999999}
                            formatter={(value) => antdUtil.currencyFormatter(value)}
                            parser={(value) => antdUtil.currencyParser(value) as 0 | 999999999}
                            disabled={isLocked}
                          />
                        </ChangeTrackerFormItem>
                      </Col>
                    )}
                    {Boolean(isTaxableInvestment || isSharedInvestment) && (
                      <Col span={12}>
                        <ChangeTrackerFormItem name="estimatedTaxOnDisposition" label={t.ESTIMATED_TAX_ON_DISPOSITION}>
                          <InputNumber
                            size="large"
                            style={{ width: '100%' }}
                            min={0}
                            max={999999999}
                            formatter={(value) => antdUtil.currencyFormatter(value)}
                            parser={(value) => antdUtil.currencyParser(value) as 0 | 999999999}
                            disabled={isLocked}
                          />
                        </ChangeTrackerFormItem>
                      </Col>
                    )}
                    {Boolean(isOwnershipInterest) && (
                      <Col span={12}>
                        <ChangeTrackerFormItem name="debtorsOwnershipInterest" label={t.DEBTORS_OWNERSHIP_INTEREST}>
                          <InputNumber
                            size="large"
                            style={{ width: '100%' }}
                            min={0}
                            max={100}
                            formatter={(value) => antdUtil.percentFormatter(value)}
                            parser={(value) => antdUtil.percentParser(value) as 0 | 100}
                            disabled={isLocked}
                          />
                        </ChangeTrackerFormItem>
                      </Col>
                    )}
                  </Row>
                )}

                {/* Motor Vehicles */}
                {Boolean(isMotorVehicle) && (
                  <Row gutter={20}>
                    <Col span={12}>
                      <ChangeTrackerFormItem
                        name="year"
                        className="ApplicationFormTab__input-label-error"
                        label={t.YEAR}
                      >
                        <DatePicker
                          size="large"
                          picker="year"
                          style={{ width: '100%' }}
                          placeholder="yyyy"
                          disabled={isLocked}
                        />
                      </ChangeTrackerFormItem>
                    </Col>
                    <Col span={12}>
                      <ChangeTrackerFormItem
                        name="make"
                        className="ApplicationFormTab__input-label-error"
                        label={t.MAKE}
                      >
                        <Input size="large" disabled={isLocked} />
                      </ChangeTrackerFormItem>
                    </Col>
                    <Col span={12}>
                      <ChangeTrackerFormItem
                        name="model"
                        className="ApplicationFormTab__input-label-error"
                        label={t.MODEL}
                      >
                        <Input size="large" disabled={isLocked} />
                      </ChangeTrackerFormItem>
                    </Col>
                    <Col span={12}>
                      <ChangeTrackerFormItem name="trim" label={t.TRIM}>
                        <Input size="large" disabled={isLocked} />
                      </ChangeTrackerFormItem>
                    </Col>
                    <Col span={12}>
                      <ChangeTrackerFormItem name="style" label={t.STYLE}>
                        <Input size="large" disabled={isLocked} />
                      </ChangeTrackerFormItem>
                    </Col>
                    <Col span={12}>
                      <ChangeTrackerFormItem name="kilometers" label={t.KMS}>
                        <InputNumber
                          size="large"
                          style={{ width: '100%' }}
                          min={0}
                          max={999999999}
                          formatter={(value) => antdUtil.numberFormatter(value)}
                          parser={(value) => antdUtil.numberParser(value) as 0 | 999999999}
                          disabled={isLocked}
                        />
                      </ChangeTrackerFormItem>
                    </Col>
                    <Col span={12}>
                      <ChangeTrackerFormItem
                        name="vin"
                        className="ApplicationFormTab__input-label-warning"
                        label={t.VIN}
                      >
                        <Input size="large" disabled={isLocked} />
                      </ChangeTrackerFormItem>
                    </Col>
                    <Col span={12}>
                      <ChangeTrackerFormItem name="isVehicleNeededForEmployment" valuePropName="checked">
                        <Checkbox disabled={isLocked}>{t.VEHICLE_NEEDED_FOR_EMPLOYMENT}</Checkbox>
                      </ChangeTrackerFormItem>
                    </Col>
                  </Row>
                )}

                {Boolean(isRecreationalEquipment) && (
                  <Row gutter={20}>
                    <Col span={12}>
                      <ChangeTrackerFormItem name="basisOfValue" label={t.BASIS_OF_VALUE}>
                        <Input size="large" disabled={isLocked} />
                      </ChangeTrackerFormItem>
                    </Col>
                  </Row>
                )}
              </TabPane>
            )}

            {formTabs.indexOf('documents') >= 0 && (
              <TabPane
                tab={
                  <Row align="middle" gutter={5}>
                    <Col>{t.DOCUMENTS}</Col>
                    <Col>
                      {isMissingSupportingDocuments && (
                        <Icon iconName="AlertSolid" className="ApplicationFormTab__tab-error-icon" />
                      )}
                    </Col>
                  </Row>
                }
                key="documents"
              >
                <AttachmentsTab
                  form={form}
                  applicationFileId={fileId}
                  supportingFolderCode="assets"
                  prefixCodes={prefixCodes}
                  isFlatLayout
                  flatLayoutProps={{ hasInstruction: true, hasSupportingList: true, hasExistingFiles: true }}
                  columnsProps={{ hasSizeColumn: true, hasFolderColumn: false, isDeleteDisabled: isLocked }}
                  draggerProps={{ disabled: isLocked }}
                  existingDocumentIds={existingDocumentIds}
                  appFormAssetsDebtsAssetId={assetId}
                  updateUploadListenerStatus={setUploadListenerStatus}
                />
              </TabPane>
            )}
          </Tabs>

          <Row gutter={12} justify="end">
            <Col>
              <Button kind="cancel" padding="large" onClick={onCancel}>
                {t.CANCEL}
              </Button>
            </Col>
            <Col>
              <Button
                kind="primary"
                padding="large"
                onClick={onFormSubmit}
                disabled={!form.getFieldValue('assetTypeId') || (isLastTab && isLocked)}
              >
                {!isLastTab ? t.NEXT : t.SAVE}
              </Button>
            </Col>
          </Row>
        </Form>
      </Spin>
    </Modal>
  );
}

export default AssetsDebtsAsset;
