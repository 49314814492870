import DataItem from '../../../../components/DataItem/DataItem';

interface IProps {
  label?: string;
  amount?: number;
  isVoided?: boolean;
}

const AmountDataItem = ({ label, amount, isVoided = false }: IProps) => (
  <DataItem
    layout="vertical"
    colSpan={12}
    label={label}
    value={{
      value: amount || '',
      format: 'currency',
      color: 'gray-text',
      size: 'large',
    }}
    valueExtraText={
      isVoided
        ? {
            text: 'VOID',
            color: 'red',
            size: 'large',
          }
        : undefined
    }
  />
);

export default AmountDataItem;
