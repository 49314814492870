import { useCallback, useEffect, useState } from 'react';
import { Modal, Form, Select, Row, Col, Spin, Empty } from 'antd';

import Button from '../Button/Button';
import SelectSuffixIcon from '../SelectSuffixIcon/SelectSuffixIcon';

import { IDebtorSearchResult, IDebtorSearchCriteria } from './types';
import { readableDebtorOption, argumentifyDebtorSearchCriteria } from './utils';
import { MAX_PAGE_SIZE } from '../../constants/common';

import useLocale from '../../hooks/useLocale';
import useDebounce from '../../hooks/useDebounce';
import API from '../../utils/api';

import './DebtorSearchModal.scss';

interface IProps {
  searchCriteria?: IDebtorSearchCriteria;
  onOk: (debtorSearchResult?: IDebtorSearchResult) => void;
  onCancel: () => void;
}

interface IForm {
  fileId?: string;
}

function DebtorSearchModal({ searchCriteria = {}, onOk, onCancel }: IProps): JSX.Element {
  const { t } = useLocale();
  const [form] = Form.useForm<IForm>();
  const { Option } = Select;

  const [criteria, setCriteria] = useState<IDebtorSearchCriteria>(searchCriteria);

  const [source, setSource] = useState<IDebtorSearchResult[]>();

  const [copying, setCopying] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  const [searchString, setSearchString] = useState<string>();
  const value = useDebounce(searchString);

  const handleSearch = useCallback((searchString) => {
    setSearchString(searchString);
  }, []);

  const requestDebtors = useCallback(async (criteria: IDebtorSearchCriteria) => {
    if (!criteria?.searchString || (criteria?.searchString && criteria?.searchString?.length < 3)) return;

    setLoading(true);
    setSource(undefined);

    const response = await API.getDebtorSearchResults(
      ...argumentifyDebtorSearchCriteria({ ...criteria, pageSize: MAX_PAGE_SIZE })
    ).catch(() => setLoading(false));
    setLoading(false);

    if (response?.clients) {
      const result = response?.clients
        ?.map((item) =>
          item?.files?.map((file) => ({
            ...file,
            clientId: item?.clientId,
            firstName: item?.firstName,
            lastName: item?.lastName,
          }))
        )
        .flat() as IDebtorSearchResult[];

      setSource(result || []);
    }
  }, []);

  useEffect(() => {
    requestDebtors(criteria);
  }, [criteria, requestDebtors]);

  useEffect(() => {
    setCriteria((prev) => ({ ...prev, searchString: value }));
  }, [value]);
  return (
    <Modal
      title={t.SELECT_DEBTOR}
      visible
      closable
      onCancel={onCancel}
      className="DebtorSearchModal"
      footer={null}
      width={700}
    >
      <Form
        form={form}
        layout="vertical"
        onFinish={(values) => onOk(source?.find((item) => item.fileId === values?.fileId))}
      >
        <Form.Item name="fileId" label={t.DEBTOR} rules={[{ required: true, message: t.DEBTOR_REQUIRED }]}>
          <Select
            size="large"
            suffixIcon={<SelectSuffixIcon />}
            showSearch
            onSearch={handleSearch}
            filterOption={false}
            placeholder={t.SEARCH_NAME_EMAIL_PHN_FN_ESTATE_NO}
            notFoundContent={loading ? <Spin spinning={loading} /> : <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />}
          >
            {source?.map((option: IDebtorSearchResult) => (
              <Option key={option?.clientId + option?.fileName} value={option?.fileId}>
                {readableDebtorOption(option)}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Row gutter={10} justify="end">
          <Col>
            <Form.Item>
              <Button key="cancel" kind="cancel" padding="large" onClick={onCancel} style={{ marginRight: 6 }}>
                {t.CANCEL}
              </Button>
            </Form.Item>
          </Col>
          <Col>
            <Form.Item>
              <Button key="ok" padding="large" kind="primary" htmlType="submit">
                {t.NEXT}
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
}

export default DebtorSearchModal;
