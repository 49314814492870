import { useState, useCallback, useEffect, useMemo } from 'react';
import { Modal, Tabs, Form, Row, Col, Spin } from 'antd';
import Button from '../../../../components/Button/Button';
import { ClientService } from '../../../../shared/api/ClientService';
import useLocale from '../../../../hooks/useLocale';
import API from '../../../../utils/api';
import IncomeExpenseExpenseTab from './IncomeExpenseExpenseTab';
import { Icon } from '@fluentui/react/lib/Icon';

import AttachmentsTab, { AttachmentsFormProps, convertIdToLookupCodeName } from '../../../../components/AttachmentsTab';

import { IExpenseTab, ExpenseDescriptionLookupDto } from './types';

import './ApplicationFormTab.scss';

interface IProps {
  fileId?: string;
  data?: ClientService.AppFormIncomeExpenseExpenseDto;
  expenseTypes: ClientService.LookupDto[];
  hasDocumentsTab?: boolean;
  loading?: boolean;
  onSave: (body: ClientService.AppFormIncomeExpenseExpenseDto, uploadListenerStatus: boolean) => void;
  onCancel?: () => void;
  isLocked?: boolean;
  isMissingSupportingDocuments?: boolean;
}

interface IForm extends AttachmentsFormProps {
  tabs: IExpenseTab[];
}

function IncomeExpenseExpense({
  fileId,
  data,
  expenseTypes,
  onSave,
  onCancel,
  hasDocumentsTab = true,
  loading = false,
  isLocked,
  isMissingSupportingDocuments = false,
}: IProps): JSX.Element {
  const { t } = useLocale();
  const { TabPane } = Tabs;
  const [form] = Form.useForm<IForm>();

  const [isMarried, setIsMarried] = useState(false);
  const [formTabs, setFormTabs] = useState<string[]>();
  const [activeTab, setActiveTab] = useState<string>();
  const [documentsTabKey, setDocumentsTabKey] = useState<string>();

  const [initialFormExpense, setFormExpense] = useState<ClientService.AppFormIncomeExpenseExpenseDto[]>();
  const [prefixCodes, setPrefixCodes] = useState<(string | undefined)[]>();
  const [prefixHint, setPrefixHint] = useState<string[]>();

  const [attachmentTabEnabled, setAttachmentTabEnabled] = useState<boolean>(false);

  const [nonDiscretionalExpenseDescriptions, setNonDiscretionalExpenseDescriptions] =
    useState<ExpenseDescriptionLookupDto[]>();

  const nonDiscretionalExpenseTypeId = expenseTypes?.find(
    (type) => type.enumValue === ClientService.ExpenseTypeEnum.NonDiscretionary
  )?.id;

  const [uploadListenerStatus, setUploadListenerStatus] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    if (expenseTypes) {
      const tabs = (expenseTypes || [])?.map((item, index) => String(index));
      if (Boolean(tabs?.length)) {
        const documentTabKey = hasDocumentsTab && attachmentTabEnabled ? String(tabs?.length) : undefined;

        if (documentTabKey) {
          setDocumentsTabKey(documentTabKey);
          setFormTabs([...tabs, documentTabKey]);
        } else {
          setFormTabs(tabs);
        }

        setActiveTab(tabs[0]);
      }
    }
  }, [expenseTypes, hasDocumentsTab, attachmentTabEnabled]);

  useEffect(() => {
    let tabs: IExpenseTab[] = [];

    expenseTypes.forEach((type) => {
      tabs.push({
        expenseType: type,
        expenseDetails: data?.expenseDetails?.filter((item) => item.expenseTypeId === type.id) || [],
      });
    });

    form.setFieldsValue({ tabs: tabs });

    setFormExpense(data?.expenseDetails);
    setIsMarried(data?.isMarried || false);
  }, [expenseTypes, form, data]);

  const handleSave = useCallback(
    (iForm: IForm) => {
      if (isLocked) return;

      let dto = new ClientService.AppFormIncomeExpenseExpenseDto();
      dto.fileId = fileId;
      dto.isMarried = isMarried;

      const hiddenExpenseDetails =
        initialFormExpense?.filter(
          (item: ClientService.AppFormIncomeExpenseExpenseDetailDto) =>
            !expenseTypes.find((expense) => expense.id === item.expenseTypeId)
        ) || [];

      const updatedExpenseDetails =
        iForm.tabs
          .map((item) => {
            return item?.expenseDetails.map((detail) => {
              let mapped = new ClientService.AppFormIncomeExpenseExpenseDetailDto();
              mapped.id = detail?.id;
              mapped.expenseDescriptionId = detail?.expenseDescriptionId;
              mapped.specifiedDescription = detail?.specifiedDescription;
              mapped.applicantAmount = detail?.applicantAmount;
              mapped.expenseTypeId = detail?.expenseTypeId;
              mapped.spouseAmount = detail?.spouseAmount;
              mapped.otherHouseholdMemberAmount = detail?.otherHouseholdMemberAmount;

              return mapped;
            });
          })
          .reduce((arr, current) => arr.concat(current)) || [];
      dto.expenseDetails = [...updatedExpenseDetails, ...hiddenExpenseDetails];

      setIsLoading(true);
      onSave(dto, uploadListenerStatus);
    },
    [isLocked, expenseTypes, fileId, initialFormExpense, isMarried, onSave, uploadListenerStatus]
  );

  const handleNextStepOrSubmit = useCallback(() => {
    if (!formTabs || !activeTab) return;

    const activeKeyIndex = formTabs?.indexOf(activeTab);
    const values = form.getFieldsValue();

    if (formTabs?.length - 1 > activeKeyIndex) {
      setActiveTab(formTabs[activeKeyIndex + 1]);
    } else {
      handleSave(values);
    }
  }, [activeTab, form, formTabs, handleSave]);

  const handleTabChange = useCallback(
    (key) =>
      form.validateFields().then((values) => {
        setActiveTab(key);
      }),
    [form]
  );

  const onFormSubmit = useCallback(async () => {
    form
      .validateFields()
      .then(handleNextStepOrSubmit)
      .catch((error) => console.log(error));
  }, [form, handleNextStepOrSubmit]);

  const requestNonDiscretionalTypeDescriptions = useCallback(async () => {
    const response = await API.listExpenseDescriptions(nonDiscretionalExpenseTypeId);
    if (response) setNonDiscretionalExpenseDescriptions(response);
  }, [nonDiscretionalExpenseTypeId]);

  const updateAttachmentTabStatus = useCallback(() => {
    const nonEmptyFields = form
      .getFieldsValue()
      ?.tabs?.map((item: IExpenseTab) => item.expenseDetails)
      .flat()
      ?.filter(
        (item: ClientService.AppFormIncomeExpenseExpenseDetailDto) =>
          (item?.applicantAmount || item?.spouseAmount || item?.otherHouseholdMemberAmount) &&
          Boolean(expenseTypes?.find((type) => type.id === nonDiscretionalExpenseTypeId))
      );

    const prefixCodes = nonEmptyFields
      ?.map((item: ClientService.AppFormIncomeExpenseExpenseDetailDto) =>
        convertIdToLookupCodeName(
          item?.expenseDescriptionId as string,
          nonDiscretionalExpenseDescriptions as ClientService.LookupDto[],
          ClientService.ExpenseDescriptionEnum
        )
      )
      ?.filter(
        (item?: string) => Boolean(item) && (item === 'ChildSupportPayments' || item === 'SpousalSupportPayments')
      )
      ?.map((item?: string) => `Expense.${item}`);

    if (prefixCodes?.length) {
      setAttachmentTabEnabled(true);
      setPrefixCodes(prefixCodes);
    } else {
      setAttachmentTabEnabled(false);
      setPrefixCodes([]);
    }
  }, [form, nonDiscretionalExpenseDescriptions, setPrefixCodes, setAttachmentTabEnabled]);

  useEffect(() => {
    requestNonDiscretionalTypeDescriptions();
  }, [requestNonDiscretionalTypeDescriptions]);

  useEffect(() => {
    const nonEmptyFields = form
      .getFieldsValue()
      ?.tabs?.map((item: IExpenseTab) => item.expenseDetails)
      .flat()
      ?.filter(
        (item: ClientService.AppFormIncomeExpenseExpenseDetailDto) =>
          (item?.applicantAmount || item?.spouseAmount || item?.otherHouseholdMemberAmount) &&
          Boolean(expenseTypes?.find((type) => type.id === nonDiscretionalExpenseTypeId))
      );

    const prefixCodes = nonEmptyFields
      ?.map((item: ClientService.AppFormIncomeExpenseExpenseDetailDto) =>
        convertIdToLookupCodeName(
          item?.expenseDescriptionId as string,
          nonDiscretionalExpenseDescriptions as ClientService.LookupDto[],
          ClientService.ExpenseDescriptionEnum
        )
      )
      ?.filter(
        (item?: string) => Boolean(item) && (item === 'ChildSupportPayments' || item === 'SpousalSupportPayments')
      )
      ?.map((item?: string) => `Expense.${item}`);

    if (prefixCodes?.length) {
      setAttachmentTabEnabled(true);
      setPrefixCodes(prefixCodes);
    } else {
      setAttachmentTabEnabled(false);
      setPrefixCodes([]);
    }

    const isSpousalSupportFilled = nonEmptyFields?.find(
      (item: ClientService.AppFormIncomeExpenseExpenseDetailDto) =>
        item.expenseDescriptionId ===
        nonDiscretionalExpenseDescriptions?.find(
          (type) => type.enumValue === ClientService.ExpenseDescriptionEnum.SpousalSupportPayments
        )?.id
    );

    const isChildSupportFilled = nonEmptyFields?.find(
      (item: ClientService.AppFormIncomeExpenseExpenseDetailDto) =>
        item.expenseDescriptionId ===
        nonDiscretionalExpenseDescriptions?.find(
          (type) => type.enumValue === ClientService.ExpenseDescriptionEnum.ChildSupportPayments
        )?.id
    );

    const spousalSupportHint = isSpousalSupportFilled
      ? t.ONLY_1_OF_THE_2_SUPPORTING_DOCUMENTS_LISTED_FOR_SPOUSAL_SUPPORT_PAYMENT_IS_REQUIRED
      : undefined;
    const childSupportHint = isChildSupportFilled
      ? t.ONLY_1_OF_THE_2_SUPPORTING_DOCUMENTS_LISTED_FOR_CHILD_SUPPORT_PAYMENT_IS_REQUIRED
      : undefined;

    const prefixHint = [spousalSupportHint, childSupportHint]?.filter(Boolean);
    if (prefixHint?.length > 0) setPrefixHint(prefixHint as string[]);
  }, [
    activeTab,
    expenseTypes,
    form,
    nonDiscretionalExpenseDescriptions,
    nonDiscretionalExpenseTypeId,
    t.ONLY_1_OF_THE_2_SUPPORTING_DOCUMENTS_LISTED_FOR_CHILD_SUPPORT_PAYMENT_IS_REQUIRED,
    t.ONLY_1_OF_THE_2_SUPPORTING_DOCUMENTS_LISTED_FOR_SPOUSAL_SUPPORT_PAYMENT_IS_REQUIRED,
  ]);

  const isLastTab = useMemo(
    () => Boolean(activeTab && formTabs && formTabs?.length - 1 === formTabs.indexOf(activeTab)),
    [activeTab, formTabs]
  );

  return (
    <Modal
      destroyOnClose
      centered
      visible
      title={t.MONTHLY_EXPENSE}
      width={1200}
      footer={null}
      closable
      maskClosable={false}
      onCancel={onCancel}
      className="ApplicationFormTab__modal"
    >
      <Spin spinning={loading || isLoading}>
        <Form form={form} layout="vertical" onChange={() => updateAttachmentTabStatus()}>
          <Tabs className="Tabs" activeKey={activeTab} onChange={handleTabChange}>
            {expenseTypes.map((row, index) => (
              <TabPane tab={row?.name} key={`${index}`} forceRender>
                <IncomeExpenseExpenseTab
                  key={`tab-${index}`}
                  form={form}
                  tabIndex={index}
                  isMarried={isMarried}
                  expenseType={row}
                  isLocked={isLocked}
                  onChange={() => updateAttachmentTabStatus()}
                />
              </TabPane>
            ))}
            {hasDocumentsTab && attachmentTabEnabled && (
              <TabPane
                tab={
                  <Row align="middle" gutter={5} wrap={false}>
                    <Col>{t.DOCUMENTS}</Col>
                    <Col>
                      {isMissingSupportingDocuments && (
                        <Icon iconName="AlertSolid" className="ApplicationFormTab__tab-error-icon" />
                      )}
                    </Col>
                  </Row>
                }
                key={documentsTabKey}
              >
                <AttachmentsTab
                  form={form}
                  applicationFileId={fileId}
                  supportingFolderCode="surplus-ie"
                  prefixCodes={prefixCodes}
                  isPrefixCodesRequired={true}
                  isFlatLayout
                  flatLayoutProps={{
                    hasInstruction: true,
                    hasSupportingList: true,
                    hasExistingFiles: true,
                    supportingListLabelHint: prefixHint,
                  }}
                  columnsProps={{ hasSizeColumn: true, hasFolderColumn: false, isDeleteDisabled: isLocked }}
                  draggerProps={{ disabled: isLocked }}
                  updateUploadListenerStatus={setUploadListenerStatus}
                />
              </TabPane>
            )}
          </Tabs>

          <Row gutter={12} justify="end">
            <Col>
              <Button kind="cancel" padding="large" onClick={onCancel}>
                {t.CANCEL}
              </Button>
            </Col>
            <Col>
              <Button kind="primary" padding="large" onClick={onFormSubmit} disabled={isLastTab && isLocked}>
                {!isLastTab ? t.NEXT : t.SAVE}
              </Button>
            </Col>
          </Row>
        </Form>
      </Spin>
    </Modal>
  );
}

export default IncomeExpenseExpense;
