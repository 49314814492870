import { useState, useEffect } from 'react';
import { Icon } from '@fluentui/react/lib/Icon';
import { Col, Row } from 'antd';
import { ClientService } from '../../shared/api/ClientService';
import useLocale from '../../hooks/useLocale';

import styles from './ErrorsContainer.module.scss';

export interface IErrorsMsgAndType {
  message: string;
  messageType?: ClientService.ResultMessageType;
}
interface IProps {
  noTitle?: boolean;
  errors?: IErrorsMsgAndType[];
  messages?: ClientService.Message[];
  className?: string;
  style?: React.CSSProperties;
}

export const mapAndOrderMessages = (messages: ClientService.Message[] | undefined) => {
  const errorMessages: IErrorsMsgAndType[] = [];
  const warningMessages: IErrorsMsgAndType[] = [];
  // extract error and warning messages
  messages?.forEach((msg) => {
    if (msg.messageType === ClientService.ResultMessageType.Error && msg.body) {
      errorMessages.push({
        message: msg.body,
        messageType: msg.messageType,
      });
    } else if (msg.messageType === ClientService.ResultMessageType.Warning && msg.body) {
      warningMessages.push({
        message: msg.body,
        messageType: msg.messageType,
      });
    }
  });
  return [...errorMessages, ...warningMessages];
};

const ErrorsContainer: React.FC<IProps> = ({
  noTitle = false,
  // errors = [],
  errors,
  // messages = [],
  messages,
  className,
  children,
  style,
}): JSX.Element => {
  // console.log(errors);
  // console.log(messages);

  const { t } = useLocale();

  const [displayErrors, setDisplayErrors] = useState<IErrorsMsgAndType[]>([]);

  useEffect(() => {
    if (!errors && !messages) return;

    if (errors && errors.length > 0) {
      setDisplayErrors(errors);
    } else if (messages && messages.length > 0) {
      setDisplayErrors(mapAndOrderMessages(messages));
    }
  }, [errors, messages]);

  return (
    <div className={`${styles.ErrorsContainer} ${className ? className : ''}`} style={style}>
      {!noTitle && <div className="title">{t.ERRORS_CONTAINER_TITLE}</div>}
      {displayErrors.length > 0 &&
        displayErrors.map((error, index) => {
          const { messageType } = error;
          const isError =
            messageType === undefined || messageType === ClientService.ResultMessageType.Error ? true : false;
          return (
            <Row gutter={10} key={`error-${index}`}>
              <Col span={1}>
                <Icon
                  iconName={isError ? 'StatusErrorFull' : 'AlertSolid'}
                  className={isError ? 'error-icon' : 'warning-icon'}
                />
              </Col>
              <Col span={23}>{error.message}</Col>
            </Row>
          );
        })}
      {children}
    </div>
  );
};

export default ErrorsContainer;
