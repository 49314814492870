import { useState } from 'react';
import { Modal, Form, Col, Row, Spin } from 'antd';

import Button from '../../components/Button/Button';

import useLocale from '../../hooks/useLocale';

import './ChangeFileName.scss';
import FormInput from '../../components/Forms/FormInput/FormInput';
import { ClientService } from '../../shared/api/ClientService';

interface IProps {
  file: ClientService.IApplicationFileDto | null;
  onSave: (applicationFileName: string, onSaveFailure?: () => void) => void;
  onCancel: () => void;
  shouldDisableOnSave?: boolean;
}

interface IForm {
  fileName: string;
}

const ChangeFileNameModal = ({ file: updateFile, onSave, onCancel, shouldDisableOnSave = false }: IProps) => {
  const { t } = useLocale();
  const [form] = Form.useForm<IForm>();
  const [loading] = useState(false);
  const [disabled, setDisabled] = useState<boolean>(false);
  const [noChanged, setNoChanged] = useState<boolean>(true);

  const handleValuesChange = (changedValues: any) => {
    if (changedValues !== undefined) {
      setNoChanged(changedValues === updateFile?.applicationFileName);
    }
  };

  const validateFileName = (_: any, value: any) => {
    const invalidFileNameRegex = /[&,\\/\\:*?"<>|"'À-ÿ]/; // & , / \ : * ? ” < > |"'and special characters from À to ÿ
    if (!invalidFileNameRegex.test(value)) {
      return Promise.resolve();
    }
    return Promise.reject();
  };

  return (
    <Modal
      destroyOnClose
      centered
      width={440}
      visible
      closable={!loading}
      title={t.ADMIN_INFO_FILE_NAME}
      onCancel={onCancel}
      footer={null}
      className="ChangeFileName"
    >
      <Spin spinning={loading}>
        <Form
          layout="vertical"
          form={form}
          onValuesChange={() => {
            handleValuesChange(form.getFieldValue('fileName'));
          }}
          onFinish={(values) => {
            if (shouldDisableOnSave) setDisabled(true);

            onSave(values?.fileName, () => {
              if (shouldDisableOnSave) setDisabled(false);
            });
          }}
        >
          <FormInput
            type="text"
            name="fileName"
            label={t.ADMIN_INFO_FILE_NAME}
            initialValue={updateFile?.applicationFileName}
            rules={[
              {
                validator: validateFileName,
                message: t.FILE_NAME_IS_INVALID,
              },
            ]}
          />
          <Row align="middle" justify="end" gutter={10} style={{ paddingTop: 16 }}>
            <Col>
              <Form.Item noStyle>
                <Button kind="cancel" onClick={onCancel}>
                  {t.CANCEL}
                </Button>
              </Form.Item>
            </Col>
            <Col>
              <Form.Item noStyle>
                <Button kind="primary" htmlType="submit" disabled={disabled || noChanged}>
                  {t.SAVE}
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Spin>
    </Modal>
  );
};

export default ChangeFileNameModal;
