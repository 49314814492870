import { ClientService } from '../../../../../shared/api/ClientService';

export const PROPOSAL_PROCESS_STATUS_DROPDOWN_OPTIONS = [
  { enumValue: ClientService.ProposalProcessStatusEnum.Pending, disabled: false },
  { enumValue: ClientService.ProposalProcessStatusEnum.InProgress, disabled: true },
  { enumValue: ClientService.ProposalProcessStatusEnum.Completed, disabled: false },
];

export const FOLDER_COURT_REVIVAL = 'discharge';
export const PREFIX_COURT_REVIVAL = 'Proposal.CourtRevival';

export const FOLDER_CHECKLIST = 'lead';
export const PREFIX_CHECKLIST = 'Proposal.Checklist';

export const FOLDER_FORMS = 'lead';
export const PREFIX_FORM56 = 'Proposal.Form56';
export const PREFIX_FORM93 = 'Proposal.Form93';
export const PREFIX_FORM95 = 'Proposal.Form95';
export const PREFIX_FORM96 = 'Proposal.Form96';
