import { useMemo } from 'react';
import { Menu, MenuProps } from 'antd';
import DropdownButton from '../../../../../components/Button/DropdownButton/DropdownButton';
import ButtonContainer from '../../../../../components/ButtonContainer/ButtonContainer';
import Button from '../../../../../components/Button/Button';
import CancelButton from '../../../../../components/Button/AppButtons/CancelButton';
import useLocale from '../../../../../hooks/useLocale';

// const onMenuClick: MenuProps['onClick'] = (e) => {
//   console.log('click', e);
// };

interface IProps {
  buttonsEnabled?: boolean;
  onCancel?: () => void;
  onSaveAsDraft?: () => void;
  onSaveAsDraftWithAlert?: () => void;
  onComplete?: () => void;
  onCompleteWithAlert?: () => void;
}

const ButtonsGroupCancelDraftComplete = ({
  buttonsEnabled = true,
  onCancel,
  onSaveAsDraft,
  onSaveAsDraftWithAlert,
  onComplete,
  onCompleteWithAlert,
}: IProps): JSX.Element => {
  const { t } = useLocale();

  const draftButtonMenu = useMemo(() => {
    return (
      // <Menu onClick={onMenuClick}>
      <Menu>
        <Menu.Item key="draft" onClick={onSaveAsDraft}>
          {t.BUTTON_SAVE_AS_DRAFT}
        </Menu.Item>
        <Menu.Item key="draft-with-alert" onClick={onSaveAsDraftWithAlert}>
          {t.BUTTON_SAVE_AS_DRAFT_WITH_ALERT}
        </Menu.Item>
      </Menu>
    );
  }, [t.BUTTON_SAVE_AS_DRAFT, t.BUTTON_SAVE_AS_DRAFT_WITH_ALERT, onSaveAsDraft, onSaveAsDraftWithAlert]);

  const completeButtonMenu = useMemo(() => {
    return (
      // <Menu onClick={onMenuClick}>
      <Menu>
        <Menu.Item key="complete" onClick={onComplete}>
          {t.BUTTON_MARK_AS_COMPLETE}
        </Menu.Item>
        <Menu.Item key="complete-with-alert" onClick={onCompleteWithAlert}>
          {t.BUTTON_MARK_AS_COMPLETE_WITH_ALERT}
        </Menu.Item>
      </Menu>
    );
  }, [t.BUTTON_MARK_AS_COMPLETE, t.BUTTON_MARK_AS_COMPLETE_WITH_ALERT, onComplete, onCompleteWithAlert]);

  return (
    <ButtonContainer inline>
      <CancelButton narrow onClick={onCancel ? onCancel : undefined} />
      {onSaveAsDraftWithAlert ? (
        <DropdownButton
          disabled={!buttonsEnabled}
          narrow
          padding="medium"
          kind="primary"
          overlay={draftButtonMenu}
          onClick={!buttonsEnabled ? undefined : onSaveAsDraft}
        >
          {t.BUTTON_SAVE_AS_DRAFT}
        </DropdownButton>
      ) : (
        <Button disabled={!buttonsEnabled} narrow padding="medium" onClick={onSaveAsDraft}>
          {t.BUTTON_SAVE_AS_DRAFT}
        </Button>
      )}
      {onCompleteWithAlert ? (
        <DropdownButton
          disabled={!buttonsEnabled}
          narrow
          padding="medium"
          kind="success"
          overlay={completeButtonMenu}
          onClick={!buttonsEnabled ? undefined : onComplete}
        >
          {t.BUTTON_COMPLETE}
        </DropdownButton>
      ) : (
        <Button disabled={!buttonsEnabled} narrow padding="medium" kind="success" onClick={onComplete}>
          {t.BUTTON_COMPLETE}
        </Button>
      )}
    </ButtonContainer>
  );
};

export default ButtonsGroupCancelDraftComplete;
