import { useCallback, useEffect, useState, useRef } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Spin, Form, Collapse, Row, Col, Input, Radio, Checkbox, Divider } from 'antd';
import { useQuery } from 'react-query';
import InputMask from 'react-input-mask';
import moment from 'moment';

import Button from '../../../components/Button/Button';
import Breadcrumb from '../../../components/Breadcrumb/Breadcrumb';
import PageSubtitle from '../../../components/PageSubtitle/PageSubtitle';
import DatePicker from '../../../components/DatePicker/DatePicker';
import SelectLookupDto from '../../../components/SelectLookupDto/SelectLookupDto';
import FormSectionTitle from '../../../components/FormSectionTitle/FormSectionTitle';
import AttachmentsTab, { AttachmentsFormProps } from '../../../components/AttachmentsTab';
import AddressComponent, { IAddressComponent, formatAddressLine } from '../../../components/AddressComponent';
import PanelHeader from './components/PanelHeader';
import DataItem from '../../../components/DataItem/DataItem';

import useLocale from '../../../hooks/useLocale';

import { UPLOAD_FILES_EVENT } from '../../../constants/eventBus';
import { ROUTES } from '../../../constants/routes';
import { COUNTRIES_QUERY, PHONE_TYPES_QUERY, USE_QUERY_OPTIONS } from '../../../constants/reactQuery';
import {
  PREFIX_CODE_CONSENT_CB,
  FOLDER_CODE_LEAD,
  CANADA_COUNTRY_CODE,
  PERSONAL_INFO_TAB,
  ADDRESS_TAB,
  CONSENT_TAB,
} from './constants';
import { IError } from './types';
import { DATE_FORMAT2, MAX_PAGE_SIZE } from '../../../constants/common';
import { argumentifyDocumentsSearchCriteria } from '../../ApplicationOverviewPage/DocumentsContent/utils';
import genericMessage from '../../../utils/genericMessage';
import { eventBus } from '../../../utils/eventBus';
import { convertInputDateToServerDateOnly, convertServerDateOnlyToInputDate } from '../../../utils/helpers';
import { DOCUMENTS_TAB } from '../ApplicationOverviewContent/ApplicationOverviewContent';

import { ClientService } from '../../../shared/api/ClientService';
import API from '../../../utils/api';

import './CreditReportContent.scss';

const personalDetailsFields = [
  'firstName',
  'lastName',
  'birthDate',
  'sin',
  'email',
  'mainPhoneTypeId',
  'mainPhoneNumber',
];

const addressFields = ['hasStayedMoreThan2Years'];

const consentFields = ['userConsent'];

type ICreditReportDtoToSaveAppForm = Omit<
  ClientService.CreditReportRequestDto,
  'clientId' | 'residenceDate' | 'userConsent'
>;

interface IForm extends AttachmentsFormProps, ClientService.CreditReportRequestDto {
  hasStayedMoreThan2Years?: boolean;
  prev: IAddressComponent[];
  addressLine?: string;
}

const RequestCreditReport = () => {
  const { t } = useLocale();
  const { applicationFileId } = useParams<{ applicationFileId: string }>();
  const navigate = useNavigate();
  const [form] = Form.useForm<IForm>();
  const { Panel } = Collapse;

  const refPersonalPanel = useRef<HTMLDivElement>(null);
  const refAddressPanel = useRef<HTMLDivElement>(null);
  const refConsentPanel = useRef<HTMLDivElement>(null);

  const [initialValues, setInitialValues] = useState<ClientService.AppFormPersonalInfoDto>();
  const [initialQuestionnaire, setInitialQuestionnaire] = useState<ClientService.AppFormQuestionnaireDto>();
  const [loading, setLoading] = useState<boolean>(false);

  const [isAnyConsentDocument, setIsAnyConsentDocument] = useState<boolean>(false);
  const [isConsentGiven, setIsConsentGiven] = useState<boolean>(false);

  const { data: phoneTypes } = useQuery([PHONE_TYPES_QUERY], () => API.listPhoneTypes(), USE_QUERY_OPTIONS);
  const { data: countries } = useQuery([COUNTRIES_QUERY], () => API.listCountries(), USE_QUERY_OPTIONS);
  const requestConsentDocuments = useCallback(async (applicationFileId: string) => {
    const folders = await API.listFolders();
    const response = await API.documentsGET(
      ...argumentifyDocumentsSearchCriteria({
        fileId: applicationFileId,
        documentFolderId: folders?.find((item) => item.code === FOLDER_CODE_LEAD)?.id,
        documentPrefixCodes: [PREFIX_CODE_CONSENT_CB],
        maxResultCount: MAX_PAGE_SIZE,
      })
    );

    if (response?.totalCount) setIsAnyConsentDocument(true);
  }, []);

  const requestUpdateAppForm = useCallback(
    async (changedValues: ICreditReportDtoToSaveAppForm) => {
      await API.updateAppFormPersonalInfo({
        ...initialValues,
        ...changedValues,
        birthDate: convertInputDateToServerDateOnly(changedValues?.birthDate),
        fileId: applicationFileId,
      });
    },
    [applicationFileId, initialQuestionnaire, initialValues]
  );

  const requestCreditReport = useCallback(
    async ({ hasStayedMoreThan2Years, existingFiles, ...values }) => {
      setLoading(true);
      const profile = await API.getDebtorProfile(applicationFileId).catch(() => setLoading(false));
      const provinces = await API.listProvinces(values?.countryId as string).catch(() => setLoading(false));
      const clientId = profile?.profile?.clientId;

      const body = {
        ...values,
        residenceDate: convertServerDateOnlyToInputDate(initialValues?.residenceDate),
        previousAddresses: values.prev,
        provinceId: provinces?.find((item) => item.id === values?.provinceId)?.code,
        clientId,
        fileId: applicationFileId,
      };

      delete body.addressLine;
      delete body.prev;

      const response = await API.runCreditCheckReport(body as ClientService.CreditReportRequestDto).catch((e) => {
        setLoading(false);
        genericMessage.error(e);
      });
      setLoading(false);

      if (response?.result === ClientService.Result.Successful && response?.returnId) {
        delete body.residenceDate;
        delete body.clientId;
        delete body.userConsent;

        await requestUpdateAppForm({ ...body, provinceId: values?.provinceId } as ICreditReportDtoToSaveAppForm);
        navigate(`${ROUTES.APPLICATION_OVERVIEW}/${applicationFileId}/${ROUTES.CREDIT_REPORT}/${response?.returnId}`);
      } else if (response?.result === ClientService.Result.Failed) {
        genericMessage.error({}, response?.messages?.[0].body);
      }
    },
    [applicationFileId, initialValues?.residenceDate, requestUpdateAppForm, navigate]
  );

  const requestGetDuplicates = useCallback(async () => {
    const values = form.getFieldsValue();
    setLoading(true);
    const result = await API.checkForDuplicates(
      values?.firstName,
      values?.lastName,
      values?.mainPhoneNumber,
      values?.emailAddress,
      []
    )
      .catch(genericMessage.error)
      .finally(() => {
        setLoading(false);
      });

    return result;
  }, [form]);

  const handleSubmit = useCallback(
    (isValidationOnly?: boolean) => {
      form.validateFields().then(async () => {
        if (!isValidationOnly) {
          const duplicates = await requestGetDuplicates();

          const notCurrent =
            duplicates &&
            duplicates?.filter(
              (item) =>
                ((item.isMatchingFirstName && item.isMatchingLastName && item.isMatchingPhoneNumber) ||
                  (item.isMatchingFirstName && item.isMatchingLastName && item.isMatchingEmailAddress) ||
                  item.isMatchingEmailAddress) &&
                item.id !== applicationFileId
            );

          if (notCurrent && notCurrent?.length) {
            genericMessage.error({}, t.DUPLICATE_RECORDS_ERROR);
            return;
          } else {
            const values = form.getFieldsValue();
            requestCreditReport({
              ...values,
              birthDate: convertInputDateToServerDateOnly(values?.birthDate),
            });
            eventBus.dispatch(UPLOAD_FILES_EVENT);
          }
        }
      });
    },
    [applicationFileId, form, requestCreditReport, requestGetDuplicates, t.DUPLICATE_RECORDS_ERROR]
  );
  const isCanadianAddress = (id: string | undefined) => {
    return Boolean(countries?.find((item) => item.id === id)?.code === CANADA_COUNTRY_CODE);
  };

  const requestAppForm = useCallback(
    async (fileId: string) => {
      setLoading(true);

      const response = await API.getAppFormPersonalInfo(fileId).catch(() => setLoading(false));
      const questionnaire = await API.getAppFormQuestionnaire(applicationFileId).catch(() => setLoading(false));
      const countries = await API.listCountries().catch(() => setLoading(false));

      setLoading(false);

      setIsConsentGiven(questionnaire?.isConsentChecked || false);

      if (response && questionnaire && countries) {
        setInitialValues(response);
        setInitialQuestionnaire(questionnaire);

        form.setFieldsValue({
          ...response,
          birthDate: convertServerDateOnlyToInputDate(response?.birthDate),
          prev: response?.previousAddresses?.map((item) => ({
            ...item,
            addressLine:
              item?.streetName && item?.streetNumber
                ? formatAddressLine({
                    streetName: item?.streetName,
                    streetNumber: item?.streetNumber,
                  })
                : item?.address,
          })),
          addressLine:
            response?.streetName && response?.streetNumber
              ? formatAddressLine({
                  streetName: response?.streetName,
                  streetNumber: response?.streetNumber,
                })
              : response?.address,
          previousAddresses: response?.previousAddresses,
          hasStayedMoreThan2Years: !response?.residenceDate
            ? undefined
            : moment().diff(convertServerDateOnlyToInputDate(response?.residenceDate), 'y') > 2,
          userConsent: questionnaire?.isConsentChecked,
        });

        form.validateFields().catch(async (e) => {
          if (Boolean(e?.errorFields?.filter((item: IError) => item?.errors?.length)?.length)) {
            genericMessage.error({}, t.MISSING_VALUES_CREDIT_REPORT_ERROR);
          }
        });

        const isAnyNotCanadian = Boolean(
          [response?.countryId, ...(response?.previousAddresses?.map((item) => item.countryId) || [])]?.find(
            (id) => countries?.find((item) => item.id === id)?.code !== CANADA_COUNTRY_CODE
          )
        );
        if (isAnyNotCanadian) {
          genericMessage.error({}, t.COUNTRY_ADDRESS_CREDIT_REPORT_ERROR);
        }
      }
    },
    [applicationFileId, form, t.COUNTRY_ADDRESS_CREDIT_REPORT_ERROR, t.MISSING_VALUES_CREDIT_REPORT_ERROR]
  );

  useEffect(() => {
    if (applicationFileId) {
      requestAppForm(applicationFileId);
      requestConsentDocuments(applicationFileId);
    }
  }, [applicationFileId, requestAppForm, requestConsentDocuments]);

  const checkIfConcernFilesUploaded = (values: IForm) => {
    if (values) {
      return (
        (values?.existingFiles && values?.existingFiles.length > 0) ||
        (values?.newFiles && values?.newFiles.length > 0) ||
        isAnyConsentDocument
      );
    } else {
      return false;
    }
  };

  return (
    <div className="CreditReportContent">
      <Breadcrumb
        data={[
          { link: ROUTES.DASHBOARD, title: t.DASHBOARD },
          { link: `${ROUTES.DEBTOR_SEARCH}`, title: t.DEBTOR_SEARCH },
          { link: `${ROUTES.APPLICATION_OVERVIEW}/${applicationFileId}/`, title: t.APPLICATION_OVERVIEW },
          {
            link: `${ROUTES.APPLICATION_OVERVIEW}/${applicationFileId}/${ROUTES.CREDIT_REPORT_HISTORY}`,
            title: t.CREDIT_REPORT_HISTORY,
          },
          {
            title: t.REQUEST_CREDIT_REPORT,
          },
        ]}
      />
      <PageSubtitle title={t.REQUEST_CREDIT_REPORT} />
      <Spin spinning={loading}>
        <Form form={form} layout="vertical">
          <Collapse defaultActiveKey={PERSONAL_INFO_TAB} ghost>
            <Panel
              key={PERSONAL_INFO_TAB}
              header={
                <div ref={refPersonalPanel}>
                  <PanelHeader title={t.PERSONAL_INFORMATION} panelFieldsNames={personalDetailsFields} />
                </div>
              }
              forceRender
            >
              <Row gutter={20}>
                <Col span={8}>
                  <Form.Item
                    label={t.FIRST_NAME}
                    required
                    name="firstName"
                    rules={[{ required: true, message: t.REQUIRED_FIELD }]}
                  >
                    <Input size="large" />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item label={t.MIDDLE_NAME} name="middleName">
                    <Input size="large" />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item
                    label={t.LAST_NAME}
                    required
                    name="lastName"
                    rules={[{ required: true, message: t.REQUIRED_FIELD }]}
                  >
                    <Input size="large" />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item
                    label={t.DOB}
                    required
                    name="birthDate"
                    rules={[{ required: true, message: t.REQUIRED_FIELD }]}
                  >
                    <DatePicker dateSetter={null} size="large" format={DATE_FORMAT2} />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item
                    name="sin"
                    label={t.SIN}
                    rules={[{ pattern: new RegExp('\\d\\d\\d \\d\\d\\d \\d\\d\\d'), message: t.SIN_IS_INVALID }]}
                  >
                    <InputMask mask="999 999 999">
                      <Input size="large" />
                    </InputMask>
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item
                    name="emailAddress"
                    label={t.EMAIL_ADDRESS}
                    rules={[{ type: 'email', message: t.EMAIL_IS_INVALID }]}
                  >
                    <Input size="large" />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Row gutter={10}>
                    <Col span={8}>
                      <Form.Item
                        name="mainPhoneTypeId"
                        label={t.MAIN_PHONE_NUMBER}
                        rules={[{ required: true, message: t.REQUIRED_FIELD }]}
                      >
                        <SelectLookupDto data={phoneTypes} />
                      </Form.Item>
                    </Col>
                    <Col span={16}>
                      <Form.Item name="mainPhoneNumber" label=" ">
                        <InputMask mask="(999) 999-9999">
                          <Input size="large" />
                        </InputMask>
                      </Form.Item>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Panel>
            <Panel
              key={ADDRESS_TAB}
              header={
                <div ref={refAddressPanel}>
                  <PanelHeader title={t.ADDRESS} panelFieldsNames={addressFields} />
                </div>
              }
              forceRender
            >
              <Row gutter={20}>
                <Col span={24}>
                  <FormSectionTitle titleText={t.CURRENT_ADDRESS} style={{ fontSize: 14 }} />
                </Col>
                <Col span={24}>
                  <AddressComponent
                    form={form}
                    countryId={initialValues?.countryId}
                    customCountryValidator={isCanadianAddress}
                  />
                </Col>

                <Col span={8}>
                  <Form.Item
                    name="hasStayedMoreThan2Years"
                    label={t.HAS_STAYED_MORE_THAN_2YEARS}
                    rules={[{ required: true, message: t.REQUIRED_FIELD }]}
                  >
                    <Radio.Group size="large" buttonStyle="solid" optionType="button">
                      <Radio.Button value={true}>{t.YES}</Radio.Button>
                      <Radio.Button value={false}>{t.NO}</Radio.Button>
                    </Radio.Group>
                  </Form.Item>
                </Col>
              </Row>
              <Form.Item noStyle shouldUpdate>
                {({ getFieldValue }) =>
                  getFieldValue('hasStayedMoreThan2Years') === false && (
                    <Row gutter={20}>
                      <Col span={24}>
                        <FormSectionTitle titleText={t.PREVIOUS_ADDRESS} style={{ fontSize: 14 }} />
                      </Col>
                      <Col span={24}>
                        <Form.List name="prev">
                          {(fields, { add, remove }) => (
                            <>
                              {fields?.map((row, index) => (
                                <>
                                  <Form.Item noStyle shouldUpdate key={`prev-${row.name}`}>
                                    <AddressComponent
                                      form={form}
                                      listProps={{
                                        rowName: row.name,
                                        listName: 'prev',
                                      }}
                                      countryId={getFieldValue(['prev', row.name, 'countryId'])}
                                      hasDelete
                                      onDelete={() => remove(row.name)}
                                      customCountryValidator={isCanadianAddress}
                                    />
                                  </Form.Item>
                                  {index < fields?.length - 1 && <Divider />}
                                </>
                              ))}
                              <div onClick={() => add()} className="CreditReportContent__add-address-icon">
                                {t.ADD_PREVIOUS_ADDRESS}
                              </div>
                            </>
                          )}
                        </Form.List>
                      </Col>
                    </Row>
                  )
                }
              </Form.Item>
            </Panel>
            <Panel
              key={CONSENT_TAB}
              forceRender
              header={
                <div ref={refConsentPanel}>
                  <PanelHeader title={t.CONSENT} panelFieldsNames={consentFields} />
                </div>
              }
            >
              <Row>
                <>
                  <Col span={24}>
                    <FormSectionTitle titleText={t.CONSENT_AGREEMENT} style={{ fontSize: 14 }} />
                  </Col>
                  <Col span={24}>
                    <Form.Item>{t.CONSENT_TEXT_CREDIT_REPORT}</Form.Item>
                  </Col>

                  <Col span={8}>
                    <Form.Item
                      name="userConsent"
                      valuePropName="checked"
                      rules={[
                        {
                          validator: (_, value) =>
                            value ? Promise.resolve() : Promise.reject(new Error(t.REQUIRED_FIELD)),
                        },
                      ]}
                    >
                      <Checkbox disabled={isConsentGiven}>{t.DEBTOR_UNDERSTAND}</Checkbox>
                    </Form.Item>
                  </Col>
                </>

                {initialQuestionnaire && initialQuestionnaire?.isConsentChecked && (
                  <Col span={24} style={{ paddingTop: 40 }}>
                    <DataItem
                      label={t.CONSENT}
                      value={`${t.CONSENT_VIA_APP_FORM} ${moment(initialQuestionnaire?.consentDate).format(
                        DATE_FORMAT2
                      )}`}
                      horizontalSplit={[25, 75]}
                    />
                  </Col>
                )}
                <Col span={24} style={{ paddingTop: 40 }}>
                  <div>
                    <Form.Item noStyle shouldUpdate>
                      {() => {
                        const values = form.getFieldsValue();
                        if (!checkIfConcernFilesUploaded(values))
                          return <div className="ConcernFileRequired">{t.CONCERN_DOCUMENT_REQUIRED}</div>;
                        else return <></>;
                      }}
                    </Form.Item>
                    <AttachmentsTab
                      form={form}
                      applicationFileId={applicationFileId}
                      supportingFolderCode={FOLDER_CODE_LEAD}
                      prefixCodes={[PREFIX_CODE_CONSENT_CB]}
                      newSupportingFileRowLayoutProps={{
                        disabledPrefixInput: true,
                        preselectedPrefixInput: true,
                        disabledFoldersInput: true,
                      }}
                      isFlatLayout
                      flatLayoutProps={{
                        hasExistingFiles: true,
                        hasUploader: !isAnyConsentDocument,
                        label: <span style={{ color: 'black' }}>{t.CONSENT_FILE}</span>,
                      }}
                      columnsProps={{
                        hasAdditionalInfoColumn: false,
                        hasDeleteButton: !isAnyConsentDocument,
                        onDocumentNameClick: (documentId) =>
                          navigate(`${ROUTES.APPLICATION_OVERVIEW}/${applicationFileId}`, {
                            state: {
                              preselectedDocumentId: documentId,
                              preselectedTab: DOCUMENTS_TAB,
                            },
                          }),
                      }}
                    />
                  </div>
                </Col>
              </Row>
            </Panel>
          </Collapse>
          <Row justify="end" gutter={20} className="CreditReportContent__footer">
            <Col>
              <Button padding="large" kind="cancel">
                {t.CANCEL}
              </Button>
            </Col>
            <Col>
              <Form.Item noStyle shouldUpdate>
                {({ getFieldsError }) => {
                  const values = form.getFieldsValue();
                  const countriesIds = [
                    values?.countryId,
                    ...(values?.previousAddresses?.map((item) => item.countryId) || []),
                  ];
                  return (
                    <Button
                      kind="primary"
                      padding="large"
                      onClick={() => handleSubmit(false)}
                      disabled={
                        Boolean(getFieldsError()?.filter((item) => item?.errors?.length)?.length) ||
                        Boolean(countriesIds?.find((id) => countries?.find((item) => item.id === id)?.code !== 'CA')) ||
                        Boolean(!checkIfConcernFilesUploaded(values))
                      }
                    >
                      {t.SEND}
                    </Button>
                  );
                }}
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Spin>
    </div>
  );
};

export default RequestCreditReport;
