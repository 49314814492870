import { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Collapse, Row, Col, Spin } from 'antd';
import moment from 'moment';

import DataItem from '../../../components/DataItem/DataItem';
import Button from '../../../components/Button/Button';
import Breadcrumb from '../../../components/Breadcrumb/Breadcrumb';
import PageSubtitle from '../../../components/PageSubtitle/PageSubtitle';
import TransAlerts from './panels/TransAlerts';
import TradePanel from './panels/Trade';
import AddressHistory from './panels/AddressHistory';

import useLocale from '../../../hooks/useLocale';

import { DATE_FORMAT2 } from '../../../constants/common';
import { BASIC_INFO, TRANS_ALERTS, TRADE_KEY, ADDRESS_HISTORY_KEY } from './constants';
import { ROUTES } from '../../../constants/routes';
import { eventBus } from '../../../utils/eventBus';
import { IMPORT_ALL_TRADES, IMPORT_SELECTED_TRADES } from '../../../constants/eventBus';

import { ClientService } from '../../../shared/api/ClientService';
import API from '../../../utils/api';

import './CreditReportContent.scss';

const CreditReport = () => {
  const { t } = useLocale();
  const { Panel } = Collapse;
  const { applicationFileId, reportId } = useParams<{ applicationFileId: string; reportId?: string }>();

  const [creditReport, setCreditReport] = useState<ClientService.CreditReportHistoryDto>();
  const [transAlerts, setTransAlerts] = useState<ClientService.CreditReportHistoryAlertDto[]>();
  const [alertsLoading, setAlertsLoading] = useState<boolean>(false);

  const [trades, setTrades] = useState<ClientService.CreditReportHistoryTradeDto[]>();
  const [tradesLoading, setTradesLoading] = useState<boolean>(false);

  const [address, setAddress] = useState<ClientService.CreditReportHistoryAddressDto[]>();
  const [addressLoading, setAddressLoading] = useState<boolean>(false);

  const [activePanels, setActivePanels] = useState<string[]>([BASIC_INFO]);

  const requestCreditReportHistoryDetail = useCallback(async () => {
    const response = await API.getCreditReportHistoryDetail(reportId as string);
    if (response) setCreditReport(response);
  }, [reportId]);

  const requestTrades = useCallback(async () => {
    setTradesLoading(true);
    const trades = await API.getTradeListByCreditReportHistoryId(reportId as string).catch(() =>
      setTradesLoading(false)
    );
    setTradesLoading(false);

    if (trades) setTrades(trades);
    return trades;
  }, [reportId]);

  const requestAddresses = useCallback(async () => {
    setAddressLoading(true);
    const address = await API.getAddressListByCreditReportHistoryId(reportId as string).catch(() =>
      setAddressLoading(false)
    );
    setAddressLoading(false);

    if (address) setAddress(address);
    return address;
  }, [reportId]);

  const requestAlerts = useCallback(async () => {
    setAlertsLoading(true);
    const transAlerts = await API.getAlertListByCreditReportHistoryId(reportId as string).catch(() =>
      setAlertsLoading(false)
    );
    setAlertsLoading(false);

    if (transAlerts) setTransAlerts(transAlerts);
    return transAlerts;
  }, [reportId]);

  const requestPanelInfo = useCallback(async () => {
    const address = await requestAddresses();
    const transAlerts = await requestAlerts();
    const trades = await requestTrades();

    setActivePanels((prev) => {
      const activeKeys = [...prev];
      if (trades?.length) activeKeys.push(TRADE_KEY);
      if (transAlerts?.length) activeKeys.push(TRANS_ALERTS);
      if (address?.length) activeKeys.push(ADDRESS_HISTORY_KEY);

      return activeKeys;
    });
  }, [requestAddresses, requestAlerts, requestTrades]);

  useEffect(() => {
    requestCreditReportHistoryDetail();
    requestPanelInfo();
  }, [reportId, requestCreditReportHistoryDetail, requestPanelInfo]);

  return (
    <div className="CreditReportContent">
      <Breadcrumb
        data={[
          { link: ROUTES.DASHBOARD, title: t.DASHBOARD },
          { link: `${ROUTES.DEBTOR_SEARCH}`, title: t.DEBTOR_SEARCH },
          { link: `${ROUTES.APPLICATION_OVERVIEW}/${applicationFileId}/`, title: t.APPLICATION_OVERVIEW },
          {
            link: `${ROUTES.APPLICATION_OVERVIEW}/${applicationFileId}/${ROUTES.CREDIT_REPORT_HISTORY}`,
            title: t.CREDIT_REPORT_HISTORY,
          },
          {
            title: t.CREDIT_REPORT,
          },
        ]}
      />
      <PageSubtitle
        title={t.CREDIT_REPORT}
        subtitle={`${t.REPORT_GENERATED_BY} ${creditReport?.firstName || ''} ${creditReport?.lastName || ''} ${t.ON} ${
          creditReport?.onFileDate ? moment(creditReport?.onFileDate).format(DATE_FORMAT2) : ''
        }`}
      />

      <Row align="middle" justify="space-between" style={{ paddingBottom: 40 }}>
        <Col>
          <Button
            onClick={() =>
              eventBus.dispatch(IMPORT_SELECTED_TRADES, {
                onSuccess: requestTrades,
              })
            }
          >
            Import Selected
          </Button>
        </Col>
        <Col>
          <Button
            onClick={() =>
              eventBus.dispatch(IMPORT_ALL_TRADES, {
                onSuccess: requestTrades,
              })
            }
          >
            Import All
          </Button>
        </Col>
      </Row>

      <Spin spinning={alertsLoading || addressLoading || tradesLoading}>
        <Collapse
          className="CreditReportContent__collapse"
          ghost
          activeKey={activePanels}
          onChange={(keys) => {
            if (Array.isArray(keys)) setActivePanels(keys);
          }}
        >
          <Panel key={BASIC_INFO} header={t.BASIC_INFO}>
            <Row>
              <DataItem
                label={t.NAME}
                value={`${creditReport?.firstName || ''} ${creditReport?.lastName || ''}`}
                colSpan={6}
              />
              <DataItem label={t.SIN} value={`${creditReport?.sin || '-'}`} colSpan={6} />
              <DataItem
                label={t.DOB}
                value={`${creditReport?.birthDate ? moment(creditReport?.birthDate).utc().format(DATE_FORMAT2) : '-'}`}
                colSpan={6}
              />
            </Row>
          </Panel>
          <Panel key={ADDRESS_HISTORY_KEY} header={t.ADDRESS_HISTORY}>
            <AddressHistory dataSource={address} />
          </Panel>
          <Panel key={TRANS_ALERTS} header={t.TRANS_ALERTS}>
            <TransAlerts dataSource={transAlerts} />
          </Panel>
          <Panel key={TRADE_KEY} header={t.TRADE} className="PanelContentAlignedLeft">
            <TradePanel dataSource={trades} />
          </Panel>
        </Collapse>
      </Spin>
    </div>
  );
};

export default CreditReport;
