import { useState, useEffect, useCallback } from 'react';
import { IErrorsMsgAndType, mapAndOrderMessages } from '../components/ErrorsContainer/ErrorsContainer';
import { ClientService } from '../shared/api/ClientService';
import useLocale from './useLocale';
import { APISaveResponse } from '../types/APICustom';

// interface IArgs {
//     genericErrorMessageKey?: string;
// }

// type IReturn = [
//     IErrorsMsgAndType[] | undefined,
//     React.Dispatch<React.SetStateAction<IErrorsMsgAndType[] | undefined>>,
//     React.Dispatch<any>
// ];

export interface IErrorsResponse {
  messages: IErrorsMsgAndType[] | undefined;
  hasErrors: boolean;
}

interface IReturn {
  processResponseForErrors: (response: APISaveResponse, genericErrorMessage?: string) => IErrorsResponse;
}

function useErrorHandling(): IReturn {
  const { t } = useLocale();

  // const [response, setResponse] = useState<ClientService.SaveResult | ClientService.RemoteServiceErrorResponse | undefined>()
  // const [errors, setErrors] = useState<IErrorsMsgAndType[] | undefined>();

  // the 'errors' values returned from this hook can be fed
  // into the 'errors' prop of the <ErrorsContainer> component

  const processResponseForErrors = useCallback(
    (response: any, genericErrorMessage: string = t.FORM_GENERIC_ERROR_SUBMITTING): IErrorsResponse => {
      // if (!response) return { messages: undefined, hasErrors: false };
      if (response == null)
        return {
          messages: [{ message: t.NO_DATA_WAS_RETRIEVED }],
          hasErrors: true,
        };

      if (response instanceof ClientService.RemoteServiceErrorResponse) {
        if (response.error?.code) {
          return { messages: [{ message: genericErrorMessage }], hasErrors: true };
        }
        if (response.error?.message) {
          console.error(response.error.message);
          return { messages: [{ message: response.error.message }], hasErrors: false };
        }
        console.error('There was an error.');
        return { messages: undefined, hasErrors: false };
      }

      if (
        response.hasErrors ||
        ((response instanceof ClientService.SaveResult || response instanceof ClientService.SaveResult) &&
          response.result === ClientService.Result.Failed)
      ) {
        let responseErrors: IErrorsMsgAndType[];
        if (response.messages && response.messages.length > 0) {
          responseErrors = mapAndOrderMessages(response.messages);
        } else {
          responseErrors = [
            {
              message: genericErrorMessage,
              messageType: ClientService.ResultMessageType.Error,
            },
          ];
        }
        return { messages: responseErrors, hasErrors: true };
      }

      if (response.hasWarnings) {
        if (response.messages && response.messages.length > 0) {
          const responseWarnings = mapAndOrderMessages(response.messages);
          return { messages: responseWarnings, hasErrors: false };
        }
        console.error('The server returned a warning without any attached message.');
        return { messages: undefined, hasErrors: false };
      }

      return { messages: undefined, hasErrors: false };
      // }
      // return { messages: undefined, hasErrors: false };
    },
    [t]
  );

  return {
    processResponseForErrors: processResponseForErrors,
  };
}

export default useErrorHandling;
