export const LOCALE_STORAGE_KEY = 'locale';
export const MISSING_NUMBER = 0;
export const SORTING_SAME = 0;
export const SORTING_HIGHER = 1;
export const SORTING_LOWER = -1;
export const HUNDRED_PERCENT = 100;
export const DATE_FORMAT = 'MM/DD/YYYY';
export const DATE_FORMAT2 = 'DD/MMM/YYYY';
export const DATE_FORMAT3 = 'MMM DD';
export const DATE_FORMAT4 = 'DD MMM YYYY';
export const DATE_FORMAT_PLACEHOLDER = 'dd/mmm/yyyy';
export const DATE_FORMAT_NOTE = 'DD/MMM/YYYY @ HH:mm:ss';
export const DATE_AND_TIME_FORMAT = 'MM/DD/YYYY hh:mm A';
export const DATE_AND_TIME_FORMAT2 = 'DD/MMM/YYYY hh:mm A';
export const DATE_AND_TIME_FORMAT3 = 'MM/DD/YYYY HH:mm';
export const TIME_FORMAT = 'HH:mm';
export const TIME_FORMAT2 = 'hh:mm A';
export const FIRST_ITEM = 0;
export const DEFAULT_PAGE_SIZE = 10;
export const OVERVIEW_LIST_SIZE = 5;
export const INITIAL_PAGE_INDEX = 0;
export const TASKACTION_LIST_SIZE = 50;

export const WEEKLY_VERSION = 'weekly';
export const PLACES_LIBRARY = 'places';
export const GEOMETRY_LIBRARY = 'geometry';
export const DEFAULT_LAT = 43.653225;
export const DEFAULT_LNG = -79.383186;
export const SORTING_ASC = 'asc';
export const SORTING_DESC = 'desc';

export const MAX_PAGE_SIZE = 1000;
