import React, { useState, useCallback, ReactNode, useEffect, ReactElement, isValidElement } from 'react';
import { Row, Col } from 'antd';
import DataItem, { DataItemProps } from '../DataItem/DataItem';
import Loading from '../Loading/Loading';
import useLocale from '../../hooks/useLocale';

import styles from './DynamicInfoBlock.module.scss';

export type TDataItem = ReactElement<DataItemProps> | DataItemProps;
type TSetStateWithDataItems = React.Dispatch<React.SetStateAction<TDataItem[] | undefined>>;

export interface IDynamicInfoBlockProps {
  apiGet?: () => Promise<any>;
  apiSetData?: (response: any, setState: TSetStateWithDataItems) => void; // allows you to manipulate response data before it is placed in state
  itemsPerRow?: number;
  data?: TDataItem[];
  externalDataIsLoading?: boolean;
  loadError?: boolean;
  className?: string;
  style?: React.CSSProperties;
}

const COLS_IN_GRID = 24;

const DynamicInfoBlock = ({
  apiGet,
  apiSetData,
  data,
  externalDataIsLoading,
  loadError,
  itemsPerRow = 3,
  className,
  style,
}: IDynamicInfoBlockProps): JSX.Element => {
  const { t } = useLocale();

  const [dataItems, setDataItems] = useState<TDataItem[]>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [loadingError, setLoadingError] = useState<boolean>(false);

  useEffect(() => {
    if (loadError) {
      setLoadingError(true);
    } else {
      setLoadingError(false);
    }
  }, [loadError]);

  const getColWithRecord = useCallback(
    (record, index) => {
      if (isValidElement(record)) {
        return (
          <Col key={`record_${String(index)}`} span={COLS_IN_GRID / itemsPerRow}>
            {record}
          </Col>
        );
      }
      return (
        <Col key={`record_${String(index)}`} span={COLS_IN_GRID / itemsPerRow}>
          <DataItem {...record} />
        </Col>
      );
    },
    [itemsPerRow]
  );

  const getRowOfRecords = useCallback(
    (records, rowIndex) => {
      return (
        <Row key={`row_${String(rowIndex)}`} gutter={20} className={styles.record_row}>
          {records.map((record: any, index: number) => {
            return getColWithRecord(record, index);
          })}
        </Row>
      );
    },
    [getColWithRecord]
  );

  const renderData = useCallback(() => {
    if (dataItems && dataItems.length > 0) {
      const allRows: ReactNode[] = [];
      let currentSetOfRecords: any[] = [];

      dataItems.forEach((dataItem, index, arr) => {
        const count = index + 1;
        if (count % itemsPerRow === 0 || count === arr.length) {
          currentSetOfRecords = [...currentSetOfRecords, dataItem];
          allRows.push(getRowOfRecords(currentSetOfRecords, index));
          currentSetOfRecords = [];
        } else {
          currentSetOfRecords = [...currentSetOfRecords, dataItem];
        }
      });

      return allRows;
    }
  }, [dataItems, getRowOfRecords, itemsPerRow]);

  const setData = useCallback(() => {
    if (data && data.length > 0) {
      setDataItems(data);
      return false;
    }

    if (apiGet) {
      setIsLoading(true);
      setLoadingError(false);
      apiGet()
        .then((response) => {
          if (response?.hasError) setLoadingError(true);
          setIsLoading(false);
          // set dataItems in state
          if (apiSetData) {
            apiSetData(response, setDataItems);
          } else {
            setDataItems(response);
          }
        })
        .catch((error) => {
          setLoadingError(true);
          console.error('There was an error retrieving the Summary data');
        });
    }
  }, [data, apiGet, apiSetData]);

  useEffect(() => {
    setData();
  }, [setData]);

  return (
    <div className={`${styles.DynamicInfoBlock} ${className ? className : ''}`} style={style}>
      {(isLoading || externalDataIsLoading) && <Loading noText />}
      {!isLoading && !externalDataIsLoading && loadingError ? (
        <div style={{ textAlign: 'center' }}>{t.DATA_COULD_NOT_BE_RETRIEVED}</div>
      ) : (
        renderData()
      )}
    </div>
  );
};

export default DynamicInfoBlock;
