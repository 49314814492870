import { useCallback, useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Table, Row, Col, Tabs, Space, Spin, Tooltip, Input, Divider, Typography } from 'antd';
import { Icon } from '@fluentui/react/lib/Icon';
import moment from 'moment';

import PreviewFile, { DocumentPreviewTypeEnum } from '../../../../components/PreviewFile';
import { IconRed } from '../Icons';
import NotesList from '../../NotesContent/NotesList/NotesList';
import Button, { Kinds_Custom } from '../../../../components/Button/Button';
import ReviewStatusModal from '../ReviewStatusModal/ReviewStatusModal';
import RejectModal from '../RejectModal/RejectModal';
import Filters from '../Filters/Filters';
import PreviewFileTab from '../DocumentsTable/PreviewFileTab';

import {
  readableBytes,
  argumentifyDocumentVerificationsSearchCriteria,
  statusNameToReadable,
  getVerificationRole,
  verifierButtonDetails,
  argumentifyVerifyDocument,
  getVerificationsStatusColor,
} from '../utils';
import { DATE_FORMAT2, DEFAULT_PAGE_SIZE, FIRST_ITEM, SORTING_ASC, SORTING_DESC } from '../../../../constants/common';
import { paginationShowTotal } from '../../../../utils/helpers';
import { IDocumentVerificationsSearchCriteria } from '../types';
import { AuthorizeService } from '../../../../components/Auth/AuthorizeService';
import { eventBus } from '../../../../utils/eventBus';
import { VERIFICATION_CHANGE_EVENT } from '../../../../constants/eventBus';
import { ROUTES } from '../../../../constants/routes';

import genericMessage from '../../../../utils/genericMessage';
import useModal from '../../../../hooks/useModal';
import useLocale from '../../../../hooks/useLocale';
import useDebounce from '../../../../hooks/useDebounce';
import { ClientService } from '../../../../shared/api/ClientService';
import API from '../../../../utils/api';
import { DOCUMENTS_TAB } from '../../ApplicationOverviewContent/ApplicationOverviewContent';
import { VERIFICATIONS_TAB } from '../DocumentsContent';

import '../DocumentsTable/DocumentsTable.scss';

enum TableViewEnum {
  TableList = 0,
  TablePreview = 1,
}

const LIST_TAB = 'list';
const NOTES_TAB = 'notes';

interface IProps {
  initialDocumentId?: string;
}

function VerificationTab({ initialDocumentId }: IProps): JSX.Element {
  const { TabPane } = Tabs;
  const { Search } = Input;
  const { Text } = Typography;

  const navigate = useNavigate();

  const { t, getLocalizedDocumentName, getLocalizedDocumentVerificationName } = useLocale();
  const { applicationFileId } = useParams<{ applicationFileId?: string }>();
  const user = AuthorizeService.getCurrentUserInfo();
  const { showModal, closeModal } = useModal();

  const [documents, setDocuments] = useState<ClientService.DocumentVerificationDto[]>();
  const [totalCount, setTotalCount] = useState<number>(0);
  const [documentsLoading, setDocumentsLoading] = useState<boolean>(false);

  const [criteria, setCriteria] = useState<IDocumentVerificationsSearchCriteria>({
    maxResultCount: DEFAULT_PAGE_SIZE,
    skipCount: 0,
    fileId: applicationFileId,
    assignedToCurrentUser: true,
    includeOnlyPending: true,
    filterText: '',
    documentId: initialDocumentId,
  });

  const [documentId, setDocumentId] = useState<string | undefined>();
  const [document, setDocument] = useState<ClientService.IDocumentDto>();

  const [documentVerificationId, setDocumentVerificationId] = useState<string>();
  const [documentVerification, setDocumentVerification] = useState<ClientService.DocumentVerificationDetailDto>();

  const [documentVerificationRole, setDocumentVerificationRole] = useState<ClientService.DocumentVerificationRoleDto>();

  const [listTypes, setListTypes] = useState<ClientService.LookupDto[]>();
  const [listFolders, setListFolders] = useState<ClientService.LookupDto[]>();

  const [tableView, setTableView] = useState<TableViewEnum>(
    initialDocumentId ? TableViewEnum.TablePreview : TableViewEnum.TableList
  );
  const [activeTab, setActiveTab] = useState<string>(initialDocumentId ? NOTES_TAB : LIST_TAB);

  const [searchText, setSearchText] = useState<string>();
  const filterText = useDebounce(searchText);

  const requestVerificationDocuments = useCallback(async () => {
    setDocumentsLoading(true);
    const response = await API.documentVerificationsGET2(
      ...argumentifyDocumentVerificationsSearchCriteria({ ...criteria })
    ).catch((e) => {
      setDocumentsLoading(false);
      genericMessage.error(e);
    });
    setDocumentsLoading(false);

    if (response) {
      setDocuments(response?.items);
      if (!documentId) setDocumentId(response?.items?.[FIRST_ITEM]?.documentId);
      if (!documentVerificationId) setDocumentVerificationId(response?.items?.[FIRST_ITEM]?.id);
    }
    setTotalCount(response?.totalCount || 0);
  }, [criteria, documentId, documentVerificationId]);

  const requestVerificationDocument = useCallback(
    async (documentVerificationId) => {
      const response = await API.documentVerificationsGET(documentVerificationId as string);

      if (response) {
        setDocumentVerification(response);
        setDocumentVerificationRole(getVerificationRole(response, user?.profile?.sub));
      }
    },
    [user?.profile?.sub]
  );

  const requestDocument = useCallback(async (documentId) => {
    const response = await API.documentsGET2(documentId);
    if (response) {
      setDocument(response);
    }
  }, []);

  const requestListTypes = useCallback(async () => {
    const response = await API.listTypes();
    if (response) setListTypes(response);
  }, []);

  const requestListFolders = useCallback(async () => {
    const response = await API.listFolders();
    if (response) setListFolders(response);
  }, []);

  const handleFiltersReset = useCallback(() => {
    setCriteria((prev) => ({ ...prev, documentFolders: [], skipCount: 0 }));
  }, []);

  const handleFiltersChange = useCallback((documentFolders) => {
    setCriteria((prev) => ({ ...prev, documentFolders, skipCount: 0 }));
  }, []);

  const handleSearchChange = useCallback((e) => {
    const value = e.target.value;
    setSearchText(value);
  }, []);

  const handleTableChange = useCallback(
    (pagination: any, filters: any, sorter: any) => {
      setCriteria((prev) => ({
        ...prev,
        documentTypes: filters.documentTypeName || undefined,
        verificationStatuses: filters.documentVerificationStatus || undefined,
        isUrgent: filters?.isUrgent?.length === 1 ? filters?.isUrgent?.[0] : undefined,
        maxResultCount: pagination.pageSize,
        skipCount: pagination.pageSize * (pagination.current - 1),
        sorting:
          sorter.field !== undefined
            ? `${sorter.field === 'uploadDate' ? 'CreationTime' : sorter.field} ${
                sorter.order === 'ascend' ? SORTING_ASC : SORTING_DESC
              }`
            : prev.sorting,
      }));
    },
    [setCriteria]
  );

  const resetCriteria = useCallback(
    () =>
      setCriteria((prev) => ({
        ...prev,
        maxResultCount: DEFAULT_PAGE_SIZE,
        skipCount: 0,
        filterText: '',
        documentId: undefined,
      })),
    []
  );

  const handleVerificationChangeSuccess = useCallback(() => {
    resetCriteria();
    setActiveTab(LIST_TAB);
    setTableView(TableViewEnum.TableList);
    eventBus.dispatch(VERIFICATION_CHANGE_EVENT);
  }, [resetCriteria]);

  const handleReject = useCallback(() => {
    showModal(
      <RejectModal
        documentVerificationId={documentVerificationId}
        verificationRole={documentVerificationRole?.verificationRole}
        onOk={() => {
          closeModal();
          handleVerificationChangeSuccess();
        }}
        onCancel={closeModal}
      />
    );
  }, [
    showModal,
    documentVerificationId,
    documentVerificationRole?.verificationRole,
    closeModal,
    handleVerificationChangeSuccess,
  ]);

  const handleAccept = useCallback(async () => {
    const result = await API.submitPUT(
      ...argumentifyVerifyDocument({
        id: documentVerificationId as string,
        body: {
          verificationRole: documentVerificationRole?.verificationRole,
          verificationResult: ClientService.DocumentVerificationRoleResultEnum.Accept,
        } as ClientService.DocumentVerificationSubmitDto,
      })
    );

    if (result?.result === ClientService.Result.Successful) {
      handleVerificationChangeSuccess();
    } else {
      genericMessage.error({}, result?.messages?.[0]?.body);
    }
  }, [documentVerificationId, documentVerificationRole?.verificationRole, handleVerificationChangeSuccess]);

  const handleEdit = useCallback((id) => {
    setDocumentVerificationId(id);
    setActiveTab(NOTES_TAB);
    setTableView(TableViewEnum.TablePreview);
  }, []);

  const excludeDocumentIdFromSearchResults = useCallback(() => {
    if (criteria?.documentId) {
      setCriteria((prev) => ({ ...prev, documentId: undefined }));
    }
  }, [criteria?.documentId]);

  const toggleTableView = useCallback(() => {
    excludeDocumentIdFromSearchResults();
    setTableView(tableView === TableViewEnum.TablePreview ? TableViewEnum.TableList : TableViewEnum.TablePreview);
    setActiveTab(LIST_TAB);
  }, [excludeDocumentIdFromSearchResults, tableView]);

  const handleTabClick = useCallback((activeKey) => {
    setActiveTab(activeKey);
  }, []);

  useEffect(() => {
    requestVerificationDocuments();
  }, [criteria, requestVerificationDocuments]);

  useEffect(() => {
    if (documentVerificationId && activeTab === NOTES_TAB) {
      requestVerificationDocument(documentVerificationId);
    }
  }, [activeTab, documentVerificationId, requestVerificationDocument, tableView]);

  useEffect(() => {
    if (!listTypes) requestListTypes();
  }, [listTypes, requestListTypes]);

  useEffect(() => {
    if (!listFolders) requestListFolders();
  }, [listFolders, requestListFolders]);

  useEffect(() => {
    if (documentId && tableView === TableViewEnum.TablePreview) requestDocument(documentId);
  }, [documentId, requestDocument, tableView]);

  useEffect(() => {
    setCriteria((prevCriteria) => ({ ...prevCriteria, filterText }));
  }, [filterText]);

  useEffect(() => {
    if (initialDocumentId) {
      setTableView(TableViewEnum.TablePreview);
      setDocumentId(initialDocumentId);
      navigate(`${ROUTES.APPLICATION_OVERVIEW}/${applicationFileId}`, {
        state: { preselectedTab: DOCUMENTS_TAB, preselectedDocumentsSubTab: VERIFICATIONS_TAB },
      });
    }
  }, [applicationFileId, initialDocumentId, navigate]);

  const columns = [
    {
      title: t.DOCUMENTS_NAME,
      dataIndex: 'documentName',
      key: 'documentName',
      sorter: true,
      width: '25%',
      render: (_: any, item: ClientService.DocumentVerificationDto) => (
        <Text style={{ whiteSpace: 'normal', wordBreak: 'break-word' }}>
          {getLocalizedDocumentVerificationName(item)}
        </Text>
      ),
    },
    {
      title: t.DOCUMENTS_FOLDER,
      dataIndex: 'documentFolderName',
      render: (text: string, item: ClientService.DocumentVerificationDto) => <>{item.documentFolderName}</>,
      key: 'documentFolderName',
      sorter: true,
      hidden: tableView === TableViewEnum.TablePreview,
    },
    {
      title: t.DOCUMENTS_UPLOADED,
      dataIndex: 'uploadDate',
      key: 'uploadDate',
      render: (text: string, item: ClientService.DocumentVerificationDto) => (
        <>{moment(item.uploadDate).format(DATE_FORMAT2)}</>
      ),
      sorter: true,
      hidden: tableView === TableViewEnum.TablePreview,
    },
    {
      title: t.DOCUMENTS_MODIFIED,
      dataIndex: 'modifiedDate',
      key: 'modifiedDate',
      render: (text: string, item: ClientService.DocumentVerificationDto) => (
        <>{moment(item.modifiedDate).format(DATE_FORMAT2)}</>
      ),
      sorter: true,
      hidden: tableView === TableViewEnum.TablePreview,
    },
    {
      title: t.DOCUMENTS_SIZE,
      dataIndex: 'fileSizeInBytes',
      key: 'fileSizeInBytes',
      render: (text: string, item: ClientService.DocumentVerificationDto) => <>{readableBytes(item.fileSizeInBytes)}</>,
      sorter: true,
      hidden: tableView === TableViewEnum.TablePreview,
    },
    {
      title: t.DOCUMENTS_TYPE,
      dataIndex: 'documentTypeName',
      key: 'documentTypeName',
      sorter: true,
      hidden: tableView === TableViewEnum.TablePreview,
      filters: listTypes?.map((item) => ({ text: item?.name as string, value: item?.enumValue as number })),
    },
    {
      title: t.DOCUMENTS_STATUS,
      key: 'documentVerificationStatus',
      render: (text: string, item: ClientService.DocumentVerificationDto) => (
        <div
          className="DocumentsTable__status-link"
          onClick={(e) => {
            e.stopPropagation();
            showModal(
              <ReviewStatusModal
                documentId={item?.id}
                onClose={closeModal}
                onDelete={() => {
                  closeModal();
                  requestVerificationDocuments();
                }}
              />
            );
          }}
          style={{ color: getVerificationsStatusColor(item?.documentVerificationStatus) }}
        >
          {statusNameToReadable(item?.documentVerificationStatus)}
        </div>
      ),
      sorter: true,
      filters: [
        {
          text: statusNameToReadable(ClientService.DocumentVerificationStatusEnum.Pending),
          value: ClientService.DocumentVerificationStatusEnum.Pending,
        },
      ],
    },
    {
      title: t.DOCUMENTS_DUE,
      dataIndex: 'dueDate',
      key: 'dueDate',
      render: (dueDate: moment.Moment) => (
        <>{dueDate && moment(dueDate).isAfter(moment('1970', 'Y')) ? moment(dueDate).format(DATE_FORMAT2) : '-'}</>
      ),
      sorter: true,
    },
    {
      title: tableView === TableViewEnum.TableList && t.DOCUMENTS_URGENT,
      dataIndex: 'isUrgent',
      key: 'isUrgent',
      render: (isUrgent: boolean) => (
        <>
          {tableView === TableViewEnum.TableList ? (
            isUrgent ? (
              t.YES
            ) : (
              t.NO
            )
          ) : isUrgent ? (
            <IconRed iconName="AlertSolid" />
          ) : (
            <></>
          )}
        </>
      ),
      sorter: tableView === TableViewEnum.TableList,
      filters: [
        { text: t.URGENT, value: true },
        { text: t.NOT_URGENT, value: false },
      ],
    },
    {
      key: 'action',
      render: (text: string, item: ClientService.DocumentVerificationDto) => (
        <IconRed iconName="Edit" onClick={() => handleEdit(item?.id)} />
      ),
      hidden: tableView === TableViewEnum.TablePreview,
    },
  ].filter((item) => !item.hidden);

  const table = (
    <Spin spinning={documentsLoading}>
      <Table
        rowKey="id"
        dataSource={documents}
        columns={columns}
        onChange={handleTableChange}
        pagination={{
          position: ['bottomLeft'],
          defaultPageSize: DEFAULT_PAGE_SIZE,
          showSizeChanger: true,
          total: totalCount,
          showTotal: paginationShowTotal,
        }}
        onRow={(record) => ({
          onClick: () => {
            setDocumentVerificationId(record?.id);
            setDocumentId(record?.documentId);
          },
        })}
        rowClassName={(record: ClientService.DocumentVerificationDto) =>
          record?.id === documentVerificationId ? 'DocumentsTable__row--selected' : 'DocumentsTable__row'
        }
        className={documents?.length ? 'DocumentsTable__table' : 'DocumentsTable__table-empty'}
      />
    </Spin>
  );

  const previewTable = (
    <Spin spinning={documentsLoading}>
      <Table
        rowKey="id"
        dataSource={documents}
        columns={columns}
        onChange={handleTableChange}
        pagination={{
          position: ['bottomLeft'],
          defaultPageSize: DEFAULT_PAGE_SIZE,
          showSizeChanger: true,
          total: totalCount,
          showTotal: paginationShowTotal,
        }}
        onRow={(record) => ({
          onClick: () => {
            setDocumentVerificationId(record?.id);
            setDocumentId(record?.documentId);
          },
        })}
        rowClassName={(record: ClientService.DocumentVerificationDto) =>
          record?.id === documentVerificationId ? 'DocumentsTable__row--selected' : 'DocumentsTable__row'
        }
        className={documents?.length ? 'DocumentsTable__table' : 'DocumentsTable__table-empty'}
      />
    </Spin>
  );

  return (
    <div className="DocumentsTable">
      <Row justify="space-between" className="DocumentsTable__header">
        <Col xl={18} xs={16}>
          <Row align="middle" gutter={20} justify="start">
            <Filters
              listFolders={listFolders}
              documentFolders={criteria.documentFolders}
              onFoldersSelect={handleFiltersChange}
            />
            <Col onClick={handleFiltersReset} className="DocumentsTable__clear">
              {t.CLEAR_ALL}
            </Col>
            <Col>
              <Search value={searchText} onChange={handleSearchChange} placeholder={t.SEARCH} allowClear />
            </Col>
          </Row>
        </Col>
      </Row>

      <Divider className="DocumentsTable__divider" />

      {tableView === TableViewEnum.TableList && (
        <>
          <Row align="middle" justify="end">
            <Col>
              <Space>
                <Tooltip title={t.FILE_VIEW}>
                  <IconRed iconName="Page" onClick={toggleTableView} />
                </Tooltip>
              </Space>
            </Col>
          </Row>
          {table}
        </>
      )}

      {tableView === TableViewEnum.TablePreview && (
        <Row gutter={20}>
          <Col span={8}>
            <Tabs
              tabBarExtraContent={
                <Space>
                  <Tooltip title={t.BACK_TO_LIST_VIEW}>
                    <IconRed iconName="BulletedList" onClick={toggleTableView} />
                  </Tooltip>
                </Space>
              }
              activeKey={activeTab}
              onTabClick={handleTabClick}
              renderTabBar={() => (
                <Row align="middle" justify="space-between">
                  <Col>
                    <Row align="middle" justify="start">
                      <PreviewFileTab
                        title={t.FILE_INFORMATION}
                        iconName="PageList"
                        onClick={() => setActiveTab(LIST_TAB)}
                        isActive={activeTab === LIST_TAB}
                      />
                      <PreviewFileTab
                        title={t.COMMENTS}
                        iconName="Chat"
                        onClick={() => setActiveTab(NOTES_TAB)}
                        isActive={activeTab === NOTES_TAB}
                        disabled={!documentVerificationId}
                      />
                    </Row>
                  </Col>
                  <Col>
                    <Space>
                      <Tooltip title={t.BACK_TO_LIST_VIEW}>
                        <IconRed iconName="BulletedList" onClick={toggleTableView} />
                      </Tooltip>
                    </Space>
                  </Col>
                </Row>
              )}
            >
              <TabPane key={LIST_TAB}>{previewTable}</TabPane>
              <TabPane key={NOTES_TAB}>
                <>
                  {documentVerificationRole?.verificationResult ===
                    ClientService.DocumentVerificationRoleResultEnum.Pending && (
                    <>
                      {documentVerification?.isUrgent && (
                        <Row justify="end" align="middle" gutter={7}>
                          <Col>Urgent</Col>
                          <Col>
                            <Icon iconName="AlertSolid" className="DocumentsTable__basic-icon" />
                          </Col>
                        </Row>
                      )}
                      <Row gutter={20} justify="center">
                        <Col>
                          <Button onClick={handleReject}>Reject</Button>
                        </Col>
                        <Col>
                          <Button
                            onClick={handleAccept}
                            kind={
                              verifierButtonDetails(documentVerificationRole?.verificationRole)?.kind as Kinds_Custom
                            }
                          >
                            {verifierButtonDetails(documentVerificationRole?.verificationRole)?.title}
                          </Button>
                        </Col>
                      </Row>
                    </>
                  )}
                  <NotesList
                    pagination={false}
                    maxResultCount={5}
                    isAddMode
                    documentId={documentId}
                    documentVerificationId={documentVerificationId}
                    isAvatarVisible={false}
                  />
                </>
              </TabPane>
            </Tabs>
          </Col>
          <Col span={16}>
            {!documentsLoading && (
              <PreviewFile
                file={document}
                documentPreviewType={DocumentPreviewTypeEnum.document}
                onUpdate={requestVerificationDocuments}
              />
            )}
          </Col>
        </Row>
      )}
    </div>
  );
}

export default VerificationTab;
