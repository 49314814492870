import { useRef } from 'react';
import { useDrag, useDrop } from 'react-dnd';
import { Icon } from '@fluentui/react/lib/Icon';

import '../RequestForReview.scss';

export const ItemTypes = {
  CARD: 'card',
};

interface ICard {
  id: string;
  text: string;
  index: number;
  moveCard: (dragIndex: number, hoverIndex: number) => void;
  isEditable?: boolean;
}

const DndCard = ({ id, text, index, moveCard, isEditable = false }: ICard) => {
  const ref = useRef<HTMLInputElement>(null);
  const [{ handlerId }, drop] = useDrop({
    accept: ItemTypes.CARD,
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      };
    },
    hover(item: any, monitor) {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;
      if (dragIndex === hoverIndex) {
        return;
      }

      moveCard(dragIndex, hoverIndex);

      item.index = hoverIndex;
    },
  });

  const [{ isDragging }, drag] = useDrag({
    type: ItemTypes.CARD,
    item: () => {
      return { id, index };
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const opacity = isDragging ? 0 : 1;
  if (isEditable) drag(drop(ref));

  return (
    <div ref={ref} style={{ opacity }} className="RequestForReview__card" data-handler-id={handlerId}>
      {isEditable && <Icon iconName="GripperDotsVertical" />}
      {text}
    </div>
  );
};

export default DndCard;
