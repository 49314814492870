import { createContext, useState } from 'react';

interface IAttachmentContext {
  isUnsavedForm: boolean;
  setIsUnsavedForm: (isUnsavedForm: boolean) => void;
}

interface IProps {
  children: JSX.Element;
}

const UnsavedFormContext = createContext<IAttachmentContext>({ isUnsavedForm: false, setIsUnsavedForm: () => {} });

const UnsavedFormProvider = ({ children }: IProps) => {
  const [isUnsavedForm, setIsUnsavedForm] = useState<boolean>(false);

  return (
    <UnsavedFormContext.Provider value={{ isUnsavedForm, setIsUnsavedForm }}>{children}</UnsavedFormContext.Provider>
  );
};

export { UnsavedFormContext, UnsavedFormProvider };
