import { useCallback, useEffect } from 'react';
import { Form, Input, Col, Row, Select, ConfigProvider } from 'antd';

import { ClientService } from '../../../shared/api/ClientService';
import API from '../../../utils/api';

import './MyProfile.scss';
import useLocale from '../../../hooks/useLocale';

function MyProfile() {
  const [form] = Form.useForm<ClientService.ProfileInfoDto>();
  const { t } = useLocale();
  const requestProfileInfo = useCallback(async () => {
    const response = await API.getProfileInfo();

    if (response) {
      form.setFieldsValue(response?.[0]);
    }
  }, [form]);

  useEffect(() => {
    requestProfileInfo();
  }, [requestProfileInfo]);

  return (
    <ConfigProvider componentSize="large">
      <Form form={form} layout="vertical" className="MyProfile">
        <div className="MyProfile__subtitle">{t.ACCOUNT_DETAILS}</div>
        <Row gutter={20}>
          <Col span={8}>
            <Form.Item label={t.NAME} name="name">
              <Input disabled />
            </Form.Item>

            <Form.Item label={t.EMAIL} name="email">
              <Input disabled />
            </Form.Item>

            <Form.Item label={t.ROLE_S} name="roles">
              <Select mode="multiple" allowClear={false} suffixIcon={null} disabled />
            </Form.Item>

            <Form.Item label={t.TEAM_S} name="teams">
              <Select mode="multiple" allowClear={false} suffixIcon={null} disabled />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </ConfigProvider>
  );
}

export default MyProfile;
