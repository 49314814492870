import { useCallback, useState, useEffect } from 'react';
import { Form, Upload, message, FormInstance } from 'antd';
import type { RcFile, UploadFile } from 'antd/es/upload/interface';

import BulkRow from './BulkRow';
import NewFileRow from './NewFileRow';
import NewSupportingFileRow from './NewSupportingFileRow';
import ConvertToPdfWrapper from './ConvertToPdfWrapper';
import DraggerInner from './DraggerInner';

import useLocale from '../../../hooks/useLocale';
import { FileProps, AttachmentsFormProps, FileActionEnum, DraggerProps } from '../types';
import { NewSupportingFileRowLayoutProps, FilesColumnsProps } from '../types';
import { ClientService } from '../../../shared/api/ClientService';
import API from '../../../utils/api';
import { eventBus } from '../../../utils/eventBus';
import { CONVERT_TO_PDF_EVENT } from '../../../constants/eventBus';

import '../AttachmentsTab.scss';

interface IProps {
  form: FormInstance<AttachmentsFormProps>;
  supportingFolderId?: string;
  prefixCodes?: (string | undefined)[];
  newSupportingFileRowLayoutProps?: NewSupportingFileRowLayoutProps;
  draggerProps?: DraggerProps;
  columnsProps?: FilesColumnsProps;
  businessNames?: (string | undefined)[];
  supportingFolderCode?: string | undefined;
}

function NewFiles({
  form,
  supportingFolderId,
  prefixCodes,
  newSupportingFileRowLayoutProps,
  draggerProps,
  columnsProps,
  businessNames,
  supportingFolderCode,
}: IProps): JSX.Element {
  const { t } = useLocale();

  const [totalSize, setTotalSize] = useState<number>(0);
  const [types, setTypes] = useState<ClientService.LookupDto[]>();

  const requestListTypes = useCallback(async () => {
    const response = await API.listTypes();
    if (response) setTypes(response);
  }, []);

  const checkIfAllowedType = useCallback(
    (file: RcFile) =>
      types?.find(
        (item) =>
          item?.code === file?.type || file?.name?.split('.')?.pop()?.toLowerCase() === item?.name?.toLowerCase()
      ),
    [types]
  );

  const handleBeforeUpload = useCallback(
    (file: RcFile, fileList: RcFile[]) => {
      if (!checkIfAllowedType(file)) {
        message.error(t.DOCUMENTS_INVALID_TYPE_MESSAGE);
        return Upload.LIST_IGNORE;
      }

      if (draggerProps?.singleFileSizeLimitMB) {
        const allowedSingleFileSize = file.size / 1024 / 1024 < draggerProps?.singleFileSizeLimitMB;
        if (!allowedSingleFileSize) {
          message.error(
            `${t.THE_SIZE_OF_ATTACHMENTS_SHLD_NT_BE_GREATER_THAN} ${draggerProps?.singleFileSizeLimitMB}  ${t.MB}`
          );
          return Upload.LIST_IGNORE;
        }
      }

      if (draggerProps?.totalFilesSizeLimitMB) {
        const allFilesSize = fileList.reduce((prev, curr) => prev || 0 + curr.size || 0, 0) + totalSize;
        const allowedAllFilesSize = allFilesSize / 1024 / 1024 < draggerProps?.totalFilesSizeLimitMB;

        if (!allowedAllFilesSize) {
          if (file.uid === fileList?.[0]?.uid) {
            message.error(
              `${t.THE_SIZE_OF_ATTACHMENTS_SHLD_NT_BE_GREATER_THAN} ${draggerProps?.totalFilesSizeLimitMB}  ${t.MB}`
            );
          }
          return Upload.LIST_IGNORE;
        }

        setTotalSize(allFilesSize);
      }

      return false;
    },
    [
      checkIfAllowedType,
      draggerProps?.singleFileSizeLimitMB,
      draggerProps?.totalFilesSizeLimitMB,
      t.DOCUMENTS_INVALID_TYPE_MESSAGE,
      t.THE_SIZE_OF_ATTACHMENTS_SHLD_NT_BE_GREATER_THAN,
      t.MB,
      totalSize,
    ]
  );

  const handleUploadFileChange = useCallback(
    (info) => {
      eventBus.dispatch(CONVERT_TO_PDF_EVENT, {
        uploadFiles: info?.fileList,
        onConvertionSuccess: (fileList: UploadFile[]) => {
          const isReplaceMode = draggerProps?.mode === FileActionEnum.Replace;
          const newFiles = form.getFieldValue('newFiles') || [];
          const newItems = fileList?.filter(
            (item: UploadFile) => !newFiles?.find((i: FileProps) => i.uid === item.uid)
          );
          const newFileList = [...(isReplaceMode ? [] : newFiles), ...(newItems || [])]?.map((item) =>
            supportingFolderId
              ? {
                  ...item,
                  documentFolderId: supportingFolderId,
                  isForSpouse: !columnsProps?.hasForSpouseColumn
                    ? undefined
                    : newSupportingFileRowLayoutProps?.preselectedForSpouseInput,
                  businessName:
                    !columnsProps?.hasBusinessNameColumn ||
                    !newSupportingFileRowLayoutProps?.preselectedBusinessInput ||
                    (businessNames?.length && businessNames?.length > 1)
                      ? undefined
                      : businessNames?.[0],
                }
              : {
                  ...item,
                  documentFolderId: isReplaceMode ? newFiles?.[0]?.documentFolderId : undefined,
                  documentPrefixId: isReplaceMode ? newFiles?.[0]?.documentPrefixId : undefined,
                  additionalInformation: isReplaceMode ? newFiles?.[0]?.additionalInformation : undefined,
                  id: isReplaceMode ? newFiles?.[0]?.id : undefined,
                }
          );
          form.setFieldsValue({ newFiles: newFileList });
        },
      });
    },
    [
      draggerProps?.mode,
      form,
      supportingFolderId,
      columnsProps?.hasForSpouseColumn,
      columnsProps?.hasBusinessNameColumn,
      newSupportingFileRowLayoutProps?.preselectedForSpouseInput,
      newSupportingFileRowLayoutProps?.preselectedBusinessInput,
      businessNames,
    ]
  );

  useEffect(() => {
    if (!types) requestListTypes();
  }, [types, requestListTypes]);

  return (
    <>
      {draggerProps?.mode !== FileActionEnum.Edit && (
        <ConvertToPdfWrapper>
          <Form.Item shouldUpdate noStyle>
            {({ getFieldValue }) => (
              <Upload.Dragger
                {...draggerProps}
                name="files"
                className="AttachmentsTab__dragger"
                fileList={getFieldValue('newFiles')}
                beforeUpload={handleBeforeUpload}
                onChange={handleUploadFileChange}
                showUploadList={false}
              >
                <DraggerInner {...draggerProps} />
              </Upload.Dragger>
            )}
          </Form.Item>
        </ConvertToPdfWrapper>
      )}

      {!supportingFolderId && !(draggerProps?.mode === FileActionEnum.Replace) && (
        <BulkRow mode={draggerProps?.mode} columnsProps={columnsProps} />
      )}

      <Form.Item shouldUpdate noStyle>
        {({ getFieldValue }) =>
          getFieldValue('newFiles') && (
            <Form.List name="newFiles" initialValue={[]}>
              {(fields, { remove }) => (
                <div className="AttachmentsTab__upload-list">
                  {fields?.map((row, index) => (
                    <div key={`newFile-${row.name}`}>
                      {supportingFolderId ? (
                        <NewSupportingFileRow
                          remove={remove}
                          rowName={row.name}
                          prefixCodes={prefixCodes}
                          newSupportingFileRowLayoutProps={{
                            ...newSupportingFileRowLayoutProps,
                            hasLabels: index === 0,
                          }}
                          columnsProps={columnsProps}
                          businessNames={businessNames}
                          supportingFolderCode={supportingFolderCode}
                        />
                      ) : (
                        <NewFileRow
                          remove={remove}
                          rowName={row.name}
                          hasLabels={draggerProps?.mode === FileActionEnum.Replace}
                          columnsProps={{
                            ...columnsProps,
                            hasDeleteButton:
                              !(index === 0 && fields?.length === 1) ||
                              draggerProps?.mode === FileActionEnum.Upload ||
                              columnsProps?.hasDeleteButton,
                          }}
                        />
                      )}
                    </div>
                  ))}
                </div>
              )}
            </Form.List>
          )
        }
      </Form.Item>
    </>
  );
}

export default NewFiles;
