export enum DocumentPreviewTypeEnum {
  document = 0,
  documentVersion = 1,
  verificationDocument = 2,
}

export enum DocumentTypeEnum {
  PDF = 0,
  MSG = 1,
  OTHER = -1,
  UNDEFINED = -2,
}
