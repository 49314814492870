import { useCallback, useEffect, useState, useMemo } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Spin } from 'antd';

import Breadcrumb from '../../../components/Breadcrumb/Breadcrumb';
import PageSubtitle from '../../../components/PageSubtitle/PageSubtitle';
import SMSEditor from './SMSEditor/SMSEditor';
import BlockWithLabel from '../../../components/BlockWithLabel/BlockWithLabel';
import Divider from '../../../components/Divider/Divider';
import Button from '../../../components/Button/Button';

import useLocale from '../../../hooks/useLocale';
import useModal from '../../../hooks/useModal';
import { ROUTES } from '../../../constants/routes';
import { useApplicationFile } from '../../ApplicationOverviewPage';

import { ClientService } from '../../../shared/api/ClientService';
import API from '../../../utils/api';

import './SMSView.scss';

type SMSAttributesDto = ClientService.SMSAttributesDto;

function SMSView(): JSX.Element {
  const { applicationFileId, smsId } = useParams<{ applicationFileId: string; smsId: string }>();
  const { t } = useLocale();
  const navigate = useNavigate();
  const { showModal, closeModal } = useModal();

  const [details, setDetails] = useState<SMSAttributesDto>();
  const [detailsLoading, setDetailsLoading] = useState<boolean>();

  const [templates, setTemplates] = useState<ClientService.AppSMSTemplateDto[]>();
  const [templatesLoading, setTemplatesLoading] = useState<boolean>();

  const { data: appFileData } = useApplicationFile();
  const isDuplicate = useMemo(() => appFileData?.isDuplicate, [appFileData]);

  const requestSMSDetails = useCallback(async () => {
    setDetailsLoading(true);
    const response = await API.getSMSAttributes(smsId).catch(() => setDetailsLoading(false));
    if (response) setDetails(response);
    setDetailsLoading(false);
  }, [smsId]);

  const requestSMSTemplates = useCallback(async () => {
    setTemplatesLoading(true);
    const response = await API.getSMSTemplates().catch(() => setTemplatesLoading(false));
    if (response) setTemplates(response);
    setTemplatesLoading(false);
  }, []);

  useEffect(() => {
    if (smsId) requestSMSDetails();
  }, [smsId, requestSMSDetails]);

  useEffect(() => {
    if (!templates) requestSMSTemplates();
  }, [requestSMSTemplates, templates]);

  const redirectToHistoryPage = useCallback(() => {
    navigate(`${ROUTES.APPLICATION_OVERVIEW}/${applicationFileId}/${ROUTES.SMS_HISTORY}`);
  }, [navigate, applicationFileId]);

  const handleResend = useCallback(async () => {
    if (smsId) {
      await API.resendSMS({
        smsId: smsId as string,
      } as ClientService.SmsResendDto);
      redirectToHistoryPage();
    }
  }, [smsId, redirectToHistoryPage]);

  const handleShowEditor = useCallback(
    (data?: SMSAttributesDto) => {
      if (applicationFileId)
        showModal(
          <SMSEditor
            data={data}
            fileId={applicationFileId}
            onNext={() => {
              closeModal();
              redirectToHistoryPage();
            }}
            onCancel={closeModal}
          />
        );
    },
    [applicationFileId, showModal, closeModal, redirectToHistoryPage]
  );

  return (
    <div className="SMSView">
      <Breadcrumb
        data={[
          { link: ROUTES.DASHBOARD, title: t.DASHBOARD },
          { link: `${ROUTES.APPLICATION_OVERVIEW}/${applicationFileId}/`, title: t.APPLICATION_OVERVIEW },
          { link: `${ROUTES.APPLICATION_OVERVIEW}/${applicationFileId}/${ROUTES.SMS_HISTORY}`, title: t.SMS_HISTORY },
          { title: t.SMS_VIEW },
        ]}
      />
      <PageSubtitle
        title={t.SMS_SENT}
        buttonTitle={!isDuplicate && t.SMS_NEW}
        onButtonClick={() => handleShowEditor()}
      />

      <Spin spinning={detailsLoading || templatesLoading}>
        <BlockWithLabel label={t.TEMPLATE}>
          {templates?.find((item) => item.id === details?.templateId)?.name || '-'}
        </BlockWithLabel>
        <Divider />
        <BlockWithLabel label={t.SMS_TO_MOBILE}>{details?.phoneNumber}</BlockWithLabel>
        <BlockWithLabel label={t.MESSAGE}>{details?.smsBody}</BlockWithLabel>
        {!isDuplicate && (
          <div className="SMSView__buttons-container">
            <Button kind="cancel" padding="large" disabled={!details} onClick={() => handleShowEditor(details)}>
              {t.DUPLICATE}
            </Button>
            <Button kind="primary" padding="large" disabled={!details} onClick={handleResend}>
              {t.RESEND}
            </Button>
          </div>
        )}
      </Spin>
    </div>
  );
}

export default SMSView;
