import { useCallback, useEffect, useState, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { Col, Row, Tabs, Typography, Space, message } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
import { Icon } from '@fluentui/react/lib/Icon';

import Table from '../../../../components/Table/Table';
import IncomeExpenseIncome from './IncomeExpenseIncome';
import IncomeExpenseExpense from './IncomeExpenseExpense';
import ActionConfirmationModal from '../../../../modals/ActionConfirmationModal/ActionConfirmationModal';
import Button from '../../../../components/Button/Button';

import useModal from '../../../../hooks/useModal';
import useLocale from '../../../../hooks/useLocale';

import eventBus from '../../../../utils/eventBus';
import { UPLOAD_FILES_EVENT } from '../../../../constants/eventBus';
import { DEFAULT_PAGE_SIZE, SORTING_SAME, SORTING_HIGHER, SORTING_LOWER } from '../../../../constants/common';
import { paginationShowTotal } from '../../../../utils/helpers';
import { formatter } from '../../../../utils/format';
import { ClientService } from '../../../../shared/api/ClientService';
import API from '../../../../utils/api';

import './ApplicationFormTab.scss';
import styles from '../../../../styles/style.module.scss';

interface IProps {
  fileAnalysisView?: boolean;
  applicationFile?: ClientService.IApplicationFileDto | null;
  onUpdate?: () => void;
  updateStatus?: () => void;
  onContinue?: () => void;
}

const IncomeExpense = ({
  applicationFile,
  onUpdate,
  fileAnalysisView = false,
  updateStatus,
  onContinue,
}: IProps): JSX.Element => {
  const { t } = useLocale();
  const { showModal, closeModal } = useModal();
  const { Text } = Typography;
  const { TabPane } = Tabs;
  const { applicationFileId } = useParams<{ applicationFileId: string }>();

  const isAppFormLocked = useMemo(
    () => Boolean(applicationFile?.isAppFormLocked) || Boolean(applicationFile?.isLocked),
    [applicationFile?.isAppFormLocked, applicationFile?.isLocked]
  );

  const [isReviewed, setIsReviewed] = useState(false);
  const [needToBeReviewed, setNeedToBeReviewed] = useState(false);
  const [incomeDetails, setIncomeDetails] = useState<ClientService.AppFormIncomeExpenseIncomeDetailListItem[]>([]);
  const [incomeDetailsLoading, setIncomeDetailsLoading] = useState(false);
  const [isIncomeReviewed, setIsIncomeReviewed] = useState(false);

  const [expenseDetails, setExpenseDetails] = useState<ClientService.AppFormIncomeExpenseExpenseDetailListItem[]>([]);
  const [expenseDetailsLoading, setExpenseDetailsLoading] = useState(false);
  const [isExpenseReviewed, setIsExpenseReviewed] = useState(false);

  const [totalIncome, setTotalIncome] = useState(0);
  const [totalExpense, setTotalExpense] = useState(0);
  const [budget, setBudget] = useState(0);

  const [incomeTypes, setIncomeTypes] = useState<ClientService.LookupDto[]>([]);
  const [expenseTypes, setExpenseTypes] = useState<ClientService.LookupDto[]>([]);

  const [activeTab, setActiveTab] = useState<string>('incomes');
  const [saveExpenseLoading, setSaveExpenseLoading] = useState<boolean>(false);
  const [saveIncomesLoading, setSaveIncomesLoading] = useState<boolean>(false);

  const refreshStatus = useCallback(() => {
    API.getIncomeExpenseStatus(applicationFileId).then((response) => {
      setIsReviewed(response.status === ClientService.AppFormStatusEnum.Reviewed);
      setNeedToBeReviewed(
        response.status === ClientService.AppFormStatusEnum.ReadyForReview ||
          response.status === ClientService.AppFormStatusEnum.Incomplete_Review
      );
    });
  }, [applicationFileId]);

  const markComplete = useCallback(() => {
    API.incomeExpenseMarkAsComplete(applicationFileId).then((response) => {
      if (updateStatus) updateStatus();
      refreshStatus();
    });
  }, [applicationFileId, refreshStatus, updateStatus]);

  const markReviewed = useCallback(() => {
    const markAsReviewType =
      isIncomeReviewed && isExpenseReviewed
        ? ClientService.IncomeExpenseMarkAsReviewTypeEnum.Both
        : isIncomeReviewed
        ? ClientService.IncomeExpenseMarkAsReviewTypeEnum.Income
        : ClientService.IncomeExpenseMarkAsReviewTypeEnum.Expense;

    API.incomeExpenseMarkAsReviewed(applicationFileId, markAsReviewType).then((response) => {
      if (
        markAsReviewType == ClientService.IncomeExpenseMarkAsReviewTypeEnum.Both ||
        markAsReviewType == ClientService.IncomeExpenseMarkAsReviewTypeEnum.Income
      )
        requestIncome();
      if (
        markAsReviewType == ClientService.IncomeExpenseMarkAsReviewTypeEnum.Both ||
        markAsReviewType == ClientService.IncomeExpenseMarkAsReviewTypeEnum.Expense
      )
        requestExpenses();
      if (updateStatus) updateStatus();
      refreshStatus();
    });
  }, [applicationFileId, isIncomeReviewed, isExpenseReviewed, refreshStatus, updateStatus]);

  const requestIncome = useCallback(() => {
    setIncomeDetailsLoading(true);
    API.listAppFormIncomeDetails(applicationFileId).then((response) => {
      setIncomeDetails(response);
      setIncomeDetailsLoading(false);

      setTotalIncome(response.map((item) => item?.amount || 0).reduce((sum, current) => sum + current, 0));
      if (onUpdate) onUpdate();
    });
  }, [applicationFileId, onUpdate]);

  const requestExpenses = useCallback(() => {
    setExpenseDetailsLoading(true);
    API.listAppFormExpenseDetails(applicationFileId).then((response) => {
      setExpenseDetails(
        fileAnalysisView
          ? response.filter((item) => item.expenseType?.enumValue === ClientService.ExpenseTypeEnum.NonDiscretionary)
          : response
      );
      setExpenseDetailsLoading(false);

      setTotalExpense(response.map((item) => item?.amount || 0).reduce((sum, current) => sum + current, 0));
      if (onUpdate) onUpdate();
    });
  }, [applicationFileId, fileAnalysisView, onUpdate]);

  useEffect(() => {
    requestIncome();
    requestExpenses();

    API.listIncomeTypes().then((response) => {
      setIncomeTypes(response);
    });

    API.listExpenseTypes().then((response) => {
      setExpenseTypes(response);
    });

    refreshStatus();
  }, [refreshStatus, requestExpenses, requestIncome, needToBeReviewed]);

  useEffect(() => {
    setBudget(totalIncome - totalExpense);
  }, [totalIncome, totalExpense]);

  const handleIncomeSave = useCallback(
    async (body: ClientService.AppFormIncomeExpenseIncomeDto, incomeUploadListenerStatus: boolean) => {
      setSaveIncomesLoading(true);

      if (incomeUploadListenerStatus) {
        eventBus.dispatch(UPLOAD_FILES_EVENT, {
          onUploadSuccess: async (documentIds?: string[]) => {
            body.documentIds = documentIds || [];
            const response = await API.updateAppFormIncome(body).catch(() => setSaveIncomesLoading(false));

            setSaveIncomesLoading(false);

            if (response) {
              closeModal();
              requestIncome();
              if (updateStatus) updateStatus();
              refreshStatus();
            }
          },
          onUploadError: (errorMessage?: string) => {
            setSaveIncomesLoading(false);
            errorMessage && message.error(errorMessage);
          },
        });
      } else {
        body.documentIds = [];
        const response = await API.updateAppFormIncome(body).catch(() => setSaveIncomesLoading(false));

        setSaveIncomesLoading(false);

        if (response) {
          closeModal();
          requestIncome();
          if (updateStatus) updateStatus();
          refreshStatus();
        }
      }
    },
    [closeModal, refreshStatus, requestIncome, updateStatus]
  );

  const handleOpenIncome = useCallback(async () => {
    const response = await API.getAppFormIncome(applicationFileId);

    if (response) {
      showModal(
        <IncomeExpenseIncome
          fileId={applicationFileId}
          data={response}
          incomeTypes={incomeTypes}
          onSave={(body: ClientService.AppFormIncomeExpenseIncomeDto, incomeUploadListenerStatus: boolean) => {
            setIsIncomeReviewed(true);
            handleIncomeSave(body, incomeUploadListenerStatus);
          }}
          onCancel={() => {
            setIsIncomeReviewed(true);
            closeModal();
          }}
          loading={saveIncomesLoading}
          isLocked={isAppFormLocked}
          isMissingSupportingDocuments={Boolean(incomeDetails?.find((item) => item?.isSupportingDocumentsIncomplete))}
        />
      );
    }
  }, [
    applicationFileId,
    closeModal,
    handleIncomeSave,
    incomeDetails,
    incomeTypes,
    isAppFormLocked,
    saveIncomesLoading,
    showModal,
  ]);

  const handleExpenseSave = useCallback(
    async (body: ClientService.AppFormIncomeExpenseExpenseDto, expenseUploadListenerStatus: boolean) => {
      if (expenseUploadListenerStatus) {
        setSaveExpenseLoading(true);
        eventBus.dispatch(UPLOAD_FILES_EVENT, {
          onUploadSuccess: async (documentIds?: string[]) => {
            body.documentIds = documentIds || [];
            const response = await API.updateAppFormExpense(body).catch(() => setSaveExpenseLoading(false));

            setSaveExpenseLoading(false);

            if (response) {
              closeModal();
              requestExpenses();
              if (updateStatus) updateStatus();
              refreshStatus();
            }
          },
          onUploadError: (errorMessage?: string) => {
            setSaveExpenseLoading(false);
            errorMessage && message.error(errorMessage);
          },
        });
      } else {
        body.documentIds = [];
        const response = await API.updateAppFormExpense(body).catch(() => setSaveExpenseLoading(false));

        setSaveExpenseLoading(false);

        if (response) {
          closeModal();
          requestExpenses();
          if (updateStatus) updateStatus();
          refreshStatus();
        }
      }
    },
    [closeModal, refreshStatus, requestExpenses, updateStatus]
  );

  const handleOpenExpense = useCallback(async () => {
    const data = await API.getAppFormExpense(applicationFileId);

    if (data) {
      showModal(
        <IncomeExpenseExpense
          fileId={applicationFileId}
          data={data}
          expenseTypes={
            fileAnalysisView
              ? expenseTypes.filter((item) => item.enumValue === ClientService.ExpenseTypeEnum.NonDiscretionary)
              : expenseTypes
          }
          loading={saveExpenseLoading}
          onSave={(body: ClientService.AppFormIncomeExpenseExpenseDto, expenseUploadListenerStatus: boolean) => {
            setIsExpenseReviewed(true);
            handleExpenseSave(body, expenseUploadListenerStatus);
          }}
          onCancel={() => {
            setIsExpenseReviewed(true);
            closeModal();
          }}
          isLocked={isAppFormLocked}
          isMissingSupportingDocuments={Boolean(expenseDetails?.find((item) => item?.isSupportingDocumentsIncomplete))}
        />
      );
    }
  }, [
    applicationFileId,
    closeModal,
    expenseDetails,
    expenseTypes,
    fileAnalysisView,
    handleExpenseSave,
    isAppFormLocked,
    saveExpenseLoading,
    showModal,
  ]);

  const handleDeleteIncome = useCallback(
    (incomeId?: string) => {
      if (incomeId) {
        API.deleteAppFormIncome(incomeId).then((response) => {
          requestIncome();
          if (updateStatus) updateStatus();
          refreshStatus();
        });
      }
    },
    [refreshStatus, requestIncome, updateStatus]
  );

  const handleDeleteIncomeConfirmation = useCallback(
    (incomeId?: string) => {
      showModal(
        <ActionConfirmationModal
          title={t.INCOME_DELETE_CONFIRMATION_TITLE}
          message={t.INCOME_DELETE_CONFIRMATION_MESSAGE}
          okText={t.DELETE}
          onOk={() => {
            handleDeleteIncome(incomeId);
            closeModal();
          }}
          onCancel={closeModal}
        />
      );
    },
    [
      closeModal,
      handleDeleteIncome,
      showModal,
      t.DELETE,
      t.INCOME_DELETE_CONFIRMATION_MESSAGE,
      t.INCOME_DELETE_CONFIRMATION_TITLE,
    ]
  );

  const handleDeleteExpense = useCallback(
    (expenseId?: string) => {
      if (expenseId) {
        API.deleteAppFormExpense(expenseId).then((response) => {
          requestExpenses();
          if (updateStatus) updateStatus();
          refreshStatus();
        });
      }
    },
    [refreshStatus, requestExpenses, updateStatus]
  );

  const handleDeleteExpenseConfirmation = useCallback(
    (expenseId?: string) => {
      showModal(
        <ActionConfirmationModal
          title={t.EXPENSE_DELETE_CONFIRMATION_TITLE}
          message={t.EXPENSE_DELETE_CONFIRMATION_MESSAGE}
          okText={t.DELETE}
          onOk={() => {
            handleDeleteExpense(expenseId);
            closeModal();
          }}
          onCancel={closeModal}
        />
      );
    },
    [
      closeModal,
      handleDeleteExpense,
      showModal,
      t.DELETE,
      t.EXPENSE_DELETE_CONFIRMATION_MESSAGE,
      t.EXPENSE_DELETE_CONFIRMATION_TITLE,
    ]
  );

  const incomeColumns = useMemo(
    () => [
      {
        title: t.TYPE,
        dataIndex: 'incomeType.name',
        key: 'incomeType',
        sorter: (
          a: ClientService.AppFormIncomeExpenseIncomeDetailListItem,
          b: ClientService.AppFormIncomeExpenseIncomeDetailListItem
        ) => {
          if (!a.incomeType?.name && !b.incomeType?.name) return SORTING_SAME;
          if (!a.incomeType?.name) return SORTING_LOWER;
          if (!b.incomeType?.name) return SORTING_HIGHER;

          return a.incomeType?.name.localeCompare(b.incomeType?.name);
        },
        render: (text: string, item: ClientService.AppFormIncomeExpenseIncomeDetailListItem) => (
          <Row gutter={4} align="middle">
            <Col>
              {item?.isSupportingDocumentsIncomplete && (
                <Icon iconName="AlertSolid" className="ApplicationFormTab__grid-error" />
              )}
            </Col>
            <Col>{item?.incomeType?.name}</Col>
          </Row>
        ),
      },
      {
        title: t.AMOUNT,
        dataIndex: 'amount',
        key: 'amount',
        sorter: (
          a: ClientService.AppFormIncomeExpenseIncomeDetailListItem,
          b: ClientService.AppFormIncomeExpenseIncomeDetailListItem
        ) => {
          if (!a.amount && !b.amount) return SORTING_SAME;
          if (!a.amount) return SORTING_LOWER;
          if (!b.amount) return SORTING_HIGHER;

          return a.amount - b.amount;
        },
        render: (text: string, item: ClientService.AppFormIncomeExpenseIncomeDetailListItem) => (
          <>{formatter.currency(item?.amount)}</>
        ),
      },

      {
        title: '',
        dataIndex: 'action',
        key: 'action',
        render: (text: string, item: ClientService.AppFormIncomeExpenseIncomeDetailListItem) => (
          <>
            <DeleteOutlined
              className="ApplicationFormTab__input-action-icon"
              style={{
                color: isAppFormLocked ? styles.colorSecondary : styles.colorDanger,
                cursor: isAppFormLocked ? 'not-allowed' : 'pointer',
              }}
              onClick={(event) => {
                event.stopPropagation();
                if (isAppFormLocked) return;
                handleDeleteIncomeConfirmation(item?.id);
              }}
            />
          </>
        ),
      },
    ],
    [Text, handleDeleteIncomeConfirmation, isAppFormLocked, t.AMOUNT, t.DESCRIPTION, t.TYPE]
  );

  const expenseColumns = useMemo(
    () => [
      {
        title: t.TYPE,
        dataIndex: 'expenseType.name',
        key: 'expenseType',
        sorter: (
          a: ClientService.AppFormIncomeExpenseExpenseDetailListItem,
          b: ClientService.AppFormIncomeExpenseExpenseDetailListItem
        ) => {
          if (!a.expenseType?.name && !b.expenseType?.name) return SORTING_SAME;
          if (!a.expenseType?.name) return SORTING_LOWER;
          if (!b.expenseType?.name) return SORTING_HIGHER;

          return a.expenseType?.name.localeCompare(b.expenseType?.name);
        },
        render: (text: string, item: ClientService.AppFormIncomeExpenseExpenseDetailListItem) => (
          <Row gutter={4} align="middle">
            <Col>
              {item?.isSupportingDocumentsIncomplete && (
                <Icon iconName="AlertSolid" className="ApplicationFormTab__grid-error" />
              )}
            </Col>
            <Col>{item?.expenseType?.name}</Col>
          </Row>
        ),
      },
      {
        title: t.DESCRIPTION,
        dataIndex: 'expenseDescription.name',
        key: 'expenseDescription',
        sorter: (
          a: ClientService.AppFormIncomeExpenseExpenseDetailListItem,
          b: ClientService.AppFormIncomeExpenseExpenseDetailListItem
        ) => {
          if (!a.expenseDescription?.name && !b.expenseType?.name) return SORTING_SAME;
          if (!a.expenseDescription?.name) return SORTING_LOWER;
          if (!b.expenseDescription?.name) return SORTING_HIGHER;

          return a.expenseDescription?.name.localeCompare(b.expenseDescription?.name);
        },
        render: (text: string, item: ClientService.AppFormIncomeExpenseExpenseDetailListItem) => (
          <>{item?.expenseDescription?.name}</>
        ),
      },
      {
        title: t.AMOUNT,
        dataIndex: 'amount',
        key: 'amount',
        sorter: (
          a: ClientService.AppFormIncomeExpenseExpenseDetailListItem,
          b: ClientService.AppFormIncomeExpenseExpenseDetailListItem
        ) => {
          if (!a.amount && !b.amount) return SORTING_SAME;
          if (!a.amount) return SORTING_LOWER;
          if (!b.amount) return SORTING_HIGHER;

          return a.amount - b.amount;
        },
        render: (text: string, item: ClientService.AppFormIncomeExpenseExpenseDetailListItem) => (
          <>{formatter.currency(item?.amount)}</>
        ),
      },
      {
        title: '',
        dataIndex: 'action',
        key: 'action',
        render: (text: string, item: ClientService.AppFormIncomeExpenseExpenseDetailListItem) => (
          <>
            <DeleteOutlined
              className="ApplicationFormTab__input-action-icon"
              style={{
                color: isAppFormLocked ? styles.colorSecondary : styles.colorDanger,
                cursor: isAppFormLocked ? 'not-allowed' : 'pointer',
              }}
              onClick={(event) => {
                event.stopPropagation();
                if (isAppFormLocked) return;
                handleDeleteExpenseConfirmation(item?.id);
              }}
            />
          </>
        ),
      },
    ],
    [handleDeleteExpenseConfirmation, isAppFormLocked, t.AMOUNT, t.DESCRIPTION, t.TYPE]
  );

  const incomeTable = useMemo(
    () => (
      <Table
        rowKey="id"
        dataSource={incomeDetails}
        columns={incomeColumns}
        onRow={() => ({
          onClick: handleOpenIncome,
        })}
        rowClassName={(record: ClientService.AppFormIncomeExpenseIncomeDetailListItem) =>
          `ApplicationFormTab__clickable-row ${record?.updatedByClient ? 'user-changed' : ''}`
        }
        pagination={{
          position: ['bottomRight'],
          defaultPageSize: DEFAULT_PAGE_SIZE,
          showSizeChanger: true,
          total: incomeDetails.length,
          showTotal: paginationShowTotal,
        }}
        loading={incomeDetailsLoading}
        allWhite
      />
    ),
    [handleOpenIncome, incomeColumns, incomeDetails, incomeDetailsLoading]
  );

  const expenseTable = useMemo(
    () => (
      <Table
        rowKey="id"
        dataSource={expenseDetails}
        columns={expenseColumns}
        onRow={() => ({
          onClick: handleOpenExpense,
        })}
        rowClassName={(record: ClientService.AppFormIncomeExpenseExpenseDetailListItem) =>
          `ApplicationFormTab__clickable-row ${record?.updatedByClient ? 'user-changed' : ''}`
        }
        pagination={{
          position: ['bottomRight'],
          defaultPageSize: DEFAULT_PAGE_SIZE,
          showSizeChanger: true,
          total: expenseDetails.length,
          showTotal: paginationShowTotal,
        }}
        loading={expenseDetailsLoading}
        allWhite
      />
    ),
    [expenseColumns, expenseDetails, expenseDetailsLoading, handleOpenExpense]
  );

  return (
    <div className="ApplicationFormTab__incomes-expense">
      {!fileAnalysisView && (
        <>
          <Row gutter={20}>
            <Col span={8}>
              <Row className="ApplicationFormTab__incomes-expense--header">
                <b>
                  <div
                    className="ApplicationFormTab__incomes-expense--total-assets"
                    style={{ color: isAppFormLocked ? styles.colorSecondary : styles.colorGreen }}
                  >
                    {formatter.currency(totalIncome, 0)}
                  </div>
                  <div>{t.MONTHLY_INCOME}</div>
                </b>
              </Row>
            </Col>
            <Col span={8}>
              <Row className="ApplicationFormTab__incomes-expense--header">
                <b>
                  <div
                    className="ApplicationFormTab__incomes-expense--total-debts"
                    style={{ color: isAppFormLocked ? styles.colorSecondary : styles.colorDanger }}
                  >
                    {formatter.currency(totalExpense, 0)}
                  </div>
                  <div>{t.MONTHLY_EXPENSE}</div>
                </b>
              </Row>
            </Col>
            <Col span={8}>
              <Row className="ApplicationFormTab__incomes-expense--header">
                <b>
                  <div
                    className={`ApplicationFormTab__incomes-expense--total-${budget > 0 ? 'assets' : 'debts'}`}
                    style={{
                      color: isAppFormLocked
                        ? styles.colorSecondary
                        : budget > 0
                        ? styles.colorGreen
                        : styles.colorDanger,
                    }}
                  >
                    {formatter.currency(Math.abs(budget), 0)}
                  </div>
                  <div>
                    {t.BUDGET} {budget > 0 ? 'Surplus' : 'Deficit'}
                  </div>
                </b>
              </Row>
            </Col>
          </Row>

          <Row gutter={20}>
            <Col span={12}>
              <Row align="bottom" justify="space-between" style={{ paddingBottom: 8 }}>
                <Col span={20}>
                  <div className="ApplicationFormTab__incomes-expense--table-title">{t.INCOME}</div>
                </Col>
                <Col span={4}>
                  <Button
                    kind="primary"
                    narrow
                    className="ApplicationFormTab__add-icon"
                    onClick={handleOpenIncome}
                    disabled={isAppFormLocked}
                  >
                    <Icon iconName="Edit" /> {t.EDIT}
                  </Button>
                </Col>
              </Row>
            </Col>
            <Col span={12}>
              <Row align="bottom" justify="space-between" style={{ paddingBottom: 8 }}>
                <Col span={20}>
                  <div className="ApplicationFormTab__incomes-expense--table-title">{t.EXPENSE}</div>
                </Col>
                <Col span={4}>
                  <Button
                    kind="primary"
                    narrow
                    className="ApplicationFormTab__add-icon"
                    onClick={handleOpenExpense}
                    disabled={isAppFormLocked}
                  >
                    <Icon iconName="Edit" /> {t.EDIT}
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>
        </>
      )}

      {!fileAnalysisView ? (
        <>
          <Row gutter={20}>
            <Col span={12}>{incomeTable}</Col>
            <Col span={12}>{expenseTable}</Col>
          </Row>
          <Row justify="end" gutter={20} className="ApplicationFormTab__buttons-container">
            <Col>
              <Button
                padding="large"
                disabled={!needToBeReviewed || (!isExpenseReviewed && !isIncomeReviewed) || isAppFormLocked}
                onClick={markReviewed}
              >
                {t.MARK_AS_REVIEWED}
              </Button>
            </Col>
            <Col>
              <Button padding="large" disabled={!isReviewed || isAppFormLocked} onClick={markComplete}>
                {t.MARK_AS_COMPLETED}
              </Button>
            </Col>
            <Col>
              <Button kind="cancel" padding="large" onClick={onContinue} disabled={isAppFormLocked}>
                {t.CONTINUE}
              </Button>
            </Col>
          </Row>
        </>
      ) : (
        <Tabs
          tabBarExtraContent={
            <Space className="FileAnalysisConfiguration__edit-btn-container">
              {!isAppFormLocked && (
                <Button
                  kind="primary"
                  narrow
                  className="ApplicationFormTab__add-icon"
                  onClick={activeTab === 'incomes' ? handleOpenIncome : handleOpenExpense}
                >
                  <Icon iconName="Edit" /> {t.EDIT}
                </Button>
              )}
            </Space>
          }
          onTabClick={setActiveTab}
          activeKey={activeTab}
        >
          <TabPane key="incomes" tab={t.INCOMES}>
            {incomeTable}
          </TabPane>
          <TabPane key="expenses" tab={t.EXPENSES}>
            {expenseTable}
          </TabPane>
        </Tabs>
      )}
    </div>
  );
};

export default IncomeExpense;
