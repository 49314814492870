import { Menu, Col, Row } from 'antd';
import AddIcon from '../../images/add_icon.svg';

import { FieldTimeOutlined } from '@ant-design/icons';

import './ActionMenu.scss';

interface IProps {
  addItemTitle: string;
  historyItemTitle: string;
  onAddClick: () => void;
  onHistoryClick: () => void;
  addAllowed?: boolean;
}

const ActionMenu = ({ addItemTitle, historyItemTitle, onAddClick, onHistoryClick, addAllowed = true }: IProps) => (
  <Menu className="ActionMenu">
    {addAllowed && (
      <Menu.Item onClick={onAddClick} key="add">
        <Row gutter={8} align="middle">
          <Col>
            <img src={AddIcon} alt="add-icon" />
          </Col>
          <Col>{addItemTitle}</Col>
        </Row>
      </Menu.Item>
    )}
    <Menu.Item onClick={onHistoryClick} key="history">
      <Row gutter={8} align="middle">
        <Col>
          <FieldTimeOutlined className="ActionMenu__icon" />
        </Col>
        <Col>{historyItemTitle}</Col>
      </Row>
    </Menu.Item>
  </Menu>
);

export default ActionMenu;
