import { useCallback, useEffect } from 'react';
import { Spin, Form, FormItemProps } from 'antd';
import { useQuery } from 'react-query';

import SelectLookupDto from '../../SelectLookupDto/SelectLookupDto';

import { LabelsLayoutProps, OverrideInfo, PrefixInputLayoutProps } from '../types';
import { PREFIXES_QUERY, USE_QUERY_OPTIONS } from '../../../constants/reactQuery';
import API from '../../../utils/api';

import './PrefixesWithLoad.scss';
import useLocale from '../../../hooks/useLocale';

interface IProps extends FormItemProps, LabelsLayoutProps, PrefixInputLayoutProps {
  rowName: number;
  documentFolderId?: string;
  prefixCodes?: (string | undefined)[];
  overrideData?: OverrideInfo[];
  onDataFetch?: (documentPrefixId?: string) => void;
  onSelectedPrefixChanged?: () => void;
}

const PrefixFormItem = ({
  rowName,
  documentFolderId,
  prefixCodes,
  overrideData,
  onDataFetch,
  onSelectedPrefixChanged,
  ...props
}: IProps) => {
  const { t } = useLocale();

  const requestListPrefixes = useCallback(
    async (documentFolderId?: string, prefixCodes?: (string | undefined)[]) => {
      if (documentFolderId) {
        if (overrideData)
          return (await API.listPrefixes(documentFolderId, prefixCodes)).filter(
            (i) => overrideData.findIndex((j) => j.documentPrefixId === i.id && j.isOverridden) < 0
          );
        else return await API.listPrefixes(documentFolderId, prefixCodes);
      }
    },
    [overrideData]
  );

  const { data, isFetching } = useQuery(
    [PREFIXES_QUERY, documentFolderId, `${prefixCodes}`, overrideData],
    () => requestListPrefixes(documentFolderId, prefixCodes),
    {
      refetchOnMount: true,
      refetchOnWindowFocus: true,
      onSuccess: (response) => {
        if (onDataFetch) onDataFetch(response?.[0]?.id);
      },
    }
  );

  return (
    <Form.Item
      label={props?.hasLabels && 'Pre-fix'}
      rules={[{ required: true, message: t.PRFX_REQUIRED }]}
      name={[rowName, 'documentPrefixId']}
      initialValue={props?.preselectedPrefixInput ? data?.[0]?.id : undefined}
    >
      <SelectLookupDto
        placeholder={t.SELECT_PREFIX}
        data={data}
        loading={isFetching}
        notFoundContent={isFetching ? <Spin /> : null}
        disabled={props?.disabledPrefixInput}
        className="PrefixesWithLoad"
        onChange={() => {
          if (onSelectedPrefixChanged) onSelectedPrefixChanged();
        }}
      />
    </Form.Item>
  );
};

export default PrefixFormItem;
