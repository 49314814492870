import { useState, useEffect, useMemo, useCallback } from 'react';
import { Checkbox, Col } from 'antd';
import FormInput from '../../../../../components/Forms/FormInput/FormInput';
import { IAscendVariableFieldsBlockProps } from '../SearchAscend';
import LookAheadField from '../../../../../components/LookAheadField/LookAheadField';
import API from '../../../../../utils/api';
import useLocale from '../../../../../hooks/useLocale';

import { stylesSearchAscend } from '../SearchAscend';

export interface IAscendClaimValues {
  ascendCreditorName?: string;
  ascendAccountNumber?: string;
  ascendMergeCreditorEmail?: boolean;
}

const COLS_IN_GRID = 24;
const COLS_IN_ROW = 3;

const newClaimAscendFields = ['ascendCreditorName', 'ascendAccountNumber', 'ascendMergeCreditorEmail'];

// The SearchAscend component will inject these props
const NewClaimFields = ({
  form,
  variableFieldsFromParent,
  setVariableFieldsListInParent,
  updateAscendSearchForm,
  isMatched,
}: IAscendVariableFieldsBlockProps): JSX.Element => {
  const { t } = useLocale();

  const [claimFields, setClaimFields] = useState<IAscendClaimValues | undefined>(undefined);

  useEffect(() => {
    if (setVariableFieldsListInParent) {
      setVariableFieldsListInParent(newClaimAscendFields);
    }
  }, [setVariableFieldsListInParent]);

  useEffect(() => {
    if (claimFields && updateAscendSearchForm) {
      updateAscendSearchForm((prev) => ({
        ...prev,
        ...claimFields,
      }));
    }
  }, [updateAscendSearchForm, claimFields]);

  const creditorNameDisplayValue = useMemo(() => {
    if (form?.getFieldValue('ascendCreditorName')) return form?.getFieldValue('ascendCreditorName');
    if (variableFieldsFromParent?.ascendCreditorName) return variableFieldsFromParent?.ascendCreditorName;
    if (claimFields?.ascendCreditorName) return claimFields.ascendCreditorName;
    return undefined;
  }, [form, variableFieldsFromParent?.ascendCreditorName, claimFields?.ascendCreditorName]);

  const updateCreditorName = useCallback((value: string | undefined) => {
    setClaimFields((prev) => ({
      ...prev,
      ascendCreditorName: value,
    }));
  }, []);

  return (
    <>
      {!isMatched && <div className={stylesSearchAscend.number_of_fields_message}>{t.ASCEND_AT_LEAST_ONE_FIELD}</div>}
      <div className={stylesSearchAscend.dynamic_fields_container}>
        <Col span={COLS_IN_GRID / COLS_IN_ROW}>
          <FormInput
            label={t.ASCEND_CREDITOR_NAME}
            key={`ascendCreditorName-matched-${isMatched}`}
            name="ascendCreditorName"
          >
            <LookAheadField
              displayValue={creditorNameDisplayValue}
              apiSearch={(searchText) => {
                return API.listCreditors(searchText, 25);
              }}
              apiSetOptionsData={(response, setOptionsData) => {
                // console.log('creditor response:', response);
                setOptionsData(
                  response.map((creditor: any, index: number) => {
                    const safeName = creditor.displayName.replaceAll(' ', '_');
                    return {
                      key: `option_${index}_${safeName}`,
                      value: creditor.displayName,
                      label: creditor.displayName,
                    };
                  })
                );
              }}
              parentUpdater={updateCreditorName}
              onSearch={(searchText) => {
                if (searchText.trim().length === 0) {
                  updateCreditorName(undefined);
                }
              }}
            />
          </FormInput>
        </Col>
        <Col span={COLS_IN_GRID / COLS_IN_ROW}>
          <FormInput
            label={t.ASCEND_ACCOUNT_NUMBER}
            key={`ascendAccountNumber-matched-${isMatched}`}
            name="ascendAccountNumber"
            onChange={(e: any) => {
              setClaimFields((prev) => ({
                ...prev,
                ascendAccountNumber: e.target.value,
              }));
            }}
          />
        </Col>
        <Col span={COLS_IN_GRID / COLS_IN_ROW}>
          {isMatched &&
            (() => {
              if (claimFields?.ascendMergeCreditorEmail == null) {
                setClaimFields((prev) => ({
                  ...prev,
                  ascendMergeCreditorEmail: false,
                }));
              }
              return (
                <FormInput type="checkbox" valuePropName="checked" name="ascendMergeCreditorEmail">
                  <Checkbox
                    style={{ paddingTop: '30px' }}
                    defaultChecked={false}
                    onChange={(e: any) => {
                      setClaimFields((prev) => ({
                        ...prev,
                        ascendMergeCreditorEmail: e.target.checked,
                      }));
                    }}
                  >
                    {t.ASCEND_MERGE_CREDITOR_EMAIL}
                  </Checkbox>
                </FormInput>
              );
            })()}
        </Col>
      </div>
    </>
  );
};

export default NewClaimFields;
