import { useCallback, useEffect, useState } from 'react';
import { Col, Modal, RadioChangeEvent, Row } from 'antd';
import ButtonContainer from '../../../../components/ButtonContainer/ButtonContainer';
import AppForm from '../../../../components/Forms/AppForm/AppForm';
import FormInput from '../../../../components/Forms/FormInput/FormInput';
import Button from '../../../../components/Button/Button';
import useModal from '../../../../hooks/useModal';
import Label from '../../../../components/Forms/Label/Label';
import { ISelectOption } from '../../../../components/Forms/FormInput/SelectInput';
import ErrorsContainer, { IErrorsMsgAndType } from '../../../../components/ErrorsContainer/ErrorsContainer';
import API from '../../../../utils/api';
import { argumentifyAgentListSearchCriteria, argumentifyTeamsSearchCriteria } from '../utils';
import useErrorHandling from '../../../../hooks/useErrorHandling';
import useLocale from '../../../../hooks/useLocale';

import styles from './AlertModal.module.scss';

type TAlertTarget = 'users' | 'team';

interface AlertModalProps {
  onCancel?: () => void;
  onSave?: (formValues: any) => void;
  setResponseDataForSave?: (formValues: any) => unknown;
}

const AlertModal = ({ onCancel, onSave, setResponseDataForSave }: AlertModalProps) => {
  const { closeModal } = useModal();
  const { t } = useLocale();

  const [alertForm] = AppForm.AntD.useForm();

  const { processResponseForErrors } = useErrorHandling();

  const [allUsers, setAllUsers] = useState<ISelectOption[] | undefined>();
  const [allTeams, setAllTeams] = useState<ISelectOption[] | undefined>();
  const [alertTarget, setAlertTarget] = useState<TAlertTarget | undefined>();
  const [searchValue, setSearchValue] = useState<string | undefined>();
  const [targetOptions, setTargetOptions] = useState<ISelectOption[] | undefined>();
  const [isLoading, setIsLoading] = useState(false);

  const [errors, setErrors] = useState<IErrorsMsgAndType[] | undefined>();

  const setUsersAsOptions = useCallback(
    (passedSearchValue: string | undefined) => {
      setIsLoading(true);
      API.searchAgent(
        ...argumentifyAgentListSearchCriteria({ isActive: true, maxResultCount: 500, fullName: passedSearchValue })
      )
        .then((response) => {
          const responseErrors = processResponseForErrors(response);
          setErrors(responseErrors.messages);
          if (responseErrors.hasErrors) return;
          const usersList: ISelectOption[] | undefined = response.items?.map((agent) => {
            return {
              label: agent.fullName as string,
              value: agent.id as string,
            };
          });
          setAllUsers(usersList);
          setTargetOptions(usersList);
        })
        .catch((error) => {
          const requestErrors = processResponseForErrors(error);
          setErrors(requestErrors.messages);
        })
        .finally(() => setIsLoading(false));
    },
    [alertForm, allUsers, processResponseForErrors]
  );

  const setTeamsAsOptions = useCallback(
    (passedSearchValue: string | undefined) => {
      setIsLoading(true);
      API.getList3(...argumentifyTeamsSearchCriteria({ maxResultCount: 500, name: passedSearchValue }))
        .then((response) => {
          const responseErrors = processResponseForErrors(response);
          setErrors(responseErrors.messages);
          if (responseErrors.hasErrors) return;
          const teamsList: ISelectOption[] | undefined = response.items?.map((team) => {
            return {
              label: team.name as string,
              value: team.id as string,
            };
          });
          setAllTeams(teamsList);
          setTargetOptions(teamsList);
        })
        .finally(() => setIsLoading(false));
    },
    [alertForm, allTeams, processResponseForErrors]
  );

  const isFormValid = useCallback(async () => {
    let requestIsValid = true;
    try {
      await alertForm.validateFields();
    } catch (error) {
      requestIsValid = false;
    }
    return requestIsValid;
  }, [alertForm]);

  useEffect(() => {
    setAlertTarget('users');
    alertForm.setFieldsValue({ alertTarget: 'users' });

    setUsersAsOptions(undefined);
  }, []);

  useEffect(() => {
    if (!alertTarget) return;

    if (alertTarget === 'users') setUsersAsOptions(undefined);
    if (alertTarget === 'team') setTeamsAsOptions(undefined);

    alertForm.setFieldsValue({ sendAlert: undefined });
  }, [alertTarget]);

  useEffect(() => {
    if (alertTarget === 'users') setUsersAsOptions(searchValue);
    if (alertTarget === 'team') setTeamsAsOptions(searchValue);
  }, [searchValue]);

  return (
    <Modal
      destroyOnClose
      onCancel={() => {
        if (onCancel) onCancel();
        closeModal();
      }}
      centered
      visible
      title={t.SEND_ALERT}
      width={600}
      footer={[
        <ButtonContainer
          key="modal-button-container"
          style={{
            paddingTop: '0',
          }}
        >
          <Button
            key="cancel-button"
            kind="cancel"
            onClick={() => {
              if (onCancel) onCancel();
              closeModal();
            }}
          >
            {t.CANCEL}
          </Button>
          <Button
            key="save-button"
            onClick={async () => {
              const isRequestValid = await isFormValid();
              if (!isRequestValid) {
                setErrors([{ message: t.ALERT_MODAL_MISSING_VALUE_MESSAGE }]);
                return;
              }
              if (onSave && setResponseDataForSave) {
                onSave(setResponseDataForSave(alertForm.getFieldsValue()));
              } else if (onSave) {
                onSave(alertForm.getFieldsValue());
              }
              closeModal();
            }}
          >
            {t.SAVE}
          </Button>
        </ButtonContainer>,
      ]}
    >
      <div className={styles.AlertModal}>
        <AppForm form={alertForm}>
          {errors && <ErrorsContainer errors={errors} noTitle />}
          <Row gutter={20}>
            <Col span={24}>
              <FormInput
                type="select"
                selectMode="multiple"
                placeholder={isLoading ? t.LOADING : t.PLEASE_SELECT}
                loading={isLoading}
                noSelectOption
                name="sendAlert"
                onSearch={(value: any) => {
                  setSearchValue(value);
                }}
                required
                label={
                  <Label
                    value={t.SEND_ALERT}
                    required
                    extraContent={
                      <FormInput
                        type="radio"
                        name="alertTarget"
                        radioGroupName="targetOptions"
                        disabled={isLoading}
                        radioOptions={[
                          { label: t.USERS, value: 'users' },
                          { label: t.TEAM, value: 'team' },
                        ]}
                        inputClassName={styles.radio_group_in_label}
                        onChange={({ target: { value } }: RadioChangeEvent) => {
                          alertForm.setFieldsValue({ sendAlert: undefined });
                          setTargetOptions(undefined);
                          setAlertTarget(value);
                        }}
                      />
                    }
                  />
                }
                optionsList={targetOptions}
                filterOption={(inputValue: any, option: any) => true}
                autoClearSearchValue={true}
              />
            </Col>
          </Row>
          <Row gutter={20}>
            <Col span={24}>
              <FormInput type="textarea" name="notes" required label={t.NOTES} height={300} />
            </Col>
          </Row>
        </AppForm>
      </div>
    </Modal>
  );
};

export default AlertModal;
