import { Col, Row } from 'antd';

interface IProp {
  label?: string;
  required?: boolean;
  extraComponent?: JSX.Element | string;
}

const LabelWithExtra = ({ label, required, extraComponent }: IProp) => {
  return (
    <Row align="middle" justify="space-between" className="DebtorProfileContent__form-item--label">
      <Col className={required ? 'DebtorProfileContent__form-item--label-required' : ''}>{label}</Col>
      <Col>{extraComponent}</Col>
    </Row>
  );
};

export default LabelWithExtra;
