import React, { ReactNode, useMemo, useCallback } from 'react';
import Breadcrumb, { IBreadcrumb } from '../../../../components/Breadcrumb/Breadcrumb';
import BackToOverviewButton from '../../../../components/Button/AppButtons/BackToOverview/BackToOverviewButton';
import ErrorsContainer, { IErrorsMsgAndType } from '../../../../components/ErrorsContainer/ErrorsContainer';
// import ButtonContainerHandler from '../../../../components/ButtonContainer/ButtonContainerHandler';
import SimplifiedActionsToolbar from '../../../../components/SimplifiedActionsToolbar/SimplifiedActionsToolbar';
import useLocale from '../../../../hooks/useLocale';

import styles from './NewAndViewHeader.module.scss';

type NewViewSubtitles =
  | 'new-claim'
  | 'view-claim'
  | 'view-secured-claim'
  | 'view-unsecured-claim'
  | 'new-income-and-expense'
  | 'view-income-and-expense'
  | 'new-tax-return'
  | 'view-tax-return';

interface INewAndViewHeaderProps {
  fileId?: string;
  claimFileId?: string;
  breadcrumbData: IBreadcrumb[];
  subtitle?: NewViewSubtitles | 'Loading...' | null;
  status?: string; //'active' | 'inactive' | 'complete' | 'duplicate';
  errors?: IErrorsMsgAndType[];
  className?: string;
  style?: React.CSSProperties;
  buttons?: ReactNode | ReactNode[];
}

const NewAndViewHeader = ({
  fileId,
  claimFileId,
  breadcrumbData,
  subtitle,
  status,
  errors,
  className,
  style,
  buttons,
}: INewAndViewHeaderProps): JSX.Element => {
  const { t } = useLocale();

  const currentSubtitle = useMemo(() => {
    if (subtitle != null) {
      if (subtitle === 'Loading...') return subtitle;

      const subtitleToString = {
        'new-claim': t.NEWVIEW_SUBTITLE_NEW_CLAIM,
        'view-claim': t.NEWVIEW_SUBTITLE_VIEW_CLAIM,
        'view-secured-claim': t.NEWVIEW_SUBTITLE_VIEW_SECURED_CLAIM,
        'view-unsecured-claim': t.NEWVIEW_SUBTITLE_VIEW_UNSECURED_CLAIM,
        'new-income-and-expense': t.NEWVIEW_SUBTITLE_NEW_INCOME_AND_EXPENSE,
        'view-income-and-expense': t.NEWVIEW_SUBTITLE_VIEW_INCOME_AND_EXPENSE,
        'new-tax-return': t.NEWVIEW_SUBTITLE_NEW_TAX_RETURN,
        'view-tax-return': t.NEWVIEW_SUBTITLE_VIEW_TAX_RETURN,
      };

      return subtitleToString[subtitle];
    }
    return null;
  }, [
    subtitle,
    t.NEWVIEW_SUBTITLE_NEW_CLAIM,
    t.NEWVIEW_SUBTITLE_VIEW_CLAIM,
    t.NEWVIEW_SUBTITLE_VIEW_SECURED_CLAIM,
    t.NEWVIEW_SUBTITLE_VIEW_UNSECURED_CLAIM,
    t.NEWVIEW_SUBTITLE_NEW_INCOME_AND_EXPENSE,
    t.NEWVIEW_SUBTITLE_VIEW_INCOME_AND_EXPENSE,
    t.NEWVIEW_SUBTITLE_NEW_TAX_RETURN,
    t.NEWVIEW_SUBTITLE_VIEW_TAX_RETURN,
  ]);

  // const renderButtons = useCallback(() => {
  //   if (buttons) {
  //     return <ButtonContainerHandler buttons={buttons} inline />;
  //   }
  // }, [
  //   buttons,
  // ]);

  return (
    <div className={`${styles.NewAndViewHeader} ${className ? className : ''}`} style={style}>
      <div className={styles.breadcrumb_and_overview_button}>
        <Breadcrumb data={breadcrumbData} />
        {fileId && <BackToOverviewButton fileId={fileId} />}
      </div>
      {errors && <ErrorsContainer noTitle errors={errors} />}
      <div className={styles.subtitle_and_buttons}>
        <div className={styles.subtitle_and_toolbar}>
          <h2 className={styles.subtitle}>
            {currentSubtitle} {status && <span className={styles.status}>({status})</span>}
          </h2>
          {fileId && (
            <div className={styles.toolbar}>
              <SimplifiedActionsToolbar fileId={fileId as string} claimFileId={claimFileId} />
            </div>
          )}
        </div>
        {/* <div>{renderButtons()}</div> */}
        <div>{buttons && buttons}</div>
      </div>
    </div>
  );
};

export default NewAndViewHeader;
