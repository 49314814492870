import { useCallback, useState } from 'react';
import { Modal, Form, Row, Col, Input, Spin } from 'antd';
import { useQuery } from 'react-query';

import Button from '../../../../../../components/Button/Button';
import SelectLookupDto from '../../../../../../components/SelectLookupDto/SelectLookupDto';

import useLocale from '../../../../../../hooks/useLocale';
import { FILE_COURT_AGENTS_QUERY, USE_QUERY_OPTIONS } from '../../../../../../constants/reactQuery';
import { ClientService } from '../../../../../../shared/api/ClientService';
import API from '../../../../../../utils/api';

import './RequestCourtAppearanceModal.scss';
import genericMessage from '../../../../../../utils/genericMessage';

interface IProps {
  dischargeId: string;
  applicationFileId: string;
  onOk?: () => void;
  onCancel?: () => void;
}

function RequestCourtAppearanceModal({ dischargeId, applicationFileId, onOk, onCancel }: IProps): JSX.Element {
  const { t } = useLocale();
  const { TextArea } = Input;

  const [form] = Form.useForm<ClientService.DischargeCourtApperanceRequestCreateDto>();
  const [loading, setLoading] = useState<boolean>(false);

  const { data: agents } = useQuery(
    [FILE_COURT_AGENTS_QUERY, applicationFileId],

    () => {
      if (applicationFileId) return API.listCourtAgentsForFile(applicationFileId);
    },
    USE_QUERY_OPTIONS
  );

  const handleFinish = useCallback(
    async (values) => {
      setLoading(true);
      const response = await API.courtApperanceRequest(applicationFileId as string, {
        ...values,
        id: dischargeId,
      }).catch((error) => {
        setLoading(false);
      });

      setLoading(false);

      if (response?.result === ClientService.Result.Successful) {
        if (onOk) {
          onOk();
        }
      } else {
        //
        // Any server side error, display error messages.
        //
        if (response?.messages) {
          genericMessage.error({}, response?.messages?.[0]?.body);
        } else {
          genericMessage.error({}, t.SAVE_COURT_APPEARANCE_REQUEST_FAILED);
        }
      }
    },
    [onOk, dischargeId, applicationFileId]
  );

  const handleCancel = useCallback(() => {
    if (onCancel) onCancel();
  }, [onCancel]);

  return (
    <Modal
      title={t.DISCHARGE_COURT_APPEARANCE_REQUEST}
      destroyOnClose
      centered
      visible
      footer={null}
      closable
      onCancel={handleCancel}
      width={700}
      className="RequestCourtAppearanceModal"
    >
      <Spin spinning={loading}>
        <Form form={form} layout="vertical" name="RequestCourtAppearanceModal" onFinish={handleFinish}>
          <Row>
            <Col span={24}>
              <Form.Item
                name="agentId"
                label={t.DISCHARGE_SELECT_TRUSTEE}
                required
                rules={[{ required: true, message: t.REQUIRED_FIELD }]}
              >
                <SelectLookupDto data={agents} showSearch />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                name="comments"
                label={t.DISCHARGE_COMMENTS}
                required
                rules={[{ required: true, message: t.REQUIRED_FIELD }]}
              >
                <TextArea rows={6} maxLength={1000} />
              </Form.Item>
            </Col>
          </Row>

          <Row align="middle" justify="end" className="RequestCourtAppearanceModal__footer" wrap={false} gutter={10}>
            <Col>
              <Form.Item noStyle>
                <Button kind="cancel" onClick={onCancel}>
                  {t.CANCEL}
                </Button>
              </Form.Item>
            </Col>
            <Col>
              <Form.Item noStyle>
                <Button htmlType="submit">{t.SAVE}</Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Spin>
    </Modal>
  );
}

export default RequestCourtAppearanceModal;
