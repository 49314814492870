import { ReactNode } from 'react';
import Label, { LabelProps } from './Label';
import CurrencyDisplay from '../../CurrencyDisplay/CurrencyDisplay';

import styles from './LabelWithExtraValue.module.scss';

interface LabelWithExtraValueProps extends LabelProps {
  extraValue?: ReactNode;
  extraDollarValue?: never;
  extraDollarDecimalDigits?: never;
  extraContent?: never;
}

interface LabelWithExtraDollarValueProps extends LabelProps {
  extraDollarValue?: string | number;
  extraDollarDecimalDigits?: number;
  extraValue?: never;
  extraContent?: never;
}

type LabelWithCombinedExtraValues = LabelWithExtraValueProps | LabelWithExtraDollarValueProps;

function LabelWithExtraValue({
  value,
  extraValue,
  extraDollarValue,
  extraDollarDecimalDigits,
  icon,
  required,
  className,
}: // ...props
LabelWithCombinedExtraValues): JSX.Element {
  return (
    <Label
      value={value}
      icon={icon}
      required={required}
      extraContent={
        <div className={styles.label_extra_value}>
          {extraValue && extraValue}
          {extraDollarValue && <CurrencyDisplay amount={extraDollarValue} decimalDigits={extraDollarDecimalDigits} />}
        </div>
      }
      className={className}
    />
  );
}

export default LabelWithExtraValue;
