import { ClientService } from '../../shared/api/ClientService';
import {
  IDocumentSearchCriteria,
  ISupportingDocumentSearchCriteria,
  CreateDocument,
  CreateSupportingDocument,
  UpdateDocument,
  FilesColumnsProps,
} from './types';
import { FILE_SIZE_COLUMN_WIDTH, DELETE_COLUMN_WIDTH, FOR_SPOUSE_COLUMN_WIDTH } from './constants';
import type { RcFile } from 'antd/es/upload/interface';

export const argumentifyDocumentsSearchCriteria = ({
  filterText,
  filterTextlang,
  fileId,
  documentId,
  documentIds,
  documentFolderId,
  documentVerificationStatusId,
  documentVerificationStatuses,
  documentTypeId,
  documentTypes,
  documentPrefixes,
  documentPrefixCodes,
  documentFolders,
  isVisible,
  isDeleted,
  modifiedDateMin,
  modifiedDateMax,
  sorting,
  skipCount,
  maxResultCount,
}: IDocumentSearchCriteria) =>
  [
    filterText,
    filterTextlang,
    fileId,
    documentId,
    documentIds,
    documentFolderId,
    documentVerificationStatusId,
    documentVerificationStatuses,
    documentTypeId,
    documentTypes,
    documentPrefixes,
    documentPrefixCodes,
    documentFolders,
    isVisible,
    isDeleted,
    modifiedDateMin,
    modifiedDateMax,
    sorting,
    skipCount,
    maxResultCount,
  ] as const;

//argumentify supporting documents search
export const argumentifySupportingDocumentsSearchCriteria = ({
  appFormAssetsDebtsAssetId,
  isDeleted,
  sorting,
  skipCount,
  maxResultCount,
}: ISupportingDocumentSearchCriteria) =>
  [appFormAssetsDebtsAssetId, isDeleted, sorting, skipCount, maxResultCount] as const;

export const argumentifyCreateDocument = ({
  fileId,
  documentFolderId,
  documentPrefixId,
  additionalInformation,
  isVisible,
  fileModifiedDate,
  content,
  isForSpouse,
  businessName,
}: CreateDocument) =>
  [
    fileId,
    documentFolderId,
    documentPrefixId,
    additionalInformation,
    isVisible,
    fileModifiedDate,
    content,
    isForSpouse,
    businessName,
  ] as const;

export const argumentifyCreateSupportingDocument = ({
  supportingDocumentId,
  documentFolderId,
  documentPrefixId,
  additionalInformation,
  isVisible,
  fileModifiedDate,
  content,
  isForSpouse,
  businessName,
}: CreateSupportingDocument) =>
  [
    supportingDocumentId,
    documentFolderId,
    documentPrefixId,
    additionalInformation,
    isVisible,
    fileModifiedDate,
    content,
    isForSpouse,
    businessName,
  ] as const;

export const argumentifyUpdateDocument = ({
  id,
  documentFolderId,
  documentPrefixId,
  additionalInformation,
  fileModifiedDate,
  content,
  isVisible,
}: UpdateDocument) =>
  [id, documentFolderId, documentPrefixId, additionalInformation, fileModifiedDate, content, isVisible] as const;

export const readableBytes = (bytes?: number) => {
  if (!bytes) return '0 KB';
  const i = Math.floor(Math.log(bytes) / Math.log(1024));
  const sizes = ['B', 'KB', 'MB', 'GB'];

  return (bytes / Math.pow(1024, i)).toFixed(2) + ' ' + sizes[i];
};

export const convertIdToLookupCodeName = (
  itemId: string,
  lookupArray:
    | ClientService.LookupDto[]
    | ClientService.LookupDto_1OfOfExpenseDescriptionEnumAndCoreAnd_0AndCulture_neutralAndPublicKeyToken_null[],
  lookupEnum: any
) => {
  for (const enumMember in lookupEnum) {
    const value = Number(lookupEnum[enumMember]);
    const isValueProperty = value >= 0;
    if (isValueProperty && value === lookupArray?.find((item) => item.id === itemId)?.enumValue) {
      return enumMember;
    }
  }
};

export const convertLookupCodeName = (str: string) => {
  switch (str) {
    case 'CanadaChildBenefit':
      return 'ChildBenefit';
    case 'ChildTaxBenefit':
      return 'ChildBenefit';
    case 'EmploymentInsuranceBenefits':
      return 'EmploymentInsurance';
    default:
      return str;
  }
};

export const convertUndefinedToTrue = (value?: boolean) => (value === undefined ? true : value);

export const getNumberOfColumns = (columnsProps?: FilesColumnsProps) => {
  const hasFolderColumn = convertUndefinedToTrue(columnsProps?.hasFolderColumn);
  const hasPrefixColumn = convertUndefinedToTrue(columnsProps?.hasPrefixColumn);
  const hasAdditionalInfoColumn = convertUndefinedToTrue(columnsProps?.hasAdditionalInfoColumn);
  const hasBusinessNameColumn = Boolean(columnsProps?.hasBusinessNameColumn);

  return +hasFolderColumn + +hasPrefixColumn + +hasAdditionalInfoColumn + +hasBusinessNameColumn + 1;
};

export const getAvailableCols = (columnsProps?: FilesColumnsProps) => {
  const hasSizeColumn = columnsProps?.hasSizeColumn;
  const hasForSpouseColumn = columnsProps?.hasForSpouseColumn;

  let cols = 24 - DELETE_COLUMN_WIDTH;
  cols -= hasSizeColumn ? FILE_SIZE_COLUMN_WIDTH : 0;
  cols -= hasForSpouseColumn ? FOR_SPOUSE_COLUMN_WIDTH : 0;
  return cols;
};

export const getColumnWidth = (columnsProps?: FilesColumnsProps) =>
  Math.floor(getAvailableCols(columnsProps) / getNumberOfColumns(columnsProps));

export const getNameColumnWidth = (columnsProps?: FilesColumnsProps) =>
  getAvailableCols(columnsProps) - getColumnWidth(columnsProps) * (getNumberOfColumns(columnsProps) - 1);

export const verifyUploadedFileType = (file?: RcFile, types?: ClientService.LookupDto[]) =>
  types?.find(
    (item) => item?.code === file?.type || file?.name?.split('.')?.pop()?.toLowerCase() === item?.name?.toLowerCase()
  );

export const getEmptyGuid = () => '00000000-0000-0000-0000-000000000000';
