import { IOfficeLocationsSearchCriteria } from './types';
import { LocationDto } from '../../modals/GoogleMaps/types';

export const argumentifyOfficeLocationsSearchCriteria = ({
  filterText,
  nearbyLatitude,
  nearbyLongitude,
  sorting,
  skipCount,
  maxResultCount,
}: IOfficeLocationsSearchCriteria) =>
  [filterText, nearbyLatitude, nearbyLongitude, sorting, skipCount, maxResultCount] as const;

export const formatAddressLine = (autofilledAddress: LocationDto) => {
  if (!autofilledAddress?.streetNumber && !autofilledAddress?.streetName) return;
  if (autofilledAddress?.streetNumber && !autofilledAddress?.streetName) return autofilledAddress?.streetNumber;
  if (!autofilledAddress?.streetNumber && autofilledAddress?.streetName) return autofilledAddress?.streetName;
  return `${autofilledAddress?.streetNumber} ${autofilledAddress?.streetName}`;
};

export const validatePhoneNumberComplete = (value: string) => /^\(\d{3}\) \d{3}-\d{4}$/.test(value);

export const prevalidatePhoneNumber = (value?: string) => {
  return value?.replace(/\D+/g, '').replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3');
};
