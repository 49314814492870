import React, { useCallback, useEffect, useState, useRef, useMemo } from 'react';
import InputMask from 'react-input-mask';
import {
  Checkbox,
  Collapse,
  DatePicker,
  Form,
  Input,
  InputNumber,
  Radio,
  RadioChangeEvent,
  Select,
  Row,
  Col,
  Divider,
  Spin,
} from 'antd';
import { InternalNamePath, ValidateErrorEntity } from 'rc-field-form/es/interface';

import update from 'immutability-helper';
import { DeleteOutlined } from '@ant-design/icons';
import useLocale from '../../../../hooks/useLocale';
import { Label } from '@fluentui/react';
import TextArea from 'antd/lib/input/TextArea';
import { ClientService } from '../../../../shared/api/ClientService';
import API from '../../../../utils/api';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import PersonalInformationPreviousInsolvency from './PersonalInformationPreviousInsolvency';
import PersonalInformationEmploymentDetail from './PersonalInformationEmploymentDetail';
import CheckboxButtonStyle from '../../../../components/CheckboxButtonStyle/CheckboxButtonStyle';
import Button from '../../../../components/Button/Button';
import PersonalInformationEIPeriod from './PersonalInformationEIPeriod';
import SearchLocationInput from '../../../../components/SearchLocationInput/SearchLocationInput';
import { LocationDto } from '../../../../modals/GoogleMaps/types';
import ErrorComponent, { IError } from './ErrorComponent';
import AttachmentsTab, {
  AttachmentsFormProps,
  argumentifyDocumentsSearchCriteria,
} from '../../../../components/AttachmentsTab';

import './ApplicationFormTab.scss';
import { antdUtil } from '../../../../utils/antdUtil';
import { DATE_FORMAT2, DATE_FORMAT_PLACEHOLDER, MAX_PAGE_SIZE } from '../../../../constants/common';
import moment from 'moment';
import genericMessage from '../../../../utils/genericMessage';
import { Icon } from '@fluentui/react/lib/Icon';
import useUnsaved from '../../../../hooks/useUnsaved';
import eventBus from '../../../../utils/eventBus';
import { APPFORM_SAVE_AND_LEAVE_EVENT, UPLOAD_FILES_EVENT } from '../../../../constants/eventBus';
import {
  convertInputDateToServerDateOnly,
  convertServerDateOnlyToInputDate,
  debounceWithTimeOut,
} from '../../../../utils/helpers';
import SelectSuffixIcon from '../../../../components/SelectSuffixIcon/SelectSuffixIcon';
import { AttachmentTypeEnum } from '../../../../components/AttachmentsTab/types';
import SelectLookupDto from '../../../../components/SelectLookupDto/SelectLookupDto';
import ActionConfirmationModal from '../../../../modals/ActionConfirmationModal/ActionConfirmationModal';
import useModal from '../../../../hooks/useModal';
import ChangeTrackerFormItem from '../../../../components/ChangeTrackerFormItem';

const { Panel } = Collapse;

interface IProps {
  data: ClientService.IApplicationFileDto | null;
  updateStatus: () => void;
  onContinue: () => void;
}

interface IForm extends ClientService.AppFormPersonalInfoDto, AttachmentsFormProps {
  isFinancialDifficultyReason?: boolean;
  isSpouseFinancialDifficultyReason?: boolean;
}

const personalDetailsFields = ['birthDate', 'sin', 'maritalStatusId'];

const spousePersonalDetailsFields = ['spouseFirstName', 'spouseLastName', 'spouseSIN'];

const supportingDocumentsFields = ['existingFiles'];

const contactInformationFields = [
  'address',
  'city',
  'provinceId',
  'postalCode',
  'countryId',
  'residenceDate',
  'mainPhoneTypeId',
  'mainPhoneNumber',
  'emailAddress',
  'spouseEmailAddress',
];

const employmentFields = [
  'isEmployedPastTwoYears',
  'employmentDetails',
  'isReceivedEmploymentInsurancePastTwoYears',
  'employmentInsurancePeriods',
  'isSpouseEmployedPastTwoYears',
  'spouseEmploymentDetails',
  'isSpouseReceivedEmploymentInsurancePastTwoYears',
  'spouseEmploymentInsurancePeriods',
];

const financialDifficultyFields = ['specifiedFDReason', 'specifyFDReasonLegalMatters', 'isFinancialDifficultyReason'];
const spouseFinancialDifficultyFields = [
  'spouseSpecifiedFDReason',
  'spouseSpecifyFDReasonLegalMatters',
  'isSpouseFinancialDifficultyReason',
];

const financialDifficultyReasonsGroup = [
  'hasFDReasonMaritalSeparationOrRelationshipBreakdown',
  'hasFDReasonGambling',
  'hasFDReasonBusinessFailure',
  'hasFDReasonLitigationIssues',
  'hasFDReasonDrugAlcoholAddicition',
  'hasFDReasonOther',
  'hasFDReasonLossOfIncome',
  'hasFDReasonMedicalReasons',
  'hasFDReasonTaxLiabilities',
  'hasFDReasonFinancialSupportOfOthers',
  'hasFDReasonStudentDebt',
  'hasFDReasonMovingOrRelocationExpenses',
  'hasFDReasonFailedProposal',
  'hasFDReasonFinancialMismanagement',
];

const spouseFinancialDifficultyReasonsGroup = [
  'spouseHasFDReasonMaritalSeparationOrRelationshipBreakdown',
  'spouseHasFDReasonGambling',
  'spouseHasFDReasonBusinessFailure',
  'spouseHasFDReasonLitigationIssues',
  'spouseHasFDReasonDrugAlcoholAddicition',
  'spouseHasFDReasonOther',
  'spouseHasFDReasonLossOfIncome',
  'spouseHasFDReasonMedicalReasons',
  'spouseHasFDReasonTaxLiabilities',
  'spouseHasFDReasonFinancialSupportOfOthers',
  'spouseHasFDReasonStudentDebt',
  'spouseHasFDReasonMovingOrRelocationExpenses',
  'spouseHasFDReasonFailedProposal',
  'spouseHasFDReasonFinancialMismanagement',
];

const dependantsFields = ['numberOfPersonsInHousehold', 'dependants'];

const previousInsolvenciesFields = [
  'hasPreviousInsolvency',
  'previousInsolvencies',
  'hasSpousePreviousInsolvency',
  'spousePreviousInsolvencies',
];

const personalInfoPrefixCodes = [
  'Personal.Drivers-License',
  'Personal.Health-Card',
  'Personal.Other-Id',
  'Personal.Passport',
];

type IClientUpdatedPanelsType = Pick<
  ClientService.AppFormPersonalInfoDto,
  | 'isPersoanlDetailUpdatedByClient'
  | 'isContactInformationUpdatedByClient'
  | 'isEmploymentUpdatedByClient'
  | 'isPreviousInsolvencyUpdatedByClient'
  | 'isDependantsUpdatedByClient'
  | 'isFinancialDifficultyByClient'
  | 'isFinancialDifficultyByClientSpouse'
>;

const PERSONAL_DETAILS_TAB = 'personalDetails';
const CONTACT_INFORMATION_TAB = 'contactInformation';
const EMPLOYMENT_TAB = 'employment';
const FINANCIAL_DIFFICULTY_TAB = 'financialDifficulty';
const SPOUSE_FINANCIAL_DIFFICULTY_TAB = 'spouseFinancialDifficulty';
const DEPENDANTS_TAB = 'dependants';
const PREVIOUS_INSOLVENCIES_TAB = 'previousInsolvencies';

const PersonalInformation = ({ data, updateStatus, onContinue }: IProps): JSX.Element => {
  const { t } = useLocale();
  const { Option } = Select;
  const { setIsUnsavedForm } = useUnsaved();
  const { showModal, closeModal } = useModal();

  const isAppFormLocked = useMemo(
    () => Boolean(data?.isAppFormLocked) || Boolean(data?.isLocked),
    [data?.isAppFormLocked, data?.isLocked]
  );

  const refPersonalDetails = useRef<HTMLDivElement>(null);
  const refSpousePersonalDetails = useRef<HTMLDivElement>(null);
  const refSupportingDocuments = useRef<HTMLDivElement>(null);
  const refContactInformation = useRef<HTMLDivElement>(null);
  const refEmployment = useRef<HTMLDivElement>(null);
  const tabRefFinancialDifficulty = useRef<HTMLDivElement>(null);
  const tabRefSpouseFinancialDifficulty = useRef<HTMLDivElement>(null);
  const refDependants = useRef<HTMLDivElement>(null);
  const refPreviousInsolvency = useRef<HTMLDivElement>(null);

  const [form] = Form.useForm<IForm>();
  const [formErrors, setFormErrors] = useState<IError[]>();

  const [clientUpdatedPanels, setClientUpdatedPanels] = useState<IClientUpdatedPanelsType>();

  const [isReviewed, setIsReviewed] = useState(false);
  const [needToBeReviewed, setNeedToBeReviewed] = useState(false);
  const [maritalStatuses, setMaritalStatuses] = useState<ClientService.LookupDto[]>();
  const [maritalStatusesLoading, setMaritalStatusesLoading] = useState<boolean>(false);
  const [selectedMaritalStatusId, setSelectedMaritalStatusId] = useState('');

  const [educationLevels, setEducationLevels] = useState<ClientService.LookupDto[]>();
  const [educationLevelsLoading, setEducationLevelsLoading] = useState(false);

  const [countries, setCountries] = useState<ClientService.LookupDto[]>();
  const [countriesLoading, setCountriesLoading] = useState<boolean>(false);

  const [phoneTypes, setPhoneTypes] = useState<ClientService.LookupDto[]>();
  const [phoneTypesLoading, setPhoneTypesLoading] = useState<boolean>(false);

  const [relationshipTypes, setRelationshipTypes] = useState<ClientService.LookupDto[]>();
  const [relationshipTypesLoading, setRelationshipTypesLoading] = useState<boolean>(false);

  const [selectedCountryId, setSelectedCountryId] = useState('');
  const [provinces, setProvinces] = useState<ClientService.LookupDto[]>();
  const [provincesLoading, setProvincesLoading] = useState<boolean>(false);

  const [spouseSelectedCountryId, setSpouseSelectedCountryId] = useState('');
  const [spouseProvinces, setSpouseProvinces] = useState<ClientService.LookupDto[]>();
  const [spouseProvincesLoading, setSpouseProvincesLoading] = useState<boolean>(false);

  const [marriageDateDisabled, setMarriageDateDisabled] = useState<boolean>(true);
  const [isMarried, setIsMarried] = useState<boolean>(false);

  const [isJointFile, setIsJointFile] = useState<boolean>(false);
  const [isLessThan2YearsAtCurrentAddress, setIsLessThan2YearsAtCurrentAddress] = useState<boolean>(false);
  const [isSpouseAddressSame, setIsSpouseAddressSame] = useState<boolean>(false);

  const [isRecentlyEI, setIsRecentlyEI] = useState<boolean>(false);
  const [isSpouseRecentlyEI, setIsSpouseRecentlyEI] = useState<boolean>(false);

  const [hasLegalMattersFDReason, setHasLegalMattersFDReason] = useState<boolean>(false);
  const [hasOtherFDReason, setHasOtherFDReason] = useState<boolean>(false);

  const [spouseHasLegalMattersFDReason, setSpouseHasLegalMattersFDReason] = useState<boolean>(false);
  const [spouseHasOtherFDReason, setSpouseHasOtherFDReason] = useState<boolean>(false);

  const [hasDependants, setHasDependants] = useState<boolean>(false);

  const [hasPreviousInsolvency, setHasPreviousInsolvency] = useState<boolean>(false);
  const [hasSpousePreviousInsolvency, setHasSpousePreviousInsolvency] = useState<boolean>(false);

  const [currentTab, setCurrentTab] = useState<string | undefined>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isPrevalidated, setIsPrevalidated] = useState<boolean>(false);
  const [existingDocumentIds, setExistingDocumentIds] = useState<string[]>([]);

  const [isRegisteredToClientPortal, setIsRegisteredToClientPortal] = useState<boolean>(true);

  const calculateLessThan2YearsResidency = (value: moment.Moment | null | undefined) => {
    if (!value) {
      return false;
    } else {
      return moment().diff(value, 'years') < 2;
    }
  };

  const handleMaritalStatusSelect = (value: string, option: any) => {
    setSelectedMaritalStatusId(value);
  };

  const handleJointFileChecked = (e: CheckboxChangeEvent) => {
    setIsJointFile(e?.target?.checked);
  };

  const handleCountrySelect = (value: any, option: any) => {
    setSelectedCountryId(value);

    form.setFieldsValue({ provinceId: undefined });
  };

  const handleSpouseCountrySelect = (value: any, option: any) => {
    setSpouseSelectedCountryId(value);

    form.setFieldsValue({ spouseProvinceId: undefined });
  };

  const handleResidenceDateChanged = (value: moment.Moment | null | undefined) => {
    let val = calculateLessThan2YearsResidency(value);
    setIsLessThan2YearsAtCurrentAddress(val);
  };

  const handleSpouseSameContactInfoChecked = (e: CheckboxChangeEvent) => {
    setIsSpouseAddressSame(e?.target?.checked);

    if (e?.target?.checked) {
      let countryId = form.getFieldValue('countryId');
      setSpouseSelectedCountryId(countryId);

      form.setFieldsValue({
        spouseAddress: form.getFieldValue('address'),
        spouseUnit: form.getFieldValue('unit'),
        spouseCity: form.getFieldValue('city'),
        spousePostalCode: form.getFieldValue('postalCode'),
        spouseProvinceId: form.getFieldValue('provinceId'),
        spouseCountryId: countryId,
        spouseMainPhoneTypeId: form.getFieldValue('mainPhoneTypeId'),
        spouseMainPhoneNumber: form.getFieldValue('mainPhoneNumber'),
      });
    }
  };

  const handleEIRadioChanged = (e: RadioChangeEvent) => {
    setIsRecentlyEI(e?.target?.value);
  };

  const handleSpouseEIRadioChanged = (e: RadioChangeEvent) => {
    setIsSpouseRecentlyEI(e?.target?.value);
  };

  const handleLegalMattersFDReasonChecked = (e: CheckboxChangeEvent) => {
    setHasLegalMattersFDReason(e?.target?.checked);
    form.setFieldsValue({ specifyFDReasonLegalMatters: undefined });
  };

  const handleOtherFDReasonChecked = (e: CheckboxChangeEvent) => {
    setHasOtherFDReason(e?.target?.checked);
    form.setFieldsValue({ specifiedFDReason: undefined });
  };

  const handleSpouseLegalMattersFDReasonChecked = (e: CheckboxChangeEvent) => {
    setSpouseHasLegalMattersFDReason(e?.target?.checked);
    form.setFieldsValue({ spouseSpecifyFDReasonLegalMatters: undefined });
  };

  const handleSpouseOtherFDReasonChecked = (e: CheckboxChangeEvent) => {
    setSpouseHasOtherFDReason(e?.target?.checked);
    form.setFieldsValue({ spouseSpecifiedFDReason: undefined });
  };

  const handleDependantsRadioChanged = (e: RadioChangeEvent) => {
    setHasDependants(e?.target?.value);
  };

  const handlePreviousInsolvencyRadioChanged = (e: RadioChangeEvent) => {
    setHasPreviousInsolvency(e?.target?.value);
  };

  const handleSpousePreviousInsolvencyRadioChanged = (e: RadioChangeEvent) => {
    setHasSpousePreviousInsolvency(e?.target?.value);
  };

  const formatAddressLine = useCallback((autofilledAddress: LocationDto) => {
    if (!autofilledAddress?.streetNumber && !autofilledAddress?.streetName) return;
    if (autofilledAddress?.streetNumber && !autofilledAddress?.streetName) return autofilledAddress?.streetNumber;
    if (!autofilledAddress?.streetNumber && autofilledAddress?.streetName) return autofilledAddress?.streetName;
    return `${autofilledAddress?.streetNumber} ${autofilledAddress?.streetName}`;
  }, []);

  const handleAddressAutocomplete = useCallback(
    async (autofilledAddress: LocationDto) => {
      const countryId = autofilledAddress?.country?.id;

      form.setFieldsValue({
        ...autofilledAddress,
        address: formatAddressLine(autofilledAddress),
        postalCode: autofilledAddress?.postalCode,
        city: autofilledAddress.city,
        countryId,
      });

      if (countryId && countryId !== selectedCountryId) {
        const provinces = await API.listProvinces(countryId);
        setProvinces(provinces);
        setSelectedCountryId(countryId);
        form.setFieldsValue({
          provinceId: provinces?.find((item) => item.code === autofilledAddress?.province?.code)?.id,
        });
      } else {
        form.setFieldsValue({ provinceId: autofilledAddress?.province?.id });
      }
    },
    [form, formatAddressLine, selectedCountryId]
  );

  const handleSpouseAddressAutocomplete = useCallback(
    async (autofilledAddress) => {
      const spouseCountryId = autofilledAddress?.country?.id;
      form.setFieldsValue({
        spouseAddress: `${autofilledAddress?.streetNumber} ${autofilledAddress?.streetName}`,
        spousePostalCode: autofilledAddress?.postalCode,
        spouseCity: autofilledAddress.city,
        spouseCountryId: autofilledAddress?.country?.id,
      });

      if (spouseCountryId !== spouseSelectedCountryId) {
        const spouseProvinces = await API.listProvinces(spouseCountryId);
        setSpouseProvinces(spouseProvinces);
        setSpouseSelectedCountryId(spouseCountryId);
        form.setFieldsValue({
          spouseProvinceId: spouseProvinces?.find((item) => item.code === autofilledAddress?.province?.code)?.id,
        });
      } else {
        form.setFieldsValue({ spouseProvinceId: autofilledAddress?.province?.id });
      }
    },
    [form, spouseSelectedCountryId]
  );

  const handleAutofilledAddressSplitForPreviousAddress = useCallback(
    async ({ country, address, city, postalCode, streetName, streetNumber, province }: LocationDto) => {
      const countryId = countries?.find((item) => item.code === country?.code)?.id;

      const provinces = await API.listProvinces(countryId);
      const provinceId = provinces.find((item) => item.code === province?.code)?.id;

      return {
        address,
        city,
        postalCode,
        streetName,
        streetNumber,
        countryId,
        provinceId,
      };
    },
    [countries]
  );

  const handlePreviousAddressAutocomplete = useCallback(
    (row: number) => async (autofilledAddress: LocationDto) => {
      const updatedAddress = await handleAutofilledAddressSplitForPreviousAddress(autofilledAddress);
      form.setFieldsValue(
        update(form.getFieldsValue(), {
          previousAddresses: {
            [row]: { $merge: updatedAddress },
          },
        })
      );
    },
    [form, handleAutofilledAddressSplitForPreviousAddress]
  );

  const handleAutofilledAddressSplitForEmployerAddress = useCallback(
    async ({ country, address, city, postalCode, streetName, streetNumber, province }: LocationDto) => {
      const employerCountryId = countries?.find((item) => item.code === country?.code)?.id;

      const provinces = await API.listProvinces(employerCountryId);
      const employerProvinceId = provinces.find((item) => item.code === province?.code)?.id;

      return {
        employerAddress: address,
        employerCity: city,
        employerPostalCode: postalCode,
        employerStreetName: streetName,
        employerStreetNumber: streetNumber,
        employerCountryId,
        employerProvinceId,
      };
    },
    [countries]
  );

  const handleEmployerAddressAutocomplete = useCallback(
    (row: number) => async (autofilledAddress: LocationDto) => {
      const updatedAddress = await handleAutofilledAddressSplitForEmployerAddress(autofilledAddress);
      form.setFieldsValue(
        update(form.getFieldsValue(), {
          employmentDetails: {
            [row]: { $merge: updatedAddress },
          },
        })
      );
    },
    [form, handleAutofilledAddressSplitForEmployerAddress]
  );

  const handleSpouseEmployerAddressAutofill = useCallback(
    (row: number) => async (autofilledAddress: LocationDto) => {
      const updatedAddress = await handleAutofilledAddressSplitForEmployerAddress(autofilledAddress);

      form.setFieldsValue(
        update(form.getFieldsValue(), {
          spouseEmploymentDetails: {
            [row]: { $merge: updatedAddress },
          },
        })
      );
    },
    [form, handleAutofilledAddressSplitForEmployerAddress]
  );

  const validateFinancialDifficultyReasonsGroup = useCallback(async () => {
    if (!financialDifficultyReasonsGroup.filter((item) => form.getFieldValue(item))?.length) {
      return Promise.reject();
    } else {
      return Promise.resolve();
    }
  }, [form]);

  const updateFinancialDifficultyReasonsGroupError = useCallback(
    (values) => {
      if (
        financialDifficultyReasonsGroup.filter((item) => Object.keys(values).indexOf(item))?.length &&
        Object.values(values).filter(Boolean)
      ) {
        form.setFields([
          {
            name: 'isFinancialDifficultyReason',
            errors: [],
          },
        ]);
      }
    },
    [form]
  );

  const validateSpouseFinancialDifficultyReasonsGroup = useCallback(async () => {
    if (!spouseFinancialDifficultyReasonsGroup.filter((item) => form.getFieldValue(item))?.length) {
      return Promise.reject();
    } else {
      return Promise.resolve();
    }
  }, [form]);

  const updateSpouseFinancialDifficultyReasonsGroupError = useCallback(
    (values) => {
      if (
        spouseFinancialDifficultyReasonsGroup.filter((item) => Object.keys(values).indexOf(item))?.length &&
        Object.values(values).filter(Boolean)
      ) {
        form.setFields([
          {
            name: 'isSpouseFinancialDifficultyReason',
            errors: [],
          },
        ]);
      }
    },
    [form]
  );

  const getPanelFieldsErrors = useCallback(
    (panelFields?: string[]) => {
      if (!panelFields) return undefined;

      const filtered = formErrors?.filter(
        (item) =>
          (item?.errors?.length || item?.warnings?.length) &&
          Boolean(item?.name?.find((name) => panelFields.indexOf(String(name)) > -1))
      );

      return filtered?.length ? filtered : undefined;
    },
    [formErrors]
  );

  const onClickError = useCallback((tab: string, tabRef: React.RefObject<HTMLDivElement>) => {
    setCurrentTab(tab);
    setTimeout(() => tabRef?.current?.scrollIntoView({ block: 'start', behavior: 'smooth', inline: 'start' }), 500);
  }, []);

  const hasPanelErrors = useCallback(
    (errors?: IError[]) => Boolean(errors?.filter((item) => item?.errors?.length)?.length),
    []
  );

  const refreshStatus = useCallback(() => {
    API.getPersonalInfoStatus(data?.id).then((response) => {
      setIsReviewed(response.status === ClientService.AppFormStatusEnum.Reviewed);
      setNeedToBeReviewed(
        response.status === ClientService.AppFormStatusEnum.ReadyForReview ||
          response.status === ClientService.AppFormStatusEnum.Incomplete_Review
      );
    });
  }, [data?.id]);

  const checkIfDocumentUploadHasErrors = useCallback(
    () =>
      form
        .validateFields()
        .then(() => false)
        .catch((e: ValidateErrorEntity) => {
          console.log(e?.errorFields);
          return Boolean(
            e?.errorFields
              ?.map((field) => field?.name)
              ?.flat()
              ?.find((i) => i === 'newFiles')
          );
        }),
    [form]
  );

  const handlePersonalInfoUpdateRequest = useCallback(
    (dto: IForm, onSaveSuccess?: () => void) => {
      setIsLoading(true);
      eventBus.dispatch(UPLOAD_FILES_EVENT, {
        onUploadSuccess: async (documentIds: string[]) => {
          const response = await API.updateAppFormPersonalInfo({
            ...dto,
            fileId: data?.id,
            sin: dto?.sin?.toString(),
            birthDate: convertInputDateToServerDateOnly(dto?.birthDate),
            marriageDate: convertInputDateToServerDateOnly(dto?.marriageDate),
            spouseBirthDate: convertInputDateToServerDateOnly(dto?.spouseBirthDate),
            residenceDate: convertInputDateToServerDateOnly(dto?.residenceDate),
            spouseResidenceDate: convertInputDateToServerDateOnly(dto?.spouseResidenceDate),
            dependants: dto?.dependants?.map(
              (item: ClientService.AppFormPersonalInfoDependantDto) =>
                ({
                  ...item,
                  birthDate: convertInputDateToServerDateOnly(item?.birthDate),
                } as ClientService.AppFormPersonalInfoDependantDto)
            ),
            employmentDetails: dto?.employmentDetails?.map(
              (item: ClientService.AppFormPersonalInfoEmploymentDetailDto) =>
                ({
                  ...item,
                  startDate: convertInputDateToServerDateOnly(item?.startDate),
                  endDate: convertInputDateToServerDateOnly(item?.endDate),
                } as ClientService.AppFormPersonalInfoEmploymentDetailDto)
            ),
            spouseEmploymentDetails: dto?.spouseEmploymentDetails?.map(
              (item: ClientService.AppFormPersonalInfoEmploymentDetailDto) =>
                ({
                  ...item,
                  startDate: convertInputDateToServerDateOnly(item?.startDate),
                  endDate: convertInputDateToServerDateOnly(item?.endDate),
                } as ClientService.AppFormPersonalInfoEmploymentDetailDto)
            ),
            employmentInsurancePeriods: dto?.employmentInsurancePeriods?.map(
              (item: ClientService.AppFormPersonalInfoEmploymentInsurancePeriodDto) =>
                ({
                  ...item,
                  startDate: convertInputDateToServerDateOnly(item?.startDate),
                  endDate: convertInputDateToServerDateOnly(item?.endDate),
                } as ClientService.AppFormPersonalInfoEmploymentInsurancePeriodDto)
            ),
            spouseEmploymentInsurancePeriods: dto?.spouseEmploymentInsurancePeriods?.map(
              (item: ClientService.AppFormPersonalInfoEmploymentInsurancePeriodDto) =>
                ({
                  ...item,
                  startDate: convertInputDateToServerDateOnly(item?.startDate),
                  endDate: convertInputDateToServerDateOnly(item?.endDate),
                } as ClientService.AppFormPersonalInfoEmploymentInsurancePeriodDto)
            ),
            previousInsolvencies: dto?.previousInsolvencies?.map(
              (item: ClientService.AppFormPersonalInfoPreviousInsolvencyDto) =>
                ({
                  ...item,
                  bankruptcyDate: convertInputDateToServerDateOnly(item?.bankruptcyDate),
                  dischargeDate: convertInputDateToServerDateOnly(item?.dischargeDate),
                  proposalDate: convertInputDateToServerDateOnly(item?.proposalDate),
                } as ClientService.AppFormPersonalInfoPreviousInsolvencyDto)
            ),
            spousePreviousInsolvencies: dto?.spousePreviousInsolvencies?.map(
              (item: ClientService.AppFormPersonalInfoPreviousInsolvencyDto) =>
                ({
                  ...item,
                  bankruptcyDate: convertInputDateToServerDateOnly(item?.bankruptcyDate),
                  dischargeDate: convertInputDateToServerDateOnly(item?.dischargeDate),
                  proposalDate: convertInputDateToServerDateOnly(item?.proposalDate),
                } as ClientService.AppFormPersonalInfoPreviousInsolvencyDto)
            ),
            documentIds: documentIds || undefined,
          } as ClientService.AppFormPersonalInfoDto);

          if (response?.result === ClientService.Result.Successful) {
            genericMessage.success(t.SUCCESSFULLY_SAVED);
            setIsUnsavedForm(false);
            updateStatus();
            refreshStatus();

            if (onSaveSuccess) {
              onSaveSuccess();
            }

            setIsLoading(false);
          } else {
            setIsLoading(false);
            genericMessage.error({}, response?.messages?.[0]?.body);
          }
        },
        onUploadError: async (errorMessage: string) => {
          setIsLoading(false);
          genericMessage.error({}, errorMessage);
        },
      });
    },
    [data?.id, refreshStatus, setIsUnsavedForm, t.SUCCESSFULLY_SAVED, updateStatus]
  );

  const onFinish = useCallback(
    async (dto?: IForm, onSaveSuccess?: () => void) => {
      if (!dto || isAppFormLocked) return;

      setFormErrors(form.getFieldsError());
      setCurrentTab(undefined);

      if (isPrevalidated) {
        const documentUploadErrors = await checkIfDocumentUploadHasErrors();
        if (documentUploadErrors) {
          showModal(
            <ActionConfirmationModal
              title={t.CONFIRMATION}
              message={t.PERSONAL_INFO_DOCUMENTS_UPLOAD_ERROR_CONFIRM}
              okText={t.LEAVE}
              cancelText={t.STAY}
              onOk={() => {
                closeModal();
                handlePersonalInfoUpdateRequest(dto, onSaveSuccess);
              }}
              onCancel={closeModal}
            />
          );
        } else {
          handlePersonalInfoUpdateRequest(dto, onSaveSuccess);
        }
      } else {
        setIsPrevalidated(true);
      }
    },
    [
      isAppFormLocked,
      form,
      isPrevalidated,
      checkIfDocumentUploadHasErrors,
      showModal,
      t.CONFIRMATION,
      t.PERSONAL_INFO_DOCUMENTS_UPLOAD_ERROR_CONFIRM,
      t.LEAVE,
      t.STAY,
      closeModal,
      handlePersonalInfoUpdateRequest,
    ]
  );

  const saveAndLeave = useCallback(
    (e) => {
      onFinish(form.getFieldsValue(), e?.detail?.onSaveSuccess);
    },
    [form, onFinish]
  );

  const saveAndContinue = useCallback(() => {
    if (isLoading) return;
    try {
      setIsLoading(true);
      onFinish(form.getFieldsValue(), onContinue);
    } catch (e) {
      console.log(e);
    } finally {
      setIsLoading(false);
    }
  }, [form, isLoading, onContinue, onFinish, setIsLoading]);

  /**
   *  Event to mark Personal Information as completed.
   *  Note: System needs to save Personal Information section data first,
   *  then update status as "Completed".
   */
  const markComplete = useCallback(() => {
    setIsLoading(true);
    API.personalInfoMarkAsComplete(data?.id)
      .then((response) => {
        setIsLoading(false);
        updateStatus();
        refreshStatus();
      })
      .catch(() => {
        setIsLoading(false);
      });
  }, [data?.id, refreshStatus, updateStatus]);

  const markReviewed = useCallback(() => {
    setIsLoading(true);
    API.personalInfoMarkAsReviewed(data?.id)
      .then((response) => {
        setIsLoading(false);
        updateStatus();
        refreshStatus();
      })
      .catch(() => {
        setIsLoading(false);
      });
  }, [data?.id, refreshStatus, updateStatus]);

  /**
   * Called when click "Mark as Complete".
   */
  const onMarkCompleteClicked = useCallback(() => {
    onFinish(form.getFieldsValue(), markComplete);
  }, [form, onFinish, markComplete]);

  const preFillCollections = useCallback((response: ClientService.AppFormPersonalInfoDto) => {
    if (!calculateLessThan2YearsResidency(response?.residenceDate)) {
      if (!response.previousAddresses) {
        response.previousAddresses = [];
      }
      response.previousAddresses?.push(new ClientService.AppFormPersonalInfoPreviousAddressDto());
    }
    if (!response.isReceivedEmploymentInsurancePastTwoYears) {
      if (!response.employmentInsurancePeriods) {
        response.employmentInsurancePeriods = [];
      }
      response.employmentInsurancePeriods?.push(new ClientService.AppFormPersonalInfoEmploymentInsurancePeriodDto());
    }
    if (!response.isSpouseReceivedEmploymentInsurancePastTwoYears) {
      if (!response.spouseEmploymentInsurancePeriods) {
        response.spouseEmploymentInsurancePeriods = [];
      }
      response.spouseEmploymentInsurancePeriods?.push(
        new ClientService.AppFormPersonalInfoEmploymentInsurancePeriodDto()
      );
    }
    if (!response.hasDependants) {
      if (!response.dependants) {
        response.dependants = [];
      }
      response.dependants?.push(new ClientService.AppFormPersonalInfoDependantDto());
    }
    if (!response.hasPreviousInsolvency) {
      if (!response.previousInsolvencies) {
        response.previousInsolvencies = [];
      }
      response.previousInsolvencies?.push(new ClientService.AppFormPersonalInfoPreviousInsolvencyDto());
    }
    if (!response.hasSpousePreviousInsolvency) {
      if (!response.spousePreviousInsolvencies) {
        response.spousePreviousInsolvencies = [];
      }
      response.spousePreviousInsolvencies?.push(new ClientService.AppFormPersonalInfoPreviousInsolvencyDto());
    }

    return response;
  }, []);

  useEffect(() => {
    return () => setIsUnsavedForm(false);
  }, [setIsUnsavedForm]);

  useEffect(() => {
    eventBus.on(APPFORM_SAVE_AND_LEAVE_EVENT, saveAndLeave);

    return () => {
      eventBus.remove(APPFORM_SAVE_AND_LEAVE_EVENT, saveAndLeave);
    };
  }, [saveAndLeave]);

  useEffect(() => {
    const selStatus = maritalStatuses?.find((item) => item?.id === selectedMaritalStatusId);

    let neverMarried =
      selStatus?.enumValue === undefined || selStatus?.enumValue === ClientService.MaritalStatusEnum.Single;
    if (neverMarried) {
      form.setFieldsValue({ marriageDate: undefined });
    }
    setMarriageDateDisabled(neverMarried);
    setIsMarried(
      selStatus?.enumValue === ClientService.MaritalStatusEnum.Married ||
        selStatus?.enumValue === ClientService.MaritalStatusEnum.CommonLaw
    );
  }, [selectedMaritalStatusId, maritalStatuses, form]);

  useEffect(() => {
    if (selectedCountryId) {
      setProvincesLoading(true);
      API.listProvinces(selectedCountryId).then((response) => {
        setProvinces(response);
        setProvincesLoading(false);
      });
    }
  }, [selectedCountryId, countries]);

  useEffect(() => {
    if (spouseSelectedCountryId) {
      setSpouseProvincesLoading(true);
      API.listProvinces(spouseSelectedCountryId).then((response) => {
        setSpouseProvinces(response);
        setSpouseProvincesLoading(false);
      });
    }
  }, [spouseSelectedCountryId, countries]);

  useEffect(() => {
    setMaritalStatusesLoading(true);
    API.listMaritalStatuses().then((response) => {
      setMaritalStatuses(response);
      setMaritalStatusesLoading(false);
    });

    setEducationLevelsLoading(true);
    API.listEducationLevels().then((response) => {
      setEducationLevels(response);
      setEducationLevelsLoading(false);
    });

    setCountriesLoading(true);
    API.listCountries().then((response) => {
      setCountries(response);
      setCountriesLoading(false);
    });

    setPhoneTypesLoading(true);
    API.listPhoneTypes().then((response) => {
      setPhoneTypes(response);
      setPhoneTypesLoading(false);
    });

    setRelationshipTypesLoading(true);
    API.listRelationshipTypes().then((response) => {
      setRelationshipTypes(response);
      setRelationshipTypesLoading(false);
    });
  }, []);

  const requestPersonalInfoDocuments = useCallback(
    async (isJointFile?: boolean) => {
      const folders = await API.listFolders();
      const documentFolderId = folders?.find((item) => item.code === 'lead')?.id;

      const response = await API.documentsGET(
        ...argumentifyDocumentsSearchCriteria({
          fileId: data?.id,
          documentFolderId,
          documentPrefixCodes: personalInfoPrefixCodes,
          maxResultCount: MAX_PAGE_SIZE,
        })
      );

      if (response) {
        const notEnoughDocuments =
          (isJointFile &&
            (Number(response?.items?.filter((i) => !i.isForSpouse).length || 0) < 1 ||
              Number(response?.items?.filter((i) => i.isForSpouse).length || 0) < 1)) ||
          (!isJointFile && Number(response?.totalCount || 0) < 1);
        if (notEnoughDocuments) {
          setFormErrors((e) => [
            ...(e || []),
            {
              name: ['existingFiles'] as InternalNamePath,
              errors: [
                isJointFile
                  ? t.FOR_JOINT_FILES__1_ID_REQUIRED_FOR_APPLICANT_1_ID_REQUIRED_FOR_SPOUSE
                  : t.ONE_ID_REQUIRED_FOR_APPLICANT,
              ],
            } as IError,
          ]);
        }
      }
    },
    [data?.id, t.FOR_JOINT_FILES__1_ID_REQUIRED_FOR_APPLICANT_1_ID_REQUIRED_FOR_SPOUSE, t.ONE_ID_REQUIRED_FOR_APPLICANT]
  );

  const updateReviewedPanels = useCallback((panelKey?: string) => {
    switch (panelKey) {
      case PERSONAL_DETAILS_TAB:
        return setClientUpdatedPanels((prev) => ({ ...prev, isPersoanlDetailUpdatedByClient: false }));
      case FINANCIAL_DIFFICULTY_TAB:
        return setClientUpdatedPanels((prev) => ({ ...prev, isFinancialDifficultyByClient: false }));
      case SPOUSE_FINANCIAL_DIFFICULTY_TAB:
        return setClientUpdatedPanels((prev) => ({ ...prev, isFinancialDifficultyByClientSpouse: false }));
      case CONTACT_INFORMATION_TAB:
        return setClientUpdatedPanels((prev) => ({ ...prev, isContactInformationUpdatedByClient: false }));
      case EMPLOYMENT_TAB:
        return setClientUpdatedPanels((prev) => ({ ...prev, isEmploymentUpdatedByClient: false }));
      case PREVIOUS_INSOLVENCIES_TAB:
        return setClientUpdatedPanels((prev) => ({ ...prev, isPreviousInsolvencyUpdatedByClient: false }));
      case DEPENDANTS_TAB:
        return setClientUpdatedPanels((prev) => ({ ...prev, isDependantsUpdatedByClient: false }));
      default:
        return;
    }
  }, []);

  const handleTabChange = useCallback(
    (key: string | string[]) => {
      const newTab = Array.isArray(key) ? key?.[0] : key;
      setCurrentTab(newTab);
      updateReviewedPanels(newTab);
    },
    [updateReviewedPanels]
  );

  useEffect(() => {
    if (data?.id) {
      API.getAppFormPersonalInfo(data?.id).then(async (response) => {
        setClientUpdatedPanels({
          isDependantsUpdatedByClient: response?.isDependantsUpdatedByClient,
          isPersoanlDetailUpdatedByClient: response?.isPersoanlDetailUpdatedByClient,
          isContactInformationUpdatedByClient: response?.isContactInformationUpdatedByClient,
          isEmploymentUpdatedByClient: response?.isEmploymentUpdatedByClient,
          isFinancialDifficultyByClient: response?.isFinancialDifficultyByClient,
          isFinancialDifficultyByClientSpouse: response?.isFinancialDifficultyByClientSpouse,
          isPreviousInsolvencyUpdatedByClient: response?.isPreviousInsolvencyUpdatedByClient,
        });
        preFillCollections(response);

        setIsJointFile(response?.isJointFile || false);
        setSelectedMaritalStatusId(response?.maritalStatusId || '');

        setIsSpouseAddressSame(response?.isSpouseAddressSame || false);
        setIsLessThan2YearsAtCurrentAddress(calculateLessThan2YearsResidency(response?.residenceDate));
        setExistingDocumentIds(response?.documentIds || []);
        setIsRecentlyEI(response?.isReceivedEmploymentInsurancePastTwoYears || false);
        setIsSpouseRecentlyEI(response?.isSpouseReceivedEmploymentInsurancePastTwoYears || false);
        setHasLegalMattersFDReason(response?.hasFDReasonLitigationIssues || false);
        setHasOtherFDReason(response?.hasFDReasonOther || false);
        setSpouseHasLegalMattersFDReason(response?.spouseHasFDReasonLitigationIssues || false);
        setSpouseHasOtherFDReason(response?.spouseHasFDReasonOther || false);
        setHasDependants(response?.hasDependants || false);
        setHasPreviousInsolvency(response?.hasPreviousInsolvency || false);
        setHasSpousePreviousInsolvency(response?.hasSpousePreviousInsolvency || false);
        setIsRegisteredToClientPortal(response?.isRegisteredToClientPortal || false);

        let countryId = response?.countryId;
        let spouseCountryId = response?.countryId;

        if (!countryId || !spouseCountryId) {
          const countries = await API.listCountries();
          const canadaCountryId = countries?.find((item) => item?.code === 'CA')?.id;
          countryId = countryId || canadaCountryId;
          spouseCountryId = spouseCountryId || canadaCountryId;
        }

        setSelectedCountryId(countryId || '');
        setSpouseSelectedCountryId(spouseCountryId || '');

        form.setFieldsValue({
          ...response,
          address: response?.address?.trim(),
          birthDate: convertServerDateOnlyToInputDate(response?.birthDate),
          marriageDate: convertServerDateOnlyToInputDate(response?.marriageDate),
          spouseBirthDate: convertServerDateOnlyToInputDate(response?.spouseBirthDate),
          residenceDate: convertServerDateOnlyToInputDate(response?.residenceDate),
          spouseResidenceDate: convertServerDateOnlyToInputDate(response?.spouseResidenceDate),
          countryId,
          spouseCountryId,
          dependants: response?.dependants?.map(
            (item: ClientService.AppFormPersonalInfoDependantDto) =>
              ({
                ...item,
                birthDate: convertServerDateOnlyToInputDate(item?.birthDate),
              } as ClientService.AppFormPersonalInfoDependantDto)
          ),
          employmentDetails:
            Array.isArray(response?.employmentDetails) && response?.employmentDetails.length > 0
              ? response.employmentDetails.map(
                  (item: ClientService.AppFormPersonalInfoEmploymentDetailDto) =>
                    ({
                      ...item,
                      startDate: convertServerDateOnlyToInputDate(item?.startDate),
                      endDate: convertServerDateOnlyToInputDate(item?.endDate),
                    } as ClientService.AppFormPersonalInfoEmploymentDetailDto)
                )
              : [{} as ClientService.AppFormPersonalInfoEmploymentDetailDto],
          spouseEmploymentDetails:
            Array.isArray(response?.spouseEmploymentDetails) && response?.spouseEmploymentDetails.length > 0
              ? response.spouseEmploymentDetails.map(
                  (item: ClientService.AppFormPersonalInfoEmploymentDetailDto) =>
                    ({
                      ...item,
                      startDate: convertServerDateOnlyToInputDate(item?.startDate),
                      endDate: convertServerDateOnlyToInputDate(item?.endDate),
                    } as ClientService.AppFormPersonalInfoEmploymentDetailDto)
                )
              : [{} as ClientService.AppFormPersonalInfoEmploymentDetailDto],
          employmentInsurancePeriods: response?.employmentInsurancePeriods?.map(
            (item: ClientService.AppFormPersonalInfoEmploymentInsurancePeriodDto) =>
              ({
                ...item,
                startDate: convertServerDateOnlyToInputDate(item?.startDate),
                endDate: convertServerDateOnlyToInputDate(item?.endDate),
              } as ClientService.AppFormPersonalInfoEmploymentInsurancePeriodDto)
          ),
          spouseEmploymentInsurancePeriods: response?.spouseEmploymentInsurancePeriods?.map(
            (item: ClientService.AppFormPersonalInfoEmploymentInsurancePeriodDto) =>
              ({
                ...item,
                startDate: convertServerDateOnlyToInputDate(item?.startDate),
                endDate: convertServerDateOnlyToInputDate(item?.endDate),
              } as ClientService.AppFormPersonalInfoEmploymentInsurancePeriodDto)
          ),
          previousInsolvencies: response?.previousInsolvencies?.map(
            (item: ClientService.AppFormPersonalInfoPreviousInsolvencyDto) =>
              ({
                ...item,
                bankruptcyDate: convertServerDateOnlyToInputDate(item?.bankruptcyDate),
                dischargeDate: convertServerDateOnlyToInputDate(item?.dischargeDate),
                proposalDate: convertServerDateOnlyToInputDate(item?.proposalDate),
              } as ClientService.AppFormPersonalInfoPreviousInsolvencyDto)
          ),
          spousePreviousInsolvencies: response?.spousePreviousInsolvencies?.map(
            (item: ClientService.AppFormPersonalInfoPreviousInsolvencyDto) =>
              ({
                ...item,
                bankruptcyDate: convertServerDateOnlyToInputDate(item?.bankruptcyDate),
                dischargeDate: convertServerDateOnlyToInputDate(item?.dischargeDate),
                proposalDate: convertServerDateOnlyToInputDate(item?.proposalDate),
              } as ClientService.AppFormPersonalInfoPreviousInsolvencyDto)
          ),
          numberOfPersonsInHousehold: response?.numberOfPersonsInHousehold || 1,
          isFinancialDifficultyReason:
            response?.hasFDReasonMaritalSeparationOrRelationshipBreakdown ||
            response?.hasFDReasonGambling ||
            response?.hasFDReasonBusinessFailure ||
            response?.hasFDReasonLitigationIssues ||
            response?.hasFDReasonDrugAlcoholAddicition ||
            response?.hasFDReasonLossOfIncome ||
            response?.hasFDReasonMedicalReasons ||
            response?.hasFDReasonTaxLiabilities ||
            response?.hasFDReasonFinancialSupportOfOthers ||
            response?.hasFDReasonStudentDebt ||
            response?.hasFDReasonMovingOrRelocationExpenses ||
            response?.hasFDReasonFailedProposal ||
            response?.hasFDReasonFinancialMismanagement ||
            response?.hasFDReasonOther,
          isSpouseFinancialDifficultyReason:
            response?.spouseHasFDReasonMaritalSeparationOrRelationshipBreakdown ||
            response?.spouseHasFDReasonGambling ||
            response?.spouseHasFDReasonBusinessFailure ||
            response?.spouseHasFDReasonLitigationIssues ||
            response?.spouseHasFDReasonDrugAlcoholAddicition ||
            response?.spouseHasFDReasonLossOfIncome ||
            response?.spouseHasFDReasonMedicalReasons ||
            response?.spouseHasFDReasonTaxLiabilities ||
            response?.spouseHasFDReasonFinancialSupportOfOthers ||
            response?.spouseHasFDReasonStudentDebt ||
            response?.spouseHasFDReasonMovingOrRelocationExpenses ||
            response?.spouseHasFDReasonFailedProposal ||
            response?.spouseHasFDReasonFinancialMismanagement ||
            response?.spouseHasFDReasonOther,
        });

        const personalInfoFormStatus = await API.getPersonalInfoStatus(data?.id);
        const isNotStarted = personalInfoFormStatus?.status === ClientService.AppFormStatusEnum.NotStarted;

        if (!isAppFormLocked && !isNotStarted) {
          form.submit();
          requestPersonalInfoDocuments(response?.isJointFile);
        }

        if (isNotStarted) {
          setIsPrevalidated(true);
        }
      });

      refreshStatus();
    }
  }, [
    data?.id,
    form,
    isAppFormLocked,
    preFillCollections,
    refreshStatus,
    requestPersonalInfoDocuments,
    needToBeReviewed,
  ]);

  return (
    <Spin spinning={isLoading}>
      <Form
        form={form}
        onFinish={(values) => onFinish(values)}
        onFinishFailed={() => onFinish(form.getFieldsValue())}
        layout="vertical"
        className="ApplicationFormTab"
        validateMessages={antdUtil.validateMessages}
        onValuesChange={(values) => {
          setIsUnsavedForm(true);
          updateFinancialDifficultyReasonsGroupError(values);
          updateSpouseFinancialDifficultyReasonsGroupError(values);
        }}
        validateTrigger={['onSubmit', 'onBlur']}
      >
        {Boolean(formErrors?.filter((item) => item?.errors?.length || item?.warnings?.length)?.length) && (
          <div className="ApplicationFormTab__errors-container">
            <div className="ApplicationFormTab__errors-container--title">{t.VALIDATION_ERRORS_AND_WARNINGS}</div>
            <ErrorComponent
              errors={getPanelFieldsErrors(personalDetailsFields)}
              onClick={() => onClickError('1', refPersonalDetails)}
            />
            <ErrorComponent
              errors={getPanelFieldsErrors(supportingDocumentsFields)}
              onClick={() => onClickError('1', refSupportingDocuments)}
            />
            <ErrorComponent
              errors={getPanelFieldsErrors(spousePersonalDetailsFields)}
              onClick={() => onClickError('1', refSpousePersonalDetails)}
            />
            <ErrorComponent
              errors={getPanelFieldsErrors(contactInformationFields)}
              onClick={() => onClickError('2', refContactInformation)}
            />
            <ErrorComponent
              errors={getPanelFieldsErrors(employmentFields)}
              onClick={() => onClickError('3', refEmployment)}
            />
            <ErrorComponent
              errors={getPanelFieldsErrors(financialDifficultyFields)}
              onClick={() => onClickError('4', tabRefFinancialDifficulty)}
            />
            <ErrorComponent
              errors={getPanelFieldsErrors(spouseFinancialDifficultyFields)}
              onClick={() => onClickError('5', tabRefSpouseFinancialDifficulty)}
            />
            <ErrorComponent
              errors={getPanelFieldsErrors(dependantsFields)}
              onClick={() => onClickError('6', refDependants)}
            />
            <ErrorComponent
              errors={getPanelFieldsErrors(previousInsolvenciesFields)}
              onClick={() => onClickError('7', refPreviousInsolvency)}
            />
          </div>
        )}
        <Collapse
          ghost
          activeKey={currentTab}
          onChange={handleTabChange}
          accordion
          expandIcon={({ isActive }) => (
            <div
              className={`ms-Icon ms-Icon--${
                isActive ? 'ChevronUp' : 'ChevronRight'
              } ApplicationOverviewPage__chevron-icon`}
            />
          )}
        >
          {/* Personal Details */}
          <Panel
            className={clientUpdatedPanels?.isPersoanlDetailUpdatedByClient ? 'user-changed' : ''}
            header={
              <div className="ApplicationOverviewContent__panel-header" ref={refPersonalDetails}>
                {t.PERSONAL_DETAILS}
                {hasPanelErrors([
                  ...(getPanelFieldsErrors(personalDetailsFields) || []),
                  ...(getPanelFieldsErrors(spousePersonalDetailsFields) || []),
                  ...(getPanelFieldsErrors(supportingDocumentsFields) || []),
                ]) && <Icon iconName="AlertSolid" className="ApplicationFormTab__panel-header-error" />}
              </div>
            }
            key={PERSONAL_DETAILS_TAB}
            forceRender
          >
            <div className="ApplicationFormTab__header">{t.APPLICANT}</div>
            <Row gutter={20}>
              <Col span={8}>
                <Form.Item
                  name="firstName"
                  label={<span className="ApplicationFormTab__label-required">{t.FIRST_NAME}</span>}
                >
                  <Input size="large" disabled />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item name="middleName" label={t.MIDDLE_NAME}>
                  <Input size="large" disabled />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  name="lastName"
                  label={<span className="ApplicationFormTab__label-required">{t.LAST_NAME}</span>}
                >
                  <Input size="large" disabled />
                </Form.Item>
              </Col>
              <Col span={8}>
                <ChangeTrackerFormItem name="akaName" label={t.ALSO_KNOWN_AS}>
                  <Input size="large" disabled={isAppFormLocked} maxLength={100} />
                </ChangeTrackerFormItem>
              </Col>
              <Col span={8}>
                <ChangeTrackerFormItem name="birthDate" label={t.DATE_OF_BIRTH} rules={[{ required: true }]}>
                  <DatePicker
                    size="large"
                    disabledDate={antdUtil.futureDates}
                    format={DATE_FORMAT2}
                    placeholder={DATE_FORMAT_PLACEHOLDER}
                    disabled={isAppFormLocked}
                  />
                </ChangeTrackerFormItem>
              </Col>
              <Col span={8}>
                <ChangeTrackerFormItem
                  name="sin"
                  label={t.SIN}
                  rules={[
                    { required: true },
                    { pattern: new RegExp('\\d\\d\\d \\d\\d\\d \\d\\d\\d'), message: t.SIN_IS_INVALID },
                  ]}
                >
                  <InputMask mask="999 999 999" disabled={isAppFormLocked}>
                    <Input size="large" />
                  </InputMask>
                </ChangeTrackerFormItem>
              </Col>
              <Col span={8}>
                <ChangeTrackerFormItem name="maritalStatusId" label={t.MARITAL_STATUS} rules={[{ required: true }]}>
                  <Select
                    loading={maritalStatusesLoading}
                    onChange={handleMaritalStatusSelect}
                    suffixIcon={<SelectSuffixIcon />}
                    size="large"
                    disabled={isAppFormLocked}
                  >
                    {maritalStatuses?.map((option) => (
                      <Option key={option.id} value={option?.id}>
                        {option?.name}
                      </Option>
                    ))}
                  </Select>
                </ChangeTrackerFormItem>
              </Col>
              <Col span={8}>
                <ChangeTrackerFormItem name="marriageDate" label={t.DATE_OF_MARRIAGE}>
                  <DatePicker
                    size="large"
                    style={{ width: '100%' }}
                    disabled={marriageDateDisabled || isAppFormLocked}
                    disabledDate={antdUtil.futureDates}
                    format={DATE_FORMAT2}
                    placeholder={DATE_FORMAT_PLACEHOLDER}
                  />
                </ChangeTrackerFormItem>
              </Col>
              <Col span={8}>
                <ChangeTrackerFormItem name="educationLevelId" label={t.HIGHEST_EDUCATION_LEVEL_COMPLETED}>
                  <Select
                    loading={educationLevelsLoading}
                    suffixIcon={<SelectSuffixIcon />}
                    size="large"
                    disabled={isAppFormLocked}
                  >
                    {educationLevels?.map((option) => (
                      <Option key={option.id} value={option?.id}>
                        {option?.name}
                      </Option>
                    ))}
                  </Select>
                </ChangeTrackerFormItem>
              </Col>
            </Row>

            {isMarried && (
              <>
                <Divider />
                <div className="ApplicationFormTab__header" ref={refSpousePersonalDetails}>
                  {t.SPOUSE_PARTNER}
                </div>
                <Row gutter={20}>
                  <Col span={8}>
                    <ChangeTrackerFormItem
                      name="spouseFirstName"
                      className={isJointFile ? '' : 'ApplicationFormTab__input-label-warning'}
                      label={t.FIRST_NAME}
                      rules={[
                        {
                          required: true,
                          warningOnly: !isJointFile,
                          message: isJointFile ? t.REQUIRED_FIELD : t.RECOMMENDED_FIELD,
                        },
                      ]}
                    >
                      <Input size="large" disabled={isAppFormLocked} maxLength={100} />
                    </ChangeTrackerFormItem>
                  </Col>
                  <Col span={8}>
                    <ChangeTrackerFormItem name="spouseMiddleName" label={t.MIDDLE_NAME}>
                      <Input size="large" disabled={isAppFormLocked} maxLength={100} />
                    </ChangeTrackerFormItem>
                  </Col>
                  <Col span={8}>
                    <ChangeTrackerFormItem
                      name="spouseLastName"
                      className={isJointFile ? '' : 'ApplicationFormTab__input-label-warning'}
                      label={t.LAST_NAME}
                      rules={[
                        {
                          required: true,
                          warningOnly: !isJointFile,
                          message: isJointFile ? t.REQUIRED_FIELD : t.RECOMMENDED_FIELD,
                        },
                      ]}
                    >
                      <Input size="large" disabled={isAppFormLocked} maxLength={100} />
                    </ChangeTrackerFormItem>
                  </Col>
                  <Col span={8}>
                    <ChangeTrackerFormItem name="spouseAkaName" label={t.ALSO_KNOWN_AS}>
                      <Input size="large" disabled={isAppFormLocked} maxLength={100} />
                    </ChangeTrackerFormItem>
                  </Col>
                  <Col span={8}>
                    <ChangeTrackerFormItem
                      name="spouseBirthDate"
                      label={t.DATE_OF_BIRTH}
                      rules={[{ required: isJointFile }]}
                    >
                      <DatePicker
                        size="large"
                        disabledDate={antdUtil.futureDates}
                        format={DATE_FORMAT2}
                        disabled={isAppFormLocked}
                      />
                    </ChangeTrackerFormItem>
                  </Col>
                  <Col span={8}>
                    <ChangeTrackerFormItem
                      name="spouseSIN"
                      label={t.SIN}
                      rules={[
                        { required: isJointFile },
                        {
                          pattern: new RegExp('\\d\\d\\d \\d\\d\\d \\d\\d\\d'),
                          message: t.SIN_IS_INCORRECT,
                        },
                      ]}
                    >
                      <InputMask mask="999 999 999" disabled={isAppFormLocked}>
                        <Input size="large" />
                      </InputMask>
                    </ChangeTrackerFormItem>
                  </Col>
                  <Col span={8}>
                    <ChangeTrackerFormItem name="spouseEducationLevelId" label={t.HIGHEST_EDUCATION_LEVEL_COMPLETED}>
                      <Select
                        loading={educationLevelsLoading}
                        suffixIcon={<SelectSuffixIcon />}
                        size="large"
                        disabled={isAppFormLocked}
                      >
                        {educationLevels?.map((option) => (
                          <Option key={option.id} value={option?.id}>
                            {option?.name}
                          </Option>
                        ))}
                      </Select>
                    </ChangeTrackerFormItem>
                  </Col>
                  <Col span={16}>
                    <ChangeTrackerFormItem name="isJointFile" valuePropName="checked" style={{ textAlign: 'right' }}>
                      <Checkbox onChange={handleJointFileChecked} disabled={isAppFormLocked}>
                        {t.JOINT_FILE}
                      </Checkbox>
                    </ChangeTrackerFormItem>
                  </Col>
                </Row>
              </>
            )}

            <Divider />
            <Row>
              <Col span={24}>
                <div className="ApplicationFormTab__header" ref={refSupportingDocuments}>
                  {t.SUPPORTING_DOCUMENTS}
                </div>
                <AttachmentsTab
                  applicationFileId={data?.id as string}
                  form={form}
                  supportingFolderCode="lead"
                  prefixCodes={[
                    'Personal.Drivers-License',
                    'Personal.Health-Card',
                    'Personal.Other-Id',
                    'Personal.Passport',
                  ]}
                  isFlatLayout
                  flatLayoutProps={{
                    hasInstruction: true,
                    hasSupportingList: true,
                    instructionText: t.FOR_JOINT_FILES__1_ID_REQUIRED_FOR_APPLICANT_1_ID_REQUIRED_FOR_SPOUSE,
                    hasExistingFiles: true,
                    supportingListLabelHint: [
                      t.ONLY_1_OF_THE_4_SUPPORTING_DOCUMENTS_LISTED_FOR_PERSONAL_IDENTIFICATION_IS_REQUIRED,
                    ],
                  }}
                  newSupportingFileRowLayoutProps={{ disabledFoldersInput: true }}
                  columnsProps={{
                    hasForSpouseColumn: isJointFile,
                    hasSizeColumn: true,
                    isDeleteDisabled: isAppFormLocked,
                  }}
                  draggerProps={{ disabled: isAppFormLocked }}
                  limitSupportingDocsByPrefixCode={personalInfoPrefixCodes}
                  attachmentType={AttachmentTypeEnum.AppFormPersonalInfo}
                  existingDocumentIds={existingDocumentIds}
                />
              </Col>
            </Row>

            <Divider />
            <Row>
              <Col span={18}>
                <ChangeTrackerFormItem name="additionalNotes" label={t.ADDITIONAL_NOTES}>
                  <TextArea size="large" disabled={isAppFormLocked} maxLength={10000} />
                </ChangeTrackerFormItem>
              </Col>
            </Row>
          </Panel>

          {/* Contact Information */}
          <Panel
            className={clientUpdatedPanels?.isContactInformationUpdatedByClient ? 'user-changed' : ''}
            header={
              <div className="ApplicationOverviewPage__panel-header" ref={refContactInformation}>
                {t.CONTACT_INFORMATION}
                {hasPanelErrors(getPanelFieldsErrors(contactInformationFields)) && (
                  <Icon iconName="AlertSolid" className="ApplicationFormTab__panel-header-error" />
                )}
              </div>
            }
            key={CONTACT_INFORMATION_TAB}
            forceRender
          >
            <div className="ApplicationFormTab__header">{t.APPLICANT}</div>
            <Row gutter={20}>
              <Col span={8}>
                <ChangeTrackerFormItem name="address" label={t.ADDRESS} rules={[{ required: true }]}>
                  <SearchLocationInput
                    address={{
                      streetName: form.getFieldValue('address'),
                      unit: form.getFieldValue('unit'),
                      postalCode: form.getFieldValue('postalCode'),
                      city: form.getFieldValue('city'),
                      province: { id: form.getFieldValue('provinceId') },
                      country: { id: form.getFieldValue('countryId') },
                    }}
                    onSelect={handleAddressAutocomplete}
                    countries={countries}
                    provinces={provinces}
                    size="large"
                    disabled={isAppFormLocked}
                  />
                </ChangeTrackerFormItem>
              </Col>
              <Col span={0}>
                <ChangeTrackerFormItem name="streetNumber">
                  <Input />
                </ChangeTrackerFormItem>
              </Col>
              <Col span={0}>
                <ChangeTrackerFormItem name="streetName">
                  <Input />
                </ChangeTrackerFormItem>
              </Col>
              <Col span={8}>
                <ChangeTrackerFormItem name="unit" label={t.UNIT}>
                  <Input size="large" disabled={isAppFormLocked} />
                </ChangeTrackerFormItem>
              </Col>
              <Col span={8}>
                <ChangeTrackerFormItem name="city" label={t.CITY} rules={[{ required: true }]}>
                  <Input size="large" disabled={isAppFormLocked} />
                </ChangeTrackerFormItem>
              </Col>
              <Col span={8}>
                <ChangeTrackerFormItem
                  name="provinceId"
                  label={t.PROVINCE}
                  rules={[{ required: provinces?.length != undefined && provinces?.length > 0 }]}
                >
                  <SelectLookupDto
                    loading={provincesLoading}
                    suffixIcon={<SelectSuffixIcon />}
                    data={provinces}
                    showSearch
                    disabled={isAppFormLocked || provinces?.length == undefined || provinces?.length == 0}
                  />
                </ChangeTrackerFormItem>
              </Col>
              <Col span={8}>
                <ChangeTrackerFormItem name="postalCode" label={t.POSTAL_CODE} rules={[{ required: true }]}>
                  <Input size="large" disabled={isAppFormLocked} />
                </ChangeTrackerFormItem>
              </Col>
              <Col span={8}>
                <ChangeTrackerFormItem name="countryId" label={t.COUNTRY} rules={[{ required: true }]}>
                  <SelectLookupDto
                    loading={countriesLoading}
                    onSelect={handleCountrySelect}
                    data={countries}
                    showSearch
                    disabled={isAppFormLocked}
                  />
                </ChangeTrackerFormItem>
              </Col>
              <Col span={8}>
                <ChangeTrackerFormItem
                  name="residenceDate"
                  label={t.AT_THIS_ADDRESS_SINCE}
                  rules={[{ required: true }]}
                >
                  <DatePicker
                    size="large"
                    disabledDate={antdUtil.futureDates}
                    format={DATE_FORMAT2}
                    onChange={(e) => handleResidenceDateChanged(e)}
                    placeholder={DATE_FORMAT_PLACEHOLDER}
                    disabled={isAppFormLocked}
                  />
                </ChangeTrackerFormItem>
              </Col>
            </Row>

            {Boolean(isLessThan2YearsAtCurrentAddress) && (
              <Form.List name="previousAddresses">
                {(previousAddresses, { add, remove }) => (
                  <>
                    {previousAddresses?.map((row, index) => (
                      <Row gutter={20} align="middle" key={`previousAddresses-${index}`}>
                        <Col span={20}>
                          <Form.Item name={[row.name, 'id']} hidden>
                            <Input disabled={isAppFormLocked} />
                          </Form.Item>
                          <Form.Item name={[row.name, 'streetNumber']} hidden>
                            <Input disabled={isAppFormLocked} />
                          </Form.Item>
                          <Form.Item name={[row.name, 'streetName']} hidden>
                            <Input disabled={isAppFormLocked} />
                          </Form.Item>
                          <Form.Item name={[row.name, 'city']} hidden>
                            <Input disabled={isAppFormLocked} />
                          </Form.Item>
                          <Form.Item name={[row.name, 'postalCode']} hidden>
                            <Input disabled={isAppFormLocked} />
                          </Form.Item>
                          <Form.Item name={[row.name, 'provinceId']} hidden>
                            <Input disabled={isAppFormLocked} />
                          </Form.Item>
                          <Form.Item name={[row.name, 'countryId']} hidden>
                            <Input disabled={isAppFormLocked} />
                          </Form.Item>
                          <ChangeTrackerFormItem
                            name={[row.name, 'address']}
                            label={t.PREVIOUS_ADDRESS}
                            listName="previousAddresses"
                          >
                            <SearchLocationInput
                              address={{ streetName: form.getFieldValue([row.name, 'address']) }}
                              onSelect={handlePreviousAddressAutocomplete(row.name)}
                              size="large"
                              disabled={isAppFormLocked}
                            />
                          </ChangeTrackerFormItem>
                        </Col>
                        <Col span={3}>
                          <ChangeTrackerFormItem name={[row.name, 'unit']} label={t.UNIT} listName="previousAddresses">
                            <Input size="large" disabled={isAppFormLocked} />
                          </ChangeTrackerFormItem>
                        </Col>
                        <Col span={isAppFormLocked ? 0 : 1} style={{ textAlign: 'right' }}>
                          <DeleteOutlined
                            className="ApplicationFormTab__input-action-icon"
                            onClick={() => remove(row.name)}
                          />
                        </Col>
                      </Row>
                    ))}
                    {!isAppFormLocked && (
                      <Row gutter={20}>
                        <Col span={8}>
                          <Form.Item>
                            <div onClick={() => add()} className="ApplicationFormTab__link-left">
                              {t.ADD_PREVIOUS_ADDRESS}
                            </div>
                          </Form.Item>
                        </Col>
                      </Row>
                    )}
                  </>
                )}
              </Form.List>
            )}

            <Row gutter={20}>
              <Col span={8}>
                <Label className="ApplicationFormTab__label ApplicationFormTab__label-required">
                  {t.MAIN_PHONE_NO}
                </Label>
                <Row gutter={20}>
                  <Col span={8}>
                    <ChangeTrackerFormItem
                      name="mainPhoneTypeId"
                      rules={[{ required: true, message: t.REQUIRED_FIELD }]}
                    >
                      <Select
                        loading={phoneTypesLoading}
                        suffixIcon={<SelectSuffixIcon />}
                        size="large"
                        disabled={isAppFormLocked}
                      >
                        {phoneTypes
                          ?.filter((item) => item?.enumValue !== ClientService.PhoneTypeEnum.Fax)
                          ?.map((option) => (
                            <Option key={option.id} value={option?.id}>
                              {option?.name}
                            </Option>
                          ))}
                      </Select>
                    </ChangeTrackerFormItem>
                  </Col>
                  <Col span={16}>
                    <ChangeTrackerFormItem
                      name="mainPhoneNumber"
                      rules={[{ required: true, message: t.REQUIRED_FIELD }]}
                    >
                      <InputMask mask="(999) 999-9999" disabled={isAppFormLocked}>
                        <Input size="large" />
                      </InputMask>
                    </ChangeTrackerFormItem>
                  </Col>
                </Row>
              </Col>
              <Col span={8}>
                <Form.Item
                  name="emailAddress"
                  className="ApplicationFormTab__input-label-warning"
                  label={t.EMAIL_ADDRESS}
                  rules={[
                    { required: true, warningOnly: true, message: t.RECOMMENDED_FIELD },
                    { type: 'email', message: t.EMAIL_ADDRESS_IS_INCORRECT },
                  ]}
                >
                  <Input size="large" disabled={isAppFormLocked || isRegisteredToClientPortal} />
                </Form.Item>
              </Col>
            </Row>

            {isMarried && (
              <>
                <div className="ApplicationFormTab__header">{t.SPOUSE_PARTNER}</div>

                <ChangeTrackerFormItem name="isSpouseContactInfoSame" valuePropName="checked">
                  <Checkbox onChange={handleSpouseSameContactInfoChecked} disabled={isAppFormLocked}>
                    {t.SAME_AS_APPLICANTS_CONTACT_INFORMATION}
                  </Checkbox>
                </ChangeTrackerFormItem>

                <Row gutter={20}>
                  <Col span={8}>
                    <ChangeTrackerFormItem name="spouseAddress" label={t.ADDRESS}>
                      <SearchLocationInput
                        address={{
                          streetName: form.getFieldValue('spouseAddress'),
                          unit: form.getFieldValue('spouseUnit'),
                          postalCode: form.getFieldValue('spousePostalCode'),
                          city: form.getFieldValue('spouseCity'),
                          province: { id: form.getFieldValue('spouseProvinceId') },
                          country: { id: form.getFieldValue('spouseCountryId') },
                        }}
                        onSelect={handleSpouseAddressAutocomplete}
                        countries={countries}
                        provinces={spouseProvinces}
                        size="large"
                        disabled={isSpouseAddressSame || isAppFormLocked}
                      />
                    </ChangeTrackerFormItem>
                  </Col>
                  <Col span={8}>
                    <ChangeTrackerFormItem name="spouseUnit" label={t.UNIT}>
                      <Input size="large" disabled={isSpouseAddressSame || isAppFormLocked} />
                    </ChangeTrackerFormItem>
                  </Col>
                  <Col span={8}>
                    <ChangeTrackerFormItem name="spouseCity" label={t.CITY}>
                      <Input size="large" disabled={isSpouseAddressSame || isAppFormLocked} />
                    </ChangeTrackerFormItem>
                  </Col>
                  <Col span={8}>
                    <ChangeTrackerFormItem name="spouseProvinceId" label={t.PROVINCE}>
                      <SelectLookupDto
                        loading={spouseProvincesLoading}
                        data={spouseProvinces}
                        disabled={isSpouseAddressSame || isAppFormLocked}
                        showSearch
                      />
                    </ChangeTrackerFormItem>
                  </Col>
                  <Col span={8}>
                    <ChangeTrackerFormItem name="spousePostalCode" label={t.POSTAL_CODE}>
                      <Input size="large" disabled={isSpouseAddressSame || isAppFormLocked} />
                    </ChangeTrackerFormItem>
                  </Col>
                  <Col span={8}>
                    <ChangeTrackerFormItem name="spouseCountryId" label={t.COUNTRY}>
                      <SelectLookupDto
                        loading={countriesLoading}
                        onSelect={handleSpouseCountrySelect}
                        data={countries}
                        showSearch
                        disabled={isSpouseAddressSame || isAppFormLocked}
                      />
                    </ChangeTrackerFormItem>
                  </Col>
                  <Col span={8}>
                    <ChangeTrackerFormItem name="spouseResidenceDate" label={t.AT_THIS_ADDRESS_SINCE}>
                      <DatePicker
                        size="large"
                        disabledDate={antdUtil.futureDates}
                        format={DATE_FORMAT2}
                        placeholder={DATE_FORMAT_PLACEHOLDER}
                        disabled={isAppFormLocked}
                      />
                    </ChangeTrackerFormItem>
                  </Col>
                </Row>

                <Row gutter={20}>
                  <Col span={8}>
                    <Label className="ApplicationFormTab__label">{t.MAIN_PHONE_NO}</Label>
                    <Row gutter={20}>
                      <Col span={8}>
                        <ChangeTrackerFormItem name="spouseMainPhoneTypeId">
                          <Select
                            loading={phoneTypesLoading}
                            suffixIcon={<SelectSuffixIcon />}
                            size="large"
                            disabled={isSpouseAddressSame || isAppFormLocked}
                          >
                            {phoneTypes
                              ?.filter((item) => item?.enumValue !== ClientService.PhoneTypeEnum.Fax)
                              ?.map((option) => (
                                <Option key={option.id} value={option?.id}>
                                  {option?.name}
                                </Option>
                              ))}
                          </Select>
                        </ChangeTrackerFormItem>
                      </Col>
                      <Col span={16}>
                        <ChangeTrackerFormItem name="spouseMainPhoneNumber">
                          <InputMask mask="(999) 999-9999" disabled={isSpouseAddressSame || isAppFormLocked}>
                            <Input size="large" />
                          </InputMask>
                        </ChangeTrackerFormItem>
                      </Col>
                    </Row>
                  </Col>
                  <Col span={8}>
                    <ChangeTrackerFormItem
                      name="spouseEmailAddress"
                      label={t.EMAIL_ADDRESS}
                      rules={[{ type: 'email', message: t.EMAIL_ADDRESS_IS_INCORRECT }]}
                    >
                      <Input size="large" disabled={isAppFormLocked} />
                    </ChangeTrackerFormItem>
                  </Col>
                </Row>
              </>
            )}
          </Panel>

          {/* Employment */}
          <Panel
            className={clientUpdatedPanels?.isEmploymentUpdatedByClient ? 'user-changed' : ''}
            header={
              <div className="ApplicationOverviewPage__panel-header" ref={refEmployment}>
                {t.EMPLOYMENT}
                {hasPanelErrors(getPanelFieldsErrors(employmentFields)) && (
                  <Icon iconName="AlertSolid" className="ApplicationFormTab__panel-header-error" />
                )}
              </div>
            }
            key={EMPLOYMENT_TAB}
            forceRender
          >
            <div className="ApplicationFormTab__header">{t.APPLICANT}</div>
            <div className="ApplicationFormTab__sub-header">
              {t.PLEASE_PROVIDE_YOUR_EXPLOYMENT_HISTORY_DURING_THE_LAST_2_YEARS}
            </div>
            <PersonalInformationEmploymentDetail
              name="employmentDetails"
              onAddressAutocomplete={handleEmployerAddressAutocomplete}
              isLocked={isAppFormLocked}
            />
            <Row gutter={20}>
              <Col span={24}>
                <ChangeTrackerFormItem
                  name="isReceivedEmploymentInsurancePastTwoYears"
                  label={t.HAVE_YOU_RECEIVED_EI_IN_THE_LAST_TWO_YEARS}
                  rules={[{ required: true }]}
                >
                  <Radio.Group
                    buttonStyle="solid"
                    optionType="button"
                    onChange={handleEIRadioChanged}
                    style={{ width: '32%' }}
                    disabled={isAppFormLocked}
                  >
                    <Radio.Button value={true}>{t.YES}</Radio.Button>
                    <Radio.Button value={false}>{t.NO}</Radio.Button>
                  </Radio.Group>
                </ChangeTrackerFormItem>
              </Col>
            </Row>

            {isRecentlyEI && (
              <PersonalInformationEIPeriod name="employmentInsurancePeriods" isLocked={isAppFormLocked} />
            )}

            {isMarried && (
              <>
                <div className="ApplicationFormTab__header">{t.SPOUSE}</div>
                <PersonalInformationEmploymentDetail
                  name="spouseEmploymentDetails"
                  onAddressAutocomplete={handleSpouseEmployerAddressAutofill}
                  isLocked={isAppFormLocked}
                />
                <Row gutter={20}>
                  <Col span={24}>
                    <ChangeTrackerFormItem
                      name="isSpouseReceivedEmploymentInsurancePastTwoYears"
                      label={t.HAVE_YOU_RECEIVED_EI_IN_THE_LAST_TWO_YEARS}
                      rules={[{ required: isJointFile }]}
                    >
                      <Radio.Group
                        buttonStyle="solid"
                        optionType="button"
                        onChange={handleSpouseEIRadioChanged}
                        style={{ width: '32%' }}
                        disabled={isAppFormLocked}
                      >
                        <Radio.Button value={true}>{t.YES}</Radio.Button>
                        <Radio.Button value={false}>{t.NO}</Radio.Button>
                      </Radio.Group>
                    </ChangeTrackerFormItem>
                  </Col>
                </Row>

                {isSpouseRecentlyEI && (
                  <PersonalInformationEIPeriod name="spouseEmploymentInsurancePeriods" isLocked={isAppFormLocked} />
                )}
              </>
            )}
          </Panel>

          {/* Financial Difficulty */}
          <Panel
            className={clientUpdatedPanels?.isFinancialDifficultyByClient ? 'user-changed' : ''}
            header={
              <div className="ApplicationOverviewPage__panel-header" ref={tabRefFinancialDifficulty}>
                {t.FINANCIAL_DIFFICULTY}
                {hasPanelErrors(getPanelFieldsErrors(financialDifficultyFields)) && (
                  <Icon iconName="AlertSolid" className="ApplicationFormTab__panel-header-error" />
                )}
              </div>
            }
            key={FINANCIAL_DIFFICULTY_TAB}
            forceRender
          >
            <div className="ApplicationFormTab__header">{t.REASONS_FOR_FINANCIAL_DIFFICULTY}</div>

            <Label className="ApplicationFormTab__label">{t.WHAT_ARE_THE_REASONS_FOR_YOUR_FINANCIAL_DIFFICULTY}</Label>

            <ChangeTrackerFormItem noStyle shouldUpdate>
              {({ getFieldError }) => (
                <ChangeTrackerFormItem
                  name="isFinancialDifficultyReason"
                  rules={[
                    {
                      validator: validateFinancialDifficultyReasonsGroup,
                      message: t.PLEASE_SELECT_AT_LEAST_1_REASON_FOR_FINANCIAL_DIFFICULTY,
                    },
                  ]}
                >
                  <Row
                    gutter={[10, 10]}
                    className={
                      getFieldError('isFinancialDifficultyReason')?.length
                        ? 'ApplicationFormTab__financial-difficulty-error-container'
                        : ''
                    }
                  >
                    <Col xl={8} xs={24}>
                      <ChangeTrackerFormItem name="hasFDReasonLossOfIncome" valuePropName="checked">
                        <CheckboxButtonStyle disabled={isAppFormLocked}>{t.LOSS_OF_INCOME}</CheckboxButtonStyle>
                      </ChangeTrackerFormItem>
                    </Col>
                    <Col xl={8} xs={24}>
                      <ChangeTrackerFormItem name="hasFDReasonMedicalReasons" valuePropName="checked">
                        <CheckboxButtonStyle disabled={isAppFormLocked}>{t.MEDICAL_REASONS}</CheckboxButtonStyle>
                      </ChangeTrackerFormItem>
                    </Col>
                    <Col xl={8} xs={24}>
                      <ChangeTrackerFormItem name="hasFDReasonBusinessFailure" valuePropName="checked">
                        <CheckboxButtonStyle disabled={isAppFormLocked}>{t.BUSINESS_FAILURE}</CheckboxButtonStyle>
                      </ChangeTrackerFormItem>
                    </Col>
                    <Col xl={8} xs={24}>
                      <ChangeTrackerFormItem name="hasFDReasonGambling" valuePropName="checked">
                        <CheckboxButtonStyle disabled={isAppFormLocked}>{t.GAMBLING}</CheckboxButtonStyle>
                      </ChangeTrackerFormItem>
                    </Col>
                    <Col xl={8} xs={24}>
                      <ChangeTrackerFormItem name="hasFDReasonDrugAlcoholAddicition" valuePropName="checked">
                        <CheckboxButtonStyle disabled={isAppFormLocked}>
                          {t.ADDICTION_OTHER_THAN_GAMBLING}
                        </CheckboxButtonStyle>
                      </ChangeTrackerFormItem>
                    </Col>
                    <Col xl={8} xs={24}>
                      <ChangeTrackerFormItem
                        name="hasFDReasonMaritalSeparationOrRelationshipBreakdown"
                        valuePropName="checked"
                      >
                        <CheckboxButtonStyle disabled={isAppFormLocked}>{t.RELATIONSHIP_BREAKDOWN}</CheckboxButtonStyle>
                      </ChangeTrackerFormItem>
                    </Col>
                    <Col xl={8} xs={24}>
                      <ChangeTrackerFormItem name="hasFDReasonTaxLiabilities" valuePropName="checked">
                        <CheckboxButtonStyle disabled={isAppFormLocked}>{t.TAX_LIABILITIES}</CheckboxButtonStyle>
                      </ChangeTrackerFormItem>
                    </Col>
                    <Col xl={8} xs={24}>
                      <ChangeTrackerFormItem name="hasFDReasonFinancialSupportOfOthers" valuePropName="checked">
                        <CheckboxButtonStyle disabled={isAppFormLocked}>
                          {t.FINANCIAL_SUPPORT_OF_OTHERS}
                        </CheckboxButtonStyle>
                      </ChangeTrackerFormItem>
                    </Col>
                    <Col xl={8} xs={24}>
                      <ChangeTrackerFormItem name="hasFDReasonStudentDebt" valuePropName="checked">
                        <CheckboxButtonStyle disabled={isAppFormLocked}>{t.STUDENT_DEBT}</CheckboxButtonStyle>
                      </ChangeTrackerFormItem>
                    </Col>
                    <Col xl={8} xs={24}>
                      <ChangeTrackerFormItem name="hasFDReasonLitigationIssues" valuePropName="checked">
                        <CheckboxButtonStyle onChange={handleLegalMattersFDReasonChecked} disabled={isAppFormLocked}>
                          {t.LEGAL_MATTERS}
                        </CheckboxButtonStyle>
                      </ChangeTrackerFormItem>
                    </Col>
                    <Col xl={8} xs={24}>
                      <ChangeTrackerFormItem name="hasFDReasonMovingOrRelocationExpenses" valuePropName="checked">
                        <CheckboxButtonStyle disabled={isAppFormLocked}>
                          {t.MOVING_RELOCATION_EXPENSES}
                        </CheckboxButtonStyle>
                      </ChangeTrackerFormItem>
                    </Col>
                    <Col xl={8} xs={24}>
                      <ChangeTrackerFormItem name="hasFDReasonFailedProposal" valuePropName="checked">
                        <CheckboxButtonStyle disabled={isAppFormLocked}>{t.FAILED_PROPOSAL}</CheckboxButtonStyle>
                      </ChangeTrackerFormItem>
                    </Col>
                    <Col xl={8} xs={24}>
                      <ChangeTrackerFormItem name="hasFDReasonFinancialMismanagement" valuePropName="checked">
                        <CheckboxButtonStyle disabled={isAppFormLocked}>
                          {t.FINANCIAL_MISMANAGEMENT}
                        </CheckboxButtonStyle>
                      </ChangeTrackerFormItem>
                    </Col>
                    <Col xl={8} xs={24}>
                      <ChangeTrackerFormItem name="hasFDReasonOther" valuePropName="checked">
                        <CheckboxButtonStyle onChange={handleOtherFDReasonChecked} disabled={isAppFormLocked}>
                          {t.OTHER}
                        </CheckboxButtonStyle>
                      </ChangeTrackerFormItem>
                    </Col>
                  </Row>
                </ChangeTrackerFormItem>
              )}
            </ChangeTrackerFormItem>

            {hasLegalMattersFDReason && (
              <Row>
                <Col span={18}>
                  <ChangeTrackerFormItem
                    name="specifyFDReasonLegalMatters"
                    label={t.LEGAL_MATTERS_PLEASE_SPECIFY}
                    rules={[{ required: true }]}
                  >
                    <TextArea size="large" maxLength={5000} disabled={isAppFormLocked} />
                  </ChangeTrackerFormItem>
                </Col>
              </Row>
            )}

            {hasOtherFDReason && (
              <Row>
                <Col span={18}>
                  <ChangeTrackerFormItem
                    name="specifiedFDReason"
                    label={t.OTHER_PLEASE_SPECIFY}
                    rules={[{ required: true }]}
                  >
                    <TextArea size="large" maxLength={5000} disabled={isAppFormLocked} />
                  </ChangeTrackerFormItem>
                </Col>
              </Row>
            )}

            <Row>
              <Col span={18}>
                <ChangeTrackerFormItem
                  name="reasonForNeedingFinancialHelp"
                  label={t.DESCRIBE_WHY_YOU_NEED_FINANCIAL_HELP}
                >
                  <TextArea size="large" disabled={isAppFormLocked} />
                </ChangeTrackerFormItem>
              </Col>
            </Row>
          </Panel>

          {/* Financial Difficulty Spouse */}
          {isJointFile ? (
            <Panel
              className={clientUpdatedPanels?.isFinancialDifficultyByClientSpouse ? 'user-changed' : ''}
              header={
                <div className="ApplicationOverviewPage__panel-header" ref={tabRefSpouseFinancialDifficulty}>
                  {t.FINANCIAL_DIFFICULTY_SPOUSE}
                  {hasPanelErrors(getPanelFieldsErrors(spouseFinancialDifficultyFields)) && (
                    <Icon iconName="AlertSolid" className="ApplicationFormTab__panel-header-error" />
                  )}
                </div>
              }
              key={SPOUSE_FINANCIAL_DIFFICULTY_TAB}
              forceRender
            >
              <div className="ApplicationFormTab__header">{t.REASONS_FOR_FINANCIAL_DIFFICULTY}</div>

              <Label className="ApplicationFormTab__label">
                {t.WHAT_ARE_THE_REASONS_FOR_YOUR_FINANCIAL_DIFFICULTY}
              </Label>

              <ChangeTrackerFormItem noStyle shouldUpdate>
                {({ getFieldError }) => (
                  <ChangeTrackerFormItem
                    name="isSpouseFinancialDifficultyReason"
                    rules={[
                      {
                        validator: validateSpouseFinancialDifficultyReasonsGroup,
                        message: t.PLEASE_SELECT_AT_LEAST_1_REASON_FOR_FINANCIAL_DIFFICULTY,
                      },
                    ]}
                  >
                    <Row
                      gutter={[10, 10]}
                      className={
                        getFieldError('isSpouseFinancialDifficultyReason')?.length
                          ? 'ApplicationFormTab__financial-difficulty-error-container'
                          : ''
                      }
                    >
                      <Col xl={8} xs={24}>
                        <ChangeTrackerFormItem name="spouseHasFDReasonLossOfIncome" valuePropName="checked">
                          <CheckboxButtonStyle disabled={isAppFormLocked}>{t.LOSS_OF_INCOME}</CheckboxButtonStyle>
                        </ChangeTrackerFormItem>
                      </Col>
                      <Col xl={8} xs={24}>
                        <ChangeTrackerFormItem name="spouseHasFDReasonMedicalReasons" valuePropName="checked">
                          <CheckboxButtonStyle disabled={isAppFormLocked}>{t.MEDICAL_REASONS}</CheckboxButtonStyle>
                        </ChangeTrackerFormItem>
                      </Col>
                      <Col xl={8} xs={24}>
                        <ChangeTrackerFormItem name="spouseHasFDReasonBusinessFailure" valuePropName="checked">
                          <CheckboxButtonStyle disabled={isAppFormLocked}>{t.BUSINESS_FAILURE}</CheckboxButtonStyle>
                        </ChangeTrackerFormItem>
                      </Col>
                      <Col xl={8} xs={24}>
                        <ChangeTrackerFormItem name="spouseHasFDReasonGambling" valuePropName="checked">
                          <CheckboxButtonStyle disabled={isAppFormLocked}>{t.GAMBLING}</CheckboxButtonStyle>
                        </ChangeTrackerFormItem>
                      </Col>
                      <Col xl={8} xs={24}>
                        <ChangeTrackerFormItem name="spouseHasFDReasonDrugAlcoholAddicition" valuePropName="checked">
                          <CheckboxButtonStyle disabled={isAppFormLocked}>
                            {t.ADDICTION_OTHER_THAN_GAMBLING}
                          </CheckboxButtonStyle>
                        </ChangeTrackerFormItem>
                      </Col>
                      <Col xl={8} xs={24}>
                        <ChangeTrackerFormItem
                          name="spouseHasFDReasonMaritalSeparationOrRelationshipBreakdown"
                          valuePropName="checked"
                        >
                          <CheckboxButtonStyle disabled={isAppFormLocked}>
                            {t.RELATIONSHIP_BREAKDOWN}
                          </CheckboxButtonStyle>
                        </ChangeTrackerFormItem>
                      </Col>
                      <Col xl={8} xs={24}>
                        <ChangeTrackerFormItem name="spouseHasFDReasonTaxLiabilities" valuePropName="checked">
                          <CheckboxButtonStyle disabled={isAppFormLocked}>{t.TAX_LIABILITIES}</CheckboxButtonStyle>
                        </ChangeTrackerFormItem>
                      </Col>
                      <Col xl={8} xs={24}>
                        <ChangeTrackerFormItem name="spouseHasFDReasonFinancialSupportOfOthers" valuePropName="checked">
                          <CheckboxButtonStyle disabled={isAppFormLocked}>
                            {t.FINANCIAL_SUPPORT_OF_OTHERS}
                          </CheckboxButtonStyle>
                        </ChangeTrackerFormItem>
                      </Col>
                      <Col xl={8} xs={24}>
                        <ChangeTrackerFormItem name="spouseHasFDReasonStudentDebt" valuePropName="checked">
                          <CheckboxButtonStyle disabled={isAppFormLocked}>{t.STUDENT_DEBT}</CheckboxButtonStyle>
                        </ChangeTrackerFormItem>
                      </Col>
                      <Col xl={8} xs={24}>
                        <ChangeTrackerFormItem name="spouseHasFDReasonLitigationIssues" valuePropName="checked">
                          <CheckboxButtonStyle
                            onChange={handleSpouseLegalMattersFDReasonChecked}
                            disabled={isAppFormLocked}
                          >
                            {t.LEGAL_MATTERS}
                          </CheckboxButtonStyle>
                        </ChangeTrackerFormItem>
                      </Col>
                      <Col xl={8} xs={24}>
                        <ChangeTrackerFormItem
                          name="spouseHasFDReasonMovingOrRelocationExpenses"
                          valuePropName="checked"
                        >
                          <CheckboxButtonStyle disabled={isAppFormLocked}>
                            {t.MOVING_RELOCATION_EXPENSES}
                          </CheckboxButtonStyle>
                        </ChangeTrackerFormItem>
                      </Col>
                      <Col xl={8} xs={24}>
                        <ChangeTrackerFormItem name="spouseHasFDReasonFailedProposal" valuePropName="checked">
                          <CheckboxButtonStyle disabled={isAppFormLocked}>{t.FAILED_PROPOSAL}</CheckboxButtonStyle>
                        </ChangeTrackerFormItem>
                      </Col>
                      <Col xl={8} xs={24}>
                        <ChangeTrackerFormItem name="spouseHasFDReasonFinancialMismanagement" valuePropName="checked">
                          <CheckboxButtonStyle disabled={isAppFormLocked}>
                            {t.FINANCIAL_MISMANAGEMENT}
                          </CheckboxButtonStyle>
                        </ChangeTrackerFormItem>
                      </Col>
                      <Col xl={8} xs={24}>
                        <ChangeTrackerFormItem name="spouseHasFDReasonOther" valuePropName="checked">
                          <CheckboxButtonStyle onChange={handleSpouseOtherFDReasonChecked} disabled={isAppFormLocked}>
                            {t.OTHER}
                          </CheckboxButtonStyle>
                        </ChangeTrackerFormItem>
                      </Col>
                    </Row>
                  </ChangeTrackerFormItem>
                )}
              </ChangeTrackerFormItem>

              {spouseHasLegalMattersFDReason && (
                <Row>
                  <Col span={18}>
                    <ChangeTrackerFormItem
                      name="spouseSpecifyFDReasonLegalMatters"
                      label={t.LEGAL_MATTERS_PLEASE_SPECIFY}
                      rules={[{ required: true }]}
                    >
                      <TextArea size="large" maxLength={5000} disabled={isAppFormLocked} />
                    </ChangeTrackerFormItem>
                  </Col>
                </Row>
              )}

              {spouseHasOtherFDReason && (
                <Row>
                  <Col span={18}>
                    <ChangeTrackerFormItem
                      name="spouseSpecifiedFDReason"
                      label={t.OTHER_PLEASE_SPECIFY}
                      rules={[{ required: true }]}
                    >
                      <TextArea size="large" maxLength={5000} disabled={isAppFormLocked} />
                    </ChangeTrackerFormItem>
                  </Col>
                </Row>
              )}

              <Row>
                <Col span={18}>
                  <ChangeTrackerFormItem
                    name="spouseReasonForNeedingFinancialHelp"
                    label={t.DESCRIBE_WHY_YOU_NEED_FINANCIAL_HELP}
                  >
                    <TextArea size="large" disabled={isAppFormLocked} />
                  </ChangeTrackerFormItem>
                </Col>
              </Row>
            </Panel>
          ) : null}

          {/* Dependants */}
          <Panel
            className={clientUpdatedPanels?.isDependantsUpdatedByClient ? 'user-changed' : ''}
            header={
              <div className="ApplicationOverviewPage__panel-header" ref={refDependants}>
                {t.DEPENDANTS}
                {hasPanelErrors(getPanelFieldsErrors(dependantsFields)) && (
                  <Icon iconName="AlertSolid" className="ApplicationFormTab__panel-header-error" />
                )}
              </div>
            }
            key={DEPENDANTS_TAB}
            forceRender
          >
            <div className="ApplicationFormTab__header">{t.APPLICANT_HOUSEHOLD}</div>

            <Row gutter={20}>
              <Col span={24}>
                <ChangeTrackerFormItem
                  name="numberOfPersonsInHousehold"
                  label={t.PERSONS_IN_THE_HOUSEHOLD_UNIT_INCLUDING_APPLICANT}
                  rules={[{ required: true }]}
                >
                  <InputNumber size="large" min={1} max={99} style={{ width: '32%' }} disabled={isAppFormLocked} />
                </ChangeTrackerFormItem>
              </Col>
            </Row>

            <Row gutter={20}>
              <Col span={24}>
                <ChangeTrackerFormItem name="hasDependants" label={t.ANY_DEPENDANT_CHILDREN_AND_OR_OTHER_DEPENDANTS}>
                  <Radio.Group
                    size="large"
                    onChange={handleDependantsRadioChanged}
                    buttonStyle="solid"
                    optionType="button"
                    style={{ width: '32%' }}
                    disabled={isAppFormLocked}
                  >
                    <Radio.Button value={true}>{t.YES}</Radio.Button>
                    <Radio.Button value={false}>{t.NO}</Radio.Button>
                  </Radio.Group>
                </ChangeTrackerFormItem>
              </Col>
            </Row>

            {hasDependants && (
              <Form.List name="dependants">
                {(dependants, { add, remove }) => (
                  <>
                    {dependants?.map((row, index) => (
                      <>
                        <Row gutter={20} align="middle" key={`dependants-${index}`}>
                          <Col span={8}>
                            <Form.Item name={[row.name, 'id']} hidden>
                              <Input disabled={isAppFormLocked} />
                            </Form.Item>
                            <ChangeTrackerFormItem
                              name={[row.name, 'firstName']}
                              className="ApplicationFormTab__input-label-warning"
                              label={t.DEPENDANT_FIRST_NAME}
                              rules={[{ required: true, warningOnly: true, message: t.RECOMMENDED_FIELD }]}
                              listName="dependants"
                            >
                              <Input size="large" disabled={isAppFormLocked} />
                            </ChangeTrackerFormItem>
                          </Col>
                          <Col span={8}>
                            <ChangeTrackerFormItem
                              name={[row.name, 'lastName']}
                              className="ApplicationFormTab__input-label-warning"
                              label={t.DEPENDANT_LAST_NAME}
                              rules={[{ required: true, warningOnly: true, message: t.RECOMMENDED_FIELD }]}
                              listName="dependants"
                            >
                              <Input size="large" disabled={isAppFormLocked} />
                            </ChangeTrackerFormItem>
                          </Col>
                          <Col span={8}>
                            <ChangeTrackerFormItem
                              name={[row.name, 'relationshipTypeId']}
                              label={t.RELATIONSHIP}
                              className="ApplicationFormTab__input-label-warning"
                              rules={[
                                {
                                  required: true,
                                  warningOnly: true,
                                  message: t.RECOMMENDED_FIELD,
                                },
                              ]}
                              listName="dependants"
                            >
                              <Select
                                loading={relationshipTypesLoading}
                                suffixIcon={<SelectSuffixIcon />}
                                size="large"
                                disabled={isAppFormLocked}
                              >
                                {relationshipTypes?.map((option) => (
                                  <Option key={option.id} value={option?.id}>
                                    {option?.name}
                                  </Option>
                                ))}
                              </Select>
                            </ChangeTrackerFormItem>
                          </Col>
                          <Col span={8}>
                            <ChangeTrackerFormItem
                              name={[row.name, 'birthDate']}
                              className="ApplicationFormTab__input-label-warning"
                              label={t.DATE_OF_BIRTH}
                              rules={[
                                {
                                  validator: async (_: any, value?: any) =>
                                    value ? Promise.resolve() : Promise.reject(),
                                  warningOnly: true,
                                  message: t.RECOMMENDED_FIELD,
                                  validateTrigger: 'onChange',
                                },
                              ]}
                              listName="dependants"
                            >
                              <DatePicker
                                size="large"
                                disabledDate={antdUtil.futureDates}
                                format={DATE_FORMAT2}
                                placeholder={DATE_FORMAT_PLACEHOLDER}
                                disabled={isAppFormLocked}
                              />
                            </ChangeTrackerFormItem>
                          </Col>
                          <Col span={15}></Col>
                          <Col span={isAppFormLocked ? 0 : 1} style={{ textAlign: 'right' }}>
                            <DeleteOutlined
                              className="ApplicationFormTab__input-action-icon"
                              onClick={() => remove(row.name)}
                            />
                          </Col>
                        </Row>
                        {(dependants?.length || 0) - 1 !== index && <Divider />}
                      </>
                    ))}
                    {!isAppFormLocked && (
                      <Row gutter={20}>
                        <Col span={8}>
                          <Form.Item noStyle shouldUpdate>
                            {({ getFieldValue, getFieldsError }) => (
                              <div
                                onClick={() => add()}
                                className="ApplicationFormTab__link-left"
                                style={{
                                  marginTop: getFieldsError([
                                    ['dependants', getFieldValue('dependants')?.length - 1, 'birthDate'],
                                  ])?.[0]?.warnings?.length
                                    ? 12
                                    : -12,
                                }}
                              >
                                {t.ADD_DEPENDANT}
                              </div>
                            )}
                          </Form.Item>
                        </Col>
                      </Row>
                    )}
                  </>
                )}
              </Form.List>
            )}
          </Panel>

          {/* Previous Insolvency */}
          <Panel
            className={clientUpdatedPanels?.isPreviousInsolvencyUpdatedByClient ? 'user-changed' : ''}
            header={
              <div className="ApplicationOverviewPage__panel-header" ref={refPreviousInsolvency}>
                {t.PREVIOUS_INSOLVENCIES}
                {hasPanelErrors(getPanelFieldsErrors(previousInsolvenciesFields)) && (
                  <Icon iconName="AlertSolid" className="ApplicationFormTab__panel-header-error" />
                )}
              </div>
            }
            key={PREVIOUS_INSOLVENCIES_TAB}
            forceRender
          >
            <div className="ApplicationFormTab__header">{t.APPLICANT}</div>
            <Row>
              <Col span={24}>
                <ChangeTrackerFormItem
                  name="hasPreviousInsolvency"
                  label={t.HAVE_YOU_PREVIOUSLY_FILED_FOR_INSOLVENCY_IN_CANADA_OR_ELSEWHERE}
                  rules={[{ required: true }]}
                >
                  <Radio.Group
                    size="large"
                    onChange={handlePreviousInsolvencyRadioChanged}
                    buttonStyle="solid"
                    optionType="button"
                    style={{ width: '32%' }}
                    disabled={isAppFormLocked}
                  >
                    <Radio.Button value={true}>{t.YES}</Radio.Button>
                    <Radio.Button value={false}>{t.NO}</Radio.Button>
                  </Radio.Group>
                </ChangeTrackerFormItem>
              </Col>
            </Row>

            {hasPreviousInsolvency && (
              <PersonalInformationPreviousInsolvency
                form={form}
                name="previousInsolvencies"
                isLocked={isAppFormLocked}
              />
            )}

            {isMarried && isJointFile && (
              <>
                <div className="ApplicationFormTab__header">{t.SPOUSE_PARTNER}</div>

                <Col span={24}>
                  <ChangeTrackerFormItem
                    name="hasSpousePreviousInsolvency"
                    label={t.HAVE_YOU_PREVIOUSLY_FILED_FOR_INSOLVENCY_IN_CANADA_OR_ELSEWHERE}
                    rules={[{ required: true }]}
                  >
                    <Radio.Group
                      size="large"
                      onChange={handleSpousePreviousInsolvencyRadioChanged}
                      buttonStyle="solid"
                      optionType="button"
                      style={{ width: '32%' }}
                      disabled={isAppFormLocked}
                    >
                      <Radio.Button value={true}>{t.YES}</Radio.Button>
                      <Radio.Button value={false}>{t.NO}</Radio.Button>
                    </Radio.Group>
                  </ChangeTrackerFormItem>
                </Col>

                {hasSpousePreviousInsolvency && (
                  <PersonalInformationPreviousInsolvency
                    form={form}
                    name="spousePreviousInsolvencies"
                    isLocked={isAppFormLocked}
                  />
                )}
              </>
            )}
          </Panel>
        </Collapse>

        <Row justify="end" gutter={20} className="ApplicationFormTab__buttons-container">
          <Col>
            <Button
              padding="large"
              disabled={
                !needToBeReviewed ||
                !clientUpdatedPanels ||
                Object.values(clientUpdatedPanels)?.filter(Boolean)?.length !== 0 ||
                isAppFormLocked
              }
              onClick={markReviewed}
            >
              {t.MARK_AS_REVIEWED}
            </Button>
          </Col>
          <Col>
            <Button padding="large" disabled={!isReviewed || isAppFormLocked} onClick={onMarkCompleteClicked}>
              {t.MARK_AS_COMPLETED}
            </Button>
          </Col>
          <Col>
            <Button
              kind="cancel"
              padding="large"
              disabled={isAppFormLocked || isLoading}
              onClick={debounceWithTimeOut(saveAndContinue, 2000)}
            >
              {t.CONTINUE}
            </Button>
          </Col>
        </Row>
      </Form>
    </Spin>
  );
};

export default PersonalInformation;
