import { Row, Col } from 'antd';
import { ClientService } from '../../../../shared/api/ClientService';

import ResultsRow, { IResultsRow } from './ResultsRow';
import useLocale from '../../../../hooks/useLocale';

import '../FileAnalysis.scss';

function EstimatedRealizationsTable({
  calculations,
}: {
  calculations?: ClientService.FileAnalysisEstimatedRealizationsDto;
}) {
  const { t } = useLocale();
  const bankruptcyDataSource: IResultsRow[] = [
    {
      name: t.BANKRUPTCY,
      type: 'subtitle',
      style: { paddingTop: 6, paddingBottom: 6 },
    },
    {
      name: t.REALIZATION_FROM_ASSETS,
      type: 'item',
      secondColCellValue: ' ',
    },
    {
      name: t.FURNITURE,
      secondColCellValue: calculations?.bankruptcy?.furniture,
      type: 'subitem',
    },
    {
      name: t.PERSONAL_EFFECTS,
      secondColCellValue: calculations?.bankruptcy?.personalEffects,
      type: 'subitem',
    },
    {
      name: t.CASH_ON_HAND,
      secondColCellValue: calculations?.bankruptcy?.cashOnHand,
      type: 'subitem',
    },
    {
      name: t.POLICIES_PENSIONS_ETC,
      secondColCellValue: calculations?.bankruptcy?.policiesPensionsRRSPTotal,
      type: 'subitem',
    },
    {
      name: t.SECURITIES,
      secondColCellValue: calculations?.bankruptcy?.securitiesTotal,
      type: 'subitem',
    },
    ...(calculations?.bankruptcy?.realEstateTotals || []).map(
      (item) =>
        ({
          name: `${t.REAL_ESTATE} - ${item?.address || 'N/A'} ${item?.unit ? `${t.UNIT} ${item?.unit}` : ''}`,
          secondColCellValue: item?.realEstateTotal,
          type: 'subitem',
        } as IResultsRow)
    ),
    {
      name: t.MOTOR_VEHICLES,
      secondColCellValue: calculations?.bankruptcy?.motorVehiclesTotal,
      type: 'subitem',
    },
    {
      name: t.RECREATIONAL_EQUIPMENT,
      secondColCellValue: calculations?.bankruptcy?.recreationalEquipmentTotal,
      type: 'subitem',
    },
    {
      name: t.TAX_REFUNDS,
      secondColCellValue: calculations?.bankruptcy?.taxRefundsAssetsTotal,
      type: 'subitem',
    },
    {
      name: t.IMPACT_TO_RECOVERY,
      secondColCellValue: calculations?.bankruptcy?.impactToRecoveryTotal,
      type: 'subitem',
    },
    {
      name: t.OTHER_TOOLS_OF_TRADE,
      secondColCellValue: calculations?.bankruptcy?.toolsOfTradeTotal,
      type: 'subitem',
    },
    {
      name: t.OTHER_HUNTING_FISHING_FARMING_TOOLS,
      secondColCellValue: calculations?.bankruptcy?.huntingFishingFarmingTotal,
      type: 'subitem',
    },
    {
      name: t.SURPLUS_INCOME_CONTRIBUTIONS,
      secondColCellValue: calculations?.bankruptcy?.surplusIncomeContributions || '-',
      type: 'item',
    },
    {
      name: t.OTHER_CONTRIBUTIONS_BY_DEBTOR,
      secondColCellValue: calculations?.bankruptcy?.otherContributionsByDebtor || '-',
      type: 'item',
    },
  ];

  const proposalDataSource: IResultsRow[] = [
    {
      name: t.PROPOSAL,
      type: 'subtitle',
      style: { paddingTop: 6, paddingBottom: 6 },
    },
    {
      name: t.TOTAL_PROPOSAL_PAYMENTS,
      firstColCellValue: calculations?.proposal?.totalProposalPayments || '-',
      type: 'item',
    },
  ];

  const receiptsDataSource: IResultsRow[] = [
    {
      name: t.ESTIMATED_RECEIPTS,
      type: 'subtitle',
      firstColCellValue: calculations?.proposal?.estimatedReceipts || '-',
      secondColCellValue: calculations?.bankruptcy?.estimatedReceipts || '-',
      style: { paddingTop: 6, paddingBottom: 6 },
    },
  ];

  const administrationCostDataSource: IResultsRow[] = [
    {
      name: t.COST_OF_ADMINISRATION,
      type: 'subtitle',
      style: { paddingTop: 6, paddingBottom: 6 },
    },
    {
      name: t.FILING_FEES,
      firstColCellValue: calculations?.proposal?.filingFee || '-',
      secondColCellValue: calculations?.bankruptcy?.filingFee || '-',
      type: 'subitem',
    },
    {
      name: t.ADMINISTRATIVE_DESBURSEMENTS,
      firstColCellValue: calculations?.proposal?.administrativeDisbursements || '-',
      secondColCellValue: calculations?.bankruptcy?.administrativeDisbursements || '-',
      type: 'subitem',
    },
    {
      name: t.ADMINISTRATOR_TRUSTEE_FEES,
      firstColCellValue: calculations?.proposal?.administratorTrusteeFee || '-',
      secondColCellValue: calculations?.bankruptcy?.administratorTrusteeFee || '-',
      type: 'subitem',
    },
    {
      name: t.LEGAL_FEES,
      firstColCellValue: calculations?.proposal?.legalFee || '-',
      secondColCellValue: calculations?.bankruptcy?.legalFee || '-',
      type: 'subitem',
    },
    {
      name: t.OTHER_COST_ORDINARY_BANKRUPTCY,
      firstColCellValue: calculations?.proposal?.otherCostInOrdinaryBankruptcy || '-',
      secondColCellValue: calculations?.bankruptcy?.otherCostInOrdinaryBankruptcy || '-',
      type: 'subitem',
    },
    {
      name: `${calculations?.proposal?.taxTypeLabel || t.TAX}`,
      firstColCellValue: calculations?.proposal?.gst || '-',
      secondColCellValue: calculations?.bankruptcy?.gst || '-',
      type: 'subitem',
    },
    {
      name: t.ESTIMATED_DISBURSEMENTS,
      firstColCellValue: calculations?.proposal?.estimatedDisbursements || '-',
      secondColCellValue: calculations?.bankruptcy?.estimatedDisbursements || '-',
      type: 'total',
      style: { paddingTop: 6, paddingBottom: 6 },
    },
  ];

  const estimatedAmountAvailableForDisbursementsDataSource: IResultsRow[] = [
    {
      name: t.ESTIMATED_AMOUNT_AVAILABLE_FOR_DISTRIBUTION,
      firstColCellValue: calculations?.proposal?.estimatedAmountAvailableForDisbursements || '-',
      secondColCellValue: calculations?.bankruptcy?.estimatedAmountAvailableForDisbursements || '-',
      type: 'subtitle',
    },
  ];

  const estimatedClaimsOfPreferredCreditorsDataSource: IResultsRow[] = [
    {
      name: t.ESTIMATED_CLAIMS_PREFERRED_CREDITORS,
      firstColCellValue: calculations?.proposal?.estimatedClaimsOfPreferredCreditors || '-',
      secondColCellValue: calculations?.bankruptcy?.estimatedClaimsOfPreferredCreditors || '-',
      type: 'subtitle',
    },
  ];

  const estimatedClaimsOfOrdinaryCreditorsDataSource: IResultsRow[] = [
    {
      name: t.ESTIMATED_CLAIMS_ORDINARY_CREDITORS,
      firstColCellValue: calculations?.proposal?.estimatedClaimsOfOrdinaryCreditors || '-',
      secondColCellValue: calculations?.bankruptcy?.estimatedClaimsOfOrdinaryCreditors || '-',
      type: 'subtitle',
    },
  ];

  const estimatedClaimsOfSecuredCreditorsDataSource: IResultsRow[] = [
    {
      name: t.ESTIMATED_CLAIMS_SECURED_CREDITORS,
      firstColCellValue: calculations?.proposal?.estimatedClaimsOfSecuredCreditors || '-',
      secondColCellValue: calculations?.bankruptcy?.estimatedClaimsOfSecuredCreditors || '-',
      type: 'subtitle',
    },
  ];

  const estimatedDistributionDataSource: IResultsRow[] = [
    {
      name: t.ESTIMATED_DISTRIBUTION,
      type: 'subtitle',
    },
    {
      name: t.SUPERINTENDENTS_LEVY,
      firstColCellValue: calculations?.proposal?.superintendentsLevy || '-',
      secondColCellValue: calculations?.bankruptcy?.superintendentsLevy || '-',
      type: 'subitem',
    },
    {
      name: t.DIVIDEND_TO_PREFERRED_CREDITORS,
      firstColCellValue: calculations?.proposal?.dividendToPreferredCreditors || '-',
      secondColCellValue: calculations?.bankruptcy?.dividendToPreferredCreditors || '-',
      type: 'subitem',
    },
    {
      name: t.DIVIDEND_TO_ORDINARY_CREDITORS,
      firstColCellValue: calculations?.proposal?.dividendToOrdinaryCreditors || '-',
      secondColCellValue: calculations?.bankruptcy?.dividendToOrdinaryCreditors || '-',
      type: 'subitem',
    },
    {
      name: t.DIVIDEND_TO_SECURED_CREDITOR,
      firstColCellValue: calculations?.proposal?.dividendToSecuredCreditors || '-',
      secondColCellValue: calculations?.bankruptcy?.dividendToSecuredCreditors || '-',
      type: 'subitem',
    },
    {
      name: t.TOTAL_DIVIDEND_AND_LEVY,
      firstColCellValue: calculations?.proposal?.totalDividendAndLevy || '-',
      secondColCellValue: calculations?.bankruptcy?.totalDividendAndLevy || '-',
      type: 'total',
      style: { paddingTop: 6, paddingBottom: 6 },
    },
  ];

  const estimatedDistributionToPreferredCreditorsDataSource: IResultsRow[] = [
    {
      name: t.ESTIMATED_DISTRIBUTION_TO_PREFERRED_CREDITORS,
      firstColCellValue: `${calculations?.proposal?.estimatedDistributionToPreferredCreditors || 0}%`,
      secondColCellValue: `${calculations?.bankruptcy?.estimatedDistributionToPreferredCreditors || 0}%`,
      type: 'subtitle',
    },
  ];

  const estimatedDistributionToOrdinaryCreditorsDataSource: IResultsRow[] = [
    {
      name: t.ESTIMATED_DISTRIBUTION_TO_ORDINARY_CREDITORS,
      firstColCellValue: `${calculations?.proposal?.estimatedDistributionToOrdinaryCreditors || 0}%`,
      secondColCellValue: `${calculations?.bankruptcy?.estimatedDistributionToOrdinaryCreditors || 0}%`,
      type: 'subtitle',
    },
  ];

  const estimatedDistributionToSecuredCreditorsDataSource: IResultsRow[] = [
    {
      name: t.ESTIMATED_DISTRIBUTION_TO_SECURED_CREDITORS,
      firstColCellValue: `${calculations?.proposal?.estimatedDistributionToSecuredCreditors || 0}%`,
      secondColCellValue: `${calculations?.bankruptcy?.estimatedDistributionToSecuredCreditors || 0}%`,
      type: 'subtitle',
    },
  ];

  const allocationAmountAvailableDataSource: IResultsRow[] = [
    {
      name: t.ALLOCATION_OF_AMOUNT_AVAILABLE_FOR_DISTRIBUTION,
      type: 'subtitle',
    },
    {
      name: t.PREFERRED_CREDITORS,
      firstColCellValue: calculations?.proposal?.allocationAmountAvailablePreferredCreditors || '-',
      secondColCellValue: calculations?.bankruptcy?.allocationAmountAvailablePreferredCreditors || '-',
      type: 'subitem',
    },
    {
      name: t.ORDINARY_CREDITORS,
      firstColCellValue: calculations?.proposal?.allocationAmountAvailableOrdinaryCreditors || '-',
      secondColCellValue: calculations?.bankruptcy?.allocationAmountAvailableOrdinaryCreditors || '-',
      type: 'subitem',
    },
    {
      name: t.SECURED_CREDITORS,
      firstColCellValue: calculations?.proposal?.allocationAmountAvailableSecuredCreditors || '-',
      secondColCellValue: calculations?.bankruptcy?.allocationAmountAvailableSecuredCreditors || '-',
      type: 'subitem',
    },
    {
      name: t.EXCESS_FUNDS,
      firstColCellValue: calculations?.proposal?.excessFunds || '-',
      secondColCellValue: calculations?.bankruptcy?.excessFunds || '-',
      type: 'subitem',
    },
    {
      name: t.TOTAL_ALLOCATION_OF_AMOUNT_AVAILABLE_FOR_DISTRIBUTION,
      secondColCellValue: calculations?.bankruptcy?.allocationAmountAvailableTotal,
      type: 'total',
      style: { paddingTop: 6, paddingBottom: 6 },
    },
  ];

  return (
    <div className="FileAnalysis__results--container-striped">
      <Row align="stretch">
        <Col offset={12} span={6} className="FileAnalysis__results--container-striped--header">
          <div>
            <b>{t.CONSUMER_PROPOSAL}</b>
          </div>
          <div>{calculations?.proposal?.proposalType}</div>
        </Col>
        <Col span={6} className="FileAnalysis__results--container-striped--header">
          <div>
            <b>{t.BANKRUPTCY}</b>
          </div>
          <div>{calculations?.bankruptcy?.bankruptcyType}</div>
        </Col>
      </Row>
      {bankruptcyDataSource.map(
        (item) =>
          Boolean(item?.firstColCellValue || item?.secondColCellValue || item.type === 'subtitle') && (
            <ResultsRow {...item} key={item.name} firstCellSpan={5} secondCellSpan={6} />
          )
      )}
      <ResultsRow type="empty" />
      {proposalDataSource.map((item) => (
        <ResultsRow {...item} key={item.name} firstCellSpan={5} secondCellSpan={6} />
      ))}
      <ResultsRow type="empty" />
      {receiptsDataSource.map((item) => (
        <ResultsRow {...item} key={item.name} firstCellSpan={5} secondCellSpan={6} />
      ))}
      <ResultsRow type="empty" />
      {administrationCostDataSource.map((item) => (
        <ResultsRow {...item} key={item.name} firstCellSpan={5} secondCellSpan={6} />
      ))}
      <ResultsRow type="empty" />
      {estimatedAmountAvailableForDisbursementsDataSource.map((item) => (
        <ResultsRow {...item} key={item.name} firstCellSpan={5} secondCellSpan={6} />
      ))}
      <ResultsRow type="empty" />
      {estimatedClaimsOfPreferredCreditorsDataSource.map((item) => (
        <ResultsRow {...item} key={item.name} firstCellSpan={5} secondCellSpan={6} />
      ))}
      {estimatedClaimsOfOrdinaryCreditorsDataSource.map((item) => (
        <ResultsRow {...item} key={item.name} firstCellSpan={5} secondCellSpan={6} />
      ))}
      {estimatedClaimsOfSecuredCreditorsDataSource.map((item) => (
        <ResultsRow {...item} key={item.name} firstCellSpan={5} secondCellSpan={6} />
      ))}
      <ResultsRow type="empty" />
      {estimatedDistributionDataSource.map((item) => (
        <ResultsRow {...item} key={item.name} firstCellSpan={5} secondCellSpan={6} />
      ))}
      <ResultsRow type="empty" />
      {estimatedDistributionToPreferredCreditorsDataSource.map((item) => (
        <ResultsRow {...item} key={item.name} firstCellSpan={5} secondCellSpan={6} />
      ))}
      {estimatedDistributionToOrdinaryCreditorsDataSource.map((item) => (
        <ResultsRow {...item} key={item.name} firstCellSpan={5} secondCellSpan={6} />
      ))}
      {estimatedDistributionToSecuredCreditorsDataSource.map((item) => (
        <ResultsRow {...item} key={item.name} firstCellSpan={5} secondCellSpan={6} />
      ))}
      <ResultsRow type="empty" />
      {allocationAmountAvailableDataSource.map((item) => (
        <ResultsRow {...item} key={item.name} firstCellSpan={5} secondCellSpan={6} />
      ))}
    </div>
  );
}

export default EstimatedRealizationsTable;
