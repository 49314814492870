import { ClientService } from '../../../shared/api/ClientService';

import { ICourt } from './types';

export const convertCourtsToDataSource = (courts?: ICourt[]) =>
  courts?.sort((a, b) => Number(a?.courtNumber) - Number(b?.courtNumber));

export const filterDataSourceBySearchValue = (
  value?: string,
  dataSource?: ICourt[],
  agents?: ClientService.LookupDto[]
) => {
  const target = value?.toLowerCase();
  return target
    ? dataSource?.filter(
        (item) =>
          item?.courtNumber?.toLowerCase()?.includes(target) ||
          item?.courtName?.toLowerCase()?.includes(target) ||
          item?.courtCity?.toLowerCase()?.includes(target) ||
          item?.trusteeIds?.find((id) =>
            agents
              ?.find((agent) => agent?.id === id)
              ?.name?.toLowerCase()
              ?.includes(target)
          )
      )
    : dataSource;
};

export const convertCourtToReadableOption = (courtItem: ICourt) =>
  `${courtItem?.courtNumber || ''}${
    courtItem?.courtNumber && (courtItem?.courtName || courtItem?.courtCity) ? ' - ' : ''
  }${courtItem?.courtName || ''}${courtItem?.courtName && courtItem?.courtCity ? ' - ' : ''}${
    courtItem?.courtCity || ''
  }`;
