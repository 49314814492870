import { useCallback, useEffect, useState } from 'react';
import { Table, Spin } from 'antd';

import EditableRow from './EditableRow';
import EditableCell from './EditableCell';
import { Col, Row } from 'antd';
import Button from '../../../../../components/Button/Button';

import useLocale from '../../../../../hooks/useLocale';
import { IDataProps, ColumnType, InputTypeEnum, IConfigurationType } from '../../types';
import { convertCellValueToReadable } from '../../utils';

import './EditableTable.scss';

interface IProps {
  isEditable?: boolean;
  data?: IDataProps<IConfigurationType>[];
  loading?: boolean;
  defaultColumns?: ColumnType[];
  onSave?: (dataSource?: IDataProps<IConfigurationType>[]) => void;
  onCancel?: () => void;
}

const EditableTable = ({ isEditable, data, loading = false, defaultColumns, onSave, onCancel }: IProps) => {
  const { t } = useLocale();
  const [dataSource, setDataSource] = useState<IDataProps<IConfigurationType>[]>();

  useEffect(() => {
    setDataSource(data);
  }, [data]);

  const handleSaveCellChanges = useCallback(
    (row: IDataProps<IConfigurationType>) => {
      if (!dataSource) return;
      const newData = [...dataSource];
      const index = newData.findIndex((item) => {
        return row.key === item.key;
      });
      const item = newData[index];

      newData.splice(index, 1, {
        ...item,
        ...row,
      });
      setDataSource(newData);
    },
    [dataSource]
  );

  const handleSaveTableChanges = useCallback(async () => {
    if (onSave) {
      onSave(dataSource);
    }
  }, [dataSource, onSave]);

  const handleCancelTableChanges = useCallback(() => {
    if (onCancel) onCancel();
  }, [onCancel]);

  const components = {
    body: {
      row: EditableRow,
      cell: EditableCell,
    },
  };

  const columns = defaultColumns?.map((col) => {
    const column = {
      ...col,
      render: (value: string | number) => <>{convertCellValueToReadable(value, col?.type)}</>,
    };

    if (!isEditable) {
      return column;
    }

    return {
      ...column,
      onCell: (record: IDataProps<IConfigurationType>) => ({
        record,
        editable: col?.type !== InputTypeEnum.ReadOnly ? isEditable : false,
        dataIndex: col?.dataIndex,
        title: col?.title,
        type: col?.type,
        options: col?.options,
        precision: col?.precision,
        handleSave: handleSaveCellChanges,
      }),
    };
  });

  return (
    <Spin spinning={loading}>
      <Table
        components={components}
        className="EditableTable"
        rowClassName="EditableTable__editable-row"
        dataSource={dataSource}
        columns={columns}
        pagination={false}
      />
      {isEditable && (
        <Row align="middle" justify="end" gutter={20} className="EditableTable__buttons-container">
          <Col>
            <Button kind="cancel" onClick={handleCancelTableChanges}>
              {t.CANCEL}
            </Button>
          </Col>
          <Col>
            <Button
              kind="primary"
              onClick={handleSaveTableChanges}
              disabled={Boolean(dataSource?.find((i) => i.hasError))}
            >
              {t.SAVE}
            </Button>
          </Col>
        </Row>
      )}
    </Spin>
  );
};

export default EditableTable;
