import { Form } from 'antd';
import { useQuery } from 'react-query';
import moment from 'moment';

import { Icon } from '@fluentui/react/lib/Icon';
import { MEETING_TYPES_QUERY } from '../../../../constants/reactQuery';

import { getAgentByPreselectRuleForAppointment } from '../../utils';
import { ISelectedTimeslot } from '../../types';
import { ClientService } from '../../../../shared/api/ClientService';

import API from '../../../../utils/api';
import './AvailableTimeslots.scss';
import useLocale from '../../../../hooks/useLocale';

interface IProps {
  meetingTypeEnum: ClientService.MeetingTypeEnum;
  timeslot?: ClientService.AppointmentTimeSlotDto;
  disabled?: boolean;
}

/**
 * Work for TimeslotRow component only.
 * Work for Appointment Schedule page.
 */
const RowItem = ({ meetingTypeEnum, timeslot, disabled = false }: IProps) => {
  const { data: meetingTypes } = useQuery([MEETING_TYPES_QUERY], () => API.listMeetingTypes());
  const { t } = useLocale();

  return (
    <Form.Item noStyle shouldUpdate>
      {({ getFieldValue, setFieldsValue }) => {
        const selectedTimeslot = getFieldValue('selectedTimeslot') as ISelectedTimeslot;
        const timeslotMeetingTypeEnum = meetingTypes?.find(
          (item) => item?.id === selectedTimeslot?.timeslotMeetingTypeId
        )?.enumValue;

        const isSelected =
          moment(selectedTimeslot?.time)?.isSame(moment(timeslot?.time)) && timeslotMeetingTypeEnum === meetingTypeEnum;
        const meetingTypeId = meetingTypes?.find((item) => item?.enumValue === meetingTypeEnum)?.id;

        const iconName = meetingTypeEnum === ClientService.MeetingTypeEnum.Phone ? 'Phone' : 'Contact';
        const text = meetingTypeEnum === ClientService.MeetingTypeEnum.Phone ? t.PHONE_VIDEO : t.IN_PERSON;

        return (
          <div
            onClick={() => {
              if (disabled) return;
              setFieldsValue({
                selectedTimeslot: {
                  ...timeslot,
                  timeslotMeetingTypeId: meetingTypeId,
                },
                agentId: getAgentByPreselectRuleForAppointment(timeslot?.availableAgents, meetingTypeId),
              });
            }}
            className={`AvailableTimeslots__option-button ${
              isSelected ? 'AvailableTimeslots__option-button-active' : ''
            } ${disabled ? 'AvailableTimeslots__option-button-disabled' : ''}`}
          >
            <Icon iconName={iconName} />
            {text}
          </div>
        );
      }}
    </Form.Item>
  );
};

export default RowItem;
