import { useState, useEffect, useCallback } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import moment from 'moment';

import Layout from '../../components/Layout/Layout';
import PageTitle from '../../components/PageTitle/PageTitle';
import NewAndViewHeader from '../ApplicationOverviewPage/NewAndViewPageComponents/NewAndViewHeader/NewAndViewHeader';
import { ROUTES } from '../../constants/routes';
import useLocale from '../../hooks/useLocale';
import AppForm from '../../components/Forms/AppForm/AppForm';
import SearchAscend, {
  IAscendFormValues,
} from '../ApplicationOverviewPage/NewAndViewPageComponents/SearchAscend/SearchAscend';
import NewTaxReturnFields, {
  IAscendTaxReturnValues,
} from '../ApplicationOverviewPage/NewAndViewPageComponents/SearchAscend/VariableFieldGroups/NewTaxReturnFields';
import EmailContentDisplay, {
  TEmailData,
} from '../ApplicationOverviewPage/NewAndViewPageComponents/EmailContentDisplay/EmailContentDisplay';
import DataItem from '../../components/DataItem/DataItem';
import ThreeColumnLowerLayout from '../ApplicationOverviewPage/NewAndViewPageComponents/ThreeColumnLowerLayout/ThreeColumnLowerLayout';
import ErrorsContainer, { IErrorsMsgAndType } from '../../components/ErrorsContainer/ErrorsContainer';
import { ClientService } from '../../shared/api/ClientService';
import API from '../../utils/api';
import Loading from '../../components/Loading/Loading';
import {
  getUndefinedOrSelf,
  convertServerDateOnlyToInputDate,
  convertInputDateToServerDateOnly,
} from '../../utils/helpers';
import { ISearchAscendFileCriteria } from '../ApplicationOverviewPage/NewAndViewPageComponents/types';
import { argumentifyAscendFileSearchCriteria } from '../ApplicationOverviewPage/NewAndViewPageComponents/utils';
import MarkAsActiveRadioSelection from '../ApplicationOverviewPage/NewAndViewPageComponents/SearchAscend/AscendConfirmActionModal/MarkAsActiveRadioSelection/MarkAsActiveRadioSelection';
import { ISelectOption } from '../../components/Forms/FormInput/SelectInput';
import useErrorHandling from '../../hooks/useErrorHandling';
import useAsyncModalForm from '../../hooks/useAsyncModalForm';
import { IGenericObject } from '../../types/IGenericObject';
import ButtonsGroupCancelDraftRedundant from '../ApplicationOverviewPage/NewAndViewPageComponents/NewAndViewHeader/HeaderButtonGroups/ButtonsGroupCancelDraftRedundant';
import useAlertAsAsync from '../ApplicationOverviewPage/NewAndViewPageComponents/AlertModal/useAlertAsAsync';
import genericMessage from '../../utils/genericMessage';
import { TAX_PROCESS_TAB } from '../ApplicationOverviewPage/ApplicationOverviewContent/AdminInfo/AdminInfoTab';
import { ADMIN_INFO_TAB } from '../ApplicationOverviewPage/ApplicationOverviewContent/ApplicationOverviewContent';

import styles from './NewTaxReturnPage.module.scss';

type TaxReturnAscendFormValues = IAscendFormValues & IAscendTaxReturnValues;

const NewTaxReturnPage = () => {
  const { t } = useLocale();
  const { taskActionId } = useParams();
  const { getFromAsyncModalForm } = useAsyncModalForm();
  const { handleAlert } = useAlertAsAsync();
  const navigate = useNavigate();

  const [form] = AppForm.AntD.useForm<any>();
  const { processResponseForErrors } = useErrorHandling();

  const [pageErrors, setPageErrors] = useState<IErrorsMsgAndType[] | undefined>();
  const [taxReturnResponseFailed, setTaxReturnResponseFailed] = useState<boolean>(false);
  const [taxReturnResponse, setTaxReturnResponse] = useState<ClientService.AppTaskActionDto | undefined>();
  const [newTaxReturnFormValues, setNewTaxReturnFormValues] = useState<TaxReturnAscendFormValues | undefined>();
  const [initialFormValuesHaveBeenSet, setInitialFormValuesHaveBeenSet] = useState<boolean>(false);
  const [formIsLoading, setFormIsLoading] = useState<boolean>(true);
  const [emailInfo, setEmailInfo] = useState<TEmailData | undefined>();

  const [mainFormErrors, setMainFormErrors] = useState<IErrorsMsgAndType[] | undefined>();

  const [extraAscendValues, setExtraAscendValues] = useState<any>();
  const [externalAscendErrors, setExternalAscendErrors] = useState<IErrorsMsgAndType[] | undefined>();

  const [documentIds, setDocumentIds] = useState<string[] | undefined>();

  const [threeColLayoutIsLoading, setThreeColLayoutIsLoading] = useState<boolean>(true);
  const [threeColErrors, setThreeColErrors] = useState<IErrorsMsgAndType[] | undefined>();

  // const resetPageData = useCallback(() => {
  //   // console.log('RESETTING PAGE DATA');
  //   setTaxReturnResponse(undefined);
  //   setNewTaxReturnFormValues(undefined);
  //   setThreeColLayoutIsLoading(true);
  //   setPageErrors(undefined);
  //   setMainFormErrors(undefined);
  // }, []);

  useEffect(() => {
    if (taxReturnResponse || !taskActionId) return;
    setTaxReturnResponseFailed(false);

    API.taskActionsGET2(taskActionId)
      .then((response) => {
        // console.log('New Tax Return response:', response);
        const responseErrors = processResponseForErrors(response);
        setPageErrors(responseErrors.messages);
        if (responseErrors.hasErrors) {
          setTaxReturnResponseFailed(true);
          setFormIsLoading(false);
          return;
        }

        /*
          NOTE: IF THE TASK HAS ALREADY BEEN LINKED, THE PAGE SHOULD REDIRECT
          - CHECK IF response.linkedTaxReturnSubmissionIds HAS A VALUE
          - IF SO, REDIRECT ... BUT I'M NOT ENTIRELY SURE WHERE, ASK JOE
          - OTHERWISE...
        */

        setTaxReturnResponse(response);
      })
      .catch((error) => {
        setPageErrors([{ message: t.DATA_COULD_NOT_BE_RETRIEVED }]);
        setTaxReturnResponseFailed(true);
        setFormIsLoading(false);
      });
  }, [taxReturnResponse, taskActionId, processResponseForErrors, t.DATA_COULD_NOT_BE_RETRIEVED]);

  useEffect(() => {
    if (!taxReturnResponse) return;

    setEmailInfo({
      fromName: taxReturnResponse.importedEmailSenderName,
      fromAddress: taxReturnResponse.importedEmailSenderEmailAddress,
      toName: taxReturnResponse.importedEmailRecipientName,
      toAddress: taxReturnResponse.importedEmailRecipientEmailAddress,
      subjectField: taxReturnResponse.importedEmailSubject,
      bodyField: taxReturnResponse.importedEmailBody,
    });

    setNewTaxReturnFormValues({
      ascendEstateNumber: getUndefinedOrSelf(taxReturnResponse.extractedEstateNumber),
      ascendSin: getUndefinedOrSelf(taxReturnResponse.extractedSINNumber),
      ascendDebtorName: getUndefinedOrSelf(taxReturnResponse.extractedDebtorName),
      ascendDateOfInsolvency: taxReturnResponse?.extractedInsolvencyDate
        ? convertServerDateOnlyToInputDate(moment(taxReturnResponse?.extractedInsolvencyDate))
        : undefined,
      ascendSubmission: getUndefinedOrSelf(taxReturnResponse.submission),
      ascendTaxPeriods: getUndefinedOrSelf(taxReturnResponse.taxReturnPeriodIds),
      ascendFileId: getUndefinedOrSelf(taxReturnResponse.searchedFileId),
    });

    setDocumentIds(taxReturnResponse.attachedDocumentIds);
  }, [taxReturnResponse]);

  useEffect(() => {
    if (initialFormValuesHaveBeenSet || !newTaxReturnFormValues) return;
    setInitialFormValuesHaveBeenSet(true);
    setFormIsLoading(false);
  }, [form, initialFormValuesHaveBeenSet, newTaxReturnFormValues]);

  useEffect(() => {
    if (!taxReturnResponse || !emailInfo || !documentIds) return;
    setThreeColLayoutIsLoading(false);
  }, [taxReturnResponse, emailInfo, documentIds]);

  const handleSave = useCallback(
    async (withAlert = false, isRedundant = false) => {
      if (!taxReturnResponse || taskActionId == null) return;
      setMainFormErrors(undefined);

      const formValues = form.getFieldsValue();

      let requestBody: ClientService.IAppTaskActionTaxReturnUpdateDto = {
        id: taskActionId,
        searchedFileId: extraAscendValues?.searchedFileId,
        estateNumber: formValues.ascendEstateNumber,
        sin: formValues.ascendSin,
        debtorName: formValues.ascendDebtorName,
        dateOfInsolvency: convertInputDateToServerDateOnly(moment(formValues?.ascendDateOfInsolvency)),
        submission: formValues.ascendSubmission,
        taxReturnPeriodIds: formValues.ascendTaxPeriods,
        alerts: new ClientService.AlertCreateDto(),
      };
      // console.log('requestBody:', requestBody);

      const request = new ClientService.AppTaskActionTaxReturnUpdateDto(requestBody);

      if (withAlert) {
        const alertBody = await handleAlert();
        if (alertBody === false) return;
        const typedAlertBody = alertBody as ClientService.IAlertCreateDto;
        const alertRequest = new ClientService.AlertCreateDto(typedAlertBody);
        request.alerts = alertRequest;
      }

      if (isRedundant) request.isRedundant = true;

      // console.log('final save request:', request);

      API.updateTaxReturn(request)
        .then((response: ClientService.SaveResult | ClientService.RemoteServiceErrorResponse) => {
          const responseErrors = processResponseForErrors(response);
          setMainFormErrors(responseErrors.messages);
          if (responseErrors.hasErrors) return;
          // resetPageData();
          setTimeout(() => genericMessage.success(t.SUCCESSFULLY_SAVED), 500);

          if (isRedundant) navigate(ROUTES.DASHBOARD);
        })
        .catch((error) => {
          const requestErrors = processResponseForErrors(error);
          console.error(requestErrors.messages);
          setMainFormErrors([{ message: t.ERROR__TRYING_TO_SAVE }]);
        });
    },
    [
      taxReturnResponse,
      taskActionId,
      form,
      extraAscendValues?.searchedFileId,
      handleAlert,
      processResponseForErrors,
      navigate,
      t.SUCCESSFULLY_SAVED,
      t.ERROR__TRYING_TO_SAVE,
    ]
  );

  return (
    <Layout page={ROUTES.TAX_RETURN}>
      <div className={styles.NewIncomeAndExpensePage}>
        {pageErrors && <ErrorsContainer errors={pageErrors} noTitle />}
        <PageTitle title={t.TAXRETURN_TAX_RETURN_PROCESS} />
        <NewAndViewHeader
          subtitle="new-tax-return"
          errors={mainFormErrors}
          breadcrumbData={[
            { link: ROUTES.DASHBOARD, title: t.DASHBOARD },
            { title: t.NEWVIEW_SUBTITLE_NEW_TAX_RETURN },
          ]}
          buttons={
            <ButtonsGroupCancelDraftRedundant
              buttonsEnabled={initialFormValuesHaveBeenSet}
              onSaveAsDraft={() => handleSave()}
              onSaveAsDraftWithAlert={() => handleSave(true)}
              onRedundant={() => handleSave(false, true)}
            />
          }
        />
        <AppForm form={form} isLoading={formIsLoading}>
          {externalAscendErrors && <ErrorsContainer errors={externalAscendErrors} noTitle />}
          <SearchAscend
            form={form}
            externalStateSetter={setExtraAscendValues}
            initialValues={newTaxReturnFormValues}
            variableFieldsComponent={<NewTaxReturnFields />}
            numberOfVariableFieldsForSearch={0}
            showVariableFieldsWhen="on-match"
            apiSearch={(ascendSearchValues) => {
              const args: ISearchAscendFileCriteria = {
                estateNumber: ascendSearchValues.ascendEstateNumber,
                sIN: ascendSearchValues.ascendSin,
                debtorName: ascendSearchValues.ascendDebtorName,
                dateOfInsolvency: ascendSearchValues?.ascendDateOfInsolvency
                  ? convertInputDateToServerDateOnly(moment(ascendSearchValues?.ascendDateOfInsolvency))
                  : undefined,
              };
              return API.search(...argumentifyAscendFileSearchCriteria(args));
            }}
            apiSetData={(responseData, setStateData) => {
              // console.log('searchAscend Response:', responseData);
              const updatedFormValues: IAscendFormValues = {
                ascendEstateNumber: responseData.estateNumber,
                ascendSin: responseData.sin,
                ascendDebtorName: responseData.debtorName,
                ascendDateOfInsolvency: convertServerDateOnlyToInputDate(moment(responseData.insolvencyDate)),
              };
              setStateData(updatedFormValues);
              setExtraAscendValues((prev: any) => {
                const updatedValues = {
                  ...prev,
                  searchedFileId: responseData.fileId,
                };
                return updatedValues;
              });
            }}
            mainFieldsRequiredForConfirm={false}
            confirmLinkMessage={t.ASCEND_ABOUT_TO_LINK_TAX_RETURN}
            handleConfirmValidation={(ascendSearchFormValues) => {
              // console.log('ascendSearchFormValues:', ascendSearchFormValues);

              const genericErrorMessage = t.ERROR__ERROR_WITH_REQUEST;
              if (!taskActionId) {
                console.error('The task action ID is missing');
                return genericErrorMessage;
              }

              if (!ascendSearchFormValues) {
                return t.ASCEND_ERROR__NO_FIELDS_HAVE_VALUE;
              }

              if (!extraAscendValues?.searchedFileId) {
                console.error('The ID of the matched file is missing');
                return genericErrorMessage;
              }

              if (ascendSearchFormValues.ascendSubmission == null || ascendSearchFormValues.ascendTaxPeriods == null) {
                return t.ERROR__REQUIRED_FIELDS_MISSING;
              }

              //Cater for the case where the user has selected a submission type NOA (Single Tax Periods selected)
              if (extraAscendValues?.ascendTaxPeriodOptions == null) {
                return t.ERROR__REQUIRED_FIELDS_MISSING;
              }

              //Cater for the case where the user has selected a submission type Tax Return (Multiple Tax Periods selected)
              if (extraAscendValues?.ascendTaxPeriodOptions instanceof Array) {
                if (extraAscendValues?.ascendTaxPeriodOptions.length === 0) {
                  return t.ERROR__REQUIRED_FIELDS_MISSING;
                }
              }

              return true;
            }}
            apiConfirm={(ascendSearchFormValues) => {
              const confirmRequestBody: ClientService.ITaxReturnSubmissionLinkDto = {
                appTaskActionId: taskActionId,
                searchedFileId: extraAscendValues?.searchedFileId,
                submission: ascendSearchFormValues.ascendSubmission,
              };

              //create a variable to hold the tax periods as an empty array of type ClientService.TaxReturnSubmissionPeriodLinkDto
              let taxPeriods: ClientService.TaxReturnSubmissionPeriodLinkDto[] = [];
              //check if the ascendTaxPeriodOptions is an array, then we have a submission type of Tax Return filing
              if (extraAscendValues && Array.isArray(extraAscendValues.ascendTaxPeriodOptions)) {
                taxPeriods = extraAscendValues.ascendTaxPeriodOptions.map((taxPeriodOption: ISelectOption) => {
                  return new ClientService.TaxReturnSubmissionPeriodLinkDto({
                    externalTaxReturnId: taxPeriodOption.value as string,
                    isMarkAsActive: undefined,
                    taxPeriod: taxPeriodOption.label,
                  });
                });
                //if the ascendTaxPeriodOptions is not an array, then we have a submission type of NOA
              } else if (extraAscendValues) {
                const selectTaxPeriod = extraAscendValues.ascendTaxPeriodOptions;
                taxPeriods.push(
                  new ClientService.TaxReturnSubmissionPeriodLinkDto({
                    externalTaxReturnId: selectTaxPeriod.value as string,
                    isMarkAsActive: undefined,
                    taxPeriod: selectTaxPeriod.label,
                  })
                );
              }

              confirmRequestBody.taxReturnPeriods = taxPeriods;
              const confirmRequest = new ClientService.TaxReturnSubmissionLinkDto(confirmRequestBody);

              return {
                apiCall: () => {
                  return API.linkTaskAction3(confirmRequest);
                },
                requestBody: confirmRequestBody,
              };
            }}
            onConfirm={() => {
              navigate(`${ROUTES.APPLICATION_OVERVIEW}/${extraAscendValues?.searchedFileId}`, {
                state: { preselectedTab: ADMIN_INFO_TAB, preselectedAdminInfoSubTab: TAX_PROCESS_TAB },
              });
            }}
            handleAsyncConfirmAction={async (
              confirmAttemptResponse,
              ascendFormValues,
              ascendSearchResponse,
              lastConfirmRequestBody,
              onConfirm
            ) => {
              // console.log('apiConfirm response:', confirmAttemptResponse);

              let periodsToCheck: ClientService.TaxReturnSubmissionPeriodLinkDto[] | undefined;
              if (confirmAttemptResponse.data != null) {
                periodsToCheck = confirmAttemptResponse.data;
              }

              // For testing
              // periodsToCheck = lastConfirmRequestBody.taxReturnPeriods;
              // console.log('periodsToCheck:', periodsToCheck);

              if (periodsToCheck == null) {
                setExternalAscendErrors([{ message: t.ERROR__TRYING_TO_CONFIRM }]);
                console.error(
                  'Confirm attempt response indicated a need to confirm active status of tax periods but no tax periods were provided.'
                );
                return;
              }

              const modalFormValues = await getFromAsyncModalForm({
                title: t.ASCEND_MARK_AS_ACTIVE_Q,
                width: 600,
                initialFormValues: () => {
                  const initialVals: IGenericObject = {};
                  periodsToCheck?.forEach((period) => {
                    initialVals[period.externalTaxReturnId as string] = true;
                  });
                  return initialVals;
                },
                renderFormBody: (form) => {
                  return (
                    <>
                      <p>{t.TAXRETURN_PERIODS_ALREADY_HAVE_ACTIVE_SUBMISSION}</p>
                      {periodsToCheck &&
                        (periodsToCheck as ClientService.TaxReturnSubmissionPeriodLinkDto[]).map((period, index) => {
                          return (
                            <MarkAsActiveRadioSelection
                              key={`period-${index}`}
                              name={`${period.externalTaxReturnId}`}
                              label={period.taxPeriod}
                            />
                          );
                        })}
                    </>
                  );
                },
              });

              if (modalFormValues === false) return;

              // console.log('modalFormValues:', modalFormValues);
              let updatedTaxPeriods = lastConfirmRequestBody.taxReturnPeriods.map(
                (period: ClientService.TaxReturnSubmissionPeriodLinkDto) => {
                  const markAsActiveSettingForId =
                    period.externalTaxReturnId != null
                      ? (modalFormValues as IGenericObject)[period.externalTaxReturnId]
                      : undefined;
                  return new ClientService.TaxReturnSubmissionPeriodLinkDto({
                    externalTaxReturnId: period.externalTaxReturnId,
                    isMarkAsActive:
                      markAsActiveSettingForId != null && markAsActiveSettingForId === false ? false : true,
                    taxPeriod: period.taxPeriod,
                  });
                }
              );

              // console.log('updatedTaxPeriods:', updatedTaxPeriods);

              lastConfirmRequestBody.taxReturnPeriods = updatedTaxPeriods;
              const updatedRequest = new ClientService.TaxReturnSubmissionLinkDto(lastConfirmRequestBody);
              // console.log('updatedRequest:', updatedRequest);

              API.linkTaskAction3(updatedRequest)
                .then((response) => {
                  const responseErrors = processResponseForErrors(response);
                  setExternalAscendErrors(responseErrors.messages);
                  if (responseErrors.hasErrors) return;
                  if (onConfirm) onConfirm();
                })
                .catch((error) => {
                  setExternalAscendErrors([{ message: t.ERROR__TRYING_TO_CONFIRM }]);
                });
            }}
          />
          {threeColLayoutIsLoading ? (
            <Loading noText />
          ) : !threeColLayoutIsLoading && taxReturnResponseFailed ? (
            <div style={{ textAlign: 'center' }}>{t.DATA_COULD_NOT_BE_RETRIEVED}</div>
          ) : (
            <ThreeColumnLowerLayout
              errors={threeColErrors}
              leftColumnContent={<DataItem label={t.TAXRETURN_TAX_RETURN_INFO} value={' '} />}
              filesList={documentIds}
              rightColumnContent={
                <EmailContentDisplay
                  title={t.TAXRETURN_TAX_RETURN_EMAIL}
                  errorsSetter={setThreeColErrors}
                  {...emailInfo}
                />
              }
            />
          )}
        </AppForm>
      </div>
    </Layout>
  );
};

export default NewTaxReturnPage;
