import { useCallback, useEffect, useMemo, useState } from 'react';
import { InfoCircleOutlined } from '@ant-design/icons';
import { Popover, Col, Row, Form, Checkbox, Input } from 'antd';

import { ClientService } from '../../shared/api/ClientService';
import API from '../../utils/api';

import './AttachmentsTab.scss';
import useLocale from '../../hooks/useLocale';
import { OverrideInfo } from './types';

const SupportingDocsLabel = ({
  supportingFolderId,
  prefixCodes,
  isPrefixCodesRequired = false,
  hint,
  limitSupportingDocsByPrefixCode,
}: {
  supportingFolderId: string;
  prefixCodes?: (string | undefined)[];
  isPrefixCodesRequired?: boolean;
  hint?: string[];
  limitSupportingDocsByPrefixCode?: (string | undefined)[];
}) => {
  const [supportingDocs, setSupportingDoc] = useState<ClientService.LookupDto[]>();

  const depsPrefixCodes = useMemo(() => `${prefixCodes}`, [prefixCodes]);
  const depsLimit = useMemo(() => `${limitSupportingDocsByPrefixCode}`, [limitSupportingDocsByPrefixCode]);

  const { t, getLocalizedDtoName } = useLocale();
  const requestListPrefixes = useCallback(
    async (documentFolderId: string) => {
      if (isPrefixCodesRequired && (!prefixCodes || prefixCodes?.length === 0)) {
        setSupportingDoc([]);
        return;
      }

      const response = await API.listPrefixes(documentFolderId, prefixCodes);
      if (response) {
        const supportingDocs = limitSupportingDocsByPrefixCode
          ? response?.filter((item) => limitSupportingDocsByPrefixCode?.indexOf(item?.code) >= 0)
          : response;
        setSupportingDoc(supportingDocs);
      }
    },
    [isPrefixCodesRequired, prefixCodes, limitSupportingDocsByPrefixCode]
  );

  useEffect(() => {
    if (supportingFolderId) requestListPrefixes(supportingFolderId);
  }, [supportingFolderId, depsPrefixCodes, depsLimit]);

  const addOrUpdateOverride = (newOverride: OverrideInfo, overrideData: OverrideInfo[]): OverrideInfo[] => {
    console.log('addOrUpdateOverride called', newOverride);
    if (!overrideData) return [newOverride];
    else {
      const index = overrideData.findIndex((item) => item.documentPrefixId === newOverride.documentPrefixId);

      if (index !== -1) {
        // Update existing item
        const updatedData = [...overrideData];
        updatedData[index] = newOverride;
        return updatedData;
      } else {
        // Add new item
        const existingData = [...overrideData];
        existingData.push(newOverride);
        return existingData;
      }
    }
  };
  return (
    <Form.Item noStyle shouldUpdate>
      {({ getFieldValue, setFieldsValue }) => {
        const overrideData = getFieldValue('SupportingDocOverrides') as OverrideInfo[];
        return (
          <div className="AttachmentsTab__supporting-docs-label">
            <Row gutter={10}>
              <Col span={12}>
                <Popover
                  placement="bottom"
                  content={
                    hint && hint?.length > 1 ? (
                      <ul>
                        {hint?.map((item, index) => (
                          <li key={`hint-${index}`}>{item}</li>
                        ))}
                      </ul>
                    ) : (
                      hint?.[0]
                    )
                  }
                  style={{ width: '100%' }}
                  trigger="hover"
                >
                  <Row gutter={8} align="middle">
                    <Col>
                      {hint && <InfoCircleOutlined className="AttachmentsTab__supporting-docs-label--hint-icon" />}
                    </Col>
                    <Col>{t.SUPPORTING_DOCUMENTS_NEEDED}:</Col>
                  </Row>
                </Popover>
              </Col>
              <Col>{t.OVERRIDE_TITLE}</Col>
            </Row>
            <ol>
              {supportingDocs?.map((item, index) => {
                const override = overrideData?.find((i) => i.documentPrefixId == item.id);
                return (
                  <li key={String(index)} className="AttachmentsTab__supporting-docs-label--list-item">
                    <Row gutter={10} align="middle">
                      <Col span={12}>
                        {item.description && item.displayName
                          ? `${getLocalizedDtoName(item) || item?.displayName} => ${item.description}`
                          : item.description || item.displayName}
                      </Col>
                      <Col>
                        <Form.Item
                          name={`override-${String(override?.documentPrefixId)}`}
                          style={{ marginBottom: '0px' }}
                          initialValue={override?.isOverridden}
                        >
                          <Checkbox
                            disabled={override?.isDisabled}
                            checked={override && override.isOverridden}
                            onChange={(e) =>
                              setFieldsValue({
                                SupportingDocOverrides: addOrUpdateOverride(
                                  {
                                    ...override,
                                    documentPrefixId: item.id!,
                                    isOverridden: e.target.checked,
                                  } as OverrideInfo,
                                  overrideData
                                ),
                              })
                            }
                          />
                        </Form.Item>
                      </Col>
                      <Col span={9}>
                        <Form.Item
                          name={`overrideReason-${String(override?.documentPrefixId)}`}
                          style={{
                            marginBottom: '0px',
                            display: override && override.isOverridden ? 'inline' : 'none',
                          }}
                          initialValue={override?.overrideReason}
                          required={override?.isOverridden}
                          rules={[{ required: override?.isOverridden, message: t.OVERRIDE_REASON_REQUIRED }]}
                        >
                          <Input
                            value={override?.overrideReason}
                            onChange={(e) =>
                              setFieldsValue({
                                SupportingDocOverrides: addOrUpdateOverride(
                                  {
                                    ...override,
                                    documentPrefixId: item.id!,
                                    overrideReason: e.target.value,
                                  } as OverrideInfo,
                                  overrideData
                                ),
                              })
                            }
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                  </li>
                );
              })}
            </ol>
          </div>
        );
      }}
    </Form.Item>
  );
};

export default SupportingDocsLabel;
