import { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import { Input, Divider, Select, DatePicker, Col, Row, Form, Spin, ConfigProvider, Radio } from 'antd';
import { Icon } from '@fluentui/react/lib/Icon';
import moment from 'moment';
import { useQuery } from 'react-query';

import Breadcrumb from '../../../components/Breadcrumb/Breadcrumb';
import Button from '../../../components/Button/Button';
import PageSubtitle from '../../../components/PageSubtitle/PageSubtitle';
import RequestForReview from '../../../components/RequestForReview/RequestForReview';
import SelectLookupDto from '../../../components/SelectLookupDto/SelectLookupDto';
import SelectSuffixIcon from '../../../components/SelectSuffixIcon/SelectSuffixIcon';

import PaymentMethod from './components/PaymentMethod';
import VoidConfirmationModal from './modals/VoidConfirmationModal';
import AmountDataItem from './components/AmountDataItem';
import CurrencyInput from './components/CurrencyInput';

import { DATE_FORMAT2, DATE_FORMAT_PLACEHOLDER } from '../../../constants/common';
import { validator } from '../../../utils/validation';
import { AuthorizeService } from '../../../components/Auth/AuthorizeService';
import { ClientService } from '../../../shared/api/ClientService';
import { ROUTES } from '../../../constants/routes';
import { EXCLUDE_INACTIVE, INCLUDE_INACTIVE, OFFICE_LOCATIONS_QUERY } from '../../../constants/reactQuery';
import { calculateCashAmount, argumentifyOfficeLocationsSearchCriteria, convertScheduleItemToReadable } from './utils';
import { MAX_PAGE_SIZE } from '../../../constants/common';

import useLocale from '../../../hooks/useLocale';
import useModal from '../../../hooks/useModal';
import { useApplicationFile } from '../../ApplicationOverviewPage';

import genericMessage from '../../../utils/genericMessage';
import API from '../../../utils/api';

import './ReceiptEditor.scss';
import DownloadFile from '../../../components/DownloadFile/DownloadFile';

const MAX_CASH_AMOUNT = 2500;

interface ILocationState {
  padScheduleId?: string;
}

interface IForm extends ClientService.ReceiptDto {
  amountDue?: number;
}

/** Work for Create new Receipt or Edit existing Receipt. */
const ReceiptEditor = () => {
  const { t } = useLocale();
  const { Option } = Select;
  const navigate = useNavigate();
  const { applicationFileId, receiptId } = useParams<{ applicationFileId: string; receiptId: string }>();
  const location = useLocation();
  const padScheduleId = (location?.state as ILocationState)?.padScheduleId;

  const { showModal, closeModal } = useModal();
  const [form] = Form.useForm<IForm>();

  const [initialValues, setInitialValues] = useState<ClientService.ReceiptDto>();
  const [loading, setLoading] = useState<boolean>(false);

  const [isConfirmVoidModalVisible, setIsConfirmVoidModalVisible] = useState(false);
  const [paymentMethodServerError, setPaymentMethodServerError] = useState<string>();

  const [padScheduleList, setPadScheduleList] = useState<ClientService.PadScheduleSelectListItemDto[]>();
  const [padScheduleLoading, setPadScheduleLoading] = useState<boolean>(false);

  const { data: appFileData } = useApplicationFile();
  const isDuplicate = useMemo(() => appFileData?.isDuplicate, [appFileData]);
  const isNewReceipt = useMemo(() => !Boolean(receiptId), [receiptId]);

  const { data: officeLocations } = useQuery(
    [OFFICE_LOCATIONS_QUERY, !receiptId ? EXCLUDE_INACTIVE : INCLUDE_INACTIVE, MAX_PAGE_SIZE],
    () =>
      API.officeLocationsGET(
        ...argumentifyOfficeLocationsSearchCriteria({
          includeInactive: Boolean(receiptId),
          maxResultCount: MAX_PAGE_SIZE,
        })
      )
  );

  const user = AuthorizeService.getCurrentUserInfo();

  /** Current logon user's full name. Display as "Per" */
  const userFullName = useMemo(() => {
    const userProfile = user?.profile;
    let name = '';

    if (userProfile) {
      if (userProfile.given_name && userProfile.family_name) {
        name = `${userProfile.given_name} ${userProfile.family_name}`;
      } else if (userProfile.name) {
        name = userProfile.name;
      }
    }

    return name;
  }, [user]);

  /** Get current logon user's Id (guid). Work for assign "AgentId" in creating new Receipt. */
  const userId = useMemo(() => {
    const userProfile = user?.profile;
    let userId = '';

    if (userProfile) {
      if (userProfile.sub) {
        userId = `${userProfile.sub}`;
      }
    }

    return userId;
  }, [user]);

  const validatePaymentMethod = useCallback(
    (_, paymentMethod) => {
      const paymentMethodDetail = form.getFieldValue('paymentMethodDetail');
      const paymentAmount = form.getFieldValue('paymentAmount');

      if (
        paymentMethod === ClientService.ReceiptPaymentMethodEnum.Cheque &&
        !validator.validateNumbersAndLetters(paymentMethodDetail)
      ) {
        return Promise.reject(Error(t.RECEIPT_ERROR_INVALID_CHEQUE_NUMBER));
      } else if (
        paymentMethod === ClientService.ReceiptPaymentMethodEnum.Cash &&
        calculateCashAmount(form.getFieldsValue()) !== Number(paymentAmount)
      ) {
        return Promise.reject(Error(t.RECEIPT_ERROR_INCORRECT_DENOMINATION));
      } else if (
        paymentMethod === ClientService.ReceiptPaymentMethodEnum.Cash &&
        Number(paymentAmount) > MAX_CASH_AMOUNT
      ) {
        return Promise.reject(Error(t.RECEIPT_ERROR_CASH_AMOUN_EXCEEDED));
      } else if (
        paymentMethod === ClientService.ReceiptPaymentMethodEnum.Other &&
        !validator.validateRequiredString(paymentMethodDetail)
      ) {
        return Promise.reject(Error(t.RECEIPT_PAYMENT_METHOD_DETAIL));
      } else {
        return Promise.resolve();
      }
    },
    [form, t]
  );

  const requestPadScheduleList = useCallback(
    async (applicationFileId: string) => {
      const profileResponse = await API.getProfileInfo();
      if (profileResponse) {
        form.setFieldsValue({
          per: profileResponse?.[0].name,
        });
      }

      setPadScheduleLoading(true);
      const response = await API.padScheduleSelectlist(applicationFileId).catch(() => setPadScheduleLoading(false));
      setPadScheduleLoading(false);

      if (response) {
        setPadScheduleList(response);

        if (padScheduleId) {
          form.setFieldsValue({
            receiptType: ClientService.ReceiptTypeEnum.Scheduled,
            padScheduleId,
            amountDue: response?.find((item) => item?.id === padScheduleId)?.amountDue,
          });
        }
      }
    },
    [form, padScheduleId]
  );

  /** Get existing receipt data from calling remove server with receipt id. Only called when input request Id has value.*/
  const requestGetReceipt = useCallback(
    async (receiptId: string) => {
      setLoading(true);
      const response = await API.receiptsGET2(receiptId);

      if (response) {
        form.setFieldsValue({
          ...response,
          per: response?.per || userFullName,
          agentId: response?.agentId || userId,
          paymentDate: moment(response?.paymentDate),
        });
        setInitialValues(response);
      }
      setLoading(false);
    },
    [form, userFullName, userId]
  );

  /** Called when submit new receipt. Save new receipt to database. */
  const requestCreateReceipt = useCallback(
    async (data: ClientService.ReceiptCreateDto) => {
      setLoading(true);
      const response = await API.receiptsPOST(data).catch(() => setLoading(false));
      setLoading(false);

      if (response?.result === ClientService.Result.Successful) {
        genericMessage.success(t.RECEIPT_CREATED_SUCCESS_MESSAGE);
        navigate(`../${ROUTES.RECEIPTS}`);
      } else if (response?.messages?.[0]?.fieldIdentifier === 26) {
        setPaymentMethodServerError(t.TOTAL_AMOUNT_7_DAYS_ERROR);
      } else {
        genericMessage.error({}, response?.messages?.[0].body || '');
      }
    },
    [navigate, t.RECEIPT_CREATED_SUCCESS_MESSAGE, t.TOTAL_AMOUNT_7_DAYS_ERROR]
  );

  const onFinish = useCallback(
    (values) => {
      if (!receiptId && applicationFileId) {
        requestCreateReceipt({
          ...values,
          fileId: applicationFileId,
          //Note: By default, agentId is current logon user Id.
          agentId: userId,
        });
      }
    },
    [receiptId, applicationFileId, requestCreateReceipt, userId]
  );

  const handleShowReviewEditor = useCallback(
    async (documentId: string) => {
      const document = await API.documentsGET2(documentId as string);

      if (document) {
        showModal(<RequestForReview data={[document]} onOk={closeModal} onCancel={closeModal} />);
      }
    },
    [showModal, closeModal]
  );

  const voidReceipt = useCallback(async () => {
    if (receiptId) {
      const response = await API.void(receiptId);

      if (response?.result === ClientService.Result.Successful) {
        genericMessage.success(t.RECEIPT_VOIDED_SUCCESS_MESSAGE);
        requestGetReceipt(receiptId);

        if (response.returnId) {
          handleShowReviewEditor(response.returnId);
        }
      }
    }

    setIsConfirmVoidModalVisible(false);
  }, [handleShowReviewEditor, receiptId, requestGetReceipt, t.RECEIPT_VOIDED_SUCCESS_MESSAGE]);

  useEffect(() => {
    if (receiptId) {
      requestGetReceipt(receiptId);
    }
  }, [receiptId, requestGetReceipt]);

  useEffect(() => {
    if (applicationFileId) requestPadScheduleList(applicationFileId);
  }, [applicationFileId, requestPadScheduleList]);

  const handleFormValuesChange = useCallback(
    (values) => {
      Object.keys(values).forEach((field) => {
        const error = form.getFieldError(field);
        if (!error.length) {
          return;
        }
        form.setFields([
          {
            name: field,
            errors: [],
          },
        ]);
      });
      setPaymentMethodServerError(undefined);
    },
    [form]
  );

  const getDownloadUrl = (id: string) => {
    return `${process.env.REACT_APP_BASE_URL}/api/client-service/receipts/${id}/download`;
  };

  return (
    <Spin spinning={loading || padScheduleLoading}>
      <div className="ReceiptEditor">
        <Breadcrumb
          data={[
            { link: ROUTES.DASHBOARD, title: t.DASHBOARD },
            { link: `${ROUTES.DEBTOR_SEARCH}`, title: t.DEBTOR_SEARCH },
            { link: `${ROUTES.APPLICATION_OVERVIEW}/${applicationFileId}/`, title: t.APPLICATION_OVERVIEW },
            {
              link: `${ROUTES.APPLICATION_OVERVIEW}/${applicationFileId}/${ROUTES.RECEIPTS}`,
              title: t.RECEIPT_HISTORY,
            },
            { title: t.RECEIPT },
          ]}
        />
        <PageSubtitle
          title={
            <div className="ReceiptEditor__title">
              {isNewReceipt ? t.RECEIPT : `${t.RECEIPT} - ${initialValues?.receiptNumber}`}
              {!isNewReceipt && (
                <DownloadFile downloadUrl={getDownloadUrl(receiptId as string)}>
                  <Icon iconName="Download" />
                </DownloadFile>
              )}
            </div>
          }
          buttonTitle={!!receiptId && !isDuplicate && t.RECEIPT_NEW}
          onButtonClick={() => {
            form.resetFields();
            navigate(`../${ROUTES.ADD_RECEIPT}`);
          }}
        />
        <Form
          form={form}
          layout="vertical"
          onFinish={onFinish}
          onValuesChange={handleFormValuesChange}
          validateTrigger={['onSubmit', 'onChange']}
          className="ReceiptEditor__form"
        >
          <>
            <ConfigProvider componentSize="large">
              <Row gutter={20}>
                <Col span={8}>
                  <Form.Item name="receiptType" label={t.TYPE} rules={[{ required: true, message: t.REQUIRED_FIELD }]}>
                    <Select suffixIcon={<SelectSuffixIcon />} disabled={!isNewReceipt || Boolean(padScheduleId)}>
                      <Option value={ClientService.ReceiptTypeEnum.General}>General</Option>
                      <Option value={ClientService.ReceiptTypeEnum.Scheduled}>Scheduled</Option>
                    </Select>
                  </Form.Item>
                </Col>
                <Form.Item noStyle shouldUpdate>
                  {({ getFieldValue, setFieldsValue }) => (
                    <Col span={getFieldValue('receiptType') === ClientService.ReceiptTypeEnum.Scheduled ? 8 : 0}>
                      <Form.Item name="padScheduleId" label={t.SCHEDULE}>
                        <Select
                          suffixIcon={<SelectSuffixIcon />}
                          disabled={!isNewReceipt}
                          onSelect={(padScheduleId?: string) =>
                            setFieldsValue({
                              amountDue: padScheduleList?.find((item) => item.id === padScheduleId)?.amountDue,
                            })
                          }
                        >
                          {padScheduleList?.map((item, index) => (
                            <Option key={`${index}-${item?.id}`} value={item?.id}>
                              {convertScheduleItemToReadable(item)}
                            </Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </Col>
                  )}
                </Form.Item>
              </Row>
              <Row gutter={20}>
                <Col span={8}>
                  <Form.Item
                    name="paymentDate"
                    label={t.RECEIPT_PAYMENT_DATE}
                    rules={[{ required: true, message: t.REQUIRED_FIELD }]}
                  >
                    <DatePicker
                      disabledDate={(date) => date.isAfter(moment(), 'day')}
                      format={DATE_FORMAT2}
                      disabled={!isNewReceipt}
                      allowClear={false}
                      suffixIcon={<Icon iconName="Calendar" />}
                      placeholder={DATE_FORMAT_PLACEHOLDER}
                    />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item
                    name="officeLocationId"
                    label={t.RECEIPT_OFFICE_LOCATION}
                    rules={[{ required: true, message: t.REQUIRED_FIELD }]}
                  >
                    <SelectLookupDto disabled={!isNewReceipt} data={officeLocations?.items} showSearch />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item name="per" label={t.RECEIPT_PER} rules={[{ required: true }]} initialValue={userFullName}>
                    {/* {userFullName && userFullName.length > 0 && <Input disabled />} */}
                    <Input disabled />
                  </Form.Item>
                </Col>
              </Row>

              <Divider />

              <Row gutter={20}>
                <Col span={16} className="ReceiptEditor__container">
                  <Row gutter={20}>
                    <Col xl={12} xs={24}>
                      <Form.Item
                        name="postType"
                        label={t.RECEIPT_POST_TO}
                        rules={[{ required: true, message: t.REQUIRED_FIELD }]}
                      >
                        <Radio.Group disabled={!isNewReceipt} key="postType" buttonStyle="solid" optionType="button">
                          <Radio.Button value={ClientService.ReceiptPostTypeEnum.PrefilingAccount}>
                            {t.RECEIPT_POSTTYPE_PREFILING}
                          </Radio.Button>
                          <Radio.Button value={ClientService.ReceiptPostTypeEnum.Estate}>
                            {t.RECEIPT_POSTTYPE_ESTATE}
                          </Radio.Button>
                          <Radio.Button value={ClientService.ReceiptPostTypeEnum.Holding}>
                            {t.RECEIPT_POSTTYPE_HOLDING}
                          </Radio.Button>
                          );
                        </Radio.Group>
                      </Form.Item>
                    </Col>

                    <Col xl={12} xs={24}>
                      <Form.Item
                        name="paymentMethod"
                        label={t.RECEIPT_PAYMENT_METHOD}
                        rules={[{ required: true, message: t.REQUIRED_FIELD }, { validator: validatePaymentMethod }]}
                        validateTrigger={'onSubmit'}
                        validateStatus={paymentMethodServerError && 'error'}
                        help={paymentMethodServerError}
                      >
                        <Radio.Group disabled={!isNewReceipt} key="method" buttonStyle="solid" optionType="button">
                          <Radio.Button value={ClientService.ReceiptPaymentMethodEnum.Cheque}>
                            {t.RECEIPT_PAYMENTMETHOD_CHEQUE}
                          </Radio.Button>
                          <Radio.Button value={ClientService.ReceiptPaymentMethodEnum.Cash}>
                            {t.RECEIPT_PAYMENTMETHOD_CASH}
                          </Radio.Button>
                          <Radio.Button value={ClientService.ReceiptPaymentMethodEnum.Other}>
                            {t.RECEIPT_PAYMENTMETHOD_OTHER}
                          </Radio.Button>
                          );
                        </Radio.Group>
                      </Form.Item>
                    </Col>
                  </Row>

                  <Form.Item noStyle shouldUpdate>
                    {({ getFieldValue }) => (
                      <Row gutter={20} align="bottom" wrap={false}>
                        {!isNewReceipt ? (
                          <>
                            <AmountDataItem
                              label={t.RECEIPT_PAYMENT_AMOUNT}
                              amount={initialValues?.paymentAmount}
                              isVoided={initialValues?.isVoided}
                            />
                            {getFieldValue('receiptType') === ClientService.ReceiptTypeEnum.Scheduled && (
                              <AmountDataItem
                                label={t.AMOUNT_DUE}
                                amount={
                                  padScheduleList?.find((item) => item.id === initialValues?.padScheduleId)?.amountDue
                                }
                              />
                            )}
                          </>
                        ) : (
                          <>
                            <Col span={12}>
                              <Form.Item
                                name="paymentAmount"
                                label={t.RECEIPT_PAYMENT_AMOUNT}
                                rules={[{ required: true, message: t.REQUIRED_FIELD }]}
                              >
                                <CurrencyInput />
                              </Form.Item>
                            </Col>
                            <Col
                              span={getFieldValue('receiptType') === ClientService.ReceiptTypeEnum.Scheduled ? 12 : 0}
                            >
                              <Form.Item name="amountDue" label={t.AMOUNT_DUE} shouldUpdate>
                                <CurrencyInput />
                              </Form.Item>
                            </Col>
                          </>
                        )}
                      </Row>
                    )}
                  </Form.Item>
                </Col>

                <Col span={8}>
                  <PaymentMethod disabled={!isNewReceipt} />
                </Col>
              </Row>
            </ConfigProvider>

            <Divider className="ReceiptEditor__divider" />

            <Form.Item noStyle shouldUpdate>
              {({ getFieldValue }) => {
                const isVoided = getFieldValue('isVoided');

                return (
                  <Row gutter={20} className="ReceiptEditor__footer" align="bottom" justify="space-between">
                    <Col xl={8} xs={24}>
                      <Form.Item name="paymentMadeBy" label={t.RECEIPT_PAYMENT_MADE_BY}>
                        <Input disabled={!isNewReceipt} size="large" maxLength={255} />
                      </Form.Item>
                    </Col>
                    <Col xl={16} xs={24}>
                      <Row justify="end" gutter={20} wrap={false}>
                        <Col>
                          <Form.Item noStyle shouldUpdate>
                            <Button kind="cancel" onClick={() => navigate(`../${ROUTES.RECEIPTS}`)}>
                              {isVoided ? t.CLOSE : t.RECEIPT_CANCEL}
                            </Button>
                          </Form.Item>
                        </Col>

                        {!isNewReceipt && !isVoided && !isDuplicate && (
                          <Col>
                            <Form.Item noStyle shouldUpdate>
                              <Button kind="secondary" onClick={() => setIsConfirmVoidModalVisible(true)}>
                                {t.RECEIPT_VOID}
                              </Button>
                            </Form.Item>
                          </Col>
                        )}
                        {isNewReceipt && !isDuplicate && (
                          <Col>
                            <Form.Item noStyle shouldUpdate>
                              <Button htmlType="submit">{t.RECEIPT_SAVE}</Button>
                            </Form.Item>
                          </Col>
                        )}
                      </Row>
                    </Col>
                  </Row>
                );
              }}
            </Form.Item>
          </>
        </Form>

        <VoidConfirmationModal
          visible={isConfirmVoidModalVisible}
          onCancel={() => setIsConfirmVoidModalVisible(false)}
          onOk={voidReceipt}
        />
      </div>
    </Spin>
  );
};

export default ReceiptEditor;
