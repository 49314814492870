export type LocaleObj = { [key: string]: string };

interface ILocales {
  en: LocaleObj;
  fr: LocaleObj;
}

const Locales: ILocales = {
  en: {
    CLIENT_OVERVIEW_PAGE_OVERVIEW: 'Overview',
    CLIENT_OVERVIEW_PAGE_CLIENT_INFO: 'Client Info',
    CLIENT_OVERVIEW_PAGE_DOCUMENTS: 'Documents',
    CLIENT_OVERVIEW_PAGE_APPLICATION_FORM: 'Application Form',
    LOGO_SUBTEXT: 'PDS Staff Portal',
    DASHBOARD: 'Dashboard',
    NEW_PROSPECT: 'New Prospect',
    SAVE: 'Save',
    RESET: 'Reset',
    CANCEL: 'Cancel',
    CANCEL_ANYWAY: 'Cancel anyway',
    CONTINUE_EDITING: 'Continue editing',
    CONTINUE: 'Continue',
    COMPLETE: 'Complete',
    NEXT: 'Next',
    SEND: 'Send',
    CLOSE: 'Close',
    DELETE: 'Delete',
    CONFIRM: 'Confirm',
    UPLOAD: 'Upload',
    UPLOADED: 'Uploaded',
    ACCEPT: 'Accept',
    REJECT: 'Reject',
    BACK: 'Back',
    STAY: 'Stay',
    LEAVE: 'Leave',
    MARK_AS_REVIEWED: 'Mark as reviewed',
    SEARCH: 'Search',
    ADD_TASK: 'Add Task',
    CLEAR_ALL: 'Clear all',
    APPLY: 'Apply',
    FILTERS: 'Filters',
    BUNDLE: 'Bundle',
    DOWNLOAD: 'Download',
    PREVIOUS: 'Previous',
    COPY: 'Copy',
    EDIT: 'Edit',
    COMMENT: 'Comment',
    REPLACE: 'Replace',
    DOCUMENT: 'Document',
    FILES: 'Files',
    OVERVIEW: 'Overview',
    ALL: 'All',
    FOR: 'For',
    AGAINST: 'Against',
    LETTER: 'Letter',
    PROXY: 'Proxy',
    INCOME: 'Income',

    TOTAL_ALLOCATIONS: 'Total Allocations',

    LOADING: 'Loading',
    LOADING_FILE_UPLOADS: 'Loading file uploads...',

    DASHBOARD_SEARCH_PLACEHOLDER: 'Filter estate, filename, stage, task status',
    DASHBOARD_TABLE_FILE_NAME: 'File Name',
    DASHBOARD_TABLE_ESTATE: 'Estate #',
    DASHBOARD_TABLE_OPEN_DAYS: 'Days',
    DASHBOARD_TABLE_ASSIGNED: 'Assigned',
    DASHBOARD_TABLE_STAGE: 'Stage',
    DASHBOARD_TABLE_FOLLOW_UP: 'Follow Up',
    DASHBOARD_TABLE_TASK_NAME: 'Task',
    DASHBOARD_TABLE_TASK_STATUS: 'Task Status',
    NEW_PROSPECT_PERSONAL_DETAILS: 'Personal Details',
    NEW_PROSPECT_FIRST_NAME: 'First Name',
    NEW_PROSPECT_MIDDLE_NAME: 'Middle Name',
    NEW_PROSPECT_LAST_NAME: 'Last Name',
    NEW_PROSPECT_MAIN_PHONE: 'Main Phone',
    NEW_PROSPECT_EMAIL_ADDRESS: 'Email Address',
    NEW_PROSPECT_CANCEL: 'Cancel',
    NEW_PROSPECT_SAVE_AND_CLOSE: 'Save and Close',
    NEW_PROSPECT_SAVE_AND_CONTINUE: 'Save and Continue',
    NEW_PROSPECT_FOLLOW_UP: 'Follow-up',
    NEW_PROSPECT_FOLLOW_UP_DATE: 'Follow-up date',
    NEW_PROSPECT_SIMILAR_RECORDS: 'Similar Records Exist',
    NEW_PROSPECT_SAVE_AS_NEW: 'Save as New',
    SIMILAR_RECORDS_ADDRESS: 'Address',
    SIMILAR_RECORDS_MAIN_PHONE_NUMBER: 'Main Phone #',
    NEW_PROSPECT_SIMILAR_RECORDS_ERROR: 'Error',
    NEW_PROSPECT_SIMILAR_RECORDS_MESSAGE:
      'Unable to proceed since there are one or more confirmed duplicate record(s). Please update the entered first name, last name, main phone # or email address to proceed.',
    CONFIRMATION: 'Confirmation',
    NEW_PROSPECT_EMPTY_EMAIL_MESSAGE: 'The email address is blank, do you still want to continue?',
    YES: 'Yes',
    NO: 'No',
    NEW_PROSPECT_EMPTY_REQUIRED_FIELDS: 'The mandatory fields are empty:',
    NEW_PROSPECT_CANCEL_MESSAGE: 'Changes you made may not be saved.',
    INPUT_YOUR: 'Please input your ',
    IS_REQUIRED: 'is required',
    IS_INVALID: 'is invalid',
    ESTATE_ADMINISTRATOR: 'Estate Administrator',
    DEBTOR_PROFILE: 'Debtor Profile',
    DEBTOR_PROFILE_INFO_CREATED_ON: 'Created On',
    DEBTOR_PROFILE_INFO_AGENT: 'A',
    DEBTOR_PROFILE_ESTATE_ADMINISTRATOR: 'EA',
    DEBTOR_PROFILE_TRUSTEE: 'T',
    DEBTOR_PROFILE_STAGE: 'Stage',
    DEBTOR_PROFILE_BASIC_INFO: 'Basic Information',
    DEBTOR_PROFILE_CONTACT_INFO: 'Contact Information',
    DEBTOR_PROFILE_SOURCE: 'Source',
    DEBTOR_PROFILE_NOTES: 'Notes',
    DEBTOR_PROFILE_SAVE_AND_CLOSE: 'Save and Close',
    DEBTOR_PROFILE_SAVE_AND_BOOK: 'Save and Book',
    DEBTOR_PROFILE_FIRST_NAME: 'First Name',
    DEBTOR_PROFILE_MIDDLE_NAME: 'Middle Name',
    DEBTOR_PROFILE_LAST_NAME: 'Last Name',
    DEBTOR_PROFILE_DATE_OF_BIRTH: 'Date of Birth',
    DEBTOR_PROFILE_ENABLE_CLIENT_LOGIN: 'Enable Client Login',
    DEBTOR_PROFILE_HEAR_ABOUT_US: 'How did you hear about us?',
    DEBTOR_PROFILE_INTAKE_SOURCE: 'Intake Source',
    DEBTOR_PROFILE_SOURCES: 'Sources',
    DEBTOR_PROFILE_RESET_PASSWORD_MESSAGE: 'Do you want to send the client portal reset password email to the debtor?',
    DEBTOR_PROFILE_REGISTRATION_MESSAGE: 'Do you want to send the client portal registration email to the debtor?',
    DEBTOR_PROFILE_EMAIL_FAILURE: 'Error: Unable to send the email specified. Please try again later.',
    DEBTOR_PROFILE_EMAIL_SUCCESS: 'The email was sent successfully to the debtor.',
    DEBTOR_PROFILE_UNIT: 'Unit #',
    DEBTOR_PROFILE_CITY: 'City',
    DEBTOR_PROFILE_PROVINCE: 'Province/State',
    DEBTOR_PROFILE_POSTAL_CODE: 'Postal Code',
    DEBTOR_PROFILE_COUNTRY: 'Country',
    DEBTOR_PROFILE_PRIMARY_PHONE_NUMBER: 'Main Phone #',
    DEBTOR_PROFILE_EMAIL_ADDRESS: 'Email Address',
    DEBTOR_PROFILE_ALTERNATE_PHONE_NUMBER: 'Alternate Phone #',
    DEBTOR_PROFILE_PROVINCE_MISSING_TEXT: 'Province is required to proceed',
    DEBTOR_PROFILE_CREATE_NEW_APPLICATION: 'Create New Application',
    DEBTOR_PROFILE_ENTER_EMAIL: 'Please enter an email address since the client login is enabled',
    DEBTOR_PROFILE_ADDRESS: 'Street Number and Name',
    DEBTOR_PROFILE_PREFERRED_LANGUAGE: 'Preferred language',
    DEBTOR_PROFILE_NAME_SURNAME_PHONE_DUPLICATE:
      'There is an existing prospect/debtor with the same first name, last name and main phone #. Please check and try again.',
    DEBTOR_PROFILE_NAME_SURNAME_EMAIL_DUPLICATE:
      'There is an existing prospect/debtor with the same first name, last name and email address. Please check and try again.',
    DEBTOR_PROFILE_EMAIL_DUPLICATE:
      'There is an existing prospect/debtor with the same email address. Please check and try again.',
    ADDRESS: 'Address',
    NETWORK_ERROR: 'Network Error',
    NO_DATA_FOUND: 'No data found',
    RETRY: 'Retry',
    SEND_RESET_PASSWORD: 'Send Reset Password Email',
    SEND_REGISTRATION_PASSWORD: 'Send Registration Email',
    PORTAL_NOTIFICATION: 'Portal Notification',
    CLIENT_REGISTERED: 'Client Registered',
    ADD_SOURCE: 'Add Source',
    ADD_NUMBER: 'Add Number',
    DNE: 'DNE',
    DNC: 'DNC',
    SMS: 'SMS',
    DONOTSMS: 'Do Not SMS',
    APPOINTMENTS: 'Appointment(s)',
    APPOINTMENT_SCHEDULING: 'Appointment',
    APPOINTMENT_SCHEDULING_APPOINTMENT_TYPE: 'Appointment Type',
    APPOINTMENT_SCHEDULING_LOCATION: 'Location',
    APPOINTMENT_SCHEDULING_AVAILABLE_DATES: 'Available Dates',
    APPOINTMENT_SCHEDULING_DATE: 'Date',
    APPOINTMENT_SCHEDULING_MEETING_TYPE: 'Meeting Type',
    APPOINTMENT_SCHEDULING_AVAILABLE_APPOINTMENTS: 'Available Appointments',
    APPOINTMENT_SCHEDULING_SCHEDULE_APPOINTMENT: 'Schedule Appointment',
    APPOINTMENT_SCHEDULING_VIEW_APPOINTMENT: 'View Appointment',
    APPOINTMENT_SCHEDULING_AGENTS: 'Agents',
    APPOINTMENT_SCHEDULING_SUMMARY: 'Summary',
    APPOINTMENT_SCHEDULING_SUMMARY_TYPE: 'Type',
    APPOINTMENT_SCHEDULING_SUMMARY_TIME: 'Time',
    APPOINTMENT_SCHEDULING_SUMMARY_DATE: 'Date',
    APPOINTMENT_SCHEDULING_SCHEDULE: 'Schedule',
    APPOINTMENT_SCHEDULING_SCHEDULING_NOTE: 'Scheduling Note',
    APPOINTMENT_SCHEDULING_STATUS: 'Status',
    APPOINTMENT_ADD: 'Add appt.',
    APPOINTMENT_MEETING_TYPE_IN_PERSON: 'In Person',
    APPOINTMENT_MEETING_TYPE_PHONE_VIDEO: 'Phone/Video',
    APPOINTMENT_MEETING_TYPE_PHONE: 'Phone',
    APPOINTMENT_CREATED_SUCCESS: 'Appointment created',
    APPOINTMENT_UPDATED_SUCCESS: 'Appointment updated',

    APPOINTMENTS_HISTORY: 'Appointment History',

    HEADER_SEARCH_PLACEHOLDER: 'Search estate, name, status',
    DEBTOR_SEARCH: 'Debtor Search',
    DEBTOR_SEARCH_ADVANCED_SEARCH: 'Advanced search',
    DEBTOR_SEARCH_HIDE_ADVANCED_SEARCH: 'Hide advanced search',
    DEBTOR_SEARCH_FILING_TYPE: 'Filing Type',
    DEBTOR_SEARCH_SERVICE_LOCATION: 'Service Location',
    DEBTOR_SEARCH_TRUSTEE: 'Trustee',
    DEBTOR_SEARCH_ADMIN: 'Admin',
    DEBTOR_SEARCH_STATUS: 'Status',
    DEBTOR_SEARCH_STAGE: 'Stage',
    DEBTOR_SEARCH_EMAIL: 'Email',
    DEBTOR_SEARCH_ESTATE: 'Estate',

    APPLICATION_OVERVIEW: 'Application Overview',
    APPLICATION_OVERVIEW_FILESTAGE: 'Stage',
    APPLICATION_OVERVIEW_FILENAME: 'FN',
    APPLICATION_OVERVIEW_FILENAME_TOOLTIP: 'Filename',
    APPLICATION_OVERVIEW_ESTATE: 'E',
    APPLICATION_OVERVIEW_ESTATE_TOOLTIP: 'Estate #',
    APPLICATION_OVERVIEW_SERVICELOCATION: 'SL',
    APPLICATION_OVERVIEW_SERVICELOCATION_TOOLTIP: 'Service Location',
    APPLICATION_OVERVIEW_AGENT: 'A',
    APPLICATION_OVERVIEW_AGENT_TOOLTIP: 'Agent',
    APPLICATION_OVERVIEW_ESTATEADMIN: 'EA',
    APPLICATION_OVERVIEW_ESTATEADMIN_TOOLTIP: 'Estate Administrator',
    APPLICATION_OVERVIEW_TRUSTEE: 'T',
    APPLICATION_OVERVIEW_TRUSTEE_TOOLTIP: 'Trustee',
    APPLICATION_OVERVIEW_FILING_TYPE: 'FT',
    APPLICATION_OVERVIEW_FILING_TYPE_TOOLTIP: 'Filing Type',

    APPLICATION_OVERVIEW_OVERVIEW_TAB: 'Overview',
    APPLICATION_OVERVIEW_CLIENT_INFO_TAB: 'Client Info',
    APPLICATION_OVERVIEW_ADMIN_INFO_TAB: 'Admin Info',
    APPLICATION_OVERVIEW_ANALYSIS_TAB: 'Analysis',
    APPLICATION_OVERVIEW_DOCUMENTS_TAB: 'Documents',

    APPLICATION_OVERVIEW_TASKS: 'Tasks',
    APPLICATION_OVERVIEW_NOTES: 'Notes',
    APPLICATION_OVERVIEW_APPOINTMENTS: 'Appointments',

    APPLICATION_FORM_STATUS_COMPLETE: 'Complete',
    APPLICATION_FORM_STATUS_NOT_STARTED: 'Not started',

    APPLICATION_CHECKLIST_COMPLETE: 'Complete',
    APPLICATION_CHECKLIST_INCOMPLETE: 'Incomplete',

    APPLICATION_FILE_STAGE_IN_PROGRESS: 'In progress',
    APPLICATION_FILE_STAGE_COMPLETE: 'Complete',
    APPLICATION_FILE_STAGE_NOT_STARTED: 'Not started',

    RECEIPT: 'Receipt',
    RECEIPT_HISTORY: 'Receipt history',

    RECEIPT_NEW: 'New Receipt',
    RECEIPT_VOID: 'Void',
    RECEIPT_SAVE: 'Issue',
    RECEIPT_CANCEL: 'Cancel',

    EMAIL_HISTORY: 'Email History',
    EMAIL_NEW: 'New Email',
    EMAIL_VIEW: 'View Email',
    EMAIL_SENT: 'Sent Email',
    EMAIL: 'Email',
    EMAIL_ATTACHMENTS: 'Attachments',
    EMAIL_TO: 'To email',
    EMAIL_FROM: 'From email',
    EMAIL_SUBJECT: 'Email subject',
    EMAIL_BODY: 'Email body',
    EMAIL_TEMPLATE: 'Email template',
    EMAIL_SENT_SUCCESS: 'Email sent successfully',

    SUBJECT: 'Subject',
    TEMPLATE: 'Template',
    MESSAGE: 'Message',

    SMS_HISTORY: 'SMS History',
    SMS_NEW: 'New SMS',
    SMS_VIEW: 'View SMS',
    SMS_SENT: 'Sent SMS',
    SMS_TO_MOBILE: 'To mobile',

    CREDIT_REPORT_HISTORY: 'Credit Report History',
    REQUEST_CREDIT_REPORT: 'Request Credit Report',
    CREDIT_REPORT: 'Credit Report',

    NOTE_CREATE_SUCCESS: 'Note created',
    NOTE_UPDATE_SUCCESS: 'Note updated',
    NOTE_DELETE_SUCCESS: 'Note deleted',
    NOTES_HISTORY: 'Notes history',
    NOTE_ADD: 'Add note',
    NOTE_DELETE_CONFIRMATION: 'Do you want to Delete?',

    REQUIRED_FIELD: 'This field is mandatory',

    TASK_PROCESS: 'Process',
    TASK_STAGE: 'Stage',
    TASK_NAME: 'Task name',
    TASK_DESCRIPTION: 'Description',
    TASK_ASSIGN: 'Assign',
    TASK_USERS: 'Users',
    TASK_TEAM: 'Team',
    TASK_NEW: 'Add task',
    TASK_STATUS: 'Status',
    TASK_OPENED: 'Opened',
    TASK_CLOSED: 'Closed',
    TASK: 'Task',
    TASKS_HISTORY: 'Tasks History',
    ACTION_NEW: 'Add action',

    ATTACHMENT_NEW: 'New',
    ATTACHMENT_EXISTING: 'Existing',
    ATTACHMENT: 'Attachment',
    ATTACHMENT_SEARCH: 'Search file',

    RECEIPT_PAYMENT_DATE: 'Payment date',
    RECEIPT_PER: 'Per',
    RECEIPT_LOCATION: 'Location',
    RECEIPT_PAYMENT_AMOUNT: 'Payment amount',
    RECEIPT_PAYMENT_MADE_BY: 'Payment made by',
    RECEIPT_PAYMENT_METHOD: 'Payment method',
    RECEIPT_PAYMENT_METHOD_DETAIL: 'Payment detail',
    RECEIPT_POST_TO: 'Post to',

    RECEIPT_OFFICE_LOCATION: 'Location',

    RECEIPT_PAYMENTMETHOD_CASH: 'Cash',
    RECEIPT_PAYMENTMETHOD_CHEQUE: 'Cheque',
    RECEIPT_PAYMENTMETHOD_CHEQUE_NUMBER: 'Cheque no',
    RECEIPT_PAYMENTMETHOD_OTHER: 'Other',

    RECEIPT_POSTTYPE_PREFILING: 'Pre-file account',

    RECEIPT_POSTTYPE_PREFILL: 'Pre-fill',
    RECEIPT_POSTTYPE_ESTATE: 'Estate',
    RECEIPT_POSTTYPE_HOLDING: 'Holding',

    RECEIPT_ERROR_INVALID_PAYMENT_METHOD: 'Please select a payment method',

    RECEIPT_ERROR_INCORRECT_DENOMINATION:
      'The total denomination values is not the same as the payment amount. Please check and try again',
    RECEIPT_ERROR_INVALID_CHEQUE_NUMBER: 'Please enter a valid cheque number',
    RECEIPT_ERROR_CASH_AMOUN_EXCEEDED:
      'The payment amount cannot be greater than $2,500.00 as the payment method is cash. Please check and try again.',

    RECEIPT_VOID_CONFIRM_TITLE: 'Confirm void receipt',
    RECEIPT_VOID_CONFIRM_MESSAGE: 'Are you sure you want to void this receipt?',
    RECEIPT_VOID_CONFIRM_POSITIVE: 'Yes',
    RECEIPT_VOID_CONFIRM_NEGATIVE: 'No',

    ASSETS_CURRENT_MARKET_VALUE: 'Current market value',
    ASSETS_ESTIMATED_RESALE_VALUE: 'Estimated re-sale value',

    EXPENSE_DELETE_CONFIRMATION_TITLE: 'Delete Expense?',
    EXPENSE_DELETE_CONFIRMATION_MESSAGE: 'Are you sure you want to delete this Expense?',

    INCOME_DELETE_CONFIRMATION_TITLE: 'Delete Income?',
    INCOME_DELETE_CONFIRMATION_MESSAGE: 'Are you sure you want to delete this Income?',

    DEBT_DELETE_CONFIRMATION_TITLE: 'Delete Debt?',
    DEBT_DELETE_CONFIRMATION_MESSAGE: 'Are you sure you want to delete this Debt?',

    ASSET_DELETE_CONFIRMATION_TITLE: 'Delete Asset?',
    ASSET_DELETE_CONFIRMATION_MESSAGE: 'Are you sure you want to delete this Asset?',

    UPLOAD_FILE_SIZE_ERROR: 'The file size of the attachment should not be greater than 5 MB',
    UPLOAD_FILES_SIZE_ERROR: 'The size of all attachments should not be greater than 15 MB',

    UNSAVED_CHANGED_TITLE: 'Unsaved changes',
    UNSAVED_CHANGED_MESSAGE: 'Any unsaved changes in the form will be lost. Do you want to leave anyways?',
    DOCUMENTS: 'Documents',
    DOCUMENTS_ALL: 'All Documents',
    DOCUMENTS_REVIEW_APPROVAL: 'Review & Approval',
    DOCUMENTS_OVERVIEW: 'Overview',
    DOCUMENTS_NAME: 'Name',
    DOCUMENTS_FOLDER: 'Folder',
    DOCUMENTS_PREFIX: 'Pre-fix',
    DOCUMENTS_UPLOADED: 'Uploaded',
    DOCUMENTS_MODIFIED: 'Modified',
    DOCUMENTS_SIZE: 'Size',
    DOCUMENTS_FOR_SPOUSE: 'For spouse',
    DOCUMENTS_FILE_SIZE: 'File size',
    DOCUMENTS_TYPE: 'Type',
    DOCUMENTS_STATUS: 'Status',
    DOCUMENTS_VISIBLE: 'Visible',
    DOCUMENTS_DUE: 'Due',
    DOCUMENTS_URGENT: 'Urgent',
    DOCUMENTS_FILE_TYPE: 'File type',
    DOCUMENTS_RECOVER_DELETED: 'Recover deleted',
    DOCUMENTS_UPLOAD_FILE: 'Upload file',
    DOCUMENTS_ERROR_NO_ASSIGNEES: 'Please select at least one reviewer(s), approver(s) or signee(s) to proceed',
    DOCUMENTS_ERROR_SAME_AGENT:
      'Same agents cannot be assigned as a reviewer, approver or signee for the same file. Please check and try again',
    DOCUMENTS_FILENAME: 'File name',
    DOCUMENTS_REVIEWERS: 'Reviewer(s)',
    DOCUMENTS_APPROVERS: 'Approver(s)',
    DOCUMENTS_SIGNEES: 'Signee(s)',
    DOCUMENTS_DUE_DATE: 'Due date',
    DOCUMENTS_COMMENTS: 'Comments',
    DOCUMENTS_DEBTOR_VISIBILITY: 'Enable debtor visibility',
    DOCUMENTS_DEBTOR_VISIBILITY_DISABLE: 'Disable debtor visibility',
    DOCUMENTS_DOWNLOAD: 'Download',
    DOCUMENTS_ADDITIONAL_INFORMATION: 'Additional information',
    DOCUMENTS_ADDITIONAL_DETAILS: 'Additional details',
    DOCUMENTS_BUNDLE_SUCCESS:
      'Your bundle request is being processed. A notification will be sent to you once the bundle is complete',
    DOCUMENTS_ERROR_IS_ALREADY_VISIBLE:
      'One or more of the selected file is already visible to the debtor. Please check and try again',
    DOCUMENTS_VERSION: 'Version',
    DOCUMENTS_MODIFIED_BY: 'Modified by',
    DOCUMENTS_MODIFIED_ON: 'Modified on',
    DOCUMENTS_REVIEW_STATUS: 'Review and approval status',
    DOCUMENTS_REQUEST_REVIEW: 'Request review',
    DOCUMENTS_SEND_FOR_REVIEW: 'Send for review',
    DOCUMENTS_INVALID_TYPE_MESSAGE: `Invalid file format. Valid file formats are pdf, doc, docx, xls, xsls, csv, ppt, msg, jpg, jpeg, bmp, png, gif, heic, avif, tiff and webp. Please check and try again.`,

    ADMINISTRATION: 'Administration',
    FILE_ANALYSIS_CONFIGURATION: 'File analysis configuration',
    PROVINCIAL_EXEMPTION: 'Provincial Exemption Amounts And Tax Rates',
    SURPLUS_INCOME: 'Surplus Income',
    NORTHWEST_TERRITORY_MULTIPLES: 'Northwest Territory Multiples',
    OSB_FEES: 'OSB and Other Fees',
    REAL_ESTATE_FEES: 'Real Estate Fees',
    CONFIGURATION_CHANGE_SUCCESS: 'Configuration changes are saved',

    FILE_ANALYSIS: 'File Analysis',
    FILE_ANALYSIS_ESTIMATED_REALIZATIONS: 'Estimated Realizations',
    FILE_ANALYSIS_FORM_48: 'Form 48',
    FILE_ANALYSIS_CALCULATIONS: 'Calculations',
    FILE_ANALYSIS_SURPLUS_INCOME: 'Surplus Income',
    FILE_ANALYSIS_FEES: 'Fees',
    FILE_ANALYSIS_PROPOSAL_DETAILS: 'Proposal Details',
    FILE_ANALYSIS_BANKRUPTCY_DETAILS: 'Bankruptcy Details',
    FILE_ANALYSIS_TRUSTEE_REPORT_DETAILS: 'Trustee Report Details',
    FILE_ANALYSIS_ASSETS: 'Assets',
    FILE_ANALYSIS_DEBTS_CREDITORS: 'Debts/Creditors',
    FILE_ANALYSIS_SURPLUS_INCOME_INFO: 'Surplus Income Information',

    ADMIN_INFO_PROCESS_GENERAL: 'General',
    ADMIN_INFO_PROCESS_SIGNUP: 'Sign-up',
    ADMIN_INFO_PROCESS_COUNSELLING: 'Counselling',
    ADMIN_INFO_PROCESS_CLAIMS: 'Claims',
    ADMIN_INFO_PROCESS_ASSET_REALIZATION: 'Asset Realization',
    ADMIN_INFO_PROCESS_BANKING: 'Banking',
    ADMIN_INFO_PROCESS_INCOME_AND_EXPENSE: 'Income and Expense',
    ADMIN_INFO_PROCESS_TAX_RETURN: 'Tax Return',
    ADMIN_INFO_PROCESS_DISCHARGE: 'Discharge',
    ADMIN_INFO_PROCESS_RECEIPTS_AND_DISBURSEMENTS: 'Receipts and Disbursements',
    ADMIN_INFO_PROCESS_PROPOSAL: 'Proposal',
    ADMIN_INFO_PROCESS_45DAY_REVIEW: '45 Day Review',
    // ADMIN_INFO_PROCESS_CREDITORS_MEETING: 'Creditors Meeting',
    // ADMIN_INFO_PROCESS_TRANSFER: 'Transfer',

    ADMIN_INFO_GENERAL_DETAILS: 'General details',
    ADMIN_INFO_AGENT: 'Agent',
    ADMIN_INFO_ESTATE_ADMINISTRATOR: 'Estate Administrator',
    ADMIN_INFO_TRUSTEE: 'Trustee',
    ADMIN_INFO_INTERVIEWER: 'Interviewer',
    ADMIN_INFO_FILING_TYPE: 'Filing type',
    ADMIN_INFO_SERVICE_LOCATION: 'Service location',
    ADMIN_INFO_FILE_NAME: 'File name',
    ADMIN_INFO_INSOLVENCY_DATE: 'Insolvency date',
    ADMIN_INFO_ESTATE_NUMBER: 'Estate number',
    ADMIN_INFO_DURATION_OF_BANKRUPTCY: 'Duration of bankruptcy',
    ADMIN_INFO_UPDATE_FILING_TYPE: 'Update filing type',

    ADMIN_INFO_CLAIMS_STATUS: 'Claim process status',

    ADMIN_INFO_CLAIMS_CLAIMFIELDS_TYPE: 'Type',
    ADMIN_INFO_CLAIMS_CLAIMFIELDS_ACCOUNT: 'Account',
    ADMIN_INFO_CLAIMS_CLAIMFIELDS_DEBT: 'Debt',
    ADMIN_INFO_CLAIMS_CLAIMFIELDS_LETTER_SENT: 'Letter sent',
    ADMIN_INFO_CLAIMS_CLAIMFIELDS_COMPLETED: 'Completed',

    ADMIN_INFO_ASSET_REALIZATION_STATUS: 'Asset realization status',

    ADMIN_INFO_ASSET_REALIZATION_NO_ASSET_SECURED_CLAIM: 'No Asset secured claim',

    ADMIN_INFO_AR_ASSETFIELDS_DESC: 'Desc.',
    ADMIN_INFO_AR_ASSETFIELDS_EST: 'Est.',
    ADMIN_INFO_AR_ASSETFIELDS_REAL: 'Real.',
    ADMIN_INFO_AR_ASSETFIELDS_COMPLETED: 'Completed',
    ADMIN_INFO_AR_ASSETFIELDS_EXEMPT: 'Exempt',

    ADMIN_INFO_AR_CLAIMBYASSETFIELDS_CREDITOR_INFO: 'Creditor information',
    ADMIN_INFO_AR_CLAIMBYASSETFIELDS_SECURED: 'Secured',

    ADMIN_INFO_INCOME_EXPENSE_STATUS: 'Income & Expense status',

    ADMIN_INFO_IANDE_SCHEDULEFIELDS_PERIOD: 'Period',
    ADMIN_INFO_IANDE_SCHEDULEFIELDS_DUE: 'Due',
    ADMIN_INFO_IANDE_SCHEDULEFIELDS_ESTIMATED_SURPLUS: 'Est. Surplus',
    ADMIN_INFO_IANDE_SCHEDULEFIELDS_STATUS: 'Status',

    ADMIN_INFO_TAX_RETURN_STATUS: 'Tax return status',

    ADMIN_INFO_TR_APPLICANT: 'Applicant',
    ADMIN_INFO_TR_SPOUSE: 'Spouse',
    ADMIN_INFO_TR_YEAR_LAST_RETURN_FILED: 'Year last return filed',
    ADMIN_INFO_TR_REFUND_AMOUNT: 'Refund amount',
    ADMIN_INFO_TR_AMOUNT_OWING: 'Amount owing',

    ADMIN_INFO_TR_TAXRETURNFIELDS_SOURCE: 'Source',
    ADMIN_INFO_TR_TAXRETURNFIELDS_ESTIMATED_AMOUNT: 'Est.',
    ADMIN_INFO_TR_TAXRETURNFIELDS_DEPOSITED: 'Dep.',
    ADMIN_INFO_TR_TAXRETURNFIELDS_TAX_STATUS: 'Tax Status',
    ADMIN_INFO_TR_TAXRETURNFIELDS_ASCEND: 'Ascend',
    ADMIN_INFO_TR_TAXRETURNFIELDS_DISPOSITION: 'Disp.',
    ADMIN_INFO_TR_TAXRETURNFIELDS_COMPLETE: 'Complete',

    ADMIN_INFO_DETAILS_TABLE_COL_RECEIVED: 'Received',
    ADMIN_INFO_DETAILS_TABLE_COL_ASSIGNED_TO: 'Assigned to',
    ADMIN_INFO_DETAILS_TABLE_COL_STATUS: 'Status',
    ADMIN_INFO_DETAILS_TABLE_COL_DISCREPANCY: 'Discrepancy',
    ADMIN_INFO_DETAILS_TABLE_COL_STATE: 'State',
    ADMIN_INFO_DETAILS_TABLE_COL_RESULT: 'Result',
    ADMIN_INFO_DETAILS_TABLE_COL_ACTUAL_SURPLUS: 'Actual surplus',

    PROFILE: 'Profile',
    MY_PROFILE: 'My Profile',
    AVAILABILITY: 'Availability',

    NEWVIEW_SUBTITLE_NEW_CLAIM: 'New Claim',
    NEWVIEW_SUBTITLE_VIEW_CLAIM: 'View Claim',
    NEWVIEW_SUBTITLE_VIEW_SECURED_CLAIM: 'View Secured Claim',
    NEWVIEW_SUBTITLE_VIEW_UNSECURED_CLAIM: 'View Unsecured Claim',
    NEWVIEW_SUBTITLE_NEW_INCOME_AND_EXPENSE: 'New Income & Expense',
    NEWVIEW_SUBTITLE_VIEW_INCOME_AND_EXPENSE: 'View Income & Expense',
    NEWVIEW_SUBTITLE_NEW_TAX_RETURN: 'New Tax Return',
    NEWVIEW_SUBTITLE_VIEW_TAX_RETURN: 'View Tax Return',

    EMAIL_FIELD_FROM: 'From',
    EMAIL_FIELD_TO: 'To',
    EMAIL_FIELD_SUBJECT: 'Subject',
    EMAIL_FIELD_BODY: 'Body',

    FILEARRAY_PREVIEW_FILE: 'File',
    FILEARRAY_PREVIEW_RECEIVED: 'Received',

    SAVING: 'Saving',
    SUCCESSFULLY_SAVED: 'Successfully Saved',

    FORM_GENERIC_ERROR_SUBMITTING: 'There was an error submitting your form data. Please try again later.',

    TABS_NO_CONTENT_DEFINED: 'No content defined',

    PLEASE: 'Please',
    ENTER_PARTIAL: 'enter',
    SELECT_PARTIAL: 'select',
    CHOOSE_PARTIAL: 'choose',
    PICK_PARTIAL: 'pick',
    ONE_OR_MORE_PARTIAL: 'one or more',
    ONLY_HAS_SPACES_PARTIAL: 'only has spaces',
    // TO_ACCESS_THE_PARTIAL: 'To access the',
    // NEW_PARTIAL: 'new',
    // DUPLICATE_PARTIAL: 'duplicate',
    // FILE_THAT_WAS_CREATED_PARTIAL: 'file that was created',
    CLICK_HERE_PARTIAL: 'click here',

    TO_ACCESS_THE_FILE_THAT_WAS_CREATED: 'To access the file that was created',
    TO_ACCESS_NEW_FILE_THAT_WAS_CREATED: 'To access the new file that was created',
    TO_ACCESS_DUPLICATE_FILE_THAT_WAS_CREATED: 'To access the duplicate file that was created',

    ENTER_VALUE: 'enter a value',
    ENTER_REQUIRED_TEXT: 'enter the required text',
    MAKE_A_SELECTION: 'make a selection',
    MAKE_YOUR_SELECTION: 'make your selection(s)',
    SELECT_THE_DATE: 'select the date(s)',

    FILING_TYPE_UPDATED_IN_FILE: 'The filing type was updated in the current file.',
    STAGE_UPDATED_TO_STATUS_CHANGED: 'The stage of the current file has been updated to "Status Changed"',

    NEW_FILE_HAS_BEEN_CREATED: 'a new file has been created',
    DUPLICATE_FILE_HAS_BEEN_CREATED: 'a duplicate file has been created',

    CREATE_ESTATE_IN_ASCEND_FOR_DUPLICATE_FILE:
      'Please ensure that a new estate is created in Ascend for the duplicate file.',
    CREATE_ESTATE_IN_ASCEND_FOR_NEW_FILE: 'Please ensure that a new estate is created in Ascend for the new file.',

    ASCEND_SEARCH_SUBMIT_ERROR: 'There was an error in submitting your search. Please try again later.',

    ASCEND_ESTATE_NUMBER: 'Estate number',
    ASCEND_SIN: 'SIN',
    ASCEND_DEBTOR_NAME: 'Debtor name',
    ASCEND_DATE_OF_INSOLVENCY: 'Date of insolvency',
    ASCEND_CREDITOR_NAME: 'Creditor name',
    ASCEND_ACCOUNT_NUMBER: 'Account number',
    ASCEND_MERGE_CREDITOR_EMAIL: 'Merge creditor email',
    ASCEND_NUMBER_OF_HOUSEHOLD: 'No. of household',
    ASCEND_SELECT_INCOME_AND_EXPENSE: 'Select income & expense',
    ASCEND_SUBMISSION: 'Submission',
    ASCEND_TAX_PERIOD: 'Tax Period',

    ASCEND_LINK_TO_ESTATE: 'Link to Estate',

    ASCEND_BUTTON_SEARCH_ASCEND: 'Search Ascend',
    ASCEND_BUTTON_CONFIRM: 'Confirm',

    ASCEND_AT_LEAST_TWO_FIELDS: 'To search, please provide values for at least two of the following four fields.',
    ASCEND_AT_LEAST_ONE_FIELD: 'To search, please provide a value for at least one of the following fields.',

    ASCEND_ERROR__ERROR_TRYING_TO_VALIDATE: 'An error was encountered when trying to validate your submission.',
    ASCEND_ERROR__NO_FIELDS_HAVE_VALUE: 'No form fields contain a value.',
    ASCEND_ERROR__ONE_OR_MORE_FIELDS_MISSING: 'At least one required form fields is missing a value.',
    ASCEND_ERROR__ONE_OR_MORE_FIELDS_MISSING_NO_HOUSEHOLD: 'No. of household is required.',
    ASCEND_ERROR__ONE_OR_MORE_FIELDS_MISSING_INCOME_AND_EXPENSE: 'Select income & expense is required.',
    ASCEND_ERROR__ONE_OR_MORE_FIELDS_MISSING_HOUSEHOLD_INCOME_AND_EXPENSE:
      'No. of household and Income & expense is required.',

    ASCEND_ERROR__NOT_ENOUGH_FIELDS:
      'To search, please provide values for the required number of fields, as specified below.',

    ASCEND_ABOUT_TO_LINK_CLAIM:
      'You are about to link this case file with the selected estate and liability which cannot be reversed. Do you want to continue?',
    ASCEND_ABOUT_TO_LINK_I_AND_E:
      'You are about to link this I&E submission with the selected file and I&E period which cannot be reversed. Do you want to continue?',
    ASCEND_MARK_AS_ACTIVE_Q: 'Mark as Active?',
    ASCEND_OPTION_ACTIVE: 'Active',
    ASCEND_OPTION_INACTIVE: 'Inactive',
    ASCEND_OPTION_DUPLICATE: 'Duplicate',

    CLAIMS_CLAIM_PROCESS: 'Claim Process',
    CLAIMS_CREDITOR_EMAIL: 'Creditor email',
    CLAIMS_STAGE_CLAIM: 'Claims',

    NEW_CLAIM_MOVE_CLAIM_TO: 'Move claim to',
    NEW_CLAIM_CLAIM_STATUS: 'Claim status',

    VIEW_CLAIM_RESULT: 'Result',
    VIEW_CLAIM_DISCREPANCY: 'Discrepancy',
    VIEW_CLAIM_DISCREPANCY_CONFIRMATION: 'Discrepancy Confirmation Required',
    VIEW_CLAIM_DISCREPANCY_CONFIRMATION_MESSAGE:
      'The difference between the claim amount and SOA to not be more than 25%. Please select Yes if you would like to complete the claim or select No.',
    VIEW_CLAIM_CREDITOR_EMAIL_TEMPLATE: 'Creditor Email Template',
    VIEW_CLAIM_SOA: 'SOA',
    VIEW_CLAIM_CLAIM_AMOUNT_FILED: 'Claim amount/filed',
    VIEW_CLAIM_CLAIM_VOTING: 'Claim voting',
    VIEW_CLAIM_MEETING_REQUESTED: 'Meeting requested',
    VIEW_CLAIM_VOTED_BY: 'Voted by',
    VIEW_CLAIM_CLAIM_AMOUNT_MATCHES: 'Claim Amount Matches?',
    VIEW_CLAIM_SECURITY_AGREEMENT: 'Security Agreement',
    VIEW_CLAIM_SECURITY_REGISTRATION: 'Security Registration',
    VIEW_CLAIM_VALIDATE_REG_DESCRIPTION: 'Validate Reg. Description',
    VIEW_CLAIM_SECURITY_REGISTRATION_DATE: 'Security Registration Date',
    VIEW_CLAIM_IS_THERE_AN_EQUITY: 'Is there an Equity?',
    VIEW_CLAIM_170_REPORT_REQUESTED: '170 Report Requested',

    VIEW_CLAIM_CLAIM_VOTING__FOR: 'For',
    VIEW_CLAIM_CLAIM_VOTING__AGAINST: 'Against',
    VIEW_CLAIM_CLAIM_VOTING__DID_NOT_VOTE: 'Did not vote',

    VIEW_CLAIM_VOTED_BY__IN_PERSON: 'In Person',
    VIEW_CLAIM_VOTED_BY__LETTER: 'Letter',
    VIEW_CLAIM_VOTED_BY__PROXY: 'Proxy',

    VIEW_CLAIM_SECURITY_AGREEMENT__AVAILABLE: 'Available',
    VIEW_CLAIM_SECURITY_AGREEMENT__NOT_AVAILABLE: 'Not available',

    VIEW_CLAIM_VALIDATE_REG_DESCRIPTION__MATCHES: 'Matches',
    VIEW_CLAIM_VALIDATE_REG_DESCRIPTION__DOES_NOT_MATCH: 'Does not match',

    VIEW_CLAIM_ERROR__AT_LEAST_ONE_FILE:
      'Secured claim checklist document should be uploaded to complete the claim process. Please check and try again.',

    VIEW_CLAIM_CONFIRM_DELETE_CLAIM: 'You are about to delete an imported claim. Do you want to continue?',

    TAXRETURN_TAX_RETURN_PROCESS: 'Tax Return Process',
    TAXRETURN_TAX_RETURN_EMAIL: 'Tax Return email',
    TAXRETURN_TAX_RETURN_INFO: 'Tax Return Info',
    TAXRETURN_STAGE_TAX_RETURN: 'Tax Return',

    VIEW_TAXRETURN_RESULT: 'Result',
    VIEW_TAXRETURN_DISCREPANCY: 'Discrepancy',
    VIEW_TAXRETURN_ADDITIONAL_PROCESS: 'Additional Process',
    VIEW_TAXRETURN_ADDITIONAL_PROCESS_INSTRUCTIONS: 'Additional Process - Instructions',
    VIEW_TAXRETURN_COMMENTS_FOR_CLIENT_PORTAL: 'Comments for Client Portal',
    VIEW_TAXRETURN_DATE_FILED: 'Date filed',
    VIEW_TAXRETURN_REMINDER_DATE: 'Reminder date',
    VIEW_TAXRETURN_ESTIMATED: 'Estimated',
    VIEW_TAXRETURN_SOURCE: 'Source',
    VIEW_TAXRETURN_ASSESSMENT_DATE: 'Assessment date',
    VIEW_TAXRETURN_STATUS: 'Status',
    VIEW_TAXRETURN_DISPOSITION_DATE: 'Disposition Date',
    VIEW_TAXRETURN_DISPOSITION: 'Disposition',

    INCOMEEXPENSE_INCOME_EXPENSE_PROCESS: 'Income & Expense Process',
    INCOMEEXPENSE_INCOME_EXPENSE_EMAIL: 'Income & Expense email',
    INCOMEEXPENSE_INCOME_EXPENSE_INFO: 'I&E Info',
    INCOMEEXPENSE_STAGE_INCOME_EXPENSE: 'Income and Expense',

    VIEW_INCOMEEXPENSE_RESULT: 'Result',
    VIEW_INCOMEEXPENSE_MISSING_INFO: 'Missing Information',
    VIEW_INCOMEEXPENSE_DISCREPANCY: 'Discrepancy',
    VIEW_INCOMEEXPENSE_AMENDMENT_REQUIRED: 'Amendment Required',
    VIEW_INCOMEEXPENSE_COMMENTS_FOR_CLIENT_PORTAL: 'Comments for Client Portal',
    VIEW_INCOMEEXPENSE_SUPERINTENDENTS_STANDARD: "Superintendent's Standard",
    VIEW_INCOMEEXPENSE_STANDARD: 'Standard',
    VIEW_INCOMEEXPENSE_CUSTOM: 'Custom',
    VIEW_INCOMEEXPENSE_BANKRUPTS_INCOME: "Bankrupt's Income",
    VIEW_INCOMEEXPENSE_SPOUSES_INCOME: "Spouse's Income",
    VIEW_INCOMEEXPENSE_OTHER_INCOME: 'Other Income',
    VIEW_INCOMEEXPENSE_NONDISCRETIONARY_EXPENSES: 'Non-Discretionary Expenses',
    VIEW_INCOMEEXPENSE_AVAILABLE_FAMILY_INCOME: 'Available Family Income',
    VIEW_INCOMEEXPENSE_TOTAL_MONTHLY_SURPLUS: 'Total Monthly Surplus',
    VIEW_INCOMEEXPENSE_BANKRUPT_PORTION: 'Bankrupt Portion',
    VIEW_INCOMEEXPENSE_PERCENTAGE_OF_SURPLUS: 'Percentage of Surplus',
    VIEW_INCOMEEXPENSE_BANKRUPTS_PORTION_OF_SURPLUS: "Bankrupt's Portion of Surplus",
    VIEW_INCOMEEXPENSE_BANKRUPTS_NET_SURPLUS: "Bankrupt's Net Surplus",
    VIEW_INCOMEEXPENSE_CALCULATED_SURPLUS_PAYMENT: 'Calculated Surplus Payment',
    VIEW_INCOMEEXPENSE_ESTIMATED_SURPLUS_PAYMENT: 'Estimated Surplus Payment',

    VIEW_INCOMEEXPENSE_INCOME: 'Income ($)',
    VIEW_INCOMEEXPENSE_EXPENSE: 'Expense ($)',
    VIEW_INCOMEEXPENSE_IGNORE_AND_COMPLETE: 'Ignore and Complete',
    VIEW_INCOMEEXPENSE_IGNORE_AND_COMPLETE_MSG:
      'The calculated surplus value varies from the estimated surplus by 10% and it is less than or equal to $100. Please select “Ignore and Complete” or “Cancel”.',

    ASCEND_ABOUT_TO_LINK_TAX_RETURN:
      'You are about to link this Tax Return submission with the selected estate and Tax Return which cannot be reversed. Do you want to continue?',
    TAXRETURN_PERIODS_ALREADY_HAVE_ACTIVE_SUBMISSION:
      'The following tax periods already have an "Active" submission linked. Please mark each period as "Active" or "Duplicate" to proceed.',

    EMAIL_CONFIRMATION_TITLE: 'Email confirmation',
    SENDING_EMAIL: 'Sending Email',

    INFO_FILENAME: 'File name',
    INFO_DESCRIPTION: 'Description',

    DATA_COULD_NOT_BE_RETRIEVED: 'Data could not be retrieved.',
    SOME_DATA_COULD_NOT_BE_RETRIEVED: 'Some data could not be retrieved.',
    NO_DATA_WAS_RETRIEVED: 'No data was retrieved.',
    EMAIL_TEMPLATE_COULD_NOT_BE_RETRIEVED: 'Email Template could not be retrieved.',

    ERROR: 'Error',
    LOCATIONS: 'Locations',

    ERRORS_CONTAINER_TITLE: 'Errors and Warnings',

    ERROR__REQUIRED_FIELDS_MISSING: 'Required fields are missing.',
    ERROR__TRYING_TO_CONFIRM: 'An error was encountered while to trying to Confirm.',
    ERROR__TRYING_TO_SAVE: 'An error was encountered while trying to Save.',
    ERROR__TRYING_TO_SEND_EMAIL: 'An error was encountered while trying to send the email.',
    ERROR__ERROR_WITH_REQUEST: 'There was an error with your request.',
    ERROR__ERROR_WITH_UPLOAD: 'An error was encountered while trying to upload your document.',
    ERROR__ERROR_GETTING_FORM_DATA: 'An error was encountered retrieving form data.',
    ERROR__FAILED_TO_SAVE_INCOME_ENTRIES: 'Failed to save Income entries.',
    ERROR__FAILED_TO_SAVE_EXPENSE_ENTRIES: 'Failed to save Expense entries.',

    BUTTON_SAVE_AS_DRAFT: 'Save as draft',
    BUTTON_SAVE_AS_DRAFT_WITH_ALERT: 'Save as draft with alert',
    BUTTON_COMPLETE: 'Complete',
    BUTTON_MARK_AS_COMPLETE: 'Mark as complete',
    BUTTON_MARK_AS_COMPLETE_WITH_ALERT: 'Mark as complete with alert',
    BUTTON_ADD_NEW_DEBT: 'Add new debt',
    BUTTON_REDUNDANT: 'Redundant',
    BUTTON_EDIT: 'Edit',

    NOTICE_DEEMED_ANNULEMENT:
      'Notice to Creditors and Report to Official Receiver on Deemed Annulment of Consumer Proposal - Filing',
    NOTICE_AUTOMATED_REVIVAL:
      'Notice to Creditors and to Official Receiver of Impending Automatic Revival of Consumer Proposal - Filing',
    NOTICE_NO_AUTOMATIC_REVIVAL:
      'Notice to Creditors and to Official Receiver of No Automatic Revival of Consumer Proposal - Filing',
    NOTICE_REVIVAL: 'Notice to Creditors and Report to Official Receiver of the Revival of Consumer Proposal - Filing',

    VISIBILITY_CHANGE_TITLE: 'Confirm visibility change',
    VISIBILITY_CHANGE_MESSAGE: 'You are about to change the selected file visibility. Do you want to continue?',
    VISIBILITY_CHANGE_ERROR: 'The visibility update failed',

    FILING_DATE_UPDATED: 'The filing date is updated',
    FILING_DATE_UPDATE_ERROR: 'The filing date update failed',

    DISCHARGE_170_PROCESS: '170 Process',
    DISCHARGE_OPPOSITION_LETTER: 'Opposition Letter',
    DISCHARGE_30_DAYS_NOTICE: '30 Days Notice',
    DISCHARGE_TYPE: 'Discharge Type',
    DISCHARGE_170_CHECKLIST: '170 Checklist',
    DISCHARGE_170_REPORT: '170 Report',
    DISCHARGE_170_REPORT_FILING: '170 Report filing',
    DISCHARGE_DISCHARGE_CERT: 'Discharge cert.',
    DISCHARGE_DISCHARGE_CERTIFICATE: 'Discharge Certificate',
    DISCHARGE_OPPOSITION: 'Opposition',
    DISCHARGE_FORM_80: 'Form 80',
    DISCHARGE_POSSIBLE_MEDIATION: 'Possible Mediation',
    DISCHARGE_INITIAL_COURT: 'Initial Court Discharge',
    DISCHARGE_MEDIATION: 'Mediation',
    DISCHARGE_MEDIATION_STATUS: 'Mediation Status',
    DISCHARGE_MEDIATION_MODE: 'Mode of Mediation',
    DISCHARGE_FORM_63: 'Form 63',
    DISCHARGE_SIGNED_AGREEMENT: 'Signed Agreement',
    DISCHARGE_COURT_DISCHARGE: 'Court Discharge',
    DISCHARGE_CHEQUE_REQUISITION: 'Cheque requisition',
    DISCHARGE_COURT: 'Court',
    DISCHARGE_RECOMMEND_LETTER: 'Recommend. letter',
    DISCHARGE_RECOMMENDATION_LETTER: 'Recommendation letter',
    DISCHARGE_CONDITIONAL_ORDER: 'Conditional Order',
    DISCHARGE_NOTIFY_COURT_APP: 'Notify Court app.',
    DISCHARGE_COMPLIANCE_NOTIF: 'Compliance notif.',
    DISCHARGE_CHEQUE_REQUISITION_HISTORY: 'Cheque Requisition History',
    DISCHARGE: 'Discharge',
    DISCHARGE_TOTAL_DUE: 'Total Due',
    DISCHARGE_TOTAL_PAYMENTS: 'Total Payments',
    DISCHARGE_STATUS: 'Discharge Status',
    DISCHARGE_COURT_APPEARANCE_REQUEST: 'Court appearance request',
    DISCHARGE_SELECT_TRUSTEE: 'Select trustee',
    DISCHARGE_COMMENTS: 'Comments',
    DISCHARGE_COURT_APPEARANCE_RESPONSE: 'Court appearance response',
    DISCHARGE_RESPONSE: 'Response',
    DISCHARGE_REVIEW_OPPOSITION_RESPONSE: 'Review opposition response',
    DISCHARGE_PENDING_PROCESS: 'Pending Process',
    DISCHARGE_OPPOSITION_COMMENTS: 'Opposition comments',
    DISCHARGE_OPPOSITION_DEBTOR_RESPONSE: 'Opposition debtor response',
    DISCHARGE_WITHDRAW_OPPOSITION: 'Withdraw opposition',
    RECEIPTS_DISBURSEMENTS_30_DAYS_NOTICE: '30 Days Notice',
    RECEIPTS_DISBURSEMENTS_PROPOSAL_COMPLETION: 'Completion of Proposal',
    RECEIPTS_DISBURSEMENTS_CERTIFICATE_FULL_PERFORMANCE: 'Certificate of Full Performance',
    RECEIPTS_DISBURSEMENTS_CERTIFICATE_FULL_PERFORMANCE_FILING: 'Certificate of Full Performance Filing',
    RECEIPTS_DISBURSEMENTS_FINAL_SRD: 'Final SRD',
    RECEIPTS_DISBURSEMENTS_SRD_REPORT: 'SRD Report',
    RECEIPTS_DISBURSEMENTS_SRD_REPORT_FILING: 'SRD Report filing',
    RECEIPTS_DISBURSEMENTS_FINAL_DIVIDENTS_NOTICES: 'Final Dividents & Notices',
    RECEIPTS_DISBURSEMENTS_NOTICE_LABEL_PROPOSAL:
      "Notice of Taxation of Administration of Administrator's Accounts and Discharge of Administrator",
    RECEIPTS_DISBURSEMENTS_NOTICE_LABEL_SUMMARY:
      "Notice of Deemed Taxation of Trustee's Accounts and Deemed Discharge of Trustee",
    RECEIPTS_DISBURSEMENTS_FORM_58_FILING: 'Form 58 Filing',
    RECEIPTS_DISBURSEMENTS_FORM_15_FILING: 'Form 15 Filing',
    RECEIPTS_DISBURSEMENTS_DIVIDENT_CHECKLIST: 'Dividend Checklist',
    RECEIPTS_DISBURSEMENTS_SRD_CHECKLIST: 'SRD Checklist',
    RECEIPTS_DISBURSEMENTS_TRUSTEE_DISCHARGE: 'Trustee Discharge',
    RECEIPTS_DISBURSEMENTS_TRUSTEE_DISCHARGE_LABEL:
      'Certificate of Compliance and Deemed Discharge of Trustee or Administrator',
    RECEIPTS_DISBURSEMENTS_FORM_16_FILING: 'Form 16 Filing',
    RECEIPTS_DISBURSEMENTS_FILE_CLOSURE: 'File Closure',
    SELECT: 'Select',
    UPDATED_SUCCESSFULLY: 'Updated successfully',
    RECEIPTS_DISBURSEMENTS: 'Receipts & Disbursements',
    RECEIPTS_DISBURSEMENTS_STATUS: 'R&D Status',

    TASK_ACTION_BTN_CLAIM: 'Claim',
    TASK_ACTION_BTN_NEW_IE: 'New I&E',
    TASK_ACTION_BTN_VIEW_IE: 'View I&E',
    TASK_ACTION_BTN_NEW_TAX_RETURN: 'New Tax Return',
    TASK_ACTION_BTN_VIEW_TAX_RETURN: 'View Tax Return',
    TASK_ACTION_BTN_DISCHARGE: 'Discharge',
    TASK_ACTION_BTN_VIEW_RND: 'View R&D',
    TASK_ACTION_BTN_BANKING: 'Banking',
    TASK_ACTION_BTN_PROPOSAL: 'Proposal',

    PENDING: 'Pending',
    CANCELLED: 'Cancelled',
    COMPLETED: 'Completed',
    MULTIPLE_FILENAMES_MESSAGE:
      'Multiple estates with the same FileName exists in Ascend. Please check Ascend and try again',
    MIGRATED: 'Migrated',
    COPIED_FROM_FILE: 'Copied Over',
    DUPLICATE_FILE: 'Duplicate File',
    DUPLICATE_FILE_CONFIRMATION_TITLE: 'Confirm File Duplication',
    DUPLICATE_FILE_CONFIRMATION_MESSAGE:
      'You are about to create a new file with some information copied over from the current file. You still need to review the information in the new file. Do you want to continue?',
    DUPLICATE_FILE_FORM_DIALOG_TITLE: 'New File Prospect Information',

    PHONE_NUMBER_REQUIRED_ERROR: 'Phone Number is required!',
    PHONE_NUMBER_INCORRECT_ERROR: 'Phone Number is incorrect',

    NO_TRADES_SELECTED_ERROR: 'No trades were selected',
    TRADES_UPDATED_SUCCESS: 'Trades are successfully updated',

    DUPLICATE_RECORDS_ERROR:
      'Unable to proceed since there are one or more confirmed duplicate record(s). Please update the entered first name, last name or main phone # to proceed',
    MISSING_VALUES_CREDIT_REPORT_ERROR:
      'The highlighted fields have missing values. Please update the missing value to request for credit report.',
    COUNTRY_ADDRESS_CREDIT_REPORT_ERROR:
      'The country in the address should be Canada to generate the credit report. Please update the address to request for credit report.',
    PERSONAL_INFORMATION: 'Personal Information',
    FIRST_NAME: 'First Name',
    MIDDLE_NAME: 'Middle Name',
    LAST_NAME: 'Last Name',
    DOB: 'Date of Birth',
    SIN: 'SIN',
    EMAIL_ADDRESS: 'Email Address',
    MAIN_PHONE_NUMBER: 'Main phone #',
    CURRENT_ADDRESS: 'Current Address',
    HAS_STAYED_MORE_THAN_2YEARS: 'Has the debtor stayed in the current address for more than 2 years?',
    PREVIOUS_ADDRESS: 'Previous Address',
    CONSENT: 'Consent',
    CONSENT_AGREEMENT: 'Consent Agreement',

    ADD_PREVIOUS_ADDRESS: 'Add Previous Address',
    CONSENT_TEXT_CREDIT_REPORT:
      'I consent to my personal information being provided to the Trustee for the purposes of the Trustee making a credit report request to TransUnion and the Trustee validating and/or discovering my debts/liabilities. This personal information may include: my name, date of birth, marital status, phone numbers, address, and information on my: current lines of credit or accounts, banking, bankruptcy events, legal proceedings and collection events, if applicable.',
    DEBTOR_UNDERSTAND: 'Debtor, I Understand',
    CONSENT_FILE: 'Consent File',
    REQUEST_ON: 'Request On',
    REQUEST_BY: 'Request By',
    SEND_REQUEST: 'Send request',
    BASIC_INFO: 'Basic Info',
    ADDRESS_HISTORY: 'Address History',
    TRANS_ALERTS: 'Trans Alerts',
    TRADE: 'Trade',
    NAME: 'Name',

    CONSENT_VIA_APP_FORM: 'Consent through Application Form - Consent Provided on',
    USER_ACCOUNT_PENDING_ACTIONS_ERROR:
      'This user account has one or more “Pending” actions assigned. Please complete those actions or re-assign to a different user.',
    USER_ACCOUNT_PENDING_TASKS_ERROR:
      'This user account has one or more “Pending” taks assigned. Please complete those tasks or re-assign to a different user.',
    USER_ACCOUNT_PENDING_APPOINTMENTS_ERROR:
      'This user account has one or more “Pending” appointments assigned. Please complete those appointments or re-assign to a different user.',
    USER_ACCOUNT_ESTATE_ADMIN_FILES_ERROR:
      'This account has one or more files assigned as Estate Administrator. Please assign a different Estate Administrator for these files.',
    USER_ACCOUNT_AGENT_FILES_ERROR:
      'This account has one or more files assigned as Agent. Please assign a different Agent for these files.',
    USER_ACCOUNT_TRUSTEE_COURTS_ERROR:
      'This account is linked to one or more courts as a Trustee. Please remove this account as a trustee from the courts and try again.',

    PHONE_IS_USED_ERROR: 'This phone number is already used',
    USERNAME: 'Username',

    CHECKLIST_UPDATED: 'Checklist updated',
    PLEASE_SELECT_TO_SAVE_THE_CHANGES: 'Please select to save the changes.',
    YOU_STILL_HAVE_ONE_OR_MORE_PENDING_CHECKLIST_ITEMS:
      'You still have one or more pending checklist items. Please check and try again.',
    SIGNUP_DOCUMENT_CHECKLISTS: 'Sign-up Document Checklist',
    FILING_TYPE: 'Filing type',
    CHECKLIST_STATUS: 'Checklist Status',
    CHECKLIST_STATUS_IS_REQUIRED: 'Checklist Status is required',

    LOCK_APP_FORM_TITLE: 'Lock Application Form?',
    LOCK_APP_FORM_MESSAGE:
      'Client Users and Staff Users will no longer be able to edit the Application Form when locked.',
    UNLOCK_APP_FORM_TITLE: 'Unlock Application Form?',
    UNLOCK_APP_FORM_MESSAGE: 'Client Users and Staff Users will be able to edit the Application Form again.',
    LOCK_APP_TAB_NAME: 'Lock Application Form',
    UNLOCK_APP_TAB_NAME: 'Unlock Application Form',
    LOCK: 'Lock',
    UNLOCK: 'Unlock',
    STRING_LENGTH: 'Must be %1 long',
    NUMERIC_ONLY: 'Must be a numeric value',
    FILLING_DATE: 'Filing date',
    UPDATED_ON: 'Updated On',
    UPDATE_BY: 'Updated By',
    LOADING_MAP: 'Loading map...',
    ERROR_LOADING_MAP: 'Error while loading the map',
    CMBND_FILE_GREATER_THAN_100_ERROR: '*Combined file upload size cannot be more than 100 MB.',
    UPLOAD_N_SHARE: 'Upload & Share',
    FILING_DATE_REQUIRED: 'Filing date is required',
    ALLOCATION_SHOULD_BE_100: 'The total allocation % should always be 100%. Please check and try again',
    ALLOCATION_UPDATED: 'Allocation is updated',
    COURT_NUMBER: 'Court Number',
    COURT_NAME: 'Court Name',
    COURT_CITY: 'Court City',
    COURTS: 'Courts',
    COURT_TRUSTEE: 'Court Trustee',
    FOLDER_NAME: 'Folder Name',
    SEARCH_BY_FOLDER_NAME_AND_PREFIX: 'Search by Folder Name and Pre-fix',
    EDIT_PRFX: 'Edit Pre-fix',
    ADD_PRFX: 'Add Pre-fix',
    FOLDER_IS_REQUIRED: 'Folder is required',
    PRFX_REQUIRED: 'Pre-fix is required',
    STATUS: 'Status',
    FOLDER: 'Folder',
    PRE_FIX: 'Pre-fix',
    COMMUNITY: 'Community',
    MULTIPLE: 'Multiple',
    FEE_TYPE: 'Fee type',
    AMOUNT: 'Amount',
    PROVINCE: 'Province',
    VEHICLE: 'Vehicle',
    RESIDENCE_AMOUNT: 'Residence amt',
    RESIDENCY_APP: 'Residence app',
    RESIDENCY_AVAIL: 'Residence avail',
    HOUSEHOLD: 'Household',
    TYPE: 'Type',
    RATE: 'Rate',
    RRSP: 'RRSP',
    RESPS: 'RESPs',
    TOOLS: 'Tools',
    HUNT_FISH_FARM: 'Hunt/Fish/Farm',
    COMMISION: 'Commission',
    OVER_LAKH_DOLLARS: 'Over $100,000',
    LEGAL_FEES: 'Legal Fees',
    TOTAL_SURPLUS_INC_CONT: 'Total surplus income contribution',
    NUMBER_OF_PAYMENTS_RQRD_BANKRUPT: 'Number of monthly payments required during bankruptcy',
    MONTHLY_PYMNT_RQRD: 'Monthly payment required',
    IND_PORTION_OF_MNTHLY_SRPLS: 'Individual’s portion of the total monthly surplus',

    //start here
    TASK_CREATED_SUCCESS_MESSAGE: 'Task created',
    TASK_UPDATED_SUCCESS_MESSAGE: 'Task updated',
    MAX_500_CHAR_ERROR: 'Description should not exceed 5000 characters',
    DATE: 'Date',
    DESCRIPTION: 'Description',
    CREATED_BY: 'Created by',
    ASSIGNED_TO: 'Assigned to',
    ACTION_CREATED_SUCCESS_MESSAGE: 'Action created',
    ACTION_UPDATED_SUCCESS_MESSAGE: 'Action updated',
    DETAILS: 'Details',
    ATTACHMENTS: 'Attachments',
    ACTION: 'Action',
    ACTION_NAME: 'Action name',
    FOLLOW_UP: 'Follow up',
    DUPLICATE: 'Duplicate',
    RESEND: 'Resend',
    TO_MOBILE: 'To Mobile',
    SMS_SENT_SUCCESS_MESSAGE: 'SMS is sent',
    RECEIPT_NO: 'Receipt no.',
    LOCATION: 'Location',
    PER: 'Per',
    AMOUNT_DOLLARS: 'Amount($)',
    SCHEDULE: 'Schedule',
    METHOD: 'Method',
    POSTED_TO: 'Posted to',
    VOID: 'Void',
    EXPORT: 'Export',
    VIEW: 'View',
    RECEIPT_CREATED_SUCCESS_MESSAGE: 'Receipt created',
    TOTAL_AMOUNT_7_DAYS_ERROR:
      'The total payment amount collected from the debtor in the last 7 days cannot be greater than $5,000.00 as the payment method is cash.',
    RECEIPT_VOIDED_SUCCESS_MESSAGE: 'Receipt is voided',
    AMOUNT_DUE: 'Amount Due',
    TOTAL_DOLLARS: 'Total($)',
    NOTE_TEXT_REQUIRED_MESSAGE: 'Note text is required',
    ADD_A_NOTE: 'Add a note',
    OPEN_IN_NEW_TAB: 'Open in new tab',
    REDUCE_FILE_PREVIEW_SIZE: 'Reduce file preview size',
    INCREASE_FILE_PREVIEW_SIZE: 'Increase file preview size',
    SEND_ALERT: 'Send alert',
    ALERT_MODAL_MISSING_VALUE_MESSAGE: 'Your data cannot be saved. At least one value is missing.',
    PLEASE_SELECT: 'Please select',
    USERS: 'Users',
    TEAM: 'Team',
    NOTES: 'Notes',
    DEBTOR: 'Debtor',
    SPOUSE: 'Spouse',
    OTHER: 'Other',
    TOTAL: 'Total',
    NET_MONTHLY_INCOME: 'Net monthly income',
    NON_DSCRTNRY_EXPNS: 'Non-discretionary expenses',
    TOTAL_MNTLY_NET_INC_IN_SURPLUS_CALCULATION: 'Total monthly net income used in surplus income calculation',
    NO_OF_PERSONS_IN_HOUSEHOLD: 'Number of persons in household',
    ALLOWABLE_MNTHLY_NET_INC_STNDRD: 'Allowable monthly net income per standard',
    TOTAL_MONHLY_SURPLUS: 'Total monthly surplus',
    OFFICE_LOCATIONS: 'Office Locations',
    OFFICE_LOCATION: 'Office Location',
    OFFICE_EDITOR_TITLE: 'Edit Office Location',
    OFFICE_NAME: 'Office Name',
    OFFICE_NAME_REQUIRED: 'Office name is required',
    OFFICE_DISPLAYNAME: 'Display Name',
    OFFICE_LOCATIONCODE_REQUIRED: 'Display name is required',
    OFFICE_PREFIX: 'Prefix',
    OFFICE_PREFIX_REQUIRED: 'Prefix is required',
    OFFICE_LATITUDE: 'Latitude',
    OFFICE_LATITUDE_REQUIRED: 'Latitude is required',
    OFFICE_LONGITUDE: 'Longitude',
    OFFICE_LONGITUDE_REQUIRED: 'Longitude is required',
    OFFICE_ADDRESS1: 'Street Name',
    OFFICE_ADDRESS1_REQUIRED: 'Street Name is required',
    OFFICE_STREETNUMBER_REQUIRED: 'Street Number is required',
    OFFICE_ADDRESS2: 'Street Number',
    OFFICE_ADDRESS_UNIT: 'Unit',
    OFFICE_CITY: 'City',
    OFFICE_CITY_REQUIRED: 'City is required',
    OFFICE_POSTALCODE: 'Postal Code',
    OFFICE_POSTALCODE_REQUIRED: 'Postal Code is required',
    OFFICE_TIMEZONE: 'Time Zone',
    OFFICE_MOVETO: 'Move To',
    OFFICE_IS_SERVICE_LOCATION: 'Service Location',
    OFFICE_IS_TRUSTEE_OFFICE: 'Trustee Office',
    HOLIDAY_DATE: 'Holiday Date',
    ACTIVE: 'Active',
    INACTIVE: 'Inactive',
    HOLIDAYS: 'Holidays',
    SEARCH_BY_OFFICE_LOCATION: 'Search by office location',
    ADD_HOLIDAY: 'Add Holiday',
    EDIT_HOLIDAY: 'Edit Holiday',
    CUSTOM: 'Custom',
    STANDARD: 'Standard',
    TRUSTEE: 'Trustee',
    ASSIGN_TRUSTEE: 'Assign Trustee',
    No_SEARCH_RESULTS_AVLABLE_PLS_CHK_N_TRY_AGAIN: 'No search results available. Please check and try again',
    EDIT_TEMPLATE: 'Edit Template',
    ADD_TEMPLATE: 'Add Template',
    EDIT_USER: 'Edit User',
    ADD_USER: 'Add User',
    BODY: 'Body',
    SYSTEM_FIELDS: 'System Fields',
    SEARCH_BY_EMAIL_ID: 'Search by Email ID',
    EMAIL_ID: 'Email ID',
    PHONE_NUMBER: 'Phone Numbe',
    ROLE: 'Role',
    ROLE_S: 'Role(s)',
    PREFERRED_LANGUAGE: 'Preferred Language',
    TEAM_TYPE: 'Team Type',
    DEFAULT_OFFICE: 'Default Office',
    TEAM_S: 'Team(s)',
    STATION_ID: 'Station Id',
    USER_MANAGEMENT: 'User Management',
    SEARCH_BY_EMAILID_FN_LN: 'Search user by Email ID, first name, last name',
    USER_INFO: 'User info',
    USER: 'User',
    LOG_OUT: 'Log-out',
    NOT_FOUND_PAGE: 'not found page',
    ACCOUNT_DETAILS: 'Account Details',
    SELECT_PREFIX: 'Select Pre-fix',
    SEARCH_NAME_EMAIL_PHN_FN_ESTATE_NO: 'Search name, email, phone, filename, estate no.',
    OOPSIE_DOOPSIE: 'oopsie doopsie',
    CHANGES_ARE_SAVED: 'Changes are saved',
    ACCESS_TOKEN: 'Access token',
    SEARCH_BY_TEMPLATE_NAME: 'Search by template name',
    IF_YOU_HAVE_DOCS_UPLOAD_ELSE_SKIP_AND_UPLOAD_LATER:
      'If you have your documents ready, upload them here. If not, skip this step and upload later.',
    NO_FORM: 'no form',
    NO_APPLICATION_FILE_ID: 'no application file id',
    NO_FILES_SELECTED: 'No files selected',
    BULK_UPDATE: 'Bulk update',
    NEXT_AVAILABLE_MORNING: 'Next Available Morning',
    NEXT_AVAILABLE_AFTERNOON: 'Next Available Afternoon',
    NEXT_AVAILABLE_EVENING: 'Next Available Evening',
    AGENT_IS_REQUIRED: 'Agent is required',
    SELECT_SOME_FILES_TO_ATTACH: 'Select some files to attach',
    NEW_EMAIL: 'New Email',
    NEW_SMS: 'New SMS',
    NEW_NOTE: 'New Note',
    NEW_TASK: 'New Task',
    DUE_DATE_IS_REQUIRED: 'Due Date is required',
    ONE_OR_MORE_SELECTED_FILES_HAS_PENDING_REVIEW_APPROVAL_SIGN_STATUS_CHECK_AND_TRYAGAIN:
      'One or more of the selected files already has a pending review, approval or sign status. Please check and try again',
    NEW_RECEIPT: 'New Receipt',
    NEW_APPOINTMENT: 'New Appointment',
    APPOINTMENT_HISTORY: 'Appointments History',
    NOTE_HISTORY: 'Note History',
    TASK_HISTORY: 'Task History',
    ADD_NOTE: 'Add Note',
    FILE_TYPE: 'File type',
    SIZE: 'Size',
    MODIFIED: 'Modified',
    ANNOTATION_STICKYNOTE: 'annotation.stickyNote',
    SAVE_PDF: 'Save PDF',
    SAVE_OPTIMIZED_PDF: 'Save Optimized PDF',
    THE_SIZE_OF_ALL_ATTACHMENTS_SHOULDNOT_BE_GREATER_THAN_100MB:
      'The size of all attachments should not be greater than 100 MB',
    CLICK_OR_DRAG_FILE_TO_THIS_AREA_TO_UPLOAD: 'Click or drag file to this area to upload',
    TOTAL_FILES_SIZE_SHOULD_NOT_EXCEED_100_MB: 'Total files size should not exceed 100 MB',
    ZOOM: 'Zoom',
    LATITUDE: 'Latitude',
    LONGITUDE: 'Longitude',
    TEST: 'Test',
    NOTICE_TO_USER: 'Notice to user',
    THERE_IS_AN_ERROR_IN_YOUR_FORM: 'There is an error in your form.',
    PDF_OR_JPEGS_UNDER_5MB: 'PDF or JPEGs under 5mb',
    SELECT_DEBTOR: 'Select Debtor',
    DEBTOR_REQUIRED: 'Debtor required',
    SUPPORTING_DOCUMENTS_NEEDED: 'Supporting documents needed',
    PREFIX_IS_REQUIRED: 'Prefix is required',
    TIME: 'Time',
    APPOINTMENT_TYPE: 'Appointment Type',
    PHONE: 'Phone',
    IN_PERSON: ' In-Person',
    STREET_NAME: 'Street Name',
    STREET_NUMBER: 'Street Number',
    ESTATE_ADMIN: 'Estate Admin',
    OFFICE: 'Office',
    NUMBER_OF_FILES: 'Number of Files',
    PENDING_CONFIRMATION: 'Pending Confirmation',
    CONVERSION_RATE: 'Conversion Rate',
    SIGNUP_IN_PROGRESS: 'Signup In-Progress',
    CONVERSION_RATES: 'Conversion Rate',
    FILES_SIGNED: 'Files Signed',
    DEBTOR_CONVERSION_REPORT: 'Debtor Conversion Report',
    CREATED_DATE_FROM: 'Created Date From',
    CREATED_DATE_TO: 'Created Date To',
    DOWNLOAD_CSV: ' Download CSV',
    DOWNLOAD_AS_CSV_FILE: 'Download as CSV file',
    CREATED: 'Created',
    FILENAME: 'Filename',
    ESTATE: 'Estate #',
    SERVICE_LOCATION: 'Service Location',
    STAGE: 'Stage',
    ACTIONS: 'Actions',
    PRINT_ALL_RECORDS: 'Print all records',
    PRINT: 'Print',
    WEEKLY_AVAILABILITY_IS_UPDATED: 'Weekly availability is updated',
    SET_WEEKLY_HOURS: 'Set weekly hours',
    SCHEDULING_NOTES: 'Scheduling notes',
    END_TIME_SHOULD_BE_GREATER_THAN_START_TIME: 'End time should be greater than start time',
    ADD_OVERRIDE_DATES: 'Add override dates',
    OVERRIDE_IS_DELETED: 'Override is deleted',
    UNAVAILABLE: 'Unavailable',
    ADD_OVERRIDE_DATE: 'Add override date',
    SIGN_OUT: 'Sign Out',
    CANCEL_NEW_PROSPECT: 'Cancel New Prospect',
    NO_TASKS_ARE_FOUND_WITH_THE_SPECIFIED_CRITERIA: 'No tasks are found with the speficied criteria',
    RECEIVED_ON: 'Received On',
    RECEIVED: 'Received',
    SENT_BY: 'Sent By',
    ACTION_STATUS: 'Action Status',
    DAYS_OPEN: 'Days Open',
    ACTION_STATUS_IS_UPDATED: 'Action status is updated',
    TEMPLATE_NAME: 'Template Name',
    EMAIL_AND_SMS_TEMPLATES: 'Email & SMS Templates',
    SEND_AS_ATTACHMENT: 'Send as attachment',
    INVALID_EMAIL: 'Invalid email',
    CLEAR: 'Clear',
    NEW_INCOME_EXPENSE: 'New Income & Expense',
    DEBTOR_PROFILE_IS_UPDATED: 'Debtor Profile is updated',
    SELECT_FOLDER: 'Select Folder',
    THE_SIZE_OF_ATTACHMENTS_SHLD_NT_BE_GREATER_THAN: 'The size of all attachments should not be greater than ',
    TOTAL_FILE_SIZE_SHLD_NT_EXCEED: 'Total files size should not exceed',
    FILES_UNDER: 'Files under',
    MB_EACH: 'MB each',
    MB: 'MB',
    ALL_SELECTED_FILES: 'All selected files',
    COMMENTS: 'Comments',
    DUE_DATE: 'Due Date',
    SIGNEE_S: 'Signee(s)',
    APPROVERS_S: 'Approver(s)',
    REVIEWERS_S: 'Reviewer(s)',
    DATE_IS_REQUIRED: 'Date is required',
    LOCATION_IS_REQUIRED: 'Location is required',
    PHONE_VIDEO: 'Phone/Video',

    ALL_TASKS: 'All Tasks',
    CLAIM_ACTIONS: 'Claim Actions',
    I_E_ACTIONS: 'I&E Actions',
    TAX_RETURN_ACTIONS: 'Tax Return Actions',
    PROPOSAL_ACTIONS: 'Proposal Actions',
    BANKING_ACTIONS: 'Banking Actions',
    ACTIONS_170: '170 Actions',
    COURT_ACTIONS: 'Court Actions',
    SRD_ACTIONS: 'SRD Actions',
    TRUSTEE_ACTIONS: 'Trustee Actions',
    PRE_SIGNUP: 'Pre-Signup',
    SIGNED: 'Signed',
    FILE_IN_PROCESS: 'File In Process',
    CLOSED: 'Closed',
    ANNULLED: 'Annulled',
    TRANSFERRED: 'Transferred',
    NON_DISCRETIONARY_EXPENSES: 'Non-discretionary expenses',
    TOTAL_NET_INCOME_IN_SURPLUS: 'Total monthly net income used in surplus income calculation',
    NUMBER_PERSONS_HOUSEHOLD: 'Number of persons in household',
    ALLOWABLE_NET_INCOME_PER_STANDARD: 'Allowable monthly net income per standard',
    TOTAL_MONTHLY_SURPLUS: 'Total monthly surplus',
    INDIVIDUAL_PORTION_INSURPLUS: 'Individual’s portion of the total monthly surplus',
    MONTHLY_PAYMENT_REQUIRED: 'Monthly payment required',
    NUMBER_PAYMENTS_BUNKRUPTCY: 'Number of monthly payments required during bankruptcy',
    TOTAL_SURPLUS_INCOME_CONTRIBUTION: 'Total surplus income contribution',
    FEES_IN_CONSUMER_PROPOSAL: 'FEES IN A CONSUMER PROPOSAL',
    ESTIMATED_FEES: 'Estimated fees',
    COUNSELLING_FEES: 'Counselling fees',
    ON_FILLING_OF_PROPOSAL: 'On filing of proposal',
    ON_APPROVAL_DEEMED_APPROVAL: 'On approval/deemed approval',
    UPON_DISTRIBUTION: 'Upon distribution',
    RECEIPTS: 'Receipts',
    FILING_FEES: 'Filing fees',
    ON_FILING: 'On filing',
    ABOVE_FEES: 'above fees',
    TAX: 'Tax',
    PERCENT20_ABOVE_AMOUNT: '20% of above amount',
    TRUSTEES_FEES_BEFORE_TAX: 'Trustee’s fees (before',
    FEES_IN_SUMMARY_BANKRUPTCY: 'FEES IN A SUMMARY BANKRUPTCY',
    ESTIMATED_RECEIPTS: 'Estimated receipts',
    TRUSTEES_FEES: "Trustee's fees",
    ON_FIRST_975: 'On first $975',
    ON_NEXT_UP_TO_2000: 'On next receipts up to $2,000',
    ON_BALANCE: 'On balance (maximum to $15,000)',
    FEES_ORDINARY_BANKRUPTCY: 'FEES IN AN ORDINARY BANKRUPTCY',
    FEES_DIVISION_I_PROPOSAL: 'FEES IN A DIVISION I PROPOSAL',
    ON_FILING_PROPOSAL: 'On filing of proposal',
    ON_APPROVAL: 'On approval',
    ON_ABOVE_FEES: 'on above fees',
    PERCENT15_ABOVE_AMOUNT: '15% of above amount',
    EMAIL_SENT_SUCCESS_MESSAGE: 'Email sent',
    URGENT: 'Urgent',
    NOT_URGENT: 'Not urgent',
    FILE_VIEW: 'File View',
    BACK_TO_LIST_VIEW: 'Back to List View',
    FILE_INFORMATION: 'File Information',
    UPLOAD_FILE_TO_REPLACE_MESSAGE: 'Upload a file to replace',
    EDIT_FILE: 'Edit file',
    REPLACE_FILE: 'Replace file',
    UPLOAD_FILE: 'Upload file',
    MAX_100MB_UPLOAD_MESSAGE: '*Combined file upload size cannot be more than 100 MB.',
    CONFIRM_DELETE_REVIEW_REQUEST_MESSAGE: 'You are about to delete selected request. Do you want to continue?',
    CONFIRM_DELETE: 'Confirm delete',
    REJECT_DOCUMENT: 'Reject document',
    REASON_FOR_REJECTION: 'Reason for rejection',
    CONFIRM_DELETE_SELECTED_FILE_MESSAGE: 'You are about to delete the selected file. Do you want to continue?',
    CONFIRM_RECOVER: 'Confirm recover',
    CONFIRM_RECOVER_SELECTED_FILES_MESSAGE: 'You are about to recover the selected file(s). Do you want to continue?',
    VISIBLE: 'Visible',
    HIDDEN: 'Hidden',
    RECOVER_FILE: 'Recover file',
    VERSION_HISTORY: 'Version History',
    CONFIRM_SET_VISIBLE_MESSAGE:
      'You have selected the following files to be visible for the debtor in the client portal. Do you want to continue?',
    CONFIRM_SET_HIDDEN_MESSAGE:
      'You have selected the following files to be revoked from the client portal of the debtor. Do you want to continue?',
    COPY_FILES: 'Copy files',
    SELECT_A_DEBTOR: 'Select a debtor',
    SEARCH_DEBTOR_PLACEHOLDER: 'Search name, email, phone, filename, estate no.',
    BUNDLE_FILES: 'Bundle files',
    ADDITIONAL_INFORMATION: 'Additional information',
    EMAIL_IS_INVALID: 'Email is invalid',
    SIN_IS_INVALID: 'SIN is invalid',
    FILE_NAME_IS_INVALID: 'Accented letters and the following characters are not allowed: & , / \\ : * ? ” < > |',
    REPORT_GENERATED_BY: 'Report Generated By',
    ON: 'on',
    CODE: 'Code',
    TEXT: 'Text',
    MEMBER_CODE: 'Mem. code',
    MEMBER_NAME: 'Mem. Name',
    ACCOUNT: 'Account',
    JOIN: 'Join',
    OPENED: 'Opened',
    REVISED: 'Revised',
    LAST_ACTIVITY: 'Last activity',
    MANNER_OF_PAYMENT: 'Manner of payment',
    BALANCE_DOLLARS: 'Balance ($)',
    HIGH_CREDIT_DOLLARS: 'High Credit ($)',
    CREDIT_LIMIT_DOLLARS: 'Credit Limit ($)',
    FREQUENCY: 'Frequency',
    PAYMENT: 'Payment',
    PAST_DUE_DOLLARS: 'Past Due ($)',
    PAYMENT_PATTERN_START_DATE: 'Payment Pattern Start Date',
    PAYMENT_PATTERN: 'Payment Pattern',
    MONTHS_REVIEWED: 'Months Reviewed',
    PLUS_30: 'Plus 30',
    PLUS_60: 'Plus 60',
    PLUS_90: 'Plus 90',
    ADDRESS_TYPE: 'Address Type',
    STREET: 'Street',
    CITY: 'City',
    POSTAL_CODE: 'Postal Code',
    DATE_REPORTED: 'Date Reported',
    VIEW_HISTORY: 'View History',
    INCOMPLETE: 'Incomplete',
    BANKRUPTCY: 'Bankruptcy',
    REALIZATION_FROM_ASSETS: 'Realization from assets',
    FURNITURE: 'Furniture',
    PERSONAL_EFFECTS: 'Personal effects',
    CASH_ON_HAND: 'Cash on hand',
    POLICIES_PENSIONS_ETC: 'Policies, Pensions, RRSPs, RESP, & RDSPs',
    SECURITIES: 'Securities',
    REAL_ESTATE: 'Real Estate',
    UNIT: 'Unit',
    MOTOR_VEHICLES: 'Motor vehicles',
    RECREATIONAL_EQUIPMENT: 'Recreational equipment',
    TAX_REFUNDS: 'Tax Refunds',
    IMPACT_TO_RECOVERY: 'Impact to Recovery',
    OTHER_TOOLS_OF_TRADE: 'Other - Tools of trade',
    OTHER_HUNTING_FISHING_FARMING_TOOLS: 'Other - Hunting/fishing/farming tools',
    SURPLUS_INCOME_CONTRIBUTIONS: 'Surplus income contributions',
    OTHER_CONTRIBUTIONS_BY_DEBTOR: 'Other contributions by debtor',
    PROPOSAL: 'Proposal',
    TOTAL_PROPOSAL_PAYMENTS: 'Total proposal payments',
    COST_OF_ADMINISRATION: 'Costs of administration',
    ADMINISTRATIVE_DESBURSEMENTS: 'Administrative disbursements',
    ADMINISTRATOR_TRUSTEE_FEES: 'Administrator/Trustee fees',
    OTHER_COST_ORDINARY_BANKRUPTCY: 'Other cost in ordinary bankruptcy',
    ESTIMATED_DISBURSEMENTS: 'Estimated disbursements',
    ESTIMATED_AMOUNT_AVAILABLE_FOR_DISTRIBUTION: 'Estimated amount available for distribution',
    ESTIMATED_CLAIMS_PREFERRED_CREDITORS: 'Estimated claims of preferred creditors',
    ESTIMATED_CLAIMS_ORDINARY_CREDITORS: 'Estimated claims of ordinary creditors',
    ESTIMATED_CLAIMS_SECURED_CREDITORS: 'Estimated claims of participating secured creditors',
    ESTIMATED_DISTRIBUTION: 'Estimated distribution',
    SUPERINTENDENTS_LEVY: "Superintendent's levy",
    DIVIDEND_TO_PREFERRED_CREDITORS: 'Dividend to preferred creditors',
    DIVIDEND_TO_ORDINARY_CREDITORS: 'Dividend to ordinary creditors',
    DIVIDEND_TO_SECURED_CREDITOR: 'Dividend to secured creditor',
    TOTAL_DIVIDEND_AND_LEVY: 'Total dividend and levy',
    ESTIMATED_DISTRIBUTION_TO_PREFERRED_CREDITORS: 'Estimated distribution to preferred creditors',
    ESTIMATED_DISTRIBUTION_TO_ORDINARY_CREDITORS: 'Estimated distribution to ordinary creditors',
    ESTIMATED_DISTRIBUTION_TO_SECURED_CREDITORS: 'Estimated distribution to secured creditors',
    ALLOCATION_OF_AMOUNT_AVAILABLE_FOR_DISTRIBUTION: 'Allocation of amount available for distribution',
    PREFERRED_CREDITORS: 'Preferred creditors',
    ORDINARY_CREDITORS: 'Ordinary creditors',
    SECURED_CREDITORS: 'Secured creditors',
    EXCESS_FUNDS: 'Excess funds',
    TOTAL_ALLOCATION_OF_AMOUNT_AVAILABLE_FOR_DISTRIBUTION: 'Total allocation of amount available for distribution',
    CONSUMER_PROPOSAL: 'Consumer Proposal',
    CSV_OF_NONEXEMPT_LIFE_INSURANCE_POLICIES: 'CSV of non-exempt life insurance policies',
    PENSIONS: 'Pensions',
    RRSPS: 'RRSPs',
    RDSPS: 'RDSPs',
    TOTAL_POLICIES_PENSIONS_RRSPS: 'Total Policies, Pensions & RRSPs',
    CURRENT_MARKET_VALUE: 'Current market value',
    GRANTS_TO_BE_REPAID: 'Grants to be repaid',
    VALUE_AFTER_GRANTS: 'Value after Grants',
    COST_TO_COLLAPSE_RESP_25: 'Cost to collapse RESP (25%)',
    ESTIMATED_EQUITY: 'Estimated equity',
    DEBTORS_INTEREST: "Debtor's interest",
    CONTRIBUTIONS_IN_LAST_12_MONTHS: 'Contributions in last 12 months',
    WITHHOLDING_TAX_RATE: 'Withholding tax rate',
    WITHHOLDING_TAX: 'Withholding tax',
    NET_REALIZABLE_VALUE: 'Net realizable value',
    INVESTMENTS: 'Investments',
    ESTIMATED_TAX_ON_DISPOSITION: 'Estimated tax on disposition',
    ESTIMATED_MARKET_VALUE_BASED_ON_OPINIONS_OF_VALUE: 'Estimated market value based on opinions of value',
    ESTIMATED_REAL_ESTATE_COMMISSION: 'Estimated real Estate Commission',
    ESTIMATED_LEGAL_COSTS: 'Estimated Legal Costs',
    ESTIMATED_AVAILABLE_BEFORE_SECURED_CLAIMS: 'Estimated Available before Secured Claims',
    ESTIMATED_SECURED_CLAIMS: 'Estimated secured claims',
    MORTGAGE: 'Mortgage',
    PROPERTY_TAX_ARREARS: 'Property tax arrears',
    TOTAL_ESTIMATED_SECURED_CLAIMS: 'Total estimated secured claims',
    PROVINCIAL_EXEMPTION_HOUSEHOLD_IF_ALLOWED: 'Provincial exemption - Household (if allowed)',
    ESTIMATED_EQUITY_ABOVE_ALLOWED_EXEMPTION_CLAIMS_IF_ALLOWED:
      'Estimated equity (above allowed exemption claim(s), if allowed)',
    DEBTORS_OWNERSHIP_INTEREST: 'Debtors ownership interest',
    DEBTORS_ESTIMATED_EQUITY_BEFORE_EXEMPTION: 'Debtors estimated equity before exemption',
    PROVINCIAL_EXEMPTION_INIDIVIDUAL_IF_ALLOWED: 'Provincial exemption - Inidividual (if allowed)',
    DEBTORS_ESTIMATED_EQUITY: "Debtor's estimated equity",
    MODEL: 'Model',
    ESTIMATED_VALUE: 'Estimated value',
    OUTSTANDING_LOAN_LEASE: 'Outstanding loan/lease',
    VALUE_AFTER_DEBTS: 'Value After Debts',
    PERSONAL_EXEMPTION: 'Personal exemption',
    TOOLS_OF_THE_TRADE: 'Tools of the trade',
    TOOLS_OF_THE_TRADE_REALIZATION: 'Tools of the Trade Realization',
    HUNTING_FISHING_FARMING: 'Hunting/Fishing/Farming',
    HUNTING_FISHING_FARMING_REALIZATION: 'Hunting/Fishing/Farming Realization',
    TAX_REFUND: 'Tax Refund',
    OTHER_TOTAL_REALIZATIONS: 'Other Total Realizations',
    REAL_PROPERTY_OR_IMMOVABLE: 'Real Pproperty or Immovable',
    RECREATIONAL_EQUIPMENTS: 'Recreational Equipments',
    MARKET_VALUE: 'Market value',
    POLICIES_PENSIONS_RRSPS: 'Policies, pensions & RRSPs',
    ESTIMATED_RECOVERY: 'Estimated recovery',
    INITIAL_DEPOSIT: 'Initial deposit',
    ANY_GIFTS_CONVEYANCES_OR_OTHER_TRANSACTIONS_WHICH_REQUIRE_NOTE_DISCLOSURE:
      'Any gifts, conveyances, or other transactions which require note disclosure?',
    ADD_DESIRED_NOTE_DISCLOSURE_HERE:
      'Add desired note disclosure here if you do not wish to use the standard note provided in the report.',
    ADJUSTMENT_TO_UNSECURED_CLAIMS: 'Adjustment to Unsecured Claims',
    ANY_DEFECTS_IN_SECURITY: 'Any defects in security which require note disclosure?',
    PROVIDE_ANY_ADDITIONAL_COMMENTS_HERE: 'Provide any additional comments here to be included in note disclosure.',
    IS_THERE_A_SECURED_CREDITOR: 'Is there a secured creditor with a potential shortfall on its security?',
    WILL_THE_SECURED_CREDITOR_PARTICIPATE_IN_DIVIDEND_DISTRIBUTIONS:
      'If yes to the above, will the secured creditor participate in dividend distributions?',
    ANY_COSIGNED_GUARANTEED_CLAIMS: 'Any co-signed/guaranteed claims?',
    DOES_THE_AMOUNT_RELATED_TO_A_COSIGNED_GUARANTEED:
      'If yes, does the amount related to a co-signed/guaranteed amount where we expect payments to continue by the third party in the ordinary course?',
    ANY_FACTS_OR_SITUATIONS_WHICH_COULD_AFFECT_THE_RECOVERY_TO_CREDITORS:
      'Any facts or situations which could affect the recovery to creditors that are not reflected on the Statement of Affairs which require note disclosure?',
    TRUSTEE_TO_PREPARE_AND_INSERT_NOTE:
      'Trustee to prepare and insert note disclosure here regarding facts which could affect the recovery to creditors.',
    NET_INCOME: 'Net income',
    APPLICANT: 'Applicant',
    ABSTAIN: 'Abstain',
    LUMP_SUM: 'Lump sum',
    MONTHS: 'Months',
    TOTAL_MONTHLY_PAYMENTS: 'Total monthly payments',
    RECOMMENDATION: 'Recommendation',
    ADMINISTRATOR_TRUSTEE_FEE_SUBJECT_TO_TAXES: 'Administrator/Trustee Fee subject to Taxes',
    ADD: 'Add',
    AMOUNT_REQUIRED_FOR_PROPOSAL_CONTRIBUTION: 'Amount Required for 100% Proposal Contribution:',
    ASCRIBED_VALUE_OF_SECURED_CLAIMS: 'Ascribed Value of Secured Claims',
    UNSECURED_CREDITORS: 'Unsecured creditors',
    CREDITOR_NOT_PARTICIPATING_IN_PROPOSAL: 'Creditor Not Participating in Proposal',
    UNSECURED_PORTION_OF_SECURED_CLAIMS: 'Unsecured Portion of Secured Claims',
    CONTINGENT_DEFERRED_LIABILITIES: 'Contingent/Deferred Liabilities',
    ALL_OTHER_UNSECURED_CREDITORS: 'All Other Unsecured Creditors',
    UNSECURED_PORTION: 'Unsecured Portion',
    FEES_FOR_ORDINARY: 'Fees for ordinary',
    OTHER_COSTS_SUBJECT_TO: 'Other costs subject to',
    OTHER_COSTS: 'Other costs',
    STANDARD_VOLUNTARY_PAYMENTS: 'Standard voluntary payments (if no surplus or assets realization)',
    STANDARD_MULTIPLE_FOR_NUNAVUT: 'Standard multiple for Nunavut',
    BASED_OFF: 'Based off',
    PERSONS_IN_HOUSEHOLD: 'Person(s) in Household',
    ALLOWABLE_MONTHLY_NET_INCOME_PER_STANDARD: 'Allowable monthly net income per standard',
    OVERRIDE: 'Override',
    TOTAL_ASSETS: 'Total Assets',
    VALIDATION_ERRORS_AND_WARNINGS: 'Validation errors and warnings',
    DEBT_AREAS_APPLICANT: 'Debt Areas - Applicant',
    DO_YOU_HAVE_ANY_DEBTS_ARISING_FROM: 'Do you have any debts arising from:',
    FINE_OR_PENALTY_IMPOSED_BY_COURT_INCLUDING_ASSAULT: 'Fine or Penalty Imposed by Court (Including Assault)',
    RECOGNIZANCE_OF_BAIL_BOND: 'Recognizance of Bail Bond',
    ALIMONY: 'Alimony',
    MAINTENANCE_OF_AFFILIATION_ORDER: 'Maintenance of Affiliation Order',
    FRAUD: 'Fraud',
    EMBEZZLEMENT: 'Embezzlement',
    MISAPPROPRIATION: 'Misappropriation',
    DEFALCATION_WHILE_ACTING_IN_A_FIDUCIARY_CAPACITY: 'Defalcation While Acting in a Fiduciary Capacity',
    PROPERTY_OR_SERVICES_OBTAINED_BY_FALSE_MEANSFRAUD: 'Property or Services Obtained by False Means/Fraud',
    STUDENT_LOANS_OUTSTANDING: 'Student Loans Outstanding',
    DATE_YOU_CEASED_BEING_FULL_TIMEPART_TIME_STUDENT: 'Date You Ceased being Full-time/Part-time Student',
    DEBT_AREAS_SPOUSE_PARTNER: 'Debt Areas - Spouse/Partner',
    MAINTENANCE_OF_SUPPORT_OF_SEPARATED_FAMILY: 'Maintenance of Support of Separated Family',
    CO_SIGNS_OR_GUARANTEED_LOANS: 'Co-Signs or Guaranteed Loans',
    LENDERS_ADDRESS: 'Lenders Address',
    BORROWERS_NAME: 'Borrowers Name',
    BORROWERS_ADDRESS: 'Borrowers Address',
    BORROWERS_ADDRESS_UNIT: 'Borrowers Address Unit',
    COMPLETE_SEARCH_IN_ASCEND_AND_CONFIRM_ESTATE: 'Complete search in Ascend and confirm estate',
    ADD_LOAN: 'Add Loan',
    TRANSACTIONS_APPLICANT: 'Transactions - Applicant',
    HAVE_YOU_MADE_PAYMENTS_IN_EXCESS_OF_THE_REGULAR_AMOUNT_TO_CREDITORS_IN_THE_PAST__MONTHS:
      'Have you made payments in excess of the regular amount to creditors in the past 12 months?',
    HAVE_YOU_HAD_ANY_ASSETS_SEIZED_OR_GARNISHED_BY_A_CREDITORS_IN_THE_PAST__MONTHS:
      'Have you had any assets seized or garnished by a creditor(s) in the past 12 months?',
    HAVE_YOU_SOLD_DISPOSED_OR_TRANSFERRED_ANY_REAL_PROPERTY_OR_OTHER_ASSETS_IN_THE_PAST_YEARS:
      'In the past five (5) years have you sold, disposed or transferred any assets, cashed RRSPs or changed the named beneficiary on a life insurance policy?',
    INSOLVENT_AT_THE_TIME: 'Insolvent at the Time?',
    HAVE_YOU_MADE_ANY_GIFTS_TO_RELATIVES_OR_OTHERS_IN_THE_EXCESS_OF_WHILE_YOU_KNEW_YOURSELF_TO_BE_INSOLVENT_IN_THE_PAST_YEARS:
      'Within the past five (5) years prior to the date of the initial bankruptcy event, have you made any gifts to relatives or others in excess of $1,000?',
    DO_YOU_EXPECT_TO_RECEIVE_ANY_SUMS_OF_MONEY_WHICH_ARE_NOT_RELATED_TO_YOUR_NORMAL_INCOME_OR_ANY_OTHER_PROPERTY_WITHIN_THE_NEXT__MONTHS_INCLUDING_INHERITANCE:
      'Do you expect to receive any sums of money, which are not related to your normal income, or any other property within the next 12 months (including inheritance)?',
    HAVE_YOU_BEEN_OR_ARE_YOU_INVOLVED_IN_CIVIL_LITIGATION_FROM_WHICH_YOU_MAY_RECEIVE_MONIES_OR_PROPERTY:
      'Have you been or are you involved in civil litigation from which you may receive monies or property?',
    HAVE_YOU_MADE_ARRANGEMENTS_TO_CONTINUE_TO_PAY_ANY_CREDITORS_AFTER_FILING:
      'Have you made arrangements to continue to pay any creditors after filing?',
    TRANSACTIONS_SPOUSE_PARTNER: 'Transactions - Spouse/Partner',
    BUSINESS: 'Business',
    BUSINESS_REQUIRED: 'Business is required',
    HAVE_YOU_OWNED_A_BUSINESS_WITHIN_THE_LAST_5_YEARS: 'Have You Owned A Business within the last 5 Years?',
    ADD_BUSINESS: 'Add Business',
    ADVICE_RECEIVED_BY_OTHER_CONSULTANTS_APPLICANT_TITLE: 'Advice Received by Other Consultants or Professionals',
    ADVICE_RECEIVED_BY_OTHER_CONSULTANTS_APPLICANT_TITLE_APPLICANT: 'Applicant',
    ADVICE_RECEIVED_BY_OTHER_CONSULTANTS_APPLICANT_TITLE_SPOUSE: 'Spouse/Partner',
    ADVICE_RECEIVED_BY_OTHER_CONSULTANTS_APPLICANT_RADIO_SUBTITLE:
      'In the last six(6) months, have you received professional advice regarding your financial situation other than from the Licensed Insolvency Trustee?',
    ADVICE_RECEIVED_BY_OTHER_CONSULTANTS_APPLICANT_SECTION_SUBTITLE:
      'Name Any and All Providers of Professional Financial Advice:',
    ADVICE_RECEIVED_BY_OTHER_CONSULTANTS_APPLICANT_SURNAME: 'Surname',
    ADVICE_RECEIVED_BY_OTHER_CONSULTANTS_APPLICANT_SURNAME_VALIDATION: 'Surname is required!',
    ADVICE_RECEIVED_BY_OTHER_CONSULTANTS_APPLICANT_GIVEN_NAME: 'Given Names',
    ADVICE_RECEIVED_BY_OTHER_CONSULTANTS_APPLICANT_GIVEN_NAME_VALIDATION: 'Given Names is required!',
    ADVICE_RECEIVED_BY_OTHER_CONSULTANTS_APPLICANT_FIRM_NAME:
      'Name the Firm/Organization of the Individual Provider of Professional Financial Advice',
    ADVICE_RECEIVED_BY_OTHER_CONSULTANTS_APPLICANT_FIRM_NAME_VALIDATION:
      'Name the Firm/Organization of the Individual Provider of Professional Financial Advice is required!',
    ADVICE_RECEIVED_BY_OTHER_CONSULTANTS_APPLICANT_FIRM_ADDRESS: 'Address of the Firm/Organization',
    ADVICE_RECEIVED_BY_OTHER_CONSULTANTS_APPLICANT_FIRM_ADDRESS_VALIDATION:
      'Address of the Firm/Organization is required!',
    ADVICE_RECEIVED_BY_OTHER_CONSULTANTS_APPLICANT_FIRM_ADDRESS_UNIT: 'Address Unit',
    ADVICE_RECEIVED_BY_OTHER_CONSULTANTS_APPLICANT_TOTAL_AMOUNT_PAID_TO_DATE: 'Total Amount Paid to Date',
    ADVICE_RECEIVED_BY_OTHER_CONSULTANTS_APPLICANT_TOTAL_AMOUNT_PAID_TO_DATE_VALIDATION:
      'Total Amount Paid to Date is required!',
    ADVICE_RECEIVED_BY_OTHER_CONSULTANTS_APPLICANT_TOTAL_REMAINING_AMOUNT_TO_BE_PAID:
      'Total Remaining Amount to be Paid',
    ADVICE_RECEIVED_BY_OTHER_CONSULTANTS_APPLICANT_TOTAL_REMAINING_AMOUNT_TO_BE_PAID_VALIDATION:
      'Total Remaining Amount to be Paid is required!',
    ADVICE_RECEIVED_BY_OTHER_CONSULTANTS_APPLICANT_ADD_PROVIDER_BUTTON_TEXT: '"Add Provider"',
    CREDIT_BUREAU_CONSENT: 'Credit Bureau Consent',
    CONSENT_AGREEMENT_TEXT:
      'I consent to my personal information being provided to the Trustee for the purposes of the Trustee making a credit report request to Transunion and the Trustee validating and/or discovering my debts/liabilities. This personal information may include: my name, date of birth, marital status, phone numbers, address, and information on my: current lines of credit or accounts, banking, bankruptcy events, legal proceedings and collection events, if applicable.',
    I_UNDERSTAND: 'I Understand',
    MARK_AS_COMPLETED: 'Mark As Completed',
    ANY_COSIGNED_OR_GUARANTEED_LOANS: 'Any Co-Signed or Guaranteed Loans?',
    SPOUSE_PARTNER: 'Spouse/Partner',
    SUPPORTING_DOCUMENTS: 'Supporting Documents',
    TYPE_OF_OWNERSHIP: 'Type of Ownership',
    TYPE_OF_BUSINESS: 'Type of Business',
    BUSINESS_ADDRESS: 'Business Address',
    BUSINESS_ADDRESS_UNIT: 'Business Address Unit',
    START_DATE: 'Start Date',
    DATE_OPERATIONS_CEASED: 'Date Operations Ceased',
    IS_THE_CORPORATION_BANKRUPT: 'Is the Corporation Bankrupt?',
    DOES_THE_BUSINESS_HAVE_EMPLOYEES_OR_SUBCONTRACTORS: 'Does the Business have Employees or Sub-Contractors?',
    DOES_THE_BUSINESS_OWE_ANY_WAGES_TO_EMPLOYEES: 'Does the Business Owe any Wages to Employees?',
    DOES_THE_BUSINESS_OWE_ANY_SOURCE_DEDUCTIONS_ON_WAGES: 'Does the Business Owe any Source Deductions on Wages?',
    ARE_YOU_A_DIRECTOR: 'Are you a Director?',
    RECOMMENDED_FIELD: 'This field is recommended',
    NAME_OF_PARTNERS__DIRECTORS: 'Name of Partners / Directors',
    OTHER_DETAILS: 'Other Details',
    BUSINESS_NAME: 'Business Name',
    BUSINESS_NUMBER_OF_EMPLOYEES: 'Maximum number of employees in the past twelve (12) months',
    BUSINESS_NUMBER_OF_EMPLOYEES_REQUIRED: 'Maximum number of employees in the past twelve (12) months is required!',
    UNDER_WHAT_NAME_DID_YOU_FILE: 'Under what name did you file?',
    TRUSTEES_NAME: "Trustee's Name",
    ESTATE_NUMBER: 'Estate Number',
    PLACE_FILED: 'Place Filed',
    PROPOSAL_RESULT: 'Proposal Result',
    PROPOSAL_DATE: 'Proposal Date',
    BANKRUPTCY_DISCHARGE_DATE: 'Bankruptcy Discharge Date',
    BANKRUPTCY_DATE: 'Bankruptcy Date',
    ADD_INSOLVENCY: 'Add Insolvency',
    DISCLOSE_ALL_PREVIOUS_FILINGS: '*Disclose all previous filings',
    EMPLOYER: 'Employer',
    OCCUPATION: 'Occupation',
    EMPLOYMENT_STATUS: 'Employment Status',
    EMPLOYER_ADDRESS: 'Employer Address',
    EMPLOYER_ADDRESS_UNIT: 'Employer Address Unit',
    END_DATE: 'End Date',
    CURRENT_EMPLOYER: 'Current Employer',
    ADD_EMPLOYMENT: 'Add Employment',
    CURRENTLY_RECEIVING_EI: 'Currently Receiving EI',
    ADD_EI_PERIOD: 'Add EI Period',
    FOR_JOINT_FILES__1_ID_REQUIRED_FOR_APPLICANT_1_ID_REQUIRED_FOR_SPOUSE:
      'For Joint Files - 1 ID required for Applicant, 1 ID required for Spouse!',
    ONE_ID_REQUIRED_FOR_APPLICANT: '1 ID required for Applicant!',
    ALSO_KNOWN_AS: 'Also Known As',
    DATE_OF_BIRTH: 'Date Of Birth',
    MARITAL_STATUS: 'Marital Status',
    DATE_OF_MARRIAGE: 'Date Of Marriage',
    HIGHEST_EDUCATION_LEVEL_COMPLETED: 'Highest Education Level Completed',
    SIN_IS_INCORRECT: 'SIN is incorrect',
    JOINT_FILE: 'Joint File',
    ONLY_1_OF_THE_4_SUPPORTING_DOCUMENTS_LISTED_FOR_PERSONAL_IDENTIFICATION_IS_REQUIRED:
      'Only 1 of the 4 supporting documents listed for Personal Identification is required.',
    ADDITIONAL_NOTES: 'Additional Notes',
    CONTACT_INFORMATION: 'Contact Information',
    AT_THIS_ADDRESS_SINCE: 'At This Address Since',
    MAIN_PHONE_NO: 'Main Phone #',
    EMAIL_ADDRESS_IS_INCORRECT: 'Email Address is incorrect',
    SAME_AS_APPLICANTS_CONTACT_INFORMATION: 'Same as Applicant’s Contact Information',
    EMPLOYMENT: 'Employment',
    HAVE_YOU_RECEIVED_EI_IN_THE_LAST_TWO_YEARS: 'Have you received EI in the last two years?',
    FINANCIAL_DIFFICULTY: 'Financial Difficulty',
    FINANCIAL_DIFFICULTY_SPOUSE: 'Spouse/Partner Financial Difficulty',
    REASONS_FOR_FINANCIAL_DIFFICULTY: 'Reasons for Financial Difficulty',
    WHAT_ARE_THE_REASONS_FOR_YOUR_FINANCIAL_DIFFICULTY:
      'What are the reasons for your financial difficulty? (Please select all that apply)',
    PLEASE_SELECT_AT_LEAST_1_REASON_FOR_FINANCIAL_DIFFICULTY:
      'Please select at least 1 Reason for Financial Difficulty',
    RELATIONSHIP_BREAKDOWN: 'Relationship Breakdown',
    INCONSISTENT_EMPLOYMENT: 'Inconsistent Employment',
    BUSINESS_FAILURE: 'Business Failure',
    SCAM_FRAUD: 'Scam/fraud',
    VICTIM_OF_PREDATORY_LENDING: 'Low income leading to predatory lending',
    RETIREMENT_LOW_FIXED_INCOME: 'Retirement / Low Fixed Income',
    LEGAL_MATTERS: 'Legal Matters',
    ADDICTION_OTHER_THAN_GAMBLING: 'Addiction other than gambling',
    GAMBLING: 'Gambling',
    REDUCTION_IN_INCOME: 'Reduction of household income',
    SPECIFY: 'Specify',
    DESCRIBE_WHY_YOU_NEED_FINANCIAL_HELP: 'Describe in your own words why you need financial help',
    DEPENDANTS: 'Dependants',
    APPLICANT_HOUSEHOLD: 'Applicant Household',
    PERSONS_IN_THE_HOUSEHOLD_UNIT_INCLUDING_APPLICANT: 'Persons in the household unit (including applicant)',
    ANY_DEPENDANT_CHILDREN_AND_OR_OTHER_DEPENDANTS: 'Any dependant children and/or other dependants?',
    DEPENDANT_FIRST_NAME: 'Dependant First Name',
    DEPENDANT_LAST_NAME: 'Dependant Last Name',
    RELATIONSHIP: 'Relationship',
    ADD_DEPENDANT: 'Add Dependant',
    PREVIOUS_INSOLVENCIES: 'Previous Insolvencies',
    HAVE_YOU_PREVIOUSLY_FILED_FOR_INSOLVENCY_IN_CANADA_OR_ELSEWHERE:
      'Have you previously filed for insolvency in Canada or elsewhere?',
    PERSONAL_DETAILS: 'Personal Details',
    YEAR_LAST_RETURN_FILED: 'Year Last Return Filed',
    REFUND_AMOUNT: 'Refund Amount',
    AMOUNT_OWING: 'Amount Owing',
    ONLY_1_OF_THE_2_SUPPORTING_DOCUMENTS_LISTED_FOR_INCOME_TAX_INFORMATION_IS_REQUIRED:
      'Only 1 of the 2 supporting documents listed for Income Tax Information is required.',
    ONLY_1_OF_THE_3_SUPPORTING_DOCUMENTS_LISTED_FOR_SELFEMPLOYMENT_INCOME_IS_REQUIRED:
      'Only 1 of the 3 supporting documents listed for Self-Employment Income is required.',
    MONTHLY_INCOME: 'Monthly Income',
    SPOUSE_REFUSED_TO_PROVIDE_INCOME_DATA: 'Spouse Refused to Provide Income Data',
    APPLICANT_AMOUNT: 'Applicant Amount',
    SPOUSE_AMOUNT: 'Spouse Amount',
    OTHER_HOUSEHOLD_MEMBERS: 'Other Household Member(s)',
    ADD_INCOME_EXPENSE: 'Add Income Expense',
    SOURCE: 'Source',
    EXPENSE: 'Expense',
    ONLY_1_OF_THE_2_SUPPORTING_DOCUMENTS_LISTED_FOR_SPOUSAL_SUPPORT_PAYMENT_IS_REQUIRED:
      'Only 1 of the 2 supporting documents listed for Spousal Support Payment is required.',
    ONLY_1_OF_THE_2_SUPPORTING_DOCUMENTS_LISTED_FOR_CHILD_SUPPORT_PAYMENT_IS_REQUIRED:
      'Only 1 of the 2 supporting documents listed for Child Support Payment is required.',
    MONTHLY_EXPENSE: 'Monthly Expense',
    BUDGET: 'Budget',
    GO_TO_ERROR: 'Go to error',
    GO_TO_WARNING: 'Go to warning',
    INSTITUTION: 'Institution',
    ACCOUNT_NUMBER: 'Account Number',
    OVERDRAFT_OWED: 'Overdraft Owed',
    JOINT_ACCOUNT: 'Joint Account',
    ADD_BANKING_INFORMATION: 'Add Banking Information',
    EDIT_DEBT: 'Edit Debt',
    NEW_DEBT: 'New Debt',
    BASIC: 'Basic',
    CREDITOR_NAME: 'Creditor Name',
    OTHER_SPECIFY: 'Other - Specify',
    OWNERSHIP: 'Ownership',
    CREDITOR_ADDRESS: 'Creditor Address',
    CREDITOR_UNIT: 'Creditor Unit',
    CREDITOR_EMAIL_ADDRESS: 'Creditor Email Address',
    CREDITOR_EMAIL_ADDRESS_IS_INCORRECT: 'Creditor Email Address is incorrect',
    BUSINESS_DEBT: 'Business Debt',
    EXTENDED_INFO: 'Extended Info',
    UNIQUE_CREDITOR: 'Unique Creditor',
    CREDITOR_LIST_NAME: 'Creditor List Name',
    CREDITOR_TYPE: 'Creditor Type',
    UNSECURED_AMOUNT: 'Unsecured Amount',
    REASON_FOR_PREFERRED: 'Reason For Preferred',
    POTENTIALLY_STATUTEBARRED: 'Potentially Statute-Barred',
    INCLUDE_IN_PROPOSAL: 'Include in Proposal',
    SECURED_BY: 'Secured By',
    NEW_ASSET: 'New Asset',
    EDIT_ASSET: 'Edit Asset',
    SECURED_TO: 'Secured To',
    APPLICANT_OWNERSHIP_PERCENTAGE: 'Applicant Ownership Percentage',
    SPOUSE_OWNERSHIP_PERCENTAGE: 'Spouse Ownership Percentage',
    TOTAL_NUMBER_OF_OWNERS: 'Total Number Of Owners',
    OTHER_OWNERSHIP_PERCENTAGE: 'Other Ownership Percentage',
    OWNERSHIP_HELD_IN_JOINT_TENANCY: 'Ownership Held In Joint Tenancy',
    PRINCIPAL_RESIDENCE: 'Principal Residence',
    NAME_OF_INSTITUTION: 'Name Of Institution',
    GRANT_TO_BE_REPAID: 'Grant To Be Repaid',
    YEAR: 'Year',
    MAKE: 'Make',
    TRIM: 'Trim',
    STYLE: 'Style',
    KMS: 'KMs',
    VIN: 'VIN',
    VEHICLE_NEEDED_FOR_EMPLOYMENT: 'Vehicle Needed for Employment',
    BASIS_OF_VALUE: 'Basis of Value',
    EXEMPT: 'Exempt',
    VALUE: 'Value',
    TOTAL_DEBTS: 'Total Debts',
    TOTAL_UNSECURED: 'Total Unsecured',
    TOTAL_SECURED: 'Total Secured',
    ASSETS: 'Assets',
    DEBTS: 'Debts',
    SAVE_AND_LEAVE: 'Save and Leave',
    ASSETS_DEBTS: 'Assets & Debts',
    INCOME_EXPENSES: 'Income & Expenses',
    INCOME_TAX: 'Income Tax',
    BANKING_INFORMATION: 'Banking Information',
    QUESTIONNAIRE: 'Questionnaire',
    SIGNUP_INPROGRESS: 'Signup In-Progress',
    UNABLE_TO_PROCEED_AS_THERE_ARE_ONE_OR_MORE_SECTIONS_IN_THE_APPLICATION_FORM_THAT_ARE_STILL_PENDING:
      'Unable to proceed as there are one or more sections in the application form that are still pending. Please check and try again.',
    STATUS_CHANGE_CONFIRMATION: 'Status Change Confirmation',
    PLEASE_ENSURE_THE_ESTATE_NO_IS_UPDATED_FOR_THIS_FILE_IN_ASCEND:
      'Please ensure the estate # is updated for this file in Ascend. Do you want to update the status as Signed?',
    DO_YOU_WANT_TO_UPDATE_THE_STATUS_AS_SIGNUP_INPROGRESS:
      'Do you want to update the status as Signup In-progress? Hub will create an initial interview record in Ascend for this file',
    PPSA_SEARCH: 'PPSA search',
    INSOLVENCY_NAME_SEARCH: 'Insolvency name search',
    TRUSTEE_TO_FOLLOW_ONLINE_STEPS_FOR_ENGAGEMENT_ACCEPTANCE__RISK_ASSESSMENT:
      'Trustee to Follow online steps for engagement acceptance & risk assessment',
    TRUSTEE_TO_FOLLOW_RISK_MANAGEMENT_SECTION_IN_MY_BDO_AND_CONFLICT_CHECK:
      'Trustee to Follow risk management section in My BDO and conflict check',
    SIGNUP_STATUS: 'Sign-up Status',
    SELECT_STATUS: 'Select status',
    FOLLOWUP_DATE: 'Follow-up Date',
    INFORMATION_UPDATED: 'Information updated',
    UPDATE_INFORMATION: 'Update information',
    AGENT: 'Agent',
    SELECT_AGENT: 'Select agent',
    SELECT_ESTATE_ADMINISTRATOR: 'Select estate administrator',
    INTERVIEWER: 'Interviewer',
    SELECT_INTERVIEWER: 'Select interviewer',
    SELECT_FILING_TYPE: 'Select filing type',
    SELECT_TRUSTEE: 'Select trustee',
    SELECT_SERVICE_LOCATION: 'Select service location',
    IN_PROGRESS: 'In Progress',
    APPLICATION_FORM_STATUS: 'Application Form Status',
    FILING_TYPE_NOT_SELECTED: 'Filing type not selected',
    SIGNUP_DOCUMENT_CHECKLIST: 'Sign-up Document Checklist',
    NA: 'N/A',
    FORM_56_UPLOAD: 'Form 56 Upload',
    FORM_56_FILING_HISTORY: 'Form 56 Filing History',
    FORM_93_FILING: 'Form 93 Filing',
    FORM_93_FILING_HISTORY: 'Form 93 Filing History',
    FORM_95_FILING: 'Form 95 Filing',
    FORM_95_FILING_HISTORY: 'Form 95 Filing History',
    FORM_96_FILING: 'Form 96 Filing',
    FORM_96_FILING_HISTORY: 'Form 96 Filing History',
    PROPOSAL_CHECKLIST: 'Proposal Checklist',
    FORM_56_FILING: 'Form 56 Filing',
    YOU_ARE_ABOUT_TO_UPDATE_THE_PROPOSAL_AS_ACCEPTED:
      'You are about to update the proposal as Accepted. Do you want to continue?',
    CONFIRM_PROPOSAL_STATE_CHANGE: 'Confirm Proposal State Change',
    ENSURE_TO_CHECK_IF_THE_APPROVAL_STATUS_IN_ASCEND_IS_MEETING_REQUESTED:
      'Please ensure to check if the approval status in Ascend is “Meeting Requested”, then a meeting with creditor should be completed in the current system.',
    UNABLE_TO_PROCESS_YOUR_REQUEST_AS_THERE_IS_ONE_OR_MORE_CREDITORS_NOT_MARKED_AS_YES:
      'Unable to process your request as there is one or more creditors with Completed not marked as Yes. Please check and try again.',
    UNABLE_TO_PROCESS_YOUR_REQUEST_AS_THERE_IS_ONE_OR_MORE_MEETING_WITH_CREDITOR_APPOINTMENTS_THAT_ARE_NOT_COMPLETE:
      'Unable to process your request as there is one or more ‘Meeting with Creditor’ appointments that are not complete. Please check and try again.',
    UNABLE_TO_PROCESS_YOUR_REQUEST_AS_NO_VALUE_IS_SELECTED_FOR_PROPOSAL_STATE:
      'Unable to process your request as no value is selected in the ‘Proposal State’ field. Please check and try again.',
    UNABLE_TO_PROCESS_YOUR_REQUEST_AS_THERE_IS_NO_CHECKLIST_DOCUMENT_ATTACHED:
      'Unable to process your request as there is no checklist document attached in the “Proposal Checklist” field. Please check and try again.',

    PROPOSAL_REJECTED_PROCESS: 'Proposal Rejected Process',
    PROPOSAL_WITHDRAWN_PROCESS: 'Proposal Withdrawn Process',
    INSOLVENCY: 'Insolvency',
    NO_OF_WARNINGS: 'No. of warnings',
    PROPOSAL_STATUS: 'Proposal status',
    COURT_REVIVAL: 'Court Revival',
    PROPOSAL_PROCESS_STATUS: 'Proposal Process Status',
    PROPOSAL_STATE: 'Proposal State',
    PAYMENT_STATUS: 'Payment Status',
    FORMS: 'Forms',
    MEETING_WITH_CREDITORS: 'Meeting with creditors',
    ADD_APPOINTMENT: 'Add appointment',
    REVIVED: 'Revived',
    UPLOAD_SHARE: 'Upload & Share',
    PENDING_PROCESS: 'Pending process',
    OPPOSITION_COMMENTS: 'Opposition comments',
    DOCUMENT_NAME: 'Document Name',
    REQUESTED_ON: 'Requested on',
    REQUESTED_BY: 'Requested by',
    APPROVED_ON: 'Approved On',
    APPROVED_BY: 'Approved By',
    EXCEPTION: 'Exception',
    SESSIONS: 'Sessions',
    ADD_SESSION: 'Add Session',
    COUNSELLING: 'Counselling',
    SESSION: 'Session',
    RELATED_DEBTORS: 'Related Debtor(s)',
    INCLUDE_RELATED_DEBTOR_IN_COUNSELLING_SESSION: 'Include Related Debtor in Counselling Session',
    SESSION_IS_UPDATED: 'Session is updated',
    SCHEDULE_THE_COUNSELLING_SESSION_WITH_THE_CURRENT_AND_RELATED_DEBTOR:
      'You are about to schedule the counselling session with the current and related debtor. Do you want to continue?',
    INITIAL_INFO: 'initial info',
    NEW_COUNSELLING_SESSION: 'New counselling session',
    INITIAL_INFORMATION: 'Initial Information',
    SCHEDULING_APPOINTMENT: 'Scheduling Appointment',
    WITH: 'with',
    BANKING: 'Banking',
    VIEW_PAD_REQUEST: 'View PAD request',
    REVIEW_STATUS: 'Review status',
    REJECT_REASON: 'Reject reason',
    VIEW_CHEQUEPAD_REQUEST: 'View cheque/PAD request',
    NUMBER: 'Number',
    AGR_NUMBER: 'Agr number',
    DEPOSITED: 'Deposited',
    STATE: 'State',
    HOLD_UNTIL: 'Hold until',
    HOLD_REASON: 'Hold reason',
    PAD_INFO_CHANGE_REQUEST_IS_CREATED: 'PAD Info Change Request is created',
    PAD_INFO_CHANGE_REQUEST_IS_UPDATED: 'PAD Info Change Request is updated',
    REVIEW_PAD_REQUEST: 'Review PAD request',
    ACCOUNT_NAME: 'Account name',
    BANK_NAME: 'Bank name',
    BANK_ADDRESS: 'Bank address',
    TRANSIT_NUMBER: 'Transit number',
    BRANCH_NUMBER: 'Branch number',
    REVIEW: 'Review',
    HOLD_REQUESTED: 'Hold Requested',
    ON_HOLD: 'On Hold',
    REQUEST_REJECTED: 'Request Rejected',
    CHEQUEPAD_REQUEST_IS_UPDATED: 'Cheque/PAD request is updated',
    REVIEW_CHEQUEPAD_REQUEST: 'Review Cheque/PAD request',
    HOLD_UNTIL_DATE_SHOULD_BE_IN_FUTURE: 'Hold Until date should be in future',
    SAVE_CONFIRM: 'Save & Confirm',
    NOT_DEPOSITED: 'Not deposited',
    UPDATED_BY: 'Updated by',
    PAD_HISTORY: 'PAD History',
    CHEQUEPAD_SCHEDULE: 'Cheque/PAD Schedule',
    CHEQUEPAD_SCHEDULE_HISTORY: 'Cheque/PAD Schedule History',
    BANKING_STATUS: 'Banking status',
    PAD_INFORMATION: 'PAD information',
    CHANGE_REQUEST: 'Change Request',
    NEW: 'New',
    VIEW_ASSET: 'View Asset',
    ASSET_TYPE: 'Asset type',
    MOTOR_VEHICLE: 'Motor vehicle',
    ASSET_DESC: 'Asset desc.',
    CAR: 'Car',
    CREDITOR: 'Creditor',
    BMO_BANK: 'BMO Bank',
    DEBT: 'Debt',
    ESTIMATION_VALUE: 'Estimation value',
    REALIZATION_VALUE: 'Realization value',
    DISPOSITION: 'Disposition',
    POLICY_NUMBER: 'Policy Number',
    PREAUTHORIZATION_FORM_REQUIRED_FOR_ACCOUNT_IN_WHICH_YOU_WILL_BE_DRAWING_MONEY_FROM:
      'Pre-Authorization Form required for account in which you will be drawing money from',
    INCOMES: 'Incomes',
    EXPENSES: 'Expenses',
    NO_TASKS_ARE_FOUND_WITH_THE_SPEFICIED_CRITERIA: 'No tasks are found with the speficied criteria',
    ONLY_1_OF_THE_2_SUPPORTING_DOCUMENTS_LISTED_FOR_BANKING_INFORMATION_IS_REQUIRED:
      'Only 1 of the 2 supporting documents listed for Banking Information is required.',

    UPLOAD_FAILED: 'Upload failed',
    UPLOAD_FAILED_APPFORM_OVER_ONE: "Upload failed. Can't upload more than one document.",
    UPLOAD_FAILED_APPFORM_OVER_TWO: "Upload failed. Can't upload more than two documents.",
    UPLOAD_FAILED_APPFORM_ONE_DOCUMENT_FOR_SPOUSE_REQUIRED:
      'Upload failed. One of the documents needs to be for the spouse of the client.',
    UPLOAD_FAILED_APPFORM_ONE_DOCUMENT_FOR_CLIENT_REQUIRED:
      'Upload failed. One of the documents needs to be for the client.',
    REQUEST_FOR_REVIEW: 'Request for review',
    STAFF_USER: 'Staff User',
    ALLOCATION: 'Allocation',
    ALLOCATIONS: 'Allocations',
    AUTO_ASSIGN: 'Auto-Assign',
    SEARCH_BY_COURTNUMBER_COURTNAME_COURTCITY_OR_TRUSTEENAME:
      'Search by court number, court name, court city or trustee name',
    DOCUMENT_PREFIX: 'Document Prefix',
    GROUP_BY: 'Group By',
    TO_DATE_SHOULD_BE_AFTER_FROM_DATE: 'To Date should be after From Date',
    PARAMETERS: 'Parameters',
    VIEW_REPORT: 'View Report',
    HIDE_ADVANCED_SEARCH: 'Hide advanced search',
    ADVANCED_SEARCH: 'Advanced search',
    HAS_EMAIL: 'Has Email',
    HAS_ESTATE: 'Has Estate #',
    SELECT_THE_DATES_YOU_WANT_TO_OVERRIDE: 'Select the date(s) you want to override.',
    START_TIME: 'Start Time',
    END_TIME: 'End Time',
    MEETING_TYPEID: 'meetingTypeId',
    MEETING_TYPE: 'Meeting type',
    SELECT_MEETING_TYPE: 'Select meeting type',
    OFFICE_LOCATION_ID: 'officeLocationId',
    SELECT_OFFICE: 'Select office',
    OFFICE_REGION_ID: 'officeRegionId',
    REGION: 'Region',
    SELECT_REGION: 'Select region',
    COURT: 'Court',
    COURT_IS_REQUIRED: 'Court is required',
    SUBTOTAL: 'Subtotal',
    LENDERS_ADRS_UNIT: "Lender's Address Unit",
    LENDERS_NAME: "Lender's Name",
    BY: 'By',

    BODY_MAX_5000_CHAR_ERROR: 'Body should not exceed 5000 characters',
    MESSAGE_MAX_5000_CHAR_ERROR: 'Message should not exceed 5000 characters',
    RESULT: 'Result',

    ADMITTED: 'Admitted',
    NOTPROVED: 'Not Proved',
    DISALLOWED: 'Disallowed',
    ADMINCOMPLETED: 'Admin Completed',
    AMOUNTDISCREPANCY: 'Amount Discrepancy',
    AMOUNTPOSTINSOLVENCYDATE: 'Amount Post Insolvency Date',
    BANKRUPTCYHIGHWAYCONFIRMATIONS: 'Bankruptcy Highway Confirmations',
    CALCULATIONVERIFICATION: 'Calculation Verification',
    CORRESPONDENCE: 'Correspondence',
    CREDITORSSIGNATUREMISSING: 'Creditors Signature Missing',
    DATEDISCREPANCY: 'Date Discrepancy',
    DEFICIENCY: 'Deficiency',
    DUPLICATECLAIM: 'Duplicate Claim',
    FCTCLAIM: 'FCT Claim',
    FILECLOSED: 'File Closed',
    INSUFFICIENTTOADMIT: 'Insufficient To Admit',
    MERGETAG: 'Merge Tag',
    MISSINGSCHEDULEA: 'Missing Schedule A',
    NAMEDISCREPANCY: 'Name Discrepancy',
    REQUESTSFORADDITIONALINFORMATION: 'Requests For Additional Information',
    RIGHTTOPRIORITY: 'Right To Priority',
    SECUREDCLAIMSFORWARD: 'Secured Claims Forward',
    STATUTEBARRED: 'Statute Barred',
    UNSECURED: 'Unsecured',
    UNSECUREDREVIEW: 'Unsecured - Review',
    ADMINCOMPLETEDSECURED: 'Admin Completed Secured',
    AMOUNTPOSTINSOLVENCYDATESECURED: 'Amount Post Insolvency Date Secured',
    ASSETDISPOSED: 'Asset Disposed',
    CALCULATIONVERIFICATIONSECURED: 'Calculation Verification Secured',
    CORRESPONDENCESECURED: 'Correspondence Secured',
    CREDITORSSIGNATUREMISSINGSECURED: 'Creditors Signature Missing Secured',
    DATEDISCREPANCYSECURED: 'Date Discrepancy Secured',
    FILECLOSEDSECURED: 'File Closed Secured',
    INSUFFICIENTTOPROCESSSECURED: 'Insufficient To Process - Secured',
    LIENREMOVAL: 'Lien Removal',
    MISSINGSECURITYAGREEMENTSECURED: 'Missing Security Agreement - Secured',
    MISSINGSECURITYDETAILS: 'Missing Security Details',
    MISSINGSECURITYREGISTRATION: 'Missing Security Registration',
    PROPERTY: 'Property',
    RIGHTTOPRIORITYSECURED: 'Right To Priority Secured',
    SECURED: 'Secured',
    SECUREDREVIEW: 'Secured Review',
    UNKNOWN: 'Unknown',
    COUNTRY: 'Country',

    TASKS_STATUS_IS_UPDATED: 'Tasks status is updated',
    FILE: 'File',

    TASK_HAS_PENDING_ACTIONS: 'There are one or more pending actions for this task.',

    WORKING_HOURS: 'Working hours',

    WEEKLY_SCHEDULE: 'Weekly Schedule',
    CALENDAR_VIEW: 'Calendar View',

    RESTORE_VERSION: 'Restore Version',
    ADD_APPOINTMENT_UPPER: 'Add Appointment',

    CONFIRM_FILE_IN_EXISTING_SYSTEM: 'Confirm File in existing system',
    SAVE_COURT_APPEARANCE_REQUEST_FAILED: 'Save Court Appearance Request failed',
    SAVE_COURT_APPEARANCE_RESPONSE_FAILED: 'Save Court Appearance Response failed',

    DISCHARGE_COURT_ORDER: 'Court Order',

    CONCERN_DOCUMENT_REQUIRED: 'At least one consent document (Prefix is Consent CB) is required',
    TRADES_IMPORTED_SUCCESS: 'The selected liabilities are imported into the application form as debts',
    CREDITOR_EMAIL: 'Creditor Email',
    CREDITOR_EMAIL_REQUIRED: 'No email address is available for the creditor. Cannot send Creditor Email.',
    ONLY_1_OF_THE_2_SUPPORTING_DOCUMENTS_LISTED_FOR_PENSION_INCOME_IS_REQUIRED:
      'Only 1 of the 2 supporting documents listed for Pension/Annuities is required.',

    PERSONAL_INFO_DOCUMENTS_UPLOAD_ERROR_CONFIRM:
      'You have not selected a prefix for the Document(s) that have been uploaded. Select "Stay" to stay on the page and select prefixes. Select "Leave" to leave the page and the uploaded documents will be lost.',
    REQUIRED_DOCUMENT_FOR_BUSINESS_IS_MISSING: 'Required document(s) for business section is missing',
    QUESTIONNAIRE_DOCUMENTS_UPLOAD_ERROR_CONFIRM:
      'You have not selected a prefix or a business for the Document(s) that have been uploaded. Select "Stay" to stay on the page and select prefixes/businesses. Select "Leave" to leave the page and the uploaded documents will be lost.',

    ENGLISH_SPEAKING: 'English Speaking',
    FRENCH_SPEAKING: 'French Speaking',
    BILINGUAL_SPEAKING: 'Bilingual Speaking',
    FILINGTYPE_CHANGE_CONFIRMATION_MESSAGE:
      'You are about to annul the current estate and a new estate will be created. Do you want to continue?',
    FILINGTYPE_CHANGE_CONFIRMATION_TITLE: 'Update Filing Type',
    OVERRIDE_REASON_REQUIRED: 'Override Reason is required',
    OVERRIDE_TITLE: 'Override',
    FILE_UPLOAD: 'File Upload',
    REVIEWED_BY_ADMIN: 'Reviewed by Admin',
    CONFIRM_DELETION: 'Confirm Deletion',
    ARE_YOU_SURE_YOU_WANT_TO_DELETE_FILE: 'Are you sure you want to delete {0}?',
    DOCUMENT_WAS_DELETED: 'Document was deleted',
    SOMETHING_WENT_WRONG: 'Something went wrong',
    REVIEW_STATUS_WAS_CHANGED: 'Review Status was changed',
    UPLOAD_DOCUMENT: 'Upload Document',
    SUCCESSFULLY_OVERRIDEN: 'Successfully overriden',
    REMOVE_OVERRIDE: 'Remove Override',
    ENTER_OVERRIDE_REASON: 'Enter Override Reason',
    OVERRIDE_REASON: 'Override reason',
    PROVIDE_REASON_TO_REMOVE_A_REQUIRED_DOCUMENT:
      'In order to remove "{0}" as a required document, please enter a reason.',
    ARE_YOU_SURE_YOU_WANT_TO_REMOVE_THIS_OVERRIDE: 'Are you sure you want to remove this override?',
    OVERRIDE_SUCCESSFULLY_REMOVED: 'Override successfully removed',
    RECEIVED_EMAIL_CONSENT: 'Received Email Consent',
    MARKETING_REPORT: 'Marketing Report',
    REPORTS: 'Reports',
    APPOINTMENT_SCHEDULED_FROM: 'Appointment Scheduled From',
    APPOINTMENT_SCHEDULED_TO: 'Appointment Scheduled To',
    FILE_CREATED_FROM: 'File Created From',
    FILE_CREATED_TO: 'File Created To',
    REPORT_PERIOD_CANNOT_EXCEED_1_YEAR: 'Report period cannot exceed 1 year',
    REPORT_PERIOD_CANNOT_EXCEED_N_MONTHS: 'Report period cannot exceed {0} months',
    FROM_DATE_SHOULD_BE_BEFORE_TO_DATE: 'From Date should be before To Date',
    DEBTOR_FIRST_NAME: 'Debtor First Name',
    DEBTOR_LAST_NAME: 'Debtor Last Name',
    DEBTOR_EMAIL_ID: 'Debtor Email ID',
    DEBTOR_MAIN_PHONE_NUMBER: 'Debtor Main Phone Number',
    REFERRAL_SOURCE: 'Referral Source',
    REFERRAL_SOURCE_DETAIL: 'Referral Source Detail',
    REFERRAL_SOURCE_ALL: 'Referral Source All',
    WEBURL: 'WebURL',
    DEBTOR_CREATED_DATE_AND_TIME: 'Debtor Created Date and Time',
    ESTATE_NO: 'Estate No',
    FILESTAGE: 'FileStage',
    NOT_STARTED: 'Not Started',
    INITIAL_CONSULTATION: 'Initial Consultation',
    GATHER_DOCUMENTS: 'Gather Documents',
    SIGNUP: 'SignUp',
    FILE_IN_PROGRESS: 'File In Progress',
    SIGNUP_COMPLETED_DATE_TIME: 'Sign-up Completed Date & Time',
    APPOINTMENT_CREATION_DATE_TIME: 'Appointment Creation Date & Time',
    APPOINTMENT_CREATED_BY: 'Appointment Created By',
    APPOINTMENT_ASSIGNED_TO: 'Appointment Assigned To',
    APPOINTMENT_STATUS: 'Appointment Status',
    APPOINTMENT_DATE_TIME: 'Appointment Date & Time',
    APPOINTMENT_LOCATION: 'Appointment Location',
    APPOINTMENT_MEETING_TYPE: 'Appointment Meeting Type',
    NO_SHOW: 'No show',
    TOTAL_DUE: 'Total Due $',
    INTAKE_SOURCE: 'Intake Source',
    TOTAL_DEPOSITED: 'Total Deposited $',
    TOTAL_OUTSTANDING: 'Total Outstanding $',
    PAYMENT_SUMMARY: 'Payment Summary',
    FRENCH_PREFIX: 'French Prefix',
    FRENCH_PREFIX_REQUIRED: 'French Prefix is required',

    CASH: 'Cash',
    GIC: 'GIC',
    SAVINGS: 'Savings',
    TFSA: 'TFSA',
    HOUSEHOLD_FURNITURE_EFFECTS: 'Household Furniture & Effects',
    JEWELRY: 'Jewelry',
    LIFE_INSURANCE: 'Life Insurance',
    RRIF: 'RRIF',
    LIRA: 'LIRA',
    RESP: 'RESP',
    SHARES: 'Shares',
    BONDS: 'Bonds',
    COOP_SHARES: 'Co-Op Shares',
    CREDIT_UNION_SHARES: 'Credit Union Shares',
    RDSP: 'RDSP',
    PENSION: 'Pension',
    HOUSE: 'House',
    CONDO: 'Condo',
    LAND: 'Land',
    COTTAGE: 'Cottage',
    AUTOMOBILE: 'Automobile',
    SNOWMOBILE: 'Snowmobile',
    MOTORCYCLE: 'Motorcycle',
    BOAT: 'Boat',
    TRAILER: 'Trailer',
    CAMPER: 'Camper',
    ATV: 'ATV',
    TOOLS_OF_TRADE: 'Tools Of Trade',
    HUNTING_TOOLS: 'Hunting Tools',
    FISHING_TOOLS: 'Fishing Tools',
    FARMING_TOOLS: 'Farming Tools',
    WITHDRAWN: 'Withdrawn',
    WHICH_INDUSTRY_DO_YOU_WORK_IN: 'Which industry do you work in?',
    WHICH_INDUSTRY_DO_YOU_WORK_IN_REQUIRED: 'Which industry do you work in? is required',
    PLEASE_PROVIDE_YOUR_EXPLOYMENT_HISTORY_DURING_THE_LAST_2_YEARS:
      'Please provide your employment history during the last two (2) years.',
    EMPLOYED_FULLTIME: 'Employed – full-time',
    EMPLOYED_PART_TIME: 'Employed – part time',
    EMPLOYED_SEASONAL: 'Employed – Seasonal',
    SELFEMPLOYED: 'Self-employed',
    RETIRED: 'Retired',
    STUDENT: 'Student',
    DECEASED: 'Deceased',
    NOT_EMPLOYED: 'Not Employed',
    HEALTHCARE: 'Healthcare',
    EDUCATION: 'Education',
    INFORMATION_TECHNOLOGY: 'Information Technology',
    ENGINEERING: 'Engineering',
    FINANCE: 'Finance',
    SALES_AND_MARKETING: 'Sales and Marketing',
    LEGAL: 'Legal',
    ARTS_AND_MEDIA: 'Arts and Media',
    HOSPITALITY: 'Hospitality',
    CONSTRUCTION_AND_TRADES: 'Construction and Trades',
    TRANSPORTATION: 'Transportation',
    MANUFACTURING: 'Manufacturing',
    RETAIL: 'Retail',
    GOVERNMENT: 'Government',
    SCIENCE_AND_RESEARCH: 'Science and Research',
    SOCIAL_SERVICES: 'Social Services',
    AGRICULTURE: 'Agriculture',
    HUMAN_RESOURCES: 'Human Resources',
    CUSTOMER_SERVICE: 'Customer Service',
    SECURITY: 'Security',
    SPORTS_AND_RECREATION: 'Sports and Recreation',
    HOME: 'Home',
    TO_THE_ESTATE: 'To the Estate',
    TO_PROVIDER_OF_FINANCIAL_ADVICE_OTHER_THAN_THE_LICENSED_INSOLVENCY_TRUSTEE:
      'To Provider of Financial Advice (other than the Licensed Insolvency Trustee)',
    CHILD_SUPPORT_PAYMENTS: 'Child Support Payments',
    SPOUSAL_SUPPORT_PAYMENTS: 'Spousal Support Payments',
    CHILD_CARE: 'Child Care',
    MEDICAL_DENTAL_PRESCRIPTION: 'Medical / Dental / Prescription',
    FINES_PENALTIES_IMPOSED_BY_COURT: 'Fines / Penalties Imposed By Court',
    AS_A_CONDITION_OF_EMPLOYMENT: 'As a Condition of Employment',
    DEBTS_WHERE_STAY_HAS_BEEN_LIFTED: 'Debts Where Stay Has Been Lifted',
    RENT_BOARD_MORTGAGE_PAYMENT: 'Rent / Board / Mortgage Payment',
    PROPERTY_TAXES_CONDO_FEES: 'Property Taxes / Condo Fees',
    RENT_MORTGAGE_HYPOTHEC: 'Rent / Mortgage / Hypothec',
    TELEPHONE_CELL_PHONE: 'Telephone / Cell Phone',
    CABLE_STREAMING_SERVICES: 'Cable / Streaming Services',
    TOBACCO_VAPING_CANNABIS: 'Tobacco / Vaping / Cannabis',
    MEALS_RESTAURANTS: 'Meals / Restaurants',
    VEHICLE_INSURANCE_REGISTRATION: 'Vehicle Insurance / Registration',
    ELECTRICITY: 'Electricity',
    HEAT_GAS_OIL: 'Heat / Gas / Oil',
    TELEPHONE: 'Telephone',
    CABLE_INTERNET: 'Cable / Internet',
    HYDRO_ELECTRICITY: 'Hydro / Electricity',
    WATER: 'Water',
    FOOD_GROCERY: 'Food / Grocery',
    LAUNDRY_DRY_CLEANING: 'Laundry / Dry Cleaning',
    GROOMING_TOILETRIES: 'Grooming / Toiletries',
    CLOTHING: 'Clothing',
    CAR_LEASE_FINANCE_PAYMENTS: 'Car Lease / Finance Payments',
    REPAIR_MAINTENANCE_GAS: 'Repair / Maintenance / Gas',
    PUBLIC_TRANSPORTATION: 'Public Transportation',
    SMOKING: 'Smoking',
    ALCOHOL: 'Alcohol',
    DINING_LUNCHES_RESTAURANTS: 'Dining / Lunches / Restaurants',
    ENTERTAINMENT_SPORTS: 'Entertainment / Sports',
    GIFTS_CHARITABLE_DONATIONS: 'Gifts / Charitable Donations',
    ALLOWANCES: 'Allowances',
    FURNITURE_CONTENTS: 'Furniture / Contents',
    TO_SECURED_CREDITORS: 'To Secured Creditor(s)',
    PRESCRIPTIONS: 'Prescriptions',
    DENTAL: 'Dental',
    LOSS_OF_INCOME: 'Loss of Income',
    MEDICAL_REASONS: 'Medical Reasons',
    TAX_LIABILITIES: 'Tax Liabilities',
    FINANCIAL_SUPPORT_OF_OTHERS: 'Financial Support of Others',
    STUDENT_DEBT: 'Student Debt',
    MOVING_RELOCATION_EXPENSES: 'Moving/Relocation Expenses',
    FAILED_PROPOSAL: 'Failed Proposal',
    FINANCIAL_MISMANAGEMENT: 'Financial Mismanagement',
    LEGAL_MATTERS_PLEASE_SPECIFY: 'Legal Matters (Please Specify)',
    OTHER_PLEASE_SPECIFY: 'Other (Please Specify)',
    EMPLOYMENT_INCOME: 'Employment Income',
    EMPLOYMENT_INSURANCE_BENEFITS: 'Employment Insurance Benefits',
    SPOUSAL_SUPPORT: 'Spousal Support',
    PENSION_ANNUITIES: 'Pension / Annuities',
    CHILD_SUPPORT: 'Child Support',
    SELFEMPLOYMENT_INCOME: 'Self-Employment Income',
    SOCIAL_ASSISTANCE: 'Social Assistance',
    CHILD_TAX_BENEFIT: 'Child Tax Benefit',
    RENTAL_INCOME: 'Rental Income',
    GOVERNMENT_BENEFITS: 'Government Benefits',
    OTHER_BENEFITS: 'Other Benefits',
  },
  fr: {
    //Text from Apr 14 Translation file
    RESET: 'Réinitialiser',
    ABOVE_FEES: 'Frais susmentionnés',
    ABSTAIN: 'S’abstenir',
    ACCESS_TOKEN: 'Jeton d’accès',
    ACCOUNT: 'Compte',
    ACCOUNT_DETAILS: 'Renseignements sur le compte',
    ACCOUNT_NAME: 'Nom du compte',
    ACCOUNT_NUMBER: 'Numéro de compte',
    ACTION: 'Action',
    ACTION_CREATED_SUCCESS_MESSAGE: 'Action créée',
    ACTION_NAME: 'Nom de l’action',
    ACTION_s: 'Actions liées au rapport du syndic (article 170)',
    ACTION_STATUS: 'État de l’action',
    ACTION_STATUS_IS_UPDATED: 'État de l’action mis à jour',
    ACTION_UPDATED_SUCCESS_MESSAGE: 'Action mise à jour',
    ACTIONS: 'Actions',
    ACTIONS_170: 'Actions liées au rapport du syndic (article 170)',
    ACTIVE: 'Actif',
    ADD: 'Ajouter',
    ADD_A_NOTE: 'Ajouter une note',
    ADD_APPOINTMENT: 'Ajouter un rendez-vous',
    ADD_BANKING_INFORMATION: 'Ajouter des renseignements bancaires',
    ADD_BUSINESS: 'Ajouter une entreprise',
    ADVICE_RECEIVED_BY_OTHER_CONSULTANTS_APPLICANT_TITLE: "Conseils reçus par d'autres consultants ou professionnels",
    ADVICE_RECEIVED_BY_OTHER_CONSULTANTS_APPLICANT_TITLE_APPLICANT: 'Demandeur',
    ADVICE_RECEIVED_BY_OTHER_CONSULTANTS_APPLICANT_TITLE_SPOUSE: 'Conjoint(e)/Partenaire',
    ADVICE_RECEIVED_BY_OTHER_CONSULTANTS_APPLICANT_RADIO_SUBTITLE:
      'Au cours des six (6) derniers mois, avez-vous reçu des conseils professionnels sur votre situation financière par un fournisseur autre que le syndic autorisé en insolvabilité?',
    ADVICE_RECEIVED_BY_OTHER_CONSULTANTS_APPLICANT_SECTION_SUBTITLE:
      'Identifiez tout fournisseur de conseils financiers professionnels:',
    ADVICE_RECEIVED_BY_OTHER_CONSULTANTS_APPLICANT_SURNAME: 'Nom de famille',
    ADVICE_RECEIVED_BY_OTHER_CONSULTANTS_APPLICANT_SURNAME_VALIDATION: 'Le champ Nom de famille est obligatoire.',
    ADVICE_RECEIVED_BY_OTHER_CONSULTANTS_APPLICANT_GIVEN_NAME: 'Prénom(s)',
    ADVICE_RECEIVED_BY_OTHER_CONSULTANTS_APPLICANT_GIVEN_NAME_VALIDATION: 'Le champ Prénom(s) est obligatoire.',
    ADVICE_RECEIVED_BY_OTHER_CONSULTANTS_APPLICANT_FIRM_NAME:
      'Identifiez l’entreprise ou l’organisation de la personne ayant fourni les conseils financiers professionnels',
    ADVICE_RECEIVED_BY_OTHER_CONSULTANTS_APPLICANT_FIRM_NAME_VALIDATION:
      'Le champ Identifiez l’entreprise ou l’organisation de la personne ayant fourni les conseils financiers professionnels est obligatoire.',
    ADVICE_RECEIVED_BY_OTHER_CONSULTANTS_APPLICANT_FIRM_ADDRESS: 'Adresse de l’entreprise ou l’organisation',
    ADVICE_RECEIVED_BY_OTHER_CONSULTANTS_APPLICANT_FIRM_ADDRESS_VALIDATION:
      'Le champ Adresse de l’entreprise ou l’organisation est obligatoire.',
    ADVICE_RECEIVED_BY_OTHER_CONSULTANTS_APPLICANT_FIRM_ADDRESS_UNIT: 'Unité d’adresse',
    ADVICE_RECEIVED_BY_OTHER_CONSULTANTS_APPLICANT_TOTAL_AMOUNT_PAID_TO_DATE: 'Montant total versé à ce jour',
    ADVICE_RECEIVED_BY_OTHER_CONSULTANTS_APPLICANT_TOTAL_AMOUNT_PAID_TO_DATE_VALIDATION:
      'Le champ Montant total versé à ce jour est obligatoire.',
    ADVICE_RECEIVED_BY_OTHER_CONSULTANTS_APPLICANT_TOTAL_REMAINING_AMOUNT_TO_BE_PAID:
      'Montant total restant à être versé',
    ADVICE_RECEIVED_BY_OTHER_CONSULTANTS_APPLICANT_TOTAL_REMAINING_AMOUNT_TO_BE_PAID_VALIDATION:
      'Le champ Montant total restant à être versé est obligatoire.',
    ADVICE_RECEIVED_BY_OTHER_CONSULTANTS_APPLICANT_ADD_PROVIDER_BUTTON_TEXT: '“Ajouter un fournisseur”',
    ADD_DEPENDANT: 'Ajouter une personne à charge',
    ADD_DESIRED_NOTE_DISCLOSURE_HERE:
      'Ajouter ici la note en annexe si vous ne souhaitez pas utiliser la note standard fournie dans le rapport.',
    ADD_EI_PERIOD: 'Ajouter une période d’assurance-emploi',
    ADD_EMPLOYMENT: 'Ajouter un emploi',
    ADD_HOLIDAY: 'Ajouter un congé',
    ADD_INCOME_EXPENSE: 'Ajouter des revenus et des dépenses',
    ADD_INSOLVENCY: 'Ajouter une insolvabilité',
    ADD_LOAN: 'Ajouter un prêt',
    ADD_NOTE: 'Ajouter une note',
    ADD_OVERRIDE_DATE: 'Ajouter une date de dérogation',
    ADD_OVERRIDE_DATES: 'Ajouter des dates de dérogation',
    ADD_SESSION: 'Ajouter une séance',
    ADD_TEMPLATE: 'Ajouter un modèle',
    ADD_USER: 'Ajouter un utilisateur',
    ADDITIONAL_INFORMATION: 'Renseignements supplémentaires',
    ADDITIONAL_NOTES: 'Notes supplémentaires',
    ADDRESS_TYPE: 'Type d’adresse',
    ADJUSTMENT_TO_UNSECURED_CLAIMS: 'Ajustement aux créances ordinaires',
    ADMINCOMPLETED: 'Administration terminée',
    ADMINCOMPLETEDSECURED: 'Administration terminée consignée',
    ADMINISTRATIVE_DESBURSEMENTS: 'Débours au titre des frais d’administration',
    ADMINISTRATOR_TRUSTEE_FEE_SUBJECT_TO_TAXES: 'Honoraires de l’administrateur ou du syndic assujettis à l’impôt',
    ADMINISTRATOR_TRUSTEE_FEES: 'Honoraires de l’administrateur ou du syndic',
    ADMITTED: 'Admis',
    ADVANCED_SEARCH: 'Recherche avancée',
    AGAINST: 'Contre',
    AGENT: 'Agent',
    AGENT_IS_REQUIRED: 'Un agent est obligatoire.',
    AGR_NUMBER: 'Numéro de contrat',
    ALERT_MODAL_MISSING_VALUE_MESSAGE:
      'Vos données ne peuvent pas être enregistrées. Au moins une valeur est manquante.',
    ALIMONY: 'Pension alimentaire',
    ALL_OTHER_UNSECURED_CREDITORS: 'Tous les autres créanciers ordinaires',
    ALL_SELECTED_FILES: 'Tous les dossiers sélectionnés',
    ALL_TASKS: 'Toutes les tâches',
    ALLOCATION: 'Répartition',
    ALLOCATION_OF_AMOUNT_AVAILABLE_FOR_DISTRIBUTION: 'Répartition du montant disponible aux fins de distribution',
    ALLOCATIONS: 'Répartitions',
    ALLOWABLE_MNTHLY_NET_INC_STNDRD: 'Revenu net mensuel permis selon les normes',
    ALLOWABLE_MONTHLY_NET_INCOME_PER_STANDARD: 'Revenu net mensuel permis selon les normes',
    ALLOWABLE_NET_INCOME_PER_STANDARD: 'Revenu net mensuel permis selon les normes',
    ALSO_KNOWN_AS: 'Alias',
    AMOUNT: 'Montant',
    AMOUNT_DOLLARS: 'Montant ($)',
    AMOUNT_DUE: 'Montant dû',
    AMOUNT_OWING: 'Solde à payer',
    AMOUNT_REQUIRED_FOR_PROPOSAL_CONTRIBUTION:
      'Montant requis pour une cotisation de 100 % dans le cadre de la proposition :',
    AMOUNTDISCREPANCY: 'Montant en écart',
    AMOUNTPOSTINSOLVENCYDATE: 'Montant après la date d’insolvabilité',
    AMOUNTPOSTINSOLVENCYDATESECURED: 'Montant après la date d’insolvabilité consignée',
    ANNOTATION_STICKYNOTE: 'annotation et note récurrente',
    ANNULLED: 'Annulé',
    ANY_COSIGNED_GUARANTEED_CLAIMS: 'Existe-t-il des réclamations cosignées ou garanties?',
    ANY_COSIGNED_OR_GUARANTEED_LOANS: 'Existe-t-il des prêts cosignés ou garantis?',
    ANY_DEFECTS_IN_SECURITY: 'Existe-t-il un défaut de sûreté devant être présenté par voie de note?',
    ANY_DEPENDANT_CHILDREN_AND_OR_OTHER_DEPENDANTS:
      'Est-ce qu’il y a des enfants à charge et d’autres personnes à charge?',
    ANY_FACTS_OR_SITUATIONS_WHICH_COULD_AFFECT_THE_RECOVERY_TO_CREDITORS:
      'Est-ce que des faits ou des situations pouvant avoir une incidence sur le recouvrement pour les créanciers qui ne sont pas reflétés dans le bilan du failli doivent être présentés par voie de note?',
    ANY_GIFTS_CONVEYANCES_OR_OTHER_TRANSACTIONS_WHICH_REQUIRE_NOTE_DISCLOSURE:
      'Est-ce que des dons, transferts ou autres opérations doivent être présentés par voie de note?',
    APPLICANT: 'Demandeur',
    APPLICANT_AMOUNT: 'Montant du demandeur',
    APPLICANT_HOUSEHOLD: 'Unité familiale du demandeur',
    APPLICANT_OWNERSHIP_PERCENTAGE: 'Pourcentage de propriété du demandeur',
    APPLICATION_FORM_STATUS: 'État du formulaire de demande',
    APPOINTMENT_HISTORY: 'Historique des rendez-vous',
    APPOINTMENT_TYPE: 'Type de rendez-vous',
    APPOINTMENTS: 'Rendez-vous',
    APPROVED_BY: 'Approuvé par',
    APPROVED_ON: 'Approuvé le',
    APPROVERS_S: 'Approbateur(s)',
    ARE_YOU_A_DIRECTOR: 'Agissez-vous à titre d’administrateur?',
    ASCEND_ERROR__ONE_OR_MORE_FIELDS_MISSING:
      'Au moins une valeur est manquante dans l’un des champs obligatoires du formulaire.',
    ASCEND_ERROR__ONE_OR_MORE_FIELDS_MISSING_HOUSEHOLD_INCOME_AND_EXPENSE:
      'Le nombre de membres dans l’unité familiale de même que les revenus et dépenses sont obligatoires.',
    ASCEND_ERROR__ONE_OR_MORE_FIELDS_MISSING_INCOME_AND_EXPENSE:
      'La sélection des revenus et des dépenses est obligatoire.',
    ASCEND_ERROR__ONE_OR_MORE_FIELDS_MISSING_NO_HOUSEHOLD:
      'Le nombre de membres dans l’unité familiale est obligatoire.',
    ASCRIBED_VALUE_OF_SECURED_CLAIMS: 'Valeurs attribuées aux créances garanties',
    ASSET_DESC: 'Description du bien',
    ASSET_TYPE: 'Type de bien',
    ASSETDISPOSED: 'Bien cédé',
    ASSETS: 'Biens',
    ASSETS_DEBTS: 'Biens et dettes',
    ASSIGN_TRUSTEE: 'Désigner un syndic',
    ASSIGNED_TO: 'Attribué à',
    AT_THIS_ADDRESS_SINCE: 'Depuis quand habitez-vous à cette adresse?',
    ATTACHMENTS: 'Pièces jointes',
    AUTO_ASSIGN: 'Attribuer à soi-même',
    BACK_TO_LIST_VIEW: 'Retour à l’affichage Liste',
    BALANCE_DOLLARS: 'Solde ($)',
    BANK_ADDRESS: 'Adresse de la banque',
    BANK_NAME: 'Nom de la banque',
    BANKING: 'Renseignements bancaires',
    BANKING_ACTIONS: 'Actions bancaires',
    BANKING_INFORMATION: 'Renseignements bancaires',
    BANKING_STATUS: 'Statut bancaire',
    BANKRUPTCY: 'Faillite',
    BANKRUPTCY_DATE: 'Date de la faillite',
    BANKRUPTCY_DISCHARGE_DATE: 'Date de libération de la faillite',
    BANKRUPTCYHIGHWAYCONFIRMATIONS: 'Confirmation de Bankruptcy Highway',
    BASED_OFF: 'Basé sur',
    BASIC: 'De base',
    BASIS_OF_VALUE: 'Base de l’évaluation',
    BMO_BANK: 'Banque BMO',
    BODY: 'Corps du texte',
    BODY_MAX_5000_CHAR_ERROR: 'Le texte ne doit pas dépasser 5 000 caractères.',
    BORROWERS_ADDRESS: 'Adresse des emprunteurs',
    BORROWERS_ADDRESS_UNIT: 'Numéro d’unité des emprunteurs',
    BORROWERS_NAME: 'Noms des emprunteurs',
    BRANCH_NUMBER: 'Numéro de succursale',
    BUDGET: 'Budget',
    BULK_UPDATE: 'Mise à jour en lot',
    BUNDLE_FILES: 'Groupe de dossiers',
    BUSINESS: 'Entreprise',
    BUSINESS_REQUIRED: 'Business is required fr',
    BUSINESS_ADDRESS: 'Adresse de l’entreprise',
    BUSINESS_ADDRESS_UNIT: 'Numéro d’unité de l’entreprise',
    BUSINESS_DEBT: 'Dette de l’entreprise',
    BUSINESS_NAME: 'Nom de l’entreprise',
    BUSINESS_NUMBER_OF_EMPLOYEES: 'Nombre maximal d’employés au cours des douze (12) derniers mois',
    BUSINESS_NUMBER_OF_EMPLOYEES_REQUIRED:
      'Le champ Nombre maximal d’employés au cours des douze (12) derniers mois est obligatoire.',
    BY: 'Par',
    CALCULATIONVERIFICATION: 'Vérification du calcul',
    CALCULATIONVERIFICATIONSECURED: 'Vérification du calcul consignée',
    CANCEL_NEW_PROSPECT: 'Annuler le nouveau client potentiel',
    CAR: 'Véhicule',
    CASH_ON_HAND: 'Espèces en main',
    CHANGE_REQUEST: 'Demande de modification',
    CHANGES_ARE_SAVED: 'Modifications enregistrées',
    CHECKLIST_STATUS: 'État de la liste de contrôle',
    CHECKLIST_STATUS_IS_REQUIRED: 'L’état de la liste de contrôle est obligatoire.',
    CHECKLIST_UPDATED: 'Mise à jour de la liste de contrôle',
    CHEQUEPAD_REQUEST_IS_UPDATED: 'Mise à jour de la demande de paiement par chèque ou prélèvement automatique',
    CHEQUEPAD_SCHEDULE: 'Calendrier des paiements par chèques ou prélèvements automatiques',
    CHEQUEPAD_SCHEDULE_HISTORY: 'Historique du calendrier des paiements par chèques ou prélèvements automatiques',
    CITY: 'Ville',
    CLAIM_ACTIONS: 'Actions liées à une réclamation',
    CLAIMS_STAGE_CLAIM: 'Créances',
    CLEAR: 'Effacer',
    CLICK_OR_DRAG_FILE_TO_THIS_AREA_TO_UPLOAD: 'Cliquer ou faire glisser les fichiers ici pour les téléverser',
    CLOSED: 'Fermé',
    CO_SIGNS_OR_GUARANTEED_LOANS: 'Prêts cosignés ou garantis',
    CODE: 'Code',
    COMMENTS: 'Commentaires',
    COMMISION: 'Commission',
    COMPLETE_SEARCH_IN_ASCEND_AND_CONFIRM_ESTATE: 'Terminer la recherche dans Ascend et confirmer la succession.',
    CONFIRM_DELETE: 'Confirmer la suppression',
    CONFIRM_DELETE_REVIEW_REQUEST_MESSAGE:
      'Vous êtes sur le point de supprimer la demande sélectionnée. Voulez-vous continuer?',
    CONFIRM_DELETE_SELECTED_FILE_MESSAGE:
      'Vous êtes sur le point de supprimer le dossier sélectionné. Voulez-vous continuer?',
    CONFIRM_PROPOSAL_STATE_CHANGE: 'Confirmer la modification de l’état de la proposition',
    CONFIRM_RECOVER: 'Confirmer la récupération',
    CONFIRM_RECOVER_SELECTED_FILES_MESSAGE:
      'Vous êtes sur le point de récupérer le dossier sélectionné. Voulez-vous continuer?',
    CONFIRM_SET_HIDDEN_MESSAGE:
      'Vous avez sélectionné les dossiers du débiteur suivants à révoquer dans le portail client. Voulez-vous continuer?',
    CONFIRM_SET_VISIBLE_MESSAGE:
      'Vous avez sélectionné les dossiers suivants en donnant accès au débiteur dans le portail client. Voulez-vous continuer?',
    CONSENT_AGREEMENT_TEXT:
      'Je consens à ce que mes renseignements personnels soient fournis au syndic aux fins de l’établissement d’une demande de dossier de crédit auprès de TransUnion et du syndic de même que de la validation et de la réalisation de mes dettes et de mes obligations. Ces renseignements personnels peuvent comprendre les éléments suivants : nom, date de naissance, état civil, numéros de téléphone, adresse, renseignements sur une ligne de crédit ou comptes actuels, renseignements bancaires, événements liés à une faillite, à des procédures judiciaires et à des événements de recouvrement, le cas échéant.',
    CONSUMER_PROPOSAL: 'Proposition de consommateur',
    CONTACT_INFORMATION: 'Coordonnées',
    CONTINGENT_DEFERRED_LIABILITIES: 'Créances conditionnelles ou reportées',
    CONTRIBUTIONS_IN_LAST_12_MONTHS: 'Cotisations au cours des 12 derniers mois',
    CONVERSION_RATE: 'Taux de conversion',
    CONVERSION_RATES: 'Taux de conversion',
    COPY_FILES: 'Copie des dossiers',
    CORRESPONDENCE: 'Correspondance',
    CORRESPONDENCESECURED: 'Correspondance consignée',
    COST_OF_ADMINISRATION: 'Frais d’administration',
    COST_TO_COLLAPSE_RESP_25: 'Coût de liquidation du REEE (25 %)',
    COUNSELLING: 'Consultation',
    COUNSELLING_FEES: 'Honoraires de consultation',
    COUNTRY: 'Pays',
    COURT: 'Tribunal',
    COURT_ACTIONS: 'Recours en justice',
    COURT_IS_REQUIRED: 'Le tribunal est obligatoire.',
    COURT_NAME: 'Nom du tribunal',
    COURT_NUMBER: 'Numéro du tribunal',
    COURT_REVIVAL: 'Rétablissement octroyé par le tribunal',
    CREATED: 'Créé',
    CREATED_BY: 'Créé par',
    CREATED_DATE_FROM: 'Date de création (du)',
    CREATED_DATE_TO: 'Date de création (au)',
    CREDIT_BUREAU_CONSENT: 'Consentement du bureau de crédit',
    CREDIT_LIMIT_DOLLARS: 'Limite de crédit ($)',
    CREDITOR: 'Créancier',
    CREDITOR_ADDRESS: 'Adresse du créancier',
    CREDITOR_EMAIL_ADDRESS: 'Adresse électronique du créancier',
    CREDITOR_EMAIL_ADDRESS_IS_INCORRECT: 'L’adresse électronique du créancier est incorrecte.',
    CREDITOR_LIST_NAME: 'Nom de la liste de créanciers',
    CREDITOR_NAME: 'Nom du créancier',
    CREDITOR_NOT_PARTICIPATING_IN_PROPOSAL: 'Créancier ne participant pas à la proposition',
    CREDITOR_TYPE: 'Type de créancier',
    CREDITOR_UNIT: 'Numéro d’unité du créancier',
    CREDITORSSIGNATUREMISSING: 'La signature du créancier est manquante.',
    CREDITORSSIGNATUREMISSINGSECURED: 'La signature des créanciers garantis est manquante.',
    CSV_OF_NONEXEMPT_LIFE_INSURANCE_POLICIES: 'Valeur de rachat des polices d’assurance vie non exemptées',
    CURRENT_EMPLOYER: 'Employeur actuel',
    CURRENT_MARKET_VALUE: 'Valeur marchande actuelle',
    CURRENTLY_RECEIVING_EI: 'Bénéficiaire actuel de l’assurance-emploi',
    CUSTOM: 'Personnalisation',
    DATE: 'Date',
    DATE_IS_REQUIRED: 'Une date est obligatoire.',
    DATE_OF_BIRTH: 'Date de naissance',
    DATE_OF_MARRIAGE: 'Date du mariage',
    DATE_OPERATIONS_CEASED: 'Date de fin des activités',
    DATE_REPORTED: 'Date reportée',
    DATE_YOU_CEASED_BEING_FULL_TIMEPART_TIME_STUDENT:
      'Date à laquelle vous avez cessé d’être étudiant à temps plein ou temps partiel',
    DATEDISCREPANCY: 'Date de l’écart',
    DATEDISCREPANCYSECURED: 'Date de l’écart consignée',
    DAYS_OPEN: 'Jours d’ouverture',
    DEBT: 'Dette',
    DEBT_AREAS_APPLICANT: 'Secteurs d’endettement - Demandeur',
    DEBT_AREAS_SPOUSE_PARTNER: 'Secteurs d’endettement - Époux ou conjoint',
    DEBTOR: 'Débiteur',
    DEBTOR_CONVERSION_REPORT: 'Rapport de conversion du débiteur',
    DEBTOR_PROFILE_IS_UPDATED: 'Le profil du débiteur a été mis à jour.',
    DEBTOR_REQUIRED: 'Le débiteur est obligatoire.',
    DEBTORS_ESTIMATED_EQUITY: 'Valeur nette réelle estimative du débiteur',
    DEBTORS_ESTIMATED_EQUITY_BEFORE_EXEMPTION: 'Valeur nette réelle estimative du débiteur avant l’exemption',
    DEBTORS_INTEREST: 'Intérêt du débiteur',
    DEBTORS_OWNERSHIP_INTEREST: 'Participation des débiteurs',
    DEBTS: 'Dettes',
    DEFALCATION_WHILE_ACTING_IN_A_FIDUCIARY_CAPACITY: 'Abus de confiance en agissant à titre de fiduciaire',
    DEFAULT_OFFICE: 'Bureau par défaut',
    DEFICIENCY: 'Déficience',
    DEPENDANT_FIRST_NAME: 'Prénom de la personne à charge',
    DEPENDANT_LAST_NAME: 'Nom de la personne à charge',
    DEPENDANTS: 'Personnes à charge',
    DEPOSITED: 'Soumis',
    DESCRIBE_WHY_YOU_NEED_FINANCIAL_HELP:
      'Expliquez, dans vos propres mots, pourquoi vous avez besoin d’aide en matière de finances :',
    DESCRIPTION: 'Description',
    DETAILS: 'Renseignements',
    DISALLOWED: 'Refusé',
    DISCLOSE_ALL_PREVIOUS_FILINGS: '*Divulguez toutes les déclarations antérieures',
    DISPOSITION: 'Cession',
    DIVIDEND_TO_ORDINARY_CREDITORS: 'Dividendes aux créanciers ordinaires',
    DIVIDEND_TO_PREFERRED_CREDITORS: 'Dividendes aux créanciers privilégiés',
    DIVIDEND_TO_SECURED_CREDITOR: 'Dividendes aux créanciers garantis',
    DO_YOU_EXPECT_TO_RECEIVE_ANY_SUMS_OF_MONEY_WHICH_ARE_NOT_RELATED_TO_YOUR_NORMAL_INCOME_OR_ANY_OTHER_PROPERTY_WITHIN_THE_NEXT__MONTHS_INCLUDING_INHERITANCE:
      'Vous attendez-vous à recevoir des sommes d’argent qui ne sont pas liées à vos revenus habituels ou tout autre bien au cours des 12 prochains mois (y compris un héritage)?',
    DO_YOU_HAVE_ANY_DEBTS_ARISING_FROM: 'Avez-vous des dettes découlant des éléments suivants :',
    DO_YOU_WANT_TO_UPDATE_THE_STATUS_AS_SIGNUP_INPROGRESS:
      'Souhaitez-vous modifier le statut du dossier pour qu’il apparaisse Signature en cours ? Hub va alors créer un dossier dans la section Entrevue d’Ascend',
    DOCUMENT_NAME: 'Nom du document',
    DOCUMENT_PREFIX: 'Préfixe du document',
    DOES_THE_AMOUNT_RELATED_TO_A_COSIGNED_GUARANTEED:
      'Dans l’affirmative, le montant se rapporte-t-il à un montant cosigné ou garanti dont les prochains paiements prévus seront effectués par un tiers dans le cours normal des affaires?',
    DOES_THE_BUSINESS_HAVE_EMPLOYEES_OR_SUBCONTRACTORS: 'L’entreprise a-t-elle des employés ou des sous-traitants?',
    DOES_THE_BUSINESS_OWE_ANY_SOURCE_DEDUCTIONS_ON_WAGES: 'L’entreprise doit-elle des retenues à la source?',
    DOES_THE_BUSINESS_OWE_ANY_WAGES_TO_EMPLOYEES: 'L’entreprise doit-elle des salaires à ses employés?',
    DONOTSMS: 'Ne pas envoyer de message texte',
    DOWNLOAD_AS_CSV_FILE: 'Télécharger comme fichier .csv',
    DOWNLOAD_CSV: ' Télécharger le fichier .csv',
    DUE_DATE: 'Date d’échéance',
    DUE_DATE_IS_REQUIRED: 'Une date d’échéance est obligatoire.',
    DUPLICATE: 'Duplicata',
    DUPLICATECLAIM: 'Réclamation en double',
    EDIT_ASSET: 'Modifier le bien',
    EDIT_DEBT: 'Modifier la dette',
    EDIT_FILE: 'Modifier le dossier',
    EDIT_HOLIDAY: 'Modifier le congé',
    EDIT_TEMPLATE: 'Modifier le modèle',
    EDIT_USER: 'Modifier l’utilisateur',
    EMAIL_ADDRESS_IS_INCORRECT: 'L’adresse électronique est invalide.',
    EMAIL_AND_SMS_TEMPLATES: 'Modèles de courriels et de messages textes',
    EMAIL_ID: 'Identifiant de courriel',
    EMAIL_IS_INVALID: 'L’adresse électronique est invalide.',
    EMAIL_SENT_SUCCESS_MESSAGE: 'Courriel envoyé',
    EMBEZZLEMENT: 'Détournement de fonds',
    EMPLOYER: 'Employeur',
    EMPLOYER_ADDRESS: 'Adresse de l’employeur',
    EMPLOYER_ADDRESS_UNIT: 'Numéro d’unité de l’employeur',
    EMPLOYMENT: 'Emploi',
    EMPLOYMENT_STATUS: 'Statut d’emploi',
    END_DATE: 'Date de fin',
    END_TIME: 'Heure de fin',
    END_TIME_SHOULD_BE_GREATER_THAN_START_TIME: 'L’heure de fin doit être ultérieure à l’heure de début',
    ENSURE_TO_CHECK_IF_THE_APPROVAL_STATUS_IN_ASCEND_IS_MEETING_REQUESTED:
      'Veuillez vérifier que l’état de l’approbation dans Ascend est Réunion demandée. Une réunion avec le créancier devrait être indiquée comme terminée dans le système actuel.',
    ERRORS_CONTAINER_TITLE: 'Erreurs et avertissements',
    ESTATE: 'No de la succession',
    ESTATE_ADMIN: 'Administration de la succession',
    ESTATE_NUMBER: 'Numéro de succession',
    ESTIMATED_AMOUNT_AVAILABLE_FOR_DISTRIBUTION: 'Montant estimatif disponible aux fins de distribution',
    ESTIMATED_AVAILABLE_BEFORE_SECURED_CLAIMS: 'Montant estimatif disponible avant les créances garanties',
    ESTIMATED_CLAIMS_ORDINARY_CREDITORS: 'Réclamations estimatives des créanciers ordinaires',
    ESTIMATED_CLAIMS_PREFERRED_CREDITORS: 'Réclamations estimatives des créanciers privilégiés',
    ESTIMATED_CLAIMS_SECURED_CREDITORS: 'Réclamations estimatives des créanciers garantis participants',
    ESTIMATED_DISBURSEMENTS: 'Débours estimatifs',
    ESTIMATED_DISTRIBUTION: 'Distribution estimative',
    ESTIMATED_DISTRIBUTION_TO_ORDINARY_CREDITORS: 'Distribution estimative aux créanciers ordinaires',
    ESTIMATED_DISTRIBUTION_TO_PREFERRED_CREDITORS: 'Distribution estimative aux créanciers privilégiés',
    ESTIMATED_DISTRIBUTION_TO_SECURED_CREDITORS: 'Distribution estimative aux créanciers garantis',
    ESTIMATED_EQUITY: 'Valeur nette réelle estimative',
    ESTIMATED_EQUITY_ABOVE_ALLOWED_EXEMPTION_CLAIMS_IF_ALLOWED:
      'Valeur nette réelle estimative (au-dessus des demandes d’exemption autorisées, le cas échéant)',
    ESTIMATED_FEES: 'Estimation des honoraires',
    ESTIMATED_LEGAL_COSTS: 'Estimation des frais juridiques',
    ESTIMATED_MARKET_VALUE_BASED_ON_OPINIONS_OF_VALUE: 'Valeur marchande estimative selon les opinions sur la valeur',
    ESTIMATED_REAL_ESTATE_COMMISSION: 'Commissions de courtage estimatives',
    ESTIMATED_RECEIPTS: 'Recettes estimatives',
    ESTIMATED_RECOVERY: 'Recouvrement estimatif',
    ESTIMATED_SECURED_CLAIMS: 'Créances garanties estimatives',
    ESTIMATED_TAX_ON_DISPOSITION: 'Impôt estimatif à la cession',
    ESTIMATED_VALUE: 'Valeur estimative',
    ESTIMATION_VALUE: 'Valeur estimative',
    EXCEPTION: 'Exception',
    EXCESS_FUNDS: 'Fonds excédentaires',
    EXEMPT: 'Exempté',
    EXPENSE: 'Dépense',
    EXPENSES: 'Dépenses',
    EXPORT: 'Exporter',
    EXTENDED_INFO: 'Informations complémentaires',
    FCTCLAIM: 'Réclamation de FCT',
    FEE_TYPE: 'Type de frais',
    FEES_DIVISION_I_PROPOSAL: 'HONORAIRES POUR UNE PROPOSITION EN VERTU DE LA SECTION I',
    FEES_FOR_ORDINARY: 'Honoraires pour faillite ordinaire',
    FEES_IN_CONSUMER_PROPOSAL: 'HONORAIRES POUR UNE PROPOSITION DE CONSOMMATEUR',
    FEES_IN_SUMMARY_BANKRUPTCY: 'HONORAIRES POUR UNE FAILLITE SOMMAIRE',
    FEES_ORDINARY_BANKRUPTCY: 'HONORAIRES POUR UNE FAILLITE ORDINAIRE',
    FILE: 'Dossier',
    FILE_IN_PROCESS: 'Dossier en cours',
    FILE_INFORMATION: 'Renseignements du dossier',
    FILE_TYPE: 'Type de dossier',
    FILE_VIEW: 'Consulter le dossier',
    FILECLOSED: 'Dossier fermé',
    FILECLOSEDSECURED: 'Dossier fermé consigné',
    FILENAME: 'Nom du dossier',
    FILES_SIGNED: 'Dossiers signés',
    FILES_UNDER: 'Sous-dossiers',
    FILING_FEES: 'Frais de dépôt',
    FILING_TYPE: 'Type de dépôt',
    FILING_TYPE_NOT_SELECTED: 'Type de dépôt non sélectionné',
    FINANCIAL_DIFFICULTY: 'Difficultés financières',
    FINANCIAL_DIFFICULTY_SPOUSE: 'Difficulté financière du conjoint/partenaire',
    FINE_OR_PENALTY_IMPOSED_BY_COURT_INCLUDING_ASSAULT:
      'Amende ou pénalité imposée par un tribunal (y compris les voies de fait)',
    FOLLOW_UP: 'Suivi',
    FOLLOWUP_DATE: 'Date de suivi',
    FOR: 'Pour',
    FOR_JOINT_FILES__1_ID_REQUIRED_FOR_APPLICANT_1_ID_REQUIRED_FOR_SPOUSE:
      'Pour les dossiers conjoints, une pièce d’identité pour le demandeur et une pièce d’identité pour le conjoint sont obligatoires.',
    FORM_56_FILING: 'Production du formulaire 56',
    FORM_56_FILING_HISTORY: 'Historique de production du formulaire 56',
    FORM_56_UPLOAD: 'Téléverser le formulaire 56',
    FORM_93_FILING: 'Production du formulaire 93',
    FORM_93_FILING_HISTORY: 'Historique de production du formulaire 93',
    FORM_95_FILING: 'Production du formulaire 95',
    FORM_95_FILING_HISTORY: 'Historique de production du formulaire 95',
    FORM_96_FILING: 'Production du formulaire 96',
    FORM_96_FILING_HISTORY: 'Historique de production du formulaire 96',
    FORMS: 'Formulaires',
    FRAUD: 'Fraude',
    FREQUENCY: 'Fréquence des publications',
    FURNITURE: 'Mobilier',
    GAMBLING: 'Problèmes de jeu',
    GO_TO_ERROR: 'Consulter l’erreur',
    GO_TO_WARNING: 'Consulter l’avertissement',
    GRANT_TO_BE_REPAID: 'Subvention à rembourser',
    GRANTS_TO_BE_REPAID: 'Subventions à rembourser',
    GROUP_BY: 'Grouper par',
    HAS_EMAIL: 'A une adresse électronique',
    HAS_ESTATE: 'A un numéro de succession',
    HAVE_YOU_BEEN_OR_ARE_YOU_INVOLVED_IN_CIVIL_LITIGATION_FROM_WHICH_YOU_MAY_RECEIVE_MONIES_OR_PROPERTY:
      'Êtes-vous ou avez-vous été partie à une procédure civile dans le cadre de laquelle vous pourriez recevoir un montant ou un bien?',
    HAVE_YOU_HAD_ANY_ASSETS_SEIZED_OR_GARNISHED_BY_A_CREDITORS_IN_THE_PAST__MONTHS:
      'Vos biens ont-ils fait l’objet d’une saisie ou d’une saisie-arrêt demandée par des créanciers au cours des 12 derniers mois?',
    HAVE_YOU_MADE_ANY_GIFTS_TO_RELATIVES_OR_OTHERS_IN_THE_EXCESS_OF_WHILE_YOU_KNEW_YOURSELF_TO_BE_INSOLVENT_IN_THE_PAST_YEARS:
      'Au cours des cinq (5) années précédant la date de votre première faillite, avez-vous donné à des proches ou d’autres personnes des cadeaux d’une valeur de plus de 1 000 $?',
    HAVE_YOU_MADE_ARRANGEMENTS_TO_CONTINUE_TO_PAY_ANY_CREDITORS_AFTER_FILING:
      'Avez-vous pris des dispositions pour continuer à payer vos créanciers après le dépôt de votre bilan?',
    HAVE_YOU_MADE_PAYMENTS_IN_EXCESS_OF_THE_REGULAR_AMOUNT_TO_CREDITORS_IN_THE_PAST__MONTHS:
      'Avez-vous effectué des paiements supérieurs au montant habituel à des créanciers au cours des douze derniers mois?',
    HAVE_YOU_OWNED_A_BUSINESS_WITHIN_THE_LAST_5_YEARS:
      'Avez-vous possédé une entreprise au cours des cinq dernières années?',
    HAVE_YOU_PREVIOUSLY_FILED_FOR_INSOLVENCY_IN_CANADA_OR_ELSEWHERE:
      'Avez-vous déjà déposé un bilan pour insolvabilité au Canada ou ailleurs?',
    HAVE_YOU_RECEIVED_EI_IN_THE_LAST_TWO_YEARS:
      'Avez-vous reçu des prestations d’assurance-emploi au cours des deux dernières années?',
    HAVE_YOU_SOLD_DISPOSED_OR_TRANSFERRED_ANY_REAL_PROPERTY_OR_OTHER_ASSETS_IN_THE_PAST_YEARS:
      'Au cours des cinq (5) dernières années, avez-vous vendu, disposé ou transféré des actifs, encaissé des REER ou modifié le bénéficiaire désigné d’une police d’assurance vie?',
    HIDDEN: 'Masqué',
    HIDE_ADVANCED_SEARCH: 'Masquer la recherche avancée',
    HIGH_CREDIT_DOLLARS: 'Crédit élevé ($)',
    HIGHEST_EDUCATION_LEVEL_COMPLETED: 'Plus haut niveau de scolarité atteint',
    HOLD_REASON: 'Motif de la mise en suspens',
    HOLD_REQUESTED: 'Mise en suspens demandée',
    HOLD_UNTIL: 'En suspens jusqu’au',
    HOLD_UNTIL_DATE_SHOULD_BE_IN_FUTURE: 'La date En suspens jusqu’au doit être à venir.',
    OFFICE_LOCATIONS: 'Office Locations',
    OFFICE_LOCATION: 'Office Location',
    OFFICE_EDITOR_TITLE: 'Edit Office Location',
    OFFICE_NAME: 'Office Name',
    OFFICE_NAME_REQUIRED: 'Office name is required',
    OFFICE_DISPLAYNAME: 'Display Name',
    OFFICE_LOCATIONCODE_REQUIRED: 'Display name is required',
    OFFICE_PREFIX: 'Prefix',
    OFFICE_PREFIX_REQUIRED: 'Prefix is required',
    OFFICE_LATITUDE: 'Latitude',
    OFFICE_LATITUDE_REQUIRED: 'Latitude is required',
    OFFICE_LONGITUDE: 'Longitude',
    OFFICE_LONGITUDE_REQUIRED: 'Longitude is required',
    OFFICE_ADDRESS1: 'Street Name',
    OFFICE_ADDRESS1_REQUIRED: 'Street Name is required',
    OFFICE_STREETNUMBER_REQUIRED: 'Street Number is required',
    OFFICE_ADDRESS2: 'Street Number',
    OFFICE_ADDRESS_UNIT: 'Unit',
    OFFICE_CITY: 'City',
    OFFICE_CITY_REQUIRED: 'City is required',
    OFFICE_POSTALCODE: 'Postal Code',
    OFFICE_POSTALCODE_REQUIRED: 'Postal Code is required',
    OFFICE_TIMEZONE: 'Time Zone',
    OFFICE_MOVETO: 'Move To',
    OFFICE_IS_SERVICE_LOCATION: 'Emplacement du service',
    OFFICE_IS_TRUSTEE_OFFICE: 'Bureau du Syndic',
    HOLIDAY_DATE: 'Date de congé',
    HOLIDAYS: 'Congés',
    HOUSEHOLD: 'Unité familiale',
    HUNT_FISH_FARM: 'Chasse, pêche et agriculture',
    HUNTING_FISHING_FARMING: 'Outils de chasse, de pêche et d’agriculture',
    HUNTING_FISHING_FARMING_REALIZATION: 'Réalisation des biens liés aux outils de chasse, de pêche et d’agriculture',
    I_E_ACTIONS: 'Actions liées aux revenus et aux dépenses',
    I_UNDERSTAND: 'Je comprends',
    IF_YOU_HAVE_DOCS_UPLOAD_ELSE_SKIP_AND_UPLOAD_LATER:
      'Si vos documents sont prêts, téléversez-les ici. Autrement, passez à la prochaine étape et téléversez-les plus tard.',
    IMPACT_TO_RECOVERY: 'Incidence sur la récupération',
    IN_PERSON: 'En personne',
    IN_PROGRESS: 'En cours',
    INACTIVE: 'Inactif',
    INCLUDE_IN_PROPOSAL: 'Inclure dans la proposition',
    INCLUDE_RELATED_DEBTOR_IN_COUNSELLING_SESSION: 'Inclure un débiteur lié dans la séance de consultation',
    INCOME_EXPENSES: 'Revenus et dépenses',
    INCOME_TAX: 'Impôt sur le revenu',
    INCOMEEXPENSE_STAGE_INCOME_EXPENSE: 'Revenus et dépenses',
    INCOMES: 'Revenus',
    INCOMPLETE: 'Incomplet',
    INCONSISTENT_EMPLOYMENT: 'Précarité d’emploi',
    BUSINESS_FAILURE: 'Échec de l’entreprise',
    SCAM_FRAUD: 'Scam/fraud fr',
    VICTIM_OF_PREDATORY_LENDING: 'Low income leading to predatory lending fr',
    RETIREMENT_LOW_FIXED_INCOME: 'Retirement / Low Fixed Income fr',
    LEGAL_MATTERS: 'Problèmes judiciaires',
    ADDICTION_OTHER_THAN_GAMBLING: 'Dépendance autre qu’au jeu',
    INCREASE_FILE_PREVIEW_SIZE: 'Augmenter la taille de l’aperçu du fichier',
    IND_PORTION_OF_MNTHLY_SRPLS: 'Partie du particulier de l’excédent mensuel total',
    INDIVIDUAL_PORTION_INSURPLUS: 'Partie du particulier de l’excédent mensuel total',
    INFORMATION_UPDATED: 'Informations mises à jour',
    INITIAL_DEPOSIT: 'Dépôt initial',
    INITIAL_INFO: 'Renseignements initiaux',
    INITIAL_INFORMATION: 'Renseignements initiaux',
    INSOLVENCY: 'Insolvabilité',
    INSOLVENCY_NAME_SEARCH: 'Recherche de nom dans un dossier d’insolvabilité',
    INSOLVENT_AT_THE_TIME: 'Insolvable actuellement?',
    INSTITUTION: 'Institution',
    INSUFFICIENTTOADMIT: 'Insuffisant pour l’admission',
    INSUFFICIENTTOPROCESSSECURED: 'Insuffisant pour le traitement - Consigné',
    INTERVIEWER: 'Entrevue réalisée par',
    INVALID_EMAIL: 'Adresse électronique invalide',
    INVESTMENTS: 'Placements',
    IS_THE_CORPORATION_BANKRUPT: 'L’entreprise a-t-elle déclaré faillite?',
    IS_THERE_A_SECURED_CREDITOR: 'Est-ce qu’un créancier garanti risque d’avoir un manque à gagner sur sa créance?',
    JOIN: 'Joindre',
    JOINT_ACCOUNT: 'Compte conjoint',
    JOINT_FILE: 'Dossier conjoint',
    KMS: 'km',
    LAST_ACTIVITY: 'Dernière activité',
    LATITUDE: 'Latitude',
    LEGAL_FEES: 'Frais juridiques',
    LENDERS_ADDRESS: 'Adresse du prêteur',
    LENDERS_ADRS_UNIT: 'Numéro d’unité du prêteur',
    LENDERS_NAME: 'Nom du prêteur',
    LETTER: 'Lettre',
    LIENREMOVAL: 'Suppression du lien',
    LOCATION: 'Emplacement',
    LOCATION_IS_REQUIRED: 'Un emplacement est obligatoire.',
    LOCK_APP_FORM_MESSAGE:
      'Les utilisateurs, soit les clients et les membres du personnel, ne pourront plus modifier le formulaire de demande après le verrouillage.',
    LOCK_APP_FORM_TITLE: 'Voulez-vous verrouiller le formulaire de demande?',
    LOCK_APP_TAB_NAME: 'Verrouiller le formulaire de demande',
    LOG_OUT: 'Fermer la session',
    LONGITUDE: 'Longitude',
    LUMP_SUM: 'Montant forfaitaire',
    MAIN_PHONE_NO: 'No de téléphone principal',
    MAINTENANCE_OF_AFFILIATION_ORDER: 'Maintien d’une ordonnance de filiation',
    MAINTENANCE_OF_SUPPORT_OF_SEPARATED_FAMILY: 'Maintien du soutien à une famille séparée',
    MAKE: 'Marque',
    MANNER_OF_PAYMENT: 'Mode de paiement',
    RELATIONSHIP_BREAKDOWN: 'Rupture du couple',
    MARITAL_STATUS: 'État civil',
    MARK_AS_COMPLETED: 'Indiquer comme terminé',
    MARKET_VALUE: 'Valeur marchande',
    MAX_100MB_UPLOAD_MESSAGE: '*La taille du téléversement combiné ne peut pas dépasser 100 MB.',
    MAX_500_CHAR_ERROR: 'La description ne doit pas dépasser 5 000 caractères.',
    MB: 'MB',
    MB_EACH: 'MB pour chaque',
    MEETING_TYPE: 'Type de réunion',
    MEETING_TYPEID: 'Identifiant du type de réunion',
    MEETING_WITH_CREDITORS: 'Réunion avec les créanciers',
    MEMBER_CODE: 'Mem. code',
    MEMBER_NAME: 'Membre Nom',
    MERGETAG: 'Fusionner l’étiquette',
    MESSAGE_MAX_5000_CHAR_ERROR: 'Le message ne doit pas dépasser 5 000 caractères.',
    METHOD: 'Méthode',
    MISAPPROPRIATION: 'Détournement',
    MISSINGSCHEDULEA: 'Annexe A manquante',
    MISSINGSECURITYAGREEMENTSECURED: 'Contrat de garantie manquant – Consigné',
    MISSINGSECURITYDETAILS: 'Détails de la garantie manquants',
    MISSINGSECURITYREGISTRATION: 'Enregistrement de la garantie manquante',
    MODEL: 'Modèle',
    MODIFIED: 'Modifié',
    MONTHLY_EXPENSE: 'Dépense mensuelle',
    MONTHLY_INCOME: 'Revenu mensuel',
    MONTHLY_PAYMENT_REQUIRED: 'Paiement mensuel exigé',
    MONTHLY_PYMNT_RQRD: 'Paiement mensuel exigé',
    MONTHS: 'Mois',
    MONTHS_REVIEWED: 'Mois examinés',
    MORTGAGE: 'Prêt hypothécaire',
    MOTOR_VEHICLE: 'Véhicule automobile',
    MOTOR_VEHICLES: 'Véhicules automobiles',
    NA: 'S.O.',
    NAME_OF_INSTITUTION: 'Nom de l’institution',
    NAME_OF_PARTNERS__DIRECTORS: 'Noms des associés ou des administrateurs',
    NAMEDISCREPANCY: 'Différence de nom',
    NET_INCOME: 'Résultat net',
    NET_MONTHLY_INCOME: 'Revenu net mensuel',
    NET_REALIZABLE_VALUE: 'Valeur réalisable nette',
    NEW: 'Nouveau',
    NEW_APPOINTMENT: 'Nouveau rendez-vous',
    NEW_ASSET: 'Nouveau bien',
    NEW_COUNSELLING_SESSION: 'Nouvelle séance de consultation',
    NEW_DEBT: 'Nouvelle dette',
    NEW_EMAIL: 'Nouveau courriel',
    NEW_INCOME_EXPENSE: 'Nouveaux revenus et dépenses',
    NEW_NOTE: 'Nouvelle note',
    NEW_RECEIPT: 'Nouveau reçu',
    NEW_SMS: 'Nouveau message texte',
    NEW_TASK: 'Nouvelle tâche',
    NEXT_AVAILABLE_AFTERNOON: 'Prochain après-midi disponible',
    NEXT_AVAILABLE_EVENING: 'Prochaine soirée disponible',
    NEXT_AVAILABLE_MORNING: 'Prochaine matinée disponible',
    NO_APPLICATION_FILE_ID: 'aucun identifiant de dossier pour la demande',
    NO_FILES_SELECTED: 'Aucun dossier sélectionné',
    NO_FORM: 'Pas de formulaire',
    NO_OF_PERSONS_IN_HOUSEHOLD: 'Nombre de personnes dans l’unité familiale',
    NO_OF_WARNINGS: 'Nombre d’avertissements',
    No_SEARCH_RESULTS_AVLABLE_PLS_CHK_N_TRY_AGAIN:
      'Aucun résultat de recherche n’est disponible. Veuillez vérifier et essayer de nouveau.',
    NO_TASKS_ARE_FOUND_WITH_THE_SPECIFIED_CRITERIA: 'Aucune tâche n’a été trouvée avec les critères fournis.',
    NO_TASKS_ARE_FOUND_WITH_THE_SPEFICIED_CRITERIA: 'Aucune tâche n’a été trouvée avec les critères fournis.',
    NON_DISCRETIONARY_EXPENSES: 'Dépenses non discrétionnaires',
    NON_DSCRTNRY_EXPNS: 'Dépenses non discrétionnaires',
    NOT_DEPOSITED: 'Non déposé',
    NOT_FOUND_PAGE: 'page non trouvée',
    NOT_URGENT: 'Pas urgent',
    NOTE_HISTORY: 'Historique des notes',
    NOTE_TEXT_REQUIRED_MESSAGE: 'Le texte de note est obligatoire.',
    NOTES: 'Notes',
    NOTICE_TO_USER: 'Avis à l’utilisateur',
    NOTPROVED: 'Non reconnu',
    NUMBER: 'Numéro',
    NUMBER_OF_FILES: 'Nombre de dossiers',
    NUMBER_OF_PAYMENTS_RQRD_BANKRUPT: 'Nombre de paiements mensuels exigés durant la faillite',
    NUMBER_PAYMENTS_BUNKRUPTCY: 'Nombre de paiements mensuels exigés durant la faillite',
    NUMBER_PERSONS_HOUSEHOLD: 'Nombre de personnes dans l’unité familiale',
    OCCUPATION: 'Profession',
    OFFICE: 'Bureau',
    OFFICE_LOCATION_ID: 'Identifiant de l’emplacement du bureau',
    OFFICE_REGION_ID: 'Identifiant de la région du bureau',
    ON: 'à',
    ON_ABOVE_FEES: 'sur les frais susmentionnés',
    ON_APPROVAL: 'À l’approbation',
    ON_APPROVAL_DEEMED_APPROVAL: 'À l’approbation/approbation réputée',
    ON_BALANCE: 'Sur le solde (maximum de 15 000 $)',
    ON_FILING: 'Au dépôt',
    ON_FILING_PROPOSAL: 'Au dépôt de la proposition',
    ON_FILLING_OF_PROPOSAL: 'Au dépôt de la proposition',
    ON_FIRST_975: 'Sur la première tranche de 975 $',
    ON_HOLD: 'En suspens',
    ON_NEXT_UP_TO_2000: 'Sur les encaissements suivants, jusqu’à 2 000 $',
    ONE_ID_REQUIRED_FOR_APPLICANT: 'Un identifiant pour le demandeur est obligatoire.',
    ONE_OR_MORE_SELECTED_FILES_HAS_PENDING_REVIEW_APPROVAL_SIGN_STATUS_CHECK_AND_TRYAGAIN:
      'Un ou plusieurs des fichiers sélectionnés font déjà l’objet d’une demande de révision, d’approbation ou de signature en suspens. Veuillez vérifier et essayer de nouveau.',
    ONLY_1_OF_THE_2_SUPPORTING_DOCUMENTS_LISTED_FOR_BANKING_INFORMATION_IS_REQUIRED:
      'L’un des deux documents justificatifs indiqués dans Renseignements bancaires est obligatoire.',
    ONLY_1_OF_THE_2_SUPPORTING_DOCUMENTS_LISTED_FOR_CHILD_SUPPORT_PAYMENT_IS_REQUIRED:
      'L’un des deux documents justificatifs indiqués dans Pension alimentaire pour enfants est obligatoire.',
    ONLY_1_OF_THE_2_SUPPORTING_DOCUMENTS_LISTED_FOR_INCOME_TAX_INFORMATION_IS_REQUIRED:
      'L’un des deux documents justificatifs indiqués dans Renseignements sur l’impôt sur le revenu est obligatoire.',
    ONLY_1_OF_THE_2_SUPPORTING_DOCUMENTS_LISTED_FOR_SPOUSAL_SUPPORT_PAYMENT_IS_REQUIRED:
      'L’un des deux documents justificatifs indiqués dans Pension alimentaire matrimoniale est obligatoire.',
    ONLY_1_OF_THE_3_SUPPORTING_DOCUMENTS_LISTED_FOR_SELFEMPLOYMENT_INCOME_IS_REQUIRED:
      'L’un des trois documents justificatifs indiqués dans Revenu d’un travail indépendant est obligatoire.',
    ONLY_1_OF_THE_4_SUPPORTING_DOCUMENTS_LISTED_FOR_PERSONAL_IDENTIFICATION_IS_REQUIRED:
      'L’un des quatre documents justificatifs indiqués dans Renseignements personnels est obligatoire.',
    OOPSIE_DOOPSIE: 'Oups...',
    OPEN_IN_NEW_TAB: 'Ouvrir dans un nouvel onglet',
    OPENED: 'Ouvert',
    OPPOSITION_COMMENTS: 'Commentaires relatifs à l’opposition',
    ORDINARY_CREDITORS: 'Créanciers ordinaires',
    OTHER: 'Autre',
    OTHER_CONTRIBUTIONS_BY_DEBTOR: 'Autres cotisations du débiteur',
    OTHER_COST_ORDINARY_BANKRUPTCY: 'Autres coûts d’une faillite ordinaire',
    OTHER_COSTS: 'Autres coûts',
    OTHER_COSTS_SUBJECT_TO: 'Autres coûts assujettis',
    OTHER_DETAILS: 'Autres détails',
    OTHER_HOUSEHOLD_MEMBERS: 'Autres membres de l’unité familiale',
    OTHER_HUNTING_FISHING_FARMING_TOOLS: 'Autres - Outils de chasse, de pêche et d’agriculture',
    OTHER_OWNERSHIP_PERCENTAGE: 'Autre pourcentage de participation',
    OTHER_SPECIFY: 'Autres - Préciser',
    OTHER_TOOLS_OF_TRADE: 'Autres – Outils de travail',
    OTHER_TOTAL_REALIZATIONS: 'Autres - Total des réalisations',
    OUTSTANDING_LOAN_LEASE: 'Prêt ou crédit-bail non remboursé',
    OVER_LAKH_DOLLARS: 'Supérieur à 100 000 $',
    OVERDRAFT_OWED: 'Découvert dû',
    OVERRIDE: 'Dérogation',
    OVERRIDE_IS_DELETED: 'La dérogation a été supprimée.',
    OWNERSHIP: 'Propriété',
    OWNERSHIP_HELD_IN_JOINT_TENANCY: 'Bien détenu en propriété conjointe',
    PAD_HISTORY: 'Historique des paiements par prélèvements automatiques',
    PAD_INFO_CHANGE_REQUEST_IS_CREATED: 'Demande de modification des paiements par prélèvements automatiques créée',
    PAD_INFO_CHANGE_REQUEST_IS_UPDATED:
      'Demande de modification des paiements par prélèvements automatiques mise à jour',
    PAD_INFORMATION: 'Renseignements sur les paiements par prélèvements automatiques',
    PARAMETERS: 'Paramètres',
    PAST_DUE_DOLLARS: 'En souffrance ($)',
    PAYMENT: 'Paiement',
    PAYMENT_PATTERN: 'Mode de paiement',
    PAYMENT_PATTERN_START_DATE: 'Date de début du mode de paiement',
    PAYMENT_STATUS: 'État du paiement',
    PDF_OR_JPEGS_UNDER_5MB: 'PDF ou JPEG de moins de 5 MB',
    PENDING_CONFIRMATION: 'Confirmation en suspens',
    PENDING_PROCESS: 'Processus en cours',
    PENSIONS: 'Régimes de retraite',
    PER: 'Par',
    PERCENT15_ABOVE_AMOUNT: '15 % du montant ci-dessus',
    PERCENT20_ABOVE_AMOUNT: '20 % du montant ci-dessus',
    PERSONAL_DETAILS: 'Renseignements personnels',
    PERSONAL_EFFECTS: 'Effets personnels',
    PERSONAL_EXEMPTION: 'Exemption personnelle',
    PERSONS_IN_HOUSEHOLD: 'Membres de l’unité familiale',
    PERSONS_IN_THE_HOUSEHOLD_UNIT_INCLUDING_APPLICANT: 'Membres de l’unité familiale (y compris le demandeur)',
    PHONE: 'Téléphone',
    PHONE_IS_USED_ERROR: 'Ce numéro de téléphone est déjà utilisé.',
    PHONE_NUMBER: 'Numéro de téléphone',
    PHONE_VIDEO: 'Téléphone/vidéo',
    PLACE_FILED: 'Lieu du dépôt',
    PLEASE_ENSURE_THE_ESTATE_NO_IS_UPDATED_FOR_THIS_FILE_IN_ASCEND:
      'Veuillez vous assurer que le numéro de succession est mis à jour pour ce dossier dans Ascend. Souhaitez-vous mettre à jour l’état à Signé?',
    PLEASE_SELECT: 'Veuillez faire un choix.',
    PLEASE_SELECT_AT_LEAST_1_REASON_FOR_FINANCIAL_DIFFICULTY:
      'Veuillez sélectionner au moins une raison justifiant les difficultés financières.',
    PLEASE_SELECT_TO_SAVE_THE_CHANGES: 'Veuillez faire une sélection pour enregistrer les modifications.',
    PLUS_30: 'Plus de 30',
    PLUS_60: 'Plus de 60',
    PLUS_90: 'Plus de 90',
    POLICIES_PENSIONS_ETC: 'Polices d’assurance, régimes de retraite, REER, REEE et REEI',
    POLICIES_PENSIONS_RRSPS: 'Polices d’assurance, régimes de retraite et REER',
    POLICY_NUMBER: 'Numéro de police',
    POSTAL_CODE: 'Code postal',
    POSTED_TO: 'Envoyé par la poste à',
    POTENTIALLY_STATUTEBARRED: 'Potentiellement prescrite',
    PPSA_SEARCH: 'Recherche au titre de la Loi sur les sûretés mobilières',
    PRE_SIGNUP: 'Préinscription',
    PREAUTHORIZATION_FORM_REQUIRED_FOR_ACCOUNT_IN_WHICH_YOU_WILL_BE_DRAWING_MONEY_FROM:
      'Le formulaire de préautorisation est obligatoire pour le compte duquel vous retirerez des fonds.',
    PREFERRED_CREDITORS: 'Créanciers privilégiés',
    PREFERRED_LANGUAGE: 'Langue de préférence',
    PREFIX_IS_REQUIRED: 'Le préfixe est obligatoire.',
    PREVIOUS_INSOLVENCIES: 'Situations d’insolvabilité antérieures',
    PRINCIPAL_RESIDENCE: 'Résidence principale',
    PRINT: 'Imprimer',
    PRINT_ALL_RECORDS: 'Imprimer tous les dossiers',
    PROPERTY: 'Propriété',
    PROPERTY_OR_SERVICES_OBTAINED_BY_FALSE_MEANSFRAUD:
      'Biens ou services obtenus par des moyens fallacieux ou frauduleux',
    PROPERTY_TAX_ARREARS: 'Arriérés d’impôts fonciers',
    PROPOSAL: 'Proposition',
    PROPOSAL_ACTIONS: 'Actions liées à la proposition',
    PROPOSAL_CHECKLIST: 'Liste de contrôle de la proposition',
    PROPOSAL_DATE: 'Date de la proposition',
    PROPOSAL_PROCESS_STATUS: 'État du processus de la proposition',
    PROPOSAL_REJECTED_PROCESS: 'Processus lié au rejet de la proposition',
    PROPOSAL_RESULT: 'Résultat de la proposition',
    PROPOSAL_STATE: 'Phase de la proposition',
    PROPOSAL_STATUS: 'État de la proposition',
    PROPOSAL_WITHDRAWN_PROCESS: 'Processus lié au retrait de la proposition',
    PROVIDE_ANY_ADDITIONAL_COMMENTS_HERE: 'Fournir des commentaires supplémentaires à divulguer par voie de notes.',
    PROVINCE: 'Province',
    PROVINCIAL_EXEMPTION_HOUSEHOLD_IF_ALLOWED: 'Exemption provinciale - Unité familiale (si autorisée)',
    PROVINCIAL_EXEMPTION_INIDIVIDUAL_IF_ALLOWED: 'Exemption provinciale - Particulier (si autorisée)',
    PROXY: 'Procuration',
    INCOME: 'Income fr',

    QUESTIONNAIRE: 'Questionnaire',
    RATE: 'Taux',
    RDSPS: 'REEI',
    REAL_ESTATE: 'Immobilier',
    REAL_PROPERTY_OR_IMMOVABLE: 'Bien immobilier ou bien immeuble',
    REALIZATION_FROM_ASSETS: 'Réalisation des biens',
    REALIZATION_VALUE: 'Valeur de réalisation',
    REASON_FOR_PREFERRED: 'Raison de la préférence',
    REASON_FOR_REJECTION: 'Raison du refus',
    REASONS_FOR_FINANCIAL_DIFFICULTY: 'Raisons des difficultés financières',
    RECEIPT_CREATED_SUCCESS_MESSAGE: 'Reçu créé',
    RECEIPT_NO: 'Numéro du reçu',
    RECEIPT_VOIDED_SUCCESS_MESSAGE: 'Le reçu est annulé.',
    RECEIPTS: 'Reçus',
    RECEIPTS_DISBURSEMENTS_NOTICE_LABEL_PROPOSAL:
      'Avis de taxation de l’administration des comptes et de la libération de l’administrateur',
    RECEIPTS_DISBURSEMENTS_NOTICE_LABEL_SUMMARY:
      'Avis de taxation présumée des comptes et de libération présumée du syndic',
    RECEIVED: 'Reçu',
    RECEIVED_ON: 'Reçu le',
    RECOGNIZANCE_OF_BAIL_BOND: 'Engagement ou cautionnement',
    RECOMMENDATION: 'Recommandations',
    RECOMMENDED_FIELD: 'Il est recommandé de remplir ce champ.',
    RECOVER_FILE: 'Récupérer un dossier',
    RECREATIONAL_EQUIPMENT: 'Véhicule récréatif',
    RECREATIONAL_EQUIPMENTS: 'Véhicules récréatifs',
    REDUCE_FILE_PREVIEW_SIZE: 'Réduire la taille de l’aperçu du fichier',
    REDUCTION_IN_INCOME: 'Reduction of household income fr',
    REFUND_AMOUNT: 'Montant du remboursement',
    REGION: 'Région',
    REJECT_DOCUMENT: 'Refuser le document',
    REJECT_REASON: 'Motif de refus',
    RELATED_DEBTORS: 'Débiteur(s) lié(s)',
    RELATIONSHIP: 'Relation',
    REPLACE_FILE: 'Remplacer le dossier',
    REPORT_GENERATED_BY: 'Rapport généré par',
    REQUEST_FOR_REVIEW: 'Demande de révision',
    REQUEST_REJECTED: 'Demande refusée',
    REQUESTED_BY: 'Demande effectuée par',
    REQUESTED_ON: 'Demande effectuée le',
    REQUESTSFORADDITIONALINFORMATION: 'Demandes de renseignements complémentaires',
    RESEND: 'Renvoyer',
    RESIDENCE_AMOUNT: 'Montant pour la résidence',
    RESIDENCY_APP: 'Demande pour la résidence',
    RESIDENCY_AVAIL: 'Disponibilité de la résidence',
    RESPS: 'REEE',
    RESULT: 'Résultat',
    REVIEW: 'Examen',
    REVIEW_CHEQUEPAD_REQUEST: 'Examiner la demande de paiement par chèque ou prélèvement automatique',
    REVIEW_PAD_REQUEST: 'Examiner la demande de paiement par prélèvement automatique',
    REVIEW_STATUS: 'État de la révision',
    REVIEWERS_S: 'Responsable(s) de la révision',
    REVISED: 'Révisé',
    REVIVED: 'Rétabli',
    RIGHTTOPRIORITY: 'Droits de priorité',
    RIGHTTOPRIORITYSECURED: 'Droit de priorité consigné',
    ROLE: 'Rôle',
    ROLE_S: 'Rôles',
    RRSP: 'REER',
    RRSPS: 'REER',
    SAME_AS_APPLICANTS_CONTACT_INFORMATION: 'Identique aux coordonnées du demandeur',
    SAVE_AND_LEAVE: 'Enregistrer et quitter',
    SAVE_CONFIRM: 'Enregistrer et confirmer',
    SAVE_OPTIMIZED_PDF: 'Enregistrer un PDF optimisé',
    SAVE_PDF: 'Enregistrer le PDF',
    SCHEDULE: 'Horaire',
    SCHEDULE_THE_COUNSELLING_SESSION_WITH_THE_CURRENT_AND_RELATED_DEBTOR:
      'Vous êtes sur le point de fixer un rendez-vous pour une séance de consultation avec le débiteur actuel et les débiteurs liés. Voulez-vous continuer?',
    SCHEDULING_APPOINTMENT: 'Prise de rendez-vous',
    SCHEDULING_NOTES: 'Remarques sur l’horaire',
    SEARCH_BY_COURTNUMBER_COURTNAME_COURTCITY_OR_TRUSTEENAME:
      'Effectuer une recherche au moyen du numéro du tribunal, du nom du tribunal, de la ville du tribunal ou du nom du syndic',
    SEARCH_BY_EMAIL_ID: 'Effectuer une recherche au moyen de l’identifiant de courriel',
    SEARCH_BY_EMAILID_FN_LN:
      'Effectuer une recherche au moyen de l’identifiant de courriel, du prénom ou du nom de famille',
    SEARCH_BY_OFFICE_LOCATION: 'Effectuer une recherche au moyen de l’emplacement',
    SEARCH_BY_TEMPLATE_NAME: 'Effectuer une recherche au moyen du nom du modèle',
    SEARCH_DEBTOR_PLACEHOLDER:
      'Effectuer une recherche du nom, de l’adresse électronique, du numéro de téléphone, du nom du dossier, du numéro de succession',
    SEARCH_NAME_EMAIL_PHN_FN_ESTATE_NO:
      'Effectuer une recherche du nom, de l’adresse électronique, du numéro de téléphone, du nom du dossier, du numéro de succession',
    SECURED: 'Consigné',
    SECURED_BY: 'Consigné par',
    SECURED_CREDITORS: 'Créanciers garantis',
    SECURED_TO: 'Consigné dans',
    SECUREDCLAIMSFORWARD: 'Transfert des créances garanties',
    SECUREDREVIEW: 'Examen consigné',
    SECURITIES: 'Garanties',
    SELECT_A_DEBTOR: 'Sélectionner un débiteur',
    SELECT_AGENT: 'Sélectionner un agent',
    SELECT_DEBTOR: 'Sélectionner un débiteur',
    SELECT_ESTATE_ADMINISTRATOR: 'Sélectionner un administrateur de succession',
    SELECT_FILING_TYPE: 'Sélectionner un type de dépôt',
    SELECT_FOLDER: 'Sélectionner le dossier',
    SELECT_INTERVIEWER: 'Sélectionner le responsable de l’entrevue',
    SELECT_MEETING_TYPE: 'Sélectionner le type de rendez-vous',
    SELECT_OFFICE: 'Sélectionner le bureau',
    SELECT_PREFIX: 'Sélectionner le préfixe',
    SELECT_REGION: 'Sélectionner une région',
    SELECT_SERVICE_LOCATION: 'Sélectionner un point de service',
    SELECT_SOME_FILES_TO_ATTACH: 'Sélectionner certains fichiers à joindre',
    SELECT_STATUS: 'Sélectionner un état',
    SELECT_THE_DATES_YOU_WANT_TO_OVERRIDE: 'Sélectionner la ou les dates que vous souhaitez modifier.',
    SELECT_TRUSTEE: 'Sélectionner un syndic',
    SEND_ALERT: 'Envoyer une alerte',
    SEND_AS_ATTACHMENT: 'Envoyer en pièce jointe',
    SENT_BY: 'Envoyé par',
    SERVICE_LOCATION: 'Point de service',
    SESSION: 'Séance',
    SESSION_IS_UPDATED: 'La séance a été mise à jour.',
    SESSIONS: 'Séances',
    SET_WEEKLY_HOURS: 'Fixer les heures hebdomadaires',
    SIGN_OUT: 'Fermer la session',
    SIGNED: 'Signé',
    SIGNEE_S: 'Signataire(s)',
    SIGNUP_DOCUMENT_CHECKLIST: 'Liste de contrôle du document d’inscription',
    SIGNUP_DOCUMENT_CHECKLISTS: 'Liste de contrôle du document d’inscription',
    SIGNUP_IN_PROGRESS: 'Inscription en cours',
    SIGNUP_INPROGRESS: 'Inscription en cours',
    SIGNUP_STATUS: 'État de l’inscription',
    SIN_IS_INCORRECT: 'Le NAS est incorrect.',
    SIN_IS_INVALID: 'Le NAS est invalide.',
    FILE_NAME_IS_INVALID: 'Les accents et les caractères & , / \\ : * ? ” < > | ne sont pas acceptés',
    SIZE: 'Taille',
    SMS_SENT_SUCCESS_MESSAGE: 'Message texte envoyé',
    SOURCE: 'Source',
    SPECIFY: 'Préciser',
    SPOUSE: 'Conjoint',
    SPOUSE_AMOUNT: 'Montant de l’époux',
    SPOUSE_OWNERSHIP_PERCENTAGE: 'Pourcentage de propriété de l’époux',
    SPOUSE_PARTNER: 'Époux ou conjoint',
    SPOUSE_REFUSED_TO_PROVIDE_INCOME_DATA: 'L’époux a refusé de fournir des données sur le revenu',
    SRD_ACTIONS: 'Actions liées à l’ERD',
    STAFF_USER: 'Utilisateur au sein du personnel',
    STAGE: 'Phase',
    STANDARD: 'Standard',
    STANDARD_MULTIPLE_FOR_NUNAVUT: 'Multiple standard pour le Nunavut',
    STANDARD_VOLUNTARY_PAYMENTS: 'Paiements volontaires standard (si aucun excédent ou réalisation de biens)',
    START_DATE: 'Date de début',
    START_TIME: 'Heure de début',
    STATE: 'État',
    STATION_ID: 'Identifiant de la station',
    STATUS: 'État',
    STATUS_CHANGE_CONFIRMATION: 'Confirmation de changement d’état',
    STATUTEBARRED: 'Prescription',
    STREET: 'Rue',
    STREET_NAME: 'Nom de la rue',
    STREET_NUMBER: 'Numéro de la rue',
    STUDENT_LOANS_OUTSTANDING: 'Prêts étudiants non remboursés',
    STYLE: 'Style',
    SUBTOTAL: 'Sous-total',
    SUPERINTENDENTS_LEVY: 'Prélèvement du surintendant',
    SUPPORTING_DOCUMENTS: 'Documents justificatifs',
    SUPPORTING_DOCUMENTS_NEEDED: 'Documents justificatifs nécessaires',
    SURPLUS_INCOME_CONTRIBUTIONS: 'Paiements au titre du revenu excédentaire',
    SYSTEM_FIELDS: 'Champs du système',
    TASK_ACTION_BTN_VIEW_IE: 'Consulter les revenus et les dépenses',
    TASK_CREATED_SUCCESS_MESSAGE: 'Tâche créée',
    TASK_HISTORY: 'Historique des tâches',
    TASK_UPDATED_SUCCESS_MESSAGE: 'Tâche mise à jour',
    TASKS_STATUS_IS_UPDATED: 'État de la tâche modifié',
    TAX: 'Impôt',
    TAX_REFUND: 'Remboursement d’impôt',
    TAX_REFUNDS: 'Remboursements d’impôt',
    TAX_RETURN_ACTIONS: 'Actions liées à la déclaration de revenus',
    TAXRETURN_STAGE_TAX_RETURN: 'Déclaration de revenus',
    TEAM: 'Équipe',
    TEAM_S: 'Équipes',
    TEAM_TYPE: 'Type d’équipe',
    TEMPLATE_NAME: 'Nom du modèle',
    TEST: 'Tests',
    TEXT: 'Texte',
    THE_SIZE_OF_ALL_ATTACHMENTS_SHOULDNOT_BE_GREATER_THAN_100MB:
      'La taille totale des fichiers joints ne doit pas excéder 100 MB.',
    THE_SIZE_OF_ATTACHMENTS_SHLD_NT_BE_GREATER_THAN: 'La taille totale des fichiers joints ne doit pas excéder ',
    THERE_IS_AN_ERROR_IN_YOUR_FORM: 'Il y a une erreur dans votre formulaire.',
    TIME: 'Heure',
    TO_DATE_SHOULD_BE_AFTER_FROM_DATE: 'La date de fin doit être ultérieure à la date de début',
    TO_MOBILE: 'Vers le mobile',
    TOOLS: 'Outils',
    TOOLS_OF_THE_TRADE: 'Outils de travail',
    TOOLS_OF_THE_TRADE_REALIZATION: 'Réalisation des outils de travail',
    TOTAL: 'Total',
    TOTAL_ALLOCATION_OF_AMOUNT_AVAILABLE_FOR_DISTRIBUTION:
      'Répartition du montant total disponible aux fins de distribution',
    TOTAL_ALLOCATIONS: 'Total des montants répartis',
    TOTAL_AMOUNT_7_DAYS_ERROR:
      'Le montant total des paiements collectés auprès du débiteur au cours des sept derniers jours ne peut être supérieur à 5 000 $, car le paiement est fait en espèces.',
    TOTAL_ASSETS: 'Total des biens',
    TOTAL_DEBTS: 'Total des dettes',
    TOTAL_DIVIDEND_AND_LEVY: 'Total des dividendes et des prélèvements',
    TOTAL_DOLLARS: 'Total ($)',
    TOTAL_ESTIMATED_SECURED_CLAIMS: 'Total des créances garanties estimatives',
    TOTAL_FILE_SIZE_SHLD_NT_EXCEED: 'La taille totale des fichiers ne peut pas excéder.',
    TOTAL_FILES_SIZE_SHOULD_NOT_EXCEED_100_MB: 'La taille totale des fichiers ne peut pas excéder 100 MB.',
    TOTAL_MNTLY_NET_INC_IN_SURPLUS_CALCULATION:
      'Total du revenu net mensuel utilisé dans le calcul du revenu excédentaire',
    TOTAL_MONHLY_SURPLUS: 'Total de l’excédent mensuel',
    TOTAL_MONTHLY_PAYMENTS: 'Total des paiements mensuels',
    TOTAL_MONTHLY_SURPLUS: 'Total de l’excédent mensuel',
    TOTAL_NET_INCOME_IN_SURPLUS: 'Total du revenu net mensuel utilisé dans le calcul du revenu excédentaire',
    TOTAL_NUMBER_OF_OWNERS: 'Total du nombre de propriétaires',
    TOTAL_POLICIES_PENSIONS_RRSPS: 'Total des politiques, régimes de retraite et REER',
    TOTAL_PROPOSAL_PAYMENTS: 'Total des paiements pour la proposition',
    TOTAL_SECURED: 'Total des créances garanties',
    TOTAL_SURPLUS_INC_CONT: 'Total des paiements au titre du revenu excédentaire',
    TOTAL_SURPLUS_INCOME_CONTRIBUTION: 'Total des paiements au titre du revenu excédentaire',
    TOTAL_UNSECURED: 'Total des créances ordinaires',
    TRANSACTIONS_APPLICANT: 'Transactions - Demandeur',
    TRANSACTIONS_SPOUSE_PARTNER: 'Transactions - Époux ou conjoint',
    TRANSFERRED: 'Transféré',
    TRANSIT_NUMBER: 'Numéro de transit',
    TRIM: 'Réduire',
    TRUSTEE: 'Syndic',
    TRUSTEE_ACTIONS: 'Actions du syndic',
    TRUSTEE_TO_FOLLOW_ONLINE_STEPS_FOR_ENGAGEMENT_ACCEPTANCE__RISK_ASSESSMENT:
      'Le syndic doit suivre les étapes en ligne pour l’acceptation de la mission et l’évaluation des risques.',
    TRUSTEE_TO_FOLLOW_RISK_MANAGEMENT_SECTION_IN_MY_BDO_AND_CONFLICT_CHECK:
      'Le syndic doit suivre les directives de la section sur la gestion des risques et la vérification de conflits d’intérêts dans mon BDO.',
    TRUSTEE_TO_PREPARE_AND_INSERT_NOTE:
      'Le syndic doit préparer et indiquer ici les présentations par voie de notes à propos des faits qui pourraient avoir une incidence sur le recouvrement pour les créanciers.',
    TRUSTEES_FEES: 'Honoraires du syndic',
    TRUSTEES_FEES_BEFORE_TAX: 'Honoraires du syndic (avant)',
    TRUSTEES_NAME: 'Nom du syndic',
    TYPE: 'Type',
    TYPE_OF_BUSINESS: 'Type d’entreprise',
    TYPE_OF_OWNERSHIP: 'Type de propriété',
    UNABLE_TO_PROCEED_AS_THERE_ARE_ONE_OR_MORE_SECTIONS_IN_THE_APPLICATION_FORM_THAT_ARE_STILL_PENDING:
      'Impossible de continuer, car une ou plusieurs sections du formulaire de demande ne sont pas encore remplies. Veuillez vérifier et essayer de nouveau.',
    UNABLE_TO_PROCESS_YOUR_REQUEST_AS_NO_VALUE_IS_SELECTED_FOR_PROPOSAL_STATE:
      'Impossible de traiter votre demande, car aucune valeur n’est sélectionnée dans le champ État de la proposition. Veuillez vérifier et essayer de nouveau.',
    UNABLE_TO_PROCESS_YOUR_REQUEST_AS_THERE_IS_ONE_OR_MORE_MEETING_WITH_CREDITOR_APPOINTMENTS_THAT_ARE_NOT_COMPLETE:
      'Impossible de traiter votre demande, car un ou plusieurs rendez-vous de type Réunion avec un créancier ne sont pas terminés. Veuillez vérifier et essayer de nouveau.',
    UNAVAILABLE: 'Indisponible',
    UNDER_WHAT_NAME_DID_YOU_FILE: 'Sous quel nom avez-vous effectué le dépôt?',
    UNIQUE_CREDITOR: 'Créditeur unique',
    UNIT: 'Unité',
    UNKNOWN: 'Inconnu',
    UNLOCK_APP_FORM_MESSAGE:
      'Les utilisateurs, soit les clients et les membres du personnel, pourront modifier le formulaire de demande après le verrouillage.',
    UNLOCK_APP_FORM_TITLE: 'Voulez-vous déverrouiller le formulaire de demande?',
    UNSECURED: 'Non consigné',
    UNSECURED_AMOUNT: 'Montant non consigné',
    UNSECURED_CREDITORS: 'Créanciers ordinaires',
    UNSECURED_PORTION: 'Partie non consignée',
    UNSECURED_PORTION_OF_SECURED_CLAIMS: 'Partie non consignée des créances garanties',
    UNSECUREDREVIEW: 'Non consigné - Examen',
    UPDATE_INFORMATION: 'Mettre à jour les informations',
    UPDATED_BY: 'Mise à jour effectuée par',
    UPDATED_ON: 'Mise à jour effectuée le',
    UPLOAD_FAILED: 'Échec du téléversement',
    UPLOAD_FAILED_APPFORM_OVER_ONE: "Upload failed. Can't upload more than one document. fr",
    UPLOAD_FAILED_APPFORM_OVER_TWO: "Upload failed. Can't upload more than two documents. fr",
    UPLOAD_FAILED_APPFORM_ONE_DOCUMENT_FOR_SPOUSE_REQUIRED:
      'Upload failed. One of the documents needs to be for the spouse of the client. fr',
    UPLOAD_FAILED_APPFORM_ONE_DOCUMENT_FOR_CLIENT_REQUIRED:
      'Upload failed. One of the documents needs to be for the client. fr',
    UPLOAD_FILE: 'Téléverser un fichier',
    UPLOAD_FILE_TO_REPLACE_MESSAGE: 'Téléverser un fichier de remplacement',
    UPLOAD_SHARE: 'Télécharger et partager',
    UPON_DISTRIBUTION: 'À la distribution',
    URGENT: 'Urgent',
    USER: 'Utilisateur',
    USER_ACCOUNT_AGENT_FILES_ERROR:
      'Plusieurs dossiers font l’objet d’une attribution à Agent dans ce compte. Veuillez désigner un autre agent pour ces dossiers.',
    USER_INFO: 'Renseignements de l’utilisateur',
    USER_MANAGEMENT: 'Gestion de l’utilisateur',
    USERNAME: 'Nom d’utilisateur',
    USERS: 'Utilisateurs',
    VALIDATION_ERRORS_AND_WARNINGS: 'Erreurs et avertissements valides',
    VALUE: 'Valeur',
    VALUE_AFTER_DEBTS: 'Valeur après les dettes',
    VALUE_AFTER_GRANTS: 'Valeur après les subventions',
    VEHICLE: 'Véhicule',
    VEHICLE_NEEDED_FOR_EMPLOYMENT: 'Véhicule nécessaire à l’emploi',
    VERSION_HISTORY: 'Historique des versions',
    VIEW: 'Afficher',
    VIEW_ASSET: 'Afficher le bien',
    VIEW_CHEQUEPAD_REQUEST: 'Afficher la demande de paiement par chèque ou prélèvement automatique',
    VIEW_CLAIM_DISCREPANCY_CONFIRMATION: 'La confirmation de l’écart est obligatoire.',
    VIEW_CLAIM_DISCREPANCY_CONFIRMATION_MESSAGE:
      'La différence entre le montant de la demande et le bilan ne peut pas être supérieure à 25 %. Veuillez sélectionner Oui pour terminer la réclamation ou sélectionner Non.',
    VIEW_HISTORY: 'Afficher l’historique',
    VIEW_PAD_REQUEST: 'Afficher la demande de paiement par prélèvement automatique',
    VIEW_REPORT: 'Afficher le rapport',
    VIN: 'NIV',
    VISIBLE: 'Visible',
    VOID: 'Annulé',
    WEEKLY_AVAILABILITY_IS_UPDATED: 'La disponibilité hebdomadaire a été mise à jour.',
    WHAT_ARE_THE_REASONS_FOR_YOUR_FINANCIAL_DIFFICULTY:
      'Quelles sont les raisons de vos difficultés financières? (Veuillez cocher toutes les réponses pertinentes)',
    WILL_THE_SECURED_CREDITOR_PARTICIPATE_IN_DIVIDEND_DISTRIBUTIONS:
      'Si oui, le créancier garanti participe-t-il à la distribution des dividendes?',
    WITH: 'avec',
    WITHHOLDING_TAX: 'Retenue d’impôt',
    WITHHOLDING_TAX_RATE: 'Taux de la retenue d’impôt',
    YEAR: 'Année',
    YEAR_LAST_RETURN_FILED: 'Année d’imposition de la dernière déclaration de revenus',
    YOU_ARE_ABOUT_TO_UPDATE_THE_PROPOSAL_AS_ACCEPTED:
      'Vous êtes sur le point de mettre à jour l’état de la proposition à Accepté. Voulez-vous continuer?',
    YOU_STILL_HAVE_ONE_OR_MORE_PENDING_CHECKLIST_ITEMS:
      'Un ou plusieurs éléments de la liste de contrôle sont en suspens. Veuillez vérifier et essayer de nouveau.',
    ZOOM: 'Zoom',

    //Text from Feb 24 Translation file
    CLIENT_OVERVIEW_PAGE_OVERVIEW: 'Aperçu',
    CLIENT_OVERVIEW_PAGE_CLIENT_INFO: 'Renseignements sur le client',
    CLIENT_OVERVIEW_PAGE_DOCUMENTS: 'Documents',
    CLIENT_OVERVIEW_PAGE_APPLICATION_FORM: 'Formulaire de demande',
    LOGO_SUBTEXT: 'Portail des membres de l’équipe Solutions à l’endettement personnel',
    DASHBOARD: 'Tableau de bord',
    NEW_PROSPECT: 'Nouveau client potentiel',
    SAVE: 'Enregistrer',
    CANCEL: 'Annuler',
    CANCEL_ANYWAY: 'Annuler quand même',
    CONTINUE_EDITING: 'Continuer l’édition',
    CONTINUE: 'Continuer',
    COMPLETE: 'Terminé',
    NEXT: 'Suivant',
    SEND: 'Envoyer',
    CLOSE: 'Fermer',
    DELETE: 'Supprimer',
    CONFIRM: 'Confirmer',
    UPLOAD: 'Téléverser',
    UPLOADED: 'Téléversé',
    ACCEPT: 'Accepter',
    REJECT: 'Refuser',
    BACK: 'Retour',
    STAY: 'Rester',
    LEAVE: 'Sortir',
    MARK_AS_REVIEWED: 'Indiquer comme révisé',
    SEARCH: 'Recherche',
    ADD_TASK: 'Ajouter une tâche',
    CLEAR_ALL: 'Tout effacer',
    APPLY: 'Appliquer',
    FILTERS: 'Filtres',
    BUNDLE: 'Grouper',
    DOWNLOAD: 'Télécharger',
    PREVIOUS: 'Précédent',
    COPY: 'Copier',
    EDIT: 'Modifier',
    COMMENT: 'Commentaire',
    REPLACE: 'Remplacer',
    DOCUMENT: 'Document',
    FILES: 'Dossiers',
    OVERVIEW: 'Aperçu',
    ALL: 'Tout',
    LOADING: 'Chargement en cours',
    LOADING_FILE_UPLOADS: 'Chargement des fichiers téléversés...',
    DASHBOARD_SEARCH_PLACEHOLDER: 'Filtrer par numéro de succession, nom de fichier, phase ou état de la tâche',
    DASHBOARD_TABLE_FILE_NAME: 'Nom du fichier',
    DASHBOARD_TABLE_ESTATE: 'No de la succession',
    DASHBOARD_TABLE_OPEN_DAYS: 'Jours',
    DASHBOARD_TABLE_ASSIGNED: 'Attribué',
    DASHBOARD_TABLE_STAGE: 'Phase',
    DASHBOARD_TABLE_FOLLOW_UP: 'Suivi',
    DASHBOARD_TABLE_TASK_NAME: 'Tâche',
    DASHBOARD_TABLE_TASK_STATUS: 'État de la tâche',
    NEW_PROSPECT_PERSONAL_DETAILS: 'Renseignements personnels',
    NEW_PROSPECT_FIRST_NAME: 'Prénom',
    NEW_PROSPECT_MIDDLE_NAME: 'Second prénom',
    NEW_PROSPECT_LAST_NAME: 'Nom',
    NEW_PROSPECT_MAIN_PHONE: 'Numéro de téléphone principal',
    NEW_PROSPECT_EMAIL_ADDRESS: 'Adresse électronique',
    NEW_PROSPECT_CANCEL: 'Annuler',
    NEW_PROSPECT_SAVE_AND_CLOSE: 'Enregistrer et fermer',
    NEW_PROSPECT_SAVE_AND_CONTINUE: 'Enregistrer et continuer',
    NEW_PROSPECT_FOLLOW_UP: 'Suivi',
    NEW_PROSPECT_FOLLOW_UP_DATE: 'Date de suivi',
    NEW_PROSPECT_SIMILAR_RECORDS: 'Des dossiers similaires existent',
    NEW_PROSPECT_SAVE_AS_NEW: 'Enregistrer comme nouveau client',
    SIMILAR_RECORDS_ADDRESS: 'Adresse',
    SIMILAR_RECORDS_MAIN_PHONE_NUMBER: 'No de téléphone principal',
    NEW_PROSPECT_SIMILAR_RECORDS_ERROR: 'Erreur',
    NEW_PROSPECT_SIMILAR_RECORDS_MESSAGE:
      'Impossible de continuer, car au moins un dossier contient les mêmes renseignements. Veuillez mettre à jour le prénom, le nom, le numéro de téléphone principal ou l’adresse électronique pour continuer.',
    CONFIRMATION: 'Confirmation',
    NEW_PROSPECT_EMPTY_EMAIL_MESSAGE: 'Aucune adresse électronique n’a été saisie. Voulez-vous continuer?',
    YES: 'Oui',
    NO: 'Non',
    NEW_PROSPECT_EMPTY_REQUIRED_FIELDS: 'Les champs obligatoires sont vides :',
    NEW_PROSPECT_CANCEL_MESSAGE: 'Les changements apportés pourraient ne pas être enregistrés.',
    INPUT_YOUR: 'Veuillez saisir votre ',
    IS_REQUIRED: 'est un champ requis',
    IS_INVALID: 'est une réponse invalide',
    ESTATE_ADMINISTRATOR: 'Administrateur de la succession',
    DEBTOR_PROFILE: 'Profil du débiteur',
    DEBTOR_PROFILE_INFO_CREATED_ON: 'Créé le',
    DEBTOR_PROFILE_INFO_AGENT: 'A',
    DEBTOR_PROFILE_ESTATE_ADMINISTRATOR: 'AS',
    DEBTOR_PROFILE_TRUSTEE: 'S',
    DEBTOR_PROFILE_STAGE: 'Phase',
    DEBTOR_PROFILE_BASIC_INFO: 'Renseignements de base',
    DEBTOR_PROFILE_CONTACT_INFO: 'Coordonnées',
    DEBTOR_PROFILE_SOURCE: 'Source',
    DEBTOR_PROFILE_NOTES: 'Remarques',
    DEBTOR_PROFILE_SAVE_AND_CLOSE: 'Enregistrer et fermer',
    DEBTOR_PROFILE_SAVE_AND_BOOK: 'Enregistrer et réserver',
    DEBTOR_PROFILE_FIRST_NAME: 'Prénom',
    DEBTOR_PROFILE_MIDDLE_NAME: 'Second prénom',
    DEBTOR_PROFILE_LAST_NAME: 'Nom',
    DEBTOR_PROFILE_DATE_OF_BIRTH: 'Date de naissance',
    DEBTOR_PROFILE_ENABLE_CLIENT_LOGIN: 'Activer l’identifiant de connexion du client',
    DEBTOR_PROFILE_HEAR_ABOUT_US: 'Comment avez-vous entendu parler de BDO?',
    DEBTOR_PROFILE_INTAKE_SOURCE: 'Renseignements sur la source',
    DEBTOR_PROFILE_SOURCES: 'Sources',
    DEBTOR_PROFILE_RESET_PASSWORD_MESSAGE:
      'Do you want to send the client portal reset password email to the debtor? fr',
    DEBTOR_PROFILE_REGISTRATION_MESSAGE: 'Do you want to send the client portal registration email to the debtor? fr',
    DEBTOR_PROFILE_EMAIL_FAILURE: 'Error: Unable to send the email specified. Please try again later. fr',
    DEBTOR_PROFILE_EMAIL_SUCCESS: 'The email was sent successfully to the debtor. fr',
    DEBTOR_PROFILE_UNIT: 'No d’appartement',
    DEBTOR_PROFILE_CITY: 'Ville',
    DEBTOR_PROFILE_PROVINCE: 'Province/État',
    DEBTOR_PROFILE_POSTAL_CODE: 'Code postal',
    DEBTOR_PROFILE_COUNTRY: 'Pays',
    DEBTOR_PROFILE_PRIMARY_PHONE_NUMBER: 'No de téléphone principal',
    DEBTOR_PROFILE_EMAIL_ADDRESS: 'Adresse électronique',
    DEBTOR_PROFILE_ALTERNATE_PHONE_NUMBER: 'Autre no de téléphone ',
    DEBTOR_PROFILE_PROVINCE_MISSING_TEXT: 'Veuillez indiquer la province pour continuer.',
    DEBTOR_PROFILE_CREATE_NEW_APPLICATION: 'Créer une nouvelle demande',
    DEBTOR_PROFILE_ENTER_EMAIL:
      'Veuillez saisir une adresse électronique, puisque l’identifiant de connexion du client est activé.',
    DEBTOR_PROFILE_ADDRESS: 'Adresse (numéro et rue)',
    DEBTOR_PROFILE_PREFERRED_LANGUAGE: 'Préférence linguistique',
    DEBTOR_PROFILE_NAME_SURNAME_PHONE_DUPLICATE:
      'Ces prénom, nom et numéro de téléphone principal sont déjà associés à un client potentiel ou à un débiteur. Veuillez vérifier et essayer de nouveau.',
    DEBTOR_PROFILE_NAME_SURNAME_EMAIL_DUPLICATE:
      'Ces prénom, nom et adresse électronique sont déjà associés à un client potentiel ou à un débiteur. Veuillez vérifier et essayer de nouveau.',
    DEBTOR_PROFILE_EMAIL_DUPLICATE:
      'Cette adresse électronique est déjà associée à un client potentiel ou à un débiteur. Veuillez vérifier et essayer de nouveau.',
    ADDRESS: 'Adresse',
    NETWORK_ERROR: 'Erreur liée au réseau',
    NO_DATA_FOUND: 'Aucune donnée trouvée',
    RETRY: 'Réessayer',
    SEND_RESET_PASSWORD: 'Send Reset Password Email fr',
    SEND_REGISTRATION_PASSWORD: 'Send Registration Email fr',
    PORTAL_NOTIFICATION: 'Avis du portail',
    CLIENT_REGISTERED: 'Client Registered fr',
    ADD_SOURCE: 'Ajouter une source',
    ADD_NUMBER: 'Ajouter un numéro',
    DNE: 'Ne pas communiquer par courriel',
    DNC: 'Ne pas communiquer par appel',
    SMS: 'Message texte',
    APPOINTMENT_SCHEDULING: 'Rendez-vous',
    APPOINTMENT_SCHEDULING_APPOINTMENT_TYPE: 'Type de rendez-vous',
    APPOINTMENT_SCHEDULING_LOCATION: 'Emplacement',
    APPOINTMENT_SCHEDULING_AVAILABLE_DATES: 'Dates disponibles',
    APPOINTMENT_SCHEDULING_DATE: 'Date',
    APPOINTMENT_SCHEDULING_MEETING_TYPE: 'Type de réunion',
    APPOINTMENT_SCHEDULING_AVAILABLE_APPOINTMENTS: 'Rendez-vous disponibles',
    APPOINTMENT_SCHEDULING_SCHEDULE_APPOINTMENT: 'Prendre un rendez-vous',
    APPOINTMENT_SCHEDULING_VIEW_APPOINTMENT: 'Aperçu du rendez-vous',
    APPOINTMENT_SCHEDULING_AGENTS: 'Agents',
    APPOINTMENT_SCHEDULING_SUMMARY: 'Sommaire',
    APPOINTMENT_SCHEDULING_SUMMARY_TYPE: 'Type',
    APPOINTMENT_SCHEDULING_SUMMARY_TIME: 'Heure',
    APPOINTMENT_SCHEDULING_SUMMARY_DATE: 'Date',
    APPOINTMENT_SCHEDULING_SCHEDULE: 'Horaire',
    APPOINTMENT_SCHEDULING_SCHEDULING_NOTE: 'Remarques sur l’horaire',
    APPOINTMENT_SCHEDULING_STATUS: 'État',
    APPOINTMENT_ADD: 'Ajouter un R-V',
    APPOINTMENT_MEETING_TYPE_IN_PERSON: 'En personne',
    APPOINTMENT_MEETING_TYPE_PHONE_VIDEO: 'Téléphone/vidéo',
    APPOINTMENT_MEETING_TYPE_PHONE: 'Téléphone',
    APPOINTMENT_CREATED_SUCCESS: 'Le rendez-vous a été créé.',
    APPOINTMENT_UPDATED_SUCCESS: 'Le rendez-vous a été mis à jour.',
    APPOINTMENTS_HISTORY: 'Historique des rendez-vous',
    HEADER_SEARCH_PLACEHOLDER: 'Rechercher la succession, le nom, l’état',
    DEBTOR_SEARCH: 'Recherche d’un débiteur',
    DEBTOR_SEARCH_ADVANCED_SEARCH: 'Recherche avancée',
    DEBTOR_SEARCH_HIDE_ADVANCED_SEARCH: 'Masquer la recherche avancée',
    DEBTOR_SEARCH_FILING_TYPE: 'Type de dépôt',
    DEBTOR_SEARCH_SERVICE_LOCATION: 'Point de service',
    DEBTOR_SEARCH_TRUSTEE: 'Syndic',
    DEBTOR_SEARCH_ADMIN: 'Administrateur',
    DEBTOR_SEARCH_STATUS: 'État',
    DEBTOR_SEARCH_STAGE: 'Phase',
    DEBTOR_SEARCH_EMAIL: 'Courriel',
    DEBTOR_SEARCH_ESTATE: 'Succession',
    APPLICATION_OVERVIEW: 'Aperçu de la demande',
    APPLICATION_OVERVIEW_FILESTAGE: 'Phase',
    APPLICATION_OVERVIEW_FILENAME: 'NF',
    APPLICATION_OVERVIEW_FILENAME_TOOLTIP: 'Nom du fichier',
    APPLICATION_OVERVIEW_ESTATE: 'S',
    APPLICATION_OVERVIEW_ESTATE_TOOLTIP: 'No de la succession',
    APPLICATION_OVERVIEW_SERVICELOCATION: 'PS',
    APPLICATION_OVERVIEW_SERVICELOCATION_TOOLTIP: 'Point de service',
    APPLICATION_OVERVIEW_AGENT: 'A',
    APPLICATION_OVERVIEW_AGENT_TOOLTIP: 'Agent',
    APPLICATION_OVERVIEW_ESTATEADMIN: 'AS',
    APPLICATION_OVERVIEW_ESTATEADMIN_TOOLTIP: 'Administrateur de la succession',
    APPLICATION_OVERVIEW_TRUSTEE: 'S',
    APPLICATION_OVERVIEW_TRUSTEE_TOOLTIP: 'Syndic',
    APPLICATION_OVERVIEW_OVERVIEW_TAB: 'Aperçu',
    APPLICATION_OVERVIEW_CLIENT_INFO_TAB: 'Renseignements sur le client',
    APPLICATION_OVERVIEW_ADMIN_INFO_TAB: 'Renseignements sur l’administrateur',
    APPLICATION_OVERVIEW_ANALYSIS_TAB: 'Analyse',
    APPLICATION_OVERVIEW_DOCUMENTS_TAB: 'Documents',
    APPLICATION_OVERVIEW_TASKS: 'Tâches',
    APPLICATION_OVERVIEW_NOTES: 'Remarques',
    APPLICATION_OVERVIEW_APPOINTMENTS: 'Rendez-vous',
    APPLICATION_FORM_STATUS_COMPLETE: 'Terminé',
    APPLICATION_FORM_STATUS_NOT_STARTED: 'Non commencé',
    APPLICATION_CHECKLIST_COMPLETE: 'Complet',
    APPLICATION_CHECKLIST_INCOMPLETE: 'Incomplet',
    APPLICATION_FILE_STAGE_IN_PROGRESS: 'En cours',
    APPLICATION_FILE_STAGE_COMPLETE: 'Terminé',
    APPLICATION_FILE_STAGE_NOT_STARTED: 'Non commencé',
    RECEIPT: 'Encaissement',
    RECEIPT_HISTORY: 'Historique des encaissements',
    RECEIPT_NEW: 'Nouvel encaissement',
    RECEIPT_VOID: 'Annuler',
    RECEIPT_SAVE: 'Produire',
    RECEIPT_CANCEL: 'Annuler',
    EMAIL_HISTORY: 'Historique des courriels',
    EMAIL_NEW: 'Nouveau courriel',
    EMAIL_VIEW: 'Voir le courriel',
    EMAIL_SENT: 'Courriel envoyé',
    EMAIL: 'Courriel',
    EMAIL_ATTACHMENTS: 'Pièces jointes',
    EMAIL_TO: 'Destinataire',
    EMAIL_FROM: 'Expéditeur',
    EMAIL_SUBJECT: 'Objet',
    EMAIL_BODY: 'Corps',
    EMAIL_TEMPLATE: 'Modèle de courriel',
    EMAIL_SENT_SUCCESS: 'Courriel envoyé avec succès',
    SUBJECT: 'Objet',
    TEMPLATE: 'Modèle',
    MESSAGE: 'Message',
    SMS_HISTORY: 'Historique des messages textes',
    SMS_NEW: 'Nouveau message texte',
    SMS_VIEW: 'Voir le message texte',
    SMS_SENT: 'Message texte envoyé',
    SMS_TO_MOBILE: 'Numéro de cellulaire',
    CREDIT_REPORT_HISTORY: 'Historique du dossier de crédit',
    REQUEST_CREDIT_REPORT: 'Demander un dossier de crédit',
    CREDIT_REPORT: 'Dossier de crédit',
    NOTE_CREATE_SUCCESS: 'La note a été ajoutée.',
    NOTE_UPDATE_SUCCESS: 'La note a été mise à jour.',
    NOTE_DELETE_SUCCESS: 'La note a été supprimée.',
    NOTES_HISTORY: 'Historique des notes',
    NOTE_ADD: 'Ajouter une note',
    NOTE_DELETE_CONFIRMATION: 'Souhaitez-vous supprimer la note?',
    REQUIRED_FIELD: 'Ce champ doit être rempli.',
    TASK_PROCESS: 'Processus',
    TASK_STAGE: 'Phase',
    TASK_NAME: 'Nom de la tâche',
    TASK_DESCRIPTION: 'Description',
    TASK_ASSIGN: 'Attribuer',
    TASK_USERS: 'Utilisateurs',
    TASK_TEAM: 'Équipe',
    TASK_NEW: 'Ajouter une tâche',
    TASK_STATUS: 'État',
    TASK_OPENED: 'Ouverte',
    TASK_CLOSED: 'Fermée',
    TASK: 'Tâche',
    TASKS_HISTORY: 'Historique des tâches',
    ACTION_NEW: 'Ajouter une action',
    ATTACHMENT_NEW: 'Nouvelle',
    ATTACHMENT_EXISTING: 'Existante',
    ATTACHMENT: 'Pièce jointe',
    ATTACHMENT_SEARCH: 'Rechercher un fichier',
    RECEIPT_PAYMENT_DATE: 'Date du paiement',
    RECEIPT_PER: 'Par',
    RECEIPT_LOCATION: 'Emplacement',
    RECEIPT_PAYMENT_AMOUNT: 'Montant du paiement',
    RECEIPT_PAYMENT_MADE_BY: 'Paiement effectué par',
    RECEIPT_PAYMENT_METHOD: 'Méthode de paiement',
    RECEIPT_PAYMENT_METHOD_DETAIL: 'Détails du paiement',
    RECEIPT_POST_TO: 'Envoyer à',
    RECEIPT_OFFICE_LOCATION: 'Emplacement',
    RECEIPT_PAYMENTMETHOD_CASH: 'Argent comptant',
    RECEIPT_PAYMENTMETHOD_CHEQUE: 'Chèque',
    RECEIPT_PAYMENTMETHOD_CHEQUE_NUMBER: 'No du chèque',
    RECEIPT_PAYMENTMETHOD_OTHER: 'Autre',
    RECEIPT_POSTTYPE_PREFILING: 'Compte préalimenté',
    RECEIPT_POSTTYPE_PREFILL: 'Préremplir',
    RECEIPT_POSTTYPE_ESTATE: 'Succession',
    RECEIPT_POSTTYPE_HOLDING: 'Société de portefeuille',
    RECEIPT_ERROR_INVALID_PAYMENT_METHOD: 'Veuillez sélectionner une méthode de paiement.',
    RECEIPT_ERROR_INCORRECT_DENOMINATION:
      'La valeur totale des coupures ne correspond pas au montant du paiement. Veuillez vérifier et essayer de nouveau.',
    RECEIPT_ERROR_INVALID_CHEQUE_NUMBER: 'Veuillez saisir un numéro de chèque valide.',
    RECEIPT_ERROR_CASH_AMOUN_EXCEEDED:
      'Le montant du paiement ne doit pas être supérieur à 2 500,00 $, puisque le paiement est fait en argent. Veuillez vérifier et essayer de nouveau.',
    RECEIPT_VOID_CONFIRM_TITLE: 'Confirmer l’annulation de l’encaissement',
    RECEIPT_VOID_CONFIRM_MESSAGE: 'Voulez-vous vraiment annuler cet encaissement?',
    RECEIPT_VOID_CONFIRM_POSITIVE: 'Oui',
    RECEIPT_VOID_CONFIRM_NEGATIVE: 'Non',
    ASSETS_CURRENT_MARKET_VALUE: 'Valeur marchande actuelle',
    ASSETS_ESTIMATED_RESALE_VALUE: 'Valeur de revente estimée',
    EXPENSE_DELETE_CONFIRMATION_TITLE: 'Supprimer la dépense?',
    EXPENSE_DELETE_CONFIRMATION_MESSAGE: 'Voulez-vous vraiment supprimer cette dépense?',
    INCOME_DELETE_CONFIRMATION_TITLE: 'Supprimer le revenu?',
    INCOME_DELETE_CONFIRMATION_MESSAGE: 'Voulez-vous vraiment supprimer ce revenu?',
    DEBT_DELETE_CONFIRMATION_TITLE: 'Supprimer la dette?',
    DEBT_DELETE_CONFIRMATION_MESSAGE: 'Voulez-vous vraiment supprimer cette dette?',
    ASSET_DELETE_CONFIRMATION_TITLE: 'Supprimer le bien?',
    ASSET_DELETE_CONFIRMATION_MESSAGE: 'Voulez-vous vraiment supprimer ce bien?',
    UPLOAD_FILE_SIZE_ERROR: 'La taille du fichier joint ne doit pas excéder 5 Mo.',
    UPLOAD_FILES_SIZE_ERROR: 'La taille totale des pièces jointes ne doit pas excéder 15 Mo.',
    UNSAVED_CHANGED_TITLE: 'Modifications non enregistrées',
    UNSAVED_CHANGED_MESSAGE:
      'Les modifications apportées au formulaire qui ne sont pas enregistrées seront perdues. Souhaitez-vous quitter quand même?',
    DOCUMENTS: 'Documents',
    DOCUMENTS_ALL: 'Tous les documents',
    DOCUMENTS_REVIEW_APPROVAL: 'Révision et approbation',
    DOCUMENTS_OVERVIEW: 'Aperçu',
    DOCUMENTS_NAME: 'Nom',
    DOCUMENTS_FOLDER: 'Dossier',
    DOCUMENTS_PREFIX: 'Préfixe',
    DOCUMENTS_UPLOADED: 'Téléversé',
    DOCUMENTS_MODIFIED: 'Modifié',
    DOCUMENTS_SIZE: 'Taille',
    DOCUMENTS_FOR_SPOUSE: 'For spouse fr',
    DOCUMENTS_FILE_SIZE: 'Taille du fichier',
    DOCUMENTS_TYPE: 'Type',
    DOCUMENTS_STATUS: 'État',
    DOCUMENTS_VISIBLE: 'Accessible',
    DOCUMENTS_DUE: 'Échéance',
    DOCUMENTS_URGENT: 'Urgent',
    DOCUMENTS_FILE_TYPE: 'Type de dossier',
    DOCUMENTS_RECOVER_DELETED: 'Récupérer le fichier supprimé',
    DOCUMENTS_UPLOAD_FILE: 'Téléverser un fichier',
    DOCUMENTS_ERROR_NO_ASSIGNEES:
      'Veuillez choisir au moins un responsable de la révision, un approbateur ou un signataire pour continuer.',
    DOCUMENTS_ERROR_SAME_AGENT:
      'Un agent ne peut pas remplir deux rôles différents pour un même fichier (responsable de la révision, approbateur ou signataire). Veuillez vérifier et essayer de nouveau.',
    DOCUMENTS_FILENAME: 'Nom du fichier',
    DOCUMENTS_REVIEWERS: 'Responsable(s) de la révision',
    DOCUMENTS_APPROVERS: 'Approbateur(s)',
    DOCUMENTS_SIGNEES: 'Signataire(s)',
    DOCUMENTS_DUE_DATE: 'Date d’échéance',
    DOCUMENTS_COMMENTS: 'Commentaires',
    DOCUMENTS_DEBTOR_VISIBILITY: 'Activer l’accès au débiteur',
    DOCUMENTS_DEBTOR_VISIBILITY_DISABLE: 'Désactiver l’accès au débiteur',
    DOCUMENTS_DOWNLOAD: 'Télécharger',
    DOCUMENTS_ADDITIONAL_INFORMATION: 'Renseignements supplémentaires',
    DOCUMENTS_ADDITIONAL_DETAILS: 'Précisions supplémentaires',
    DOCUMENTS_BUNDLE_SUCCESS:
      'Votre demande de regroupement est en cours de traitement. Une notification vous sera envoyée lorsque le regroupement sera terminé.',
    DOCUMENTS_ERROR_IS_ALREADY_VISIBLE:
      'Le débiteur a déjà accès à un ou plusieurs des fichiers sélectionnés. Veuillez vérifier et essayer de nouveau.',
    DOCUMENTS_VERSION: 'Version',
    DOCUMENTS_MODIFIED_BY: 'Modifié par',
    DOCUMENTS_MODIFIED_ON: 'Modifié le',
    DOCUMENTS_REVIEW_STATUS: 'Révision et statut de l’approbation',
    DOCUMENTS_REQUEST_REVIEW: 'Demander une révision',
    DOCUMENTS_SEND_FOR_REVIEW: 'Envoyer en révision',
    DOCUMENTS_INVALID_TYPE_MESSAGE:
      'Le format du fichier est invalide. Les formats valides sont les suivants : pdf, doc, docx, xls, xsls, csv, ppt, msg, jpg, jpeg, bmp, png, gif, heic, avif, tiff et webp. Veuillez vérifier et essayer de nouveau.',
    ADMINISTRATION: 'Administration',
    FILE_ANALYSIS_CONFIGURATION: 'Configuration de l’analyse du dossier',
    PROVINCIAL_EXEMPTION: 'Montants d’exemptions provinciales et taux d’imposition',
    SURPLUS_INCOME: 'Revenu excédentaire',
    NORTHWEST_TERRITORY_MULTIPLES: 'Multiplicateurs des Territoires du Nord-Ouest',
    OSB_FEES: 'BSF et autres frais',
    REAL_ESTATE_FEES: 'Honoraires immobiliers',
    CONFIGURATION_CHANGE_SUCCESS: 'Les changements apportés à la configuration ont été enregistrés.',
    FILE_ANALYSIS: 'Analyse du fichier',
    FILE_ANALYSIS_ESTIMATED_REALIZATIONS: 'Réalisations estimatives',
    FILE_ANALYSIS_FORM_48: 'Formulaire 48',
    FILE_ANALYSIS_CALCULATIONS: 'Calculs',
    FILE_ANALYSIS_SURPLUS_INCOME: 'Revenu excédentaire',
    FILE_ANALYSIS_FEES: 'Honoraires',
    FILE_ANALYSIS_PROPOSAL_DETAILS: 'Détails de la proposition',
    FILE_ANALYSIS_BANKRUPTCY_DETAILS: 'Renseignements sur la faillite',
    FILE_ANALYSIS_TRUSTEE_REPORT_DETAILS: 'Renseignements sur le rapport du syndic (article 170)',
    FILE_ANALYSIS_ASSETS: 'Biens',
    FILE_ANALYSIS_DEBTS_CREDITORS: 'Dettes/créanciers',
    FILE_ANALYSIS_SURPLUS_INCOME_INFO: 'Renseignements sur le revenu excédentaire',
    ADMIN_INFO_PROCESS_GENERAL: 'Renseignements généraux',
    ADMIN_INFO_PROCESS_SIGNUP: 'Inscription',
    ADMIN_INFO_PROCESS_COUNSELLING: 'Consultation',
    ADMIN_INFO_PROCESS_CLAIMS: 'Réclamations',
    ADMIN_INFO_PROCESS_ASSET_REALIZATION: 'Réalisation des biens',
    ADMIN_INFO_PROCESS_BANKING: 'Renseignements bancaires',
    ADMIN_INFO_PROCESS_INCOME_AND_EXPENSE: 'Revenus et dépenses',
    ADMIN_INFO_PROCESS_TAX_RETURN: 'Déclaration de revenus',
    ADMIN_INFO_PROCESS_DISCHARGE: 'Libération',
    ADMIN_INFO_PROCESS_RECEIPTS_AND_DISBURSEMENTS: 'Encaissements et décaissements',
    ADMIN_INFO_PROCESS_PROPOSAL: 'Proposition',
    ADMIN_INFO_PROCESS_45DAY_REVIEW: 'Examen après 45 jours',
    ADMIN_INFO_GENERAL_DETAILS: 'Renseignements généraux',
    ADMIN_INFO_AGENT: 'Agent',
    ADMIN_INFO_ESTATE_ADMINISTRATOR: 'Administrateur de la succession',
    ADMIN_INFO_TRUSTEE: 'Syndic',
    ADMIN_INFO_INTERVIEWER: 'Entrevue réalisée par',
    ADMIN_INFO_FILING_TYPE: 'Type de dépôt',
    ADMIN_INFO_SERVICE_LOCATION: 'Point de service',
    ADMIN_INFO_FILE_NAME: 'Nom du fichier',
    ADMIN_INFO_INSOLVENCY_DATE: 'Date d’insolvabilité',
    ADMIN_INFO_ESTATE_NUMBER: 'Numéro de succession',
    ADMIN_INFO_DURATION_OF_BANKRUPTCY: 'Durée de la faillite',
    ADMIN_INFO_UPDATE_FILING_TYPE: 'Mettre à jour le type de dépôt',
    ADMIN_INFO_CLAIMS_STATUS: 'Statut du processus de réclamation',
    ADMIN_INFO_CLAIMS_CLAIMFIELDS_TYPE: 'Type',
    ADMIN_INFO_CLAIMS_CLAIMFIELDS_ACCOUNT: 'Compte',
    ADMIN_INFO_CLAIMS_CLAIMFIELDS_DEBT: 'Dette',
    ADMIN_INFO_CLAIMS_CLAIMFIELDS_LETTER_SENT: 'Lettre envoyée',
    ADMIN_INFO_CLAIMS_CLAIMFIELDS_COMPLETED: 'Terminé',
    ADMIN_INFO_ASSET_REALIZATION_STATUS: 'État de la réalisation des biens',
    ADMIN_INFO_ASSET_REALIZATION_NO_ASSET_SECURED_CLAIM: 'Aucune réclamation relative à une créance garantie',
    ADMIN_INFO_AR_ASSETFIELDS_DESC: 'Desc.',
    ADMIN_INFO_AR_ASSETFIELDS_EST: 'Succ.',
    ADMIN_INFO_AR_ASSETFIELDS_REAL: 'Réal.',
    ADMIN_INFO_AR_ASSETFIELDS_COMPLETED: 'Terminé',
    ADMIN_INFO_AR_ASSETFIELDS_EXEMPT: 'Exempté',
    ADMIN_INFO_AR_CLAIMBYASSETFIELDS_CREDITOR_INFO: 'Renseignements sur le créancier',
    ADMIN_INFO_AR_CLAIMBYASSETFIELDS_SECURED: 'Garanti',
    ADMIN_INFO_INCOME_EXPENSE_STATUS: 'État des revenus et des dépenses',
    ADMIN_INFO_IANDE_SCHEDULEFIELDS_PERIOD: 'Période',
    ADMIN_INFO_IANDE_SCHEDULEFIELDS_DUE: 'Échéance',
    ADMIN_INFO_IANDE_SCHEDULEFIELDS_ESTIMATED_SURPLUS: 'Surplus de la succession',
    ADMIN_INFO_IANDE_SCHEDULEFIELDS_STATUS: 'État',
    ADMIN_INFO_TAX_RETURN_STATUS: 'État de la déclaration de revenus',
    ADMIN_INFO_TR_APPLICANT: 'Demandeur',
    ADMIN_INFO_TR_SPOUSE: 'Conjoint',
    ADMIN_INFO_TR_YEAR_LAST_RETURN_FILED: 'Année d’imposition de la dernière déclaration de revenus',
    ADMIN_INFO_TR_REFUND_AMOUNT: 'Montant du remboursement',
    ADMIN_INFO_TR_AMOUNT_OWING: 'Solde à payer',
    ADMIN_INFO_TR_TAXRETURNFIELDS_SOURCE: 'Source',
    ADMIN_INFO_TR_TAXRETURNFIELDS_ESTIMATED_AMOUNT: 'Succ.',
    ADMIN_INFO_TR_TAXRETURNFIELDS_DEPOSITED: 'Dép.',
    ADMIN_INFO_TR_TAXRETURNFIELDS_TAX_STATUS: 'Statut fiscal',
    ADMIN_INFO_TR_TAXRETURNFIELDS_ASCEND: 'Ascend',
    ADMIN_INFO_TR_TAXRETURNFIELDS_DISPOSITION: 'Disp.',
    ADMIN_INFO_TR_TAXRETURNFIELDS_COMPLETE: 'Terminé',
    ADMIN_INFO_DETAILS_TABLE_COL_RECEIVED: 'Reçu',
    ADMIN_INFO_DETAILS_TABLE_COL_ASSIGNED_TO: 'Attribué à',
    ADMIN_INFO_DETAILS_TABLE_COL_STATUS: 'État',
    ADMIN_INFO_DETAILS_TABLE_COL_DISCREPANCY: 'Divergence',
    ADMIN_INFO_DETAILS_TABLE_COL_STATE: 'État',
    ADMIN_INFO_DETAILS_TABLE_COL_RESULT: 'Résultat',
    ADMIN_INFO_DETAILS_TABLE_COL_ACTUAL_SURPLUS: 'Excédent réel',
    PROFILE: 'Profil',
    MY_PROFILE: 'Mon profil',
    AVAILABILITY: 'Disponibilité',
    NEWVIEW_SUBTITLE_NEW_CLAIM: 'Nouvelle créance',
    NEWVIEW_SUBTITLE_VIEW_CLAIM: 'Voir la créance',
    NEWVIEW_SUBTITLE_VIEW_SECURED_CLAIM: 'Voir la créance garantie',
    NEWVIEW_SUBTITLE_VIEW_UNSECURED_CLAIM: 'Voir la créance ordinaire',
    NEWVIEW_SUBTITLE_NEW_INCOME_AND_EXPENSE: 'Nouveaux revenus et dépenses',
    NEWVIEW_SUBTITLE_VIEW_INCOME_AND_EXPENSE: 'Voir les revenus et les dépenses',
    NEWVIEW_SUBTITLE_NEW_TAX_RETURN: 'Nouvelle déclaration de revenus',
    NEWVIEW_SUBTITLE_VIEW_TAX_RETURN: 'Voir la déclaration de revenus',
    EMAIL_FIELD_FROM: 'De',
    EMAIL_FIELD_TO: 'À',
    EMAIL_FIELD_SUBJECT: 'Objet',
    EMAIL_FIELD_BODY: 'Corps du texte',
    FILEARRAY_PREVIEW_FILE: 'Dossier',
    FILEARRAY_PREVIEW_RECEIVED: 'Reçu',
    SAVING: 'Enregistrement en cours',
    SUCCESSFULLY_SAVED: 'Enregistrement réussi',
    FORM_GENERIC_ERROR_SUBMITTING:
      'Une erreur s’est produite lors de l’envoi de vos données. Veuillez réessayer plus tard.',
    TABS_NO_CONTENT_DEFINED: 'Aucun contenu n’est défini.',
    PLEASE: 'Veuillez',
    ENTER_PARTIAL: 'saisir',
    SELECT_PARTIAL: 'sélectionner',
    CHOOSE_PARTIAL: 'choisir',
    PICK_PARTIAL: 'choisir',
    ONE_OR_MORE_PARTIAL: 'au moins un',
    ONLY_HAS_SPACES_PARTIAL: 'ne contient que des espaces',
    CLICK_HERE_PARTIAL: 'cliquer ici',
    TO_ACCESS_THE_FILE_THAT_WAS_CREATED: 'Accéder au dossier créé',
    TO_ACCESS_NEW_FILE_THAT_WAS_CREATED: 'Accéder au nouveau dossier créé',
    TO_ACCESS_DUPLICATE_FILE_THAT_WAS_CREATED: 'Accéder au duplicata créé',
    ENTER_VALUE: 'remplir ce champ',
    ENTER_REQUIRED_TEXT: 'saisir le texte requis',
    MAKE_A_SELECTION: 'faire un choix',
    MAKE_YOUR_SELECTION: 'faire vos choix',
    SELECT_THE_DATE: 'choisir une ou plusieurs dates',
    FILING_TYPE_UPDATED_IN_FILE: 'Le type de dépôt a été mis à jour dans ce fichier.',
    STAGE_UPDATED_TO_STATUS_CHANGED: 'L’état du fichier actuel est désormais réglé à État modifié.',
    NEW_FILE_HAS_BEEN_CREATED: 'un nouveau dossier a été créé',
    DUPLICATE_FILE_HAS_BEEN_CREATED: 'une copie de ce dossier a été créée',
    CREATE_ESTATE_IN_ASCEND_FOR_DUPLICATE_FILE:
      'Veuillez vous assurer qu’une nouvelle succession est créée dans Ascend pour ce dossier en double.',
    CREATE_ESTATE_IN_ASCEND_FOR_NEW_FILE:
      'Veuillez vous assurer qu’une nouvelle succession est créée dans Ascend pour ce nouveau dossier.',
    ASCEND_SEARCH_SUBMIT_ERROR:
      'Une erreur s’est produite lors de la soumission de votre demande. Veuillez réessayer plus tard.',
    ASCEND_ESTATE_NUMBER: 'Numéro de succession',
    ASCEND_SIN: 'NAS',
    ASCEND_DEBTOR_NAME: 'Nom du débiteur',
    ASCEND_DATE_OF_INSOLVENCY: 'Date de l’insolvabilité',
    ASCEND_CREDITOR_NAME: 'Nom du créancier',
    ASCEND_ACCOUNT_NUMBER: 'Numéro de compte',
    ASCEND_MERGE_CREDITOR_EMAIL: 'Fusionner l’adresse électronique du créancier',
    ASCEND_NUMBER_OF_HOUSEHOLD: 'Nombre de membres dans l’unité familiale',
    ASCEND_SELECT_INCOME_AND_EXPENSE: 'Sélectionner des revenus et des dépenses',
    ASCEND_SUBMISSION: 'Soumettre',
    ASCEND_TAX_PERIOD: 'Période d’imposition',
    ASCEND_LINK_TO_ESTATE: 'Lier à la succession',
    ASCEND_BUTTON_SEARCH_ASCEND: 'Recherche dans Ascend',
    ASCEND_BUTTON_CONFIRM: 'Confirmer',
    ASCEND_AT_LEAST_TWO_FIELDS:
      'Pour effectuer une recherche, veuillez entrer une valeur dans au moins deux des quatre champs suivants.',
    ASCEND_AT_LEAST_ONE_FIELD:
      'Pour effectuer une recherche, veuillez entrer une valeur dans au moins un des champs suivants.',
    ASCEND_ERROR__ERROR_TRYING_TO_VALIDATE: 'Une erreur s’est produite au moment de valider la soumission.',
    ASCEND_ERROR__NO_FIELDS_HAVE_VALUE: 'Aucun champ du formulaire ne contient une valeur.',
    ASCEND_ERROR__NOT_ENOUGH_FIELDS:
      'Pour effectuer une recherche, veuillez entrer des valeurs dans le nombre de champs requis, comme il est indiqué ci-dessous.',
    ASCEND_ABOUT_TO_LINK_CLAIM:
      'Vous êtes sur le point de lier ce dossier à la succession et à l’obligation sélectionnées. Cette action ne peut être annulée. Voulez-vous continuer?',
    ASCEND_ABOUT_TO_LINK_I_AND_E:
      'Vous êtes sur le point de lier cette soumission de renseignements sur les revenus et les dépenses au dossier et à la période des revenus et des dépenses sélectionnés. Cette action ne peut être annulée. Voulez-vous continuer?',
    ASCEND_MARK_AS_ACTIVE_Q: 'Indiquer comme actif?',
    ASCEND_OPTION_ACTIVE: 'Actif',
    ASCEND_OPTION_INACTIVE: 'Inactif',
    ASCEND_OPTION_DUPLICATE: 'Duplicata',
    CLAIMS_CLAIM_PROCESS: 'Processus de réclamation',
    CLAIMS_CREDITOR_EMAIL: 'Adresse électronique du créancier',
    NEW_CLAIM_MOVE_CLAIM_TO: 'Déplacer la créance vers',
    NEW_CLAIM_CLAIM_STATUS: 'État de la réclamation',
    VIEW_CLAIM_RESULT: 'Résultat',
    VIEW_CLAIM_DISCREPANCY: 'Écart',
    VIEW_CLAIM_CREDITOR_EMAIL_TEMPLATE: 'Modèle de message électronique à un créancier',
    VIEW_CLAIM_SOA: 'Bilan',
    VIEW_CLAIM_CLAIM_AMOUNT_FILED: 'Montant de la réclamation ou de la déclaration',
    VIEW_CLAIM_CLAIM_VOTING: 'Vote relatif à une réclamation',
    VIEW_CLAIM_MEETING_REQUESTED: 'Réunion demandée',
    VIEW_CLAIM_VOTED_BY: 'Voté par',
    VIEW_CLAIM_CLAIM_AMOUNT_MATCHES: 'Correspondance du montant de la réclamation?',
    VIEW_CLAIM_SECURITY_AGREEMENT: 'Contrat de garantie',
    VIEW_CLAIM_SECURITY_REGISTRATION: 'Enregistrement de la garantie',
    VIEW_CLAIM_VALIDATE_REG_DESCRIPTION: 'Valider la description de la production',
    VIEW_CLAIM_SECURITY_REGISTRATION_DATE: 'Date d’enregistrement de la garantie',
    VIEW_CLAIM_IS_THERE_AN_EQUITY: 'Valeur nette?',
    VIEW_CLAIM_170_REPORT_REQUESTED: 'Rapport du syndic demandé (article 170)',
    VIEW_CLAIM_CLAIM_VOTING__FOR: 'Pour',
    VIEW_CLAIM_CLAIM_VOTING__AGAINST: 'Contre',
    VIEW_CLAIM_CLAIM_VOTING__DID_NOT_VOTE: 'N’a pas voté',
    VIEW_CLAIM_VOTED_BY__IN_PERSON: 'En personne',
    VIEW_CLAIM_VOTED_BY__LETTER: 'Lettre',
    VIEW_CLAIM_VOTED_BY__PROXY: 'Procuration',
    VIEW_CLAIM_SECURITY_AGREEMENT__AVAILABLE: 'Disponibilité',
    VIEW_CLAIM_SECURITY_AGREEMENT__NOT_AVAILABLE: 'Non disponible',
    VIEW_CLAIM_VALIDATE_REG_DESCRIPTION__MATCHES: 'Correspondances',
    VIEW_CLAIM_VALIDATE_REG_DESCRIPTION__DOES_NOT_MATCH: 'Aucune correspondance',
    VIEW_CLAIM_ERROR__AT_LEAST_ONE_FILE:
      'Le document de la liste de contrôle concernant les créances garanties doit être téléversé pour terminer le processus de réclamation. Veuillez vérifier et essayer de nouveau.',
    VIEW_CLAIM_CONFIRM_DELETE_CLAIM:
      'Vous êtes sur le point de supprimer une preuve de réclamation importante. Voulez-vous continuer ?',
    TAXRETURN_TAX_RETURN_PROCESS: 'Processus relatif aux déclarations de revenus',
    TAXRETURN_TAX_RETURN_EMAIL: 'Message électronique relatif aux déclarations de revenus',
    TAXRETURN_TAX_RETURN_INFO: 'Renseignements relatifs aux déclarations de revenus',
    VIEW_TAXRETURN_RESULT: 'Résultat',
    VIEW_TAXRETURN_DISCREPANCY: 'Écart',
    VIEW_TAXRETURN_ADDITIONAL_PROCESS: 'Processus supplémentaire',
    VIEW_TAXRETURN_ADDITIONAL_PROCESS_INSTRUCTIONS: 'Processus supplémentaire - Instructions',
    VIEW_TAXRETURN_COMMENTS_FOR_CLIENT_PORTAL: 'Commentaires pour le portail client',
    VIEW_TAXRETURN_DATE_FILED: 'Date de production',
    VIEW_TAXRETURN_REMINDER_DATE: 'Date de rappel',
    VIEW_TAXRETURN_ESTIMATED: 'Estimation',
    VIEW_TAXRETURN_SOURCE: 'Source',
    VIEW_TAXRETURN_ASSESSMENT_DATE: 'Date d’évaluation',
    VIEW_TAXRETURN_STATUS: 'État',
    VIEW_TAXRETURN_DISPOSITION_DATE: 'Date de cession',
    VIEW_TAXRETURN_DISPOSITION: 'Cession',
    INCOMEEXPENSE_INCOME_EXPENSE_PROCESS: 'Processus relatif aux revenus et aux dépenses',
    INCOMEEXPENSE_INCOME_EXPENSE_EMAIL: 'Message électronique relatif aux revenus et aux dépenses',
    INCOMEEXPENSE_INCOME_EXPENSE_INFO: 'Renseignements relatifs aux revenus et dépenses',
    VIEW_INCOMEEXPENSE_RESULT: 'Résultat',
    VIEW_INCOMEEXPENSE_MISSING_INFO: 'Informations manquantes',
    VIEW_INCOMEEXPENSE_DISCREPANCY: 'Écart',
    VIEW_INCOMEEXPENSE_AMENDMENT_REQUIRED: 'Modification nécessaire',
    VIEW_INCOMEEXPENSE_COMMENTS_FOR_CLIENT_PORTAL: 'Commentaires pour le portail client',
    VIEW_INCOMEEXPENSE_SUPERINTENDENTS_STANDARD: 'Norme du surintendant',
    VIEW_INCOMEEXPENSE_STANDARD: 'Norme',
    VIEW_INCOMEEXPENSE_CUSTOM: 'Personnalisation',
    VIEW_INCOMEEXPENSE_BANKRUPTS_INCOME: 'Revenus du failli',
    VIEW_INCOMEEXPENSE_SPOUSES_INCOME: 'Revenus de l’époux ou du conjoint',
    VIEW_INCOMEEXPENSE_OTHER_INCOME: 'Autres revenus',
    VIEW_INCOMEEXPENSE_NONDISCRETIONARY_EXPENSES: 'Dépenses non discrétionnaires',
    VIEW_INCOMEEXPENSE_AVAILABLE_FAMILY_INCOME: 'Revenu familial disponible',
    VIEW_INCOMEEXPENSE_TOTAL_MONTHLY_SURPLUS: 'Total du revenu excédentaire mensuel',
    VIEW_INCOMEEXPENSE_BANKRUPT_PORTION: 'Portion du failli',
    VIEW_INCOMEEXPENSE_PERCENTAGE_OF_SURPLUS: 'Pourcentage du revenu excédentaire',
    VIEW_INCOMEEXPENSE_BANKRUPTS_PORTION_OF_SURPLUS: 'Portion du revenu excédentaire du failli',
    VIEW_INCOMEEXPENSE_BANKRUPTS_NET_SURPLUS: 'Revenu excédentaire net du failli',
    VIEW_INCOMEEXPENSE_CALCULATED_SURPLUS_PAYMENT: 'Calcul du paiement au titre du revenu excédentaire',
    VIEW_INCOMEEXPENSE_ESTIMATED_SURPLUS_PAYMENT: 'Estimation du paiement au titre du revenu excédentaire',
    VIEW_INCOMEEXPENSE_INCOME: 'Revenus ($)',
    VIEW_INCOMEEXPENSE_EXPENSE: 'Dépenses ($)',
    VIEW_INCOMEEXPENSE_IGNORE_AND_COMPLETE: 'Ignorer et terminer',
    VIEW_INCOMEEXPENSE_IGNORE_AND_COMPLETE_MSG:
      'La valeur du paiement excédentaire varie de 10 % par rapport à la valeur estimée et elle est inférieure ou égale à 100 $. Veuillez cliquer sur Ignorer et terminer ou sur Annuler.',
    ASCEND_ABOUT_TO_LINK_TAX_RETURN:
      'Vous êtes sur le point de lier cette soumission de déclaration de revenus à la succession et à la déclaration de revenus sélectionnées. Cette action ne peut être annulée. Voulez-vous continuer?',
    TAXRETURN_PERIODS_ALREADY_HAVE_ACTIVE_SUBMISSION:
      'Les périodes d’impositions suivantes sont liées à une soumission active. Veuillez indiquer l’état de chaque période comme active ou en duplicata pour procéder.',
    EMAIL_CONFIRMATION_TITLE: 'Confirmation par message électronique',
    SENDING_EMAIL: 'Envoyer le message',
    INFO_FILENAME: 'Nom du fichier',
    INFO_DESCRIPTION: 'Description',
    DATA_COULD_NOT_BE_RETRIEVED: 'Les données n’ont pas pu être récupérées.',
    SOME_DATA_COULD_NOT_BE_RETRIEVED: 'Certaines données n’ont pas pu être récupérées.',
    NO_DATA_WAS_RETRIEVED: 'Aucune donnée n’a pu être récupérée.',
    EMAIL_TEMPLATE_COULD_NOT_BE_RETRIEVED: 'Le modèle de message électronique n’a pas pu être récupéré.',
    ERROR: 'Erreur',
    LOCATIONS: 'Emplacements',
    ERROR__REQUIRED_FIELDS_MISSING: 'Des champs obligatoires ne sont pas remplis.',
    ERROR__TRYING_TO_CONFIRM: 'Une erreur s’est produite pendant la confirmation.',
    ERROR__TRYING_TO_SAVE: 'Une erreur s’est produite pendant l’enregistrement.',
    ERROR__TRYING_TO_SEND_EMAIL: 'Une erreur s’est produite pendant l’envoi du message électronique.',
    ERROR__ERROR_WITH_REQUEST: 'Votre demande contient une erreur.',
    ERROR__ERROR_WITH_UPLOAD: 'Une erreur s’est produite au moment de téléverser votre document.',
    ERROR__ERROR_GETTING_FORM_DATA: 'Une erreur s’est produite pendant la récupération des données du formulaire.',
    ERROR__FAILED_TO_SAVE_INCOME_ENTRIES: 'Échec de l’enregistrement des données relatives aux revenus.',
    ERROR__FAILED_TO_SAVE_EXPENSE_ENTRIES: 'Échec de l’enregistrement des données relatives aux dépenses.',
    BUTTON_SAVE_AS_DRAFT: 'Enregistrer le brouillon',
    BUTTON_SAVE_AS_DRAFT_WITH_ALERT: 'Enregistrer le brouillon avec une alerte',
    BUTTON_COMPLETE: 'Terminé',
    BUTTON_MARK_AS_COMPLETE: 'Indiquer comme terminé',
    BUTTON_MARK_AS_COMPLETE_WITH_ALERT: 'Indiquer comme terminé avec alerte',
    BUTTON_ADD_NEW_DEBT: 'Ajouter une nouvelle dette',
    BUTTON_REDUNDANT: 'Redondant',
    BUTTON_EDIT: 'Modifier',
    NOTICE_DEEMED_ANNULEMENT:
      'Préavis aux créanciers et rapport au séquestre officiel concernant l’annulation présumée de la proposition de consommateur - Production',
    NOTICE_AUTOMATED_REVIVAL:
      'Préavis aux créanciers et au séquestre officiel du rétablissement d’office de la proposition de consommateur',
    NOTICE_NO_AUTOMATIC_REVIVAL:
      'Avis aux créanciers et au séquestre officiel du non-rétablissement d’office de la proposition de consommateur - Production',
    NOTICE_REVIVAL:
      'Avis aux créanciers et rapport au séquestre officiel sur le rétablissement de la proposition de consommateur - Production',
    VISIBILITY_CHANGE_TITLE: 'Confirmer le changement de visibilité',
    VISIBILITY_CHANGE_MESSAGE:
      'Vous êtes sur le point de modifier la visibilité du fichier sélectionné. Voulez-vous continuer?',
    VISIBILITY_CHANGE_ERROR: 'La mise à jour de la visibilité a échoué.',
    FILING_DATE_UPDATED: 'La date de production a été mise à jour',
    FILING_DATE_UPDATE_ERROR: 'La mise à jour de la date de production a échoué.',
    DISCHARGE_170_PROCESS: 'Processus relatif au rapport du syndic (article 170)',
    DISCHARGE_OPPOSITION_LETTER: 'Lettre d’opposition',
    DISCHARGE_30_DAYS_NOTICE: 'Avis de 30 jours',
    DISCHARGE_TYPE: 'Type de libération',
    DISCHARGE_170_CHECKLIST: 'Liste de contrôle du rapport du syndic (article 170)',
    DISCHARGE_170_REPORT: 'Rapport du syndic (article 170)',
    DISCHARGE_170_REPORT_FILING: 'Soumission du rapport du syndic (article 170)',
    DISCHARGE_DISCHARGE_CERT: 'Cert. de libération',
    DISCHARGE_DISCHARGE_CERTIFICATE: 'Certificat de libération',
    DISCHARGE_OPPOSITION: 'Opposition',
    DISCHARGE_FORM_80: 'Formulaire 80',
    DISCHARGE_POSSIBLE_MEDIATION: 'Médiation possible',
    DISCHARGE_INITIAL_COURT: 'Libération initiale du tribunal',
    DISCHARGE_MEDIATION: 'Médiation',
    DISCHARGE_MEDIATION_STATUS: 'État de la médiation',
    DISCHARGE_MEDIATION_MODE: 'Mode de médiation',
    DISCHARGE_FORM_63: 'Formulaire 63',
    DISCHARGE_SIGNED_AGREEMENT: 'Accord signé',
    DISCHARGE_COURT_DISCHARGE: 'Libération par le tribunal',
    DISCHARGE_CHEQUE_REQUISITION: 'Demande de chèque',
    DISCHARGE_COURT: 'Tribunal',
    DISCHARGE_RECOMMEND_LETTER: 'Lettre de recommandation',
    DISCHARGE_RECOMMENDATION_LETTER: 'Lettre de recommandation',
    DISCHARGE_CONDITIONAL_ORDER: 'Ordonnance conditionnelle',
    DISCHARGE_NOTIFY_COURT_APP: 'Notification de la demande d’audience',
    DISCHARGE_COMPLIANCE_NOTIF: 'Notification de conformité',
    DISCHARGE_CHEQUE_REQUISITION_HISTORY: 'Historique des demandes de chèques',
    DISCHARGE: 'Libération',
    DISCHARGE_TOTAL_DUE: 'Montant total dû',
    DISCHARGE_TOTAL_PAYMENTS: 'Total des paiements',
    DISCHARGE_STATUS: 'Statut de la libération',
    DISCHARGE_COURT_APPEARANCE_REQUEST: 'Demande d’audience',
    DISCHARGE_SELECT_TRUSTEE: 'Sélectionner le syndic',
    DISCHARGE_COMMENTS: 'Commentaires',
    DISCHARGE_COURT_APPEARANCE_RESPONSE: 'Réponse relative à l’audience',
    DISCHARGE_RESPONSE: 'Réponse',
    DISCHARGE_REVIEW_OPPOSITION_RESPONSE: 'Examen de la réponse relative à l’opposition',
    DISCHARGE_PENDING_PROCESS: 'Processus en cours',
    DISCHARGE_OPPOSITION_COMMENTS: 'Commentaires relatifs à l’opposition',
    DISCHARGE_OPPOSITION_DEBTOR_RESPONSE: 'Réponse du débiteur relative à l’opposition',
    DISCHARGE_WITHDRAW_OPPOSITION: 'Retrait de l’opposition',
    RECEIPTS_DISBURSEMENTS_30_DAYS_NOTICE: 'Avis de 30 jours',
    RECEIPTS_DISBURSEMENTS_PROPOSAL_COMPLETION: 'Achèvement de la proposition',
    RECEIPTS_DISBURSEMENTS_CERTIFICATE_FULL_PERFORMANCE: 'Certificat d’exécution intégrale',
    RECEIPTS_DISBURSEMENTS_CERTIFICATE_FULL_PERFORMANCE_FILING: 'Certificat de production d’exécution intégrale',
    RECEIPTS_DISBURSEMENTS_FINAL_SRD: 'ERD final',
    RECEIPTS_DISBURSEMENTS_SRD_REPORT: 'Rapport de l’ERD',
    RECEIPTS_DISBURSEMENTS_SRD_REPORT_FILING: 'Production du rapport de l’ERD',
    RECEIPTS_DISBURSEMENTS_FINAL_DIVIDENTS_NOTICES: 'Dividendes définitifs et avis',
    RECEIPTS_DISBURSEMENTS_NOTICE_LABEL: 'Avis de taxation des comptes et de la libération de l’administrateur',
    RECEIPTS_DISBURSEMENTS_FORM_58_FILING: 'Production du formulaire 58',
    RECEIPTS_DISBURSEMENTS_FORM_15_FILING: 'Production du formulaire 15',
    RECEIPTS_DISBURSEMENTS_DIVIDENT_CHECKLIST: 'Liste de contrôle des dividendes',
    RECEIPTS_DISBURSEMENTS_SRD_CHECKLIST: 'Liste de contrôle de l’ERD',
    RECEIPTS_DISBURSEMENTS_TRUSTEE_DISCHARGE: 'Libération du syndic',
    RECEIPTS_DISBURSEMENTS_TRUSTEE_DISCHARGE_LABEL:
      'Certificat de conformité et libération présumée du syndic ou de l’administrateur',
    RECEIPTS_DISBURSEMENTS_FORM_16_FILING: 'Production du formulaire 16',
    RECEIPTS_DISBURSEMENTS_FILE_CLOSURE: 'Fermeture du dossier',
    SELECT: 'Sélectionner',
    UPDATED_SUCCESSFULLY: 'Mise à jour réussie',
    RECEIPTS_DISBURSEMENTS: 'Recettes et débours',
    RECEIPTS_DISBURSEMENTS_STATUS: 'État des recettes et débours',
    TASK_ACTION_BTN_CLAIM: 'Réclamation',
    TASK_ACTION_BTN_NEW_IE: 'Nouveaux revenus et nouvelles dépenses',
    TASK_ACTION_BTN_NEW_TAX_RETURN: 'Nouvelle déclaration de revenus',
    TASK_ACTION_BTN_VIEW_TAX_RETURN: 'Voir la déclaration de revenus',
    TASK_ACTION_BTN_DISCHARGE: 'Libération',
    TASK_ACTION_BTN_VIEW_RND: 'Voir les revenus et dépenses',
    TASK_ACTION_BTN_BANKING: 'Renseignements bancaires',
    PENDING: 'En suspens',
    CANCELLED: 'Annulé',
    COMPLETED: 'Terminé',
    MULTIPLE_FILENAMES_MESSAGE:
      'Plusieurs successions ayant le même nom de dossier existent dans Ascend. Veuillez vérifier dans Ascend et essayer de nouveau.',
    MIGRATED: 'Migration effectuée',
    COPIED_FROM_FILE: 'Copié par-dessus',
    DUPLICATE_FILE: 'Fichier en double',
    DUPLICATE_FILE_CONFIRMATION_TITLE: 'Confirmer la duplication du fichier',
    DUPLICATE_FILE_CONFIRMATION_MESSAGE:
      'Vous êtes sur le point de créer un nouveau fichier avec certaines informations copiées depuis le fichier actuel. Vous devez toujours vérifier les informations dans le nouveau fichier. Voulez-vous continuer?',
    DUPLICATE_FILE_FORM_DIALOG_TITLE: 'Nouveau fichier - Informations sur les prospects',
    PHONE_NUMBER_REQUIRED_ERROR: 'Le numéro de téléphone est requis.',
    PHONE_NUMBER_INCORRECT_ERROR: 'Le numéro de téléphone est incorrect.',
    NO_TRADES_SELECTED_ERROR: 'Aucune activité n’a été sélectionnée.',
    TRADES_UPDATED_SUCCESS: 'Les activités ont été mises à jour avec succès.',
    DUPLICATE_RECORDS_ERROR:
      'Impossible de continuer, car au moins un dossier contient les mêmes renseignements. Veuillez mettre à jour le prénom, le nom, le numéro de téléphone principal pour continuer.',
    MISSING_VALUES_CREDIT_REPORT_ERROR:
      'Les champs surlignés comportent des valeurs manquantes. Veuillez mettre la valeur à jour pour demander un dossier de crédit.',
    COUNTRY_ADDRESS_CREDIT_REPORT_ERROR:
      'Le pays dans lequel se trouve l’adresse devrait être Canada pour générer le dossier de crédit. Veuillez mettre l’adresse à jour pour demander un dossier de crédit.',
    PERSONAL_INFORMATION: 'Renseignements personnels',
    FIRST_NAME: 'Prénom',
    MIDDLE_NAME: 'Second prénom',
    LAST_NAME: 'Nom',
    DOB: 'Date de naissance',
    SIN: 'NAS',
    EMAIL_ADDRESS: 'Adresse électronique',
    MAIN_PHONE_NUMBER: 'No de téléphone principal',
    CURRENT_ADDRESS: 'Adresse actuelle',
    HAS_STAYED_MORE_THAN_2YEARS: 'Le débiteur a-t-il habité à l’adresse actuelle pendant plus de deux ans?',
    PREVIOUS_ADDRESS: 'Adresse précédente',
    CONSENT: 'Consentement',
    CONSENT_AGREEMENT: 'Accord de consentement',
    ADD_PREVIOUS_ADDRESS: 'Ajouter l’adresse précédente',
    CONSENT_TEXT_CREDIT_REPORT:
      'Je consens à ce que mes renseignements personnels soient fournis au syndic aux fins de l’établissement d’une demande de rapport de crédit auprès de TransUnion et du syndic de même que de la validation et de la réalisation de mes dettes et obligations. Ces renseignements personnels peuvent comprendre les éléments suivants : nom, date de naissance, état civil, numéros de téléphone, adresse, renseignements sur une ligne de crédit ou des comptes actuels, renseignements bancaires, événements liés à une faillite, procédures judiciaires et événements de recouvrement, le cas échéant.',
    DEBTOR_UNDERSTAND: 'À titre de débiteur, je comprends',
    CONSENT_FILE: 'Dossier de consentement',
    REQUEST_ON: 'Demande effectuée le',
    REQUEST_BY: 'Demandé par',
    SEND_REQUEST: 'Envoyer la demande',
    BASIC_INFO: 'Renseignements de base',
    ADDRESS_HISTORY: 'Historique d’adresses',
    TRANS_ALERTS: 'Alertes de TransUnion',
    TRADE: 'Activité',
    NAME: 'Nom',
    CONSENT_VIA_APP_FORM: 'Consentement fourni au moyen d’un formulaire de demande - Obtention du consentement',
    USER_ACCOUNT_PENDING_ACTIONS_ERROR:
      'Ce compte d’utilisateur a une ou plusieurs actions en suspens. Veuillez terminer ces actions ou les réassigner à un autre utilisateur.',
    USER_ACCOUNT_PENDING_TASKS_ERROR:
      'Ce compte utilisateur a une ou plusieurs tâches "En attente" assignées. Veuillez compléter ces tâches ou les réassigner à un autre utilisateur.',
    USER_ACCOUNT_PENDING_APPOINTMENTS_ERROR:
      'Ce compte d’utilisateur a un ou plusieurs rendez-vous en suspens. Veuillez terminer ces rendez-vous ou les réassigner à un autre utilisateur.',
    USER_ACCOUNT_ESTATE_ADMIN_FILES_ERROR:
      'Ce compte a un ou plusieurs dossiers qui lui sont attribués en tant qu’administrateur de succession. Veuillez désigner un autre administrateur de succession pour ces dossiers.',
    USER_ACCOUNT_TRUSTEE_COURTS_ERROR:
      'Ce compte est lié à un ou plusieurs tribunaux en tant que syndic. Veuillez retirer ce compte en tant que syndic et essayer de nouveau.',

    //Untranslated text
    TASK_ACTION_BTN_PROPOSAL: 'Proposal fr',
    UNLOCK_APP_TAB_NAME: 'Unlock Application Form fr',
    LOCK: 'Lock fr',
    UNLOCK: 'Unlock fr',
    STRING_LENGTH: 'Must be %1 long fr',
    NUMERIC_ONLY: 'Must be a numeric value fr',
    FILLING_DATE: 'Filing date fr',
    UPDATE_BY: 'Updated By fr',
    LOADING_MAP: 'Loading map... fr',
    ERROR_LOADING_MAP: 'Error while loading the map fr',
    CMBND_FILE_GREATER_THAN_100_ERROR: '*Combined file upload size cannot be more than 100 MB. fr',
    UPLOAD_N_SHARE: 'Upload & Share fr',
    FILING_DATE_REQUIRED: 'Filing date is required fr',
    ALLOCATION_SHOULD_BE_100: 'The total allocation % should always be 100%. Please check and try again fr',
    ALLOCATION_UPDATED: 'Allocation is updated fr',
    COURT_CITY: 'Court City fr',
    COURTS: 'Courts fr',
    COURT_TRUSTEE: 'Syndic pour Tribunal',
    FOLDER_NAME: 'Folder Name fr',
    SEARCH_BY_FOLDER_NAME_AND_PREFIX: 'Search by Folder Name and Pre-fix fr',
    EDIT_PRFX: 'Edit Pre-fix fr',
    ADD_PRFX: 'Add Pre-fix fr',
    FOLDER_IS_REQUIRED: 'Folder is required fr',
    PRFX_REQUIRED: 'Pre-fix is required fr',
    FOLDER: 'Folder fr',
    PRE_FIX: 'Pre-fix fr',
    COMMUNITY: 'Community fr',
    MULTIPLE: 'Multiple fr',
    TASK_HAS_PENDING_ACTIONS: 'There are one or more pending actions for this task. fr',
    WORKING_HOURS: 'Working hours fr',

    WEEKLY_SCHEDULE: 'Weekly Schedule fr',
    CALENDAR_VIEW: 'Calendar View fr',

    RESTORE_VERSION: 'Restore Version fr',
    ADD_APPOINTMENT_UPPER: 'Add Appointment fr',

    CONFIRM_FILE_IN_EXISTING_SYSTEM: 'Confirm File in existing system fr',
    SAVE_COURT_APPEARANCE_REQUEST_FAILED: 'Save Court Appearance Request failed fr',
    SAVE_COURT_APPEARANCE_RESPONSE_FAILED: 'Save Court Appearance Response failed fr',

    DISCHARGE_COURT_ORDER: 'Court Order fr',

    CONCERN_DOCUMENT_REQUIRED: 'At least one consent document (Prefix is Consent CB) is required fr',

    APPLICATION_OVERVIEW_FILING_TYPE: 'FT fr',
    APPLICATION_OVERVIEW_FILING_TYPE_TOOLTIP: 'Filing Type fr',
    TRADES_IMPORTED_SUCCESS: 'The selected liabilities are imported into the application form as debts fr',
    CREDITOR_EMAIL: 'Creditor Email fr',
    CREDITOR_EMAIL_REQUIRED: 'No email address is available for the creditor. Cannot send Creditor Email. fr',
    ONLY_1_OF_THE_2_SUPPORTING_DOCUMENTS_LISTED_FOR_PENSION_INCOME_IS_REQUIRED:
      'Only 1 of the 2 supporting documents listed for Pension/Annuities is required. fr',

    PERSONAL_INFO_DOCUMENTS_UPLOAD_ERROR_CONFIRM:
      'You have not selected a prefix for the Document(s) that have been uploaded. Select "Stay" to stay on the page and select prefixes. Select "Leave" to leave the page and the uploaded documents will be lost. fr',
    REQUIRED_DOCUMENT_FOR_BUSINESS_IS_MISSING: 'Required document(s) for business section is missing',
    QUESTIONNAIRE_DOCUMENTS_UPLOAD_ERROR_CONFIRM:
      'You have not selected a prefix or a business for the Document(s) that have been uploaded. Select "Stay" to stay on the page and select prefixes/businesses. Select "Leave" to leave the page and the uploaded documents will be lost.',

    ENGLISH_SPEAKING: 'Parlant anglais',
    FRENCH_SPEAKING: 'Parlant français',
    BILINGUAL_SPEAKING: 'Parlant bilingue',
    FILINGTYPE_CHANGE_CONFIRMATION_MESSAGE:
      'Vous êtes sur le point d’annuler le présent dossier et d’en créer un nouveau. Voulez-vous continuer?',
    FILINGTYPE_CHANGE_CONFIRMATION_TITLE: 'Mettre à jour le type de dépôt',
    OVERRIDE_REASON_REQUIRED: 'La raison du remplacement est requise.',
    OVERRIDE_TITLE: 'Remplacer',
    FILE_UPLOAD: 'Téléversement de fichiers',
    REVIEWED_BY_ADMIN: 'Examiné par l’administrateur',
    CONFIRM_DELETION: 'Confirmer la suppression',
    ARE_YOU_SURE_YOU_WANT_TO_DELETE_FILE: 'Voulez-vous vraiment supprimer {0}?',
    DOCUMENT_WAS_DELETED: 'Le document a été supprimé.',
    SOMETHING_WENT_WRONG: 'Un problème est survenu',
    REVIEW_STATUS_WAS_CHANGED: 'L’état d’examen a été modifié',
    UPLOAD_DOCUMENT: 'Téléverser des documents',
    SUCCESSFULLY_OVERRIDEN: 'Modification réussie',
    REMOVE_OVERRIDE: 'Annuler la modification',
    ENTER_OVERRIDE_REASON: 'Saisir la raison de l’annulation',
    OVERRIDE_REASON: 'Raison de l’annulation',
    PROVIDE_REASON_TO_REMOVE_A_REQUIRED_DOCUMENT:
      'Pour supprimer « {0} » comme document requis, veuillez saisir une raison.',
    ARE_YOU_SURE_YOU_WANT_TO_REMOVE_THIS_OVERRIDE: 'Voulez-vous vraiment annuler la modification?',
    OVERRIDE_SUCCESSFULLY_REMOVED: 'Modification annulée ',
    RECEIVED_EMAIL_CONSENT: 'Consentement à la réception de courriels',
    MARKETING_REPORT: 'Rapport de marketing',
    REPORTS: 'Rapports',
    APPOINTMENT_SCHEDULED_FROM: 'Début du rendez-vous planifié',
    APPOINTMENT_SCHEDULED_TO: 'Fin du rendez-vous planifié',
    FILE_CREATED_FROM: 'Début de la création du fichier',
    FILE_CREATED_TO: 'Fin de la création du fichier',
    REPORT_PERIOD_CANNOT_EXCEED_1_YEAR: 'La période de référence ne peut pas dépasser un an',
    REPORT_PERIOD_CANNOT_EXCEED_N_MONTHS: 'Report period cannot exceed {0} months',
    FROM_DATE_SHOULD_BE_BEFORE_TO_DATE: 'La date de début doit précéder la date de fin',
    DEBTOR_FIRST_NAME: 'Prénom du débiteur',
    DEBTOR_LAST_NAME: 'Nom de famille du débiteur',
    DEBTOR_EMAIL_ID: 'Adresse électronique du débiteur',
    DEBTOR_MAIN_PHONE_NUMBER: 'Numéro de téléphone principal du débiteur',
    REFERRAL_SOURCE: 'Source de recommandation',
    REFERRAL_SOURCE_ALL: 'Toutes les sources de recommandation',
    WEBURL: 'URL',
    DEBTOR_CREATED_DATE_AND_TIME: 'Date et heure de création du débiteur',
    ESTATE_NO: 'No de dossier',
    FILESTAGE: 'État du dossier',
    NOT_STARTED: 'Non commencé',
    INITIAL_CONSULTATION: 'Consultation initiale',
    GATHER_DOCUMENTS: 'Obtention des documents',
    SIGNUP: 'Inscription',
    FILE_IN_PROGRESS: 'Dossier en cours',
    SIGNUP_COMPLETED_DATE_TIME: 'Date et heure de fin d’inscription',
    APPOINTMENT_CREATION_DATE_TIME: 'Date et heure de création du rendez-vous',
    APPOINTMENT_CREATED_BY: 'Rendez-vous créé par',
    APPOINTMENT_ASSIGNED_TO: 'Rendez-vous attribué à',
    APPOINTMENT_STATUS: 'État du rendez-vous',
    APPOINTMENT_DATE_TIME: 'Date et heure du rendez-vous',
    APPOINTMENT_LOCATION: 'Lieu du rendez-vous',
    APPOINTMENT_MEETING_TYPE: 'Type de rendez-vous',
    NO_SHOW: 'Absence',
    INTAKE_SOURCE: 'Renseignements sur la source',
    TOTAL_DUE: 'Montant total dû $',

    REFERRAL_SOURCE_DETAIL: 'Referral Source Detail fr',
    TOTAL_DEPOSITED: 'Total Deposited $ fr',
    TOTAL_OUTSTANDING: 'Total Outstanding $ fr',
    PAYMENT_SUMMARY: 'Payment Summary fr',
    FRENCH_PREFIX: 'French Prefix fr',
    FRENCH_PREFIX_REQUIRED: 'French Prefix is required fr',

    CASH: 'Argent comptant',
    GIC: 'Certificat de placement garanti',
    SAVINGS: 'Épargne',
    TFSA: 'CELI',
    HOUSEHOLD_FURNITURE_EFFECTS: 'Meubles et articles ménagers',
    JEWELRY: 'Bijoux',
    LIFE_INSURANCE: 'Assurance vie',
    RRIF: 'FERR',
    LIRA: 'CRI',
    RESP: 'REEE',
    SHARES: 'Actions',
    BONDS: 'Obligations',
    COOP_SHARES: 'Parts dans une coopérative',
    CREDIT_UNION_SHARES: 'Parts dans une coopérative de crédit',
    RDSP: 'REEI',
    PENSION: 'Régimes de retraite',
    HOUSE: 'Maison',
    CONDO: 'Copropriété',
    LAND: 'Terrain',
    COTTAGE: 'Chalet',
    AUTOMOBILE: 'Automobile',
    SNOWMOBILE: 'Motoneige',
    MOTORCYCLE: 'Motocyclette',
    BOAT: 'Bateau',
    TRAILER: 'Remorque',
    CAMPER: 'Autocaravane',
    ATV: 'VTT',
    TOOLS_OF_TRADE: 'Outils de travail',
    HUNTING_TOOLS: 'Outils de chasse',
    FISHING_TOOLS: 'Outils de pêche',
    FARMING_TOOLS: 'Outils agricoles',
    WITHDRAWN: 'Retirée',
    WHICH_INDUSTRY_DO_YOU_WORK_IN: 'Dans quel secteur d’activité travaillez-vous?',
    WHICH_INDUSTRY_DO_YOU_WORK_IN_REQUIRED: 'Le champ Dans quel secteur d’activité travaillez-vous? est obligatoire.',
    PLEASE_PROVIDE_YOUR_EXPLOYMENT_HISTORY_DURING_THE_LAST_2_YEARS:
      'Veuillez indiquer votre historique d’emploi au cours des deux (2) dernières années.',
    EMPLOYED_FULLTIME: 'Employé à temps plein',
    EMPLOYED_PART_TIME: 'Employé à temps partiel',
    EMPLOYED_SEASONAL: 'Employé saisonnier',
    SELFEMPLOYED: 'Travailleur autonome',
    RETIRED: 'Retraité',
    STUDENT: 'Étudiant',
    DECEASED: 'Décédé',
    NOT_EMPLOYED: 'Sans emploi',
    HEALTHCARE: 'Soins de la santé',
    EDUCATION: 'Éducation',
    INFORMATION_TECHNOLOGY: "Technologies de l'information",
    ENGINEERING: 'Ingénierie',
    FINANCE: 'Finances',
    SALES_AND_MARKETING: 'Ventes et mise en marché',
    LEGAL: 'Droit',
    ARTS_AND_MEDIA: 'Arts et médias',
    HOSPITALITY: 'Hôtelerie',
    CONSTRUCTION_AND_TRADES: 'Métiers de la construction',
    TRANSPORTATION: 'Transports',
    MANUFACTURING: 'Secteur manufacturier',
    RETAIL: 'Commerce de détail',
    GOVERNMENT: 'Gouvernement',
    SCIENCE_AND_RESEARCH: 'Sciences et recherche',
    SOCIAL_SERVICES: 'Services sociaux',
    AGRICULTURE: 'Agriculture',
    HUMAN_RESOURCES: 'Ressources humaines',
    CUSTOMER_SERVICE: 'Service à la clientèle',
    SECURITY: 'Sécurité',
    SPORTS_AND_RECREATION: 'Sports et loisirs',
    HOME: 'Habitation',
    TO_THE_ESTATE: 'Effectués à l’actif',
    TO_PROVIDER_OF_FINANCIAL_ADVICE_OTHER_THAN_THE_LICENSED_INSOLVENCY_TRUSTEE:
      'Effectués à un fournisseur de conseils financiers (autre que le syndic autorisé en insolvabilité)',
    CHILD_SUPPORT_PAYMENTS: 'Pension alimentaire pour enfant',
    SPOUSAL_SUPPORT_PAYMENTS: 'Pension alimentaire pour ex-conjoint',
    CHILD_CARE: 'Frais de garde d’enfants',
    MEDICAL_DENTAL_PRESCRIPTION: 'Soins médicaux, dentaire et médicaments',
    FINES_PENALTIES_IMPOSED_BY_COURT: 'Amendes et pénalités imposées par le tribunal',
    AS_A_CONDITION_OF_EMPLOYMENT: 'À titre de condition d’emploi',
    DEBTS_WHERE_STAY_HAS_BEEN_LIFTED: 'Dettes sujettes à une levée des procédures',
    RENT_BOARD_MORTGAGE_PAYMENT: 'Loyer, frais de pension ou hypothèque',
    PROPERTY_TAXES_CONDO_FEES: 'Taxes foncières et frais de copropriété',
    HEAT_GAS_OIL: 'Chauffage, gaz ou huile',
    TELEPHONE: 'Téléphone',
    CABLE_INTERNET: 'Câble et Internet',
    HYDRO_ELECTRICITY: 'Électricité',
    WATER: 'Eau',
    FOOD_GROCERY: 'Nourriture et épicerie',
    LAUNDRY_DRY_CLEANING: 'Buanderie et nettoyage à sec',
    GROOMING_TOILETRIES: 'Soins personnels',
    CLOTHING: 'Vêtements',
    CAR_LEASE_FINANCE_PAYMENTS: 'Location ou financement d’automobile',
    REPAIR_MAINTENANCE_GAS: 'Entretien, réparations et essence',
    PUBLIC_TRANSPORTATION: 'Transport en commun',
    SMOKING: 'Tabac',
    ALCOHOL: 'Alcool',
    DINING_LUNCHES_RESTAURANTS: 'Repas et restaurants',
    ENTERTAINMENT_SPORTS: 'Divertissements et sports',
    GIFTS_CHARITABLE_DONATIONS: 'Cadeaux et dons de charité',
    ALLOWANCES: 'Allocations',
    FURNITURE_CONTENTS: 'Ameublement',
    TO_SECURED_CREDITORS: 'À un ou des créancier(s) hypothécaire(s)',
    PRESCRIPTIONS: 'Ordonnances',
    DENTAL: 'Soins dentaires',
    RENT_MORTGAGE_HYPOTHEC: 'Loyer / Hypothèque',
    TELEPHONE_CELL_PHONE: 'Téléphone / Cellulaire',
    CABLE_STREAMING_SERVICES: 'Câble / Services de diffusion en ligne',
    TOBACCO_VAPING_CANNABIS: 'Tabac / Vapotage / Cannabis',
    MEALS_RESTAURANTS: 'Repas / Restaurants',
    VEHICLE_INSURANCE_REGISTRATION: 'Assurances automobile / Immatriculation',
    ELECTRICITY: 'Électricité',
    LOSS_OF_INCOME: 'Diminution de revenus',
    MEDICAL_REASONS: 'Problèmes médicaux',
    TAX_LIABILITIES: 'Dettes fiscales',
    FINANCIAL_SUPPORT_OF_OTHERS: 'Aide financière à un tiers',
    STUDENT_DEBT: 'Dettes d’études',
    MOVING_RELOCATION_EXPENSES: 'Déménagement',
    FAILED_PROPOSAL: 'Échec d’une proposition',
    FINANCIAL_MISMANAGEMENT: 'Mauvaise gestion financière',
    LEGAL_MATTERS_PLEASE_SPECIFY: 'Problèmes judiciaires (veuillez préciser)',
    OTHER_PLEASE_SPECIFY: 'Autre (veuillez préciser)',
    EMPLOYMENT_INCOME: 'Revenu d’emploi',
    EMPLOYMENT_INSURANCE_BENEFITS: 'Prestations d’assurance-emploi',
    SPOUSAL_SUPPORT: 'Pension alimentaire pour ex-conjoint',
    PENSION_ANNUITIES: 'Pensions et rentes',
    CHILD_SUPPORT: 'Pension alimentaire pour enfant',
    SELFEMPLOYMENT_INCOME: 'Revenu de travail indépendant',
    SOCIAL_ASSISTANCE: 'Aide sociale',
    CHILD_TAX_BENEFIT: 'Prestations fiscales pour enfants',
    RENTAL_INCOME: 'Revenu locatif',
    GOVERNMENT_BENEFITS: 'Prestations gouvernementales',
    OTHER_BENEFITS: 'Autres prestations',
  },
};

export default Locales;
