import { ClientService } from '../../shared/api/ClientService';
import { IDebtorSearchCriteria, IOfficeLocationsSearchCriteria, ICSVDebtors } from './types';
import { DATE_FORMAT2 } from '../../constants/common';
import moment from 'moment';

export const argumentifyDebtorSearchCriteria = ({
  searchString,
  fullName,
  hasActiveFiles,
  pageSize,
  pageIndex,
  clientStatus,
  fileStage,
  filingType,
  trustee,
  serviceLocation,
  estateAdmin,
  isEmailChecked,
  isEstateNumberChecked,
  sortingFieldName,
  isAscending,
  includeFileIds,
  fileStageId,
  filingTypeId,
  debtorId,
  trusteeId,
  officeId,
  estateAdminId,
}: IDebtorSearchCriteria) =>
  [
    searchString,
    fullName,
    hasActiveFiles,
    pageSize,
    pageIndex,
    clientStatus,
    fileStage,
    filingType,
    trustee,
    serviceLocation,
    estateAdmin,
    isEmailChecked,
    isEstateNumberChecked,
    sortingFieldName,
    isAscending,
    includeFileIds,
    fileStageId,
    filingTypeId,
    debtorId,
    trusteeId,
    officeId,
    estateAdminId,
  ] as const;

export const argumentifyOfficeLocationsSearchCriteria = ({
  filterText,
  nearbyLatitude,
  nearbyLongitude,
  includeInactive,
  isActive,
  sorting,
  skipCount,
  maxResultCount,
}: IOfficeLocationsSearchCriteria) =>
  [filterText, nearbyLatitude, nearbyLongitude, includeInactive, isActive, sorting, skipCount, maxResultCount] as const;

export const convertDebtorsListToCSVData = (debtors?: ClientService.ClientResultsDto[]) =>
  [...(debtors || [])]
    ?.map(({ firstName, middleName, lastName, mainPhoneNumber, emailAddress, clientStatus, createdOn, files }) =>
      files?.map(
        ({
          fileName,
          estateNumber,
          filingType,
          serviceLocation,
          estateAdministrator,
          trustee,
          stage,
        }: ClientService.FileResultsDto) => ({
          firstName,
          middleName,
          lastName,
          mainPhoneNumber,
          emailAddress,
          clientStatus,
          createdOn: moment(createdOn).format(DATE_FORMAT2),
          fileName,
          estateNumber,
          filingType,
          serviceLocation,
          estateAdministrator,
          trustee,
          stage,
        })
      )
    )
    ?.flat() as ICSVDebtors[];
