import { Form, Input, Select, Row, Col, InputNumber, FormInstance } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';

import { ClientService } from '../../../../shared/api/ClientService';
import useLocale from '../../../../hooks/useLocale';
import { antdUtil } from '../../../../utils/antdUtil';
import SelectSuffixIcon from '../../../../components/SelectSuffixIcon/SelectSuffixIcon';
import ChangeTrackerFormItem from '../../../../components/ChangeTrackerFormItem';

import './ApplicationFormTab.scss';
import styles from '../../../../styles/style.module.scss';

interface IProps {
  form: FormInstance<ClientService.AppFormIncomeExpenseIncomeDto>;
  isMarried: boolean;
  incomeTypes: ClientService.LookupDto[];
  rowName: number;
  remove: (index: number | number[]) => void;
  isLocked?: boolean;
}

function IncomeExpenseIncomeRow({
  form,
  isMarried,
  incomeTypes,
  rowName,
  remove,
  isLocked = false,
}: IProps): JSX.Element {
  const { t, getLocalizedDtoName } = useLocale();
  const { Option } = Select;

  return (
    <Form.Item
      noStyle
      shouldUpdate={(previousValue, currentExpenseValue) =>
        previousValue?.incomeDetails !== currentExpenseValue?.incomeDetails
      }
    >
      {({ getFieldValue }) => {
        const incomeDetails = getFieldValue('incomeDetails') as ClientService.AppFormIncomeExpenseIncomeDetailDto[];
        const incomeTypeId = incomeDetails?.[rowName]?.incomeTypeId;
        const currentIncomeType = incomeTypes.find((item) => item.id === incomeTypeId);
        const unusedIncomeTypes = incomeTypes?.filter(
          (item) => !Boolean(incomeDetails.find((i) => item.id === i.incomeTypeId))
        );
        const selectOptions = currentIncomeType ? [...unusedIncomeTypes, currentIncomeType] : unusedIncomeTypes;

        const descriptionRequired =
          currentIncomeType?.enumValue === ClientService.IncomeTypeEnum.Other ||
          currentIncomeType?.enumValue === ClientService.IncomeTypeEnum.GovernmentBenefits ||
          currentIncomeType?.enumValue === ClientService.IncomeTypeEnum.OtherBenefits;

        const isInitial = incomeDetails?.[rowName]?.isInitial;
        return (
          <Row gutter={20} align="middle">
            <Col span={descriptionRequired ? 5 : 11}>
              <ChangeTrackerFormItem
                name={[rowName, 'incomeTypeId']}
                rules={[{ required: true, message: t.REQUIRED_FIELD }]}
                className="ApplicationFormTab__income-expense-type"
              >
                <Select
                  suffixIcon={<SelectSuffixIcon />}
                  size="large"
                  showArrow={!incomeTypeId}
                  disabled={isInitial || isLocked}
                >
                  {selectOptions?.map((option) => (
                    <Option key={option.id} value={option?.id}>
                      {getLocalizedDtoName(option)}
                    </Option>
                  ))}
                </Select>
              </ChangeTrackerFormItem>
            </Col>
            {Boolean(descriptionRequired) && (
              <Col span={6}>
                <ChangeTrackerFormItem
                  name={[rowName, 'description']}
                  rules={[{ required: descriptionRequired, message: t.REQUIRED_FIELD }]}
                  required
                  listName="incomeDetails"
                >
                  <Input size="large" placeholder={t.DESCRIPTION} disabled={isLocked} />
                </ChangeTrackerFormItem>
              </Col>
            )}
            <Col span={Boolean(isMarried) ? 4 : 6}>
              <ChangeTrackerFormItem name={[rowName, 'applicantAmount']} listName="incomeDetails">
                <InputNumber
                  size="large"
                  style={{ width: '100%' }}
                  min={0}
                  max={999999999}
                  defaultValue={0}
                  formatter={(value) => antdUtil.currencyFormatter(value)}
                  parser={(value) => antdUtil.currencyParser(value) as 0 | 999999999}
                  disabled={isLocked}
                />
              </ChangeTrackerFormItem>
            </Col>
            {Boolean(isMarried) && (
              <Col span={4}>
                <ChangeTrackerFormItem name={[rowName, 'spouseAmount']} listName="incomeDetails">
                  <InputNumber
                    size="large"
                    style={{ width: '100%' }}
                    min={0}
                    max={999999999}
                    defaultValue={0}
                    formatter={(value) => antdUtil.currencyFormatter(value)}
                    parser={(value) => antdUtil.currencyParser(value) as 0 | 999999999}
                    disabled={isLocked}
                  />
                </ChangeTrackerFormItem>
              </Col>
            )}
            <Col span={Boolean(isMarried) ? 4 : 6}>
              <ChangeTrackerFormItem name={[rowName, 'otherHouseholdMemberAmount']} listName="incomeDetails">
                <InputNumber
                  size="large"
                  style={{ width: '100%' }}
                  min={0}
                  max={999999999}
                  defaultValue={0}
                  formatter={(value) => antdUtil.currencyFormatter(value)}
                  parser={(value) => {
                    console.log(value);
                    return antdUtil.currencyParser(value) as 0 | 999999999;
                  }}
                  disabled={isLocked}
                />
              </ChangeTrackerFormItem>
            </Col>
            <Col span={1}>
              <DeleteOutlined
                className="ApplicationFormTab__input-action-icon"
                onClick={() => {
                  if (!isLocked) remove(rowName);
                }}
                style={{
                  cursor: !isLocked ? 'pointer' : 'not-allowed',
                  color: !isLocked ? styles.colorDanger : styles.colorSecondary,
                }}
              />
            </Col>
          </Row>
        );
      }}
    </Form.Item>
  );
}

export default IncomeExpenseIncomeRow;
