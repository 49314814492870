import { Col, DatePicker, Radio, Form } from 'antd';
import useLocale from '../../../../hooks/useLocale';

import { antdUtil } from '../../../../utils/antdUtil';
import { Label } from '@fluentui/react';
import TextArea from 'antd/lib/input/TextArea';
import { DATE_FORMAT2, DATE_FORMAT_PLACEHOLDER } from '../../../../constants/common';
import ChangeTrackerFormItem from '../../../../components/ChangeTrackerFormItem';

import './ApplicationFormTab.scss';

interface IProps {
  label: string;
  flagName: string;
  flagRequired?: boolean;
  detailsName?: string;
  detailsRequired?: boolean;
  dateName?: string;
  dateLabel?: string;
  boolName?: string;
  boolLabel?: string;
  isLocked?: boolean;
}

const QuestionnaireQuestion = ({
  label,
  flagName,
  flagRequired = false,
  detailsName,
  detailsRequired = false,
  dateName,
  dateLabel,
  boolName,
  boolLabel,
  isLocked = false,
}: IProps): JSX.Element => {
  const { t } = useLocale();

  return (
    <>
      <Col span={16}>
        <Label className="ApplicationFormTab__label">{label}</Label>
      </Col>
      <Col span={8}>
        <Form.Item noStyle shouldUpdate>
          <ChangeTrackerFormItem
            name={flagName}
            className="ApplicationFormTab__questionnaire-question"
            rules={[{ required: Boolean(flagRequired), message: t.REQUIRED_FIELD }]}
          >
            <Radio.Group size="large" buttonStyle="solid" optionType="button" disabled={isLocked}>
              <Radio.Button value={true}>{t.YES}</Radio.Button>
              <Radio.Button value={false}>{t.NO}</Radio.Button>
            </Radio.Group>
          </ChangeTrackerFormItem>
        </Form.Item>
      </Col>

      <Form.Item noStyle shouldUpdate>
        {({ getFieldValue }) =>
          getFieldValue(flagName) && (
            <>
              {detailsName && (
                <Col span={24}>
                  <ChangeTrackerFormItem
                    name={detailsName}
                    className="ApplicationFormTab__questionnaire-question-additional-info"
                    rules={[{ required: Boolean(detailsRequired), message: t.REQUIRED_FIELD }]}
                    validateTrigger="onBlur"
                  >
                    <TextArea size="large" placeholder={t.DETAILS} disabled={isLocked} />
                  </ChangeTrackerFormItem>
                </Col>
              )}

              {dateName && (
                <>
                  <Col span={12}>
                    <ChangeTrackerFormItem
                      name={dateName}
                      label={dateLabel}
                      className="ApplicationFormTab__questionnaire-question-additional-info"
                    >
                      <DatePicker
                        size="large"
                        disabledDate={antdUtil.futureDates}
                        format={DATE_FORMAT2}
                        placeholder={DATE_FORMAT_PLACEHOLDER}
                        disabled={isLocked}
                      />
                    </ChangeTrackerFormItem>
                  </Col>
                  <Col span={12}></Col>
                </>
              )}

              {boolName && (
                <>
                  <Col span={16}>
                    <Label className="ApplicationFormTab__label ApplicationFormTab__questionnaire-question-additional-info">
                      {boolLabel}
                    </Label>
                  </Col>
                  <Col span={8}>
                    <ChangeTrackerFormItem
                      name={boolName}
                      className="ApplicationFormTab__questionnaire-question-additional-info"
                    >
                      <Radio.Group size="large" buttonStyle="solid" optionType="button" disabled={isLocked}>
                        <Radio.Button value={true}>{t.YES}</Radio.Button>
                        <Radio.Button value={false}>{t.NO}</Radio.Button>
                      </Radio.Group>
                    </ChangeTrackerFormItem>
                  </Col>
                </>
              )}
            </>
          )
        }
      </Form.Item>
    </>
  );
};

export default QuestionnaireQuestion;
