import { SwitchProps, Row, Col, RowProps } from 'antd';
import { Icon, IIconProps } from '@fluentui/react/lib/Icon';

import DataItem from '../DataItem/DataItem';
import Button, { CustomButtonProps } from '../Button/Button';
import SwitchWithIcon from '../SwitchWithIcon/SwitchWithIcon';

import useLocale from '../../hooks/useLocale';

import './UploadDataItem.scss';

export type IUploadDataType = 'extra-icon' | 'extra-switch' | 'extra-review';

export interface IUploadDataItem {
  label?: string;
  type?: IUploadDataType[];
  link?: string | false;
  buttonProps?: CustomButtonProps;
  switchProps?: SwitchProps;
  iconProps?: IIconProps;
  reviewIconProps?: IIconProps;
  reviewIconVisible?: boolean;
  disabled?: boolean;
  labelColSpan?: number;
  colSpan?: number;
  dataRowProps?: RowProps;
}

const UploadDataItem = ({
  label,
  type,
  buttonProps,
  switchProps,
  iconProps,
  reviewIconProps,
  reviewIconVisible,
  link,
  disabled,
  labelColSpan,
  colSpan,
  dataRowProps,
}: IUploadDataItem) => {
  const { t } = useLocale();

  return (
    <Col span={colSpan || 8}>
      <Row align="middle">
        <Col span={labelColSpan || 10}>
          <DataItem.Label label={label} />
        </Col>
        <Col span={labelColSpan ? 24 - labelColSpan : 14}>
          <Row align="middle" justify={dataRowProps?.justify || 'space-between'}>
            <Col>
              <Button kind="link" {...buttonProps} disabled={buttonProps?.disabled || disabled}>
                {link || buttonProps?.children || t.UPLOAD}
              </Button>
            </Col>

            {type && type?.indexOf('extra-review') >= 0 && reviewIconVisible && (
              <Col>
                <Icon {...reviewIconProps} iconName="RedEye" className="UploadDataItem__review-icon" />
              </Col>
            )}

            {type && type?.indexOf('extra-switch') >= 0 && (
              <Col>
                <SwitchWithIcon {...switchProps} disabled={switchProps?.disabled || disabled} />
              </Col>
            )}

            {type && type?.indexOf('extra-icon') >= 0 && (
              <Col>
                <Icon
                  {...iconProps}
                  iconName={iconProps?.iconName || 'History'}
                  className={`UploadDataItem__history-icon ${iconProps?.className || ''}`}
                  onClick={(e) => {
                    if (!disabled && iconProps?.onClick) iconProps?.onClick(e);
                  }}
                />
              </Col>
            )}
          </Row>
        </Col>
      </Row>
    </Col>
  );
};

export default UploadDataItem;
