import useLocale from '../../hooks/useLocale';

interface IProps {
  json?: string;
}
const WorkingHours = ({ json = '' }: IProps) => {
  const { t } = useLocale();

  if (!json) return <></>;

  return (
    <div style={{ paddingTop: 12 }}>
      <b>{t.WORKING_HOURS}:</b>
      {JSON.parse(json)
        ?.Hours?.split(';')
        ?.map((item: string, index: number) => (
          <div key={index}>{item?.replaceAll('=', ' ')}</div>
        ))}
    </div>
  );
};
export default WorkingHours;
