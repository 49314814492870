import { useState, useCallback, useEffect, Key } from 'react';

import EditableTable from './EditableTable/EditableTable';

import genericMessage from '../../../../utils/genericMessage';
import { NUMERIC_PRECISION } from '../constants';
import { InputTypeEnum, IDataProps, IConfigurationType } from '../types';
import API from '../../../../utils/api';
import { ClientService } from '../../../../shared/api/ClientService';
import useLocale from '../../../../hooks/useLocale';

interface IProps {
  isEditable?: boolean;
  setIsEditable?: (isEditable: boolean) => void;
}

function NorthwestTerritoryMultiplesTable({ isEditable, setIsEditable }: IProps): JSX.Element {
  const { t } = useLocale();

  const [data, setData] = useState<IDataProps<IConfigurationType>[]>();
  const [loading, setLoading] = useState<boolean>(false);

  const requestConfigurations = useCallback(async () => {
    setLoading(true);
    const response = await API.getFileAnalysisNWTerritoryMultiplesConfigurationsList().catch(() => setLoading(false));
    if (response) setData(response?.map((item) => ({ ...item, key: item.id as Key, hasError: false })));
    setLoading(false);
  }, []);

  const requestUpdateConfigurations = useCallback(
    async (data?: IDataProps<IConfigurationType>[]) => {
      const body = data?.map((item: any) => ({
        id: item?.id,
        multiple: Number(item?.multiple),
      })) as ClientService.FileAnalysisNWTerritoryMultiplesConfigurationInputDto[];
      setLoading(true);
      const response = await API.updateFileAnalysisNWTerritoryMultiplesConfigurations(body).catch(() =>
        setLoading(false)
      );
      if (response?.result === ClientService.Result.Successful) {
        genericMessage.success(t.CONFIGURATION_CHANGE_SUCCESS);
        if (setIsEditable) setIsEditable(false);
        requestConfigurations();
      } else {
        genericMessage.error({}, response?.messages?.[0].body);
      }
      setLoading(false);
    },
    [requestConfigurations, setIsEditable, t.CONFIGURATION_CHANGE_SUCCESS]
  );

  const handleCancel = useCallback(() => {
    if (setIsEditable) setIsEditable(false);
    requestConfigurations();
  }, [requestConfigurations, setIsEditable]);

  useEffect(() => {
    if (!data) requestConfigurations();
  }, [data, requestConfigurations]);

  const defaultColumns = [
    {
      title: t.COMMUNITY,
      dataIndex: 'communityName',
      key: 'communityName',
      type: InputTypeEnum.ReadOnly,
      width: '300px',
    },
    {
      title: t.MULTIPLE,
      dataIndex: 'multiple',
      key: 'multiple',
      type: InputTypeEnum.Numeric,
      precision: NUMERIC_PRECISION,
    },
  ];

  return (
    <EditableTable
      data={data}
      loading={loading}
      defaultColumns={defaultColumns}
      isEditable={isEditable}
      onSave={requestUpdateConfigurations}
      onCancel={handleCancel}
    />
  );
}

export default NorthwestTerritoryMultiplesTable;
