import { Table } from 'antd';
import moment from 'moment';

import { DATE_FORMAT2 } from '../../../../constants/common';
import { ClientService } from '../../../../shared/api/ClientService';
import useLocale from '../../../../hooks/useLocale';

import './Panels.scss';

interface IProps {
  dataSource?: ClientService.CreditReportHistoryAddressDto[];
}

const AddressHistory = ({ dataSource }: IProps) => {
  const { t } = useLocale();
  const columns = [
    {
      title: t.ADDRESS_TYPE,
      dataIndex: 'addressType',
      key: 'addressType',
    },
    {
      title: t.STREET,
      dataIndex: 'street',
      key: 'street',
    },
    {
      title: t.CITY,
      dataIndex: 'city',
      key: 'city',
    },
    {
      title: t.PROVINCE,
      dataIndex: 'prov',
      key: 'prov',
    },
    {
      title: t.POSTAL_CODE,
      dataIndex: 'postal',
      key: 'postal',
    },
    {
      title: t.DATE_REPORTED,
      dataIndex: 'dateReported',
      key: 'dateReported',
      render: (item: moment.Moment) => <>{item ? moment(item).format(DATE_FORMAT2) : '-'}</>,
    },
  ];

  return <Table columns={columns} dataSource={dataSource} rowKey="id" pagination={false} />;
};

export default AddressHistory;
