import { SelectProps, Spin } from 'antd';
import { useQuery } from 'react-query';

import SelectLookupDto from '../../SelectLookupDto/SelectLookupDto';

import { PREFIXES_QUERY, USE_QUERY_OPTIONS } from '../../../constants/reactQuery';
import API from '../../../utils/api';

import './PrefixesWithLoad.scss';
import useLocale from '../../../hooks/useLocale';

interface IProps extends SelectProps {
  documentFolderId?: string;
  prefixCodes?: (string | undefined)[];
}

const PrefixesWithLoad = ({ documentFolderId, prefixCodes, ...props }: IProps) => {
  const { data, isFetching } = useQuery(
    [PREFIXES_QUERY, documentFolderId, `${prefixCodes}`],
    () => {
      if (documentFolderId) {
        return API.listPrefixes(documentFolderId, prefixCodes);
      }
    },
    USE_QUERY_OPTIONS
  );
  const { t } = useLocale();

  return (
    <SelectLookupDto
      placeholder={t.SELECT_PREFIX}
      data={data}
      loading={isFetching}
      notFoundContent={isFetching ? <Spin /> : null}
      className="PrefixesWithLoad"
      {...props}
    />
  );
};

export default PrefixesWithLoad;
