import { useState, useMemo, useEffect } from 'react';
import { Row, Col } from 'antd';
import moment from 'moment';
import ExpandableRecordsDisplay, {
  IRecordField,
  ITableColumn,
} from '../components/ExpandableRecordsDisplay/ExpandableRecordsDisplay';
import ProcessStatusSelection from '../components/ProcessStatusSelection/ProcessStatusSelection';
import { ClientService } from '../../../../../shared/api/ClientService';
import API from '../../../../../utils/api';
import { getTaxReturnSubmissionStateValueAsElement } from '../../../helpers';
import useLocale from '../../../../../hooks/useLocale';
import FormSectionTitle from '../../../../../components/FormSectionTitle/FormSectionTitle';
import DataItem from '../../../../../components/DataItem/DataItem';
import ErrorsContainer, { IErrorsMsgAndType } from '../../../../../components/ErrorsContainer/ErrorsContainer';
import useErrorHandling from '../../../../../hooks/useErrorHandling';
import Loading from '../../../../../components/Loading/Loading';
import { getEnumTextValuesAsArray } from '../../../../../utils/helpers';
import { DATE_AND_TIME_FORMAT } from '../../../../../constants/common';
import { ROUTES } from '../../../../../constants/routes';
import { eventBus } from '../../../../../utils/eventBus';
import { ADMIN_INFO_CHANGE_EVENT } from '../../../../../constants/eventBus';

import styles from './AdminInfo_TaxReturn.module.scss';
import { argumentifyTaxReturnPeriodSearchCriteria } from '../utils';

interface IProps {
  data: ClientService.IApplicationFileDto | null;
  filingTypesList: ClientService.ILookupDto[] | null;
  handleUpdateData: () => void;
  disableAllFields?: boolean;
}

const getSelfOrUndefined = (value: any) => {
  return value == null ? undefined : value;
};

const getSelfOrZero = (value: any) => {
  return value == null ? 0 : value;
};

const TaxReturn = ({ data, filingTypesList, handleUpdateData, disableAllFields = false }: IProps): JSX.Element => {
  const { t } = useLocale();

  const { processResponseForErrors } = useErrorHandling();

  const [taxReturnResponse, setTaxReturnResponse] = useState<any | undefined>();
  const [taxReturnProcessStatus, setTaxReturnProcessStatus] = useState<any | undefined>();
  const [allTaxPeriodsCompleted, setAllTaxPeriodsCompleted] = useState<boolean | undefined>();

  const [tabErrors, setTabErrors] = useState<IErrorsMsgAndType[] | undefined>();

  const taxReturnRecordFields: IRecordField[] = [
    { label: t.ADMIN_INFO_TR_TAXRETURNFIELDS_SOURCE, dataIndex: 'source' },
    { label: t.ADMIN_INFO_TR_TAXRETURNFIELDS_ESTIMATED_AMOUNT, dataIndex: 'estimatedAmount', format: 'currency' },
    { label: t.ADMIN_INFO_TR_TAXRETURNFIELDS_DEPOSITED, dataIndex: 'deposited', format: 'currency' },
    { label: t.ADMIN_INFO_TR_TAXRETURNFIELDS_TAX_STATUS, dataIndex: 'taxStatus' },
    { label: t.ADMIN_INFO_TR_TAXRETURNFIELDS_ASCEND, dataIndex: 'ascendStatus' },
    { label: t.ADMIN_INFO_TR_TAXRETURNFIELDS_DISPOSITION, dataIndex: 'disposition' },
    { label: t.ADMIN_INFO_TR_TAXRETURNFIELDS_COMPLETE, dataIndex: 'complete' },
  ];

  const ClaimFilesTableCols: ITableColumn[] = [
    { title: t.ADMIN_INFO_DETAILS_TABLE_COL_RECEIVED, dataIndex: 'received' },
    { title: t.ADMIN_INFO_DETAILS_TABLE_COL_ASSIGNED_TO, dataIndex: 'assignedTo' },
    { title: t.ADMIN_INFO_DETAILS_TABLE_COL_STATUS, dataIndex: 'status' },
    { title: t.ADMIN_INFO_DETAILS_TABLE_COL_DISCREPANCY, dataIndex: 'discrepancy' },
    { title: t.ADMIN_INFO_DETAILS_TABLE_COL_STATE, dataIndex: 'state' },
  ];

  const fileId = useMemo((): string => {
    if (data?.id) {
      return data.id;
    }
    return '';
  }, [data?.id]);

  useEffect(() => {
    if (taxReturnResponse) return;

    API.taxReturnGET(fileId).then((response) => {
      const responseErrors = processResponseForErrors(response);
      setTabErrors(responseErrors.messages);
      if (responseErrors.hasErrors) return;

      setTaxReturnResponse(response);
      setTaxReturnProcessStatus(response.taxReturnProcessStatus);
      setAllTaxPeriodsCompleted(response.completedAllTaxReturnPeriods);
    });
  }, [taxReturnResponse, fileId, processResponseForErrors]);

  if (!taxReturnResponse) return <Loading />;

  return (
    <>
      {tabErrors && <ErrorsContainer errors={tabErrors} noTitle />}
      <Row gutter={20} style={{ borderBottom: '2px dotted #e8e8e9' }}>
        <Col span={24}>
          <div className={styles.person_row_container}>
            <FormSectionTitle titleText={t.ADMIN_INFO_TR_APPLICANT} style={{ padding: '0 10px 5px 10px' }} />
            <div className={styles.person_row}>
              <DataItem
                colSpan={8}
                layout="vertical"
                label={t.ADMIN_INFO_TR_YEAR_LAST_RETURN_FILED}
                value={getSelfOrUndefined(taxReturnResponse.yearLastReturnFiled)}
              />
              <DataItem
                colSpan={8}
                layout="vertical"
                label={t.ADMIN_INFO_TR_REFUND_AMOUNT}
                value={{ value: getSelfOrZero(taxReturnResponse.refundAmount), format: 'currency-no-dollar-sign' }}
              />
              <DataItem
                colSpan={8}
                layout="vertical"
                label={t.ADMIN_INFO_TR_AMOUNT_OWING}
                value={{ value: getSelfOrZero(taxReturnResponse.amountOwing), format: 'currency-no-dollar-sign' }}
              />
            </div>
          </div>
        </Col>
      </Row>
      <Row gutter={20}>
        <Col span={24}>
          <div className={styles.person_row_container}>
            <FormSectionTitle titleText={t.ADMIN_INFO_TR_SPOUSE} style={{ padding: '16px 10px 5px 10px' }} />
            <div className={styles.person_row}>
              <DataItem
                colSpan={8}
                layout="vertical"
                label={t.ADMIN_INFO_TR_YEAR_LAST_RETURN_FILED}
                value={getSelfOrUndefined(taxReturnResponse.spouseYearLastReturnFiled)}
              />
              <DataItem
                colSpan={8}
                layout="vertical"
                label={t.ADMIN_INFO_TR_REFUND_AMOUNT}
                value={{
                  value: getSelfOrZero(taxReturnResponse.spouseRefundAmount),
                  format: 'currency-no-dollar-sign',
                }}
              />
              <DataItem
                colSpan={8}
                layout="vertical"
                label={t.ADMIN_INFO_TR_AMOUNT_OWING}
                value={{ value: getSelfOrZero(taxReturnResponse.spouseAmountOwing), format: 'currency-no-dollar-sign' }}
              />
            </div>
          </div>
        </Col>
      </Row>
      <ProcessStatusSelection
        label={t.ADMIN_INFO_TAX_RETURN_STATUS}
        changedFormProtection
        disableAllFields={disableAllFields}
        status={taxReturnProcessStatus}
        allItemsCompleted={allTaxPeriodsCompleted}
        apiSubmit={(values) => {
          return API.taxReturnPUT(fileId, {
            taxReturnProcessStatus: values.processStatus,
          } as ClientService.AdminInfoTaxReturnUpdateDto);
        }}
        onSubmitResponseSuccess={() => eventBus.dispatch(ADMIN_INFO_CHANGE_EVENT)}
      />
      <Row gutter={20}>
        <Col span={24}>
          <ExpandableRecordsDisplay
            recordFields={taxReturnRecordFields}
            apiGetMasterRecords={() => {
              return API.taxReturnPeriod2(
                ...argumentifyTaxReturnPeriodSearchCriteria({ fileId: fileId, maxResultCount: 1000 })
              );
            }}
            apiSetMasterRecords={(response, setMasterRecords) => {
              const taxStatusStrings = getEnumTextValuesAsArray(ClientService.TaxStatusEnum);
              const masterRecords = response.map((record: any) => {
                return {
                  id: record.id,
                  leadValue: record.taxPeriod,
                  source: record.sourceType,
                  estimatedAmount: record.amountEstimated,
                  deposited: record.amountDeposited,
                  taxStatus: taxStatusStrings[record.taxStatus],
                  ascendStatus: record.ascendStatus,
                  disposition: record.dispositionType,
                  complete: record.isComplete ? t.YES : t.NO,
                };
              });
              setMasterRecords(masterRecords);
            }}
            tableColumns={ClaimFilesTableCols}
            apiGetTableData={(taxPeriodId) => {
              return API.taxReturnSubmissionAll(taxPeriodId as string);
            }}
            apiSetTableData={(response, setTableData) => {
              const taxSubmissionStatusStrings = getEnumTextValuesAsArray(ClientService.TaxReturnSubmissionStatusEnum);
              const taxReturnSubmissions = response.map(
                (submission: ClientService.TaxReturnSubmissionListItemDto, index: number) => {
                  return {
                    id: submission.id,
                    received:
                      submission.receivedDate == null
                        ? undefined
                        : moment(submission.receivedDate).format(DATE_AND_TIME_FORMAT),
                    assignedTo: submission.assignedTo,
                    status: submission.result == null ? undefined : taxSubmissionStatusStrings[submission.result],
                    discrepancy: submission.hasDiscrepancy ? t.YES : t.NO,
                    state: getTaxReturnSubmissionStateValueAsElement(submission.state),
                  };
                }
              );
              setTableData(taxReturnSubmissions);
            }}
            viewTableRecordRoute={`${ROUTES.APPLICATION_OVERVIEW}/${fileId}/${ROUTES.TAX_RETURN}`}
          />
        </Col>
      </Row>
    </>
  );
};

export default TaxReturn;
