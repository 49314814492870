import { Modal } from 'antd';
import useLocale from '../../hooks/useLocale';
import Button from '../../components/Button/Button';

import './GenericErrorModal.scss';

interface IProps {
  onCancel: () => void;
  message: string;
  title?: string;
}

const GenericErrorModal = ({ onCancel, message, title }: IProps): JSX.Element => {
  const { t } = useLocale();

  return (
    <Modal
      destroyOnClose
      centered
      title={title || t.ERROR}
      visible
      closable={true}
      onCancel={onCancel}
      footer={[
        <Button key="back" kind="cancel" onClick={onCancel}>
          {t.CANCEL}
        </Button>,
      ]}
      className="GenericErrorModal"
    >
      <div>{message}</div>
    </Modal>
  );
};

export default GenericErrorModal;
