import ButtonContainer from '../../../../../components/ButtonContainer/ButtonContainer';
import Button from '../../../../../components/Button/Button';
import useLocale from '../../../../../hooks/useLocale';

interface IProps {
  buttonsEnabled?: boolean;
  onEdit?: () => void;
}

const ButtonsGroupEdit = ({ buttonsEnabled = true, onEdit }: IProps): JSX.Element => {
  const { t } = useLocale();

  return (
    <ButtonContainer inline>
      <Button disabled={!buttonsEnabled} narrow padding="large" onClick={onEdit}>
        {t.BUTTON_EDIT}
      </Button>
    </ButtonContainer>
  );
};

export default ButtonsGroupEdit;
