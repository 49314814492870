import React, { useState, useEffect, useCallback } from 'react';
import { Divider, Row, Col, Spin } from 'antd';
import { useParams } from 'react-router-dom';
import moment from 'moment';
import Layout from '../../components/Layout/Layout';
import PageTitle from '../../components/PageTitle/PageTitle';
import NewAndViewHeader from '../ApplicationOverviewPage/NewAndViewPageComponents/NewAndViewHeader/NewAndViewHeader';
import { ROUTES } from '../../constants/routes';
import useLocale from '../../hooks/useLocale';
import AppForm from '../../components/Forms/AppForm/AppForm';
import EmailContentDisplay, {
  TEmailData,
} from '../ApplicationOverviewPage/NewAndViewPageComponents/EmailContentDisplay/EmailContentDisplay';
import FormInput from '../../components/Forms/FormInput/FormInput';
import DataItem from '../../components/DataItem/DataItem';
import DynamicInfoBlock, { TDataItem } from '../../components/DynamicInfoBlock/DynamicInfoBlock';
import ThreeColumnLowerLayout from '../ApplicationOverviewPage/NewAndViewPageComponents/ThreeColumnLowerLayout/ThreeColumnLowerLayout';
import LineItemsDisplay, {
  LineItemGroup,
} from '../ApplicationOverviewPage/NewAndViewPageComponents/LineItemsDisplay/LineItemsDisplay';
import Loading from '../../components/Loading/Loading';
import API from '../../utils/api';
import { ClientService } from '../../shared/api/ClientService';
import { DATE_FORMAT2 } from '../../constants/common';
import {
  getEnumTextValuesAsArray,
  getFormValueOrFetchedValueOrUndefined,
  getUndefinedOrBoolean,
  getUndefinedOrSelf,
  convertServerDateOnlyToInputDate,
  splitOnCapital,
} from '../../utils/helpers';
import useModal from '../../hooks/useModal';
import IncomeExpenseIncome from '../ApplicationOverviewPage/ApplicationOverviewContent/ApplicationForm/IncomeExpenseIncome';
import IncomeExpenseExpense from '../ApplicationOverviewPage/ApplicationOverviewContent/ApplicationForm/IncomeExpenseExpense';
import ButtonsGroupCancelDraftComplete from '../ApplicationOverviewPage/NewAndViewPageComponents/NewAndViewHeader/HeaderButtonGroups/ButtonsGroupCancelDraftComplete';
import useAlertAsAsync from '../ApplicationOverviewPage/NewAndViewPageComponents/AlertModal/useAlertAsAsync';
import useErrorHandling from '../../hooks/useErrorHandling';
import ErrorsContainer, { IErrorsMsgAndType } from '../../components/ErrorsContainer/ErrorsContainer';
import ActionConfirmationModal from '../../modals/ActionConfirmationModal/ActionConfirmationModal';
import genericMessage from '../../utils/genericMessage';
import { debounce } from '../../utils/debounce';
// import { saveTestPromise } from '../../utils/APITestFunctions';

import styles from './ViewIncomeAndExpensePage.module.scss';

interface IViewIncomeExpenseForm {
  result?: number;
  missingInformation?: string;
  discrepancy?: string;
  amendmentRequired?: string;
  commentsForClientPortal?: string;
  superintendentStandardType?: string;
  superintendentStandardAmount?: string;
  estimatedSurplusPayment?: string;
}

const YES = 'yes',
  NO = 'no';

const STANDARD = 'standard',
  CUSTOM = 'custom';

const getSubmitValuesFromForm = (
  submissionGetResponse: ClientService.IncomeExpenseSubmissionDetailsDto,
  incomeExpenseFetchedInfo: ClientService.IncomeExpenseInfoDto,
  formValues: IViewIncomeExpenseForm
): ClientService.IIncomeExpenseSubmissionUpdateDto => {
  return {
    state: submissionGetResponse.state,
    result: getUndefinedOrSelf(formValues.result),
    hasMissingInformation: getUndefinedOrBoolean(formValues.missingInformation),
    hasDiscrepancy: getFormValueOrFetchedValueOrUndefined(
      formValues.discrepancy,
      submissionGetResponse.hasDiscrepancy,
      YES,
      NO
    ),
    isAmendmentRequired: getFormValueOrFetchedValueOrUndefined(
      formValues.amendmentRequired,
      submissionGetResponse.isAmendmentRequired,
      YES,
      NO
    ),
    commentsForClientPortal: getFormValueOrFetchedValueOrUndefined(
      formValues.commentsForClientPortal,
      submissionGetResponse.comments
    ),
    isMarkedAsComplete: false,
    superintendentStandard: getFormValueOrFetchedValueOrUndefined(
      Number(formValues.superintendentStandardAmount),
      submissionGetResponse.superintendentStandard
    ),
    superintendentType:
      getUndefinedOrSelf(formValues.superintendentStandardType) === STANDARD
        ? ClientService.SuperintendentTypeEnum.Standard
        : ClientService.SuperintendentTypeEnum.Custom,
    bankruptIncome: getUndefinedOrSelf(incomeExpenseFetchedInfo.bankruptIncome),
    spouseIncome: getUndefinedOrSelf(incomeExpenseFetchedInfo.spouseIncome),
    otherIncome: getUndefinedOrSelf(incomeExpenseFetchedInfo.otherIncome),
    nonDiscretionaryExpenses: getUndefinedOrSelf(incomeExpenseFetchedInfo.nonDiscretionaryExpenses),
    availableFamilyIncome: getUndefinedOrSelf(incomeExpenseFetchedInfo.availableFamilyIncome),
    totalMonthlySurplus: getUndefinedOrSelf(incomeExpenseFetchedInfo.totalMonthlySurplus),
    bankruptPortion: getUndefinedOrSelf(incomeExpenseFetchedInfo.bankruptPortion),
    percentageOfSurplus: getUndefinedOrSelf(incomeExpenseFetchedInfo.percentageOfSurplus),
    bankruptPortionOfSurplus: getUndefinedOrSelf(incomeExpenseFetchedInfo.bankruptPortionOfSurplus),
    bankruptNetSurplus: getUndefinedOrSelf(incomeExpenseFetchedInfo.bankruptNetSurplus),
    calculatedSurplusPayment: getUndefinedOrSelf(incomeExpenseFetchedInfo.calculatedSurplusPayment),
    estimatedSurplusPayment: getUndefinedOrSelf(Number(formValues.estimatedSurplusPayment)),
    isIgnoreAndComplete: false,
  };
};

const convertIncomeItemsForLineItemsDisplay = (incomeItems: { [key: string]: number }) => {
  const incomeItemKeys = Object.keys(incomeItems);
  return incomeItemKeys.map((itemKey) => {
    return {
      label: itemKey,
      amount: incomeItems[itemKey],
    };
  });
};

const convertExpenseItemsForLineItemsDisplay = (expenseItems: { [key: string]: { [key: string]: number } }) => {
  const expenseCategoriesWithLineItems: LineItemGroup[] = [];
  const expenseCategoryKeys = Object.keys(expenseItems);
  expenseCategoryKeys.forEach((expenseCategoryKey) => {
    const expenseCategoryObject: LineItemGroup = {};
    expenseCategoryObject.group = expenseCategoryKey;
    const category = expenseItems[expenseCategoryKey];
    const expenseCategoryLineItemKeys = Object.keys(category);
    const expenseCategoryLineItems = expenseCategoryLineItemKeys.map((itemKey) => {
      return {
        label: itemKey,
        amount: category[itemKey],
      };
    });
    expenseCategoryObject.lineItems = expenseCategoryLineItems;
    expenseCategoriesWithLineItems.push(expenseCategoryObject);
  });
  return expenseCategoriesWithLineItems;
};
const normalizeInputToOnlyIntegersAndLength = (value: string, maxlenght = 25) => {
  // not allowing alphabets and restricting the size to 25 characters
  const transformedvalue = value ? value.replace(/[^0-9.-]/g, '').slice(0, maxlenght) : value;
  return transformedvalue;
};

/**
 * Rendering "View Income & Expense" page
 * @returns
 */
const ViewIncomeAndExpensePage = () => {
  const { applicationFileId, submissionId } = useParams();

  const { t } = useLocale();
  const { handleAlert } = useAlertAsAsync();
  const [form] = AppForm.AntD.useForm<IViewIncomeExpenseForm>();
  const { processResponseForErrors } = useErrorHandling();
  const { showModal, closeModal } = useModal();

  const [submissionResponseFailed, setSubmissionResponseFailed] = useState<boolean>(false);
  const [submissionInfo, setSubmissionInfo] = useState<ClientService.IncomeExpenseSubmissionDetailsDto | undefined>();
  const [submissionStatus, setSubmissionStatus] = useState<string | undefined>();
  const [infoBlockItems, setInfoBlockItems] = useState<TDataItem[] | undefined>();

  const [leftColumnResponseFailed, setLeftColumnResponseFailed] = useState<boolean>(false);
  const [leftColumnInfo, setLeftColumnInfo] = useState<any | undefined>();
  const [documentIds, setDocumentIds] = useState<string[] | undefined>();
  const [emailInfo, setEmailInfo] = useState<TEmailData | undefined>();

  const [isMissingInfo, setIsMissingInfo] = useState<boolean>(false);
  const [isDiscrepancy, setIsDiscrepancy] = useState<boolean>(false);
  const [superintendentStandardIsStandard, setSuperintendentStandardIsStandard] = useState<boolean | undefined>();
  const [superintendentStandardInitialValue, setSuperintendentStandardInitialValue] = useState<number | undefined>();
  const [superintendentStandardDefaultValue, setSuperintendentStandardDefaultValue] = useState<number | undefined>();

  const [initialFormValuesHaveBeenSet, setInitialFormValuesHaveBeenSet] = useState<boolean>(false);
  const [formIsLoading, setFormIsLoading] = useState<boolean>(true);
  const [isFormInactive, setIsFormInactive] = useState<boolean>(false);

  const [incomeLineItems, setIncomeLineItems] = useState<LineItemGroup[] | undefined>();
  const [expenseLineItems, setExpenseLineItems] = useState<LineItemGroup[] | undefined>();

  const [infoBlockIsLoading, setInfoBlockIsLoading] = useState<boolean>(true);
  const [threeColLayoutIsLoading, setThreeColLayoutIsLoading] = useState<boolean>(true);

  const [incomeTypes, setIncomeTypes] = useState<ClientService.LookupDto[]>([]);
  const [expenseTypes, setExpenseTypes] = useState<ClientService.LookupDto[]>([]);

  const [saveIncomeLoading, setSaveIncomeLoading] = useState<boolean>(false);
  const [saveExpensesLoading, setSaveExpensesLoading] = useState<boolean>(false);

  const [pageErrors, setPageErrors] = useState<IErrorsMsgAndType[] | undefined>();
  const [headerErrors, setHeaderErrors] = useState<IErrorsMsgAndType[] | undefined>();
  const [threeColumnLayoutErrors, setThreeColumnLayoutErrors] = useState<IErrorsMsgAndType[] | undefined>();

  //
  // Work for show/hide processing spinner in the UI when start/end server side call.
  //
  const [completing, setIsCompleting] = useState<boolean>(false);

  useEffect(() => {
    API.listIncomeTypes().then((response) => {
      // console.log('incomeTypes:', response);
      setIncomeTypes(response);
    });

    API.listExpenseTypes().then((response) => {
      // console.log('Expense Types:', response);
      setExpenseTypes(response);
    });
  }, []);

  /**
   * Convert input infor data to list of items with label and value properties.
   * Work for rendering data in "View Income & Expense" section.
   */
  const getInfoBlockItems = useCallback(
    (info: { [key: string]: any }): TDataItem[] => {
      return [
        { label: t.ASCEND_ESTATE_NUMBER, value: info.estateNumber },
        { label: t.ASCEND_SIN, value: info.sin },
        { label: t.ASCEND_DEBTOR_NAME, value: info.debtorName },
        {
          label: t.ASCEND_DATE_OF_INSOLVENCY,
          value: info?.dateOfInsolvency
            ? convertServerDateOnlyToInputDate(moment(info?.dateOfInsolvency))?.format(DATE_FORMAT2)
            : '-',
        },
        { label: t.ASCEND_NUMBER_OF_HOUSEHOLD, value: info.numberOfHousehold },
        { label: t.ASCEND_SELECT_INCOME_AND_EXPENSE, value: info.selectIE },
        { label: t.INFO_FILENAME, value: info.fileName },
        { label: t.INFO_DESCRIPTION, value: getUndefinedOrSelf(info.description) },
      ];
    },
    [
      t.ASCEND_DATE_OF_INSOLVENCY,
      t.ASCEND_DEBTOR_NAME,
      t.ASCEND_ESTATE_NUMBER,
      t.ASCEND_NUMBER_OF_HOUSEHOLD,
      t.ASCEND_SELECT_INCOME_AND_EXPENSE,
      t.ASCEND_SIN,
      t.INFO_DESCRIPTION,
      t.INFO_FILENAME,
    ]
  );

  const getAndSetIncomeSummary = useCallback(() => {
    if (!applicationFileId || !submissionId) return;

    API.incomeSummary(applicationFileId, submissionId)
      .then((response) => {
        // console.log('incomeSummary response:', response);
        const lineItems = convertIncomeItemsForLineItemsDisplay(response);
        // console.log('income line items:', lineItems);
        setIncomeLineItems([
          {
            lineItems: lineItems,
          },
        ]);
      })
      .catch((error) => {
        console.error('Failed to fetch Income Summary');
        // console.error(error);
      });
  }, [applicationFileId, submissionId]);

  useEffect(() => {
    if (incomeLineItems) return;
    getAndSetIncomeSummary();
  }, [incomeLineItems, getAndSetIncomeSummary]);

  const getAndSetExpenseSummary = useCallback(() => {
    if (!applicationFileId || !submissionId) return;

    API.expenseSummary(applicationFileId, submissionId)
      .then((response) => {
        // console.log('expenseSummary response:', response);
        const expenseCategoriesWithLineItems = convertExpenseItemsForLineItemsDisplay(response);
        // console.log('expense line items:', expenseCategoriesWithLineItems);
        setExpenseLineItems(expenseCategoriesWithLineItems);
      })
      .catch((error) => {
        console.error('Failed to fetch Expenses Summary');
        // console.error(error);
      });
  }, [applicationFileId, submissionId]);

  /**
   * Call web api "/api/client-service/admin-info/income-expense/{id}/submissions/{submissionId}"
   * to get data from _adminInfoAppService.GetNewIncomeExpense method,
   * table dbo.[AppTaskAction] in Pds_Client database.
   */
  const getSubmissionInfo = useCallback(
    (applicationFileId: string, submissionId: string) => {
      API.submissionsGET(applicationFileId, submissionId)
        .then((response) => {
          // console.log('Submission response object:', response);
          // console.log('submission state:', ClientService.IncomeExpenseSubmissionStateEnum[response.state as number]);
          const responseErrors = processResponseForErrors(response);
          setPageErrors(responseErrors.messages);
          if (responseErrors.hasErrors) {
            setSubmissionResponseFailed(true);
            setFormIsLoading(false);
            setInfoBlockIsLoading(false);
            setThreeColLayoutIsLoading(false);
          }
          setSubmissionInfo(response);
        })
        .catch((error) => {
          setPageErrors([{ message: t.DATA_COULD_NOT_BE_RETRIEVED }]);
          setSubmissionResponseFailed(true);
          setFormIsLoading(false);
          setInfoBlockIsLoading(false);
          setThreeColLayoutIsLoading(false);
          console.error('Failed to get primary page data');
        });
    },
    [processResponseForErrors, t.DATA_COULD_NOT_BE_RETRIEVED]
  );

  useEffect(() => {
    if (expenseLineItems) return;
    getAndSetExpenseSummary();
  }, [expenseLineItems, getAndSetExpenseSummary]);

  useEffect(() => {
    if (submissionInfo || !applicationFileId || !submissionId) return;
    getSubmissionInfo(applicationFileId, submissionId);

    // Get submission info
  }, [applicationFileId, submissionId, submissionInfo, getSubmissionInfo]);

  useEffect(() => {
    if (!submissionInfo) return;

    if (submissionInfo.info) {
      const infoBlockData = submissionInfo.info;
      setInfoBlockItems(getInfoBlockItems(infoBlockData));
      setInfoBlockIsLoading(false);
    } else {
      setInfoBlockIsLoading(false);
    }

    if (submissionInfo.state != null) {
      setSubmissionStatus(ClientService.IncomeExpenseSubmissionStateEnum[submissionInfo.state]);
      setIsFormInactive(submissionInfo.state === ClientService.IncomeExpenseSubmissionStateEnum.Inactive);
    }

    setEmailInfo({
      fromName: submissionInfo.importedEmailSenderName,
      fromAddress: submissionInfo.importedEmailSenderEmailAddress,
      toName: submissionInfo.importedEmailRecipientName,
      toAddress: submissionInfo.importedEmailRecipientEmailAddress,
      subjectField: submissionInfo.importedEmailSubject,
      bodyField: submissionInfo.importedEmailBody,
    });

    setDocumentIds(submissionInfo.documentIds);
    //setSuperintendentStandardInitialValue(getUndefinedOrSelf(submissionInfo.superintendentStandard));
  }, [getInfoBlockItems, submissionInfo]);

  const getAndSetReadOnlyIncomeAndExpenseValues = useCallback(
    (superintendentStandard?: number) => {
      if (!applicationFileId || !submissionId) return;

      API.incomeExpenseInfo(applicationFileId, submissionId, superintendentStandard)
        .then((response) => {
          const responseErrors = processResponseForErrors(response);
          setPageErrors(responseErrors.messages);
          if (responseErrors.hasErrors) {
            setLeftColumnResponseFailed(true);
            // setFormIsLoading(false);
          }
          setLeftColumnInfo(response);

          if (superintendentStandard === undefined) {
            setSuperintendentStandardDefaultValue(response.superintendentStandard);
            setSuperintendentStandardIsStandard(response.isStandard);
          }

          setSuperintendentStandardInitialValue(getUndefinedOrSelf(response.superintendentStandard));
        })
        .catch((error) => {
          console.error('Failed to get read-only Income and Expense values');
          setPageErrors([{ message: t.SOME_DATA_COULD_NOT_BE_RETRIEVED }]);
          setLeftColumnResponseFailed(true);
          // setFormIsLoading(false);
        });
    },
    [applicationFileId, submissionId, processResponseForErrors, t.SOME_DATA_COULD_NOT_BE_RETRIEVED]
  );

  useEffect(() => {
    // Get left column values
    if (leftColumnInfo) return;

    getAndSetReadOnlyIncomeAndExpenseValues(superintendentStandardInitialValue);
  }, [
    applicationFileId,
    submissionId,
    getAndSetReadOnlyIncomeAndExpenseValues,
    leftColumnInfo,
    processResponseForErrors,
    superintendentStandardInitialValue,
    t.SOME_DATA_COULD_NOT_BE_RETRIEVED,
  ]);

  useEffect(() => {
    if (expenseLineItems) return;
    getAndSetExpenseSummary();
  }, [expenseLineItems, getAndSetExpenseSummary]);

  useEffect(() => {
    // console.log('superintendentStandardIsStandard:', superintendentStandardIsStandard);
    // console.log('leftColumnInfo:', leftColumnInfo);
    if (initialFormValuesHaveBeenSet || !submissionInfo) {
      return;
    }

    // Set intial form values
    form.setFieldsValue({
      result: submissionInfo.result,
      missingInformation: submissionInfo.hasMissingInformation ? YES : NO,
      discrepancy: submissionInfo.hasDiscrepancy ? YES : NO,
      amendmentRequired: submissionInfo.isAmendmentRequired ? YES : NO,
      commentsForClientPortal: getUndefinedOrSelf(submissionInfo.comments),
      superintendentStandardType: superintendentStandardIsStandard ? STANDARD : CUSTOM,
      superintendentStandardAmount: getUndefinedOrSelf(
        String((submissionInfo.superintendentStandard ?? superintendentStandardInitialValue)?.toFixed(2))
      ),
      estimatedSurplusPayment: String(submissionInfo.estimatedSurplusPayment?.toFixed(2) ?? ''),
    });
    setInitialFormValuesHaveBeenSet(true);
    setFormIsLoading(false);
  }, [
    form,
    initialFormValuesHaveBeenSet,
    submissionInfo,
    superintendentStandardIsStandard,
    superintendentStandardInitialValue,
  ]);

  useEffect(() => {
    if (submissionResponseFailed || leftColumnResponseFailed) {
      setThreeColLayoutIsLoading(false);
    }

    if (
      !submissionInfo ||
      !emailInfo ||
      !documentIds ||
      !leftColumnInfo ||
      !incomeLineItems ||
      !expenseLineItems ||
      !initialFormValuesHaveBeenSet
    ) {
      return;
    }

    setThreeColLayoutIsLoading(false);
  }, [
    documentIds,
    emailInfo,
    expenseLineItems,
    incomeLineItems,
    initialFormValuesHaveBeenSet,
    leftColumnInfo,
    leftColumnResponseFailed,
    submissionInfo,
    submissionResponseFailed,
  ]);

  const handleIncomeSave = useCallback(
    async (body: ClientService.AppFormIncomeExpenseIncomeDto, fileId: string, submissionId: string) => {
      setThreeColumnLayoutErrors(undefined);
      setSaveIncomeLoading(true);

      if (body.incomeDetails == null) return;

      let request: ClientService.IncomeSubmissionItemUpsertDto[] = [];

      if (body.incomeDetails.length) {
        const details = body.incomeDetails as ClientService.AppFormIncomeExpenseIncomeDetailDto[];
        request = details.map((incomeItem) => {
          const incomeTypeDetails = incomeTypes.find((type) => {
            return type.id === incomeItem.incomeTypeId;
          });
          return new ClientService.IncomeSubmissionItemUpsertDto({
            incomeType: incomeTypeDetails?.enumValue,
            description: incomeItem.description,
            amountApplicant: incomeItem.applicantAmount,
            amountSpouse: incomeItem.spouseAmount,
            amountOther: incomeItem.otherHouseholdMemberAmount,
          });
        });
      }

      // console.log('SAVE INCOME ITEMS REQUEST:', request);

      try {
        const response = await API.incomeItemsPOST(fileId, submissionId, request);
        // const response = await saveTestPromise(body.incomeDetails);
        const superintendentStandard = form.getFieldValue('superintendentStandardAmount');
        setSaveIncomeLoading(false);
        if (response) {
          getAndSetIncomeSummary();
          getAndSetReadOnlyIncomeAndExpenseValues(Number(superintendentStandard));
        }
      } catch (error) {
        setSaveIncomeLoading(false);
        setThreeColumnLayoutErrors([{ message: t.ERROR__FAILED_TO_SAVE_INCOME_ENTRIES }]);
      }
      closeModal();
    },
    [
      closeModal,
      form,
      getAndSetIncomeSummary,
      getAndSetReadOnlyIncomeAndExpenseValues,
      incomeTypes,
      t.ERROR__FAILED_TO_SAVE_INCOME_ENTRIES,
    ]
  );

  const handleOpenEditIncome = useCallback(async () => {
    if (!applicationFileId || !submissionId) return;

    const data = await API.incomeItemsGET(applicationFileId, submissionId);
    // console.log('income items data:', data);

    if (data) {
      showModal(
        <IncomeExpenseIncome
          fileId={applicationFileId}
          data={data}
          incomeTypes={incomeTypes}
          hasDocumentsTab={false}
          loading={saveIncomeLoading}
          onSave={(body) => handleIncomeSave(body, applicationFileId, submissionId)}
          onCancel={closeModal}
        />
      );
    }
  }, [applicationFileId, closeModal, submissionId, handleIncomeSave, incomeTypes, saveIncomeLoading, showModal]);

  const handleExpensesSave = useCallback(
    async (body: ClientService.AppFormIncomeExpenseExpenseDto, fileId: string, submissionId: string) => {
      setThreeColumnLayoutErrors(undefined);
      setSaveExpensesLoading(true);

      if (body.expenseDetails == null) return;

      let request: ClientService.ExpenseSubmissionItemUpsertDto[] = [];

      if (body.expenseDetails.length) {
        const details = body.expenseDetails as ClientService.AppFormIncomeExpenseExpenseDetailDto[];
        request = details.map((expenseItem) => {
          const expenseTypeDetails = expenseTypes.find((type) => {
            return type.id === expenseItem.expenseTypeId;
          });
          return new ClientService.ExpenseSubmissionItemUpsertDto({
            expenseType: expenseTypeDetails?.enumValue,
            expenseDescriptionId: expenseItem.expenseDescriptionId,
            amountApplicant: expenseItem.applicantAmount,
            amountSpouse: expenseItem.spouseAmount,
            amountOther: expenseItem.otherHouseholdMemberAmount,
            otherDescription: expenseItem?.specifiedDescription,
          });
        });
      }

      try {
        // console.log('body.expenseDetails from Expenses Modal:', body.expenseDetails);
        // console.log('request sent to save expenses:', request);
        const response = await API.expenseItemsPOST(fileId, submissionId, request);
        const superintendentStandard = form.getFieldValue('superintendentStandardAmount');
        setSaveExpensesLoading(false);
        if (response) {
          getAndSetExpenseSummary();
          getAndSetReadOnlyIncomeAndExpenseValues(Number(superintendentStandard));
        }
      } catch (error) {
        setSaveExpensesLoading(false);
        setThreeColumnLayoutErrors([{ message: t.ERROR__FAILED_TO_SAVE_EXPENSE_ENTRIES }]);
      }
      closeModal();
    },
    [
      closeModal,
      expenseTypes,
      form,
      getAndSetExpenseSummary,
      getAndSetReadOnlyIncomeAndExpenseValues,
      t.ERROR__FAILED_TO_SAVE_EXPENSE_ENTRIES,
    ]
  );

  const openEditExpense = useCallback(async () => {
    if (!applicationFileId || !submissionId) return;

    const data = await API.expenseItemsGET(applicationFileId, submissionId);

    if (data) {
      showModal(
        <IncomeExpenseExpense
          fileId={applicationFileId}
          data={data}
          expenseTypes={expenseTypes}
          hasDocumentsTab={false}
          loading={saveExpensesLoading}
          onSave={(body) => handleExpensesSave(body, applicationFileId, submissionId)}
          onCancel={closeModal}
        />
      );
    }
  }, [applicationFileId, closeModal, submissionId, expenseTypes, handleExpensesSave, saveExpensesLoading, showModal]);

  const renderEmailDisplay = useCallback(() => {
    if (!emailInfo) return null;

    let emailHasSomeValue = false;
    const emailKeys = Object.keys(emailInfo);
    emailKeys.forEach((key: string) => {
      if ((emailInfo as any)[key] != null) emailHasSomeValue = true;
    });

    if (!emailHasSomeValue) return null;

    return <EmailContentDisplay title={t.INCOMEEXPENSE_INCOME_EXPENSE_EMAIL} {...emailInfo} />;
  }, [emailInfo, t.INCOMEEXPENSE_INCOME_EXPENSE_EMAIL]);

  const handleIgnoreAndComplete = useCallback(
    (message: string): Promise<boolean> => {
      return new Promise((resolve) => {
        showModal(
          <ActionConfirmationModal
            title={`${t.VIEW_INCOMEEXPENSE_IGNORE_AND_COMPLETE}?`}
            message={message}
            okText={t.VIEW_INCOMEEXPENSE_IGNORE_AND_COMPLETE}
            onOk={() => {
              closeModal();
              resolve(true);
            }}
            onCancel={() => {
              closeModal();
              resolve(false);
            }}
          />
        );
      });
    },
    [closeModal, showModal, t.VIEW_INCOMEEXPENSE_IGNORE_AND_COMPLETE]
  );

  /** Called when click "Complete" button on the action buttons (Buttons rendered in ButtonsGroupCancelDraftComplete.tsx).*/
  const handleSave = useCallback(
    async (isComplete = false, withAlert = false) => {
      if (!applicationFileId || !submissionInfo || !submissionId) return;
      setHeaderErrors(undefined);

      let requestIsValid = true;
      const formValues = form.getFieldsValue();
      if (isComplete) {
        try {
          await form.validateFields();
        } catch (error) {
          requestIsValid = false;
        }
      }

      if (!requestIsValid) {
        setHeaderErrors([{ message: t.ERROR__REQUIRED_FIELDS_MISSING }]);
        return;
      }

      let requestBody: ClientService.IIncomeExpenseSubmissionUpdateDto = getSubmitValuesFromForm(
        submissionInfo,
        leftColumnInfo,
        formValues
      );

      if (isComplete) {
        requestBody = {
          ...requestBody,
          isMarkedAsComplete: true,
        };
      }

      const request = new ClientService.IncomeExpenseSubmissionUpdateDto(requestBody);

      if (withAlert) {
        const alertBody = await handleAlert();
        if (!alertBody) return;
        const typedAlertBody = alertBody as ClientService.IAlertCreateDto;
        const alertRequest = new ClientService.AlertCreateDto(typedAlertBody);
        request.alerts = alertRequest;
      }

      try {
        setIsCompleting(true);
        const saveResponse = await API.submissionsPUT(applicationFileId, submissionId, request).catch(() =>
          setIsCompleting(false)
        );
        setIsCompleting(false);

        if (saveResponse) {
          const responseErrors = processResponseForErrors(saveResponse);
          setHeaderErrors(responseErrors.messages);
          if (saveResponse.hasErrors) return;

          if (!isComplete || (isComplete && !saveResponse?.hasConfirm)) {
            genericMessage.success(t.SUCCESSFULLY_SAVED);
            //
            // Refresh submission data from server side after save successfully completed.
            //
            getSubmissionInfo(applicationFileId, submissionId);
            return;
          }

          if (saveResponse?.hasConfirm) {
            const ignorePromptMessage =
              saveResponse?.messages?.find((item) => item?.messageType === ClientService.ResultMessageType.Confirm)
                ?.body || t.VIEW_INCOMEEXPENSE_IGNORE_AND_COMPLETE_MSG;

            const ignoreAndComplete = await handleIgnoreAndComplete(ignorePromptMessage);
            if (!ignoreAndComplete) return;

            request.isIgnoreAndComplete = true;

            setIsCompleting(true);
            const reSaveResponse = await API.submissionsPUT(applicationFileId, submissionId, request).catch(() =>
              setIsCompleting(false)
            );
            setIsCompleting(false);

            if (reSaveResponse) {
              const reResponseErrors = processResponseForErrors(reSaveResponse);
              setHeaderErrors(reResponseErrors.messages);
              if (reSaveResponse.hasErrors) return;
              genericMessage.success(t.SUCCESSFULLY_SAVED);
              getSubmissionInfo(applicationFileId, submissionId);
            }
          }
        }
      } catch (error) {
        setHeaderErrors([{ message: t.ERROR__TRYING_TO_SAVE }]);
        return;
      }
    },
    [
      applicationFileId,
      submissionInfo,
      submissionId,
      form,
      leftColumnInfo,
      t.ERROR__REQUIRED_FIELDS_MISSING,
      t.VIEW_INCOMEEXPENSE_IGNORE_AND_COMPLETE_MSG,
      t.SUCCESSFULLY_SAVED,
      t.ERROR__TRYING_TO_SAVE,
      handleAlert,
      processResponseForErrors,
      handleIgnoreAndComplete,
      getSubmissionInfo,
    ]
  );

  const debouncedUpdateReadOnlyValues = debounce((superIntendentValue) => {
    getAndSetReadOnlyIncomeAndExpenseValues(superIntendentValue);
  }, 300);

  const handleSuperintendentValueChange = useCallback(
    (event: React.FormEvent<HTMLInputElement>) => {
      debouncedUpdateReadOnlyValues(event.currentTarget.value);
    },
    [debouncedUpdateReadOnlyValues]
  );

  return (
    <Layout page={ROUTES.INCOME_AND_EXPENSE}>
      <Spin spinning={completing}>
        <div className={styles.ViewIncomeAndExpensePage}>
          {pageErrors && <ErrorsContainer errors={pageErrors} noTitle />}
          <PageTitle title={t.INCOMEEXPENSE_INCOME_EXPENSE_PROCESS} />
          <NewAndViewHeader
            errors={headerErrors}
            fileId={applicationFileId}
            subtitle="view-income-and-expense"
            status={submissionStatus}
            breadcrumbData={[
              { link: ROUTES.DASHBOARD, title: t.DASHBOARD },
              { link: `${ROUTES.APPLICATION_OVERVIEW}/${applicationFileId}`, title: t.APPLICATION_OVERVIEW },
              { title: t.NEWVIEW_SUBTITLE_VIEW_INCOME_AND_EXPENSE },
            ]}
            buttons={
              !isFormInactive && (
                <ButtonsGroupCancelDraftComplete
                  buttonsEnabled={initialFormValuesHaveBeenSet && !isFormInactive}
                  onSaveAsDraft={() => handleSave()}
                  onSaveAsDraftWithAlert={() => handleSave(false, true)}
                  onComplete={() => handleSave(true)}
                  onCompleteWithAlert={() => handleSave(true, true)}
                />
              )
            }
          />
          <AppForm form={form} isLoading={formIsLoading} disableAllFields={isFormInactive}>
            <Row gutter={20}>
              <Col span={24}>
                <DynamicInfoBlock
                  externalDataIsLoading={infoBlockIsLoading}
                  loadError={submissionResponseFailed}
                  itemsPerRow={3}
                  data={infoBlockItems}
                />
              </Col>
            </Row>
            <Row gutter={20}>
              <Col span={6}>
                <FormInput
                  type="select"
                  label={t.VIEW_INCOMEEXPENSE_RESULT}
                  name="result"
                  required
                  optionsList={(() => {
                    const resultOptions = getEnumTextValuesAsArray(ClientService.IncomeExpenseSubmissionStatusEnum);
                    return resultOptions.map((option, index) => {
                      return { label: splitOnCapital(option), value: index };
                    });
                  })()}
                  disabled={isFormInactive}
                />
              </Col>
              <Col span={6}>
                <FormInput
                  type="select"
                  label={t.VIEW_INCOMEEXPENSE_MISSING_INFO}
                  name="missingInformation"
                  noSelectOption
                  required
                  optionsList={[
                    { label: t.NO, value: NO },
                    { label: t.YES, value: YES },
                  ]}
                  onChange={(value: string) => {
                    if (value === YES) {
                      setIsMissingInfo(true);
                    } else {
                      setIsMissingInfo(false);
                      setIsDiscrepancy(false);
                      form.setFieldsValue({
                        discrepancy: NO,
                        amendmentRequired: NO,
                      });
                    }
                  }}
                  disabled={isFormInactive}
                />
              </Col>
              {!isMissingInfo && (
                <Col span={6}>
                  <FormInput
                    type="select"
                    label={t.VIEW_INCOMEEXPENSE_DISCREPANCY}
                    name="discrepancy"
                    noSelectOption
                    required
                    optionsList={[
                      { label: t.NO, value: NO },
                      { label: t.YES, value: YES },
                    ]}
                    onChange={(value: string) => {
                      if (value === YES) {
                        setIsDiscrepancy(true);
                      } else {
                        setIsDiscrepancy(false);
                        form.setFieldsValue({
                          amendmentRequired: NO,
                        });
                      }
                    }}
                    disabled={isFormInactive}
                  />
                </Col>
              )}
              {!isMissingInfo && !isDiscrepancy && (
                <Col span={6}>
                  <FormInput
                    type="select"
                    label={t.VIEW_INCOMEEXPENSE_AMENDMENT_REQUIRED}
                    name="amendmentRequired"
                    noSelectOption
                    required
                    optionsList={[
                      { label: t.NO, value: NO },
                      { label: t.YES, value: YES },
                    ]}
                    disabled={isFormInactive}
                  />
                </Col>
              )}
            </Row>
            <Row gutter={20}>
              <Col span={12}>
                <FormInput
                  label={t.VIEW_INCOMEEXPENSE_COMMENTS_FOR_CLIENT_PORTAL}
                  name="commentsForClientPortal"
                  disabled={isFormInactive}
                />
              </Col>
            </Row>
            {threeColLayoutIsLoading ? (
              <Loading noText />
            ) : !threeColLayoutIsLoading && submissionResponseFailed ? (
              <div style={{ textAlign: 'center' }}>{t.DATA_COULD_NOT_BE_RETRIEVED}</div>
            ) : (
              <ThreeColumnLowerLayout
                errors={threeColumnLayoutErrors}
                leftColumnContent={
                  <>
                    {leftColumnResponseFailed && (
                      <div style={{ textAlign: 'center', paddingBottom: '10px', fontWeight: 'bold' }}>
                        {t.DATA_COULD_NOT_BE_RETRIEVED}
                      </div>
                    )}
                    <FormInput
                      type="select"
                      label={`${t.VIEW_INCOMEEXPENSE_SUPERINTENDENTS_STANDARD} ($)`}
                      name="superintendentStandardType"
                      optionsList={[
                        { label: t.VIEW_INCOMEEXPENSE_STANDARD, value: STANDARD },
                        { label: t.VIEW_INCOMEEXPENSE_CUSTOM, value: CUSTOM },
                      ]}
                      onChange={(value: any) => {
                        if (value === STANDARD) {
                          setSuperintendentStandardIsStandard(true);
                          form.setFieldsValue({
                            superintendentStandardAmount: superintendentStandardDefaultValue?.toString(),
                          });
                        } else if (value === CUSTOM) {
                          setSuperintendentStandardIsStandard(false);
                          form.setFieldsValue({ superintendentStandardAmount: '' });
                        }
                      }}
                    />
                    <FormInput
                      name="superintendentStandardAmount"
                      readOnly={superintendentStandardIsStandard ? true : false}
                      onChange={handleSuperintendentValueChange}
                      normalize={(value) => normalizeInputToOnlyIntegersAndLength(value)}
                      disabled={isFormInactive}
                    />
                    <Divider className={styles.divider} />
                    <DataItem
                      layout="vertical"
                      noCol
                      label={`${t.VIEW_INCOMEEXPENSE_BANKRUPTS_INCOME} ($)`}
                      value={{
                        value: leftColumnInfo?.bankruptIncome || '0',
                        format: 'currency-no-dollar-sign',
                        color: 'green',
                      }}
                    />
                    <DataItem
                      layout="vertical"
                      noCol
                      label={`${t.VIEW_INCOMEEXPENSE_SPOUSES_INCOME} ($)`}
                      value={{
                        value: leftColumnInfo?.spouseIncome || '0',
                        format: 'currency-no-dollar-sign',
                        color: 'green',
                      }}
                    />
                    <DataItem
                      layout="vertical"
                      noCol
                      label={`${t.VIEW_INCOMEEXPENSE_OTHER_INCOME} ($)`}
                      value={{
                        value: leftColumnInfo?.otherIncome || '0',
                        format: 'currency-no-dollar-sign',
                        color: 'green',
                      }}
                    />
                    <DataItem
                      layout="vertical"
                      noCol
                      label={`${t.VIEW_INCOMEEXPENSE_NONDISCRETIONARY_EXPENSES} ($)`}
                      value={{
                        value: leftColumnInfo?.nonDiscretionaryExpenses || '0',
                        format: 'currency-no-dollar-sign',
                        color: 'red',
                      }}
                    />
                    <DataItem
                      layout="vertical"
                      noCol
                      label={`${t.VIEW_INCOMEEXPENSE_AVAILABLE_FAMILY_INCOME} ($)`}
                      value={{
                        value: leftColumnInfo?.availableFamilyIncome || '0',
                        format: 'currency-no-dollar-sign',
                      }}
                    />
                    <Divider className={styles.divider} />
                    <DataItem
                      layout="vertical"
                      noCol
                      label={`${t.VIEW_INCOMEEXPENSE_TOTAL_MONTHLY_SURPLUS} ($)`}
                      value={{
                        value: leftColumnInfo?.totalMonthlySurplus || '0',
                        format: 'currency-no-dollar-sign',
                        bold: true,
                      }}
                    />
                    <DataItem
                      layout="vertical"
                      noCol
                      label={`${t.VIEW_INCOMEEXPENSE_BANKRUPT_PORTION} (%)`}
                      value={{
                        value: leftColumnInfo?.bankruptPortion.toFixed(1) || '-',
                      }}
                    />
                    <DataItem
                      layout="vertical"
                      noCol
                      label={`${t.VIEW_INCOMEEXPENSE_PERCENTAGE_OF_SURPLUS} (%)`}
                      value={{
                        value: leftColumnInfo?.percentageOfSurplus.toFixed(1) || '-',
                      }}
                    />
                    <DataItem
                      layout="vertical"
                      noCol
                      label={`${t.VIEW_INCOMEEXPENSE_BANKRUPTS_PORTION_OF_SURPLUS} ($)`}
                      value={{
                        value: leftColumnInfo?.bankruptPortionOfSurplus || '0',
                        format: 'currency-no-dollar-sign',
                      }}
                    />
                    <DataItem
                      layout="vertical"
                      noCol
                      label={`${t.VIEW_INCOMEEXPENSE_BANKRUPTS_NET_SURPLUS} ($)`}
                      value={{
                        value: leftColumnInfo?.bankruptNetSurplus || '0',
                        format: 'currency-no-dollar-sign',
                      }}
                    />
                    <DataItem
                      layout="vertical"
                      noCol
                      label={`${t.VIEW_INCOMEEXPENSE_CALCULATED_SURPLUS_PAYMENT} ($)`}
                      value={{
                        value: leftColumnInfo?.calculatedSurplusPayment || '0',
                        format: 'currency-no-dollar-sign',
                      }}
                    />
                    <FormInput
                      name="estimatedSurplusPayment"
                      label={`${t.VIEW_INCOMEEXPENSE_ESTIMATED_SURPLUS_PAYMENT} ($)`}
                      normalize={(value) => normalizeInputToOnlyIntegersAndLength(value)}
                      disabled={isFormInactive}
                    />
                  </>
                }
                filesList={documentIds}
                rightColumnContent={
                  <>
                    {renderEmailDisplay()}
                    <LineItemsDisplay
                      title={t.VIEW_INCOMEEXPENSE_INCOME}
                      subtotalsColor="green"
                      calculateSubtotal
                      data={incomeLineItems}
                      onEdit={handleOpenEditIncome}
                      hideZeroValueItems
                      editable={!isFormInactive}
                    />
                    <LineItemsDisplay
                      style={{ marginTop: '15px' }}
                      title={t.VIEW_INCOMEEXPENSE_EXPENSE}
                      subtotalsColor="red"
                      calculateSubtotal
                      data={expenseLineItems}
                      onEdit={openEditExpense}
                      hideZeroValueItems
                      editable={!isFormInactive}
                    />
                  </>
                }
              />
            )}
          </AppForm>
        </div>
      </Spin>
    </Layout>
  );
};

export default ViewIncomeAndExpensePage;
