import { useState, useCallback, useEffect, useMemo } from 'react';
import { Select, Form, FormInstance, Col, Row, Spin } from 'antd';
import update from 'immutability-helper';

import FileRow from '../FileRow/FileRow';
import AddIcon from '../../../images/add_icon.svg';

import useLocale from '../../../hooks/useLocale';
import useDebounce from '../../../hooks/useDebounce';
import { MAX_PAGE_SIZE } from '../../../constants/common';

import {
  argumentifyDocumentsSearchCriteria,
  argumentifySupportingDocumentsSearchCriteria,
  getEmptyGuid,
} from '../utils';
import { AttachmentsFormProps, IDocumentSearchCriteria, FilesColumnsProps } from '../types';
import { eventBus } from '../../../utils/eventBus';
import { DELETE_FILE_EVENT, UPLOAD_FILES_EVENT } from '../../../constants/eventBus';
import { ClientService } from '../../../shared/api/ClientService';
import API from '../../../utils/api';

interface IProps {
  applicationFileId: string;
  form: FormInstance<AttachmentsFormProps>;
  supportingFolderId?: string;
  prefixCodes?: (string | undefined)[];
  columnsProps?: FilesColumnsProps;
  disabled?: boolean;
  appFormAssetsDebtsAssetId?: string;
  documentIds?: string[];
  onDocumentDeleted?: CallableFunction;
}

function ExistingFiles({
  applicationFileId,
  form,
  supportingFolderId,
  prefixCodes,
  columnsProps,
  disabled,
  appFormAssetsDebtsAssetId = getEmptyGuid(),
  documentIds,
  onDocumentDeleted,
}: IProps): JSX.Element {
  const { t, getLocalizedDocumentName } = useLocale();
  const { Option } = Select;

  const [appFileDocuments, setAppFileDocuments] = useState<ClientService.DocumentDto[]>();
  const [appFileDocumentsLoading, setAppFileDocumentsLoading] = useState<boolean>(false);
  const [filterText, setFilterText] = useState<string>();
  const [searchFolderId, setSearchFolderId] = useState<string>();
  const value = useDebounce(filterText);

  const depsPrefixCodes = useMemo(() => `${prefixCodes}`, [prefixCodes]);

  const criteria = useMemo<IDocumentSearchCriteria>(
    () => ({
      fileId: applicationFileId,
      documentFolderId: supportingFolderId || searchFolderId,
      documentPrefixCodes: prefixCodes,
      filterText: value,
    }),
    [applicationFileId, supportingFolderId, searchFolderId, prefixCodes, value]
  );

  const requestDocumentsByIdGET = useCallback(async () => {
    setAppFileDocumentsLoading(true);
    if (documentIds != undefined && documentIds.length > 0) {
      const response = await API.documentsGET(
        ...argumentifyDocumentsSearchCriteria({
          fileId: criteria?.fileId,
          documentIds: documentIds,
          maxResultCount: MAX_PAGE_SIZE,
        })
      ).catch(() => setAppFileDocumentsLoading(false));

      if (response?.items) setAppFileDocuments(response?.items);
    }
    setAppFileDocumentsLoading(false);
  }, [documentIds]);

  const requestDocumentsGET = useCallback(async () => {
    setAppFileDocumentsLoading(true);
    const response = await API.documentsGET(
      ...argumentifyDocumentsSearchCriteria({
        fileId: criteria?.fileId,
        documentFolderId: criteria?.documentFolderId,
        documentPrefixCodes: criteria?.documentPrefixCodes,
        filterText: criteria?.filterText,
        maxResultCount: MAX_PAGE_SIZE,
      })
    ).catch(() => setAppFileDocumentsLoading(false));
    setAppFileDocumentsLoading(false);

    if (response?.items) setAppFileDocuments(response?.items);
  }, [criteria]);

  const requestDocuments = useMemo(
    () => (documentIds != undefined ? requestDocumentsByIdGET : requestDocumentsGET),
    [documentIds, requestDocumentsGET, requestDocumentsByIdGET]
  );

  const handleClickAdd = useCallback(() => {
    const searchFileId = form.getFieldValue('searchFileId');
    const searchFile = appFileDocuments?.find((item) => item.id === searchFileId);

    if (searchFile) {
      form.setFieldsValue({
        existingFiles: update(form.getFieldValue('existingFiles'), {
          $push: [searchFile],
        }),
        searchFileId: undefined,
      });

      setFilterText(undefined);
    }
  }, [appFileDocuments, form]);

  const handleDeleteFromDocuments = useCallback(
    async (fileId?: string) => {
      if (fileId) {
        await API.documentsDELETE([fileId]);
        eventBus.dispatch(DELETE_FILE_EVENT);
        if (onDocumentDeleted) {
          onDocumentDeleted();
        }
        requestDocuments();
      }
    },
    [requestDocuments]
  );

  useEffect(() => {
    if (appFileDocuments && supportingFolderId) {
      form.setFieldsValue({
        existingFiles: appFileDocuments?.filter((item) => item.documentFolderId === supportingFolderId),
      });
    }
  }, [appFileDocuments, form, supportingFolderId]);

  useEffect(() => {
    requestDocuments();
  }, [criteria?.fileId, criteria?.documentFolderId, depsPrefixCodes, criteria?.filterText, documentIds]);

  useEffect(() => {
    eventBus.on(UPLOAD_FILES_EVENT, requestDocuments);

    return () => {
      eventBus.remove(UPLOAD_FILES_EVENT, requestDocuments);
    };
  }, [requestDocuments]);

  return (
    <Form.Item
      noStyle
      shouldUpdate={(prev, curr) => {
        if (prev.searchFolderId !== curr.searchFolderId) {
          setSearchFolderId(curr.searchFolderId);
        }

        return true;
      }}
    >
      {({ getFieldValue, getFieldsValue }) => {
        const values = getFieldsValue();
        const existingFiles = values?.existingFiles as ClientService.DocumentDto[];
        const filteredSearchDocuments = appFileDocuments?.filter(
          (item) => !existingFiles?.find((file) => file.id === item.id)
        );

        return (
          <>
            {!supportingFolderId && (
              <Row align="middle" justify="space-between" gutter={10}>
                <Col span={23}>
                  <Form.Item label={t.ATTACHMENT_SEARCH} name="searchFileId" shouldUpdate>
                    <Select
                      showSearch
                      showArrow={false}
                      filterOption={false}
                      onSearch={setFilterText}
                      size="large"
                      notFoundContent={appFileDocumentsLoading ? <Spin /> : null}
                      disabled={disabled}
                    >
                      {filteredSearchDocuments?.map((item) => (
                        <Option key={item.id} value={item.id} name={getLocalizedDocumentName(item)}>
                          {getLocalizedDocumentName(item)}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={1}>
                  <Form.Item noStyle>
                    <img src={AddIcon} alt="add-icon" width={15} height={15} onClick={handleClickAdd} />
                  </Form.Item>
                </Col>
              </Row>
            )}

            <Spin spinning={appFileDocumentsLoading}>
              <Form.List name="existingFiles" initialValue={[]}>
                {(fields, { remove }) => (
                  <>
                    {fields?.map((row, index) => {
                      const file = getFieldValue(['existingFiles', row.name]);
                      return (
                        <FileRow
                          file={file}
                          onDelete={() => {
                            if (Boolean(supportingFolderId)) handleDeleteFromDocuments(file?.id);
                            remove(row.name);
                          }}
                          name={index}
                          key={`existingFile-${index}`}
                          hasLabels={
                            (!Boolean(supportingFolderId) && Boolean(existingFiles?.length) && index === 0) ||
                            (Boolean(supportingFolderId) && !Boolean(values?.newFiles?.length) && index === 0)
                          }
                          columnsProps={columnsProps}
                        />
                      );
                    })}
                  </>
                )}
              </Form.List>
            </Spin>
          </>
        );
      }}
    </Form.Item>
  );
}

export default ExistingFiles;
