import { Input, Col, Form } from 'antd';

import CashInputBlock from './CashInputBlock';
import CashInputTotal from './CashInputTotal';

import { ClientService } from '../../../../shared/api/ClientService';
import useLocale from '../../../../hooks/useLocale';
import { calculateCashAmount } from '../utils';

import styles from '../../../../styles/style.module.scss';

interface IProps {
  disabled?: boolean;
}

const PaymentMethod = ({ disabled }: IProps) => {
  const { t } = useLocale();

  return (
    <Form.Item noStyle shouldUpdate>
      {({ getFieldValue, getFieldError, getFieldsValue }) => {
        const paymentMethod = getFieldValue('paymentMethod');
        const hasErrors = getFieldError('paymentMethod')?.length > 0;

        if (!paymentMethod) return <></>;

        if (paymentMethod === ClientService.ReceiptPaymentMethodEnum.Cash) {
          return (
            <Col style={{ backgroundColor: styles.colorActiveRow, padding: 10 }}>
              <CashInputBlock disabled={disabled} />
              <CashInputTotal value={calculateCashAmount(getFieldsValue())?.toFixed(2)} hasErrors={hasErrors} />
            </Col>
          );
        }

        return (
          <Form.Item
            name="paymentMethodDetail"
            label={
              paymentMethod === ClientService.ReceiptPaymentMethodEnum.Cheque
                ? t.RECEIPT_PAYMENTMETHOD_CHEQUE_NUMBER
                : t.RECEIPT_PAYMENTMETHOD_OTHER
            }
            rules={[{ required: true, message: t.REQUIRED_FIELD }]}
          >
            <Input disabled={disabled} />
          </Form.Item>
        );
      }}
    </Form.Item>
  );
};
export default PaymentMethod;
