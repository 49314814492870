import { useEffect, useState } from 'react';
import { Col, DatePicker, Divider, FormInstance, Input, Radio, Row, Select, Form } from 'antd';
import useLocale from '../../../../hooks/useLocale';
import { ClientService } from '../../../../shared/api/ClientService';
import { antdUtil } from '../../../../utils/antdUtil';
import TextArea from 'antd/lib/input/TextArea';
import { DATE_FORMAT2, DATE_FORMAT_PLACEHOLDER } from '../../../../constants/common';
import SearchLocationInput from '../../../../components/SearchLocationInput/SearchLocationInput';
import { LocationDto } from '../../../../modals/GoogleMaps';
import { DeleteOutlined } from '@ant-design/icons';
import SelectSuffixIcon from '../../../../components/SelectSuffixIcon/SelectSuffixIcon';
import ChangeTrackerFormItem from '../../../../components/ChangeTrackerFormItem';

import './ApplicationFormTab.scss';

interface IProps {
  form: FormInstance<ClientService.AppFormQuestionnaireDto>;
  businessOwnershipTypes: ClientService.LookupDto[];
  businessTypes: ClientService.LookupDto[];
  isRequired: boolean;
  rowName: number;
  onAddressSelect: (row: number) => (autofilledAddress: LocationDto) => void;
  remove: (index: number | number[]) => void;
  isLocked?: boolean;
  listName?: string;
}

const QuestionnaireBusinessRow = ({
  form,
  businessOwnershipTypes,
  businessTypes,
  isRequired,
  rowName,
  onAddressSelect,
  remove,
  isLocked = false,
  listName,
}: IProps): JSX.Element => {
  const { t } = useLocale();
  const { Option } = Select;

  const [isCorporation, setIsCorporation] = useState<boolean>(false);
  const [isSoleProprietorship, setIsSoleProprietorship] = useState<boolean>(false);

  useEffect(() => {
    let typeId = form.getFieldValue([rowName, 'businessOwnershipTypeId']);
    let selType = businessOwnershipTypes?.find((item) => item?.id === typeId);

    setIsCorporation(selType?.enumValue === ClientService.BusinessOwnershipTypeEnum.Corporation);
    setIsSoleProprietorship(selType?.enumValue === ClientService.BusinessOwnershipTypeEnum.SoleProprietorship);
  }, [form, businessOwnershipTypes, rowName]);

  const handleBusinessOwnershipTypeSelect = (value: string, _: any) => {
    const selType = businessOwnershipTypes?.find((item) => item?.id === value);

    setIsCorporation(selType?.enumValue === ClientService.BusinessOwnershipTypeEnum.Corporation);
    setIsSoleProprietorship(selType?.enumValue === ClientService.BusinessOwnershipTypeEnum.SoleProprietorship);
  };

  return (
    <>
      <Row gutter={20} align="middle" style={{ position: 'relative' }}>
        <Col span={8}>
          <ChangeTrackerFormItem
            name={[rowName, 'businessName']}
            label={t.BUSINESS_NAME}
            rules={[{ required: isRequired }]}
            listName={listName}
          >
            <Input size="large" disabled={isLocked} />
          </ChangeTrackerFormItem>
        </Col>
        <Col span={8}>
          <ChangeTrackerFormItem
            name={[rowName, 'businessOwnershipTypeId']}
            label={t.TYPE_OF_OWNERSHIP}
            rules={[{ required: isRequired }]}
            listName={listName}
          >
            <Select
              suffixIcon={<SelectSuffixIcon />}
              size="large"
              onChange={handleBusinessOwnershipTypeSelect}
              disabled={isLocked}
            >
              {businessOwnershipTypes?.map((option) => (
                <Option key={option.id} value={option?.id}>
                  {option?.name}
                </Option>
              ))}
            </Select>
          </ChangeTrackerFormItem>
        </Col>
        <Col span={8}>
          <ChangeTrackerFormItem
            name={[rowName, 'businessTypeId']}
            label={t.TYPE_OF_BUSINESS}
            rules={[{ required: isRequired }]}
            listName={listName}
          >
            <Select suffixIcon={<SelectSuffixIcon />} size="large" disabled={isLocked}>
              {businessTypes?.map((option) => (
                <Option key={option.id} value={option?.id}>
                  {option?.name}
                </Option>
              ))}
            </Select>
          </ChangeTrackerFormItem>
        </Col>

        <Col span={16}>
          <Form.Item
            noStyle
            shouldUpdate={(prevValues, currentValues) => prevValues[rowName] !== currentValues[rowName]}
          >
            {({ getFieldValue }) => (
              <ChangeTrackerFormItem
                name={[rowName, 'businessAddress']}
                label={t.BUSINESS_ADDRESS}
                rules={[{ required: isRequired }]}
                listName={listName}
              >
                <SearchLocationInput
                  address={{ address: getFieldValue([rowName, 'businessAddress']) }}
                  onSelect={onAddressSelect(rowName)}
                  size="large"
                  disabled={isLocked}
                />
              </ChangeTrackerFormItem>
            )}
          </Form.Item>
        </Col>
        <Col span={8}>
          <ChangeTrackerFormItem name={[rowName, 'businessUnit']} label={t.BUSINESS_ADDRESS_UNIT} listName={listName}>
            <Input size="large" disabled={isLocked} />
          </ChangeTrackerFormItem>
        </Col>

        <Col span={8}>
          <ChangeTrackerFormItem
            name={[rowName, 'startDate']}
            label={t.START_DATE}
            rules={[{ required: isRequired }]}
            listName={listName}
          >
            <DatePicker
              size="large"
              disabledDate={antdUtil.futureDates}
              format={DATE_FORMAT2}
              placeholder={DATE_FORMAT_PLACEHOLDER}
              disabled={isLocked}
            />
          </ChangeTrackerFormItem>
        </Col>
        <Col span={8}>
          <ChangeTrackerFormItem
            name={[rowName, 'dateOperationsCeased']}
            label={t.DATE_OPERATIONS_CEASED}
            listName={listName}
          >
            <DatePicker
              size="large"
              disabledDate={antdUtil.futureDates}
              format={DATE_FORMAT2}
              placeholder={DATE_FORMAT_PLACEHOLDER}
              disabled={isLocked}
            />
          </ChangeTrackerFormItem>
        </Col>
        <Col span={8}>
          <ChangeTrackerFormItem
            name={[rowName, 'maximumNumberOfEmployeesInThePast12Months']}
            label={t.BUSINESS_NUMBER_OF_EMPLOYEES}
            rules={[{ required: isRequired, message: t.BUSINESS_NUMBER_OF_EMPLOYEES_REQUIRED }]}
          >
            <Input size="large" type={'number'} disabled={isLocked} />
          </ChangeTrackerFormItem>
        </Col>

        <Col span={24} className="ApplicationFormTab__questionnaire-question--horizontal">
          <ChangeTrackerFormItem
            name={[rowName, 'isCorporationBankrupt']}
            rules={[{ required: isRequired, message: t.REQUIRED_FIELD }]}
            label={t.IS_THE_CORPORATION_BANKRUPT}
            labelCol={{ span: 16 }}
            wrapperCol={{ span: 8 }}
            validateTrigger="onChange"
            listName={listName}
          >
            <Radio.Group size="large" buttonStyle="solid" optionType="button" disabled={isLocked}>
              <Radio.Button value={true}>{t.YES}</Radio.Button>
              <Radio.Button value={false}>{t.NO}</Radio.Button>
            </Radio.Group>
          </ChangeTrackerFormItem>
        </Col>

        <Divider dashed className="ApplicationFormTab__questionnaire-question-divider" />

        <Col span={24} className="ApplicationFormTab__questionnaire-question--horizontal">
          <ChangeTrackerFormItem
            name={[rowName, 'hasEmployeesOrSubContractors']}
            rules={[{ required: isRequired, message: t.REQUIRED_FIELD }]}
            label={t.DOES_THE_BUSINESS_HAVE_EMPLOYEES_OR_SUBCONTRACTORS}
            labelCol={{ span: 16 }}
            wrapperCol={{ span: 8 }}
            validateTrigger="onChange"
            listName={listName}
          >
            <Radio.Group size="large" buttonStyle="solid" optionType="button" disabled={isLocked}>
              <Radio.Button value={true}>{t.YES}</Radio.Button>
              <Radio.Button value={false}>{t.NO}</Radio.Button>
            </Radio.Group>
          </ChangeTrackerFormItem>
        </Col>

        <Divider dashed className="ApplicationFormTab__questionnaire-question-divider" />

        <Col span={24} className="ApplicationFormTab__questionnaire-question--horizontal">
          <ChangeTrackerFormItem
            name={[rowName, 'isOwingWagesToEmployees']}
            rules={[{ required: isRequired, message: t.REQUIRED_FIELD }]}
            label={t.DOES_THE_BUSINESS_OWE_ANY_WAGES_TO_EMPLOYEES}
            labelCol={{ span: 16 }}
            wrapperCol={{ span: 8 }}
            validateTrigger="onChange"
            listName={listName}
          >
            <Radio.Group size="large" buttonStyle="solid" optionType="button" disabled={isLocked}>
              <Radio.Button value={true}>{t.YES}</Radio.Button>
              <Radio.Button value={false}>{t.NO}</Radio.Button>
            </Radio.Group>
          </ChangeTrackerFormItem>
        </Col>

        <Divider dashed className="ApplicationFormTab__questionnaire-question-divider" />

        <Col span={24} className="ApplicationFormTab__questionnaire-question--horizontal">
          <ChangeTrackerFormItem
            name={[rowName, 'isOwingSourceDeductionOnWages']}
            rules={[{ required: isRequired, message: t.REQUIRED_FIELD }]}
            label={t.DOES_THE_BUSINESS_OWE_ANY_SOURCE_DEDUCTIONS_ON_WAGES}
            labelCol={{ span: 16 }}
            wrapperCol={{ span: 8 }}
            validateTrigger="onChange"
            listName={listName}
          >
            <Radio.Group size="large" buttonStyle="solid" optionType="button" disabled={isLocked}>
              <Radio.Button value={true}>{t.YES}</Radio.Button>
              <Radio.Button value={false}>{t.NO}</Radio.Button>
            </Radio.Group>
          </ChangeTrackerFormItem>
        </Col>

        {isCorporation && (
          <>
            <Divider dashed className="ApplicationFormTab__questionnaire-question-divider" />
            <Col span={24} className="ApplicationFormTab__questionnaire-question--horizontal">
              <ChangeTrackerFormItem
                name={[rowName, 'isDirector']}
                rules={[{ required: isRequired, message: t.REQUIRED_FIELD }]}
                label={t.ARE_YOU_A_DIRECTOR}
                labelCol={{ span: 16 }}
                wrapperCol={{ span: 8 }}
                validateTrigger="onChange"
                style={{ marginBottom: 24 }}
                listName={listName}
              >
                <Radio.Group size="large" buttonStyle="solid" optionType="button" disabled={isLocked}>
                  <Radio.Button value={true}>{t.YES}</Radio.Button>
                  <Radio.Button value={false}>{t.NO}</Radio.Button>
                </Radio.Group>
              </ChangeTrackerFormItem>
            </Col>
          </>
        )}

        {!isSoleProprietorship && (
          <>
            <Col span={16}>
              <ChangeTrackerFormItem
                name={[rowName, 'nameOfPartnersOrDirectors']}
                label={t.NAME_OF_PARTNERS__DIRECTORS}
                className="ApplicationFormTab__input-label-warning"
                rules={[{ required: isRequired, warningOnly: true, message: t.RECOMMENDED_FIELD }]}
                listName={listName}
              >
                <TextArea size="large" disabled={isLocked} />
              </ChangeTrackerFormItem>
            </Col>
            <Col span={8}></Col>
          </>
        )}

        <Col span={16}>
          <ChangeTrackerFormItem name={[rowName, 'otherDetails']} label={t.OTHER_DETAILS} listName={listName}>
            <TextArea size="large" disabled={isLocked} />
          </ChangeTrackerFormItem>
        </Col>
        <Col span={7}></Col>
        {!isLocked && (
          <Col span={2} style={{ position: 'absolute', bottom: '0', right: '0' }}>
            <DeleteOutlined
              className="ApplicationFormTab__input-action-icon"
              onClick={() => {
                if (isLocked) return;
                remove(rowName);
              }}
            />
          </Col>
        )}
      </Row>
      <Divider dashed className="ApplicationFormTab__questionnaire-question-divider" />
    </>
  );
};

export default QuestionnaireBusinessRow;
