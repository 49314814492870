import { ClientService } from '../../../../../shared/api/ClientService';

export const getCouncellingTypeEnumBySessionNumber = (session: number) => {
  switch (session) {
    case 1:
      return ClientService.AppointmentTypeEnum.Counselling_1st;
    case 2:
      return ClientService.AppointmentTypeEnum.Counselling_2nd;
    case 3:
      return ClientService.AppointmentTypeEnum.Counselling_3rd;
    default:
      return;
  }
};

export const getMeetingTypeEnumByDeiveryMethodDisplayName = (deliveryMethodDisplayName?: string) => {
  switch (deliveryMethodDisplayName) {
    case 'In-person':
      return ClientService.MeetingTypeEnum.InPerson;
    case 'Telephone':
      return ClientService.MeetingTypeEnum.Phone;
    case 'Video':
      return ClientService.MeetingTypeEnum.Phone;
    default:
      return;
  }
};

export const getSessionsByNumber = (
  sessionNumber?: number,
  sessions?: ClientService.AdminInfoCounsellingSessionDto[]
) => sessions?.filter((session) => session?.sessionNumber === sessionNumber);

export const isSessionCompleted = (session?: ClientService.AdminInfoCounsellingSessionDto) =>
  session?.appointment?.appointmentStatus?.enumValue === ClientService.AppointmentStatusEnum.Completed;

export const isSessionPending = (session?: ClientService.AdminInfoCounsellingSessionDto) =>
  session?.appointment?.appointmentStatus?.enumValue === ClientService.AppointmentStatusEnum.Pending;

export const isSessionCancelled = (session?: ClientService.AdminInfoCounsellingSessionDto) =>
  session?.appointment?.appointmentStatus?.enumValue === ClientService.AppointmentStatusEnum.Cancelled;

export const getCompletedSession = (sessions?: ClientService.AdminInfoCounsellingSessionDto[]) =>
  sessions?.find((session) => isSessionCompleted(session));

export const getPendingSession = (sessions?: ClientService.AdminInfoCounsellingSessionDto[]) =>
  sessions?.find((session) => isSessionPending(session));

export const getCancelledSession = (sessions?: ClientService.AdminInfoCounsellingSessionDto[]) =>
  sessions?.find((session) => isSessionCancelled(session));

export const isAnySessionCompleted = (sessions?: ClientService.AdminInfoCounsellingSessionDto[]) =>
  Boolean(getCompletedSession(sessions));

export const isAnySessionPending = (sessions?: ClientService.AdminInfoCounsellingSessionDto[]) =>
  Boolean(getPendingSession(sessions));

export const isAnySessionCancelled = (sessions?: ClientService.AdminInfoCounsellingSessionDto[]) =>
  Boolean(getCancelledSession(sessions));

export const getSessionStatusNameByAppointmentEnum = (appointmentEnum?: ClientService.AppointmentStatusEnum) => {
  switch (appointmentEnum) {
    case ClientService.AppointmentStatusEnum.Pending:
      return 'Pending';
    case ClientService.AppointmentStatusEnum.Cancelled:
      return 'Cancelled';
    case ClientService.AppointmentStatusEnum.Completed:
      return 'Completed';
    default:
      return;
  }
};
