import { useCallback, useEffect, useState, useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { CSVLink } from 'react-csv';
import { Menu, Dropdown, Row, Col } from 'antd';
import { Icon } from '@fluentui/react/lib/Icon';
import moment from 'moment';
import { useQuery } from 'react-query';

import Table from '../../../components/Table/Table';
import Breadcrumb from '../../../components/Breadcrumb/Breadcrumb';
import PageSubtitle from '../../../components/PageSubtitle/PageSubtitle';
import Button from '../../../components/Button/Button';
import VoidConfirmationModal from './modals/VoidConfirmationModal';
import DownloadFile from '../../../components/DownloadFile/DownloadFile';

import useLocale from '../../../hooks/useLocale';
import { DATE_FORMAT2, DEFAULT_PAGE_SIZE } from '../../../constants/common';
import { ROUTES } from '../../../constants/routes';
import { paginationShowTotal } from '../../../utils/helpers';
import {
  convertReceiptPostTypeEnumToReadable,
  convertReceiptTypeEnumToReadable,
  convertScheduleItemToReadable,
} from './utils';
import { PAD_SCHEDULE_LIST, USE_QUERY_OPTIONS } from '../../../constants/reactQuery';
import { useApplicationFile } from '../../ApplicationOverviewPage';

import { ClientService } from '../../../shared/api/ClientService';
import API from '../../../utils/api';

import './ReceiptContent.scss';

interface ISearchCriteria {
  id: string;
  fileId: string;
  maxResultCount: number;
  paymentDateMax: moment.Moment | undefined;
  paymentDateMin: moment.Moment | undefined;
  receiptPaymentMethod: ClientService.ReceiptPaymentMethodEnum | undefined;
  skipCount: number;
  sorting: string;
}

const ReceiptTable = (): JSX.Element => {
  const navigate = useNavigate();
  const { applicationFileId } = useParams<{ applicationFileId: string }>();
  const { t } = useLocale();

  const [tableData, setTableData] = useState<ClientService.ReceiptDto[]>([]);
  const [tableDataLoading, setTableDataLoading] = useState(true);

  const [totalCount, setTotalCount] = useState(0);
  const [criteria, setCriteria] = useState({
    id: '',
    fileId: applicationFileId ?? '',
    maxResultCount: DEFAULT_PAGE_SIZE,
    receiptPaymentMethod: undefined,
    paymentDateMax: undefined,
    paymentDateMin: undefined,
    skipCount: 0,
    sorting: 'paymentDate desc',
  });
  const [isConfirmVoidModalVisible, setIsConfirmVoidModalVisible] = useState(false);
  const [receiptIdToVoid, setReceiptIdToVoid] = useState<string>();

  const { data: appFileData } = useApplicationFile();
  const isDuplicate = useMemo(() => appFileData?.isDuplicate, [appFileData]);

  const { data: padScheduleList } = useQuery(
    [PAD_SCHEDULE_LIST, applicationFileId],
    () => {
      if (applicationFileId) {
        return API.padScheduleSelectlist(applicationFileId);
      }
    },
    USE_QUERY_OPTIONS
  );

  useEffect(() => {
    loadData(criteria);
  }, [criteria]);

  const columns = [
    {
      title: t.TYPE,
      label: t.TYPE,
      dataIndex: 'receiptType',
      key: 'receiptType',
      render: (receiptType?: ClientService.ReceiptTypeEnum) => <>{convertReceiptTypeEnumToReadable(receiptType)}</>,
      sorter: true,
    },
    {
      title: t.DATE,
      label: t.DATE,
      dataIndex: 'paymentDate',
      key: 'paymentDate',
      render: (paymentDate: ClientService.ReceiptDto['paymentDate'], item: ClientService.ReceiptDto) => (
        <div className="ReceiptTable__cell-content">{moment(paymentDate).format(DATE_FORMAT2)}</div>
      ),
      sorter: true,
    },
    {
      title: t.RECEIPT_NO,
      label: t.RECEIPT_NO,
      dataIndex: 'receiptNumber',
      key: 'receiptNumber',
      render: (receiptNumber: ClientService.IReceiptDto['receiptNumber'], item: ClientService.IReceiptDto) => (
        <div className="ReceiptTable__cell-content">
          {receiptNumber} {item.isVoided && <Icon iconName="EntryDecline" />}
        </div>
      ),
      sorter: true,
    },
    {
      title: t.LOCATION,
      label: t.LOCATION,
      dataIndex: 'officeLocationName',
      key: 'officeLocationName',
      sorter: true,
    },
    {
      title: t.PER,
      label: t.PER,
      dataIndex: 'per',
      key: 'per',
      sorter: true,
    },
    {
      title: t.AMOUNT_DOLLARS,
      label: t.AMOUNT_DOLLARS,
      dataIndex: 'paymentAmount',
      key: 'paymentAmount',
      sorter: true,
    },
    {
      title: t.SCHEDULE,
      label: t.SCHEDULE,
      dataIndex: 'padScheduleId',
      key: 'padScheduleId',
      sorter: true,
      render: (padScheduleId?: string) => (
        <>{convertScheduleItemToReadable(padScheduleList?.find((item) => item.id === padScheduleId))}</>
      ),
    },
    {
      title: t.METHOD,
      label: t.METHOD,
      dataIndex: 'paymentMethod',
      key: 'paymentMethod',
      render: (paymentMethod: ClientService.IReceiptDto['paymentMethod'], item: ClientService.IReceiptDto) => (
        <div className="ReceiptTable__cell-content">
          {paymentMethod && ClientService.ReceiptPaymentMethodEnum[paymentMethod]}
        </div>
      ),
      sorter: true,
    },
    {
      title: t.POSTED_TO,
      label: t.POSTED_TO,
      dataIndex: 'postType',
      key: 'postType',
      render: (postType: ClientService.IReceiptDto['postType'], item: ClientService.IReceiptDto) => (
        <div className="ReceiptTable__cell-content">{convertReceiptPostTypeEnumToReadable(postType)}</div>
      ),
      sorter: true,
    },
    {
      title: '',
      key: 'action',
      render: (text: string, record: ClientService.IReceiptDto) => {
        const actionMenu = (
          <Menu>
            <Menu.Item onClick={() => navigate(record?.id as string)} key={`view-${record?.id}`}>
              <Icon iconName="OpenInNewWindow" className="ReceiptTable__action-icon" />
              <span className="ReceiptTable__action-name">{t.VIEW}</span>
            </Menu.Item>

            {!isDuplicate && (
              <Menu.Item
                onClick={() => confirmVoidIntent(record?.id)}
                key={`void-${record?.id}`}
                disabled={record?.isVoided}
              >
                <Icon iconName="EntryDecline" className={!record.isVoided ? 'ReceiptTable__action-icon' : ''} />
                <span className="ReceiptTable__action-name">{t.VOID}</span>
              </Menu.Item>
            )}

            <Menu.Item key={`download-${record?.id}`}>
              <DownloadFile downloadUrl={getDownloadUrl(record?.id as string)}>
                <Icon iconName="Download" className="ReceiptTable__action-icon" />
                <span className="ReceiptTable__action-name">{t.DOWNLOAD}</span>
              </DownloadFile>
            </Menu.Item>
          </Menu>
        );

        return (
          <Dropdown
            overlay={actionMenu}
            placement="bottomLeft"
            getPopupContainer={(triggerNode: HTMLElement) => triggerNode.parentElement as HTMLElement}
          >
            <Icon iconName="More" className="ReceiptTable__action-dropdown-icon" />
          </Dropdown>
        );
      },
    },
  ];

  const getDownloadUrl = (id: string) => {
    return `${process.env.REACT_APP_BASE_URL}/api/client-service/receipts/${id}/download`;
  };

  const loadData = (criteria: ISearchCriteria) => {
    setTableDataLoading(true);

    API.receiptsGET(
      criteria.id,
      criteria.fileId,
      criteria.paymentDateMin,
      criteria.paymentDateMax,
      criteria.receiptPaymentMethod,
      undefined,
      false,
      criteria.sorting,
      criteria.skipCount,
      criteria.maxResultCount
    )
      .then((response) => {
        setTotalCount(response.totalCount ?? 0);

        setTableData(response.items ?? []);
        setTableDataLoading(false);
      })
      .catch(() => setTableDataLoading(false));
  };

  const confirmVoidIntent = useCallback((receiptId) => {
    setReceiptIdToVoid(receiptId);
    setIsConfirmVoidModalVisible(true);
  }, []);

  const voidReceipt = useCallback(() => {
    if (receiptIdToVoid) {
      API.void(receiptIdToVoid).then(() => {
        setIsConfirmVoidModalVisible(false);
        setReceiptIdToVoid(undefined);
        loadData(criteria);
      });
    }
  }, [receiptIdToVoid, criteria]);

  const handleTableChange = useCallback(
    (pagination: any, filters: any, sorter: any) => {
      setCriteria({
        ...criteria,
        skipCount: pagination.pageSize * (pagination.current - 1),
        sorting: sorter.field !== undefined ? `${sorter.field} ${sorter.order === 'ascend' ? 'asc' : 'desc'}` : '',
        maxResultCount: pagination.pageSize,
      });
    },
    [criteria]
  );

  return (
    <div className="ReceiptTable">
      <Breadcrumb
        data={[
          { link: ROUTES.DASHBOARD, title: t.DASHBOARD },
          { link: `${ROUTES.DEBTOR_SEARCH}`, title: t.DEBTOR_SEARCH },
          { link: `${ROUTES.APPLICATION_OVERVIEW}/${applicationFileId}/`, title: t.APPLICATION_OVERVIEW },
          {
            title: t.RECEIPT_HISTORY,
          },
        ]}
      />

      <PageSubtitle
        title={t.RECEIPT_HISTORY}
        leftSideComponent={
          <Row align="middle" justify="end" gutter={20}>
            <Col>
              <CSVLink
                filename={'Receipt_History.csv'}
                data={[...tableData]?.map(
                  ({
                    paymentDate,
                    receiptNumber,
                    isVoided,
                    officeLocationName,
                    per,
                    paymentAmount,
                    paymentMethod,
                    postType,
                  }) => ({
                    paymentDate: moment(paymentDate).format(DATE_FORMAT2),
                    receiptNumber: `${receiptNumber} ${isVoided ? `(${t.VOID})` : ''}`,
                    officeLocationName,
                    per,
                    paymentAmount,
                    paymentMethod: paymentMethod && ClientService.ReceiptPaymentMethodEnum[paymentMethod],
                    postType: postType && ClientService.ReceiptPostTypeEnum[postType],
                  })
                )}
                headers={columns?.map((item) => ({ label: item.label || '', key: item.key }))}
                className="btn btn-primary"
              >
                <Button kind="cancel">{t.EXPORT}</Button>
              </CSVLink>
            </Col>
            {!isDuplicate && (
              <Col>
                <Button kind="primary" onClick={() => navigate(`../${ROUTES.ADD_RECEIPT}`)}>
                  {t.RECEIPT_NEW}
                </Button>
              </Col>
            )}
          </Row>
        }
      />

      <Table
        rowKey="id"
        dataSource={[...tableData]}
        columns={columns}
        onChange={handleTableChange}
        pagination={{
          position: ['bottomRight'],
          pageSize: criteria.maxResultCount,
          total: totalCount,
          showSizeChanger: true,
          showTotal: paginationShowTotal,
        }}
        loading={tableDataLoading}
        className={tableData?.length ? 'ReceiptTable__table' : 'ReceiptTable__table-empty'}
      />
      <VoidConfirmationModal
        visible={isConfirmVoidModalVisible}
        onCancel={() => {
          setIsConfirmVoidModalVisible(false);
        }}
        onOk={voidReceipt}
      />
    </div>
  );
};

export default ReceiptTable;
