import { useCallback } from 'react';
import { Modal, Row, Col } from 'antd';

import Button from '../../../../components/Button/Button';
import useLocale from '../../../../hooks/useLocale';

import './VoidConfirmationModal.scss';

interface IProps {
  visible?: boolean;
  onOk?: () => void;
  onCancel?: () => void;
}

function VoidConfirmationModal({ visible = false, onOk, onCancel }: IProps): JSX.Element {
  const { t } = useLocale();
  const handleOk = useCallback(() => {
    if (onOk) onOk();
  }, [onOk]);

  const handleCancel = useCallback(() => {
    if (onCancel) onCancel();
  }, [onCancel]);
  return (
    <Modal
      title={t.RECEIPT_VOID_CONFIRM_TITLE}
      visible={visible}
      footer={
        <Row gutter={10} justify="end">
          <Col>
            <Button kind="cancel" padding="large" onClick={handleCancel}>
              {t.RECEIPT_VOID_CONFIRM_NEGATIVE}
            </Button>
          </Col>
          <Col>
            <Button padding="large" onClick={handleOk}>
              {t.RECEIPT_VOID_CONFIRM_POSITIVE}
            </Button>
          </Col>
        </Row>
      }
      className="ReceiptTable__modal"
    >
      <p>{t.RECEIPT_VOID_CONFIRM_MESSAGE}</p>
    </Modal>
  );
}

export default VoidConfirmationModal;
