import { Breadcrumb as AntdBreadcrumb } from 'antd';
import { Link } from 'react-router-dom';

import { Icon } from '@fluentui/react/lib/Icon';

import './Breadcrumb.scss';

export interface IBreadcrumb {
  title?: string;
  link?: string;
}

function Breadcrumb({ data }: { data: IBreadcrumb[] }): JSX.Element {
  return (
    <AntdBreadcrumb className="Breadcrumb" separator={<Icon iconName="ChevronRight" className="Breadcrumb__chevron" />}>
      {data?.map((item, index) => (
        <AntdBreadcrumb.Item
          key={`breadcrumb-${index}`}
          className="Breadcrumb__item"
          separator={<Icon iconName="ChevronRight" className="Breadcrumb__chevron" />}
        >
          {item?.link ? <Link to={item?.link}>{item?.title}</Link> : item.title}
        </AntdBreadcrumb.Item>
      ))}
    </AntdBreadcrumb>
  );
}

export default Breadcrumb;
