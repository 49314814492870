import { useCallback, useEffect, useState, useMemo } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Icon } from '@fluentui/react/lib/Icon';
import moment from 'moment';

import Table from '../../../../components/Table/Table';
import Breadcrumb from '../../../../components/Breadcrumb/Breadcrumb';
import PageSubtitle from '../../../../components/PageSubtitle/PageSubtitle';
import EmailEditor from '../EmailEditor/EmailEditor';

import useLocale from '../../../../hooks/useLocale';
import useModal from '../../../../hooks/useModal';
import { ROUTES } from '../../../../constants/routes';
import { SORTING_ASC, SORTING_DESC } from '../constants';
import { IEmailSearchCriteria } from '../../types';
import { argumentifyEmailSearchCriteria } from '../../utils';
import { DEFAULT_PAGE_SIZE, DATE_FORMAT2 } from '../../../../constants/common';
import { paginationShowTotal } from '../../../../utils/helpers';
import { useApplicationFile } from '../../ApplicationOverviewPage';

import { ClientService } from '../../../../shared/api/ClientService';
import API from '../../../../utils/api';
import genericMessage from '../../../../utils/genericMessage';

import './EmailTable.scss';

const NOT_AVAILABLE = '-';

type AppEmailDto = ClientService.AppEmailDto;

function EmailTable(): JSX.Element {
  const { applicationFileId } = useParams<{ applicationFileId: string }>();
  const { t } = useLocale();
  const navigate = useNavigate();
  const { showModal, closeModal } = useModal();

  const [emails, setEmails] = useState<AppEmailDto[]>();
  const [emailsLoading, setEmailsLoading] = useState<boolean>(false);

  const [totalCount, setTotalCount] = useState<number>(0);
  const [criteria, setCriteria] = useState<IEmailSearchCriteria>({
    fileId: applicationFileId,
    createdDateMin: undefined,
    createdDateMax: undefined,
    sorting: 'creationTime desc',
    maxResultCount: DEFAULT_PAGE_SIZE,
    skipCount: 0,
  });

  const { data: appFileData } = useApplicationFile();
  const isDuplicate = useMemo(() => appFileData?.isDuplicate, [appFileData]);

  const requestEmails = useCallback(() => {
    setEmailsLoading(true);

    API.getList2(...argumentifyEmailSearchCriteria(criteria))
      .then((response) => {
        setTotalCount(response?.totalCount || 0);
        setEmails(response?.items);
        setEmailsLoading(false);
      })
      .catch((error) => {
        setEmailsLoading(false);
        genericMessage.error(error);
      });
  }, [criteria]);

  useEffect(() => {
    requestEmails();
  }, [criteria, requestEmails]);

  const columns = [
    {
      title: t.DATE,
      dataIndex: 'creationTime',
      key: 'creationTime',
      render: (creationTime: AppEmailDto['creationTime']) => <>{moment(creationTime).format(DATE_FORMAT2)}</>,
      sorter: true,
    },
    {
      title: t.TEMPLATE,
      dataIndex: 'messageTemplateName',
      key: 'messageTemplateName',
      render: (messageTemplateName: AppEmailDto['messageTemplateName']) => <>{messageTemplateName || NOT_AVAILABLE}</>,
      sorter: true,
    },
    {
      title: t.SUBJECT,
      dataIndex: 'subject',
      key: 'subject',
      sorter: true,
    },
    {
      title: t.ATTACHMENT,
      dataIndex: 'attachmentDocumentIds',
      key: 'attachmentDocumentIds',
      render: (value?: string[]) => <>{value && value?.length > 0 ? t.YES : t.NO}</>,
      sorter: true,
    },
    {
      title: '',
      dataIndex: 'action',
      key: 'action',
      render: (text: string, item: AppEmailDto) => (
        <Icon
          iconName="PageEdit"
          onClick={() => navigate(`${ROUTES.APPLICATION_OVERVIEW}/${applicationFileId}/${ROUTES.EMAIL}/${item?.id}`)}
        />
      ),
    },
  ];

  const handleShowEditor = useCallback(() => {
    showModal(
      <EmailEditor
        onNext={() => {
          closeModal();
          requestEmails();
        }}
        onCancel={closeModal}
        fileId={applicationFileId as string}
      />
    );
  }, [showModal, closeModal, applicationFileId, requestEmails]);

  const handleTableChange = useCallback(
    (pagination: any, filters: any, sorter: any) => {
      setCriteria({
        ...criteria,
        maxResultCount: pagination.pageSize,
        skipCount: pagination.pageSize * (pagination.current - 1),
        sorting:
          sorter.field !== undefined && sorter.field !== 'attachments'
            ? `${sorter.field} ${sorter.order === 'ascend' ? SORTING_ASC : SORTING_DESC}`
            : criteria.sorting,
      });
    },
    [setCriteria, criteria]
  );

  return (
    <div className="EmailTable">
      <Breadcrumb
        data={[
          { link: ROUTES.DASHBOARD, title: t.DASHBOARD },
          { link: `${ROUTES.DEBTOR_SEARCH}`, title: t.DEBTOR_SEARCH },
          { link: `${ROUTES.APPLICATION_OVERVIEW}/${applicationFileId}`, title: t.APPLICATION_OVERVIEW },
          { title: t.EMAIL_HISTORY },
        ]}
      />
      <PageSubtitle
        title={t.EMAIL_HISTORY}
        buttonTitle={!isDuplicate && t.EMAIL_NEW}
        onButtonClick={handleShowEditor}
      />
      <Table
        rowKey="id"
        dataSource={emails}
        columns={columns}
        onChange={handleTableChange}
        pagination={{
          position: ['bottomRight'],
          defaultPageSize: DEFAULT_PAGE_SIZE,
          showSizeChanger: true,
          total: totalCount,
          showTotal: paginationShowTotal,
        }}
        loading={emailsLoading}
        className={emails?.length ? 'EmailTable__table' : 'EmailTable__table-empty'}
      />
    </div>
  );
}

export default EmailTable;
