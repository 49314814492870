import { useState } from 'react';
import { Tabs, Space } from 'antd';

import Layout from '../../../components/Layout/Layout';
import Breadcrumb from '../../../components/Breadcrumb/Breadcrumb';
import Button from '../../../components/Button/Button';

import ProvinceExemptionsTable from './ConfigurationTables/ProvinceExemptionsTable';
import SurplusIncomeTable from './ConfigurationTables/SurplusIncomeTable';
import NorthwestTerritoryMultiplesTable from './ConfigurationTables/NorthwestTerritoryMultiplesTable';
import OSBOtherFeesTable from './ConfigurationTables/OSBOtherFeesTable';
import RealEstateTable from './ConfigurationTables/RealEstateTable';

import { ROUTES } from '../../../constants/routes';
import useLocale from '../../../hooks/useLocale';

import './FileAnalysisConfiguration.scss';

function FileAnalysisConfiguration(): JSX.Element {
  const { t } = useLocale();
  const { TabPane } = Tabs;

  const [isEditable, setIsEditable] = useState<boolean>(false);

  return (
    <Layout page={ROUTES.ADMINISTRATION} className="FileAnalysisConfiguration">
      <Breadcrumb data={[{ title: t.ADMINISTRATION }, { title: t.FILE_ANALYSIS_CONFIGURATION }]} />
      <Tabs
        className="FileAnalysisConfiguration__tabs"
        tabBarExtraContent={
          <Space className="FileAnalysisConfiguration__edit-btn-container">
            <Button
              type="default"
              onClick={() => setIsEditable(!isEditable)}
              padding="medium"
              narrow
              disabled={isEditable}
            >
              {t.EDIT}
            </Button>
          </Space>
        }
        onTabClick={() => setIsEditable(false)}
        destroyInactiveTabPane
      >
        <TabPane key="1" tab={t.PROVINCIAL_EXEMPTION}>
          <ProvinceExemptionsTable isEditable={isEditable} setIsEditable={setIsEditable} />
        </TabPane>
        <TabPane key="2" tab={t.SURPLUS_INCOME}>
          <SurplusIncomeTable isEditable={isEditable} setIsEditable={setIsEditable} />
        </TabPane>
        <TabPane key="3" tab={t.NORTHWEST_TERRITORY_MULTIPLES}>
          <NorthwestTerritoryMultiplesTable isEditable={isEditable} setIsEditable={setIsEditable} />
        </TabPane>
        <TabPane key="4" tab={t.OSB_FEES}>
          <OSBOtherFeesTable isEditable={isEditable} setIsEditable={setIsEditable} />
        </TabPane>
        <TabPane key="5" tab={t.REAL_ESTATE_FEES}>
          <RealEstateTable isEditable={isEditable} setIsEditable={setIsEditable} />
        </TabPane>
      </Tabs>
    </Layout>
  );
}

export default FileAnalysisConfiguration;
