import { ClientService } from '../../shared/api/ClientService';
import type { UploadFile } from 'antd/es/upload/interface';
import { UploadProps } from 'antd';

export type DocumentsSearchResult =
  ClientService.PagedResultDto_1OfOfDocumentDtoAndContractsAnd_0AndCulture_neutralAndPublicKeyToken_null;

export interface IDocumentSearchCriteria {
  filterText?: string;
  filterTextlang?: ClientService.LanguageEnum;
  fileId?: string;
  documentId?: string;
  documentIds?: string[];
  documentFolderId?: string;
  documentVerificationStatusId?: string;
  documentVerificationStatuses?: ClientService.DocumentVerificationStatusEnum[];
  documentTypeId?: string;
  documentTypes?: ClientService.DocumentTypeEnum[];
  documentPrefixes?: string[];
  documentPrefixCodes?: (string | undefined)[];
  documentFolders?: string[];
  isVisible?: boolean;
  isDeleted?: boolean;
  modifiedDateMin?: moment.Moment;
  modifiedDateMax?: moment.Moment;
  sorting?: string;
  skipCount?: number;
  maxResultCount?: number;
}

export interface ISupportingDocumentSearchCriteria {
  appFormAssetsDebtsAssetId: string;
  isDeleted?: boolean;
  sorting?: string;
  skipCount?: number;
  maxResultCount?: number;
}

export interface CreateDocument {
  fileId?: string;
  documentFolderId?: string;
  documentPrefixId?: string;
  additionalInformation?: string;
  isVisible?: boolean;
  fileModifiedDate?: moment.Moment;
  content?: ClientService.FileParameter;
  isForSpouse?: boolean;
  businessName?: string;
  uid?: string;
}

export interface CreateSupportingDocument {
  supportingDocumentId?: string | undefined;
  documentFolderId?: string;
  documentPrefixId?: string;
  additionalInformation?: string;
  isVisible?: boolean;
  fileModifiedDate?: moment.Moment;
  content?: ClientService.FileParameter;
  isForSpouse?: boolean;
  businessName?: string;
  uid?: string;
}

export interface UpdateDocument {
  id: string;
  documentFolderId?: string;
  documentPrefixId?: string;
  additionalInformation?: string;
  fileModifiedDate?: moment.Moment;
  content?: ClientService.FileParameter;
  isVisible?: boolean;
}

export interface FileProps extends UploadFile {
  isForSpouse?: boolean;
  businessName?: string;
  documentFolderId?: string;
  documentPrefixId?: string;
  additionalInformation?: string;
  id?: string;
}

export interface AttachmentsFormProps {
  newFiles?: FileProps[];
  existingFiles?: ClientService.DocumentDto[];
  searchFileId?: string;
  searchFolderId?: string;
  bulkAdditionalInfo?: string;
  bulkFolders?: ClientService.LookupDto[];
  bulkFolderId?: string;
  bulkPrefixes?: ClientService.LookupDto[];
  bulkPrefixId?: string;
  defaultFolderName?: string;
  SupportingDocOverrides?: OverrideInfo[];
}

export enum AttachmentTabLayoutEnum {
  Tabs = 0,
  Flat = 1,
}

export enum FileActionEnum {
  Upload = 0,
  Replace = 1,
  Edit = 2,
}

export interface DraggerProps extends UploadProps {
  singleFileSizeLimitMB?: number;
  totalFilesSizeLimitMB?: number;
  visible?: boolean;
  mode?: FileActionEnum;
  multiple?: boolean;
  disabled?: boolean;
}

export interface LabelsLayoutProps {
  hasLabels?: boolean;
}

export interface PrefixInputLayoutProps {
  disabledPrefixInput?: boolean;
  preselectedPrefixInput?: boolean;
}

export interface IsForSpouseCheckboxLayoutProps {
  disabledForSpouseInput?: boolean;
  preselectedForSpouseInput?: boolean;
}

export interface BusinessInputLayoutProps {
  disabledBusinessInput?: boolean;
  preselectedBusinessInput?: boolean;
}

export interface FoldersInputLayoutProps {
  disabledFoldersInput?: boolean;
}
export interface NewSupportingFileRowLayoutProps
  extends LabelsLayoutProps,
    PrefixInputLayoutProps,
    FoldersInputLayoutProps,
    IsForSpouseCheckboxLayoutProps,
    BusinessInputLayoutProps {}

export interface AttachmentsTabFlatLayoutProps {
  instructionText?: string;
  label?: string | JSX.Element;
  hasInstruction?: boolean;
  hasSupportingList?: boolean;
  hasExistingFiles?: boolean;
  isAdditionalInfoEditableForExistingFiles?: boolean;
  hasUploader?: boolean;
  supportingListLabelHint?: string[];
  existingFilesContainerMaxHeight?: number;
}

export interface FilesColumnsProps {
  hasForSpouseColumn?: boolean;
  hasSizeColumn?: boolean;
  hasFolderColumn?: boolean;
  hasAdditionalInfoColumn?: boolean;
  hasPrefixColumn?: boolean;
  hasDeleteButton?: boolean;
  onDocumentNameClick?: (fileId?: string) => void;
  isDeleteDisabled?: boolean;
  isAdditionalInfoColumnDisabled?: boolean;
  hasBusinessNameColumn?: boolean;
}

export interface OverrideInfo {
  documentPrefixId: string;
  isOverridden: boolean;
  overrideReason: string;
  isDisabled: boolean;
}

export enum AttachmentTypeEnum {
  AppFormPersonalInfo = 0,
  AppFormIncomeTaxReturn = 1,
}
