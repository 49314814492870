import { Tabs } from 'antd';
import { Icon } from '@fluentui/react/lib/Icon';

import WeeklyAvailability from './WeeklyAvailability/WeeklyAvailability';
import CalendarAvailability from './CalendarAvailability/CalendarAvailability';

import useLocale from '../../../hooks/useLocale';

import './Availability.scss';

function Availability() {
  const { t } = useLocale();
  const { TabPane } = Tabs;

  return (
    <div className="Availability">
      <Tabs destroyInactiveTabPane>
        <TabPane
          key="weekly"
          tab={
            <div className="Availability__tab">
              <Icon iconName="bulletedList" />
              {t.WEEKLY_SCHEDULE}
            </div>
          }
        >
          <WeeklyAvailability />
        </TabPane>
        <TabPane
          key="monthly"
          tab={
            <div className="Availability__tab">
              <Icon iconName="Calendar" />
              {t.CALENDAR_VIEW}
            </div>
          }
        >
          <CalendarAvailability />
        </TabPane>
      </Tabs>
    </div>
  );
}

export default Availability;
