import { ReactNode, useCallback, useState } from 'react';
import GenericModalForm, { GenericModalFormProps } from '../components/Forms/GenericModalForm/GenericModalForm';
import useModal from './useModal';

const useAsyncModalForm = () => {
  const { showModal, closeModal } = useModal();

  const getFromAsyncModalForm = useCallback(
    ({
      title,
      width,
      initialFormValues,
      renderFormBody,
      onValidationFailSideEffects,
      onCancelSideEffects,
      onSaveSideEffects,
      className,
      style,
    }: GenericModalFormProps) => {
      return new Promise((resolve, reject) => {
        showModal(
          <GenericModalForm
            className={className}
            style={style}
            title={title}
            width={width}
            initialFormValues={initialFormValues}
            renderFormBody={renderFormBody}
            onCancelSideEffects={() => {
              if (onCancelSideEffects) onCancelSideEffects();
              resolve(false);
              closeModal();
            }}
            onValidationFailSideEffects={onValidationFailSideEffects}
            onSaveSideEffects={(formValues) => {
              if (onSaveSideEffects) onSaveSideEffects(formValues);
              resolve(formValues);
              closeModal();
            }}
          />
        );
      });
    },
    [showModal, closeModal]
  );

  return {
    getFromAsyncModalForm,
  };
};

export default useAsyncModalForm;
