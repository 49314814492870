import { createContext, useState } from 'react';

interface IMenuContext {
  openMenu: () => void;
  closeMenu: () => void;
  collapsed: boolean;
  isSupervisorAllocation: boolean | undefined;
  setIsSupervisorAllocation: (isSupervisorAllocation: boolean | undefined) => void;
  isSuperAdmin: boolean | undefined;
  setIsSuperAdmin: (setIsSuperAdmin: boolean | undefined) => void;
}

export const AppMenuContext = createContext<IMenuContext>({
  openMenu: () => {},
  closeMenu: () => {},
  collapsed: true,
  isSupervisorAllocation: undefined,
  setIsSupervisorAllocation: () => {},
  isSuperAdmin: undefined,
  setIsSuperAdmin: () => {},
});

interface IProps {
  children: JSX.Element;
}

const AppMenuProvider = ({ children }: IProps): JSX.Element => {
  const [collapsed, setIsCollapsed] = useState<boolean>(true);
  const [isSupervisorAllocation, setIsSupervisorAllocation] = useState<boolean>();
  const [isSuperAdmin, setIsSuperAdmin] = useState<boolean>();

  const closeMenu = () => setIsCollapsed(true);

  const openMenu = () => setIsCollapsed(false);

  return (
    <AppMenuContext.Provider
      value={{
        collapsed,
        closeMenu,
        openMenu,
        isSuperAdmin,
        isSupervisorAllocation,
        setIsSuperAdmin,
        setIsSupervisorAllocation,
      }}
    >
      {children}
    </AppMenuContext.Provider>
  );
};

export default AppMenuProvider;
