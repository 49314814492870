export const DISCHARGE_FOLDER_CODE = 'discharge';
export const PENDING_CODE = 'pending';

export const DISCHARGE_CHECKLIST170_PREFIX = 'Discharge.170Checklist';
export const DISCHARGE_REPORT170_PREFIX = 'Discharge.170Report';
export const DISCHARGE_CERTIFICATE_PREFIX = 'Discharge.DischargeCertificate';
export const DISCHARGE_FORM80_PREFIX = 'Discharge.Form80';
export const DISCHARGE_FORM63_PREFIX = 'Discharge.Form63';
export const DISCHARGE_SIGNED_AGREEMENT_PREFIX = 'Discharge.SignedAgreement';
export const DISCHARGE_CHEQUE_REQUISITION_PREFIX = 'Discharge.ChequeRequisition';
export const DISCHARGE_RECOMMENDATION_LETTER_PREFIX = 'Discharge.RecommendationLetter';
export const DISCHARGE_CONDITIONAL_ORDER_PREFIX = 'Discharge.ConditionalOrder';
export const DISCHARGE_OPPOSITION_LETTER = 'Discharge.OppositionLetter';
export const DISCHARGE_LETTER_TO_DEBTOR_RE_OPPOSITION = 'Discharge.LetterToDebtorReOpposition';
