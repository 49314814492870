import { useCallback, useMemo } from 'react';
import { Col, Row, Form, Typography, Checkbox, Input } from 'antd';
import { IconRed } from '../../../pages/ApplicationOverviewPage/DocumentsContent/Icons';

import useLocale from '../../../hooks/useLocale';

import { readableBytes, downloadUtil } from '../../../pages/ApplicationOverviewPage/DocumentsContent/utils';
import { FilesColumnsProps } from '../types';
import { convertUndefinedToTrue, getColumnWidth, getNameColumnWidth } from '../utils';
import { ClientService } from '../../../shared/api/ClientService';
import authService from '../../Auth/AuthorizeService';

import './FileRow.scss';
import styles from '../../../styles/style.module.scss';

interface IProps {
  name: string | number;
  file: ClientService.DocumentDto;
  onDelete?: () => void;
  hasLabels?: boolean;
  hasDivider?: boolean;
  columnsProps?: FilesColumnsProps;
}

function FileRow({ name, file, onDelete, hasLabels = false, hasDivider = true, columnsProps }: IProps): JSX.Element {
  const { t, getLocalizedDocumentName } = useLocale();
  const { Text } = Typography;

  const columnWidth = useMemo(() => getColumnWidth(columnsProps), [columnsProps]);

  const handleDelete = useCallback(() => {
    if (onDelete && !columnsProps?.isDeleteDisabled) onDelete();
  }, [columnsProps?.isDeleteDisabled, onDelete]);

  const handleDownload = useCallback(async () => {
    let token = await authService.getAccessToken();

    fetch(`${process.env.REACT_APP_BASE_URL}/api/client-service/documents/${file?.id}/download`, {
      method: 'GET',
      headers: new Headers({
        Authorization: `Bearer ${token}`,
      }),
    })
      .then((response) => {
        return response.blob();
      })
      .then((blob) => downloadUtil(blob, getLocalizedDocumentName(file) as string));
  }, [file, getLocalizedDocumentName]);

  const handleFileNameClick = useCallback(() => {
    if (columnsProps?.onDocumentNameClick) {
      columnsProps?.onDocumentNameClick(file?.id);
    } else {
      handleDownload();
    }
  }, [columnsProps, file?.id, handleDownload]);

  if (!file) return <></>;

  return (
    <Row className="FileRow" gutter={20} style={!hasDivider ? { borderBottom: 0 } : {}}>
      <Col span={getNameColumnWidth(columnsProps)}>
        <Form.Item label={hasLabels && t.DOCUMENTS_FILENAME}>
          <Text ellipsis className="FileRow__title" onClick={handleFileNameClick}>
            {getLocalizedDocumentName(file)}
          </Text>
        </Form.Item>
      </Col>
      <Col span={columnsProps?.hasForSpouseColumn ? 2 : 0}>
        <Form.Item label={hasLabels && <Text>{t.DOCUMENTS_FOR_SPOUSE}</Text>}>
          <Checkbox checked={file?.isForSpouse || false} disabled={true} />
        </Form.Item>
      </Col>
      <Col span={columnsProps?.hasSizeColumn ? 2 : 0}>
        <Form.Item label={hasLabels && <Text>{t.DOCUMENTS_FILE_SIZE}</Text>}>
          <Text>{readableBytes(file?.fileSizeInBytes)}</Text>
        </Form.Item>
      </Col>

      <Col span={convertUndefinedToTrue(columnsProps?.hasFolderColumn) ? columnWidth : 0}>
        <Form.Item label={hasLabels && <span className="FileRow__item">{t.DOCUMENTS_FOLDER}</span>}>
          <div className="FileRow__item">{file?.documentFolderName}</div>
        </Form.Item>
      </Col>
      <Col span={convertUndefinedToTrue(columnsProps?.hasPrefixColumn) ? columnWidth : 0}>
        <Form.Item label={hasLabels && <span className="FileRow__item">{t.DOCUMENTS_PREFIX}</span>}>
          <div className="FileRow__item">{file?.documentPrefixName}</div>
        </Form.Item>
      </Col>
      <Col span={Boolean(columnsProps?.hasBusinessNameColumn) ? columnWidth : 0}>
        <Form.Item label={hasLabels && <span className="FileRow__item">{t.BUSINESS}</span>}>
          <div className="FileRow__item">{file?.businessName}</div>
        </Form.Item>
      </Col>
      <Col span={convertUndefinedToTrue(columnsProps?.hasAdditionalInfoColumn) ? columnWidth : 0}>
        <Form.Item
          name={[name, 'additionalInformation']}
          label={hasLabels && <span className="FileRow__item">{t.DOCUMENTS_ADDITIONAL_INFORMATION}</span>}
        >
          {columnsProps?.isAdditionalInfoColumnDisabled ? (
            <div className="FileRow__item">{file?.additionalInformation}</div>
          ) : (
            <Input size="large" />
          )}
        </Form.Item>
      </Col>
      <Col span={1} style={{ textAlign: 'right' }}>
        {convertUndefinedToTrue(columnsProps?.hasDeleteButton) && (
          <Form.Item label={hasLabels && ' '}>
            <IconRed
              iconName="Delete"
              onClick={handleDelete}
              style={{
                color: columnsProps?.isDeleteDisabled ? styles.colorSecondary : styles.colorDanger,
                cursor: columnsProps?.isDeleteDisabled ? 'not-allowed' : 'pointer',
                fontSize: 18,
              }}
            />
          </Form.Item>
        )}
      </Col>
    </Row>
  );
}

export default FileRow;
