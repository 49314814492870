import { Form, FormItemProps } from 'antd';

interface IChangeTrackerFormItemProps extends FormItemProps {
  listName?: string | (string | number)[];
}

const ChangeTrackerFormItem = ({ listName, ...props }: IChangeTrackerFormItemProps) => {
  return (
    <Form.Item noStyle shouldUpdate>
      {({ getFieldValue }) => {
        const changedFieldName =
          Array.isArray(props?.name) && listName
            ? [
                ...(Array.isArray(listName) ? listName : [listName]),
                ...props?.name?.slice(0, -1),
                'clientUpdatedFields',
                ...props?.name?.slice(-1),
              ]
            : ['clientUpdatedFields', props?.name];

        const userChanged = getFieldValue(changedFieldName as any);

        let className = props?.className || '';
        if (userChanged) {
          className += ' user-changed';
        }
        return <Form.Item {...props} className={className} />;
      }}
    </Form.Item>
  );
};

export default ChangeTrackerFormItem;
