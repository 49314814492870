export interface IPieChartItemInterface {
  name: string;
  value: number;
}

export enum ProposalFormEnum {
  Form56 = 0,
  Form93 = 1,
  Form95 = 2,
  Form96 = 3,
  Checklist = 4,
  CourtRevival = 5,
}
