import { Typography } from 'antd';

import './PageTitle.scss';

interface IProps {
  title: string | JSX.Element | Array<JSX.Element>;
  icon?: JSX.Element | false;
  refLink?: JSX.Element | false;
}

const PageTitle = ({ title, icon, refLink }: IProps) => {
  const { Title } = Typography;

  return (
    <Title level={2} className="PageTitle">
      {title}
      <span className="PageTitle__icon">{icon}</span>
      {refLink && <span className="PageTitle__link">{refLink}</span>}
    </Title>
  );
};

export default PageTitle;
