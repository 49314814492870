import { useState } from 'react';
import { Divider, ConfigProvider } from 'antd';

import FormSectionTitle from '../../../../../components/FormSectionTitle/FormSectionTitle';
import PADTable from './components/PADTable/PADTable';
import PADScheduleHistoryTable from './components/PADScheduleHistoryTable/PADScheduleHistoryTable';
import PADScheduleTable from './components/PADScheduleTable/PADScheduleTable';
import BankingForm from './components/BankingForm/BankingForm';

import { BankingContext } from './BankingContext';
import { BankingPageViewEnum } from './types';
import useLocale from '../../../../../hooks/useLocale';

import './Banking.scss';

interface IProps {
  disableAllFields?: boolean;
}

const Banking = ({ disableAllFields }: IProps) => {
  const [pageView, setPageView] = useState<BankingPageViewEnum>(BankingPageViewEnum.Banking);
  const { t } = useLocale();
  return (
    <ConfigProvider
      getPopupContainer={(node?: HTMLElement) => {
        if (node) {
          return node.parentElement as HTMLElement;
        }
        return document.body;
      }}
    >
      <BankingContext.Provider value={{ pageView, setPageView }}>
        {pageView === BankingPageViewEnum.PadHistory && <PADTable />}
        {pageView === BankingPageViewEnum.PadScheduleHistory && <PADScheduleHistoryTable />}
        {pageView === BankingPageViewEnum.Banking && (
          <div className="Banking">
            <FormSectionTitle titleText={t.BANKING} />
            <BankingForm disableAllFields={disableAllFields} />
            <Divider style={{ margin: 0 }} dashed />
            <PADScheduleTable disableAllFields={disableAllFields} />
          </div>
        )}
      </BankingContext.Provider>
    </ConfigProvider>
  );
};

export default Banking;
