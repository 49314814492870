import { useCallback, useEffect, useState } from 'react';
import { Modal, Form, Select, Col, Row, Spin } from 'antd';

import Button from '../../../../../../components/Button/Button';
import SelectLookupDto from '../../../../../../components/SelectLookupDto/SelectLookupDto';
import SelectSuffixIcon from '../../../../../../components/SelectSuffixIcon/SelectSuffixIcon';

import useLocale from '../../../../../../hooks/useLocale';
import genericMessage from '../../../../../../utils/genericMessage';
import { ClientService } from '../../../../../../shared/api/ClientService';
import API from '../../../../../../utils/api';

import './UpdateInfoModal.scss';
import { argumentifyOfficeLocationsSearchCriteria } from '../../General/utils';
import { MAX_PAGE_SIZE } from '../../../../../../constants/common';

interface IProps {
  applicationFileId?: string;
  onOk?: () => void;
  onCancel?: () => void;
}

const UpdateInfoModal = ({ applicationFileId, onOk, onCancel }: IProps) => {
  const { t } = useLocale();
  const [form] = Form.useForm<ClientService.AdminInfoGeneralUpdateDto>();
  const { Option } = Select;

  const [agents, setAgents] = useState<ClientService.LookupDto[]>();
  const [offices, setOffices] = useState<ClientService.OfficeLocationDto[]>();
  const [officeLocationsList, setOfficeLocationsList] = useState<ClientService.OfficeLocationDto[]>();
  const [filingTypes, setFilingTypes] = useState<ClientService.LookupDto[]>();

  const [loading, setLoading] = useState<boolean>(false);

  const requestGeneralGET = useCallback(
    async (applicationFileId: string) => {
      const response = await API.generalGET(applicationFileId);
      const types = await API.listFilingTypes();

      if (response) {
        form.setFieldsValue({
          agentId: response?.agent?.id,
          estateAdministratorId: response?.estateAdministrator?.id,
          interviewerId: response?.interviewer?.id,
          filingType: types?.find((item) => item.id === response.filingType?.id)?.enumValue,
          trusteeId: response?.trustee?.id,
          serviceLocationId: response?.serviceLocation?.id,
          trusteeOfficeId: response?.trusteeOffice?.id,
        });
      }
    },
    [form]
  );

  const requestGeneralPUT = useCallback(
    async (values: ClientService.AdminInfoGeneralUpdateDto) => {
      if (applicationFileId) {
        setLoading(true);
        const response = await API.generalPUT(applicationFileId, values).catch(() => setLoading(false));

        setLoading(false);
        if (response?.result === ClientService.Result.Successful) {
          genericMessage.success(t.INFORMATION_UPDATED);
          if (onOk) onOk();
        } else {
          genericMessage.error({}, response?.messages?.[0]?.body);
        }
      }
    },
    [applicationFileId, onOk, t.INFORMATION_UPDATED]
  );

  const requestAgents = useCallback(async () => {
    const response = await API.listAgents();
    if (response) setAgents(response);
  }, []);

  const requestOffices = useCallback(async () => {
    const response = await API.listOfficeLocations(false);
    if (response) setOffices(response);
  }, []);

  const getTrusteeOffices = useCallback(async () => {
    const offices = await API.officeLocationsGET(
      ...argumentifyOfficeLocationsSearchCriteria({ maxResultCount: MAX_PAGE_SIZE })
    );
    if (offices?.items)
      setOfficeLocationsList(
        offices?.items
          .filter((f) => f.isTrusteeOffice)
          .map((item) => new ClientService.LookupDto({ id: item.id, displayName: item.name }))
      );
  }, []);

  const requestFilingTypes = useCallback(async () => {
    const response = await API.listFilingTypes();
    if (response) setFilingTypes(response);
  }, []);

  useEffect(() => {
    if (applicationFileId) requestGeneralGET(applicationFileId);
  }, [applicationFileId, requestGeneralGET]);

  useEffect(() => {
    if (!agents) requestAgents();
  }, [agents, requestAgents]);

  useEffect(() => {
    if (!offices) requestOffices();
  }, [offices, requestOffices]);

  useEffect(() => {
    if (!officeLocationsList) getTrusteeOffices();
  }, [officeLocationsList, getTrusteeOffices]);

  useEffect(() => {
    if (!filingTypes) requestFilingTypes();
  }, [filingTypes, requestFilingTypes]);

  return (
    <Modal
      destroyOnClose
      centered
      visible
      title={t.UPDATE_INFORMATION}
      className="UpdateInfoModal"
      width={750}
      footer={null}
      closable
      onCancel={onCancel}
    >
      <Spin spinning={loading}>
        <Form form={form} layout="vertical" onFinish={requestGeneralPUT}>
          <Row gutter={24}>
            <Col span={12}>
              <Form.Item label={t.AGENT} name="agentId" rules={[{ required: true, message: t.REQUIRED_FIELD }]}>
                <SelectLookupDto data={agents} placeholder={t.SELECT_AGENT} showSearch />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label={t.ESTATE_ADMINISTRATOR}
                name="estateAdministratorId"
                rules={[{ required: true, message: t.REQUIRED_FIELD }]}
              >
                <SelectLookupDto data={agents} placeholder={t.SELECT_ESTATE_ADMINISTRATOR} showSearch />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label={t.INTERVIEWER}
                name="interviewerId"
                rules={[{ required: true, message: t.REQUIRED_FIELD }]}
              >
                <SelectLookupDto data={agents} placeholder={t.SELECT_INTERVIEWER} showSearch />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label={t.FILING_TYPE}
                name="filingType"
                rules={[{ required: true, message: t.REQUIRED_FIELD }]}
              >
                <Select size="large" suffixIcon={<SelectSuffixIcon />} placeholder={t.SELECT_FILING_TYPE}>
                  {filingTypes?.map((item, index) => (
                    <Option key={`filingType-${String(index)}`} value={item.enumValue}>
                      {item.name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label={t.TRUSTEE} name="trusteeId" rules={[{ required: true, message: t.REQUIRED_FIELD }]}>
                <SelectLookupDto data={agents} placeholder={t.SELECT_TRUSTEE} showSearch />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label={t.SERVICE_LOCATION}
                name="serviceLocationId"
                rules={[{ required: true, message: t.REQUIRED_FIELD }]}
              >
                <SelectLookupDto data={offices} placeholder={t.SELECT_SERVICE_LOCATION} showSearch />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label={t.OFFICE_IS_TRUSTEE_OFFICE}
                name="trusteeOfficeId"
                rules={[{ required: true, message: t.REQUIRED_FIELD }]}
              >
                <SelectLookupDto data={officeLocationsList} placeholder={t.OFFICE_IS_TRUSTEE_OFFICE} showSearch />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={20} justify="end" className="UpdateInfoModal__footer">
            <Col>
              <Form.Item>
                <Button kind="cancel" onClick={onCancel}>
                  {t.CANCEL}
                </Button>
              </Form.Item>
            </Col>
            <Col>
              <Form.Item>
                <Button kind="primary" htmlType="submit">
                  {t.SAVE}
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Spin>
    </Modal>
  );
};

export default UpdateInfoModal;
