import useLocale from '../../hooks/useLocale';
import { useState } from 'react';
import { Col, Form, Modal, Row, Spin } from 'antd';
import Button from '../../components/Button/Button';

interface IProps {
  onYes: () => void;
  onNo: () => void;
  isUserRegistered?: boolean;
}

const PasswordResetConfirmation = ({ onYes, onNo, isUserRegistered }: IProps): JSX.Element => {
  const { t } = useLocale();
  const [loading, setLoading] = useState(false);

  return (
    <Modal
      destroyOnClose
      centered
      width={440}
      visible
      title={t.CONFIRMATION}
      onOk={onYes}
      okText={t.YES}
      onCancel={onNo}
      footer={null}
      cancelText={t.NO}
    >
      <Spin spinning={loading}>
        <Form
          layout="vertical"
          onFinish={() => {
            setLoading(true);
            onYes();
          }}
        >
          <Row align="middle" justify="center" style={{ paddingTop: 24 }}>
            <p style={{ textAlign: 'center', fontSize: 16 }}>
              {isUserRegistered ? t.DEBTOR_PROFILE_RESET_PASSWORD_MESSAGE : t.DEBTOR_PROFILE_REGISTRATION_MESSAGE}
            </p>
          </Row>
          <Row align="middle" justify="center" gutter={10} style={{ paddingTop: 24 }}>
            <Col>
              <Form.Item noStyle>
                <Button kind="cancel" onClick={onNo}>
                  {t.NO}
                </Button>
              </Form.Item>
            </Col>
            <Col>
              <Form.Item noStyle>
                <Button kind="primary" htmlType="submit">
                  {t.YES}
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Spin>
    </Modal>
  );
};

export default PasswordResetConfirmation;
