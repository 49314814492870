import { useCallback } from 'react';
import addIcon from '../../../images/add_icon.svg';

import useLocale from '../../../hooks/useLocale';

import './ApplicationOverviewContent.scss';

interface IPanelExtra {
  onViewHistory?: () => void;
  addItemTitle?: string;
  isActive?: boolean;
  onAddItem?: () => void;
  onExpand?: () => void;
  addAllowed?: boolean;
}

function PanelExtra({
  addItemTitle,
  onViewHistory,
  onAddItem,
  onExpand,
  isActive = false,
  addAllowed = true,
}: IPanelExtra): JSX.Element {
  const { t } = useLocale();

  const handleViewCallback = useCallback(
    (event) => {
      event.stopPropagation();
      if (onViewHistory) onViewHistory();
    },
    [onViewHistory]
  );

  const handleAddCallback = useCallback(
    (event) => {
      if (isActive) event.stopPropagation();
      if (onAddItem) onAddItem();
    },
    [onAddItem, isActive]
  );

  return (
    <div className="ApplicationOverviewContent__extra-container">
      <div className="ApplicationOverviewContent__extra-inner-container">
        <div className="ApplicationOverviewContent__extra-btn" onClick={handleViewCallback}>
          {t.VIEW_HISTORY}
        </div>
        {addAllowed && (
          <div
            className="ApplicationOverviewContent__extra-btn ApplicationOverviewContent__extra-btn-add"
            onClick={handleAddCallback}
          >
            {addItemTitle}
          </div>
        )}
      </div>
      {addAllowed && (
        <div onClick={onExpand}>
          <img src={addIcon} alt="add-icon" width={15} height={15} onClick={handleAddCallback} />
        </div>
      )}
    </div>
  );
}

export default PanelExtra;
