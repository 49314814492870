import { useMemo } from 'react';
import { Input, Form, Row, Col, Typography } from 'antd';
import update from 'immutability-helper';
import { Icon } from '@fluentui/react/lib/Icon';

import PrefixesWithLoad from '../PrefixesWithLoad/PrefixesWithLoad';
import FoldersWithLoad from '../FoldersWithLoad/FoldersWithLoad';
import { FilesColumnsProps } from '../types';
import { convertUndefinedToTrue, getColumnWidth, getNameColumnWidth } from '../utils';

import useLocale from '../../../hooks/useLocale';

import '../AttachmentsTab.scss';

interface IProps {
  remove: (rowName: number) => void;
  rowName: number;
  hasLabels?: boolean;
  columnsProps?: FilesColumnsProps;
}

const NewFileRow = ({ remove, rowName, hasLabels = false, columnsProps }: IProps) => {
  const { t, getLocalizedDocumentName } = useLocale();
  const { Text } = Typography;

  const columnWidth = useMemo(() => getColumnWidth(columnsProps), [columnsProps]);

  return (
    <Form.Item noStyle shouldUpdate>
      {({ getFieldValue, setFieldsValue, getFieldsValue }) => {
        return (
          <Row gutter={20} align="middle" key={`new-file-${rowName}`}>
            <Col span={getNameColumnWidth(columnsProps)} className="AttachmentsTab__file-name">
              <Form.Item label={hasLabels && ' '}>
                <Text ellipsis>{getLocalizedDocumentName(getFieldValue(['newFiles', rowName]))}</Text>
              </Form.Item>
            </Col>

            <Col span={convertUndefinedToTrue(columnsProps?.hasAdditionalInfoColumn) ? columnWidth : 0}>
              <Form.Item label={hasLabels && t.DOCUMENTS_ADDITIONAL_DETAILS} name={[rowName, 'additionalInformation']}>
                <Input
                  size="large"
                  onChange={() => {
                    if (getFieldValue('bulkAdditionalInfo')) {
                      setFieldsValue({ bulkAdditionalInfo: undefined });
                    }
                  }}
                />
              </Form.Item>
            </Col>

            <Col span={convertUndefinedToTrue(columnsProps?.hasFolderColumn) ? columnWidth : 0}>
              <Form.Item
                label={hasLabels && t.DOCUMENTS_FOLDER}
                rules={[{ required: true, message: t.FOLDER_IS_REQUIRED }]}
                name={[rowName, 'documentFolderId']}
              >
                <FoldersWithLoad
                  onSelect={() =>
                    setFieldsValue(
                      update(getFieldsValue(), {
                        newFiles: {
                          [rowName]: { $merge: { documentPrefixId: undefined } },
                        },
                        bulkFolderId: { $set: undefined },
                        bulkPrefixId: { $set: undefined },
                      })
                    )
                  }
                />
              </Form.Item>
            </Col>

            <Col span={convertUndefinedToTrue(columnsProps?.hasPrefixColumn) ? columnWidth : 0}>
              <Form.Item noStyle shouldUpdate>
                {({ getFieldValue }) => {
                  return (
                    <Form.Item
                      label={hasLabels && t.DOCUMENTS_PREFIX}
                      name={[rowName, 'documentPrefixId']}
                      rules={[{ required: true, message: t.PREFIX_IS_REQUIRED }]}
                    >
                      <PrefixesWithLoad documentFolderId={getFieldValue(['newFiles', rowName, 'documentFolderId'])} />
                    </Form.Item>
                  );
                }}
              </Form.Item>
            </Col>

            <Col span={1}>
              {convertUndefinedToTrue(columnsProps?.hasDeleteButton) && (
                <Form.Item label={hasLabels && ' '}>
                  <Icon iconName="Delete" onClick={() => remove(rowName)} className="AttachmentsTab__icon" />
                </Form.Item>
              )}
            </Col>
          </Row>
        );
      }}
    </Form.Item>
  );
};

export default NewFileRow;
