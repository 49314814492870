import { useCallback, useEffect, useState, useMemo } from 'react';
import { Modal, Form, Select, Col, Row, RadioChangeEvent } from 'antd';
import update from 'immutability-helper';

import useLocale from '../../../../../../hooks/useLocale';

import Button from '../../../../../../components/Button/Button';
import RadioGroup from '../RadioGroup';
import SelectSuffixIcon from '../../../../../../components/SelectSuffixIcon/SelectSuffixIcon';

import genericMessage from '../../../../../../utils/genericMessage';
import { ClientService } from '../../../../../../shared/api/ClientService';
import API from '../../../../../../utils/api';

import './ChecklistModal.scss';

interface IProps {
  applicationFileId?: string;
  checklistType?: ClientService.ChecklistTypeEnum;
  onOk?: () => void;
  onCancel?: () => void;
}

interface IRadio extends ClientService.ChecklistItemDto {
  value?: boolean;
}

interface IForm extends ClientService.ChecklistUpdateDto {
  checklistItems?: IRadio[] | undefined;
}

const UpdateInfoModal = ({ applicationFileId, checklistType, onOk, onCancel }: IProps) => {
  const [form] = Form.useForm<IForm>();
  const { Option } = Select;
  const { t } = useLocale();

  const [initialValues, setInitialValues] = useState<ClientService.ChecklistDto>();

  const CHECKLIST_STATUS_DROPDOWN_OPTIONS = useMemo(
    () => [
      { value: ClientService.ChecklistStatusEnum.Pending, label: t.PENDING, disabled: false },
      { value: ClientService.ChecklistStatusEnum.Incomplete, label: t.INCOMPLETE, disabled: true },
      { value: ClientService.ChecklistStatusEnum.Complete, label: t.COMPLETE, disabled: false },
    ],
    [t.COMPLETE, t.INCOMPLETE, t.PENDING]
  );

  const handleFillOptionDropdown = useCallback(
    (signupStatus?: number) => {
      const options = [...CHECKLIST_STATUS_DROPDOWN_OPTIONS];
      switch (signupStatus) {
        case ClientService.ChecklistStatusEnum.Pending:
          return options?.splice(0, 1);
        case ClientService.ChecklistStatusEnum.Incomplete:
          return options?.splice(1, 2);
        case ClientService.ChecklistStatusEnum.Complete:
          return options?.splice(2, 1);
        default:
          return options?.splice(0, 1);
      }
    },
    [CHECKLIST_STATUS_DROPDOWN_OPTIONS]
  );

  const checkIfCompleted = useCallback(
    (values?: IRadio[]) => values?.filter((item: IRadio) => item.value === undefined)?.length === 0,
    []
  );

  const checkIfStarted = useCallback(
    (values?: IRadio[]) => +Boolean(values?.filter((item: IRadio) => item.value !== undefined)?.length) > 0,
    []
  );

  const setInitialFormValues = useCallback(
    (initialValues: ClientService.ChecklistDto) => {
      const checklistItems = initialValues?.checklistItems?.map(
        (item) => ({ ...item, value: item?.isNotApplicable ? false : item.isChecked ? true : undefined } as IRadio)
      );

      form.setFieldsValue({
        ...initialValues,
        checklistItems,
      });
    },
    [form]
  );

  const requestChecklist = useCallback(
    async (applicationFileId: string, checklistTypeEnum: ClientService.ChecklistTypeEnum) => {
      const response = await API.checklistGET(applicationFileId, checklistTypeEnum);

      if (response) {
        setInitialValues({ ...response } as ClientService.ChecklistDto);
        setInitialFormValues({ ...response } as IForm);
      }
    },
    [setInitialFormValues]
  );

  const requestChecklistUpdate = useCallback(
    async ({ checklistStatus, checklistItems }) => {
      const body = checklistItems?.map((item: IRadio) => ({
        id: item?.id,
        isChecked: item?.value === true || item?.isChecked,
        isNotApplicable: item?.value === false || item?.isNotApplicable,
      }));

      if (applicationFileId) {
        const response = await API.checklistPUT(applicationFileId, {
          id: initialValues?.id,
          checklistStatus,
          checklistItems: body,
        } as ClientService.ChecklistUpdateDto).catch((e) => console.log(e));

        if (response?.result === ClientService.Result.Successful) {
          genericMessage.success(t.CHECKLIST_UPDATED);
          if (onOk) onOk();
        } else {
          genericMessage.error({}, response?.messages?.[0]?.body);
        }
      }
    },
    [applicationFileId, initialValues?.id, onOk, t.CHECKLIST_UPDATED]
  );

  const handleRadioChange = useCallback(
    (rowName: number) => (e: RadioChangeEvent) => {
      form.setFieldsValue(
        update(form.getFieldsValue(), {
          checklistItems: {
            [rowName]: { value: { $set: e?.target?.value } },
          },
        })
      );
    },
    [form]
  );

  const handleValidation = useCallback(() => {
    const values = form.getFieldsValue();
    if (
      values.checklistStatus === ClientService.ChecklistStatusEnum.Pending &&
      !checkIfStarted(values?.checklistItems)
    ) {
      genericMessage.error({}, t.PLEASE_SELECT_TO_SAVE_THE_CHANGES);
      return false;
    }

    if (
      values.checklistStatus === ClientService.ChecklistStatusEnum.Complete &&
      !checkIfCompleted(values?.checklistItems)
    ) {
      genericMessage.error({}, t.YOU_STILL_HAVE_ONE_OR_MORE_PENDING_CHECKLIST_ITEMS);
      return false;
    }

    return true;
  }, [
    checkIfCompleted,
    checkIfStarted,
    form,
    t.PLEASE_SELECT_TO_SAVE_THE_CHANGES,
    t.YOU_STILL_HAVE_ONE_OR_MORE_PENDING_CHECKLIST_ITEMS,
  ]);

  const onFinish = useCallback(
    (values: IForm) => {
      if (handleValidation()) {
        requestChecklistUpdate(values);
      }
    },
    [handleValidation, requestChecklistUpdate]
  );

  useEffect(() => {
    if (applicationFileId && checklistType) requestChecklist(applicationFileId, checklistType);
  }, [applicationFileId, checklistType, requestChecklist]);

  return (
    <Modal
      destroyOnClose
      centered
      visible
      title={t.SIGNUP_DOCUMENT_CHECKLISTS}
      className="ChecklistModal"
      width={980}
      footer={null}
      closable
      onCancel={onCancel}
    >
      <Form form={form} layout="horizontal" onFinish={onFinish}>
        <Row gutter={24}>
          <Col span={24}>
            <Row align="middle">
              <Col span={16} className="ChecklistModal__filing-type">
                <Form.Item label={t.FILING_TYPE}>{initialValues?.filingType?.name}</Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item noStyle shouldUpdate>
                  {({ getFieldValue }) => (
                    <Form.Item
                      name="checklistStatus"
                      label={t.CHECKLIST_STATUS}
                      rules={[{ required: true, message: t.CHECKLIST_STATUS_IS_REQUIRED }]}
                    >
                      <Select size="large" suffixIcon={<SelectSuffixIcon />}>
                        {handleFillOptionDropdown(
                          initialValues?.checklistStatus === ClientService.ChecklistStatusEnum.Incomplete &&
                            checkIfCompleted(getFieldValue('checklistItems'))
                            ? ClientService.ChecklistStatusEnum.Complete
                            : initialValues?.checklistStatus
                        )?.map((item) => (
                          <Option value={item.value} key={item.value} disabled={item.disabled}>
                            {item.label}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  )}
                </Form.Item>
              </Col>
            </Row>
            <Form.List name="checklistItems">
              {(fields) => (
                <div className="ChecklistModal__list">
                  {fields?.map((row, index) => (
                    <Form.Item noStyle shouldUpdate key={`checklistItem-${index}`}>
                      {({ getFieldValue }) => {
                        const checklistItem = getFieldValue(['checklistItems', row.name]);
                        return (
                          <Form.Item
                            label={checklistItem?.label}
                            labelCol={{ span: 16 }}
                            className="ChecklistModal__list-item"
                          >
                            <RadioGroup
                              noText={t.NA}
                              value={checklistItem?.value}
                              onChange={handleRadioChange(row.name)}
                            />
                          </Form.Item>
                        );
                      }}
                    </Form.Item>
                  ))}
                </div>
              )}
            </Form.List>
          </Col>
        </Row>
        <Row gutter={20} justify="end" className="ChecklistModal__footer">
          <Col>
            <Form.Item>
              <Button kind="cancel" onClick={onCancel}>
                {t.CANCEL}
              </Button>
            </Form.Item>
          </Col>
          <Col>
            <Form.Item>
              <Button kind="primary" htmlType="submit">
                {t.SAVE}
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

export default UpdateInfoModal;
