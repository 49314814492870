import { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Form, InputNumber, Row, Col, Spin, Popover, Checkbox } from 'antd';
import { Icon } from '@fluentui/react';

import CurrencyInput from './CurrencyInput';

import { currencyParser, handleCurrencyValidation, handlePositiveOnlyNumberValidation } from '../utils';
import { NUNVUT_PROVINCE_CODE } from '../constants';
import { ClientService } from '../../../../shared/api/ClientService';
import API from '../../../../utils/api';
import eventBus from '../../../../utils/eventBus';
import useLocale from '../../../../hooks/useLocale';

import '../FileAnalysis.scss';

interface IProps {
  taxTypeLabel?: string;
  disabled?: boolean;
}

function BankruptcyDetails({ taxTypeLabel, disabled }: IProps): JSX.Element {
  const { applicationFileId } = useParams<{ applicationFileId: string }>();
  const [form] = Form.useForm<ClientService.FileAnalysisBankruptcyDetailDto>();
  const { t } = useLocale();

  const [bankruptcyDetailsLoading, setBankruptcyDetailsLoading] = useState<boolean>(false);
  const [bankruptcyDetailsUpdating, setBankruptcyDetailsUpdating] = useState<boolean>(false);

  const [personalInfo, setPersonalInfo] = useState<ClientService.AppFormPersonalInfoDto>();
  const [personalInfoLoading, setPersonalInfoLoading] = useState<boolean>(false);

  const [listCountries, setListCountries] = useState<ClientService.LookupDto[]>();
  const [listProvinces, setListProvinces] = useState<ClientService.LookupDto[]>();

  const [initialValues, setInitialValues] = useState<ClientService.FileAnalysisBankruptcyDetailDto>();

  const requestFileAnalysisBankruptcyDetails = useCallback(async () => {
    setBankruptcyDetailsLoading(true);

    const response = await API.getFileAnalysisBankruptcyDetails(applicationFileId).catch(() =>
      setBankruptcyDetailsLoading(false)
    );
    if (response) {
      form.setFieldsValue({
        ...response,
        isOtherCostsSubjectToTax:
          response?.isOtherCostsSubjectToTax === null ? true : response?.isOtherCostsSubjectToTax,
        stdMultipleForNunavut: response.stdMultipleForNunavut || 0,
      });
      setInitialValues(response);

      eventBus.dispatch('dataEntryFormValuesChanged');
    }

    setBankruptcyDetailsLoading(false);
  }, [applicationFileId, form]);

  const requestAppFormPersonalInfo = useCallback(async () => {
    setPersonalInfoLoading(true);
    const response = await API.getAppFormPersonalInfo(applicationFileId).catch(() => setPersonalInfoLoading(false));
    if (response) setPersonalInfo(response);

    setPersonalInfoLoading(false);
  }, [applicationFileId]);

  const requestListCountries = useCallback(async () => {
    const response = await API.listCountries();
    if (response) setListCountries(response);
  }, []);

  const requestListProvinces = useCallback(async (countryId) => {
    const response = await API.listProvinces(countryId);
    if (response) setListProvinces(response);
  }, []);

  const requestUpdateFileAnalysisBankruptcyDetails = useCallback(
    async (updatedBankruptcyDetails: ClientService.FileAnalysisBankruptcyDetailDto) => {
      setBankruptcyDetailsUpdating(true);

      const response = await API.updateFileAnalysisBankruptcyDetails(updatedBankruptcyDetails).catch(() =>
        setBankruptcyDetailsUpdating(false)
      );

      if (response?.result === ClientService.Result.Successful) {
        requestFileAnalysisBankruptcyDetails();
      }

      setBankruptcyDetailsUpdating(false);
    },
    [requestFileAnalysisBankruptcyDetails]
  );

  useEffect(() => {
    requestFileAnalysisBankruptcyDetails();
  }, [requestFileAnalysisBankruptcyDetails]);

  useEffect(() => {
    if (!personalInfo) requestAppFormPersonalInfo();
  }, [personalInfo, requestAppFormPersonalInfo]);

  useEffect(() => {
    if (!listCountries) requestListCountries();
  }, [listCountries, requestListCountries]);

  useEffect(() => {
    if (!listProvinces && personalInfo?.countryId) requestListProvinces(personalInfo?.countryId);
  }, [listProvinces, requestListProvinces, personalInfo?.countryId]);

  const onFinish = useCallback(
    async (values) => {
      const updatedProposalDetails = {
        ...values,
        fileId: applicationFileId,
        ordinaryFee: currencyParser(values?.ordinaryFee),
        legalFee: currencyParser(values?.legalFee),
        otherCosts: currencyParser(values?.otherCosts),
        stdMultipleForNunavut:
          listProvinces?.find((item) => item.id === personalInfo?.provinceId)?.code === NUNVUT_PROVINCE_CODE
            ? currencyParser(values?.stdMultipleForNunavut)
            : undefined,
        stdVoluntaryPayments: currencyParser(values?.stdVoluntaryPayments),
        allowableMonthlyNetIncome: Number(values?.allowableMonthlyNetIncome),
      };

      requestUpdateFileAnalysisBankruptcyDetails(updatedProposalDetails);
    },
    [applicationFileId, listProvinces, personalInfo?.provinceId, requestUpdateFileAnalysisBankruptcyDetails]
  );

  const handleSubmit = useCallback(() => {
    form.submit();
  }, [form]);

  const handleEnter = useCallback(
    (event: any) => {
      if (event.key === 'Enter') {
        form.submit();
      }
    },
    [form]
  );

  const isNunvutProvince =
    listProvinces?.find((item) => item.id === personalInfo?.provinceId)?.code === NUNVUT_PROVINCE_CODE;

  return (
    <Spin spinning={bankruptcyDetailsLoading || bankruptcyDetailsUpdating || personalInfoLoading}>
      <Form form={form} layout="vertical" className="FileAnalysis__form" onFinish={onFinish} validateTrigger="onSubmit">
        {(_, formInstance) => (
          <>
            <Row gutter={12}>
              <Col span={24}>
                <Form.Item
                  label={t.FEES_FOR_ORDINARY}
                  name="ordinaryFee"
                  rules={[{ validator: handleCurrencyValidation }]}
                >
                  <CurrencyInput
                    onBlur={() => {
                      if (form.getFieldValue('ordinaryFee') !== initialValues?.ordinaryFee) {
                        handleSubmit();
                      }
                    }}
                    onKeyUp={handleEnter}
                    disabled={disabled}
                  />
                </Form.Item>
              </Col>

              <Col span={24}>
                <Form.Item label={t.LEGAL_FEES} name="legalFee" rules={[{ validator: handleCurrencyValidation }]}>
                  <CurrencyInput
                    onBlur={() => {
                      if (form.getFieldValue('legalFee') !== initialValues?.legalFee) {
                        handleSubmit();
                      }
                    }}
                    onKeyUp={handleEnter}
                    disabled={disabled}
                  />
                </Form.Item>
              </Col>

              <Col span={24}>
                <Form.Item
                  label={
                    <Row justify="space-between" align="middle" style={{ display: 'flex', flex: 1 }}>
                      <Col>{t.OTHER_COSTS}</Col>
                      <Col>
                        <Form.Item name="isOtherCostsSubjectToTax" valuePropName="checked" noStyle>
                          <Checkbox disabled={disabled} onChange={handleSubmit}>{`${t.OTHER_COSTS_SUBJECT_TO} ${
                            taxTypeLabel || t.TAX
                          }`}</Checkbox>
                        </Form.Item>
                      </Col>
                    </Row>
                  }
                  className="FileAnalysis__form--label-with-checkbox"
                  name="otherCosts"
                  trigger="onChange"
                  rules={[{ validator: handleCurrencyValidation }]}
                >
                  <CurrencyInput
                    onBlur={() => {
                      if (form.getFieldValue('otherCosts') !== initialValues?.otherCosts) {
                        handleSubmit();
                      }
                    }}
                    onKeyUp={handleEnter}
                    disabled={disabled}
                  />
                </Form.Item>
              </Col>

              <Col span={24}>
                <Form.Item
                  label={t.STANDARD_VOLUNTARY_PAYMENTS}
                  name="stdVoluntaryPayments"
                  rules={[{ validator: handleCurrencyValidation }]}
                >
                  <CurrencyInput
                    onBlur={() => {
                      if (form.getFieldValue('stdVoluntaryPayments') !== initialValues?.stdVoluntaryPayments) {
                        handleSubmit();
                      }
                    }}
                    onKeyUp={handleEnter}
                    disabled={disabled}
                  />
                </Form.Item>
              </Col>

              {isNunvutProvince && (
                <Col span={24}>
                  <Form.Item
                    label={t.STANDARD_MULTIPLE_FOR_NUNAVUT}
                    name="stdMultipleForNunavut"
                    rules={[{ validator: handlePositiveOnlyNumberValidation }]}
                    validateTrigger="onChange"
                  >
                    <InputNumber
                      size="large"
                      precision={0}
                      controls={false}
                      min={0}
                      max={999}
                      onBlur={() => {
                        if (form.getFieldValue('stdMultipleForNunavut') !== initialValues?.stdMultipleForNunavut) {
                          handleSubmit();
                        }
                      }}
                      onKeyUp={handleEnter}
                      disabled={disabled}
                    />
                  </Form.Item>
                </Col>
              )}

              <Col span={24}>
                <Form.Item
                  label={
                    <Row align="middle" gutter={8}>
                      <Col>{t.ALLOWABLE_MONTHLY_NET_INCOME_PER_STANDARD}</Col>
                      <Col>
                        <Popover
                          content={
                            <div>{`${t.BASED_OFF} ${personalInfo?.numberOfPersonsInHousehold || 1} ${
                              t.PERSONS_IN_HOUSEHOLD
                            }`}</div>
                          }
                          trigger="hover"
                          placement="bottom"
                        >
                          <Icon iconName="Info" className="FileAnalysis__form--tooltip-icon" />
                        </Popover>
                      </Col>
                    </Row>
                  }
                  name="allowableMonthlyNetIncome"
                  rules={[{ validator: handleCurrencyValidation }]}
                >
                  <CurrencyInput
                    disabled={!formInstance.getFieldValue('isOverrideMonthlyNetIncome') || disabled}
                    className="FileAnalysis__form--available-net-income"
                    onBlur={() => {
                      if (
                        form.getFieldValue('allowableMonthlyNetIncome') !== initialValues?.allowableMonthlyNetIncome
                      ) {
                        handleSubmit();
                      }
                    }}
                    onKeyUp={handleEnter}
                  />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item name="isOverrideMonthlyNetIncome" valuePropName="checked" style={{ marginTop: -24 }}>
                  <Checkbox onChange={handleSubmit} disabled={disabled}>
                    {t.OVERRIDE}
                  </Checkbox>
                </Form.Item>
              </Col>
            </Row>
          </>
        )}
      </Form>
    </Spin>
  );
}

export default BankruptcyDetails;
