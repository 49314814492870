import { getAsSystemColorOrUnchanged } from '../../utils/systemColors';
import { ClientService } from '../../shared/api/ClientService';
import { getEnumTextValuesAsArray } from '../../utils/helpers';

const getStateValueAsElement = (stateValue: any, stateNames: string[]) => {
  const stateColorMap: { [key: string]: string } = {
    inactive: 'gray-text',
    active: 'orange',
    duplicate: 'blue-special',
    complete: 'green',
  };

  const stateName = stateValue != null ? stateNames[stateValue] : undefined;
  const colorName = stateName != null ? stateColorMap[stateName.toLowerCase()] : 'black';
  const color = getAsSystemColorOrUnchanged(colorName);

  return (
    <div
      style={{
        color: color,
        fontWeight: 'bold',
        textTransform: 'capitalize',
      }}
    >
      {stateName}
    </div>
  );
};

export const getClaimStateValueAsElement = (stateValue: ClientService.ClaimFileStateEnum | undefined): JSX.Element => {
  const claimFileStateNames = getEnumTextValuesAsArray(ClientService.ClaimFileStateEnum);

  return getStateValueAsElement(stateValue, claimFileStateNames);
};

export const getSubmissionStateValueAsElement = (
  stateValue: ClientService.IncomeExpenseSubmissionStateEnum | undefined
): JSX.Element => {
  const submissionStateNames = getEnumTextValuesAsArray(ClientService.IncomeExpenseSubmissionStateEnum);

  return getStateValueAsElement(stateValue, submissionStateNames);
};

export const getTaxReturnSubmissionStateValueAsElement = (
  stateValue: ClientService.TaxReturnSubmissionStateEnum | undefined
): JSX.Element => {
  const taxReturnSubmissionStateNames = getEnumTextValuesAsArray(ClientService.TaxReturnSubmissionStateEnum);

  return getStateValueAsElement(stateValue, taxReturnSubmissionStateNames);
};
