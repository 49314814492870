import { useCallback, useState, useEffect } from 'react';
import { Row, Col, Form, Modal, Select, Spin, Empty } from 'antd';

import Button from '../../../../components/Button/Button';
import FileRow from '../../../../components/AttachmentsTab/FileRow/FileRow';

import { ClientService } from '../../../../shared/api/ClientService';
import API from '../../../../utils/api';
import useLocale from '../../../../hooks/useLocale';
import { IDebtorSearchCriteria, ICopyOptionSource } from '../types';
import { argumentifyDebtorSearchParams, readableOptionName, argumentifyCopyDocument } from '../utils';
import { MAX_PAGE_SIZE } from '../../../../constants/common';
import useDebounce from '../../../../hooks/useDebounce';

import './CopyFilesModal.scss';

interface IProps {
  fileId?: string;
  documents?: ClientService.IDocumentDto[];
  onOk?: () => void;
  onCancel?: () => void;
}

interface IForm {
  destinationFileId?: string;
  files: ClientService.IDocumentDto[];
}

function CopyFilesModal({ fileId, documents, onOk, onCancel }: IProps): JSX.Element {
  const { t } = useLocale();
  const { Option } = Select;
  const [form] = Form.useForm<IForm>();

  const [criteria, setCriteria] = useState<IDebtorSearchCriteria>({
    searchString: '',
  });

  const [source, setSource] = useState<ICopyOptionSource[]>();

  const [copying, setCopying] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  const [searchString, setSearchString] = useState<string>();
  const value = useDebounce(searchString);

  const handleSearch = useCallback((searchString) => {
    setSearchString(searchString);
  }, []);

  const requestDebtors = useCallback(async (criteria: IDebtorSearchCriteria) => {
    if (!criteria?.searchString || (criteria?.searchString && criteria?.searchString?.length < 3)) return;

    setLoading(true);
    setSource(undefined);

    const response = await API.getDebtorSearchResults(
      ...argumentifyDebtorSearchParams({ ...criteria, pageSize: MAX_PAGE_SIZE })
    ).catch(() => setLoading(false));
    setLoading(false);

    if (response?.clients) {
      const result = response?.clients
        ?.map((item) =>
          item?.files?.map((file) => ({
            ...file,
            clientId: item?.clientId,
            firstName: item?.firstName,
            lastName: item?.lastName,
          }))
        )
        .flat() as ICopyOptionSource[];

      setSource(result || []);
    }
  }, []);

  useEffect(() => {
    requestDebtors(criteria);
  }, [criteria, requestDebtors]);

  useEffect(() => {
    setCriteria((prev) => ({ ...prev, searchString: value }));
  }, [value]);

  const handleSubmit = useCallback(
    async (values) => {
      const { files, destinationFileId } = values;

      const ids = files?.map((file: ClientService.IDocumentDto) => file.id);

      setCopying(true);

      for (let id of ids) {
        await API.copy(
          ...argumentifyCopyDocument({
            id: id as string,
            body: { destinationFileId: destinationFileId as string } as ClientService.DocumentCopyDto,
          })
        ).catch(() => setCopying(false));
      }

      if (onOk) onOk();
      setCopying(false);
    },
    [onOk]
  );

  return (
    <Modal
      destroyOnClose
      centered
      visible
      title={t.COPY_FILES}
      className="CopyFilesModal"
      width={900}
      footer={null}
      closable
      onCancel={onCancel}
    >
      <Spin spinning={copying}>
        <Form form={form} layout="vertical" onFinish={handleSubmit}>
          {(_, formInstance) => (
            <>
              <Row gutter={20} align="middle">
                <Col span={24}>
                  <Form.Item
                    label={t.SELECT_A_DEBTOR}
                    rules={[{ required: true, message: t.REQUIRED_FIELD }]}
                    name="destinationFileId"
                  >
                    <Select
                      showSearch
                      placeholder={t.SEARCH_DEBTOR_PLACEHOLDER}
                      showArrow={false}
                      filterOption={false}
                      onSearch={handleSearch}
                      notFoundContent={
                        loading ? <Spin spinning={loading} /> : <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                      }
                    >
                      {source?.map((item) => (
                        <Option key={item?.clientId + item.fileId} value={item?.fileId}>
                          {readableOptionName(item)}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>

              <Form.List name="files" initialValue={documents}>
                {(fields, { add, remove }) => (
                  <div className="CopyFilesModal__list">
                    {fields?.map((field, index) => (
                      <FileRow
                        hasLabels={index === 0}
                        key={`copyFile-${index}`}
                        name={index}
                        file={formInstance?.getFieldsValue()?.files?.[field?.name]}
                        onDelete={() => remove(field?.name)}
                        columnsProps={{ hasDeleteButton: fields?.length > 1 }}
                      />
                    ))}
                  </div>
                )}
              </Form.List>

              <Row gutter={12} justify="end" className="CopyFilesModal__buttons-container">
                <Col>
                  <Form.Item>
                    <Button kind="cancel" padding="large" onClick={onCancel}>
                      {t.CANCEL}
                    </Button>
                  </Form.Item>
                </Col>
                <Col>
                  <Form.Item>
                    <Button kind="primary" padding="large" htmlType="submit">
                      {t.COPY}
                    </Button>
                  </Form.Item>
                </Col>
              </Row>
            </>
          )}
        </Form>
      </Spin>
    </Modal>
  );
}

export default CopyFilesModal;
