import { useState, useCallback, useEffect } from 'react';
import { Modal, Form, Select, Col, Row, Radio, Spin, Tabs } from 'antd';
import ReactQuill from 'react-quill';
import { useQuery } from 'react-query';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';

import Button from '../../../../components/Button/Button';
import AttachmentsTab, {
  AttachmentsFormProps,
  argumentifyDocumentsSearchCriteria,
} from '../../../../components/AttachmentsTab';
import BlockWithLabel from '../../../../components/BlockWithLabel/BlockWithLabel';
import SelectLookupDto from '../../../../components/SelectLookupDto/SelectLookupDto';
import SelectSuffixIcon from '../../../../components/SelectSuffixIcon/SelectSuffixIcon';

import genericMessage from '../../../../utils/genericMessage';
import useLocale from '../../../../hooks/useLocale';
import eventBus from '../../../../utils/eventBus';

import { DATE_AND_TIME_FORMAT2, MAX_PAGE_SIZE } from '../../../../constants/common';
import { AssignTypeEnum } from '../types';
import { NO_DATA } from '../constants';
import { UPLOAD_FILES_EVENT, TASK_ADDED_EVENT, TASK_UPDATE_EVENT } from '../../../../constants/eventBus';
import { AGENTS_QUERY, TEAMS_QUERY, USE_QUERY_OPTIONS } from '../../../../constants/reactQuery';
import { ROUTES } from '../../../../constants/routes';
import { replaceLineBreaksWithBrTag } from '../../../../utils/helpers';

import { ClientService } from '../../../../shared/api/ClientService';
import API from '../../../../utils/api';

import './TaskEditor.scss';

interface IFormCreate extends ClientService.AppTaskCreateDto, AttachmentsFormProps {
  assignType?: AssignTypeEnum;
}

interface IFormUpdate extends ClientService.AppTaskUpdateDto, AttachmentsFormProps {
  assignType?: AssignTypeEnum;
}

interface IProps {
  fileId: string;
  onNext?: () => void;
  onCancel?: () => void;
  fileStageId?: string;
  taskId?: string;
  showFileButton?: boolean;
}

const DETAILS_TAB = 'details';
const ATTACHMENTS_TAB = 'attachments';

const formTabs = [DETAILS_TAB, ATTACHMENTS_TAB];

function TaskEditor({ fileId, onNext, onCancel, fileStageId, taskId, showFileButton }: IProps): JSX.Element {
  const { t } = useLocale();
  const { Option } = Select;
  const { TabPane } = Tabs;
  const [form] = Form.useForm<IFormCreate | IFormUpdate>();

  const [fileProcessesLoading, setFileProcessingLoading] = useState<boolean>(false);
  const [fileProcesses, setFileProcesses] = useState<ClientService.LookupDto[]>();

  const [fileStagesLoading, setFileStagesLoading] = useState<boolean>(false);
  const [fileStages, setFileStages] = useState<ClientService.LookupDto[]>();

  const [appTaskNamesLoading, setAppTaskNamesLoading] = useState<boolean>(false);
  const [appTaskNames, setAppTaskNames] = useState<ClientService.LookupDto[]>();

  const [appTaskStatusesLoading, setAppTaskStatusesLoading] = useState<boolean>(false);
  const [appTaskStatuses, setAppTaskStatuses] = useState<ClientService.LookupDto[]>();

  const [taskCreateLoading, setTaskCreateLoading] = useState<boolean>(false);
  const [taskUpdateLoading, setTaskUpdateLoading] = useState<boolean>(false);

  const [task, setTask] = useState<ClientService.IAppTaskDto>();
  const [taskLoading, setTaskLoading] = useState<boolean>(false);

  const [defaultValues, setDefaultValues] = useState<IFormCreate | IFormUpdate>();
  const [activeTab, setActiveTab] = useState<string>(formTabs[0]);

  const { data: agents } = useQuery([AGENTS_QUERY], () => API.listAgents(), USE_QUERY_OPTIONS);
  const { data: teams } = useQuery([TEAMS_QUERY], () => API.listTeams(undefined), USE_QUERY_OPTIONS);

  const navigate = useNavigate();

  const requestFileStages = useCallback(async () => {
    setFileStagesLoading(true);
    const response = await API.listFileStages();
    if (response) setFileStages(response);
    setFileStagesLoading(false);
  }, []);

  const requestDocuments = useCallback(
    async (documentIds?: string[]) => {
      if (!documentIds || !documentIds?.length) return;

      const response = await API.documentsGET(
        ...argumentifyDocumentsSearchCriteria({ fileId, documentIds, maxResultCount: MAX_PAGE_SIZE })
      );

      if (response.items) {
        form.setFieldsValue({
          existingFiles: response.items,
        });
      }
    },
    [fileId, form]
  );

  const requestFileProcesses = useCallback(async () => {
    setFileProcessingLoading(true);
    const response = await API.listFileProcessesByFileStage(fileStageId).catch(() => setFileProcessingLoading(false));
    if (response) setFileProcesses(response);
    setFileProcessingLoading(false);
  }, [fileStageId]);

  const requestAppTaskNames = useCallback(
    async (fileProcessId) => {
      if (!fileProcessId) return;

      setAppTaskNamesLoading(true);
      const response = await API.listAppTaskNames(fileProcessId).catch(() => setAppTaskNamesLoading(false));
      if (response) {
        form.setFieldsValue({ appTaskNameId: '' });
        setAppTaskNames(response);
      }
      setAppTaskNamesLoading(false);
    },
    [form]
  );

  const requestAppTaskStatuses = useCallback(async () => {
    setAppTaskStatusesLoading(true);
    const response = await API.listAppTaskStatuses();
    if (response) setAppTaskStatuses(response);
    setAppTaskStatusesLoading(false);
  }, []);

  const requestGetTask = useCallback(
    async (taskId) => {
      setTaskLoading(true);
      const task = await API.tasksGET2(taskId).catch(() => setTaskLoading(false));
      setTaskLoading(false);

      if (task) {
        setTask(task);
        requestDocuments(task?.attachedDocumentIds);
      }
    },
    [requestDocuments]
  );

  const requestCreateTask = useCallback(
    async ({ fileProcessId, description, appTaskNameId, assignedAgentId, assignedTeamId, attachedDocumentIds }) => {
      const body = {
        fileId,
        fileProcessId,
        description,
        openDate: moment(),
        appTaskNameId,
        assignedAgentId,
        assignedTeamId,
        attachedDocumentIds,
      } as ClientService.AppTaskCreateDto;

      setTaskCreateLoading(true);
      const response = await API.tasksPOST(body).catch(() => setTaskCreateLoading(false));
      setTaskCreateLoading(false);

      if (response?.result === ClientService.Result.Successful) {
        eventBus.dispatch(TASK_ADDED_EVENT);
        genericMessage.success(t.TASK_CREATED_SUCCESS_MESSAGE);
        if (onNext) onNext();
      } else {
        genericMessage.error({}, response?.messages?.[0]?.body);
      }
    },
    [fileId, onNext, t.TASK_CREATED_SUCCESS_MESSAGE]
  );

  const requestUpdateTask = useCallback(
    async ({ appTaskStatusId, description, assignedTeamId, assignedAgentId, attachedDocumentIds }) => {
      const body = {
        description,
        appTaskStatus: appTaskStatuses?.find((item) => item.id === appTaskStatusId)?.enumValue,
        assignedAgentId,
        assignedTeamId,
        attachedDocumentIds,
      } as ClientService.AppTaskUpdateDto;

      setTaskUpdateLoading(true);
      const response = await API.tasksPUT(task?.id as string, body).catch(() => setTaskUpdateLoading(false));
      setTaskUpdateLoading(false);

      if (response?.result === ClientService.Result.Successful) {
        eventBus.dispatch(TASK_UPDATE_EVENT);
        genericMessage.success(t.TASK_UPDATED_SUCCESS_MESSAGE);
        if (onNext) onNext();
      } else {
        genericMessage.error({}, response?.messages?.[0]?.body);
      }
    },
    [appTaskStatuses, task?.id, t.TASK_UPDATED_SUCCESS_MESSAGE, onNext]
  );

  const handleValuesChange = useCallback(
    (values) => {
      if (values.fileProcessId) {
        requestAppTaskNames(values.fileProcessId);
      }

      Object.keys(values).forEach((field) => {
        const error = form.getFieldError(field);
        if (!error.length) {
          return;
        }
        form.setFields([
          {
            name: field,
            errors: [],
          },
        ]);
      });
    },
    [requestAppTaskNames, form]
  );

  const onFinish = useCallback(
    (values) => {
      eventBus.dispatch(UPLOAD_FILES_EVENT, {
        onUploadSuccess: (attachedDocumentIds?: string[]) => {
          const params = { ...values, attachedDocumentIds };
          if (task?.id) {
            requestUpdateTask(params);
          } else {
            requestCreateTask(params);
          }
        },
      });
    },
    [task?.id, requestUpdateTask, requestCreateTask]
  );

  const handleTabChange = useCallback((key) => form.validateFields().then(() => setActiveTab(key)), [form]);
  const handleFileClick = () => {
    onCancel && onCancel();
    navigate(`${ROUTES.APPLICATION_OVERVIEW}/${fileId}`);
  };

  useEffect(() => {
    if (!fileProcesses) {
      requestFileProcesses();
    }
  }, [fileProcesses, requestFileProcesses]);

  useEffect(() => {
    if (!fileStages) {
      requestFileStages();
    }
  }, [fileStages, requestFileStages]);

  useEffect(() => {
    if (taskId && !task) {
      requestGetTask(taskId);
    }
  }, [taskId, task, requestGetTask]);

  useEffect(() => {
    if (defaultValues) return;

    if (taskId && task && agents) {
      const assignType = Boolean(task?.assignedAgentId) ? AssignTypeEnum.Users : AssignTypeEnum.Team;
      setDefaultValues({ ...task, assignType } as IFormUpdate);
    }

    if (!taskId) {
      setDefaultValues({ assignType: AssignTypeEnum.Users } as IFormCreate);
    }
  }, [task, defaultValues, taskId, form, agents]);

  useEffect(() => {
    if (!appTaskNames && task) {
      requestAppTaskNames(task?.fileProcessId);
    }
  }, [appTaskNames, requestAppTaskNames, task]);

  useEffect(() => {
    if (taskId && !appTaskStatuses) {
      requestAppTaskStatuses();
    }
  }, [appTaskStatuses, requestAppTaskStatuses, taskId]);

  useEffect(() => {
    if (defaultValues) {
      form.setFieldsValue({
        ...defaultValues,
        description: replaceLineBreaksWithBrTag(defaultValues?.description),
      });
    }
  }, [defaultValues, form]);

  return (
    <Modal
      destroyOnClose
      centered
      visible
      title={
        taskId ? task?.appTaskName || appTaskNames?.find((item) => item.id === task?.appTaskNameId)?.name : t.TASK_NEW
      }
      width={900}
      footer={null}
      onCancel={onCancel}
      className="TaskEditor"
    >
      <Spin spinning={fileStagesLoading || taskLoading || taskCreateLoading || taskUpdateLoading}>
        <Form
          form={form}
          layout="vertical"
          initialValues={defaultValues}
          onValuesChange={handleValuesChange}
          onFinish={onFinish}
          validateTrigger={['onSubmit', 'onChange']}
        >
          {(_, formInstance) => (
            <>
              <Tabs activeKey={activeTab} onChange={handleTabChange}>
                <TabPane tab={t.DETAILS} key={formTabs[0]} className="TaskEditor__tabs-container">
                  <Row gutter={10} className="TaskEditor__info-container">
                    <Col span={12}>
                      <BlockWithLabel label={t.TASK_STAGE} layout="horizontal" labelSpan={8} childrenSpan={16}>
                        {fileStages?.find((item) => item.id === fileStageId)?.name || task?.fileStageName}
                      </BlockWithLabel>
                    </Col>
                    {taskId && (
                      <>
                        <Col span={12}>
                          <BlockWithLabel label={t.TASK_OPENED} layout="horizontal" labelSpan={8} childrenSpan={16}>
                            {task?.openDate ? moment(task?.openDate).format(DATE_AND_TIME_FORMAT2) : NO_DATA}
                          </BlockWithLabel>
                        </Col>

                        <Col span={12}>
                          <BlockWithLabel label={t.TASK_PROCESS} layout="horizontal" labelSpan={8} childrenSpan={16}>
                            {task?.fileProcessName ||
                              fileProcesses?.find((item) => item.id === task?.fileProcessId)?.name}
                          </BlockWithLabel>
                        </Col>

                        <Col span={12}>
                          <BlockWithLabel label={t.TASK_CLOSED} layout="horizontal" labelSpan={8} childrenSpan={16}>
                            {task?.closeDate ? moment(task?.closeDate).format(DATE_AND_TIME_FORMAT2) : NO_DATA}
                          </BlockWithLabel>
                        </Col>

                        <Col span={12}>
                          <BlockWithLabel label={t.TASK} layout="horizontal" labelSpan={8} childrenSpan={16}>
                            {task?.appTaskName || appTaskNames?.find((item) => item.id === task?.appTaskNameId)?.name}
                          </BlockWithLabel>
                        </Col>
                      </>
                    )}
                  </Row>

                  {!taskId && (
                    <Row gutter={20}>
                      <Col span={12}>
                        <Form.Item
                          name="fileProcessId"
                          label={t.TASK_PROCESS}
                          rules={[{ required: true, message: t.REQUIRED_FIELD }]}
                        >
                          <Select
                            suffixIcon={<SelectSuffixIcon />}
                            size="large"
                            loading={fileProcessesLoading}
                            placeholder={t.SELECT}
                          >
                            {fileProcesses?.map((option) => (
                              <Option key={option?.id} value={option?.id}>
                                {option?.name}
                              </Option>
                            ))}
                          </Select>
                        </Form.Item>
                      </Col>
                      <Col span={12}>
                        <Form.Item
                          name="appTaskNameId"
                          label={t.TASK_NAME}
                          rules={[{ required: true, message: t.REQUIRED_FIELD }]}
                        >
                          <Select
                            loading={appTaskNamesLoading}
                            suffixIcon={<SelectSuffixIcon />}
                            size="large"
                            disabled={!formInstance.getFieldValue('fileProcessId')}
                            placeholder={t.SELECT}
                          >
                            {appTaskNames?.map((option) => (
                              <Option key={option?.id} value={option?.id}>
                                {option?.name}
                              </Option>
                            ))}
                          </Select>
                        </Form.Item>
                      </Col>
                    </Row>
                  )}

                  {taskId && (
                    <Row gutter={20}>
                      <Col span={24}>
                        <Form.Item name="appTaskStatusId" label={t.TASK_STATUS}>
                          <Select
                            suffixIcon={<SelectSuffixIcon />}
                            size="large"
                            loading={appTaskStatusesLoading}
                            placeholder={t.SELECT}
                          >
                            {appTaskStatuses?.map((option) => (
                              <Option key={option?.id} value={option?.id}>
                                {option?.name}
                              </Option>
                            ))}
                          </Select>
                        </Form.Item>
                      </Col>
                    </Row>
                  )}
                  <Row>
                    <Form.Item
                      name="description"
                      label={t.TASK_DESCRIPTION}
                      rules={[
                        { required: true, message: t.REQUIRED_FIELD },
                        { max: 5000, message: t.MAX_500_CHAR_ERROR },
                      ]}
                      initialValue=""
                    >
                      <ReactQuill
                        onChange={(content: any, delta: any, source: any, editor: any) => {
                          if (!editor.getText() || editor.getText() === '\n') {
                            form.setFieldsValue({ description: '' });
                          }
                        }}
                        style={{ wordBreak: 'break-all' }}
                      />
                    </Form.Item>
                  </Row>
                  <Row gutter={20} align="bottom">
                    <Col span={12}>
                      <Form.Item label={t.TASK_ASSIGN} name="assignType" required>
                        <Radio.Group size="large" buttonStyle="solid" optionType="button">
                          <Radio.Button value={AssignTypeEnum.Users}>{t.TASK_USERS}</Radio.Button>
                          <Radio.Button value={AssignTypeEnum.Team}>{t.TASK_TEAM}</Radio.Button>
                        </Radio.Group>
                      </Form.Item>
                    </Col>
                    <Col span={12} className="TaskActionEditor__search-container">
                      <Form.Item
                        noStyle
                        shouldUpdate={(prevValues, currentValues) => prevValues.assignType !== currentValues.assignType}
                      >
                        {({ getFieldValue }) => (
                          <>
                            {getFieldValue('assignType') === AssignTypeEnum.Users && (
                              <Form.Item name="assignedAgentId" rules={[{ required: true, message: t.REQUIRED_FIELD }]}>
                                <SelectLookupDto data={agents} showSearch />
                              </Form.Item>
                            )}

                            {getFieldValue('assignType') === AssignTypeEnum.Team && (
                              <Form.Item name="assignedTeamId" rules={[{ required: true, message: t.REQUIRED_FIELD }]}>
                                <Select
                                  showSearch
                                  suffixIcon={<SelectSuffixIcon />}
                                  size="large"
                                  filterOption={(input, option) => {
                                    return option?.title?.toLowerCase().includes(input.toLowerCase() || '');
                                  }}
                                  placeholder={t.SELECT}
                                >
                                  {teams?.map((option) => (
                                    <Option key={option?.id} value={option?.id} title={option?.name}>
                                      {option?.name}
                                    </Option>
                                  ))}
                                </Select>
                              </Form.Item>
                            )}
                          </>
                        )}
                      </Form.Item>
                    </Col>
                  </Row>
                </TabPane>
                <TabPane tab={t.ATTACHMENTS} key={formTabs[1]} forceRender>
                  <AttachmentsTab form={form} applicationFileId={fileId} />
                </TabPane>
              </Tabs>
              <Row gutter={12} justify="end" className="TaskEditor__footer">
                <Col>
                  {showFileButton && (
                    <Button kind="primary" padding="large" onClick={handleFileClick}>
                      {t.FILE}
                    </Button>
                  )}
                </Col>
                <Col>
                  <Button kind="cancel" padding="large" onClick={onCancel}>
                    {t.CANCEL}
                  </Button>
                </Col>
                <Col>
                  <Button kind="primary" padding="large" htmlType="submit">
                    {t.SAVE}
                  </Button>
                </Col>
              </Row>
            </>
          )}
        </Form>
      </Spin>
    </Modal>
  );
}

export default TaskEditor;
