import { useState, useCallback, useEffect } from 'react';
import { Tabs } from 'antd';

import TabsLeftNav from '../../../../components/TabsLeftNav/TabsLeftNav';

import PersonalInformation from './PersonalInformation';
import AssetsDebts from './AssetsDebts';
import IncomeExpense from './IncomeExpense';
import IncomeTax from './IncomeTax';
import BankingInfo from './BankingInfo';
import Questionnaire from './Questionnaire';
import SupportingDocuments from './SupportingDocuments';
import ActionConfirmationModal from '../../../../modals/ActionConfirmationModal/ActionConfirmationModal';

import SubTab, { ISubTab } from './SubTab/SubTab';
import useLocale from '../../../../hooks/useLocale';
import useModal from '../../../../hooks/useModal';
import useUnsaved from '../../../../hooks/useUnsaved';

import { mapAppFormStatusToSubTabInfo } from '../../utils';
import { eventBus } from '../../../../utils/eventBus';
import { APPFORM_SAVE_AND_LEAVE_EVENT, SCROLL_TO_TOP_EVENT } from '../../../../constants/eventBus';

import { ClientService } from '../../../../shared/api/ClientService';
import API from '../../../../utils/api';

import './ApplicationFormTab.scss';

interface IProps {
  data: ClientService.IApplicationFileDto | null;
  defaultActiveKey?: string;
}

interface ITab extends ISubTab {
  key: string;
}

const ApplicationFormTab = ({ data, defaultActiveKey }: IProps): JSX.Element => {
  const { t } = useLocale();
  const { showModal, closeModal } = useModal();
  const { isUnsavedForm, setIsUnsavedForm } = useUnsaved();
  const { TabPane } = Tabs;

  const [activeTab, setActiveTab] = useState<string>(defaultActiveKey || '1');
  const [tabs, setTabs] = useState<ITab[]>([]);

  const handleTabChange = useCallback(
    (tabKey: string) => {
      if (tabKey === activeTab) return;

      if (isUnsavedForm) {
        showModal(
          <ActionConfirmationModal
            title={t.UNSAVED_CHANGED_TITLE}
            message={t.UNSAVED_CHANGED_MESSAGE}
            okText={t.SAVE_AND_LEAVE}
            onOk={() => {
              eventBus.dispatch(APPFORM_SAVE_AND_LEAVE_EVENT, {
                onSaveSuccess: () => {
                  setActiveTab(tabKey);
                  eventBus.dispatch(SCROLL_TO_TOP_EVENT);
                  closeModal();
                },
              });
            }}
            cancelText={t.LEAVE}
            onCancel={() => {
              setIsUnsavedForm(false);
              setActiveTab(tabKey);
              closeModal();
            }}
          />
        );
      } else {
        setActiveTab(tabKey);
        eventBus.dispatch(SCROLL_TO_TOP_EVENT);
      }
    },
    [
      activeTab,
      closeModal,
      isUnsavedForm,
      setIsUnsavedForm,
      showModal,
      t.LEAVE,
      t.SAVE_AND_LEAVE,
      t.UNSAVED_CHANGED_MESSAGE,
      t.UNSAVED_CHANGED_TITLE,
    ]
  );
  const updateStatus = useCallback(() => {
    if (data?.id) {
      API.getAppFormStatus(data?.id).then((response) => {
        const tabs = [
          mapAppFormStatusToSubTabInfo(t.PERSONAL_INFORMATION, '1', response.personalInfoStatus),
          mapAppFormStatusToSubTabInfo(t.ASSETS_DEBTS, '2', response.assetsDebtsStatus),
          mapAppFormStatusToSubTabInfo(t.INCOME_EXPENSES, '3', response.incomeExpenseStatus),
          mapAppFormStatusToSubTabInfo(t.INCOME_TAX, '4', response.incomeTaxStatus),
          mapAppFormStatusToSubTabInfo(t.BANKING_INFORMATION, '5', response.bankingInformationStatus),
          mapAppFormStatusToSubTabInfo(t.QUESTIONNAIRE, '6', response.questionnaireStatus),
        ];

        tabs?.push(
          mapAppFormStatusToSubTabInfo(
            t.SUPPORTING_DOCUMENTS,
            '7',
            response.supportingDocumentStatus,
            `${response?.numberOfUploadedSupportingDocuments} out of ${response?.numberOfRequiredSupportingDocuments}`
          )
        );

        setTabs(tabs);
      });
    }
  }, [
    data?.id,
    t.ASSETS_DEBTS,
    t.BANKING_INFORMATION,
    t.INCOME_EXPENSES,
    t.INCOME_TAX,
    t.PERSONAL_INFORMATION,
    t.QUESTIONNAIRE,
    t.SUPPORTING_DOCUMENTS,
  ]);

  useEffect(() => {
    updateStatus();
  }, [updateStatus]);

  useEffect(() => {
    if (defaultActiveKey) {
      setActiveTab(defaultActiveKey);
    }
  }, [defaultActiveKey]);

  useEffect(() => {
    return () => {
      setIsUnsavedForm(false);
    };
  }, [setIsUnsavedForm]);

  return (
    <TabsLeftNav
      activeKey={activeTab}
      renderSubTabs={() =>
        tabs.map((item, index) => (
          <div key={String(index)} onClick={() => handleTabChange(item.key)}>
            <SubTab {...item} active={item.key === activeTab} />
          </div>
        ))
      }
      destroyInactiveTabPane
    >
      <TabPane key="1">
        <PersonalInformation data={data} updateStatus={updateStatus} onContinue={() => setActiveTab('2')} />
      </TabPane>
      <TabPane key="2">
        <AssetsDebts applicationFile={data} updateStatus={updateStatus} onContinue={() => setActiveTab('3')} />
      </TabPane>
      <TabPane key="3">
        <IncomeExpense applicationFile={data} updateStatus={updateStatus} onContinue={() => setActiveTab('4')} />
      </TabPane>
      <TabPane key="4">
        <IncomeTax data={data} updateStatus={updateStatus} onContinue={() => setActiveTab('5')} />
      </TabPane>
      <TabPane key="5">
        <BankingInfo data={data} updateStatus={updateStatus} onContinue={() => setActiveTab('6')} />
      </TabPane>
      <TabPane key="6">
        <Questionnaire data={data} updateStatus={updateStatus} />
      </TabPane>
      <TabPane key="7">
        <SupportingDocuments data={data} updateStatus={updateStatus} />
      </TabPane>
    </TabsLeftNav>
  );
};

export default ApplicationFormTab;
