import { useState, useEffect } from 'react';
import { Col } from 'antd';
import FormInput from '../../../../../components/Forms/FormInput/FormInput';
import Label from '../../../../../components/Forms/Label/Label';
import { IAscendVariableFieldsBlockProps } from '../SearchAscend';
import { stylesSearchAscend } from '../SearchAscend';
import { ClientService } from '../../../../../shared/api/ClientService';
import API from '../../../../../utils/api';
import { ISelectOption } from '../../../../../components/Forms/FormInput/SelectInput';
import useLocale from '../../../../../hooks/useLocale';
import useErrorHandling from '../../../../../hooks/useErrorHandling';

export interface IAscendTaxReturnValues {
  ascendSubmission?: string | ClientService.TaxReturnSubmissionTypeEnum;
  ascendTaxPeriods?: string[];
}

const COLS_IN_GRID = 24;
const COLS_IN_ROW = 3;

const newTaxReturnAscendFields = ['ascendSubmission', 'ascendTaxPeriods'];

// The SearchAscend component will inject these props
const NewTaxReturnFields = ({
  externalStateSetter,
  updateAscendSearchForm,
  setVariableFieldsListInParent,
  isMatched,
  searchResponse,
}: IAscendVariableFieldsBlockProps): JSX.Element => {
  const { t } = useLocale();
  const { processResponseForErrors } = useErrorHandling();

  const [taxReturnFields, setTaxReturnFields] = useState<IAscendTaxReturnValues | undefined>(undefined);

  const [submissionOptions, setSubmissionOptions] = useState<ISelectOption[] | undefined>();
  const [taxPeriodOptions, setTaxPeriodOptions] = useState<ISelectOption[] | undefined>([]);

  // const [submissionSelection, setSubmissionSelection] = useState<number | undefined>();

  useEffect(() => {
    if (setVariableFieldsListInParent) {
      setVariableFieldsListInParent(newTaxReturnAscendFields);
    }
  }, [setVariableFieldsListInParent]);

  useEffect(() => {
    if (taxReturnFields && updateAscendSearchForm) {
      updateAscendSearchForm((prev) => ({
        ...prev,
        ...taxReturnFields,
      }));
    }
  }, [updateAscendSearchForm, taxReturnFields]);

  useEffect(() => {
    if (submissionOptions) return;

    API.submissionTypes().then((response) => {
      // console.log('submission types:', response);
      setSubmissionOptions(
        response.map((option) => {
          return {
            label: option.name,
            value: option.enumValue,
          };
        })
      );
    });
  }, [submissionOptions]);

  useEffect(() => {
    if (!isMatched || !searchResponse) return;
    if (!searchResponse.fileId) return;
    API.taxPeriods(searchResponse.fileId)
      .then((response) => {
        const responseErrors = processResponseForErrors(response as any);
        if (responseErrors.hasErrors) {
          console.error('Could not fetch tax periods');
        }
        // console.log('tax periods response:', response);
        // const orderedTaxPeriods = response.sort((a, b) => {
        //   if (a.externalTaxReturnId && b.externalTaxReturnId) {
        //     return a.externalTaxReturnId - b.externalTaxReturnId;
        //   }
        //   return 0;
        // });
        setTaxPeriodOptions(
          response.map((option) => {
            return {
              label: option.taxPeriod,
              value: option.externalTaxReturnId,
            };
          })
        );
      })
      .catch((error) => {
        console.error('ERROR GETTING TAX PERIODS:', error);
      });
  }, [isMatched, searchResponse, processResponseForErrors]);

  return (
    <>
      <div className={stylesSearchAscend.dynamic_fields_container}>
        <Col span={COLS_IN_GRID / COLS_IN_ROW}>
          <FormInput
            type="select"
            label={<Label value={t.ASCEND_SUBMISSION} required={isMatched} />}
            name="ascendSubmission"
            optionsList={submissionOptions}
            onChange={(value: string) => {
              setTaxReturnFields((prev) => ({
                ...prev,
                ascendSubmission: value,
                ascendTaxPeriods: undefined,
              }));

              if (externalStateSetter) {
                externalStateSetter((prev: any) => {
                  const newState = {
                    ...prev,
                    ascendTaxPeriodOptions: undefined,
                  };
                  return newState;
                });
              }
            }}
          />
        </Col>
        <Col span={COLS_IN_GRID / COLS_IN_ROW}>
          {taxReturnFields?.ascendSubmission != null && taxReturnFields?.ascendSubmission !== 'select' ? (
            <FormInput
              type="select"
              key={`taxPeriod_submissionType-${taxReturnFields?.ascendSubmission}`}
              selectMode={taxReturnFields?.ascendSubmission === 1 ? 'multiple' : undefined}
              noSelectOption={taxReturnFields?.ascendSubmission === 1}
              label={<Label value={t.ASCEND_TAX_PERIOD} required={isMatched} />}
              name="ascendTaxPeriods"
              optionsList={taxPeriodOptions}
              onChange={(value: string | string[], option: any) => {
                // console.log('tax period option:', option);
                setTaxReturnFields((prev) => ({
                  ...prev,
                  ascendTaxPeriods: Array.isArray(value) ? value : [value],
                }));

                if (externalStateSetter) {
                  externalStateSetter((prev: any) => {
                    const newState = {
                      ...prev,
                      ascendTaxPeriodOptions: option,
                    };
                    return newState;
                  });
                }
              }}
            />
          ) : null}
        </Col>
      </div>
    </>
  );
};

export default NewTaxReturnFields;
