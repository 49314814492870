import { useState } from 'react';
import { Modal, Form, Col, Row, Spin } from 'antd';

import Button from '../../components/Button/Button';

import useLocale from '../../hooks/useLocale';

import './UpdateFilingType.scss';
import FormInput from '../../components/Forms/FormInput/FormInput';
import { ClientService } from '../../shared/api/ClientService';

interface IProps {
  filingTypes: ClientService.ILookupDto[] | null;
  onSave: (filingType?: ClientService.FilingTypeEnum, onSaveFailure?: () => void) => void;
  onCancel: () => void;
  shouldDisableOnSave?: boolean;
}

interface IForm {
  filingType?: ClientService.FilingTypeEnum;
}

const UpdateFilingTypeModal = ({
  filingTypes: updateFilingTypes,
  onSave,
  onCancel,
  shouldDisableOnSave = false,
}: IProps) => {
  const { t } = useLocale();
  const [form] = Form.useForm<IForm>();
  const [loading, setLoading] = useState(false);
  const [disabled, setDisabled] = useState<boolean>(false);

  return (
    <Modal
      destroyOnClose
      centered
      width={440}
      visible
      closable={!loading}
      title={t.ADMIN_INFO_FILING_TYPE}
      onCancel={onCancel}
      footer={null}
      className="UpdateFilingType"
    >
      <Spin spinning={loading}>
        <Form
          layout="vertical"
          form={form}
          onFinish={(values) => {
            if (shouldDisableOnSave) setDisabled(true);

            onSave(values?.filingType, () => {
              if (shouldDisableOnSave) setDisabled(false);
            });
          }}
        >
          <FormInput
            type="select"
            name="filingType"
            label={t.ADMIN_INFO_FILING_TYPE}
            noSelectOption
            optionsList={updateFilingTypes?.map((filingType) => ({
              value: filingType.enumValue,
              label: filingType.name,
            }))}
          />
          <Row align="middle" justify="end" gutter={10} style={{ paddingTop: 16 }}>
            <Col>
              <Form.Item noStyle>
                <Button kind="cancel" onClick={onCancel}>
                  {t.CANCEL}
                </Button>
              </Form.Item>
            </Col>
            <Col>
              <Form.Item noStyle>
                <Button kind="primary" htmlType="submit" disabled={disabled}>
                  {t.SAVE}
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Spin>
    </Modal>
  );
};

export default UpdateFilingTypeModal;
