import { Select, SelectProps } from 'antd';

import SelectSuffixIcon from '../../../../components/SelectSuffixIcon/SelectSuffixIcon';

import { ClientService } from '../../../../shared/api/ClientService';

export interface ISelectLookupDto extends SelectProps {
  data?: ClientService.LookupDto[];
  disabledArray?: ClientService.LookupDto[];
}

const SelectLookupDto = ({ data, disabledArray, ...props }: ISelectLookupDto) => {
  const { Option } = Select;

  return (
    <Select size="large" suffixIcon={<SelectSuffixIcon />} {...props}>
      {data?.map((item: ClientService.LookupDto) => (
        <Option
          key={item?.id}
          value={item?.id}
          disabled={disabledArray?.find((d) => d.id === item.id)}
          title={item?.name}
        >
          {item?.name}
        </Option>
      ))}
    </Select>
  );
};

export default SelectLookupDto;
