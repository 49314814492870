import { useCallback, useEffect, useState, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { Col, Row } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
import { Icon } from '@fluentui/react/lib/Icon';

import Table from '../../../../components/Table/Table';
import Button from '../../../../components/Button/Button';
import AssetsDebtsAsset, { IOtherAssetProp } from './AssetsDebtsAsset';
import AssetsDebtsDebt from './AssetsDebtsDebt';
import ActionConfirmationModal from '../../../../modals/ActionConfirmationModal/ActionConfirmationModal';

import useLocale from '../../../../hooks/useLocale';
import useModal from '../../../../hooks/useModal';

import { SORTING_SAME, SORTING_HIGHER, SORTING_LOWER } from '../../../../constants/common';
import { DEFAULT_PAGE_SIZE } from '../../../../constants/common';
import { paginationShowTotal } from '../../../../utils/helpers';
import { formatter } from '../../../../utils/format';

import { ClientService } from '../../../../shared/api/ClientService';
import API from '../../../../utils/api';

import './ApplicationFormTab.scss';
import styles from '../../../../styles/style.module.scss';

interface IProps {
  assetsOnly?: boolean;
  debtsOnly?: boolean;
  applicationFile?: ClientService.IApplicationFileDto | null;
  onUpdateAssets?: () => void;
  onUpdateDebts?: () => void;
  updateStatus?: () => void;
  onContinue?: () => void;
}

const AssetsDebts = ({
  assetsOnly = false,
  debtsOnly = false,
  applicationFile,
  onUpdateAssets,
  onUpdateDebts,
  updateStatus,
  onContinue,
}: IProps): JSX.Element => {
  const { t } = useLocale();
  const { showModal, closeModal } = useModal();
  const { applicationFileId } = useParams<{ applicationFileId: string }>();

  const isAppFormLocked = useMemo(
    () => Boolean(applicationFile?.isAppFormLocked) || Boolean(applicationFile?.isLocked),
    [applicationFile?.isAppFormLocked, applicationFile?.isLocked]
  );

  const [isReviewed, setIsReviewed] = useState(false);
  const [needToBeReviewed, setNeedToBeReviewed] = useState(false);

  const [assets, setAssets] = useState<ClientService.AppFormAssetsDebtsAssetListItem[]>([]);
  const [assetsLoading, setAssetsLoading] = useState(false);
  const [reviewedAssets, setReviewedAssets] = useState<string[]>([]);

  const [debts, setDebts] = useState<ClientService.AppFormAssetsDebtsDebtListItem[]>([]);
  const [debtsLoading, setDebtsLoading] = useState(false);
  const [reviewedDebts, setReviewedDebts] = useState<string[]>([]);

  const [totalAssets, setTotalAssets] = useState(0);

  const [totalDebts, setTotalDebts] = useState(0);
  const [totalUnsecuredDebts, setTotalUnsecuredDebts] = useState(0);
  const [totalSecuredDebts, setTotalSecuredDebts] = useState(0);

  const refreshStatus = useCallback(() => {
    API.getAssetsDebtsStatus(applicationFileId).then((response) => {
      setIsReviewed(response.status === ClientService.AppFormStatusEnum.Reviewed);
      setNeedToBeReviewed(
        response.status === ClientService.AppFormStatusEnum.ReadyForReview ||
          response.status === ClientService.AppFormStatusEnum.Incomplete_Review
      );
    });
  }, [applicationFileId]);

  const markComplete = useCallback(() => {
    API.assetsDebtsMarkAsComplete(applicationFileId).then((response) => {
      if (updateStatus) updateStatus();
      refreshStatus();
    });
  }, [applicationFileId, refreshStatus, updateStatus]);

  const markReviewed = useCallback(() => {
    API.assetsDebtsMarkAsReviewed(applicationFileId).then((response) => {
      if (updateStatus) updateStatus();
      refreshStatus();
    });
  }, [applicationFileId, refreshStatus, updateStatus]);

  const requestAssets = useCallback(
    (applicationFileId: string) => {
      setAssetsLoading(true);
      API.listAppFormAssets(applicationFileId).then((response) => {
        setAssets(response);
        setAssetsLoading(false);

        setTotalAssets(response.map((item) => item?.value || 0).reduce((sum, current) => sum + current, 0));
        if (onUpdateAssets) onUpdateAssets();
        refreshStatus();
      });
    },
    [onUpdateAssets, refreshStatus]
  );

  const requestDebts = useCallback(
    (applicationFileId: string) => {
      setDebtsLoading(true);
      API.listAppFormDebts(applicationFileId).then((response) => {
        setDebts(response);
        setDebtsLoading(false);

        setTotalDebts(response.map((item) => item?.amount || 0).reduce((sum, current) => sum + current, 0));
        setTotalUnsecuredDebts(
          response
            .filter((item) => !item?.isSecured)
            .map((item) => item?.amount || 0)
            .reduce((sum, current) => sum + current, 0)
        );
        setTotalSecuredDebts(
          response
            .filter((item) => item?.isSecured)
            .map((item) => item?.amount || 0)
            .reduce((sum, current) => sum + current, 0)
        );
        if (onUpdateDebts) onUpdateDebts();
      });
    },
    [onUpdateDebts]
  );

  const isOtherAsset = useCallback((asset: ClientService.AppFormAssetsDebtsAssetListItem) => {
    let enumVal = asset?.assetDescription?.enumValue;

    return (
      enumVal === ClientService.AssetDescriptionEnum.Other_CashOrSavings ||
      enumVal === ClientService.AssetDescriptionEnum.Other_Furniture ||
      enumVal === ClientService.AssetDescriptionEnum.Other_PersonalEffects ||
      enumVal === ClientService.AssetDescriptionEnum.Other_InvestmentsOrPolicies ||
      enumVal === ClientService.AssetDescriptionEnum.Other_RealEstate ||
      enumVal === ClientService.AssetDescriptionEnum.Other_RecreationalEquipment
    );
  }, []);

  const assetColumns: any = [
    {
      title: t.TYPE,
      dataIndex: 'assetType.name',
      key: 'assetType',
      sorter: (a: ClientService.AppFormAssetsDebtsAssetListItem, b: ClientService.AppFormAssetsDebtsAssetListItem) => {
        if (!a.assetType?.name && !b.assetType?.name) return SORTING_SAME;
        if (!a.assetType?.name) return SORTING_LOWER;
        if (!b.assetType?.name) return SORTING_HIGHER;

        return a.assetType?.name.localeCompare(b.assetType?.name);
      },
      render: (text: string, item: ClientService.AppFormAssetsDebtsAssetListItem) => (
        <Row gutter={4} align="middle">
          <Col>
            {(item?.isMissingDetails || item?.isMissingSupportingDocuments) && (
              <Icon iconName="AlertSolid" className="ApplicationFormTab__grid-error" />
            )}
          </Col>
          <Col>{item?.assetType?.name}</Col>
        </Row>
      ),
    },
    {
      title: t.DESCRIPTION,
      dataIndex: 'assetDescription.name',
      key: 'assetDescription',
      sorter: (a: ClientService.AppFormAssetsDebtsAssetListItem, b: ClientService.AppFormAssetsDebtsAssetListItem) => {
        if (!a.assetDescription?.name && !b.assetDescription?.name) return SORTING_SAME;
        if (!a.assetDescription?.name) return SORTING_LOWER;
        if (!b.assetDescription?.name) return SORTING_HIGHER;

        return a.assetDescription?.name.localeCompare(b.assetDescription?.name);
      },
      render: (text: string, item: ClientService.AppFormAssetsDebtsAssetListItem) => (
        <>
          {item?.assetDescription?.name} {Boolean(isOtherAsset(item)) && ` - ${item.specifiedDescription}`}
        </>
      ),
    },
    {
      title: t.VALUE,
      dataIndex: 'value',
      key: 'value',
      sorter: (a: ClientService.AppFormAssetsDebtsAssetListItem, b: ClientService.AppFormAssetsDebtsAssetListItem) => {
        if (!a.value && !b.value) return SORTING_SAME;
        if (!a.value) return SORTING_LOWER;
        if (!b.value) return SORTING_HIGHER;

        return a.value - b.value;
      },
      render: (text: string, item: ClientService.AppFormAssetsDebtsAssetListItem) => (
        <>{formatter.currency(item?.value)}</>
      ),
    },
    {
      title: '',
      dataIndex: 'action',
      key: 'action',
      render: (text: string, item: ClientService.AppFormAssetsDebtsAssetListItem) => (
        <DeleteOutlined
          className="ApplicationFormTab__input-action-icon"
          style={{
            color: isAppFormLocked ? styles.colorSecondary : styles.colorDanger,
            cursor: isAppFormLocked ? 'not-allowed' : 'pointer',
          }}
          onClick={(event) => {
            event.stopPropagation();
            if (isAppFormLocked) return;
            handleDeleteAsset(item?.id);
          }}
        />
      ),
    },
  ];

  const debtColumns: any = [
    {
      title: t.DESCRIPTION,
      dataIndex: 'debtDescription.name',
      key: 'debtDescription',
      sorter: (a: ClientService.AppFormAssetsDebtsDebtListItem, b: ClientService.AppFormAssetsDebtsDebtListItem) => {
        if (!a.debtDescription?.name && !b.debtDescription?.name) return SORTING_SAME;
        if (!a.debtDescription?.name) return SORTING_LOWER;
        if (!b.debtDescription?.name) return SORTING_HIGHER;

        return a.debtDescription?.name.localeCompare(b.debtDescription?.name);
      },
      render: (text: string, item: ClientService.AppFormAssetsDebtsDebtListItem) => (
        <Row gutter={4} align="middle">
          <Col>
            {item?.isMissingDetails && <Icon iconName="AlertSolid" className="ApplicationFormTab__grid-error" />}
          </Col>
          <Col>{item?.debtDescription?.name}</Col>
        </Row>
      ),
    },
    {
      title: t.CREDITOR_NAME,
      dataIndex: 'creditorName',
      key: 'creditorName',
      sorter: (a: ClientService.AppFormAssetsDebtsDebtListItem, b: ClientService.AppFormAssetsDebtsDebtListItem) => {
        if (!a.creditorName && !b.creditorName) return SORTING_SAME;
        if (!a.creditorName) return SORTING_LOWER;
        if (!b.creditorName) return SORTING_HIGHER;

        return a.creditorName.localeCompare(b.creditorName);
      },
    },
    {
      title: t.VALUE,
      dataIndex: 'amount',
      key: 'amount',
      defaultSortOrder: 'descend',
      sorter: (a: ClientService.AppFormAssetsDebtsDebtListItem, b: ClientService.AppFormAssetsDebtsDebtListItem) => {
        if (!a.amount && !b.amount) return SORTING_SAME;
        if (!a.amount) return SORTING_LOWER;
        if (!b.amount) return SORTING_HIGHER;

        return a.amount - b.amount;
      },
      render: (text: string, item: ClientService.AppFormAssetsDebtsDebtListItem) => (
        <>{formatter.currency(item?.amount)}</>
      ),
    },
    {
      title: '',
      dataIndex: 'action',
      key: 'action',
      render: (text: string, item: ClientService.AppFormAssetsDebtsDebtListItem) => (
        <DeleteOutlined
          className="ApplicationFormTab__input-action-icon"
          style={{
            color: isAppFormLocked ? styles.colorSecondary : styles.colorDanger,
            cursor: isAppFormLocked ? 'not-allowed' : 'pointer',
          }}
          onClick={(event) => {
            event.stopPropagation();
            if (isAppFormLocked) return;
            handleDeleteDebt(item?.id);
          }}
        />
      ),
    },
  ];

  const handleUpdateAllAssetExemptStatusByDescription = useCallback(
    async ({ assetId, assetDescriptionEnum, isExempt }: IOtherAssetProp) => {
      const filteredAssets = assets.filter(
        (asset) => asset.assetDescription?.enumValue === assetDescriptionEnum && asset.id !== assetId
      );

      for (const asset of filteredAssets) {
        await API.getAppFormAsset(asset?.id).then((asset) => {
          API.updateAppFormAsset({
            ...asset,
            fileId: applicationFileId,
            isExempt,
          } as ClientService.AppFormAssetsDebtsAssetDto);
        });
      }
    },
    [applicationFileId, assets]
  );

  const addAssetToReviewed = useCallback(
    (assetId?: string) => {
      if (assetId && reviewedAssets?.indexOf(assetId) === -1) {
        setReviewedAssets([...reviewedAssets, assetId]);
      }
    },
    [reviewedAssets]
  );

  const handleOpenAsset = useCallback(
    (asset?: ClientService.AppFormAssetsDebtsAssetListItem) => {
      showModal(
        <AssetsDebtsAsset
          assetId={asset?.id}
          fileId={applicationFileId as string}
          isMissingDetails={asset?.isMissingDetails}
          isMissingSupportingDocuments={asset?.isMissingSupportingDocuments}
          onNext={(otherAsset?: IOtherAssetProp) => {
            if (asset?.updatedByClient) addAssetToReviewed(asset?.id);
            if (otherAsset) {
              handleUpdateAllAssetExemptStatusByDescription(otherAsset);
            }
            requestAssets(applicationFileId as string);
            if (updateStatus) updateStatus();
            refreshStatus();
            closeModal();
          }}
          onCancel={() => {
            if (asset?.updatedByClient) addAssetToReviewed(asset?.id);
            closeModal();
          }}
          isLocked={isAppFormLocked}
        />
      );
    },
    [
      showModal,
      applicationFileId,
      isAppFormLocked,
      addAssetToReviewed,
      requestAssets,
      updateStatus,
      refreshStatus,
      closeModal,
      handleUpdateAllAssetExemptStatusByDescription,
    ]
  );

  const addDebtToReviewed = useCallback(
    (debtId?: string) => {
      if (debtId && reviewedDebts?.indexOf(debtId) === -1) {
        setReviewedDebts([...reviewedDebts, debtId]);
      }
    },
    [reviewedDebts]
  );

  const handleOpenDebt = useCallback(
    (debt?: ClientService.AppFormAssetsDebtsDebtListItem) => {
      showModal(
        <AssetsDebtsDebt
          debtId={debt?.id}
          fileId={applicationFileId as string}
          isMissingDetails={debt?.isMissingDetails}
          onNext={() => {
            if (debt?.updatedByClient) addDebtToReviewed(debt?.id);
            closeModal();
            requestDebts(applicationFileId as string);
            if (updateStatus) updateStatus();
            refreshStatus();
          }}
          onCancel={() => {
            if (debt?.updatedByClient) addDebtToReviewed(debt?.id);
            closeModal();
          }}
          isLocked={isAppFormLocked}
        />
      );
    },
    [
      showModal,
      applicationFileId,
      isAppFormLocked,
      addDebtToReviewed,
      closeModal,
      requestDebts,
      updateStatus,
      refreshStatus,
    ]
  );

  const handleDeleteAsset = useCallback(
    (assetId?: string) => {
      showModal(
        <ActionConfirmationModal
          title={t.ASSET_DELETE_CONFIRMATION_TITLE}
          message={t.ASSET_DELETE_CONFIRMATION_MESSAGE}
          okText={t.DELETE}
          onOk={() => {
            API.deleteAppFormAsset(assetId).then((response) => {
              requestAssets(applicationFileId as string);
              if (updateStatus) updateStatus();
              refreshStatus();
            });
            closeModal();
          }}
          onCancel={closeModal}
        />
      );
    },
    [
      applicationFileId,
      closeModal,
      refreshStatus,
      requestAssets,
      showModal,
      t.ASSET_DELETE_CONFIRMATION_MESSAGE,
      t.ASSET_DELETE_CONFIRMATION_TITLE,
      t.DELETE,
      updateStatus,
    ]
  );

  const handleDeleteDebt = useCallback(
    (debtId?: string) => {
      showModal(
        <ActionConfirmationModal
          title={t.DEBT_DELETE_CONFIRMATION_TITLE}
          message={t.DEBT_DELETE_CONFIRMATION_MESSAGE}
          okText={t.DELETE}
          onOk={() => {
            API.deleteAppFormDebt(debtId).then((response) => {
              requestDebts(applicationFileId as string);
              if (updateStatus) updateStatus();
              refreshStatus();
            });
            closeModal();
          }}
          onCancel={closeModal}
        />
      );
    },
    [
      applicationFileId,
      closeModal,
      refreshStatus,
      requestDebts,
      showModal,
      t.DEBT_DELETE_CONFIRMATION_MESSAGE,
      t.DEBT_DELETE_CONFIRMATION_TITLE,
      t.DELETE,
      updateStatus,
    ]
  );

  useEffect(() => {
    if (applicationFileId) {
      requestAssets(applicationFileId as string);
      requestDebts(applicationFileId as string);
    }
  }, [requestAssets, requestDebts, applicationFileId, needToBeReviewed]);

  return (
    <>
      <Row gutter={20} className="ApplicationFormTab__assets-debts">
        {!assetsOnly && !debtsOnly && (
          <>
            <Col xl={12} xs={24}>
              <Row className="ApplicationFormTab__assets-debts--header">
                <b>
                  <div
                    className="ApplicationFormTab__assets-debts--total-assets"
                    style={{ color: isAppFormLocked ? styles.colorSecondary : styles.colorGreen }}
                  >
                    {formatter.currency(totalAssets, 0)}
                  </div>
                  <div>{t.TOTAL_ASSETS}</div>
                </b>
              </Row>
            </Col>
            <Col xl={12} xs={24}>
              <Row className="ApplicationFormTab__assets-debts--header">
                <Col span={8}>
                  <div
                    className="ApplicationFormTab__assets-debts--total-debts"
                    style={{ color: isAppFormLocked ? styles.colorSecondary : styles.colorDanger }}
                  >
                    <b>{formatter.currency(totalDebts, 0)}</b>
                  </div>
                  <div>
                    <b>{t.TOTAL_DEBTS}</b>
                  </div>
                </Col>
                <Col span={8} className="ApplicationFormTab__assets-debts--total-debts-container">
                  <div
                    className="ApplicationFormTab__assets-debts--total-debts"
                    style={{ color: isAppFormLocked ? styles.colorSecondary : styles.colorDanger }}
                  >
                    {formatter.currency(totalUnsecuredDebts, 0)}
                  </div>
                  <div>{t.TOTAL_UNSECURED}</div>
                </Col>
                <Col span={8} className="ApplicationFormTab__assets-debts--total-debts-container">
                  <div
                    className="ApplicationFormTab__assets-debts--total-debts"
                    style={{ color: isAppFormLocked ? styles.colorSecondary : styles.colorDanger }}
                  >
                    {formatter.currency(totalSecuredDebts, 0)}
                  </div>
                  <div>{t.TOTAL_SECURED}</div>
                </Col>
              </Row>
            </Col>
          </>
        )}

        {!debtsOnly && (
          <Col span={assetsOnly ? 24 : 12}>
            <Row align="bottom" justify="space-between" gutter={20} style={{ paddingBottom: 8 }}>
              <Col span={20}>
                <div className="ApplicationFormTab__assets-debts--table-title">{t.ASSETS}</div>
              </Col>
              <Col span={!isAppFormLocked ? 4 : 0}>
                <Button
                  kind="primary"
                  narrow
                  className="ApplicationFormTab__add-icon"
                  onClick={() => {
                    if (isAppFormLocked) return;
                    handleOpenAsset(undefined);
                  }}
                  disabled={isAppFormLocked}
                >
                  <Icon iconName="Add" /> {t.ADD}
                </Button>
              </Col>
            </Row>
          </Col>
        )}

        {!assetsOnly && (
          <Col span={debtsOnly ? 24 : 12}>
            <Row align="bottom" justify="space-between" style={{ paddingBottom: 8 }}>
              <Col span={20}>
                <div className="ApplicationFormTab__assets-debts--table-title">{t.DEBTS}</div>
              </Col>
              <Col span={!isAppFormLocked ? 4 : 0}>
                <Button
                  kind="primary"
                  narrow
                  className="ApplicationFormTab__add-icon"
                  onClick={() => {
                    if (isAppFormLocked) return;
                    handleOpenDebt(undefined);
                  }}
                  disabled={isAppFormLocked}
                >
                  <Icon iconName="Add" /> {t.ADD}
                </Button>
              </Col>
            </Row>
          </Col>
        )}

        {!debtsOnly && (
          <Col span={assetsOnly ? 24 : 12}>
            <Table
              rowKey="id"
              dataSource={assets}
              columns={assetColumns}
              onRow={(record: ClientService.AppFormAssetsDebtsAssetListItem) => ({
                onClick: () => handleOpenAsset(record),
              })}
              rowClassName={(record: ClientService.AppFormAssetsDebtsAssetListItem) =>
                `ApplicationFormTab__clickable-row ${
                  record?.updatedByClient && record?.id && reviewedAssets?.indexOf(record?.id) < 0 ? 'user-changed' : ''
                }`
              }
              pagination={{
                position: ['bottomRight'],
                defaultPageSize: DEFAULT_PAGE_SIZE,
                showSizeChanger: true,
                total: assets.length,
                showTotal: paginationShowTotal,
              }}
              loading={assetsLoading}
              allWhite
            />
          </Col>
        )}

        {!assetsOnly && (
          <Col span={debtsOnly ? 24 : 12}>
            <Table
              rowKey="id"
              dataSource={debts}
              columns={debtColumns}
              onRow={(record: ClientService.AppFormAssetsDebtsDebtListItem) => ({
                onClick: () => handleOpenDebt(record),
              })}
              rowClassName={(record: ClientService.AppFormAssetsDebtsDebtListItem) =>
                `ApplicationFormTab__clickable-row ${
                  record?.updatedByClient && record?.id && reviewedDebts?.indexOf(record?.id) < 0 ? 'user-changed' : ''
                }`
              }
              pagination={{
                position: ['bottomRight'],
                defaultPageSize: DEFAULT_PAGE_SIZE,
                showSizeChanger: true,
                total: debts.length,
                showTotal: paginationShowTotal,
              }}
              loading={debtsLoading}
              allWhite
            />
          </Col>
        )}
      </Row>

      {!assetsOnly && !debtsOnly && (
        <Row justify="end" gutter={20} className="ApplicationFormTab__buttons-container">
          <Col>
            <Button
              padding="large"
              disabled={
                !needToBeReviewed ||
                reviewedAssets?.length !== assets?.filter((item) => item.updatedByClient)?.length ||
                reviewedDebts?.length !== debts?.filter((item) => item.updatedByClient)?.length ||
                isAppFormLocked
              }
              onClick={markReviewed}
            >
              {t.MARK_AS_REVIEWED}
            </Button>
          </Col>
          <Col>
            <Button padding="large" disabled={!isReviewed || isAppFormLocked} onClick={markComplete}>
              {t.MARK_AS_COMPLETED}
            </Button>
          </Col>
          <Col>
            <Button kind="cancel" padding="large" onClick={onContinue} disabled={isAppFormLocked}>
              {t.CONTINUE}
            </Button>
          </Col>
        </Row>
      )}
    </>
  );
};

export default AssetsDebts;
