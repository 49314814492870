import { Col, Row, Form } from 'antd';
import { useQuery } from 'react-query';
import moment from 'moment';

import RowItem from './RowItem';

import { TIME_FORMAT2 } from '../../../../constants/common';
import { MEETING_TYPES_QUERY } from '../../../../constants/reactQuery';
import { ClientService } from '../../../../shared/api/ClientService';
import API from '../../../../utils/api';

import './AvailableTimeslots.scss';
import { setTimeslotSpanSize } from '../../utils';
import { convertServerDateOnlyToInputDate } from '../../../../utils/helpers';

interface IProps {
  timeslot?: ClientService.AppointmentTimeSlotDto;
  disabled?: boolean;
}

/**
 * Represent one row of time slot information for "Available Appointments" grid in Appointment Schedule page.
 */
const TimeslotRow = ({ timeslot, disabled = false }: IProps) => {
  const { data: meetingTypes } = useQuery([MEETING_TYPES_QUERY], () => API.listMeetingTypes());

  return (
    <Form.Item noStyle shouldUpdate>
      {({ getFieldValue }) => {
        const meetingTypeId = getFieldValue('meetingTypeId');

        const isAvailableInPerson = Boolean(
          timeslot?.availableAgents?.find((a) =>
            a.meetingTypes?.find((m) => m.enumValue === ClientService.MeetingTypeEnum.InPerson)
          )
        );

        const isAvaialableByPhone = Boolean(
          timeslot?.availableAgents?.find((a) =>
            a.meetingTypes?.find((m) => m.enumValue === ClientService.MeetingTypeEnum.Phone)
          )
        );

        return (
          <Row gutter={12} justify="center" align="middle" className="AvailableTimeslots__appointment-item">
            <Col span={6}>
              <div className="AvailableTimeslots__option-time">{`${
                timeslot?.time ? convertServerDateOnlyToInputDate(moment(timeslot?.time))?.format(TIME_FORMAT2) : ''
              } ${timeslot?.timeZone?.code || ''}`}</div>
            </Col>
            <Col span={setTimeslotSpanSize(meetingTypeId, ClientService.MeetingTypeEnum.Phone, meetingTypes)}>
              {isAvaialableByPhone && (
                <RowItem
                  meetingTypeEnum={ClientService.MeetingTypeEnum.Phone}
                  timeslot={timeslot}
                  disabled={disabled}
                />
              )}
            </Col>
            <Col span={setTimeslotSpanSize(meetingTypeId, ClientService.MeetingTypeEnum.InPerson, meetingTypes)}>
              {isAvailableInPerson && (
                <RowItem
                  meetingTypeEnum={ClientService.MeetingTypeEnum.InPerson}
                  timeslot={timeslot}
                  disabled={disabled}
                />
              )}
            </Col>
          </Row>
        );
      }}
    </Form.Item>
  );
};

export default TimeslotRow;
