import { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Icon } from '@fluentui/react/lib/Icon';
import moment from 'moment';

import Table from '../../components/Table/Table';
import { ROUTES } from '../../constants/routes';
import { DEFAULT_PAGE_SIZE, DATE_FORMAT2 } from '../../constants/common';
import useLocale from '../../hooks/useLocale';
import useIsMounted from '../../hooks/useIsMounted';
import genericMessage from '../../utils/genericMessage';
import { ClientService } from '../../shared/api/ClientService';
import API from '../../utils/api';
import { paginationShowTotal } from '../../utils/helpers';

import './AppointmentTable.scss';

interface IProps {
  isAllAppointments?: boolean;
  onDataLoad?: (data?: ClientService.IViewAppointmentsResultDto[]) => void;
  pageSize?: number;
  onTotalCountChange?: (count?: number) => void;
  editable?: boolean;
}
function AppointmentTable({ isAllAppointments = true, onTotalCountChange, editable = true }: IProps): JSX.Element {
  const { t } = useLocale();
  const navigate = useNavigate();
  const isMounted = useIsMounted();
  const { applicationFileId } = useParams<{ applicationFileId?: string }>();

  const [tableData, setTableData] = useState<ClientService.IViewAppointmentsResultDto[]>([]);
  const [tableDataLoading, setTableDataLoading] = useState(true);

  const [totalCount, setTotalCount] = useState<number>(0);

  useEffect(() => {
    API.listAppointmentDetails(applicationFileId, isAllAppointments)
      .then((response) => {
        const responseWithKeys = response.map((item: ClientService.IViewAppointmentsResultDto) => ({
          ...item,
          key: item?.appointmentId,
        }));

        if (isMounted.current) {
          setTableData(responseWithKeys);
          setTotalCount(responseWithKeys?.length || 0);
          setTableDataLoading(false);
        }
      })
      .catch(genericMessage.error)
      .catch((e) => setTableDataLoading(false));
  }, [applicationFileId, isAllAppointments, isMounted]);

  useEffect(() => {
    if (onTotalCountChange) onTotalCountChange(totalCount || 0);
  }, [totalCount, onTotalCountChange]);

  const columns = [
    {
      title: t.DATE,
      dataIndex: 'appointmentStartTime',
      key: 'appointmentDate',
      sorter: (a: ClientService.IViewAppointmentsResultDto, b: ClientService.IViewAppointmentsResultDto) =>
        moment(a?.appointmentStartTime).diff(moment(b?.appointmentStartTime)),
      render: (appointmentStartTime: ClientService.IViewAppointmentsResultDto['appointmentStartTime']) => (
        <>{moment(appointmentStartTime).utc().format(DATE_FORMAT2)}</>
      ),
    },
    {
      title: t.TIME,
      key: 'appointmentTime',
      sorter: (a: ClientService.IViewAppointmentsResultDto, b: ClientService.IViewAppointmentsResultDto) =>
        moment(a?.appointmentStartTime).format('HH:mm').localeCompare(moment(b?.appointmentStartTime).format('HH:mm')),
      render: (value: string, record: ClientService.IViewAppointmentsResultDto) => (
        <>{`${moment(record.appointmentStartTime).utc().format('h:mm A')} - ${moment(record.appointmentEndTime)
          .utc()
          .format('h:mm A')}`}</>
      ),
    },
    {
      title: t.TYPE,
      dataIndex: 'meetingType',
      key: 'meetingType',
      sorter: (a: ClientService.IViewAppointmentsResultDto, b: ClientService.IViewAppointmentsResultDto) =>
        (a?.meetingType as any).localeCompare(b?.meetingType as any),
    },
    {
      title: t.APPOINTMENT_TYPE,
      dataIndex: 'appointmentType',
      key: 'appointmentType',
      sorter: (a: ClientService.IViewAppointmentsResultDto, b: ClientService.IViewAppointmentsResultDto) =>
        (a?.appointmentType as any).localeCompare(b?.appointmentType as any),
    },
    {
      title: t.LOCATION,
      dataIndex: 'officeLocation',
      key: 'officeLocation',
      sorter: (a: ClientService.IViewAppointmentsResultDto, b: ClientService.IViewAppointmentsResultDto) =>
        (a?.officeLocation as any).localeCompare(b?.officeLocation as any),
    },
    {
      title: t.ASSIGNED_TO,
      dataIndex: 'agentName',
      key: 'agentName',
      sorter: (a: ClientService.IViewAppointmentsResultDto, b: ClientService.IViewAppointmentsResultDto) =>
        (a?.agentName as any).localeCompare(b?.agentName as any),
    },
    {
      title: t.STATUS,
      dataIndex: 'appointmentStatus',
      key: 'appointmentStatus',
      sorter: (a: ClientService.IViewAppointmentsResultDto, b: ClientService.IViewAppointmentsResultDto) =>
        (a?.appointmentStatus as any).localeCompare(b?.appointmentStatus as any),
    },

    {
      key: 'operation',
      render: (value: string, record: ClientService.IViewAppointmentsResultDto) =>
        editable && (
          <Icon
            iconName="PageEdit"
            className="AppointmentTable__edit-icon"
            onClick={() =>
              navigate(
                `${ROUTES.APPLICATION_OVERVIEW}/${applicationFileId}/${ROUTES.APPOINTMENT_SCHEDULING}/${record?.appointmentId}`,
                {
                  state: { fromApplicationOverview: !isAllAppointments },
                }
              )
            }
          />
        ),
    },
  ];

  return (
    <Table
      columns={columns}
      dataSource={isAllAppointments ? tableData : tableData.slice(0, 5)}
      rowKey={(record: ClientService.IViewAppointmentsResultDto) => record.appointmentId ?? ''}
      loading={tableDataLoading}
      className={tableData?.length ? 'AppointmentTable' : 'AppointmentTable__empty-table'}
      pagination={
        isAllAppointments && {
          position: ['bottomRight'],
          defaultPageSize: DEFAULT_PAGE_SIZE,
          showSizeChanger: true,
          showTotal: paginationShowTotal,
        }
      }
    />
  );
}

export default AppointmentTable;
