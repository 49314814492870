import { useCallback, useState, useMemo } from 'react';
import { Modal, Form, Row, Col, Input, Spin, message, Typography } from 'antd';

import Button from '../../components/Button/Button';

import useLocale from '../../hooks/useLocale';

import { ClientService } from '../../shared/api/ClientService';
import API from '../../utils/api';

import './OverrideModal.scss';

export interface IOverrideModal {
  supportingDocument?: ClientService.SupportingDocumentStaffPortalDto;
  onOk?: () => void;
  onCancel?: () => void;
}

interface IForm {
  overrideReason?: string;
}

function OverrideModal({ supportingDocument, onOk, onCancel }: IOverrideModal): JSX.Element {
  const { t } = useLocale();
  const { TextArea } = Input;
  const [form] = Form.useForm<IForm>();

  const [isOverriding, setIsOverriding] = useState<boolean>(false);

  const isRemoveOverrideAction = useMemo(() => supportingDocument?.isOverridden, [supportingDocument?.isOverridden]);

  const isCreateOverrideAction = useMemo(() => !supportingDocument?.isOverridden, [supportingDocument?.isOverridden]);

  const handleOverride = useCallback(
    async (values: IForm) => {
      setIsOverriding(true);
      const result = await API.updateOverrideStatus({
        supportingDocumentId: supportingDocument?.id,
        isOverriden: !Boolean(supportingDocument?.isOverridden),
        overrideReason: isCreateOverrideAction ? values?.overrideReason : undefined,
      } as ClientService.UpdateOverrideStatusDto).catch(() => setIsOverriding(false));

      if (result?.result === ClientService.Result.Successful) {
        message.success(isCreateOverrideAction ? t.SUCCESSFULLY_OVERRIDEN : t.OVERRIDE_SUCCESSFULLY_REMOVED);
        onOk?.();
      } else {
        message.error(result?.messages?.[0]?.body || t.SOMETHING_WENT_WRONG);
      }
      setIsOverriding(false);
    },
    [
      isCreateOverrideAction,
      onOk,
      supportingDocument?.id,
      supportingDocument?.isOverridden,
      t.OVERRIDE_SUCCESSFULLY_REMOVED,
      t.SOMETHING_WENT_WRONG,
      t.SUCCESSFULLY_OVERRIDEN,
    ]
  );

  const handleCancel = useCallback(() => {
    if (onCancel) onCancel();
  }, [onCancel]);

  return (
    <Modal
      title={isRemoveOverrideAction ? t.REMOVE_OVERRIDE : t.ENTER_OVERRIDE_REASON}
      destroyOnClose
      centered
      visible
      footer={null}
      closable
      onCancel={handleCancel}
      className="OverrideModal"
    >
      <Spin spinning={isOverriding}>
        <Typography
          style={{
            paddingBottom: isRemoveOverrideAction ? 36 : 18,
          }}
        >
          {isRemoveOverrideAction
            ? t.ARE_YOU_SURE_YOU_WANT_TO_REMOVE_THIS_OVERRIDE
            : t.PROVIDE_REASON_TO_REMOVE_A_REQUIRED_DOCUMENT?.replace(
                '{0}',
                supportingDocument?.documentName as string
              )}
        </Typography>
        <Form form={form} layout="vertical" initialValues={{ overrideReason: undefined }} onFinish={handleOverride}>
          {isCreateOverrideAction && (
            <Row>
              <Col span={24}>
                <Form.Item
                  name="overrideReason"
                  label={t.OVERRIDE_REASON}
                  required={isCreateOverrideAction}
                  rules={[{ required: isCreateOverrideAction, message: t.REQUIRED_FIELD }]}
                >
                  <TextArea size="large" maxLength={100} rows={3} />
                </Form.Item>
              </Col>
            </Row>
          )}

          <Row gutter={12} justify="end" align="bottom" wrap={false}>
            <Col>
              <Form.Item noStyle>
                <Button kind="cancel" onClick={onCancel}>
                  {t.CANCEL}
                </Button>
              </Form.Item>
            </Col>
            <Col>
              <Form.Item noStyle shouldUpdate>
                {({ getFieldsError, isFieldsTouched }) => (
                  <Button
                    htmlType="submit"
                    disabled={
                      isCreateOverrideAction &&
                      (!isFieldsTouched() || getFieldsError().some(({ errors }) => errors.length))
                    }
                  >
                    {isRemoveOverrideAction ? t.CONFIRM : t.SAVE}
                  </Button>
                )}
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Spin>
    </Modal>
  );
}

export default OverrideModal;
