import { ReactNode, useMemo } from 'react';
import { deepMapChildren } from '../../utils/deepMapChildren';

interface IProps {
  run?: boolean;
  processChild?: (child: any) => ReactNode;
}

const DeepEditChildren: React.FC<IProps> = ({ run = false, processChild, children }): JSX.Element => {
  const processedChildren = useMemo(() => {
    if (run && processChild && typeof processChild === 'function') {
      return deepMapChildren(children, processChild);
    }
    return children;
  }, [run, processChild, children]);

  return processedChildren;
};

export default DeepEditChildren;
