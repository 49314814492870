import { Form, Row, Space } from 'antd';
import moment, { Moment } from 'moment';

import Button from '../../Button/Button';

import { ClientService } from '../../../shared/api/ClientService';
import useLocale from '../../../hooks/useLocale';

interface IProps {
  disabled?: boolean;
  onClick: (moment: Moment, officeLocationId: string, timeRange: ClientService.AppointmentTimeRangeEnum) => void;
}

const NextAvailableRow = ({ disabled = false, onClick }: IProps) => {
  const { t } = useLocale();

  return (
    <Form.Item noStyle shouldUpdate>
      {({ getFieldValue }) => {
        const officeLocationId = getFieldValue('officeLocationId');

        return (
          <Row justify="center">
            <Space wrap align="center">
              <Form.Item>
                <Button
                  disabled={disabled}
                  kind="cancel"
                  onClick={() => onClick(moment(), officeLocationId, ClientService.AppointmentTimeRangeEnum.Morning)}
                >
                  {t.NEXT_AVAILABLE_MORNING}
                </Button>
              </Form.Item>
              <Form.Item>
                <Button
                  disabled={disabled}
                  kind="cancel"
                  onClick={() => onClick(moment(), officeLocationId, ClientService.AppointmentTimeRangeEnum.Afternoon)}
                >
                  {t.NEXT_AVAILABLE_AFTERNOON}
                </Button>
              </Form.Item>
              <Form.Item>
                <Button
                  disabled={disabled}
                  kind="cancel"
                  onClick={() => onClick(moment(), officeLocationId, ClientService.AppointmentTimeRangeEnum.Evening)}
                >
                  {t.NEXT_AVAILABLE_EVENING}
                </Button>
              </Form.Item>
            </Space>
          </Row>
        );
      }}
    </Form.Item>
  );
};

export default NextAvailableRow;
