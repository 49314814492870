import { useState, useCallback, useEffect } from 'react';
import { Modal, Input, Select, Form, Spin } from 'antd';

import Button from '../../../../components/Button/Button';
import SelectSuffixIcon from '../../../../components/SelectSuffixIcon/SelectSuffixIcon';

import useLocale from '../../../../hooks/useLocale';
import genericMessage from '../../../../utils/genericMessage';
import { MAX_PAGE_SIZE } from '../../../../constants/common';

import { argumentifyTemplatesSearchCriteria } from '../../../../pages/AdministrationPage/Templates/utils';
import { ClientService } from '../../../../shared/api/ClientService';
import API from '../../../../utils/api';

import './SMSEditor.scss';

interface IForm {
  phoneNumber?: string;
  templateId?: string;
  smsBody?: string;
}

interface IProps {
  data?: ClientService.ISMSAttributesDto;
  fileId: string;
  onNext?: () => void;
  onCancel?: () => void;
}

const EMAIL_TYPE_NAME = 'Email';

function SMSEditor({ data, fileId, onNext, onCancel }: IProps): JSX.Element {
  const { t } = useLocale();
  const { Option } = Select;
  const { TextArea } = Input;
  const [form] = Form.useForm<IForm>();

  const [phones, setPhones] = useState<ClientService.ClientPhoneDto[]>();
  const [phonesLoading, setPhonesLoading] = useState<boolean>(false);

  const [phoneTypes, setPhoneTypes] = useState<ClientService.PhoneTypeLookupDto[]>();
  const [phoneTypesLoading, setPhoneTypesLoading] = useState<boolean>(false);

  const [templates, setTemplates] = useState<ClientService.AppSMSTemplateDto[]>();
  const [loading, setLoading] = useState<boolean>(false);

  const requestGetTemplates = useCallback(async () => {
    setLoading(true);
    const templates = await API.getMessageTemplateList(
      ...argumentifyTemplatesSearchCriteria({
        maxResultCount: MAX_PAGE_SIZE,
      })
    ).catch(() => setLoading(false));
    setLoading(false);

    if (templates?.items) {
      setTemplates(templates?.items?.filter((item) => item?.typeName !== EMAIL_TYPE_NAME && item?.isActive));
    }
  }, []);

  const requestPhoneTypes = useCallback(async () => {
    setPhoneTypesLoading(true);
    const response = await API.listPhoneTypes();
    if (response) setPhoneTypes(response);
    setPhoneTypesLoading(false);
  }, []);

  const selectPhoneByDefault = useCallback(
    (filePhoneNumbers?: ClientService.ClientPhoneDto[]) => {
      if (filePhoneNumbers?.length === 1) {
        form.setFieldsValue({ phoneNumber: filePhoneNumbers?.[0]?.phoneNumber });
      }
    },
    [form]
  );

  const requestPhoneNumbers = useCallback(async () => {
    setPhonesLoading(true);
    const response = await API.getFilePhoneNumbers(fileId);
    const filePhoneNumbers = response?.filePhoneNumbers;
    if (filePhoneNumbers) {
      const mobilePhoneTypeId = phoneTypes?.find((type) => type.enumValue === ClientService.PhoneTypeEnum.Mobile)?.id;
      const mobilePhonesNumbers = filePhoneNumbers?.filter((item) => item.phoneTypeId === mobilePhoneTypeId) || [];
      setPhones(mobilePhonesNumbers);
      selectPhoneByDefault(mobilePhonesNumbers);
    }
    setPhonesLoading(false);
  }, [fileId, selectPhoneByDefault, phoneTypes]);

  useEffect(() => {
    requestGetTemplates();
    requestPhoneTypes();
  }, [requestGetTemplates, requestPhoneTypes]);

  useEffect(() => {
    if (!phones && phoneTypes) {
      requestPhoneNumbers();
    }
  }, [phones, phoneTypes, requestPhoneNumbers]);

  const handleSubmit = useCallback(async () => {
    const validatedValues = await form.validateFields();

    if (validatedValues) {
      setLoading(true);
      const response = await API.sendSMS({ ...validatedValues, fileId } as ClientService.SMSAttributesDto).catch(
        (error) => {
          setLoading(false);
          genericMessage.error({}, error?.error?.message);
        }
      );

      setLoading(false);
      if (response?.result === ClientService.Result.Successful) {
        genericMessage.success(t.SMS_SENT_SUCCESS_MESSAGE);
        if (onNext) onNext();
      } else {
        genericMessage.error({}, response?.messages?.[0]?.body);
      }
    }
  }, [form, fileId, t.SMS_SENT_SUCCESS_MESSAGE, onNext]);

  const handleTemplateSelect = useCallback(
    (value, option) => {
      const selectedTemplate = templates?.find((item) => item?.id === option?.key);
      form.setFieldsValue({ smsBody: selectedTemplate?.body });
    },
    [form, templates]
  );

  return (
    <Modal
      destroyOnClose
      centered
      visible
      title={t.SMS_NEW}
      width={700}
      onCancel={onCancel}
      footer={null}
      afterClose={() => form.resetFields()}
      className="SMSEditor"
    >
      <Spin spinning={phoneTypesLoading || loading || phonesLoading}>
        <Form
          name="form"
          form={form}
          layout="vertical"
          initialValues={{
            phoneNumber: data?.phoneNumber,
            smsBody: data?.messageTemplateBody,
            templateId: data?.templateId,
          }}
          className="SMSEditor__form"
          onFinish={handleSubmit}
        >
          <Form.Item name="templateId" label={t.TEMPLATE}>
            <Select
              onChange={handleTemplateSelect}
              suffixIcon={<SelectSuffixIcon />}
              size="large"
              showSearch
              filterOption={(input, option) => option?.title?.toLowerCase().includes(input.toLowerCase() || '')}
            >
              {templates?.map((item) => (
                <Option key={item?.id} value={item?.id} title={item?.name}>
                  {item?.name}
                </Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item name="phoneNumber" label={t.SMS_TO_MOBILE} rules={[{ required: true, message: t.REQUIRED_FIELD }]}>
            <Select size="large" suffixIcon={<SelectSuffixIcon />}>
              {phones?.map((item) => (
                <Option key={item?.id} value={item?.phoneNumber}>
                  {item?.phoneNumber}
                </Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item name="smsBody" label={t.MESSAGE} rules={[{ required: true, message: t.REQUIRED_FIELD }]}>
            <TextArea rows={6} />
          </Form.Item>

          <div className="SMSEditor__row">
            <Button kind="cancel" padding="large" onClick={onCancel}>
              {t.CANCEL}
            </Button>
            <Button kind="primary" padding="large" htmlType="submit">
              {t.SEND}
            </Button>
          </div>
        </Form>
      </Spin>
    </Modal>
  );
}

export default SMSEditor;
