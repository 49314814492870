import { IListUsersSearchCriteria, IOfficeLocationsSearchCriteria } from './types';
import { MAX_PAGE_SIZE } from '../../../../../constants/common';

export const argumentifyListUsersSearchCriteria = ({
  searchText,
  roleId,
  isExternal,
  excludeAgentswithNoNames,
  pageIndex,
  pageSize = MAX_PAGE_SIZE,
  sortBy,
  isAscending,
  isActive,
}: IListUsersSearchCriteria) =>
  [
    searchText,
    roleId,
    isExternal,
    excludeAgentswithNoNames,
    pageIndex,
    pageSize,
    sortBy,
    isAscending,
    isActive,
  ] as const;

export const argumentifyOfficeLocationsSearchCriteria = ({
  filterText,
  nearbyLatitude,
  nearbyLongitude,
  includeInactive,
  isActive,
  sorting,
  skipCount,
  maxResultCount = MAX_PAGE_SIZE,
}: IOfficeLocationsSearchCriteria) =>
  [filterText, nearbyLatitude, nearbyLongitude, includeInactive, isActive, sorting, skipCount, maxResultCount] as const;
