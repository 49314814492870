import { Form as AntDForm, FormProps } from 'antd';
import React, { useCallback, useEffect, useState } from 'react';
import ErrorsContainer, { IErrorsMsgAndType, mapAndOrderMessages } from '../../ErrorsContainer/ErrorsContainer';
import DeepEditChildren from '../../DeepEditChildren/DeepEditChildren';
import { ClientService } from '../../../shared/api/ClientService';
import useLocale from '../../../hooks/useLocale';
import useUnsaved from '../../../hooks/useUnsaved';
import genericMessage from '../../../utils/genericMessage';
import Loading from '../../Loading/Loading';
import { IGenericObject } from '../../../types/IGenericObject';

type HandleResponseFunction = (response: any, values?: any) => void;
type HandleResponseFunctionAndOption = {
  function: HandleResponseFunction;
  override?: boolean;
};

type HandleErrorFunction = (error: any) => void;
type HandleErrorFunctionAndOption = {
  function: HandleErrorFunction;
  override?: boolean;
};

type HandleResponseType = HandleResponseFunction | HandleResponseFunctionAndOption;

type HandleErrorType = HandleErrorFunction | HandleErrorFunctionAndOption;

export type CustomFormPropsStandard = {
  changedFormProtection?: boolean;
  disableAllFields?: boolean;
  readonlyAllFields?: boolean;
  isLoading?: boolean;
  onFinish?: (values: any) => void;
  apiSubmit?: never;
  onValidationSuccess?: never;
  onSubmitResponseError?: never;
  onSubmitResponseSuccess?: never;
  onSubmitRequestError?: never;
} & FormProps;

export type CustomFormPropsAPISegments = {
  changedFormProtection?: boolean;
  disableAllFields?: boolean;
  readonlyAllFields?: boolean;
  isLoading?: boolean;
  apiSubmit?: (values: any) => Promise<ClientService.SaveResult | ClientService.RemoteServiceErrorResponse>;
  onValidationSuccess?: (values: any) => void;
  onSubmitResponseError?: HandleResponseType;
  onSubmitResponseSuccess?: HandleResponseType;
  onSubmitRequestError?: HandleErrorType;
  onFinish?: never;
} & Omit<FormProps, 'onFinish'>;

type CustomFormProps = CustomFormPropsStandard | CustomFormPropsAPISegments;

function AppForm({
  changedFormProtection,
  disableAllFields = false,
  readonlyAllFields = false,
  isLoading = false,
  className,
  children,
  onValuesChange,
  onFinishFailed,
  onFinish,
  apiSubmit,
  onValidationSuccess,
  onSubmitResponseError,
  onSubmitResponseSuccess,
  onSubmitRequestError,
  layout = 'vertical',
  validateTrigger = ['onSubmit', 'onBlur'],
  ...props
}: CustomFormProps): JSX.Element {
  const { setIsUnsavedForm } = useUnsaved();
  const { t } = useLocale();

  const [isSending, setIsSending] = useState<boolean>(false);
  const [formSubmissionErrors, setFormSubmissionErrors] = useState<IErrorsMsgAndType[] | null>(null);

  const setFormInputAsDisabledOrReadonly = useCallback(
    (child: any) => {
      if (child.type?.name === 'FormInput') {
        let settings: IGenericObject = {};
        if (disableAllFields) settings.disabled = true;
        if (readonlyAllFields) settings.readOnly = true;
        return React.cloneElement(child, settings);
      } else {
        return React.cloneElement(child);
      }
    },
    [disableAllFields, readonlyAllFields]
  );

  const handleOnValuesChange = useCallback(
    (changedValues, allValues) => {
      if (onValuesChange) {
        onValuesChange(changedValues, allValues);
      }
      if (changedFormProtection) setIsUnsavedForm(true);
    },
    [onValuesChange, changedFormProtection, setIsUnsavedForm]
  );

  const handleOnFinishFailed = useCallback(
    (errorInfo) => {
      if (onFinishFailed) {
        onFinishFailed(errorInfo);
      }
      if (changedFormProtection) setIsUnsavedForm(true);
    },
    [onFinishFailed, changedFormProtection, setIsUnsavedForm]
  );

  const handleOnFinish = useCallback(
    (values) => {
      if (onFinish) {
        onFinish(values);
        return false;
      }

      function getUserFuncAndOverride(propValue: any): [(...args: any[]) => void, boolean] {
        let userFunc = undefined;
        let useOverride = false;
        if (propValue && (propValue as any).function != null && typeof (propValue as any).function === 'function') {
          userFunc = (propValue as any).function;
          const overrideOption = (propValue as any).override;
          useOverride = overrideOption ? overrideOption : false;
        } else if (propValue && typeof propValue === 'function') {
          userFunc = propValue;
        }
        return [userFunc, useOverride];
      }

      if (onValidationSuccess) onValidationSuccess(values);

      setFormSubmissionErrors(null);

      if (apiSubmit) {
        setIsSending(true);
        console.log(values);
        apiSubmit(values)
          .then((response) => {
            setIsSending(false);
            // If the request results in an unhandled error on the back-end
            if (response instanceof ClientService.RemoteServiceErrorResponse && response.error?.code) {
              const [onSubmitResponseErrorFunction, useOverride] = getUserFuncAndOverride(onSubmitResponseError);
              if (useOverride) {
                if (onSubmitResponseErrorFunction) {
                  onSubmitResponseErrorFunction(response);
                }
              } else {
                setFormSubmissionErrors([{ message: t.FORM_GENERIC_ERROR_SUBMITTING }]);
                if (onSubmitResponseErrorFunction) {
                  onSubmitResponseErrorFunction(response);
                }
              }
              // If the request completes successfully...
            } else if (response instanceof ClientService.SaveResult) {
              // ...the response represents a handled error on the back-end
              if (response.hasErrors || response.result === ClientService.Result.Failed) {
                const [onSubmitResponseErrorFunction, useOverride] = getUserFuncAndOverride(onSubmitResponseError);
                if (useOverride) {
                  if (onSubmitResponseErrorFunction) {
                    onSubmitResponseErrorFunction(response);
                  }
                } else {
                  let responseErrors: IErrorsMsgAndType[];
                  if (response.messages && response.messages.length > 0) {
                    responseErrors = mapAndOrderMessages(response.messages);
                  } else {
                    responseErrors = [
                      {
                        message: t.FORM_GENERIC_ERROR_SUBMITTING,
                        messageType: ClientService.ResultMessageType.Error,
                      },
                    ];
                  }
                  if (onSubmitResponseErrorFunction) {
                    onSubmitResponseErrorFunction(response);
                  }
                  // set error messages in state to be rendered at top of form
                  setFormSubmissionErrors(responseErrors);
                }
                // ...the response is received without errors (though it may have warnings)
              } else {
                if (changedFormProtection) setIsUnsavedForm(false);
                const [onSubmitResponseSuccessFunction, useOverride] = getUserFuncAndOverride(onSubmitResponseSuccess);
                if (useOverride) {
                  if (onSubmitResponseSuccessFunction) {
                    onSubmitResponseSuccessFunction(response, values);
                  }
                } else {
                  if (response.hasWarnings && response.messages && response.messages.length > 0) {
                    const responseErrors = mapAndOrderMessages(response.messages);
                    // set warning messages in state to be rendered at top of form
                    // then continue
                    setFormSubmissionErrors(responseErrors);
                  }
                  if (response.result != undefined) {
                    if (onSubmitResponseSuccessFunction) {
                      onSubmitResponseSuccessFunction(response, values);
                    }
                    setTimeout(() => genericMessage.success(t.SUCCESSFULLY_SAVED), 500);
                  }
                }
              }
            }
          })
          .catch((error) => {
            setIsSending(false);
            const [onSubmitRequestErrorFunction, useOverride] = getUserFuncAndOverride(onSubmitRequestError);
            if (useOverride) {
              if (onSubmitRequestErrorFunction) {
                onSubmitRequestErrorFunction(error);
              }
            } else {
              // Set a generic error if the request didn't send properly
              setFormSubmissionErrors([{ message: t.FORM_GENERIC_ERROR_SUBMITTING }]);
              if (onSubmitRequestErrorFunction) {
                onSubmitRequestErrorFunction(error);
              }
            }
          });
      }
    },
    [
      onFinish,
      onValidationSuccess,
      apiSubmit,
      onSubmitResponseError,
      changedFormProtection,
      onSubmitResponseSuccess,
      onSubmitRequestError,
      setIsUnsavedForm,
      t.FORM_GENERIC_ERROR_SUBMITTING,
      t.SUCCESSFULLY_SAVED,
    ]
  );

  useEffect(() => {
    if (changedFormProtection) {
      return () => setIsUnsavedForm(false);
    }
  }, [changedFormProtection, setIsUnsavedForm]);

  if (isSending != null && isSending) return <Loading text={t.SAVING} />;

  if (isLoading) return <Loading />;

  return (
    <div className={`Custom_Form ${className ? className : ''}`}>
      {formSubmissionErrors && <ErrorsContainer errors={formSubmissionErrors} />}
      <AntDForm
        onValuesChange={(changedValues, allValues) => handleOnValuesChange(changedValues, allValues)}
        onFinishFailed={(errorInfo) => handleOnFinishFailed(errorInfo)}
        onFinish={(values) => handleOnFinish(values)}
        layout={layout}
        validateTrigger={validateTrigger}
        {...props}
      >
        <DeepEditChildren run={disableAllFields || readonlyAllFields} processChild={setFormInputAsDisabledOrReadonly}>
          {children}
        </DeepEditChildren>
      </AntDForm>
    </div>
  );
}

AppForm.AntD = AntDForm;

export default AppForm;
