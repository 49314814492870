import { useCallback, useState } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { Row, Col, Form, Spin } from 'antd';
import moment from 'moment';

import Breadcrumb from '../../../components/Breadcrumb/Breadcrumb';
import PageSubtitle from '../../../components/PageSubtitle/PageSubtitle';
import FollowUpModal from '../../../modals/FollowUp/FollowUp';
import SchedulingComponent, {
  ISchedulingForm,
  ISchedulingComponentProps,
} from '../../../components/SchedulingComponent';
import Button from '../../../components/Button/Button';

import useLocale from '../../../hooks/useLocale';
import useModal from '../../../hooks/useModal';
import genericMessage from '../../../utils/genericMessage';
import { APPOINTMENT_ADDED_EVENT, APPOINTMENT_CHANGE_EVENT } from '../../../constants/eventBus';
import { eventBus } from '../../../utils/eventBus';

import { ROUTES } from '../../../constants/routes';
import { ClientService } from '../../../shared/api/ClientService';
import API from '../../../utils/api';

import './AppointmentScheduling.scss';

interface LocationState {
  fromDebtorProfile?: boolean;
  fromApplicationOverview?: boolean;
  shedulingComponentProps?: ISchedulingComponentProps;
}

const AppointmentScheduling = () => {
  const { t } = useLocale();
  const navigate = useNavigate();
  const location = useLocation();
  const state = location?.state as LocationState;
  const [form] = Form.useForm<ISchedulingForm>();

  const { showModal, closeModal } = useModal();
  const { applicationFileId, appointmentId } = useParams<{ applicationFileId: string; appointmentId?: string }>();
  const [loading, setLoading] = useState<boolean>(false);

  const handleOnSuccessNavigation = useCallback(() => {
    if (state?.fromDebtorProfile) {
      navigate(ROUTES.DEBTOR_SEARCH);
    } else if (state?.fromApplicationOverview) {
      navigate(`${ROUTES.APPLICATION_OVERVIEW}/${applicationFileId}`);
    } else {
      navigate(`${ROUTES.APPLICATION_OVERVIEW}/${applicationFileId}/${ROUTES.APPOINTMENTS}`);
    }
  }, [applicationFileId, navigate, state?.fromApplicationOverview, state?.fromDebtorProfile]);

  const requestUpdateAppointment = useCallback(
    async ({ appointmentStatusId, selectedTimeslot, agentId, officeLocationId }) => {
      const body = {
        appointmentId,
        appointmentStatusId,
        appointmentDateTime: selectedTimeslot?.time,
        officeLocationId,
        meetingTypeId: selectedTimeslot?.timeslotMeetingTypeId,
        agentId,
      } as ClientService.EditAppointmentInput;

      setLoading(true);
      const response = await API.editAppointment(body).catch(() => setLoading(false));
      setLoading(false);

      if (response?.result === ClientService.Result.Successful) {
        genericMessage.success(t.APPOINTMENT_UPDATED_SUCCESS);
        eventBus.dispatch(APPOINTMENT_CHANGE_EVENT);
        handleOnSuccessNavigation();
      } else {
        genericMessage.error({}, response?.messages?.[0]?.body);
      }
    },
    [appointmentId, handleOnSuccessNavigation, t.APPOINTMENT_UPDATED_SUCCESS]
  );

  const requestBookAppointment = useCallback(
    async ({ selectedTimeslot, agentId, officeLocationId, appointmentTypeId }) => {
      const body = {
        fileId: applicationFileId,
        appointmentDateTime: selectedTimeslot?.time,
        meetingTypeId: selectedTimeslot?.timeslotMeetingTypeId,
        officeLocationId,
        appointmentTypeId,
        agentId,
      } as ClientService.BookAppointmentInput;

      setLoading(true);
      const response = await API.bookAppointment(body).catch(() => setLoading(false));
      setLoading(false);

      if (response?.result === ClientService.Result.Successful) {
        genericMessage.success(t.APPOINTMENT_CREATED_SUCCESS);
        eventBus.dispatch(APPOINTMENT_ADDED_EVENT);
        handleOnSuccessNavigation();
      } else {
        genericMessage.error({}, response?.messages?.[0]?.body);
      }
    },
    [applicationFileId, handleOnSuccessNavigation, t.APPOINTMENT_CREATED_SUCCESS]
  );

  const handleSubmit = useCallback(
    (values) => {
      if (appointmentId) {
        requestUpdateAppointment(values);
      } else {
        requestBookAppointment(values);
      }
    },
    [appointmentId, requestBookAppointment, requestUpdateAppointment]
  );

  const handleUpdateFollowUpDate = useCallback(
    async (followUpDate?: moment.Moment) => {
      const profile = await API.getDebtorProfile(applicationFileId);

      if (profile?.profile) {
        API.updateDebtorProfilePOST(true, { ...profile?.profile, followUpDate } as ClientService.DebtorProfileDto);
      }
    },
    [applicationFileId]
  );

  const handleCancel = useCallback(() => {
    if (state?.fromDebtorProfile) {
      showModal(
        <FollowUpModal
          onSave={(date) => {
            handleUpdateFollowUpDate(moment(date));
            closeModal();
            navigate(ROUTES.DEBTOR_SEARCH);
          }}
          onCancel={() => {
            closeModal();
            navigate(ROUTES.DEBTOR_SEARCH);
          }}
        />
      );
    } else {
      navigate(-1);
    }
  }, [closeModal, handleUpdateFollowUpDate, navigate, showModal, state?.fromDebtorProfile]);

  return (
    <>
      <Breadcrumb
        data={
          state?.fromDebtorProfile
            ? [
                { link: ROUTES.DASHBOARD, title: t.DASHBOARD },
                { link: ROUTES.NEW_PROSPECT, title: t.NEW_PROSPECT },
                { link: `${ROUTES.DEBTOR_PROFILE}/${applicationFileId}/`, title: t.DEBTOR_PROFILE },
                {
                  title: t.APPOINTMENT_SCHEDULING,
                },
              ]
            : [
                { link: ROUTES.DASHBOARD, title: t.DASHBOARD },
                { link: `${ROUTES.DEBTOR_SEARCH}`, title: t.DEBTOR_SEARCH },
                { link: `${ROUTES.APPLICATION_OVERVIEW}/${applicationFileId}/`, title: t.APPLICATION_OVERVIEW },
                {
                  link: `${ROUTES.APPLICATION_OVERVIEW}/${applicationFileId}/${ROUTES.APPOINTMENTS}`,
                  title: t.APPOINTMENTS_HISTORY,
                },
                {
                  title: t.APPOINTMENT_SCHEDULING,
                },
              ]
        }
      />
      <PageSubtitle
        title={
          appointmentId ? t.APPOINTMENT_SCHEDULING_VIEW_APPOINTMENT : t.APPOINTMENT_SCHEDULING_SCHEDULE_APPOINTMENT
        }
      />
      <Spin spinning={loading}>
        <Row gutter={20}>
          <Col span={24}>
            <Form layout="vertical" form={form} onFinish={handleSubmit}>
              <SchedulingComponent
                {...state?.shedulingComponentProps}
                fromDebtorProfile={state?.fromDebtorProfile}
                form={form}
              />
              <Col>
                <Row gutter={12} justify="end" align="bottom" wrap={false}>
                  <Col>
                    <Form.Item>
                      <Button kind="cancel" onClick={handleCancel}>
                        {t.CANCEL}
                      </Button>
                    </Form.Item>
                  </Col>
                  <Col>
                    <Form.Item>
                      <Button htmlType="submit">{t.SAVE}</Button>
                    </Form.Item>
                  </Col>
                </Row>
              </Col>
            </Form>
          </Col>
        </Row>
      </Spin>
    </>
  );
};

export default AppointmentScheduling;
