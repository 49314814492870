import { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Form, Col, Row, Spin, Input, Tabs, Checkbox, Divider, Select } from 'antd';
import { useQuery } from 'react-query';
import { LinkOutlined } from '@ant-design/icons';
import moment from 'moment';

import Button from '../../../../../../components/Button/Button';
import DebtorSearchModal, { IDebtorSearchResult } from '../../../../../../components/DebtorSearchModal';
import useModal from '../../../../../../hooks/useModal';
import ActionConfirmationModal from '../../../../../../modals/ActionConfirmationModal/ActionConfirmationModal';
import SchedulingComponent, { ISchedulingForm } from '../../../../../../components/SchedulingComponent';
import SectionHeader from '../components/SectionHeader';
import FirstStepSummary from './FirstStepSummary';
import SelectSuffixIcon from '../../../../../../components/SelectSuffixIcon/SelectSuffixIcon';

import {
  COUNSELLING_TYPES_QUERY,
  DELIVERY_METHODS_QUERY,
  APPLICATION_FILE_QUERY,
  APPOINTMENT_TYPES_QUERY,
  MEETING_TYPES_QUERY,
  USE_QUERY_OPTIONS,
} from '../../../../../../constants/reactQuery';

import {
  getCouncellingTypeEnumBySessionNumber,
  getSessionsByNumber,
  isAnySessionCompleted,
  isAnySessionCancelled,
  getCompletedSession,
  isAnySessionPending,
  getPendingSession,
} from '../utils';
import genericMessage from '../../../../../../utils/genericMessage';
import useLocale from '../../../../../../hooks/useLocale';
import { ADMIN_INFO_CHANGE_EVENT } from '../../../../../../constants/eventBus';
import { eventBus } from '../../../../../../utils/eventBus';

import { ClientService } from '../../../../../../shared/api/ClientService';
import API from '../../../../../../utils/api';

import './CounsellingViews.scss';

interface IForm extends ClientService.AdminInfoCounsellingSessionCreateDto, ISchedulingForm {}

const INITIAL_INFO_TAB = 'initial_info';
const SCHEDULING_TAB = 'scheduling_tab';

interface IProps {
  onSave: () => void;
  onCancel: () => void;
}

const AddSession = ({ onSave, onCancel }: IProps) => {
  const [form] = Form.useForm<IForm>();
  const { applicationFileId } = useParams<{ applicationFileId: string }>();
  const { showModal, closeModal } = useModal();
  const { TabPane } = Tabs;
  const { Option } = Select;
  const { t } = useLocale();

  const [loading, setLoading] = useState<boolean>(false);

  const [activeKey, setActiveKey] = useState<string>(INITIAL_INFO_TAB);
  const [relatedDebtor, setRelatedDebtor] = useState<IDebtorSearchResult>();
  const [meetingTypeId, setMeetingTypeId] = useState<string>();

  const { data: deliveryMethods } = useQuery(
    [DELIVERY_METHODS_QUERY],
    () => API.listMethodOfDeliveries(),
    USE_QUERY_OPTIONS
  );

  const { data: meetingTypes } = useQuery([MEETING_TYPES_QUERY], () => API.listMeetingTypes(), USE_QUERY_OPTIONS);

  const { data: applicationFile } = useQuery(
    [APPLICATION_FILE_QUERY],
    () => API.applicationFile2(applicationFileId as string),
    USE_QUERY_OPTIONS
  );

  const { data: appointmentTypes } = useQuery(
    [APPOINTMENT_TYPES_QUERY],
    () => API.listAppointmentTypes(applicationFileId),
    USE_QUERY_OPTIONS
  );

  const { data: counsellingTypes } = useQuery(
    [COUNSELLING_TYPES_QUERY],
    () => API.listCounsellingSessionTypes(),
    USE_QUERY_OPTIONS
  );

  const setSessionNumber = useCallback((sessions?: ClientService.AdminInfoCounsellingSessionDto[]) => {
    const firstSessions = getSessionsByNumber(1, sessions);
    const secondSessions = getSessionsByNumber(2, sessions);
    const thirdSessions = getSessionsByNumber(3, sessions);

    let number = 1;

    if (!sessions || !sessions?.length) {
      number = 1;
    }

    if (isAnySessionCancelled(firstSessions)) {
      number = 1;
    }

    if (
      isAnySessionCompleted(firstSessions) &&
      getCompletedSession(firstSessions)?.appointment?.completedDate &&
      moment().diff(moment(getCompletedSession(firstSessions)?.appointment?.completedDate), 'days') > 30
    ) {
      number = 2;
    }

    if (isAnySessionPending(firstSessions)) {
      number = 2;
    }

    if (isAnySessionCancelled(secondSessions) && !isAnySessionCompleted(secondSessions)) {
      number = 2;
    }

    if (isAnySessionCompleted(secondSessions)) {
      number = 3;
    }

    if (isAnySessionCancelled(thirdSessions) && !isAnySessionCompleted(thirdSessions)) {
      number = 3;
    }

    return number;
  }, []);

  const requestCounsellingGET = useCallback(
    async (applicationFileId: string) => {
      setLoading(true);
      const response = await API.counsellingGET(applicationFileId).catch(() => setLoading(false));

      if (response) {
        form.setFieldsValue({
          sessionNumber: setSessionNumber(response?.sessions),
        });
      }

      setLoading(false);
    },
    [form, setSessionNumber]
  );

  const getAppointmentTypeIdBySessionNumber = useCallback(
    (sessionNumber: number) => {
      const counsellingTypeEnum = getCouncellingTypeEnumBySessionNumber(sessionNumber);
      const appointmentType = appointmentTypes?.find((item) => item.enumValue === counsellingTypeEnum);
      return appointmentType?.id;
    },
    [appointmentTypes]
  );

  const requestSessionPOST = useCallback(
    async ({
      sessionNumber,
      ascendMethodOfDeliveryId,
      ascendCounsellingTypeId,
      relatedDebtorId,
      includeRelatedDebtor,
      ...appointmentInfo
    }) => {
      setLoading(true);

      const body = {
        sessionNumber,
        ascendMethodOfDeliveryId,
        ascendCounsellingTypeId,
        includeRelatedDebtor,
        relatedDebtorId,
        appointmentInfo: {
          fileId: applicationFileId,
          appointmentDateTime: appointmentInfo?.selectedTimeslot?.time,
          meetingTypeId: appointmentInfo?.selectedTimeslot?.timeslotMeetingTypeId,
          officeLocationId: appointmentInfo?.officeLocationId,
          appointmentTypeId: appointmentInfo?.appointmentTypeId,
          agentId: appointmentInfo?.agentId,
        },
      } as ClientService.AdminInfoCounsellingSessionCreateDto;

      const response = await API.sessionPOST(applicationFileId as string, body).catch(() => setLoading(false));
      setLoading(false);

      if (response?.result === ClientService.Result.Successful) {
        if (
          response?.messages?.[0]?.messageType == ClientService.ResultMessageType.Warning &&
          response?.messages?.[0]?.body != undefined
        ) {
          genericMessage.warning(response?.messages?.[0]?.body);
        }
        eventBus.dispatch(ADMIN_INFO_CHANGE_EVENT);
        onSave();
      } else {
        genericMessage.error({}, response?.messages?.[0]?.body);
      }
    },
    [applicationFileId, onSave]
  );

  const requestSessionValidation = useCallback(
    async ({ sessionNumber, relatedDebtorId, includeRelatedDebtor }) => {
      setLoading(true);

      const response = await API.validateCreateSession(
        applicationFileId as string,
        sessionNumber,
        includeRelatedDebtor,
        relatedDebtorId
      ).catch(() => setLoading(false));
      setLoading(false);

      if (response?.result === ClientService.Result.Successful) {
        handleIncludeRelatedDebtorConfirmation();
      } else {
        genericMessage.error({}, response?.messages?.[0]?.body);
      }
    },
    [applicationFileId]
  );

  const handleIncludeRelatedDebtorConfirmation = useCallback(() => {
    showModal(
      <ActionConfirmationModal
        title={t.CONFIRMATION}
        message={t.SCHEDULE_THE_COUNSELLING_SESSION_WITH_THE_CURRENT_AND_RELATED_DEBTOR}
        onOk={() => {
          closeModal();
          setActiveKey(SCHEDULING_TAB);
        }}
        onCancel={closeModal}
      />
    );
  }, [closeModal, showModal, t.CONFIRMATION, t.SCHEDULE_THE_COUNSELLING_SESSION_WITH_THE_CURRENT_AND_RELATED_DEBTOR]);

  const handleDebtorSelect = useCallback(() => {
    showModal(
      <DebtorSearchModal
        searchCriteria={{ hasActiveFiles: true }}
        onOk={(relatedDebtor?: IDebtorSearchResult) => {
          closeModal();
          setRelatedDebtor(relatedDebtor);
          form.setFieldsValue({ relatedDebtorId: relatedDebtor?.fileId });
        }}
        onCancel={closeModal}
      />
    );
  }, [closeModal, form, showModal]);

  const handleSubmit = useCallback(() => {
    const values = form.getFieldsValue();
    if (activeKey === SCHEDULING_TAB) {
      form.validateFields().then(() => requestSessionPOST(values));
    } else {
      form.validateFields(['ascendCounsellingTypeId', 'ascendMethodOfDeliveryId']).then(() => {
        if (values?.relatedDebtorId) {
          requestSessionValidation(values);
        } else {
          setActiveKey(SCHEDULING_TAB);
        }
      });
    }
  }, [activeKey, form, handleIncludeRelatedDebtorConfirmation, requestSessionPOST]);

  useEffect(() => {
    if (applicationFileId) requestCounsellingGET(applicationFileId);
  }, [applicationFileId, requestCounsellingGET]);

  return (
    <Spin spinning={loading}>
      <Form form={form} layout="vertical" className="CounsellingViews">
        <Tabs activeKey={activeKey} renderTabBar={() => <></>}>
          <TabPane key={INITIAL_INFO_TAB} tab={t.INITIAL_INFO}>
            <Row gutter={20}>
              <Col span={24}>
                <SectionHeader title={t.NEW_COUNSELLING_SESSION} details={t.INITIAL_INFORMATION} />
              </Col>
              <Col span={8}>
                <Form.Item name="sessionNumber" label={t.SESSION}>
                  <Input size="large" disabled />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  name="ascendCounsellingTypeId"
                  label={t.TYPE}
                  rules={[{ required: true, message: t.REQUIRED_FIELD }]}
                  initialValue={ClientService.CounsellingSessionTypeEnum.Individual}
                >
                  <Select size="large" suffixIcon={<SelectSuffixIcon />} placeholder={t.SELECT}>
                    {counsellingTypes?.map((item) => (
                      <Option value={item?.enumValue} key={item.enumValue}>
                        {item?.name}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>

              <Col span={8}>
                <Form.Item
                  name="ascendMethodOfDeliveryId"
                  label={t.METHOD}
                  rules={[{ required: true, message: t.REQUIRED_FIELD }]}
                >
                  <Select
                    size="large"
                    suffixIcon={<SelectSuffixIcon />}
                    placeholder={t.SELECT}
                    onSelect={(value: ClientService.MethodOfDeliveryEnum) => {
                      setMeetingTypeId(
                        meetingTypes?.find((item) =>
                          value === ClientService.MethodOfDeliveryEnum.InPerson
                            ? item.enumValue === ClientService.MeetingTypeEnum.InPerson
                            : item.enumValue === ClientService.MeetingTypeEnum.Phone
                        )?.id
                      );
                    }}
                  >
                    {deliveryMethods?.map((item) => (
                      <Option value={item?.enumValue} key={item.enumValue}>
                        {item?.name}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>

            <Row align="bottom">
              <Col span={8}>
                <Form.Item label={t.RELATED_DEBTORS} name="relatedDebtorId">
                  <Row gutter={8} align="middle">
                    <Col onClick={handleDebtorSelect}>
                      <LinkOutlined rotate={45} style={{ color: 'red', fontSize: 18 }} />
                    </Col>
                    <Col>{`${relatedDebtor?.firstName || ''} ${relatedDebtor?.lastName || ''}`}</Col>
                  </Row>
                </Form.Item>
              </Col>

              <Col span={relatedDebtor ? 8 : 0}>
                <Form.Item name="includeRelatedDebtor" valuePropName="checked" label=" ">
                  <Checkbox>{t.INCLUDE_RELATED_DEBTOR_IN_COUNSELLING_SESSION}</Checkbox>
                </Form.Item>
              </Col>
            </Row>

            <Divider dashed style={{ marginTop: -24 }} />
          </TabPane>

          <TabPane key={SCHEDULING_TAB} tab={t.SCHEDULE}>
            <Col span={24}>
              <SectionHeader title={t.NEW_COUNSELLING_SESSION} details={t.SCHEDULING_APPOINTMENT} />
            </Col>

            <FirstStepSummary {...form.getFieldsValue()} isEditable onEdit={() => setActiveKey(INITIAL_INFO_TAB)} />

            <Form.Item noStyle shouldUpdate>
              {({ getFieldValue }) => (
                <SchedulingComponent
                  form={form}
                  fixedOfficeLocationId={applicationFile?.serviceLocationId}
                  fixedAppointmentTypeId={getAppointmentTypeIdBySessionNumber(getFieldValue('sessionNumber'))}
                  fixedMeetingTypeId={meetingTypeId}
                  isFirstCounsellingSession
                />
              )}
            </Form.Item>
          </TabPane>
        </Tabs>

        <Row align="middle" justify="end" gutter={20} style={{ paddingTop: 24 }}>
          <Col>
            <Form.Item>
              <Button kind="cancel" onClick={onCancel}>
                {t.CANCEL}
              </Button>
            </Form.Item>
          </Col>
          <Col>
            <Form.Item>
              <Button kind="primary" onClick={handleSubmit}>
                {activeKey === SCHEDULING_TAB ? t.SAVE : t.NEXT}
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Spin>
  );
};

export default AddSession;
