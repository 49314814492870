import { ReactNode, useCallback, useMemo, useEffect, useState } from 'react';
import { Collapse } from 'antd';
import DataItem from '../../../../../../components/DataItem/DataItem';
import { IValue } from '../../../../../../components/DataItem/DataItem';
import Loading from '../../../../../../components/Loading/Loading';
import Icon from '../../../../../../components/Icon/Icon';
import SecondaryRecordsDisplay, { SecondaryRecordsDisplayProps } from './SecondaryRecordsDisplay';
import DetailsTable, { DetailsTableProps } from './DetailsTable';

import styles from './ExpandableRecordsDisplay.module.scss';

const { Panel } = Collapse;

export interface IMasterRecord {
  id: string;
  [key: string]: any;
}

export interface IRecordField {
  label: string;
  dataIndex: string;
  format?: IValue['format'];
  width?: string;
}

export interface ITableColumn {
  title: ReactNode;
  dataIndex: string;
}

interface IGenericObject {
  [key: string]: any;
}

interface ExpandableRecordsDisplayProps {
  recordFields: IRecordField[];
  masterRecords?: IMasterRecord[];
  apiGetMasterRecords?: () => Promise<any>;
  apiSetMasterRecords?: (
    response: any,
    setMasterRecords: React.Dispatch<React.SetStateAction<IMasterRecord[] | undefined>>
  ) => void;
  extraMasterRecords?: IMasterRecord[];
  apiGetExtraMasterRecords?: () => Promise<any>;
  apiSetExtraMasterRecords?: (
    response: any,
    setExtraMasterRecords: React.Dispatch<React.SetStateAction<IMasterRecord[] | undefined>>
  ) => void;
  secondaryFields?: SecondaryRecordsDisplayProps['secondaryFields'];
  argsForSecondaryApiGet?: string[];
  secondaryRecords?: SecondaryRecordsDisplayProps['secondaryRecords'];
  apiGetSecondaryRecords?: SecondaryRecordsDisplayProps['apiGetSecondaryRecords'];
  apiSetSecondaryRecords?: SecondaryRecordsDisplayProps['apiSetSecondaryRecords'];
  tableColumns: ITableColumn[];
  apiGetTableData?: DetailsTableProps['apiGetTableData'];
  apiSetTableData?: DetailsTableProps['apiSetTableData'];
  viewTableRecordRoute?: DetailsTableProps['viewRecordRoute'];
  className?: string;
  style?: React.CSSProperties;
}

const ExpandableRecordsDisplay = ({
  recordFields,
  masterRecords,
  apiGetMasterRecords,
  apiSetMasterRecords,
  extraMasterRecords,
  apiGetExtraMasterRecords,
  apiSetExtraMasterRecords,
  argsForSecondaryApiGet,
  secondaryFields,
  secondaryRecords,
  apiGetSecondaryRecords,
  apiSetSecondaryRecords,
  tableColumns,
  apiGetTableData,
  apiSetTableData,
  viewTableRecordRoute,
  className,
  style,
}: ExpandableRecordsDisplayProps): JSX.Element => {
  const [masterRecordsSet, setMasterRecordsSet] = useState<IMasterRecord[] | undefined>();
  const [extraMasterRecordsSet, setExtraMasterRecordsSet] = useState<IMasterRecord[] | undefined>();
  const [isLoadingMasterRecords, setIsLoadingMasterRecords] = useState<boolean>(false);
  const [isLoadingExtraMasterRecords, setIsLoadingExtraMasterRecords] = useState<boolean>(false);

  const addMasterRecordsSetToState = (
    passedRecordsSet: IMasterRecord[] | undefined,
    apiGetRecords: (() => Promise<any>) | undefined,
    apiSetRecords:
      | ((
          response: any,
          setFetchedRecordsInState: React.Dispatch<React.SetStateAction<IMasterRecord[] | undefined>>
        ) => void)
      | undefined,
    setLoading: React.Dispatch<React.SetStateAction<boolean>>,
    setRecordsInState: React.Dispatch<React.SetStateAction<IMasterRecord[] | undefined>>
  ) => {
    setLoading(true);

    if (passedRecordsSet) {
      setLoading(false);
      setRecordsInState(passedRecordsSet);
      return false;
    }

    if (apiGetRecords) {
      apiGetRecords()
        .then((response) => {
          setLoading(false);
          if (apiSetRecords) {
            apiSetRecords(response, setRecordsInState);
          } else {
            setRecordsInState(response);
          }
        })
        .catch((error) => {
          setLoading(false);
          console.error(error.message);
        });
    }
  };

  useEffect(() => {
    if (!masterRecordsSet) {
      addMasterRecordsSetToState(
        masterRecords,
        apiGetMasterRecords,
        apiSetMasterRecords,
        setIsLoadingMasterRecords,
        setMasterRecordsSet
      );
    }
  }, [
    masterRecordsSet,
    masterRecords,
    apiGetMasterRecords,
    apiSetMasterRecords,
    setIsLoadingMasterRecords,
    setMasterRecordsSet,
  ]);

  useEffect(() => {
    if (!extraMasterRecordsSet && (extraMasterRecords || apiGetExtraMasterRecords)) {
      addMasterRecordsSetToState(
        extraMasterRecords,
        apiGetExtraMasterRecords,
        apiSetExtraMasterRecords,
        setIsLoadingExtraMasterRecords,
        setExtraMasterRecordsSet
      );
    }
  }, [
    extraMasterRecordsSet,
    extraMasterRecords,
    apiGetExtraMasterRecords,
    apiSetExtraMasterRecords,
    setIsLoadingExtraMasterRecords,
    setExtraMasterRecordsSet,
  ]);

  const renderHeaderRow = useCallback(
    (record: any) => {
      return (
        <>
          <div className={styles.header_row}>
            {record.leadValue != null ? <div className={styles.lead_value}>{record.leadValue}</div> : null}
            <div className={styles.header_values}>
              {recordFields.map((field, index) => {
                // debugger;
                let thisValue: any;
                if (record[field.dataIndex] == null) {
                  thisValue = undefined;
                } else if ('format' in field) {
                  thisValue = { value: record[field.dataIndex], format: field.format };
                } else {
                  thisValue = record[field.dataIndex];
                }
                const thisWidth = field.width ? field.width : '1';
                return (
                  <DataItem
                    key={`record-${record.id}_field-${index}`}
                    noCol
                    className={styles.header_value}
                    label={field.label}
                    value={thisValue}
                    horizontalSplit="auto"
                    style={{ flex: `${thisWidth} 0 0` }}
                  />
                );
              })}
            </div>
          </div>
        </>
      );
    },
    [recordFields]
  );

  const useSecondaryRecordsDisplay = useMemo(() => {
    if (secondaryRecords || apiGetSecondaryRecords) {
      return true;
    }
    return false;
  }, [secondaryRecords, apiGetSecondaryRecords]);

  const renderSecondaryRecordsDisplay = useCallback(
    (record: IMasterRecord) => {
      const argsObjForSecondaryApiGet: IGenericObject = { id: record.id };
      if (argsForSecondaryApiGet && argsForSecondaryApiGet.length > 0) {
        argsForSecondaryApiGet.forEach((dataIndex) => {
          argsObjForSecondaryApiGet[dataIndex] = record[dataIndex];
        });
      }
      return (
        <SecondaryRecordsDisplay
          argsForApiGet={argsObjForSecondaryApiGet}
          secondaryFields={secondaryFields}
          secondaryRecords={secondaryRecords}
          apiGetSecondaryRecords={apiGetSecondaryRecords}
          apiSetSecondaryRecords={apiSetSecondaryRecords}
          tableColumns={tableColumns}
          apiGetTableData={apiGetTableData}
          apiSetTableData={apiSetTableData}
          viewTableRecordRoute={viewTableRecordRoute}
        />
      );
    },
    [
      secondaryFields,
      secondaryRecords,
      argsForSecondaryApiGet,
      apiGetSecondaryRecords,
      apiSetSecondaryRecords,
      tableColumns,
      apiGetTableData,
      apiSetTableData,
      viewTableRecordRoute,
    ]
  );

  const renderTable = useCallback(
    (id: string) => {
      const columns = tableColumns.map((column) => {
        return {
          key: `table-${id}_${column.dataIndex}`,
          title: column.title,
          dataIndex: column.dataIndex,
        };
      });

      return (
        <DetailsTable
          idForApiGet={id}
          columns={columns}
          apiGetTableData={apiGetTableData}
          apiSetTableData={apiSetTableData}
          viewRecordRoute={viewTableRecordRoute}
        />
      );
    },
    [tableColumns, apiGetTableData, apiSetTableData, viewTableRecordRoute]
  );

  if (isLoadingMasterRecords || isLoadingExtraMasterRecords) return <Loading noText />;

  return (
    <div className={`${styles.ExpandableRecordsDisplay} ${className ? className : ''}`} style={style}>
      <Collapse className={styles.ExpandableRecordsDisplay_Collapse} accordion bordered={false}>
        {masterRecordsSet &&
          masterRecordsSet.map((record, index) => {
            return (
              <Panel header={renderHeaderRow(record)} key={`record-${index}`}>
                {useSecondaryRecordsDisplay ? renderSecondaryRecordsDisplay(record) : renderTable(record.id)}
              </Panel>
            );
          })}
        {extraMasterRecordsSet &&
          extraMasterRecordsSet.map((record, index) => {
            return (
              <Panel header={renderHeaderRow(record)} key={`extrarecord-${index}`}>
                {useSecondaryRecordsDisplay ? renderSecondaryRecordsDisplay(record) : renderTable(record.id)}
              </Panel>
            );
          })}
      </Collapse>
    </div>
  );
};

export default ExpandableRecordsDisplay;
