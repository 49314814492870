import { useCallback, useEffect, useState, useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Collapse, Input, Form, Row, Col, Spin } from 'antd';
import moment from 'moment';

import DebtorProfileInfoBlock from '../../../components/DebtorProfileInfoBlock/DebtorProfileInfoBlock';
import FollowUpModal from '../../../modals/FollowUp/FollowUp';
import Breadcrumb from '../../../components/Breadcrumb/Breadcrumb';
import BasicInformation from './panels/BasicInformation';
import ContactInformation from './panels/ContactInformation';
import SourceInformation from './panels/SourceInformation';
import Button from '../../../components/Button/Button';
import PanelHeader from './components/PanelHeader';

import useLocale from '../../../hooks/useLocale';
import useModal from '../../../hooks/useModal';
import useUnsaved from '../../../hooks/useUnsaved';

import { ROUTES } from '../../../constants/routes';
import { DATE_FORMAT2 } from '../../../constants/common';
import genericMessage from '../../../utils/genericMessage';
import { formatAddressLine, prevalidatePhoneNumber } from '../utils';
import {
  BASIC_INFO_PANEL,
  CONTACT_INFO_PANEL,
  SOURCE_PANEL,
  NOTES_PANEL,
  INITIAL_CONSULTATION,
  CANCELLED_STATUS,
  REQUIRED_BASIC_PANEL_FIELDS,
  REQUIRED_CONTACT_PANEL_FIELDS,
  REQUIRED_SOURCE_PANEL_FIELDS,
  CONDITIONALLY_REQUIRED_CONTACT_PANEL_FIELDS,
} from './constants';
import { ClientService } from '../../../shared/api/ClientService';
import API from '../../../utils/api';
import { convertInputDateToServerDateOnly, convertServerDateOnlyToInputDate } from '../../../utils/helpers';
import { useDebtorProfile } from '../DebtorProfilePage';

import './DebtorProfileContent.scss';

interface ICollapseErrors {
  [panelName: string]: boolean;
}

interface IError {
  name: string[];
  errors: string[];
  warnings: string[];
}

const DebtorProfileContent = (): JSX.Element => {
  const { Panel } = Collapse;
  const { TextArea } = Input;

  const navigate = useNavigate();
  const params = useParams();

  const { t } = useLocale();
  const { showModal, closeModal } = useModal();
  const { setIsUnsavedForm } = useUnsaved();
  const { uuid: debtorId } = useParams<{ uuid?: string }>();
  const { isDuplicate } = useDebtorProfile();

  const [form] = Form.useForm<ClientService.DebtorProfileDto>();

  const [header, setHeader] = useState<ClientService.DebtorProfileHeaderDto>();
  const [profile, setProfile] = useState<ClientService.DebtorProfileDto>();
  const [appointments, setAppointment] = useState<ClientService.ViewAppointmentsResultDto[]>();

  const [loading, setLoading] = useState(false);

  const [errors, setErrors] = useState<ICollapseErrors>({
    [BASIC_INFO_PANEL]: false,
    [CONTACT_INFO_PANEL]: false,
    [SOURCE_PANEL]: false,
  });

  const requestDebtorProfile = useCallback(async (debtorId: string) => {
    setLoading(true);
    const response = await API.getDebtorProfile(debtorId).catch(() => setLoading(false));

    if (response) {
      let countryId = response?.profile?.countryId;

      if (!countryId) {
        const countries = await API.listCountries().catch(() => setLoading(false));
        countryId = countries?.find((item) => item?.code === 'CA')?.id;
      }

      let preferredLanguageId = response?.profile?.preferredLanguageId;

      if (!preferredLanguageId) {
        const languages = await API.listLanguages().catch(() => setLoading(false));
        preferredLanguageId = languages?.find((item) => item?.code === 'en')?.id;
      }

      setProfile({ ...response?.profile, countryId, preferredLanguageId } as ClientService.DebtorProfileDto);
      setHeader(response?.header);
    }
    setLoading(false);
  }, []);

  const requestAppointments = useCallback(async (fileId: string) => {
    const response = await API.listAppointmentDetails(fileId, true);
    if (response) {
      setAppointment(response);
    }
  }, []);

  const hasScheduledInitialConsultation = useMemo(
    () =>
      Boolean(
        appointments?.find(
          (item) => item.appointmentType === INITIAL_CONSULTATION && item.appointmentStatus !== CANCELLED_STATUS
        )
      ),
    [appointments]
  );

  const requestCreateNewApplication = useCallback(async () => {
    setLoading(true);
    const response = await API.createNewApplicationFile(profile?.clientId).catch(() => setLoading(false));
    setLoading(false);

    if (response?.result === ClientService.Result.Successful) {
      navigate(`${ROUTES.DEBTOR_PROFILE}/${response.returnId}`);
    } else {
      genericMessage.error({}, response?.messages?.[0]?.body);
    }
  }, [profile?.clientId, navigate]);

  const requestUpdateDebtorProfile = useCallback(
    async (onSuccess?: () => void, date?: moment.Moment) => {
      setLoading(true);
      const values = form.getFieldsValue();

      const response = await API.updateDebtorProfilePOST(Boolean(date), {
        ...profile,
        ...values,
        dateOfBirth: convertInputDateToServerDateOnly(values?.dateOfBirth),
        followUpDate: date ? convertInputDateToServerDateOnly(date) : values?.followUpDate,
      } as ClientService.DebtorProfileDto).catch(() => setLoading(false));
      setLoading(false);

      if (response?.result === ClientService.Result.Successful) {
        genericMessage.success(t.DEBTOR_PROFILE_IS_UPDATED);
        if (onSuccess) onSuccess();
      } else {
        genericMessage.error({}, response?.messages?.[0]?.body);
      }
    },
    [form, profile, t.DEBTOR_PROFILE_IS_UPDATED]
  );

  const handleErrors = useCallback(
    async (errors: IError[]) => {
      const errorFields = errors?.map((item) => item.name)?.flat();
      setErrors({
        [BASIC_INFO_PANEL]: Boolean(errorFields?.find((item) => REQUIRED_BASIC_PANEL_FIELDS.indexOf(item) >= 0)),
        [CONTACT_INFO_PANEL]: Boolean(
          errorFields?.find(
            (item) =>
              (form?.getFieldValue('enableClientLogin')
                ? CONDITIONALLY_REQUIRED_CONTACT_PANEL_FIELDS
                : REQUIRED_CONTACT_PANEL_FIELDS
              ).indexOf(item) >= 0
          )
        ),
        [SOURCE_PANEL]: Boolean(errorFields?.find((item) => REQUIRED_SOURCE_PANEL_FIELDS.indexOf(item) >= 0)),
      });
    },
    [form]
  );

  const handleShowFollowUpModal = useCallback(() => {
    showModal(
      <FollowUpModal
        onCancel={closeModal}
        onSave={(date?: moment.Moment) => {
          requestUpdateDebtorProfile(() => {
            closeModal();
            navigate(ROUTES.DEBTOR_SEARCH);
          }, date);
        }}
      />
    );
  }, [closeModal, navigate, requestUpdateDebtorProfile, showModal]);

  const handleSaveAndClose = useCallback(async () => {
    form
      .validateFields()
      .then(() => {
        if (!hasScheduledInitialConsultation) {
          handleShowFollowUpModal();
        } else {
          requestUpdateDebtorProfile(() => navigate(ROUTES.DEBTOR_SEARCH));
        }
      })
      .catch((errors) => handleErrors(errors?.errorFields));
  }, [
    form,
    handleErrors,
    handleShowFollowUpModal,
    hasScheduledInitialConsultation,
    navigate,
    requestUpdateDebtorProfile,
  ]);

  const handleSaveAndBook = useCallback(async () => {
    form
      .validateFields()
      .then(() => {
        requestUpdateDebtorProfile(() => {
          navigate(`${ROUTES.APPLICATION_OVERVIEW}/${profile?.fileId}/${ROUTES.APPOINTMENT_SCHEDULING}`, {
            state: { fromDebtorProfile: true },
          });
        });
      })
      .catch((errors) => handleErrors(errors?.errorFields));
  }, [form, handleErrors, navigate, profile?.fileId, requestUpdateDebtorProfile]);

  useEffect(() => {
    if (profile) {
      form.setFieldsValue({
        ...profile,
        address: formatAddressLine(profile),
        mainPhoneNumber: prevalidatePhoneNumber(profile?.mainPhoneNumber),
        dateOfBirth: convertServerDateOnlyToInputDate(profile?.dateOfBirth),
        followUpDate: convertServerDateOnlyToInputDate(profile?.followUpDate),
        alternatePhoneNumbers: profile?.alternatePhoneNumbers?.map(
          (item) =>
            ({
              ...item,
              phoneNumber: prevalidatePhoneNumber(item?.phoneNumber),
            } as ClientService.ClientPhoneDto)
        ),
      });
    }
  }, [profile, form]);

  useEffect(() => {
    if (debtorId) requestDebtorProfile(debtorId);
  }, [debtorId, requestDebtorProfile]);

  useEffect(() => {
    if (profile?.fileId) requestAppointments(profile?.fileId);
  }, [profile?.fileId, requestAppointments]);

  useEffect(() => {
    return () => {
      setIsUnsavedForm(false);
    };
  }, [setIsUnsavedForm]);

  return (
    <>
      <Breadcrumb
        data={[
          { link: ROUTES.DASHBOARD, title: t.DASHBOARD },
          { link: `${ROUTES.DEBTOR_PROFILE}/${params.uuid}/`, title: t.DEBTOR_PROFILE },
        ]}
      />
      <DebtorProfileInfoBlock
        rightSide={{
          label: header?.stage?.name,
          value: header?.stage?.orderNumber,
        }}
        leftSide={[
          {
            label: t.DEBTOR_PROFILE_INFO_CREATED_ON,
            value: header?.createdDate ? moment(header?.createdDate).format(DATE_FORMAT2) : undefined,
          },
          {
            label: t.DEBTOR_PROFILE_INFO_AGENT,
            value: header?.agent,
          },
          {
            label: t.DEBTOR_PROFILE_ESTATE_ADMINISTRATOR,
            value: header?.estateAdministrator,
          },
          {
            label: t.DEBTOR_PROFILE_TRUSTEE,
            value: header?.trustee,
          },
        ]}
      />
      <Spin spinning={loading}>
        <div className="DebtorProfileContent">
          <Form form={form} layout="vertical" onValuesChange={() => setIsUnsavedForm(true)}>
            <Collapse ghost defaultActiveKey={[BASIC_INFO_PANEL]} accordion>
              <Panel
                forceRender
                header={<PanelHeader title={t.DEBTOR_PROFILE_BASIC_INFO} hasErrors={errors?.[BASIC_INFO_PANEL]} />}
                key={BASIC_INFO_PANEL}
              >
                <BasicInformation fileId={profile?.fileId} disabled={isDuplicate} />
              </Panel>

              <Panel
                forceRender
                header={<PanelHeader title={t.DEBTOR_PROFILE_CONTACT_INFO} hasErrors={errors?.[CONTACT_INFO_PANEL]} />}
                key={CONTACT_INFO_PANEL}
              >
                <ContactInformation formInstance={form} countryId={profile?.countryId} disabled={isDuplicate} />
              </Panel>

              <Panel
                forceRender
                header={<PanelHeader title={t.DEBTOR_PROFILE_SOURCE} hasErrors={errors?.[SOURCE_PANEL]} />}
                key={SOURCE_PANEL}
              >
                <SourceInformation disabled={isDuplicate} />
              </Panel>

              <Panel header={t.DEBTOR_PROFILE_NOTES} key={NOTES_PANEL}>
                <Form.Item label={t.DEBTOR_PROFILE_NOTES} name="notes">
                  <TextArea rows={4} maxLength={5000} showCount disabled={isDuplicate} />
                </Form.Item>
              </Panel>
            </Collapse>

            <Row gutter={20} justify="end" className="DebtorProfileContent__footer">
              <Col>
                <Button kind="cancel" onClick={() => navigate(ROUTES.DASHBOARD)}>
                  {t.CANCEL}
                </Button>
              </Col>
              {!isDuplicate && (
                <>
                  <Col>
                    <Button onClick={handleSaveAndClose} kind="primary" iconName="SaveAndClose">
                      {t.DEBTOR_PROFILE_SAVE_AND_CLOSE}
                    </Button>
                  </Col>
                  <Col>
                    <Button onClick={handleSaveAndBook} iconName="Save">
                      {t.DEBTOR_PROFILE_SAVE_AND_BOOK}
                    </Button>
                  </Col>

                  {profile?.canCreateNewApplication && (
                    <Col>
                      <Button onClick={requestCreateNewApplication} iconName="Save">
                        {t.DEBTOR_PROFILE_CREATE_NEW_APPLICATION}
                      </Button>
                    </Col>
                  )}
                </>
              )}
            </Row>
          </Form>
        </div>
      </Spin>
    </>
  );
};

export default DebtorProfileContent;
