import { useState, useCallback, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Divider, Row, Col, Empty } from 'antd';
import Layout from '../../components/Layout/Layout';
import PageTitle from '../../components/PageTitle/PageTitle';
import NewAndViewHeader from '../ApplicationOverviewPage/NewAndViewPageComponents/NewAndViewHeader/NewAndViewHeader';
import { ROUTES } from '../../constants/routes';
import useLocale from '../../hooks/useLocale';
import useModal from '../../hooks/useModal';
import AppForm from '../../components/Forms/AppForm/AppForm';
import { ISelectOption } from '../../components/Forms/FormInput/SelectInput';
import SearchAscend, {
  IAscendFormValues,
} from '../ApplicationOverviewPage/NewAndViewPageComponents/SearchAscend/SearchAscend';
import NewClaimFields, {
  IAscendClaimValues,
} from '../ApplicationOverviewPage/NewAndViewPageComponents/SearchAscend/VariableFieldGroups/NewClaimFields';
import EmailContentDisplay, {
  TEmailData,
} from '../ApplicationOverviewPage/NewAndViewPageComponents/EmailContentDisplay/EmailContentDisplay';
import FormInput from '../../components/Forms/FormInput/FormInput';
import DataItem from '../../components/DataItem/DataItem';
import ThreeColumnLowerLayout from '../ApplicationOverviewPage/NewAndViewPageComponents/ThreeColumnLowerLayout/ThreeColumnLowerLayout';
import ButtonsGroupCancelDraft from '../ApplicationOverviewPage/NewAndViewPageComponents/NewAndViewHeader/HeaderButtonGroups/ButtonsGroupCancelDraft';
// import moment from 'moment';
import { ClientService } from '../../shared/api/ClientService';
import API from '../../utils/api';
import {
  argumentifyAscendSearchCriteria,
  argumentifyTeamsSearchCriteria,
} from '../ApplicationOverviewPage/NewAndViewPageComponents/utils';
import { ISearchAscendCriteria } from '../ApplicationOverviewPage/NewAndViewPageComponents/types';
import AscendConfirmActionModal from '../ApplicationOverviewPage/NewAndViewPageComponents/SearchAscend/AscendConfirmActionModal/AscendConfirmActionModal';
import MarkAsActiveRadioSelection from '../ApplicationOverviewPage/NewAndViewPageComponents/SearchAscend/AscendConfirmActionModal/MarkAsActiveRadioSelection/MarkAsActiveRadioSelection';
import ErrorsContainer, { IErrorsMsgAndType } from '../../components/ErrorsContainer/ErrorsContainer';
import genericMessage from '../../utils/genericMessage';
import useErrorHandling from '../../hooks/useErrorHandling';
import Loading from '../../components/Loading/Loading';
import useAlertAsAsync from '../ApplicationOverviewPage/NewAndViewPageComponents/AlertModal/useAlertAsAsync';
import {
  getFullName,
  getUndefinedOrSelf,
  isEmptyGuid,
  convertServerDateOnlyToInputDate,
  convertInputDateToServerDateOnly,
} from '../../utils/helpers';

import styles from './NewClaimPage.module.scss';
import moment from 'moment';
import ClaimDeleteConfirmation from './ClaimDeleteConfirmation';
import AppTaskActionNavigator from '../../components/AppTaskActionNavigator/AppTaskActionNavigator';

/*  ADD CHECK THAT TASK NAME ENUM IS EQUAL TO 'Claim Received' OR 'Secured Claim' */
type ClaimAscendFormValues = IAscendFormValues & IAscendClaimValues;

const NewClaimPage = () => {
  const { t } = useLocale();
  const navigate = useNavigate();

  const { taskActionId } = useParams();

  const [form] = AppForm.AntD.useForm<any>();

  const { processResponseForErrors } = useErrorHandling();
  const { handleAlert } = useAlertAsAsync();

  const [pageErrors, setPageErrors] = useState<IErrorsMsgAndType[] | undefined>();
  const [claimResponseFailed, setClaimResponseFailed] = useState<boolean>(false);
  const [currentAppTaskActionId, setCurrentAppTaskActionId] = useState<string | undefined>(taskActionId);
  const [claimResponse, setClaimResponse] = useState<any | undefined>();
  const [newClaimFormValues, setNewClaimFormValues] = useState<IAscendFormValues | undefined>();
  const [initialFormValuesHaveBeenSet, setInitialFormValuesHaveBeenSet] = useState<boolean>(false);
  const [formIsLoading, setFormIsLoading] = useState<boolean>(true);
  const [emailInfo, setEmailInfo] = useState<TEmailData | undefined>();
  const [teamsList, setTeamsList] = useState<ISelectOption[] | undefined>();

  const [documentIds, setDocumentIds] = useState<string[] | undefined>();

  const [mainFormErrors, setMainFormErrors] = useState<IErrorsMsgAndType[] | undefined>();
  const [externalAscendErrors, setExternalAscendErrors] = useState<IErrorsMsgAndType[] | undefined>();

  const [threeColLayoutIsLoading, setThreeColLayoutIsLoading] = useState<boolean>(true);
  const [threeColErrors, setThreeColErrors] = useState<IErrorsMsgAndType[] | undefined>();

  const { showModal, closeModal } = useModal();

  const resetPageData = useCallback(() => {
    setCurrentAppTaskActionId(undefined);
    setClaimResponse(undefined);
    setNewClaimFormValues(undefined);
    setInitialFormValuesHaveBeenSet(false);
    setThreeColLayoutIsLoading(true);
    setPageErrors(undefined);
    setMainFormErrors(undefined);
  }, []);

  const getClaimData = useCallback(() => {
    if (
      (claimResponse && taskActionId && claimResponse.id === currentAppTaskActionId) ||
      currentAppTaskActionId === undefined ||
      currentAppTaskActionId === null
    )
      return;

    const actionId = currentAppTaskActionId;

    API.taskActionsGET2(actionId ?? '')
      .then((response) => {
        const responseErrors = processResponseForErrors(response);
        setPageErrors(responseErrors.messages);
        if (responseErrors.hasErrors) {
          setClaimResponseFailed(true);
          setFormIsLoading(false);
          return;
        }

        /*
          NOTE: IF THE TASK HAS ALREADY BEEN LINKED, THE PAGE SHOULD REDIRECT
          - CHECK IF response.linkedClaimFileId HAS A VALUE
          - IF SO, REDIRECT TO, I THINK, View Claim Page
          - OTHERWISE...
        */

        setClaimResponse(response);
      })
      .catch((_error) => {
        setPageErrors([{ message: t.DATA_COULD_NOT_BE_RETRIEVED }]);
        setFormIsLoading(false);
      });
  }, [currentAppTaskActionId, taskActionId, claimResponse, processResponseForErrors, t.DATA_COULD_NOT_BE_RETRIEVED]);

  useEffect(() => {
    setCurrentAppTaskActionId(taskActionId);
  }, [taskActionId]);

  useEffect(() => {
    getClaimData();
  }, [getClaimData]);

  useEffect(() => {
    if (!claimResponse) return;

    setEmailInfo({
      fromName: claimResponse.importedEmailSenderName,
      fromAddress: claimResponse.importedEmailSenderEmailAddress,
      toName: claimResponse.importedEmailRecipientName,
      toAddress: claimResponse.importedEmailRecipientEmailAddress,
      subjectField: claimResponse.importedEmailSubject,
      bodyField: claimResponse.importedEmailBody,
    });

    if (!newClaimFormValues) {
      setNewClaimFormValues({
        ascendEstateNumber: claimResponse.extractedEstateNumber,
        ascendSin: claimResponse.extractedSINNumber,
        ascendDebtorName: claimResponse.extractedDebtorName,
        ascendDateOfInsolvency: claimResponse?.extractedInsolvencyDate
          ? convertServerDateOnlyToInputDate(moment(claimResponse?.extractedInsolvencyDate))
          : undefined,
        ascendCreditorName: claimResponse.extractedCreditorName,
        ascendAccountNumber: claimResponse.extractedAccountNumber,
      });
    }

    setDocumentIds(claimResponse.attachedDocumentIds);

    if (claimResponse.assignedAgentId) {
      let _teamTypeEnum =
        claimResponse.appTaskActionName !== 'Secured Claim'
          ? ClientService.TeamTypeEnum.Asset_CPC
          : ClientService.TeamTypeEnum.POC_CPC;

      API.getList3(
        ...argumentifyTeamsSearchCriteria({
          agentIdToBeIgnored: claimResponse.assignedAgentId,
          teamTypeEnum: _teamTypeEnum,
        })
      )
        .then((response) => {
          const responseErrors = processResponseForErrors(response);
          setPageErrors(responseErrors.messages);
          if (!responseErrors.hasErrors) {
            setTeamsList(
              response.items?.map((team) => {
                return {
                  value: team.id,
                  label: team.name,
                };
              })
            );
          }
        })
        .catch((error) => {
          const requestErrors = processResponseForErrors(error);
          setPageErrors(requestErrors.messages);
        });
    }
  }, [claimResponse, newClaimFormValues, processResponseForErrors]);

  useEffect(() => {
    if (!initialFormValuesHaveBeenSet && newClaimFormValues) {
      // Set initial form values
      form.setFieldsValue(newClaimFormValues);
      setInitialFormValuesHaveBeenSet(true);
      setFormIsLoading(false);
    }
  }, [initialFormValuesHaveBeenSet, newClaimFormValues, form]);

  useEffect(() => {
    if (!claimResponse || !emailInfo || !documentIds) return;

    setThreeColLayoutIsLoading(false);
  }, [claimResponse, documentIds, emailInfo]);

  const handleSaveAndRedirect = (redirectToItemId: string) => {
    handleSave().then(() => {
      window.location.href = `${ROUTES.TASK_ACTIONS}/${ROUTES.NEW_CLAIM}/${redirectToItemId}`;
    });
  };

  const fetchNextItemId = async (): Promise<string | undefined> => {
    let itemId: string | undefined = undefined;

    if (taskActionId) {
      const response = await API.appTaskActionNavigation(taskActionId);

      if (!response) return;

      if (response.nextItemId) itemId = response.nextItemId;
      if (!itemId && response.previousItemId) itemId = response.previousItemId;
    }

    return itemId;
  };

  const handleSave = useCallback(
    async (withAlert = false, isRedundant = false) => {
      if (!claimResponse || taskActionId == null) return;
      setMainFormErrors(undefined);

      const formValues = form.getFieldsValue();

      let requestBody: ClientService.IAppTaskActionClaimUpdateDto = {
        id: taskActionId,
        estateNumber: formValues.ascendEstateNumber,
        sin: formValues.ascendSin,
        debtorName: formValues.ascendDebtorName,
        dateOfInsolvency: convertInputDateToServerDateOnly(formValues.ascendDateOfInsolvency),
        creditorName: formValues.ascendCreditorName,
        accountNumber: formValues.ascendAccountNumber,
        isMergeCreditorEmail: formValues.ascendMergeCreditorEmail,
        teamId: getUndefinedOrSelf(formValues.moveClaimTo),
        alerts: new ClientService.AlertCreateDto(),
      };

      const request = new ClientService.AppTaskActionClaimUpdateDto(requestBody);

      if (withAlert) {
        const alertBody = await handleAlert();

        if (alertBody === false) return;
        const typedAlertBody = alertBody as ClientService.IAlertCreateDto;
        const alertRequest = new ClientService.AlertCreateDto(typedAlertBody);
        request.alerts = alertRequest;
      }

      if (isRedundant) request.isRedundant = true;

      API.updateClaim(request)
        .then((response: ClientService.SaveResult | ClientService.RemoteServiceErrorResponse) => {
          const responseErrors = processResponseForErrors(response);
          setMainFormErrors(responseErrors.messages);
          if (responseErrors.hasErrors) return;
          resetPageData();
          setTimeout(() => {
            genericMessage.success(t.SUCCESSFULLY_SAVED);

            if (isRedundant) {
              fetchNextItemId().then((itemId) => {
                if (itemId) {
                  navigate(`${ROUTES.TASK_ACTIONS}/${ROUTES.NEW_CLAIM}/${itemId}`);
                } else {
                  navigate(ROUTES.DASHBOARD);
                }
              });
            } else {
              // if the taskAction is marked as completed and a new one is created for another team then navigate to Dashboard
              if (
                response instanceof ClientService.SaveResult &&
                !isEmptyGuid(response.returnId) &&
                response?.returnId?.toUpperCase() !== taskActionId.toUpperCase()
              ) {
                navigate(`${ROUTES.DASHBOARD}`);
              }
            }
          }, 500);
        })
        .catch((error) => {
          setMainFormErrors([{ message: t.FORM_GENERIC_ERROR_SUBMITTING }]);
        });
    },
    [
      claimResponse,
      taskActionId,
      form,
      handleAlert,
      processResponseForErrors,
      resetPageData,
      t.SUCCESSFULLY_SAVED,
      t.FORM_GENERIC_ERROR_SUBMITTING,
      fetchNextItemId,
      navigate,
    ]
  );

  const handleDeleteImportedClaim = useCallback(() => {
    showModal(
      <ClaimDeleteConfirmation
        onCancel={closeModal}
        onOk={() => {
          handleSave(false, true);
          closeModal();
        }}
      />
    );
  }, [closeModal, handleSave, showModal]);

  return (
    <Layout page={ROUTES.CLAIMS}>
      <div className={styles.NewClaimPage}>
        {pageErrors && <ErrorsContainer errors={pageErrors} noTitle />}
        <PageTitle title={t.CLAIMS_CLAIM_PROCESS} />
        <NewAndViewHeader
          subtitle="new-claim"
          errors={mainFormErrors}
          breadcrumbData={[{ link: ROUTES.DASHBOARD, title: t.DASHBOARD }, { title: t.CLAIMS_CLAIM_PROCESS }]}
          buttons={
            <>
              <ButtonsGroupCancelDraft
                buttonsEnabled={initialFormValuesHaveBeenSet}
                onSaveAsDraft={() => handleSave()}
                onSaveAsDraftWithAlert={() => handleSave(true)}
                onDelete={() => handleDeleteImportedClaim()}
              />
              <AppTaskActionNavigator
                currentItemId={taskActionId}
                onGotoItem={(itemId) => handleSaveAndRedirect(itemId)}
              />
            </>
          }
        />

        <AppForm form={form} isLoading={formIsLoading}>
          <Row gutter={20}>
            <Col span={6}>
              <FormInput type="select" label={t.NEW_CLAIM_MOVE_CLAIM_TO} name="moveClaimTo" optionsList={teamsList} />
            </Col>
          </Row>
          <Divider className={styles.divider} />
          {externalAscendErrors && <ErrorsContainer errors={externalAscendErrors} noTitle />}
          <SearchAscend
            form={form}
            initialValues={newClaimFormValues}
            variableFieldsComponent={<NewClaimFields />}
            numberOfVariableFieldsForSearch={1}
            apiSearch={(ascendSearchValues) => {
              const args: ISearchAscendCriteria = {
                estateNumber: ascendSearchValues.ascendEstateNumber,
                sIN: ascendSearchValues.ascendSin,
                debtorName: ascendSearchValues.ascendDebtorName,
                dateOfInsolvency: ascendSearchValues?.ascendDateOfInsolvency
                  ? convertInputDateToServerDateOnly(ascendSearchValues?.ascendDateOfInsolvency)
                  : undefined,
                creditorName: ascendSearchValues.ascendCreditorName,
                accountNumber: ascendSearchValues.ascendAccountNumber,
              };

              return API.searchExternal(...argumentifyAscendSearchCriteria(args));
            }}
            apiSetData={(responseData: ClientService.LiabilityClaimDto, setStateData) => {
              // console.log('searchAscend Response:', responseData);
              const debtorFile = responseData?.debtorFile;
              const { firstName, middleName, lastName } = debtorFile || {};
              const updatedFormValues: ClaimAscendFormValues = {
                ascendEstateNumber: responseData.debtorFile?.estateNumber,
                ascendSin: responseData.debtorFile?.sin,
                ascendDebtorName: firstName && lastName ? getFullName(firstName, middleName, lastName) : undefined,
                ascendDateOfInsolvency: convertServerDateOnlyToInputDate(
                  moment(responseData.debtorFile?.insolvencyDate)
                ),
                ascendCreditorName: responseData.creditor?.companyName,
                ascendAccountNumber: responseData.accountNumber,
                ascendMergeCreditorEmail: false,
              };
              setStateData(updatedFormValues);
            }}
            confirmLinkMessage={t.ASCEND_ABOUT_TO_LINK_CLAIM}
            apiConfirm={(ascendFormValues, ascendSearchResponse) => {
              // console.log('values passed to apiConfirm():', ascendFormValues);
              const confirmRequestBody: ClientService.IClaimFileLinkInputDto = {
                isForSecuredClaim: claimResponse.appTaskActionName === 'Secured Claim',
                appTaskActionId: taskActionId,
                externalLiabilityClaimId: ascendSearchResponse.externalEntityId,
                estateNumber: ascendFormValues.ascendEstateNumber,
                sin: ascendFormValues.ascendSin,
                debtorName: ascendFormValues.ascendDebtorName,
                dateOfInsolvency: convertInputDateToServerDateOnly(ascendFormValues.ascendDateOfInsolvency),
                creditorName: ascendFormValues.ascendCreditorName,
                accountNumber: ascendFormValues.ascendAccountNumber,
                isMergeCreditorEmail: ascendFormValues.ascendMergeCreditorEmail,
                markAsActive: undefined,
              };

              const confirmRequest = new ClientService.ClaimFileLinkInputDto(confirmRequestBody);
              return {
                apiCall: () => {
                  return API.linkTaskAction(confirmRequest);
                },
                requestBody: confirmRequestBody,
              };
            }}
            onConfirm={(confirmResponse) => {
              const claimFileId = confirmResponse.returnId;

              API.claimFileGET(claimFileId)
                .then((response) => {
                  navigate(`${ROUTES.APPLICATION_OVERVIEW}/${response.fileId}/${ROUTES.CLAIMS}/${response.id}`);
                })
                .catch((error) => {
                  setPageErrors([{ message: t.DATA_COULD_NOT_BE_RETRIEVED }]);
                  setFormIsLoading(false);
                });
            }}
            confirmActionComponent={(
              confirmAttemptResponse,
              ascendFormValues,
              ascendSearchResponse,
              lastConfirmRequestBody,
              onConfirm
            ) => {
              return (
                <AscendConfirmActionModal
                  title={t.ASCEND_MARK_AS_ACTIVE_Q}
                  message={
                    confirmAttemptResponse.messages
                      ? confirmAttemptResponse.messages[0].body
                      : t.ASCEND_MARK_AS_ACTIVE_Q
                  }
                  onSave={(modalFormValues) => {
                    // console.log('modalFormValues passed to onSave():', modalFormValues);
                    const updatedConfirmRequest = {
                      ...lastConfirmRequestBody,
                      markAsActive: modalFormValues.markAsActive,
                    };
                    // console.log('updatedConfirmRequest:', updatedConfirmRequest);

                    const finalConfirmRequest = new ClientService.ClaimFileLinkInputDto(updatedConfirmRequest);

                    API.linkTaskAction(finalConfirmRequest)
                      .then((response: any) => {
                        const responseErrors = processResponseForErrors(response);
                        setExternalAscendErrors(responseErrors.messages);
                        if (responseErrors.hasErrors) return;

                        if (onConfirm) onConfirm(response);
                      })
                      .catch((error) => {
                        const requestErrors = processResponseForErrors(error);
                        setExternalAscendErrors(requestErrors.messages);
                      });
                  }}
                >
                  <MarkAsActiveRadioSelection
                    name="markAsActive"
                    label={t.ASCEND_MARK_AS_ACTIVE_Q}
                    falseOption="Duplicate"
                  />
                </AscendConfirmActionModal>
              );
            }}
          />
          {threeColLayoutIsLoading ? (
            <Loading noText />
          ) : !threeColLayoutIsLoading && claimResponseFailed ? (
            <div style={{ textAlign: 'center' }}>{t.DATA_COULD_NOT_BE_RETRIEVED}</div>
          ) : (
            /**
             *  Claim Process, Bottom section for "Claim Status", "Claim File Review" and "Creditor Email".
             */
            <ThreeColumnLowerLayout
              errors={threeColErrors}
              leftColumnContent={
                <>
                  <DataItem label={t.NEW_CLAIM_CLAIM_STATUS} value={' '} />
                  <div className={styles.label_n_img_wrapper}>
                    <Empty description="" />
                    <div className={styles.label}>{t.COMPLETE_SEARCH_IN_ASCEND_AND_CONFIRM_ESTATE}</div>
                  </div>
                </>
              }
              filesList={documentIds}
              rightColumnContent={
                <EmailContentDisplay title={t.CLAIMS_CREDITOR_EMAIL} errorsSetter={setThreeColErrors} {...emailInfo} />
              }
            />
          )}
        </AppForm>
      </div>
    </Layout>
  );
};

export default NewClaimPage;
