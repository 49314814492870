import { useState, useEffect } from 'react';
import { Link, Outlet, useOutletContext } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { ROUTES } from '../../constants/routes';
import Layout from '../../components/Layout/Layout';
import API from '../../utils/api';
import { ClientService } from '../../shared/api/ClientService';
import PageTitleContainer from '../../components/PageTitleContainer/PageTitleContainer';
import './DebtorProfilePage.scss';
import React from 'react';
import useLocale from '../../hooks/useLocale';

type DebtorProfileContextType = { data: ClientService.DebtorProfilePageDto | null; isDuplicate?: boolean };

const DebtorProfilePage = (): JSX.Element => {
  const params = useParams();
  const { t } = useLocale();
  const [data, setData] = React.useState<ClientService.DebtorProfilePageDto | null>(null);

  const [dataFetching, setDataFetching] = useState(true);
  const [dataError, setDataError] = useState(false);

  const [isDuplicate, setIsDuplicate] = useState<boolean>(false);

  const fetchData = () => {
    setDataError(false);
    setDataFetching(true);

    if (params.uuid) {
      API.getDebtorProfile(params.uuid)
        .then((response) => {
          setData(response);
          setIsDuplicate(response.profile?.isDuplicate ?? false);
          setDataFetching(false);
        })
        .catch(() => {
          setDataFetching(false);
          setDataError(true);
        });
    }
  };

  useEffect(fetchData, [params.uuid]);

  const getCopiedOverLink = () => {
    if (data?.profile?.copiedFromFileId) {
      return (
        <Link to={`${ROUTES.APPLICATION_OVERVIEW}/${data?.profile?.copiedFromFileId}`}>({t.COPIED_FROM_FILE})</Link>
      );
    }
  };

  return (
    <Layout
      loading={dataFetching}
      error={dataError}
      retry={fetchData}
      className="DebtorProfilePage"
      page={ROUTES.DEBTOR_PROFILE}
    >
      <PageTitleContainer
        title={`${data?.profile?.firstName} ${data?.profile?.lastName} ${isDuplicate ? `(${t.DUPLICATE})` : ''}`}
        titleRefLink={getCopiedOverLink()}
        hasToolbar={false}
      />
      <Outlet context={{ data, isDuplicate }} />
    </Layout>
  );
};

export default DebtorProfilePage;

export function useDebtorProfile() {
  return useOutletContext<DebtorProfileContextType>();
}
