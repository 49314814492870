import { useMemo } from 'react';
import { useParams } from 'react-router-dom';

import Breadcrumb from '../../../../components/Breadcrumb/Breadcrumb';
import PageSubtitle from '../../../../components/PageSubtitle/PageSubtitle';
import NotesList from '../../NotesContent/NotesList/NotesList';

import useLocale from '../../../../hooks/useLocale';
import { ROUTES } from '../../../../constants/routes';
import { useApplicationFile } from '../../ApplicationOverviewPage';

import { NEW_NOTE_EVENT } from '../../../../constants/eventBus';
import { eventBus } from '../../../../utils/eventBus';

function NotesHistory(): JSX.Element {
  const { applicationFileId } = useParams<{ applicationFileId: string }>();
  const { t } = useLocale();

  const { data: appFileData } = useApplicationFile();
  const isDuplicate = useMemo(() => appFileData?.isDuplicate, [appFileData]);

  return (
    <>
      <Breadcrumb
        data={[
          { link: ROUTES.DASHBOARD, title: t.DASHBOARD },
          { link: `${ROUTES.DEBTOR_SEARCH}`, title: t.DEBTOR_SEARCH },
          { link: `${ROUTES.APPLICATION_OVERVIEW}/${applicationFileId}`, title: t.APPLICATION_OVERVIEW },
          { title: t.NOTES_HISTORY },
        ]}
      />
      <PageSubtitle
        title={t.NOTES_HISTORY}
        buttonTitle={!isDuplicate && t.NOTE_ADD}
        onButtonClick={() => eventBus.dispatch(NEW_NOTE_EVENT)}
      />
      <NotesList pagination />
    </>
  );
}

export default NotesHistory;
