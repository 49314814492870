import { Form, Input, Select, Row, Col, InputNumber, FormInstance } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
import { ClientService } from '../../../../shared/api/ClientService';
import useLocale from '../../../../hooks/useLocale';
import SelectSuffixIcon from '../../../../components/SelectSuffixIcon/SelectSuffixIcon';

import './ApplicationFormTab.scss';
import styles from '../../../../styles/style.module.scss';
import { antdUtil } from '../../../../utils/antdUtil';
import ChangeTrackerFormItem from '../../../../components/ChangeTrackerFormItem';

interface IProps {
  form: FormInstance<IForm>;
  tabIndex: number;
  isMarried: boolean;
  hasOtherAmounts: boolean;
  descriptions: ClientService.LookupDto[];
  rowName: number;
  remove: (index: number | number[]) => void;
  onChange?: (value: any) => void;
  isLocked?: boolean;
}

interface IForm {
  tabs: ITab[];
}

interface ITab {
  expenseType: ClientService.LookupDto;
  expenseDetails: ClientService.AppFormIncomeExpenseExpenseDetailDto[];
}

function IncomeExpenseExpenseRow({
  form,
  tabIndex,
  isMarried,
  hasOtherAmounts,
  descriptions,
  rowName,
  remove,
  onChange,
  isLocked,
}: IProps): JSX.Element {
  const { t, getLocalizedDtoName } = useLocale();
  const { Option } = Select;

  return (
    <Form.Item
      noStyle
      shouldUpdate={(previousValue, currentExpenseValue) =>
        previousValue?.tabs?.[tabIndex]?.expenseDetails !== currentExpenseValue?.tabs?.[tabIndex]?.expenseDetails
      }
    >
      {({ getFieldValue }) => {
        const expenseDetails = getFieldValue([
          'tabs',
          tabIndex,
          'expenseDetails',
        ]) as ClientService.AppFormIncomeExpenseExpenseDetailDto[];
        const expenseDescriptionId = expenseDetails?.[rowName]?.expenseDescriptionId;
        const currentExpense = descriptions.find((item) => item.id === expenseDescriptionId);
        const availableExpenses = descriptions?.filter(
          (item) => !Boolean(expenseDetails.find((i) => item.id === i.expenseDescriptionId))
        );
        const options = currentExpense ? [...availableExpenses, currentExpense] : availableExpenses;

        const isOther =
          currentExpense?.enumValue === ClientService.ExpenseDescriptionEnum.Other_NonDiscretionary ||
          currentExpense?.enumValue === ClientService.ExpenseDescriptionEnum.Other_Housing ||
          currentExpense?.enumValue === ClientService.ExpenseDescriptionEnum.Other_Living ||
          currentExpense?.enumValue === ClientService.ExpenseDescriptionEnum.Other_Transportation ||
          currentExpense?.enumValue === ClientService.ExpenseDescriptionEnum.Other_Personal ||
          currentExpense?.enumValue === ClientService.ExpenseDescriptionEnum.Other_Insurance ||
          currentExpense?.enumValue === ClientService.ExpenseDescriptionEnum.Other_Payments ||
          currentExpense?.enumValue === ClientService.ExpenseDescriptionEnum.Other_Medical;

        const isAmountDeclared =
          Boolean(expenseDetails?.[rowName]?.applicantAmount) ||
          Boolean(expenseDetails?.[rowName]?.spouseAmount) ||
          Boolean(expenseDetails?.[rowName]?.otherHouseholdMemberAmount);

        const isInitial = expenseDetails?.[rowName]?.isInitial;

        return (
          <Row gutter={20} align="middle">
            <Col flex={isOther ? '10 1 40px' : '25 1 100px'}>
              <Form.Item
                name={[rowName, 'expenseDescriptionId']}
                rules={[{ required: true, message: t.REQUIRED_FIELD }]}
                className="ApplicationFormTab__income-expense-type"
              >
                <Select
                  suffixIcon={<SelectSuffixIcon />}
                  size="large"
                  showArrow={!expenseDescriptionId}
                  disabled={isInitial || isLocked}
                >
                  {options?.map((option) => (
                    <Option key={option.id} value={option?.id}>
                      {getLocalizedDtoName(option)}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            {Boolean(isOther) && (
              <Col flex="15 1 60px">
                <ChangeTrackerFormItem
                  name={[rowName, 'specifiedDescription']}
                  rules={[{ required: isOther && isAmountDeclared, message: t.REQUIRED_FIELD }]}
                  listName={['tabs', tabIndex, 'expenseDetails']}
                >
                  <Input size="large" placeholder={t.DESCRIPTION} disabled={isLocked} />
                </ChangeTrackerFormItem>
              </Col>
            )}
            <Col flex="10 1 100px">
              <ChangeTrackerFormItem
                name={[rowName, 'applicantAmount']}
                listName={['tabs', tabIndex, 'expenseDetails']}
              >
                <InputNumber
                  size="large"
                  style={{ width: '100%' }}
                  min={0}
                  max={999999999}
                  defaultValue={0}
                  formatter={(value) => antdUtil.currencyFormatter(value)}
                  parser={(value) => antdUtil.currencyParser(value) as 0 | 999999999}
                  disabled={isLocked}
                />
              </ChangeTrackerFormItem>
            </Col>
            {Boolean(hasOtherAmounts && isMarried) && (
              <Col flex="10 1 100px">
                <ChangeTrackerFormItem name={[rowName, 'spouseAmount']} listName={['tabs', tabIndex, 'expenseDetails']}>
                  <InputNumber
                    size="large"
                    style={{ width: '100%' }}
                    min={0}
                    max={999999999}
                    defaultValue={0}
                    formatter={(value) => antdUtil.currencyFormatter(value)}
                    parser={(value) => antdUtil.currencyParser(value) as 0 | 999999999}
                    disabled={isLocked}
                  />
                </ChangeTrackerFormItem>
              </Col>
            )}
            {Boolean(hasOtherAmounts) && (
              <Col flex="10 1 100px">
                <ChangeTrackerFormItem
                  name={[rowName, 'otherHouseholdMemberAmount']}
                  listName={['tabs', tabIndex, 'expenseDetails']}
                >
                  <InputNumber
                    size="large"
                    style={{ width: '100%' }}
                    min={0}
                    max={999999999}
                    defaultValue={0}
                    formatter={(value) => antdUtil.currencyFormatter(value)}
                    parser={(value) => antdUtil.currencyParser(value) as 0 | 999999999}
                    disabled={isLocked}
                  />
                </ChangeTrackerFormItem>
              </Col>
            )}
            <Col flex="0 1 40px">
              <DeleteOutlined
                className="ApplicationFormTab__input-action-icon"
                onClick={() => {
                  if (!isLocked) {
                    remove(rowName);
                    onChange && onChange({});
                  }
                }}
                style={{
                  cursor: !isLocked ? 'pointer' : 'not-allowed',
                  color: !isLocked ? styles.colorDanger : styles.colorSecondary,
                }}
              />
            </Col>
          </Row>
        );
      }}
    </Form.Item>
  );
}

export default IncomeExpenseExpenseRow;
