import { useCallback, useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Form, Col, Row, Spin } from 'antd';
import { useQuery } from 'react-query';

import Button from '../../../../../../components/Button/Button';
import SchedulingComponent, { ISchedulingForm } from '../../../../../../components/SchedulingComponent';
import SectionHeader from '../components/SectionHeader';
import FirstStepSummary from './FirstStepSummary';

import {
  APPOINTMENT_TYPES_QUERY,
  APPOINTMENT_STATUSES_QUERY,
  MEETING_TYPES_QUERY,
  USE_QUERY_OPTIONS,
} from '../../../../../../constants/reactQuery';

import genericMessage from '../../../../../../utils/genericMessage';
import { ADMIN_INFO_CHANGE_EVENT } from '../../../../../../constants/eventBus';
import { eventBus } from '../../../../../../utils/eventBus';
import { ClientService } from '../../../../../../shared/api/ClientService';
import API from '../../../../../../utils/api';
import useLocale from '../../../../../../hooks/useLocale';

import './CounsellingViews.scss';

interface IForm extends ClientService.AdminInfoCounsellingSessionUpdateDto, ISchedulingForm {}

interface IProps {
  sessionId?: string;
  onSave: () => void;
  onCancel: () => void;
}

const EditSession = ({ onSave, onCancel, sessionId }: IProps) => {
  const [form] = Form.useForm<IForm>();
  const { applicationFileId } = useParams<{ applicationFileId: string }>();
  const { t } = useLocale();

  const [loading, setLoading] = useState<boolean>(false);
  const [applicationFile, setApplicationFile] = useState<ClientService.ApplicationFileDto>();
  const [session, setSession] = useState<ClientService.AdminInfoCounsellingSessionDto>();

  const { data: meetingTypes } = useQuery([MEETING_TYPES_QUERY], () => API.listMeetingTypes(), USE_QUERY_OPTIONS);

  const { data: appointmentStatuses } = useQuery(
    [APPOINTMENT_STATUSES_QUERY],
    () => API.listAppointmentStatuses(),
    USE_QUERY_OPTIONS
  );

  const { data: appointmentTypes } = useQuery(
    [APPOINTMENT_TYPES_QUERY],
    () => API.listAppointmentTypes(applicationFileId),
    USE_QUERY_OPTIONS
  );

  const requestApplicationFile = useCallback(async (applicationFileId: string) => {
    const response = await API.applicationFile2(applicationFileId as string);
    if (response) setApplicationFile(response);
  }, []);

  const requestSessions = useCallback(async (applicationFileId: string, sessionId: string) => {
    const response = await API.counsellingGET(applicationFileId);
    if (response) setSession(response?.sessions?.find((item) => item.id === sessionId));
  }, []);

  const requestSessionPUT = useCallback(
    async (values) => {
      setLoading(true);

      const body = {
        id: session?.id,
        sessionNumber: session?.sessionNumber,
        ascendMethodOfDeliveryId: session?.ascendMethodOfDeliveryId,
        ascendCounsellingTypeId: session?.ascendCounsellingTypeId,
        includeRelatedDebtor: session?.includeRelatedDebtor,
        relatedDebtorId: session?.relatedDebtorId,
        appointmentId: session?.appointment?.id,
        appointmentStatus: appointmentStatuses?.find((item) => item.id === values?.appointmentStatusId)?.enumValue,
        appointmentDateTime: values?.selectedTimeslot?.time,
        meetingTypeId: values?.selectedTimeslot?.timeslotMeetingTypeId,
        officeLocationId: values?.officeLocationId,
        agentId: values?.agentId,
      } as ClientService.AdminInfoCounsellingSessionUpdateDto;

      const response = await API.sessionPUT(applicationFileId as string, body).catch(() => setLoading(false));
      setLoading(false);

      if (response?.result === ClientService.Result.Successful) {
        if (
          response?.messages?.[0]?.messageType == ClientService.ResultMessageType.Warning &&
          response?.messages?.[0]?.body != undefined
        ) {
          genericMessage.warning(response?.messages?.[0]?.body);
        } else {
          genericMessage.success(t.SESSION_IS_UPDATED);
        }
        eventBus.dispatch(ADMIN_INFO_CHANGE_EVENT);
        onSave();
      } else {
        genericMessage.error({}, response?.messages?.[0]?.body);
      }
    },
    [
      applicationFileId,
      appointmentStatuses,
      onSave,
      session?.appointment?.id,
      session?.ascendCounsellingTypeId,
      session?.ascendMethodOfDeliveryId,
      session?.id,
      session?.includeRelatedDebtor,
      session?.relatedDebtorId,
      session?.sessionNumber,
      t.SESSION_IS_UPDATED,
    ]
  );

  useEffect(() => {
    if (applicationFileId) requestApplicationFile(applicationFileId);
  }, [applicationFileId, requestApplicationFile]);

  useEffect(() => {
    if (applicationFileId && sessionId) requestSessions(applicationFileId, sessionId);
  }, [applicationFileId, requestSessions, sessionId]);

  if (!session) return <></>;

  return (
    <Spin spinning={loading}>
      <Row>
        <Col span={24}>
          <SectionHeader title={`${t.SESSION} ${session?.sessionNumber}`} />
        </Col>
      </Row>

      <FirstStepSummary {...session} isEditable={false} />

      <Form form={form} layout="vertical" className="CounsellingViews" onFinish={requestSessionPUT}>
        <SchedulingComponent
          form={form}
          fixedOfficeLocationId={applicationFile?.serviceLocationId}
          fixedAppointmentTypeId={
            appointmentTypes?.find((item) => item.enumValue === session?.appointment?.appointmentType?.enumValue)?.id
          }
          fixedMeetingTypeId={
            meetingTypes?.find((item) => item.enumValue === session?.appointment?.meetingType?.enumValue)?.id
          }
          appointmentId={session?.appointment?.id}
          submitComponent={(disabled?: boolean) => (
            <Row align="middle" justify="end" gutter={20}>
              <Col>
                <Form.Item>
                  <Button kind="cancel" onClick={onCancel}>
                    {t.CANCEL}
                  </Button>
                </Form.Item>
              </Col>
              <Col>
                <Form.Item>
                  <Button kind="primary" htmlType="submit" disabled={disabled}>
                    {t.SAVE}
                  </Button>
                </Form.Item>
              </Col>
            </Row>
          )}
        />
      </Form>
    </Spin>
  );
};

export default EditSession;
