import { Key, useCallback, useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Table } from 'antd';
import moment from 'moment';

import { DATE_FORMAT2 } from '../../../../constants/common';
import { eventBus } from '../../../../utils/eventBus';
import { IMPORT_ALL_TRADES, IMPORT_SELECTED_TRADES } from '../../../../constants/eventBus';

import useLocale from '../../../../hooks/useLocale';
import { ClientService } from '../../../../shared/api/ClientService';
import API from '../../../../utils/api';

import './Panels.scss';
import genericMessage from '../../../../utils/genericMessage';

interface IProps {
  dataSource?: ClientService.CreditReportHistoryTradeDto[];
}

const Trade = ({ dataSource }: IProps) => {
  const { t } = useLocale();
  const { applicationFileId } = useParams<{ applicationFileId?: string }>();
  const [selectedRowKeys, setSelectedRowKeys] = useState<Key[]>();

  const columns = [
    {
      title: t.MEMBER_CODE,
      dataIndex: 'memberCode',
      key: 'memberCode',
      width: 100,
    },
    {
      title: t.MEMBER_NAME,
      dataIndex: 'memberName',
      key: 'memberName',
    },
    {
      title: t.TYPE,
      dataIndex: 'type',
      key: 'type',
    },
    {
      title: t.ACCOUNT,
      dataIndex: 'account',
      key: 'account',
    },
    {
      title: t.JOIN,
      dataIndex: 'joint',
      key: 'joint',
    },
    {
      title: t.OPENED,
      dataIndex: 'dateOpened',
      key: 'dateOpened',
      render: (item: moment.Moment) => <>{item ? moment(item).utc().format(DATE_FORMAT2) : '-'}</>,
    },
    {
      title: t.REVISED,
      dataIndex: 'dateRevised',
      key: 'dateRevised',
      render: (item: moment.Moment) => <>{item ? moment(item).utc().format(DATE_FORMAT2) : '-'}</>,
    },
    {
      title: t.LAST_ACTIVITY,
      dataIndex: 'dateLastActivity',
      key: 'dateLastActivity',
      render: (item: moment.Moment) => <>{item ? moment(item).utc().format(DATE_FORMAT2) : '-'}</>,
    },
    {
      title: t.MANNER_OF_PAYMENT,
      dataIndex: 'mannerOfPaymentDescription',
      key: 'mop',
    },
    {
      title: t.BALANCE_DOLLARS,
      dataIndex: 'balance',
      key: 'balance',
    },
    {
      title: t.HIGH_CREDIT_DOLLARS,
      dataIndex: 'highCredit',
      key: 'highCredit',
    },
    {
      title: t.CREDIT_LIMIT_DOLLARS,
      dataIndex: 'creditLimit',
      key: 'creditLimit',
    },
    {
      title: t.FREQUENCY,
      dataIndex: 'frequency',
      key: 'frequency',
    },
    {
      title: t.PAYMENT,
      dataIndex: 'payment',
      key: 'payment',
    },
    {
      title: t.PAST_DUE_DOLLARS,
      dataIndex: 'pastDue',
      key: 'pastDue',
    },
    {
      title: t.PAYMENT_PATTERN_START_DATE,
      dataIndex: 'paymentPatternStartDate',
      key: 'paymentPatternStartDate',
    },
    {
      title: t.PAYMENT_PATTERN,
      dataIndex: 'paymentPattern',
      key: 'paymentPattern',
    },
    {
      title: t.MONTHS_REVIEWED,
      dataIndex: 'monthsReviewed',
      key: 'monthsReviewed',
    },
    {
      title: t.PLUS_30,
      dataIndex: 'plus30',
      key: 'plus30',
    },
    {
      title: t.PLUS_60,
      dataIndex: 'plus60',
      key: 'plus60',
    },
    {
      title: t.PLUS_90,
      dataIndex: 'plus90',
      key: 'plus90',
      width: 100,
    },
  ];

  const requestTradesUpdate = useCallback(
    async (trades?: ClientService.CreditReportHistoryTradeDto[], cb?: () => void) => {
      if (!trades?.length) {
        genericMessage.error({}, t.NO_TRADES_SELECTED_ERROR);
        return;
      }

      for (const trade of trades) {
        await API.updateTradeListByCreditReportHistoryId({
          ...trade,
          imported: true,
          fileId: applicationFileId,
        } as ClientService.CreditReportHistoryTradeDto);
      }

      genericMessage.success(t.TRADES_IMPORTED_SUCCESS);
      if (cb) cb();
    },
    [applicationFileId, t.NO_TRADES_SELECTED_ERROR, t.TRADES_IMPORTED_SUCCESS]
  );

  const importAllTrades = useCallback(
    async (e) => {
      setSelectedRowKeys(dataSource?.map((item) => item.id as Key));
      requestTradesUpdate(dataSource, e?.detail?.onSuccess);
    },
    [dataSource, requestTradesUpdate]
  );

  const importSelectedTrades = useCallback(
    async (e) => {
      const selected = dataSource?.filter((item) => item?.id && (selectedRowKeys || [])?.indexOf(item?.id as Key) >= 0);
      requestTradesUpdate(selected, e?.detail?.onSuccess);
    },
    [selectedRowKeys, dataSource, requestTradesUpdate]
  );

  useEffect(() => {
    eventBus.on(IMPORT_ALL_TRADES, importAllTrades);

    return () => {
      eventBus.remove(IMPORT_ALL_TRADES, importAllTrades);
    };
  }, [importAllTrades]);

  useEffect(() => {
    eventBus.on(IMPORT_SELECTED_TRADES, importSelectedTrades);

    return () => {
      eventBus.remove(IMPORT_SELECTED_TRADES, importSelectedTrades);
    };
  }, [importSelectedTrades]);

  return (
    <div className="Panels__trade-container">
      <Table
        columns={columns}
        dataSource={dataSource}
        rowKey="id"
        pagination={false}
        rowSelection={{
          selectedRowKeys,
          onChange: setSelectedRowKeys,
        }}
        rowClassName={(record: ClientService.CreditReportHistoryTradeDto) =>
          record?.imported ? 'Panels__selected-row' : ''
        }
        className="Panels__table"
        scroll={{ x: 'max-content' }}
      />
    </div>
  );
};

export default Trade;
