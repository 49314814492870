import { useCallback, useEffect } from 'react';
import { Modal, Row, Col } from 'antd';

import NoteEditor from '../../pages/ApplicationOverviewPage/NotesContent/NoteEditor/NoteEditor';
import Button from '../../components/Button/Button';

import { NOTE_ADDED_EVENT } from '../../constants/eventBus';
import { eventBus } from '../../utils/eventBus';
import useLocale from '../../hooks/useLocale';

interface IProps {
  onOk: () => void;
  onCancel: () => void;
  applicationFileId?: string;
}

const AddNoteModal = ({ onOk, onCancel, applicationFileId }: IProps) => {
  const { t } = useLocale();

  return (
    <Modal
      destroyOnClose
      centered
      visible
      title={t.ADD_NOTE}
      width={900}
      footer={null}
      onCancel={onCancel}
      className="TaskEditor"
    >
      <NoteEditor applicationFileId={applicationFileId as string} onFinishEditing={onOk} />
      <Row justify="end">
        <Col>
          <Button kind="primary" padding="large" onClick={onCancel}>
            {t.CANCEL}
          </Button>
        </Col>
      </Row>
    </Modal>
  );
};

export default AddNoteModal;
