import { ClientService } from '../../../../shared/api/ClientService';

import ResultsRow, { IResultsRow } from './ResultsRow';
import ScrollableRow, { IScrollableRow, IType } from './ScrollableRow';
import useLocale from '../../../../hooks/useLocale';

import '../FileAnalysis.scss';

function CalculationsTable({ calculations }: { calculations?: ClientService.FileAnalysisResultsCalculationsDto }) {
  const { t } = useLocale();
  const policiesPensionsRRSPDataSource: IResultsRow[] = [
    {
      name: t.CSV_OF_NONEXEMPT_LIFE_INSURANCE_POLICIES,
      secondColCellValue:
        calculations?.csvLifePoliciesEnum === ClientService.ResultCalculationsFieldValueEnum?.SumOfValues
          ? calculations?.csvLifePoliciesSum
          : calculations?.csvLifePolicies,
      type: 'subitem',
    },
    {
      name: t.PENSIONS,
      secondColCellValue:
        calculations?.pensionsEnum === ClientService.ResultCalculationsFieldValueEnum?.SumOfValues
          ? calculations?.pensionsSum
          : calculations?.pensions,
      type: 'subitem',
    },
    {
      name: t.RESPS,
      secondColCellValue:
        calculations?.respEnum === ClientService.ResultCalculationsFieldValueEnum?.SumOfValues
          ? calculations?.respSum
          : calculations?.resPs,
      type: 'subitem',
    },
    {
      name: t.RRSPS,
      secondColCellValue:
        calculations?.rrspEnum === ClientService.ResultCalculationsFieldValueEnum?.SumOfValues
          ? calculations?.rrspSum
          : calculations?.rrsPs,
      type: 'subitem',
    },
    {
      name: t.RDSPS,
      secondColCellValue:
        calculations?.rdspEnum === ClientService.ResultCalculationsFieldValueEnum?.SumOfValues
          ? calculations?.rdspSum
          : calculations?.rdsPs,
      type: 'subitem',
    },
    {
      name: t.TOTAL_POLICIES_PENSIONS_RRSPS,
      secondColCellValue:
        calculations?.policiesPensionsRRSPEnum === ClientService.ResultCalculationsFieldValueEnum?.SumOfValues
          ? calculations?.policiesPensionsRRSPTotal
          : calculations?.policiesPensionsRRSP,
      type: 'total',
      style: { fontWeight: 'bold' },
    },
  ];

  const RESPAssetDetailsDataSource: IScrollableRow[] = [
    {
      rowNames: [
        t.RESPS,
        t.CURRENT_MARKET_VALUE,
        t.GRANTS_TO_BE_REPAID,
        t.VALUE_AFTER_GRANTS,
        t.COST_TO_COLLAPSE_RESP_25,
        t.ESTIMATED_EQUITY,
        t.DEBTORS_INTEREST,
      ],
      rowKey: 'FileAnalysisRESPAssetDetailsDto',
      listValues: calculations?.resPsDetails?.map(
        ({
          respName,
          currentMarketValue,
          grantToBeRepaid,
          valueAfterGrants,
          costToCollapseRESP,
          estimatedEquity,
          debtorsInterest,
          respExemptOrNone,
        }) => ({
          respName,
          currentMarketValue: respExemptOrNone || currentMarketValue,
          grantToBeRepaid,
          valueAfterGrants,
          costToCollapseRESP,
          estimatedEquity,
          debtorsInterest,
        })
      ),
      totalValue: calculations?.respSumTotal,
    },
  ];

  const RRSPAssetDetailsDataSource: IScrollableRow[] = [
    {
      rowNames: [
        t.RRSPS,
        t.CURRENT_MARKET_VALUE,
        t.CONTRIBUTIONS_IN_LAST_12_MONTHS,
        t.WITHHOLDING_TAX_RATE,
        t.WITHHOLDING_TAX,
        t.NET_REALIZABLE_VALUE,
      ],
      rowKey: 'FileAnalysisRRSPAssetDetailsDto',
      listValues: calculations?.rrspDetails?.map(
        ({
          rrspName,
          currentMarketValue,
          contributionLast12Months,
          withholdingTaxRate,
          withholdingTax,
          netRealizableValue,
          rrspExemptOrNone,
        }) => ({
          rrspName,
          currentMarketValue: rrspExemptOrNone || currentMarketValue,
          contributionLast12Months,
          withholdingTaxRate: `${withholdingTaxRate}%`,
          withholdingTax,
          netRealizableValue,
        })
      ),
      totalValue: calculations?.rrspNetRealizableValueTotal,
    },
  ];

  const RDSPAssetDetailsDataSource: IScrollableRow[] = [
    {
      rowNames: [
        t.RDSPS,
        t.CURRENT_MARKET_VALUE,
        t.CONTRIBUTIONS_IN_LAST_12_MONTHS,
        t.WITHHOLDING_TAX_RATE,
        t.WITHHOLDING_TAX,
        t.NET_REALIZABLE_VALUE,
      ],
      rowKey: 'FileAnalysisRDSPAssetDetailsDto',
      listValues: calculations?.rdspDetails?.map(
        ({
          rdspName,
          currentMarketValue,
          contributionLast12Months,
          withholdingTaxRate,
          withholdingTax,
          netRealizableValue,
          rdspExemptOrNone,
        }) => ({
          rdspName,
          currentMarketValue: rdspExemptOrNone || currentMarketValue,
          contributionLast12Months,
          withholdingTaxRate: `${withholdingTaxRate}%`,
          withholdingTax,
          netRealizableValue,
        })
      ),
      totalValue: calculations?.rdspNetRealizableValueTotal,
    },
  ];

  const investmentDetailsDataSource: IScrollableRow[] = [
    {
      rowNames: [
        t.INVESTMENTS,
        t.CURRENT_MARKET_VALUE,
        t.ESTIMATED_TAX_ON_DISPOSITION,
        t.ESTIMATED_EQUITY,
        t.DEBTORS_INTEREST,
      ],
      rowKey: 'FileAnalysisInvestmentAssetDetails',
      listValues: calculations?.investmentDetails?.map(
        ({ investmentName, currentMarketValue, estimatedTaxOnDisposition, estimatedEquity, debtorsInterest }) => ({
          investmentName,
          currentMarketValue,
          estimatedTaxOnDisposition,
          estimatedEquity,
          debtorsInterest,
        })
      ),
      totalValue: calculations?.investmentsTotal,
    },
  ];

  const realEstateDetailsDataSource = (
    realEstateDetail: ClientService.FileAnalysisRealEstateAssetDetailsDto
  ): IResultsRow[] => [
    {
      name: `${realEstateDetail?.address || '-'} ${realEstateDetail?.unit ? `Unit ${realEstateDetail?.unit}` : ''}`,
      type: 'subtitle',
      style: { paddingTop: 24 },
    },
    {
      name: t.ESTIMATED_MARKET_VALUE_BASED_ON_OPINIONS_OF_VALUE,
      secondColCellValue: realEstateDetail?.estimatedMarketValue || '-',
      type: 'subitem',
    },
    {
      name: t.ESTIMATED_REAL_ESTATE_COMMISSION,
      secondColCellValue: (realEstateDetail?.estimatedRealEstateCommission || 0) * -1,
      type: 'subitem',
    },
    {
      name: t.ESTIMATED_LEGAL_COSTS,
      secondColCellValue: (realEstateDetail?.estimatedLegalCosts || 0) * -1,
      type: 'subitem',
    },
    {
      name: t.ESTIMATED_AVAILABLE_BEFORE_SECURED_CLAIMS,
      secondColCellValue: realEstateDetail?.estimatedAvailableBeforeSecuredClaims || '-',
      type: 'subtotal',
      style: { borderTop: '1px solid black' },
    },
    {
      name: t.ESTIMATED_SECURED_CLAIMS,
      type: 'subitem',
      style: { paddingTop: 24, fontWeight: 'bold' },
    },
    ...(realEstateDetail?.mortgageValues || [])?.map((subitem) => ({
      name: t.MORTGAGE,
      secondColCellValue: subitem?.mortgageValue || '-',
      type: 'subitem' as IType,
      subitemLevel: 3,
    })),
    ...(realEstateDetail?.propertyTaxesArrearsValues || [])?.map((subitem) => ({
      name: t.PROPERTY_TAX_ARREARS,
      secondColCellValue: subitem?.propertyTaxArrearsValues || '-',
      type: 'subitem' as IType,
      subitemLevel: 3,
    })),
    {
      name: t.TOTAL_ESTIMATED_SECURED_CLAIMS,
      secondColCellValue: realEstateDetail?.totalEstimatedSecuredClaims || '-',
      type: 'subtotal',
      style: { borderTop: '1px solid black' },
    },
    {
      name: t.ESTIMATED_EQUITY,
      secondColCellValue: realEstateDetail?.estimatedEquity || '-',
      type: 'subitem',
      style: { paddingTop: 24 },
    },
    {
      name: t.PROVINCIAL_EXEMPTION_HOUSEHOLD_IF_ALLOWED,
      secondColCellValue: realEstateDetail?.provincialExemptionHousehold || '-',
      type: 'subitem',
      hidden: !realEstateDetail?.isPrincipalResidence,
    },
    {
      name: t.ESTIMATED_EQUITY_ABOVE_ALLOWED_EXEMPTION_CLAIMS_IF_ALLOWED,
      secondColCellValue: realEstateDetail?.estimatedEquityAboveAllowedExemptionClaims || '-',
      type: 'subtotal',
      style: { borderTop: '1px solid black' },
      hidden: !realEstateDetail?.isPrincipalResidence,
    },
    {
      name: t.DEBTORS_OWNERSHIP_INTEREST,
      secondColCellValue: `${realEstateDetail?.debtorsOwnershipInterest || 0}%`,
      style: { paddingTop: 24 },
      type: 'subitem',
    },
    {
      name: t.DEBTORS_ESTIMATED_EQUITY_BEFORE_EXEMPTION,
      secondColCellValue: realEstateDetail?.debtorsEstimatedEquityBeforeExemption || '-',
      type: 'subtotal',
      style: { borderTop: '1px solid black' },
      hidden: !realEstateDetail?.isPrincipalResidence,
    },
    {
      name: t.PROVINCIAL_EXEMPTION_INIDIVIDUAL_IF_ALLOWED,
      secondColCellValue: realEstateDetail?.provincialExemptionIndividual || '-',
      type: 'subitem',
      style: { paddingTop: 24 },
      hidden: !realEstateDetail?.isPrincipalResidence,
    },
    {
      name: t.DEBTORS_ESTIMATED_EQUITY,
      secondColCellValue: realEstateDetail?.debtorsEstimatedEquity || '-',
      type: 'subtotal',
      style: { borderTop: '1px solid black', fontWeight: 'bold' },
    },
  ];

  const motorVehicleDetailsDataSource: IScrollableRow[] = [
    {
      rowNames: [
        t.MODEL,
        t.ESTIMATED_VALUE,
        t.OUTSTANDING_LOAN_LEASE,
        t.VALUE_AFTER_DEBTS,
        t.PERSONAL_EXEMPTION,
        t.ESTIMATED_EQUITY,
      ],
      rowKey: 'FileAnalysisMotorVehiclesAssetDetailsDto',
      listValues: calculations?.motorVehicleDetails?.map(
        ({
          model,
          make,
          estimatedValue,
          outstandingLoanLease,
          valueAfterDebts,
          personalExemption,
          estimatedEquity,
        }) => ({
          model: make && model ? `${make} ${model}` : make || model || '-',
          estimatedValue,
          outstandingLoanLease,
          valueAfterDebts,
          personalExemption,
          estimatedEquity,
        })
      ),
      totalValue: calculations?.motorVehiclesTotal,
    },
  ];

  const recreationalEquipmentDetailsDataSource: IScrollableRow[] = [
    {
      rowNames: [t.DESCRIPTION, t.MARKET_VALUE, t.OUTSTANDING_LOAN_LEASE, t.ESTIMATED_EQUITY],
      rowKey: 'FileAnalysisRecreationalEquipmentAssetDetailsDto',
      listValues: calculations?.recreationalEquipmentDetails?.map(
        ({ description, marketValue, outstandingLoanLease, estimatedEquity }) => ({
          description,
          marketValue,
          outstandingLoanLease,
          estimatedEquity,
        })
      ),
      totalValue: calculations?.recreationalEquipmentTotal,
    },
  ];

  const otherDetailsDataSource: IResultsRow[] = [
    {
      name: t.TOOLS_OF_THE_TRADE,
      type: 'subtitle',
    },
    {
      name: t.ESTIMATED_VALUE,
      secondColCellValue: calculations?.toolsOfTradeDetails?.estimatedValue || '-',
      type: 'subitem',
    },
    {
      name: t.PERSONAL_EXEMPTION,
      secondColCellValue: (calculations?.toolsOfTradeDetails?.personalExemption || 0) * -1 || '-',
      type: 'subitem',
    },
    {
      name: t.TOOLS_OF_THE_TRADE_REALIZATION,
      secondColCellValue: calculations?.toolsOfTradeDetails?.toolsOfTradeRealization || '-',
      type: 'total',
      style: { fontWeight: 'bold' },
    },
    {
      name: t.HUNTING_FISHING_FARMING,
      type: 'subtitle',
      style: { paddingTop: 24 },
    },
    {
      name: t.ESTIMATED_VALUE,
      secondColCellValue: calculations?.huntingFishingFarmingDetails?.estimatedValue || '-',
      type: 'subitem',
    },
    {
      name: t.PERSONAL_EXEMPTION,
      secondColCellValue: (calculations?.huntingFishingFarmingDetails?.personalExemption || 0) * -1 || '-',
      type: 'subitem',
    },
    {
      name: t.HUNTING_FISHING_FARMING_REALIZATION,
      secondColCellValue: calculations?.huntingFishingFarmingDetails?.huntingFishingFarmingRealization || '-',
      type: 'total',
      style: { fontWeight: 'bold' },
    },
    {
      name: t.TAX_REFUND,
      secondColCellValue: calculations?.taxRefundsAssetValue || '-',
      style: { fontWeight: 'bold' },
    },
    {
      name: t.OTHER_TOTAL_REALIZATIONS,
      secondColCellValue: calculations?.otherTotalRealization || '-',
      style: { fontWeight: 'bold' },
    },
  ];

  return (
    <div className="FileAnalysis__results--calculations-container">
      <ResultsRow secondColCellValue="Estimated recovery" type="title" />

      <>
        <ResultsRow name={t.CASH_ON_HAND?.toUpperCase()} type="title" />
        <div className="FileAnalysis__results--calculations-subcontainer">
          <ResultsRow
            name="Balance"
            secondColCellValue={calculations?.cashOnHandBalance || '-'}
            style={{ fontWeight: 'bold' }}
          />
        </div>
      </>

      <>
        <ResultsRow name={t.POLICIES_PENSIONS_RRSPS?.toUpperCase()} type="title" />
        <div className="FileAnalysis__results--calculations-subcontainer">
          {policiesPensionsRRSPDataSource.map((subitem) => (
            <ResultsRow {...subitem} key={subitem.name} />
          ))}
        </div>
        {Boolean(calculations?.resPsDetails?.length) && (
          <div className="FileAnalysis__results--calculations-subcontainer">
            {RESPAssetDetailsDataSource.map((subitem) => (
              <ScrollableRow {...subitem} key={subitem.rowKey} />
            ))}
          </div>
        )}
        {Boolean(calculations?.rrspDetails?.length) && (
          <div className="FileAnalysis__results--calculations-subcontainer">
            {RRSPAssetDetailsDataSource.map((subitem) => (
              <ScrollableRow {...subitem} key={subitem.rowKey} />
            ))}
          </div>
        )}
        {Boolean(calculations?.rdspDetails?.length) && (
          <div className="FileAnalysis__results--calculations-subcontainer">
            {RDSPAssetDetailsDataSource.map((subitem) => (
              <ScrollableRow {...subitem} key={subitem.rowKey} />
            ))}
          </div>
        )}
      </>

      {Boolean(calculations?.investmentDetails?.length) && (
        <>
          <ResultsRow name={t.SECURITIES?.toUpperCase()} type="title" />
          <div className="FileAnalysis__results--calculations-subcontainer">
            {investmentDetailsDataSource.map((subitem) => (
              <ScrollableRow {...subitem} key={subitem.rowKey} />
            ))}
          </div>
        </>
      )}

      {Boolean(calculations?.realEstateDetails?.length) && (
        <>
          <ResultsRow
            name={t.REAL_PROPERTY_OR_IMMOVABLE?.toUpperCase()}
            secondColCellValue={t.ESTIMATED_RECOVERY}
            type="title"
          />
          {calculations?.realEstateDetails?.map((subitem, index) => (
            <div className="FileAnalysis__results--calculations-subcontainer" key={`realEstateDetails-${index}`}>
              {realEstateDetailsDataSource(subitem)?.map((source) => (
                <ResultsRow {...source} key={`address-${source.name}`} subitemLevel={source?.subitemLevel || 2} />
              ))}
            </div>
          ))}
        </>
      )}

      {Boolean(calculations?.motorVehicleDetails?.length) && (
        <>
          <ResultsRow name={t.MOTOR_VEHICLES?.toUpperCase()} type="title" />

          <div className="FileAnalysis__results--calculations-subcontainer">
            {motorVehicleDetailsDataSource.map((subitem) => (
              <ScrollableRow {...subitem} key={subitem.rowKey} />
            ))}
          </div>
        </>
      )}
      {Boolean(calculations?.recreationalEquipmentDetails?.length) && (
        <>
          <ResultsRow name={t.RECREATIONAL_EQUIPMENTS?.toUpperCase()} type="title" />
          <div className="FileAnalysis__results--calculations-subcontainer">
            {recreationalEquipmentDetailsDataSource.map((subitem) => (
              <ScrollableRow {...subitem} key={subitem.rowKey} />
            ))}
          </div>
        </>
      )}

      {Boolean(calculations?.taxRefundsAssetValue || calculations?.otherTotalRealization) && (
        <>
          <ResultsRow name={t.OTHER?.toUpperCase()} type="title" />
          <div className="FileAnalysis__results--calculations-subcontainer">
            {otherDetailsDataSource.map((subitem, index) => (
              <ResultsRow {...subitem} key={`other-${index}`} />
            ))}
          </div>
        </>
      )}
    </div>
  );
}

export default CalculationsTable;
