import { useCallback, useEffect, useState, useMemo } from 'react';
import { Input, Row, Col } from 'antd';

import Layout from '../../../components/Layout/Layout';
import Breadcrumb from '../../../components/Breadcrumb/Breadcrumb';
import PageSubtitle from '../../../components/PageSubtitle/PageSubtitle';
import StatusValue from '../components/StatusValue';
import StyledTable from '../components/StyledTable/StyledTable';
import EditIcon from '../components/EditIcon';

import useLocale from '../../../hooks/useLocale';
import useModal from '../../../hooks/useModal';
import useDebounce from '../../../hooks/useDebounce';

import { getSortableFilterableColumns } from '../utils';
import { MAX_PAGE_SIZE } from '../../../constants/common';

import { ClientService } from '../../../shared/api/ClientService';
import API from '../../../utils/api';

import './OfficeLocations.scss';

import OfficeLocationEditor from './OfficeLocationEditor';
import { IOfficeLocationsSearchCriteria } from './types';
import { argumentifyOfficeLocationsSearchCriteria, composeAddress } from './utils';

interface IDataItem extends ClientService.OfficeLocationDto {
  key: string;
}

function OfficeLocations(): JSX.Element {
  const { t, locale } = useLocale();
  const { Search } = Input;
  const { showModal, closeModal } = useModal();

  const [searchTerm, setSearchTerm] = useState<string>('');

  const value = useDebounce(searchTerm);

  const [dataSource, setDataSource] = useState<IDataItem[]>();
  const [totalCount, setTotalCount] = useState<number>();
  const [loading, setLoading] = useState<boolean>(false);

  const [criteria, setCriteria] = useState<IOfficeLocationsSearchCriteria>({
    maxResultCount: MAX_PAGE_SIZE,
    includeInactive: true,
  });

  const requestOfficeLocations = useCallback(async () => {
    setLoading(true);
    const response = await API.officeLocationsGET(...argumentifyOfficeLocationsSearchCriteria(criteria)).catch(() =>
      setLoading(false)
    );
    setLoading(false);

    if (response) {
      setDataSource(
        response?.items?.map(
          (item) =>
            ({
              ...item,
              key: `${locale}-${item?.id}`,
            } as IDataItem)
        )
      );
      setTotalCount(response?.totalCount || 0);
    }
  }, [criteria, locale]);

  const handleShowEditor = useCallback(
    (selectedOfficeLocationId?: string) => {
      showModal(
        <OfficeLocationEditor
          id={selectedOfficeLocationId}
          onOk={() => {
            closeModal();
            requestOfficeLocations();
          }}
          onCancel={closeModal}
        />
      );
    },
    [closeModal, requestOfficeLocations, showModal]
  );

  useEffect(() => {
    requestOfficeLocations();
  }, [criteria, requestOfficeLocations]);

  useEffect(() => {
    setCriteria((prev) => ({ ...prev, filterText: value }));
  }, [value]);

  const sortableColumns = useMemo(() => {
    const columns = [
      {
        title: t.OFFICE_DISPLAYNAME,
        dataIndex: 'name',
        key: 'name',
        sorter: true,
        filters: (dataSource?.filter((x) => x.name) || [])?.map((item) => ({
          text: item.name as string,
          value: item.name as string,
        })),
        onFilter: (value: any, record: ClientService.OfficeLocationDto) =>
          (record?.name && record?.name?.indexOf(value) >= 0) || false,
      },

      {
        title: t.OFFICE_LOCATION,
        dataIndex: 'streetNumber',
        key: 'streetNumber',
        sorter: (a: ClientService.OfficeLocationDto, b: ClientService.OfficeLocationDto) =>
          composeAddress(a).localeCompare(composeAddress(b)),
        render: (_: any, record: ClientService.OfficeLocationDto) => <>{composeAddress(record)}</>,
        width: '40%',
      },
      {
        title: t.OFFICE_TIMEZONE,
        dataIndex: 'timeZoneCode',
        key: 'timeZoneCode',
        sorter: true,
      },
      {
        title: t.OFFICE_PREFIX,
        dataIndex: 'locationPrefix',
        key: 'locationPrefix',
        sorter: true,
      },
      {
        title: t.STATUS,
        dataIndex: 'isActive',
        key: 'isActive',
        sorter: true,
        render: (isActive?: boolean) => <StatusValue isActive={isActive} />,

        filters: [
          {
            text: t.ACTIVE,
            value: true,
          },
          {
            text: t.INACTIVE,
            value: false,
          },
        ],
        onFilter: (value: any, record: ClientService.OfficeLocationDto) => value === record.isActive,
      },
      {
        dataIndex: 'action',
        key: 'action',
        render: (_: any, record: ClientService.OfficeLocationDto) => (
          <EditIcon onClick={() => handleShowEditor(record?.id)} />
        ),
      },
    ];

    return getSortableFilterableColumns(columns, dataSource);
  }, [
    dataSource,
    handleShowEditor,
    t.ACTIVE,
    t.INACTIVE,
    t.STATUS,
    t.NAME,
    t.OFFICE_DISPLAYNAME,
    t.OFFICE_LOCATION,
    t.OFFICE_NAME,
  ]);

  return (
    <Layout className="OfficeLocations">
      <Breadcrumb data={[{ title: t.ADMINISTRATION }, { title: t.OFFICE_LOCATIONS }]} />
      <PageSubtitle title={t.OFFICE_LOCATIONS} />
      <Row align="bottom" justify="space-between">
        <Col span={8}>
          <Search
            placeholder={t.SEARCH_BY_OFFICE_LOCATION}
            allowClear
            size="large"
            onSearch={setSearchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </Col>
      </Row>
      <StyledTable
        rowKey="id"
        dataSource={dataSource?.map((item) => ({ ...item, key: `${locale}-${item?.id}` } as IDataItem))}
        columns={sortableColumns}
        total={totalCount}
        loading={loading}
        key={`table-${locale}`}
      />
    </Layout>
  );
}

export default OfficeLocations;
