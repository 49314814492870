import { useMemo } from 'react';
import { Input, Form, Row, Col, Typography } from 'antd';

import Divider from '../../Divider/Divider';
import PrefixesWithLoad from '../PrefixesWithLoad/PrefixesWithLoad';
import FoldersWithLoad from '../FoldersWithLoad/FoldersWithLoad';

import { FileProps, FileActionEnum, FilesColumnsProps } from '../types';
import { getColumnWidth, getNameColumnWidth } from '../utils';

import useLocale from '../../../hooks/useLocale';

import '../AttachmentsTab.scss';

interface IProps {
  mode?: FileActionEnum;
  columnsProps?: FilesColumnsProps;
}

const BulkRow = ({ mode, columnsProps }: IProps) => {
  const { t } = useLocale();
  const { Text } = Typography;

  const columnWidth = useMemo(() => getColumnWidth(columnsProps), [columnsProps]);

  return (
    <Form.Item noStyle shouldUpdate>
      {({ getFieldValue, setFieldsValue }) => {
        const newFiles = getFieldValue('newFiles');

        return (
          <Col span={newFiles?.length ? 24 : 0}>
            <Row gutter={20} align="middle" style={{ paddingTop: mode === FileActionEnum.Edit ? 0 : 24 }}>
              <Col span={getNameColumnWidth(columnsProps)} className="AttachmentsTab__file-name">
                <Text ellipsis>Bulk update</Text>
              </Col>
              <Col span={columnWidth}>
                <Form.Item label={t.DOCUMENTS_ADDITIONAL_INFORMATION} name="bulkAdditionalInfo">
                  <Input
                    size="large"
                    onChange={(e) => {
                      setFieldsValue({
                        newFiles: newFiles?.map((item: FileProps) => ({
                          ...item,
                          additionalInformation: e?.target?.value,
                        })),
                      });
                    }}
                  />
                </Form.Item>
              </Col>

              <Col span={columnWidth}>
                <Form.Item label={t.DOCUMENTS_FOLDER} name="bulkFolderId">
                  <FoldersWithLoad
                    onSelect={(documentFolderId: string) => {
                      setFieldsValue({
                        newFiles: newFiles?.map((item: FileProps) => ({
                          ...item,
                          documentFolderId,
                          documentPrefixId: undefined,
                          bulkPrefixId: undefined,
                        })),
                      });
                    }}
                  />
                </Form.Item>
              </Col>
              <Col span={columnWidth}>
                <Form.Item noStyle shouldUpdate>
                  {({ getFieldValue, setFieldsValue }) => {
                    const bulkFolderId = getFieldValue('bulkFolderId');
                    return (
                      <Form.Item label={t.DOCUMENTS_PREFIX} name="bulkPrefixId">
                        <PrefixesWithLoad
                          documentFolderId={bulkFolderId}
                          onSelect={(documentPrefixId: string) => {
                            setFieldsValue({
                              newFiles: newFiles?.map((item: FileProps) => ({ ...item, documentPrefixId })),
                            });
                          }}
                        />
                      </Form.Item>
                    );
                  }}
                </Form.Item>
              </Col>
              <Col span={1}></Col>
            </Row>
            <Divider style={{ margin: 0 }} />
          </Col>
        );
      }}
    </Form.Item>
  );
};

export default BulkRow;
