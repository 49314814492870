import { Input, Col, Row, Form, Checkbox } from 'antd';
import InputMask from 'react-input-mask';
import { useQuery } from 'react-query';
import { NamePath } from 'rc-field-form/es/interface';

import useLocale from '../../../../hooks/useLocale';
import SelectLookupDto from './SelectLookupDto';

import { ClientService } from '../../../../shared/api/ClientService';
import { USE_QUERY_OPTIONS, PHONE_TYPES_QUERY } from '../../../../constants/reactQuery';
import { EMPTY_PHONE_MASK, PHONE_MASK } from '../constants';
import { validatePhoneNumberComplete } from '../../utils';
import API from '../../../../utils/api';

import '../DebtorProfileContent.scss';

interface IProp {
  phoneTypeFormName: NamePath;
  phoneNumberFormName: NamePath;
  phoneDoNotContactFormName: NamePath;
  phoneDoNotSMSFormName: NamePath;
  required?: boolean;
  label?: string;
  isLabelVisible?: boolean;
  faxExcluded?: boolean;
  disabled?: boolean;
}

const PhoneFormItem = ({
  phoneTypeFormName,
  phoneNumberFormName,
  phoneDoNotContactFormName,
  phoneDoNotSMSFormName,
  required = false,
  label,
  isLabelVisible = true,
  faxExcluded = false,
  disabled = false,
}: IProp) => {
  const { t } = useLocale();
  const { data: phoneTypes } = useQuery([PHONE_TYPES_QUERY], () => API.listPhoneTypes(), USE_QUERY_OPTIONS);

  return (
    <Form.Item noStyle shouldUpdate>
      {({ getFieldsValue }) => {
        const values = getFieldsValue() as ClientService.DebtorProfileDto;
        const alternatePhoneNumbers = values?.alternatePhoneNumbers as ClientService.ClientPhoneDto[];

        const phoneTypesArray = [
          ...(alternatePhoneNumbers?.map((item) => item?.phoneTypeId) || []),
          values?.mainPhoneTypeId,
        ];
        const phoneNumbersArray = [
          ...(alternatePhoneNumbers?.map((item) => item?.phoneNumber) || []),
          values?.mainPhoneNumber,
        ];

        return (
          <Row gutter={20} align="middle">
            <Col span={9}>
              <Form.Item
                name={phoneTypeFormName}
                label={label}
                rules={[{ required, message: t.REQUIRED_FIELD }]}
                className={!isLabelVisible ? 'DebtorProfileContent__form-item--label-hidden' : ''}
              >
                <SelectLookupDto
                  data={
                    faxExcluded
                      ? phoneTypes?.filter((item) => item.enumValue !== ClientService.PhoneTypeEnum.Fax)
                      : phoneTypes
                  }
                  disabledArray={phoneTypes?.filter((item) => phoneTypesArray?.indexOf(item?.id) >= 0)}
                  disabled={disabled}
                />
              </Form.Item>
            </Col>
            <Col span={15}>
              <Form.Item
                name={phoneNumberFormName}
                label={
                  <Row align="middle" justify="end" className="DebtorProfileContent__form-item--label">
                    <Col>
                      <Form.Item name={phoneDoNotSMSFormName} valuePropName="checked" noStyle>
                        <Checkbox className="DebtorProfileContent__checkbox" disabled={disabled}>
                          {t.DONOTSMS}
                        </Checkbox>
                      </Form.Item>
                    </Col>
                    <Col>
                      <Form.Item name={phoneDoNotContactFormName} valuePropName="checked" noStyle>
                        <Checkbox className="DebtorProfileContent__checkbox" disabled={disabled}>
                          {t.DNC}
                        </Checkbox>
                      </Form.Item>
                    </Col>
                  </Row>
                }
                requiredMark={required ? 'optional' : false}
                rules={[
                  { required, message: t.REQUIRED_FIELD },
                  {
                    validator: async (_, value) => {
                      if (!value || value === EMPTY_PHONE_MASK) {
                        return Promise.reject(t.PHONE_NUMBER_REQUIRED_ERROR);
                      }

                      if (!validatePhoneNumberComplete(value)) {
                        return Promise.reject(t.PHONE_NUMBER_INCORRECT_ERROR);
                      }

                      if (phoneNumbersArray?.filter((item) => item === value)?.length > 1) {
                        return Promise.reject(t.PHONE_IS_USED_ERROR);
                      }
                      return Promise.resolve();
                    },
                  },
                ]}
                className="DebtorProfileContent__form-item--extra-label"
                validateTrigger="onChange"
              >
                <InputMask mask={PHONE_MASK} disabled={disabled}>
                  <Input size="large" />
                </InputMask>
              </Form.Item>
            </Col>
          </Row>
        );
      }}
    </Form.Item>
  );
};

export default PhoneFormItem;
