import { createContext, useState } from 'react';
import { WebViewerInstance } from '@pdftron/webviewer';

interface IPDFTronContext {
  instance?: WebViewerInstance;
  setInstance: (instance?: WebViewerInstance) => void;
  converter?: WebViewerInstance;
  setConverter: (instance?: WebViewerInstance) => void;
}

interface IProps {
  children: JSX.Element;
}

const PDFTronContext = createContext<IPDFTronContext>({
  instance: undefined,
  setInstance: () => {},
  converter: undefined,
  setConverter: () => {},
});

const PDFTronProvider = ({ children }: IProps) => {
  const [instance, setInstance] = useState<WebViewerInstance>();
  const [converter, setConverter] = useState<WebViewerInstance>();

  return (
    <PDFTronContext.Provider value={{ instance, setInstance, converter, setConverter }}>
      {children}
    </PDFTronContext.Provider>
  );
};

export { PDFTronContext, PDFTronProvider };
