import { Checkbox, CheckboxProps } from 'antd';

import './CheckboxButtonStyle.scss';

const CheckboxButtonStyle = (props: CheckboxProps): JSX.Element => (
  <div className={props.checked ? 'CheckboxButtonStyle__checked' : 'CheckboxButtonStyle'}>
    <Checkbox {...props} />
  </div>
);

export default CheckboxButtonStyle;
