const EmailIcon = (): JSX.Element => (
  <svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M22 12V4.5H12H2V12V19.5H12"
      stroke="#858e96"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M22 17H15" stroke="#858e96" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    <path
      d="M19.5 14.5L22 17L19.5 19.5"
      stroke="#858e96"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M2 4.5L12 12L22 4.5" stroke="#858e96" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);

export default EmailIcon;
