import { ClientService } from '../../../../shared/api/ClientService';
import useLocale from '../../../../hooks/useLocale';

import ResultsRow, { IResultsRow } from './ResultsRow';
import '../FileAnalysis.scss';

function FeesTable({
  calculations,
  taxTypeLabel,
}: {
  calculations?: ClientService.FileAnalysisFeeProposalBankruptcyDto;
  taxTypeLabel?: string;
}) {
  const { t } = useLocale();

  const fileAnalysisFeeInAConsumerProposalDataSource: IResultsRow[] = [
    {
      name: t.FEES_IN_CONSUMER_PROPOSAL,
      secondColCellValue: t.ESTIMATED_FEES,
      type: 'title',
    },
    {
      name: t.COUNSELLING_FEES,
      secondColCellValue: calculations?.fileAnalysisFeeInAConsumerProposal?.counsellingFee,
      type: 'item',
      style: { paddingTop: 20 },
    },
    {
      name: t.ON_FILLING_OF_PROPOSAL,
      secondColCellValue: calculations?.fileAnalysisFeeInAConsumerProposal?.onFilingOfProposalFee,
      type: 'item',
    },
    {
      name: t.ON_APPROVAL_DEEMED_APPROVAL,
      secondColCellValue: calculations?.fileAnalysisFeeInAConsumerProposal?.onApprovalDeemedApprovalFee,
      type: 'item',
    },
    { name: t.UPON_DISTRIBUTION, type: 'subtitle' },
    {
      name: t.RECEIPTS,
      firstColCellValue: calculations?.fileAnalysisFeeInAConsumerProposal?.distributionReceipts,
      type: 'subitem',
    },
    {
      name: t.FILING_FEES,
      firstColCellValue: (calculations?.fileAnalysisFeeInAConsumerProposal?.distributionFilingFee || 0) * -1,
      type: 'subitem',
    },
    {
      name: t.COUNSELLING_FEES,
      firstColCellValue: (calculations?.fileAnalysisFeeInAConsumerProposal?.distributionCounsellingFee || 0) * -1,
      type: 'subitem',
    },
    {
      name: t.ON_FILING,
      firstColCellValue: (calculations?.fileAnalysisFeeInAConsumerProposal?.distributionOnFiling || 0) * -1,
      type: 'subitem',
    },
    {
      name: t.ON_APPROVAL_DEEMED_APPROVAL,
      firstColCellValue:
        (calculations?.fileAnalysisFeeInAConsumerProposal?.distributionOnApprovalDeemedApproval || 0) * -1,
      type: 'subitem',
    },
    {
      name: `${taxTypeLabel || t.TAX} ${t.ABOVE_FEES}`,
      firstColCellValue: (calculations?.fileAnalysisFeeInAConsumerProposal?.gstOnFee || 0) * -1,
      type: 'subitem',
    },
    {
      firstColCellValue: calculations?.fileAnalysisFeeInAConsumerProposal?.distributionTotal,
      type: 'subtotal',
      style: { borderTop: '1px solid black' },
    },
    {
      name: t.PERCENT20_ABOVE_AMOUNT,
      secondColCellValue: calculations?.fileAnalysisFeeInAConsumerProposal?.twentyPercentAmount,
      type: 'subtotal',
    },
    {
      name: `${t.TRUSTEES_FEES_BEFORE_TAX} ${taxTypeLabel || t.TAX?.toLowerCase()})`,
      secondColCellValue: calculations?.fileAnalysisFeeInAConsumerProposal?.trusteeFee,
      type: 'total',
      style: { textIndent: 20, borderTop: 0, borderBottom: '1px solid black' },
    },
  ];

  const fileAnalysisFeeInASummaryBankruptcyDataSource: IResultsRow[] = [
    {
      name: t.FEES_IN_SUMMARY_BANKRUPTCY,
      firstColCellValue: t.DEBTOR,
      secondColCellValue: t.SPOUSE,
      type: 'title',
      style: { paddingBottom: 20 },
    },
    {
      name: t.ESTIMATED_RECEIPTS,
      firstColCellValue: calculations?.fileAnalysisFeeInASummaryBankruptcy?.estimatedReceiptsDebtor,
      secondColCellValue: calculations?.fileAnalysisFeeInASummaryBankruptcy?.estimatedReceiptsSpouse,
      type: 'item',
      style: { borderTop: '1px solid black' },
    },
    {
      name: t.COUNSELLING_FEES,
      firstColCellValue: calculations?.fileAnalysisFeeInASummaryBankruptcy?.counsellingFeeDebtor,
      type: 'item',
      style: { paddingTop: 24 },
    },
    {
      name: t.TRUSTEES_FEES,
      type: 'subtitle',
    },
    {
      name: t.ON_FIRST_975,
      firstColCellValue: calculations?.fileAnalysisFeeInASummaryBankruptcy?.trusteeFeeFirst975Debtor,
      type: 'subitem',
    },
    {
      name: t.ON_NEXT_UP_TO_2000,
      firstColCellValue: calculations?.fileAnalysisFeeInASummaryBankruptcy?.trusteeFeeNext2000Debtor,
      type: 'subitem',
    },
    {
      name: t.ON_BALANCE,
      firstColCellValue: calculations?.fileAnalysisFeeInASummaryBankruptcy?.trusteeFeeBalanceDebtor,
      type: 'subitem',
    },
    {
      name: `${t.TRUSTEES_FEES_BEFORE_TAX} ${taxTypeLabel || t.TAX?.toLowerCase()})`,
      firstColCellValue: calculations?.fileAnalysisFeeInASummaryBankruptcy?.trusteeFeeBeforeGSTDebtor,
      type: 'total',
      style: { textIndent: 20 },
    },
    {
      name: t.FILING_FEES,
      firstColCellValue: calculations?.fileAnalysisFeeInASummaryBankruptcy?.filingFeeDebtor,
      secondColCellValue: calculations?.fileAnalysisFeeInASummaryBankruptcy?.filingFeeSpouse,
      type: 'total',
      style: { textIndent: 20, borderTop: 0, borderBottom: '1px solid black' },
    },
  ];

  const fileAnalysisFeeInAOrdinaryBankruptcyDataSource: IResultsRow[] = [
    {
      name: t.FEES_ORDINARY_BANKRUPTCY,
      type: 'title',
    },
    {
      name: t.COUNSELLING_FEES,
      firstColCellValue: calculations?.fileAnalysisFeeInAOrdinaryBankruptcy?.counsellingFee,
      type: 'subitem',
    },
    {
      name: t.TRUSTEES_FEES,
      firstColCellValue: calculations?.fileAnalysisFeeInAOrdinaryBankruptcy?.trusteeFee,
      type: 'subitem',
    },
    {
      name: `${t.TRUSTEES_FEES_BEFORE_TAX} ${taxTypeLabel || t.TAX?.toLowerCase()})`,
      firstColCellValue: calculations?.fileAnalysisFeeInAOrdinaryBankruptcy?.trusteeFeeBeforeGST,
      type: 'total',
      style: { textIndent: 20 },
    },
    {
      name: t.FILING_FEES,
      firstColCellValue: calculations?.fileAnalysisFeeInAOrdinaryBankruptcy?.filingFee,
      type: 'total',
      style: { textIndent: 20, borderTop: 0, borderBottom: '1px solid black' },
    },
  ];

  const fileAnalysisFeeInADivisionProposalDataSource: IResultsRow[] = [
    {
      name: t.FEES_DIVISION_I_PROPOSAL,
      secondColCellValue: t.ESTIMATED_FEES,
      type: 'title',
    },
    {
      name: t.COUNSELLING_FEES,
      secondColCellValue: calculations?.fileAnalysisFeeInADivisionProposal?.counsellingFee,
      type: 'item',
    },
    {
      name: t.ON_FILING_PROPOSAL,
      secondColCellValue: calculations?.fileAnalysisFeeInADivisionProposal?.onFilingOfProposalFee,
      type: 'item',
    },
    {
      name: t.ON_APPROVAL,
      secondColCellValue: calculations?.fileAnalysisFeeInADivisionProposal?.onApprovalDeemedApprovalFee,
      type: 'item',
    },
    { name: t.UPON_DISTRIBUTION, type: 'subtitle' },
    {
      name: t.RECEIPTS,
      firstColCellValue: calculations?.fileAnalysisFeeInADivisionProposal?.distributionReceipts,
      type: 'subitem',
    },
    {
      name: t.FILING_FEES,
      firstColCellValue: (calculations?.fileAnalysisFeeInADivisionProposal?.distributionFilingFee || 0) * -1,
      type: 'subitem',
    },
    {
      name: t.COUNSELLING_FEES,
      firstColCellValue: (calculations?.fileAnalysisFeeInADivisionProposal?.distributionCounsellingFee || 0) * -1,
      type: 'subitem',
    },
    {
      name: t.ON_FILING,
      firstColCellValue: (calculations?.fileAnalysisFeeInADivisionProposal?.distributionOnFiling || 0) * -1,
      type: 'subitem',
    },
    {
      name: t.ON_APPROVAL_DEEMED_APPROVAL,
      firstColCellValue:
        (calculations?.fileAnalysisFeeInADivisionProposal?.distributionOnApprovalDeemedApproval || 0) * -1,
      type: 'subitem',
    },
    {
      name: `${taxTypeLabel || t.TAX} ${t.ON_ABOVE_FEES}`,
      firstColCellValue: (calculations?.fileAnalysisFeeInADivisionProposal?.gstOnFee || 0) * -1,
      type: 'subitem',
    },
    {
      firstColCellValue: calculations?.fileAnalysisFeeInADivisionProposal?.distributionTotal,
      type: 'subtotal',
      style: { borderTop: '1px solid black' },
    },
    {
      name: t.PERCENT15_ABOVE_AMOUNT,
      secondColCellValue: calculations?.fileAnalysisFeeInADivisionProposal?.amount15Percent,
      type: 'subtotal',
    },
    {
      name: `${t.TRUSTEES_FEES_BEFORE_TAX} ${taxTypeLabel || t.TAX?.toLowerCase()})`,
      secondColCellValue: calculations?.fileAnalysisFeeInADivisionProposal?.trusteeFeeBeforeGST,
      type: 'total',
      style: { textIndent: 20, borderTop: 0, borderBottom: '1px solid black' },
    },
  ];

  return (
    <div className="FileAnalysis__results--fees-container">
      <div className="FileAnalysis__results--fees-subcontainer">
        {fileAnalysisFeeInAConsumerProposalDataSource.map((item) => (
          <ResultsRow {...item} key={item.name} precision={2} />
        ))}
      </div>
      <div className="FileAnalysis__results--fees-subcontainer">
        {fileAnalysisFeeInASummaryBankruptcyDataSource.map((item) => (
          <ResultsRow {...item} key={item.name} precision={2} />
        ))}
      </div>
      <div className="FileAnalysis__results--fees-subcontainer">
        {fileAnalysisFeeInAOrdinaryBankruptcyDataSource.map((item) => (
          <ResultsRow {...item} key={item.name} precision={2} />
        ))}
      </div>
      <div className="FileAnalysis__results--fees-subcontainer">
        {fileAnalysisFeeInADivisionProposalDataSource.map((item) => (
          <ResultsRow {...item} key={item.name} precision={2} />
        ))}
      </div>
    </div>
  );
}

export default FeesTable;
