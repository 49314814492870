import { ITeamSearchCriteria, ISearchUserCriteria } from './types';

export const argumentifyTeamSearchCriteria = ({
  name,
  agentIdToBeIgnored,
  sorting,
  skipCount,
  maxResultCount,
}: ITeamSearchCriteria) => [name, agentIdToBeIgnored, sorting, skipCount, maxResultCount] as const;

// export const argumentifyUserSearchCriteria = ({ sorting, filter, skipCount, maxResultCount }: ISearchUserCriteria) =>
//   [sorting, filter, skipCount, maxResultCount] as const;

export const argumentifyUserSearchCriteria = ({
  searchText,
  pageIndex,
  pageSize,
  sortBy,
  isAscending,
  isActive,
}: ISearchUserCriteria) =>
  [searchText, undefined, false, false, pageIndex, pageSize, sortBy, isAscending, isActive] as const;
