import { useCallback, useEffect, useMemo, useState } from 'react';
import { InfoCircleOutlined } from '@ant-design/icons';
import { Popover, Col, Row } from 'antd';

import { ClientService } from '../../shared/api/ClientService';
import API from '../../utils/api';

import './AttachmentsTab.scss';
import useLocale from '../../hooks/useLocale';

const SupportingDocsLabel = ({
  supportingFolderId,
  prefixCodes,
  isPrefixCodesRequired = false,
  hint,
  limitSupportingDocsByPrefixCode,
}: {
  supportingFolderId: string;
  prefixCodes?: (string | undefined)[];
  isPrefixCodesRequired?: boolean;
  hint?: string[];
  limitSupportingDocsByPrefixCode?: (string | undefined)[];
}) => {
  const [supportingDocs, setSupportingDoc] = useState<ClientService.LookupDto[]>();
  const depsPrefixCodes = useMemo(() => `${prefixCodes}`, [prefixCodes]);
  const depsLimit = useMemo(() => `${limitSupportingDocsByPrefixCode}`, [limitSupportingDocsByPrefixCode]);

  const { t, getLocalizedDtoName } = useLocale();
  const requestListPrefixes = useCallback(
    async (documentFolderId: string) => {
      if (isPrefixCodesRequired && (!prefixCodes || prefixCodes?.length === 0)) {
        setSupportingDoc([]);
        return;
      }

      const response = await API.listPrefixes(documentFolderId, prefixCodes);
      if (response) {
        const supportingDocs = limitSupportingDocsByPrefixCode
          ? response?.filter((item) => limitSupportingDocsByPrefixCode?.indexOf(item?.code) >= 0)
          : response;
        setSupportingDoc(supportingDocs);
      }
    },
    [isPrefixCodesRequired, prefixCodes, limitSupportingDocsByPrefixCode]
  );

  useEffect(() => {
    if (supportingFolderId) requestListPrefixes(supportingFolderId);
  }, [supportingFolderId, depsPrefixCodes, depsLimit]);

  return (
    <div className="AttachmentsTab__supporting-docs-label">
      <Popover
        placement="bottomLeft"
        content={
          hint && hint?.length > 1 ? (
            <ul>
              {hint?.map((item, index) => (
                <li key={`hint-${index}`}>{item}</li>
              ))}
            </ul>
          ) : (
            hint?.[0]
          )
        }
        trigger="hover"
      >
        <Row gutter={8} align="middle">
          <Col>{hint && <InfoCircleOutlined className="AttachmentsTab__supporting-docs-label--hint-icon" />}</Col>
          <Col>{t.SUPPORTING_DOCUMENTS_NEEDED}:</Col>
        </Row>
      </Popover>

      <ol>
        {supportingDocs?.map((item, index) => (
          <li key={String(index)} className="AttachmentsTab__supporting-docs-label--list-item">
            {item.description && item.displayName
              ? `${getLocalizedDtoName(item) || item?.displayName} => ${item.description}`
              : item.description || item.displayName}
          </li>
        ))}
      </ol>
    </div>
  );
};

export default SupportingDocsLabel;
