import { CSSProperties, useCallback } from 'react';
import { Row, Col } from 'antd';

import { convertNegativeToReadable } from '../utils';

import '../FileAnalysis.scss';

export interface IResultsRow {
  name?: string;
  firstColCellValue?: number | string;
  firstCellSpan?: number;
  secondColCellValue?: number | string;
  secondCellSpan?: number;
  style?: CSSProperties;
  type?: 'title' | 'subtitle' | 'item' | 'subitem' | 'subtotal' | 'total' | 'empty';
  subitemLevel?: number;
  precision?: number;
  hidden?: boolean;
}

interface IStyle {
  containerStyle?: CSSProperties;
  nameStyle?: CSSProperties;
  cellStyle?: CSSProperties;
}

const ResultsRow = ({
  name,
  firstColCellValue,
  secondColCellValue,
  style,
  type,
  subitemLevel = 1,
  precision = 0,
  firstCellSpan = 4,
  secondCellSpan = 4,
  hidden,
}: IResultsRow) => {
  const setTypeStyle = useCallback((): IStyle => {
    const basicPaddings = { paddingTop: 4, paddingBottom: 4 };
    switch (type) {
      case 'title':
        return {
          containerStyle: {
            fontWeight: 700,
            borderBottom: 0,
            ...basicPaddings,
          },
        };
      case 'subtitle':
        return {
          containerStyle: {
            fontWeight: 700,
            borderBottom: 0,
            ...basicPaddings,
          },
        };
      case 'item':
        return {
          containerStyle: {
            borderBottom: 0,
            ...basicPaddings,
          },
        };
      case 'subitem':
        return {
          containerStyle: {
            borderBottom: 0,
            ...basicPaddings,
          },
          nameStyle: {
            textIndent: subitemLevel * 20,
          },
        };

      case 'subtotal':
        return {
          containerStyle: {
            ...basicPaddings,
          },
          nameStyle: {
            textIndent: subitemLevel * 20,
          },
        };
      case 'total':
        return {
          containerStyle: {
            borderTop: '1px solid black',
            ...basicPaddings,
          },
          cellStyle: {
            fontWeight: 700,
          },
        };

      case 'empty':
        return {
          containerStyle: {
            height: 34,
          },
        };
      default:
        return {};
    }
  }, [subitemLevel, type]);

  if (hidden) return <></>;

  return (
    <Row className="FileAnalysis__results--row" style={{ ...setTypeStyle()?.containerStyle, ...style }}>
      <Col
        span={24 - firstCellSpan - secondCellSpan}
        className="FileAnalysis__results--row-title"
        style={{ ...setTypeStyle()?.nameStyle }}
      >
        {name}
      </Col>
      <Col span={firstCellSpan} style={{ ...(setTypeStyle()?.cellStyle || {}) }}>
        {typeof firstColCellValue === 'string'
          ? firstColCellValue
          : convertNegativeToReadable(firstColCellValue, precision)}
      </Col>
      <Col span={secondCellSpan} style={{ ...(setTypeStyle()?.cellStyle || {}) }}>
        {typeof secondColCellValue === 'string'
          ? secondColCellValue
          : convertNegativeToReadable(secondColCellValue, precision)}
      </Col>
    </Row>
  );
};

export default ResultsRow;
