import React, { ReactNode, useState } from 'react';
import { Link } from 'react-router-dom';
import { Divider, Row, Col, Tooltip } from 'antd';
import { CompressOutlined, ExpandOutlined } from '@ant-design/icons';
import PreviewFileArray from '../PreviewFileArray/PreviewFileArray';
import Icon from '../../../../components/Icon/Icon';
import { getAsSystemColorOrUnchanged } from '../../../../utils/systemColors';
import ErrorsContainer, { IErrorsMsgAndType } from '../../../../components/ErrorsContainer/ErrorsContainer';

import styles from './ThreeColumnLowerLayout.module.scss';
import { ROUTES } from '../../../../constants/routes';
import useLocale from '../../../../hooks/useLocale';

interface ThreeColumnLowerLayoutProps {
  errors?: IErrorsMsgAndType[];
  leftColumnContent?: ReactNode;
  filesList?: string[];
  rightColumnContent?: ReactNode;
  className?: string;
  style?: React.CSSProperties;
}

/** Section works for Claim Process page. Dislay Claim Status, Imported Files and Creditor Email. #24309 */
const ThreeColumnLowerLayout = ({
  errors,
  leftColumnContent,
  filesList,
  rightColumnContent,
  className,
  style,
}: ThreeColumnLowerLayoutProps) => {
  const { t } = useLocale();
  // EXPAND AND CONTRACT BUTTONS SHOULD ACTUALLY BE DEFINED HERE
  const [filePreviewIsFullWidth, setFilePreviewIsFullWidth] = useState<boolean>(false);

  const addPropsAndTooltipToIconComponent = (IconComponent: any, tooltipText?: string): JSX.Element => {
    // return React.cloneElement(IconComponent, {
    const ClonedIcon = React.cloneElement(IconComponent, {
      onClick: () => {
        setFilePreviewIsFullWidth((prevValue) => {
          return !prevValue;
        });
      },
      style: {
        color: getAsSystemColorOrUnchanged('red'),
        fontSize: '20px',
      },
    });
    if (tooltipText) {
      return (
        <Tooltip placement="top" title={tooltipText}>
          {ClonedIcon}
        </Tooltip>
      );
    }
    return ClonedIcon;
  };

  return (
    <div className={`${styles.ThreeColumnLowerLayout} ${className ? className : ''}`} style={style}>
      <Divider className={styles.divider} />
      {errors && <ErrorsContainer errors={errors} noTitle />}
      <Row gutter={20} className={styles.three_col_row}>
        {/* LEFT COLUMN */}
        <Col span={6} className={styles.left_col}>
          {leftColumnContent && leftColumnContent}
        </Col>

        {/* CENTER COLUMN */}
        <Col span={filePreviewIsFullWidth ? 18 : 12}>
          <div className={styles.preview_container}>
            <PreviewFileArray
              webviewerHeight="822px"
              filesList={filesList ? filesList : undefined}
              extraActionButtons={
                <>
                  {filesList && filesList.length > 0 && (
                    <Link to={ROUTES.FILE_PREVIEW} target="_blank">
                      <Icon
                        iconName="MiniExpand"
                        color="red"
                        fontSize="24px"
                        style={{ position: 'relative', top: '1px' }}
                        onClick={() => {
                          console.log('filesList:', filesList);
                          if (filesList == null || filesList.length === 0) return;
                          localStorage.setItem('redirect', ROUTES.FILE_PREVIEW);
                          localStorage.setItem('fileIdArray', JSON.stringify(filesList));
                        }}
                        tooltip={{
                          placement: 'top',
                          title: t.OPEN_IN_NEW_TAB,
                        }}
                      />
                    </Link>
                  )}
                  {filePreviewIsFullWidth
                    ? addPropsAndTooltipToIconComponent(<CompressOutlined />, t.REDUCE_FILE_PREVIEW_SIZE)
                    : addPropsAndTooltipToIconComponent(<ExpandOutlined />, t.INCREASE_FILE_PREVIEW_SIZE)}
                </>
              }
            />
          </div>
        </Col>

        {/* RIGHT COLUMN */}
        {!filePreviewIsFullWidth && (
          <Col span={6} className={styles.right_col}>
            {rightColumnContent && rightColumnContent}
          </Col>
        )}
      </Row>
    </div>
  );
};

export default ThreeColumnLowerLayout;
