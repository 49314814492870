import { Typography, Progress } from 'antd';
import useLocale from '../../hooks/useLocale';
import { HUNDRED_PERCENT, MISSING_NUMBER } from '../../constants/common';
import './DebtorProfileInfoBlock.scss';

const { Text } = Typography;

interface ILeftSideItem {
  label: string;
  value?: string;
}

interface IRightSide {
  label?: string;
  value?: number;
}

interface IProps {
  leftSide?: ILeftSideItem[];
  rightSide?: IRightSide;
}

const NA = '-';
const MAX_LENGTH_OF_ITEMS = 5;

const DebtorProfileInfoBlock = ({ leftSide, rightSide }: IProps): JSX.Element => {
  const { t } = useLocale();

  return (
    <div className="DebtorProfileInfoBlock">
      <div className="DebtorProfileInfoBlock__left-side">
        {!!leftSide?.length &&
          leftSide.map((item) => (
            <div key={item.label} className="DebtorProfileInfoBlock__item">
              <Text className="DebtorProfileInfoBlock__item-label" type="secondary">
                {item.label}
              </Text>
              <div className="DebtorProfileInfoBlock__item-value">{item.value || NA}</div>
            </div>
          ))}
      </div>
      <div className="DebtorProfileInfoBlock__right-side">
        <div style={{ marginLeft: 'auto' }} className="DebtorProfileInfoBlock__item">
          <Text className="DebtorProfileInfoBlock__item-label" style={{ marginRight: 8 }} type="secondary">
            {t.DEBTOR_PROFILE_STAGE}
          </Text>
          {rightSide?.label}
          <div className="DebtorProfileInfoBlock__item-value">
            <Progress
              success={{
                percent: ((rightSide?.value ?? MISSING_NUMBER) / MAX_LENGTH_OF_ITEMS) * HUNDRED_PERCENT,
              }}
              showInfo={false}
              style={{ width: '280px' }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default DebtorProfileInfoBlock;
