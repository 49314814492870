import { Dropdown } from 'antd';
import { Icon } from '@fluentui/react/lib/Icon';

import ActionMenu from './ActionMenu';

import './QuickActionsToolbar.scss';
import React from 'react';

interface IProps {
  onAddClick: () => void;
  onHistoryClick: () => void;
  iconName?: string;
  icon?: JSX.Element;
  addItemTitle: string;
  historyItemTitle: string;
  style?: React.CSSProperties;
  addAllowed?: boolean;
}

const ActionDropdown = ({
  onAddClick,
  onHistoryClick,
  iconName,
  icon,
  addItemTitle,
  historyItemTitle,
  style,
  addAllowed,
}: IProps): JSX.Element => (
  <Dropdown
    overlay={
      <ActionMenu
        addItemTitle={addItemTitle}
        historyItemTitle={historyItemTitle}
        onAddClick={onAddClick}
        onHistoryClick={onHistoryClick}
        addAllowed={addAllowed}
      />
    }
    placement="bottomRight"
    getPopupContainer={(triggerNode: HTMLElement) => triggerNode.parentElement as HTMLElement}
  >
    <div className="QuickActionsToolbar__icon">{icon || <Icon iconName={iconName} style={style} />}</div>
  </Dropdown>
);

export default ActionDropdown;
