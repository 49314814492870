import { useCallback, useEffect, useState } from 'react';
import { Modal, Form, Select, Col, Row, Checkbox, Spin } from 'antd';

import Button from '../../../components/Button/Button';
import SelectWithResultingList from '../components/SelectWithResultingList/SelectWithResultingList';
import SelectSuffixIcon from '../../../components/SelectSuffixIcon/SelectSuffixIcon';

import useLocale from '../../../hooks/useLocale';
import useDebounce from '../../../hooks/useDebounce';

import genericMessage from '../../../utils/genericMessage';
import { ICourt } from './types';
import { convertCourtToReadableOption } from './utils';
import { ClientService } from '../../../shared/api/ClientService';
import API from '../../../utils/api';

import './Courts.scss';

interface IProps {
  externalCourtId?: string;
  onOk: () => void;
  onCancel: () => void;
}

const CourtsEditor = ({ externalCourtId, onOk, onCancel }: IProps) => {
  const { t } = useLocale();
  const { Option } = Select;

  const [form] = Form.useForm<ClientService.CourtInputDetailsDto>();

  const [courts, setCourts] = useState<ICourt[]>();
  const [searchTerm, setSearchTerm] = useState<string>();
  const [loading, setLoading] = useState<boolean>(false);

  const [agents, setAgents] = useState<ClientService.LookupDto[]>();

  const value = useDebounce(searchTerm);

  const requestCourts = useCallback(
    async (value?: string, externalCourtId?: string) => {
      setLoading(true);
      const response = await API.getCourtsSearchResults(value, externalCourtId, undefined).catch(() =>
        setLoading(false)
      );
      const agents = await API.listAgents().catch(() => setLoading(false));
      setLoading(false);

      if (response?.court && agents) {
        setCourts(
          response?.court?.map((item) => ({
            ...item,
            trusteeNames: item?.trusteeIds?.map((id) => agents?.find((agent) => agent?.id === id)?.name),
          })) as ICourt[]
        );
        setAgents(agents);
        if (response?.court?.length === 1 && response?.court?.[0]) form.setFieldsValue(response?.court?.[0]);
      }
    },
    [form]
  );

  const handleSubmit = useCallback(
    async ({ externalCourtId, trusteeIds, status }) => {
      const response = await API.editTrusteeAssignment({
        externalCourtId,
        trusteeIds,
        status,
      } as ClientService.CourtInputDetailsDto);

      if (response?.result === ClientService.Result.Successful) {
        genericMessage.success(externalCourtId ? 'Trustee Assignment is edited' : 'Trustee(s) is assigned');

        if (onOk) onOk();
      }
    },
    [onOk]
  );

  useEffect(() => {
    requestCourts(value, externalCourtId);
  }, [requestCourts, value, externalCourtId]);

  return (
    <Modal
      destroyOnClose
      centered
      visible
      title={externalCourtId ? 'Edit Trustee Assignment' : 'Assign Trustee'}
      width={900}
      footer={null}
      onCancel={onCancel}
      className="Courts__modal"
    >
      <Spin spinning={loading}>
        <Form form={form} initialValues={{ externalCourtId, status: true }} onFinish={handleSubmit}>
          <Row>
            <Col span={23}>
              <Form.Item
                name="externalCourtId"
                label={t.COURT}
                labelCol={{ span: 4 }}
                labelAlign="left"
                rules={[{ required: true, message: t.COURT_IS_REQUIRED }]}
                required
              >
                <Select
                  size="large"
                  suffixIcon={<SelectSuffixIcon />}
                  showSearch
                  placeholder={t.SELECT}
                  onSearch={setSearchTerm}
                  filterOption={false}
                  disabled={Boolean(externalCourtId)}
                >
                  {courts?.map((item) => (
                    <Option value={item?.externalCourtId} key={item?.externalCourtId}>
                      {convertCourtToReadableOption(item)}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={24}>
              <SelectWithResultingList
                name="trusteeIds"
                data={agents}
                label={t.TRUSTEE}
                labelCol={{ span: 4 }}
                required
                showSearch
                filterOption={(input, option) => {
                  return option?.title?.toLowerCase().includes(input.toLowerCase() || '');
                }}
              />
            </Col>

            <Col span={24}>
              <Form.Item name="status" valuePropName="checked" label="Status" labelCol={{ span: 4 }} labelAlign="left">
                <Checkbox />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={24} justify="end" className="Courts__modal--footer">
            <Col>
              <Button kind="cancel" onClick={onCancel}>
                {t.CANCEL}
              </Button>
            </Col>
            <Col>
              <Button kind="primary" htmlType="submit">
                {t.SAVE}
              </Button>
            </Col>
          </Row>
        </Form>
      </Spin>
    </Modal>
  );
};

export default CourtsEditor;
