import { createContext, useState } from 'react';

interface IModalContext {
  showModal: (modal: JSX.Element) => void;
  closeModal: () => void;
  modal: JSX.Element | null;
}

export const ModalContext = createContext<IModalContext>({
  showModal: () => {},
  closeModal: () => {},
  modal: null,
});

interface IProps {
  children: JSX.Element;
}

const ModalProvider = ({ children }: IProps): JSX.Element => {
  const [modal, setModal] = useState<JSX.Element | null>(null);

  const showModal = (modal: JSX.Element) => setModal(modal);

  const closeModal = () => setModal(null);

  return <ModalContext.Provider value={{ showModal, closeModal, modal }}>{children}</ModalContext.Provider>;
};

export default ModalProvider;
