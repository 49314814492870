interface IProps {
  title?: string;
  details?: string;
}

const SectionHeader = ({ title, details }: IProps) => (
  <div className="CounsellingViews__section-header">
    <span>{title}</span>
    {details ? ': ' : ''}
    <span className="CounsellingViews__section-header--details">{details}</span>
  </div>
);

export default SectionHeader;
