import { useQuery } from 'react-query';

import SelectLookupDto, { ISelectLookupDto } from './SelectLookupDto';
import { Form } from 'antd';
import { NamePath } from 'rc-field-form/lib/interface';

import { USE_QUERY_OPTIONS, REFERRAL_SOURCE_DETAILS_QUERY } from '../../../../constants/reactQuery';
import API from '../../../../utils/api';

interface IProps extends ISelectLookupDto {
  referralSourceTypeId: string;
  name?: NamePath;
}

const SourceDetailsSelect = ({ referralSourceTypeId, name, ...props }: IProps) => {
  let { data } = useQuery(
    [REFERRAL_SOURCE_DETAILS_QUERY, referralSourceTypeId],
    () => API.listReferralSourceDetails(referralSourceTypeId),
    USE_QUERY_OPTIONS
  );

  return (
    <Form.Item name={name}>
      <SelectLookupDto data={data} {...props} />
    </Form.Item>
  );
};

export default SourceDetailsSelect;
