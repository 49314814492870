import React from 'react';

interface IProps extends React.HTMLAttributes<HTMLSpanElement> {
  fill?: string;
  size?: number;
}

const AddIcon = ({ fill = '#47576b', size = 14, ...props }: IProps) => {
  return (
    <span style={{ cursor: 'pointer' }} {...props}>
      <svg width={size} height={size} viewBox="0 0 18 18">
        <g transform="translate(-3 -3)">
          <path
            d="M12,7a.75.75,0,0,1,.75.75v3.5h3.5a.75.75,0,0,1,0,1.5h-3.5v3.5a.75.75,0,0,1-1.5,0v-3.5H7.75a.75.75,0,0,1,0-1.5h3.5V7.75A.75.75,0,0,1,12,7Z"
            fill={fill}
          />
          <path
            d="M3,6.25A3.25,3.25,0,0,1,6.25,3h11.5A3.25,3.25,0,0,1,21,6.25v11.5A3.25,3.25,0,0,1,17.75,21H6.25A3.25,3.25,0,0,1,3,17.75ZM6.25,4.5A1.75,1.75,0,0,0,4.5,6.25v11.5A1.751,1.751,0,0,0,6.25,19.5h11.5a1.75,1.75,0,0,0,1.75-1.75V6.25A1.75,1.75,0,0,0,17.75,4.5Z"
            fill={fill}
          />
        </g>
      </svg>
    </span>
  );
};

export default AddIcon;
