import { InputNumber, InputNumberProps } from 'antd';

import { antdUtil } from '../../../../utils/antdUtil';

import '../ReceiptEditor.scss';

const CurrencyInput = ({ ...props }: InputNumberProps) => (
  <InputNumber
    {...props}
    placeholder="$ 0.00"
    className="ReceiptEditor__payment-amount"
    min={0}
    max={9999999}
    precision={2}
    step={1}
    formatter={(value) => antdUtil.currencyFormatter(Number(value))}
    controls={false}
  />
);

export default CurrencyInput;
