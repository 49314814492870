import { useCallback, useEffect, useState, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { Form, Select, Col, Row, Spin } from 'antd';
import { Icon } from '@fluentui/react/lib/Icon';
import moment from 'moment';

import Button from '../../../../../../components/Button/Button';
import OverviewRow from '../components/OverviewRow';
import SelectSuffixIcon from '../../../../../../components/SelectSuffixIcon/SelectSuffixIcon';

import {
  getSessionsByNumber,
  isAnySessionCompleted,
  isAnySessionPending,
  getCompletedSession,
  getPendingSession,
} from '../utils';
import genericMessage from '../../../../../../utils/genericMessage';
import { ClientService } from '../../../../../../shared/api/ClientService';
import API from '../../../../../../utils/api';
import useLocale from '../../../../../../hooks/useLocale';
import { eventBus } from '../../../../../../utils/eventBus';
import { ADMIN_INFO_CHANGE_EVENT } from '../../../../../../constants/eventBus';

import './CounsellingViews.scss';
import styles from '../../../../../../styles/style.module.scss';

interface IProps {
  onAddSession: () => void;
  onSessionSelect: (sessionId: string) => void;
  disabled?: boolean;
}

const SessionsOverview = ({ onAddSession, onSessionSelect, disabled }: IProps) => {
  const [form] = Form.useForm<ClientService.AdminInfoCounsellingUpdateDto>();
  const { applicationFileId } = useParams<{ applicationFileId: string }>();
  const { Option } = Select;
  const { t } = useLocale();

  const [initialValues, setInitialValues] = useState<ClientService.AdminInfoCounsellingDto>();
  const [loading, setLoading] = useState<boolean>(false);

  const firstSessions = useMemo(() => getSessionsByNumber(1, initialValues?.sessions), [initialValues?.sessions]);
  const secondSessions = useMemo(() => getSessionsByNumber(2, initialValues?.sessions), [initialValues?.sessions]);
  const thirdSessions = useMemo(() => getSessionsByNumber(3, initialValues?.sessions), [initialValues?.sessions]);

  const COUNSELLING_DROPDOWN_OPTIONS = useMemo(
    () => [
      { value: ClientService.FileProcessStatusEnum.Pending, label: t.PENDING },
      { value: ClientService.FileProcessStatusEnum.Exception, label: t.EXCEPTION },
      { value: ClientService.FileProcessStatusEnum.Complete, label: t.COMPLETED },
    ],
    [t.COMPLETED, t.EXCEPTION, t.PENDING]
  );

  const requestCounsellingGET = useCallback(
    async (applicationFileId: string) => {
      setLoading(true);
      const response = await API.counsellingGET(applicationFileId).catch(() => setLoading(false));

      if (response) {
        setInitialValues({ ...response } as ClientService.AdminInfoCounsellingDto);
        form.setFieldsValue({ status: response?.counsellingStatus?.status || undefined });
      }

      setLoading(false);
    },
    [form]
  );

  const requestCounsellingPUT = useCallback(
    async (values: ClientService.AdminInfoCounsellingUpdateDto) => {
      setLoading(true);
      const response = await API.counsellingPUT(applicationFileId as string, values).catch(() => setLoading(false));
      setLoading(false);

      if (response?.result === ClientService.Result.Successful) {
        eventBus.dispatch(ADMIN_INFO_CHANGE_EVENT);
      } else {
        genericMessage.error({}, response?.messages?.[0]?.body);
      }
    },
    [applicationFileId]
  );

  const counsellingOptions = useMemo(
    () =>
      isAnySessionCompleted(firstSessions) && isAnySessionCompleted(secondSessions)
        ? COUNSELLING_DROPDOWN_OPTIONS
        : COUNSELLING_DROPDOWN_OPTIONS?.filter((item) => item.value !== ClientService.FileProcessStatusEnum.Complete),
    [COUNSELLING_DROPDOWN_OPTIONS, firstSessions, secondSessions]
  );

  const isAddSessionButtonVisible = useMemo(() => {
    if (isAnySessionPending(thirdSessions) || isAnySessionCompleted(thirdSessions)) {
      return false;
    }

    if (
      isAnySessionPending(secondSessions) &&
      (isAnySessionCompleted(firstSessions) || isAnySessionPending(firstSessions))
    ) {
      return false;
    }

    return true;
  }, [firstSessions, secondSessions, thirdSessions]);

  useEffect(() => {
    if (applicationFileId) requestCounsellingGET(applicationFileId);
  }, [applicationFileId, requestCounsellingGET]);

  return (
    <Spin spinning={loading}>
      <Row className="CounsellingViews">
        <Col span={24}>
          <Form form={form} layout="vertical" onFinish={requestCounsellingPUT}>
            <div className="CounsellingViews__section">
              <div className="CounsellingViews__section-header">{t.COUNSELLING}</div>
              <Row gutter={24} align="bottom">
                <Col xl={8}>
                  <Form.Item name="status" label={t.STATUS} rules={[{ required: true, message: t.REQUIRED_FIELD }]}>
                    <Select
                      size="large"
                      suffixIcon={<SelectSuffixIcon />}
                      placeholder={t.SELECT_STATUS}
                      disabled={disabled}
                    >
                      {counsellingOptions?.map((item) => (
                        <Option value={item?.value} key={item.value}>
                          {item?.label}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col>
                  <Form.Item>
                    <Button kind="primary" htmlType="submit" disabled={disabled}>
                      {t.SAVE}
                    </Button>
                  </Form.Item>
                </Col>
              </Row>
            </div>
            <div className="CounsellingViews__section-header">{t.SESSIONS}</div>
            {initialValues?.sessions?.map((item, index) => (
              <OverviewRow
                key={`session-${String(index)}`}
                session={item}
                onClick={() => {
                  if (!disabled) onSessionSelect(item?.id as string);
                }}
              />
            ))}
            <Row justify="end">
              <Col>
                {isAddSessionButtonVisible && (
                  <Row
                    align="middle"
                    className="CounsellingViews__add-session-container"
                    gutter={6}
                    onClick={onAddSession}
                    style={{
                      color: disabled ? styles.colorSecondary : styles.colorDanger,
                      cursor: disabled ? 'not-allowed' : 'pointer',
                    }}
                  >
                    <Col>
                      <Icon
                        iconName="Add"
                        className="CounsellingViews__add-icon"
                        style={{
                          borderColor: disabled ? styles.colorSecondary : styles.colorDanger,
                        }}
                      />
                    </Col>
                    <Col>{t.ADD_SESSION}</Col>
                  </Row>
                )}
              </Col>
            </Row>
          </Form>
        </Col>
      </Row>
    </Spin>
  );
};

export default SessionsOverview;
