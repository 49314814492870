import { useCallback, useEffect, useState, useRef, useMemo } from 'react';
import { Col, Divider, Form, Input, Radio, Row, Popover, message } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
import useLocale from '../../../../hooks/useLocale';
import { ClientService } from '../../../../shared/api/ClientService';
import API from '../../../../utils/api';
import Button from '../../../../components/Button/Button';
import genericMessage from '../../../../utils/genericMessage';
import ErrorComponent, { IError } from './ErrorComponent';
import useUnsaved from '../../../../hooks/useUnsaved';
import eventBus from '../../../../utils/eventBus';
import { APPFORM_SAVE_AND_LEAVE_EVENT, UPLOAD_FILES_EVENT } from '../../../../constants/eventBus';
import AttachmentsTab, { AttachmentsFormProps } from '../../../../components/AttachmentsTab';
import { Icon } from '@fluentui/react/lib/Icon';
import ChangeTrackerFormItem from '../../../../components/ChangeTrackerFormItem';

import './ApplicationFormTab.scss';
import { debounceWithTimeOut } from '../../../../utils/helpers';

interface IProps {
  data: ClientService.IApplicationFileDto | null;
  updateStatus: () => void;
  onContinue: () => void;
}

const bankingInfoFields = ['institution', 'accountNumber'];

interface IForm extends ClientService.AppFormBankingInfoDto, AttachmentsFormProps {}

const BankingInfo = ({ data, updateStatus, onContinue }: IProps): JSX.Element => {
  const { t } = useLocale();

  const [form] = Form.useForm<IForm>();
  const [formErrors, setFormErrors] = useState<IError[]>();
  const { setIsUnsavedForm } = useUnsaved();
  const isLocked = useMemo(
    () => Boolean(data?.isAppFormLocked) || Boolean(data?.isLocked),
    [data?.isAppFormLocked, data?.isLocked]
  );

  const [isReviewed, setIsReviewed] = useState(false);
  const [needToBeReviewed, setNeedToBeReviewed] = useState(false);
  const [isNotStarted, setIsNotStarted] = useState(false);
  const [institutionFieldHasValue, setInstitutionFieldHasValue] = useState(false);

  const [isMarried, setIsMarried] = useState<boolean>(false);
  const [isPrevalidated, setIsPrevalidated] = useState<boolean>(false);
  const [isInstitution, setIsInstitution] = useState<boolean>(false);

  const refBankingInfo = useRef<HTMLDivElement>(null);
  const refErrorContainer = useRef<HTMLDivElement>(null);

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [existingDocumentIds, setExistingDocumentIds] = useState<string[]>([]);

  const refreshStatus = useCallback(() => {
    API.getBankingInfoStatus(data?.id).then((response) => {
      setIsReviewed(
        response.status === ClientService.AppFormStatusEnum.Reviewed ||
          response.status === ClientService.AppFormStatusEnum.NotStarted
      );
      setNeedToBeReviewed(
        response.status === ClientService.AppFormStatusEnum.ReadyForReview ||
          response.status === ClientService.AppFormStatusEnum.Incomplete_Review
      );
      setIsNotStarted(response.status === ClientService.AppFormStatusEnum.NotStarted);
    });
  }, [data?.id]);

  const markComplete = useCallback(() => {
    API.bankingInfoMarkAsComplete(data?.id).then((response) => {
      updateStatus();
      refreshStatus();
    });
  }, [data?.id, refreshStatus, updateStatus]);

  const markReviewed = useCallback(() => {
    API.bankingInfoMarkAsReviewed(data?.id).then((response) => {
      updateStatus();
      refreshStatus();
    });
  }, [data?.id, refreshStatus, updateStatus]);

  const isAnyInstitutionInDetails = useCallback(
    (details?: ClientService.AppFormBankingInfoDetailDto[]) =>
      Boolean(details?.find((item) => Boolean(item?.institution) || false)),
    []
  );

  useEffect(() => {
    if (data?.id) {
      API.getAppFormBankingInfo(data?.id).then(async (response) => {
        preFillCollections(response);
        form.setFieldsValue(response);

        //As per my understanding the effect can prevent us form reclicking on button

        const bankingInfoFormStatus = await API.getBankingInfoStatus(data?.id);
        const isNotStarted = bankingInfoFormStatus?.status === ClientService.AppFormStatusEnum.NotStarted;

        if (!data?.isAppFormLocked && !isNotStarted) {
          form.submit();
        }

        if (isNotStarted) {
          setIsPrevalidated(true);
        }

        setIsMarried(response.isMarried || false);
        setIsInstitution(isAnyInstitutionInDetails(response?.details));
        setExistingDocumentIds(response?.documentIds || []);
      });
      refreshStatus();
    }
  }, [data?.id, data?.isAppFormLocked, form, isAnyInstitutionInDetails, refreshStatus, needToBeReviewed]);

  const preFillCollections = (response: ClientService.AppFormBankingInfoDto) => {
    if (!response.details) {
      response.details = [];
    }
    if (response.details.length === 0) {
      response.details.push(new ClientService.AppFormBankingInfoDetailDto());
    }
    if (!response.spouseDetails) {
      response.spouseDetails = [];
    }
    if (response.spouseDetails.length === 0) {
      response.spouseDetails.push(new ClientService.AppFormBankingInfoDetailDto());
    }

    return response;
  };

  const scrollToRef = useCallback((tabRef: React.RefObject<HTMLDivElement>) => {
    setTimeout(() => tabRef?.current?.scrollIntoView({ block: 'start', behavior: 'smooth', inline: 'start' }), 500);
  }, []);

  const requestBankingInfoUpdate = useCallback(
    async (dto: ClientService.AppFormBankingInfoDto, onSaveSuccess?: () => void) => {
      const response = await API.updateAppFormBankingInfo(dto);

      if (response?.result === ClientService.Result.Successful) {
        genericMessage.success(t.SUCCESSFULLY_SAVED);
        setIsUnsavedForm(false);
        updateStatus();
        refreshStatus();

        if (onSaveSuccess) {
          onSaveSuccess();
        }
      } else {
        genericMessage.error({}, response?.messages?.[0]?.body);
      }
    },
    [refreshStatus, setIsUnsavedForm, t.SUCCESSFULLY_SAVED, updateStatus]
  );

  const onFinish = useCallback(
    (dto: ClientService.AppFormBankingInfoDto, onSaveSuccess?: () => void) => {
      if (data?.isAppFormLocked) return;

      setFormErrors(form.getFieldsError());

      if (isPrevalidated) {
        dto.fileId = data?.id;

        if (isInstitution) {
          eventBus.dispatch(UPLOAD_FILES_EVENT, {
            onUploadSuccess: async (documentIds: string[]) => {
              dto.documentIds = documentIds;
              requestBankingInfoUpdate(dto, onSaveSuccess);
            },
            onUploadError: (errorMessage?: string) => {
              errorMessage && message.error(errorMessage);
            },
          });
        } else {
          requestBankingInfoUpdate(dto, onSaveSuccess);
        }
      } else {
        setIsPrevalidated(true);
      }
    },
    [data?.isAppFormLocked, data?.id, form, isPrevalidated, isInstitution, requestBankingInfoUpdate]
  );

  const getPanelFieldsErrors = useCallback(
    (panelFields?: string[]) => {
      if (!panelFields) return undefined;

      const filtered = formErrors?.filter(
        (item) =>
          (item?.errors?.length || item?.warnings?.length) &&
          Boolean(item?.name?.find((name) => panelFields.indexOf(String(name)) > -1))
      );

      return filtered?.length ? filtered : undefined;
    },
    [formErrors]
  );

  const saveAndLeave = useCallback(
    (e) => {
      onFinish(form.getFieldsValue(), e?.detail?.onSaveSuccess);
    },
    [form, onFinish]
  );

  const saveAndContinue = useCallback(() => {
    if (isLoading) return;
    try {
      setIsLoading(true);
      onFinish(form.getFieldsValue(), onContinue);
    } catch (e) {
      console.log(e);
    } finally {
      setIsLoading(false);
    }
  }, [form, isLoading, onContinue, onFinish, setIsLoading]);

  useEffect(() => {
    return () => setIsUnsavedForm(false);
  }, [setIsUnsavedForm]);

  useEffect(() => {
    eventBus.on(APPFORM_SAVE_AND_LEAVE_EVENT, saveAndLeave);

    return () => {
      eventBus.remove(APPFORM_SAVE_AND_LEAVE_EVENT, saveAndLeave);
    };
  }, [saveAndLeave]);

  return (
    <div ref={refErrorContainer}>
      <Form
        form={form}
        onFinish={(values) => onFinish(values)}
        onFinishFailed={() => onFinish(form.getFieldsValue())}
        layout="vertical"
        className="ApplicationFormTab"
        validateTrigger={['onSubmit', 'onBlur']}
        onValuesChange={(value, values) => {
          setIsUnsavedForm(true);

          const isInstitutionUpdated = isAnyInstitutionInDetails(values?.details);
          if (isInstitutionUpdated !== isInstitution) {
            setIsInstitution(isInstitutionUpdated);
          }
          const institutionFieldHasValue = values?.details?.some(
            (detail) =>
              detail?.institution !== undefined && detail?.institution !== null && detail?.institution?.trim() !== ''
          );
          setInstitutionFieldHasValue(institutionFieldHasValue || false);
        }}
      >
        {Boolean(formErrors?.filter((item) => item?.errors?.length || item?.warnings?.length)?.length) && (
          <div className="ApplicationFormTab__errors-container" ref={refErrorContainer}>
            <div className="ApplicationFormTab__errors-container--title">{t.VALIDATION_ERRORS_AND_WARNINGS}</div>
            <ErrorComponent
              errors={getPanelFieldsErrors(bankingInfoFields)}
              onClick={() => scrollToRef(refBankingInfo)}
            />
          </div>
        )}
        <div className="ApplicationFormTab__header" ref={refBankingInfo}>
          {t.APPLICANT}
        </div>
        <Form.List name="details">
          {(details, { add, remove }) => (
            <>
              {details?.map((row, index) => (
                <>
                  <Row gutter={20} align="middle" key={`details-${index}`}>
                    <Col span={8}>
                      <ChangeTrackerFormItem
                        name={[row.name, 'institution']}
                        className="ApplicationFormTab__input-label-warning"
                        label={t.INSTITUTION}
                        rules={[{ required: true, warningOnly: true, message: t.RECOMMENDED_FIELD }]}
                        listName="details"
                      >
                        <Input size="large" disabled={isLocked} />
                      </ChangeTrackerFormItem>
                    </Col>
                    <Col span={8}>
                      <ChangeTrackerFormItem
                        name={[row.name, 'accountNumber']}
                        className="ApplicationFormTab__input-label-warning"
                        label={t.ACCOUNT_NUMBER}
                        rules={[{ required: true, warningOnly: true, message: t.RECOMMENDED_FIELD }]}
                        listName="details"
                      >
                        <Input size="large" disabled={isLocked} />
                      </ChangeTrackerFormItem>
                    </Col>
                    <Col span={8}>
                      <ChangeTrackerFormItem name={[row.name, 'comments']} label={t.COMMENTS} listName="details">
                        <Input size="large" disabled={isLocked} />
                      </ChangeTrackerFormItem>
                    </Col>
                    <Col span={8}>
                      <ChangeTrackerFormItem
                        name={[row.name, 'isOverdraftOwed']}
                        label={t.OVERDRAFT_OWED}
                        listName="details"
                      >
                        <Radio.Group size="large" buttonStyle="solid" optionType="button" disabled={isLocked}>
                          <Radio.Button value={true}>{t.YES}</Radio.Button>
                          <Radio.Button value={false}>{t.NO}</Radio.Button>
                        </Radio.Group>
                      </ChangeTrackerFormItem>
                    </Col>
                    <Col span={8}>
                      <ChangeTrackerFormItem
                        name={[row.name, 'isJointAccount']}
                        label={t.JOINT_ACCOUNT}
                        listName="details"
                      >
                        <Radio.Group size="large" buttonStyle="solid" optionType="button" disabled={isLocked}>
                          <Radio.Button value={true}>{t.YES}</Radio.Button>
                          <Radio.Button value={false}>{t.NO}</Radio.Button>
                        </Radio.Group>
                      </ChangeTrackerFormItem>
                    </Col>
                    <Col span={7}></Col>
                    <Col span={+!isLocked} style={{ textAlign: 'right' }}>
                      <DeleteOutlined
                        className="ApplicationFormTab__input-action-icon"
                        onClick={() => remove(row.name)}
                      />
                    </Col>
                  </Row>
                </>
              ))}
              {!isLocked && (
                <Row gutter={20}>
                  <Col span={8}>
                    <Form.Item>
                      <div onClick={() => add()} className="ApplicationFormTab__link-left">
                        {t.ADD_BANKING_INFORMATION}
                      </div>
                    </Form.Item>
                  </Col>
                </Row>
              )}
            </>
          )}
        </Form.List>

        {isMarried && (
          <>
            <Divider />
            <div className="ApplicationFormTab__header">{t.SPOUSE_PARTNER}</div>
            <Form.List name="spouseDetails">
              {(spouseDetails, { add, remove }) => (
                <>
                  {spouseDetails?.map((row, index) => (
                    <Row gutter={20} align="middle" key={`spouseDetails-${index}`}>
                      <Col span={8}>
                        <ChangeTrackerFormItem
                          name={[row.name, 'institution']}
                          label={t.INSTITUTION}
                          listName="spouseDetails"
                        >
                          <Input size="large" disabled={isLocked} />
                        </ChangeTrackerFormItem>
                      </Col>
                      <Col span={8}>
                        <ChangeTrackerFormItem
                          name={[row.name, 'accountNumber']}
                          label={t.ACCOUNT_NUMBER}
                          listName="spouseDetails"
                        >
                          <Input size="large" disabled={isLocked} />
                        </ChangeTrackerFormItem>
                      </Col>
                      <Col span={8}>
                        <ChangeTrackerFormItem
                          name={[row.name, 'comments']}
                          label={t.COMMENTS}
                          listName="spouseDetails"
                        >
                          <Input size="large" disabled={isLocked} />
                        </ChangeTrackerFormItem>
                      </Col>
                      <Col span={8}>
                        <ChangeTrackerFormItem
                          name={[row.name, 'isOverdraftOwed']}
                          label={t.OVERDRAFT_OWED}
                          listName="spouseDetails"
                        >
                          <Radio.Group size="large" buttonStyle="solid" optionType="button" disabled={isLocked}>
                            <Radio.Button value={true}>{t.YES}</Radio.Button>
                            <Radio.Button value={false}>{t.NO}</Radio.Button>
                          </Radio.Group>
                        </ChangeTrackerFormItem>
                      </Col>
                      <Col span={8}>
                        <ChangeTrackerFormItem
                          name={[row.name, 'isJointAccount']}
                          label={t.JOINT_ACCOUNT}
                          listName="spouseDetails"
                        >
                          <Radio.Group size="large" buttonStyle="solid" optionType="button" disabled={isLocked}>
                            <Radio.Button value={true}>{t.YES}</Radio.Button>
                            <Radio.Button value={false}>{t.NO}</Radio.Button>
                          </Radio.Group>
                        </ChangeTrackerFormItem>
                      </Col>
                      <Col span={7}></Col>
                      <Col span={+!isLocked} style={{ textAlign: 'right' }}>
                        <DeleteOutlined
                          className="ApplicationFormTab__input-action-icon"
                          onClick={() => remove(row.name)}
                        />
                      </Col>
                    </Row>
                  ))}
                  {!isLocked && (
                    <Row gutter={20}>
                      <Col span={8}>
                        <Form.Item>
                          <div onClick={() => add()} className="ApplicationFormTab__link-left">
                            {t.ADD_BANKING_INFORMATION}
                          </div>
                        </Form.Item>
                      </Col>
                    </Row>
                  )}
                </>
              )}
            </Form.List>
          </>
        )}

        {isInstitution && (
          <>
            <Divider />
            <div className="ApplicationFormTab__header">
              Supporting Documents
              <Popover
                content={t.PREAUTHORIZATION_FORM_REQUIRED_FOR_ACCOUNT_IN_WHICH_YOU_WILL_BE_DRAWING_MONEY_FROM}
                trigger="hover"
                placement="bottomLeft"
              >
                <Icon iconName="Info" className="ApplicationFormTab__tooltip" style={{ cursor: 'pointer' }} />
              </Popover>
            </div>
            <AttachmentsTab
              applicationFileId={data?.id as string}
              form={form}
              supportingFolderCode="banking"
              isFlatLayout
              prefixCodes={['Banking.Information.PreAuth-Form', 'Banking.Void-Cheque']}
              flatLayoutProps={{
                hasInstruction: true,
                hasSupportingList: true,
                hasExistingFiles: true,
                supportingListLabelHint: [
                  t.ONLY_1_OF_THE_2_SUPPORTING_DOCUMENTS_LISTED_FOR_BANKING_INFORMATION_IS_REQUIRED,
                ],
              }}
              columnsProps={{ hasSizeColumn: true, isDeleteDisabled: isLocked }}
              draggerProps={{ disabled: isLocked }}
              limitSupportingDocsByPrefixCode={['Banking.Information.PreAuth-Form', 'Banking.Void-Cheque']}
              newSupportingFileRowLayoutProps={{ disabledFoldersInput: true }}
              existingDocumentIds={existingDocumentIds}
            />
          </>
        )}

        <Row justify="end" gutter={20} className="ApplicationFormTab__buttons-container">
          <Col>
            <Button padding="large" disabled={!needToBeReviewed || isLocked} onClick={markReviewed}>
              {t.MARK_AS_REVIEWED}
            </Button>
          </Col>
          <Col>
            <Button
              padding="large"
              disabled={!isReviewed || (isNotStarted && institutionFieldHasValue) || isLocked}
              onClick={markComplete}
            >
              {t.MARK_AS_COMPLETED}
            </Button>
          </Col>
          <Col>
            <Button
              kind="cancel"
              padding="large"
              disabled={isLocked || isLoading}
              onClick={debounceWithTimeOut(saveAndContinue, 2000)}
            >
              {t.CONTINUE}
            </Button>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default BankingInfo;
