import { useCallback, useEffect, useMemo, useState } from 'react';
import { Col, Row, Form, Select, Spin } from 'antd';
import { useParams, useNavigate } from 'react-router-dom';

import Button from '../../../../../../components/Button/Button';
import FormSectionTitle from '../../../../../../components/FormSectionTitle/FormSectionTitle';
import SelectSuffixIcon from '../../../../../../components/SelectSuffixIcon/SelectSuffixIcon';

import useLocale from '../../../../../../hooks/useLocale';
import { RECEIPTS_DISBURSEMENTS_DROPDOWN_OPTIONS } from '../utils';
import genericMessage from '../../../../../../utils/genericMessage';
import { eventBus } from '../../../../../../utils/eventBus';
import {
  RECEIPTS_DISBURSEMENTS_FORM_CHANGE_EVENT,
  ADMIN_INFO_CHANGE_EVENT,
} from '../../../../../../constants/eventBus';

import { ClientService } from '../../../../../../shared/api/ClientService';
import API from '../../../../../../utils/api';

import '../ReceiptsAndDisbursements.scss';

interface IProps {
  disableAllFields?: boolean;
}

const ReceiptsAndDisbursementsStatusForm = ({ disableAllFields }: IProps) => {
  const { Option } = Select;
  const { applicationFileId } = useParams<{ applicationFileId: string }>();
  const { t } = useLocale();
  const navigate = useNavigate();

  const [form] = Form.useForm<ClientService.AdminInfoReceiptsDisbursementsDto>();
  const [initial, setInitial] = useState<ClientService.AdminInfoReceiptsDisbursementsDto>();
  const [loading, setLoading] = useState<boolean>(false);

  const options = useMemo(() => {
    const options = [...RECEIPTS_DISBURSEMENTS_DROPDOWN_OPTIONS];
    switch (initial?.receiptsDisbursementsProcessStatus) {
      case ClientService.FileProcessStatusEnum.Pending:
        return options?.splice(initial?.isProcessAllowedToBeCompleted ? 2 : 0, 1);
      case ClientService.FileProcessStatusEnum.InProgress:
        return options?.splice(1, initial?.isProcessAllowedToBeCompleted ? 2 : 1);
      case ClientService.FileProcessStatusEnum.Incomplete:
        return options?.splice(1, initial?.isProcessAllowedToBeCompleted ? 2 : 1);
      case ClientService.FileProcessStatusEnum.Complete:
        return options?.splice(2, 1);
      default:
        return options?.splice(0, 1);
    }
  }, [initial]);

  const requestReceiptsDisbursementsStatus = useCallback(
    async (applicationFileId: string) => {
      const response = await API.receiptsDisbursementsGET(applicationFileId);
      if (response) {
        form.setFieldsValue(response);
        setInitial(response);
      }
    },
    [form]
  );

  const handleSubmit = useCallback(async () => {
    setLoading(true);
    const receiptDisbursementProcessStatus = form.getFieldsValue().receiptsDisbursementsProcessStatus;
    const response = await API.receiptsDisbursementsPUT(
      applicationFileId as string,
      { receiptDisbursementProcessStatus } as ClientService.AdminInfoReceiptsDisbursementsUpdateDto
    ).catch(() => setLoading(false));
    setLoading(false);

    if (response?.result === ClientService.Result.Successful) {
      eventBus.dispatch(ADMIN_INFO_CHANGE_EVENT);
      genericMessage.success(t.UPDATED_SUCCESSFULLY);
    }
  }, [applicationFileId, form, t.UPDATED_SUCCESSFULLY]);

  const requestStatusOnFormChange = useCallback(() => {
    requestReceiptsDisbursementsStatus(applicationFileId as string);
  }, [applicationFileId, requestReceiptsDisbursementsStatus]);

  useEffect(() => {
    if (applicationFileId) {
      requestReceiptsDisbursementsStatus(applicationFileId);
    }
  }, [applicationFileId, requestReceiptsDisbursementsStatus]);

  useEffect(() => {
    eventBus.on(RECEIPTS_DISBURSEMENTS_FORM_CHANGE_EVENT, requestStatusOnFormChange);

    return () => {
      eventBus.remove(RECEIPTS_DISBURSEMENTS_FORM_CHANGE_EVENT, requestStatusOnFormChange);
    };
  }, [requestStatusOnFormChange]);

  return (
    <Spin spinning={loading}>
      <FormSectionTitle titleText={t.RECEIPTS_DISBURSEMENTS} />
      <Form layout="vertical" form={form} onFinish={handleSubmit}>
        <Row gutter={20} align="bottom">
          <Col span={8}>
            <Form.Item label={t.RECEIPTS_DISBURSEMENTS_STATUS} required name="receiptsDisbursementsProcessStatus">
              <Select placeholder="Select" size="large" suffixIcon={<SelectSuffixIcon />} disabled={disableAllFields}>
                {options?.map((item) => (
                  <Option key={item?.value} value={item.value} disabled={item?.disabled}>
                    {item?.label}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col>
            <Form.Item>
              <Button kind="cancel" disabled={disableAllFields}>
                {t.CANCEL}
              </Button>
            </Form.Item>
          </Col>
          <Col>
            <Form.Item>
              <Button kind="primary" htmlType="submit" disabled={disableAllFields}>
                {t.SAVE}
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Spin>
  );
};

export default ReceiptsAndDisbursementsStatusForm;
