import moment from 'moment';

import { IHolidaysSearchCriteria } from './types';
import { convertArrayToReadable } from '../utils';
import { DATE_FORMAT2 } from '../../../constants/common';
import { ClientService } from '../../../shared/api/ClientService';

export const argumentifyHolidaysSearchCriteria = ({ filterText, skipCount, maxResultCount }: IHolidaysSearchCriteria) =>
  [filterText, skipCount, maxResultCount] as const;

export const convertDateToReadable = (date?: moment.Moment) => (date ? moment(date).format(DATE_FORMAT2) : '');

export const convertOfficesToReadable = (item?: ClientService.HolidayDto) =>
  item?.isForAllOffices ? 'All' : convertArrayToReadable(item?.officeLocationNames);
