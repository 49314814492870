import { useEffect, useState } from 'react';
import { Form, Col, Row, Select, Tooltip } from 'antd';
import { Icon } from '@fluentui/react/lib/Icon';
import moment from 'moment';
import update from 'immutability-helper';
import { useQuery } from 'react-query';

import TimePicker from './TimePicker';
import SelectLookupDto from '../../../../../components/SelectLookupDto/SelectLookupDto';
import SelectSuffixIcon from '../../../../../components/SelectSuffixIcon/SelectSuffixIcon';

import { argumentifyOfficeLocationsSearchCriteria } from '../../../utils';
import { getFilledArray, setRequiredRule } from './utils';
import { MeetingTypeDto } from '../../../types';
import {
  INCLUDE_INACTIVE,
  MEETING_TYPES_QUERY,
  OFFICE_LOCATIONS_WITH_INACTIVE_QUERY,
  REGIONS_LOCATIONS_QUERY,
} from '../../../../../constants/reactQuery';
import { MAX_PAGE_SIZE } from '../../../../../constants/common';

import { ClientService } from '../../../../../shared/api/ClientService';
import API from '../../../../../utils/api';

import './WeeklyRow.scss';
import useLocale from '../../../../../hooks/useLocale';

interface IProps {
  rowName: number;
  weekdayPropName: string;
  remove: () => void;
}

function AvailabilityItem({ remove, rowName, weekdayPropName }: IProps) {
  const { Option } = Select;

  const [hours, setHours] = useState<number[]>();
  const [error, setError] = useState<string>();

  const { data: meetingTypes, isFetching: meetingTypesLoading } = useQuery([MEETING_TYPES_QUERY], () =>
    API.listMeetingTypes()
  );

  const { data: regions, isFetching: regionsLoading } = useQuery([REGIONS_LOCATIONS_QUERY], () =>
    API.listOfficeRegions()
  );

  const { data: locations, isFetching: locationsLoading } = useQuery(
    [OFFICE_LOCATIONS_WITH_INACTIVE_QUERY, INCLUDE_INACTIVE, MAX_PAGE_SIZE],
    () =>
      API.officeLocationsGET(
        ...argumentifyOfficeLocationsSearchCriteria({ includeInactive: true, maxResultCount: MAX_PAGE_SIZE })
      )
  );

  const { t } = useLocale();

  useEffect(() => {
    if (!hours) setHours(getFilledArray(12));
  }, [hours]);

  return (
    <Form.Item noStyle shouldUpdate>
      {({ getFieldValue, setFieldsValue, getFieldsValue }) => {
        const startTime = getFieldValue([weekdayPropName, rowName, 'startTime']);
        const endTime = getFieldValue([weekdayPropName, rowName, 'endTime']);
        const meetingTypeId = getFieldValue([weekdayPropName, rowName, 'meetingTypeId']);
        const isMeetingInPerson =
          meetingTypes?.find((item) => item.id === meetingTypeId)?.enumValue === ClientService.MeetingTypeEnum.InPerson;
        return (
          <Row gutter={10}>
            <Col span={8}>
              <Row gutter={5} justify="space-between">
                <Col span={11}>
                  <Form.Item
                    name={[rowName, 'startTime']}
                    rules={[
                      setRequiredRule(t.START_TIME),
                      {
                        validator: async (_, startTime) => {
                          const startTimeInSeconds = startTime.hour() * 60 + startTime.minutes();
                          const endTimeInSeconds = endTime.hour() * 60 + endTime.minutes();

                          if (startTimeInSeconds >= endTimeInSeconds) {
                            setError(t.END_TIME_SHOULD_BE_GREATER_THAN_START_TIME);
                            return Promise.reject();
                          } else {
                            setError(undefined);
                            return Promise.resolve();
                          }
                        },
                      },
                    ]}
                    validateTrigger={['onSubmit', 'onBlur']}
                    noStyle
                  >
                    <TimePicker
                      onSelect={(startTime: moment.Moment) =>
                        setFieldsValue(
                          update(getFieldsValue(), {
                            [weekdayPropName]: {
                              [rowName]: { $merge: { startTime } },
                            },
                          })
                        )
                      }
                    />
                  </Form.Item>
                </Col>
                <Col span={2}>
                  <Form.Item style={{ textAlign: 'center' }} noStyle>
                    -
                  </Form.Item>
                </Col>
                <Col span={11}>
                  <Form.Item
                    noStyle
                    name={[rowName, 'endTime']}
                    rules={[
                      setRequiredRule(t.END_TIME),
                      {
                        validator: async (_, endTime) => {
                          const startTimeInSeconds = startTime.hour() * 60 + startTime.minutes();
                          const endTimeInSeconds = endTime.hour() * 60 + endTime.minutes();

                          if (startTimeInSeconds >= endTimeInSeconds) {
                            setError(t.END_TIME_SHOULD_BE_GREATER_THAN_START_TIME);
                            return Promise.reject();
                          } else {
                            setError(undefined);
                            return Promise.resolve();
                          }
                        },
                      },
                    ]}
                    validateTrigger={['onSubmit', 'onBlur']}
                  >
                    <TimePicker
                      onSelect={(endTime: moment.Moment) =>
                        setFieldsValue(
                          update(getFieldsValue(), {
                            [weekdayPropName]: {
                              [rowName]: { $merge: { endTime } },
                            },
                          })
                        )
                      }
                    />
                  </Form.Item>
                </Col>
                {error && (
                  <Col span={24} className="WeeklyRow__error">
                    {error}
                  </Col>
                )}
              </Row>
            </Col>

            <Col span={7}>
              <Form.Item name={[rowName, 'meetingTypeId']} rules={[setRequiredRule(t.MEETING_TYPE)]}>
                <Select
                  size="large"
                  placeholder={t.SELECT_MEETING_TYPE}
                  suffixIcon={<SelectSuffixIcon />}
                  disabled={meetingTypesLoading}
                >
                  {meetingTypes
                    ?.filter((item) => item.enumValue !== ClientService.MeetingTypeEnum.Unknown)
                    ?.map((item: MeetingTypeDto) => (
                      <Option key={item?.id}>{item?.name}</Option>
                    ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={8}>
              {isMeetingInPerson ? (
                <Form.Item noStyle shouldUpdate>
                  {({ getFieldValue }) => {
                    const officeLocationIds = getFieldValue([weekdayPropName, rowName, 'officeLocationId']);
                    const data = locations?.items?.filter(
                      (item) => item.isActive || officeLocationIds?.indexOf(item?.id) >= 0
                    );
                    return (
                      <Form.Item name={[rowName, 'officeLocationId']} rules={[setRequiredRule(t.LOCATION)]}>
                        <SelectLookupDto
                          disabled={locationsLoading}
                          placeholder={t.SELECT_OFFICE}
                          data={data}
                          showSearch
                        />
                      </Form.Item>
                    );
                  }}
                </Form.Item>
              ) : (
                <Form.Item name={[rowName, 'officeRegionId']} rules={[setRequiredRule(t.REGION)]}>
                  <SelectLookupDto disabled={regionsLoading} placeholder={t.SELECT_REGION} data={regions} showSearch />
                </Form.Item>
              )}
            </Col>
            <Col span={1}>
              <Form.Item>
                <Tooltip title={t.DELETE}>
                  <Icon iconName="Delete" onClick={remove} className="WeeklyRow__delete-icon" />
                </Tooltip>
              </Form.Item>
            </Col>
          </Row>
        );
      }}
    </Form.Item>
  );
}

export default AvailabilityItem;
