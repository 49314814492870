import LocaleProvider from './LocaleProvider';
import ModalProvider from './ModalProvider';
import { UnsavedFormProvider } from './UnsavedFormContext';
import { AttachmentsProvider } from './AttachmentsContext';
import { PDFTronProvider } from './PDFTronContext';
import AppMenuProvider from './AppMenuContext';

interface IProps {
  children: JSX.Element;
}

const ContextProviders = ({ children }: IProps): JSX.Element => {
  return (
    <AppMenuProvider>
      <UnsavedFormProvider>
        <AttachmentsProvider>
          <ModalProvider>
            <PDFTronProvider>
              <LocaleProvider>{children}</LocaleProvider>
            </PDFTronProvider>
          </ModalProvider>
        </AttachmentsProvider>
      </UnsavedFormProvider>
    </AppMenuProvider>
  );
};

export default ContextProviders;
