import { useCallback, useEffect, useState, useMemo } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Icon } from '@fluentui/react/lib/Icon';
import moment from 'moment';

import Table from '../../../components/Table/Table';
import Breadcrumb from '../../../components/Breadcrumb/Breadcrumb';
import PageSubtitle from '../../../components/PageSubtitle/PageSubtitle';
import SMSEditor from './SMSEditor/SMSEditor';

import useLocale from '../../../hooks/useLocale';
import useModal from '../../../hooks/useModal';
import { ROUTES } from '../../../constants/routes';
import { useApplicationFile } from '../../ApplicationOverviewPage';

import { ClientService } from '../../../shared/api/ClientService';
import API from '../../../utils/api';
import { DEFAULT_PAGE_SIZE, DATE_FORMAT2 } from '../../../constants/common';
import { paginationShowTotal } from '../../../utils/helpers';

import './SMSTable.scss';

const NOT_AVAILABLE = '-';

type ViewSMSResultDto = ClientService.ViewSMSResultDto;

function SMSTable(): JSX.Element {
  const { applicationFileId } = useParams<{ applicationFileId: string }>();
  const { t } = useLocale();
  const navigate = useNavigate();
  const { showModal, closeModal } = useModal();

  const [tableData, setTableData] = useState<ViewSMSResultDto[]>();
  const [tableDataLoading, setTableDataLoading] = useState<boolean>(false);

  const [totalCount, setTotalCount] = useState<number>(0);

  const { data: appFileData } = useApplicationFile();
  const isDuplicate = useMemo(() => appFileData?.isDuplicate, [appFileData]);

  const requestGetAll = useCallback(() => {
    setTableDataLoading(true);
    API.listSMSDetails(applicationFileId)
      .then((response: ViewSMSResultDto[]) => {
        setTotalCount(response?.length);
        setTableData(response);
        setTableDataLoading(false);
      })
      .catch((error) => setTableDataLoading(false));
  }, [applicationFileId]);

  useEffect(() => {
    requestGetAll();
  }, [requestGetAll]);

  const columns = [
    {
      title: t.DATE,
      dataIndex: 'sendDateTime',
      key: 'sendDateTime',
      width: '15%',
      render: (value: ViewSMSResultDto['sendDateTime']) => <>{moment(value).format(DATE_FORMAT2)}</>,
      sorter: (a: ViewSMSResultDto, b: ViewSMSResultDto) => moment(a?.sendDateTime).diff(moment(b?.sendDateTime)),
    },
    {
      title: t.TEMPLATE,
      dataIndex: 'templateName',
      key: 'templateName',
      sorter: (a: ViewSMSResultDto, b: ViewSMSResultDto) =>
        (a?.templateName || '').localeCompare(b?.templateName || ''),
      render: (value: ViewSMSResultDto['templateName']) => <>{value || NOT_AVAILABLE}</>,
    },
    {
      title: t.TO_MOBILE,
      dataIndex: 'phoneNumber',
      key: 'phoneNumber',
      width: '15%',
      sorter: (a: ViewSMSResultDto, b: ViewSMSResultDto) => (a?.phoneNumber || '').localeCompare(b?.phoneNumber || ''),
    },
    {
      title: t.MESSAGE,
      dataIndex: 'smsBody',
      key: 'smsBody',
      sorter: (a: ViewSMSResultDto, b: ViewSMSResultDto) => (a?.smsBody || '').localeCompare(b?.smsBody || ''),
    },
    {
      title: '',
      dataIndex: 'action',
      key: 'action',
      render: (value: string, record: ViewSMSResultDto) => (
        <Icon
          iconName="PageEdit"
          onClick={() => navigate(`${ROUTES.APPLICATION_OVERVIEW}/${applicationFileId}/${ROUTES.SMS}/${record?.smsId}`)}
        />
      ),
    },
  ];

  const handleShowEditor = useCallback(() => {
    if (applicationFileId) {
      showModal(
        <SMSEditor
          fileId={applicationFileId}
          onNext={() => {
            closeModal();
            requestGetAll();
          }}
          onCancel={closeModal}
        />
      );
    }
  }, [showModal, closeModal, requestGetAll, applicationFileId]);

  return (
    <div className="SMSTable">
      <Breadcrumb
        data={[
          { link: ROUTES.DASHBOARD, title: t.DASHBOARD },
          { link: `${ROUTES.DEBTOR_SEARCH}`, title: t.DEBTOR_SEARCH },
          { link: `${ROUTES.APPLICATION_OVERVIEW}/${applicationFileId}`, title: t.APPLICATION_OVERVIEW },
          { title: t.SMS_HISTORY },
        ]}
      />
      <PageSubtitle title={t.SMS_HISTORY} buttonTitle={!isDuplicate && t.SMS_NEW} onButtonClick={handleShowEditor} />
      <Table
        rowKey="smsId"
        dataSource={tableData}
        columns={columns}
        pagination={{
          position: ['bottomRight'],
          defaultPageSize: DEFAULT_PAGE_SIZE,
          showSizeChanger: true,
          total: totalCount,
          showTotal: paginationShowTotal,
        }}
        loading={tableDataLoading}
        className={tableData?.length ? 'SMSTable__table' : 'SMSTable__table-empty'}
      />
    </div>
  );
}

export default SMSTable;
