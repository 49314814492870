//panels
export const BASIC_INFO_PANEL = 'basic_information';
export const CONTACT_INFO_PANEL = 'contact_information';
export const SOURCE_PANEL = 'source';
export const NOTES_PANEL = 'notes';

export const INITIAL_CONSULTATION = 'Initial Consultation';
export const CANCELLED_STATUS = 'Cancelled';

// required fields
export const REQUIRED_BASIC_PANEL_FIELDS = ['firstName', 'lastName'];
const BASIC_REQUIRED_CONTACT_PANEL_FIELDS = ['mainPhoneTypeId', 'mainPhoneNumber', 'provinceId'];
export const REQUIRED_CONTACT_PANEL_FIELDS = [...BASIC_REQUIRED_CONTACT_PANEL_FIELDS, 'receiveEmailConsent'];
export const REQUIRED_SOURCE_PANEL_FIELDS = ['intakeSourceId', 'referralSourceTypeId', 'referralSourcesSelector'];
export const CONDITIONALLY_REQUIRED_CONTACT_PANEL_FIELDS = [...REQUIRED_CONTACT_PANEL_FIELDS, 'emailAddress'];

export const EMPTY_PHONE_MASK = '(___) ___-____';
export const PHONE_MASK = '(999) 999-9999';
