import { Tabs, TabsProps } from 'antd';
import { ReactNode } from 'react';

import styles from './TabsLeftNav.module.scss';

type ITabsPropsLimited = Omit<TabsProps, 'renderTabBar' | 'tabPosition'>;
type ITabsPropsCustom = {
  renderSubTabs: () => ReactNode;
};
type ITabsProps = ITabsPropsLimited & ITabsPropsCustom;

const TabsLeftNav = ({
  activeKey,
  renderSubTabs,
  destroyInactiveTabPane = true,
  children,
  ...props
}: ITabsProps): JSX.Element => {
  return (
    <Tabs
      {...props}
      tabPosition="left"
      activeKey={activeKey}
      renderTabBar={() => (
        <div className={styles.TabsLeft__subtab}>
          <div className={styles.TabsLeft__subtab_container}>{renderSubTabs()}</div>
        </div>
      )}
      destroyInactiveTabPane={destroyInactiveTabPane}
    >
      {children}
    </Tabs>
  );
};

export default TabsLeftNav;
