import { useCallback, useEffect, useState } from 'react';
import { Modal, Col, Row, Spin, Divider } from 'antd';

import Button from '../../../../../../../components/Button/Button';
import DataItem from '../../../../../../../components/DataItem/DataItem';
import ScheduleStateLabel from '../../components/ScheduleStateLabel';

import { convertDateToReadable } from '../../utils';
import { ClientService } from '../../../../../../../shared/api/ClientService';
import API from '../../../../../../../utils/api';
import useLocale from '../../../../../../../hooks/useLocale';
import { convertServerDateOnlyToInputDate } from '../../../../../../../utils/helpers';

import './PADScheduleHistoryModal.scss';

interface IProps {
  id?: string;
  applicationFileId: string;
  onClose: () => void;
}

const PADScheduleHistoryModal = ({ id, applicationFileId, onClose }: IProps) => {
  const [data, setData] = useState<ClientService.PADScheduleHistoryDto>();
  const [loading, setLoading] = useState<boolean>(false);
  const { t } = useLocale();

  const requestPadHistory = useCallback(async (id: string, applicationFileId: string) => {
    setLoading(true);
    const response = await API.padScheduleHistory(id, applicationFileId).catch(() => setLoading(false));
    if (response) setData(response);

    setLoading(false);
  }, []);

  useEffect(() => {
    if (id && applicationFileId) requestPadHistory(id, applicationFileId);
  }, [applicationFileId, id, requestPadHistory]);

  return (
    <Modal
      destroyOnClose
      centered
      visible
      title={t.VIEW_CHEQUEPAD_REQUEST}
      className="PADScheduleHistoryModal"
      width={750}
      footer={null}
      closable
      onCancel={onClose}
    >
      <Spin spinning={loading}>
        <div style={{ minHeight: 400 }}>
          <Row gutter={24} className="PADScheduleHistoryModal__data-row">
            <DataItem
              colSpan={12}
              label={t.DUE_DATE}
              value={convertDateToReadable(convertServerDateOnlyToInputDate(data?.dueDate))}
            />
            <DataItem colSpan={12} label={t.TYPE} value={data?.type} />
          </Row>

          <Row gutter={24} className="PADScheduleHistoryModal__data-row">
            <DataItem colSpan={12} label={t.NUMBER} value={data?.no} />
            <DataItem colSpan={12} label={t.AGR_NUMBER} value={data?.agrNo} />
          </Row>

          <Row gutter={24} className="PADScheduleHistoryModal__data-row">
            <DataItem colSpan={12} label={t.AMOUNT} value={data?.amount} />
            <DataItem colSpan={12} label={t.DEPOSITED} value="0.00" />
          </Row>

          <Row gutter={24} className="PADScheduleHistoryModal__data-row">
            <DataItem colSpan={12} label={t.STATUS} value={data?.status} />
          </Row>

          <Divider dashed />

          <Row gutter={24} className="PADScheduleHistoryModal__data-row">
            <DataItem colSpan={12} label={t.STATE} value={{ value: <ScheduleStateLabel state={data?.state} /> }} />
            <DataItem colSpan={12} label={t.HOLD_UNTIL} value={convertDateToReadable(data?.holdUntil)} />
          </Row>

          <Divider dashed />

          <Row gutter={24} className="PADScheduleHistoryModal__data-row">
            <DataItem colSpan={12} label={t.HOLD_REASON} value={data?.holdReason} />
          </Row>
        </div>

        <Row justify="end">
          <Col>
            <Button kind="primary" onClick={onClose}>
              {t.CLOSE}
            </Button>
          </Col>
        </Row>
      </Spin>
    </Modal>
  );
};

export default PADScheduleHistoryModal;
