import { DatePicker, Form, Row, Col, Checkbox, Input } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';

import { DATE_FORMAT2, DATE_FORMAT_PLACEHOLDER } from '../../../../constants/common';
import { antdUtil } from '../../../../utils/antdUtil';
import useLocale from '../../../../hooks/useLocale';
import ChangeTrackerFormItem from '../../../../components/ChangeTrackerFormItem';

import './ApplicationFormTab.scss';

interface IProps {
  name: string;
  isLocked?: boolean;
}

const PersonalInformationEIPeriod = ({ name, isLocked = false }: IProps): JSX.Element => {
  const { t } = useLocale();

  return (
    <Form.List name={name}>
      {(employmentInsurancePeriods, { add, remove }) => (
        <>
          {employmentInsurancePeriods?.map((row, index) => (
            <div key={`insurance-periods-${index}`}>
              <Form.Item name={[row.name, 'id']} hidden>
                <Input disabled={isLocked} />
              </Form.Item>
              <Row gutter={20} align="middle">
                <Col span={8}>
                  <ChangeTrackerFormItem
                    name={[row.name, 'startDate']}
                    label={t.START_DATE}
                    rules={[{ required: true }]}
                    listName={name}
                  >
                    <DatePicker
                      size="large"
                      disabledDate={antdUtil.futureDates}
                      format={DATE_FORMAT2}
                      placeholder={DATE_FORMAT_PLACEHOLDER}
                      disabled={isLocked}
                    />
                  </ChangeTrackerFormItem>
                </Col>
                <Col span={8}>
                  <ChangeTrackerFormItem
                    noStyle
                    shouldUpdate={(prevValues, currentValues) =>
                      prevValues[name][row.name] !== currentValues[name][row.name]
                    }
                  >
                    {({ getFieldsValue }) => (
                      <ChangeTrackerFormItem
                        listName={name}
                        name={[row.name, 'endDate']}
                        label={
                          <Row justify="space-between" className="ApplicationFormTab__label-extended">
                            <Col
                              flex={1}
                              className={
                                getFieldsValue()?.[name]?.[row.name]?.isCurrentPeriod
                                  ? ''
                                  : 'ApplicationFormTab__label-required'
                              }
                            >
                              {t.END_DATE}
                            </Col>
                            <Col flex={1}>
                              <ChangeTrackerFormItem
                                name={[row.name, 'isCurrentPeriod']}
                                valuePropName="checked"
                                className="ApplicationFormTab__form-item-no-margin"
                                listName={name}
                              >
                                <Checkbox className="ApplicationFormTab__checkbox" disabled={isLocked}>
                                  {t.CURRENTLY_RECEIVING_EI}
                                </Checkbox>
                              </ChangeTrackerFormItem>
                            </Col>
                          </Row>
                        }
                        className="ApplicationFormTab__input-label-extended ApplicationFormTab__label-nomark"
                        rules={[
                          {
                            required: !getFieldsValue()?.[name]?.[row.name]?.isCurrentPeriod,
                            message: t.REQUIRED_FIELD,
                          },
                        ]}
                      >
                        <DatePicker
                          size="large"
                          disabledDate={antdUtil.futureDates}
                          format={DATE_FORMAT2}
                          disabled={getFieldsValue()?.[name]?.[row.name]?.isCurrentPeriod || isLocked}
                          placeholder={DATE_FORMAT_PLACEHOLDER}
                        />
                      </ChangeTrackerFormItem>
                    )}
                  </ChangeTrackerFormItem>
                </Col>
                {!isLocked && (
                  <Col span={8} style={{ textAlign: 'right' }}>
                    <DeleteOutlined
                      className="ApplicationFormTab__input-action-icon"
                      onClick={() => remove(row.name)}
                    />
                  </Col>
                )}
              </Row>
            </div>
          ))}
          {!isLocked && (
            <Row gutter={20}>
              <Col span={8}>
                <Form.Item noStyle shouldUpdate>
                  {({ getFieldError, getFieldValue }) => (
                    <div
                      onClick={() => add()}
                      className="ApplicationFormTab__link-left"
                      style={{
                        marginTop:
                          getFieldError([name, getFieldValue(name)?.length - 1, 'startDate'])?.length ||
                          getFieldError([name, getFieldValue(name)?.length - 1, 'endDate'])?.length
                            ? 12
                            : -12,
                      }}
                    >
                      {t.ADD_EI_PERIOD}
                    </div>
                  )}
                </Form.Item>
              </Col>
            </Row>
          )}
        </>
      )}
    </Form.List>
  );
};

export default PersonalInformationEIPeriod;
