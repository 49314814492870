import { useCallback } from 'react';
import useModal from '../../../../hooks/useModal';
import { ClientService } from '../../../../shared/api/ClientService';
// import useLocale from '../../../../hooks/useLocale';
import AlertModal from './AlertModal';

interface IGenericObject {
  [key: string]: any;
}

type SetData = (alertFormValues: IGenericObject) => unknown;

function useAlertAsAsync() {
  //   const { t } = useLocale();
  const { showModal } = useModal();

  const handleAlert = useCallback(
    (setResponseDataForSave?: SetData) => {
      let setDataFunc: SetData = (alertFormValues) => {
        const alertBody: IGenericObject = {};
        const targetKey = alertFormValues.alertTarget === 'users' ? 'agentIds' : 'teamIds';
        alertBody[targetKey] = alertFormValues.sendAlert;
        alertBody.note = alertFormValues.notes;
        return alertBody as ClientService.IAlertCreateDto;
      };
      if (setResponseDataForSave) setDataFunc = setResponseDataForSave;

      return new Promise((resolve, reject) => {
        showModal(
          <AlertModal
            onCancel={() => resolve(false)}
            setResponseDataForSave={setDataFunc}
            onSave={(alertBody) => resolve(alertBody)}
          />
        );
      });
    },
    [showModal]
  );

  return {
    handleAlert,
  };
}

export default useAlertAsAsync;
