import { ReactNode } from 'react';
import Icon, { IconProps } from '../../Icon/Icon';

import styles from './Label.module.scss';

export interface LabelProps {
  value: string | ReactNode;
  icon?: IconProps;
  extraContent?: ReactNode;
  required?: boolean;
  className?: string;
}

const Label: React.FC<LabelProps> = ({ value, icon, className, required, extraContent }): JSX.Element => {
  return (
    <div className={`${styles.Custom_Label} ${required ? 'required' : ''} ${className ? className : ''}`}>
      <div className="label-text-wrapper">
        <div className="label-text">{value}</div>
        {icon && (
          <div className="label-text-icon">
            <Icon {...icon} />
          </div>
        )}
      </div>
      {extraContent && <div className="extra-content">{extraContent}</div>}
    </div>
  );
};

export default Label;
