import { CSSProperties, useCallback, useRef, useEffect, useState, useLayoutEffect } from 'react';
import { Row, Col, Typography } from 'antd';

import { convertNegativeToReadable } from '../utils';
import useWidth from '../../../../hooks/useWidth';

import '../FileAnalysis.scss';

export type IType = 'title' | 'subtitle' | 'item' | 'subitem' | 'subtotal' | 'total';

export interface IScrollableRow {
  rowNames?: Array<JSX.Element | string>;
  totalValue?: number | string;
  listValues?: Array<any>;
  style?: CSSProperties;
  type?: IType;
  rowKey: string;
}

interface IStyle {
  containerStyle?: CSSProperties;
  nameStyle?: CSSProperties;
  cellStyle?: CSSProperties;
}

const ScrollableRow = ({ rowNames, totalValue, listValues, style, type, rowKey }: IScrollableRow) => {
  const ref = useRef<HTMLDivElement>(null);
  const width = useWidth(ref);
  const [scrollableColWidth, setScrollableColWidth] = useState<number>();

  const { Text } = Typography;
  const setTypeStyle = useCallback((): IStyle => {
    switch (type) {
      case 'title':
        return {
          containerStyle: {
            fontWeight: 700,
            borderBottom: 0,
          },
        };
      case 'subtitle':
        return {
          containerStyle: {
            fontWeight: 700,
            borderBottom: 0,
          },
        };
      case 'item':
        return {
          containerStyle: {
            borderBottom: 0,
            paddingTop: 6,
            paddingBottom: 6,
          },
        };
      case 'subitem':
        return {
          containerStyle: {
            borderBottom: 0,
            paddingTop: 6,
            paddingBottom: 6,
          },
          nameStyle: {
            textIndent: 8,
          },
        };
      case 'subtotal':
        return {};
      case 'total':
        return {
          containerStyle: {
            borderTop: '1px solid black',
          },
          cellStyle: {
            fontWeight: 700,
          },
        };
      default:
        return {};
    }
  }, [type]);

  const setTypeText = useCallback((value?: number | string) => {
    switch (typeof value) {
      case 'string':
        return value;
      case 'number':
        return value ? convertNegativeToReadable(value as number, 0) : '-';
      default:
        return '-';
    }
  }, []);

  useEffect(() => {
    if (width) setScrollableColWidth(width);
  }, [width]);

  useLayoutEffect(() => {
    setTimeout(() => setScrollableColWidth(ref?.current?.getBoundingClientRect().width), 100);
  });

  if (!listValues || !Boolean(listValues?.length)) return <></>;

  return (
    <Row style={{ ...setTypeStyle()?.containerStyle, ...style }} align="top">
      <Col
        span={listValues?.length > 1 ? 16 : 20}
        className={Boolean(listValues?.length) ? 'FileAnalysis__scrollable--bordered-column' : ''}
      >
        {rowNames?.map((rowName, index) => (
          <Row key={`${rowKey}-rowName-${index}`} className="FileAnalysis__scrollable--row" justify="space-between">
            <Col span={20} className="FileAnalysis__scrollable--name-column">
              {rowName}
            </Col>
            <Col span={4} className="FileAnalysis__scrollable--total-column">
              {index === 0 ? 'Total' : index === rowNames?.length - 1 ? setTypeText(totalValue) : ' '}
            </Col>
          </Row>
        ))}
      </Col>
      <Col span={listValues?.length > 1 ? 8 : 4} className="FileAnalysis__scrollable--column" ref={ref}>
        <div style={{ overflowX: 'auto' }}>
          <div className="FileAnalysis__scrollable--content">
            {listValues?.map((listValue, valueIndex) => {
              type Key = keyof typeof listValue;
              const keys = Object.keys(listValue) as Key[];
              return (
                <div key={`${rowKey}-valueIndex-${valueIndex}`}>
                  {keys?.map((key, keyIndex) => (
                    <Text
                      key={`${rowKey}-keyIndex-${keyIndex}`}
                      className="FileAnalysis__scrollable--row"
                      ellipsis={true}
                      style={{
                        width: (scrollableColWidth || 100) / (listValues?.length > 1 ? 2 : 1),
                      }}
                    >
                      {setTypeText(listValue?.[key])}
                    </Text>
                  ))}
                </div>
              );
            })}
          </div>
        </div>
      </Col>
    </Row>
  );
};

export default ScrollableRow;
