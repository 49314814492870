import { useRef, useEffect, useCallback, useState } from 'react';

import useLocale from '../../hooks/useLocale';
import { IMarker, LocationDto } from './types';

import './GoogleMaps.scss';

export interface IProps {
  data?: LocationDto[];
  target?: LocationDto;
  center?: google.maps.LatLngLiteral;
  zoom?: number;
  setSelectedMarkerId?: (markerId?: string) => void;
}

const MapComponent = ({ center, zoom, data, target, setSelectedMarkerId }: IProps): JSX.Element => {
  const ref = useRef<HTMLDivElement>(null);
  const { t } = useLocale();

  const [markers, setMarkers] = useState<IMarker[]>();
  const [map, setMap] = useState<google.maps.Map>();

  const convertLocationToMarker = useCallback(
    (location: LocationDto) => ({
      id: location?.id,
      name: location?.name,
      address: `${location.streetName} ${location?.streetNumber} ${location?.city} ${location?.postalCode}`,
      position: new google.maps.LatLng(location?.latitude as number, location?.longitude as number),
      marker: new google.maps.Marker(),
      hours: location?.hours ? JSON.parse(location?.hours)?.Hours : undefined,
    }),
    []
  );

  const createInfoMarkerContent = useCallback(
    (marker: IMarker) => {
      const hoursArray = marker?.hours?.split(';');
      let hours = '';

      if (hoursArray && Array.isArray(hoursArray) && hoursArray?.length > 0) {
        hours += `<div class="GoogleMaps__marker--working-hours">${t.WORKING_HOURS}</div>`;
        hoursArray?.forEach((item: string) => (hours += `<div>${item?.replace('=', ' ')}</div>`));
      }

      return `<div>\
        <div class="GoogleMaps__marker">${marker?.name}</div>\
        <span>${marker?.address}</span>\
        ${hours}
      </div>`;
    },
    [t.WORKING_HOURS]
  );

  useEffect(() => {
    if (data) {
      const markers = data.filter((m) => m.longitude && m.latitude).map(convertLocationToMarker);

      setMarkers(markers);
    }
  }, [convertLocationToMarker, data]);

  useEffect(() => {
    if (map && markers && markers.length > 0) {
      const infowindow = new google.maps.InfoWindow();

      ['click'].forEach((eventName) => google.maps.event.clearListeners(map, eventName));

      markers?.forEach((item) => {
        const { position, id, marker } = item;
        marker.setOptions({ position, map });

        google.maps.event.addListener(marker, 'click', () => {
          if (setSelectedMarkerId && id) setSelectedMarkerId(id);

          infowindow.setContent(createInfoMarkerContent(item));
          infowindow.open({
            anchor: marker,
            map,
            shouldFocus: false,
          });
        });
      });
    }
  }, [createInfoMarkerContent, map, markers, setSelectedMarkerId]);

  useEffect(() => {
    if (ref?.current && !map) {
      setMap(new window.google.maps.Map(ref.current, {}));
    }
  }, [ref, map]);

  useEffect(() => {
    if (ref?.current && map) {
      console.log('options set');
      map.setOptions({ center, zoom: Boolean(target) ? 12 : zoom });
    }
  }, [center, zoom, map, ref, target]);

  useEffect(() => {
    return () => {
      markers?.forEach((marker) => {
        if (marker?.marker) {
          marker.marker.setMap(null);
        }
      });
    };
  }, [markers]);

  return <div ref={ref} id="map" style={{ width: 700 - 24 * 2, height: 500 }} />;
};

export default MapComponent;
