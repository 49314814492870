import { useState, useCallback, useEffect } from 'react';
import AddNoteIcon from '../QuickActionsToolbar/AddNoteIcon';
import ActionItem from './ActionItem';
import EmailEditor from '../../pages/ApplicationOverviewPage/EmailContent/EmailEditor/EmailEditor';
import SMSEditor from '../../pages/ApplicationOverviewPage/SMSContent/SMSEditor/SMSEditor';
import AddNoteModal from '../QuickActionsToolbar/AddNoteModal';
import TaskEditor from '../../pages/ApplicationOverviewPage/TasksContent/TaskEditor/TaskEditor';
import { ClientService } from '../../shared/api/ClientService';
import API from '../../utils/api';
import eventBus from '../../utils/eventBus';
import { NOTE_ADDED_EVENT, TASK_ADDED_EVENT } from '../../constants/eventBus';
import useModal from '../../hooks/useModal';

import styles from './SimplifiedActionsToolbar.module.scss';
import useLocale from '../../hooks/useLocale';

interface SimplifiedActionsToolbarProps {
  fileId: string;
  claimFileId?: string;
}

const SimplifiedActionsToolbar = ({ fileId, claimFileId }: SimplifiedActionsToolbarProps) => {
  const { showModal, closeModal } = useModal();

  const [appFile, setAppFile] = useState<ClientService.ApplicationFileDto>();
  const { t } = useLocale();
  const requestAppFileData = useCallback(async (applicationFileId: string) => {
    const response = await API.applicationFile2(applicationFileId);
    if (response) setAppFile(response);
  }, []);

  useEffect(() => {
    requestAppFileData(fileId);
  }, [requestAppFileData, fileId]);

  const handleSendNewEmailAction = useCallback(() => {
    showModal(
      <EmailEditor onNext={closeModal} onCancel={closeModal} fileId={fileId as string} claimFileId={claimFileId} />
    );
  }, [showModal, closeModal, fileId, claimFileId]);

  const handleSendNewSMSAction = useCallback(() => {
    showModal(<SMSEditor fileId={fileId as string} onNext={closeModal} onCancel={closeModal} />);
  }, [showModal, closeModal, fileId]);

  const handleAddNoteAction = useCallback(() => {
    showModal(
      <AddNoteModal
        onCancel={closeModal}
        applicationFileId={fileId as string}
        onOk={() => {
          eventBus.dispatch(NOTE_ADDED_EVENT);
          closeModal();
        }}
      />
    );
  }, [showModal, closeModal, fileId]);

  const handleAddTask = useCallback(() => {
    showModal(
      <TaskEditor
        onNext={() => {
          closeModal();
          eventBus.dispatch(TASK_ADDED_EVENT);
        }}
        onCancel={closeModal}
        fileId={fileId as string}
        fileStageId={appFile?.fileStage?.id}
      />
    );
  }, [showModal, closeModal, fileId, appFile?.fileStage?.id]);

  return (
    <div className={styles.SimplifiedActionsToolbar}>
      <ActionItem title={t.NEW_EMAIL} iconName="Mail" onClick={handleSendNewEmailAction} />
      <ActionItem title={t.NEW_SMS} iconName="CannedChat" onClick={handleSendNewSMSAction} />
      <ActionItem title={t.NEW_NOTE} icon={<AddNoteIcon />} onClick={handleAddNoteAction} />
      <ActionItem title={t.NEW_TASK} iconName="CheckList" onClick={handleAddTask} />
    </div>
  );
};

export default SimplifiedActionsToolbar;
