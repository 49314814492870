import { useCallback } from 'react';
import { Modal } from 'antd';

import Button from '../../components/Button/Button';
import useLocale from '../../hooks/useLocale';

import './FilingTypeConfirmationModal.scss';

interface IProps {
  title?: string;
  message?: string;
  okText?: string;
  cancelText?: string;
  onOk?: () => void;
  onCancel?: () => void;
}

function FilingTypeConfirmationModal({ title, message, onOk, onCancel, cancelText, okText }: IProps): JSX.Element {
  const { t } = useLocale();
  const handleOk = useCallback(() => {
    if (onOk) onOk();
  }, [onOk]);

  const handleCancel = useCallback(() => {
    if (onCancel) onCancel();
  }, [onCancel]);

  return (
    <Modal
      title={title}
      visible
      footer={[
        <Button key="cancel" kind="cancel" padding="large" onClick={handleCancel} style={{ marginRight: 6 }}>
          {cancelText || t.CANCEL}
        </Button>,
        <Button key="ok" padding="large" kind="primary" onClick={handleOk}>
          {okText || t.CONFIRM}
        </Button>,
      ]}
      closable
      onCancel={handleCancel}
      className="ActionConfirmationModal"
    >
      <p>{message}</p>
    </Modal>
  );
}

export default FilingTypeConfirmationModal;
