export const debounce = (callback: (...args: any[]) => void | any, wait: number) => {
  let timeout: any;

  return function (...args: any[]) {
    const later = () => {
      timeout = null;
      callback(...args);
    };
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
  };
};
