import { Tooltip, TooltipProps } from 'antd';
import { Icon } from '@fluentui/react/lib/Icon';

import styles from './ActionItem.module.scss';

interface IActionItemProps {
  title?: string;
  iconName?: string;
  icon?: JSX.Element;
  onClick?: () => void;
  style?: React.CSSProperties;
}

const ActionItem = ({ title, iconName, icon, onClick, style }: IActionItemProps) => {
  const renderIcon = () => {
    return <div className={styles.icon}>{icon || <Icon iconName={iconName} style={style} />}</div>;
  };
  return (
    <div className={styles.ActionItem} onClick={onClick}>
      {title ? (
        <Tooltip title={title} placement="top" arrowPointAtCenter>
          {renderIcon()}
        </Tooltip>
      ) : (
        renderIcon()
      )}
    </div>
  );
};

export default ActionItem;
