import { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Form, Col, Row, Spin } from 'antd';

import IncomeExpense from '../../../ApplicationOverviewPage/ApplicationOverviewContent/ApplicationForm/IncomeExpense';
import Divider from '../../../../components/Divider/Divider';

import { ClientService } from '../../../../shared/api/ClientService';
import { currencyFormatter } from '../utils';
import API from '../../../../utils/api';
import eventBus from '../../../../utils/eventBus';
import useLocale from '../../../../hooks/useLocale';

import '../FileAnalysis.scss';
import styles from '../../../../styles/style.module.scss';

function SurplusIncomeInformation({
  disabled = false,
  applicationFile,
}: {
  disabled?: boolean;
  applicationFile?: ClientService.IApplicationFileDto;
}): JSX.Element {
  const { applicationFileId } = useParams<{ applicationFileId: string }>();
  const { t } = useLocale();

  const [surplusIncome, setSurplusIncome] = useState<ClientService.FileAnalysisSurplusIncomeDto>();
  const [surplusIncomeLoading, setSurplusIncomeLoading] = useState<boolean>(false);

  const requestFileAnalysisSurplusIncomeDetails = useCallback(async () => {
    setSurplusIncomeLoading(true);
    const response = await API.getFileAnalysisSurplusIncomeDetails(applicationFileId).catch(() =>
      setSurplusIncomeLoading(false)
    );
    if (response) {
      setSurplusIncome(response);
      eventBus.dispatch('dataEntryFormValuesChanged');
    }
    setSurplusIncomeLoading(false);
  }, [applicationFileId]);

  useEffect(() => {
    requestFileAnalysisSurplusIncomeDetails();
  }, [requestFileAnalysisSurplusIncomeDetails]);

  return (
    <Spin spinning={surplusIncomeLoading}>
      <Form layout="vertical" className="FileAnalysis__form">
        <Row className="FileAnalysis__form--incomes-expenses-list" gutter={20}>
          <Col span={12}>
            <Form.Item label={t.NET_INCOME}>
              <div
                className="FileAnalysis__form--total-incomes"
                style={{
                  color: disabled ? styles.colorSecondary : styles.colorGreen,
                }}
              >
                $
                {currencyFormatter(
                  (surplusIncome?.monthlyNetIncomeApplicant || 0) +
                    (surplusIncome?.monthlyNetIncomeSpouse || 0) +
                    (surplusIncome?.monthlyNetIncomeOther || 0),
                  0
                )}
              </div>
            </Form.Item>
            <Divider className="FileAnalysis__form--total-divider" />
            <Form.Item label={t.APPLICANT}>${currencyFormatter(surplusIncome?.monthlyNetIncomeApplicant, 0)}</Form.Item>
            <Form.Item label={t.SPOUSE}>${currencyFormatter(surplusIncome?.monthlyNetIncomeSpouse, 0)}</Form.Item>
            <Form.Item label={t.OTHER}>${currencyFormatter(surplusIncome?.monthlyNetIncomeOther, 0)}</Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label={t.NON_DISCRETIONARY_EXPENSES}>
              <div
                className="FileAnalysis__form--total-expenses"
                style={{
                  color: disabled ? styles.colorSecondary : styles.colorDanger,
                }}
              >
                $
                {currencyFormatter(
                  (surplusIncome?.monthlyNonDiscretionaryExpensesApplicant || 0) +
                    (surplusIncome?.monthlyNonDiscretionaryExpensesSpouse || 0) +
                    (surplusIncome?.monthlyNonDiscretionaryExpensesOther || 0),
                  0
                )}
              </div>
            </Form.Item>
            <Divider className="FileAnalysis__form--total-divider" />
            <Form.Item label={t.APPLICANT}>
              ${currencyFormatter(surplusIncome?.monthlyNonDiscretionaryExpensesApplicant, 0)}
            </Form.Item>
            <Form.Item label={t.SPOUSE}>
              ${currencyFormatter(surplusIncome?.monthlyNonDiscretionaryExpensesSpouse, 0)}
            </Form.Item>
            <Form.Item label={t.OTHER}>
              ${currencyFormatter(surplusIncome?.monthlyNonDiscretionaryExpensesOther, 0)}
            </Form.Item>
          </Col>
        </Row>
        <IncomeExpense
          fileAnalysisView
          onUpdate={requestFileAnalysisSurplusIncomeDetails}
          applicationFile={applicationFile}
        />
      </Form>
    </Spin>
  );
}

export default SurplusIncomeInformation;
