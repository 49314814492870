import { useCallback } from 'react';
import { Form, Col, Row, Checkbox } from 'antd';
import { useQuery } from 'react-query';

import Button from '../../components/Button/Button';
import SelectLookupDto from '../../components/SelectLookupDto/SelectLookupDto';

import {
  OFFICE_LOCATIONS_QUERY,
  CLIENT_STATUSES_QUERY,
  FILE_STAGES_QUERY,
  FILING_TYPES_QUERY,
  AGENTS_QUERY,
  USE_QUERY_OPTIONS,
  EXCLUDE_INACTIVE,
} from '../../constants/reactQuery';
import { IDebtorSearchCriteria } from './types';
import useLocale from '../../hooks/useLocale';
import { argumentifyOfficeLocationsSearchCriteria } from './utils';
import { ALL_ID } from './constants';
import API from '../../utils/api';

import './DebtorSearchPage.scss';
import { ClientService } from '../../shared/api/ClientService';
import { MAX_PAGE_SIZE } from '../../constants/common';

type IFormDropdowns = Pick<
  IDebtorSearchCriteria,
  'clientStatus' | 'fileStage' | 'filingType' | 'trustee' | 'serviceLocation' | 'estateAdmin'
>;

type IFormCheckboxes = Pick<IDebtorSearchCriteria, 'isEmailChecked' | 'isEstateNumberChecked'>;

interface IForm extends IFormDropdowns, IFormCheckboxes {}

interface IProps {
  isPopupView: boolean;
  criteria?: IDebtorSearchCriteria;
  onChange: (criteria: IDebtorSearchCriteria) => void;
}

const Filters = ({ isPopupView, criteria, onChange }: IProps) => {
  const { t } = useLocale();
  const [form] = Form.useForm<IForm>();

  const LOOKUP_ITEM_ALL = { name: t.ALL, id: ALL_ID } as ClientService.LookupDto;

  const { data: fileStages, isFetching: fileStagesLoading } = useQuery(
    [FILE_STAGES_QUERY],
    () => API.listFileStages(),
    USE_QUERY_OPTIONS
  );

  const { data: filingTypes, isFetching: filingTypesLoading } = useQuery(
    [FILING_TYPES_QUERY],
    () => API.listFilingTypes(),
    USE_QUERY_OPTIONS
  );

  const { data: agents, isFetching: agentsLoading } = useQuery(
    [AGENTS_QUERY],
    () => API.listAgents(),
    USE_QUERY_OPTIONS
  );

  const { data: filingLocations, isFetching: filingLocationsLoading } = useQuery(
    [OFFICE_LOCATIONS_QUERY, EXCLUDE_INACTIVE, MAX_PAGE_SIZE],
    () =>
      API.officeLocationsGET(
        ...argumentifyOfficeLocationsSearchCriteria({ includeInactive: false, maxResultCount: MAX_PAGE_SIZE })
      ),
    USE_QUERY_OPTIONS
  );

  const { data: clientStatuses, isFetching: clientStatusesLoading } = useQuery(
    [CLIENT_STATUSES_QUERY],
    () => API.listClientStatuses(),
    USE_QUERY_OPTIONS
  );

  const handleSelectWithAll = useCallback(
    (fieldName: string) => (value: string) => {
      form.setFieldsValue({
        [fieldName]:
          value === ALL_ID ? [ALL_ID] : form.getFieldValue(fieldName)?.filter((item: string) => item !== ALL_ID),
      });
    },
    [form]
  );

  const excludeAllOption = useCallback((stringArray?: string[]) => stringArray?.filter((item) => item !== ALL_ID), []);

  const setInitialValues = useCallback((stringArray?: string[]) => (stringArray?.length ? stringArray : [ALL_ID]), []);

  return (
    <Form
      layout="vertical"
      form={form}
      onFinish={(values) => {
        return onChange({
          ...values,
          clientStatus: excludeAllOption(values?.clientStatus),
          fileStage: excludeAllOption(values?.fileStage),
          filingType: excludeAllOption(values?.filingType),
          serviceLocation: excludeAllOption(values?.serviceLocation),
          trustee: excludeAllOption(values?.trustee),
          estateAdmin: excludeAllOption(values?.estateAdmin),
        });
      }}
      initialValues={{
        ...criteria,
        clientStatus: setInitialValues(criteria?.clientStatus),
        fileStage: setInitialValues(criteria?.fileStage),
        filingType: setInitialValues(criteria?.filingType),
        serviceLocation: setInitialValues(criteria?.serviceLocation),
        trustee: setInitialValues(criteria?.trustee),
        estateAdmin: setInitialValues(criteria?.estateAdmin),
      }}
      className="DebtorSearchPage__filters"
    >
      <Row gutter={20}>
        <Col span={isPopupView ? 24 : 12}>
          <Form.Item
            name="filingType"
            label={
              <Row justify="space-between" align="bottom" style={{ flex: 1 }}>
                <Col>{t.DEBTOR_SEARCH_FILING_TYPE}</Col>
                <Col>
                  {isPopupView && (
                    <Button
                      kind="link"
                      htmlType="button"
                      onClick={(e) => {
                        form.setFieldsValue({
                          clientStatus: [ALL_ID],
                          fileStage: [ALL_ID],
                          filingType: [ALL_ID],
                          trustee: [ALL_ID],
                          serviceLocation: [ALL_ID],
                          estateAdmin: [ALL_ID],
                          isEmailChecked: undefined,
                          isEstateNumberChecked: undefined,
                        });
                      }}
                    >
                      {t.CLEAR_ALL}
                    </Button>
                  )}
                </Col>
              </Row>
            }
          >
            <SelectLookupDto
              disabled={filingTypesLoading}
              mode="multiple"
              onSelect={handleSelectWithAll('filingType')}
              data={[...(filingTypes || []), LOOKUP_ITEM_ALL]}
              showSearch
            />
          </Form.Item>
        </Col>
        <Col span={isPopupView ? 24 : 12}>
          <Form.Item label={t.DEBTOR_SEARCH_SERVICE_LOCATION} name="serviceLocation">
            <SelectLookupDto
              disabled={filingLocationsLoading}
              mode="multiple"
              onSelect={handleSelectWithAll('serviceLocation')}
              data={[...(filingLocations?.items || []), LOOKUP_ITEM_ALL]}
              showSearch
            />
          </Form.Item>
        </Col>
        <Col span={isPopupView ? 24 : 12}>
          <Form.Item label={t.DEBTOR_SEARCH_TRUSTEE} name="trustee">
            <SelectLookupDto
              disabled={agentsLoading}
              mode="multiple"
              onSelect={handleSelectWithAll('trustee')}
              data={[...(agents || []), LOOKUP_ITEM_ALL]}
              showSearch
            />
          </Form.Item>
        </Col>
        <Col span={isPopupView ? 24 : 12}>
          <Form.Item label={t.ESTATE_ADMINISTRATOR} name="estateAdmin">
            <SelectLookupDto
              disabled={agentsLoading}
              mode="multiple"
              onSelect={handleSelectWithAll('estateAdmin')}
              data={[...(agents || []), LOOKUP_ITEM_ALL]}
              showSearch
            />
          </Form.Item>
        </Col>
        <Col span={isPopupView ? 24 : 12}>
          <Form.Item label={t.DEBTOR_SEARCH_STATUS} name="clientStatus">
            <SelectLookupDto
              disabled={clientStatusesLoading}
              mode="multiple"
              onSelect={handleSelectWithAll('clientStatus')}
              data={[...(clientStatuses || []), LOOKUP_ITEM_ALL]}
              showSearch
            />
          </Form.Item>
        </Col>
        <Col span={isPopupView ? 24 : 12}>
          <Form.Item label={t.DEBTOR_SEARCH_STAGE} name="fileStage">
            <SelectLookupDto
              disabled={fileStagesLoading}
              mode="multiple"
              onSelect={handleSelectWithAll('fileStage')}
              data={[...(fileStages || []), LOOKUP_ITEM_ALL]}
              showSearch
            />
          </Form.Item>
        </Col>
      </Row>

      <Row align="middle" justify={isPopupView ? 'center' : 'space-between'} wrap>
        <Col span={isPopupView ? 24 : 8}>
          <Row gutter={20}>
            <Col span={12}>
              <Form.Item name="isEmailChecked" valuePropName="checked">
                <Checkbox className="DebtorSearchPage__checkbox">{t.HAS_EMAIL}</Checkbox>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name="isEstateNumberChecked" valuePropName="checked">
                <Checkbox className="DebtorSearchPage__checkbox">{t.HAS_ESTATE}</Checkbox>
              </Form.Item>
            </Col>
          </Row>
        </Col>
        <Col>
          <Form.Item>
            <Button htmlType="submit" className="DebtorSearchPage__filters--button">
              {t.APPLY}
            </Button>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};

export default Filters;
