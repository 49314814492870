import React, { useCallback, useEffect, useState } from 'react';
import { Row, Col, Select, Form, Divider } from 'antd';
import { Icon } from '@fluentui/react';
import moment from 'moment';

import Table from '../../../components/Table/Table';
import Button from '../../../components/Button/Button';
import TaskActionEditor from '../../ApplicationOverviewPage/TasksContent/TaskActionEditor/TaskActionEditor';
import SelectSuffixIcon from '../../../components/SelectSuffixIcon/SelectSuffixIcon';

import useLocale from '../../../hooks/useLocale';
import useModal from '../../../hooks/useModal';
import genericMessage from '../../../utils/genericMessage';

import { AuthorizeService } from '../../../components/Auth/AuthorizeService';
import { DEFAULT_PAGE_SIZE, DATE_AND_TIME_FORMAT2, SORTING_ASC, SORTING_DESC } from '../../../constants/common';
import { paginationShowTotal } from '../../../utils/helpers';
import { argumentifyTaskActionSearchCriteria } from '../utils';
import { ITaskActionSearchCriteria } from '../types';
import eventBus from '../../../utils/eventBus';
import { TASK_ACTION_UPDATE_EVENT } from '../../../constants/eventBus';
import { ClientService } from '../../../shared/api/ClientService';
import API from '../../../utils/api';

import './ActionTable.scss';
import { useColumnSearchProps } from '../../../utils/Table/useColumnSearchProps';

interface IProps {
  teamTypes?: ClientService.TeamTypeEnum[];
}

interface IForm {
  appTaskActionStatusEnum?: string;
}

const ActionTable = ({ teamTypes }: IProps): JSX.Element => {
  const { t } = useLocale();
  const { Option } = Select;
  const { showModal, closeModal } = useModal();
  const [form] = Form.useForm<IForm>();
  const { getColumnSearchProps } = useColumnSearchProps<ClientService.AppTaskActionDto>();

  const user = AuthorizeService.getCurrentUserInfo();

  const [criteria, setCriteria] = useState<ITaskActionSearchCriteria>({
    maxResultCount: 10,
    skipCount: 0,
    assignedAgentId: user?.profile?.sub,
    calledFromDashboard: true,
    teamTypes,
  });
  const [dataSource, setDataSource] = useState<ClientService.AppTaskActionDto[]>();
  const [tableDataLoading, setTableDataLoading] = useState<boolean>(false);
  const [totalCount, setTotalCount] = useState<number>(0);
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>();

  const requestAppTasks = useCallback(async (criteria) => {
    setTableDataLoading(true);
    const response = await API.taskActionsGET(...argumentifyTaskActionSearchCriteria(criteria)).catch((error) => {
      setTableDataLoading(false);
      genericMessage.error(error);
    });

    setTableDataLoading(false);
    if (response) {
      setDataSource(response?.items);
      setTotalCount(response?.totalCount || 0);
    }
  }, []);

  const handleTableSubjectSearchSubmit = useCallback(
    (searchValue?: string) => {
      setCriteria({
        ...criteria,
        filterSubjectText: searchValue,
      });
    },
    [setCriteria, criteria]
  );

  const columns = [
    {
      title: t.RECEIVED_ON,
      dataIndex: 'receivedOn',
      key: 'receivedOn',
      render: (item: moment.Moment) => <>{item ? moment(item)?.format(DATE_AND_TIME_FORMAT2) : '-'}</>,
      sorter: true,
      hidden: false,
    },
    {
      title: t.SENT_BY,
      dataIndex: 'importedEmailSenderEmailAddress',
      key: 'importedEmailSenderEmailAddress',
      hidden:
        !teamTypes ||
        (teamTypes &&
          teamTypes?.indexOf(ClientService.TeamTypeEnum.POC_CPC) < 0 &&
          teamTypes?.indexOf(ClientService.TeamTypeEnum.Asset_CPC) < 0),
    },
    {
      title: t.SUBJECT,
      dataIndex: 'importedEmailSubject',
      key: 'importedEmailSubject',
      hidden:
        !teamTypes ||
        (teamTypes &&
          teamTypes?.indexOf(ClientService.TeamTypeEnum.POC_CPC) < 0 &&
          teamTypes?.indexOf(ClientService.TeamTypeEnum.Asset_CPC) < 0),
      ...getColumnSearchProps(`${t.SEARCH} ${t.SUBJECT}`, handleTableSubjectSearchSubmit),
    },
    {
      title: t.ACTION_STATUS,
      dataIndex: 'appTaskActionStatusName',
      key: 'appTaskActionStatusName',
      sorter: true,
      hidden: false,
    },
    {
      title: t.DAYS_OPEN,
      dataIndex: 'openDate',
      key: 'openDate',
      render: (item?: moment.Moment, record?: ClientService.AppTaskActionDto) => (
        <>{item ? moment(record?.closeDate).diff(moment(item), 'days') : '0'}</>
      ),
      sorter: true,
      hidden: false,
    },
    {
      title: t.ASSIGNED_TO,
      dataIndex: 'assignedName',
      key: 'assignedName',
      sorter: true,
      hidden: false,
    },
    {
      title: t.ACTION,
      dataIndex: 'action',
      key: 'action',
      render: (_: any, record?: ClientService.AppTaskActionDto) => (
        <Icon
          iconName="RedEye"
          onClick={() => handleViewTaskAction(record?.appTaskId, record?.id, record?.fileId)}
          className="ActionTable__action-icon"
        />
      ),
      hidden: false,
    },
  ].filter((item) => !item.hidden);

  const handleTableChange = useCallback(
    (pagination: any, filters: any, sorter: any) => {
      setCriteria({
        ...criteria,
        maxResultCount: pagination?.pageSize,
        skipCount: pagination?.pageSize * (pagination?.current - 1),
        sorting:
          sorter?.field !== undefined
            ? `${sorter?.field === 'appTaskActionStatusName' ? 'appTaskActionStatus' : sorter?.field} ${
                sorter?.order === 'ascend' ? SORTING_ASC : SORTING_DESC
              }`
            : criteria?.sorting,
      });
    },
    [setCriteria, criteria]
  );

  const handleViewTaskAction = useCallback(
    (appTaskId?: string, actionId?: string, fileId?: string) => {
      showModal(
        <TaskActionEditor
          fileId={fileId as string}
          taskId={appTaskId}
          actionId={actionId}
          onNext={closeModal}
          onCancel={closeModal}
        />
      );
    },
    [closeModal, showModal]
  );

  const handleSubmit = useCallback(
    async ({ appTaskActionStatusEnum }) => {
      if (!selectedRowKeys?.length) return;

      const appTaskActionStatus = await API.getAppTaskActionStatus(appTaskActionStatusEnum);

      if (appTaskActionStatus) {
        const response = await API.updateAll2({
          ids: selectedRowKeys as string[],
          appTaskActionStatusId: appTaskActionStatus?.id,
        } as ClientService.AppTaskActionUpdateManyDto);

        if (response?.result === ClientService.Result.Successful) {
          genericMessage.success(t.ACTION_STATUS_IS_UPDATED);

          requestAppTasks(criteria);
        }
      }
    },
    [criteria, requestAppTasks, selectedRowKeys, t.ACTION_STATUS_IS_UPDATED]
  );

  const requestAppTasksOnCriteriaChange = useCallback(() => {
    requestAppTasks(criteria);
  }, [criteria, requestAppTasks]);

  useEffect(() => {
    requestAppTasks(criteria);
  }, [criteria, requestAppTasks]);

  useEffect(() => {
    eventBus.on(TASK_ACTION_UPDATE_EVENT, requestAppTasksOnCriteriaChange);
    return () => {
      eventBus.remove(TASK_ACTION_UPDATE_EVENT, requestAppTasksOnCriteriaChange);
    };
  }, [requestAppTasksOnCriteriaChange]);

  return (
    <div className="ActionTable">
      <Form form={form} onFinish={handleSubmit} layout="vertical">
        <Row gutter={20}>
          <Col span={6}>
            <Form.Item name="appTaskActionStatusEnum" noStyle>
              <Select
                suffixIcon={<SelectSuffixIcon />}
                size="large"
                placeholder={t.SELECT}
                allowClear
                style={{ width: '100%' }}
              >
                <Option
                  key={ClientService.AppTaskActionStatusEnum.Pending}
                  value={ClientService.AppTaskActionStatusEnum.Pending}
                >
                  {t.PENDING}
                </Option>
                <Option
                  key={ClientService.AppTaskActionStatusEnum.Completed}
                  value={ClientService.AppTaskActionStatusEnum.Completed}
                >
                  {t.COMPLETED}
                </Option>
                <Option
                  key={ClientService.AppTaskActionStatusEnum.Cancelled}
                  value={ClientService.AppTaskActionStatusEnum.Cancelled}
                >
                  {t.CANCELLED}
                </Option>
              </Select>
            </Form.Item>
          </Col>
          <Col>
            <Form.Item noStyle>
              <Button kind="primary" htmlType="submit">
                {t.SAVE}
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>

      <Divider />

      <Table
        rowKey="id"
        dataSource={dataSource}
        columns={columns}
        onChange={handleTableChange}
        pagination={{
          size: 'small',
          position: ['bottomRight'],
          defaultPageSize: DEFAULT_PAGE_SIZE,
          showSizeChanger: true,
          showTotal: paginationShowTotal,
          total: totalCount,
        }}
        loading={tableDataLoading}
        rowSelection={{
          selectedRowKeys,
          onChange: setSelectedRowKeys,
        }}
      />
    </div>
  );
};

export default ActionTable;
