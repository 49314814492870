import { useCallback, useEffect, useState, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { List } from 'antd';

import Note from '../Note/Note';
import NoteEditor from '../NoteEditor/NoteEditor';

import { INoteSearchCriteria, INoteDto } from '../../types';
import { argumentifyNoteSearchCriteria } from '../../utils';
import { paginationShowTotal } from '../../../../utils/helpers';
import {
  NOTE_ADDED_EVENT,
  NEW_NOTE_EVENT,
  NOTE_CHANGE_INIT_EVENT,
  NOTE_CHANGE_EVENT,
} from '../../../../constants/eventBus';
import { eventBus } from '../../../../utils/eventBus';
import API from '../../../../utils/api';
import { useApplicationFile } from '../../ApplicationOverviewPage';

import './NotesList.scss';

interface IProps {
  isAddMode?: boolean;
  pagination?: boolean;
  pageSize?: number;
  maxResultCount?: number;
  documentId?: string;
  documentVerificationId?: string;
  isAvatarVisible?: boolean;
}

const NotesList = ({
  isAddMode = false,
  pagination = true,
  pageSize = 10,
  maxResultCount,
  documentId,
  documentVerificationId,
  isAvatarVisible = true,
}: IProps): JSX.Element => {
  const { applicationFileId } = useParams<{ applicationFileId: string }>();

  const [notes, setNotes] = useState<INoteDto[]>();
  const [loadingNotes, setLoadingNotes] = useState<boolean>();
  const [totalCount, setTotalCount] = useState<number>(0);

  const [isNoteEditor, setIsNoteEditor] = useState<boolean>(isAddMode);
  const [editItemId, setEditItemId] = useState<string>();

  const [criteria, setCriteria] = useState<INoteSearchCriteria>({
    fileId: applicationFileId,
    maxResultCount: maxResultCount || pageSize,
    documentId,
    documentVerificationId,
  });

  const { data: appFileData } = useApplicationFile();
  const isDuplicate = useMemo(() => appFileData?.isDuplicate, [appFileData]);

  const requestGetNotesList = useCallback(() => {
    setLoadingNotes(true);
    API.notesGET(...argumentifyNoteSearchCriteria(criteria)).then((response) => {
      setLoadingNotes(false);
      setNotes(response?.items);
      setTotalCount(response?.totalCount || 0);
    });
  }, [criteria]);

  useEffect(() => {
    if (criteria) requestGetNotesList();
  }, [criteria, requestGetNotesList]);

  const handlePageChange = useCallback(
    (page, pageSize) => {
      const updatedCriteria = {
        ...criteria,
        skipCount: (page - 1) * pageSize,
        maxResultCount: pageSize,
      };
      setCriteria(updatedCriteria);
    },
    [criteria]
  );

  const handleShowEditor = useCallback(() => {
    setIsNoteEditor(true);
    setEditItemId(undefined);
  }, []);

  const handleStartEditing = useCallback((e) => {
    setEditItemId(e?.detail?.noteId);
  }, []);

  const handleFinishEditing = useCallback(() => {
    setEditItemId(undefined);
    requestGetNotesList();
  }, [requestGetNotesList]);

  useEffect(() => {
    eventBus.on(NOTE_ADDED_EVENT, requestGetNotesList);

    return () => {
      eventBus.remove(NOTE_ADDED_EVENT, requestGetNotesList);
    };
  }, [requestGetNotesList]);

  useEffect(() => {
    eventBus.on(NEW_NOTE_EVENT, handleShowEditor);

    return () => {
      eventBus.remove(NEW_NOTE_EVENT, handleShowEditor);
    };
  }, [handleShowEditor]);

  useEffect(() => {
    eventBus.on(NOTE_CHANGE_INIT_EVENT, handleStartEditing);

    return () => {
      eventBus.remove(NOTE_CHANGE_INIT_EVENT, handleStartEditing);
    };
  }, [handleStartEditing]);

  useEffect(() => {
    eventBus.on(NOTE_CHANGE_EVENT, handleFinishEditing);

    return () => {
      eventBus.remove(NOTE_CHANGE_EVENT, handleFinishEditing);
    };
  }, [handleFinishEditing]);

  if (!notes || !notes.length)
    return isNoteEditor ? (
      <NoteEditor
        documentId={documentId}
        documentVerificationId={documentVerificationId}
        isAvatarVisible={isAvatarVisible}
        applicationFileId={applicationFileId as string}
      />
    ) : (
      <></>
    );

  return (
    <>
      {isNoteEditor && !isDuplicate && (
        <NoteEditor
          documentId={documentId}
          documentVerificationId={documentVerificationId}
          isAvatarVisible={isAvatarVisible}
          applicationFileId={applicationFileId as string}
        />
      )}
      <List
        dataSource={notes}
        itemLayout="horizontal"
        className="NotesList"
        renderItem={(props) => (
          <Note
            item={props}
            isEditMode={editItemId === props?.id}
            documentId={documentId}
            documentVerificationId={documentVerificationId}
            isAvatarVisible={isAvatarVisible}
            applicationFileId={applicationFileId as string}
            editable={!isDuplicate}
          />
        )}
        loading={loadingNotes}
        pagination={
          pagination && {
            position: 'bottom',
            defaultPageSize: pageSize,
            showSizeChanger: true,
            showTotal: paginationShowTotal,
            total: totalCount,
            onChange: handlePageChange,
          }
        }
      />
    </>
  );
};

export default NotesList;
