import { useCallback, useEffect, useState, useMemo } from 'react';
import { Input, Row, Col } from 'antd';
import { useQuery } from 'react-query';

import Layout from '../../../components/Layout/Layout';
import Breadcrumb from '../../../components/Breadcrumb/Breadcrumb';
import PageSubtitle from '../../../components/PageSubtitle/PageSubtitle';
import Button from '../../../components/Button/Button';
import HolidaysEditor from './HolidaysEditor';
import StatusValue from '../components/StatusValue';
import StyledTable from '../components/StyledTable/StyledTable';
import EditIcon from '../components/EditIcon';

import useLocale from '../../../hooks/useLocale';
import useModal from '../../../hooks/useModal';
import useDebounce from '../../../hooks/useDebounce';

import { argumentifyHolidaysSearchCriteria, convertDateToReadable, convertOfficesToReadable } from './utils';
import { argumentifyOfficeLocationsSearchCriteria } from '../../../components/SchedulingComponent/utils';
import { IHolidaysSearchCriteria } from './types';
import { OFFICE_LOCATIONS_QUERY, USE_QUERY_OPTIONS, EXCLUDE_INACTIVE } from '../../../constants/reactQuery';
import { getSortableFilterableColumns } from '../utils';
import { convertServerDateOnlyToInputDate } from '../../../utils/helpers';
import { MAX_PAGE_SIZE } from '../../../constants/common';

import { ClientService } from '../../../shared/api/ClientService';
import API from '../../../utils/api';

import './Holidays.scss';
import moment from 'moment';

interface IDataItem extends ClientService.HolidayDto {
  key: string;
}

function Holidays(): JSX.Element {
  const { t, locale } = useLocale();
  const { Search } = Input;
  const { showModal, closeModal } = useModal();

  const [searchTerm, setSearchTerm] = useState<string>('');
  const value = useDebounce(searchTerm);

  const [dataSource, setDataSource] = useState<IDataItem[]>();
  const [totalCount, setTotalCount] = useState<number>();
  const [loading, setLoading] = useState<boolean>(false);
  const [criteria, setCriteria] = useState<IHolidaysSearchCriteria>({
    maxResultCount: MAX_PAGE_SIZE,
  });

  const { data: offices } = useQuery(
    [OFFICE_LOCATIONS_QUERY, EXCLUDE_INACTIVE, MAX_PAGE_SIZE],
    () =>
      API.officeLocationsGET(
        ...argumentifyOfficeLocationsSearchCriteria({ maxResultCount: MAX_PAGE_SIZE, includeInactive: false })
      ),
    USE_QUERY_OPTIONS
  );

  const requestHolidays = useCallback(async () => {
    setLoading(true);
    const response = await API.getHolidayList(...argumentifyHolidaysSearchCriteria(criteria)).catch(() =>
      setLoading(false)
    );
    setLoading(false);

    if (response) {
      setDataSource(
        response?.items?.map(
          (item) =>
            ({
              ...item,
              holidayDate: convertServerDateOnlyToInputDate(item?.holidayDate),
              key: `${locale}-${item?.id}`,
            } as IDataItem)
        )
      );
      setTotalCount(response?.totalCount || 0);
    }
  }, [criteria, locale]);

  const handleShowEditor = useCallback(
    (holidayId?: string) => {
      showModal(
        <HolidaysEditor
          id={holidayId}
          onOk={() => {
            closeModal();
            requestHolidays();
          }}
          onCancel={closeModal}
        />
      );
    },
    [closeModal, requestHolidays, showModal]
  );

  useEffect(() => {
    requestHolidays();
  }, [criteria, requestHolidays]);

  useEffect(() => {
    setCriteria((prev) => ({ ...prev, filterText: value }));
  }, [value]);

  const sortableColumns = useMemo(() => {
    const columns = [
      {
        title: t.OFFICE_LOCATION,
        dataIndex: 'officeLocationNames',
        key: 'officeLocationNames',
        sorter: (a?: ClientService.HolidayDto, b?: ClientService.HolidayDto) =>
          convertOfficesToReadable(a).localeCompare(convertOfficesToReadable(b)),
        render: (names?: string[], record?: ClientService.HolidayDto) => <>{convertOfficesToReadable(record)}</>,
        filters: (offices?.items || [])?.map((item) => ({
          text: item.name as string,
          value: item.name as string,
        })),
        onFilter: (value: any, record: ClientService.HolidayDto) =>
          record?.isForAllOffices ||
          (record?.officeLocationNames && record?.officeLocationNames?.indexOf(value) >= 0) ||
          false,
        width: '40%',
      },
      {
        title: t.HOLIDAY_DATE,
        dataIndex: 'holidayDate',
        key: 'holidayDate',
        sorter: true,
        render: (date?: moment.Moment) => <>{convertDateToReadable(date)}</>,
        filters: [...new Set(dataSource?.map((item) => convertDateToReadable(item.holidayDate)))]?.map(
          (uniqueItem) => ({
            text: uniqueItem,
            value: uniqueItem,
          })
        ),
        onFilter: (value: any, record: ClientService.HolidayDto) => value === convertDateToReadable(record.holidayDate),
      },
      {
        title: t.STATUS,
        dataIndex: 'isActive',
        key: 'isActive',
        sorter: true,
        render: (isActive?: boolean) => <StatusValue isActive={isActive} />,
        filters: [
          {
            text: t.ACTIVE,
            value: true,
          },
          {
            text: t.INACTIVE,
            value: false,
          },
        ],
        onFilter: (value: any, record: ClientService.HolidayDto) => value === record.isActive,
      },
      {
        dataIndex: 'action',
        key: 'action',
        render: (_: any, record: ClientService.HolidayDto) => <EditIcon onClick={() => handleShowEditor(record?.id)} />,
      },
    ];

    return getSortableFilterableColumns(columns, dataSource);
  }, [dataSource, handleShowEditor, offices?.items, t.ACTIVE, t.HOLIDAY_DATE, t.INACTIVE, t.OFFICE_LOCATION, t.STATUS]);

  return (
    <Layout className="Holidays">
      <Breadcrumb data={[{ title: t.ADMINISTRATION }, { title: t.Holidays }]} />
      <PageSubtitle title={t.Holidays} />
      <Row align="bottom" justify="space-between">
        <Col span={8}>
          <Search
            placeholder={t.SEARCH_BY_OFFICE_LOCATION}
            allowClear
            size="large"
            onSearch={setSearchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </Col>
        <Col>
          <Button onClick={() => handleShowEditor()}>{t.ADD_HOLIDAY}</Button>
        </Col>
      </Row>
      <StyledTable
        rowKey="id"
        dataSource={dataSource?.map((item) => ({ ...item, key: `${locale}-${item?.id}` } as IDataItem))}
        columns={sortableColumns}
        total={totalCount}
        loading={loading}
        key={`table-${locale}`}
      />
    </Layout>
  );
}

export default Holidays;
