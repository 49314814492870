import { useState, useCallback, useEffect, useMemo } from 'react';
import { Modal, Tabs, Form, Input, Select, Row, Col, Checkbox, InputNumber, Spin } from 'antd';
import Button from '../../../../components/Button/Button';
import { ClientService } from '../../../../shared/api/ClientService';
import useLocale from '../../../../hooks/useLocale';
import API from '../../../../utils/api';
import TextArea from 'antd/lib/input/TextArea';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import SearchLocationInput from '../../../../components/SearchLocationInput/SearchLocationInput';
import { LocationDto } from '../../../../modals/GoogleMaps/types';
import { Icon } from '@fluentui/react/lib/Icon';
import SelectSuffixIcon from '../../../../components/SelectSuffixIcon/SelectSuffixIcon';
import SelectLookupDto from '../../../../components/SelectLookupDto/SelectLookupDto';

import './ApplicationFormTab.scss';
import { antdUtil } from '../../../../utils/antdUtil';
import LookAheadField from '../../../../components/LookAheadField/LookAheadField';
import ChangeTrackerFormItem from '../../../../components/ChangeTrackerFormItem';

interface IProps {
  debtId?: string;
  fileId: string;
  isMissingDetails?: boolean;
  onNext?: () => void;
  onCancel?: () => void;
  isLocked?: boolean;
}

export interface IOption {
  value?: string;
  label?: string;
  [key: string]: any;
}

const formTabs = ['basic', 'extendedInfo'];

function AssetsDebtsDebt({
  debtId,
  fileId,
  isMissingDetails,
  onNext,
  onCancel,
  isLocked = false,
}: IProps): JSX.Element {
  const { t } = useLocale();
  const { TabPane } = Tabs;
  const { Option } = Select;
  const [form] = Form.useForm<ClientService.AppFormAssetsDebtsDebtDto>();

  const [title, setTitle] = useState('');
  const [subTitle, setSubTitle] = useState('');

  const [selectedDescriptionId, setSelectedDescriptionId] = useState('');
  const [descriptions, setDescriptions] = useState<ClientService.LookupDto[]>([]);
  const [descriptionsLoading, setDescriptionsLoading] = useState<boolean>(false);

  const [ownershipTypes, setOwnershipTypes] = useState<ClientService.LookupDto[]>([]);
  const [ownershipTypesLoading, setOwnershipTypesLoading] = useState<boolean>(false);

  const [selectedCreditorNameId, setSelectedCreditorNameId] = useState('');
  const [selectedCreditorDisplayName, setSelectedCreditorDisplayName] = useState('');
  const [selectedCreditorTypeId, setSelectedCreditorTypeId] = useState('');
  const [creditorTypes, setCreditorTypes] = useState<ClientService.LookupDto[]>([]);
  const [creditorTypesLoading, setCreditorTypesLoading] = useState<boolean>(false);

  const [ltpCodes, setLTPCodes] = useState<ClientService.LookupDto[]>([]);
  const [ltpCodesLoading, setLTPCodesLoading] = useState<boolean>(false);

  const [reasonForPreferreds, setReasonForPreferreds] = useState<ClientService.LookupDto[]>([]);
  const [reasonForPreferredsLoading, setReasonForPreferredsLoading] = useState<boolean>(false);

  const [isOther, setIsOther] = useState(false);
  const [isPersonalLoan, setIsPersonalLoan] = useState(false);

  const [isUnique, setIsUnique] = useState(false);
  const [isSecurable, setIsSecurable] = useState(false);
  const [isSecuredRequired, setIsSecuredRequired] = useState(false);
  const [isPreferred, setIsPreferred] = useState(false);
  const [isApplicableForProposal, setIsApplicableForProposal] = useState(false);

  const [selectedAssetIds, setSelectedAssetIds] = useState<string[]>([]);
  const [assets, setAssets] = useState<ClientService.AppFormAssetsDebtsAssetListItem[]>([]);
  const [assetsLoading, setAssetsLoading] = useState(false);
  const [debtLoading, setDebtLoading] = useState(false);
  const [activeTab, setActiveTab] = useState<string>('basic');

  useEffect(() => {
    if (debtId) {
      setDebtLoading(true);
      setTitle(t.EDIT_DEBT);

      API.getAppFormDebt(debtId)
        .then((response) => {
          form.setFieldsValue(response);

          setSelectedDescriptionId(response?.debtDescriptionId || '');

          setSelectedCreditorNameId(response?.externalCreditorNameId || '');

          if (response.externalCreditorNameId) {
            API.getCreditor(response.externalCreditorNameId)
              .then((creditor_response) => {
                setSelectedCreditorDisplayName(creditor_response.displayName || '');
              })
              .catch(() => {});
          }

          setSelectedCreditorTypeId(response?.creditorTypeId || '');
          setSelectedAssetIds(response?.securedByAssetIds || []);
          setDebtLoading(false);
        })
        .catch(() => {
          setDebtLoading(false);
        });
    } else {
      setDebtLoading(false);
      setTitle(t.NEW_DEBT);
    }

    setDescriptionsLoading(true);
    API.listDebtDescriptions().then((response) => {
      setDescriptions(response);
      setDescriptionsLoading(false);
    });

    setOwnershipTypesLoading(true);
    API.listAssetDebtOwnershipTypes().then((response) => {
      setOwnershipTypes(response);
      setOwnershipTypesLoading(false);
    });

    setCreditorTypesLoading(true);
    API.listCreditorTypes().then((response) => {
      setCreditorTypes(response);
      setCreditorTypesLoading(false);
    });

    setLTPCodesLoading(true);
    API.listLTPCodes().then((response) => {
      setLTPCodes(response);
      setLTPCodesLoading(false);
    });

    setReasonForPreferredsLoading(true);
    API.listReasonForPreferreds().then((response) => {
      setReasonForPreferreds(response);
      setReasonForPreferredsLoading(false);
    });

    setAssetsLoading(true);
    API.listAppFormAssets(fileId).then((response) => {
      setAssets(response);
      setAssetsLoading(false);
    });
  }, []);

  useEffect(() => {
    if (!debtId) {
      let defaultLTPCode = ltpCodes.find((item) => item.enumValue == ClientService.LTPCodeEnum.Other);

      form.setFieldsValue({ ltpCodeId: defaultLTPCode?.id });
    }
  }, [debtId, ltpCodes]);

  useEffect(() => {
    const selDesc = descriptions?.find((item) => item?.id === selectedDescriptionId);
    const selDescVal = selDesc?.enumValue;

    setSubTitle(selDesc?.name || '');

    setIsOther(selDescVal === ClientService.DebtDescriptionEnum.Other);
    setIsPersonalLoan(selDescVal === ClientService.DebtDescriptionEnum.PersonalLoan);
  }, [selectedDescriptionId, descriptions]);

  useEffect(() => {
    const selCredType = creditorTypes?.find((item) => item?.id === selectedCreditorTypeId);
    const selCredTypeVal = selCredType?.enumValue;

    setIsSecurable(
      selCredTypeVal === ClientService.CreditorTypeEnum.Secured ||
        selCredTypeVal === ClientService.CreditorTypeEnum.DeemedTrust ||
        selCredTypeVal === ClientService.CreditorTypeEnum.Property
    );
    setIsSecuredRequired(
      selCredTypeVal === ClientService.CreditorTypeEnum.Secured ||
        selCredTypeVal === ClientService.CreditorTypeEnum.Property
    );
    setIsPreferred(selCredTypeVal === ClientService.CreditorTypeEnum.Preferred);
    setIsApplicableForProposal(
      selCredTypeVal === ClientService.CreditorTypeEnum.Secured ||
        selCredTypeVal === ClientService.CreditorTypeEnum.Property ||
        (selCredTypeVal === ClientService.CreditorTypeEnum.DeemedTrust && selectedAssetIds?.length > 0)
    );
  }, [selectedCreditorTypeId, creditorTypes, selectedAssetIds]);

  useEffect(() => {
    if (!debtId) {
      let defaultOwnershipType = ownershipTypes.find(
        (item) => item.enumValue == ClientService.AssetDebtOwnershipTypeEnum.Applicant
      );

      form.setFieldsValue({ ownershipTypeId: defaultOwnershipType?.id });
    }
  }, [debtId, ownershipTypes]);

  const updateExternalCreditorNameId = useCallback((value: string | undefined, option: IOption) => {
    form.setFieldsValue({ externalCreditorNameId: option?.value });
  }, []);

  const handleDescriptionSelect = (value: string, option: any) => {
    setSelectedDescriptionId(value);

    const selDesc = descriptions?.find((item) => item?.id === value);
    const selDescVal = selDesc?.enumValue;

    if (
      selDescVal === ClientService.DebtDescriptionEnum.AutoLoan ||
      selDescVal === ClientService.DebtDescriptionEnum.Mortgage
    ) {
      const securedCreditorType = creditorTypes.find(
        (item) => item?.enumValue === ClientService.CreditorTypeEnum.Secured
      );
      setSelectedCreditorTypeId(securedCreditorType?.id || '');
      form.setFieldsValue({ creditorTypeId: securedCreditorType?.id });
    }
  };

  const handleUniqueCreditorChecked = (e: CheckboxChangeEvent) => {
    setIsUnique(e?.target?.checked);
  };

  const handleCreditorTypeSelect = (value: string, option: any) => {
    setSelectedCreditorTypeId(value);
  };

  const handleUpdateUnsecured = useCallback(() => {
    let selAssetIds = form.getFieldValue('securedByAssetIds') as string[];
    let debtAmount = form.getFieldValue('amount') as number;
    let totalAmount = 0;
    assets?.forEach((item) => {
      if (selAssetIds?.indexOf(item?.id || '') > -1) {
        totalAmount += item?.value || 0;
      }
    });

    form.setFieldsValue({ unsecuredAmount: debtAmount - totalAmount });
  }, [assets, form]);

  const save = useCallback(
    (dto: ClientService.AppFormAssetsDebtsAssetDto) => {
      if (isLocked) return;

      setDebtLoading(true);

      dto.fileId = fileId;
      if (debtId) {
        dto.id = debtId;

        API.updateAppFormDebt(dto)
          .then((response) => {
            setDebtLoading(false);
            if (onNext) onNext();
          })
          .catch(() => {
            setDebtLoading(false);
          });
      } else {
        API.createAppFormDebt(dto)
          .then((response) => {
            setDebtLoading(false);
            if (onNext) onNext();
          })
          .catch(() => {
            setDebtLoading(false);
          });
      }
    },
    [debtId, isLocked, fileId, onNext]
  );

  const handleNextStepOrSubmit = useCallback(() => {
    const activeKeyIndex = formTabs.indexOf(activeTab);

    if (formTabs?.length - 1 > activeKeyIndex) {
      setActiveTab(formTabs[activeKeyIndex + 1]);
    } else {
      const values = form.getFieldsValue();
      save(values);
    }
  }, [activeTab, form, save]);

  const handleTabChange = useCallback((key) => form.validateFields().then(() => setActiveTab(key)), [form]);

  const handleCreditorAddressAutocomplete = useCallback(
    (autofilledAddress: LocationDto) => {
      form.setFieldsValue({ creditorAddress: autofilledAddress.address });
    },
    [form]
  );

  const onFormSubmit = useCallback(async () => {
    form
      .validateFields()
      .then(handleNextStepOrSubmit)
      .catch((error) => console.log(error));
  }, [form, handleNextStepOrSubmit]);

  const isLastTab = useMemo(() => formTabs?.length - 1 === formTabs.indexOf(activeTab), [activeTab]);

  return (
    <Modal
      destroyOnClose
      centered
      visible
      title={
        <>
          <div>{title}</div>
          <div className="ApplicationFormTab__modal-sub-title">{subTitle}</div>
        </>
      }
      width={700}
      footer={null}
      onCancel={onCancel}
      closable
      maskClosable={false}
      className="ApplicationFormTab__modal"
    >
      <Spin spinning={debtLoading}>
        <Form form={form} layout="vertical" validateMessages={antdUtil.validateMessages}>
          <Tabs className="Tabs" activeKey={activeTab} onChange={handleTabChange}>
            <TabPane tab={t.BASIC} key="basic" forceRender>
              <Row gutter={20}>
                <Col span={12}>
                  <ChangeTrackerFormItem name="debtDescriptionId" label={t.DESCRIPTION} rules={[{ required: true }]}>
                    <Select
                      loading={descriptionsLoading}
                      onChange={handleDescriptionSelect}
                      suffixIcon={<SelectSuffixIcon />}
                      size="large"
                      disabled={isLocked}
                    >
                      {descriptions?.map((option) => (
                        <Option key={option.id} value={option?.id}>
                          {option?.name}
                        </Option>
                      ))}
                    </Select>
                  </ChangeTrackerFormItem>
                </Col>
                <Col span={12}>
                  <ChangeTrackerFormItem name="creditorName" label={t.CREDITOR_NAME} rules={[{ required: true }]}>
                    <Input size="large" disabled={isLocked} />
                  </ChangeTrackerFormItem>
                </Col>
                {isOther ? (
                  <Col span={24}>
                    <ChangeTrackerFormItem
                      name="specifiedDescription"
                      className="ApplicationFormTab__input-label-warning"
                      label={t.OTHER_SPECIFY}
                      rules={[{ required: true, warningOnly: true, message: t.RECOMMENDED_FIELD }]}
                    >
                      <TextArea size="large" disabled={isLocked} />
                    </ChangeTrackerFormItem>
                  </Col>
                ) : (
                  <></>
                )}
                <Col span={12}>
                  <ChangeTrackerFormItem name="amount" label={t.AMOUNT} rules={[{ required: true }]}>
                    <InputNumber
                      size="large"
                      style={{ width: '100%' }}
                      min={1}
                      max={999999999}
                      precision={0}
                      formatter={(value) => antdUtil.currencyFormatter(value)}
                      parser={(value) => antdUtil.currencyParser(value) as 1 | 999999999}
                      onChange={handleUpdateUnsecured}
                      disabled={isLocked}
                    />
                  </ChangeTrackerFormItem>
                </Col>
                <Col span={12}>
                  <ChangeTrackerFormItem name="ownershipTypeId" label={t.OWNERSHIP}>
                    <Select
                      loading={ownershipTypesLoading}
                      suffixIcon={<SelectSuffixIcon />}
                      size="large"
                      disabled={isLocked}
                    >
                      {ownershipTypes?.map((option) => (
                        <Option key={option.id} value={option?.id}>
                          {option?.name}
                        </Option>
                      ))}
                    </Select>
                  </ChangeTrackerFormItem>
                </Col>
                <Col span={24}>
                  <ChangeTrackerFormItem
                    name="accountNumber"
                    className="ApplicationFormTab__input-label-warning"
                    label={t.ACCOUNT_NUMBER}
                    rules={[{ required: true, warningOnly: true, message: t.RECOMMENDED_FIELD }]}
                  >
                    <Input size="large" disabled={isLocked} />
                  </ChangeTrackerFormItem>
                </Col>
                <Col span={24}>
                  <ChangeTrackerFormItem name="creditorAddress" label={t.CREDITOR_ADDRESS}>
                    <SearchLocationInput
                      onSelect={handleCreditorAddressAutocomplete}
                      size="large"
                      disabled={isLocked}
                    />
                  </ChangeTrackerFormItem>
                </Col>
                <Col span={24}>
                  <ChangeTrackerFormItem name="creditorUnit" label={t.CREDITOR_UNIT}>
                    <Input size="large" disabled={isLocked} />
                  </ChangeTrackerFormItem>
                </Col>
                {isPersonalLoan ? (
                  <Col span={24}>
                    <ChangeTrackerFormItem
                      name="creditorEmailAddress"
                      label={t.CREDITOR_EMAIL_ADDRESS}
                      rules={[{ type: 'email', message: t.CREDITOR_EMAIL_ADDRESS_IS_INCORRECT }]}
                    >
                      <Input size="large" disabled={isLocked} />
                    </ChangeTrackerFormItem>
                  </Col>
                ) : (
                  <></>
                )}
                <Col span={24}>
                  <ChangeTrackerFormItem name="comments" label={t.COMMENTS}>
                    <TextArea size="large" disabled={isLocked} />
                  </ChangeTrackerFormItem>
                </Col>
                <Col span={24}>
                  <ChangeTrackerFormItem name="isBusinessDebt" valuePropName="checked">
                    <Checkbox disabled={isLocked}>{t.BUSINESS_DEBT}</Checkbox>
                  </ChangeTrackerFormItem>
                </Col>
              </Row>
            </TabPane>
            <TabPane
              tab={
                <Row align="middle" gutter={5}>
                  <Col>{t.EXTENDED_INFO}</Col>
                  <Col>
                    {isMissingDetails && <Icon iconName="AlertSolid" className="ApplicationFormTab__tab-error-icon" />}
                  </Col>
                </Row>
              }
              key="extendedInfo"
              forceRender
            >
              <Row gutter={20}>
                <Col span={24}>
                  <ChangeTrackerFormItem name="isUniqueCreditor" valuePropName="checked">
                    <Checkbox onChange={handleUniqueCreditorChecked} disabled={isLocked}>
                      {t.UNIQUE_CREDITOR}
                    </Checkbox>
                  </ChangeTrackerFormItem>
                </Col>
                <Col span={12}>
                  <ChangeTrackerFormItem
                    name="externalCreditorNameId"
                    className={isUnique ? '' : 'ApplicationFormTab__input-label-error'}
                    label={t.CREDITOR_LIST_NAME}
                    rules={[{ required: activeTab !== 'basic' && !isUnique, message: t.REQUIRED_FIELD }]}
                  >
                    <LookAheadField
                      displayValue={selectedCreditorDisplayName}
                      apiSearch={(searchText) => {
                        return API.listCreditors(searchText, 25);
                      }}
                      apiSetOptionsData={(response, setOptionsData) => {
                        console.log('creditor response:', response);

                        setOptionsData(
                          response.map((creditor: any, index: number) => {
                            const safeName = creditor.displayName.replaceAll(' ', '_');

                            return {
                              key: `option_${index}_${safeName}`,
                              value: creditor.id,
                              label: creditor.displayName,
                            };
                          })
                        );
                      }}
                      onSelect={updateExternalCreditorNameId}
                      onSearch={(searchText) => {
                        if (searchText.trim().length === 0) {
                          updateExternalCreditorNameId('', {});
                        }
                      }}
                    />
                  </ChangeTrackerFormItem>
                </Col>
                <Col span={12}>
                  <ChangeTrackerFormItem
                    name="creditorTypeId"
                    className="ApplicationFormTab__input-label-error"
                    label={t.CREDITOR_TYPE}
                  >
                    <Select
                      loading={creditorTypesLoading}
                      onChange={handleCreditorTypeSelect}
                      suffixIcon={<SelectSuffixIcon />}
                      size="large"
                      disabled={isLocked}
                    >
                      {creditorTypes?.map((option) => (
                        <Option key={option.id} value={option?.id}>
                          {option?.name}
                        </Option>
                      ))}
                    </Select>
                  </ChangeTrackerFormItem>
                </Col>
                <Col span={12}>
                  <ChangeTrackerFormItem
                    name="ltpCodeId"
                    className="ApplicationFormTab__input-label-error"
                    label="LTP Code"
                  >
                    <Select
                      loading={ltpCodesLoading}
                      suffixIcon={<SelectSuffixIcon />}
                      size="large"
                      disabled={isLocked}
                    >
                      {ltpCodes?.map((option) => (
                        <Option key={option.id} value={option?.id}>
                          {option?.name}
                        </Option>
                      ))}
                    </Select>
                  </ChangeTrackerFormItem>
                </Col>
                {Boolean(isSecurable) && (
                  <Col span={12}>
                    <ChangeTrackerFormItem name="unsecuredAmount" label={t.UNSECURED_AMOUNT}>
                      <InputNumber
                        size="large"
                        style={{ width: '100%' }}
                        min={-999999999}
                        max={999999999}
                        precision={0}
                        formatter={(value) => antdUtil.currencyFormatter(value)}
                        parser={(value) => antdUtil.currencyParser(value) as -999999999 | 999999999}
                        disabled
                      />
                    </ChangeTrackerFormItem>
                  </Col>
                )}
                {isPreferred ? (
                  <Col span={12}>
                    <ChangeTrackerFormItem
                      name="reasonForPreferredId"
                      className="ApplicationFormTab__input-label-error"
                      label={t.REASON_FOR_PREFERRED}
                    >
                      <Select
                        loading={descriptionsLoading}
                        suffixIcon={<SelectSuffixIcon />}
                        size="large"
                        disabled={isLocked}
                      >
                        {reasonForPreferreds?.map((option) => (
                          <Option key={option.id} value={option?.id}>
                            {option?.name}
                          </Option>
                        ))}
                      </Select>
                    </ChangeTrackerFormItem>
                  </Col>
                ) : (
                  <></>
                )}
              </Row>
              <Row gutter={20}>
                <Col span={12}>
                  <ChangeTrackerFormItem name="isPotentiallyStatuteBarred" valuePropName="checked">
                    <Checkbox disabled={isLocked}>{t.POTENTIALLY_STATUTEBARRED}</Checkbox>
                  </ChangeTrackerFormItem>
                </Col>
                <Col span={12}>
                  {isApplicableForProposal ? (
                    <ChangeTrackerFormItem name="isIncludeInProposal" valuePropName="checked">
                      <Checkbox disabled={isLocked}>{t.INCLUDE_IN_PROPOSAL}</Checkbox>
                    </ChangeTrackerFormItem>
                  ) : (
                    <ChangeTrackerFormItem name="isCreditorNotParticipatingInProposal" valuePropName="checked">
                      <Checkbox disabled={isLocked}>{t.CREDITOR_NOT_PARTICIPATING_IN_PROPOSAL}</Checkbox>
                    </ChangeTrackerFormItem>
                  )}
                </Col>
              </Row>
              {isSecurable && (
                <Row>
                  <Col span={24}>
                    <ChangeTrackerFormItem
                      name="securedByAssetIds"
                      className={isSecuredRequired ? 'ApplicationFormTab__input-label-error' : ''}
                      label={t.SECURED_BY}
                    >
                      <SelectLookupDto
                        data={assets}
                        loading={assetsLoading}
                        mode="multiple"
                        showSearch
                        showArrow
                        onChange={(selectedAssetIds?: string[]) => {
                          setSelectedAssetIds(selectedAssetIds || []);
                          handleUpdateUnsecured();
                        }}
                        disabled={isLocked}
                      />
                    </ChangeTrackerFormItem>
                  </Col>
                </Row>
              )}
            </TabPane>
          </Tabs>

          <Row gutter={12} justify="end">
            <Col>
              <Button kind="cancel" padding="large" onClick={onCancel}>
                {t.CANCEL}
              </Button>
            </Col>
            <Col>
              <Button kind="primary" padding="large" onClick={onFormSubmit} disabled={isLastTab && isLocked}>
                {!isLastTab ? t.NEXT : t.SAVE}
              </Button>
            </Col>
          </Row>
        </Form>
      </Spin>
    </Modal>
  );
}

export default AssetsDebtsDebt;
