import { isValidElement, ReactNode } from 'react';
import { Col } from 'antd';
import CurrencyDisplay from '../CurrencyDisplay/CurrencyDisplay';
import Icon, { IconProps } from '../Icon/Icon';
import InfoBubble, { TInfoBubble } from '../InfoBubble/InfoBubble';
import { getAsSystemColorOrUnchanged, SystemColorsType } from '../../utils/systemColors';

import styles from './DataItem.module.scss';

interface IExtraText {
  text: string;
  size?: 'small' | 'large';
  color?: SystemColorsType;
}

interface IExtraContent {
  content: ReactNode;
  alignRight?: boolean;
}

export interface ILabel {
  label: ReactNode;
  size?: 'small' | 'regular';
  color?: SystemColorsType;
  bold?: boolean;
}

export interface IValue {
  value: ReactNode;
  size?: 'small' | 'regular' | 'large';
  format?: 'normal' | 'currency' | 'currency-no-decimals' | 'currency-no-dollar-sign';
  color?: SystemColorsType;
  bold?: boolean;
}

export interface DataItemProps {
  colSpan?: number;
  noCol?: boolean;
  noColPadding?: boolean;
  layout?: 'horizontal' | 'vertical';
  horizontalSplit?: [number, number] | [number] | 'auto'; // numbers represent %
  inverted?: boolean;
  bold?: boolean;
  label?: ReactNode | ILabel;
  labelIcon?: ReactNode | IconProps;
  labelExtraContent?: ReactNode | IExtraContent;
  value?: ReactNode | IValue;
  valueAlignRight?: boolean;
  infoBubble?: TInfoBubble;
  valueIcon?: ReactNode | IconProps;
  valueExtraText?: string | IExtraText;
  valueExtraContent?: ReactNode | IExtraContent;
  className?: string;
  children?: ReactNode;
  style?: React.CSSProperties;
}

export interface DataItemLabelProps {
  horizontalWidth?: number;
  label: ReactNode;
  size?: ILabel['size'];
  color?: SystemColorsType;
  bold?: boolean;
  icon?: ReactNode | IconProps;
  className?: string;
  children?: ReactNode;
  contentAlignRight?: boolean;
  style?: React.CSSProperties;
}
export interface DataItemValueProps {
  horizontalWidth?: number;
  value: ReactNode;
  alignRight?: boolean;
  format?: IValue['format'];
  size?: IValue['size'];
  color?: SystemColorsType;
  bold?: boolean;
  extraText?: string | IExtraText;
  infoBubble?: TInfoBubble;
  icon?: ReactNode | IconProps;
  className?: string;
  children?: ReactNode;
  contentAlignRight?: boolean;
  style?: React.CSSProperties;
}

const elementHasProperty = (element: any, property: string) => {
  return element && typeof element === 'object' && property in element;
};

const renderIcon = (icon: ReactNode | IconProps) => {
  if (icon && isValidElement(icon)) return icon;
  return <Icon {...(icon as IconProps)} />;
};

const renderInfoBubble = (infoBubble: TInfoBubble) => {
  if (infoBubble) {
    if (isValidElement(infoBubble)) return InfoBubble;
    if ('direction' in infoBubble) return <InfoBubble.Directional {...infoBubble} />;
    return <InfoBubble {...infoBubble} />;
  }
};

const getLabelAsObject = (
  label: ReactNode | ILabel,
  bold: boolean,
  size?: ILabel['size'] | undefined,
  color?: ILabel['color'] | undefined
) => {
  const thisLabel = (label as any)?.label || label;
  const thisSize = (label as any)?.size || size || 'regular';
  const thisColor = (label as any)?.color || color || 'gray-text';
  return {
    label: thisLabel,
    size: thisSize,
    color: getAsSystemColorOrUnchanged(thisColor),
    bold: bold,
  };
};

const getValueAsObject = (
  value: ReactNode | IValue,
  bold: boolean,
  size?: IValue['size'] | undefined,
  format?: IValue['format'] | undefined,
  color?: IValue['color'] | undefined
) => {
  const typeCastValue = value as any;
  // let thisValue = (value as any)?.value != null ? (value as any)?.value : value;
  let thisValue = typeCastValue?.value != null ? typeCastValue?.value : typeCastValue;
  thisValue = thisValue != null ? thisValue : '-';
  const thisSize = (value as any)?.size || size || 'regular';
  const thisFormat = (value as any)?.format || format || 'normal';
  const thisColor = (value as any)?.color || color || 'black';
  return {
    value: thisValue,
    size: thisSize,
    format: thisFormat,
    color: getAsSystemColorOrUnchanged(thisColor),
    bold: bold,
  };
};

const getExtraContentAsObj = (extraContent: ReactNode | IExtraContent | undefined): IExtraContent => {
  return {
    content: (extraContent as any)?.content || extraContent,
    alignRight: (extraContent as any)?.alignRight || false,
  };
};

const getExtraTextAsObj = (extraText: string | IExtraText | undefined) => {
  const color = (extraText as any)?.color;
  return {
    text: (extraText as any)?.text || extraText,
    size: (extraText as any)?.size || 'small',
    color: color ? getAsSystemColorOrUnchanged(color) : 'black',
  };
};

interface ILabelParts {
  label: ILabel['label'];
  size: ILabel['size'];
  color: string | SystemColorsType;
  bold: boolean;
}
const renderLabelCommonMarkup = ({ label, size, color, bold }: ILabelParts, icon: ReactNode | IconProps) => {
  const labelStyles: React.CSSProperties = { color: color };
  return (
    <>
      <div className={`${styles.label} ${size ? styles[size] : ''} ${bold ? styles.bold : ''}`} style={labelStyles}>
        {label}
      </div>
      {icon && <div className={styles.label_icon}>{renderIcon(icon)}</div>}
    </>
  );
};

interface IValueParts {
  value: IValue['value'];
  size: IValue['size'];
  format: IValue['format'];
  bold: boolean;
}
const renderValueCommonMarkup = (
  { value, size, format, bold }: IValueParts,
  icon: ReactNode | IconProps | undefined,
  extraText: string | IExtraText | undefined,
  infoBubble: TInfoBubble | undefined
) => {
  const isCurrency = format === 'currency' || format === 'currency-no-decimals' || format === 'currency-no-dollar-sign';
  const valueExtraTextObj = getExtraTextAsObj(extraText);
  const extraTextStyles: React.CSSProperties = { color: valueExtraTextObj.color };
  return (
    <>
      <div
        className={`${styles.value} ${isCurrency ? styles.currency : ''} ${size ? styles[size] : ''} ${
          bold ? styles.bold : ''
        }`}
      >
        {isCurrency && (typeof value === 'string' || typeof value === 'number') ? (
          <CurrencyDisplay
            amount={value}
            noDollarSign={format === 'currency-no-dollar-sign'}
            decimalDigits={format === 'currency-no-decimals' ? 0 : undefined}
          />
        ) : (
          value
        )}
      </div>
      {valueExtraTextObj.text && (
        <div
          className={`${styles.extra_text} ${styles[`extra_text_${valueExtraTextObj.size}`]}`}
          style={extraTextStyles}
        >
          {valueExtraTextObj.text}
        </div>
      )}
      {infoBubble && <div className={styles.info_bubble}>{renderInfoBubble(infoBubble)}</div>}
      {icon && <div className={styles.value_icon}>{renderIcon(icon)}</div>}
    </>
  );
};

let instanceLayout: 'horizontal' | 'vertical' = 'horizontal';
let dotLabelWidth: number | undefined = undefined;

function DataItem({
  colSpan = 24,
  noCol = false,
  noColPadding,
  layout = 'horizontal',
  horizontalSplit = [40, 60],
  inverted,
  bold = false,
  label,
  labelIcon,
  labelExtraContent,
  value = '-',
  valueAlignRight = false,
  infoBubble,
  valueIcon,
  valueExtraText,
  valueExtraContent,
  className,
  children,
  style,
}: DataItemProps): JSX.Element {
  instanceLayout = layout;

  let cssClasses = `${styles.DataItem}`;
  cssClasses += layout === 'vertical' ? ` ${styles.vertical}` : ` ${styles.horizontal}`;
  cssClasses += inverted ? ` ${styles.inverted}` : '';
  cssClasses += bold ? ` ${styles.bold}` : '';
  cssClasses += className ? ` ${className}` : '';

  let hLabelWidth, hValueWidth;
  let [labelSplit, valueSplit] = horizontalSplit;
  if (layout === 'horizontal' && horizontalSplit !== 'auto' && typeof labelSplit === 'number') {
    hLabelWidth = labelSplit;
    valueSplit = valueSplit ? valueSplit : 100 - labelSplit;
    hValueWidth = valueSplit < 0 ? 0 : (valueSplit as number);
  }

  const colStyles: React.CSSProperties = {};
  if (noColPadding) {
    colStyles.paddingLeft = '0';
    colStyles.paddingRight = '0';
  }

  const labelWrapperStyles: React.CSSProperties = {};
  if (hLabelWidth) labelWrapperStyles.flex = `${hLabelWidth / 100} 0 0`;

  const labelIsILabelType: boolean = elementHasProperty(label, 'label');

  let labelBold = bold;
  if (label && labelIsILabelType) {
    const theLabel = label as ILabel;
    if (theLabel.bold != null) {
      labelBold = theLabel.bold;
    }
  }
  const labelObj = getLabelAsObject(label, labelBold);

  const labelExtraContentObj = getExtraContentAsObj(labelExtraContent);

  const valueIsIValueType: boolean = elementHasProperty(value, 'value');

  let valueBold = bold;
  if (value && valueIsIValueType) {
    const theValue = value as IValue;
    if (theValue.bold != null) {
      valueBold = theValue.bold;
    }
  }
  const valueObj = getValueAsObject(value, valueBold);

  const valueWrapperStyles: React.CSSProperties = { color: valueObj.color };
  if (hValueWidth) valueWrapperStyles.flex = `${hValueWidth / 100} 0 0`;
  if (valueAlignRight) valueWrapperStyles.justifyContent = 'flex-end';

  const valueExtraContentObj = getExtraContentAsObj(valueExtraContent);

  if (children) {
    const content = (
      <div className={cssClasses} style={style}>
        {children}
      </div>
    );

    return (
      <>
        {noCol ? (
          content
        ) : (
          <Col span={colSpan} style={colStyles}>
            {content}
          </Col>
        )}
      </>
    );
  }

  const content = (
    <div className={cssClasses} style={style}>
      <div className={styles.label_wrapper} style={labelWrapperStyles}>
        {renderLabelCommonMarkup(labelObj, labelIcon)}
        {labelExtraContentObj.content && (
          <div className={`${styles.extra_content} ${labelExtraContentObj.alignRight ? styles.at_right : ''}`}>
            {labelExtraContentObj.content}
          </div>
        )}
      </div>
      <div className={styles.value_wrapper} style={valueWrapperStyles}>
        {renderValueCommonMarkup(valueObj, valueIcon, valueExtraText, infoBubble)}
        {valueExtraContentObj.content && (
          <div className={`${styles.extra_content} ${valueExtraContentObj ? styles.at_right : ''}`}>
            {valueExtraContentObj.content}
          </div>
        )}
      </div>
    </div>
  );

  return (
    <>
      {noCol ? (
        content
      ) : (
        <Col span={colSpan} style={colStyles}>
          {content}
        </Col>
      )}
    </>
  );
}

DataItem.Label = ({
  horizontalWidth,
  label,
  size,
  color = 'gray-text',
  bold = false,
  icon,
  className,
  children,
  contentAlignRight,
  style,
}: DataItemLabelProps): JSX.Element => {
  horizontalWidth = horizontalWidth ? horizontalWidth : instanceLayout === 'horizontal' ? 40 : 100;
  const labelWrapperStyles: React.CSSProperties = {
    flex: `${horizontalWidth / 100} 0 0`,
    ...style,
  };

  const labelObj = getLabelAsObject(label, bold, size, color);

  return (
    <div className={`${styles.label_wrapper} ${className ? className : ''}`} style={labelWrapperStyles}>
      {renderLabelCommonMarkup(labelObj, icon)}
      {children && (
        <div className={`${styles.extra_content} ${contentAlignRight ? styles.at_right : ''}`}>{children}</div>
      )}
    </div>
  );
};

DataItem.Value = ({
  horizontalWidth,
  value = '-',
  alignRight = false,
  format = 'normal',
  size = 'regular',
  color,
  bold = false,
  extraText,
  infoBubble,
  icon,
  className,
  children,
  contentAlignRight,
  style,
}: DataItemValueProps): JSX.Element => {
  if (!horizontalWidth && dotLabelWidth) horizontalWidth = 100 - dotLabelWidth;
  if (!horizontalWidth) horizontalWidth = instanceLayout === 'horizontal' ? 60 : 100;

  const valueObj = getValueAsObject(value, bold, size, format, color);

  const valueWrapperStyles: React.CSSProperties = {};
  valueWrapperStyles.flex = `${horizontalWidth / 100} 0 0`;
  if (color) valueWrapperStyles.color = getAsSystemColorOrUnchanged(color);
  if (alignRight) valueWrapperStyles.justifyContent = 'flex-end';

  return (
    <div
      className={`${styles.value_wrapper} ${className ? className : ''}`}
      style={{
        ...valueWrapperStyles,
        ...style,
      }}
    >
      {renderValueCommonMarkup(valueObj, icon, extraText, infoBubble)}
      {children && (
        <div className={`${styles.extra_content} ${contentAlignRight ? styles.at_right : ''}`}>{children}</div>
      )}
    </div>
  );
};

export default DataItem;
