import { IReportSearchCriteria } from './types';

export const argumentifyReportSearchCriteria = ({
  createdFrom,
  createdTo,
  isOfficeSelected,
  isEstateAdminSelected,
  isTrusteeSelected,
}: IReportSearchCriteria) =>
  [createdFrom, createdTo, isOfficeSelected, isEstateAdminSelected, isTrusteeSelected] as const;

export const convertNumberToPercentage = (data?: number) => {
  if (isNaN(Number(data))) return '0%';
  return `${data}%`;
};
