import React, { ReactNode } from 'react';

/*
    This function can be used in any case where we need to recursively loop through
    all the children of a component and then do something to those children.

    The function itslef only maps and returns the children. A separate function can
    be pass as a callback (processChild) to handle specific processing requirements.
*/

export const deepMapChildren = (children: any, processChild: (child: any) => ReactNode | void) => {
  return React.Children.map(children, (child: any) => {
    if (child) {
      // console.log('child:', child);
      if (child.props != null && child.props.children != null) {
        child = React.cloneElement(child, {
          children: deepMapChildren(child.props.children, processChild),
        });
      }

      if (child.type) {
        return processChild(child);
      } else {
        return child;
      }
    }
  });
};

export const deepScanChildren = (children: any, processChild: (child: any) => void) => {
  React.Children.map(children, (child: any) => {
    if (child) {
      if (child.props != null && child.props.children != null) {
        deepScanChildren(child.props.children, processChild);
      }

      if (child.type) {
        processChild(child);
      }
    }
  });
};
