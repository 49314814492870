import { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useQuery } from 'react-query';
import { Form, Select, Col, Row, Spin, Divider, FormInstance, ConfigProvider } from 'antd';
import moment, { Moment } from 'moment';

import DatePicker from '../DatePicker/DatePicker';
import AvailableTimeslots from './FormComponents/AvailableTimeslots/AvailableTimeslots';
import Summary from './FormComponents/Summary';
import Calendar from '../Calendar/Calendar';
import AgentSelect from './FormInputs/AgentSelect';
import NextAvailableRow from './FormComponents/NextAvailableRow';
import LocationSelect from './FormInputs/LocationSelect';
import MeetingTypeSelect from './FormInputs/MeetingTypeSelect';
import SelectSuffixIcon from '../../components/SelectSuffixIcon/SelectSuffixIcon';

import useLocale from '../../hooks/useLocale';
import {
  argumentifyTimeslotsSearchCriteria,
  getAvailableTimeslotsByMeetingType,
  argumentifyOfficeLocationsSearchCriteria,
  getAgentByPreselectRuleForAppointment,
} from './utils';
import {
  APPOINTMENT_STATUSES_QUERY,
  MEETING_TYPES_QUERY,
  APPOINTMENT_TYPES_QUERY,
  OFFICE_LOCATIONS_QUERY,
  USE_QUERY_OPTIONS,
  EXCLUDE_INACTIVE,
} from '../../constants/reactQuery';
import { DATE_FORMAT2, DATE_FORMAT_PLACEHOLDER, MAX_PAGE_SIZE } from '../../constants/common';
import { ALL_OPTION_ID } from './constants';
import { ISelectedTimeslot, ISchedulingForm } from './types';

import { ClientService } from '../../shared/api/ClientService';
import API, { GetPreSelectedAppointmentDateClientAPI } from '../../utils/api';
import { PdsUserClientAPI } from '../../utils/identityServiceApi';

import './SchedulingComponent.scss';
import { convertInputDateToServerDateOnly, getEmptyGuid, isEmptyGuid } from '../../utils/helpers';

export interface ISchedulingComponentProps {
  form?: FormInstance<ISchedulingForm>;
  fromDebtorProfile?: boolean;
  fixedOfficeLocationId?: string;
  fixedAppointmentTypeId?: string;
  fixedMeetingTypeId?: string;
  fixedAgentTeamNames?: string[];
  appointmentId?: string;
  submitComponent?: (disabled?: boolean) => JSX.Element;
  isFirstCounsellingSession?: boolean;
}

const SchedulingComponent = ({
  form,
  fromDebtorProfile,
  fixedOfficeLocationId,
  fixedAppointmentTypeId,
  fixedMeetingTypeId,
  fixedAgentTeamNames,
  submitComponent,
  isFirstCounsellingSession,
  ...props
}: ISchedulingComponentProps) => {
  const { t } = useLocale();
  const { Option } = Select;

  const params = useParams<{ applicationFileId: string; appointmentId?: string }>();
  const appointmentId = params.appointmentId || props.appointmentId;

  const [initialTimeslots, setInitialTimeslots] = useState<ClientService.AppointmentTimeSlotDto[]>();
  const [initialAppointment, setInitialAppointment] = useState<ClientService.InitialAppointmentsDto>();
  const [disabled, setDisabled] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  const [source, setSource] = useState<ClientService.AppointmentTimeSlotDto[]>();
  const [filteredAgentIds, setFilteredAgentsIds] = useState<string[]>();

  const { data: meetingTypes } = useQuery([MEETING_TYPES_QUERY], () => API.listMeetingTypes(), USE_QUERY_OPTIONS);
  const { data: appointmentStatuses } = useQuery(
    [APPOINTMENT_STATUSES_QUERY],
    () => API.listAppointmentStatuses(),
    USE_QUERY_OPTIONS
  );
  const { data: appointmentTypes } = useQuery(
    [APPOINTMENT_TYPES_QUERY],
    () => API.listAppointmentTypes(params.applicationFileId),
    {
      refetchOnWindowFocus: false,
      refetchOnMount: true,
    }
  );
  const { data: officeLocations } = useQuery(
    [OFFICE_LOCATIONS_QUERY, EXCLUDE_INACTIVE, MAX_PAGE_SIZE],
    () =>
      API.officeLocationsGET(
        ...argumentifyOfficeLocationsSearchCriteria({ maxResultCount: MAX_PAGE_SIZE, includeInactive: false })
      ),
    USE_QUERY_OPTIONS
  );

  const getRoleNamesByAppointmentType = useCallback(
    (appointmentTypeId?: string) => {
      const appointmentTypeEnum = appointmentTypes?.find((item) => item?.id === appointmentTypeId)?.enumValue;

      if (
        appointmentTypeEnum === ClientService.AppointmentTypeEnum.Counselling_1st ||
        appointmentTypeEnum === ClientService.AppointmentTypeEnum.Counselling_2nd ||
        appointmentTypeEnum === ClientService.AppointmentTypeEnum.Counselling_3rd
      )
        return ['Counsellors'];
      else if (appointmentTypeEnum === ClientService.AppointmentTypeEnum.InitialConsultation)
        return ['Estate Administrator', 'Agent', 'Trustee'];
      else if (appointmentTypeEnum === ClientService.AppointmentTypeEnum.MeetingWithCreditors)
        return ['Estate Administrator', 'Proposal Frontliner'];
    },
    [appointmentTypes]
  );

  const checkIsAppointmentTypeNeedFiltering = useCallback(
    (appointmentTypeId?: string) => {
      const roleNames = getRoleNamesByAppointmentType(appointmentTypeId);
      return Array.isArray(roleNames) && roleNames.length > 0;
    },
    [getRoleNamesByAppointmentType]
  );

  const getUniqueAgentsArray = useCallback(
    (initialTimeslots?: ClientService.AppointmentTimeSlotDto[]) =>
      initialTimeslots
        ?.map((timeslot) => timeslot?.availableAgents)
        ?.flat()
        ?.map((agent) => agent?.agentId as string)
        ?.filter(Boolean)
        ?.filter((value, index, array) => {
          return array.indexOf(value) === index;
        }),
    []
  );

  const getFilterdAgentsIds = useCallback(
    async (initialTimeslots?: ClientService.AppointmentTimeSlotDto[]) => {
      const uniqueAgentsIds = getUniqueAgentsArray(initialTimeslots);
      if (!uniqueAgentsIds || !uniqueAgentsIds?.length) return;

      const appointmentTypeId = form?.getFieldValue('appointmentTypeId');
      const filterRoleNames = getRoleNamesByAppointmentType(appointmentTypeId);

      return await PdsUserClientAPI.filterUsers(uniqueAgentsIds, '', filterRoleNames, undefined);
    },
    [getUniqueAgentsArray, getRoleNamesByAppointmentType]
  );

  const updateTimeslotsToIncludeFilteredAgentsOnly = useCallback(
    (filteredAgentIds?: string[], timeslots?: ClientService.AppointmentTimeSlotDto[]) =>
      timeslots
        ?.filter((item) =>
          item?.availableAgents?.find(
            (agent) => agent?.agentId && (filteredAgentIds || [])?.indexOf(agent?.agentId) >= 0
          )
        )
        ?.map((item) => ({
          ...item,
          availableAgents: item?.availableAgents?.filter(
            (agent) => agent?.agentId && (filteredAgentIds || [])?.indexOf(agent?.agentId) >= 0
          ),
        })) as ClientService.AppointmentTimeSlotDto[],
    []
  );

  const requestAppointmentDetails = useCallback(async (appointmentId) => {
    if (appointmentId) {
      setLoading(true);
      const response = await API.getAppointmentDetails(appointmentId).catch(() => setLoading(false));
      if (response) setInitialAppointment(response);
      setLoading(false);
    }
  }, []);

  const requestAppointmentTimes = useCallback(
    async (date: moment.Moment, officeLocationId: string, timeRange?: ClientService.AppointmentTimeRangeEnum) => {
      setLoading(true);
      //console.log("Request Time Slots For ",date,date.local(),date.toDate());
      const appointmentTypeId = form?.getFieldValue('appointmentTypeId');
      const response = await API.getAvailableTimeSlotsByTeam(
        ...argumentifyTimeslotsSearchCriteria({
          //date: moment(date.format('YYYY MM DD, hh:mm')), //This is required to fix the bug for the times which their equivalent UTC goes to the next day
          fileId: params?.applicationFileId,
          date: convertInputDateToServerDateOnly(date),
          officeLocationId,
          timeRange: timeRange || ClientService.AppointmentTimeRangeEnum.Any,
          meetingTypeId: getEmptyGuid(), // meeting types are handled in FE and we only need them to be processed in BE for nextAvailable method so here we send emptyGuid.
          appointmentTypeId: appointmentTypeId,
          teamNames: fixedAgentTeamNames,
        })
      ).catch(() => setLoading(false));

      if (response) {
        const filteredAgentIds = await getFilterdAgentsIds(response);
        setFilteredAgentsIds(filteredAgentIds);

        setInitialTimeslots(
          checkIsAppointmentTypeNeedFiltering(appointmentTypeId)
            ? updateTimeslotsToIncludeFilteredAgentsOnly(filteredAgentIds, response)
            : response
        );

        setSource(response);
      }
      setLoading(false);
    },
    [
      checkIsAppointmentTypeNeedFiltering,
      fixedAgentTeamNames,
      form,
      getFilterdAgentsIds,
      params?.applicationFileId,
      updateTimeslotsToIncludeFilteredAgentsOnly,
    ]
  );

  const requestAppointmentTimesAndPresetDate = useCallback(
    async (
      date: moment.Moment,
      officeLocationId: string,
      timeRange?: ClientService.AppointmentTimeRangeEnum,
      meetingTypeId?: string
    ) => {
      setLoading(true);
      //console.log("Request Time Slots For ",date,date.local(),date.toDate());
      const appointmentTypeId = form?.getFieldValue('appointmentTypeId');

      const response = await API.getAvailableTimeSlotsByTeam(
        ...argumentifyTimeslotsSearchCriteria({
          //date: moment(date.format('YYYY MM DD, hh:mm')), //This is required to fix the bug for the times which their equivalent UTC goes to the next day
          fileId: params?.applicationFileId,
          date: convertInputDateToServerDateOnly(date),
          officeLocationId,
          timeRange: timeRange || ClientService.AppointmentTimeRangeEnum.Any,
          meetingTypeId: meetingTypeId || getEmptyGuid(),
          appointmentTypeId: appointmentTypeId,
          teamNames: fixedAgentTeamNames,
        })
      ).catch(() => setLoading(false));

      if (response) {
        const values = form?.getFieldsValue();
        setSource(response);

        const filteredAgentIds = await getFilterdAgentsIds(response);
        setFilteredAgentsIds(filteredAgentIds);

        const timeslots = checkIsAppointmentTypeNeedFiltering(values?.appointmentTypeId)
          ? updateTimeslotsToIncludeFilteredAgentsOnly(filteredAgentIds, response)
          : response;
        setInitialTimeslots(timeslots);

        const availableTimeslots = getAvailableTimeslotsByMeetingType(timeslots, values?.meetingTypeId);
        const firstAvailableTimeslot = availableTimeslots?.[0];
        const selectedTimeslot = firstAvailableTimeslot as ISelectedTimeslot;
        const firstAvailableAgentMeetingTypeId = firstAvailableTimeslot?.availableAgents?.[0]?.meetingTypes?.[0]?.id;

        if (selectedTimeslot) {
          selectedTimeslot.timeslotMeetingTypeId = firstAvailableAgentMeetingTypeId;
        }
        form?.setFieldsValue({
          date: firstAvailableTimeslot?.time ? moment(firstAvailableTimeslot.time) : values?.date,
          agentId: selectedTimeslot
            ? getAgentByPreselectRuleForAppointment(selectedTimeslot?.availableAgents, firstAvailableAgentMeetingTypeId)
            : undefined,
          availableTimeslots,
          selectedTimeslot,
        });
      }
      setLoading(false);
    },
    [fixedAgentTeamNames, form, getFilterdAgentsIds, params?.applicationFileId]
  );

  const nextAvailableTimeSlots = useCallback(
    (date: moment.Moment, officeLocationId: string, timeRange?: ClientService.AppointmentTimeRangeEnum) => {
      requestAppointmentTimesAndPresetDate(date, officeLocationId, timeRange, form?.getFieldValue('meetingTypeId'));
    },
    [fixedAgentTeamNames, form, getFilterdAgentsIds, params?.applicationFileId]
  );

  const fillFormWithAppointmentDetails = useCallback(
    async (initialAppointment: ClientService.InitialAppointmentsDto) => {
      const appointmentDetails = initialAppointment?.appointmentDetails;

      if (initialAppointment?.appointmentTimeSlots) {
        const appointmentTypeId = appointmentDetails?.appointmentTypeId;
        form?.setFieldsValue({ appointmentTypeId: appointmentDetails?.appointmentTypeId });
        const filteredAgentIds = await getFilterdAgentsIds(initialAppointment?.appointmentTimeSlots);
        setFilteredAgentsIds(filteredAgentIds);
        if (checkIsAppointmentTypeNeedFiltering(appointmentTypeId)) {
          initialAppointment.appointmentTimeSlots = updateTimeslotsToIncludeFilteredAgentsOnly(
            filteredAgentIds,
            initialAppointment?.appointmentTimeSlots
          );
        }
      }

      form?.setFieldsValue({
        date: appointmentDetails?.appointmentDateTime,
        time: appointmentDetails?.appointmentDateTime,
        meetingTypeId: appointmentDetails?.meetingTypeId,
        officeLocationId:
          appointmentDetails?.officeLocationId && !isEmptyGuid(appointmentDetails?.officeLocationId)
            ? appointmentDetails?.officeLocationId
            : undefined,
        appointmentTypeId: appointmentDetails?.appointmentTypeId,
        agentId: appointmentDetails?.agentId,
        appointmentStatusId: appointmentDetails?.appointmentStatusId,
        availableTimeslots: initialAppointment?.appointmentTimeSlots,
        selectedTimeslot: {
          ...initialAppointment?.appointmentTimeSlots?.find(
            (i) => i.time?.toString() == appointmentDetails?.appointmentDateTime?.toString()
          ),
          timeslotMeetingTypeId: appointmentDetails?.meetingTypeId,
        },
      });
    },
    [form, appointmentTypes]
  );

  const fillFormWithDefaultValues = useCallback(
    async (InitialOfficeLocationID: string | undefined) => {
      console.log('fillFormWithDefaultValues Called');
      const date =
        isFirstCounsellingSession && params?.applicationFileId && fixedOfficeLocationId
          ? await GetPreSelectedAppointmentDateClientAPI.session1(params?.applicationFileId, fixedOfficeLocationId)
          : undefined;

      form?.setFieldsValue({
        officeLocationId: fixedOfficeLocationId,
        appointmentTypeId: fixedAppointmentTypeId || appointmentTypes?.[0]?.id,
        date: moment(date),
        meetingTypeId: fixedMeetingTypeId || ALL_OPTION_ID,
      });

      if (fixedOfficeLocationId) {
        console.log('fixedOfficeLocationId', fixedOfficeLocationId);
        await requestAppointmentTimes(moment(date), fixedOfficeLocationId);
      } else if (InitialOfficeLocationID) {
        console.log('InitialOfficeLocationID', InitialOfficeLocationID);
        await requestAppointmentTimes(moment(date), InitialOfficeLocationID);
      }
    },
    [
      params?.applicationFileId,
      fixedOfficeLocationId,
      isFirstCounsellingSession,
      form,
      fixedAppointmentTypeId,
      appointmentTypes,
      fixedMeetingTypeId,
      requestAppointmentTimes,
    ]
  );

  const fillFormWithAvailableTimeslots = useCallback(
    async (initialTimeslots: ClientService.AppointmentTimeSlotDto[]) => {
      const values = form?.getFieldsValue();
      const availableTimeslots = getAvailableTimeslotsByMeetingType(initialTimeslots, values?.meetingTypeId);

      const firstAvailableTimeslot = availableTimeslots?.[0];
      const selectedTimeslot = firstAvailableTimeslot as ISelectedTimeslot;
      const firstAvailableAgentMeetingTypeId = firstAvailableTimeslot?.availableAgents?.[0]?.meetingTypes?.[0]?.id;

      const AllMeetingTypes = await API.listMeetingTypes();

      if (selectedTimeslot) {
        if (
          AllMeetingTypes.find((i) => i.id == values?.meetingTypeId)?.enumValue !=
            ClientService.MeetingTypeEnum.InPerson &&
          AllMeetingTypes.find((i) => i.id == values?.meetingTypeId)?.enumValue != ClientService.MeetingTypeEnum.Phone
        ) {
          selectedTimeslot.timeslotMeetingTypeId = firstAvailableAgentMeetingTypeId;
        } else {
          selectedTimeslot.timeslotMeetingTypeId = values?.meetingTypeId;
        }
      }
      //console.log("firstAvailableTimeslot",firstAvailableTimeslot?.time,"Moment", moment(firstAvailableTimeslot?.time));
      form?.setFieldsValue({
        //date: firstAvailableTimeslot?.time ? moment(firstAvailableTimeslot.time) : values?.date,
        agentId: selectedTimeslot
          ? getAgentByPreselectRuleForAppointment(selectedTimeslot?.availableAgents, firstAvailableAgentMeetingTypeId)
          : undefined,
        availableTimeslots,
        selectedTimeslot,
        // meetingTypeId:
        //   fixedMeetingTypeId ||
        //   getMeetingTypeIfOnlyOneAvailable(initialTimeslots, meetingTypes) ||
        //   values?.meetingTypeId,
      });
      //////
    },
    [form]
  );

  const shouldDisableForm = useCallback(
    (initialAppointment: ClientService.InitialAppointmentsDto, appointmentStatuses: ClientService.LookupDto[]) => {
      const appointmentDetails = initialAppointment?.appointmentDetails;
      const appointmentStatusEnum = appointmentStatuses?.find(
        (item) => item.id === appointmentDetails?.appointmentStatusId
      )?.enumValue;
      return (
        (appointmentStatusEnum === ClientService.AppointmentStatusEnum.Completed ||
          appointmentStatusEnum === ClientService.AppointmentStatusEnum.Cancelled) &&
        moment(appointmentDetails?.appointmentDateTime).isBefore(moment())
      );
    },
    []
  );

  const shouldFilterOutUnknown = useCallback(() => {
    const unknownMeetingTypeId = meetingTypes?.find((i) => i.enumValue === ClientService.MeetingTypeEnum.Unknown)?.id;

    return !appointmentId || unknownMeetingTypeId !== initialAppointment?.appointmentDetails?.meetingTypeId;
  }, [initialAppointment, setInitialAppointment, appointmentId]);

  useEffect(() => {
    if (initialAppointment && appointmentStatuses) {
      const disabled = Boolean(appointmentId) && shouldDisableForm(initialAppointment, appointmentStatuses);
      setDisabled(disabled);
    }
  }, [appointmentId, appointmentStatuses, initialAppointment, shouldDisableForm]);

  useEffect(() => {
    console.log('initialTimeslots', initialTimeslots);
    if (initialTimeslots) fillFormWithAvailableTimeslots(initialTimeslots);
  }, [fillFormWithAvailableTimeslots, initialTimeslots, appointmentStatuses]);

  useEffect(() => {
    //
    //Note: Need to make sure appointmentTypes got values first before fill form data.
    //
    if (!initialTimeslots && !appointmentId && appointmentTypes) {
      //fillFormWithDefaultValues((officeLocations?.items as ClientService.OfficeLocationDto[])[0].id!);
      fillFormWithDefaultValues(undefined);
    }
  }, [appointmentId, fillFormWithDefaultValues, initialTimeslots, appointmentTypes, officeLocations]);

  useEffect(() => {
    setInitialTimeslots(undefined);
    setSource(undefined);
  }, [fixedOfficeLocationId, fixedAppointmentTypeId, fixedMeetingTypeId]);

  useEffect(() => {
    if (appointmentId) requestAppointmentDetails(appointmentId);
  }, [requestAppointmentDetails, appointmentId]);

  useEffect(() => {
    if (initialAppointment) fillFormWithAppointmentDetails(initialAppointment);
  }, [fillFormWithAppointmentDetails, initialAppointment]);

  if (!form) {
    return <>{t.NO_FORM}!</>;
  }

  return (
    <ConfigProvider
      getPopupContainer={(node?: HTMLElement) => {
        if (node) {
          return node.parentElement as HTMLElement;
        }
        return document.body;
      }}
    >
      <div className="SchedulingComponent">
        <Spin spinning={loading}>
          <Form.Item
            noStyle
            shouldUpdate={(prev, curr) => {
              if (
                prev?.appointmentTypeId !== curr?.appointmentTypeId &&
                checkIsAppointmentTypeNeedFiltering(curr?.appointmentTypeId) !==
                  checkIsAppointmentTypeNeedFiltering(prev?.appointmentTypeId)
              ) {
                const timeslots = checkIsAppointmentTypeNeedFiltering(curr?.appointmentTypeId)
                  ? updateTimeslotsToIncludeFilteredAgentsOnly(filteredAgentIds, source)
                  : source;
                setInitialTimeslots(timeslots);
              }
              return true;
            }}
          >
            {({ getFieldValue }) => {
              const officeLocationId = getFieldValue('officeLocationId');
              const date = getFieldValue('date');
              return (
                <>
                  <Row gutter={40} align="middle">
                    <Col span={12}>
                      <Form.Item
                        label={t.APPOINTMENT_SCHEDULING_APPOINTMENT_TYPE}
                        name="appointmentTypeId"
                        rules={[{ required: true, message: t.LOCATION_IS_REQUIRED }]}
                      >
                        <Select
                          suffixIcon={<SelectSuffixIcon />}
                          size="large"
                          disabled={disabled || Boolean(fixedAppointmentTypeId) || fromDebtorProfile}
                        >
                          {appointmentTypes?.map((item) => (
                            <Option key={item.id} value={item.id}>
                              {item.name}
                            </Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </Col>

                    <Col span={12}>
                      <LocationSelect
                        onChange={(officeLocationId) => {
                          console.log('OnChanged Fired');
                          requestAppointmentTimes(date || moment(), officeLocationId);
                        }}
                        disabled={disabled}
                        form={form}
                        prefillWithClosestOfficeDetails={!appointmentId}
                      />
                    </Col>
                  </Row>

                  <Divider />

                  <Row align="top" wrap gutter={40}>
                    <Col span={12}>
                      <Form.Item label={t.APPOINTMENT_SCHEDULING_AVAILABLE_DATES} name="date">
                        <Calendar
                          disabledDate={(date) =>
                            date.isBefore(moment(), 'day') || !date.isSame(date, 'month') || disabled
                          }
                          cellStyle={{
                            height: 66,
                            marginRight: 4,
                            display: 'flex',
                            flex: 1,
                            flexDirection: 'column',
                          }}
                          onChange={(date) => {
                            if (date && !Array.isArray(date)) requestAppointmentTimes(date, officeLocationId);
                          }}
                          disabled={disabled}
                        />
                      </Form.Item>
                      <NextAvailableRow onClick={nextAvailableTimeSlots} disabled={disabled} />
                    </Col>
                    <Col span={12}>
                      <Form.Item
                        label={t.APPOINTMENT_SCHEDULING_DATE}
                        name="date"
                        rules={[{ required: true, message: t.DATE_IS_REQUIRED }]}
                      >
                        <DatePicker
                          format={DATE_FORMAT2}
                          dateSetter={null}
                          size="large"
                          disabledDate={(date) => date.isBefore(moment(), 'day')}
                          placeholder={DATE_FORMAT_PLACEHOLDER}
                          onChange={(date: Moment | null) => {
                            if (date) {
                              requestAppointmentTimes(date, officeLocationId);
                            }
                          }}
                          disabled={disabled}
                          className="SchedulingComponent__date-picker"
                        />
                      </Form.Item>
                      <MeetingTypeSelect
                        initialTimeslots={initialTimeslots || initialAppointment?.appointmentTimeSlots}
                        disabled={disabled || Boolean(fixedMeetingTypeId)}
                        filterOutUnknown={shouldFilterOutUnknown()}
                      />
                      {/* Available Appointments section. #20024 */}
                      <AvailableTimeslots disabled={disabled} />
                      {/* Agents section. Select Available Agents based on deptor's prefer language. #20024 */}
                      <AgentSelect disabled={disabled} />
                      <Summary />
                      <Row justify="space-between" align="bottom" gutter={12}>
                        <Col span={8}>
                          {appointmentId && (
                            <Form.Item label={t.APPOINTMENT_SCHEDULING_STATUS} name="appointmentStatusId">
                              <Select suffixIcon={<SelectSuffixIcon />} size="large" disabled={disabled}>
                                {appointmentStatuses?.map((item) => (
                                  <Option key={item.id} value={item.id}>
                                    {item.name}
                                  </Option>
                                ))}
                              </Select>
                            </Form.Item>
                          )}
                        </Col>
                        {submitComponent && submitComponent(disabled)}
                      </Row>
                    </Col>
                  </Row>
                </>
              );
            }}
          </Form.Item>
        </Spin>
      </div>
    </ConfigProvider>
  );
};

export default SchedulingComponent;
