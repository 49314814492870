import { useCallback, useEffect, useState } from 'react';
import ButtonContainer from '../ButtonContainer/ButtonContainer';
import Button from '../Button/Button';
import { ClientService } from '../../shared/api/ClientService';
import API from '../../utils/api';

import './AppTaskActionNavigator.scss';

interface IProps {
  currentItemId: string | undefined;

  onGotoItem?: (id: string) => void;
}

const AppTaskActionNavigator = ({ currentItemId, onGotoItem }: IProps): JSX.Element => {
  const [nav, setNavigationData] = useState(new ClientService.AppTaskActionNavigationDto());
  const [displayPrevButton, setDisplayPrevButton] = useState(false);
  const [displayNextButton, setDisplayNextButton] = useState(false);

  const updateNavigationData = useCallback((taskActionId: string | undefined) => {
    if (!taskActionId) return;

    API.appTaskActionNavigation(taskActionId)
      .then((response) => {
        if (response) {
          setNavigationData(response);

          if (response.previousItemId !== null) setDisplayPrevButton(true);
          if (response.nextItemId !== null) setDisplayNextButton(true);
        }
      })
      .catch((error) => {});
  }, []);

  useEffect(() => {
    updateNavigationData(currentItemId);
  }, [currentItemId, updateNavigationData]);

  return (
    <ButtonContainer inline className="NavigationButtons">
      {displayPrevButton && (
        <Button
          iconName="ChevronLeftSmall"
          narrow
          kind="darkgray"
          padding="medium"
          onClick={() => {
            onGotoItem && onGotoItem(nav.previousItemId ?? '');
          }}
        />
      )}
      {displayNextButton && (
        <Button
          iconName="ChevronRightSmall"
          narrow
          kind="darkgray"
          padding="medium"
          onClick={() => {
            onGotoItem && onGotoItem(nav.nextItemId ?? '');
          }}
        />
      )}
    </ButtonContainer>
  );
};

export default AppTaskActionNavigator;
