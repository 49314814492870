import React, { ReactNode } from 'react';
import styles from './ButtonContainer.module.scss';

interface IProps {
  inline?: boolean;
  children?: ReactNode;
  className?: string;
  style?: React.CSSProperties;
}

const ButtonContainer = ({ inline, className, children, style }: IProps): JSX.Element => {
  return (
    <div
      className={`${styles.ButtonContainer} ${inline ? styles.inline : ''} ${className ? className : ''}`}
      style={style}
    >
      {children}
    </div>
  );
};

export default ButtonContainer;
