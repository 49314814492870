export const WEEKDAYS_OPTIONS = [
  { label: 'Sunday', value: 'sundayEntries', shortName: 'Sun' },
  {
    label: 'Monday',
    value: 'mondayEntries',
    shortName: 'Mon',
  },
  {
    label: 'Tuesday',
    value: 'tuesdayEntries',
    shortName: 'Tue',
  },
  {
    label: 'Wednesday',
    value: 'wednesdayEntries',
    shortName: 'Wed',
  },
  {
    label: 'Thursday',
    value: 'thursdayEntries',
    shortName: 'Thu',
  },
  {
    label: 'Friday',
    value: 'fridayEntries',
    shortName: 'Fri',
  },
  {
    label: 'Saturday',
    value: 'saturdayEntries',
    shortName: 'Sat',
  },
];
