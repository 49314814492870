import { createContext, useState, useCallback } from 'react';
import type { UploadFile } from 'antd/es/upload/interface';
import { ClientService } from '../shared/api/ClientService';
import moment from 'moment';

import API from '../utils/api';

import {
  argumentifyUpdateDocument,
  argumentifyCreateDocument,
  sanitizeDocumentName,
} from '../pages/ApplicationOverviewPage/DocumentsContent/utils';

export interface FileProps extends UploadFile {
  documentFolderId?: string;
  documentPrefixId?: string;
  additionalInformation?: string;
}

interface IAttachmentContext {
  newFiles: FileProps[];
  setNewFiles: (data: FileProps[]) => void;
  existingFiles: ClientService.DocumentDto[];
  setExistingFiles: (data: ClientService.DocumentDto[]) => void;
  requestFilesUpload: (fileId: string) => void;
}

interface IProps {
  children: JSX.Element;
}

const AttachmentsContext = createContext<IAttachmentContext>({
  newFiles: [],
  setNewFiles: () => {},
  existingFiles: [],
  setExistingFiles: () => {},
  requestFilesUpload: () => {},
});

const AttachmentsProvider = ({ children }: IProps) => {
  const [newFiles, setNewFiles] = useState<FileProps[]>([]);
  const [existingFiles, setExistingFiles] = useState<ClientService.DocumentDto[]>([]);

  const requestFilesUpload = async (fileId: string) => {
    for (const file of newFiles) {
      const { documentFolderId, documentPrefixId, additionalInformation, fileName, originFileObj } = file;
      await API.documentsPOST(
        ...argumentifyCreateDocument({
          fileId,
          fileModifiedDate: moment(),
          content: { fileName: sanitizeDocumentName(fileName as string), data: originFileObj as Blob },
          isVisible: false, // By default, not visible. 22521.
          documentFolderId,
          documentPrefixId,
          additionalInformation,
          isForSpouse: false, // UPDATE, when backend fixed
        })
      );
    }
  };

  return (
    <AttachmentsContext.Provider value={{ newFiles, setNewFiles, existingFiles, setExistingFiles, requestFilesUpload }}>
      {children}
    </AttachmentsContext.Provider>
  );
};

export { AttachmentsContext, AttachmentsProvider };
