import { useCallback, useState, useEffect } from 'react';
import { Input, Col, Row, Form, Checkbox, FormInstance, Select } from 'antd';
import { Icon } from '@fluentui/react/lib/Icon';
import { useQuery } from 'react-query';

import Divider from '../../../../components/Divider/Divider';
import SearchLocationInput from '../../../../components/SearchLocationInput/SearchLocationInput';
import LabelWithExtra from '../components/LabelWithExtra';
import PhoneFormItem from '../components/PhoneFormItem';
import SelectLookupDto from '../components/SelectLookupDto';
import SelectSuffixIcon from '../../../../components/SelectSuffixIcon/SelectSuffixIcon';

import useLocale from '../../../../hooks/useLocale';
import { LocationDto } from '../../../../modals/GoogleMaps';
import { formatAddressLine } from '../../utils';
import {
  USE_QUERY_OPTIONS,
  PROVINCES_QUERY,
  COUNTRIES_QUERY,
  PHONE_TYPES_QUERY,
} from '../../../../constants/reactQuery';
import { ClientService } from '../../../../shared/api/ClientService';
import API from '../../../../utils/api';

import '../DebtorProfileContent.scss';

interface IProps {
  formInstance: FormInstance<ClientService.DebtorProfileDto>;
  countryId?: string;
  disabled?: boolean;
}

const ContactInformation = ({ formInstance, countryId, disabled = false }: IProps) => {
  const { t } = useLocale();
  const { Option } = Select;

  const [selectedCountryId, setSelectedCountryId] = useState<string | undefined>();
  const [selectedProvinceCode, setSelectedProvinceCode] = useState<string>();

  const { data: phoneTypes } = useQuery([PHONE_TYPES_QUERY], () => API.listPhoneTypes(), USE_QUERY_OPTIONS);
  const { data: countries } = useQuery([COUNTRIES_QUERY], () => API.listCountries(), USE_QUERY_OPTIONS);

  const { data: provinces } = useQuery(
    [PROVINCES_QUERY, selectedCountryId],
    () => {
      if (selectedCountryId) return API.listProvinces(selectedCountryId);
    },
    USE_QUERY_OPTIONS
  );

  const handleAddressAutocomplete = useCallback(
    async (autofilledAddress: LocationDto) => {
      setSelectedCountryId(autofilledAddress?.country?.id);
      setSelectedProvinceCode(autofilledAddress?.province?.code);

      formInstance.setFieldsValue({
        unit: autofilledAddress?.unit,
        city: autofilledAddress?.city,
        postalCode: autofilledAddress?.postalCode,
        streetName: autofilledAddress?.streetName,
        streetNumber: autofilledAddress?.streetNumber,
        address: formatAddressLine(autofilledAddress),
        latitude: autofilledAddress?.latitude,
        longitude: autofilledAddress?.longitude,
      });
    },
    [formInstance]
  );

  const handleResetValuesOnProvinceChange = useCallback(() => {
    formInstance.setFieldsValue({
      address: undefined,
      unit: undefined,
      city: undefined,
      postalCode: undefined,
      streetName: undefined,
      streetNumber: undefined,
      latitude: undefined,
      longitude: undefined,
    });
  }, [formInstance]);

  const handleResetValuesOnCountryChange = useCallback(() => {
    formInstance.setFieldsValue({ provinceId: undefined });
    handleResetValuesOnProvinceChange();
  }, [formInstance, handleResetValuesOnProvinceChange]);

  useEffect(() => {
    const values = formInstance.getFieldsValue();
    const newProvinceId = provinces?.find((item) => item.code === selectedProvinceCode)?.id;

    if (newProvinceId !== values?.provinceId || selectedCountryId !== values?.countryId) {
      formInstance.setFieldsValue({
        provinceId: newProvinceId || values?.provinceId,
        countryId: selectedCountryId || values?.countryId,
      });
    }
  }, [formInstance, provinces, selectedCountryId, selectedProvinceCode]);

  useEffect(() => {
    if (countryId) setSelectedCountryId(countryId);
  }, [countryId]);

  return (
    <Form.Item noStyle shouldUpdate>
      {({ getFieldValue, getFieldsValue }) => {
        const values = getFieldsValue();
        const isRegisteredToClientPortal = getFieldValue('isRegisteredToClientPortal');
        const otherPhoneTypeIds = values?.alternatePhoneNumbers
          ?.map((item: ClientService.ClientPhoneDto) => item?.phoneTypeId)
          ?.filter(Boolean);
        const filtered = phoneTypes?.filter(
          (item) => [...(otherPhoneTypeIds || []), values?.mainPhoneTypeId]?.indexOf(item?.id) < 0
        );
        return (
          <>
            <Row gutter={20} align="bottom">
              <Col span={8}>
                <Form.Item name="address" label={t.DEBTOR_PROFILE_ADDRESS}>
                  <SearchLocationInput
                    address={{
                      address: values?.address,
                      unit: values?.unit,
                      city: values?.city,
                      postalCode: values?.postalCode,
                      streetName: values?.streetName,
                      streetNumber: values?.streetNumber,
                      province: { id: values?.provinceId },
                      country: { id: values?.countryId },
                      latitude: values?.latitude,
                      longitude: values?.longitude,
                    }}
                    onSelect={handleAddressAutocomplete}
                    countries={countries}
                    provinces={provinces}
                    size="large"
                    disabled={disabled}
                  />
                </Form.Item>
              </Col>
              <Col span={0}>
                <Form.Item label={t.STREET_NAME} name="streetName">
                  <Input disabled={disabled} />
                </Form.Item>
              </Col>
              <Col span={0}>
                <Form.Item label={t.STREET_NUMBER} name="streetNumber">
                  <Input disabled={disabled} />
                </Form.Item>
              </Col>
              <Col span={0}>
                <Form.Item label={t.LATITUDE} name="latitude">
                  <Input disabled={disabled} />
                </Form.Item>
              </Col>
              <Col span={0}>
                <Form.Item label={t.LONGITUDE} name="longitude">
                  <Input disabled={disabled} />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item label={t.DEBTOR_PROFILE_UNIT} name="unit">
                  <Input size="large" disabled={disabled} />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item label={t.DEBTOR_PROFILE_CITY} name="city">
                  <Input size="large" disabled={disabled} />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  label={t.DEBTOR_PROFILE_PROVINCE}
                  name="provinceId"
                  required
                  rules={[{ required: true, message: t.REQUIRED_FIELD }]}
                >
                  <SelectLookupDto
                    data={provinces}
                    showSearch
                    filterOption={(input, option) => option?.title?.toLowerCase().includes(input.toLowerCase() || '')}
                    onSelect={(selectedProvinceId: string) => {
                      if (values?.provinceId !== selectedProvinceId) {
                        handleResetValuesOnProvinceChange();
                      }
                    }}
                    disabled={disabled}
                  />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item label={t.DEBTOR_PROFILE_POSTAL_CODE} name="postalCode">
                  <Input size="large" disabled={disabled} />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  label={t.DEBTOR_PROFILE_COUNTRY}
                  name="countryId"
                  required
                  rules={[{ required: true, message: t.REQUIRED_FIELD }]}
                >
                  <SelectLookupDto
                    data={countries}
                    showSearch
                    filterOption={(input, option) => option?.title?.toLowerCase().includes(input.toLowerCase() || '')}
                    onSelect={(selectedCountryId: string) => {
                      if (values?.countryId !== selectedCountryId) {
                        setSelectedCountryId(selectedCountryId);
                        handleResetValuesOnCountryChange();
                      }
                    }}
                    disabled={disabled}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Divider style={{ marginBottom: 60, marginTop: 60 }} />
            <Row gutter={20}>
              <Col span={8}>
                <PhoneFormItem
                  phoneTypeFormName="mainPhoneTypeId"
                  phoneNumberFormName="mainPhoneNumber"
                  phoneDoNotContactFormName="mainPhoneDoNotContact"
                  phoneDoNotSMSFormName="mainPhoneDoNotSMS"
                  required
                  label={t.DEBTOR_PROFILE_PRIMARY_PHONE_NUMBER}
                  faxExcluded
                  disabled={disabled}
                />
              </Col>
              <Col span={8}>
                <Form.Item
                  name="emailAddress"
                  label={
                    <LabelWithExtra
                      label={t.DEBTOR_PROFILE_EMAIL_ADDRESS}
                      required={values?.enableClientLogin}
                      extraComponent={
                        <Form.Item name="doNotEmail" valuePropName="checked" noStyle>
                          <Checkbox className="DebtorProfileContent__checkbox" disabled={disabled}>
                            {t.DNE}
                          </Checkbox>
                        </Form.Item>
                      }
                    />
                  }
                  requiredMark="optional"
                  rules={[
                    { required: values?.enableClientLogin, message: t.REQUIRED_FIELD },
                    { type: 'email', message: t.INVALID_EMAIL },
                  ]}
                >
                  <Input size="large" disabled={disabled || isRegisteredToClientPortal} />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  name="receiveEmailConsent"
                  label={t.RECEIVED_EMAIL_CONSENT}
                  required
                  rules={[{ required: true, message: t.REQUIRED_FIELD }]}
                >
                  <Select size="large" suffixIcon={<SelectSuffixIcon />}>
                    <Option value={true}>{t.YES}</Option>
                    <Option value={false}>{t.NO}</Option>
                  </Select>
                </Form.Item>
              </Col>
            </Row>

            <Form.List name="alternatePhoneNumbers">
              {(details, { add, remove }) => (
                <>
                  <Row gutter={20}>
                    <Col span={!disabled ? 8 : 0}>
                      <Form.Item>
                        <div
                          onClick={() => add({ phoneTypeId: filtered?.[0]?.id })}
                          className="DebtorProfileContent__link-button"
                          style={{ paddingBottom: 24 }}
                        >
                          {t.ADD_NUMBER}
                        </div>
                      </Form.Item>
                    </Col>
                  </Row>
                  {details?.map((row, index) => (
                    <Row gutter={20} align="middle" key={`alternative-phone-${index}`}>
                      <Col span={8}>
                        <PhoneFormItem
                          phoneTypeFormName={[row.name, 'phoneTypeId']}
                          phoneNumberFormName={[row.name, 'phoneNumber']}
                          phoneDoNotContactFormName={[row.name, 'doNotContact']}
                          phoneDoNotSMSFormName={[row.name, 'doNotSMS']}
                          required={false}
                          label={t.DEBTOR_PROFILE_ALTERNATE_PHONE_NUMBER}
                          isLabelVisible={row.name === 0}
                          disabled={disabled}
                        />
                      </Col>
                      <Col span={!disabled ? 1 : 0}>
                        <Form.Item label={t.ACTION} className="DebtorProfileContent__form-item--label-hidden">
                          <Icon
                            iconName="Delete"
                            onClick={() => remove(row.name)}
                            className="DebtorProfileContent__icon"
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                  ))}
                </>
              )}
            </Form.List>
          </>
        );
      }}
    </Form.Item>
  );
};

export default ContactInformation;
