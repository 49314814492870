//credit report collapse keys

export const BASIC_INFO = 'basic_info';
export const TRANS_ALERTS = 'trans_alerts';
export const TRADE_KEY = 'trade';
export const ADDRESS_HISTORY_KEY = 'address_history';

export const PREFIX_CODE_CONSENT_CB = 'CreditReport.ConsentCB';
export const FOLDER_CODE_LEAD = 'lead';

export const CANADA_COUNTRY_CODE = 'CA';

export const PERSONAL_INFO_TAB = 'personal_info_tab';
export const ADDRESS_TAB = 'address_tab';
export const CONSENT_TAB = 'consent_tab';
