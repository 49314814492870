import { useCallback, useState } from 'react';
import { Modal, Col, Row, Spin } from 'antd';
import update from 'immutability-helper';

import Button from '../../../../components/Button/Button';
import Divider from '../../../../components/Divider/Divider';
import { IconRed } from '../Icons';

import { ClientService } from '../../../../shared/api/ClientService';
import useLocale from '../../../../hooks/useLocale';
import API from '../../../../utils/api';
import { argumentifyUpdateDocument, readableBytes } from '../utils';

import './DebtorVisibility.scss';
import moment from 'moment';

interface IProps {
  data?: ClientService.IDocumentDto[];
  checked?: boolean;
  onOk?: () => void;
  onCancel?: () => void;
}

function DebtorVisibility({ data, checked, onOk, onCancel }: IProps): JSX.Element {
  const { t } = useLocale();

  const [documents, setDocuments] = useState<ClientService.IDocumentDto[]>(data || []);
  const [loading, setLoading] = useState<boolean>(false);

  const toggleFilesVisibility = useCallback(async () => {
    setLoading(true);

    for (const document of documents) {
      await API.documentsPUT(
        ...argumentifyUpdateDocument({
          ...document,
          id: document?.id as string,
          isVisible: checked,
          fileModifiedDate: moment(),
        })
      );
    }

    setLoading(false);
    if (onOk) onOk();
  }, [documents, onOk, checked]);

  return (
    <Modal
      destroyOnClose
      centered
      visible
      title={checked ? t.DOCUMENTS_DEBTOR_VISIBILITY : t.DOCUMENTS_DEBTOR_VISIBILITY_DISABLE}
      className="DebtorVisibility"
      width={900}
      footer={null}
      closable
      onCancel={onCancel}
    >
      <Spin spinning={loading}>
        <Row align="middle">{checked ? t.CONFIRM_SET_VISIBLE_MESSAGE : t.CONFIRM_SET_HIDDEN_MESSAGE}</Row>

        <Divider />

        <Row className="DebtorVisibility__labels">
          <Col span={8}>{t.DOCUMENTS_FILENAME}</Col>
          <Col span={7}>{t.DOCUMENTS_FOLDER}</Col>
          <Col span={8}>{t.DOCUMENTS_PREFIX}</Col>
          <Col span={1}></Col>
        </Row>
        <div className="DebtorVisibility__list">
          {documents?.map((item, index) => (
            <Row className="DebtorVisibility__list-item" key={item.id}>
              <Col span={8}>{`${item?.name}(${readableBytes(item?.fileSizeInBytes)})`}</Col>
              <Col span={7}>{item?.documentFolderName}</Col>
              <Col span={8}>{item?.documentPrefixName}</Col>
              <Col span={1}>
                {documents?.length > 1 && (
                  <IconRed
                    iconName="Delete"
                    onClick={() => setDocuments((prevState) => update(prevState, { $splice: [[index, 1]] }))}
                  />
                )}
              </Col>
            </Row>
          ))}
        </div>
        <Row gutter={12} justify="end" className="DebtorVisibility__buttons-container">
          <Col>
            <Button kind="cancel" padding="large" onClick={onCancel}>
              {t.NO}
            </Button>
          </Col>
          <Col>
            <Button kind="primary" padding="large" onClick={toggleFilesVisibility}>
              {t.YES}
            </Button>
          </Col>
        </Row>
      </Spin>
    </Modal>
  );
}

export default DebtorVisibility;
