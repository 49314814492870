import { useMemo } from 'react';
import { Menu } from 'antd';
import ButtonContainer from '../../../../../components/ButtonContainer/ButtonContainer';
import DropdownButton from '../../../../../components/Button/DropdownButton/DropdownButton';
import Button from '../../../../../components/Button/Button';
import CancelButton from '../../../../../components/Button/AppButtons/CancelButton';
import useLocale from '../../../../../hooks/useLocale';

interface IProps {
  buttonsEnabled?: boolean;
  onCancel?: () => void;
  onSaveAsDraft?: () => void;
  onSaveAsDraftWithAlert?: () => void;
  onRedundant?: () => void;
}

const ButtonsGroupCancelDraftRedundant = ({
  buttonsEnabled = true,
  onCancel,
  onSaveAsDraft,
  onSaveAsDraftWithAlert,
  onRedundant,
}: IProps): JSX.Element => {
  const { t } = useLocale();

  const draftButtonMenu = useMemo(() => {
    return (
      <Menu>
        <Menu.Item key="draft" onClick={onSaveAsDraft}>
          {t.BUTTON_SAVE_AS_DRAFT}
        </Menu.Item>
        <Menu.Item key="draft-with-alert" onClick={onSaveAsDraftWithAlert}>
          {t.BUTTON_SAVE_AS_DRAFT_WITH_ALERT}
        </Menu.Item>
      </Menu>
    );
  }, [t.BUTTON_SAVE_AS_DRAFT, t.BUTTON_SAVE_AS_DRAFT_WITH_ALERT, onSaveAsDraft, onSaveAsDraftWithAlert]);

  return (
    <ButtonContainer inline>
      <CancelButton narrow onClick={onCancel ? onCancel : undefined} />
      {onSaveAsDraftWithAlert ? (
        <DropdownButton
          disabled={!buttonsEnabled}
          narrow
          padding="medium"
          kind="darkgray"
          overlay={draftButtonMenu}
          onClick={onSaveAsDraft}
        >
          {t.BUTTON_SAVE_AS_DRAFT}
        </DropdownButton>
      ) : (
        <Button disabled={!buttonsEnabled} narrow kind="darkgray" padding="medium" onClick={onSaveAsDraft}>
          {t.BUTTON_SAVE_AS_DRAFT}
        </Button>
      )}

      <Button disabled={!buttonsEnabled} narrow padding="medium" onClick={onRedundant}>
        {t.BUTTON_REDUNDANT}
      </Button>
    </ButtonContainer>
  );
};

export default ButtonsGroupCancelDraftRedundant;
