import { useCallback, useEffect, useState } from 'react';
import { Row, Col, Menu, Dropdown, Checkbox, Form } from 'antd';

import Button from '../../../../components/Button/Button';
import { IconRed } from '../Icons';

import { ClientService } from '../../../../shared/api/ClientService';
import useLocale from '../../../../hooks/useLocale';

import '../DocumentsContent.scss';
import './Filters.scss';

interface IProps {
  listFolders?: ClientService.LookupDto[];
  documentFolders?: string[];
  onFoldersSelect?: (folders: string[]) => void;
}

function Filters({ listFolders, documentFolders = [], onFoldersSelect }: IProps): JSX.Element {
  const { t } = useLocale();
  const [form] = Form.useForm();

  const [isAllSelected, setIsAllSelected] = useState<boolean>(listFolders?.length === documentFolders.length);

  const prefillFilters = useCallback(() => {
    if (listFolders && documentFolders) {
      form.setFieldsValue({ filters: documentFolders });
    }
  }, [documentFolders, form, listFolders]);

  const handleSelectAll = useCallback(
    (e) => {
      const isChecked = e.target.checked;
      if (isChecked) form.setFieldsValue({ filters: listFolders?.map((item) => item.id) });
      setIsAllSelected(isChecked);
    },
    [form, listFolders]
  );

  const handleClearAll = useCallback(() => {
    form.resetFields();
    setIsAllSelected(false);
  }, [form]);

  const handleOnFinish = useCallback(
    (values) => {
      if (onFoldersSelect) onFoldersSelect(values?.filters);
    },
    [onFoldersSelect]
  );

  useEffect(() => {
    setIsAllSelected(listFolders?.length === documentFolders?.length);
  }, [listFolders?.length, documentFolders?.length]);

  useEffect(() => {
    prefillFilters();
  }, [documentFolders, prefillFilters]);

  const filters = (
    <Menu className="Filters">
      <Form form={form} onFinish={handleOnFinish} layout="vertical">
        <div style={{ paddingLeft: 12, marginBottom: 6 }}>
          <Checkbox checked={isAllSelected} onChange={handleSelectAll}>
            {t.ALL}
          </Checkbox>
        </div>

        <Form.Item name="filters" noStyle>
          <Checkbox.Group
            options={listFolders?.map((item) => ({ value: item.id as string, label: item.name as string }))}
          />
        </Form.Item>

        <Menu.Item>
          <Form.Item noStyle>
            <Button htmlType="submit">{t.APPLY}</Button>
          </Form.Item>
        </Menu.Item>

        <Row justify="center">
          <Col>
            <Form.Item noStyle>
              <Button kind="link" onClick={handleClearAll}>
                {t.CLEAR_ALL}
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Menu>
  );

  return (
    <Dropdown
      overlay={filters}
      placement="bottomLeft"
      trigger={['click']}
      getPopupContainer={(triggerNode: HTMLElement) => triggerNode.parentElement as HTMLElement}
      destroyPopupOnHide
    >
      <Col>
        <Row align="middle" gutter={10} className="DocumentsContent__link--primary">
          <Col>
            <IconRed iconName="Filter" />
          </Col>
          <Col>{t.FILTERS}</Col>
        </Row>
      </Col>
    </Dropdown>
  );
}

export default Filters;
