import { ReactNode, useState, useCallback, useEffect } from 'react';
import { Modal, ModalProps, FormInstance } from 'antd';
import AppForm from '../AppForm/AppForm';
import ErrorsContainer, { IErrorsMsgAndType } from '../../ErrorsContainer/ErrorsContainer';
import ButtonContainer from '../../ButtonContainer/ButtonContainer';
import Button from '../../Button/Button';
import useModal from '../../../hooks/useModal';
import { IGenericObject } from '../../../types/IGenericObject';
import useLocale from '../../../hooks/useLocale';

export interface GenericModalFormProps {
  title?: string;
  width?: number;
  initialFormValues?: IGenericObject | ((form: FormInstance<any>) => IGenericObject);
  renderFormBody?: (form: FormInstance<any>) => ReactNode;
  onCancelSideEffects?: () => void;
  saveButtonText?: string;
  onSaveSideEffects?: (formValues: any) => void;
  onValidationFailSideEffects?: (error: any) => void;
  className?: string;
  style?: React.CSSProperties;
}

const GenericModalForm = ({
  title,
  width,
  initialFormValues,
  renderFormBody,
  onCancelSideEffects,
  saveButtonText,
  onSaveSideEffects,
  onValidationFailSideEffects,
  className,
  style,
}: GenericModalFormProps) => {
  const { t } = useLocale();
  const [form] = AppForm.AntD.useForm();
  const { closeModal } = useModal();

  const [errors, setErrors] = useState<IErrorsMsgAndType[] | undefined>();

  const isFormValid = useCallback(async () => {
    // let requestIsValid = true;
    try {
      const formValidation = await form.validateFields();
      console.log('Alert Modal validation:', formValidation);
      return { requestIsValid: true, error: undefined };
    } catch (error) {
      console.log('Alert Modal validation error:', error);
      return { requestIsValid: false, error };
    }
  }, [form]);

  useEffect(() => {
    if (!initialFormValues) return;
    let fieldsToSet;
    if (typeof initialFormValues === 'function') {
      fieldsToSet = initialFormValues(form);
    } else {
      fieldsToSet = initialFormValues;
    }
    form.setFieldsValue(fieldsToSet);
  }, [form, initialFormValues]);

  return (
    <Modal
      className={className}
      style={style}
      destroyOnClose
      onCancel={() => {
        if (onCancelSideEffects) onCancelSideEffects();
        closeModal();
      }}
      centered
      visible
      title={title}
      width={width}
      footer={[
        <ButtonContainer
          key="modal-button-container"
          style={{
            paddingTop: '0',
          }}
        >
          <Button
            key="cancel-button"
            kind="cancel"
            onClick={() => {
              if (onCancelSideEffects) onCancelSideEffects();
            }}
          >
            {t.CANCEL}
          </Button>
          <Button
            key="save-button"
            onClick={async () => {
              const formValues = form.getFieldsValue();
              console.log('modal form values:', formValues);
              const validationResult = await isFormValid();
              if (!validationResult.requestIsValid) {
                setErrors([{ message: t.THERE_IS_AN_ERROR_IN_YOUR_FORM }]);
                if (onValidationFailSideEffects) {
                  onValidationFailSideEffects(validationResult.error);
                }
                return;
              }
              if (onSaveSideEffects) onSaveSideEffects(formValues);
            }}
          >
            {saveButtonText ? saveButtonText : t.SAVE}
          </Button>
        </ButtonContainer>,
      ]}
    >
      {errors && <ErrorsContainer errors={errors} noTitle />}
      <AppForm form={form}>{renderFormBody && renderFormBody(form)}</AppForm>
    </Modal>
  );
};

export default GenericModalForm;
