import { useCallback, useMemo } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { useQuery } from 'react-query';
import { Input, Col, Row, Dropdown, Menu } from 'antd';
import { PlusSquareOutlined } from '@ant-design/icons';

import Button from '../Button/Button';
import frenchLogo from '../../images/logo_fr.png';
import englishLogo from '../../images/logo_en.png';
import profileIcon from '../../images/profile_icon.svg';

import useLocale from '../../hooks/useLocale';
import { ILocale } from '../../types/ILocale';
import { AGENTS_QUERY, USE_QUERY_OPTIONS } from '../../constants/reactQuery';
import { ROUTES } from '../../constants/routes';
import authService, { AuthorizeService } from '../Auth/AuthorizeService';
import { IDebtorSearchCriteria } from '../../pages/DebtorSearchPage/types';
import API from '../../utils/api';

import './Header.scss';

interface ILocation {
  pathname?: string;
  state?: IDebtorSearchCriteria;
}

const Header = (): JSX.Element => {
  const { Search } = Input;

  const { t, locale, setLocale } = useLocale();
  const navigate = useNavigate();
  const { pathname, state } = useLocation() as ILocation;

  const isHomePage = pathname === '/';
  const showNewProspectBtn = isHomePage || pathname === ROUTES.DEBTOR_SEARCH;
  const user = AuthorizeService.getCurrentUserInfo();

  const { data } = useQuery([AGENTS_QUERY], () => API.listAgents(), USE_QUERY_OPTIONS);
  const userName = useMemo(
    () => data?.find((item) => item.id === user?.profile?.sub)?.name,
    [data, user?.profile?.sub]
  );

  const handleLanguageChange = useCallback(() => {
    setLocale(locale === ILocale.en ? ILocale.fr : ILocale.en);
  }, [locale, setLocale]);

  const actionMenu = (
    <Menu className="Header__action-menu">
      <Menu.Item key="profile" onClick={() => navigate(ROUTES.USER_PROFILE)}>
        {t.MY_PROFILE}
      </Menu.Item>
      <Menu.Item key="locale" onClick={handleLanguageChange}>
        {locale === ILocale.en ? 'Français' : 'English'}
      </Menu.Item>
      <Menu.Item key="sign-out" onClick={() => authService.signOut()}>
        {t.SIGN_OUT}
      </Menu.Item>
    </Menu>
  );

  return (
    <Row gutter={40} align="middle" justify="space-between" className="Header">
      <Col>
        <Row gutter={20} className="Header__container">
          <Col>
            <Link to={ROUTES.DASHBOARD}>
              <img src={locale === ILocale.en ? englishLogo : frenchLogo} alt="bdo logo" height={30} width="auto" />
            </Link>
          </Col>
          <Col>
            <Search
              className="Header__search-input"
              placeholder={t.HEADER_SEARCH_PLACEHOLDER}
              allowClear
              onSearch={(searchString) => navigate(ROUTES.DEBTOR_SEARCH, { state: { searchString } })}
              value={state?.searchString}
            />
          </Col>
        </Row>
      </Col>
      <Col>
        <Row align="middle" gutter={20} className="Header__container" justify="end">
          <Col>
            {showNewProspectBtn && (
              <Button height="short" padding="small" onClick={() => navigate(ROUTES.NEW_PROSPECT)}>
                <PlusSquareOutlined /> {t.NEW_PROSPECT}
              </Button>
            )}
          </Col>
          <Col>
            <Dropdown
              overlay={actionMenu}
              placement="bottomLeft"
              trigger={['click']}
              getPopupContainer={(triggerNode: HTMLElement) => triggerNode.parentElement as HTMLElement}
            >
              <span>
                <img src={profileIcon} alt="profile-icon" width={32} height={32} className="Header__avatar" />
                {userName}
              </span>
            </Dropdown>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default Header;
