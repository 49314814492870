import { useCallback, useState } from 'react';
import { Row, Col, Form, InputNumber, Checkbox } from 'antd';
import { useQuery } from 'react-query';

import Layout from '../../../components/Layout/Layout';
import Breadcrumb from '../../../components/Breadcrumb/Breadcrumb';
import PageSubtitle from '../../../components/PageSubtitle/PageSubtitle';
import Button from '../../../components/Button/Button';
import StyledTable from '../components/StyledTable/StyledTable';
import SelectLookupDto from '../../../components/SelectLookupDto/SelectLookupDto';

import useLocale from '../../../hooks/useLocale';
import genericMessage from '../../../utils/genericMessage';

import { TEAMS_QUERY, USE_QUERY_OPTIONS } from '../../../constants/reactQuery';
import { antdUtil } from '../../../utils/antdUtil';
import { ClientService } from '../../../shared/api/ClientService';
import { getAllocationsTotal } from './utils';
import API from '../../../utils/api';

import './Allocations.scss';
import styles from '../../../styles/style.module.scss';

interface IProps extends ClientService.AgentAllocationDto {
  totalAllocations?: number;
}

function Allocations(): JSX.Element {
  const { t } = useLocale();
  const [form] = Form.useForm<IProps>();

  const [initial, setInitial] = useState<IProps>();
  const [loading, setLoading] = useState<boolean>(false);

  const { data: teams } = useQuery([TEAMS_QUERY], () => API.listTeams(undefined), USE_QUERY_OPTIONS);

  const requestAllocations = useCallback(
    async (teamId?: string) => {
      setLoading(true);
      const response = await API.listAllocations(teamId).catch(() => setLoading(false));
      setLoading(false);

      if (response) {
        const values = { ...response, totalAllocations: getAllocationsTotal(response?.agents) } as IProps;
        form.setFieldsValue(values);
        setInitial(values);
      }
    },
    [form]
  );

  const handleSubmit = useCallback(
    async (values) => {
      const body = {
        ...values,
        agents: initial?.agents?.map((item, index) => ({ ...item, ...values?.agents?.[index] })),
      };

      if (body.totalAllocations != 100 && !(body as ClientService.AgentAllocationDto).isAutoAssign) {
        genericMessage.warning(t.ALLOCATION_SHOULD_BE_100);
        form.resetFields();
        requestAllocations(values?.teamId);
      } else {
        const response = await API.updateAllocations(body);
        if (response?.result === ClientService.Result.Successful) {
          genericMessage.success(t.ALLOCATION_UPDATED);
          form.resetFields();
          requestAllocations(values?.teamId);
        }
      }
    },
    [form, initial?.agents, requestAllocations]
  );

  const columns = [
    {
      title: t.STAFF_USER,
      dataIndex: 'agentName',
      key: 'agentName',
      width: '33%',
    },
    {
      title: t.OFFICE_LOCATION,
      dataIndex: 'defaultOfficeName',
      key: 'defaultOfficeName',
      width: '33%',
    },
    {
      title: `${t.ALLOCATION}%`,
      dataIndex: 'allocationPercentage',
      key: 'allocationPercentage',
      render: (text: any, record: ClientService.AgentAllocationItemDto, index: number) => (
        <Form.Item noStyle shouldUpdate>
          {({ getFieldValue }) => (
            <Form.Item name={['agents', index, 'allocationPercentage']} noStyle>
              <InputNumber
                size="large"
                style={{ width: '100%' }}
                min={0}
                max={100}
                formatter={(value) => antdUtil.percentFormatter(value)}
                parser={(value) => antdUtil.percentParser(value) as 0 | 100}
                disabled={getFieldValue('isAutoAssign')}
                onChange={() => {
                  form.setFieldsValue({ totalAllocations: getAllocationsTotal(getFieldValue('agents')) });
                }}
              />
            </Form.Item>
          )}
        </Form.Item>
      ),
    },
  ];

  return (
    <Layout className="Allocations">
      <Breadcrumb data={[{ title: t.ADMINISTRATION }, { title: 'Allocations' }]} />
      <PageSubtitle title={t.ALLOCATIONS} />
      <Form form={form} onFinish={handleSubmit}>
        <Row align="bottom" justify="space-between">
          <Col span={8}>
            <Form.Item label={t.TEAM} name="teamId">
              <SelectLookupDto data={teams} onChange={(teamId) => requestAllocations(teamId)} />
            </Form.Item>
          </Col>
          <Col>
            <Row gutter={10} align="middle">
              <Col>
                <Form.Item name="isAutoAssign" valuePropName="checked" label={t.AUTO_ASSIGN}>
                  <Checkbox />
                </Form.Item>
              </Col>
              <Col>
                <Form.Item>
                  <Button htmlType="submit">{t.SAVE}</Button>
                </Form.Item>
              </Col>
              <Col>
                <Form.Item>
                  <Button
                    onClick={() => {
                      if (initial) form.setFieldsValue(initial);
                    }}
                    kind="cancel"
                  >
                    {t.CANCEL}
                  </Button>
                </Form.Item>
              </Col>
            </Row>
          </Col>
        </Row>
        <StyledTable
          rowKey="agentId"
          dataSource={initial?.agents}
          columns={columns}
          loading={loading}
          pagination={false}
        />
        <Row align="bottom" justify="end">
          <Col span={8}>
            <Form.Item noStyle shouldUpdate>
              {({ getFieldValue }) => {
                const totalAllocations = getFieldValue('totalAllocations') || 0;
                return (
                  <Form.Item name="totalAllocations">
                    <div style={{ paddingTop: 16, fontWeight: 'bold' }}>
                      {t.TOTAL_ALLOCATIONS}:
                      <span
                        style={{ color: totalAllocations < 100 ? styles.colorPrimary : styles.colorDanger }}
                      >{` ${Math.round(totalAllocations)}%`}</span>
                    </div>
                  </Form.Item>
                );
              }}
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Layout>
  );
}

export default Allocations;
