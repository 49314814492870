export const DATA_ENTRY_FORM_CHANGE_EVENT = 'dataEntryFormValuesChanged';
export const APPFORM_SAVE_AND_LEAVE_EVENT = 'appFormSaveAndLeaveSelectEvent';
export const UPLOAD_FILES_EVENT = 'attachFilesEvent';
export const DELETE_FILE_EVENT = 'deleteFileEvent';
export const VERIFICATION_CHANGE_EVENT = 'verificationChangeEvent';
export const NOTE_ADDED_EVENT = 'noteAddedEvent';
export const TASK_ADDED_EVENT = 'taskAddedEvent';
export const APPOINTMENT_ADDED_EVENT = 'appointmentAddedEvent';
export const APP_FORM_REDIRECT_EVENT = 'appFormRedirectEvent';
export const NEW_TASK_EVENT = 'newTaskEvent';
export const NEW_NOTE_EVENT = 'newNoteEvent';
export const NEW_APPOINTMENT_EVENT = 'newAppointmentEvent';
export const NOTE_CHANGE_INIT_EVENT = 'noteChangeInitEvent';
export const NOTE_CHANGE_EVENT = 'noteChangeEvent';
export const APPOINTMENT_CHANGE_EVENT = 'appointmentChangeEvent';
export const IMPORT_ALL_TRADES = 'import_all_trades';
export const IMPORT_SELECTED_TRADES = 'import_selected_trades';
export const RECEIPTS_DISBURSEMENTS_FORM_CHANGE_EVENT = 'receiptsDisbursementsFormChangeEvent';
export const SCROLL_TO_TOP_EVENT = 'scrollToTopEvent';
export const ADMIN_INFO_CHANGE_EVENT = 'adminInfoChangeEvent';
export const CONVERT_TO_PDF_EVENT = 'convertToPdfEvent';
export const TASK_ACTION_UPDATE_EVENT = 'taskActionUpdateEvent';
export const DISCHARGE_UPDATE_EVENT = 'dischargeUpdateEvent';
export const TASK_UPDATE_EVENT = 'taskUpdateEvent';
