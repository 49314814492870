import { Icon } from '@fluentui/react/lib/Icon';
import { Col, Row } from 'antd';
import { InternalNamePath } from 'rc-field-form/es/interface';

import useLocale from '../../../../hooks/useLocale';

import styles from '../../../../styles/style.module.scss';
import './ApplicationFormTab.scss';

export interface IError {
  name: InternalNamePath;
  errors: string[];
  warnings?: string[];
}

interface IProps {
  errors?: IError[];
  onClick: () => void;
}

const ErrorComponent = ({ errors, onClick }: IProps) => {
  const { t } = useLocale();

  return (
    <>
      {errors?.map((item, index) => (
        <div key={String(index)}>
          {item?.errors?.map((error, errorIndex) => (
            <Row gutter={10} key={`${error}-error-${errorIndex}`}>
              <Col>
                <Icon
                  iconName="StatusErrorFull"
                  style={{ color: styles.colorDanger }}
                  className="ApplicationFormTab__error-icon"
                />
              </Col>
              <Col onClick={onClick} className="ApplicationFormTab__error-link">
                {t.GO_TO_ERROR}
              </Col>
              <Col>{error}</Col>
            </Row>
          ))}
          {item?.warnings?.map((warning, warningIndex) => (
            <Row gutter={10} key={`${warning}-warning-${warningIndex}`}>
              <Col>
                <Icon
                  iconName="AlertSolid"
                  style={{ color: styles.colorWarning }}
                  className="ApplicationFormTab__error-icon"
                />
              </Col>
              <Col onClick={onClick} className="ApplicationFormTab__error-link">
                {t.GO_TO_WARNING}
              </Col>
              <Col>{warning}</Col>
            </Row>
          ))}
        </div>
      ))}
    </>
  );
};

export default ErrorComponent;
