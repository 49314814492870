import { useCallback, useState, useRef } from 'react';
import { Form, Col, Row, Spin, message } from 'antd';
import moment from 'moment';
import { CSVLink } from 'react-csv';

import Layout from '../../components/Layout/Layout';
import PageTitleContainer from '../../components/PageTitleContainer/PageTitleContainer';
import PageSubtitle from '../../components/PageSubtitle/PageSubtitle';
import DatePicker from '../../components/DatePicker/DatePicker';
import Button from '../../components/Button/Button';

import useLocale from '../../hooks/useLocale';

import { DATE_FORMAT2, DATE_FORMAT_PLACEHOLDER, DATE_AND_TIME_FORMAT2 } from '../../constants/common';
import { ROUTES } from '../../constants/routes';
import { convertInputDateToServerDateOnly } from '../../utils/helpers';
import { argumentifyMarketingReportSearchCriteria } from './utils';
import { IMarketingReportDataItem } from './types';
import { ClientService } from '../../shared/api/ClientService';
import API from '../../utils/api';

import './MarketingReport.scss';

interface IForm {
  appointmentDate_FromDate?: moment.Moment;
  appointmentDate_ToDate?: moment.Moment;
  prospectCreationDate_FromDate?: moment.Moment;
  prospectCreationDate_ToDate?: moment.Moment;
}

function MarketingReport() {
  const { t } = useLocale();
  const [form] = Form.useForm<IForm>();
  const ref = useRef<any>(null);

  const [marketingReportData, setMarketingReportData] = useState<IMarketingReportDataItem[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  const validateToDate = useCallback(
    (fromDate?: moment.Moment, toDate?: moment.Moment) => {
      if (fromDate && toDate && moment(fromDate).isSameOrAfter(toDate, 'day')) {
        return Promise.reject(t.TO_DATE_SHOULD_BE_AFTER_FROM_DATE);
      }

      if (fromDate && toDate && moment(toDate).diff(fromDate, 'month') >= 6) {
        return Promise.reject(t.REPORT_PERIOD_CANNOT_EXCEED_N_MONTHS?.replace('{0}', '6'));
      }

      return Promise.resolve();
    },
    [t.REPORT_PERIOD_CANNOT_EXCEED_N_MONTHS, t.TO_DATE_SHOULD_BE_AFTER_FROM_DATE]
  );

  const validateFromDate = useCallback(
    (fromDate?: moment.Moment, toDate?: moment.Moment) => {
      if (fromDate && toDate && moment(fromDate).isSameOrAfter(toDate, 'day')) {
        return Promise.reject(t.FROM_DATE_SHOULD_BE_BEFORE_TO_DATE);
      }

      if (fromDate && toDate && moment(toDate).diff(fromDate, 'month') >= 6) {
        return Promise.reject(t.REPORT_PERIOD_CANNOT_EXCEED_N_MONTHS?.replace('{0}', '6'));
      }

      return Promise.resolve();
    },
    [t.FROM_DATE_SHOULD_BE_BEFORE_TO_DATE, t.REPORT_PERIOD_CANNOT_EXCEED_N_MONTHS]
  );

  const headers = [
    {
      label: t.DEBTOR_FIRST_NAME,
      key: 'firstName',
    },
    {
      label: t.DEBTOR_LAST_NAME,
      key: 'lastName',
    },
    {
      label: t.DEBTOR_EMAIL_ID,
      key: 'emailAddress',
    },
    {
      label: t.DEBTOR_MAIN_PHONE_NUMBER,
      key: 'mainPhoneNumber',
    },
    {
      label: t.POSTAL_CODE,
      key: 'postalCode',
    },
    {
      label: t.INTAKE_SOURCE,
      key: 'intakeSource',
    },
    {
      label: t.REFERRAL_SOURCE,
      key: 'referralSource',
    },
    {
      label: t.REFERRAL_SOURCE_DETAIL,
      key: 'referralSourceDetail',
    },
    {
      label: t.REFERRAL_SOURCE_ALL,
      key: 'referralSourcesAll',
    },
    {
      label: t.RECEIVED_EMAIL_CONSENT,
      key: 'receiveEmailConsent',
    },
    {
      label: t.WEBURL,
      key: 'webUrl',
    },
    {
      label: t.DEBTOR_CREATED_DATE_AND_TIME,
      key: 'debtorCreationDate',
    },
    {
      label: t.ESTATE_NO,
      key: 'estateNumber',
    },
    {
      label: t.FILING_TYPE,
      key: 'filingType',
    },
    {
      label: t.FILENAME,
      key: 'fileName',
    },
    {
      label: t.SERVICE_LOCATION,
      key: 'serviceLocation',
    },
    {
      label: t.AGENT,
      key: 'agent',
    },
    {
      label: t.TRUSTEE,
      key: 'trustee',
    },
    {
      label: t.INTERVIEWER,
      key: 'interviewer',
    },
    {
      label: t.ESTATE_ADMINISTRATOR,
      key: 'estateAdministrator',
    },
    {
      label: t.FILESTAGE,
      key: 'fileStageName',
    },
    {
      label: t.SIGNUP_STATUS,
      key: 'signupStatus',
    },
    {
      label: t.SIGNUP_COMPLETED_DATE_TIME,
      key: 'signupCompletedDateTime',
    },
    {
      label: t.APPOINTMENT_TYPE,
      key: 'appointmentType',
    },
    {
      label: t.APPOINTMENT_CREATION_DATE_TIME,
      key: 'appointmentCreationDateTime',
    },
    {
      label: t.APPOINTMENT_CREATED_BY,
      key: 'appointmentsCreatedBy',
    },
    {
      label: t.APPOINTMENT_ASSIGNED_TO,
      key: 'appointmentsAssignedTo',
    },
    {
      label: t.APPOINTMENT_STATUS,
      key: 'appointmentStatus',
    },
    {
      label: t.APPOINTMENT_DATE_TIME,
      key: 'appointmentDateTime',
    },
    {
      label: t.APPOINTMENT_LOCATION,
      key: 'appointmentLocation',
    },
    {
      label: t.APPOINTMENT_MEETING_TYPE,
      key: 'meetingType',
    },
  ];

  const convertReportDataToCVSData = useCallback(
    (data?: ClientService.ReportDataItemDto[]) =>
      data?.map((item) => ({
        ...item,
        receiveEmailConsent: item.receiveEmailConsent ? t.YES : t.NO,
        debtorCreationDate: item.debtorCreationDate
          ? moment.parseZone(item.debtorCreationDate).format(DATE_AND_TIME_FORMAT2)
          : '',
        signupCompletedDateTime: item.signupCompletedDateTime
          ? moment.parseZone(item.signupCompletedDateTime).format(DATE_AND_TIME_FORMAT2)
          : '',
        appointmentCreationDateTime: item.appointmentCreationDateTime
          ? moment.parseZone(item.appointmentCreationDateTime).format(DATE_AND_TIME_FORMAT2)
          : '',
        appointmentDateTime: item.appointmentDateTime
          ? moment.parseZone(item.appointmentDateTime).format(DATE_AND_TIME_FORMAT2)
          : '',
      })) as IMarketingReportDataItem[],
    [t.NO, t.YES]
  );

  const handleMarketingReportRequest = useCallback(
    (values?: IForm) => {
      setLoading(true);
      API.getMarketingReport(
        ...argumentifyMarketingReportSearchCriteria({
          appointmentDate_FromDate: convertInputDateToServerDateOnly(values?.appointmentDate_FromDate),
          appointmentDate_ToDate: convertInputDateToServerDateOnly(values?.appointmentDate_ToDate),
          prospectCreationDate_FromDate: convertInputDateToServerDateOnly(values?.prospectCreationDate_FromDate),
          prospectCreationDate_ToDate: convertInputDateToServerDateOnly(values?.prospectCreationDate_ToDate),
          appointmentType: ClientService.AppointmentTypeEnum.InitialConsultation,
        })
      )
        .then((result) => {
          const marketingData = convertReportDataToCVSData(result);

          setMarketingReportData(marketingData);
          setLoading(false);

          ref?.current?.link?.click();
        })
        .catch(() => {
          message.error(t.SOMETHING_WENT_WRONG);
          setLoading(false);
        });
    },
    [convertReportDataToCVSData, t.SOMETHING_WENT_WRONG]
  );

  return (
    <Layout page={ROUTES.MARKETING_REPORT} className="MarketingReport">
      <PageTitleContainer title="Marketing Report" hasToolbar={false} />
      <PageSubtitle title={t.FILTERS} />
      <Spin spinning={loading}>
        <Form
          form={form}
          layout="vertical"
          onFinish={handleMarketingReportRequest}
          onValuesChange={() => {
            if (form.isFieldsTouched(['appointmentDate_FromDate'])) {
              form.validateFields(['appointmentDate_FromDate']);
            }
            if (form.isFieldsTouched(['appointmentDate_ToDate'])) {
              form.validateFields(['appointmentDate_ToDate']);
            }
            if (form.isFieldsTouched(['prospectCreationDate_FromDate'])) {
              form.validateFields(['prospectCreationDate_FromDate']);
            }
            if (form.isFieldsTouched(['prospectCreationDate_ToDate'])) {
              form.validateFields(['prospectCreationDate_ToDate']);
            }
          }}
        >
          <Row gutter={40}>
            <Col span={8}>
              <Form.Item noStyle shouldUpdate>
                {({ getFieldValue }) => (
                  <Form.Item
                    name="appointmentDate_FromDate"
                    label={t.APPOINTMENT_SCHEDULED_FROM}
                    rules={[
                      {
                        validator: (_, value) => validateFromDate(value, getFieldValue('appointmentDate_ToDate')),
                      },
                    ]}
                  >
                    <DatePicker
                      format={DATE_FORMAT2}
                      dateSetter={null}
                      size="large"
                      placeholder={DATE_FORMAT_PLACEHOLDER}
                    />
                  </Form.Item>
                )}
              </Form.Item>
            </Col>

            <Col span={8}>
              <Form.Item noStyle shouldUpdate>
                {({ getFieldValue }) => (
                  <Form.Item
                    name="appointmentDate_ToDate"
                    label={t.APPOINTMENT_SCHEDULED_TO}
                    rules={[
                      {
                        validator: (_, value) => validateToDate(getFieldValue('appointmentDate_FromDate'), value),
                      },
                    ]}
                  >
                    <DatePicker
                      format={DATE_FORMAT2}
                      dateSetter={null}
                      size="large"
                      placeholder={DATE_FORMAT_PLACEHOLDER}
                    />
                  </Form.Item>
                )}
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={40} style={{ paddingTop: 18 }}>
            <Col span={8}>
              <Form.Item noStyle shouldUpdate>
                {({ getFieldValue }) => (
                  <Form.Item
                    name="prospectCreationDate_FromDate"
                    label={t.FILE_CREATED_FROM}
                    rules={[
                      {
                        validator: (_, value) => validateFromDate(value, getFieldValue('prospectCreationDate_ToDate')),
                      },
                    ]}
                  >
                    <DatePicker
                      format={DATE_FORMAT2}
                      dateSetter={null}
                      size="large"
                      placeholder={DATE_FORMAT_PLACEHOLDER}
                      disabledDate={(date) => date.isAfter(moment(), 'day')}
                    />
                  </Form.Item>
                )}
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item noStyle shouldUpdate>
                {({ getFieldValue }) => (
                  <Form.Item
                    name="prospectCreationDate_ToDate"
                    label={t.FILE_CREATED_TO}
                    rules={[
                      {
                        validator: (_, value) => validateToDate(getFieldValue('prospectCreationDate_FromDate'), value),
                      },
                    ]}
                  >
                    <DatePicker
                      format={DATE_FORMAT2}
                      dateSetter={null}
                      size="large"
                      placeholder={DATE_FORMAT_PLACEHOLDER}
                      disabledDate={(date) => date.isAfter(moment(), 'day')}
                    />
                  </Form.Item>
                )}
              </Form.Item>
            </Col>
          </Row>

          <Row>
            <Col span={16}>
              <Row align="middle" justify="end" gutter={10} className="MarketingReport__footer">
                <Col>
                  <Form.Item>
                    <Button onClick={() => form.resetFields()} kind="cancel">
                      {t.CLEAR}
                    </Button>
                  </Form.Item>
                </Col>
                <Col span={0}>
                  <CSVLink filename="MarketingReport.csv" ref={ref} data={marketingReportData} headers={headers} />
                </Col>
                <Col>
                  <Form.Item shouldUpdate>
                    {({ getFieldsError, getFieldsValue, getFieldValue }) => {
                      const isAppointmentRowFilled =
                        Boolean(getFieldValue('appointmentDate_FromDate')) &&
                        Boolean(getFieldValue('appointmentDate_ToDate'));
                      const isFileCreatedRowFilled =
                        Boolean(getFieldValue('prospectCreationDate_FromDate')) &&
                        Boolean(getFieldValue('prospectCreationDate_ToDate'));
                      const isOnlyOneRowFilled =
                        Object.values(getFieldsValue()).filter(Boolean).length === 2 &&
                        (isAppointmentRowFilled || isFileCreatedRowFilled);
                      return (
                        <Button
                          htmlType="submit"
                          disabled={!isOnlyOneRowFilled || getFieldsError().some(({ errors }) => errors?.length)}
                        >
                          {t.DOWNLOAD}
                        </Button>
                      );
                    }}
                  </Form.Item>
                </Col>
              </Row>
            </Col>
          </Row>
        </Form>
      </Spin>
    </Layout>
  );
}

export default MarketingReport;
