import { Component } from 'react';
import { LoginActions } from '../../constants/auth';
import authService from './AuthorizeService';
import { LoginAction } from './LoginAction';

export default class AuthorizeRoute extends Component {
  constructor(props) {
    super(props);

    this.state = {
      ready: false,
      authenticated: false,
    };
  }

  componentDidMount() {
    this._subscription = authService.subscribe(() => this.authenticationChanged());
    this.populateAuthenticationState();
  }

  componentWillUnmount() {
    authService.unsubscribe(this._subscription);
  }

  render() {
    const { ready, authenticated } = this.state;

    if (!ready) {
      return <span>waiting...</span>;
    } else {
      const { children, ...rest } = this.props;

      if (!authenticated) {
        return <LoginAction action={LoginActions.Login}></LoginAction>;
      } else {
        return children;
      }
    }
  }

  async populateAuthenticationState() {
    const authenticated = await authService.isAuthenticated();
    this.setState({ ready: true, authenticated });
  }

  async authenticationChanged() {
    this.setState({ ready: false, authenticated: false });
    await this.populateAuthenticationState();
  }
}
