import { useQuery } from 'react-query';
import { Form } from 'antd';
import moment from 'moment';

import useLocale from '../../../hooks/useLocale';

import {
  argumentifyOfficeLocationsSearchCriteria,
  convertMeetingTypeToReadable,
  convertOfficeToReadable,
} from '../utils';
import { OFFICE_LOCATIONS_QUERY, MEETING_TYPES_QUERY, INCLUDE_INACTIVE } from '../../../constants/reactQuery';
import { DATE_FORMAT2, TIME_FORMAT2, MAX_PAGE_SIZE } from '../../../constants/common';
import { ISchedulingForm } from '../types';
import { convertServerDateOnlyToInputDate } from '../../../utils/helpers';

import { ClientService } from '../../../shared/api/ClientService';
import API from '../../../utils/api';

import '../SchedulingComponent.scss';

function Summary(): JSX.Element {
  const { t } = useLocale();

  const { data: meetingTypes } = useQuery([MEETING_TYPES_QUERY], () => API.listMeetingTypes());
  const { data: officeLocations } = useQuery([OFFICE_LOCATIONS_QUERY, INCLUDE_INACTIVE, MAX_PAGE_SIZE], () =>
    API.officeLocationsGET(
      ...argumentifyOfficeLocationsSearchCriteria({ maxResultCount: MAX_PAGE_SIZE, includeInactive: true })
    )
  );

  return (
    <Form.Item shouldUpdate noStyle>
      {({ getFieldsValue }) => {
        const values = getFieldsValue() as ISchedulingForm;
        const selectedTimeslot = values?.selectedTimeslot;

        const office = officeLocations?.items?.find(
          (office) => office.id === values?.officeLocationId
        ) as ClientService.OfficeLocationDto;

        const meetingType = meetingTypes?.find(
          (type) => type.id === selectedTimeslot?.timeslotMeetingTypeId
        ) as ClientService.MeetingTypeDto;

        const agent = selectedTimeslot?.availableAgents?.find(
          (agent: ClientService.AvailableAgentDto) => agent.agentId === values?.agentId
        ) as ClientService.AvailableAgentDto;

        return (
          <Form.Item label={t.APPOINTMENT_SCHEDULING_SUMMARY}>
            <div className="SchedulingComponent__summary">
              <div>
                <b>{convertOfficeToReadable(office)}</b>
              </div>
              <div>
                <b>{t.APPOINTMENT_SCHEDULING_SUMMARY_DATE}: </b>
                {convertServerDateOnlyToInputDate(values?.date)?.format(DATE_FORMAT2) || '-'}
              </div>
              <div>
                <b>{t.APPOINTMENT_SCHEDULING_SUMMARY_TIME}: </b>
                {selectedTimeslot?.time
                  ? `${convertServerDateOnlyToInputDate(moment(selectedTimeslot?.time))?.format(TIME_FORMAT2)} ${
                      selectedTimeslot?.timeZone?.code || ''
                    }`
                  : '-'}
              </div>
              <div>
                <b>{t.APPOINTMENT_SCHEDULING_SUMMARY_TYPE}: </b>
                {convertMeetingTypeToReadable(meetingType, agent)}
              </div>
            </div>
          </Form.Item>
        );
      }}
    </Form.Item>
  );
}

export default Summary;
