export enum ROUTES {
  DASHBOARD = '/',
  NEW_PROSPECT = '/new-prospect',
  ADD_TASK = '/add-task',

  DEBTOR_LIST = '/debtor-list',
  DEBTOR_PROFILE = '/debtor-profile',

  APPLICATION_OVERVIEW = '/application-overview',
  APPOINTMENT_SCHEDULING = 'appointment-scheduling',
  APPOINTMENTS = 'appointments',
  ADD_RECEIPT = 'receipts/add-receipt',
  RECEIPTS = 'receipts',
  EMAIL_HISTORY = 'email-history',
  EMAIL = 'email',
  SMS_HISTORY = 'sms-history',
  SMS = 'sms',
  NOTES_HISTORY = 'notes-history',
  TASKS_HISTORY = 'tasks-history',
  // DOCUMENTS = 'documents',
  // FILE_ANALYSIS = 'file-analysis',
  CREDIT_REPORT_HISTORY = 'credit-report-history',
  CREDIT_REPORT = 'credit-report',
  REQUEST_CREDIT_REPORT = 'request-credit-report',

  PROFILE = '/profile',
  USER_PROFILE = '/user-profile',

  REPORTS = '/reports',
  MARKETING_REPORT = 'marketing-report',
  DEBTOR_CONVERTION_REPORT = 'debtor-conversion-report',

  DEBTOR_SEARCH = '/debtor-search',
  NOT_FOUND = '*',
  ADMINISTRATION = '/administration',
  FILE_ANALYSIS_CONFIGURATION = 'file-analysis-configuration',
  USER_MANAGEMENT = 'user-management',
  DOCUMENT_PREFIX = 'document-prefix',
  HOLIDAYS = 'holidays',
  OFFICE_LOCATIONS = 'office-locations',
  TEMPLATES = 'templates',
  COURTS = 'courts',
  ALLOCATIONS = 'allocations',

  FILE_PREVIEW = '/file-preview',

  TASK_ACTIONS = '/task-actions',
  NEW_CLAIM = 'new-claim',
  NEW_INCOME_EXPENSE = 'new-income-expense',
  NEW_TAX_RETURN = 'new-tax-return',
  CLAIMS = 'claims',
  INCOME_AND_EXPENSE = 'income-and-expense',
  TAX_RETURN = 'tax-return',
  // NEW = 'new',
  VIEW = 'view',
}
