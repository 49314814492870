import { useCallback, useEffect, useState, useRef, useMemo } from 'react';
import { Col, DatePicker, Divider, Form, InputNumber, Row, Spin } from 'antd';
import useLocale from '../../../../hooks/useLocale';
import { ClientService } from '../../../../shared/api/ClientService';
import API from '../../../../utils/api';
import Button from '../../../../components/Button/Button';
import genericMessage from '../../../../utils/genericMessage';
import { antdUtil } from '../../../../utils/antdUtil';
import ErrorComponent, { IError } from './ErrorComponent';
import useUnsaved from '../../../../hooks/useUnsaved';
import eventBus from '../../../../utils/eventBus';
import { APPFORM_SAVE_AND_LEAVE_EVENT } from '../../../../constants/eventBus';
import { AttachmentsFormProps } from '../../../../components/AttachmentsTab';
import ChangeTrackerFormItem from '../../../../components/ChangeTrackerFormItem';

import './ApplicationFormTab.scss';
import { debounceWithTimeOut } from '../../../../utils/helpers';

interface IProps {
  data: ClientService.IApplicationFileDto | null;
  updateStatus: () => void;
  onContinue: () => void;
}

const incomeTaxFields = ['yearLastReturnFiled', 'spouseYearLastReturnFiled'];

interface IForm extends ClientService.AppFormIncomeTaxDto, AttachmentsFormProps {}

const IncomeTax = ({ data, updateStatus, onContinue }: IProps): JSX.Element => {
  const { t } = useLocale();

  const [form] = Form.useForm<IForm>();
  const [formErrors, setFormErrors] = useState<IError[]>();
  const { setIsUnsavedForm } = useUnsaved();
  const isAppFormLocked = useMemo(
    () => Boolean(data?.isAppFormLocked) || Boolean(data?.isLocked),
    [data?.isAppFormLocked, data?.isLocked]
  );

  const [isReviewed, setIsReviewed] = useState(false);
  const [needToBeReviewed, setNeedToBeReviewed] = useState(false);
  const [isNotStarted, setIsNotStarted] = useState(false);
  const [yearLastReturnFiledHasValue, setYearLastReturnFiledHasValue] = useState<boolean>(false);

  const [isMarried, setIsMarried] = useState<boolean>(false);

  const refIncomeTax = useRef<HTMLDivElement>(null);
  const refErrorContainer = useRef<HTMLDivElement>(null);
  const [isPrevalidated, setIsPrevalidated] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const refreshStatus = useCallback(() => {
    setIsLoading(true);
    API.getIncomeTaxStatus(data?.id)
      .then((response) => {
        setIsLoading(false);
        setIsReviewed(
          response.status === ClientService.AppFormStatusEnum.Reviewed ||
            response.status === ClientService.AppFormStatusEnum.NotStarted
        );
        setNeedToBeReviewed(
          response.status === ClientService.AppFormStatusEnum.ReadyForReview ||
            response.status === ClientService.AppFormStatusEnum.Incomplete_Review
        );
        setIsNotStarted(response.status === ClientService.AppFormStatusEnum.NotStarted);
      })
      .catch(() => {
        setIsLoading(false);
      });
  }, [data?.id]);

  useEffect(() => {
    API.getAppFormIncomeTax(data?.id).then(async (response) => {
      form.setFieldsValue(response);

      const incomeTaxFormStatus = await API.getIncomeTaxStatus(data?.id);
      const isNotStarted = incomeTaxFormStatus?.status === ClientService.AppFormStatusEnum.NotStarted;

      if (!data?.isAppFormLocked && !isNotStarted) {
        form.submit();
      }

      setYearLastReturnFiledHasValue(
        response.yearLastReturnFiled !== null && response.yearLastReturnFiled !== undefined
      );
      setIsPrevalidated(isNotStarted);
      setIsMarried(response.isMarried || false);
    });

    refreshStatus();
  }, [data?.id, data?.isAppFormLocked, form, refreshStatus, needToBeReviewed]);

  const scrollToRef = useCallback((tabRef: React.RefObject<HTMLDivElement>) => {
    setTimeout(() => tabRef?.current?.scrollIntoView({ block: 'start', behavior: 'smooth', inline: 'start' }), 500);
  }, []);

  const requestIncomeTaxUpdate = useCallback(
    async (dto: ClientService.AppFormIncomeTaxDto, onSaveSuccess?: () => void) => {
      setIsLoading(true);
      const response = await API.updateAppFormIncomeTax(dto).catch(() => {
        setIsLoading(false);
      });

      setIsLoading(false);

      if (response?.result === ClientService.Result.Successful) {
        setIsUnsavedForm(false);
        genericMessage.success(t.SUCCESSFULLY_SAVED);
        updateStatus();
        refreshStatus();

        if (onSaveSuccess) {
          onSaveSuccess();
        }
      } else {
        genericMessage.error({}, response?.messages?.[0]?.body);
      }
    },
    [refreshStatus, setIsUnsavedForm, t.SUCCESSFULLY_SAVED, updateStatus]
  );

  /**
   * Process Income Tax section input data validation and data save to database.
   * After process success completed, raised onSaveSuccess event to caller.
   */
  const onFinish = useCallback(
    (dto: ClientService.AppFormIncomeTaxDto, onSaveSuccess?: () => void) => {
      if (data?.isAppFormLocked) return;

      setFormErrors(form.getFieldsError());

      if (isPrevalidated) {
        dto.fileId = data?.id;

        requestIncomeTaxUpdate(dto, onSaveSuccess);
      } else {
        setIsPrevalidated(true);
      }
    },
    [data?.id, data?.isAppFormLocked, form, isPrevalidated, requestIncomeTaxUpdate]
  );

  const getPanelFieldsErrors = useCallback(
    (panelFields?: string[]) => {
      if (!panelFields) return undefined;

      const filtered = formErrors?.filter(
        (item) =>
          (item?.errors?.length || item?.warnings?.length) &&
          Boolean(item?.name?.find((name) => panelFields.indexOf(String(name)) > -1))
      );

      return filtered?.length ? filtered : undefined;
    },
    [formErrors]
  );

  const clearDependantFields = useCallback(
    (changedField, dependantFieldsNames: string[]) => {
      const changedFieldName = Object.keys(changedField)?.[0];
      if (dependantFieldsNames.indexOf(changedFieldName) < 0) return;

      dependantFieldsNames
        .filter((item) => item !== changedFieldName)
        .forEach((fieldToReset) => form.setFieldsValue({ [fieldToReset]: undefined }));
    },
    [form]
  );

  const saveAndLeave = useCallback(
    (e) => {
      onFinish(form.getFieldsValue(), e?.detail?.onSaveSuccess);
    },
    [form, onFinish]
  );

  const markReviewed = useCallback(() => {
    setIsLoading(true);
    API.incomeTaxMarkAsReviewed(data?.id)
      .then((response) => {
        setIsLoading(false);
        updateStatus();
        refreshStatus();
      })
      .catch(() => {
        setIsLoading(false);
      });
  }, [data?.id, refreshStatus, updateStatus]);

  /**
   *  event to mark income tax as completed.
   *  Note: System needs to save IncomeTax section data first,
   *  then update status as "Completed".
   */
  const markComplete = useCallback(() => {
    setIsLoading(true);
    API.incomeTaxMarkAsComplete(data?.id)
      .then((response) => {
        setIsLoading(false);
        updateStatus();
        refreshStatus();
      })
      .catch(() => {
        setIsLoading(false);
      });
  }, [data?.id, refreshStatus, updateStatus]);

  /**
   * Called when click "Mark as Complete".
   */
  const onMarkCompleteClicked = useCallback(() => {
    onFinish(form.getFieldsValue(), markComplete);
  }, [form, onFinish, markComplete]);

  /**
   * Called when click "Continue" button.
   */
  const saveAndContinue = useCallback(() => {
    if (isLoading) return;
    try {
      setIsLoading(true);
      onFinish(form.getFieldsValue(), onContinue);
    } catch (e) {
      console.log(e);
    } finally {
      setIsLoading(false);
    }
  }, [form, isLoading, onContinue, onFinish, setIsLoading]);

  useEffect(() => {
    return () => setIsUnsavedForm(false);
  }, [setIsUnsavedForm]);

  useEffect(() => {
    eventBus.on(APPFORM_SAVE_AND_LEAVE_EVENT, saveAndLeave);

    return () => {
      eventBus.remove(APPFORM_SAVE_AND_LEAVE_EVENT, saveAndLeave);
    };
  }, [saveAndLeave]);

  return (
    <Spin spinning={isLoading}>
      <Form
        form={form}
        onFinish={(values) => onFinish(values)}
        onFinishFailed={() => onFinish(form.getFieldsValue())}
        layout="vertical"
        className="ApplicationFormTab"
        validateTrigger={['onSubmit', 'onBlur']}
        onValuesChange={(value) => {
          setIsUnsavedForm(true);
          clearDependantFields(value, ['spouseRefundAmount', 'spouseAmountOwing']);
          clearDependantFields(value, ['refundAmount', 'amountOwing']);
          setYearLastReturnFiledHasValue(
            value?.yearLastReturnFiled !== undefined && value?.yearLastReturnFiled !== null
          );
        }}
      >
        {Boolean(formErrors?.filter((item) => item?.errors?.length || item?.warnings?.length)?.length) && (
          <div className="ApplicationFormTab__errors-container" ref={refErrorContainer}>
            <div className="ApplicationFormTab__errors-container--title">{t.VALIDATION_ERRORS_AND_WARNINGS}</div>
            <ErrorComponent errors={getPanelFieldsErrors(incomeTaxFields)} onClick={() => scrollToRef(refIncomeTax)} />
          </div>
        )}
        <div className="ApplicationFormTab__header" ref={refIncomeTax}>
          Applicant
        </div>
        <Row gutter={20}>
          <Col span={8}>
            <ChangeTrackerFormItem
              name="yearLastReturnFiled"
              className="ApplicationFormTab__input-label-warning"
              label={t.YEAR_LAST_RETURN_FILED}
              rules={[
                {
                  validator: async (_: any, value?: any) => (value ? Promise.resolve() : Promise.reject()),
                  warningOnly: true,
                  message: t.RECOMMENDED_FIELD,
                  validateTrigger: 'onChange',
                },
              ]}
            >
              <DatePicker
                size="large"
                picker="year"
                style={{ width: '100%' }}
                placeholder="yyyy"
                disabledDate={antdUtil.futureDates}
                disabled={isAppFormLocked}
              />
            </ChangeTrackerFormItem>
          </Col>
          <Col span={8}>
            <ChangeTrackerFormItem name="refundAmount" label={t.REFUND_AMOUNT}>
              <InputNumber
                size="large"
                style={{ width: '100%' }}
                min={0}
                max={999999999}
                formatter={(value) => antdUtil.currencyFormatter(value)}
                parser={(value) => antdUtil.currencyParser(value) as 0 | 999999999}
                disabled={isAppFormLocked}
              />
            </ChangeTrackerFormItem>
          </Col>
          <Col span={8}>
            <ChangeTrackerFormItem name="amountOwing" label={t.AMOUNT_OWING}>
              <InputNumber
                size="large"
                style={{ width: '100%' }}
                min={0}
                max={999999999}
                formatter={(value) => antdUtil.currencyFormatter(value)}
                parser={(value) => antdUtil.currencyParser(value) as 0 | 999999999}
                disabled={isAppFormLocked}
              />
            </ChangeTrackerFormItem>
          </Col>
        </Row>

        {isMarried && (
          <>
            <Divider />
            <div className="ApplicationFormTab__header">{t.SPOUSE_PARTNER}</div>
            <Row gutter={20}>
              <Col span={8}>
                <ChangeTrackerFormItem
                  name="spouseYearLastReturnFiled"
                  className="ApplicationFormTab__input-label-warning"
                  label={t.YEAR_LAST_RETURN_FILED}
                  rules={[
                    {
                      validator: async (_: any, value?: any) => (value ? Promise.resolve() : Promise.reject()),
                      warningOnly: true,
                      message: t.RECOMMENDED_FIELD,
                      validateTrigger: 'onChange',
                    },
                  ]}
                >
                  <DatePicker
                    size="large"
                    picker="year"
                    style={{ width: '100%' }}
                    placeholder="yyyy"
                    disabledDate={antdUtil.futureDates}
                    disabled={isAppFormLocked}
                  />
                </ChangeTrackerFormItem>
              </Col>
              <Col span={8}>
                <ChangeTrackerFormItem name="spouseRefundAmount" label={t.REFUND_AMOUNT}>
                  <InputNumber
                    size="large"
                    style={{ width: '100%' }}
                    min={0}
                    max={999999999}
                    formatter={(value) => antdUtil.currencyFormatter(value)}
                    parser={(value) => antdUtil.currencyParser(value) as 0 | 999999999}
                    disabled={isAppFormLocked}
                  />
                </ChangeTrackerFormItem>
              </Col>
              <Col span={8}>
                <ChangeTrackerFormItem name="spouseAmountOwing" label={t.AMOUNT_OWING}>
                  <InputNumber
                    size="large"
                    style={{ width: '100%' }}
                    min={0}
                    max={999999999}
                    formatter={(value) => antdUtil.currencyFormatter(value)}
                    parser={(value) => antdUtil.currencyParser(value) as 0 | 999999999}
                    disabled={isAppFormLocked}
                  />
                </ChangeTrackerFormItem>
              </Col>
            </Row>
          </>
        )}

        <Row justify="end" gutter={20} className="ApplicationFormTab__buttons-container">
          <Col>
            <Button padding="large" disabled={!needToBeReviewed || isAppFormLocked} onClick={markReviewed}>
              {t.MARK_AS_REVIEWED}
            </Button>
          </Col>
          <Col>
            <Button
              padding="large"
              disabled={!isReviewed || (isNotStarted && yearLastReturnFiledHasValue) || isAppFormLocked}
              onClick={onMarkCompleteClicked}
            >
              {t.MARK_AS_COMPLETED}
            </Button>
          </Col>
          <Col>
            <Button
              kind="cancel"
              padding="large"
              disabled={isAppFormLocked || isLoading}
              onClick={debounceWithTimeOut(saveAndContinue, 2000)}
            >
              {t.CONTINUE}
            </Button>
          </Col>
        </Row>
      </Form>
    </Spin>
  );
};

export default IncomeTax;
