import React, { useState, useEffect, useCallback, ReactNode } from 'react';
import { Collapse } from 'antd';
import CurrencyDisplay from '../../../../../../components/CurrencyDisplay/CurrencyDisplay';
import Icon from '../../../../../../components/Icon/Icon';
import Loading from '../../../../../../components/Loading/Loading';
import DetailsTable, { DetailsTableProps } from './DetailsTable';
import { IValue } from '../../../../../../components/DataItem/DataItem';

import styles from './SecondaryRecordsDisplay.module.scss';

const { Panel } = Collapse;

export interface ISecondaryRecord {
  id: string;
  [key: string]: any;
}

export interface ITableColumn {
  title: ReactNode;
  dataIndex: string;
}

export interface IRecordField {
  label: string;
  dataIndex: string;
  format?: IValue['format'];
  width?: string;
}

export interface SecondaryRecordsDisplayProps {
  argsForApiGet?: { [key: string]: any };
  secondaryFields?: IRecordField[];
  secondaryRecords?: ISecondaryRecord[];
  apiGetSecondaryRecords?: (objArgs: { [key: string]: any }) => Promise<any>;
  apiSetSecondaryRecords?: (
    response: any,
    setSecondaryRecords: React.Dispatch<React.SetStateAction<ISecondaryRecord[] | undefined>>
  ) => void;
  tableColumns: ITableColumn[];
  apiGetTableData?: DetailsTableProps['apiGetTableData'];
  apiSetTableData?: DetailsTableProps['apiSetTableData'];
  viewTableRecordRoute?: DetailsTableProps['viewRecordRoute'];
  className?: string;
  style?: React.CSSProperties;
}

const SecondaryRecordsDisplay = ({
  argsForApiGet,
  secondaryFields,
  secondaryRecords,
  apiGetSecondaryRecords,
  apiSetSecondaryRecords,
  tableColumns,
  apiGetTableData,
  apiSetTableData,
  viewTableRecordRoute,
  className,
  style,
}: SecondaryRecordsDisplayProps): JSX.Element => {
  const [secondaryFieldSet, setSecondaryFieldSet] = useState<IRecordField[] | undefined>();
  const [secondaryRecordsSet, setSecondaryRecordsSet] = useState<ISecondaryRecord[] | undefined>();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    if (secondaryFields) {
      console.log('secondaryFields:', secondaryFields);
      setSecondaryFieldSet(secondaryFields);
    }
  }, [secondaryFields]);

  const addSecondaryRecordsToState = useCallback(() => {
    setIsLoading(true);

    if (secondaryRecords) {
      setTimeout(() => {
        setIsLoading(false);
        setSecondaryRecordsSet(secondaryRecords);
      }, 2000);
      return false;
    }

    if (apiGetSecondaryRecords && argsForApiGet) {
      console.log('running apiGetSecondaryRecords()');
      apiGetSecondaryRecords(argsForApiGet)
        .then((response) => {
          setIsLoading(false);
          console.log('apiGetSecondaryRecords() response:', response);
          if (apiSetSecondaryRecords) {
            apiSetSecondaryRecords(response, setSecondaryRecordsSet);
          } else {
            setSecondaryRecordsSet(response);
          }
        })
        .catch((error) => {
          setIsLoading(false);
          console.error(error.message);
        });
    }
  }, [apiGetSecondaryRecords, argsForApiGet, apiSetSecondaryRecords, secondaryRecords]);

  useEffect(() => {
    if (!secondaryRecordsSet) {
      addSecondaryRecordsToState();
    }
  }, [secondaryRecordsSet, addSecondaryRecordsToState]);

  const renderHeaderTitles = () => {
    console.log('running renderHeaderTitles()');
    if (secondaryFieldSet) {
      console.log('secondaryFieldSet:', secondaryFieldSet);
      return (
        <div className={styles.header_titles}>
          {secondaryFieldSet.map((field, index) => {
            const parentId = argsForApiGet && argsForApiGet.id ? argsForApiGet.id : '1';
            const thisWidth = field.width ? field.width : '1';
            return (
              <div key={`parentId-${parentId}_header-${index}`} style={{ flex: `${thisWidth} 0 0` }}>
                {field.label}
              </div>
            );
          })}
        </div>
      );
    }
  };

  const renderHeaderRow = useCallback(
    (record: ISecondaryRecord) => {
      if (secondaryFieldSet) {
        return (
          <>
            <div className={styles.header_row}>
              <div className={styles.header_values}>
                {secondaryFieldSet.map((field, index) => {
                  const parentId = argsForApiGet && argsForApiGet.id ? argsForApiGet.id : '1';
                  const thisWidth = field.width ? field.width : '1';
                  const thisValue =
                    field.format && field.format !== 'normal' ? (
                      <CurrencyDisplay
                        amount={record[field.dataIndex]}
                        decimalDigits={field.format === 'currency-no-decimals' ? 0 : undefined}
                        noDollarSign={field.format === 'currency-no-dollar-sign' ? true : undefined}
                      />
                    ) : (
                      record[field.dataIndex]
                    );
                  return (
                    <div
                      key={`parentId-${parentId}_record-${record.id}_heading-${index}`}
                      style={{ flex: `${thisWidth} 0 0` }}
                    >
                      {thisValue}
                    </div>
                  );
                })}
              </div>
            </div>
            {/* <div className={styles.record_icon_wrapper}>
                <Icon
                    iconName='GoTo'
                    color='red' 
                    fontSize='18px'
                    style={{fontWeight: 'bold'}}
                />
              </div> */}
          </>
        );
      }
    },
    [argsForApiGet, secondaryFieldSet]
  );

  const renderTable = useCallback(
    (id: string) => {
      const columns = tableColumns.map((column) => {
        return {
          key: `table-${id}_${column.dataIndex}`,
          title: column.title,
          dataIndex: column.dataIndex,
        };
      });

      return (
        <DetailsTable
          idForApiGet={id}
          columns={columns}
          apiGetTableData={apiGetTableData}
          apiSetTableData={apiSetTableData}
          viewRecordRoute={viewTableRecordRoute}
        />
      );
    },
    [tableColumns, apiGetTableData, apiSetTableData, viewTableRecordRoute]
  );

  if (isLoading) return <Loading noText />;

  return (
    <div className={`${styles.SecondaryRecordsDisplay} ${className ? className : ''}`} style={style}>
      {renderHeaderTitles()}
      <Collapse accordion bordered={false}>
        {secondaryRecordsSet &&
          secondaryRecordsSet.map((record, index) => {
            return (
              <Panel header={renderHeaderRow(record)} key={`record-${index}`}>
                {renderTable(record.id)}
              </Panel>
            );
          })}
      </Collapse>
    </div>
  );
};

export default SecondaryRecordsDisplay;
