import { CSSProperties, ReactNode } from 'react';
import FormSectionTitle from '../FormSectionTitle/FormSectionTitle';

import styles from '../../styles/style.module.scss';

interface IProps {
  backgroundColor?: 'light' | 'dark';
  title?: string;
  children?: ReactNode;
  style?: CSSProperties;
}

const SectionContainer = ({ backgroundColor = 'dark', children, title, style }: IProps) => (
  <div
    style={{
      backgroundColor: backgroundColor === 'dark' ? styles.colorActiveRow : styles.colorInactiveRow,
      padding: '0 24px',
      marginBottom: 24,
      paddingBottom: 24,
      ...style,
    }}
  >
    {title && <FormSectionTitle titleText={title} />}
    {children}
  </div>
);

export default SectionContainer;
