import { useCallback, useEffect, useState, useMemo } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Spin } from 'antd';
import moment from 'moment';

import Breadcrumb from '../../../../components/Breadcrumb/Breadcrumb';
import PageSubtitle from '../../../../components/PageSubtitle/PageSubtitle';
import EmailEditor from '../EmailEditor/EmailEditor';
import BlockWithLabel from '../../../../components/BlockWithLabel/BlockWithLabel';
import Divider from '../../../../components/Divider/Divider';
import Button from '../../../../components/Button/Button';
import FileRow from '../../../../components/AttachmentsTab/FileRow/FileRow';

import useLocale from '../../../../hooks/useLocale';
import useModal from '../../../../hooks/useModal';
import { ROUTES } from '../../../../constants/routes';
import { DATE_FORMAT2 } from '../../../../constants/common';
import { useApplicationFile } from '../../ApplicationOverviewPage';

import { ClientService } from '../../../../shared/api/ClientService';
import API from '../../../../utils/api';
import genericMessage from '../../../../utils/genericMessage';

import './EmailView.scss';

function EmailView(): JSX.Element {
  const { applicationFileId, emailId } = useParams<{ applicationFileId: string; emailId: string }>();
  const { t } = useLocale();
  const navigate = useNavigate();
  const { showModal, closeModal } = useModal();

  const [email, setEmail] = useState<ClientService.AppEmailDto>();
  const [emailLoading, setEmailLoading] = useState<boolean>();
  const [documents, setDocuments] = useState<ClientService.DocumentDto[]>();

  const { data: appFileData } = useApplicationFile();
  const isDuplicate = useMemo(() => appFileData?.isDuplicate, [appFileData]);

  const goToEmailHistory = useCallback(() => {
    navigate(`${ROUTES.APPLICATION_OVERVIEW}/${applicationFileId}/${ROUTES.EMAIL_HISTORY}`);
  }, [navigate, applicationFileId]);

  const requestDocuments = useCallback(async (attachmentDocumentIds?: string[]) => {
    if (attachmentDocumentIds) {
      let documents: ClientService.DocumentDto[] = [];
      for (const id of attachmentDocumentIds) {
        const doc = await API.documentsGET2(id);
        if (doc) documents.push(doc);
      }

      setDocuments(documents);
    }
  }, []);

  const requestGetEmailById = useCallback(async () => {
    setEmailLoading(true);
    const response = await API.get2(emailId as string).catch(() => setEmailLoading(false));
    if (response) {
      setEmail(response);
      requestDocuments(response?.attachmentDocumentIds);
    }
    setEmailLoading(false);
  }, [emailId, requestDocuments]);

  const handleResendEmail = useCallback(() => {
    API.resend({
      emailId: emailId as string,
    } as ClientService.AppEmailResendDto)
      .then(() => goToEmailHistory())
      .catch(genericMessage.error);
  }, [email, goToEmailHistory]);

  useEffect(() => {
    if (emailId) {
      requestGetEmailById();
    }
  }, [emailId, requestGetEmailById]);

  const handleShowEditor = useCallback(
    (data?: ClientService.AppEmailDto) => {
      showModal(
        <EmailEditor
          onNext={() => {
            closeModal();
            goToEmailHistory();
          }}
          onCancel={closeModal}
          data={data}
          fileId={applicationFileId as string}
        />
      );
    },
    [showModal, closeModal, applicationFileId, goToEmailHistory]
  );

  return (
    <div className="EmailView">
      <Breadcrumb
        data={[
          { link: ROUTES.DASHBOARD, title: t.DASHBOARD },
          { link: `${ROUTES.DEBTOR_SEARCH}`, title: t.DEBTOR_SEARCH },
          { link: `${ROUTES.APPLICATION_OVERVIEW}/${applicationFileId}`, title: t.APPLICATION_OVERVIEW },
          {
            link: `${ROUTES.APPLICATION_OVERVIEW}/${applicationFileId}/${ROUTES.EMAIL_HISTORY}`,
            title: t.EMAIL_HISTORY,
          },
          { title: t.EMAIL_VIEW },
        ]}
      />
      <PageSubtitle
        title={`${t.EMAIL_SENT} - ${moment(email?.creationTime).format(DATE_FORMAT2)}`}
        buttonTitle={!isDuplicate && t.EMAIL_NEW}
        onButtonClick={handleShowEditor}
      />
      <>
        {emailLoading ? (
          <Spin className="EmailView__spinner" />
        ) : (
          <>
            <BlockWithLabel label={t.TEMPLATE}>{email?.messageTemplateName}</BlockWithLabel>
            <Divider />
            <div className="EmailView__inner-blocks-container">
              <BlockWithLabel label={t.EMAIL_FROM}>{email?.fromEmailAddress}</BlockWithLabel>
              <BlockWithLabel label={t.EMAIL_TO}>{email?.toEmailAddress}</BlockWithLabel>
            </div>
            <BlockWithLabel label={t.SUBJECT}>{email?.subject}</BlockWithLabel>
            <div className="EmailView__body" dangerouslySetInnerHTML={{ __html: email?.body || '' }} />

            {Boolean(documents?.length) && (
              <BlockWithLabel label={t.ATTACHMENTS}>
                {documents?.map((item, index) => (
                  <FileRow
                    key={`document-${index}`}
                    name={index}
                    file={item}
                    hasDivider={false}
                    columnsProps={{ hasPrefixColumn: false, hasDeleteButton: false }}
                  />
                ))}
              </BlockWithLabel>
            )}

            <Divider />

            {!isDuplicate && (
              <div className="EmailView__buttons-container">
                <Button kind="cancel" extended onClick={() => handleShowEditor(email)}>
                  {t.DUPLICATE}
                </Button>
                <Button kind="primary" extended onClick={handleResendEmail}>
                  {t.RESEND}
                </Button>
              </div>
            )}
          </>
        )}
      </>
    </div>
  );
}

export default EmailView;
