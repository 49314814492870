import './HeaderCell.scss';

interface IProps {
  title: string;
  text: string;
  clickable?: boolean;
  onClick?: () => void;
}

const HeaderCell = ({ title, text, clickable, onClick }: IProps) => (
  <div className="HeaderCell">
    <div className="HeaderCell__title">{title}</div>
    <div className={clickable ? 'HeaderCell__clickable-text' : ''} onClick={onClick}>
      {text}
    </div>
  </div>
);

export default HeaderCell;
