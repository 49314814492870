import { CSSProperties, useCallback, useMemo } from 'react';
import { CaretLeftOutlined, CaretRightOutlined } from '@ant-design/icons';
import { Moment } from 'moment';
import { Icon } from '@fluentui/react/lib/Icon';

import useLocale from '../../hooks/useLocale';

import styles from '../../styles/style.module.scss';
import './CalendarHeader.scss';

interface IProps {
  value: Moment;
  onChange: (value: Moment) => void;
  disabled?: boolean;
  style?: CSSProperties;
  rulerSize?: 'normal' | 'large';
}

function CalendarHeader({ value, onChange, disabled, style, rulerSize = 'normal' }: IProps) {
  const { t } = useLocale();

  const currentMonthName = useMemo(() => {
    const current = value?.clone();
    const localeData = value?.localeData();
    const months = [];
    for (let i = 0; i < 12; i++) {
      current?.month(i);
      months?.push(localeData?.months(current));
    }
    const month = value?.month();

    return months[month];
  }, [value]);

  const handlePrevMonthSelect = useCallback(() => {
    if (disabled) return;
    const newValue = value?.clone();
    newValue.subtract(1, 'M');
    onChange(newValue);
  }, [disabled, onChange, value]);

  const handleNextMonthSelect = useCallback(() => {
    if (disabled) return;
    const newValue = value?.clone();
    newValue?.add(1, 'M');
    onChange(newValue);
  }, [disabled, onChange, value]);

  return (
    <div className="CalendarHeader" style={{ color: disabled ? styles.colorSecondary : styles.colorPrimary, ...style }}>
      {rulerSize === 'normal' ? (
        <CaretLeftOutlined onClick={handlePrevMonthSelect} />
      ) : (
        <div className="CalendarHeader__large-ruler-wrapper" onClick={handlePrevMonthSelect}>
          <Icon iconName="ChevronLeftSmall" />
          {t.PREVIOUS}
        </div>
      )}
      <div className="CalendarHeader__month">{currentMonthName}</div>
      {rulerSize === 'normal' ? (
        <CaretRightOutlined onClick={handleNextMonthSelect} />
      ) : (
        <div className="CalendarHeader__large-ruler-wrapper" onClick={handleNextMonthSelect}>
          {t.NEXT}
          <Icon iconName="ChevronRightSmall" />
        </div>
      )}
    </div>
  );
}

export default CalendarHeader;
