import { createContext, useState, useCallback } from 'react';
import LocalizedStrings, { LocaleObj } from '../locale/LocalizedStrings';
import { ILocale } from '../types/ILocale';
import { LOCALE_STORAGE_KEY } from '../constants/common';
import LS from '../utils/localStorage';
import moment from 'moment';
import { ClientService } from '../shared/api/ClientService';

type ILocaleDocument = Pick<ClientService.DocumentDto, 'name' | 'frenchName'>;
type ILocaleDocumentVerification = Pick<ClientService.DocumentVerificationDto, 'documentName' | 'frenchName'>;
type ILocaleVersionDocument = Pick<ClientService.DocumentVersionDto, 'versionName' | 'versionFrenchName'>;

interface ILocaleContext {
  locale: ILocale;
  setLocale: (locale: ILocale) => void;
  t: LocaleObj;
  getLocalizedDtoName: (dto?: ClientService.LookupDto) => string;
  getLocalizedDocumentName: (document?: ILocaleDocument) => string;
  getLocalizedDocumentVerificationName: (document?: ILocaleDocumentVerification) => string;
  getLocalizedVersionDocumentName: (version?: ILocaleVersionDocument) => string;
}

export const LocaleContext = createContext<ILocaleContext>({
  locale: ILocale.en,
  setLocale: () => {},
  t: LocalizedStrings.en,
  getLocalizedDtoName: () => '',
  getLocalizedDocumentName: () => '',
  getLocalizedDocumentVerificationName: () => '',
  getLocalizedVersionDocumentName: () => '',
});

interface IProps {
  children: JSX.Element;
}

const LocaleProvider = ({ children }: IProps): JSX.Element => {
  const [locale, setLocale] = useState<ILocale>(() => {
    const initial = LS.get<ILocale>(LOCALE_STORAGE_KEY) || ILocale.en;
    moment.locale(initial === ILocale.fr ? 'fr-ca' : 'en');
    return initial;
  });

  const setAndSaveLocale = (locale: ILocale) => {
    setLocale(locale);
    LS.set<ILocale>(LOCALE_STORAGE_KEY, locale);
    moment.locale(locale === ILocale.fr ? 'fr-ca' : 'en');
  };

  const getLocalizedDtoName = useCallback(
    (dto?: ClientService.LookupDto) =>
      dto?.frenchName && locale === ILocale.fr
        ? dto?.frenchName
        : dto?.localizationString && LocalizedStrings[locale]?.[dto?.localizationString]
        ? LocalizedStrings[locale]?.[dto?.localizationString]
        : dto?.code && LocalizedStrings[locale]?.[dto?.code]
        ? LocalizedStrings[locale]?.[dto?.code]
        : dto?.name || '',
    [locale]
  );

  const getLocalizedDocumentName = useCallback(
    (document?: ILocaleDocument) =>
      locale === ILocale.fr && document?.frenchName ? document?.frenchName : document?.name || '',
    [locale]
  );

  const getLocalizedDocumentVerificationName = useCallback(
    (documentVerification?: ILocaleDocumentVerification) =>
      locale === ILocale.fr && documentVerification?.frenchName
        ? documentVerification?.frenchName
        : documentVerification?.documentName || '',
    [locale]
  );

  const getLocalizedVersionDocumentName = useCallback(
    (document?: ILocaleVersionDocument) =>
      locale === ILocale.fr && document?.versionFrenchName ? document?.versionFrenchName : document?.versionName || '',
    [locale]
  );

  return (
    <LocaleContext.Provider
      value={{
        setLocale: setAndSaveLocale,
        locale,
        t: LocalizedStrings[locale],
        getLocalizedDtoName,
        getLocalizedDocumentName,
        getLocalizedDocumentVerificationName,
        getLocalizedVersionDocumentName,
      }}
    >
      {children}
    </LocaleContext.Provider>
  );
};

export default LocaleProvider;
