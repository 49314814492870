import { useCallback, useEffect, useState, useContext } from 'react';
import { useParams } from 'react-router-dom';
import { Table, Row, Col, Spin } from 'antd';
import { Icon } from '@fluentui/react/lib/Icon';
import moment from 'moment';

import FormSectionTitle from '../../../../../../../components/FormSectionTitle/FormSectionTitle';
import PADScheduleHistoryModal from '../../modals/PADScheduleHistoryModal/PADScheduleHistoryModal';

import { BankingContext } from '../../BankingContext';
import { BankingPageViewEnum } from '../../types';
import { convertDateToReadable } from '../../utils';

import useModal from '../../../../../../../hooks/useModal';
import useLocale from '../../../../../../../hooks/useLocale';

import { DATE_AND_TIME_FORMAT } from '../../../../../../../constants/common';
import { ClientService } from '../../../../../../../shared/api/ClientService';
import API from '../../../../../../../utils/api';

import './PADScheduleHistoryTable.scss';
import styles from '../../../../../../../styles/style.module.scss';

const PADScheduleHistoryTable = () => {
  const { applicationFileId } = useParams<{ applicationFileId?: string }>();
  const { showModal, closeModal } = useModal();
  const { setPageView } = useContext(BankingContext);
  const { t } = useLocale();

  const [dataSource, setDataSource] = useState<ClientService.PADScheduleHistoryDto[]>();
  const [loading, setLoading] = useState<boolean>(false);

  const requestPadHistory = useCallback(async (applicationFileId: string) => {
    setLoading(true);
    const response = await API.padScheduleHistoryAll(applicationFileId).catch(() => setLoading(false));
    setLoading(false);

    if (response) setDataSource(response);
  }, []);

  const handleViewPadRequest = useCallback(
    (id: string) => {
      showModal(
        <PADScheduleHistoryModal onClose={closeModal} applicationFileId={applicationFileId as string} id={id} />
      );
    },
    [applicationFileId, closeModal, showModal]
  );

  useEffect(() => {
    if (applicationFileId) requestPadHistory(applicationFileId);
  }, [applicationFileId, requestPadHistory]);

  const columns = [
    {
      title: t.UPDATED_BY,
      dataIndex: 'updatedBy',
      key: 'updatedBy',
    },
    {
      title: t.UPDATED_ON,
      dataIndex: 'updatedOn',
      key: 'updatedOn',
      render: (date: moment.Moment) => <>{convertDateToReadable(date, DATE_AND_TIME_FORMAT)}</>,
    },
    {
      title: t.REQUESTED_BY,
      dataIndex: 'requestedBy',
      key: 'requestedBy',
    },
    {
      title: t.REQUESTED_ON,
      dataIndex: 'requestedOn',
      key: 'requestedOn',
      render: (date: moment.Moment) => <>{convertDateToReadable(date, DATE_AND_TIME_FORMAT)}</>,
    },
    {
      dataIndex: 'action',
      key: 'action',
      render: (_: any, record: ClientService.PADScheduleHistoryDto) => (
        <Icon
          iconName="DrillDown"
          style={{ fontSize: 17, color: styles.colorDanger, transform: `rotate(-90deg)`, cursor: 'pointer' }}
          onClick={() => handleViewPadRequest(record?.id as string)}
        />
      ),
    },
  ];

  return (
    <div className="PADScheduleHistoryTable">
      <Row align="middle" justify="space-between">
        <Col>
          <FormSectionTitle titleText={t.CHEQUEPAD_SCHEDULE_HISTORY} />
        </Col>
        <Col>
          <Row
            onClick={() => setPageView(BankingPageViewEnum.Banking)}
            style={{ color: styles.colorDanger, cursor: 'pointer' }}
            gutter={10}
            align="middle"
            justify="end"
          >
            <Col>{t.BANKING}</Col>
            <Col>
              <Icon iconName="RevToggleKey" style={{ fontSize: 15 }} />
            </Col>
          </Row>
        </Col>
      </Row>

      <Spin spinning={loading}>
        <Table dataSource={dataSource} columns={columns} />
      </Spin>
    </div>
  );
};

export default PADScheduleHistoryTable;
