import { message } from 'antd';

const FIVE_SECONDS = 5;
const GENERIC_ERROR_MESSAGE = 'Generic network error';

interface IError {
  error?: {
    message?: string;
  };
}

const genericMessage = {
  error: (e?: IError, errorMessage?: string | string[]) => {
    if (Array.isArray(errorMessage)) {
      for (let i = 0; i < errorMessage.length; i++) {
        message.error(errorMessage[i] || GENERIC_ERROR_MESSAGE, FIVE_SECONDS);
      }
    } else {
      message.error(e?.error?.message || errorMessage || GENERIC_ERROR_MESSAGE, FIVE_SECONDS);
    }

    if (e) throw e;
  },
  success: (successMessage: string) => {
    message.success(successMessage, FIVE_SECONDS);
  },
  warning: (warningMessage: string) => {
    message.warning(warningMessage, FIVE_SECONDS);
  },
};

export default genericMessage;
