import React, { useState, useEffect, useMemo, ReactNode, isValidElement, useCallback } from 'react';
import ActionConfirmationModal from '../../modals/ActionConfirmationModal/ActionConfirmationModal';
import useLocale from '../../hooks/useLocale';
import useModal from '../../hooks/useModal';
import useUnsaved from '../../hooks/useUnsaved';
import { eventBus } from '../../utils/eventBus';
import { SCROLL_TO_TOP_EVENT } from '../../constants/eventBus';

type TTabsGeneric = {
  key?: string;
  [prop: string]: any;
};

interface IArgs {
  changedFormProtection?: boolean;
  defaultActiveKey?: string;
  activeKey?: string;
  tabs?: TTabsGeneric[];
  children?: ReactNode;
}

function useTabChange({
  changedFormProtection = false,
  defaultActiveKey = undefined,
  activeKey = undefined,
  tabs = undefined,
  children = undefined,
}: IArgs): [string | undefined, (...args: any) => void] {
  const { t } = useLocale();
  const { showModal, closeModal } = useModal();
  const { isUnsavedForm, setIsUnsavedForm } = useUnsaved();

  const [activeTabKey, setActiveTabKey] = useState<string | undefined>();

  const initialTabKey = useMemo(() => {
    if (!activeTabKey) {
      if (activeKey) {
        return activeKey;
      } else if (defaultActiveKey) {
        return defaultActiveKey;
      } else if (tabs && tabs.length > 0) {
        return tabs[0].key;
      } else if (children) {
        const childrenArray = React.Children.toArray(children);
        const firstChild = childrenArray[0];
        if (isValidElement(firstChild) && firstChild.key) {
          const defaultKey = String(firstChild.key).replace('.$', '');
          return defaultKey;
        }
      }
      return undefined;
    }
    return activeTabKey;
  }, [activeTabKey, activeKey, tabs, defaultActiveKey, children]);

  useEffect(() => {
    setActiveTabKey(initialTabKey);
  }, [initialTabKey]);

  const handleTabChange = useCallback(
    (key: string, event?: any, customOnTabClick?: (key: string, event: any) => void, onClickFromTab?: () => void) => {
      if (key === activeTabKey) {
        return;
      }

      const runCustomOnTabClick = () => {
        if (customOnTabClick != null) {
          customOnTabClick(key, event);
        }
      };

      const runOnClickFromTab = () => {
        if (onClickFromTab != null) {
          onClickFromTab();
        }
      };

      if (isUnsavedForm && changedFormProtection) {
        showModal(
          <ActionConfirmationModal
            title={t.UNSAVED_CHANGED_TITLE}
            message={t.UNSAVED_CHANGED_MESSAGE}
            onOk={() => {
              closeModal();
              setIsUnsavedForm(false);
              setActiveTabKey(key);
              runCustomOnTabClick();
              runOnClickFromTab();
              eventBus.dispatch(SCROLL_TO_TOP_EVENT);
            }}
            onCancel={closeModal}
          />
        );
      } else {
        setActiveTabKey(key);
        runCustomOnTabClick();
        runOnClickFromTab();
        eventBus.dispatch(SCROLL_TO_TOP_EVENT);
      }
    },
    [
      activeTabKey,
      setActiveTabKey,
      changedFormProtection,
      closeModal,
      isUnsavedForm,
      setIsUnsavedForm,
      showModal,
      t.UNSAVED_CHANGED_TITLE,
      t.UNSAVED_CHANGED_MESSAGE,
    ]
  );

  return [activeTabKey, handleTabChange];
}

export default useTabChange;
