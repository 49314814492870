class formatter {
  static dateFormatter = new Intl.DateTimeFormat('en-US', {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
  });

  static date = (value: Date | undefined) => {
    if (!value) {
      return '';
    }

    return this.dateFormatter.format(value);
  };

  static currencyFormatter = (fraction = 2) =>
    new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: fraction,
      maximumFractionDigits: fraction,
    });

  static currency = (value: number | undefined, fraction?: number) => {
    if (value === undefined) {
      return '';
    }

    return this.currencyFormatter(fraction).format(value);
  };
}

export { formatter };
