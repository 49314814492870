import { useCallback, useEffect, useState } from 'react';
import { Form, Input, Divider, Spin } from 'antd';

import WeeklyRow from '../WeeklyRow/WeeklyRow';
import Button from '../../../../../components/Button/Button';

import { modifyWeeklyScheduleEntryArrayTimeToUtc } from '../../../utils';
import genericMessage from '../../../../../utils/genericMessage';
import { WEEKDAYS_OPTIONS } from '../constants';
import { ClientService } from '../../../../../shared/api/ClientService';
import API from '../../../../../utils/api';

import './WeeklySchedule.scss';
import useLocale from '../../../../../hooks/useLocale';

function WeeklySchedule() {
  const { TextArea } = Input;

  const [form] = Form.useForm<ClientService.WeeklyScheduleDto>();
  const [initialValues, setInitialValues] = useState<ClientService.WeeklyScheduleDto>();
  const [loading, setLoading] = useState<boolean>(false);
  const [submitting, setSubmitting] = useState<boolean>(false);
  const { t } = useLocale();

  const requestWeeklySchedule = useCallback(async () => {
    setLoading(true);
    const response = await API.getWeeklySchedule().catch(() => setLoading(false));
    setLoading(false);

    if (response) setInitialValues(response);
  }, []);

  const handleSubmit = useCallback(
    async (values: ClientService.WeeklyScheduleDto) => {
      setSubmitting(true);
      const response = await API.upsertWeeklySchedule({
        ...values,
        sundayEntries: modifyWeeklyScheduleEntryArrayTimeToUtc(values?.sundayEntries),
        tuesdayEntries: modifyWeeklyScheduleEntryArrayTimeToUtc(values?.tuesdayEntries),
        mondayEntries: modifyWeeklyScheduleEntryArrayTimeToUtc(values?.mondayEntries),
        wednesdayEntries: modifyWeeklyScheduleEntryArrayTimeToUtc(values?.wednesdayEntries),
        thursdayEntries: modifyWeeklyScheduleEntryArrayTimeToUtc(values?.thursdayEntries),
        fridayEntries: modifyWeeklyScheduleEntryArrayTimeToUtc(values?.fridayEntries),
        saturdayEntries: modifyWeeklyScheduleEntryArrayTimeToUtc(values?.saturdayEntries),
      } as ClientService.WeeklyScheduleDto).catch(() => setSubmitting(false));
      setSubmitting(false);

      if (response?.result === ClientService.Result.Successful) {
        genericMessage.success(t.WEEKLY_AVAILABILITY_IS_UPDATED);

        requestWeeklySchedule();
      }
    },
    [requestWeeklySchedule, t.WEEKLY_AVAILABILITY_IS_UPDATED]
  );

  useEffect(() => {
    if (!initialValues) requestWeeklySchedule();
  }, [initialValues, requestWeeklySchedule]);

  useEffect(() => {
    if (initialValues) form.setFieldsValue(initialValues);
  }, [form, initialValues]);

  return (
    <Spin spinning={loading || submitting}>
      <div className="WeeklySchedule">
        <div>{t.SET_WEEKLY_HOURS}</div>
        <Divider className="WeeklySchedule__divider" />
        <Form form={form} layout="vertical" onFinish={handleSubmit}>
          {WEEKDAYS_OPTIONS.map((item, index) => (
            <WeeklyRow name={item.value} weekday={item.shortName} key={`weekly-row-${String(index)}`} />
          ))}
          <Form.Item label={t.SCHEDULING_NOTES} name="schedulingNote" className="WeeklySchedule__scheduling-note">
            <TextArea rows={3} />
          </Form.Item>
          <Form.Item>
            <Button iconName="Save" kind="primary" className="WeeklySchedule__button" htmlType="submit">
              {t.SAVE}
            </Button>
          </Form.Item>
        </Form>
      </div>
    </Spin>
  );
}

export default WeeklySchedule;
