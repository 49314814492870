export const ALL_TASKS = 'all_tasks';
export const CLAIM_ACTIONS = 'claim_actions';
export const ASSETS_ACTIONS = 'assets_actions';
export const IE_ACTIONS = 'ie_actions';
export const TAX_RETURN_ACTIONS = 'tax_return_actions';
export const PROPOSAL_ACTIONS = 'proposal_actions';
export const BANKING_ACTIONS = 'banking_actions';
export const REPORT_170_ACTIONS = 'report170_actions';
export const COURT_ACTIONS = 'court_actions';
export const SRD_ACTIONS = 'srd_actions';
export const TRUSTEE_ACTIONS = 'trustee_actions';
