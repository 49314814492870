import { useRef } from 'react';
import { useDrag, useDrop } from 'react-dnd';
import { Col, Row } from 'antd';
import { Icon } from '@fluentui/react/lib/Icon';

import { IDocument } from '../types';
import useLocale from '../../../../hooks/useLocale';

import './BundleFilesModal.scss';
import styles from '../../../../styles/style.module.scss';

export const ItemTypes = {
  CARD: 'card',
};

interface ICard {
  id: string;
  document: IDocument;
  index: number;
  moveItem: (dragIndex: number, hoverIndex: number) => void;
  deleteItem: (itemIndex: number) => void;
  hasDelete?: boolean;
}

const DndRow = ({ id, document, index, moveItem, deleteItem, hasDelete = true }: ICard) => {
  const { getLocalizedDocumentName } = useLocale();
  const ref = useRef<HTMLInputElement>(null);
  const [{ handlerId }, drop] = useDrop({
    accept: ItemTypes.CARD,
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      };
    },
    hover(item: any, monitor) {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;
      if (dragIndex === hoverIndex) {
        return;
      }

      moveItem(dragIndex, hoverIndex);

      item.index = hoverIndex;
    },
  });

  const [{ isDragging }, drag] = useDrag({
    type: ItemTypes.CARD,
    item: () => {
      return { id, index };
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  drag(drop(ref));

  return (
    <div
      ref={ref}
      className="BundleFilesModal__list-item"
      data-handler-id={handlerId}
      style={{ opacity: isDragging ? 0.4 : 1, color: !document?.isPdf ? styles.colorDanger : styles.colorPrimary }}
    >
      <Col span={1}>
        <Icon iconName="GripperDotsVertical" />
      </Col>
      <Col span={7}>
        <div className="BundleFilesModal__list-item--title">{getLocalizedDocumentName(document)}</div>
      </Col>
      <Col span={7}>{document?.documentFolderName}</Col>
      <Col span={hasDelete ? 8 : 9}>{document?.documentPrefixName}</Col>
      <Col span={hasDelete ? 1 : 0}>
        <Icon iconName="Delete" onClick={() => deleteItem(index)} className="BundleFilesModal__delete-icon" />
      </Col>
    </div>
  );
};

export default DndRow;
