import Icon, { IconProps } from '../Icon/Icon';

import styles from './FormSectionTitle.module.scss';

interface IProps {
  titleText: string;
  noTopPadding?: boolean;
  icon?: IconProps;
  className?: string;
  style?: React.CSSProperties;
}

const FormSectionTitle = ({ titleText, noTopPadding, icon, className, style }: IProps): JSX.Element => {
  return (
    <div
      className={`${styles.FormSectionTitle} ${noTopPadding ? 'no-top-padding' : ''} ${className ? className : ''}`}
      style={style}
    >
      <div className={styles.title_text}>{titleText}</div>
      {icon && (
        <div className={styles.title_icon}>
          <Icon {...icon} />
        </div>
      )}
    </div>
  );
};

export default FormSectionTitle;
