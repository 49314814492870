import moment from 'moment';

class antdUtil {
  static numberFormatter = (value: number | undefined) => {
    if (!value) {
      return '';
    }

    return `${value}`.replace(/(?<=(\.\d{2}))(\d)+/g, '').replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  };

  static numberParser = (value: string | undefined) => {
    if (value === undefined) {
      return '';
    }

    return parseFloat(value!.replace(/\$\s?|(,*)/g, ''));
  };

  static currencyFormatter = (value: number | undefined) => {
    if (!value) {
      return '';
    }

    return `$ ${value}`.replace(/(?<=(\.\d{2}))(\d)+/g, '').replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  };

  static currencyParser = (value: string | undefined) => {
    if (value === undefined) {
      return '';
    }

    return parseFloat(value!.replace(/\$\s?|(,*)/g, ''));
  };

  static percentFormatter = (value: number | undefined) => {
    if (!value) {
      return '';
    }

    return `${value}%`;
  };

  static percentParser = (value: string | undefined) => {
    if (value === undefined) {
      return '';
    }

    return parseFloat(value!.replace('%', ''));
  };

  static sinFormatter = (value: number | undefined) => {
    if (!value) {
      return '';
    }

    return `${value}`.replace(/(?<=(\.))(\d)+/g, '').replace(/\B(?<=(^\d{3}))(?=\d)|\B(?<=(^\d{6}))(?=\d)/g, ' ');
  };

  static sinParser = (value: string | undefined) => {
    if (value === undefined) {
      return '';
    }

    return parseFloat(value!.replace(/\s*/g, ''));
  };

  static today = moment();
  static futureDates = (date: moment.Moment) => {
    return date > antdUtil.today;
  };

  static validateMessages = {
    required: '${label} is required!',
    types: {
      email: '${label} is not a valid email!',
      number: '${label} is not a valid number!',
    },
    number: {
      range: '${label} must be between ${min} and ${max}',
    },
  };
}

export { antdUtil };
