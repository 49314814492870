import { useCallback, useState } from 'react';
import { Modal, Form, Row, Col, Spin } from 'antd';
import moment from 'moment';

import Button from '../../../../../../components/Button/Button';
import DatePicker from '../../../../../../components/DatePicker/DatePicker';
import AttachmentsTab, { AttachmentsFormProps } from '../../../../../../components/AttachmentsTab';

import useLocale from '../../../../../../hooks/useLocale';
import { UPLOAD_FILES_EVENT } from '../../../../../../constants/eventBus';
import { DATE_FORMAT2, DATE_FORMAT_PLACEHOLDER } from '../../../../../../constants/common';
import { ProposalFormEnum } from '../types';
import { eventBus } from '../../../../../../utils/eventBus';
import API from '../../../../../../utils/api';

import './FormFilingModal.scss';
import { ClientService } from '../../../../../../shared/api/ClientService';

interface IProps {
  title: string;
  fileId: string;
  filingDate?: moment.Moment;
  prefixCodes: string[];
  proposalFormEnum: ProposalFormEnum;
  onOk?: () => void;
  onCancel?: () => void;
}

interface IForm extends AttachmentsFormProps {
  filingDate: moment.Moment;
}

function FormFilingModal({
  title,
  fileId,
  prefixCodes,
  onOk,
  onCancel,
  proposalFormEnum,
  filingDate,
}: IProps): JSX.Element {
  const { t } = useLocale();
  const [form] = Form.useForm<IForm>();

  const [loading, setLoading] = useState<boolean>(false);

  const requestUpdateByFormType = useCallback(
    (body: ClientService.ProposalFormFilingDto) => {
      switch (proposalFormEnum) {
        case ProposalFormEnum.Form93:
          return API.updateForm93Filing(body);
        case ProposalFormEnum.Form95:
          return API.updateForm95Filing(body);
        case ProposalFormEnum.Form96:
          return API.updateForm96Filing(body);
        default:
          return;
      }
    },
    [proposalFormEnum]
  );

  const requestFormUpdate = useCallback(
    async (documentsIds: string[], values: IForm) => {
      setLoading(true);
      for (const documentId of documentsIds) {
        await requestUpdateByFormType({
          fileId,
          documentId,
          filingDate: values?.filingDate,
        } as ClientService.ProposalFormFilingDto)?.catch(() => setLoading(false));
      }

      setLoading(false);
      if (onOk) onOk();
    },
    [onOk, requestUpdateByFormType, fileId]
  );

  const handleSubmit = useCallback(
    (values) => {
      eventBus.dispatch(UPLOAD_FILES_EVENT, {
        onUploadSuccess: (documentsIds: string[]) => requestFormUpdate(documentsIds, values),
      });
    },
    [requestFormUpdate]
  );

  const handleCancel = useCallback(() => {
    if (onCancel) onCancel();
  }, [onCancel]);

  return (
    <Modal
      title={title}
      destroyOnClose
      centered
      visible
      footer={null}
      closable
      onCancel={handleCancel}
      width={1200}
      className="FormFilingModal"
    >
      <Spin spinning={loading}>
        <Form
          form={form}
          onFinish={handleSubmit}
          layout="vertical"
          initialValues={{ filingDate }}
          name="FormFilingModal"
        >
          <Row>
            <Col span={8}>
              <Form.Item
                label={t.APPOINTMENT_SCHEDULING_DATE}
                name="filingDate"
                required
                rules={[{ required: true, message: t.REQUIRED_FIELD }]}
              >
                <DatePicker
                  format={DATE_FORMAT2}
                  dateSetter={null}
                  size="large"
                  disabledDate={(date) => date.isAfter(moment(), 'day')}
                  placeholder={DATE_FORMAT_PLACEHOLDER}
                />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item>
                <AttachmentsTab
                  key={proposalFormEnum}
                  form={form}
                  applicationFileId={fileId}
                  supportingFolderCode="lead"
                  prefixCodes={prefixCodes}
                  newSupportingFileRowLayoutProps={{
                    disabledPrefixInput: true,
                    preselectedPrefixInput: true,
                    disabledFoldersInput: true,
                  }}
                  isFlatLayout
                  flatLayoutProps={{ hasExistingFiles: true, label: t.FILES }}
                />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={12} justify="end" align="bottom" wrap={false} className="FormFilingModal__footer">
            <Col>
              <Form.Item noStyle>
                <Button kind="cancel" onClick={onCancel}>
                  {t.CANCEL}
                </Button>
              </Form.Item>
            </Col>
            <Col>
              <Form.Item noStyle>
                <Button htmlType="submit">{t.SAVE}</Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Spin>
    </Modal>
  );
}

export default FormFilingModal;
