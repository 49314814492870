import { useState, useCallback, useEffect } from 'react';
import { Modal, Form, Col, Row, Checkbox, Spin, Input, Select } from 'antd';
import { useQuery } from 'react-query';
import Button from '../../../components/Button/Button';
import useLocale from '../../../hooks/useLocale';
import genericMessage from '../../../utils/genericMessage';
import { ClientService } from '../../../shared/api/ClientService';
import { OFFICE_LOCATIONS_QUERY, PROVINCES_QUERY, REGIONS_LOCATIONS_QUERY } from '../../../constants/reactQuery';
import API from '../../../utils/api';
import './OfficeLocations.scss';
import SelectLookupDto from '../../../components/SelectLookupDto/SelectLookupDto';

interface IProps {
  id?: string;
  onOk: () => void;
  onCancel: () => void;
}

interface IDropdownOption {
  value: any;
  label: string;
}

const OfficeLocationEditor = ({ id, onOk, onCancel }: IProps) => {
  const { t } = useLocale();
  const [form] = Form.useForm<ClientService.OfficeLocationDto>();

  const [officeLocation, setOfficeLocation] = useState<ClientService.OfficeLocationDto>();
  const [loading, setLoading] = useState<boolean>(false);

  const [selectedCountryId, setSelectedCountryId] = useState<string | undefined>();
  const [selectedTimeZone, setSelectedTimeZone] = useState<ClientService.LookupDto | undefined>();

  const [isActive, setIsActive] = useState<boolean>(false);

  const validateRequiredFieldWhenRecordIsActive = (_: any, value: any) => {
    if (isActive) {
      if (!value) {
        return Promise.reject();
      }
    }
    return Promise.resolve();
  };

  const validateRequiredFieldWhenRecordIsBeingDisabled = (_: any, value: any) => {
    if (!isActive && officeLocation?.isActive) {
      if (!value) {
        return Promise.reject();
      }
    }
    return Promise.resolve();
  };

  const handleIsActiveChange = (value: any) => {
    setIsActive(value);

    if (!isActive) {
      form.validateFields([
        'name',
        'locationCode',
        'locationPrefix',
        'streetName',
        'city',
        'provinceId',
        'timeZoneId',
        'officeRegionId',
        'isServiceLocation',
        'isTrusteeOffice',
      ]); // Trigger validation on the dependent field
    }
  };

  const { data: provinces } = useQuery([PROVINCES_QUERY, selectedCountryId], () => {
    if (selectedCountryId) return API.listProvinces(selectedCountryId);
  });

  const { data: regions } = useQuery([REGIONS_LOCATIONS_QUERY], () => API.listOfficeRegions());

  const { data: officeLocations } = useQuery([OFFICE_LOCATIONS_QUERY], () => API.listOfficeLocations(false));

  const requestOfficeLocation = useCallback(
    async (officeLocationId: string) => {
      setLoading(true);
      const response = await API.officeLocationsGET2(officeLocationId).catch(() => setLoading(false));
      setLoading(false);

      if (response) {
        setSelectedCountryId(response.countryId);
        setIsActive(response.isActive ?? false);

        form.setFieldsValue({
          ...response,
        });

        setOfficeLocation(response);
      }
    },
    [form]
  );

  const requestUpdateOfficeLocation = useCallback(
    async (id, values) => {
      setLoading(true);

      const response = await API.officeLocationsPUT(id, {
        ...officeLocation,
        ...values,
      }).catch((error) => {
        setLoading(false);
        genericMessage.error({}, error?.error?.message);
      });
      setLoading(false);

      if (response?.result === ClientService.Result.Successful) {
        if (onOk) onOk();
      } else {
        genericMessage.error({}, response?.messages?.[0].body);
      }
    },
    [officeLocation, onOk]
  );

  const onFinish = useCallback(
    (values) => {
      if (id) {
        requestUpdateOfficeLocation(id, values);
      }
    },
    [id, requestUpdateOfficeLocation]
  );

  const onRegionChange = useCallback(
    (value) => {
      API.getTimeZoneByRegionId(value).then((res) => {
        setSelectedTimeZone(res);

        form.setFieldsValue({ timeZoneId: res.id, timeZoneCode: res.code });
      });
    },
    [selectedTimeZone]
  );

  useEffect(() => {
    if (id) requestOfficeLocation(id);
  }, [requestOfficeLocation, id]);

  return (
    <Modal
      destroyOnClose
      centered
      visible
      title={t.OFFICE_EDITOR_TITLE}
      width={1080}
      footer={null}
      onCancel={onCancel}
      className="OfficeLocations__modal"
    >
      <Spin spinning={loading}>
        <Form form={form} onFinish={onFinish}>
          <Row gutter={[16, 16]}>
            <Col span={12}>
              <Row>
                <Col span={24}>
                  <Form.Item
                    name="name"
                    label={t.OFFICE_DISPLAYNAME}
                    labelCol={{ span: 8 }}
                    labelAlign="left"
                    rules={[
                      { validator: validateRequiredFieldWhenRecordIsActive, message: t.OFFICE_LOCATIONCODE_REQUIRED },
                    ]}
                    required
                  >
                    <Input size="large" />
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item
                    name="locationPrefix"
                    label={t.OFFICE_PREFIX}
                    labelCol={{ span: 8 }}
                    labelAlign="left"
                    rules={[{ validator: validateRequiredFieldWhenRecordIsActive, message: t.OFFICE_PREFIX_REQUIRED }]}
                    required
                  >
                    <Input size="large" />
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item
                    name="latitude"
                    label={t.OFFICE_LATITUDE}
                    labelCol={{ span: 8 }}
                    labelAlign="left"
                    required
                  >
                    <Input size="large" readOnly disabled />
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item
                    name="longitude"
                    label={t.OFFICE_LONGITUDE}
                    labelCol={{ span: 8 }}
                    labelAlign="left"
                    required
                  >
                    <Input size="large" readOnly disabled />
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item name="isActive" label={t.STATUS} labelCol={{ span: 8 }} labelAlign="left">
                    <Select
                      size="large"
                      className="SelectLookupDto"
                      onChange={handleIsActiveChange}
                      options={
                        [
                          {
                            value: false,
                            label: t.INACTIVE,
                          },
                          {
                            label: t.ACTIVE,
                            value: true,
                          },
                        ] as IDropdownOption[]
                      }
                    ></Select>
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item
                    name="isServiceLocation"
                    valuePropName={'checked'}
                    label={t.OFFICE_IS_SERVICE_LOCATION}
                    labelCol={{ span: 8 }}
                    labelAlign="left"
                    required
                  >
                    <Checkbox disabled={true} />
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item
                    name="isTrusteeOffice"
                    valuePropName={'checked'}
                    label={t.OFFICE_IS_TRUSTEE_OFFICE}
                    labelCol={{ span: 8 }}
                    labelAlign="left"
                    required
                  >
                    <Checkbox />
                  </Form.Item>
                </Col>
              </Row>
            </Col>
            <Col span={12}>
              <Row>
                <Col span={24}>
                  <Form.Item
                    name="streetName"
                    label={t.OFFICE_ADDRESS1}
                    labelCol={{ span: 8 }}
                    labelAlign="left"
                    rules={[
                      { validator: validateRequiredFieldWhenRecordIsActive, message: t.OFFICE_ADDRESS1_REQUIRED },
                    ]}
                    required
                  >
                    <Input size="large" />
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item
                    name="streetNumber"
                    label={t.OFFICE_ADDRESS2}
                    labelCol={{ span: 8 }}
                    labelAlign="left"
                    rules={[
                      { validator: validateRequiredFieldWhenRecordIsActive, message: t.OFFICE_STREETNUMBER_REQUIRED },
                    ]}
                    required
                  >
                    <Input size="large" />
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item
                    name="unit"
                    label={t.OFFICE_ADDRESS_UNIT}
                    labelCol={{ span: 8 }}
                    labelAlign="left"
                    rules={[{ required: false }]}
                  >
                    <Input size="large" />
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item
                    name="city"
                    label={t.OFFICE_CITY}
                    labelCol={{ span: 8 }}
                    labelAlign="left"
                    rules={[{ validator: validateRequiredFieldWhenRecordIsActive, message: t.OFFICE_CITY_REQUIRED }]}
                    required
                  >
                    <Input size="large" />
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item
                    name="provinceId"
                    label={t.PROVINCE}
                    labelCol={{ span: 8 }}
                    labelAlign="left"
                    rules={[{ validator: validateRequiredFieldWhenRecordIsActive, message: t.REQUIRED_FIELD }]}
                    required
                  >
                    <SelectLookupDto data={provinces} />
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item
                    name="postalCode"
                    label={t.OFFICE_POSTALCODE}
                    labelCol={{ span: 8 }}
                    labelAlign="left"
                    required
                  >
                    <Input size="large" readOnly disabled />
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item
                    name="timeZoneCode"
                    label={t.OFFICE_TIMEZONE}
                    labelCol={{ span: 8 }}
                    labelAlign="left"
                    required
                  >
                    <Input size="large" readOnly disabled />
                  </Form.Item>
                  <Form.Item
                    name="timeZoneId"
                    label={t.OFFICE_TIMEZONE}
                    labelCol={{ span: 8 }}
                    labelAlign="left"
                    hidden={true}
                    rules={[{ validator: validateRequiredFieldWhenRecordIsActive, message: t.REQUIRED_FIELD }]}
                  >
                    <Input size="large" />
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item
                    name="officeRegionId"
                    label={t.REGION}
                    labelCol={{ span: 8 }}
                    labelAlign="left"
                    rules={[{ validator: validateRequiredFieldWhenRecordIsActive, message: t.REQUIRED_FIELD }]}
                    required
                  >
                    <SelectLookupDto data={regions} onChange={(value) => onRegionChange(value)} />
                  </Form.Item>
                </Col>
                {!isActive && officeLocation?.isActive ? (
                  <Col span={24}>
                    <Form.Item
                      name="moveToOfficeLocationId"
                      label={t.OFFICE_MOVETO}
                      labelCol={{ span: 8 }}
                      labelAlign="left"
                      rules={[{ validator: validateRequiredFieldWhenRecordIsBeingDisabled, message: t.REQUIRED_FIELD }]}
                      required
                    >
                      <SelectLookupDto
                        placeholder={t.SELECT_OFFICE}
                        data={officeLocations?.filter((item) => item.id !== officeLocation?.id)}
                      />
                    </Form.Item>
                  </Col>
                ) : (
                  <></>
                )}
              </Row>
            </Col>
          </Row>
          <Row gutter={24} justify="end" className="OfficeLocations__modal--footer">
            <Col>
              <Button kind="cancel" onClick={onCancel}>
                {t.CANCEL}
              </Button>
            </Col>
            <Col>
              <Button kind="primary" htmlType="submit">
                {t.SAVE}
              </Button>
            </Col>
          </Row>
        </Form>
      </Spin>
    </Modal>
  );
};

export default OfficeLocationEditor;
