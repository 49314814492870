import { useCallback, useEffect, useMemo, useState } from 'react';
import moment, { Moment } from 'moment';
import { Spin } from 'antd';

import Calendar from '../../../../components/Calendar/Calendar';
import OverrideModal from '../WeeklyAvailability/OverrideModal/OverrideModal';
import Content from './Content';

import useModal from '../../../../hooks/useModal';
import { MeetingTypeDto } from '../../types';
import { getYear, getMonth } from '../../utils';
import { MONTH_TIME_UNIT, DAY_TIME_UNIT } from '../../constants';
import { ClientService } from '../../../../shared/api/ClientService';
import API from '../../../../utils/api';

import './CalendarAvailability.scss';
import styles from '../../../../styles/style.module.scss';
import useLocale from '../../../../hooks/useLocale';

const CalendarAvailability = () => {
  const { showModal, closeModal } = useModal();

  const [selectedMonth, setSelectedMonth] = useState<Moment | undefined>(moment());

  const [availability, setAvailability] = useState<ClientService.AgentAvailabilityDto[]>();
  const [availabilityLoading, setAvailabilityLoading] = useState<boolean>(false);

  const [meetingTypes, setMeetingTypes] = useState<MeetingTypeDto[]>();
  const { t } = useLocale();

  const requestMeetingTypes = useCallback(async () => {
    const response = await API.listMeetingTypes();
    if (response) setMeetingTypes(response);
  }, []);

  const requestCalendarAvailability = useCallback(async (date: Moment) => {
    setAvailabilityLoading(true);
    const prevMonth = moment(date).subtract(1, MONTH_TIME_UNIT);
    const nextMonth = moment(date).add(1, MONTH_TIME_UNIT);

    const curr = await API.getAgentAvailability(getYear(date), getMonth(date)).catch(() =>
      setAvailabilityLoading(false)
    );
    const prev = await API.getAgentAvailability(getYear(prevMonth), getMonth(prevMonth)).catch(() =>
      setAvailabilityLoading(false)
    );
    const next = await API.getAgentAvailability(getYear(nextMonth), getMonth(nextMonth)).catch(() =>
      setAvailabilityLoading(false)
    );

    setAvailability([...(prev || []), ...(curr || []), ...(next || [])]);
    setAvailabilityLoading(false);
  }, []);

  const handleMonthChange = useCallback(
    (date?: Moment) => {
      if (!date?.isSame(selectedMonth, MONTH_TIME_UNIT)) {
        setSelectedMonth(date);
      }
    },
    [selectedMonth]
  );

  const handleDateSelect = useCallback(
    (date) => {
      const dateAvailability = availability?.find((item) => moment(item.date).utc().isSame(date, DAY_TIME_UNIT));
      const availabilityEntries = dateAvailability?.availabilityEntries;

      handleMonthChange(date);

      showModal(
        <OverrideModal
          data={{ date, availabilityEntries } as ClientService.AgentAvailabilityDto}
          onOk={() => {
            closeModal();
            requestCalendarAvailability(date);
          }}
          onCancel={closeModal}
        />
      );
    },
    [availability, closeModal, handleMonthChange, requestCalendarAvailability, showModal]
  );

  useEffect(() => {
    if (selectedMonth) requestCalendarAvailability(selectedMonth);
  }, [requestCalendarAvailability, selectedMonth]);

  useEffect(() => {
    requestMeetingTypes();
  }, [requestMeetingTypes]);

  const cells = useMemo(
    () =>
      availability?.map((item) => ({
        date: moment(item.date).utc(),
        content: <Content data={item} meetingTypes={meetingTypes} />,
      })),
    [availability, meetingTypes]
  );

  return (
    <Spin spinning={availabilityLoading}>
      <Calendar
        cellStyle={{
          height: 152,
          marginRight: 16,
          display: 'flex',
          flex: 1,
          flexDirection: 'column',
        }}
        contentStyle={{
          display: 'flex',
          flex: 1,
          flexDirection: 'column',
          alignContent: 'center',
          justifyContent: 'center',
        }}
        fullscreen
        disabledDate={(date) => date.isBefore(moment(), DAY_TIME_UNIT)}
        onSelect={handleDateSelect}
        onChange={handleMonthChange}
        defaultCellContent={<div className="CalendarAvailability__cell-container">{t.UNAVAILABLE}</div>}
        defaultCellStyle={{ backgroundColor: styles.colorCalendarSelectedCellColor }}
        cells={cells}
        rulerSize="large"
      />
    </Spin>
  );
};

export default CalendarAvailability;
