import { useState, useCallback } from 'react';
import { CaretDownOutlined } from '@ant-design/icons';
import type { CustomIconComponentProps } from '@ant-design/icons/lib/components/Icon';

const SelectSuffixIcon = (props: Partial<CustomIconComponentProps>) => {
  const [isActive, setActive] = useState(true);

  const toggleClass = useCallback(() => {
    setActive(!isActive);
  }, [isActive]);

  return (
    <CaretDownOutlined
      className={`${isActive ? 'ant-select-suffix' : ''} ${props?.className || ''}`}
      onClick={toggleClass}
      {...props}
    />
  );
};

export default SelectSuffixIcon;
