import React, { useEffect, useState, useCallback, useRef, ReactNode, isValidElement } from 'react';
import { useNavigate } from 'react-router-dom';
import AppTabsBase, { AppTabsBaseProps } from './AppTabsBase';
import { Popover, Space } from 'antd';
import Icon, { IconProps } from '../Icon/Icon';
import TabsLeftNav from '../TabsLeftNav/TabsLeftNav';
import SubTab from '../../pages/ApplicationOverviewPage/ApplicationOverviewContent/ApplicationForm/SubTab/SubTab';
import Button from '../Button/Button';
import useTabChange from './useTabChange';
import useLocale from '../../hooks/useLocale';

import styles from './AppTabSystem.module.scss';

const { AppTabPane } = AppTabsBase;

export type TTabsAndContentHorizontal = {
  key: string;
  title: string;
  status?: 'normal' | 'hidden' | 'disabled';
  content: JSX.Element | ReactNode | string;
  onClick?: () => void;
  onClickOverride?: () => void;
  redirectRoute?: string;
};

export type TTabsAndContentVertical = {
  key: string;
  title: string;
  subTitle?: string;
  iconName?: string;
  iconColor?: string;
  content: JSX.Element | ReactNode | string;
  onClick?: () => void;
};

type TIcon = string | JSX.Element;

type TPopoverTrigger = 'click' | 'focus' | 'hover';

export type TExtraContent = {
  popoverContent?: ReactNode | (() => ReactNode);
  popoverTrigger?: TPopoverTrigger | TPopoverTrigger[]; // e.g. "click"
  icon?: TIcon | IconProps;
  iconOnClick?: () => void;
  goToTab?: string; // key for the tab to be activated
  label: string;
  onClick?: () => void;
};

interface IPosition {
  left: string;
  width: string;
}

const isTExtraContent = (obj: any): obj is TExtraContent => {
  return 'label' in obj && ('onClick' in obj || 'goToTab' in obj || 'popoverContent' in obj);
};

type TCustomPropsCommon = {
  className?: string;
  changedFormProtection?: boolean;
  destroyInactiveTabPane?: boolean;
  defaultActiveKey?: string;
  activeKey?: string;
  onTabClick?: (key: string, event: any) => void;
};

type TCustomPropsHorizontal = {
  mode?: 'horizontal';
  tabs: TTabsAndContentHorizontal[];
  extraContent?: (TExtraContent | ReactNode)[];
  type?: 'card';
} & TCustomPropsCommon;

type TCustomPropsVertical = {
  mode: 'vertical';
  tabs: TTabsAndContentVertical[];
  extraContent?: never;
  type?: never;
} & TCustomPropsCommon;

type ExtendedAppTabsProps = AppTabsBaseProps & {
  className?: string;
  mode?: 'basic';
  changedFormProtection?: boolean;
  type?: 'line' | 'card' | 'editable-card';
  tabs?: never;
  extraContent?: never;
};

export type AppTabSystemProps = (TCustomPropsHorizontal | TCustomPropsVertical) | ExtendedAppTabsProps;

interface IObjectWithStringKeysAndValues {
  [key: string]: 'open' | 'closed';
}

function AppTabSystem({
  className,
  defaultActiveKey,
  activeKey,
  onTabClick,
  extraContent,
  type,
  changedFormProtection = false,
  destroyInactiveTabPane = true,
  ...props
}: AppTabSystemProps): JSX.Element {
  const tabs: (TTabsAndContentHorizontal[] | TTabsAndContentVertical[]) | undefined = (props as any).tabs;
  let mode: 'horizontal' | 'vertical' | 'basic' | undefined = (props as any).mode;
  const children: ReactNode | undefined = (props as any).children;

  if (tabs) {
    if (!mode || mode === 'basic') {
      mode = 'horizontal';
    }
  } else if (!tabs || children) {
    mode = 'basic';
  }

  const navigate = useNavigate();
  const { t } = useLocale();

  const [activeTabKey, handleTabChange] = useTabChange({
    changedFormProtection,
    defaultActiveKey,
    activeKey,
    tabs,
    children,
  });
  const [inkBarPosition, setInkBarPosition] = useState<IPosition>();
  const [popoverIconDirections, setPopoverIconDirections] = useState<
    IObjectWithStringKeysAndValues | Record<string, never>
  >({});

  const tabRefs = useRef<any>({});

  const setActiveTabInkBar = useCallback(() => {
    if (mode === 'horizontal') {
      if (activeTabKey != null && tabRefs.current[activeTabKey] != null) {
        setInkBarPosition({
          left: tabRefs.current[activeTabKey].offsetLeft,
          width: tabRefs.current[activeTabKey].clientWidth,
        });
      }
    }
  }, [mode, activeTabKey, tabRefs]);

  useEffect(() => {
    if (tabs) {
      setActiveTabInkBar();
    }
  }, [tabs, setActiveTabInkBar]);

  const setPopoverIconStates = useCallback(() => {
    if (extraContent != null && extraContent.length > 0) {
      let popoverStates: IObjectWithStringKeysAndValues = {};
      extraContent.forEach((extraContentItem, index) => {
        if (!isValidElement(extraContentItem)) {
          const thisExtraContentItem = extraContentItem as TExtraContent;
          if (thisExtraContentItem.popoverContent != null) {
            popoverStates[`${thisExtraContentItem.label.replaceAll(' ', '-')}_${index}`] = 'closed';
          }
        }
      });
      setPopoverIconDirections(popoverStates);
    }
  }, [extraContent]);

  useEffect(() => {
    if (tabs) {
      setPopoverIconStates();
    }
  }, [tabs, setPopoverIconStates]);

  const renderTabPaneContent = useCallback(
    (tabContent: TTabsAndContentHorizontal['content']) => {
      if (tabContent != null && typeof tabContent === 'string') {
        return <div>{tabContent}</div>;
      }
      if (tabContent != null) {
        return tabContent;
      }
      return <div>{t.TABS_NO_CONTENT_DEFINED}</div>;
    },
    [t.TABS_NO_CONTENT_DEFINED]
  );

  const renderExtraContentButton = useCallback(
    (extraContentItem: TExtraContent, index: number) => {
      const currentKey = `${extraContentItem.label.replaceAll(' ', '-')}_${index}`;
      let popoverState: 'open' | 'closed' | undefined = undefined;
      if (extraContentItem.popoverContent != null && popoverIconDirections[currentKey] != null) {
        popoverState = popoverIconDirections[currentKey];
      }
      const popoverIconName =
        popoverState != null ? (popoverState === 'closed' ? 'ChevronDown' : 'ChevronUp') : undefined;
      return (
        <Button
          key={`${index}_${extraContentItem.label.replaceAll(' ', '')}`}
          iconName={popoverIconName}
          onClick={() => {
            setPopoverIconDirections((prev) => {
              const obj = {
                ...prev,
              };
              obj[currentKey] = popoverState === 'closed' ? 'open' : 'closed';
              return obj;
            });
            if (extraContentItem.onClick != null) extraContentItem.onClick();
          }}
        >
          {extraContentItem.label}
          {extraContentItem.icon != null &&
            (() => {
              if (isValidElement(extraContentItem.icon)) {
                return extraContentItem.icon;
              } else {
                const iconOptionsName: string | undefined = (extraContentItem.icon as IconProps).iconName;

                let iconName = '';
                if (typeof extraContentItem.icon === 'string') iconName = extraContentItem.icon;
                if (iconOptionsName != null) iconName = iconOptionsName;

                const iconOptionsRotation = (extraContentItem.icon as IconProps).degreesToRotate;

                let rotationDegrees: string | undefined = undefined;
                if (iconName.toLowerCase() === 'drilldown') rotationDegrees = '-90';
                if (iconOptionsRotation != null) rotationDegrees = iconOptionsRotation;

                return (
                  <Icon
                    iconName={iconName}
                    color={(extraContentItem.icon as IconProps).color}
                    fontSize={(extraContentItem.icon as IconProps).fontSize}
                    degreesToRotate={rotationDegrees}
                    className="extracontent-icon"
                    style={(extraContentItem.icon as IconProps).style}
                    onClick={(e) => {
                      e.stopPropagation();
                      if (extraContentItem.goToTab != null) {
                        handleTabChange(extraContentItem.goToTab);
                      }
                      if (extraContentItem.iconOnClick != null) {
                        extraContentItem.iconOnClick();
                      }
                    }}
                  />
                );
              }
            })()}
        </Button>
      );
    },
    [popoverIconDirections, setPopoverIconDirections, handleTabChange]
  );

  /*
        If an array of tabs weren't passed to the "tabs" prop, render
        the AppTabsBase component without a custom tab bar and expect the
        tabs to be set through AppTabPane components passed as children
    */
  if (tabs == null) {
    return (
      <AppTabsBase
        className={className}
        defaultActiveKey={defaultActiveKey}
        activeKey={activeKey ? activeKey : activeTabKey}
        destroyInactiveTabPane={destroyInactiveTabPane}
        type={type}
        onTabClick={(key, event) => {
          handleTabChange(key, event, onTabClick);
        }}
        {...props}
      >
        {children}
      </AppTabsBase>
    );
  }

  if (mode === 'horizontal') {
    return (
      <div className={`${styles.TabSystem_horizontal_tabs} ${className ? className : ''}`}>
        <AppTabsBase
          activeKey={activeKey ? activeKey : activeTabKey}
          destroyInactiveTabPane={destroyInactiveTabPane}
          type={type}
          {...props}
          renderTabBar={() => {
            return (
              <div role="tablist" className="ant-tabs-nav">
                <div className="ant-tabs-nav-wrap">
                  <div className="ant-tabs-nav-list" style={{ transform: 'translate(0px, 0px)' }}>
                    {
                      // build the actual tabs
                      tabs.map((tabItem: TTabsAndContentHorizontal, index) => {
                        const tabStatus = tabItem.status != null ? tabItem.status : 'normal';
                        const statusClass = tabStatus === 'normal' ? '' : `tab-${tabStatus}`;
                        return (
                          <div
                            key={tabItem.key}
                            className={`ant-tabs-tab ${statusClass} ${
                              activeTabKey === tabItem.key ? 'ant-tabs-tab-active' : ''
                            }`}
                            // add a ref for each tab to the tabRefs object created
                            // with useRef. It will be used to get the active tab's
                            // location to animate position of the inkbar
                            ref={(el) => (tabRefs.current[tabItem.key] = el)}
                          >
                            <div
                              role="tab"
                              aria-selected={activeTabKey === tabItem.key ? 'true' : 'false'}
                              className={`ant-tabs-tab-btn`}
                              tabIndex={index}
                              id={`rc-tabs-${index + 1}-tab-${tabItem.key}`}
                              aria-controls={`rc-tabs-${index + 1}-panel-${tabItem.key}`}
                              onClick={(e) => {
                                if (tabStatus === 'normal') {
                                  if (tabItem.redirectRoute) {
                                    navigate(tabItem.redirectRoute);
                                  } else if (tabItem.onClickOverride != null) {
                                    tabItem.onClickOverride();
                                  } else {
                                    handleTabChange(tabItem.key, e, onTabClick, tabItem.onClick);
                                  }
                                }
                              }}
                            >
                              {tabItem.title}
                            </div>
                          </div>
                        );
                      })
                    }
                    <div
                      key="animated-ink-bar"
                      className="ant-tabs-ink-bar ant-tabs-ink-bar-animated"
                      style={{ left: inkBarPosition?.left, width: inkBarPosition?.width }}
                    ></div>
                  </div>
                </div>
                <div className="ant-tabs-extra-content">
                  {extraContent && (
                    <Space>
                      {extraContent.map((extraContentItem, index) => {
                        if (isTExtraContent(extraContentItem)) {
                          if (extraContentItem.popoverContent) {
                            return (
                              <Popover
                                key={`${extraContentItem.label.replaceAll(' ', '-')}_${index}`}
                                placement="bottomLeft"
                                content={extraContentItem.popoverContent}
                                trigger={extraContentItem.popoverTrigger ? extraContentItem.popoverTrigger : 'click'}
                                getPopupContainer={(triggerNode: HTMLElement) =>
                                  triggerNode.parentElement as HTMLElement
                                }
                              >
                                {renderExtraContentButton(extraContentItem, index)}
                              </Popover>
                            );
                          } else {
                            return renderExtraContentButton(extraContentItem, index);
                          }
                        } else if (isValidElement(extraContentItem)) {
                          if (extraContentItem.key == null) {
                            return React.cloneElement(extraContentItem, { key: `${index}_validElement` });
                          }
                          return extraContentItem;
                        }
                        return null;
                      })}
                    </Space>
                  )}
                </div>
              </div>
            );
          }}
        >
          {tabs.map((tab) => (
            <AppTabPane tab={tab.title} key={tab.key}>
              {renderTabPaneContent(tab.content)}
            </AppTabPane>
          ))}
        </AppTabsBase>
      </div>
    );
  } else {
    return (
      <div className={`${styles.TabSystem_vertical_tabs} ${className ? className : ''}`}>
        <TabsLeftNav
          activeKey={activeKey ? activeKey : activeTabKey}
          renderSubTabs={() =>
            tabs.map((tabItem: TTabsAndContentVertical, index) => (
              <div
                key={String(tabItem.key)}
                onClick={(e) => handleTabChange(tabItem.key, e, onTabClick, tabItem.onClick)}
              >
                <SubTab
                  title={tabItem.title}
                  subtitle={tabItem.subTitle != null ? tabItem.subTitle : undefined}
                  active={activeTabKey === tabItem.key}
                  iconName={tabItem.iconName != null ? tabItem.iconName : undefined}
                  iconColor={tabItem.iconColor != null ? tabItem.iconColor : undefined}
                />
              </div>
            ))
          }
          destroyInactiveTabPane={destroyInactiveTabPane}
        >
          {tabs.map((item) => {
            return <AppTabPane key={item.key}>{renderTabPaneContent(item.content)}</AppTabPane>;
          })}
        </TabsLeftNav>
      </div>
    );
  }
}

AppTabSystem.AppTabPane = AppTabPane;

export default AppTabSystem;
