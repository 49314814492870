import { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Form, InputNumber, Row, Col, Spin, Radio, Popover, Checkbox } from 'antd';
import { Icon } from '@fluentui/react';

import addIcon from '../../../../images/add_icon.svg';
import Divider from '../../../../components/Divider/Divider';
import CurrencyInput from './CurrencyInput';

import {
  currencyFormatter,
  currencyParser,
  handleCurrencyValidation,
  handlePositiveOnlyNumberValidation,
} from '../utils';
import { AdminConsumerProposal } from '../types';
import { MAX_DETAILS_COUNT } from '../constants';
import { ClientService } from '../../../../shared/api/ClientService';
import API from '../../../../utils/api';
import eventBus from '../../../../utils/eventBus';
import useLocale from '../../../../hooks/useLocale';

import '../FileAnalysis.scss';
import styles from '../../../../styles/style.module.scss';

const initialMonthlyPaymentDetails = {
  monthlyPaymentMonths: 0,
  monthlyPaymentAmount: 0,
  monthlyPaymentsTotal: 0,
};

function ProposalDetails({ disabled = false }: { disabled?: boolean }): JSX.Element {
  const { applicationFileId } = useParams<{ applicationFileId: string }>();
  const [form] = Form.useForm<ClientService.FileAnalysisProposalDetailDto>();
  const { t } = useLocale();

  const [proposalDetailsLoading, setProposalDetailsLoading] = useState<boolean>(false);
  const [proposalDetailsUpdating, setProposalDetailsUpdating] = useState<boolean>(false);

  const [listProposals, setListProposals] = useState<AdminConsumerProposal[]>();
  const [listProposalsLoading, setListProposalsLoading] = useState<boolean>(false);

  const [initialValues, setInitialValues] = useState<ClientService.FileAnalysisProposalDetailDto>();

  const requestFileAnalysisProposalDetails = useCallback(async () => {
    setProposalDetailsLoading(true);

    const response = await API.getFileAnalysisProposalDetails(applicationFileId).catch(() =>
      setProposalDetailsLoading(false)
    );

    if (response) {
      form.setFieldsValue({
        ...response,
        monthlyPaymentDetails: response?.monthlyPaymentDetails || [initialMonthlyPaymentDetails],
      } as ClientService.FileAnalysisProposalDetailDto);
      eventBus.dispatch('dataEntryFormValuesChanged');
      setInitialValues(response);
    }

    setProposalDetailsLoading(false);
  }, [applicationFileId, form]);

  const requstListProposals = useCallback(async () => {
    setListProposalsLoading(true);

    const response = await API.listAdminConsumerProposals().catch(() => setListProposalsLoading(false));
    if (response) setListProposals(response);

    setListProposalsLoading(false);
  }, []);

  const requestUpdateFileAnalysisProposalDetails = useCallback(
    async (updatedProposalDetails: ClientService.FileAnalysisProposalDetailInputDto) => {
      setProposalDetailsUpdating(true);

      const response = await API.updateFileAnalysisProposalDetails(updatedProposalDetails).catch(() =>
        setProposalDetailsUpdating(false)
      );

      if (response?.result === ClientService.Result.Successful) {
        requestFileAnalysisProposalDetails();
      }

      setProposalDetailsUpdating(false);
    },
    [requestFileAnalysisProposalDetails]
  );

  useEffect(() => {
    requestFileAnalysisProposalDetails();
  }, [requestFileAnalysisProposalDetails]);

  useEffect(() => {
    if (!listProposals) requstListProposals();
  }, [listProposals, requstListProposals]);

  const onFinish = useCallback(
    async ({
      initialDepositAmount,
      lumpSumAmount,
      adminConsumerProposalId,
      monthlyPaymentDetails,
      administratorTrusteeFeeSubjectToTaxes,
    }) => {
      const updatedProposalDetails = {
        fileId: applicationFileId,
        initialDepositAmount: currencyParser(initialDepositAmount),
        lumpSumAmount: currencyParser(lumpSumAmount),
        adminConsumerProposalId,
        monthlyPaymentDetails: (monthlyPaymentDetails as ClientService.FileAnalysisProposalMonthlyPaymentDetailDto[])
          .filter((i) => i.monthlyPaymentAmount !== undefined || i.monthlyPaymentMonths !== undefined)
          ?.map((paymentDetail) => ({
            ...paymentDetail,
            monthlyPaymentAmount: currencyParser(`${paymentDetail?.monthlyPaymentAmount}`),
          })),
        administratorTrusteeFeeSubjectToTaxes,
      } as ClientService.FileAnalysisProposalDetailInputDto;

      requestUpdateFileAnalysisProposalDetails(updatedProposalDetails);
    },
    [applicationFileId, requestUpdateFileAnalysisProposalDetails]
  );

  const getAdminConsumerProposalButtonName = useCallback(
    (enumValue?: ClientService.AdminConsumerProposalEnum) => {
      switch (enumValue) {
        case ClientService.AdminConsumerProposalEnum.AcceptProposal:
          return t.ACCEPT;
        case ClientService.AdminConsumerProposalEnum.NoRecommendation:
          return t.ABSTAIN;
        case ClientService.AdminConsumerProposalEnum.DoNotAcceptProposal:
          return t.REJECT;
        default:
          return '';
      }
    },
    [t.ABSTAIN, t.ACCEPT, t.REJECT]
  );

  const handleSubmit = useCallback(() => {
    form.submit();
  }, [form]);

  const handleEnter = (event: any) => {
    if (event.key === 'Enter') {
      form.submit();
    }
  };

  return (
    <Spin spinning={proposalDetailsLoading || proposalDetailsUpdating}>
      <Form
        form={form}
        layout="vertical"
        className="FileAnalysis__form"
        onFinish={onFinish}
        validateTrigger="onSubmit"
        onValuesChange={() => {
          if (form.getFieldValue(['monthlyPaymentDetails'])?.length !== initialValues?.monthlyPaymentDetails?.length) {
            handleSubmit();
          }
        }}
      >
        {(_, formInstance) => (
          <>
            <Row gutter={12}>
              <Col xl={7} xs={24}>
                <Form.Item
                  label={t.INITIAL_DEPOSIT}
                  name="initialDepositAmount"
                  rules={[{ validator: handleCurrencyValidation }]}
                >
                  <CurrencyInput
                    onBlur={() => {
                      if (form.getFieldValue('initialDepositAmount') !== initialValues?.initialDepositAmount) {
                        handleSubmit();
                      }
                    }}
                    onKeyUp={handleEnter}
                    disabled={disabled}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={12}>
              <Col xl={7} xs={24}>
                <Form.Item label={t.LUMP_SUM} name="lumpSumAmount" rules={[{ validator: handleCurrencyValidation }]}>
                  <CurrencyInput
                    onBlur={() => {
                      if (form.getFieldValue('lumpSumAmount') !== initialValues?.lumpSumAmount) {
                        handleSubmit();
                      }
                    }}
                    onKeyUp={handleEnter}
                    disabled={disabled}
                  />
                </Form.Item>
              </Col>
            </Row>

            <Form.List name="monthlyPaymentDetails">
              {(fields, { add, remove }) => (
                <div>
                  {fields.map(({ key, name }) => (
                    <Row gutter={12} key={name} align="bottom" className="FileAnalysis__form--list-item">
                      <Col xl={5} xs={24}>
                        <Form.Item
                          label={name === 0 && t.MONTHS}
                          name={[name, 'monthlyPaymentMonths']}
                          rules={[{ validator: handlePositiveOnlyNumberValidation }]}
                        >
                          <InputNumber
                            size="large"
                            precision={0}
                            controls={false}
                            min={0}
                            max={999}
                            onBlur={() => {
                              if (
                                form.getFieldValue(['monthlyPaymentDetails', name, 'monthlyPaymentMonths']) !==
                                initialValues?.monthlyPaymentDetails?.[name]?.monthlyPaymentMonths
                              ) {
                                handleSubmit();
                              }
                            }}
                            onKeyUp={handleEnter}
                            disabled={disabled}
                          />
                        </Form.Item>
                      </Col>
                      <Col xl={8} xs={24}>
                        <Form.Item
                          label={name === 0 && t.AMOUNT}
                          name={[name, 'monthlyPaymentAmount']}
                          rules={[{ validator: handleCurrencyValidation }]}
                        >
                          <CurrencyInput
                            onKeyUp={handleEnter}
                            onBlur={() => {
                              if (
                                form.getFieldValue(['monthlyPaymentDetails', name, 'monthlyPaymentAmount']) !==
                                initialValues?.monthlyPaymentDetails?.[name]?.monthlyPaymentAmount
                              ) {
                                handleSubmit();
                              }
                            }}
                            disabled={disabled}
                          />
                        </Form.Item>
                      </Col>
                      <Col xl={10} xs={23}>
                        <Form.Item noStyle shouldUpdate>
                          {({ getFieldValue, getFieldError }) => {
                            const hasErrors = Boolean(
                              getFieldError(['monthlyPaymentDetails', name, 'monthlyPaymentMonths'])?.length ||
                                getFieldError(['monthlyPaymentDetails', name, 'monthlyPaymentAmount'])?.length
                            );
                            const monthlyPaymentDetails = getFieldValue(['monthlyPaymentDetails', name]);
                            return (
                              <Form.Item label={name === 0 && t.TOTAL_MONTHLY_PAYMENTS}>
                                <CurrencyInput
                                  disabled
                                  value={
                                    hasErrors
                                      ? '-'
                                      : monthlyPaymentDetails?.monthlyPaymentMonths *
                                        monthlyPaymentDetails?.monthlyPaymentAmount
                                  }
                                />
                              </Form.Item>
                            );
                          }}
                        </Form.Item>
                      </Col>
                      <Col xl={1} xs={1}>
                        <Form.Item label={name === 0 && ' '}>
                          <Icon
                            iconName="Delete"
                            onClick={() => {
                              if (!disabled) remove(name);
                            }}
                            className="FileAnalysis__form--delete-icon"
                            style={{
                              color: disabled ? styles.colorSecondary : styles.colorDanger,
                              cursor: disabled ? 'auto' : 'pointer',
                            }}
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                  ))}

                  {formInstance.getFieldValue('monthlyPaymentDetails')?.length < MAX_DETAILS_COUNT && !disabled && (
                    <Form.Item>
                      <Row align="middle" justify="end">
                        <Col onClick={() => add(initialMonthlyPaymentDetails)} className="FileAnalysis__form--add-icon">
                          {t.ADD} <img src={addIcon} alt="add-icon" width={15} height={15} />
                        </Col>
                      </Row>
                    </Form.Item>
                  )}
                  <Divider />
                </div>
              )}
            </Form.List>

            <Form.Item label={t.TOTAL_PROPOSAL_PAYMENTS} name="proposalPaymentsTotal">
              <Row align="middle" gutter={8}>
                <Col
                  className="FileAnalysis__form--total-payments"
                  style={{
                    color: disabled ? styles.colorSecondary : styles.colorPrimary,
                  }}
                >
                  {`$ ${currencyFormatter(formInstance.getFieldValue('proposalPaymentsTotal'))}`}
                </Col>
                <Col>
                  <Popover
                    content={
                      <div>
                        {t.AMOUNT_REQUIRED_FOR_PROPOSAL_CONTRIBUTION}
                        <b>{` $${currencyFormatter(formInstance.getFieldValue('proposalContribution')) || 0}`}</b>
                      </div>
                    }
                    trigger="hover"
                    placement="bottom"
                  >
                    <Icon iconName="Info" className="FileAnalysis__form--tooltip-icon" />
                  </Popover>
                </Col>
              </Row>
            </Form.Item>

            <Form.Item name="adminConsumerProposalId" label={t.RECOMMENDATION}>
              <Radio.Group
                buttonStyle="solid"
                optionType="button"
                key="postType"
                disabled={listProposalsLoading || disabled}
                onChange={handleSubmit}
              >
                {listProposals?.map((item) => (
                  <Radio.Button value={item.id} key={item.id}>
                    {getAdminConsumerProposalButtonName(item?.enumValue)}
                  </Radio.Button>
                ))}
              </Radio.Group>
            </Form.Item>

            <Form.Item name="administratorTrusteeFeeSubjectToTaxes" valuePropName="checked" style={{ marginTop: -12 }}>
              <Checkbox disabled={disabled} onChange={handleSubmit}>
                {t.ADMINISTRATOR_TRUSTEE_FEE_SUBJECT_TO_TAXES}
              </Checkbox>
            </Form.Item>
          </>
        )}
      </Form>
    </Spin>
  );
}

export default ProposalDetails;
