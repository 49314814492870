import { Col, Row } from 'antd';

import './BlockWithLabel.scss';

interface IProps {
  children?: string | JSX.Element | Array<JSX.Element>;
  label?: string;
  layout?: 'horizontal' | 'vertical';
  labelSpan?: number;
  childrenSpan?: number;
  className?: string;
  labelStyle?: React.CSSProperties;
  childrenStyle?: React.CSSProperties;
}

function BlockWithLabel({
  children,
  label,
  layout,
  labelSpan,
  childrenSpan,
  className,
  labelStyle,
  childrenStyle,
}: IProps): JSX.Element {
  const layoutClassName = layout === 'horizontal' ? 'BlockWithLabel__horizontal' : 'BlockWithLabel';
  return (
    <Row className={`${layoutClassName} ${className || ''}`}>
      <Col span={labelSpan || 24} className={`${layoutClassName}__label`} style={labelStyle}>
        {label}
      </Col>
      <Col span={childrenSpan || 24} className={`${layoutClassName}__inner-container`} style={childrenStyle}>
        {children}
      </Col>
    </Row>
  );
}

export default BlockWithLabel;
