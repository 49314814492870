import { useCallback } from 'react';
import update from 'immutability-helper';

import DndCard from './DndCard';

interface IContainer {
  cards?: string[];
  containerId: string;
  setCards: (data: string[]) => void;
  isEditable?: boolean;
}

const SummaryDndContainer = ({ cards = [], containerId, setCards, isEditable = false }: IContainer) => {
  const moveCard = useCallback(
    (dragIndex, hoverIndex) => {
      if (!isEditable) return;

      setCards(
        update(cards, {
          $splice: [
            [dragIndex, 1],
            [hoverIndex, 0, cards[dragIndex]],
          ],
        })
      );
    },
    [cards, isEditable, setCards]
  );

  const renderCard = useCallback(
    (card, index) => {
      return (
        <DndCard
          key={`${containerId}-${index}`}
          index={index}
          id={`${containerId}-${index}`}
          text={card}
          moveCard={moveCard}
          isEditable={isEditable}
        />
      );
    },
    [containerId, isEditable, moveCard]
  );
  return (
    <>
      <div>{cards.map((card, i) => renderCard(card, i))}</div>
    </>
  );
};

export default SummaryDndContainer;
