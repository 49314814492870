import { useState, useEffect } from 'react';
import { Col } from 'antd';
import FormInput from '../../../../../components/Forms/FormInput/FormInput';
import Label from '../../../../../components/Forms/Label/Label';
import API from '../../../../../utils/api';
import { IAscendVariableFieldsBlockProps } from '../SearchAscend';
import { ISelectOption } from '../../../../../components/Forms/FormInput/SelectInput';
import { ClientService } from '../../../../../shared/api/ClientService';
import useLocale from '../../../../../hooks/useLocale';
import Loading from '../../../../../components/Loading/Loading';

import { stylesSearchAscend } from '../SearchAscend';

interface IIncomeAndExpenseValues {
  ascendNoOfHousehold?: string;
  ascendSelectIncomeAndExpense?: string;
}

const COLS_IN_GRID = 24;
const COLS_IN_ROW = 3;

const newIncomeAndExpenseAscendFields = ['ascendNoOfHousehold', 'ascendSelectIncomeAndExpense'];

// The SearchAscend component will inject these props
const NewIncomeAndExpenseFields = ({
  updateAscendSearchForm,
  setVariableFieldsListInParent,
  isMatched,
  searchResponse,
}: IAscendVariableFieldsBlockProps) => {
  const { t } = useLocale();

  const [incomeAndExpenseFields, setIncomeAndExpenseFields] = useState<IIncomeAndExpenseValues | undefined>();

  const [periods, setPeriods] = useState<ISelectOption[] | undefined>();

  const [loadingPeriods, setLoadingPeriods] = useState<boolean>(false);

  useEffect(() => {
    if (setVariableFieldsListInParent) {
      setVariableFieldsListInParent(newIncomeAndExpenseAscendFields);
    }
  }, [setVariableFieldsListInParent]);

  useEffect(() => {
    if (incomeAndExpenseFields && updateAscendSearchForm) {
      updateAscendSearchForm((prev) => ({
        ...prev,
        ...incomeAndExpenseFields,
      }));
    }
  }, [updateAscendSearchForm, incomeAndExpenseFields]);

  useEffect(() => {
    if (!isMatched || !searchResponse) return;
    if (!searchResponse.fileId) return;
    setLoadingPeriods(true); // set loadingPeriods to true

    // console.log('WE HAVE A MATCH!!!!!:', searchResponse);
    console.log('searchResponse.fileId:', searchResponse.fileId);
    API.periods(searchResponse.fileId)
      .then((response: ClientService.IncomeExpenseSubmissionPeriodListItemDto[]) => {
        // console.log('PERIODS response:', response);
        setPeriods(
          response.map((period) => {
            return {
              value: period.externalIeSubmissionPeriodId,
              label: period.period,
            };
          })
        );
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        setLoadingPeriods(false); // set loadingPeriods to false
      });
  }, [isMatched, searchResponse]);

  return (
    <>
      <div className={stylesSearchAscend.dynamic_fields_container}>
        <Col span={COLS_IN_GRID / COLS_IN_ROW}>
          <FormInput
            label={<Label value={t.ASCEND_NUMBER_OF_HOUSEHOLD} required={isMatched} />}
            name="ascendNoOfHousehold"
            onChange={(e: any) => {
              const value = (e?.target?.value || '')?.replace(/[^0-9]/g, '');
              const numberedValue = Number(value);

              let ascendNoOfHousehold = '';

              if (value !== '' && !isNaN(numberedValue)) {
                ascendNoOfHousehold = numberedValue > 99 ? value?.substring(0, 2) : numberedValue < 1 ? '1' : value;
              }

              setIncomeAndExpenseFields((prev) => ({
                ...prev,
                ascendNoOfHousehold,
              }));
            }}
          />
        </Col>
        <Col span={COLS_IN_GRID / COLS_IN_ROW}>
          <FormInput
            type="select"
            label={<Label value={t.ASCEND_SELECT_INCOME_AND_EXPENSE} required={isMatched} />}
            name="ascendSelectIncomeAndExpense"
            optionsList={periods}
            onChange={(value: string) => {
              setIncomeAndExpenseFields((prev) => ({
                ...prev,
                ascendSelectIncomeAndExpense: value,
              }));
            }}
          >
            {loadingPeriods ? (
              <Loading style={{ display: 'flex', alignItems: 'left' }} loadingSize={25} noText />
            ) : null}
          </FormInput>
        </Col>
      </div>
    </>
  );
};

export default NewIncomeAndExpenseFields;
