import { useCallback } from 'react';
import { Modal, Table, Col, Row } from 'antd';
import { Link } from 'react-router-dom';

import Button from '../../components/Button/Button';

import useLocale from '../../hooks/useLocale';
import useModal from '../../hooks/useModal';

import { ClientService } from '../../shared/api/ClientService';
import { ROUTES } from '../../constants/routes';

import './SimilarRecords.scss';

interface IProps {
  onCancel: () => void;
  onSaveAsNew: () => void;
  onError: () => void;
  duplicates?: ClientService.DuplicateClientDto[];
}

const HIGHLIGHTED_CLASS = 'SimilarRecords__item--highlighted';

const SimilarRecordsModal = ({ duplicates, onCancel, onSaveAsNew, onError }: IProps) => {
  const { t } = useLocale();
  const { closeModal } = useModal();

  const columns = [
    {
      title: t.NEW_PROSPECT_FIRST_NAME,
      dataIndex: 'firstName',
      key: 'firstName',
      onCell: (item: ClientService.DuplicateClientDto) =>
        item.isMatchingFirstName ? { className: HIGHLIGHTED_CLASS } : {},
    },
    {
      title: t.NEW_PROSPECT_LAST_NAME,
      dataIndex: 'lastName',
      key: 'lastName',
      onCell: (item: ClientService.DuplicateClientDto) =>
        item.isMatchingLastName ? { className: HIGHLIGHTED_CLASS } : {},
    },
    {
      title: t.DASHBOARD_TABLE_FILE_NAME,
      dataIndex: 'fileName',
      key: 'fileName',
      render: (fileName: string, item: ClientService.DuplicateClientDto) => (
        <Link onClick={closeModal} to={`${ROUTES.DEBTOR_PROFILE}/${item.id}`}>
          {fileName || '-'}
        </Link>
      ),
    },
    {
      title: t.DASHBOARD_TABLE_ESTATE,
      dataIndex: 'estateNumber',
      key: 'estateNumber',
    },
    {
      title: t.NEW_PROSPECT_EMAIL_ADDRESS,
      dataIndex: 'emailAddress',
      key: 'emailAddress',
      onCell: (item: ClientService.DuplicateClientDto) =>
        item.isMatchingEmailAddress ? { className: HIGHLIGHTED_CLASS } : {},
    },
    {
      title: t.SIMILAR_RECORDS_ADDRESS,
      dataIndex: 'address',
      key: 'address',
    },
    {
      title: t.SIMILAR_RECORDS_MAIN_PHONE_NUMBER,
      dataIndex: 'phoneNumber',
      key: 'phoneNumber',
      onCell: (item: ClientService.DuplicateClientDto) =>
        item.isMatchingPhoneNumber ? { className: HIGHLIGHTED_CLASS } : {},
    },
    {
      title: t.DASHBOARD_TABLE_STAGE,
      dataIndex: 'stage',
      key: 'stage',
    },
  ];

  const handleOk = useCallback(() => {
    const confirmedDuplicatesFound = duplicates?.some(
      (item: ClientService.DuplicateClientDto) => item.isConfirmedDuplicate
    );

    if (confirmedDuplicatesFound) {
      onError();
    } else {
      onSaveAsNew();
    }
  }, [duplicates, onError, onSaveAsNew]);

  return (
    <Modal
      destroyOnClose
      centered
      visible
      title={t.NEW_PROSPECT_SIMILAR_RECORDS}
      width={980}
      className="SimilarRecords"
      footer={null}
      onCancel={onCancel}
    >
      <Table
        rowKey="id"
        pagination={{ size: 'small', position: ['bottomLeft'] }}
        dataSource={duplicates}
        columns={columns}
      />
      <Row align="middle" justify="end" gutter={10} style={{ paddingTop: 24 }}>
        <Col>
          <Button kind="cancel" onClick={onCancel}>
            {t.NEW_PROSPECT_CANCEL}
          </Button>
        </Col>
        <Col>
          <Button kind="primary" onClick={handleOk}>
            {t.NEW_PROSPECT_SAVE_AS_NEW}
          </Button>
        </Col>
      </Row>
    </Modal>
  );
};

export default SimilarRecordsModal;
