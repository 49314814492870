import { ReactNode } from 'react';
import useLocale from '../../hooks/useLocale';
import styles from './NoticeToUser.module.scss';

interface IProps {
  text?: string;
  children?: ReactNode;
}

function NoticeToUser({ text, children }: IProps): JSX.Element {
  const { t } = useLocale();
  return (
    <div className={styles.NoticeToUser}>
      <div className="title">{t.NOTICE_TO_USER}</div>
      <div className="content">{text ? text : children}</div>
    </div>
  );
}

NoticeToUser.noticeToUserStyles = styles;

export default NoticeToUser;
