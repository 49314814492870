import { ClientService } from '../../../../shared/api/ClientService';

export enum TableViewEnum {
  TableList = 0,
  TablePreview = 1,
}

export enum TableListEnum {
  List = 0,
  RestoreList = 1,
}

export enum TablePreviewEnum {
  Regular = 0,
  FullScreen = 1,
}

export interface DocumentsDataType extends ClientService.IDocumentDto {
  key: React.Key;
}

export interface VersionsDataType extends ClientService.IDocumentVersionDto {
  key: React.Key;
}
