import { Table as AntdTable, TableProps } from 'antd';

import './Table-KS.scss';
import './TablePagination-KS.scss';

export interface ITableProps extends TableProps<any> {
  allWhite?: boolean;
}

function Table({ allWhite, ...props }: ITableProps): JSX.Element {
  const cssClass = allWhite ? 'ant-table-white' : '';
  return (
    <div className="Table_Custom">
      <AntdTable {...props} className={cssClass.length ? cssClass : ''} />
    </div>
  );
}

export default Table;
