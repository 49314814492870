import { useCallback, useState } from 'react';
import { Modal, Form, Row, Col, Spin, Checkbox } from 'antd';

import Button from '../../../../../../components/Button/Button';
import AttachmentsTab, { AttachmentsFormProps } from '../../../../../../components/AttachmentsTab';

import useLocale from '../../../../../../hooks/useLocale';
import { FOLDER_COURT_REVIVAL, PREFIX_COURT_REVIVAL } from '../constants';
import { UPLOAD_FILES_EVENT } from '../../../../../../constants/eventBus';
import { eventBus } from '../../../../../../utils/eventBus';
import { ClientService } from '../../../../../../shared/api/ClientService';
import API from '../../../../../../utils/api';
import { argumentifyUpdateDocument } from '../../../../../ApplicationOverviewPage/DocumentsContent/utils';

import './CourtRevivalModal.scss';
import moment from 'moment';

interface IProps {
  fileId: string;
  onOk?: () => void;
  onCancel?: () => void;
}

interface IForm extends AttachmentsFormProps {
  isRevived?: boolean;
}

function CourtRevivalModal({ fileId, onOk, onCancel }: IProps): JSX.Element {
  const { t } = useLocale();
  const [form] = Form.useForm<IForm>();

  const [loading, setLoading] = useState<boolean>(false);

  const requestCourtRevivalUpdate = useCallback(
    async (documentsIds: string[], values: IForm) => {
      setLoading(true);
      for (const documentId of documentsIds) {
        await API.updateCourtRevival({
          fileId,
          documentId,
          isRevived: values?.isRevived,
        } as ClientService.ProposalCourtRevivalDto)?.catch(() => setLoading(false));
      }

      setLoading(false);
      if (onOk) onOk();
    },
    [onOk, fileId]
  );

  const handleShare = useCallback(
    async (documentsIds?: string[], values?: any, isVisible?: boolean) => {
      if (!documentsIds || !documentsIds?.length) return;

      if (isVisible === undefined) {
        isVisible = false;
      }

      setLoading(true);
      for (const id of documentsIds) {
        const document = await API.documentsGET2(id).catch(() => setLoading(false));

        if (document) {
          await API.documentsPUT(
            ...argumentifyUpdateDocument({
              ...document,
              id,
              isVisible,
              fileModifiedDate: moment(),
            })
          ).catch(() => setLoading(false));
        }
      }
      setLoading(false);

      requestCourtRevivalUpdate(documentsIds, values);
    },
    [requestCourtRevivalUpdate]
  );

  const handleUploadAndShare = useCallback(() => {
    form.validateFields().then((values) => {
      eventBus.dispatch(UPLOAD_FILES_EVENT, {
        onUploadSuccess: async (documentsIds: string[]) => {
          //
          //Note: Since it is applying file share process, so, set document's IsVisible true here.
          //
          handleShare(documentsIds, values, true);
        },
      });
    });
  }, [form, handleShare]);

  const handleUpload = useCallback(() => {
    form.validateFields().then((values) => {
      eventBus.dispatch(UPLOAD_FILES_EVENT, {
        onUploadSuccess: async (documentsIds: string[]) => {
          // By default, document's IsVisible = false. 22521
          handleShare(documentsIds, values, false);
        },
      });
    });
  }, [form, handleShare]);

  const handleCancel = useCallback(() => {
    if (onCancel) onCancel();
  }, [onCancel]);

  return (
    <Modal
      title={t.COURT_REVIVAL}
      destroyOnClose
      centered
      visible
      footer={null}
      closable
      onCancel={handleCancel}
      width={1200}
      className="CourtRevivalModal"
    >
      <Spin spinning={loading}>
        <Form form={form} layout="vertical" name="CourtRevivalModal">
          <Row>
            <Col span={8}>
              <Form.Item
                name="isRevived"
                valuePropName="checked"
                rules={[
                  {
                    validator: (_, value) => (value ? Promise.resolve() : Promise.reject(new Error(t.REQUIRED_FIELD))),
                  },
                ]}
              >
                <Checkbox>
                  <span className="CourtRevivalModal__checkbox">{t.REVIVED}</span>
                </Checkbox>
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item>
                <AttachmentsTab
                  form={form}
                  applicationFileId={fileId}
                  supportingFolderCode={FOLDER_COURT_REVIVAL}
                  prefixCodes={[PREFIX_COURT_REVIVAL]}
                  newSupportingFileRowLayoutProps={{
                    disabledPrefixInput: true,
                    preselectedPrefixInput: true,
                    disabledFoldersInput: true,
                  }}
                  isFlatLayout
                  flatLayoutProps={{ hasExistingFiles: true, label: t.FILES }}
                  columnsProps={{ hasAdditionalInfoColumn: false }}
                  required
                />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={12} justify="end" align="bottom" wrap={false} className="FormFilingModal__footer">
            <Col>
              <Form.Item noStyle>
                <Button kind="cancel" onClick={onCancel}>
                  {t.CANCEL}
                </Button>
              </Form.Item>
            </Col>
            <Col>
              <Form.Item noStyle>
                <Button onClick={handleUpload}>{t.UPLOAD}</Button>
              </Form.Item>
            </Col>
            <Col>
              <Form.Item noStyle>
                <Button onClick={handleUploadAndShare}>{t.UPLOAD_SHARE}</Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Spin>
    </Modal>
  );
}

export default CourtRevivalModal;
