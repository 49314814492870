import { useCallback, useEffect, useState } from 'react';
import DataItem, { IValue } from '../../../../components/DataItem/DataItem';
import EditButton from '../../../../components/Button/AppButtons/EditButton/EditButton';
import Loading from '../../../../components/Loading/Loading';

import styles from './LineItemsDisplay.module.scss';
import useLocale from '../../../../hooks/useLocale';

type TSetStateWithLineItemData = React.Dispatch<React.SetStateAction<LineItemGroup[] | undefined>>;

type LineItemsDisplayCommonProps = {
  title?: string;
  formatAmount?: IValue['format'];
  hideZeroValueItems?: boolean;
  calculateSubtotal?: boolean;
  subtotalsColor?: IValue['color'];
  onEdit?: () => void;
  editable?: boolean;
  className?: string;
  style?: React.CSSProperties;
};

type LineItem = {
  label?: string;
  amount?: string | number;
};

export type LineItemGroup = {
  group?: string;
  lineItems?: LineItem[];
  subtotal?: string | number;
};

type LineItemsDisplayDataProps = {
  data?: LineItemGroup[];
} & LineItemsDisplayCommonProps;

type LineItemsDisplayApiProps = {
  apiGet?: () => Promise<any>;
  apiSetData?: (response: any, setState: TSetStateWithLineItemData) => void; // allows you to manipulate response data before it is placed in state
} & LineItemsDisplayCommonProps;

type LineItemsDisplayProps = LineItemsDisplayDataProps | LineItemsDisplayApiProps;

const LineItemsDisplay = ({
  title,
  formatAmount = 'currency-no-dollar-sign',
  hideZeroValueItems,
  calculateSubtotal,
  subtotalsColor,
  onEdit,
  editable = true,
  className,
  style,
  ...props
}: LineItemsDisplayProps): JSX.Element => {
  const apiGet: LineItemsDisplayApiProps['apiGet'] | undefined = (props as any).apiGet;
  const apiSetData: LineItemsDisplayApiProps['apiSetData'] | undefined = (props as any).apiSetData;

  const data: LineItemGroup[] | undefined = (props as any).data;

  const [lineItemGroups, setLineItemGroups] = useState<LineItemGroup[] | undefined>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { t } = useLocale();

  const setData = useCallback(() => {
    if (data) {
      setLineItemGroups(data);
      return false;
    }

    if (apiGet) {
      setIsLoading(true);
      apiGet()
        .then((response) => {
          // set dataItems in state
          if (apiSetData) {
            apiSetData(response, setLineItemGroups);
          } else {
            setLineItemGroups(response);
          }
        })
        .catch((error) => {
          console.error('There was an error retrieving Email data');
        });
    }
  }, [data, apiGet, apiSetData]);

  useEffect(() => {
    setData();
  }, [setData]);

  const renderLineItemGroups = useCallback(() => {
    if (lineItemGroups) {
      let itemsToShow: LineItemGroup[];
      if (hideZeroValueItems) {
        const lineItemGroupsWithValues: LineItemGroup[] = [];
        lineItemGroups.forEach((lineItemGroup) => {
          const group: LineItemGroup = {};
          if (lineItemGroup.group) group.group = lineItemGroup.group;
          group.lineItems = lineItemGroup.lineItems?.filter((item) => {
            return Number(item.amount) !== 0;
          });
          if (group.lineItems?.length) lineItemGroupsWithValues.push(group);
        });
        itemsToShow = lineItemGroupsWithValues;
      } else {
        itemsToShow = lineItemGroups;
      }
      if (!itemsToShow.length) return;
      return itemsToShow.map((lineItemGroup, index) => {
        const groupKey = `lineItemGroup_${index}`;
        let groupSum = 0;
        return (
          <div key={groupKey} className={styles.line_item_group_container}>
            {lineItemGroup.group && <div className={styles.group_title}>{lineItemGroup.group}</div>}
            {lineItemGroup.lineItems &&
              lineItemGroup.lineItems.map((lineItem, itemIndex) => {
                const itemKey = `${groupKey}_lineItem_${itemIndex}`;
                if (lineItem.amount) {
                  let amountNum =
                    typeof lineItem.amount === 'string' ? Number(lineItem.amount.replaceAll(',', '')) : lineItem.amount;
                  if (!Number.isNaN(amountNum)) groupSum += amountNum;
                }
                return (
                  <div key={itemKey} className={styles.line_item}>
                    <DataItem
                      label={lineItem.label}
                      value={{
                        value: lineItem.amount,
                        format: formatAmount,
                      }}
                      valueAlignRight
                      noColPadding
                      horizontalSplit={[70, 30]}
                    />
                  </div>
                );
              })}
            {lineItemGroup.subtotal || calculateSubtotal
              ? (() => {
                  console.log('calculating subtotal');
                  const calcSum = groupSum ? groupSum : 'none';
                  return (
                    <div className={styles.subtotal}>
                      <DataItem
                        label={t.SUBTOTAL}
                        value={{
                          value: lineItemGroup?.subtotal || calcSum,
                          format: formatAmount,
                          color: subtotalsColor,
                          bold: true,
                        }}
                        valueAlignRight
                        noColPadding
                        horizontalSplit={[70, 30]}
                      />
                    </div>
                  );
                })()
              : null}
          </div>
        );
      });
    }
  }, [lineItemGroups, hideZeroValueItems, calculateSubtotal, formatAmount, t.SUBTOTAL, subtotalsColor]);

  return (
    <div className={`${styles.LineItemsDisplay} ${className ? className : ''}`} style={style}>
      <div className={styles.title_and_edit_button}>
        <h3 className={styles.title}>{title}</h3>
        {editable && <EditButton onClick={onEdit} />}
      </div>
      <div className={styles.line_item_groups_container}>{isLoading ? <Loading noText /> : renderLineItemGroups()}</div>
    </div>
  );
};

export default LineItemsDisplay;
