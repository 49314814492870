import { useEffect, useState } from 'react';
import { Divider, DatePicker, Form, Input, Select, Row, Col, Checkbox } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
import { ClientService } from '../../../../shared/api/ClientService';
import API from '../../../../utils/api';

import SearchLocationInput from '../../../../components/SearchLocationInput/SearchLocationInput';
import { LocationDto } from '../../../../modals/GoogleMaps';
import SelectSuffixIcon from '../../../../components/SelectSuffixIcon/SelectSuffixIcon';
import ChangeTrackerFormItem from '../../../../components/ChangeTrackerFormItem';

import './ApplicationFormTab.scss';
import { DATE_FORMAT2, DATE_FORMAT_PLACEHOLDER } from '../../../../constants/common';
import { antdUtil } from '../../../../utils/antdUtil';
import useLocale from '../../../../hooks/useLocale';
import SelectLookupDto from '../../../../components/SelectLookupDto/SelectLookupDto';

interface IProps {
  name: string;
  onAddressAutocomplete: (rowName: number) => (address: LocationDto) => void;
  isLocked?: boolean;
}

const PersonalInformationEmploymentDetail = ({
  name,
  onAddressAutocomplete,
  isLocked = false,
}: IProps): JSX.Element => {
  const { Option } = Select;
  const { t, getLocalizedDtoName } = useLocale();

  const [employmentStatuses, setEmploymentStatuses] = useState<ClientService.LookupDto[]>();
  const [employmentStatusesLoading, setEmploymentStatusesLoading] = useState<boolean>(false);
  const [industries, setIndustries] = useState<ClientService.LookupDto[]>();
  const [industriesLoading, setIndustriesLoading] = useState<boolean>(false);

  useEffect(() => {
    setEmploymentStatusesLoading(true);
    API.listEmploymentStatuses().then((response) => {
      setEmploymentStatuses(response);
      setEmploymentStatusesLoading(false);
    });
  }, []);

  useEffect(() => {
    setIndustriesLoading(true);
    API.listIndustries(undefined).then((response) => {
      setIndustries(response);
      setIndustriesLoading(false);
    });
  }, []);

  return (
    <Form.List name={name}>
      {(employmentDetails, { add, remove }) => (
        <>
          {employmentDetails?.map((row, index) => {
            const isLast = employmentDetails?.length - 1 === index;
            return (
              <div key={`employment-details-${index}`}>
                <Form.Item name={[row.name, 'id']} hidden>
                  <Input disabled={isLocked} />
                </Form.Item>
                <Row gutter={20}>
                  <Col span={8}>
                    <ChangeTrackerFormItem
                      name={[row.name, 'employmentStatusId']}
                      className="ApplicationFormTab__input-label-warning"
                      label={t.EMPLOYMENT_STATUS}
                      rules={[{ required: true }]}
                      listName={name}
                    >
                      <Select
                        loading={employmentStatusesLoading}
                        suffixIcon={<SelectSuffixIcon />}
                        size="large"
                        disabled={isLocked}
                      >
                        {employmentStatuses?.map((option) => (
                          <Option key={option.id} value={option?.id}>
                            {getLocalizedDtoName(option)}
                          </Option>
                        ))}
                      </Select>
                    </ChangeTrackerFormItem>
                  </Col>
                  <Col span={8}>
                    <ChangeTrackerFormItem
                      name={[row.name, 'employerName']}
                      label={t.EMPLOYER}
                      rules={[{ required: true }]}
                      listName={name}
                    >
                      <Input size="large" disabled={isLocked} />
                    </ChangeTrackerFormItem>
                  </Col>
                  <Col span={8}>
                    <ChangeTrackerFormItem
                      name={[row.name, 'industryId']}
                      label={`${t.WHICH_INDUSTRY_DO_YOU_WORK_IN}?`}
                      rules={[{ required: true, message: t.WHICH_INDUSTRY_DO_YOU_WORK_IN_REQUIRED }]}
                      listName={name}
                    >
                      <SelectLookupDto disabled={isLocked} data={industries} showSearch loading={industriesLoading} />
                    </ChangeTrackerFormItem>
                  </Col>
                </Row>
                <Row gutter={20}>
                  <Col span={16}>
                    <ChangeTrackerFormItem
                      name={[row.name, 'occupationName']}
                      label={t.OCCUPATION}
                      rules={[{ required: true }]}
                      listName={name}
                    >
                      <Input size="large" disabled={isLocked} />
                    </ChangeTrackerFormItem>
                  </Col>
                </Row>
                <Row gutter={20}>
                  <Col span={16}>
                    <Form.Item name={[row.name, 'employerStreetNumber']} hidden>
                      <Input disabled={isLocked} />
                    </Form.Item>
                    <Form.Item name={[row.name, 'employerStreetName']} hidden>
                      <Input disabled={isLocked} />
                    </Form.Item>
                    <Form.Item name={[row.name, 'employerCity']} hidden>
                      <Input disabled={isLocked} />
                    </Form.Item>
                    <Form.Item name={[row.name, 'employerPostalCode']} hidden>
                      <Input disabled={isLocked} />
                    </Form.Item>
                    <Form.Item name={[row.name, 'employerProvinceId']} hidden>
                      <Input disabled={isLocked} />
                    </Form.Item>
                    <Form.Item name={[row.name, 'employerCountryId']} hidden>
                      <Input disabled={isLocked} />
                    </Form.Item>
                    <Form.Item
                      noStyle
                      shouldUpdate={(prevValues, currentValues) =>
                        prevValues[name][row.name] !== currentValues[name][row.name]
                      }
                    >
                      {({ getFieldValue }) => (
                        <ChangeTrackerFormItem
                          name={[row.name, 'employerAddress']}
                          label={t.EMPLOYER_ADDRESS}
                          listName={name}
                        >
                          <SearchLocationInput
                            address={{ address: getFieldValue([row.name, 'employerAddress']) }}
                            onSelect={onAddressAutocomplete(row.name)}
                            size="large"
                            disabled={isLocked}
                          />
                        </ChangeTrackerFormItem>
                      )}
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <ChangeTrackerFormItem
                      name={[row.name, 'employerUnit']}
                      label={t.EMPLOYER_ADDRESS_UNIT}
                      listName={name}
                    >
                      <Input size="large" disabled={isLocked} />
                    </ChangeTrackerFormItem>
                  </Col>
                </Row>

                <Row gutter={20} align="middle">
                  <Col span={8}>
                    <ChangeTrackerFormItem name={[row.name, 'startDate']} label={t.START_DATE} listName={name}>
                      <DatePicker
                        size="large"
                        disabledDate={antdUtil.futureDates}
                        format={DATE_FORMAT2}
                        placeholder={DATE_FORMAT_PLACEHOLDER}
                        disabled={isLocked}
                      />
                    </ChangeTrackerFormItem>
                  </Col>
                  <Col span={8}>
                    <Form.Item
                      noStyle
                      shouldUpdate={(prevValues, currentValues) =>
                        prevValues[name][row.name] !== currentValues[name][row.name]
                      }
                    >
                      {({ getFieldsValue }) => (
                        <ChangeTrackerFormItem
                          name={[row.name, 'endDate']}
                          listName={name}
                          label={
                            <Row justify="space-between" className="ApplicationFormTab__label-extended">
                              <Col flex={1}>{t.END_DATE}</Col>
                              <Col flex={1}>
                                <ChangeTrackerFormItem
                                  name={[row.name, 'isCurrentEmployer']}
                                  listName={name}
                                  valuePropName="checked"
                                  className="ApplicationFormTab__form-item-no-margin"
                                >
                                  <Checkbox className="ApplicationFormTab__checkbox" disabled={isLocked}>
                                    {t.CURRENT_EMPLOYER}
                                  </Checkbox>
                                </ChangeTrackerFormItem>
                              </Col>
                            </Row>
                          }
                          className="ApplicationFormTab__input-label-extended"
                        >
                          <DatePicker
                            size="large"
                            disabledDate={antdUtil.futureDates}
                            format={DATE_FORMAT2}
                            disabled={getFieldsValue()?.[name]?.[row.name]?.isCurrentEmployer || isLocked}
                            placeholder={DATE_FORMAT_PLACEHOLDER}
                          />
                        </ChangeTrackerFormItem>
                      )}
                    </Form.Item>
                  </Col>
                  {!isLocked && (
                    <Col span={8} style={{ textAlign: 'right' }}>
                      <DeleteOutlined
                        className="ApplicationFormTab__input-action-icon"
                        onClick={() => remove(row.name)}
                      />
                    </Col>
                  )}
                </Row>
                {!isLast && <Divider />}
              </div>
            );
          })}
          {!isLocked && (
            <Row gutter={20}>
              <Col span={8}>
                <Form.Item>
                  <div onClick={() => add()} className="ApplicationFormTab__link-left">
                    {t.ADD_EMPLOYMENT}
                  </div>
                </Form.Item>
              </Col>
            </Row>
          )}
        </>
      )}
    </Form.List>
  );
};

export default PersonalInformationEmploymentDetail;
