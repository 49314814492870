import useLocale from '../../../hooks/useLocale';
import styles from '../../../styles/style.module.scss';

const StatusValue = ({ isActive }: { isActive?: boolean }) => {
  const { t } = useLocale();
  return (
    <span style={{ color: isActive ? styles?.colorGreen : styles.colorDanger, fontWeight: 'bold' }}>
      {isActive ? t.ACTIVE : t.INACTIVE}
    </span>
  );
};

export default StatusValue;
