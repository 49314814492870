import { useCallback, useEffect, useState } from 'react';
import { Col, Row, Divider, Spin } from 'antd';
import moment from 'moment';

import AddIcon from '../../AddIcon';
import OverrideModal from '../OverrideModal/OverrideModal';
import OverrideRow from './OverrideRow';

import useModal from '../../../../../hooks/useModal';
import { ClientService } from '../../../../../shared/api/ClientService';
import API from '../../../../../utils/api';
import { convertServerDateOnlyToInputDate } from '../../../../../utils/helpers';

import './Overrides.scss';
import styles from '../../../../../styles/style.module.scss';
import useLocale from '../../../../../hooks/useLocale';

const Overrides = () => {
  const { showModal, closeModal } = useModal();

  const [overrides, setOverrides] = useState<ClientService.AvailabilityOverrideDto[]>();
  const [overridesLoading, setOverridesLoading] = useState<boolean>(false);
  const { t } = useLocale();

  const requestOverrides = useCallback(async () => {
    setOverridesLoading(true);
    const response = await API.getAvailabilityOverrides().catch(() => setOverridesLoading(false));
    setOverridesLoading(false);

    if (response) {
      setOverrides(
        response
          ?.map(
            (item: ClientService.AvailabilityOverrideDto) =>
              ({
                ...item,
                startDate: convertServerDateOnlyToInputDate(item?.startDate),
                endDate: convertServerDateOnlyToInputDate(item?.endDate),
              } as ClientService.AvailabilityOverrideDto)
          )
          ?.sort((a, b) => moment(a.startDate).diff(moment(b.startDate), 'd'))
      );
    }
  }, []);

  const handleAdd = useCallback(() => {
    showModal(
      <OverrideModal
        onOk={() => {
          closeModal();
          requestOverrides();
        }}
        onCancel={closeModal}
      />
    );
  }, [closeModal, requestOverrides, showModal]);

  useEffect(() => {
    if (!overrides) requestOverrides();
  }, [overrides, requestOverrides]);

  return (
    <Spin className="Overrides" spinning={overridesLoading}>
      <Row justify="space-between">
        <Col>{t.ADD_OVERRIDE_DATES}</Col>
        <Col>
          <AddIcon fill={styles.colorDanger} onClick={handleAdd} />
        </Col>
      </Row>
      <Divider className="Overrides__divider" />

      {overrides?.map((overrideItem, index) => (
        <OverrideRow key={`override-${String(index)}`} overrideItem={overrideItem} onDeleteSuccess={requestOverrides} />
      ))}
    </Spin>
  );
};

export default Overrides;
