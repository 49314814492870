import moment, { isMoment } from 'moment';

import { ICustomColumnType } from './types';

export const getSortableFilterableColumns = (columns: ICustomColumnType[], dataSource?: any[]) =>
  columns.map((column) => {
    const { sorter, dataIndex, filterByName, filters, onFilter, ...otherColumnProps } = column;

    return {
      ...otherColumnProps,
      dataIndex,
      sorter: !sorter
        ? false
        : typeof sorter === 'boolean'
        ? (rowA: any, rowB: any) => {
            if (isMoment(rowA[dataIndex as string]))
              return moment(rowA[dataIndex as string]).isBefore(moment(rowB[dataIndex as string]));
            if (typeof rowA[dataIndex as string] === 'string')
              return rowA[dataIndex as string].localeCompare(rowB[dataIndex as string]);
            if (typeof rowA[dataIndex as string] === 'number')
              return rowA[dataIndex as string] - rowB[dataIndex as string];
            if (typeof rowA[dataIndex as string] === 'boolean')
              return +rowA[dataIndex as string] - +rowB[dataIndex as string];

            return 0;
          }
        : sorter,
      filters: filterByName
        ? [...new Set(dataSource?.map((item) => item[dataIndex as string] as string))]
            ?.filter((i) => i && Boolean(i?.trim()))
            ?.map((uniqueItem) => ({
              text: uniqueItem,
              value: uniqueItem,
            }))
            ?.sort((a, b) => a?.value?.localeCompare(b?.value))
        : filters,
      onFilter: filterByName ? (value: any, record: any) => value === record[dataIndex as string] : onFilter,
    };
  });

export const convertArrayToReadable = (array?: (string | undefined)[]) => {
  if (!array) return '';
  return array?.join('; ');
};
