import { ClientService } from '../../shared/api/ClientService';

export const arraysHaveIntersections = (array1: string[] = [], array2: string[] = [], array3: string[] = []): boolean =>
  Boolean(array1?.filter((element) => array2?.includes(element))?.length) ||
  Boolean(array2?.filter((element) => array3?.includes(element))?.length) ||
  Boolean(array3?.filter((element) => array1?.includes(element))?.length);

export const arraysAreEmpty = (array1: string[] = [], array2: string[] = [], array3: string[] = []): boolean =>
  !array1?.length && !array2?.length && !array3?.length;

export const getLookupIdsByNames = (names?: string[], data?: ClientService.LookupDto[]) =>
  names?.map((name) => data?.find((item) => item?.name === name)?.id as string);
