import { CSSProperties, useCallback, useState, useEffect } from 'react';
import { Calendar as AntdCalendar, CalendarProps, Row, Col } from 'antd';
import moment, { Moment } from 'moment';

import CalendarHeader from '../CalendarHeader/CalendarHeader';

import './Calendar.scss';
import styles from '../../styles/style.module.scss';

export interface ICellProp {
  date: Moment;
  content?: JSX.Element;
}

interface IProps extends CalendarProps<Moment> {
  cellStyle?: CSSProperties;
  dateStyle?: CSSProperties;
  contentStyle?: CSSProperties;
  cells?: ICellProp[];
  multiple?: boolean;
  onMultiSelect?: (date?: Moment[]) => void;
  defaultCellContent?: string | JSX.Element;
  defaultCellStyle?: CSSProperties;
  disabled?: boolean;
  rulerSize?: 'normal' | 'large';
}

const Calendar = ({
  cellStyle,
  dateStyle,
  contentStyle,
  cells,
  multiple = false,
  onMultiSelect,
  defaultCellContent,
  defaultCellStyle,
  disabled = false,
  rulerSize,
  ...props
}: IProps) => {
  const [value, setValue] = useState<Moment | undefined>(props.defaultValue);
  const [values, setValues] = useState<Moment[]>([]);

  const checkIsDateSelected = useCallback(
    (date: Moment) => (multiple ? values?.find((item) => item.isSame(date, 'd')) : value?.isSame(date, 'd')),
    [values, value, multiple]
  );

  const handleOnChange = useCallback(
    (date: Moment) => {
      if (!multiple) {
        setValue(date);
      }

      if (props?.onChange) props?.onChange(date);
    },
    [multiple, props]
  );

  const handleOnSelect = useCallback(
    (date: Moment) => {
      if (multiple) {
        const newValues = checkIsDateSelected(date)
          ? values?.filter((item) => !item.isSame(date, 'd'))
          : [...(values || []), date];

        setValues(newValues);
        if (onMultiSelect) onMultiSelect(newValues);
      }

      if (props?.onSelect) props?.onSelect(date);
    },
    [checkIsDateSelected, multiple, onMultiSelect, props, values]
  );

  const handleMonthChange = useCallback(
    (date: Moment) => {
      setValue(date);
      if (props?.onChange) props?.onChange(date);
    },
    [props]
  );

  useEffect(() => {
    if (props.value) {
      return Array.isArray(props.value) ? setValues(props.value) : setValue(props.value);
    }
  }, [props.value]);

  return (
    <Row className="Calendar">
      <Col span={24}>
        <AntdCalendar
          {...props}
          value={value}
          onSelect={handleOnSelect}
          onChange={handleOnChange}
          headerRender={({ value }) => (
            <CalendarHeader
              value={value}
              onChange={handleMonthChange}
              style={{ display: 'flex', justifyContent: 'space-between' }}
              disabled={disabled}
              rulerSize={rulerSize}
            />
          )}
          dateFullCellRender={(date: Moment) => {
            const cellData = cells?.find((item) => item.date.isSame(date, 'D'));
            const isDisabled = !(value || moment())?.isSame(date, 'M') || date?.isBefore(moment(), 'd');

            let customCellStyle = { ...cellStyle };
            let customDateStyle = { ...dateStyle, color: styles.colorPrimary };
            let customContentStyle = { ...contentStyle, color: styles.colorPrimary };

            if (cellData) {
              customCellStyle.borderTopWidth = 2.5;
              customCellStyle.borderTopStyle = 'solid';
              customCellStyle.borderTopColor = isDisabled ? styles.colorDisabledText : styles.colorDanger;
            } else {
              customCellStyle = { ...customCellStyle, ...defaultCellStyle };
            }

            if (isDisabled) {
              customContentStyle.color = styles.colorDisabledText;
              customDateStyle.color = styles.colorDisabledText;
            }

            if (checkIsDateSelected(date)) {
              customCellStyle.backgroundColor = styles.colorCalendarSelectedCellBckg;
              customCellStyle.color = styles.colorCalendarSelectedCellColor;
              customDateStyle.color = styles.colorCalendarSelectedCellColor;
              customContentStyle.color = styles.colorCalendarSelectedCellColor;
            }

            return (
              <div className="ant-picker-cell-inner ant-picker-calendar-date" style={customCellStyle}>
                <div className="ant-picker-calendar-date-value" style={customDateStyle}>
                  {date.format('DD')}
                </div>
                {cells && (
                  <div className="ant-picker-calendar-date-content" style={customContentStyle}>
                    {cellData?.content || defaultCellContent}
                  </div>
                )}
              </div>
            );
          }}
        />
      </Col>
    </Row>
  );
};

export default Calendar;
