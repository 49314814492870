import { useState, useCallback, useEffect, useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Table, Spin } from 'antd';
import moment from 'moment';

import Breadcrumb from '../../../components/Breadcrumb/Breadcrumb';
import PageSubtitle from '../../../components/PageSubtitle/PageSubtitle';
import Button from '../../../components/Button/Button';

import useLocale from '../../../hooks/useLocale';
import { useApplicationFile } from '../../ApplicationOverviewPage';

import { ROUTES } from '../../../constants/routes';
import { DATE_FORMAT2 } from '../../../constants/common';
import { ClientService } from '../../../shared/api/ClientService';
import API from '../../../utils/api';

import './CreditReportContent.scss';

const CreditReportHistory = () => {
  const { t } = useLocale();
  const { applicationFileId } = useParams<{ applicationFileId: string }>();
  const navigate = useNavigate();

  const [loading, setLoading] = useState<boolean>(false);
  const [dataSource, setDataSource] = useState<ClientService.CreditReportHistoryDto[]>();

  const { data: appFileData } = useApplicationFile();
  const isDuplicate = useMemo(() => appFileData?.isDuplicate, [appFileData]);

  const requestCreditReportHistory = useCallback(async (applicationFileId: string) => {
    if (applicationFileId) {
      setLoading(true);
      const response = await API.getCreditReportHistoryListByFileId(applicationFileId).catch(() => setLoading(false));
      setLoading(false);

      if (response) setDataSource(response);
    }
  }, []);

  const columns = [
    {
      title: t.REQUEST_ON,
      dataIndex: 'onFileDate',
      key: 'onFileDate',
      render: (item: moment.Moment) => <>{moment(item).format(DATE_FORMAT2)}</>,
      sorter: true,
    },
    {
      title: t.REQUEST_BY,
      dataIndex: 'creatorName',
      key: 'creatorName',
      sorter: true,
    },
    {
      dataIndex: 'action',
      key: 'action',
      render: (_: any, item: ClientService.CreditReportHistoryDto) => (
        <Button
          kind="link"
          onClick={() =>
            navigate(`${ROUTES.APPLICATION_OVERVIEW}/${applicationFileId}/${ROUTES.CREDIT_REPORT}/${item.id}`)
          }
        >
          View
        </Button>
      ),
      width: 100,
    },
  ];

  useEffect(() => {
    if (applicationFileId) requestCreditReportHistory(applicationFileId);
  }, [requestCreditReportHistory, applicationFileId]);

  return (
    <div className="CreditReportContent">
      <Breadcrumb
        data={[
          { link: ROUTES.DASHBOARD, title: t.DASHBOARD },
          { link: `${ROUTES.DEBTOR_SEARCH}`, title: t.DEBTOR_SEARCH },
          { link: `${ROUTES.APPLICATION_OVERVIEW}/${applicationFileId}/`, title: t.APPLICATION_OVERVIEW },
          {
            title: t.CREDIT_REPORT_HISTORY,
          },
        ]}
      />
      <PageSubtitle
        title={t.CREDIT_REPORT_HISTORY}
        buttonTitle={!isDuplicate && t.SEND_REQUEST}
        onButtonClick={() =>
          navigate(`${ROUTES.APPLICATION_OVERVIEW}/${applicationFileId}/${ROUTES.REQUEST_CREDIT_REPORT}`)
        }
      />
      <Spin spinning={loading}>
        <Table columns={columns} dataSource={dataSource} rowKey="id" />
      </Spin>
    </div>
  );
};

export default CreditReportHistory;
