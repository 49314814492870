import { useState, useCallback, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Form, Col, Row, Divider } from 'antd';
import { useQuery } from 'react-query';

import Button from '../../../../../../components/Button/Button';
import DataItem from '../../../../../../components/DataItem/DataItem';

import {
  APPLICATION_FILE_QUERY,
  OFFICE_LOCATIONS_QUERY,
  USE_QUERY_OPTIONS,
  DELIVERY_METHODS_QUERY,
  COUNSELLING_TYPES_QUERY,
  INCLUDE_INACTIVE,
} from '../../../../../../constants/reactQuery';
import { argumentifyOfficeLocationsSearchCriteria } from '../../../../../../components/SchedulingComponent/utils';
import { argumentifyDebtorSearchCriteria } from '../../../../../DebtorSearchPage/utils';
import useLocale from '../../../../../../hooks/useLocale';
import { MAX_PAGE_SIZE } from '../../../../../../constants/common';

import { ClientService } from '../../../../../../shared/api/ClientService';
import API from '../../../../../../utils/api';

import './CounsellingViews.scss';

interface IProps
  extends Pick<
    ClientService.AdminInfoCounsellingSessionCreateDto,
    | 'relatedDebtorId'
    | 'includeRelatedDebtor'
    | 'sessionNumber'
    | 'ascendMethodOfDeliveryId'
    | 'ascendCounsellingTypeId'
  > {
  isEditable?: boolean;
  onEdit?: () => void;
}

const FirstStepSummary = ({
  isEditable,
  onEdit,
  relatedDebtorId,
  sessionNumber,
  ascendMethodOfDeliveryId,
  ascendCounsellingTypeId,
  includeRelatedDebtor,
}: IProps) => {
  const { applicationFileId } = useParams<{ applicationFileId: string }>();
  const { t } = useLocale();

  const [debtor, setDebtor] = useState<ClientService.ClientResultsDto>();

  const { data: deliveryMethods } = useQuery(
    [DELIVERY_METHODS_QUERY],
    () => API.listMethodOfDeliveries(),
    USE_QUERY_OPTIONS
  );

  const requestDebtors = useCallback(async (relatedDebtorId: string) => {
    const response = await API.getDebtorSearchResults(
      ...argumentifyDebtorSearchCriteria({ debtorId: relatedDebtorId, pageSize: MAX_PAGE_SIZE })
    );

    if (response) {
      setDebtor(response?.clients?.[0]);
    }
  }, []);

  const { data: officeLocations } = useQuery(
    [OFFICE_LOCATIONS_QUERY, INCLUDE_INACTIVE, MAX_PAGE_SIZE],
    () =>
      API.officeLocationsGET(
        ...argumentifyOfficeLocationsSearchCriteria({ maxResultCount: MAX_PAGE_SIZE, includeInactive: true })
      ),
    USE_QUERY_OPTIONS
  );

  const { data: applicationFile } = useQuery(
    [APPLICATION_FILE_QUERY],
    () => API.applicationFile2(applicationFileId as string),
    USE_QUERY_OPTIONS
  );

  const { data: counsellingTypes } = useQuery(
    [COUNSELLING_TYPES_QUERY],
    () => API.listCounsellingSessionTypes(),
    USE_QUERY_OPTIONS
  );

  useEffect(() => {
    if (relatedDebtorId) requestDebtors(relatedDebtorId);
  }, [relatedDebtorId, requestDebtors]);

  return (
    <>
      <Row gutter={40} className="CounsellingViews__data-row">
        <DataItem colSpan={12} horizontalSplit={[35, 65]} label={t.SESSION} value={sessionNumber} />
        <DataItem
          colSpan={12}
          horizontalSplit={[35, 65]}
          label={t.METHOD}
          value={deliveryMethods?.find((item) => item.enumValue === ascendMethodOfDeliveryId)?.name || ''}
        />
      </Row>

      <Row gutter={40} className="CounsellingViews__data-row">
        <DataItem
          colSpan={12}
          horizontalSplit={[35, 65]}
          label={t.TYPE}
          value={counsellingTypes?.find((item) => item.enumValue === ascendCounsellingTypeId)?.name || ''}
        />
        <DataItem
          colSpan={12}
          horizontalSplit={[35, 65]}
          label={t.LOCATION}
          value={officeLocations?.items?.find((item) => item.id === applicationFile?.serviceLocationId)?.name}
        />
      </Row>

      {relatedDebtorId && (
        <>
          <Divider dashed />
          <Row gutter={40} className="CounsellingViews__data-row">
            <DataItem
              colSpan={12}
              horizontalSplit={[35, 65]}
              label={t.RELATED_DEBTORS}
              value={`${debtor?.firstName || ''} ${debtor?.lastName || ''}`}
            />
            <Col span={12}>
              {includeRelatedDebtor && (
                <div>
                  <b>{t.INCLUDE_RELATED_DEBTOR_IN_COUNSELLING_SESSION}</b>
                </div>
              )}
            </Col>
          </Row>
        </>
      )}

      {isEditable && (
        <Row justify="end" style={{ paddingTop: 24 }}>
          <Col>
            <Form.Item>
              <Button kind="primary" onClick={onEdit}>
                {t.EDIT}
              </Button>
            </Form.Item>
          </Col>
        </Row>
      )}

      <Divider dashed />
    </>
  );
};

export default FirstStepSummary;
