import { useEffect, useCallback } from 'react';
import { Button, Skeleton, Typography, ConfigProvider } from 'antd';
import { useNavigate, useLocation } from 'react-router-dom';

import 'moment/locale/fr-ca';
import 'moment/locale/en-ca';
import frLocale from 'antd/es/locale/fr_CA';
import enLocale from 'antd/es/locale/en_US';

import LeftMenu from '../LeftMenu/LeftMenu';
import Header from '../Header/Header';

import useLocale from '../../hooks/useLocale';
import useMenu from '../../hooks/useMenu';

import { ROUTES } from '../../constants/routes';
import networkError from '../../images/network-error.png';
import { AuthorizeService } from '../../components/Auth/AuthorizeService';
import { PdsUserClientAPI } from '../../utils/identityServiceApi';
import { ILocale } from '../../types/ILocale';

import './Layout.scss';

interface IProps {
  children: React.ReactNode;
  className?: string;
  page?: ROUTES;
  loading?: boolean;
  error?: boolean;
  retry?: () => void;
}

const { Title, Text } = Typography;

const Layout = ({ children, className, page, loading, error, retry }: IProps): JSX.Element => {
  const { t, locale } = useLocale();
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const { isSuperAdmin, setIsSuperAdmin, isSupervisorAllocation, setIsSupervisorAllocation } = useMenu();

  const user = AuthorizeService.getCurrentUserInfo();

  const requestRoles = useCallback(
    async (email?: string) => {
      const isSuperAdmin = await PdsUserClientAPI.isSuperAdmin(email);
      setIsSuperAdmin(isSuperAdmin || false);

      const isSupervisorAllocation = await PdsUserClientAPI.isSupervisorAllocation(email);
      setIsSupervisorAllocation(isSupervisorAllocation || false);
    },
    [setIsSuperAdmin, setIsSupervisorAllocation]
  );

  useEffect(() => {
    if (user?.profile?.email && isSuperAdmin === undefined && isSupervisorAllocation === undefined) {
      requestRoles(user?.profile?.email);
    }
  }, [requestRoles, user?.profile?.email, isSuperAdmin, isSupervisorAllocation]);

  useEffect(() => {
    if (isSuperAdmin === false && pathname?.includes(ROUTES.ADMINISTRATION)) {
      if (!isSupervisorAllocation || (isSupervisorAllocation && !pathname?.includes(ROUTES.ALLOCATIONS))) {
        navigate(ROUTES.DASHBOARD);
      }
    }
  }, [isSuperAdmin, isSupervisorAllocation, navigate, pathname]);

  return (
    <ConfigProvider locale={locale === ILocale.fr ? frLocale : enLocale}>
      <div className="Layout" key={String(locale)}>
        <LeftMenu />
        <div className="Layout__main-content">
          <Header />
          <div className={`Layout__page-contents${className ? ` ${className}` : ''}${error ? ' Layout__error' : ''} `}>
            {loading && !error && <Skeleton active paragraph={{ rows: 6 }} />}
            {error && (
              <div className="Layout__error-message">
                <img alt="network error" src={networkError} />
                <Title style={{ marginBottom: 0, fontWeight: 'bold' }} level={5}>
                  {t.NETWORK_ERROR}
                </Title>
                <Text style={{ fontSize: 16 }} type="secondary">
                  {t.NO_DATA_FOUND}
                </Text>
                <Button style={{ marginTop: 5 }} onClick={retry} type="link">
                  {t.RETRY}
                </Button>
              </div>
            )}
            {!loading && !error && children}
          </div>
        </div>
      </div>
    </ConfigProvider>
  );
};

export default Layout;
