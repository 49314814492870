import { ReactNode } from 'react';
import FormInput from '../../../../../../components/Forms/FormInput/FormInput';
import Radio from '../../../../../../components/Forms/Radio/Radio';
import useLocale from '../../../../../../hooks/useLocale';

interface MarkAsActiveRadioSelectionProps {
  name?: string;
  label?: ReactNode;
  trueOption?: string;
  falseOption?: string;
}

const ACTIVE = 'Active';
const DUPLICATE = 'Duplicate';

// const convertOptionNameToValue = (optionName: string) => {
//     return optionName.toLowerCase().split(' ').join('-');
// }

const MarkAsActiveRadioSelection = ({
  name,
  label,
  trueOption = ACTIVE,
  falseOption = DUPLICATE,
}: MarkAsActiveRadioSelectionProps) => {
  const { t } = useLocale();

  const translatedTrueOption = trueOption === ACTIVE ? t.ASCEND_OPTION_ACTIVE : trueOption;
  const translatedFalseOption = falseOption === DUPLICATE ? t.ASCEND_OPTION_DUPLICATE : falseOption;

  return (
    <FormInput type="radio" name={name} label={label}>
      <Radio.Group buttonStyle="solid" optionType="button">
        <Radio.Button value={false}>{translatedFalseOption}</Radio.Button>
        <Radio.Button value={true}>{translatedTrueOption}</Radio.Button>
      </Radio.Group>
    </FormInput>
  );
};

export default MarkAsActiveRadioSelection;
