import { useMemo } from 'react';
import { Tabs } from 'antd';
import { useQuery } from 'react-query';

import Layout from '../../components/Layout/Layout';
import PageTitleContainer from '../../components/PageTitleContainer/PageTitleContainer';
import Breadcrumb from '../../components/Breadcrumb/Breadcrumb';
import MyProfile from './MyProfile/MyProfile';
import Availability from './Availability/Availability';

import { AGENTS_QUERY } from '../../constants/reactQuery';
import { AuthorizeService } from '../../components/Auth/AuthorizeService';
import API from '../../utils/api';

import useLocale from '../../hooks/useLocale';
import { ROUTES } from '../../constants/routes';

import './UserProfile.scss';

const PROFILE_TAB = 'profile';
const AVAILABILITY_TAB = 'availability';

function UserProfile() {
  const { t } = useLocale();
  const { TabPane } = Tabs;

  const user = AuthorizeService.getCurrentUserInfo();
  const { data } = useQuery([AGENTS_QUERY], () => API.listAgents());
  const userName = useMemo(
    () => data?.find((item) => item.id === user?.profile?.sub)?.name,
    [data, user?.profile?.sub]
  );

  return (
    <Layout page={ROUTES.USER_PROFILE} className="UserProfile">
      <PageTitleContainer title={userName || ''} hasToolbar={false} />
      <Breadcrumb data={[{ link: ROUTES.DASHBOARD, title: t.DASHBOARD }, { title: t.PROFILE }]} />

      <div>
        <Tabs className="UserProfile__tabs" defaultActiveKey={PROFILE_TAB}>
          <TabPane tab={t.MY_PROFILE} key={PROFILE_TAB}>
            <MyProfile />
          </TabPane>
          <TabPane tab={t.AVAILABILITY} key={AVAILABILITY_TAB}>
            <Availability />
          </TabPane>
        </Tabs>
      </div>
    </Layout>
  );
}

export default UserProfile;
