import { Form, Select, Popover, SelectProps, Row, Col } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';

import SelectSuffixIcon from '../../../components/SelectSuffixIcon/SelectSuffixIcon';

import { ClientService } from '../../../shared/api/ClientService';
import useLocale from '../../../hooks/useLocale';

/**
 * Agents drop down works for Appointment schedule page.
 */
const AgentSelect = ({ ...props }: SelectProps) => {
  const { t } = useLocale();
  const { Option } = Select;
  return (
    <Form.Item noStyle shouldUpdate>
      {({ getFieldValue }) => {
        const selectedTimeslot = getFieldValue('selectedTimeslot');
        const agentId = getFieldValue('agentId');
        const timeslotMeetingTypeId = selectedTimeslot?.timeslotMeetingTypeId;
        const agents = selectedTimeslot?.availableAgents?.filter((agent: ClientService.AvailableAgentDto) =>
          agent?.meetingTypes?.find((type) => type.id === timeslotMeetingTypeId)
        );

        const meetingNote = agents?.find(
          (agent: ClientService.AvailableAgentDto) => agent?.agentId === agentId
        )?.meetingNote;

        return (
          <Form.Item
            name="agentId"
            label={
              <Row align="bottom" justify="space-between" className="SchedulingComponent__agents--label">
                <Col className="SchedulingComponent__label--required">{t.APPOINTMENT_SCHEDULING_AGENTS}</Col>
                <Col>
                  {meetingNote && (
                    <Popover
                      placement="bottomLeft"
                      content={meetingNote}
                      trigger="hover"
                      className="SchedulingComponent__agents--popover"
                    >
                      <Row align="bottom" gutter={6}>
                        <Col>{t.APPOINTMENT_SCHEDULING_SCHEDULING_NOTE}</Col>
                        <Col>
                          <InfoCircleOutlined className="SchedulingComponent__agents--popover-icon" />
                        </Col>
                      </Row>
                    </Popover>
                  )}
                </Col>
              </Row>
            }
            className="SchedulingComponent__agents"
            requiredMark="optional"
            rules={[{ required: true, message: t.AGENT_IS_REQUIRED }]}
          >
            <Select size="large" suffixIcon={<SelectSuffixIcon />} {...props}>
              {agents?.map((item: ClientService.AvailableAgentDto) => (
                <Option key={item.agentId} value={item.agentId}>
                  {`${item.firstName || ''} ${item.lastName || ''} ${item.noOfPDSAppointmentsForDay || 0} ${
                    t.APPOINTMENTS
                  } (${
                    item.preferredLanguage === 'bi'
                      ? t.BILINGUAL_SPEAKING
                      : item.preferredLanguage === 'fr'
                      ? t.FRENCH_SPEAKING
                      : t.ENGLISH_SPEAKING
                  }`}
                  )
                </Option>
              ))}
            </Select>
          </Form.Item>
        );
      }}
    </Form.Item>
  );
};

export default AgentSelect;
