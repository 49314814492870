import DataItem from '../../../../components/DataItem/DataItem';

import useLocale from '../../../../hooks/useLocale';

interface IProps {
  value?: string;
  hasErrors?: boolean;
}

const CashInputTotal = ({ value, hasErrors }: IProps) => {
  const { t } = useLocale();
  return (
    <DataItem
      layout="vertical"
      label={t.TOTAL_DOLLARS}
      value={{
        value,
        color: hasErrors ? 'red' : 'grey',
      }}
      valueIcon={
        hasErrors && {
          iconName: 'AlertSolid',
          fontSize: 16,
          color: 'red',
        }
      }
    />
  );
};

export default CashInputTotal;
