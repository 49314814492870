import { useCallback, useState, useEffect } from 'react';
import { Input, Col, Row, Form, FormInstance, Spin } from 'antd';
import { useQuery } from 'react-query';
import update from 'immutability-helper';
import { Icon } from '@fluentui/react';

import SearchLocationInput from '../SearchLocationInput/SearchLocationInput';
import SelectLookupDto from '../SelectLookupDto/SelectLookupDto';

import useLocale from '../../hooks/useLocale';
import { LocationDto } from '../../modals/GoogleMaps/types';
import { formatAddressLine } from './utils';
import { IAddressComponent } from './types';
import { USE_QUERY_OPTIONS, PROVINCES_QUERY, COUNTRIES_QUERY } from '../../constants/reactQuery';
import API from '../../utils/api';

import './AddressComponent.scss';

interface IProps {
  form: FormInstance;
  countryId?: string;
  customCountryValidator?: (id: string) => boolean;
  listProps?: {
    rowName: number;
    listName: string | number;
  };
  hasDelete?: boolean;
  onDelete?: () => void;
}

const AddressComponent = ({ form, countryId, listProps, hasDelete, onDelete, customCountryValidator }: IProps) => {
  const { t } = useLocale();

  const [selectedCountryId, setSelectedCountryId] = useState<string | undefined>();

  const [selectedProvinceId, setSelectedProvinceId] = useState<string | undefined>();
  const [selectedProvinceCode, setSelectedProvinceCode] = useState<string>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { data: countries } = useQuery(
    [COUNTRIES_QUERY],
    () => {
      setIsLoading(true);
      return API.listCountries();
    },
    USE_QUERY_OPTIONS
  );

  const { data: provinces } = useQuery(
    [PROVINCES_QUERY, selectedCountryId, selectedProvinceCode],
    () => {
      if (selectedCountryId) {
        setIsLoading(true);
        return API.listProvinces(selectedCountryId);
      }
    },
    {
      ...USE_QUERY_OPTIONS,
      onSuccess: (data) => {
        setIsLoading(false);
        const provinceId = getFormValue('provinceId');
        const countryId = getFormValue('countryId');
        const newProvinceId = data?.find((item) => item.code === selectedProvinceCode)?.id;

        if (newProvinceId !== provinceId || selectedCountryId !== countryId) {
          updateFormValues({
            provinceId: newProvinceId || provinceId,
            countryId: selectedCountryId || countryId,
          });
          setSelectedProvinceId(newProvinceId || provinceId);
        }
      },
      onError: (e) => {
        setIsLoading(false);
      },
    }
  );

  const setFieldName = useCallback(
    (fieldName: string) => (listProps ? [listProps?.rowName, fieldName] : fieldName),
    [listProps]
  );

  const getFieldName = useCallback(
    (fieldName: string) => (listProps ? [listProps?.listName, listProps?.rowName, fieldName] : fieldName),
    [listProps]
  );

  const getFormValue = useCallback(
    (fieldName: string) => form.getFieldValue(getFieldName(fieldName)),
    [form, getFieldName]
  );

  const updateFormValues = useCallback(
    (values: IAddressComponent) => {
      if (listProps) {
        const updatedValues = update(form.getFieldsValue(), {
          [listProps?.listName]: {
            [listProps?.rowName]: { $merge: { ...values } },
          },
        });
        form.setFieldsValue(updatedValues);
      } else {
        form.setFieldsValue(values);
      }
    },
    [form, listProps]
  );

  /**
   * Handle sceanrio that end use's input address is searchable in google api.
   * Called when end user select one address from input address drop down list.
   */
  const handleAddressAutocomplete = useCallback(
    async (autofilledAddress: LocationDto) => {
      setSelectedCountryId(autofilledAddress?.country?.id);
      setSelectedProvinceCode(autofilledAddress?.province?.code);

      updateFormValues({
        unit: autofilledAddress?.unit,
        city: autofilledAddress?.city,
        postalCode: autofilledAddress?.postalCode,
        streetName: autofilledAddress?.streetName,
        streetNumber: autofilledAddress?.streetNumber,
        address: autofilledAddress?.address,
        addressLine: formatAddressLine(autofilledAddress),
      });
    },
    [updateFormValues]
  );

  /**
   *  Handle scenario that end user's input address not searchable in google api.
   *  Accept end user's address by manually typing.
   */
  const handleAddressInputChanged = useCallback(
    async (inputAddress: string) => {
      updateFormValues({
        address: inputAddress,
        addressLine: inputAddress,
        // Extract street number from end user's input address.
        streetNumber: inputAddress.match(/\d/g)?.join(''),
        // Extract street name from end user's input address.
        streetName: inputAddress.replace(/\d/g, ''),
      });
    },
    [updateFormValues]
  );

  const handleResetValuesOnProvinceChange = useCallback(() => {
    updateFormValues({
      address: undefined,
      unit: undefined,
      city: undefined,
      postalCode: undefined,
      streetName: undefined,
      streetNumber: undefined,
      addressLine: '',
    });
  }, [updateFormValues]);

  const handleResetValuesOnCountryChange = useCallback(() => {
    updateFormValues({ provinceId: undefined });
    handleResetValuesOnProvinceChange();
  }, [handleResetValuesOnProvinceChange, updateFormValues]);

  useEffect(() => {
    if (countryId) setSelectedCountryId(countryId);
  }, [countryId]);

  return (
    <Spin spinning={isLoading}>
      <Form.Item noStyle shouldUpdate>
        {({ getFieldValue }) => (
          <>
            <Row gutter={20} align="bottom">
              <Col span={8}>
                <Form.Item
                  name={setFieldName('addressLine')}
                  label={t.DEBTOR_PROFILE_ADDRESS}
                  required
                  rules={[{ required: true, message: t.REQUIRED_FIELD }]}
                >
                  <SearchLocationInput
                    address={{
                      country: { id: getFieldValue(getFieldName('countryId')) },
                    }}
                    value={getFieldValue(getFieldName('addressLine'))}
                    onSelect={handleAddressAutocomplete}
                    onChange={handleAddressInputChanged}
                    countries={countries}
                    provinces={provinces}
                    size="large"
                  />
                </Form.Item>
              </Col>
              <Col span={0}>
                <Form.Item label={t.ADDRESS} name={setFieldName('address')}>
                  <Input size="large" />
                </Form.Item>
              </Col>
              <Col span={0}>
                <Form.Item label={t.STREET_NAME} name={setFieldName('streetName')}>
                  <Input size="large" />
                </Form.Item>
              </Col>
              <Col span={0}>
                <Form.Item label={t.STREET_NUMBER} name={setFieldName('streetNumber')}>
                  <Input size="large" />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item label={t.DEBTOR_PROFILE_UNIT} name={setFieldName('unit')}>
                  <Input size="large" />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  label={t.DEBTOR_PROFILE_CITY}
                  name={setFieldName('city')}
                  required
                  rules={[{ required: true, message: t.REQUIRED_FIELD }]}
                >
                  <Input size="large" />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  label={t.DEBTOR_PROFILE_PROVINCE}
                  name={setFieldName('provinceId')}
                  required
                  rules={[{ required: true, message: t.REQUIRED_FIELD }]}
                >
                  <SelectLookupDto
                    data={provinces}
                    onSelect={(id: string) => {
                      if (id !== selectedProvinceId) {
                        setSelectedProvinceId(id);
                        handleResetValuesOnProvinceChange();
                      }
                    }}
                    showSearch
                  />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  label={t.DEBTOR_PROFILE_POSTAL_CODE}
                  name={setFieldName('postalCode')}
                  required
                  rules={[{ required: true, message: t.REQUIRED_FIELD }]}
                >
                  <Input size="large" />
                </Form.Item>
              </Col>
              <Col span={hasDelete ? 7 : 8}>
                <Form.Item
                  label={t.DEBTOR_PROFILE_COUNTRY}
                  name={setFieldName('countryId')}
                  required
                  rules={[
                    { required: true, message: t.REQUIRED_FIELD },
                    {
                      validator: (rule: any, value: string) => {
                        return customCountryValidator && customCountryValidator(value)
                          ? Promise.resolve()
                          : Promise.reject();
                      },
                    },
                  ]}
                >
                  <SelectLookupDto
                    data={countries}
                    onSelect={(id: string) => {
                      if (id !== selectedCountryId) {
                        setSelectedCountryId(id);
                        handleResetValuesOnCountryChange();
                      }
                    }}
                    showSearch
                  />
                </Form.Item>
              </Col>
              <Col span={hasDelete ? 1 : 0}>
                <Form.Item label=" ">
                  <Icon iconName="Delete" onClick={onDelete} className="AddressComponent__delete-icon" />
                </Form.Item>
              </Col>
            </Row>
          </>
        )}
      </Form.Item>
    </Spin>
  );
};

export default AddressComponent;
