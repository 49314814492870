import { Tabs, TabsProps, TabPaneProps } from 'antd';

import styles from './AppTabsBase.module.scss';

export type AppTabsBaseProps = TabsProps;
export type AppTabPaneProps = TabPaneProps;

function AppTabsBase({ children, ...props }: AppTabsBaseProps) {
  return (
    <div className={styles.AppTabs}>
      <Tabs {...props}>{children}</Tabs>
    </div>
  );
}

AppTabsBase.AppTabPane = ({ children, ...props }: TabPaneProps) => {
  const { TabPane } = Tabs;
  return <TabPane {...props}>{children}</TabPane>;
};

export default AppTabsBase;
