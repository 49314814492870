import { useState, useMemo, useEffect } from 'react';
import moment from 'moment';
import { DATE_AND_TIME_FORMAT } from '../../../../../constants/common';
import ExpandableRecordsDisplay, {
  IMasterRecord,
  IRecordField,
  ITableColumn,
} from '../components/ExpandableRecordsDisplay/ExpandableRecordsDisplay';
import { ClientService } from '../../../../../shared/api/ClientService';
import API from '../../../../../utils/api';
import { getClaimStateValueAsElement } from '../../../helpers';
import useLocale from '../../../../../hooks/useLocale';
import ProcessStatusSelection from '../components/ProcessStatusSelection/ProcessStatusSelection';
import { argumentifyClaimsSearchCriteria } from '../utils';
import { ROUTES } from '../../../../../constants/routes';
import { splitOnCapital } from '../../../../../utils/helpers';
import { eventBus } from '../../../../../utils/eventBus';
import { ADMIN_INFO_CHANGE_EVENT } from '../../../../../constants/eventBus';
import { MAX_PAGE_SIZE } from '../../../../../constants/common';

interface IProps {
  data: ClientService.IApplicationFileDto | null;
  filingTypesList: ClientService.ILookupDto[] | null;
  handleUpdateData: () => void;
  disableAllFields?: boolean;
}

const Claims = ({ data, filingTypesList, handleUpdateData, disableAllFields = false }: IProps): JSX.Element => {
  const { t } = useLocale();

  const [processStatus, setProcessStatus] = useState<ClientService.AdminInfoClaimsDto | undefined>();

  const claimRecordFields: IRecordField[] = [
    { label: t.ADMIN_INFO_CLAIMS_CLAIMFIELDS_TYPE, dataIndex: 'type' },
    { label: t.ADMIN_INFO_CLAIMS_CLAIMFIELDS_ACCOUNT, dataIndex: 'account', width: '1.4' },
    { label: t.ADMIN_INFO_CLAIMS_CLAIMFIELDS_DEBT, dataIndex: 'debt', format: 'currency-no-decimals' },
    { label: t.ADMIN_INFO_CLAIMS_CLAIMFIELDS_LETTER_SENT, dataIndex: 'letterSent' },
    { label: t.ADMIN_INFO_CLAIMS_CLAIMFIELDS_COMPLETED, dataIndex: 'completed' },
  ];

  const ClaimFilesTableCols: ITableColumn[] = [
    { title: t.ADMIN_INFO_DETAILS_TABLE_COL_RECEIVED, dataIndex: 'received' },
    { title: t.ADMIN_INFO_DETAILS_TABLE_COL_ASSIGNED_TO, dataIndex: 'assignedTo' },
    //{ title: t.ADMIN_INFO_DETAILS_TABLE_COL_STATUS, dataIndex: 'status' },
    { title: t.ADMIN_INFO_DETAILS_TABLE_COL_DISCREPANCY, dataIndex: 'discrepancy' },
    { title: t.ADMIN_INFO_DETAILS_TABLE_COL_STATE, dataIndex: 'state' },
  ];

  const fileId = useMemo((): string => {
    if (data?.id) {
      return data.id;
    }
    return '';
  }, [data?.id]);

  // const fileName = useMemo((): string => {
  //   if (data?.applicationFileName) {
  //     return data.applicationFileName;
  //   }
  //   return '';
  // }, [data?.applicationFileName]);

  useEffect(() => {
    if (fileId) {
      API.unsecuredGET(fileId).then((response) => {
        setProcessStatus(response);
      });
    }
  }, [fileId]);

  return (
    <div>
      <ProcessStatusSelection
        label={t.ADMIN_INFO_CLAIMS_STATUS}
        changedFormProtection
        disableAllFields={disableAllFields}
        status={processStatus?.claimsProcessStatus}
        allItemsCompleted={processStatus?.completedAllClaims}
        apiSubmit={(values) => {
          return API.unsecuredPUT(fileId, {
            claimsProcessStatus: values.processStatus,
          } as ClientService.AdminInfoClaimsUpdateDto);
        }}
        onSubmitResponseSuccess={() => eventBus.dispatch(ADMIN_INFO_CHANGE_EVENT)}
      />
      <ExpandableRecordsDisplay
        recordFields={claimRecordFields}
        apiGetMasterRecords={() => {
          return API.liabilities(
            ...argumentifyClaimsSearchCriteria({ id: fileId, maxResultCount: MAX_PAGE_SIZE, isSecured: false })
          );
        }}
        apiSetMasterRecords={(response, setMasterRecords) => {
          // console.log('CLAIMS GetMasterRecords response:', response)
          const masterRecords: IMasterRecord[] = response.map((claim: ClientService.LiabilityClaimDto) => {
            const liabilityType = claim?.liabilityType || 0;
            return {
              id: claim.externalEntityId,
              leadValue: claim.creditor?.companyName,
              type: splitOnCapital(ClientService.LiabilityType[liabilityType]), // ENUM - TRANSLATE TO STRING
              account: claim.accountNumber,
              debt: claim.unsecuredSoaAmount,
              letterSent: claim.isLetterSent ? t.YES : t.NO,
              completed: claim.isCompleted ? t.YES : t.NO,
            };
          });
          // console.log('CLAIMS masterRecords:', masterRecords)
          setMasterRecords(masterRecords);
        }}
        tableColumns={ClaimFilesTableCols}
        apiGetTableData={(liabilityClaimId) => {
          return API.claimFiles(fileId, liabilityClaimId as string);
        }}
        apiSetTableData={(response, setTableData) => {
          const claimFiles = response.map((claimFile: ClientService.ClaimFileDto) => {
            return {
              id: claimFile.id,
              received:
                claimFile.receivedOn != null ? moment(claimFile.receivedOn).format(DATE_AND_TIME_FORMAT) : undefined,
              assignedTo: claimFile.assignedAgent?.fullName,
              status:
                claimFile.status != null
                  ? splitOnCapital(ClientService.ClaimFileStatusEnum[claimFile.status])
                  : undefined,
              discrepancy: claimFile.isDiscrepancy ? t.YES : t.NO,
              state: getClaimStateValueAsElement(claimFile.state),
            };
          });
          setTableData(claimFiles);
        }}
        viewTableRecordRoute={`${ROUTES.APPLICATION_OVERVIEW}/${fileId}/${ROUTES.CLAIMS}`}
      />
    </div>
  );
};

export default Claims;
