import { useMemo } from 'react';
import { Select, SelectProps, ConfigProvider } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { DefaultOptionType } from 'rc-select/es/Select';
import { isArrayWithValues } from '../../../utils/helpers';
import useLocale from '../../../hooks/useLocale';

import SelectSuffixIcon from '../../../components/SelectSuffixIcon/SelectSuffixIcon';

export interface ISelectOption {
  label?: string;
  value?: string | number;
}

export interface CustomSelectProps extends SelectProps {
  readOnly?: boolean;
  noSelectOption?: boolean;
}

const SelectInput: React.FC<CustomSelectProps> = ({
  options,
  noSelectOption = false,
  defaultValue,
  readOnly = false,
  children,
  size = 'large',
  loading,
  ...props
}): JSX.Element => {
  const { t } = useLocale();
  const optionsToRender = useMemo(() => {
    let allOptions: DefaultOptionType[] = [];

    if (options && isArrayWithValues(options)) {
      options.forEach((option) => {
        allOptions.push(option);
      });
    }

    return allOptions;
  }, [noSelectOption, options]);

  return (
    <ConfigProvider
      getPopupContainer={(node?: HTMLElement) => {
        if (node) {
          return node.parentElement as HTMLElement;
        }
        return document.body;
      }}
    >
      <Select
        suffixIcon={readOnly ? null : loading ? <LoadingOutlined /> : <SelectSuffixIcon />}
        size={size}
        open={readOnly ? false : undefined}
        allowClear={noSelectOption}
        options={!children ? optionsToRender : undefined}
        defaultValue={noSelectOption ? defaultValue : t.SELECT}
        showSearch
        loading={loading}
        {...props}
      >
        {children && <>{children}</>}
      </Select>
    </ConfigProvider>
  );
};

export default SelectInput;
