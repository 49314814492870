import { Button as AntdButton, ButtonProps } from 'antd';
import { Icon } from '@fluentui/react/lib/Icon';
import CustomIcon, { IconProps } from '../Icon/Icon';
//import 'antd/lib/button/style/index.css';
import './Button-KS.scss';
import './Button.scss';
import { useMemo } from 'react';

export type Kinds_Custom = 'primary' | 'secondary' | 'cancel' | 'special' | 'success' | 'darkgray';
type Kinds_PassThrough = 'link' | 'ghost' | 'dashed' | 'text';
export interface CustomButtonProps extends ButtonProps {
  kind?: Kinds_Custom | Kinds_PassThrough;
  extended?: boolean;
  padding?: 'no-padding' | 'small' | 'medium' | 'large' | boolean;
  height?: 'short' | 'tall' | boolean;
  narrow?: boolean; // removes the min-width setting so width is determined only by content and padding
  iconName?: string; // Fabric UI 'Friendly Name'
  iconOnClick?: () => void;
  iconOptions?: IconProps;
  placeIconAfter?: boolean;
}

const btnTypesToPassThru: Kinds_PassThrough[] = ['link', 'ghost', 'dashed', 'text'];

function Button({
  kind = 'primary',
  height = false,
  extended = false,
  narrow = false,
  padding = 'small',
  iconName,
  iconOnClick,
  iconOptions,
  placeIconAfter,
  ...props
}: CustomButtonProps): JSX.Element {
  let cssClass = `Button Button__${kind} `;
  cssClass += height ? `Button__height-${height} ` : '';
  cssClass += padding ? `Button__${padding} ` : '';
  cssClass += narrow ? 'Button__narrow ' : '';
  cssClass += extended ? 'Button__extended ' : '';

  props.type = btnTypesToPassThru.indexOf(kind as Kinds_PassThrough) >= 0 ? (kind as Kinds_PassThrough) : props.type;

  const iconToUse = useMemo(() => {
    if (iconName) {
      return (
        <Icon
          iconName={iconName}
          onClick={
            iconOnClick != null
              ? (e) => {
                  e.stopPropagation();
                  iconOnClick();
                }
              : undefined
          }
        />
      );
    } else if (iconOptions) {
      return <CustomIcon {...iconOptions} className="icon-from-custom-icon" />;
    }
    return undefined;
  }, [iconName, iconOnClick, iconOptions]);

  return (
    <div className={`Button_Custom ${props.className ? props.className : ''}`} data-component-type="Button">
      <AntdButton {...props} className={cssClass}>
        {!placeIconAfter && iconToUse && iconToUse}
        {props.children}
        {placeIconAfter && iconToUse && iconToUse}
      </AntdButton>
    </div>
  );
}

export default Button;
