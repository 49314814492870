import { CSSProperties } from 'react';
import { Row, Col } from 'antd';

import { convertNegativeToReadable } from '../utils';

import '../FileAnalysis.scss';
import styles from '../../../../styles/style.module.scss';

interface ISurplusIncomeRow {
  title?: string;
  debtorCellValue?: number;
  spouseCellValue?: number | string;
  otherCellValue?: number | string;
  totalCellValue?: number;
  merged?: boolean;
  style?: CSSProperties;
}

const SurplusIncomeRow = ({
  title,
  debtorCellValue,
  spouseCellValue,
  otherCellValue,
  totalCellValue,
  merged,
  style,
}: ISurplusIncomeRow) => (
  <Row className="FileAnalysis__results--row" style={style}>
    <Col span={12} className="FileAnalysis__results--row-title">
      {title}
    </Col>

    <Col span={merged ? 0 : 3}>{convertNegativeToReadable(debtorCellValue)}</Col>
    <Col span={merged ? 0 : 3}>
      {typeof spouseCellValue === 'string' ? spouseCellValue : convertNegativeToReadable(spouseCellValue)}
    </Col>
    <Col
      span={merged ? 9 : 3}
      style={{
        color: merged ? styles.colorDanger : styles.colorPrimary,
        fontWeight: merged ? 600 : 400,
        textAlign: 'right',
      }}
    >
      {typeof otherCellValue === 'string' ? otherCellValue : convertNegativeToReadable(otherCellValue)}
    </Col>

    <Col span={3} className="FileAnalysis__results--cell-total">
      {convertNegativeToReadable(totalCellValue)}
    </Col>
  </Row>
);

export default SurplusIncomeRow;
