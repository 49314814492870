export const FILE_ANALYSIS_FORM_48_TAB = 'form-48';
export const FILE_ANALYSIS_ESTIMATED_REALIZATIONS_TAB = 'estimated-realizations';
export const FILE_ANALYSIS_CALCULATIONS_TAB = 'calculations';
export const FILE_ANALYSIS_SURPLUS_INCOME_TAB = 'surplus-income';
export const FILE_ANALYSIS_FEES_TAB = 'fees';

export const FILE_ANALYSIS_PROPOSAL_DETAILS_TAB = 'proposal_details';
export const FILE_ANALYSIS_BANKRUPTCY_DETAILS_TAB = 'bankruptcy_details';
export const FILE_ANALYSIS_TRUSTEE_REPORT_DETAILS_TAB = 'trustee_report_details';
export const FILE_ANALYSIS_ASSETS_TAB = 'assets';
export const FILE_ANALYSIS_DEBTS_CREDITORS_TAB = 'debts-creditors';
export const FILE_ANALYSIS_SURPLUS_INCOME_INFO_TAB = 'surplus-income-information';

export const MAX_DETAILS_COUNT = 5;
export const NUNVUT_PROVINCE_CODE = 'NU';
