import { useCallback } from 'react';
import { Modal, Form, Row, Col } from 'antd';
import moment from 'moment';

import Button from '../../components/Button/Button';
import DatePicker from '../../components/DatePicker/DatePicker';
import AttachmentsTab, { AttachmentsFormProps, IAttachmentTabProps } from '../../components/AttachmentsTab';

import useLocale from '../../hooks/useLocale';
import { UPLOAD_FILES_EVENT } from '../../constants/eventBus';
import { DATE_FORMAT2, DATE_FORMAT_PLACEHOLDER } from '../../constants/common';
import { eventBus } from '../../utils/eventBus';

import './UploadAndShareModal.scss';

interface IFilingDateProps {
  filingDate?: moment.Moment;
  required?: boolean;
}

export interface UploadAndShareModalProps extends IAttachmentTabProps {
  title?: string;
  hasFilingDateField?: boolean;
  hasShareOption?: boolean;
  filingDateProps?: IFilingDateProps;
  onOk?: (documentsIds?: string[], filingDate?: moment.Moment) => void;
  onCancel?: () => void;
}

interface IForm extends AttachmentsFormProps {
  filingDate: moment.Moment;
}

function UploadAndShareModal({
  title,
  hasFilingDateField,
  hasShareOption = false,
  filingDateProps,
  onOk,
  onCancel,
  ...attachmentsTabProps
}: UploadAndShareModalProps): JSX.Element {
  const { t } = useLocale();
  const [form] = Form.useForm<IForm>();

  const handleUpload = useCallback(
    (uploadAndShare: boolean) => {
      form.validateFields().then(({ filingDate }) => {
        eventBus.dispatch(UPLOAD_FILES_EVENT, {
          uploadAndShare,
          onUploadSuccess: (documentsIds: string[]) => {
            if (onOk) onOk(documentsIds, filingDate);
          },
        });
      });
    },
    [form, onOk]
  );

  const handleCancel = useCallback(() => {
    if (onCancel) onCancel();
  }, [onCancel]);

  const convertUndefinedToTrue = useCallback((value?: boolean) => (value === undefined ? true : value), []);

  return (
    <Modal
      title={title}
      destroyOnClose
      centered
      visible
      footer={null}
      closable
      onCancel={handleCancel}
      width={1080}
      className="UploadAndShareModal"
    >
      <Form
        form={form}
        layout="vertical"
        initialValues={{ filingDate: filingDateProps?.filingDate }}
        name="UploadAndShareModal"
      >
        <Row>
          <Col span={8}>
            {hasFilingDateField && (
              <Form.Item
                label={t.APPOINTMENT_SCHEDULING_DATE}
                name="filingDate"
                required={filingDateProps?.required}
                rules={[{ required: filingDateProps?.required, message: t.FILING_DATE_REQUIRED }]}
              >
                <DatePicker
                  format={DATE_FORMAT2}
                  dateSetter={null}
                  size="large"
                  disabledDate={(date) => date.isAfter(moment(), 'day')}
                  placeholder={DATE_FORMAT_PLACEHOLDER}
                />
              </Form.Item>
            )}
          </Col>
          <Col span={24}>
            <Form.Item>
              <AttachmentsTab
                form={form}
                {...attachmentsTabProps}
                newSupportingFileRowLayoutProps={{
                  ...attachmentsTabProps?.newSupportingFileRowLayoutProps,
                  disabledPrefixInput: convertUndefinedToTrue(
                    attachmentsTabProps?.newSupportingFileRowLayoutProps?.disabledPrefixInput
                  ),
                  preselectedPrefixInput: convertUndefinedToTrue(
                    attachmentsTabProps?.newSupportingFileRowLayoutProps?.preselectedPrefixInput
                  ),
                  disabledFoldersInput: convertUndefinedToTrue(
                    attachmentsTabProps?.newSupportingFileRowLayoutProps?.disabledFoldersInput
                  ),
                }}
                isFlatLayout
                flatLayoutProps={{
                  hasExistingFiles: true,
                  label: t.FILES,
                  ...attachmentsTabProps?.flatLayoutProps,
                }}
                required
              />
            </Form.Item>
          </Col>
        </Row>

        <Row align="middle" justify="space-between" className="FormFilingModal__footer" wrap={false}>
          <Col>{t.CMBND_FILE_GREATER_THAN_100_ERROR}</Col>
          <Row gutter={12} justify="end" align="bottom" wrap={false}>
            <Col>
              <Form.Item noStyle>
                <Button kind="cancel" onClick={onCancel}>
                  {t.CANCEL}
                </Button>
              </Form.Item>
            </Col>
            <Col>
              <Form.Item noStyle>
                <Button onClick={() => handleUpload(false)}>{t.UPLOAD}</Button>
              </Form.Item>
            </Col>
            <Col>
              {hasShareOption && (
                <Form.Item noStyle>
                  <Button onClick={() => handleUpload(true)}>{t.UPLOAD_N_SHARE}</Button>
                </Form.Item>
              )}
            </Col>
          </Row>
        </Row>
      </Form>
    </Modal>
  );
}

export default UploadAndShareModal;
