import { useCallback, useEffect, useMemo, useState } from 'react';
import { Modal, Form, Select, Col, Row, Spin, Input, Divider } from 'antd';
import moment from 'moment';

import Button from '../../../../../../../components/Button/Button';
import DataItem from '../../../../../../../components/DataItem/DataItem';
import DatePicker from '../../../../../../../components/DatePicker/DatePicker';
import genericMessage from '../../../../../../../utils/genericMessage';
import SelectSuffixIcon from '../../../../../../../components/SelectSuffixIcon/SelectSuffixIcon';

import useLocale from '../../../../../../../hooks/useLocale';
import { convertDateToReadable } from '../../utils';
import { DATE_FORMAT2, DATE_FORMAT_PLACEHOLDER } from '../../../../../../../constants/common';
import { ClientService } from '../../../../../../../shared/api/ClientService';
import API from '../../../../../../../utils/api';

import './ChequePADRequestReviewModal.scss';

interface IProps {
  id?: string;
  applicationFileId: string;
  onOk: () => void;
  onCancel: () => void;
}

const ChequePADRequestReviewModal = ({ id, applicationFileId, onOk, onCancel }: IProps) => {
  const { t } = useLocale();
  const [form] = Form.useForm<ClientService.PADScheduleDto>();
  const { Option } = Select;
  const { TextArea } = Input;

  const [initalValues, setInitialValues] = useState<ClientService.PADScheduleDto>();
  const [loading, setLoading] = useState<boolean>(false);

  const PAD_SCHEDULE_STATE_DROPDOWN_OPTIONS = useMemo(
    () => [
      { value: ClientService.PADScheduleStateEnum.Active, label: t.ACTIVE, disabled: true },
      { value: ClientService.PADScheduleStateEnum.HoldRequested, label: t.HOLD_REQUESTED, disabled: true },
      { value: ClientService.PADScheduleStateEnum.OnHold, label: t.ON_HOLD, disabled: false },
      { value: ClientService.PADScheduleStateEnum.RequestRejected, label: t.REQUEST_REJECTED, disabled: false },
    ],
    [t.ACTIVE, t.HOLD_REQUESTED, t.ON_HOLD, t.REQUEST_REJECTED]
  );

  const requestPadSchedule = useCallback(
    async (id: string, applicationFileId: string) => {
      setLoading(true);
      const response = await API.padScheduleGET(id, applicationFileId).catch(() => setLoading(false));
      setLoading(false);

      if (response) {
        form.setFieldsValue(response);
        setInitialValues(response);
      }
    },
    [form]
  );

  const options = useMemo(() => {
    const basis = [...PAD_SCHEDULE_STATE_DROPDOWN_OPTIONS];
    switch (initalValues?.state) {
      case ClientService.PADScheduleStateEnum.Active:
        return [basis[0], basis[2]];
      case ClientService.PADScheduleStateEnum.OnHold:
        return basis?.splice(2, 1);
      case ClientService.PADScheduleStateEnum.HoldRequested:
        return basis?.splice(1, 3);
      case ClientService.PADScheduleStateEnum.RequestRejected:
        return basis?.splice(3, 1);
      default:
        return basis?.splice(0, 2);
    }
  }, [PAD_SCHEDULE_STATE_DROPDOWN_OPTIONS, initalValues?.state]);

  const handleFinish = useCallback(
    async (values) => {
      const response = await API.padSchedulePUT(applicationFileId as string, {
        id: initalValues?.id,
        ...values,
      });

      if (response.result === ClientService.Result.Successful) {
        genericMessage.success(t.CHEQUEPAD_REQUEST_IS_UPDATED);
        if (onOk) onOk();
      } else {
        genericMessage.error({}, response?.messages?.[0]?.body);
      }
    },
    [applicationFileId, initalValues?.id, onOk, t.CHEQUEPAD_REQUEST_IS_UPDATED]
  );

  useEffect(() => {
    if (id && applicationFileId) requestPadSchedule(id, applicationFileId);
  }, [id, applicationFileId, requestPadSchedule]);

  return (
    <Modal
      destroyOnClose
      centered
      visible
      title={t.REVIEW_CHEQUEPAD_REQUEST}
      className="ChequePADRequestReviewModal"
      width={750}
      footer={null}
      closable
      onCancel={onCancel}
    >
      <Spin spinning={loading}>
        <Form form={form} layout="vertical" onFinish={handleFinish}>
          <Row gutter={24} className="ChequePADRequestReviewModal__data-row">
            <DataItem colSpan={12} label={t.DUE_DATE} value={convertDateToReadable(initalValues?.dueDate)} />
            <DataItem colSpan={12} label={t.TYPE} value={initalValues?.type} />
          </Row>

          <Row gutter={24} className="ChequePADRequestReviewModal__data-row">
            <DataItem colSpan={12} label={t.NUMBER} value={initalValues?.type} />
            <DataItem colSpan={12} label={t.AGR_NUMBER} value={initalValues?.agrNo} />
          </Row>

          <Row gutter={24} className="ChequePADRequestReviewModal__data-row">
            <DataItem colSpan={12} label={t.AMOUNT} value={initalValues?.amount} />
            <DataItem colSpan={12} label={t.DEPOSITED} value={initalValues?.deposited} />
          </Row>

          <Row gutter={24} className="ChequePADRequestReviewModal__data-row">
            <DataItem colSpan={12} label={t.STATUS} value={initalValues?.isDeposited ? t.DEPOSITED : t.NOT_DEPOSITED} />
          </Row>

          <Divider />

          <Row gutter={24}>
            <Col span={8}>
              <Form.Item label={t.STATE} name="state" rules={[{ required: true, message: t.REQUIRED_FIELD }]}>
                <Select size="large" suffixIcon={<SelectSuffixIcon />}>
                  {options?.map((item) => (
                    <Option value={item?.value} key={item?.value} disabled={item.disabled}>
                      {item?.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>

            <Form.Item noStyle shouldUpdate>
              {({ getFieldValue }) => {
                const state = getFieldValue('state');
                if (
                  state === ClientService.PADScheduleStateEnum.OnHold ||
                  state === ClientService.PADScheduleStateEnum.HoldRequested
                ) {
                  return (
                    <>
                      <Col span={8}>
                        <Form.Item
                          label={t.HOLD_UNTIL}
                          name="holdUntil"
                          rules={[
                            {
                              required: true,
                              message: t.REQUIRED_FIELD,
                            },
                            {
                              validator: (_, date) => {
                                if (moment(date).isBefore(moment())) {
                                  return Promise.reject(t.HOLD_UNTIL_DATE_SHOULD_BE_IN_FUTURE);
                                } else {
                                  return Promise.resolve();
                                }
                              },
                            },
                          ]}
                        >
                          <DatePicker
                            format={DATE_FORMAT2}
                            dateSetter={null}
                            size="large"
                            disabledDate={(date) => date.isBefore(moment(), 'day')}
                            placeholder={DATE_FORMAT_PLACEHOLDER}
                          />
                        </Form.Item>
                      </Col>
                      <Col span={24}>
                        <Form.Item
                          label={t.HOLD_REASON}
                          name="holdReason"
                          rules={[{ required: true, message: t.REQUIRED_FIELD }]}
                        >
                          <TextArea rows={4} />
                        </Form.Item>
                      </Col>
                    </>
                  );
                }

                if (state === ClientService.PADScheduleStateEnum.RequestRejected) {
                  return (
                    <Col span={24}>
                      <Form.Item
                        label={t.REJECT_REASON}
                        name="rejectionReason"
                        rules={[{ required: true, message: t.REQUIRED_FIELD }]}
                      >
                        <TextArea rows={4} />
                      </Form.Item>
                    </Col>
                  );
                }

                return <></>;
              }}
            </Form.Item>
          </Row>

          <Row gutter={20} justify="end" className="ChequePADRequestReviewModal__footer">
            <Col>
              <Form.Item noStyle shouldUpdate>
                <Button kind="cancel" onClick={onCancel}>
                  {t.CANCEL}
                </Button>
              </Form.Item>
            </Col>
            <Col>
              <Form.Item noStyle shouldUpdate>
                <Button kind="primary" htmlType="submit">
                  {t.SAVE_CONFIRM}
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Spin>
    </Modal>
  );
};

export default ChequePADRequestReviewModal;
