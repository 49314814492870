import { useCallback, useState, useEffect } from 'react';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import { Icon } from '@fluentui/react/lib/Icon';
import { Form, Select, Popover, SelectProps, Row, Col, FormInstance } from 'antd';
import { geocodeByAddress, getLatLng } from 'react-places-autocomplete';
import { Loader, LoaderOptions } from '@googlemaps/js-api-loader';

import GoogleMaps from '../../../modals/GoogleMaps/GoogleMaps';
import LocationIcon from './LocationIcon';
import WorkingHours from '../../WorkingHours/WorkingHours';

import useLocale from '../../../hooks/useLocale';
import useModal from '../../../hooks/useModal';

import { argumentifyOfficeLocationsSearchCriteria } from '../utils';
import { ISchedulingForm } from '../types';

import { INCLUDE_INACTIVE, OFFICE_LOCATIONS_QUERY, USE_QUERY_OPTIONS } from '../../../constants/reactQuery';
import { LocationDto } from '../../../modals/GoogleMaps/types';
import API from '../../../utils/api';
import {
  WEEKLY_VERSION,
  PLACES_LIBRARY,
  GEOMETRY_LIBRARY,
  DEFAULT_LNG,
  DEFAULT_LAT,
  MAX_PAGE_SIZE,
} from '../../../constants/common';

import '../SchedulingComponent.scss';

interface IProps extends SelectProps {
  form?: FormInstance<ISchedulingForm>;
  prefillWithClosestOfficeDetails?: boolean;
}

const LocationSelect = ({ form, prefillWithClosestOfficeDetails = true, ...props }: IProps) => {
  const { t } = useLocale();
  const { Option } = Select;
  const { showModal, closeModal } = useModal();
  const { applicationFileId } = useParams<{ applicationFileId: string }>();

  const [isApiLoaded, setIsApiLoaded] = useState<boolean>(false);
  const [loaderOptions, setLoaderOptions] = useState<LoaderOptions>({
    version: WEEKLY_VERSION,
    libraries: [PLACES_LIBRARY, GEOMETRY_LIBRARY],
    apiKey: '',
  });

  const { data: officeLocations } = useQuery(
    [OFFICE_LOCATIONS_QUERY, INCLUDE_INACTIVE, MAX_PAGE_SIZE],
    () =>
      API.officeLocationsGET(
        ...argumentifyOfficeLocationsSearchCriteria({ maxResultCount: MAX_PAGE_SIZE, includeInactive: true })
      ),
    USE_QUERY_OPTIONS
  );

  const getClosestOfficeId = useCallback(
    (debtorLatLngPosition: google.maps.LatLngLiteral) => {
      if (officeLocations?.items && officeLocations?.items?.length > 1) {
        return officeLocations?.items
          ?.map((item) => ({
            id: item?.id,
            position: new google.maps.LatLng(Number(item?.latitude), Number(item?.longitude)),
          }))
          ?.reduce((prev, curr) => {
            const computeDistanceBetween = google.maps.geometry.spherical.computeDistanceBetween;
            const currentPos = computeDistanceBetween(debtorLatLngPosition, curr?.position);
            const prevPos = computeDistanceBetween(debtorLatLngPosition, prev?.position);

            return currentPos < prevPos ? curr : prev;
          })?.id;
      }
    },
    [officeLocations?.items]
  );

  const requestDebtorProfile = useCallback(
    async (applicationFileId: string) => {
      const response = await API.getDebtorProfile(applicationFileId);
      const countries = await API.listCountries();

      const isCanadaBasedDebtor = Boolean(
        countries?.find((item) => item?.id === response?.profile?.countryId)?.code === 'CA'
      );

      if (isCanadaBasedDebtor && !form?.getFieldValue('officeLocationId') && prefillWithClosestOfficeDetails) {
        if (!response?.profile?.postalCode) {
          //form?.setFieldsValue({ officeLocationId: officeLocations?.items?.[0]?.id });

          if (response?.profile?.provinceId) {
            const preSelectedOfficeLocations = officeLocations?.items?.filter(
              (i) => i.provinceId == response?.profile?.provinceId
            );
            if (preSelectedOfficeLocations && preSelectedOfficeLocations.length > 0) {
              form?.setFieldsValue({ officeLocationId: preSelectedOfficeLocations?.[0].id });
              if (props.onChange) props.onChange(preSelectedOfficeLocations?.[0].id, []);
            }
          } else {
            form?.setFieldsValue({ officeLocationId: officeLocations?.items?.[0]?.id });
            if (props.onChange) props.onChange(officeLocations?.items?.[0]?.id, []);
          }

          //if (props.onChange) props.onChange(officeLocations?.items?.[0]?.id, []);
        } else {
          console.log('postal code', response?.profile?.postalCode);
          const result = await geocodeByAddress(response?.profile?.postalCode);

          if (result && result[0]) {
            const geocode = result[0];
            const debtorLatLngPosition = await getLatLng(geocode);
            console.log('debtorLatLngPosition', debtorLatLngPosition);
            const closestOfficeId = getClosestOfficeId(debtorLatLngPosition);
            console.log('closestOfficeId', closestOfficeId);
            form?.setFieldsValue({ officeLocationId: closestOfficeId });
            if (props.onChange) props.onChange(closestOfficeId, []);
          }
        }
      }
    },
    [form, getClosestOfficeId, prefillWithClosestOfficeDetails]
  );

  const requestApiKey = useCallback(async () => {
    const result = await API.getGoogleApiKey();
    const apiKey = result.apiKey;

    if (apiKey) {
      const updatedLoaderOptions = { ...loaderOptions, apiKey };
      const loader = new Loader(updatedLoaderOptions);
      const isLoaderLoaded = await loader?.load();

      if (isLoaderLoaded) {
        setLoaderOptions(updatedLoaderOptions);
        setIsApiLoaded(true);
      }
    }
  }, [loaderOptions]);

  useEffect(() => {
    if (!loaderOptions?.apiKey) {
      requestApiKey();
    }
  }, [loaderOptions?.apiKey, requestApiKey]);

  useEffect(() => {
    if (applicationFileId && isApiLoaded) requestDebtorProfile(applicationFileId);
  }, [applicationFileId, requestDebtorProfile, isApiLoaded]);

  return (
    <Form.Item noStyle shouldUpdate>
      <Form.Item
        label={
          <Row className="SchedulingComponent__location--popover" align="bottom" justify="space-between">
            <Col className="SchedulingComponent__label--required">{t.APPOINTMENT_SCHEDULING_LOCATION}</Col>
            <Col className="SchedulingComponent__location--popover-icon">
              <Form.Item noStyle shouldUpdate>
                {({ getFieldValue }) => {
                  const officeLocationId = getFieldValue('officeLocationId');
                  const office = officeLocations?.items?.find((item) => item.id === officeLocationId);
                  return (
                    officeLocationId && (
                      <Popover
                        placement="topRight"
                        content={
                          <>
                            <div>
                              <b>{office?.name}</b>
                            </div>
                            <div>{`${office?.streetName} ${office?.streetNumber} ${office?.city} ${office?.postalCode}`}</div>
                            <WorkingHours json={office?.hours} />
                          </>
                        }
                        trigger="hover"
                        className="SchedulingComponent__location--popover-icon"
                      >
                        <Icon iconName="Info" style={{ fontWeight: 'bold' }} />
                      </Popover>
                    )
                  );
                }}
              </Form.Item>
            </Col>
          </Row>
        }
        name="officeLocationId"
        className="SchedulingComponent__location"
        rules={[{ required: true, message: t.LOCATION_IS_REQUIRED }]}
        requiredMark="optional"
      >
        <Select
          {...props}
          size="large"
          suffixIcon={
            <Form.Item shouldUpdate noStyle>
              {({ getFieldValue, setFieldsValue }) => {
                const officeLocationId = getFieldValue('officeLocationId');
                const target = officeLocations?.items?.find((item) => item.id === officeLocationId);
                return (
                  <span
                    onClick={(e) => {
                      e.stopPropagation();
                      e.preventDefault();
                      if (!props.disabled) {
                        showModal(
                          <GoogleMaps
                            data={(officeLocations?.items || []) as LocationDto[]}
                            target={target as LocationDto}
                            center={{
                              lat: Number(target?.latitude || DEFAULT_LAT),
                              lng: Number(target?.longitude || DEFAULT_LNG),
                            }}
                            isCancel={t.CANCEL}
                            isOk={t.CONTINUE}
                            onCancel={closeModal}
                            onOk={(officeLocationId) => {
                              setFieldsValue({ officeLocationId });
                              closeModal();
                            }}
                          />
                        );
                      }
                    }}
                    className="SchedulingComponent__location--icon"
                  >
                    <LocationIcon />
                  </span>
                );
              }}
            </Form.Item>
          }
          showSearch
          filterOption={(input, option) => option?.title?.toLowerCase().includes(input.toLowerCase() || '')}
        >
          {officeLocations?.items
            ?.filter((item) => item?.isActive || item?.id === form?.getFieldValue('officeLocationId'))
            .map((item) => (
              <Option key={item.id} value={item.id} title={item?.name}>
                {item.name}
              </Option>
            ))}
        </Select>
      </Form.Item>
    </Form.Item>
  );
};

export default LocationSelect;
