import { useCallback, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Form, Col, Row, Spin } from 'antd';

import AssetsDebts from '../../../ApplicationOverviewPage/ApplicationOverviewContent/ApplicationForm/AssetsDebts';

import { currencyFormatter } from '../utils';
import API from '../../../../utils/api';
import eventBus from '../../../../utils/eventBus';
import useLocale from '../../../../hooks/useLocale';
import { ClientService } from '../../../../shared/api/ClientService';

import '../FileAnalysis.scss';
import styles from '../../../../styles/style.module.scss';

function AssetsDetails({
  disabled = false,
  applicationFile,
}: {
  disabled?: boolean;
  applicationFile?: ClientService.IApplicationFileDto;
}): JSX.Element {
  const { applicationFileId } = useParams<{ applicationFileId: string }>();
  const { t } = useLocale();

  const [totalAssets, setTotalAssets] = useState<number>();
  const [listAppFormAssetsLoading, setListAppFormAssetsLoading] = useState<boolean>();

  const requestListAppFormAssets = useCallback(async () => {
    setListAppFormAssetsLoading(true);
    const response = await API.listAppFormAssets(applicationFileId).catch(() => setListAppFormAssetsLoading(false));
    if (response) {
      setTotalAssets(response?.map((item) => item?.value || 0).reduce((sum, current) => sum + current, 0));

      eventBus.dispatch('dataEntryFormValuesChanged');
    }

    setListAppFormAssetsLoading(false);
  }, [applicationFileId]);

  return (
    <Spin spinning={listAppFormAssetsLoading}>
      <Form layout="vertical" className="FileAnalysis__form">
        <Form.Item label={t.TOTAL_ASSETS}>
          <Row align="middle" gutter={8}>
            <Col
              className="FileAnalysis__form--total-assets"
              style={{
                color: disabled ? styles.colorSecondary : styles.colorGreen,
              }}
            >{`$${currencyFormatter(totalAssets)}`}</Col>
          </Row>
        </Form.Item>

        <AssetsDebts assetsOnly onUpdateAssets={requestListAppFormAssets} applicationFile={applicationFile} />
      </Form>
    </Spin>
  );
}

export default AssetsDetails;
