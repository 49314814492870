import { useCallback, useState } from 'react';
import { Modal, Form, Row, Col, Select, Input, Spin } from 'antd';

import Button from '../../../../../../components/Button/Button';
import useLocale from '../../../../../../hooks/useLocale';
import SelectSuffixIcon from '../../../../../../components/SelectSuffixIcon/SelectSuffixIcon';

import { ClientService } from '../../../../../../shared/api/ClientService';
import API from '../../../../../../utils/api';

import './ResponseCourtAppearanceModal.scss';
import genericMessage from '../../../../../../utils/genericMessage';

interface IProps {
  dischargeId: string;
  applicationFileId: string;
  onOk?: () => void;
  onCancel?: () => void;
}

function ResponseCourtAppearanceModal({ dischargeId, applicationFileId, onOk, onCancel }: IProps): JSX.Element {
  const { t } = useLocale();
  const { TextArea } = Input;
  const { Option } = Select;

  const [form] = Form.useForm<ClientService.DischargeCourtApperanceResponseCreateDto>();
  const [loading, setLoading] = useState<boolean>(false);

  const handleFinish = useCallback(
    async (values) => {
      setLoading(true);
      const response = await API.courtApperanceResponse(applicationFileId as string, {
        ...values,
        id: dischargeId,
      }).catch(() => setLoading(false));
      setLoading(false);

      if (response?.result === ClientService.Result.Successful) {
        if (onOk) {
          onOk();
        }
      } else {
        //
        // Any server side error, display error messages.
        //
        if (response?.messages) {
          genericMessage.error({}, response?.messages?.[0]?.body);
        } else {
          genericMessage.error({}, t.SAVE_COURT_APPEARANCE_RESPONSE_FAILED);
        }
      }
    },
    [onOk, dischargeId, applicationFileId]
  );

  const handleCancel = useCallback(() => {
    if (onCancel) onCancel();
  }, [onCancel]);

  return (
    <Modal
      title={t.DISCHARGE_COURT_APPEARANCE_RESPONSE}
      destroyOnClose
      centered
      visible
      footer={null}
      closable
      onCancel={handleCancel}
      width={700}
      className="ResponseCourtAppearanceModal"
    >
      <Spin spinning={loading}>
        <Form form={form} layout="vertical" name="ResponseCourtAppearanceModal" onFinish={handleFinish}>
          <Row>
            <Col span={24}>
              <Form.Item
                name="response"
                label={t.DISCHARGE_RESPONSE}
                required
                rules={[{ required: true, message: t.REQUIRED_FIELD }]}
              >
                <Select placeholder={t.SELECT} size="large" suffixIcon={<SelectSuffixIcon />}>
                  <Option value={ClientService.DischargeCourtAppearanceNotificationStatusEnum.Accepted}>
                    {t.ACCEPT}
                  </Option>
                  <Option value={ClientService.DischargeCourtAppearanceNotificationStatusEnum.Rejected}>
                    {t.REJECT}
                  </Option>
                </Select>
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item noStyle shouldUpdate>
                {({ getFieldValue }) => {
                  const requestRejected =
                    getFieldValue('response') === ClientService.DischargeCourtAppearanceNotificationStatusEnum.Rejected;
                  return (
                    <Form.Item
                      name="comments"
                      label={t.DISCHARGE_COMMENTS}
                      required={requestRejected}
                      rules={[{ required: requestRejected, message: t.REQUIRED_FIELD }]}
                    >
                      <TextArea rows={6} maxLength={1000} />
                    </Form.Item>
                  );
                }}
              </Form.Item>
            </Col>
          </Row>

          <Row align="middle" justify="end" className="ResponseCourtAppearanceModal__footer" wrap={false} gutter={10}>
            <Col>
              <Form.Item noStyle>
                <Button kind="cancel" onClick={onCancel}>
                  {t.CANCEL}
                </Button>
              </Form.Item>
            </Col>
            <Col>
              <Form.Item noStyle>
                <Button htmlType="submit">{t.SAVE}</Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Spin>
    </Modal>
  );
}

export default ResponseCourtAppearanceModal;
