import { Col, Row } from 'antd';

import Overrides from './Overrides/Overrides';
import WeeklySchedule from './WeeklySchedule/WeeklySchedule';

function WeeklyAvailability() {
  return (
    <Row gutter={100}>
      <Col span={14}>
        <WeeklySchedule />
      </Col>
      <Col span={10}>
        <Overrides />
      </Col>
    </Row>
  );
}

export default WeeklyAvailability;
