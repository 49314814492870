import { useCallback, useEffect, useMemo, useState } from 'react';
import { Col, Row, Form, Select, Divider, Spin } from 'antd';
import { useParams } from 'react-router-dom';
import moment from 'moment';

import DataItem from '../../../../../components/DataItem/DataItem';
import Button from '../../../../../components/Button/Button';
import RequestForReview from '../../../../../components/RequestForReview/RequestForReview';
import FormSectionTitle from '../../../../../components/FormSectionTitle/FormSectionTitle';
import UploadSection from './components/UploadSection';
import FilingDateModal from '../../../../../modals/FilingDateModal/FilingDateModal';
import UploadAndShareModal from '../../../../../modals/UploadAndShareModal/UploadAndShareModal';
import ReceiptsAndDisbursementsStatusForm from './components/ReceiptsAndDisbursementsStatusForm';
import ActionConfirmationModal from '../../../../../modals/ActionConfirmationModal/ActionConfirmationModal';
import SelectSuffixIcon from '../../../../../components/SelectSuffixIcon/SelectSuffixIcon';

import useLocale from '../../../../../hooks/useLocale';
import useModal from '../../../../../hooks/useModal';

import {
  DISCHARGE_FOLDER_CODE,
  DISCHARGE_FULL_PERFORMANCE_CERTIFICATE_PREFIX,
  DISCHARGE_SRD_REPORT_PREFIX,
  DISCHARGE_FORM_58,
  DISCHARGE_DIVIDENT_CHECKLIST,
  DISCHARGE_SRD_CHECKLIST,
  DISCHARGE_FORM_16,
  DISCHARGE_FORM_15,
} from './constants';
import {
  argumentifyUpdateDocument,
  argumentifyDocumentsSearchCriteria,
} from '../../../../../components/AttachmentsTab/utils';
import { convertDateToReadable } from './utils';
import { eventBus } from '../../../../../utils/eventBus';
import { RECEIPTS_DISBURSEMENTS_FORM_CHANGE_EVENT } from '../../../../../constants/eventBus';
import genericMessage from '../../../../../utils/genericMessage';
import { ClientService } from '../../../../../shared/api/ClientService';
import API from '../../../../../utils/api';
import { MAX_PAGE_SIZE } from '../../../../../constants/common';

import './ReceiptsAndDisbursements.scss';

interface IProps {
  filingTypeEnum?: ClientService.FilingTypeEnum;
  disableAllFields?: boolean;
}

const ReceiptsAndDisbursements = ({ filingTypeEnum, disableAllFields }: IProps) => {
  const { Option } = Select;
  const { t } = useLocale();

  const { showModal, closeModal } = useModal();
  const { applicationFileId } = useParams<{ applicationFileId: string }>();

  const [form] = Form.useForm<ClientService.FileReceiptsDisbursementsFormUpdateDto>();
  const [initial, setInitial] = useState<ClientService.FileReceiptsDisbursementsFormDto>();
  const [loading, setLoading] = useState<boolean>(false);

  const requestReceiptsDisbursementsForm = useCallback(
    async (applicationFileId: string) => {
      setLoading(true);
      const response = await API.fileReceiptsDisbursementsFormGET(applicationFileId).catch(() => setLoading(false));
      setLoading(false);

      if (response) {
        form.setFieldsValue({
          isTrusteeDischarged: response?.isTrusteeDischarged,
          isFileClosed: response?.isFileClosed,
        });
        setInitial(response);
      }
    },
    [form]
  );

  const handleUploadForReview = useCallback(
    async (prefixCode?: string, title?: string) => {
      showModal(
        <RequestForReview
          title={title}
          applicationFileId={applicationFileId}
          supportingFolderCode={DISCHARGE_FOLDER_CODE}
          prefixCodes={prefixCode ? [prefixCode] : undefined}
          newSupportingFileRowLayoutProps={{
            disabledPrefixInput: true,
            preselectedPrefixInput: true,
            disabledFoldersInput: true,
          }}
          isFlatLayout
          flatLayoutProps={{ hasExistingFiles: true, label: t.UPLOAD }}
          onOk={() => {
            closeModal();
            requestReceiptsDisbursementsForm(applicationFileId as string);
            eventBus.dispatch(RECEIPTS_DISBURSEMENTS_FORM_CHANGE_EVENT);
          }}
          onCancel={closeModal}
        />
      );
    },
    [showModal, applicationFileId, closeModal, requestReceiptsDisbursementsForm, t]
  );

  const toggleFileVisibility = useCallback(
    async (isChecked: boolean, documentsPrefixCode: string) => {
      const response = await API.documentsGET(
        ...argumentifyDocumentsSearchCriteria({
          documentPrefixCodes: [documentsPrefixCode],
          maxResultCount: MAX_PAGE_SIZE,
        })
      );

      if (response?.items) {
        const documents = response?.items;
        for (const document of documents) {
          await API.documentsPUT(
            ...argumentifyUpdateDocument({
              ...document,
              id: document?.id as string,
              isVisible: isChecked,
              fileModifiedDate: moment(),
            })
          ).catch(() => genericMessage.error({}, t.VISIBILITY_CHANGE_ERROR));
        }
      }

      requestReceiptsDisbursementsForm(applicationFileId as string);
    },
    [applicationFileId, requestReceiptsDisbursementsForm, t.VISIBILITY_CHANGE_ERROR]
  );

  const requestFilingDateUpdate = useCallback(
    async (filingDate?: moment.Moment, documentPrefixCode?: string) => {
      const response = await API.filingDate2(
        applicationFileId as string,
        {
          id: initial?.id,
          date: filingDate,
          documentPrefixCode,
        } as ClientService.ReceiptsDisbursementsFilingDateUpdateDto
      ).catch(() => genericMessage.error({}, t.FILING_DATE_UPDATE_ERROR));

      if (response?.result === ClientService.Result.Successful) {
        requestReceiptsDisbursementsForm(applicationFileId as string);
        eventBus.dispatch(RECEIPTS_DISBURSEMENTS_FORM_CHANGE_EVENT);
      }
    },
    [applicationFileId, initial?.id, requestReceiptsDisbursementsForm, t.FILING_DATE_UPDATE_ERROR]
  );

  const handleFilingDateChange = useCallback(
    async (documentPrefixCode: string, title?: string) => {
      showModal(
        <FilingDateModal
          title={title}
          onOk={async (date) => {
            requestFilingDateUpdate(date, documentPrefixCode);
            closeModal();
          }}
          onCancel={closeModal}
        />
      );
    },
    [closeModal, requestFilingDateUpdate, showModal]
  );

  const handleUploadAndShare = useCallback(
    async (documentPrefixCode?: string, title?: string, hasFilingDateField?: boolean) => {
      showModal(
        <UploadAndShareModal
          title={title || t.UPLOAD}
          hasShareOption
          hasFilingDateField={hasFilingDateField}
          filingDateProps={{ required: true }}
          applicationFileId={applicationFileId}
          supportingFolderCode={DISCHARGE_FOLDER_CODE}
          prefixCodes={documentPrefixCode ? [documentPrefixCode] : undefined}
          newSupportingFileRowLayoutProps={{
            disabledPrefixInput: true,
            preselectedPrefixInput: true,
            disabledFoldersInput: true,
          }}
          isFlatLayout
          flatLayoutProps={{ hasExistingFiles: true, label: t.UPLOAD }}
          onOk={async (_, filingDate?: moment.Moment) => {
            if (hasFilingDateField) {
              requestFilingDateUpdate(filingDate, documentPrefixCode);
            }
            closeModal();
            requestReceiptsDisbursementsForm(applicationFileId as string);
            eventBus.dispatch(RECEIPTS_DISBURSEMENTS_FORM_CHANGE_EVENT);
          }}
          onCancel={closeModal}
        />
      );
    },
    [applicationFileId, closeModal, requestFilingDateUpdate, requestReceiptsDisbursementsForm, showModal, t.UPLOAD]
  );

  const handleVisibilityChange = useCallback(
    async (checked: boolean, documentPrefixCode: string) => {
      showModal(
        <ActionConfirmationModal
          title={t.VISIBILITY_CHANGE_TITLE}
          message={t.VISIBILITY_CHANGE_MESSAGE}
          okText={t.YES}
          cancelText={t.NO}
          onOk={() => {
            toggleFileVisibility(checked, documentPrefixCode);
            closeModal();
          }}
          onCancel={closeModal}
        />
      );
    },
    [closeModal, showModal, toggleFileVisibility, t]
  );

  const requestUpdateFileReceiptsDisbursementsForm = useCallback(
    async (values) => {
      setLoading(true);
      const response = await API.fileReceiptsDisbursementsFormPUT(applicationFileId as string, {
        id: initial?.id as string,
        ...values,
      }).catch(() => setLoading(false));
      setLoading(false);

      if (response?.result === ClientService.Result.Successful) {
        genericMessage.success(t.UPDATED_SUCCESSFULLY);
        requestReceiptsDisbursementsForm(applicationFileId as string);
        eventBus.dispatch(RECEIPTS_DISBURSEMENTS_FORM_CHANGE_EVENT);
      }
    },
    [applicationFileId, initial?.id, requestReceiptsDisbursementsForm, t.UPDATED_SUCCESSFULLY]
  );

  useEffect(() => {
    if (applicationFileId) requestReceiptsDisbursementsForm(applicationFileId);
  }, [applicationFileId, requestReceiptsDisbursementsForm]);

  const isInvolvencyTypeOfProposal = useMemo(
    () =>
      filingTypeEnum === ClientService.FilingTypeEnum.DivisionI ||
      filingTypeEnum === ClientService.FilingTypeEnum.DivisionII,
    [filingTypeEnum]
  );

  const isInsolvencyDivisionIIOrSummary = useMemo(
    () =>
      filingTypeEnum === ClientService.FilingTypeEnum.DivisionII ||
      filingTypeEnum === ClientService.FilingTypeEnum.Summary,
    [filingTypeEnum]
  );

  return (
    <Spin spinning={loading}>
      <div className="ReceiptsAndDisbursements">
        <ReceiptsAndDisbursementsStatusForm disableAllFields={disableAllFields} />
        <Divider style={{ marginTop: 0 }} />

        {isInvolvencyTypeOfProposal && (
          <>
            <Row>
              <Col span={24}>
                <FormSectionTitle titleText={t.RECEIPTS_DISBURSEMENTS_30_DAYS_NOTICE} style={{ paddingBottom: 12 }} />
              </Col>
              <DataItem
                colSpan={24}
                label={t.RECEIPTS_DISBURSEMENTS_30_DAYS_NOTICE}
                layout="vertical"
                value={{ value: initial?.thirtyDaysNotice ? t.YES : t.NO, size: 'small', color: 'red' }}
              />
            </Row>

            <UploadSection
              sectionTitle={t.RECEIPTS_DISBURSEMENTS_PROPOSAL_COMPLETION}
              type={['extra-switch']}
              label={t.RECEIPTS_DISBURSEMENTS_CERTIFICATE_FULL_PERFORMANCE}
              buttonProps={{
                onClick: () =>
                  !initial?.isCertificateOfFullPerformanceUploaded || initial?.certificateOfFullPerformanceFilingDate
                    ? handleUploadAndShare(
                        DISCHARGE_FULL_PERFORMANCE_CERTIFICATE_PREFIX,
                        t.RECEIPTS_DISBURSEMENTS_CERTIFICATE_FULL_PERFORMANCE
                      )
                    : handleFilingDateChange(
                        DISCHARGE_FULL_PERFORMANCE_CERTIFICATE_PREFIX,
                        t.RECEIPTS_DISBURSEMENTS_CERTIFICATE_FULL_PERFORMANCE_FILING
                      ),
              }}
              link={
                initial?.isCertificateOfFullPerformanceUploaded &&
                (convertDateToReadable(initial?.certificateOfFullPerformanceFilingDate) || t.UPLOADED)
              }
              switchProps={{
                checked: initial?.isCertificateOfFullPerformanceShared,
                onClick: (checked) => handleVisibilityChange(checked, DISCHARGE_FULL_PERFORMANCE_CERTIFICATE_PREFIX),
                disabled: !initial?.isCertificateOfFullPerformanceUploaded,
              }}
              disabled={disableAllFields}
            />
          </>
        )}

        <UploadSection
          sectionTitle={t.RECEIPTS_DISBURSEMENTS_FINAL_SRD}
          type={['extra-switch']}
          label={t.RECEIPTS_DISBURSEMENTS_FINAL_SRD}
          buttonProps={{
            onClick: () =>
              !initial?.isSRDReportUploaded || initial?.srdReportFilingDate
                ? handleUploadForReview(DISCHARGE_SRD_REPORT_PREFIX, t.RECEIPTS_DISBURSEMENTS_SRD_REPORT)
                : handleFilingDateChange(DISCHARGE_SRD_REPORT_PREFIX, t.RECEIPTS_DISBURSEMENTS_SRD_REPORT_FILING),
          }}
          link={initial?.isSRDReportUploaded && (convertDateToReadable(initial?.srdReportFilingDate) || t.UPLOADED)}
          switchProps={{
            checked: initial?.isSRDReportShared,
            onClick: (checked) => handleVisibilityChange(checked, DISCHARGE_SRD_REPORT_PREFIX),
            disabled: !initial?.isSRDReportUploaded,
          }}
          disabled={disableAllFields}
        />

        {isInsolvencyDivisionIIOrSummary && (
          <UploadSection
            sectionTitle={t.RECEIPTS_DISBURSEMENTS_FINAL_DIVIDENTS_NOTICES}
            type={['extra-switch']}
            label={
              isInvolvencyTypeOfProposal
                ? t.RECEIPTS_DISBURSEMENTS_NOTICE_LABEL_PROPOSAL
                : t.RECEIPTS_DISBURSEMENTS_NOTICE_LABEL_SUMMARY
            }
            buttonProps={{
              onClick: () =>
                isInvolvencyTypeOfProposal
                  ? handleUploadAndShare(DISCHARGE_FORM_58, t.RECEIPTS_DISBURSEMENTS_FORM_58_FILING, true)
                  : handleUploadAndShare(DISCHARGE_FORM_15, t.RECEIPTS_DISBURSEMENTS_FORM_15_FILING, true),
            }}
            link={
              (isInvolvencyTypeOfProposal ? initial?.isForm58Uploaded : initial?.isForm15Uploaded) &&
              (convertDateToReadable(
                isInvolvencyTypeOfProposal ? initial?.form58FilingDate : initial?.form15FilingDate
              ) ||
                t.UPLOADED)
            }
            switchProps={{
              checked: isInvolvencyTypeOfProposal ? initial?.isForm58Shared : initial?.isForm15Shared,
              onClick: (checked) =>
                handleVisibilityChange(checked, isInvolvencyTypeOfProposal ? DISCHARGE_FORM_58 : DISCHARGE_FORM_15),
              disabled: isInvolvencyTypeOfProposal ? !initial?.isForm58Uploaded : !initial?.isForm15Uploaded,
            }}
            colSpan={24}
            disabled={disableAllFields}
          />
        )}

        {isInvolvencyTypeOfProposal && (
          <UploadSection
            sectionTitle={t.RECEIPTS_DISBURSEMENTS_DIVIDENT_CHECKLIST}
            label={t.RECEIPTS_DISBURSEMENTS_DIVIDENT_CHECKLIST}
            hasDivider={false}
            buttonProps={{
              onClick: () =>
                handleUploadForReview(DISCHARGE_DIVIDENT_CHECKLIST, t.RECEIPTS_DISBURSEMENTS_DIVIDENT_CHECKLIST),
            }}
            link={initial?.isDividendChecklistUploaded && t.UPLOADED}
            disabled={disableAllFields}
          />
        )}

        <UploadSection
          sectionTitle={t.RECEIPTS_DISBURSEMENTS_SRD_CHECKLIST}
          label={t.RECEIPTS_DISBURSEMENTS_SRD_CHECKLIST}
          buttonProps={{
            onClick: () => handleUploadForReview(DISCHARGE_SRD_CHECKLIST, t.RECEIPTS_DISBURSEMENTS_SRD_CHECKLIST),
          }}
          link={initial?.isSRDChecklistUploaded && t.UPLOADED}
          disabled={disableAllFields}
        />

        <UploadSection
          sectionTitle={t.RECEIPTS_DISBURSEMENTS_TRUSTEE_DISCHARGE}
          type={['extra-switch']}
          label={t.RECEIPTS_DISBURSEMENTS_TRUSTEE_DISCHARGE_LABEL}
          buttonProps={{
            onClick: () => handleUploadAndShare(DISCHARGE_FORM_16, t.RECEIPTS_DISBURSEMENTS_FORM_16_FILING, true),
          }}
          link={initial?.isForm16Uploaded && (convertDateToReadable(initial?.form16FilingDate) || t.UPLOADED)}
          switchProps={{
            checked: initial?.isForm16Shared,
            onClick: (checked) => handleVisibilityChange(checked, DISCHARGE_FORM_16),
            disabled: !initial?.isForm16Uploaded,
          }}
          colSpan={24}
          disabled={disableAllFields}
        />

        <Form form={form} layout="vertical" onFinish={requestUpdateFileReceiptsDisbursementsForm}>
          <Row gutter={20} align="bottom" style={{ paddingTop: 24 }}>
            <Col span={12}>
              <Form.Item name="isTrusteeDischarged" label={t.RECEIPTS_DISBURSEMENTS_TRUSTEE_DISCHARGE}>
                <Select
                  placeholder={t.SELECT}
                  size="large"
                  suffixIcon={<SelectSuffixIcon />}
                  disabled={disableAllFields}
                >
                  <Option value={true}>{t.YES}</Option>
                  <Option value={false}>{t.NO}</Option>
                </Select>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name="isFileClosed" label={t.RECEIPTS_DISBURSEMENTS_FILE_CLOSURE}>
                <Select
                  placeholder={t.SELECT}
                  size="large"
                  suffixIcon={<SelectSuffixIcon />}
                  disabled={disableAllFields}
                >
                  <Option value={true}>{t.YES}</Option>
                  <Option value={false}>{t.NO}</Option>
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row justify="end">
            <Col>
              <Form.Item>
                <Button kind="primary" htmlType="submit" disabled={disableAllFields}>
                  {t.SAVE}
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </div>
    </Spin>
  );
};

export default ReceiptsAndDisbursements;
