import { Icon, IIconProps } from '@fluentui/react/lib/Icon';

import styles from '../../../styles/style.module.scss';

const EditIcon = ({ ...props }: IIconProps) => (
  <Icon
    iconName="Edit"
    {...props}
    style={{
      fontSize: 16,
      color: styles.colorDanger,
      cursor: 'pointer',
    }}
  />
);

export default EditIcon;
