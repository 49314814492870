import { createContext } from 'react';

import { BankingPageViewEnum } from './types';

interface IContext {
  pageView: BankingPageViewEnum;
  setPageView: (pageView: BankingPageViewEnum) => void;
}

export const BankingContext = createContext<IContext>({
  pageView: BankingPageViewEnum.Banking,
  setPageView: () => {},
});
