import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Progress, Row, Col, Form, Checkbox, Tooltip } from 'antd';
import type { ColumnsType } from 'antd/lib/table';
import { PlusSquareOutlined, ExclamationCircleFilled } from '@ant-design/icons';
import { useQuery } from 'react-query';

import Table from '../../../components/Table/Table';
import Divider from '../../../components/Divider/Divider';
import Button from '../../../components/Button/Button';
import DebtorSearchModal, { IDebtorSearchResult } from '../../../components/DebtorSearchModal';
import TaskEditor from '../../ApplicationOverviewPage/TasksContent/TaskEditor/TaskEditor';
import SelectLookupDto from '../../../components/SelectLookupDto/SelectLookupDto';

import useLocale from '../../../hooks/useLocale';
import useModal from '../../../hooks/useModal';
import useMenu from '../../../hooks/useMenu';

import {
  HUNDRED_PERCENT,
  MISSING_NUMBER,
  DEFAULT_PAGE_SIZE,
  SORTING_ASC,
  SORTING_DESC,
} from '../../../constants/common';
import { FILE_STAGES_QUERY, TASK_STATUSES_QUERY, TASK_NAMES_QUERY } from '../../../constants/reactQuery';
import { NEW_TASK_SEARCH_PARAM } from '../../../constants/searchParams';
import { ROUTES } from '../../../constants/routes';
import genericMessage from '../../../utils/genericMessage';
import { paginationShowTotal } from '../../../utils/helpers';
import { ModifyGetAppTasksInput, argumentifyAssignedTasksSearchCriteria } from '../utils';
import { IAssignedTasksSearchCriteria } from '../types';
import { AuthorizeService } from '../../../components/Auth/AuthorizeService';
import { ClientService } from '../../../shared/api/ClientService';
import API from '../../../utils/api';
import { PdsUserClientAPI } from '../../../utils/identityServiceApi';

import './DashboardTable.scss';

interface IProps {
  filterText: string;
}

interface DataType extends ClientService.AppTaskDto {
  key: React.Key;
}

interface IForm {
  appTaskStatusId?: string;
}

interface IFilter {
  text: string;
  value: string;
}

const MAX_LENGTH_OF_ITEMS = 5;

const DashboardTable = ({ filterText }: IProps): JSX.Element => {
  const user = AuthorizeService.getCurrentUserInfo();
  const { t } = useLocale();
  const { showModal, closeModal } = useModal();
  const { isSuperAdmin } = useMenu();

  const navigate = useNavigate();
  const [form] = Form.useForm<IForm>();

  const [tableData, setTableData] = useState<DataType[]>();
  const [tableDataLoading, setTableDataLoading] = useState<boolean>(false);
  const [totalCount, setTotalCount] = useState<number>(0);

  const [teams, setTeams] = useState<IFilter[]>();
  const [agents, setAgents] = useState<IFilter[]>();

  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>();
  const [criteria, setCriteria] = useState<IAssignedTasksSearchCriteria>();

  const { data: fileStages } = useQuery([FILE_STAGES_QUERY], () => API.listFileStages());
  const { data: appTaskNames } = useQuery([TASK_NAMES_QUERY], () => API.listAppTaskNames(undefined));
  const { data: appTaskStatuses } = useQuery([TASK_STATUSES_QUERY], () => API.listAppTaskStatuses());

  const requestTeams = useCallback(async (userId: string, isSuperAdmin?: boolean) => {
    if (isSuperAdmin) {
      const allTeams = await API.listTeams(undefined);
      if (allTeams) setTeams(allTeams?.map((item) => ({ text: item?.name as string, value: item?.id as string })));
    } else {
      const agentInfo = await API.get(userId);
      if (agentInfo)
        setTeams(agentInfo?.teams?.map((item) => ({ text: item?.name as string, value: item?.id as string })));
    }
  }, []);

  const requestAgents = useCallback(async (userId: string, isSuperAdmin?: boolean) => {
    const response = await API.listAgents();

    if (response) {
      const agents = isSuperAdmin ? response : response?.filter((item) => item?.id === userId);
      setAgents(agents?.map((item) => ({ text: item?.name as string, value: item?.id as string })));
    }
  }, []);

  const requestUserTeams = useCallback(async (userId: string) => {
    const savedCriteria = await API.dashboardGetAppTasksInputGET();

    if (savedCriteria) {
      setCriteria(ModifyGetAppTasksInput(savedCriteria));
      return;
    }

    const response = await PdsUserClientAPI.getUser(userId);

    if (response) {
      setCriteria({
        skipCount: 0,
        maxResultCount: 10,
        appTaskStatuses: [ClientService.AppTaskStatusEnum.Pending],
        assignedAgentIds: [userId],
        assignedTeamIds: response?.teamIds,
      });
    }
  }, []);

  const requestAppTasks = useCallback(
    async (criteria) => {
      setTableDataLoading(true);
      const response = await API.listAssignedTasks(...argumentifyAssignedTasksSearchCriteria(criteria)).catch(
        (error) => {
          setTableDataLoading(false);
          genericMessage.error(error);
        }
      );

      if (response) {
        const responseWithKeys = response?.items?.map(
          (item) =>
            ({
              ...item,
              key: item?.id as React.Key,
            } as DataType)
        );

        setTotalCount(response?.totalCount || 0);
        setTableData(responseWithKeys);
        setSelectedRowKeys(undefined);
      }

      setTableDataLoading(false);

      if (response?.totalCount === 0) {
        genericMessage.error({}, t.NO_TASKS_ARE_FOUND_WITH_THE_SPECIFIED_CRITERIA);
      }
    },
    [t.NO_TASKS_ARE_FOUND_WITH_THE_SPECIFIED_CRITERIA]
  );

  const handleShowTaskEditor = useCallback(
    (task?: DataType) => {
      showModal(
        <TaskEditor
          onNext={closeModal}
          onCancel={closeModal}
          fileId={task?.fileId as string}
          taskId={task?.id}
          fileStageId={task?.fileStageId}
          showFileButton={!!task?.fileId}
        />
      );
    },
    [showModal, closeModal]
  );

  const columns: ColumnsType<DataType> = [
    {
      title: t.DASHBOARD_TABLE_FILE_NAME,
      dataIndex: 'fileName',
      key: 'fileName',
      render: (text: string, item: DataType) => (
        <Row align="middle" gutter={10} wrap={false}>
          <Col>{item.isPriority && <ExclamationCircleFilled className="DashboardTable__exclamation-icon" />}</Col>
          <Col>{text}</Col>
        </Row>
      ),
      sorter: true,
      sortOrder: criteria?.sorting?.includes('fileName')
        ? criteria?.sorting?.includes('asc')
          ? 'ascend'
          : 'descend'
        : undefined,
      width: '15%',
    },
    {
      title: t.DASHBOARD_TABLE_ESTATE,
      dataIndex: 'estateNumber',
      key: 'estateNumber',
      sorter: true,
      sortOrder: criteria?.sorting?.includes('estateNumber')
        ? criteria?.sorting?.includes('asc')
          ? 'ascend'
          : 'descend'
        : undefined,
      width: '10%',
    },
    {
      title: t.DASHBOARD_TABLE_OPEN_DAYS,
      dataIndex: 'openDays',
      key: 'openDays',
      sorter: true,
      sortOrder: criteria?.sorting?.includes('openDays')
        ? criteria?.sorting?.includes('asc')
          ? 'ascend'
          : 'descend'
        : undefined,
      width: '5%',
    },
    {
      title: t.DASHBOARD_TABLE_STAGE,
      dataIndex: 'fileStageName',
      key: 'fileStageName',
      render: (text: string, item: DataType) => {
        return (
          <Row align="middle" gutter={8}>
            <Col span={16}>
              <Progress
                success={{
                  percent:
                    ((fileStages?.find((stage) => stage?.id === item?.fileStageId)?.orderNumber ?? MISSING_NUMBER) /
                      MAX_LENGTH_OF_ITEMS) *
                    HUNDRED_PERCENT,
                }}
                showInfo={false}
              />
            </Col>
            <Col span={8}>{text}</Col>
          </Row>
        );
      },
      width: '35%',
      sorter: true,
      sortOrder: criteria?.sorting?.includes('fileStageName')
        ? criteria?.sorting?.includes('asc')
          ? 'ascend'
          : 'descend'
        : undefined,
      filters: fileStages?.map((item) => ({ text: item?.name as string, value: item?.enumValue as number })),
      filteredValue: criteria?.fileStages,
    },
    {
      title: t.DASHBOARD_TABLE_TASK_NAME,
      dataIndex: 'appTaskName',
      key: 'appTaskName',
      width: '15%',
      sorter: true,
      sortOrder: criteria?.sorting?.includes('appTaskName')
        ? criteria?.sorting?.includes('asc')
          ? 'ascend'
          : 'descend'
        : undefined,
      render: (text: string, item: DataType) => (
        <div
          className={item.fileId ? 'DashboardTable__task-hyperlink' : ''}
          onClick={() => {
            item.fileId && navigate(`${ROUTES.APPLICATION_OVERVIEW}/${item.fileId}`);
          }}
        >
          {text}
        </div>
      ),
      filters: appTaskNames?.map((item) => ({ text: item?.name as string, value: item?.enumValue as number })),
      filteredValue: criteria?.appTaskNames,
    },
    {
      title: t.DASHBOARD_TABLE_TASK_STATUS,
      dataIndex: 'appTaskStatus',
      key: 'appTaskStatus',
      width: '10%',
      render: (text: string, item: DataType) => (
        <div
          className={item.isPriority ? 'DashboardTable__priority-status' : ''}
          onClick={() => handleShowTaskEditor(item)}
          style={{ cursor: 'pointer', textDecoration: 'underline' }}
        >
          {text}
        </div>
      ),
      sorter: true,
      sortOrder: criteria?.sorting?.includes('appTaskStatus')
        ? criteria?.sorting?.includes('asc')
          ? 'ascend'
          : 'descend'
        : undefined,
      filters: appTaskStatuses?.map((item) => ({ text: item?.name as string, value: item?.enumValue as number })),
      filteredValue: criteria?.appTaskStatuses,
    },
    {
      title: t.DASHBOARD_TABLE_ASSIGNED,
      dataIndex: 'assignedName',
      key: 'assignedName',
      sorter: true,
      sortOrder: criteria?.sorting?.includes('assignedName')
        ? criteria?.sorting?.includes('asc')
          ? 'ascend'
          : 'descend'
        : undefined,
      width: '15%',
      filters: [...(agents || []), ...(teams || [])]?.sort((a, b) => (a?.text || '')?.localeCompare(b?.text || '')),
      filteredValue: [...(criteria?.assignedAgentIds || []), ...(criteria?.assignedTeamIds || [])],
    },
  ];

  const handleTableChange = useCallback(
    async (pagination: any, filters: any, sorter: any) => {
      const assignedAgentIds = filters?.assignedName?.filter((filteredValue: string) =>
        agents?.find((agent) => agent?.value === filteredValue)
      );
      const assignedTeamIds = filters?.assignedName?.filter((filteredValue: string) =>
        teams?.find((team) => team?.value === filteredValue)
      );

      const newCriteria = {
        ...criteria,
        maxResultCount: pagination.pageSize,
        skipCount: pagination.pageSize * (pagination.current - 1),
        fileStages: filters.fileStageName || undefined,
        appTaskStatuses: filters.appTaskStatus || undefined,
        assignedAgentIds,
        assignedTeamIds,
        appTaskNames: filters.appTaskName || undefined,
        sorting:
          sorter.field !== undefined && sorter.order !== undefined
            ? `${sorter.field} ${sorter.order === 'ascend' ? SORTING_ASC : SORTING_DESC}`
            : undefined,
      };

      setCriteria(newCriteria);

      await API.dashboardGetAppTasksInputPUT(newCriteria as ClientService.GetAppTasksInput);
    },
    [agents, criteria, teams]
  );

  const handleAddTask = useCallback(() => {
    showModal(
      <DebtorSearchModal
        onOk={(debtorSearchResult?: IDebtorSearchResult) => {
          closeModal();
          navigate(`${ROUTES.APPLICATION_OVERVIEW}/${debtorSearchResult?.fileId}?${NEW_TASK_SEARCH_PARAM}=true`);
        }}
        onCancel={closeModal}
      />
    );
  }, [closeModal, navigate, showModal]);

  const handleSubmit = useCallback(
    async ({ appTaskStatusId }) => {
      const selectNoPendingActionsTasks = selectedRowKeys?.filter(
        (key: React.Key) => !Boolean(tableData?.find((item) => item?.id === key)?.hasPendingTaskActions)
      );

      if (!selectNoPendingActionsTasks?.length) return;

      const response = await API.updateAll({
        ids: selectNoPendingActionsTasks as string[],
        appTaskStatusId,
      } as ClientService.AppTaskUpdateManyDto);

      if (response?.result === ClientService.Result.Successful) {
        genericMessage.success(t.TASKS_STATUS_IS_UPDATED);
        requestAppTasks(criteria);
      }
    },
    [criteria, requestAppTasks, selectedRowKeys, t.TASKS_STATUS_IS_UPDATED, tableData]
  );

  useEffect(() => {
    if (criteria) requestAppTasks({ ...criteria, filterText });
  }, [criteria, requestAppTasks, filterText]);

  useEffect(() => {
    if (user?.profile?.sub) {
      requestTeams(user?.profile?.sub, isSuperAdmin);
      requestAgents(user?.profile?.sub, isSuperAdmin);
    }
  }, [isSuperAdmin, requestAgents, requestTeams, user?.profile?.sub]);

  useEffect(() => {
    if (user?.profile?.sub && !criteria) {
      requestUserTeams(user?.profile?.sub);
    }
  }, [criteria, requestUserTeams, user?.profile?.sub]);

  return (
    <div className="DashboardTable">
      <Row>
        <Col span={12}>
          <Form form={form} onFinish={handleSubmit} layout="vertical">
            <Row gutter={20}>
              <Col span={12}>
                <Form.Item name="appTaskStatusId" noStyle>
                  <SelectLookupDto data={appTaskStatuses} style={{ width: '100%' }} />
                </Form.Item>
              </Col>
              <Col>
                <Form.Item noStyle>
                  <Button kind="primary" htmlType="submit">
                    {t.SAVE}
                  </Button>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Col>

        <Col span={12}>
          <Row align="middle" justify="end">
            <Col>
              <Button kind="link" height="short" onClick={handleAddTask} icon={<PlusSquareOutlined />}>
                {t.ADD_TASK}
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>

      <Divider style={{ marginTop: 10 }} />
      <Table
        dataSource={tableData}
        columns={columns}
        onChange={handleTableChange}
        pagination={{
          size: 'small',
          position: ['bottomRight'],
          defaultPageSize: DEFAULT_PAGE_SIZE,
          showSizeChanger: true,
          showTotal: paginationShowTotal,
          total: totalCount,
        }}
        loading={tableDataLoading}
        rowSelection={{
          selectedRowKeys,
          onChange: setSelectedRowKeys,
          renderCell: (value, record, index, originNode) =>
            !record?.hasPendingTaskActions ? (
              originNode
            ) : (
              <Tooltip title={t.TASK_HAS_PENDING_ACTIONS}>
                <Checkbox disabled className="DashboardTable__disabled-checkbox" />
              </Tooltip>
            ),
        }}
        className="DashboardTable__table-empty"
      />
    </div>
  );
};

export default DashboardTable;
