import { useState, useCallback, useEffect } from 'react';
import { Modal, Tabs, Form, Input, Select, Col, Row, Spin } from 'antd';
import ReactQuill from 'react-quill';

import Button from '../../../../components/Button/Button';
import AttachmentsTab, { AttachmentsFormProps } from '../../../../components/AttachmentsTab';
import SelectSuffixIcon from '../../../../components/SelectSuffixIcon/SelectSuffixIcon';

import useLocale from '../../../../hooks/useLocale';

import { UPLOAD_FILES_EVENT } from '../../../../constants/eventBus';
import { MAX_PAGE_SIZE } from '../../../../constants/common';

import { eventBus } from '../../../../utils/eventBus';
import genericMessage from '../../../../utils/genericMessage';
import { argumentifyTemplatesSearchCriteria } from '../../../../pages/AdministrationPage/Templates/utils';
import { AuthorizeService } from '../../../../components/Auth/AuthorizeService';
import { replaceLineBreaksWithBrTag } from '../../../../utils/helpers';

import { ClientService } from '../../../../shared/api/ClientService';
import API from '../../../../utils/api';

import './EmailEditor.scss';

interface IForm extends AttachmentsFormProps {
  messageTemplateId?: string;
  fromEmailAddress?: string;
  toEmailAddress?: string;
  subject?: string;
  body?: string;
}

interface IProps {
  data?: ClientService.AppEmailDto;
  attachingDocuments?: ClientService.DocumentDto[];
  fileId: string;
  claimFileId?: string;
  onNext?: () => void;
  onCancel?: () => void;
}

const EMAIL_TAB = 'email';
const ATTACHMENTS_TAB = 'attachments';

const EMAIL_TYPE_NAME = 'Email';

function EmailEditor({ data, fileId, claimFileId, onNext, onCancel, attachingDocuments }: IProps): JSX.Element {
  const { t } = useLocale();
  const { TabPane } = Tabs;
  const { Option } = Select;
  const [form] = Form.useForm<IForm>();
  const user = AuthorizeService.getCurrentUserInfo();

  const [templates, setTemplates] = useState<ClientService.AppEmailTemplateDto[]>();
  const [loading, setLoading] = useState<boolean>(false);

  const [tab, setTab] = useState<string>(EMAIL_TAB);

  const requestGetTemplates = useCallback(async () => {
    setLoading(true);
    const templates = await API.getMessageTemplateList(
      ...argumentifyTemplatesSearchCriteria({
        maxResultCount: MAX_PAGE_SIZE,
      })
    ).catch(() => setLoading(false));
    setLoading(false);

    if (templates?.items) {
      setTemplates(templates?.items?.filter((item) => item?.typeName === EMAIL_TYPE_NAME && item?.isActive));
    }
  }, []);

  const requestReceiverEmail = useCallback(() => {
    API.getFileEmailAddress(fileId).then((response) => {
      form.setFieldsValue({ toEmailAddress: response.email });
    });
  }, [fileId, form]);

  const requestCreateEmail = useCallback(
    async ({ messageTemplateId, toEmailAddress, subject, body, attachedDocumentIds }) => {
      setLoading(true);

      const response = await API.create({
        fileId,
        claimId: claimFileId,
        messageTemplateId,
        toEmailAddress,
        subject,
        body,
        attachedDocumentIds,
      } as ClientService.AppEmailCreateDto).catch(() => setLoading(false));

      setLoading(false);

      if (response?.result === ClientService.Result.Successful) {
        genericMessage.success(t.EMAIL_SENT_SUCCESS_MESSAGE);
        if (onNext) onNext();
      } else {
        genericMessage.error({}, response?.messages?.[0]?.body);
      }
    },
    [fileId, claimFileId, onNext, t.EMAIL_SENT_SUCCESS_MESSAGE]
  );

  const onFinish = useCallback(
    async (values) => {
      eventBus.dispatch(UPLOAD_FILES_EVENT, {
        onUploadSuccess: (attachedDocumentIds: string[]) => {
          requestCreateEmail({ ...values, attachedDocumentIds });
        },
      });
    },
    [requestCreateEmail]
  );

  const handleTabChange = useCallback((key) => form.validateFields().then(() => setTab(key)), [form]);

  const handleTemplateSelect = useCallback(
    (_, option) => {
      const selectedTemplate = templates?.find((item) => item?.id === option?.key);
      form.setFieldsValue({
        subject: selectedTemplate?.subject,
        body: selectedTemplate?.body,
      });
    },
    [templates, form]
  );

  useEffect(() => {
    requestGetTemplates();
    requestReceiverEmail();
  }, [requestGetTemplates, requestReceiverEmail]);

  return (
    <Modal
      destroyOnClose
      centered
      visible
      title={t.EMAIL_NEW}
      className="EmailEditor"
      width={900}
      footer={null}
      closable
      onCancel={onCancel}
    >
      <Spin spinning={loading}>
        <Form
          form={form}
          layout="vertical"
          initialValues={{
            messageTemplateId: data?.messageTemplateId,
            fromEmailAddress: data?.fromEmailAddress || user?.profile?.email,
            subject: data?.subject,
            body: data?.messageTemplateBody || replaceLineBreaksWithBrTag(data?.body) || '',
          }}
          className="EmailEditor__form"
          onFinish={onFinish}
        >
          <Tabs className="Tabs" onTabClick={handleTabChange} activeKey={tab}>
            <TabPane tab={t.EMAIL} key={EMAIL_TAB}>
              <Form.Item name="messageTemplateId" label={t.EMAIL_TEMPLATE}>
                <Select
                  onChange={handleTemplateSelect}
                  suffixIcon={<SelectSuffixIcon />}
                  size="large"
                  showSearch
                  filterOption={(input, option) => option?.title?.toLowerCase().includes(input.toLowerCase() || '')}
                >
                  {templates?.map((option) => (
                    <Option key={option?.id} value={option?.id} title={option?.name}>
                      {option?.name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>

              <Row gutter={12}>
                <Col span={12}>
                  <Form.Item name="fromEmailAddress" label={t.EMAIL_FROM}>
                    <Input disabled size="large" />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item name="toEmailAddress" label={t.EMAIL_TO}>
                    <Input disabled size="large" />
                  </Form.Item>
                </Col>
              </Row>

              <Form.Item name="subject" label={t.EMAIL_SUBJECT} rules={[{ required: true, message: t.REQUIRED_FIELD }]}>
                <Input size="large" />
              </Form.Item>

              <Form.Item name="body" label={t.EMAIL_BODY} rules={[{ required: true, message: t.REQUIRED_FIELD }]}>
                <ReactQuill
                  onChange={(content: any, delta: any, source: any, editor: any) => {
                    if (!editor.getText() || editor.getText() === '\n') {
                      form.setFieldsValue({ body: '' });
                    }
                  }}
                />
              </Form.Item>
            </TabPane>
            <TabPane tab={t.EMAIL_ATTACHMENTS} key={ATTACHMENTS_TAB} forceRender>
              <AttachmentsTab
                attachingDocuments={attachingDocuments}
                form={form}
                applicationFileId={fileId}
                draggerProps={{
                  totalFilesSizeLimitMB: 15,
                  singleFileSizeLimitMB: 5,
                }}
              />
            </TabPane>
          </Tabs>

          <Row gutter={12} justify="end" className="EmailEditor__footer">
            <Col>
              <Button kind="cancel" padding="large" onClick={onCancel}>
                {t.CANCEL}
              </Button>
            </Col>
            <Col>
              <Button kind="primary" padding="large" htmlType="submit">
                {t.SEND}
              </Button>
            </Col>
          </Row>
        </Form>
      </Spin>
    </Modal>
  );
}

export default EmailEditor;
