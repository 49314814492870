import React, { useContext, useEffect, useRef, useState, ReactNode, useCallback } from 'react';
import { Form, Input, InputNumber, Select } from 'antd';
import type { BaseSelectRef } from 'rc-select';

import { EditableContext } from './EditableRow';

import { IDataProps, InputTypeEnum, OptionType, IConfigurationType } from '../../types';
import { UnlimitedNumericRegexPattern } from '../../utils';

interface EditableCellProps {
  title: ReactNode;
  editable: boolean;
  children: ReactNode;
  dataIndex: keyof IDataProps<IConfigurationType>;
  record: IDataProps<IConfigurationType>;
  handleSave: (record: IDataProps<IConfigurationType>) => void;
  type?: InputTypeEnum;
  precision?: number;
  options?: OptionType[];
}

const EditableCell: React.FC<EditableCellProps> = ({
  title,
  editable,
  children,
  dataIndex,
  record,
  handleSave,
  type = InputTypeEnum.Text,
  options,
  precision,
  ...restProps
}) => {
  const inputTextRef = useRef<Input>(null);
  const inputNumberRef = useRef<HTMLInputElement>(null);
  const selectRef = useRef<BaseSelectRef>(null);
  const form = useContext(EditableContext);
  const { Option } = Select;

  const [editing, setEditing] = useState(false);

  let childNode = children;

  useEffect(() => {
    if (!editing) return;

    if (inputNumberRef.current) {
      inputNumberRef.current.focus();
    }

    if (inputTextRef.current) {
      inputTextRef.current.focus();
    }

    if (selectRef.current) {
      selectRef.current.focus();
    }
  }, [editing]);

  const toggleEdit = useCallback(() => {
    setEditing(!editing);
    form?.setFieldsValue({ [dataIndex]: record[dataIndex] });
  }, [dataIndex, editing, form, record]);

  const save = useCallback(async () => {
    if (form) {
      let values = await form.validateFields().catch(() => handleSave({ ...record, hasError: true }));

      if (values) {
        toggleEdit();
        handleSave({ ...record, ...values, hasError: false });
      }
    }
  }, [form, handleSave, record, toggleEdit]);

  if (editable) {
    childNode = editing ? (
      <>
        {(type === InputTypeEnum.Text || type === InputTypeEnum.UnlimitedNumeric) && (
          <Form.Item
            className="EditableTable__form-item"
            name={dataIndex}
            rules={
              type === InputTypeEnum.UnlimitedNumeric
                ? [{ pattern: UnlimitedNumericRegexPattern, message: '' }]
                : undefined
            }
          >
            <Input ref={inputTextRef} onPressEnter={save} onBlur={save} />
          </Form.Item>
        )}
        {(type === InputTypeEnum.Numeric || type === InputTypeEnum.Percentage || type === InputTypeEnum.Currency) && (
          <Form.Item className="EditableTable__form-item" name={dataIndex}>
            <InputNumber ref={inputNumberRef} onPressEnter={save} onBlur={save} precision={precision} min={0} />
          </Form.Item>
        )}
        {type === InputTypeEnum.Select && (
          <Form.Item className="EditableTable__form-item" name={dataIndex}>
            <Select ref={selectRef} onSelect={save} onBlur={save} allowClear>
              {options?.map((item) => (
                <Option key={item?.id} value={item?.name}>
                  {item?.name}
                </Option>
              ))}
            </Select>
          </Form.Item>
        )}
      </>
    ) : (
      <div className="EditableTable__editable-cell-value-wrap" onClick={toggleEdit}>
        {children}
      </div>
    );
  }

  return <td {...restProps}>{childNode}</td>;
};

export default EditableCell;
