import { ReactNode, useCallback, useEffect, useState, Key, useMemo } from 'react';
import { useParams, useLocation, useNavigate } from 'react-router-dom';
import { Table, Typography } from 'antd';
import moment from 'moment';
import { Icon } from '@fluentui/react/lib/Icon';
import { useQuery } from 'react-query';

import TaskEditor from '../TaskEditor/TaskEditor';
import TaskActionEditor from '../TaskActionEditor/TaskActionEditor';
import Breadcrumb from '../../../../components/Breadcrumb/Breadcrumb';
import PageSubtitle from '../../../../components/PageSubtitle/PageSubtitle';

import useLocale from '../../../../hooks/useLocale';
import useIsMounted from '../../../../hooks/useIsMounted';
import useModal from '../../../../hooks/useModal';
import { useApplicationFile } from '../../../ApplicationOverviewPage';

import { ROUTES } from '../../../../constants/routes';
import {
  TASKACTION_LIST_SIZE,
  DEFAULT_PAGE_SIZE,
  DATE_FORMAT2,
  OVERVIEW_LIST_SIZE,
  SORTING_DESC,
} from '../../../../constants/common';
import { paginationShowTotal } from '../../../../utils/helpers';
import { argumentifyTaskSearchCriteria, argumentifyTaskActionSearchCriteria } from '../utils';
import { ITaskSearchCriteria } from '../types';
import { TASK_ADDED_EVENT, NEW_TASK_EVENT } from '../../../../constants/eventBus';
import { NEW_TASK_SEARCH_PARAM } from '../../../../constants/searchParams';
import { AGENTS_QUERY, TASK_STATUSES_QUERY } from '../../../../constants/reactQuery';
import { eventBus } from '../../../../utils/eventBus';
import { removeHTMLTagsFromString } from '../../../../utils/helpers';

import { ClientService } from '../../../../shared/api/ClientService';
import API from '../../../../utils/api';

import './TaskActionTable.scss';
import styles from '../../../../styles/style.module.scss';

interface IProps {
  isHistoryView?: boolean;
}

interface ITaskRow extends ClientService.IAppTaskDto {
  actions?: ClientService.IAppTaskActionDto[];
}

interface ITask extends ClientService.AppTaskDto {
  assignedTo?: string;
}

interface ITaskAction extends ClientService.AppTaskActionDto {
  assignedTo?: string;
}

const TaskActionTable = ({ isHistoryView = true }: IProps): JSX.Element => {
  const { t } = useLocale();
  const { search, pathname } = useLocation();
  const navigate = useNavigate();
  const { Paragraph } = Typography;

  const isMounted = useIsMounted();
  const { showModal, closeModal } = useModal();
  const { applicationFileId } = useParams<{ applicationFileId: string }>();

  const { data } = useApplicationFile();
  const isDuplicate = useMemo(() => data?.isDuplicate, [data]);

  const [tasks, setTasks] = useState<ITask[]>();
  const [totalCount, setTotalCount] = useState<number>();
  const [tasksLoading, setTasksLoading] = useState<boolean>(true);

  const [actions, setActions] = useState<ITaskAction[]>();
  const [actionsLoading, setActionsLoading] = useState<boolean>(false);

  const [expandedRowKey, setExpandedRowKey] = useState<string>();

  const [taskCriteria, setTaskCriteria] = useState<ITaskSearchCriteria>({
    fileId: applicationFileId,
    appTaskStatuses: !isHistoryView ? [ClientService.AppTaskStatusEnum.Pending] : undefined,
    maxResultCount: isHistoryView ? DEFAULT_PAGE_SIZE : OVERVIEW_LIST_SIZE,
    sorting: `openDate ${SORTING_DESC}`,
    skipCount: 0,
  });

  const { data: agents } = useQuery([AGENTS_QUERY], () => API.listAgents());
  const { data: taskStatuses } = useQuery([TASK_STATUSES_QUERY], () => API.listAppTaskStatuses());

  const requestGetActions = useCallback(async (appTaskId: string) => {
    setActionsLoading(true);
    const response = await API.taskActionsGET(
      ...argumentifyTaskActionSearchCriteria({
        appTaskId,
        calledFromDashboard: false,
        maxResultCount: TASKACTION_LIST_SIZE,
      })
    ).catch(() => setActionsLoading(false));

    if (response?.items)
      setActions(
        response?.items?.map(
          (item) => ({ ...item, assignedTo: item?.assignedAgentName || item?.assignedTeamName } as ITaskAction)
        )
      );
    setActionsLoading(false);
  }, []);

  const requestGetTasks = useCallback(async () => {
    setTasksLoading(true);
    const response = await API.tasksGET(...argumentifyTaskSearchCriteria(taskCriteria)).catch(() =>
      setTasksLoading(false)
    );

    if (response?.items) {
      setTasks(
        response?.items?.map(
          (item) => ({ ...item, assignedTo: item?.assignedAgentName || item?.assignedTeamName } as ITask)
        )
      );
    }
    setTotalCount(response?.totalCount || 0);
    setTasksLoading(false);
  }, [taskCriteria]);

  const handleOnExpand = useCallback((expanded: boolean, record: ITaskRow) => {
    if (expanded && record?.id) {
      setExpandedRowKey(record?.id);
    } else {
      setExpandedRowKey(undefined);
    }
  }, []);

  const removeNewTaskSearchParams = useCallback(() => {
    const searchParams = new URLSearchParams(search);
    if (searchParams.has(NEW_TASK_SEARCH_PARAM)) {
      searchParams.delete(NEW_TASK_SEARCH_PARAM);
      navigate(pathname);
    }
  }, [navigate, pathname, search]);

  const handleShowTaskEditor = useCallback(
    (taskId?: string) => {
      showModal(
        <TaskEditor
          onNext={() => {
            closeModal();
            requestGetTasks();
            removeNewTaskSearchParams();
          }}
          onCancel={() => {
            closeModal();
            removeNewTaskSearchParams();
          }}
          fileId={applicationFileId as string}
          taskId={taskId}
          fileStageId={data?.fileStage?.id}
        />
      );
    },
    [showModal, applicationFileId, data?.fileStage?.id, closeModal, requestGetTasks, removeNewTaskSearchParams]
  );

  const handleShowNewTaskEditor = useCallback(() => {
    handleShowTaskEditor();
  }, [handleShowTaskEditor]);

  const handleShowTaskActionEditor = useCallback(
    ({ taskId, actionId, isDisabled }) =>
      showModal(
        <TaskActionEditor
          onNext={() => {
            closeModal();
            requestGetActions(taskId);
          }}
          onCancel={closeModal}
          taskId={taskId}
          actionId={actionId}
          fileId={applicationFileId as string}
          isDisabled={isDisabled}
        />
      ),
    [showModal, closeModal, applicationFileId, requestGetActions]
  );

  const handleTableChange = useCallback(
    (pagination: any, filters: any, sorter: any) => {
      const sortingOrder = sorter.order === 'ascend' ? 'asc' : 'desc';
      setTaskCriteria({
        ...taskCriteria,
        skipCount:
          pagination?.pageSize && pagination?.current
            ? pagination?.pageSize * (pagination?.current - 1)
            : taskCriteria.skipCount,
        sorting: sorter.field !== undefined ? `${sorter.field} ${sortingOrder}` : '',
        maxResultCount: pagination?.pageSize || taskCriteria.maxResultCount,
      });
    },
    [taskCriteria]
  );

  useEffect(() => {
    if (isMounted.current && !tasks) {
      requestGetTasks();
    }
  }, [isMounted, tasks, requestGetTasks]);

  useEffect(() => {
    requestGetTasks();
  }, [taskCriteria, requestGetTasks]);

  useEffect(() => {
    if (expandedRowKey) requestGetActions(expandedRowKey);
  }, [expandedRowKey, requestGetActions]);

  useEffect(() => {
    eventBus.on(TASK_ADDED_EVENT, requestGetTasks);

    return () => {
      eventBus.remove(TASK_ADDED_EVENT, requestGetTasks);
    };
  }, [requestGetTasks]);

  useEffect(() => {
    eventBus.on(NEW_TASK_EVENT, () => handleShowNewTaskEditor());

    return () => {
      eventBus.remove(NEW_TASK_EVENT, () => handleShowNewTaskEditor());
    };
  }, [handleShowNewTaskEditor]);

  const expandedRowRender = (row: ITaskRow): ReactNode => {
    const isTaskActive =
      taskStatuses?.find((item) => item?.id === row?.appTaskStatusId)?.enumValue ===
      ClientService.AppTaskStatusEnum?.Pending;

    const taskStyles = {
      color: isTaskActive ? styles.colorDanger : styles.colorSecondary,
      cursor: 'pointer',
    };

    const nestedColumns = [
      {
        title: <div style={{ width: 48 }}>{t.DATE}</div>,
        dataIndex: 'openDate',
        key: 'openDate',
        render: (date: string) => moment(date).format(DATE_FORMAT2),
        width: 92,
        sorter: (a: ClientService.IAppTaskActionDto, b: ClientService.IAppTaskActionDto) =>
          moment(a.openDate).unix() - moment(b.openDate).unix(),
      },
      {
        title: () => <div style={{ width: 68 }}>{t.TYPE}</div>,
        dataIndex: 'appTaskActionName',
        key: 'appTaskActionName',
        width: 100,
        sorter: (a: ClientService.IAppTaskActionDto, b: ClientService.IAppTaskActionDto) =>
          (a?.appTaskActionName as string)?.localeCompare(b?.appTaskActionName as string) || -1,
      },
      {
        title: <div style={{ width: 362 }}>{t.DESCRIPTION}</div>,
        dataIndex: 'description',
        key: 'description',
        width: 400,
        render: (text: string) => (
          <Paragraph ellipsis={{ rows: 2, expandable: false }} style={{ width: 400 }}>
            <div dangerouslySetInnerHTML={{ __html: removeHTMLTagsFromString(text) }}></div>
          </Paragraph>
        ),
        sorter: (a: ClientService.IAppTaskActionDto, b: ClientService.IAppTaskActionDto) =>
          (a?.description as string)?.localeCompare(b?.description as string) || -1,
      },
      {
        title: t.CREATED_BY,
        dataIndex: 'createdByName',
        key: 'createdByName',
        width: 200,
        sorter: (a: ClientService.IAppTaskActionDto, b: ClientService.IAppTaskActionDto) =>
          (a?.createdByName as string)?.localeCompare(b?.createdByName as string) || -1,
      },
      {
        title: t.ASSIGNED_TO,
        dataIndex: 'assignedName',
        key: 'assignedName',
        width: 200,
        sorter: (a: ClientService.IAppTaskActionDto, b: ClientService.IAppTaskActionDto) =>
          (a?.assignedName as string)?.localeCompare(b?.assignedName as string) || -1,
      },
      {
        title: t.STATUS,
        dataIndex: 'appTaskActionStatusName',
        key: 'appTaskActionStatusName',
        width: 150,
        sorter: (a: ClientService.IAppTaskActionDto, b: ClientService.IAppTaskActionDto) =>
          (a?.appTaskActionStatusName as string)?.localeCompare(b?.appTaskActionStatusName as string) || -1,
      },
      {
        title: () =>
          !isDuplicate && (
            <div
              className="TaskActionTable__add-action"
              onClick={() => (isTaskActive ? handleShowTaskActionEditor({ taskId: row?.id as string }) : () => {})}
              style={{ width: 118, ...taskStyles }}
            >
              <div className="TaskActionTable__add-action--title">Add Action</div>
              <Icon
                iconName="Add"
                className="TaskActionTable__plus-icon"
                style={{ borderColor: taskStyles?.color, ...taskStyles }}
              />
            </div>
          ),
        key: 'operation',
        render: (record: ClientService.IAppTaskActionDto) =>
          !isDuplicate && (
            <div className="TaskActionTable__edit-btn">
              <Icon
                iconName={isTaskActive ? 'Edit' : 'View'}
                onClick={() =>
                  handleShowTaskActionEditor({
                    taskId: row?.id as string,
                    actionId: record?.id,
                    isDisabled: !isTaskActive,
                  })
                }
                className="TaskActionTable__edit-btn--icon"
                style={taskStyles}
              />
            </div>
          ),
        width: 150,
      },
    ];

    return (
      <Table
        columns={nestedColumns}
        dataSource={actions}
        rowKey={(record: ClientService.IAppTaskActionDto) => record?.id as string}
        pagination={false}
        rowClassName={(record, index) => (index % 2 === 0 ? 'table-row-dark' : 'table-row-light')}
      />
    );
  };

  const columns = [
    {
      title: <div style={{ width: 68 }}>{t.DATE}</div>,
      dataIndex: 'openDate',
      key: 'openDate',
      render: (date: string) => moment(date).format(DATE_FORMAT2),
      width: 100,
      sorter: true,
    },
    {
      title: () => <div style={{ width: 68 }}>{t.TYPE}</div>,
      dataIndex: 'appTaskName',
      key: 'appTaskName',
      width: 100,
      sorter: true,
    },
    {
      title: <div style={{ width: 362 }}>{t.DESCRIPTION}</div>,
      dataIndex: 'description',
      key: 'description',
      width: 400,
      sorter: true,
      render: (text: string) => (
        <Paragraph ellipsis={{ rows: 2, expandable: false }} style={{ width: 400 }}>
          <div dangerouslySetInnerHTML={{ __html: removeHTMLTagsFromString(text) }}></div>
        </Paragraph>
      ),
    },

    {
      title: t.CREATED_BY,
      dataIndex: 'createdByName',
      key: 'createdByName',
      render: (text: string, record: ITaskRow) => (
        <>{record?.createdByName || agents?.find((item) => item.id === record.createdById)?.name || 'System'}</>
      ),
      width: 200,
      sorter: true,
    },
    {
      title: t.ASSIGNED_TO,
      dataIndex: 'assignedName',
      key: 'assignedName',
      width: 200,
      sorter: true,
    },
    {
      title: t.STATUS,
      dataIndex: 'appTaskStatus',
      key: 'appTaskStatus',
      render: (status: string) => <span className="TaskActionTable__subtitle-status-value">{status}</span>,
      width: 150,
      sorter: true,
    },
    {
      title: () => <div style={{ width: 118 }}></div>,
      key: 'operation',
      render: (record: ITaskRow) =>
        !isDuplicate && (
          <div className="TaskActionTable__edit-btn">
            <Icon
              iconName="Edit"
              onClick={() => handleShowTaskEditor(record?.id)}
              className="TaskActionTable__edit-btn--icon"
            />
          </div>
        ),
      width: 150,
    },
  ];

  return (
    <>
      {isHistoryView && (
        <>
          <Breadcrumb
            data={[
              { link: ROUTES.DASHBOARD, title: t.DASHBOARD },
              { link: `${ROUTES.DEBTOR_SEARCH}`, title: t.DEBTOR_SEARCH },
              { link: `${ROUTES.APPLICATION_OVERVIEW}/${applicationFileId}`, title: t.APPLICATION_OVERVIEW },
              { title: t.TASKS_HISTORY },
            ]}
          />
          <PageSubtitle
            title={t.TASKS_HISTORY}
            buttonTitle={!isDuplicate && t.TASK_NEW}
            onButtonClick={handleShowNewTaskEditor}
          />
        </>
      )}
      <Table
        columns={columns}
        expandable={{
          expandedRowKeys: [expandedRowKey as Key],
          expandedRowRender,
          onExpand: handleOnExpand,
          expandIcon: (props) => (
            <Icon
              iconName={props.expanded ? 'ChevronUp' : 'ChevronRight'}
              onClick={(e) => props.onExpand(props.record, e)}
              className="TaskActionTable__clickable-icon"
            />
          ),
        }}
        dataSource={tasks}
        rowKey={(record: ITaskRow) => record.id as string}
        loading={tasksLoading || actionsLoading}
        className="TaskActionTable"
        onChange={handleTableChange}
        pagination={
          isHistoryView && {
            position: ['bottomLeft'],
            defaultPageSize: DEFAULT_PAGE_SIZE,
            showSizeChanger: true,
            total: totalCount,
            showTotal: paginationShowTotal,
          }
        }
      />
    </>
  );
};

export default TaskActionTable;
