import { ClientService } from '../../../shared/api/ClientService';

export type TaskSearchResult =
  ClientService.PagedResultDto_1OfOfAppTaskDtoAndContractsAnd_0AndCulture_neutralAndPublicKeyToken_null;

export type TaskActionSearchResult =
  ClientService.PagedResultDto_1OfOfAppTaskActionDtoAndContractsAnd_0AndCulture_neutralAndPublicKeyToken_null;

export interface ITaskSearchCriteria {
  appTaskId?: string;
  appTaskIds?: string[];
  filterText?: string;
  description?: string;
  openDateMin?: moment.Moment;
  openDateMax?: moment.Moment;
  closeDateMin?: moment.Moment;
  closeDateMax?: moment.Moment;
  assignedAgentIds?: string[];
  assignedTeamIds?: string[];
  appTaskNames?: ClientService.AppTaskNameEnum[];
  appTaskStatuses?: ClientService.AppTaskStatusEnum[];
  fileId?: string;
  fileStages?: ClientService.FileStageEnum[];
  fileProcesses?: ClientService.FileProcessEnum[];
  sorting?: string;
  skipCount?: number;
  maxResultCount?: number;
}

export interface ITaskActionSearchCriteria {
  filterText?: string;
  filterSubjectText?: string;
  fileId?: string;
  description?: string;
  followUpDateMin?: moment.Moment;
  followUpDateMax?: moment.Moment;
  openDateMin?: moment.Moment;
  openDateMax?: moment.Moment;
  closeDateMin?: moment.Moment;
  closeDateMax?: moment.Moment;
  assignedAgentId?: string;
  assignedTeamId?: string;
  appTaskId?: string;
  appTaskIds?: string[];
  appTaskActionIds?: string[];
  appTaskActionId?: string;
  appTaskActionNameId?: string;
  appTaskActionStatusId?: string;
  appTaskActionStatus?: ClientService.AppTaskActionStatusEnum;
  teamTypes?: ClientService.TeamTypeEnum[];
  appTaskActionNames?: ClientService.AppTaskActionNameEnum[];
  calledFromDashboard?: boolean | undefined;
  sorting?: string;
  skipCount?: number;
  maxResultCount?: number;
}

export enum AssignTypeEnum {
  Users = 0,
  Team = 1,
}
