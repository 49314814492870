import { Col, Row, Form } from 'antd';
import { Icon } from '@fluentui/react/lib/Icon';
import { useQuery } from 'react-query';

import SourceDetailsSelect from '../components/SourceDetailsSelect';
import SelectLookupDto from '../components/SelectLookupDto';

import useLocale from '../../../../hooks/useLocale';
import { USE_QUERY_OPTIONS, REFERRAL_SOURCE_TYPES_QUERY, INTAKE_SOURCES_QUERY } from '../../../../constants/reactQuery';
import API from '../../../../utils/api';

import '../DebtorProfileContent.scss';
import { ClientService } from '../../../../shared/api/ClientService';

const INACTIVE_REFERRAL_SOURCE = 'False';

interface IProps {
  disabled?: boolean;
}

const SourceInformation = ({ disabled = false }: IProps) => {
  const { t } = useLocale();

  const { data: referralSourceTypes } = useQuery(
    [REFERRAL_SOURCE_TYPES_QUERY],
    () => API.listReferralSourceTypes(),
    USE_QUERY_OPTIONS
  );

  const { data: intakeSources } = useQuery([INTAKE_SOURCES_QUERY], () => API.listIntakeSources(), USE_QUERY_OPTIONS);

  return (
    <Form.Item noStyle shouldUpdate>
      {({ getFieldValue }) => {
        const referralSources = getFieldValue('referralSources') as ClientService.ReferralSourceDto[];
        const notUsedSources = referralSourceTypes?.filter(
          (type) =>
            !referralSources?.find((source) => source.referralSourceTypeId === type.id) &&
            type.description !== INACTIVE_REFERRAL_SOURCE
        );

        return (
          <Row gutter={20} align="top">
            <Form.List name="referralSources">
              {(sources, { add, remove }) => (
                <Col span={8}>
                  <Row gutter={20}>
                    <Col span={22}>
                      <Form.Item
                        name="referralSourcesSelector"
                        label={t.DEBTOR_PROFILE_HEAR_ABOUT_US}
                        required
                        rules={[
                          {
                            validator: async () =>
                              referralSources && referralSources?.length
                                ? Promise.resolve()
                                : Promise.reject(t.REQUIRED_FIELD),
                          },
                        ]}
                      >
                        <SelectLookupDto
                          data={notUsedSources}
                          onSelect={(referralSourceTypeId: string) => add({ referralSourceTypeId })}
                          showSearch
                          filterOption={(input, option) =>
                            option?.title?.toLowerCase().includes(input.toLowerCase() || '')
                          }
                          disabled={disabled}
                        />
                      </Form.Item>
                    </Col>
                  </Row>

                  <>
                    {sources?.map((row, index) => (
                      <Row key={`referral-source-${index}`} gutter={20} align="bottom">
                        <Col span={11}>
                          <Form.Item name={[row.name, 'referralSourceTypeId']}>
                            <SelectLookupDto suffixIcon={null} data={referralSourceTypes} disabled />
                          </Form.Item>
                        </Col>

                        <Col span={11}>
                          <Form.Item noStyle shouldUpdate>
                            <SourceDetailsSelect
                              referralSourceTypeId={getFieldValue([
                                'referralSources',
                                row.name,
                                'referralSourceTypeId',
                              ])}
                              name={[row.name, 'referralSourceDetailsId']}
                              disabled={disabled}
                            />
                          </Form.Item>
                        </Col>
                        <Col span={!disabled ? 1 : 0}>
                          <Form.Item>
                            <Icon
                              iconName="Delete"
                              onClick={() => remove(row.name)}
                              className="DebtorProfileContent__icon"
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                    ))}
                  </>
                </Col>
              )}
            </Form.List>
            <Col span={8} pull={1} style={{ paddingLeft: 30 }}>
              <Form.Item
                label={t.DEBTOR_PROFILE_INTAKE_SOURCE}
                name="intakeSourceId"
                required
                rules={[{ required: true, message: t.REQUIRED_FIELD }]}
              >
                <SelectLookupDto data={intakeSources} disabled={disabled} />
              </Form.Item>
            </Col>
          </Row>
        );
      }}
    </Form.Item>
  );
};

export default SourceInformation;
