import { ClientService } from '../../../shared/api/ClientService';
import { IOfficeLocationsSearchCriteria } from './types';

export const argumentifyOfficeLocationsSearchCriteria = ({
  filterText,
  nearbyLatitude,
  nearbyLongitude,
  includeInactive,
  isActive,
  sorting,
  skipCount,
  maxResultCount,
}: IOfficeLocationsSearchCriteria) =>
  [filterText, nearbyLatitude, nearbyLongitude, includeInactive, isActive, sorting, skipCount, maxResultCount] as const;

export const composeAddress = (item?: ClientService.OfficeLocationDto): string => {
  if (!item) return '';

  const addressParts: string[] = [];

  if (item.streetNumber) addressParts.push(item.streetNumber);
  if (item.streetName) addressParts.push(item.streetName);
  if (item.unit) addressParts.push(item.unit);
  if (item.city) addressParts.push(item.city);
  if (item.province) addressParts.push(item.province);
  if (item.postalCode) addressParts.push(item.postalCode);
  if (item.country) addressParts.push(item.country);

  return addressParts.join(', ');
};
