import { useCallback, useEffect, useState } from 'react';
import { Modal, Col, Row, Spin } from 'antd';
import moment from 'moment';
import { Icon } from '@fluentui/react/lib/Icon';

import Button from '../../../../components/Button/Button';
import BlockWithLabel from '../../../../components/BlockWithLabel/BlockWithLabel';
import Divider from '../../../../components/Divider/Divider';

import useLocale from '../../../../hooks/useLocale';
import { DATE_FORMAT2 } from '../../../../constants/common';
import { getVerificationStatusIconDetails } from '../utils';
import { eventBus } from '../../../../utils/eventBus';
import { VERIFICATION_CHANGE_EVENT } from '../../../../constants/eventBus';
import { ClientService } from '../../../../shared/api/ClientService';
import API from '../../../../utils/api';

import styles from '../../../../styles/style.module.scss';
import './ReviewStatusModal.scss';

interface IProps {
  documentId?: string;
  onClose?: () => void;
  onDelete?: () => void;
}

function ReviewStatusModal({ documentId, onClose, onDelete }: IProps): JSX.Element {
  const { t } = useLocale();

  const [verificationDetails, setVerificationDetails] = useState<ClientService.DocumentVerificationDetailDto>();
  const [loading, setLoading] = useState<boolean>(false);

  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
  const [deleting, setDeleting] = useState<boolean>(false);

  const requestVerification = useCallback(async () => {
    if (documentId) {
      setLoading(true);
      const response = await API.documentVerificationsGET(documentId as string).catch(() => setLoading(false));
      if (response) setVerificationDetails(response);
      setLoading(false);
    }
  }, [documentId]);

  const requestVerificationDelete = useCallback(async () => {
    if (documentId) {
      setDeleting(true);
      await API.documentVerificationsDELETE(documentId as string).catch(() => setDeleting(false));

      setDeleting(false);
      eventBus.dispatch(VERIFICATION_CHANGE_EVENT);
      if (onDelete) onDelete();
    }
  }, [documentId, onDelete]);

  useEffect(() => {
    if (!verificationDetails) requestVerification();
  }, [verificationDetails, requestVerification]);

  return (
    <Modal
      destroyOnClose
      centered
      visible
      title={t.DOCUMENTS_REVIEW_STATUS}
      className="ReviewStatusModal"
      width={700}
      footer={null}
      closable
      onCancel={onClose}
    >
      <Spin spinning={loading || deleting}>
        <Row>
          <Col span={12}>
            <BlockWithLabel label={t.DOCUMENTS_FILENAME} layout="horizontal" labelSpan={8} childrenSpan={16}>
              {verificationDetails?.documentName}
            </BlockWithLabel>
          </Col>
          <Col span={12}>
            <BlockWithLabel label={t.DOCUMENTS_COMMENTS} layout="horizontal" labelSpan={8} childrenSpan={16}>
              {verificationDetails?.comments}
            </BlockWithLabel>
          </Col>
          <Col span={24}>
            <BlockWithLabel
              label={t.DOCUMENTS_URGENT}
              layout="horizontal"
              labelSpan={4}
              childrenSpan={8}
              childrenStyle={{
                color: verificationDetails?.isUrgent ? styles.colorDanger : styles.colorPrimary,
                fontWeight: verificationDetails?.isUrgent ? 600 : 400,
              }}
            >
              {verificationDetails?.isUrgent ? t.YES : t.NO}
            </BlockWithLabel>
          </Col>
          <Col span={24}>
            <BlockWithLabel label={t.DOCUMENTS_DUE_DATE} layout="horizontal" labelSpan={4} childrenSpan={8}>
              {moment(verificationDetails?.dueDate).format(DATE_FORMAT2)}
            </BlockWithLabel>
          </Col>
        </Row>

        <Divider />

        <Row>
          <Col span={8}>
            <div className="ReviewStatusModal__label">{t.DOCUMENTS_REVIEWERS}</div>
            {verificationDetails?.reviewers?.map((item, index) => (
              <Row key={`reviewerDetails-${index}`} align="middle" gutter={6}>
                <Col>
                  <Icon
                    iconName={getVerificationStatusIconDetails(item?.verificationResult)?.iconName}
                    style={{ color: getVerificationStatusIconDetails(item.verificationResult)?.color }}
                  />
                </Col>
                <Col>{item.agentName}</Col>
              </Row>
            ))}
          </Col>
          <Col span={8}>
            <div className="ReviewStatusModal__label">{t.DOCUMENTS_APPROVERS}</div>
            {verificationDetails?.approvers?.map((item, index) => (
              <Row key={`approverDetails-${index}`} align="middle" gutter={6}>
                <Col>
                  <Icon
                    iconName={getVerificationStatusIconDetails(item?.verificationResult)?.iconName}
                    style={{ color: getVerificationStatusIconDetails(item.verificationResult)?.color }}
                  />
                </Col>
                <Col>{item.agentName}</Col>
              </Row>
            ))}
          </Col>
          <Col span={8}>
            <div className="ReviewStatusModal__label">{t.DOCUMENTS_SIGNEES}</div>
            {verificationDetails?.signees?.map((item, index) => (
              <Row key={`signeeDetails-${index}`} align="middle" gutter={6}>
                <Col>
                  <Icon
                    iconName={getVerificationStatusIconDetails(item?.verificationResult)?.iconName}
                    style={{ color: getVerificationStatusIconDetails(item.verificationResult)?.color }}
                  />
                </Col>
                <Col>{item.agentName}</Col>
              </Row>
            ))}
          </Col>
        </Row>

        <Row gutter={12} justify="end" className="ReviewStatusModal__buttons-container">
          {(verificationDetails?.documentVerificationStatus === ClientService.DocumentVerificationStatusEnum.Pending ||
            verificationDetails?.documentVerificationStatus ===
              ClientService.DocumentVerificationStatusEnum.PartiallyCompleted) && (
            <Col>
              <Button kind="cancel" padding="large" onClick={() => setShowDeleteModal(true)}>
                {t.DELETE}
              </Button>
            </Col>
          )}

          <Col>
            <Button kind="primary" padding="large" onClick={onClose}>
              {t.CLOSE}
            </Button>
          </Col>
        </Row>
      </Spin>
      <Modal
        title={t.CONFIRM_DELETE}
        visible={showDeleteModal}
        centered
        onOk={requestVerificationDelete}
        footer={
          <Row gutter={20} justify="end">
            <Col>
              <Button kind="cancel" onClick={() => setShowDeleteModal(false)}>
                {t.CANCEL}
              </Button>
            </Col>
            <Col>
              <Button
                kind="primary"
                onClick={() => {
                  setShowDeleteModal(false);
                  requestVerificationDelete();
                }}
              >
                {t.CONFIRM}
              </Button>
            </Col>
          </Row>
        }
        closable
        onCancel={() => setShowDeleteModal(false)}
        className="ReviewStatusModal"
      >
        {t.CONFIRM_DELETE_REVIEW_REQUEST_MESSAGE}
      </Modal>
    </Modal>
  );
}

export default ReviewStatusModal;
