import { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Row, Col } from 'antd';

import SignUpHeaderItem from './SignUpHeaderItem';
import ChecklistModal from '../ChecklistModal/ChecklistModal';

import useModal from '../../../../../../hooks/useModal';
import useLocale from '../../../../../../hooks/useLocale';

import { ClientService } from '../../../../../../shared/api/ClientService';
import { APP_FORM_REDIRECT_EVENT } from '../../../../../../constants/eventBus';
import eventBus from '../../../../../../utils/eventBus';
import { antdUtil } from '../../../../../../utils/antdUtil';

import './SignUpHeader.scss';
import API from '../../../../../../utils/api';

interface IProps {
  data?: ClientService.AdminInfoSignupDto;
  isAssetDebtStatusComplete?: boolean;
  onUpdateSuccess?: () => void;
  disabled?: boolean;
}

const SignUpHeader = ({ data, onUpdateSuccess, isAssetDebtStatusComplete = true, disabled = false }: IProps) => {
  const { applicationFileId } = useParams<{ applicationFileId: string }>();
  const { showModal, closeModal } = useModal();
  const { t } = useLocale();

  const [selectedFilingTypeCode, setSelectedFilingTypeCode] = useState<string>();

  const requestAppFileData = useCallback(async (applicationFileId: string) => {
    const adminInfo = await API.generalGET(applicationFileId);
    const filingTypes = await API.listFilingTypes();
    if (adminInfo && adminInfo?.filingType && filingTypes) {
      setSelectedFilingTypeCode(filingTypes.find((type) => type.id === adminInfo?.filingType?.id)?.code);
    }
  }, []);

  const handleRedirectToAppForm = useCallback(() => {
    eventBus.dispatch(APP_FORM_REDIRECT_EVENT, { subTab: '1' });
  }, []);

  const handleRedirectToAssetsDebts = useCallback(() => {
    eventBus.dispatch(APP_FORM_REDIRECT_EVENT, { subTab: '2' });
  }, []);

  const handleUpdateChecklist = useCallback(() => {
    showModal(
      <ChecklistModal
        onOk={() => {
          if (onUpdateSuccess) onUpdateSuccess();
          closeModal();
        }}
        onCancel={closeModal}
        applicationFileId={applicationFileId}
        checklistType={
          selectedFilingTypeCode === 'Ordinary' || selectedFilingTypeCode === 'Summary'
            ? ClientService.ChecklistTypeEnum.BankruptcySignupDocuments
            : ClientService.ChecklistTypeEnum.ConsumerProposalSignupDocuments
        }
      />
    );
  }, [applicationFileId, closeModal, onUpdateSuccess, selectedFilingTypeCode, showModal]);

  const convertAppFormStatusEnumToReadable = useCallback(
    (status?: ClientService.AppFormStatusEnum) => {
      switch (status) {
        case ClientService.AppFormStatusEnum.NotStarted:
          return t.PENDING;
        case ClientService.AppFormStatusEnum.Complete:
          return t.COMPLETE;
        case undefined:
          return t.PENDING;
        default:
          return t.IN_PROGRESS;
      }
    },
    [t.COMPLETE, t.IN_PROGRESS, t.PENDING]
  );

  const convertChecklistStatusEnumToReadable = useCallback((status?: ClientService.ChecklistStatusEnum) => {
    switch (status) {
      case ClientService.ChecklistStatusEnum.Pending:
        return t.PENDING;
      case ClientService.ChecklistStatusEnum.Incomplete:
        return t.INCOMPLETE;
      case ClientService.ChecklistStatusEnum.Complete:
        return t.COMPLETE;
      default:
        return t.PENDING;
    }
  }, []);

  const convertToReadableCurrency = useCallback(
    (value?: number) => {
      if (!isAssetDebtStatusComplete) return '-';
      return value ? antdUtil.currencyFormatter(value) : '$ 0';
    },
    [isAssetDebtStatusComplete]
  );

  useEffect(() => {
    if (applicationFileId) requestAppFileData(applicationFileId);
  }, [applicationFileId, requestAppFileData, data]);

  return (
    <Row gutter={20} className="SignUpHeader">
      <Col xl={12} xs={24}>
        <Row className="SignUpHeader__container">
          <Col span={12}>
            <SignUpHeaderItem
              value={convertAppFormStatusEnumToReadable(data?.status)}
              label={t.APPLICATION_FORM_STATUS}
              fontWeight="bold"
              hasIcon
              onClick={handleRedirectToAppForm}
              disabled={disabled}
            />
          </Col>
          <Col span={12}>
            <SignUpHeaderItem
              value={
                selectedFilingTypeCode
                  ? convertChecklistStatusEnumToReadable(data?.signupChecklistStatus)
                  : t.FILING_TYPE_NOT_SELECTED
              }
              titleColor={selectedFilingTypeCode ? 'colorGreen' : 'colorDanger'}
              label={t.SIGNUP_DOCUMENT_CHECKLIST}
              fontWeight="bold"
              hasIcon={Boolean(selectedFilingTypeCode)}
              onClick={handleUpdateChecklist}
              disabled={disabled}
            />
          </Col>
        </Row>
      </Col>

      <Col xl={12} xs={24}>
        <Row className="SignUpHeader__container">
          <Col span={8}>
            <SignUpHeaderItem
              value={convertToReadableCurrency(data?.totalDebts)}
              label={t.TOTAL_DEBTS}
              fontWeight="bold"
              titleColor="colorDanger"
              disabled={disabled}
            />
          </Col>
          <Col span={8}>
            <SignUpHeaderItem
              value={convertToReadableCurrency(data?.totalUnsecuredDebts)}
              label={t.TOTAL_UNSECURED}
              titleColor="colorDanger"
              disabled={disabled}
            />
          </Col>
          <Col span={8}>
            <SignUpHeaderItem
              value={convertToReadableCurrency(data?.totalSecuredDebts)}
              label={t.TOTAL_SECURED}
              titleColor="colorDanger"
              hasIcon
              onClick={handleRedirectToAssetsDebts}
              disabled={disabled}
            />
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default SignUpHeader;
