import { Empty, Form } from 'antd';

import TimeslotRow from './TimeslotRow';

import useLocale from '../../../../hooks/useLocale';
import { ClientService } from '../../../../shared/api/ClientService';

import './AvailableTimeslots.scss';

/**
 * Work for Appointemnt Schedule page.
 */
const AvailableTimeslots = ({ disabled = false }: { disabled?: boolean }) => {
  const { t } = useLocale();

  return (
    <Form.Item noStyle shouldUpdate>
      {({ getFieldValue }) => {
        const availableTimeslots = getFieldValue('availableTimeslots') as ClientService.AppointmentTimeSlotDto[];

        return (
          <Form.Item label={t.APPOINTMENT_SCHEDULING_AVAILABLE_APPOINTMENTS} name="selectedTimeslot">
            <div className="AvailableTimeslots__appointments-container">
              {!availableTimeslots || !availableTimeslots?.length ? (
                <div className="AvailableTimeslots__empty-view">
                  <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                </div>
              ) : (
                <>
                  {availableTimeslots?.map((item, index) => (
                    <TimeslotRow key={`timeslot-${String(index)}`} timeslot={item} disabled={disabled} />
                  ))}
                </>
              )}
            </div>
          </Form.Item>
        );
      }}
    </Form.Item>
  );
};

export default AvailableTimeslots;
