import { useMemo } from 'react';
import { Menu, MenuProps } from 'antd';
import ButtonContainer from '../../../../../components/ButtonContainer/ButtonContainer';
import DropdownButton from '../../../../../components/Button/DropdownButton/DropdownButton';
// import Button from '../../../../../components/Button/Button';
import CancelButton from '../../../../../components/Button/AppButtons/CancelButton';
import useLocale from '../../../../../hooks/useLocale';
import DeleteButton from '../../../../../components/Button/AppButtons/DeleteButton';

// const onMenuClick: MenuProps['onClick'] = (e) => {
//   console.log('click', e);
// };
interface IProps {
  buttonsEnabled?: boolean;
  onCancel?: () => void;
  onSaveAsDraft?: () => void;
  onSaveAsDraftWithAlert?: () => void;
  onDelete?: () => void;
  // onAddNewDebt?: (e: any) => void;
}

const ButtonsGroupCancelDraftNewDebt = ({
  buttonsEnabled = true,
  onCancel,
  onSaveAsDraft,
  onSaveAsDraftWithAlert,
  onDelete,
}: // onAddNewDebt
IProps): JSX.Element => {
  const { t } = useLocale();

  const draftButtonMenu = useMemo(() => {
    return (
      <Menu>
        <Menu.Item key="draft" onClick={onSaveAsDraft}>
          {t.BUTTON_SAVE_AS_DRAFT}
        </Menu.Item>
        <Menu.Item key="draft-with-alert" onClick={onSaveAsDraftWithAlert}>
          {t.BUTTON_SAVE_AS_DRAFT_WITH_ALERT}
        </Menu.Item>
      </Menu>
    );
  }, [t.BUTTON_SAVE_AS_DRAFT, t.BUTTON_SAVE_AS_DRAFT_WITH_ALERT, onSaveAsDraft, onSaveAsDraftWithAlert]);

  return (
    <ButtonContainer inline>
      <CancelButton narrow onClick={onCancel ? onCancel : undefined} />
      <DropdownButton
        disabled={!buttonsEnabled}
        narrow
        padding="medium"
        kind="primary"
        overlay={draftButtonMenu}
        onClick={onSaveAsDraft}
      >
        {t.BUTTON_SAVE_AS_DRAFT}
      </DropdownButton>
      <DeleteButton narrow onClick={onDelete ? onDelete : undefined} />
    </ButtonContainer>
  );
};

export default ButtonsGroupCancelDraftNewDebt;
