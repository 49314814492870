import { Row, Col } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import useLocale from '../../hooks/useLocale';
import { ReactNode } from 'react';

interface IProps {
  loadingSize?: number; // e.g. 50 -> "50px"
  labelSize?: number; // e.g. 20 -> "20px"
  text?: ReactNode;
  noText?: boolean;
  style?: React.CSSProperties;
}

const Loading = ({ loadingSize = 75, labelSize = 18, text, noText, style }: IProps): JSX.Element => {
  const { t } = useLocale();
  const loadingText = text || t.LOADING;

  return (
    <div className="LoadingContainer" style={style}>
      <Row gutter={20} style={{ padding: '20px 10px' }}>
        <Col span={24} style={{ textAlign: 'center' }}>
          <LoadingOutlined style={{ fontSize: `${loadingSize}px`, color: '#ed1a3b' }} />
        </Col>
      </Row>
      {!noText && (
        <Row gutter={20}>
          <Col span={24} style={{ textAlign: 'center', fontSize: `${labelSize}px`, lineHeight: `${labelSize}px` }}>
            <div>{loadingText}</div>
          </Col>
        </Row>
      )}
    </div>
  );
};

export default Loading;
