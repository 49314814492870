import { useCallback, useEffect, useState, useMemo } from 'react';
import { Modal, Tabs, Col, Row, Form, Spin, Select, DatePicker, Input, Checkbox } from 'antd';
import moment, { Moment } from 'moment';
import { useQuery } from 'react-query';

import Button from '../Button/Button';
import ReviewRow from './components/ReviewRow';
import SummaryRow from './components/SummaryRow';
import Divider from '../Divider/Divider';
import AttachmentsTab, { AttachmentsFormProps, IAttachmentTabProps } from '../AttachmentsTab';

import useLocale from '../../hooks/useLocale';
import { DATE_FORMAT2, DATE_FORMAT_PLACEHOLDER } from '../../constants/common';
import { VERIFICATION_CHANGE_EVENT, UPLOAD_FILES_EVENT } from '../../constants/eventBus';
import { AGENTS_QUERY } from '../../constants/reactQuery';

import { eventBus } from '../../utils/eventBus';
import { arraysHaveIntersections, arraysAreEmpty, getLookupIdsByNames } from './utils';
import { IVerification } from './types';
import { ClientService } from '../../shared/api/ClientService';
import API from '../../utils/api';
import { AuthorizeService } from '../Auth/AuthorizeService';
import genericMessage from '../../utils/genericMessage';

import './RequestForReview.scss';

interface IProps extends IAttachmentTabProps {
  title?: string;
  data?: ClientService.IDocumentDto[];
  onOk?: (documentsIds?: string[], verificationsIds?: string[]) => void;
  onCancel?: () => void;
}

interface IForm extends AttachmentsFormProps {
  verifications?: IVerification[];
  bulkReviewers?: string[];
  bulkApprovers?: string[];
  bulkSignees?: string[];
  bulkDueDate?: Moment;
  bulkComments?: string;
  bulkIsUrgent?: boolean;
}

const UPLOAD_TAB = 'upload';
const SELECT_AGENTS_TAB = 'select-agents';
const SELECT_SEQUENCE_TAB = 'select-sequence';
const REVIEW_SUMMARY_TAB = 'review-summary';

function RequestForReview({ title, data, onOk, onCancel, ...attachmentTabProps }: IProps) {
  const { t } = useLocale();
  const { TabPane } = Tabs;
  const { Option } = Select;
  const [form] = Form.useForm<IForm>();

  const basic_tabs = useMemo(() => [SELECT_AGENTS_TAB, SELECT_SEQUENCE_TAB, REVIEW_SUMMARY_TAB], []);
  const tabs = useMemo(() => (Boolean(data) ? basic_tabs : [UPLOAD_TAB, ...basic_tabs]), [data, basic_tabs]);

  //const user = AuthorizeService.getCurrentUserInfo();
  //console.log(user?.profile?.sub);

  const [activeKey, setActiveKey] = useState<string>(tabs[0]);
  const [disabledNextSteps, setDisabledNextSteps] = useState<boolean>(true);
  const [isAnyPending, setIsAnyPending] = useState<boolean>(false);

  const [loading, setLoading] = useState<boolean>(false);
  const [documentsLoading, setDocumentsLoading] = useState<boolean>(false);

  const { data: agents } = useQuery([AGENTS_QUERY], () => API.listAgents(), {
    refetchOnWindowFocus: false,
    refetchOnMount: true,
  });

  const handleUploadValidation = useCallback((values: IForm) => {
    if ((!values.existingFiles || !values?.existingFiles?.length) && (!values.newFiles || !values?.newFiles?.length)) {
      genericMessage.error({}, t.SELECT_SOME_FILES_TO_ATTACH);
      return false;
    }
    return true;
  }, []);

  const handleReviewValidation = useCallback(
    (values: IForm) => {
      const verifications = values?.verifications?.map((item: IVerification) => {
        if (arraysAreEmpty(item?.reviewers, item?.signees, item?.approvers)) {
          return { ...item, error: t.DOCUMENTS_ERROR_NO_ASSIGNEES };
        }

        if (arraysHaveIntersections(item?.reviewers, item?.signees, item?.approvers)) {
          return { ...item, error: t.DOCUMENTS_ERROR_SAME_AGENT };
        }
        return { ...item, error: undefined };
      });

      form.setFieldsValue({ verifications });
      return !Boolean(verifications?.find((item) => item.error));
    },
    [form, t.DOCUMENTS_ERROR_NO_ASSIGNEES, t.DOCUMENTS_ERROR_SAME_AGENT]
  );

  const requestDocumentReview = useCallback(
    async (verifications) => {
      setLoading(true);
      const documentsIds: string[] = [];
      const verificationsIds: string[] = [];

      for (const document of verifications) {
        const { documentId, reviewers, approvers, signees, isUrgent, dueDate, comments } = document;
        const response = await API.documentVerificationsPOST({
          documentId,
          reviewers: getLookupIdsByNames(reviewers, agents),
          approvers: getLookupIdsByNames(approvers, agents), //[user?.profile?.sub],
          signees: getLookupIdsByNames(signees, agents),
          isUrgent,
          dueDate,
          comments,
        } as ClientService.DocumentVerificationCreateDto).catch(() => setLoading(false));

        if (response?.returnId) {
          verificationsIds.push(response?.returnId);
          documentsIds.push(documentId);
        }
      }

      eventBus.dispatch(VERIFICATION_CHANGE_EVENT);
      if (onOk) onOk(documentsIds, verificationsIds);

      setLoading(false);
    },
    [agents, onOk]
  );

  const updateFormOnDocumentsUpload = useCallback(
    (documents: ClientService.DocumentDto[]) => {
      form.setFieldsValue({
        existingFiles: documents,
        newFiles: [],
        verifications: documents?.map((item) => ({
          documentId: item.id,
          name: item.name,
          frenchName: item.frenchName,
          isUrgent: false,
          verificationStatus: item.verificationStatus,
        })) as IVerification[],
      });
    },
    [form]
  );

  const uploadDocumentsForReview = useCallback(
    async (attachedDocumentIds: string[], key: string) => {
      let documents: ClientService.DocumentDto[] = [];
      setDocumentsLoading(true);
      for (const documentId of attachedDocumentIds) {
        const response = await API.documentsGET2(documentId).catch(() => setDocumentsLoading(false));
        if (response) {
          documents = [...documents, response];
        }
      }
      setDocumentsLoading(false);
      updateFormOnDocumentsUpload(documents);

      setActiveKey(key);
      setDisabledNextSteps(false);
    },
    [updateFormOnDocumentsUpload]
  );

  const sortVerificationsActorsByName = useCallback(() => {
    form.setFieldsValue({
      verifications: form.getFieldsValue().verifications?.map((item) => ({
        ...item,
        reviewers: item.reviewers?.sort(),
        approvers: item.approvers?.sort(),
        signees: item.signees?.sort(),
      })),
    });
  }, [form]);

  const handleKeyChange = useCallback(
    (key) => {
      const existingFiles = form.getFieldValue('existingFiles');
      if (key === SELECT_AGENTS_TAB && activeKey === UPLOAD_TAB) {
        eventBus.dispatch(UPLOAD_FILES_EVENT, {
          onUploadSuccess: (documentIds: string[]) => {
            const newFilesIds = documentIds?.filter(
              (id) => !existingFiles?.find((item: ClientService.IDocumentDto) => item?.id === id)
            );
            uploadDocumentsForReview(newFilesIds, key);
          },
          onUploadError: (error: string) => genericMessage.error({}, error),
        });
        return;
      }

      if (key === SELECT_SEQUENCE_TAB && activeKey === SELECT_AGENTS_TAB) {
        sortVerificationsActorsByName();
      }

      setActiveKey(key);
      setDisabledNextSteps(false);
    },
    [activeKey, form, sortVerificationsActorsByName, uploadDocumentsForReview]
  );

  const handleSubmit = useCallback(
    (values) => {
      if (
        (activeKey === UPLOAD_TAB && !handleUploadValidation(values)) ||
        (activeKey !== UPLOAD_TAB && !handleReviewValidation(values))
      )
        return;
      const currentIndex = tabs.indexOf(activeKey);

      if (currentIndex < tabs?.length - 1) {
        handleKeyChange(tabs[currentIndex + 1]);
      } else {
        requestDocumentReview(values?.verifications);
      }
    },
    [activeKey, handleKeyChange, handleUploadValidation, handleReviewValidation, requestDocumentReview, tabs]
  );

  const handlePrevious = useCallback(() => {
    const currentIndex = tabs.indexOf(activeKey);

    if (currentIndex > 0) {
      setActiveKey(tabs[currentIndex - 1]);
    }
  }, [activeKey, tabs]);

  const handleBulkChange = useCallback(
    (fieldName: string, value: any) => {
      const values = form.getFieldsValue();
      form.setFieldsValue({
        verifications: values?.verifications?.map((item: IVerification) => ({ ...item, [fieldName]: value })),
      });
      handleReviewValidation(form.getFieldsValue());
    },
    [form, handleReviewValidation]
  );

  const handleValuesChange = useCallback(
    (values) => {
      const isAnyPending = form
        .getFieldsValue()
        .verifications?.find(
          (item: IVerification) => item?.verificationStatus === ClientService.DocumentVerificationStatusEnum.Pending
        );

      if (isAnyPending) setIsAnyPending(true);
      else setIsAnyPending(false);

      Object.keys(values).forEach((field) => {
        const error = form.getFieldError(field);
        if (!error.length) {
          return;
        }
        form.setFields([
          {
            name: field,
            errors: [],
          },
        ]);
      });
    },
    [form, data]
  );

  useEffect(() => {
    if (data?.find((item) => item?.verificationStatus === ClientService.DocumentVerificationStatusEnum.Pending)) {
      setIsAnyPending(true);
    }

    if (data) updateFormOnDocumentsUpload(data as ClientService.DocumentDto[]);
  }, [data, form, updateFormOnDocumentsUpload]);

  const setTabName = useCallback((tabKey: string) => `Step ${(tabs.indexOf(tabKey) || 0) + 1}`, [tabs]);

  return (
    <Modal
      destroyOnClose
      centered
      visible
      title={title || t.REQUEST_FOR_REVIEW}
      className="RequestForReview"
      width={1280}
      footer={null}
      closable
      onCancel={onCancel}
      getContainer={false}
      forceRender
    >
      <Spin spinning={loading || documentsLoading}>
        <Form
          form={form}
          layout="vertical"
          onFinish={handleSubmit}
          onFinishFailed={() => setDisabledNextSteps(true)}
          onValuesChange={handleValuesChange}
        >
          <Tabs onChange={handleKeyChange} activeKey={activeKey}>
            {!data && (
              <TabPane tab={setTabName(UPLOAD_TAB)} key={UPLOAD_TAB}>
                <AttachmentsTab
                  {...attachmentTabProps}
                  form={form}
                  isFlatLayout
                  flatLayoutProps={{
                    hasInstruction: false,
                    hasSupportingList: false,
                    hasExistingFiles: false,
                    label: t.FILES,
                  }}
                  newSupportingFileRowLayoutProps={{
                    disabledFoldersInput: true,
                    disabledPrefixInput: true,
                    preselectedPrefixInput: true,
                  }}
                />
              </TabPane>
            )}

            <TabPane tab={setTabName(SELECT_AGENTS_TAB)} key={SELECT_AGENTS_TAB} disabled={disabledNextSteps}>
              <div className="RequestForReview__subtitle">Select reviewers/approvers/signees</div>
              {isAnyPending && (
                <div className="RequestForReview__warning-message">
                  {t.ONE_OR_MORE_SELECTED_FILES_HAS_PENDING_REVIEW_APPROVAL_SIGN_STATUS_CHECK_AND_TRYAGAIN}
                </div>
              )}
              <>
                <Row gutter={12} align="top">
                  <Col span={3}>{t.ALL_SELECTED_FILES}</Col>
                  <Col span={4}>
                    <Form.Item name="bulkReviewers">
                      <Select size="large" mode="multiple" onChange={(value) => handleBulkChange('reviewers', value)}>
                        {agents?.map((option) => (
                          <Option key={`bulk-reviewers-${option?.id}`} value={option?.name}>
                            {option?.name}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col span={4}>
                    <Form.Item name="bulkApprovers">
                      <Select size="large" mode="multiple" onChange={(value) => handleBulkChange('approvers', value)}>
                        {agents?.map((option) => (
                          <Option key={`bulk-approvers-${option?.id}`} value={option?.name}>
                            {option?.name}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col span={4}>
                    <Form.Item name="bulkSignees">
                      <Select size="large" mode="multiple" onChange={(value) => handleBulkChange('signees', value)}>
                        {agents?.map((option) => (
                          <Option key={`bukl-signees-${option?.id}`} value={option?.name}>
                            {option?.name}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col span={3}>
                    <Form.Item noStyle shouldUpdate>
                      {({ getFieldValue }) => (
                        <Form.Item
                          name="bulkDueDate"
                          rules={[
                            {
                              required: getFieldValue('bulkIsUrgent'),
                              message: t.DUE_DATE_IS_REQUIRED,
                            },
                          ]}
                        >
                          <DatePicker
                            size="large"
                            format={DATE_FORMAT2}
                            disabledDate={(date) => date.isBefore(moment(), 'day')}
                            onChange={(value) => handleBulkChange('dueDate', value)}
                            placeholder={DATE_FORMAT_PLACEHOLDER}
                          />
                        </Form.Item>
                      )}
                    </Form.Item>
                  </Col>
                  <Col span={4}>
                    <Form.Item name="bulkComments">
                      <Input size="large" onChange={(e) => handleBulkChange('comments', e?.target?.value)} />
                    </Form.Item>
                  </Col>
                  <Col span={2}>
                    <Form.Item name="bulkIsUrgent">
                      <div className="RequestForReview__checkbox-container">
                        <Checkbox onChange={(e) => handleBulkChange('isUrgent', e.target.checked)} />
                      </div>
                    </Form.Item>
                  </Col>
                </Row>
              </>

              <Divider style={{ marginTop: 0 }} />

              <Form.List name="verifications">
                {(fields, { remove }) => (
                  <>
                    {fields?.map((field) => (
                      <ReviewRow
                        rowName={field?.name}
                        agents={agents}
                        remove={remove}
                        key={field?.name}
                        isRemovable={fields?.length > 1}
                        form={form}
                        handleValidation={() => handleReviewValidation(form.getFieldsValue())}
                      />
                    ))}
                  </>
                )}
              </Form.List>
            </TabPane>

            <TabPane tab={setTabName(SELECT_SEQUENCE_TAB)} key={SELECT_SEQUENCE_TAB} disabled={disabledNextSteps}>
              <div className="RequestForReview__subtitle">Select Sequence</div>
              <Form.List name="verifications">
                {(fields, { remove }) => (
                  <>
                    {fields?.map((field) => (
                      <SummaryRow
                        rowName={field?.name}
                        remove={remove}
                        key={field?.name}
                        isRemovable={fields?.length > 1}
                        isEditable
                      />
                    ))}
                  </>
                )}
              </Form.List>
            </TabPane>

            <TabPane tab={setTabName(REVIEW_SUMMARY_TAB)} key={REVIEW_SUMMARY_TAB} disabled={disabledNextSteps}>
              <div className="RequestForReview__subtitle">Review summary</div>
              <Form.List name="verifications">
                {(fields, { remove }) => (
                  <>
                    {fields?.map((field) => (
                      <SummaryRow
                        rowName={field?.name}
                        remove={remove}
                        key={field?.name}
                        isRemovable={fields?.length > 1}
                        isEditable={false}
                      />
                    ))}
                  </>
                )}
              </Form.List>
            </TabPane>
          </Tabs>

          <Row gutter={12} justify="end" className="RequestForReview__buttons-container">
            <Col>
              <Form.Item>
                <Button kind="cancel" padding="large" onClick={onCancel}>
                  {t.CANCEL}
                </Button>
              </Form.Item>
            </Col>
            {tabs.indexOf(activeKey) > 0 && (
              <Col>
                <Form.Item>
                  <Button kind="cancel" padding="large" onClick={handlePrevious}>
                    {t.PREVIOUS}
                  </Button>
                </Form.Item>
              </Col>
            )}
            <Col>
              <Form.Item>
                <Button kind="primary" padding="large" htmlType="submit" disabled={isAnyPending}>
                  {tabs.indexOf(activeKey) === tabs?.length - 1 ? t.SAVE : t.NEXT}
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Spin>
    </Modal>
  );
}

export default RequestForReview;
