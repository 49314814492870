import { useCallback } from 'react';
import { Col, Row } from 'antd';
import { Icon } from '@fluentui/react/lib/Icon';

import Entry from './Entry';

import genericMessage from '../../../../../utils/genericMessage';
import { convertDatesToReadable } from '../../../utils';
import { DATE_FORMAT3 } from '../../../../../constants/common';
import { ClientService } from '../../../../../shared/api/ClientService';
import API from '../../../../../utils/api';

import './Overrides.scss';
import useLocale from '../../../../../hooks/useLocale';

interface IProps {
  overrideItem: ClientService.AvailabilityOverrideDto;
  onDeleteSuccess: () => void;
}

const OverrideRow = ({ overrideItem, onDeleteSuccess }: IProps) => {
  const { t } = useLocale();
  const handleDelete = useCallback(
    async (overrideId: string) => {
      const response = await API.deleteAvailabilityOverride(overrideId);

      if (response?.result === ClientService.Result.Successful) {
        onDeleteSuccess();
        genericMessage.success(t.OVERRIDE_IS_DELETED);
      }
    },
    [onDeleteSuccess, t.OVERRIDE_IS_DELETED]
  );

  return (
    <Row className="Overrides__container">
      <Col span={9} className="Overrides__date-container">
        {convertDatesToReadable(overrideItem.startDate?.utc(), overrideItem.endDate?.utc(), DATE_FORMAT3, 'd')}
      </Col>
      <Col span={14} className="Overrides__entries-list">
        {overrideItem.isAvailable || (overrideItem?.entries && overrideItem?.entries?.length > 0) ? (
          <>
            {overrideItem?.entries?.map((entry, index) => (
              <Entry entry={entry} key={`entry-${String(index)}`} />
            ))}
          </>
        ) : (
          <div>{t.UNAVAILABLE}</div>
        )}
      </Col>
      <Col span={1}>
        <Icon
          iconName="Delete"
          onClick={() => handleDelete(overrideItem?.id as string)}
          className="Overrides__delete-icon"
        />
      </Col>
    </Row>
  );
};

export default OverrideRow;
