import { useCallback, useEffect, useState, useRef, useContext } from 'react';
import { useParams } from 'react-router-dom';
import { Spin } from 'antd';
import WebViewer, { Core } from '@pdftron/webviewer';
import moment from 'moment';
import { useQuery } from 'react-query';

import SaveButton from './components/SaveButton';
import EmailButton from './components/EmailButton';
import PreviewFileHeader from '../PreviewFileHeader/PreviewFileHeader';
import EmailEditor from '../../pages/ApplicationOverviewPage/EmailContent/EmailEditor/EmailEditor';

import { argumentifyUpdateDocument } from '../../pages/ApplicationOverviewPage/DocumentsContent/utils';
import { DocumentPreviewTypeEnum, DocumentTypeEnum } from './types';
import genericMessage from '../../utils/genericMessage';
import { PDFTronContext } from '../../context/PDFTronContext';
import useModal from '../../hooks/useModal';
import { USE_QUERY_OPTIONS, PDFTRON_LICENSE_KEY_QUERY, DOCUMENT_TYPES_QUERY } from '../../constants/reactQuery';

import authService, { AuthorizeService } from '../Auth/AuthorizeService';
import { ClientService } from '../../shared/api/ClientService';
import API from '../../utils/api';
import './PreviewFile.scss';
import useLocale from '../../hooks/useLocale';
import { bmvbhash } from 'blockhash-core';
import { BlobToImageData } from '../../utils/blobToImageData';
import { MSG_TO_PDF_FEATUREFLAG } from '../FeatureFlag';

export interface IPreviewFileProps {
  /** Note: It is document entity, not debtor's file. */
  file?: ClientService.IDocumentDto | ClientService.IDocumentVerificationDto | ClientService.IDocumentVersionDto;
  documentPreviewType?: DocumentPreviewTypeEnum;
  onUpdate?: () => void;
  header?: JSX.Element;
  webviewerHeight?: string;
}

const SAVE_DATA_ELEMENT = 'saveButton';
const DOWNLOAD_DATA_ELEMENT = 'downloadButtonCustom';
const SEND_DATA_ELEMENT = 'sendButtonCustom';

/**
 * It is shared component for preview pdf document. Applied in multiple pages.
 * Handle associated features such as sign with signature. #24309
 * @param param0
 * @returns
 */
function PreviewFile({
  file,
  documentPreviewType,
  onUpdate,
  header,
  webviewerHeight = '100vh',
}: IPreviewFileProps): JSX.Element {
  const viewerRef = useRef<HTMLDivElement>(null);
  const ref = useRef<HTMLDivElement>(null);
  const { instance, setInstance } = useContext(PDFTronContext);
  const { showModal, closeModal } = useModal();
  const { applicationFileId } = useParams<{ applicationFileId?: string }>();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isUpdating, setIsUpdating] = useState<boolean>(false);

  const [blob, setBlob] = useState<Blob>();
  const [isPDF, setIsPDF] = useState<boolean>(false);
  const [documentType, setDocumentType] = useState<DocumentTypeEnum>(DocumentTypeEnum.UNDEFINED);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const { t } = useLocale();

  const { data: licenseKey } = useQuery([PDFTRON_LICENSE_KEY_QUERY], () => API.getPDFTronKey(), USE_QUERY_OPTIONS);
  const { data: listTypes } = useQuery([DOCUMENT_TYPES_QUERY], () => API.listTypes(), USE_QUERY_OPTIONS);
  const [config, setConfig] = useState<ClientService.QuickActionsConfigDto>();

  const requestFileConfig = useCallback(async (applicationFileId: string) => {
    const response = await API.getQuickActionsConfig(applicationFileId);
    if (response) setConfig(response);
  }, []);

  /**
   * Download specified debtor file's document from server side.
   */
  const handlePreview = useCallback(
    async (file: ClientService.IDocumentDto) => {
      if (documentPreviewType === DocumentPreviewTypeEnum.documentVersion && !isPDF) return;

      setIsLoading(true);

      const fileId =
        documentPreviewType === DocumentPreviewTypeEnum.document
          ? file.id
          : ((file as ClientService.IDocumentVerificationDto) || (file as ClientService.IDocumentVersionDto))
              ?.documentId;

      const versionId =
        documentPreviewType === DocumentPreviewTypeEnum.documentVersion
          ? `/${(file as ClientService.IDocumentVersionDto)?.id}`
          : '';

      let token = await authService.getAccessToken();

      fetch(`${process.env.REACT_APP_BASE_URL}/api/client-service/documents/${fileId}/download` + versionId, {
        method: 'GET',
        headers: new Headers({
          Authorization: `Bearer ${token}`,
        }),
      })
        .then((response) => {
          return response.blob();
        })
        .then((blob) => {
          try {
            const url = URL.createObjectURL(blob);
            instance?.UI.loadDocument(url, { filename: file.name });
            setBlob(blob);
          } catch (err) {
            console.log(err);
          }

          setIsLoading(false);
        })
        .catch((error) => {
          setIsLoading(false);
          console.log('donwload document failed', error);
        })
        .finally(() => {
          setIsLoading(false);
        });
    },
    [documentPreviewType, instance?.UI, isPDF]
  );

  const handlePreviewV2 = useCallback(
    async (file: ClientService.IDocumentDto) => {
      if (documentType == DocumentTypeEnum.UNDEFINED) return;
      if (!instance || !instance?.UI) return;
      if (documentPreviewType === undefined || documentPreviewType === null) return;
      if (documentPreviewType === DocumentPreviewTypeEnum.documentVersion && documentType !== DocumentTypeEnum.PDF)
        return;

      setIsLoading(true);

      const fileId =
        documentPreviewType === DocumentPreviewTypeEnum.document
          ? file.id
          : ((file as ClientService.IDocumentVerificationDto) || (file as ClientService.IDocumentVersionDto))
              ?.documentId;

      const versionId =
        documentPreviewType === DocumentPreviewTypeEnum.documentVersion
          ? `/${(file as ClientService.IDocumentVersionDto)?.id}`
          : '';

      let token = await authService.getAccessToken();

      fetch(`${process.env.REACT_APP_BASE_URL}/api/client-service/documents/${fileId}/download` + versionId, {
        method: 'GET',
        headers: new Headers({
          Authorization: `Bearer ${token}`,
        }),
      })
        .then((response) => {
          return response.blob();
        })
        .then((blob) => {
          try {
            setBlob(blob);
          } catch (err) {
            console.log(err);
          }

          if (documentType === DocumentTypeEnum.MSG) {
            return fetch(
              `${process.env.REACT_APP_BASE_URL}/api/client-service/documents/${fileId}/download-as-pdf` + versionId,
              {
                method: 'GET',
                headers: new Headers({
                  Authorization: `Bearer ${token}`,
                }),
              }
            );
          } else {
            try {
              const url = URL.createObjectURL(blob);
              instance?.UI.loadDocument(url, { filename: file.name });
              setBlob(blob);
            } catch (err) {
              console.log(err);
            }

            setIsLoading(false);
          }
        })
        .then((response) => {
          if (response) return response.blob();
        })
        .then((blob2) => {
          if (blob2)
            try {
              const url = URL.createObjectURL(blob2);
              instance?.UI.loadDocument(url, { filename: file.name?.replace('.msg', '.pdf') });
            } catch (err) {
              console.log(err);
            }

          setIsLoading(false);
        })
        .catch((error) => {
          setIsLoading(false);
          console.log('donwload document failed', error);
        })
        .finally(() => {
          setIsLoading(false);
        });
    },
    [documentPreviewType, instance?.UI, documentType]
  );

  const requestUpdateDocumentVerificationFile = useCallback(
    async (documentId: string, blob: Blob) => {
      setIsUpdating(true);
      const file = await API.documentsGET2(documentId as string).catch(() => setIsUpdating(false));

      if (file) {
        const response = await API.documentsPUT(
          ...argumentifyUpdateDocument({
            ...file,
            id: file?.id as string,
            fileModifiedDate: moment(),
            content: {
              fileName: file.name as string,
              data: blob as Blob,
            },
          })
        ).catch(() => setIsUpdating(false));

        if (response?.result === ClientService.Result.Successful) {
          genericMessage.success(t.CHANGES_ARE_SAVED);
          if (onUpdate) onUpdate();
        }
      }
      setIsUpdating(false);
    },
    [onUpdate, t.CHANGES_ARE_SAVED]
  );

  const requestUpdateDocumentFile = useCallback(
    async (blob: Blob) => {
      setIsUpdating(true);
      const response = await API.documentsPUT(
        ...argumentifyUpdateDocument({
          ...file,
          id: file?.id as string,
          fileModifiedDate: moment(),
          content: {
            fileName: ((file as ClientService.IDocumentVerificationDto)?.documentName ||
              (file as ClientService.IDocumentDto)?.name) as string,
            data: blob as Blob,
          },
        })
      ).catch(() => setIsUpdating(false));

      if (response?.result === ClientService.Result.Successful) {
        genericMessage.success(t.CHANGES_ARE_SAVED);
        if (onUpdate) onUpdate();
      }
      setIsUpdating(false);
    },
    [file, onUpdate, t.CHANGES_ARE_SAVED]
  );

  const handleDownload = useCallback(async () => {
    if (isPDF) {
      instance?.UI.downloadPdf();
    } else {
      const url = URL.createObjectURL(blob as Blob);
      const link = window.document.createElement('a');
      link.href = url;
      link.setAttribute(
        'download',
        ((file as ClientService.IDocumentVerificationDto)?.documentName ||
          (file as ClientService.IDocumentDto)?.name) as string
      );
      link.click();
      URL.revokeObjectURL(url);
    }
  }, [blob, file, instance, isPDF]);

  const handleSave = useCallback(async () => {
    const core = instance?.Core as typeof Core;
    const { documentViewer, annotationManager } = core;

    const doc = documentViewer.getDocument();
    const xfdfString = await annotationManager.exportAnnotations();
    const data = await doc.getFileData({
      xfdfString,
    });
    const arr = new Uint8Array(data);
    const saveBlob = new Blob([arr]);

    if ((file as ClientService.IDocumentVerificationDto)?.documentId) {
      requestUpdateDocumentVerificationFile(
        (file as ClientService.IDocumentVerificationDto)?.documentId as string,
        saveBlob
      );
    } else {
      requestUpdateDocumentFile(saveBlob);
    }
  }, [instance, requestUpdateDocumentVerificationFile, requestUpdateDocumentFile, file]);

  const handleSaveOptimized = useCallback(async () => {
    const core = instance?.Core as typeof Core;
    const { documentViewer, PDFNet } = core;

    const doc = documentViewer.getDocument();
    const pdfDoc = await doc.getPDFDoc();

    await pdfDoc.requirePage(1);
    PDFNet.Optimizer.optimize(pdfDoc);

    documentViewer.refreshAll();
    documentViewer.updateView();

    handleSave();
  }, [handleSave, instance]);

  const handleClickOutside = useCallback((event: MouseEvent) => {
    if (ref.current && !ref.current.contains(event.target as Node)) {
      setIsOpen(false);
    }
  }, []);

  const handleShowEmailEditor = useCallback(() => {
    showModal(
      <EmailEditor
        onNext={() => {
          closeModal();
        }}
        onCancel={closeModal}
        fileId={applicationFileId as string}
        attachingDocuments={[file as ClientService.DocumentDto]}
      />
    );
  }, [showModal, closeModal, applicationFileId, file]);

  useEffect(() => {
    instance?.UI.setHeaderItems((header) => {
      const saveButton = {
        type: 'customElement',
        dataElement: SAVE_DATA_ELEMENT,
        render: () => (
          <SaveButton
            ref={ref}
            isOpen={isOpen}
            setIsOpen={setIsOpen}
            handleSave={handleSave}
            handleSaveOptimized={handleSaveOptimized}
          />
        ),
      };

      const downloadButton = {
        type: 'actionButton',
        img: 'icon-header-download',
        dataElement: DOWNLOAD_DATA_ELEMENT,
        onClick: handleDownload,
        title: t.DOWNLOAD,
      };

      const sendButton = {
        type: 'customElement',
        dataElement: SEND_DATA_ELEMENT,
        title: t.SEND_AS_ATTACHMENT,
        render: () => <EmailButton handleShowEmailEditor={handleShowEmailEditor} />,
      };

      const items = header
        .getItems()
        .filter(
          (item: any) =>
            item.dataElement !== SAVE_DATA_ELEMENT &&
            item.dataElement !== DOWNLOAD_DATA_ELEMENT &&
            item.dataElement !== SEND_DATA_ELEMENT
        );

      items.push(downloadButton);

      if (config?.canReceiveEmail) {
        items.push(sendButton);
      }
      items.push(saveButton);

      header.update(items);
    });
  }, [
    instance,
    isPDF,
    handleDownload,
    handleSave,
    blob,
    handleSaveOptimized,
    isOpen,
    handleShowEmailEditor,
    t.DOWNLOAD,
    t.SEND_AS_ATTACHMENT,
    config?.canReceiveEmail,
  ]);

  useEffect(() => {
    if (!instance && licenseKey?.key) {
      const user = AuthorizeService.getCurrentUserInfo();

      WebViewer(
        {
          annotationUser: user?.profile.email,
          path: '/static',
          //initialDoc: '/static/initial/sample.pdf', // Set default pdf document as sample. Can help local debug if cannot access remove document file. For test purpose only.
          disabledElements: [
            'toggleNotesButton',
            'selectToolButton',
            'toolbarGroup-Shapes',
            'toolbarGroup-Edit',
            'toolbarGroup-Forms',
            'toolbarGroup-Annotate',
            'ribbons',
            'ribbonsDropdown',
            'downloadButton',
            'outlinesPanel',
            'outlinesPanelButton',
            'notesPanel',
            'signaturePanelButton',
            'textSignaturePanelButton',
          ],
          fullAPI: true,
          enableAnnotations: true,
          css: './style.css',
          licenseKey: licenseKey?.key,
        },
        viewerRef.current as HTMLDivElement
      )
        .then(async (instance) => {
          const core = instance?.Core as typeof Core;
          const { PDFNet, documentViewer, annotationManager } = core;

          await PDFNet.initialize();
          instance.UI.setToolbarGroup('toolbarGroup-Insert');
          setInstance(instance);

          annotationManager.setCurrentUser(user?.profile.email ?? 'Guest');

          const signatureTool = documentViewer.getTool('AnnotationCreateSignature') as Core.Tools.SignatureCreateTool;

          const handleSignatureSave = (annotations: any, action: any) => {
            extractAnnotationSignature(annotations[0], documentViewer, 'created', file?.id);
          };

          const handleSignatureDelete = (annotation: any, index: any) => {
            extractAnnotationSignature(annotation, documentViewer, 'deleted', file?.id);
          };

          documentViewer.addEventListener('documentLoaded', () => {
            //
            // Get current logon user's existing signatures from database.
            //
            API.getAgentSignatures().then((response) => {
              signatureTool.removeEventListener('signatureSaved', handleSignatureSave);
              signatureTool?.removeEventListener('signatureDeleted', handleSignatureDelete);

              if (response && response.length > 0) {
                let tmpSignatures: any = [];

                let importedSignatures = signatureTool.getSavedSignatures();
                let total = importedSignatures.length;

                for (let i = 0; i < total; i++) {
                  signatureTool.deleteSavedSignature(0);
                }

                response.forEach((r) => {
                  if (r.signature) {
                    tmpSignatures.push(`data:image/png;base64,${r.signature}`);
                  }
                });

                signatureTool?.importSignatures(tmpSignatures);
              }

              signatureTool.addEventListener('signatureSaved', handleSignatureSave);
              signatureTool?.addEventListener('signatureDeleted', handleSignatureDelete);
            });
          });
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [file?.id, extractAnnotationSignature, instance, licenseKey?.key, setInstance]);

  const b64toBlob = async (imageUrl: string) => fetch(imageUrl).then((res) => res.blob());

  const handleSignatureBlob = async (fileId: string | undefined, action: string, blob: any) => {
    BlobToImageData(blob).then(async (value) => {
      const imageData = value as ImageData;
      const hash = bmvbhash(imageData, 16);

      //
      // Submit signature image with document id to server side.
      //
      switch (action) {
        case 'created':
          let file = { fileName: 'signature', data: blob };
          const response = await API.assignAgentSignatureToDocument(fileId, hash, file);
          if (response === ClientService.AssignSignatureStatusEnum.CreateNewSignatureSuccess) {
            console.log('assign signature to document is success');
          } else if (response === ClientService.AssignSignatureStatusEnum.AssignSignatureFailure) {
            console.log('assign signature to document is failed');
          }
          break;
        case 'deleted':
          const unsignedResponse = await API.deleteSignature(hash);
          if (unsignedResponse === true) {
            console.log('remove Signature from document success');
          }
          break;
      }
    });
  };

  async function extractAnnotationSignature(
    annotation: any,
    docViewer: any,
    action: string,
    fileId: string | undefined
  ) {
    if (!annotation) {
      return;
    }

    // Create a new Canvas to draw the Annotation on
    const canvas = document.createElement('canvas');
    // Reference the annotation from the Document
    const pageMatrix = docViewer.getDocument().getPageMatrix(annotation.PageNumber);
    // Set the height & width of the canvas to match the annotation
    canvas.height = annotation.Height;
    canvas.width = annotation.Width;
    const ctx = canvas.getContext('2d');

    if (!ctx) return;

    ctx.translate(-annotation.X, -annotation.Y);
    annotation.draw(ctx, pageMatrix);
    canvas.toBlob((blob) => {
      if (!blob) {
        try {
          annotation.getImageData().then((url: string) => {
            if (!url) return;
            b64toBlob(url).then((b) => {
              handleSignatureBlob(fileId, action, b);
            });
          });
        } catch (err) {
          console.log(err);
        }
      } else {
        handleSignatureBlob(fileId, action, blob);
      }
    });
  }

  useEffect(() => {
    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [handleClickOutside]);

  useEffect(() => {
    if (instance) {
      instance?.UI.addEventListener('click', handleClickOutside);
      return () => {
        instance?.UI.removeEventListener('click', handleClickOutside);
      };
    }
  }, [handleClickOutside, instance]);

  useEffect(() => {
    if (instance) {
      //
      // Setup tool bar buttons for WebViewer component.
      //
      instance.UI.setHeaderItems(function (header) {
        const insertHeader = header.getHeader('toolbarGroup-Insert');
        insertHeader.delete('fileAttachmentToolGroupButton');
        insertHeader.delete('changeViewToolGroupButton');
        insertHeader.delete('threeDToolGroupButton');
        insertHeader.delete('stampToolGroupButton');

        insertHeader.push({
          type: 'spacer',
        });

        // add sticky notes & free text tool to the header
        const items = insertHeader.getItems();

        items.splice(4, 0, {
          type: 'toolButton',
          toolName: 'AnnotationCreateFreeText',
        });

        items.splice(4, 0, {
          dataElement: 'stickyToolGroupButton',
          title: t.ANNOTATION_STICKYNOTE,
          toolGroup: 'stickyTools',
          type: 'toolGroupButton',
        });

        header.update(items);
      });
    }
  }, [instance, t.ANNOTATION_STICKYNOTE]);

  /** Access specified document content from back end. */
  useEffect(() => {
    if (file) {
      if (MSG_TO_PDF_FEATUREFLAG) handlePreviewV2(file);
      else handlePreview(file);
    }
  }, [file, MSG_TO_PDF_FEATUREFLAG ? handlePreviewV2 : handlePreview]);

  useEffect(() => {
    if (applicationFileId) {
      requestFileConfig(applicationFileId);
    }
  }, [applicationFileId, requestFileConfig]);

  useEffect(() => {
    if (file && listTypes) {
      const isPDF = Boolean(listTypes?.find((type) => type.id === file.documentTypeId)?.code === 'application/pdf');
      const isMSG = Boolean(
        listTypes?.find((type) => type.id === file.documentTypeId)?.code === 'application/vnd.ms-outlook'
      );
      setIsPDF(isPDF);

      if (MSG_TO_PDF_FEATUREFLAG) {
        if (isPDF) {
          setDocumentType(DocumentTypeEnum.PDF);
        } else if (isMSG) {
          setDocumentType(DocumentTypeEnum.MSG);
        } else {
          setDocumentType(DocumentTypeEnum.OTHER);
        }
      }
    }
  }, [listTypes, file?.id, file]);

  useEffect(() => {
    if (!instance) return;

    const toggleElements = ['leftPanelButton', 'toolsHeader', SAVE_DATA_ELEMENT, 'toolbarGroup-Insert'];

    if (!isPDF) {
      instance?.UI.disableElements(toggleElements);
    } else {
      instance?.UI.enableElements(toggleElements);
    }
  }, [isPDF, instance]);

  useEffect(() => {
    return () => {
      setInstance(undefined);
    };
  }, [setInstance]);

  return (
    <Spin spinning={isLoading || isUpdating} className="PreviewFile">
      {header || <PreviewFileHeader file={file} />}
      <div className="PreviewFile__preview-container">
        <div
          className="webviewer"
          ref={viewerRef}
          style={{ height: webviewerHeight, visibility: file ? 'visible' : 'hidden' }}
        />
      </div>
    </Spin>
  );
}

export default PreviewFile;
